import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { useBaggageEditButton } from "../common/useBaggageEditButton";
import { usePerBookingQuantityButtons } from "./usePerBookingQuantityButtons";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    setIsOversizedBaggageOpen: (isOpen: boolean) => void;
}

export const useMobilePerBookingOversized = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const editButton = useBaggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            m: true,
            c: "OversizedBaggage",
        }),
        isDisabled: props.baggageSection.perBooking.quantity === undefined,
        onClick: () => handlePerJourneyPerPaxViewOpen(),
    });

    const quantityButtons = usePerBookingQuantityButtons({
        baggageSection: props.baggageSection,
        isMobile: true,
    });

    const notBoughtPerBookingBag = useMemo(
        () =>
            props.baggageSection.perBooking.isAddAvailable &&
            props.baggageSection.state.journeys.every((stateJourney) =>
                stateJourney.passengers.every((passenger) => {
                    const journeyPassengers = props.context.journeys.find(
                        (journey) => journey.index === stateJourney.index,
                    ).passengers;
                    const journeyPassenger = journeyPassengers.find((pax) => pax.index === passenger.index);
                    return journeyPassenger.oversizedBaggage.quantity === 0;
                }),
            ),
        [props.baggageSection.perBooking.isAddAvailable, props.baggageSection.state.journeys, props.context.journeys],
    );

    const unformattedLowestNextPrice = (isMinimum: boolean) =>
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: isMinimum,
            original: false,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const formattedLowestNextPrice = (isMinimum: boolean) =>
        formatNumber({ amount: unformattedLowestNextPrice(isMinimum), leadingSign: true });

    // Event handlers

    const handlePerBookingAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.baggageSection.handlers.add();
    };

    const handlePerJourneyPerPaxViewOpen = () => {
        props.baggageSection.handlers.openPerJourneyPerPaxView();
        props.setIsOversizedBaggageOpen(true);
    };

    // Templates

    const perBookingNextPriceTemplate = () =>
        props.baggageSection.perBooking.isAddAvailable
            ? html`
                  <div class="b2-add-another-oversized">
                      ${i18next.t("Agrega otro por")}
                      <span
                          class="b2-amount"
                          data-test-value=${unformattedLowestNextPrice(false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, {
                              c: "OversizedBaggage",
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(false)}
                      </span>
                  </div>
              `
            : "";

    const perBookingMobileFreeSelectedTemplate = () => html`
        <div class="b2m-add-oversized">
            ${i18next.t("Agregar por")}
            <span
                data-test-value=${unformattedLowestNextPrice(true)}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                    c: "OversizedBaggage",
                    m: true,
                })}
            >
                ${formattedLowestNextPrice(true)}
            </span>
        </div>
        <button
            @click=${handlePerBookingAdd}
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                m: true,
                c: "OversizedBaggage",
            })}
            class="b2-primary-button"
        >
            ${i18next.t("Agregar")}
        </button>
    `;

    const perBookingMobilePaidSelectedTemplate = () => html`
        <div class="flex flex-col items-center justify-center">
            <div class="flex w-full items-center justify-around">
                <button disabled class="b2-primary-button ticked">${i18next.t("Agregado")}</button>
                <div class="b2-oversized-quantity">
                    <div class="ac-per-booking-quantity-buttons">${quantityButtons.htmlTemplate()}</div>
                </div>
            </div>
            ${perBookingNextPriceTemplate()}
        </div>
    `;

    const canSwitchView = () =>
        props.baggageSection.state.journeys.every((j): boolean =>
            j.passengers.every((p): boolean => {
                const journeyPassengers = props.context.journeys.find(
                    (journey) => journey.index === j.index,
                ).passengers;
                const journeyPassenger = journeyPassengers.find((pax) => pax.index === p.index);
                return p.hasSelected && journeyPassenger.oversizedBaggage.quantity > 0;
            }),
        );

    const perBookingMobileViewSwitchTemplate = () => {
        const infoText = (() => paxAmount(props.baggageSection.state) === 1)()
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

        return canSwitchView()
            ? html`
                  <div class="b2m-view">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">${infoText}</div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(props.baggageSection.state)}
                              ${paxLabel(paxAmount(props.baggageSection.state))}
                          </div>
                      </div>
                      ${editButton.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () =>
        props.baggageSection.state.perJourneyPerPaxState !== "open"
            ? html`
                  <div class="mb-2 mt-4 flex items-center justify-around">
                      ${notBoughtPerBookingBag
                          ? perBookingMobileFreeSelectedTemplate()
                          : perBookingMobilePaidSelectedTemplate()}
                  </div>
                  ${perBookingMobileViewSwitchTemplate()}
              `
            : html``;

    return { htmlTemplate };
};

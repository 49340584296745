export const isBinInRange = (
    binRangeLower: string | number,
    binRangeHigher: string | number,
    cardNumber: string,
): boolean => {
    const lowerBinLength = binRangeLower?.toString().length ?? 0;
    const higherBinLength = binRangeHigher?.toString().length ?? 0;
    const lowerBin = cardNumber.substr(0, lowerBinLength);
    const higherBin = cardNumber.substr(0, higherBinLength);

    return (
        (!binRangeLower || Number(binRangeLower) <= Number(lowerBin)) &&
        (!binRangeHigher || Number(binRangeHigher) >= Number(higherBin))
    );
};

import { BundleData, BundleOfferDetails } from "../../component-models/flight/BundleOffersV2Model";
import { ExtendedTripModel } from "../../component-models/flight/ExtendedTripModel";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { ROUTES } from "../../shared/apiRoutes";
import { commonDebug } from "../../bootstrap";
import { getRequestBodyFromInputs, hideLoader, showLoader } from "../../shared/common";
import { mapBundleOffers } from "../../component-mappers/BundleOffersMapper";
import { useBookingManager } from "../../managers/useBookingManager";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { FarelockType } from "./useFarelock";
import { BundleHelper } from "../../component-helpers/flight/BundleHelper";
import { ApiFarelockPriceResponse } from "../../V2/booking/flight/ApiFarelockPriceResponse";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const useFlightPageAjaxHandler = () => {
    const bookingManager = useBookingManager();

    const { ajaxJsonRequest, ajaxRequest } = useAjax();

    const addFarelockToBooking = async (
        type: FarelockType,
        viewModel: FlightPageViewModel,
        rootElement: HTMLElement,
    ) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });
        const body = {
            fareLockCode:
                type === "Duration24"
                    ? viewModel.FarelockViewModel.SellKeyForFareLock24
                    : viewModel.FarelockViewModel.SellKeyForFareLock48,
            timeZoneOffsetMin: (new Date().getTimezoneOffset() * -1).toString(),
        };

        const container = rootElement.parentElement;

        await bookingManager.postAddFarelock(body, container, loader);
    };

    const removeFarelockFromBooking = async (rootElement: HTMLElement) => {
        const container = rootElement.parentElement;
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });

        await bookingManager.postCancelFarelock(container, loader);
    };

    // TODO SBBD
    const submitDcForm = async (form: HTMLFormElement, rootElement: HTMLElement) => {
        try {
            await ajaxRequest({
                body: getRequestBodyFromInputs(form),
                container: rootElement.parentElement,
                form,
                url: ROUTES.ApplyMembership,
            });
        } catch (e) {
            commonDebug.error(e);
        }
    };

    const getTotal = async (
        sellKeyOut: string,
        sellKeyBack: string,
        bundleTypeOut: string,
        bundleTypeBack: string,
        isOneWay: boolean,
    ) => {
        const body = {
            SellKeyOut: sellKeyOut,
            BundleTypeOut: bundleTypeOut,
            SellKeyBack: !isOneWay ? sellKeyBack : undefined,
            BundleTypeBack: !isOneWay ? bundleTypeBack : undefined,
        };

        const response = await ajaxJsonRequest<BundleOfferDetails>({
            url: ROUTES.ApiRoutes.BundleForBanner,
            isJsonData: true,
            body,
            method: "POST",
        });

        return response?.statusCode === 200 ? response.data : undefined;
    };

    const updateFarelockPrices = async (
        sellKeys: string[],
        rootElement: HTMLElement,
        viewModel: FlightPageViewModel,
    ) => {
        if (!sellKeys || sellKeys.filter((key) => key).length === 0) {
            return [];
        }

        try {
            const body = {
                SellKeys: sellKeys.filter((key) => key),
            };

            const container = DomCrawlingHelper.findParentByClass(rootElement, LOADER_CLASS_NAMES.CommonLoaderWrapper);
            const response = await ajaxJsonRequest<ApiFarelockPriceResponse>({
                container,
                body,
                isJsonData: true,
                url: ROUTES.ApiRoutes.FarelockOffers,
                method: "POST",
            });

            if (response?.statusCode === 200) {
                const fareLockCode24 = viewModel.FarelockViewModel.SellKeyForFareLock24;
                const fareLockCode48 = viewModel.FarelockViewModel.SellKeyForFareLock48;
                const newPrice24 = response.data.FareLockPrices.find((np) => np.Code === fareLockCode24);
                const newPrice48 = response.data.FareLockPrices.find((np) => np.Code === fareLockCode48);

                return [newPrice24.Amount.FormattedAmount, newPrice48.Amount.FormattedAmount];
            } else {
                commonDebug.error("Could not update Fare Lock prices.");
            }
        } catch (e) {
            commonDebug.error("Could not update Fare Lock prices.");
        }

        return [];
    };

    // TODO SBBD
    const getCustomizedBundleOffers = async (loader: JsLoader, journeys: ExtendedTripModel[], culture: string) => {
        const keys = BundleHelper.getAllAvailableSellKeys(journeys);

        const bundleDataResponse =
            keys.length > 0
                ? await ajaxJsonRequest<BundleData>({
                      nonCancellable: true,
                      loader,
                      method: "POST",
                      url: ROUTES.ApiRoutes.BundleData,
                      body: { SellKeys: keys },
                      isJsonData: true,
                  })
                : undefined;

        if (!bundleDataResponse) {
            hideLoader(loader);
        }

        const bundleOffers =
            bundleDataResponse?.statusCode === 200
                ? bundleDataResponse.data
                : { BundleOffersCollection: [], BundleInfoCollection: [] };

        const customizedBundleOffers = mapBundleOffers(bundleOffers, culture);

        const shouldConcatenateBagSsrsInBundles = BundleHelper.shouldConcatenateBagsInBundleSsrList(
            bundleOffers.BundleOffersCollection,
        );

        return { customizedBundleOffers, shouldConcatenateBagSsrsInBundles };
    };

    return {
        addFarelockToBooking,
        getCustomizedBundleOffers,
        getTotal,
        removeFarelockFromBooking,
        submitDcForm,
        updateFarelockPrices,
    };
};

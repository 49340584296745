import { html } from "haunted";
import { useAppContext } from "../../managers/useAppContext";
import { classMap } from "lit-html/directives/class-map";
import { BRASILIAN_CULTURE_CODE, CHILEAN_CULTURE_CODE } from "../../shared/commonConstants";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ProfileAdvertisementWidgetViewModel } from "../../component-models/profile/ProfileAdvertisementWidgetViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: ProfileAdvertisementWidgetViewModel;
}

export const useProfileAdvertisementWidget = (props: Props) => {
    const getDestinationsUrl = () =>
        `https://jetsmart.com/${appContext.Country.toLowerCase()}/${appContext.Culture.toLowerCase().substring(
            0,
            2,
        )}/destinos`;

    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");

    const bancoEstadoAdTemplate = () =>
        ![5, 6].includes(userContext.bancoEstado.category) &&
        !userContext.isStaff &&
        appContext.Culture === CHILEAN_CULTURE_CODE
            ? html`
                  <div class="profile-banco-estado-ad">
                      <img class="hidden-xs" src="/Images/BancoEstado/be-logo.svg" />
                      <div class="bead-info">
                          <span>${i18next.t("MP-AdText")}</span>
                          <span
                              >${unsafeHTML(
                                  i18next.t("MP-AdText2 {{-spanStart}}{{-spanEnd}}", {
                                      spanStart: "<span>",
                                      spanEnd: "</span>",
                                  }),
                              )}</span
                          >
                      </div>
                      <a href=${props.model.BancoEstadoUrl} target="_blank">
                          ${i18next.t("MP-MoreInfo")}
                          <i class="js-icon js-circle-chevron-right"></i>
                      </a>
                  </div>
              `
            : "";

    const destinationsAdTemplate = () => {
        const tmpClassMap = classMap({
            "pdad-info": true,
            "portuguese": appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return !userContext.isStaff && appContext.Culture !== CHILEAN_CULTURE_CODE
            ? html`
                  <div class="profile-destinations-ad">
                      <img src="/Images/BancoEstado/new-profile-ad-logo.png" />
                      <div class=${tmpClassMap}>
                          ${unsafeHTML(
                              i18next.t("MP-DestinationsAd {{-br}}{{-spanStart}}{{-spanEnd}}", {
                                  br: "<br />",
                                  spanStart: "<span>",
                                  spanEnd: "</span>",
                              }),
                          )}
                      </div>
                      <button @click=${() => (window.location.href = getDestinationsUrl())}>
                          ${i18next.t("MP-Destinations")}
                          <i class="js-icon js-circle-chevron-right"></i>
                      </button>
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html` ${bancoEstadoAdTemplate()} ${destinationsAdTemplate()} `;

    return { htmlTemplate };
};

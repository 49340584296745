import classNames from "classnames";
import { html, TemplateResult } from "lit-html";

export interface LabelProps {
    isDirty: boolean;
    isDisabled: boolean;
    label: string | TemplateResult;
    isSmaller?: boolean;
    isReadOnly?: boolean;
}

export const useLabel = (props: LabelProps) => {
    const htmlTemplate = () => html`
        <label
            class=${classNames(
                "pointer-events-none absolute bottom-0 left-0 right-0 block w-full overflow-hidden whitespace-nowrap pl-[15px] text-left leading-[43px] common-transition",
                "peer-focus:font-black peer-focus:text-brand-secondary",
                {
                    "peer-focus:top-[-8px] peer-focus:text-[14px]": !props.isSmaller,
                    "peer-focus:top-[-10px] peer-focus:text-[12px]": props.isSmaller,
                    "top-[3px] text-[16px] opacity-25 peer-focus:opacity-100": !props.isDirty && !props.isSmaller,
                    "top-[0px] text-[13px] opacity-25 peer-focus:opacity-100": !props.isDirty && props.isSmaller,
                    "top-[-8px] text-[14px] font-black text-brand-secondary": props.isDirty && !props.isSmaller,
                    "top-[-10px] text-[12px] font-black text-brand-secondary": props.isDirty && props.isSmaller,
                    "disabled": props.isDisabled,
                    "opacity-50": props.isReadOnly,
                },
            )}
        >
            ${props.label}
        </label>
    `;

    return { htmlTemplate };
};

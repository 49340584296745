import { ROUTES } from "./../../../shared/apiRoutes";
import { useEffect, useState } from "./../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import {
    getAntiForgeryTokenFromHtml,
    getAntiforgerySegment,
    getRequestBodyFromNamedInputs,
    handleCugLoader,
} from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { classMap } from "lit-html/directives/class-map";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { InputFieldAttribute } from "../../../shared/customHooks/useForm/InputFieldAttribute";
import { passwordFormat } from "../../../shared/customHooks/useForm/custom-attributes/passwordFormat";
import { passwordConfirm } from "../../../shared/customHooks/useForm/custom-attributes/passwordConfirm";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const name = "ac-cug-password-edit";

export interface Properties {
    antiForgeryToken: string;
    canEditPassword: boolean;
    hasCompanyModel: boolean;
    hasUserModel: boolean;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canEditPassword: host.canEditPassword,
        hasCompanyModel: host.hasCompanyModel,
        hasUserModel: host.hasUserModel,
    };

    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
    };

    const customValidationRules = (): InputFieldAttribute[] => [passwordFormat(), passwordConfirm()];

    // COMPONENT

    const passwordFormElement = useRef<HTMLFormElement>(undefined);
    const passwordInputElement = useRef<HTMLInputElement>(undefined);
    const passwordConfirmInputElement = useRef<HTMLInputElement>(undefined);

    const [userContext] = useReduxState("userContext");

    const { ajaxRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [editPasswordMode, setEditPasswordMode] = useState<boolean>(true);
    const [showPasswordSaveError, setShowPasswordSaveError] = useState<boolean>(false);

    const form = useForm({
        customAttributes: [...commonValidationRules(), ...customValidationRules()],
        noScroll: true,
    });

    useEffect(() => form.init(passwordFormElement.current), [passwordFormElement.current]);
    useEffect(init, []);

    // EVENT LISTENERS

    const handlePasswordSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPasswordSaveError(false);

        const isValid = await form.validate();

        if (isValid) {
            handleCugLoader(passwordFormElement.current, "editPassword");

            await ajaxRequest({
                body: getRequestBodyFromNamedInputs(passwordFormElement.current),
                form: passwordFormElement.current,
                onResponseCode: {
                    204: () => setEditPasswordMode(false),
                    400: () => setShowPasswordSaveError(true),
                },
            });

            handleCugLoader(passwordFormElement.current, "editPassword");
        }
    };

    // TEMPLATES

    const saveMessageTemplate = () =>
        !editPasswordMode
            ? html`
                  <div class="cug2b-save-message">
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const passwordEditorTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-profile-edit": true,
            "cursor-default": editPasswordMode,
            "cursor-pointer": !editPasswordMode,
        });

        const onClick = !editPasswordMode ? () => setEditPasswordMode(true) : null;

        return props.canEditPassword && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <div class=${tempClassMap} @click=${onClick}>
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";
    };

    const newPasswordTemplate = () => {
        const tempClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "disabled": !editPasswordMode || !props.canEditPassword,
        });

        return html`
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Contraseña nueva")}</label>
                <input
                    ref=${ref(passwordInputElement)}
                    type="password"
                    class=${tempClassMap}
                    autocomplete="off"
                    name="NewPassword"
                    data-required
                    data-test-id=${T.CUG2_CHANGE_PASSWORD.NEW_PASSWORD_INPUT_FIELD}
                    password-format
                />
                <div class="cug-info-icon cug-register">
                    <ac-tooltip
                        .icon=${"?"}
                        .tooltip=${i18next.t(
                            "La contraseña debe contener entre 8 y 16 caracteres e incluir letras y números.",
                        )}
                    ></ac-tooltip>
                </div>
            </div>
        `;
    };

    const confirmPasswordTemplate = () => {
        const tempClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "disabled": !editPasswordMode || !props.canEditPassword,
        });

        return html`
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Confirmación de nueva contraseña")}</label>
                <input
                    ref=${ref(passwordConfirmInputElement)}
                    type="password"
                    class=${tempClassMap}
                    autocomplete="off"
                    data-required
                    data-test-id=${T.CUG2_CHANGE_PASSWORD.CONFIRM_PASSWORD_INPUT_FIELD}
                    password-confirm
                />
            </div>
        `;
    };

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !editPasswordMode || !props.canEditPassword,
        });

        return html`
            <div class="mt-8 flex w-full justify-end">
                <button
                    @click=${handlePasswordSubmit}
                    class=${tempClassMap}
                    data-test-id=${T.CUG2_CHANGE_PASSWORD.SAVE_BUTTON}
                >
                    ${i18next.t("Guardar")}
                </button>
            </div>
        `;
    };

    const passwordErrorTemplate = () =>
        showPasswordSaveError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Password could not be changed.")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const mainClassMap = classMap({
        "cug2b-edit-profile-form": true,
        "ts-error-parent": true,
        "mt-8": props.hasUserModel || props.hasCompanyModel,
        "md:mt-16": props.hasUserModel || props.hasCompanyModel,
    });

    return html`
        <form
            ref=${ref(passwordFormElement)}
            action=${ROUTES.ApiRoutes.Cug2BChangePassword}
            method="post"
            class=${mainClassMap}
            novalidate="novalidate"
        >
            <h1>${i18next.t("Cambiar contraseña")} ${passwordEditorTemplate()}</h1>
            ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)}
            <div class="row">
                <div class="col-xs-1 col-sm-1-3">${newPasswordTemplate()}</div>
                <div class="col-xs-1 col-sm-1-3">${confirmPasswordTemplate()}</div>
            </div>
            ${passwordErrorTemplate()} ${submitButtonTemplate()}
        </form>
    `;
};

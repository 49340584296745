import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useAppContext } from "../../managers/useAppContext";
import { ProfilePageViewModel } from "../../component-models/profile/ProfilePageViewModel";
import { classMap } from "lit-html/directives/class-map";
import { useProfilePersonWidget } from "./profile-person-widget/useProfilePersonWidget";
import { useProfileBenefitWidget } from "./useProfileBenefitWidget";
import { useProfileDcWidget } from "./useProfileDcWidget";
import { useProfileTripsWidget } from "./useProfileTripsWidget";
import { useProfileRockstarWidget } from "./useProfileRockstarWidget";
import { useProfileAdvertisementWidget } from "./useProfileAdvertisementWidget";
import { useProfileGiftcardWidget } from "./useProfileGiftcardWidget";
import { useProfileSearchWidget } from "./useProfileSearchWidget";
import { useProfileBancoeWidget } from "./useProfileBancoeWidget";
import { useProfileTodosumaWidget } from "./useProfileTodosumaWidget";
import { getParsedProperty } from "../../shared/common";
import { useGiftcardSuccessModal } from "./useGiftcardSuccessModal";
import { ACTIONS_WITH_BANCO_ESTADO_BAR, ACTION_NAMES } from "../../shared/commonConstants";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-profile-page";

export const observedAttributes: (keyof Attributes)[] = ["model", "anti-forgery-token"];

export interface Attributes {
    "model": string;
    "anti-forgery-token": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ProfilePageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ProfilePageViewModel>(host.model),
    };

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const giftcardSuccessModal = useGiftcardSuccessModal({
        userName: props.model.PersonWidgetViewModel.ProfilePersonalDataViewModel.FirstName,
    });

    const personWidget = useProfilePersonWidget({
        model: props.model.PersonWidgetViewModel,
        antiForgeryToken: props.antiForgeryToken,
    });

    const benefitWidget = useProfileBenefitWidget({
        model: props.model.BenefitWidgetViewModel,
    });

    const dcWidget = useProfileDcWidget({
        model: props.model.DcWidgetViewModel,
    });

    const tripsWidget = useProfileTripsWidget({
        model: props.model.TripsWidgetViewModel,
        antiForgeryToken: props.antiForgeryToken,
    });

    const rockstarWidget = useProfileRockstarWidget({
        model: props.model.RockStarWidgetViewModel,
    });

    const advertisementWidget = useProfileAdvertisementWidget({
        model: props.model.AdvertisementWidgetViewModel,
    });

    const giftcardWidget = useProfileGiftcardWidget({
        model: props.model.GiftcardWidgetViewModel,
    });

    const searchWidget = useProfileSearchWidget({
        model: props.model.SearchWidgetViewModel,
    });

    const bancoeWidget = useProfileBancoeWidget({
        model: props.model.BancoEstadoWidgetViewModel,
    });

    const todosumaWidget = useProfileTodosumaWidget({
        model: props.model.TodosumaWidgetViewModel,
    });

    const isCurrentActionForBancoEstadoBanners = () => ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action) > -1;

    const isBancoEstadoBarDisplayed = () =>
        [5, 6].includes(userContext.bancoEstado.category) &&
        (flowContext.action === ACTION_NAMES.PROFILE ||
            (isCurrentActionForBancoEstadoBanners() && flowContext.isBookingFlow));

    const benefitOrDcWidgetTemplate = () =>
        userContext.isStaff ? html` ${benefitWidget.htmlTemplate()} ` : html` ${dcWidget.htmlTemplate()} `;

    const tripsWidgetTemplate = () => html` ${tripsWidget.htmlTemplate()} `;

    const rockstarWidgetTemplate = () => html` ${rockstarWidget.htmlTemplate()} `;

    const advertisementWidgetTemplate = () => html` ${advertisementWidget.htmlTemplate()} `;

    const giftcardWidgetTemplate = () => html` ${giftcardWidget.htmlTemplate()} `;

    const searchWidgetTemplate = () => html` ${searchWidget.htmlTemplate()} `;

    const bancoeWidgetTemplate = () =>
        [5, 6].includes(userContext.bancoEstado.category) ? html` ${bancoeWidget.htmlTemplate()} ` : "";

    const todosumaWidgetTemplate = () =>
        [5, 6].includes(userContext.bancoEstado.category) && !appContext.isFeatureSwitchActive("DisableTodoSuma")
            ? html` ${todosumaWidget.htmlTemplate()} `
            : "";

    const mainClassMap = classMap({
        "be-bar-push-down": isBancoEstadoBarDisplayed(),
    });

    const sectionClassMap = classMap({
        "profile-widget-container": true,
        "banco-estado-5": userContext.bancoEstado.category === 5,
        "banco-estado-6": userContext.bancoEstado.category === 6,
        "staff": userContext.isStaff,
        "pwc-club-member": userContext.dc.hasMembership,
    });

    return html`
        <div id="mainContentWrapper" class=${mainClassMap}>
            <section class=${sectionClassMap}>
                ${benefitOrDcWidgetTemplate()} ${tripsWidgetTemplate()} ${rockstarWidgetTemplate()}
                ${advertisementWidgetTemplate()} ${giftcardWidgetTemplate()} ${searchWidgetTemplate()}
                ${bancoeWidgetTemplate()} ${todosumaWidgetTemplate()}
                <div class="profile-widget profile-person-widget">${personWidget.htmlTemplate()}</div>
            </section>
        </div>
        ${giftcardSuccessModal.htmlTemplate()}
    `;
};

import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageStateJourney, BaggageStateJourneyPassenger } from "../../../../component-models/baggage/BaggageState";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BagType } from "../useBaggagePage";
import { StaffBaggage } from "./useStaffBaggage";
import { PerJourneyPerPaxAvailability } from "../../../../component-models/baggage/BaggageSection";
import { useCallback } from "haunted";
import { useBookingContext } from "../../../../managers/useBookingContext";

interface Props {
    bagType: BagType;
    context: BaggageContext;
    staffBaggage: StaffBaggage;
}

export const usePerJourneyPerPaxAvailability = (props: Props): PerJourneyPerPaxAvailability => {
    const bookingContext = useBookingContext();

    const isAddAvailable = useCallback(
        (data: { journeyIndex: number; passengerIndex: number }) => {
            if (
                (data.journeyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound) ||
                (data.journeyIndex === INBOUND && bookingContext.isCheckinClosedInbound)
            ) {
                return false;
            }

            const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journeyIndex,
                passengerIndex: data.passengerIndex,
            });

            const available =
                props.context.journeys[data.journeyIndex][
                    props.bagType === "CabinBaggage"
                        ? "availableCabinBaggage"
                        : props.bagType === "CheckedBaggage"
                          ? "availableCheckedBaggage"
                          : "availableOversizedBaggage"
                ];

            return (
                !contextJourneyPassengerBaggage.isSoldOut &&
                contextJourneyPassengerBaggage.quantity < contextJourneyPassengerBaggage.max &&
                available > 0
            );
        },
        [
            props.context.getContextJourneyPassengerBaggage,
            bookingContext.isCheckinClosedInbound,
            bookingContext.isCheckinClosedOutbound,
            props.bagType,
            props.context,
        ],
    );

    const isRemoveAvailable = useCallback(
        (data: { journeyIndex: number; passengerIndex: number }) => {
            if (
                (data.journeyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound) ||
                (data.journeyIndex === INBOUND && bookingContext.isCheckinClosedInbound)
            ) {
                return false;
            }

            const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journeyIndex,
                passengerIndex: data.passengerIndex,
            });

            return (
                !contextJourneyPassengerBaggage.isSoldOut &&
                contextJourneyPassengerBaggage.quantity > Math.max(1, contextJourneyPassengerBaggage.min)
            );
        },
        [
            props.context.getContextJourneyPassengerBaggage,
            bookingContext.isCheckinClosedInbound,
            bookingContext.isCheckinClosedOutbound,
            props.bagType,
            props.context,
        ],
    );

    const isResetAvailable = useCallback(
        (data: { journeyIndex: number; passengerIndex: number }) => {
            if (
                (data.journeyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound) ||
                (data.journeyIndex === INBOUND && bookingContext.isCheckinClosedInbound)
            ) {
                return false;
            }

            const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journeyIndex,
                passengerIndex: data.passengerIndex,
            });

            return contextJourneyPassengerBaggage.min === 0;
        },
        [
            props.context.getContextJourneyPassengerBaggage,
            bookingContext.isCheckinClosedInbound,
            bookingContext.isCheckinClosedOutbound,
            props.bagType,
            props.context,
        ],
    );

    const getNextPrice = useCallback(
        (data: { isOriginal: boolean; journey: BaggageStateJourney; passenger: BaggageStateJourneyPassenger }) => {
            // DEVNOTE Staff members get the first bag for $1
            if (
                props.staffBaggage.canGetFirstBaggageStaffDiscount({
                    bagType: props.bagType,
                    journeyIndex: data.journey?.index,
                    paxIndex: data.passenger?.index,
                })
            ) {
                return FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
            }

            const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journey?.index,
                passengerIndex: data.passenger?.index,
            });

            if (!contextJourneyPassengerBaggage) return 0;

            return (
                data.isOriginal ? contextJourneyPassengerBaggage.originalPrices : contextJourneyPassengerBaggage.prices
            )[
                contextJourneyPassengerBaggage.quantity < contextJourneyPassengerBaggage.max
                    ? contextJourneyPassengerBaggage.quantity
                    : contextJourneyPassengerBaggage.quantity - 1
            ];
        },
        [
            props.staffBaggage.canGetFirstBaggageStaffDiscount,
            props.context.getContextJourneyPassengerBaggage,
            props.bagType,
        ],
    );

    return {
        getNextPrice,
        isAddAvailable,
        isRemoveAvailable,
        isResetAvailable,
    };
};

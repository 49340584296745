import { ROUTES } from "./../../shared/apiRoutes";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "haunted";
import { STAFF_DISCOUNT_80 } from "../../shared/commonConstants";
import { ProfileBenefitWidgetViewModel } from "../../component-models/profile/ProfileBenefitWidgetViewModel";

export interface Props {
    model: ProfileBenefitWidgetViewModel;
}

export const useProfileBenefitWidget = (props: Props) => {
    // HELPERS

    const getAll80PercentTrips = () =>
        props.model.StaffBenefitDiscount
            ? props.model.StaffBenefitDiscount.AvailableDiscounts.filter(
                  (d) => d.DiscountCode === STAFF_DISCOUNT_80,
              ).reduce((aggr, discount) => aggr + discount.PassengerSegmentAvailable + discount.PassengerSegmentUsed, 0)
            : 0;

    const getUsed80PercentTrips = () =>
        props.model.StaffBenefitDiscount
            ? props.model.StaffBenefitDiscount.AvailableDiscounts.filter(
                  (d) => d.DiscountCode === STAFF_DISCOUNT_80,
              ).reduce((aggr, discount) => aggr + discount.PassengerSegmentUsed, 0)
            : 0;

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const htmlTemplate = () =>
        html`
            <div class="profile-benefit-widget-container">
                <div class="profile-benefit-widget">
                    <div class="pbw-section-slogan">
                        ${unsafeHTML(
                            i18next.t("Conéctate SMART {{-spanStart}}{{-spanEnd}}", {
                                spanStart: "<span>",
                                spanEnd: "</span>",
                            }),
                        )}
                    </div>
                    <div class="pbw-section-journeys">
                        <span class="pbw-trip-discount-ribbon"
                            >${i18next.t("{{used}} de {{all}} tramos", {
                                used: getUsed80PercentTrips().toString(),
                                all: getAll80PercentTrips().toString(),
                            })}</span
                        >
                        <span class="pbw-trip-discount-info"
                            >${unsafeHTML(
                                i18next.t("comprados con 80% de descuento {{-spanStart}}{{-spanEnd}}", {
                                    spanStart: "<span>",
                                    spanEnd: "</span>",
                                }),
                            )}</span
                        >
                    </div>
                    <div class="pbw-section-icons">
                        <span class="pbw-icon-description">
                            <i class="js-icon-employee-discount js-ed-circling-planes"></i>
                            ${i18next.t("50% dcto pasajes ilimitado")}
                        </span>
                        <span class="pbw-icon-description">
                            <i class="js-icon-employee-discount js-ed-circle-giftcard"></i>
                            ${i18next.t("Gift card cumpleaños")}
                        </span>
                        <span class="pbw-icon-description">
                            <i class="js-icon-employee-discount js-ed-circle-baggage"></i>
                            ${i18next.t("Equipaje facturado $1")}
                        </span>
                        <span class="pbw-icon-description">
                            <i class="js-icon-employee-discount js-ed-circle-pets"></i>
                            ${i18next.t("Mascota a bordo $1")}
                        </span>
                    </div>
                    <div class="pbw-section-button">
                        <a class="rounded-primary-btn blue-white" href=${ROUTES.PageStaffBenefit}
                            >${i18next.t("Ver todos Beneficios")}</a
                        >

                        <a class="rounded-primary-btn blue-white" href=${ROUTES.PageStaffFamilyMembers}
                            >${i18next.t("Ver beneficiarios")}</a
                        >
                    </div>
                </div>
            </div>
        `;

    return { htmlTemplate };
};

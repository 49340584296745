import { ExtendedLoginResult } from "../../component-models/LoginResult";
import {
    ACTION_NAMES,
    AGENCY_URL_PARTIAL_STRING,
    CHILEAN_CULTURE_CODE,
    COMPANY_URL_PARTIAL_STRING,
    LOGIN_TYPE,
    NEW_SEARCH_ON_LOGIN_ACTIONS,
    OrgType,
    PERUVIAN_CULTURE_CODE,
    PERU_COMPRA_PARTIAL_STRING,
} from "../../shared/commonConstants";
import i18next from "i18next";
import { useState } from "../../shared/haunted/CustomHooks";
import { html, useRef, useEffect as hauntedUseEffect } from "haunted";
import { convertLoginResultToUserContext, sanitizeRutFieldValue, updateMdl } from "../../shared/common";
import { validateRut } from "../../shared/form-validation";
import { ROUTES } from "../../shared/apiRoutes";
import { resetErrorMessages } from "../../shared/errorHandler";
import { ref } from "../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useLogin } from "../login/useLogin";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { DEFAULT_LOGIN_VM, LoginVM } from "../login/login-model";
import { isPasswordLengthValid } from "../../validator/validation-helper";
import { useModal } from "../shared/useModal";
import classNames from "classnames";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-login-modal";

export interface Props {
    agency: string;
    bancoEstado: string;
    company: string;
    personal: string;
    peruCompra: string;
}

type FieldNames = keyof LoginVM;

const loginTypeInputName = "jetSmartMemberLogin.LoginType";

export const observedAttributes: (keyof Attributes)[] = [
    "agency",
    "banco-estado",
    "company",
    "personal",
    "peru-compra",
];

export interface Attributes {
    "agency": string;
    "banco-estado": string;
    "company": string;
    "personal": string;
    "peru-compra": string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        agency: host.agency,
        bancoEstado: host.bancoEstado,
        company: host.company,
        personal: host.personal,
        peruCompra: host.peruCompra,
    };

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const [_, setUserContext] = useReduxState("userContext");

    const { triggers } = usePubSub();

    const root = useRef<HTMLDivElement>(undefined);

    const [currentCallback, setCurrentCallback] = useState<Function>(undefined);
    const [isForced, setIsForced] = useState<boolean>(false);
    const [isRefund, setIsRefund] = useState<boolean>(false);
    const [loginType, setLoginType] = useState<string>(props.personal);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<LoginVM>(DEFAULT_LOGIN_VM);

    const authenticator = useLogin();

    // HELPERS

    const isLoginUserNameReadOnly = () => {
        return false;
        /* TODO: until 16 length slice on username, no prepopulation
        return Boolean(refundEmail) || Boolean(refundRut); */
    };

    const isNonBancoEstadoUsernameFormatValid = () =>
        loginType === props.bancoEstado ||
        !/^[\d|\.|\-]+$/.test(vm.username) ||
        appContext.Culture !== CHILEAN_CULTURE_CODE;

    const getUrl = () => {
        switch (loginType) {
            case props.bancoEstado:
                return ROUTES.ForgotPasswordBancoEstado;
            case LOGIN_TYPE.AGENCY:
                return `${ROUTES.ForgotPassword}?${AGENCY_URL_PARTIAL_STRING}=1`;
            case LOGIN_TYPE.COMPANY:
                return `${ROUTES.ForgotPassword}?${COMPANY_URL_PARTIAL_STRING}=1`;
            case LOGIN_TYPE.PC:
                return `${ROUTES.ForgotPassword}?${PERU_COMPRA_PARTIAL_STRING}=1`;
            default:
                return ROUTES.ForgotPassword;
        }
    };

    // EVENT HANDLERS

    const handeLoggedInUser = (result: ExtendedLoginResult) => {
        triggers.flight.flightPageCloseLoginInMenubar.publish({});
        modal.close();

        if (isRefund) {
            window.location.reload();
            return;
        }

        if (
            loginType === props.bancoEstado &&
            flowContext.isGiftcardPurchaseFlow &&
            flowContext.action === ACTION_NAMES.PAYMENT
        ) {
            window.location.href = ROUTES.GiftcardSelect;
            return;
        }

        if (currentCallback) {
            currentCallback(result);
        }

        setUserContext(convertLoginResultToUserContext(result));
    };

    const handleRadioSelect = (type: string) => {
        setLoginType(type);
        setVm({ username: "", password: "" });
        setIsValidated(false);

        resetErrorMessages(root.current);
    };

    const openModal = (isForced?: boolean, callback?: (result: ExtendedLoginResult) => Promise<void>) => {
        modal.open();
        setIsRefund(false);

        if (isForced) {
            setIsForced(true);
            handleRadioSelect(props.personal);
        }

        if (typeof callback === "function") {
            setCurrentCallback(() => callback);
        }
    };

    const openModalForRefund = (orgType?: OrgType) => {
        modal.open();
        setIsRefund(true);

        switch (orgType) {
            case props.bancoEstado:
                handleRadioSelect(props.bancoEstado);
                break;
            case props.peruCompra:
                handleRadioSelect(props.peruCompra);
                break;
            case props.agency:
                handleRadioSelect(props.agency);
                break;
            case props.company:
                handleRadioSelect(props.company);
                break;
            default:
                handleRadioSelect(props.personal);
        }
    };

    const handleClose = () => {
        modal.hide();
        setIsValidated(false);
        setVm({ username: "", password: "" });
        setLoginErrorMessage("");

        if (isRefund) {
            triggers.itinerary.closeRefundModal.publish(false);
        }

        triggers.flight.flightPageCloseLoginInMenubar.publish({});
    };

    const handleKeyup = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSubmit();
        }

        if (!isForced && e.key === "Escape") {
            handleClose();
        }
    };

    const showSearchTriggerWarning = () => {
        return (
            (flowContext.isBookingFlow || flowContext.isFarelockRoundOne) &&
            NEW_SEARCH_ON_LOGIN_ACTIONS.some((action) => action.toLowerCase() === flowContext.action)
        );
    };

    const handleSubmit = async () => {
        setLoginErrorMessage("");

        setIsValidated(true);
        const isValid = await validator.validate();

        if (!isValid) {
            return;
        }

        setShowProgressBar(true);

        const result = await authenticator.login({
            Username: vm.username,
            Password: vm.password,
            LoginType:
                loginType === props.agency
                    ? "AGENCY"
                    : loginType === props.bancoEstado
                      ? "BE"
                      : loginType === props.company
                        ? "COMPANY"
                        : loginType === props.peruCompra
                          ? "PC"
                          : "JA",
            Lgi: isForced ? "1" : undefined,
            Redirect: false,
        });

        if (result && result.LoginStatus === "loggedIn") {
            handeLoggedInUser(result);
        } else if (!result || result.LoginStatus === "notLoggedIn") {
            setLoginErrorMessage(result.ErrorMessage);
        }

        setShowProgressBar(false);
    };

    const sanitizeRutFieldInput = (e: KeyboardEvent) => {
        if (loginType === props.bancoEstado) {
            return sanitizeRutFieldValue(e.target as HTMLInputElement);
        }

        return (e.target as HTMLInputElement).value;
    };

    const subscribeToRefundModalOpen = () => {
        setLoginErrorMessage("");
        const handler = triggers.itinerary.openLoginModalForRefund.subscribe(openModalForRefund);

        return () => handler.unsubscribe();
    };

    const subscribeToLoginModalOpen = () => {
        const handler = triggers.login.openLoginModal.subscribe((e) => openModal(e.isForced, e.callback));

        return () => handler.unsubscribe();
    };

    // COMPONENT

    const validator = useFluentValidator<FieldNames, LoginVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("username", (vm: LoginVM) => vm.username).isRequired(),
            Validation.ruleFor("username", (vm: LoginVM) => vm.username)
                .isRequired()
                .fulfils(
                    (_) => Promise.resolve(loginType !== props.bancoEstado || validateRut(vm.username)),
                    i18next.t("Passengers-RutError"),
                    "form",
                ),
            Validation.ruleFor("username", (vm: LoginVM) => vm.username).fulfils(
                async (_) => isNonBancoEstadoUsernameFormatValid(),
                i18next.t("Tipo de inicio de sesión no válido para el usuario de BancoEstado."),
            ),
            Validation.ruleFor("password", (vm: LoginVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => isPasswordLengthValid(password),
                    i18next.t("La contraseña debe incluir entre 8 y 16 caracteres y, debe contener letras y números."),
                ),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    hauntedUseEffect(subscribeToRefundModalOpen, [loginType]);

    hauntedUseEffect(subscribeToLoginModalOpen, []);

    // TEMPLATE

    const invalidAgentTypeErrorTemplate = () => html`
        ${loginType === props.agency
            ? i18next.t("You are trying to log in with an organization that is a company, please log in")
            : i18next.t("You are trying to log in with an organization that is an agency, please log in")}<a
            class="text-be-blue hover:text-be-red"
            href="/V2/Login?${loginType === props.agency ? COMPANY_URL_PARTIAL_STRING : AGENCY_URL_PARTIAL_STRING}=1"
        >
            &nbsp;${i18next.t("here")}
        </a>
    `;

    const loginErrorTemplate = () => {
        let credentialErrorMessage: string;

        switch (loginType) {
            case props.agency:
            case props.company:
                credentialErrorMessage = i18next.t(
                    "El correo electrónico, nombre de usuario y/o contraseña es incorrecta. Por favor, Intentalo de nuevo.",
                );
                break;
            case props.bancoEstado:
                credentialErrorMessage = i18next.t("RUT y/o contraseña incorrecto. Por favor, inténtalo de nuevo.");
                break;
            default:
                credentialErrorMessage = i18next.t("V2-InvalidEmailOrPassword");
                break;
        }

        return loginErrorMessage
            ? html`
                  <div class="login-error" data-test-id=${T.LOGIN_MODAL.ERROR_MESSAGE}>
                      ${loginErrorMessage === "Error_InvalidAgentType"
                          ? invalidAgentTypeErrorTemplate()
                          : loginErrorMessage === "InvalidLoginCredentials"
                            ? credentialErrorMessage
                            : i18next.t(loginErrorMessage)}
                  </div>
              `
            : "";
    };

    const progressBarTemplate = () =>
        showProgressBar
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <p>${i18next.t("Header-LoggingIn")}</p>
                          <div class="mdl-progress mdl-js-progress mdl-progress__indeterminate progress-bar"></div>
                      </div>
                  </div>
              `
            : "";

    const personRadioTemplate = () => {
        const id = "optionPerson";

        return html`
            <label class="mdl-radio mdl-js-radio option1" for=${id}>
                <input
                    type="radio"
                    id=${id}
                    name=${loginTypeInputName}
                    class="mdl-radio__button"
                    ?checked=${loginType === props.personal}
                    @click=${() => handleRadioSelect(props.personal)}
                    data-test-id=${T.LOGIN_MODAL.PERSON_RADIO_BUTTON}
                />
                <span class="mdl-radio__label">${i18next.t("V2-LoginPersons")}</span>
            </label>
        `;
    };

    const peruCompraRadioTemplate = () => {
        const id = "optionPeruCompra";

        return appContext.isFeatureSwitchActive("PeruCompraInlineLogin") &&
            appContext.Culture === PERUVIAN_CULTURE_CODE &&
            !flowContext.isPostBookingFlow &&
            !isRefund
            ? html`
                  <label
                      class="mdl-radio mdl-js-radio option6"
                      for=${id}
                      data-test-id=${T.LOGIN_MODAL.PERU_COMPRA_RADIO_BUTTON}
                  >
                      <input
                          type="radio"
                          id=${id}
                          name=${loginTypeInputName}
                          class="mdl-radio__button"
                          ?checked=${loginType === props.peruCompra}
                          @click=${() => handleRadioSelect(props.peruCompra)}
                      />
                      <span class="mdl-radio__label">${i18next.t("Perú Compras")}</span>
                  </label>
              `
            : "";
    };

    const agencyRadioTemplate = () => {
        const id = "optionAgency";

        return !flowContext.isGiftcardPurchaseFlow
            ? html`
                  <label
                      class="mdl-radio mdl-js-radio option3"
                      for=${id}
                      data-test-id=${T.LOGIN_MODAL.AGENCY_RADIO_BUTTON_LABEL}
                  >
                      <input
                          type="radio"
                          id=${id}
                          name=${loginTypeInputName}
                          class="mdl-radio__button"
                          ?checked=${loginType === props.agency}
                          @click=${() => handleRadioSelect(props.agency)}
                          data-test-id=${T.LOGIN_MODAL.AGENCY_RADIO_BUTTON}
                      />
                      <span class="mdl-radio__label">${i18next.t("V2-LoginAgency")}</span>
                  </label>
              `
            : "";
    };

    const companyRadioTemplate = () => {
        const id = "optionCompany";

        return !flowContext.isGiftcardPurchaseFlow
            ? html`
                  <label class="mdl-radio mdl-js-radio option5" for=${id}>
                      <input
                          type="radio"
                          id=${id}
                          name=${loginTypeInputName}
                          class="mdl-radio__button"
                          ?checked=${loginType === props.company}
                          @click=${() => handleRadioSelect(props.company)}
                          data-test-id=${T.LOGIN_MODAL.COMPANY_RADIO_BUTTON}
                      />
                      <span class="mdl-radio__label">${i18next.t("V2-LoginCompany")}</span>
                  </label>
              `
            : "";
    };

    const bancoEstadoRadioTemplate = () => {
        const id = "optionBancoEstado";

        return appContext.isFeatureSwitchActive("BancoEstado") && appContext.Culture === CHILEAN_CULTURE_CODE
            ? html`
                  <label class="mdl-radio mdl-js-radio option4" for=${id}>
                      <input
                          type="radio"
                          id=${id}
                          name=${loginTypeInputName}
                          class="mdl-radio__button"
                          ?checked=${loginType === props.bancoEstado}
                          @click=${() => handleRadioSelect(props.bancoEstado)}
                          data-test-id=${T.LOGIN_MODAL.BANCO_ESTADO_RADIO_BUTTON}
                      />
                      <span class="mdl-radio__label nowrap radio-with-img">
                          ${i18next.t("BE-LoginLabel")}
                          <img src="/Images/BancoEstado/be-name.svg" />
                      </span>
                  </label>
              `
            : "";
    };

    const loginTypeSelectorTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="logintype-tabs">
                    ${personRadioTemplate()} ${agencyRadioTemplate()} ${companyRadioTemplate()}
                    ${peruCompraRadioTemplate()} ${bancoEstadoRadioTemplate()}
                </div>
            </div>
        </div>
    `;

    const formTemplate = () => html`
        ${usernameFieldTemplate()} ${passwordFieldTemplate()} ${formErrors.htmlTemplate()}
    `;

    const usernameFieldTemplate = () => {
        const label =
            loginType === props.bancoEstado
                ? i18next.t("Header-RUTPlaceholder")
                : loginType === props.agency || loginType === props.company
                  ? i18next.t("Correo electrónico / Nombre de usuario")
                  : loginType === props.peruCompra
                    ? i18next.t("DNI")
                    : i18next.t("Header-EmailAddressPlaceholder");

        return html`
            <div class="row">
                <div class="col-xs-1">
                    <div class="mt-[20px]">
                        <ac-input
                            .errorMessage=${validator.getMessage("username")}
                            .isInvalid=${!validator.isValid("username")}
                            .label=${label}
                            .getFocusOnRender=${true}
                            .autoComplete=${"cc-exp"}
                            .sanitizer=${(e: KeyboardEvent) => sanitizeRutFieldInput(e)}
                            .testId=${T.LOGIN_MODAL.EMAIL}
                            .readonly=${isLoginUserNameReadOnly()}
                            .value=${vm.username}
                            .onInput=${(value: string) => setVm({ ...vm, username: value })}
                            .onKeyUp=${(e: KeyboardEvent) => handleKeyup(e)}
                        ></ac-input>
                    </div>
                </div>
            </div>
        `;
    };

    const passwordFieldTemplate = () => {
        return html`
            <div class="row">
                <div class="col-xs-1">
                    <div class="mt-[20px]">
                        <ac-input
                            .errorMessage=${validator.getMessage("password")}
                            .isInvalid=${!validator.isValid("password")}
                            .type=${"password"}
                            .label=${i18next.t("Header-PasswordLabel")}
                            .autoComplete=${"new-password"}
                            .testId=${T.LOGIN_MODAL.PASSWORD}
                            .value=${vm.password}
                            .onInput=${(value: string) => setVm({ ...vm, password: value })}
                            .onKeyUp=${(e: KeyboardEvent) => handleKeyup(e)}
                        ></ac-input>
                    </div>
                </div>
            </div>
        `;
    };

    const buttonsTemplate = () => {
        const pwButtonClassMap = classMap({
            "text-brand-secondary": true,
            "text-center": true,
            "text-medium": true,
            "sm:text-base": true,
            "md:text-medium": true,
            "mt-3": true,
            "text-be-orange": loginType === props.bancoEstado,
        });

        const submitButtonClassMap = classMap({
            "rounded-primary-btn": true,
            "banco-estado-color": loginType === props.bancoEstado,
        });

        return html`
            <div class="mt-[20px] flex w-full justify-center sm:justify-end">
                <div class="flex w-3/4 flex-col justify-around sm:w-2/5">
                    <a @click=${handleSubmit} class=${submitButtonClassMap} data-test-id=${T.LOGIN_MODAL.SUBMIT_BUTTON}>
                        ${i18next.t("Application-Login")}
                    </a>
                    <a href=${getUrl()} class=${pwButtonClassMap} data-test-id=${T.LOGIN_MODAL.FORGOT_PASSWORD_BUTTON}>
                        ${i18next.t("Header-ForgotPasswordButton")}
                    </a>
                </div>
            </div>
        `;
    };

    const warningTemplate = () =>
        showSearchTriggerWarning()
            ? html`
                  <div class="banco-estado-login-footnote">
                      ${loginType === props.bancoEstado
                          ? i18next.t("BE-LoginFootnote")
                          : i18next.t("RedoSearchWarning")}
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <i class="fas fa-exclamation-circle notification-icon"></i>
        <span class="secondary-modal-header-text">${i18next.t("Signin")}</span>
    `;

    const htmlTemplate = () => html`
        <div
            ref=${ref(root)}
            class=${classNames("p-5 pb-3", {
                "px-5 sm:px-20": isRefund,
            })}
        >
            ${loginErrorTemplate()} ${progressBarTemplate()} ${loginTypeSelectorTemplate()} ${formTemplate()}
            ${buttonsTemplate()} ${warningTemplate()}
        </div>
        ${updateMdl()}
    `;

    const modal = useModal({
        closing: { isClosable: !isForced, buttonClassNames: "cancel-login", onClose: handleClose },
        content: {
            classNames: classNames("booking-modal-content ts-error-parent", {
                "banco-estado-login": loginType === props.bancoEstado,
            }),
            template: htmlTemplate,
        },
        header: { template: headerTemplate },
        overlay: {
            classNames: classNames("float-login-modal", { "refund-login": isRefund, "secondary-modal": isRefund }),
            testId: T.LOGIN_MODAL.CONTAINER,
        },
    });

    return html`${modal.htmlTemplate()} ${authenticator.htmlTemplate()}`;
};

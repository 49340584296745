import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useRef } from "haunted";
import { hideLoader, showLoader } from "../../shared/common";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";

const loaderAnimationClass = "farelock-animation";

export interface Props {
    context: FlightPageContext;
    fareLock24FormattedPrice: string;
    showFarelockDurationSelector: boolean;
}

export const useFarelockSelector = (props: Props) => {
    const loaderContainer = useRef<HTMLDivElement>(undefined);

    const [loader, setLoader] = useState<JsLoader>(undefined);

    const handleLoader = () => {
        if (!props.fareLock24FormattedPrice && !loader) {
            const container = loaderContainer.current;
            setLoader(
                showLoader({
                    container,
                    noPlane: true,
                    name: loaderAnimationClass,
                }),
            );
        }

        if (props.fareLock24FormattedPrice && loader) hideLoader(loader);
    };

    // EVENT LISTENERS

    const handleOpenFarelockSelector = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.showFarelockDurationSelector) return;

        props.context?.openFarelockSelector();
    };

    // EFFECTS

    useEffect(handleLoader, [props.fareLock24FormattedPrice]);

    // TEMPLATES

    const contentTemplate = () =>
        props.fareLock24FormattedPrice
            ? html`
                  <div class="fare-lock-selector-container" data-test-id=${T.FARELOCK.SELECTOR}>
                      <i class="js-icon js-fare-lock" data-test-id=${T.FARELOCK.ICON}></i>
                      <div class="fare-lock-selector">
                          <span>${i18next.t("FareLockInfo-1")}</span>
                          <span data-test-id=${T.FARELOCK.MIN_PRICE} data-test-value=${props.fareLock24FormattedPrice}>
                              ${i18next.t("FareLockInfo-2 {{-amount}}", {
                                  amount: props.fareLock24FormattedPrice,
                              })}
                          </span>
                      </div>
                      <button
                          class=${classNames("fare-lock-button", { selected: props.showFarelockDurationSelector })}
                          data-test-id=${T.FARELOCK.BUTTON_YES}
                          @click=${handleOpenFarelockSelector}
                      >
                          ${i18next.t("FareLockSelectorButton")}
                          <i class="js-icon js-circle-chevron-right"></i>
                      </button>
                  </div>
              `
            : "";

    const loadingTemplate = () =>
        !props.fareLock24FormattedPrice
            ? html`<div class="fare-lock-selector-container no-padding">
                  <div
                      class=${classNames(
                          "absolute inset-0 flex h-full w-full items-center justify-center",
                          "sm:static sm:inset-auto",
                      )}
                      ref=${ref(loaderContainer)}
                  >
                      <div class="${loaderAnimationClass} h-full w-full"></div>
                  </div>
              </div>`
            : "";

    const htmlTemplate = () => {
        const tempClassNames = classNames("flight-farelock-button-container", {
            "pull-up": props.context?.bundleState.bundlesMode === "PerLeg",
        });

        return html`
            <div class=${tempClassNames} data-test-id=${T.FARELOCK.CONTAINER}>
                <div class="row">
                    <div class="col-xs-1">${contentTemplate()} ${loadingTemplate()}</div>
                </div>
            </div>
        `;
    };

    return { htmlTemplate };
};

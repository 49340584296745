import { FloorplanDictionary } from "../../../../component-models/seatmap/FloorplanDictionary";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { html } from "lit-html";
import { ref } from "../../../../directives/ref";
import { useRef } from "haunted";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { BANCO_ESTADO_SEAT_ZONE_START_ROW } from "../../../../shared/commonConstants";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    handleSeatClick: (seat: ExtendedApiSeat) => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const useSeatmapSegment = (props: Props) => {
    // HELPERS

    const getLowestSeatPriceByCategory = (catNumber: number) => {
        const seatsWithCategory = allSeats?.filter((seat) => seat?.CategoryNumber === catNumber);

        const lowestPrice = seatsWithCategory.length
            ? seatsWithCategory.reduce(
                  (currentMinimum, currSeat) => (currSeat?.Price < currentMinimum ? currSeat?.Price : currentMinimum),
                  Number.POSITIVE_INFINITY,
              )
            : -1;

        return lowestPrice;
    };

    const formatSmallestSeatPriceCategory = (catNumber: number) =>
        lowestCategoryPricesMap.get(catNumber) !== -1
            ? `${i18next.t("DesdeV2")} ${formatNumber({ amount: lowestCategoryPricesMap.get(catNumber) }).replace(" ", "")}`
            : "Not available";

    const toiletsTop = () => ({
        numberLeft: floorplan().Toilets.FrontLeft,
        numberRight: floorplan().Toilets.FrontRight,
        showFlightNumber: true,
    });

    const toiletsBottom = () => ({
        numberLeft: floorplan().Toilets.BackLeft,
        numberRight: floorplan().Toilets.BackRight,
        showFlightNumber: false,
    });

    const modelSeats = () => props.seatmapState?.CurrentSegment?.Seats || [];

    const rowHasExtraLegroom = (row: number) => {
        const seats = getSeatsOfRow(row).filter((s) => s);

        return seats.length > 0 && seats.every((seat) => seat.HasExtraLegroom);
    };

    const rowHasEmergencyExit = (row: number) => {
        const seats = getSeatsOfRow(row).filter((s) => s);

        return seats.length > 0 && seats.every((seat) => seat.IsInExitRow);
    };

    const getRows = () => Array.from(new Set<number>(modelSeats().map((seat) => seat.Row)).values());

    const getSeatsOfRow = (row: number) =>
        ["A", "B", "C", "D", "E", "F"].map((column) =>
            modelSeats().find((seat) => seat.Row === row && seat.Column === column),
        );

    const isBancoEstadoRow = (seats: ExtendedApiSeat[]) =>
        props.context.Model.ShowBancoEstadoSeatMapZone && seats.some((seat) => seat.IsBancoEstado);

    const floorplan = () =>
        FloorplanDictionary.get(
            Array.from(FloorplanDictionary.keys()).find(
                (key) => props.seatmapState.CurrentSegment.PlaneType.toLowerCase().indexOf(key.toLowerCase()) > -1,
            ),
        );

    const isRowSeparatedByGap = (row: number) => floorplan().GapBeforeRows.includes(row);

    const isSeatOffsetForward = (seat: ExtendedApiSeat) =>
        seat ? floorplan().SeatsOffsetForward.includes(`${seat.Row}${seat.Column}`) : false;

    const isSeatOffsetBackward = (seat: ExtendedApiSeat) =>
        seat ? floorplan().SeatsOffsetBackward.includes(`${seat.Row}${seat.Column}`) : false;

    const doesWingStartAtRow = (row?: number) => row && floorplan().WingStartsAtRow === row;

    const isStartOfBancoEstadoZone = (row: number) =>
        props.context.Model.ShowBancoEstadoSeatMapZone && row === BANCO_ESTADO_SEAT_ZONE_START_ROW;

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);
    const allSeats = useMemo<ExtendedApiSeat[]>(
        () => [].concat(...getRows().map((row) => getSeatsOfRow(row).filter((s) => s))),
        [props.seatmapState],
    );

    const lowestCategoryPricesMap = useMemo(() => {
        const uniqueCategories = new Set(allSeats.map((seat) => seat?.CategoryNumber));
        const lowestPricesMap = new Map(
            [...uniqueCategories].map((category) => {
                const lowestPrice = getLowestSeatPriceByCategory(category);
                return [category, lowestPrice];
            }),
        );

        return lowestPricesMap;
    }, [allSeats]);

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    // TEMPLATES

    const bancoEstadoZoneLogoTemplate = (row: number) =>
        props.context.Model.ShowBancoEstadoSeatMapZone && row === 10
            ? html`
                  <div class="cf-banco-estado-zone-logo">
                      <span class="cf-banco-estado-tooltip-opener">
                          <span class="cf-banco-estado-tooltip">
                              <i class="js-icon js-cr-seats"></i>
                              <div>
                                  <span class="cf-banco-estado-emphasis">${i18next.t("Clientes BancoEstado")}</span>
                                  <span class="cf-banco-estado-tooltip-info"
                                      >${i18next.t("Asientos reservados para clientes BancoEstado")}</span
                                  >
                              </div>
                          </span>
                      </span>
                      <span>${window.seatmapResources.zoneLabel1}</span>
                      <span class="hidden-xs">${window.seatmapResources.zoneLabel2}</span>
                      <img src="/Images/BancoEstado/be-logo-w.svg" />
                  </div>
              `
            : "";

    const exitsTemplate = () => html`
        <div class="cf-exits">${rowBackgroundTemplate()} ${leftExitTemplate()} ${rightExitTemplate()}</div>
    `;

    const leftExitTemplate = () => html` <img class="cf-exit-icon" src="/Images/Seatmap/exit-left.svg" /> `;

    const rightExitTemplate = () => html` <img class="cf-exit-icon" src="/Images/Seatmap/exit-right.svg" /> `;

    const bancoEstadoMobileLogoTemplate = (row: number) =>
        row === 9 && props.context.Model.ShowBancoEstadoSeatMapZone
            ? html` <div class="cf-banco-estado-mobile-logo"></div> `
            : "";

    const rowExitsTemplate = (row: number) =>
        rowHasEmergencyExit(row)
            ? html` <div class="cf-exit-row">${leftExitTemplate()} ${rightExitTemplate()}</div> `
            : "";

    const categoryGroupLabelTemplate = (category: number) => html`
        <div class="cf-seatmap-row">
            ${rowBackgroundTemplate()}
            <div
                class="category-${category} seatmap-row-price-category-label z-10 my-2 flex h-6 w-full items-center justify-center rounded bg-brand-secondary text-sm font-bold text-white"
            >
                ${formatSmallestSeatPriceCategory(category)}
            </div>
        </div>
    `;

    const rowTemplate = (row: number) => {
        const seatsInRow = getSeatsOfRow(row).filter((s) => s);

        const dataTestId = rowHasEmergencyExit(row)
            ? getTestId(T.SEATMAP.ROW, {
                  j: props.seatmapState.CurrentJourney.JourneyIndex,
                  s: props.seatmapState.CurrentSegment.SegmentIndex,
                  c: "emergency",
              })
            : getTestId(T.SEATMAP.ROW, {
                  j: props.seatmapState.CurrentJourney.JourneyIndex,
                  s: props.seatmapState.CurrentSegment.SegmentIndex,
              });

        const tempClassMap = classMap({
            "cf-seatmap-row": true,
            "banco-estado": isBancoEstadoRow(seatsInRow),
            "cf-banco-estado-zone": isStartOfBancoEstadoZone(row),
            "unavailable": props.seatmapState.CurrentSegment.AreAllBancoEstadoSeatsSold,
            "cf-gap-before": isRowSeparatedByGap(row),
            "extra-room": rowHasExtraLegroom(row),
            "is-banco-estado-category-56": [5, 6].includes(userContext.bancoEstado.category),
        });

        return html`
            <div class=${tempClassMap} data-test-id=${dataTestId} data-test-value=${row}>
                ${rowBackgroundTemplate(row)} ${bancoEstadoMobileLogoTemplate(row)} ${seatsTemplate(row)}
                ${bancoEstadoZoneLogoTemplate(row)} ${rowExitsTemplate(row)}
            </div>
        `;
    };

    const rowBackgroundTemplate = (row?: number) => {
        const url = doesWingStartAtRow(row) ? "/Images/Seatmap/seatmap-wings.svg" : "/Images/Seatmap/seatmap-body.svg";
        const smUrl = doesWingStartAtRow(row)
            ? "/Images/Seatmap/seatmap-wings-sm.svg"
            : "/Images/Seatmap/seatmap-body-sm.svg";
        const xsUrl = "/Images/Seatmap/seatmap-body-xs.svg";

        return html`
            <img class="cf-plane-body-image hidden-md-down ${doesWingStartAtRow(row) ? "keep-ratio" : ""}" src=${url} />
            <img
                class="cf-plane-body-image hidden-lg-up ${doesWingStartAtRow(row) ? "keep-ratio" : ""} hidden-xs"
                src=${smUrl}
            />
            <img class="cf-plane-body-image hidden-sm-up" src=${xsUrl} />
        `;
    };

    const seatsTemplate = (row: number) => getSeatsOfRow(row).map(seatTemplate);

    const seatTemplate = (seat: ExtendedApiSeat) => {
        const tempClassMap = classMap({
            "cf-seatmap-seat-container": true,
            "cf-mobile-vertical-offset": seat?.HasExtraLegroom,
            "cf-offset-backward": isSeatOffsetBackward(seat),
            "cf-offset-forward": isSeatOffsetForward(seat),
        });

        return seat
            ? html`
                  <div class=${tempClassMap}>
                      <ac-seat
                          .apiSeat=${seat}
                          .context=${props.context}
                          .currentSeats=${props.currentSeats}
                          .seatmapState=${props.seatmapState}
                          .handleSeatClick=${props.handleSeatClick}
                          .updateState=${props.updateState}
                      ></ac-seat>
                  </div>
                  ${rowNumberingTemplate(seat)}
              `
            : html` <div class=${tempClassMap}></div> `;
    };

    const rowNumberingTemplate = (seat: ExtendedApiSeat) =>
        seat.Column === "C" ? html` <div class="cf-seatmap-seat-container cf-row-number">${seat.Row}</div> ` : "";

    const flightNumberTemplate = (showFlightNumber: boolean) =>
        showFlightNumber
            ? html`
                  <div class="flight-number">
                      ${props.seatmapState.CurrentSegment.CarrierCode} ${props.seatmapState.CurrentSegment.FlightNumber}
                  </div>
              `
            : "";

    const toilettesTemplate = (data: { numberLeft: number; numberRight: number; showFlightNumber: boolean }) => html`
        <div class="cf-toilette-row">
            ${rowBackgroundTemplate()}
            <div>${[...Array(data.numberLeft)].map(toiletteTemplate)}</div>
            <div>${[...Array(data.numberRight)].map(toiletteTemplate)}</div>
            ${flightNumberTemplate(data.showFlightNumber)}
        </div>
    `;

    const toiletteTemplate = () => html` <img class="cf-toilette-icon" src="/Images/Seatmap/toilette.svg" /> `;

    const renderRowsTemplate = () =>
        getRows().map((row) => {
            let previousRow = row;
            let seatsInPreviousRow: ExtendedApiSeat[] = [];
            while (previousRow >= 1 && !seatsInPreviousRow.length) {
                previousRow--;
                seatsInPreviousRow = getSeatsOfRow(previousRow).filter((s) => s);
            }

            const seatsInRow = getSeatsOfRow(row).filter((s) => s);

            const currentCategory = seatsInRow[0]?.CategoryNumber;
            const previousCategory = row === 1 ? allSeats[0]?.CategoryNumber : seatsInPreviousRow[0]?.CategoryNumber;

            return currentCategory !== previousCategory
                ? html`${categoryGroupLabelTemplate(currentCategory)}${rowTemplate(row)}`
                : html`${rowTemplate(row)}`;
        });

    const htmlTemplate = () =>
        Array.isArray(modelSeats()) && props.seatmapState?.CurrentSegment
            ? html`
                  <div
                      ref=${ref(root)}
                      data-test-id=${getTestId(T.SEATMAP.JOURNEY_PLANE, {
                          j: props.seatmapState.CurrentJourney.JourneyIndex,
                          s: props.seatmapState.CurrentSegment.SegmentIndex,
                      })}
                  >
                      ${toilettesTemplate(toiletsTop())} ${exitsTemplate()}
                      <div class="cf-seatmap-row">${categoryGroupLabelTemplate(allSeats[0]?.CategoryNumber)}</div>
                      ${renderRowsTemplate()} ${exitsTemplate()} ${toilettesTemplate(toiletsBottom())}
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

import { useRef } from "haunted";

export const useRunOnce = () => {
    const hasRun = useRef(false);

    const run = (callback: () => void, id?: string) => {
        if (hasRun.current) return;

        hasRun.current = true;
        callback();

        // eslint-disable-next-line no-console
        if (id) console.log(id);
    };

    const reset = () => (hasRun.current = false);

    return { run, reset };
};

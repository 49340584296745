import { City, Country, Region, RoutePickerModel } from "../../component-models/RoutePickerModel";
import { ApiStation } from "../../component-models/ApiCountry";
import { MacDictionary } from "../../managers/useCountryManager";
import { isPossibleDestination } from "../../component-mappers/StationMappers";

export const stationUtils = () => {
    const getAllCities = (countries: Country[]) =>
        countries.reduce(
            (allCities: City[], country) =>
                allCities.concat(
                    country.regions.reduce((countryCities: City[], region) => countryCities.concat(region.cities), []),
                ),
            [],
        );

    const getPossibleDestinations = (countries: Country[], origin: City): Country[] => {
        if (!origin) return countries;

        return countries
            .map((country) => getCountryWithPossibleDestinations(country, origin))
            .filter((country) => country.regions.length > 0);
    };

    const getCountryWithPossibleDestinations = (country: Country, origin: City): Country => ({
        ...country,
        regions: getRegionsWithPossibleDestinations(country, origin),
    });

    const getRegionsWithPossibleDestinations = (country: Country, origin: City): Region[] =>
        country.regions
            .map((region) => getRegionWithPossibleDestinations(region, origin))
            .filter((region) => region.cities.length > 0);

    const getRegionWithPossibleDestinations = (region: Region, origin: City): Region => ({
        ...region,
        cities: region.cities.filter((city) => isPossibleDestination(origin, city)),
    });

    const doesCityBeginWithSubstring = (city: City, searchExpression: string) =>
        city.code.toLowerCase().indexOf(searchExpression.toLowerCase()) === 0 ||
        getDisplayedCityName(city).toLowerCase().indexOf(searchExpression.toLowerCase()) === 0;

    const shouldShowRegion = (region: Region, searchExpression: string) =>
        region.cities.some((city) => doesCityBeginWithSubstring(city, searchExpression));

    const getMacDictionary = (allApiStations: ApiStation[]): MacDictionary => {
        const retVal = new Map<string, Set<string>>();

        allApiStations
            .filter((apiStation) => apiStation.macs?.code)
            .forEach((apiStation) => {
                if (apiStation.macs.codes.length > 0) {
                    const key = apiStation.macs.code;
                    const valuesToAdd = apiStation.macs.codes.map((code) => code.macStation);
                    const newValue = retVal.has(key)
                        ? new Set<string>([...retVal.get(key), ...valuesToAdd])
                        : new Set<string>(valuesToAdd);
                    retVal.set(key, newValue);
                    return;
                }

                retVal.set(apiStation.macs.code, new Set([apiStation.code]));
            });

        return retVal;
    };

    // EXPORTS

    const getDisplayedCityName = (city: City) => `${city.name} (${city.code})`;

    const getFilteredModel = (model: RoutePickerModel, searchExpression: string): RoutePickerModel => {
        if (!searchExpression) return model;

        return {
            countries: model.countries
                .filter((country) => country.regions.some((region) => shouldShowRegion(region, searchExpression)))
                .map((country) => ({
                    name: country.name,
                    code: country.code,
                    isAvailableForPeruCompras: country.isAvailableForPeruCompras,
                    regions: country.regions
                        .filter((region) => shouldShowRegion(region, searchExpression))
                        .map((region) => ({
                            name: region.name,
                            cities: region.cities.filter((city) => doesCityBeginWithSubstring(city, searchExpression)),
                        })),
                })),
        };
    };

    const getRouteModel = (countries: Country[], selectedOrigin?: string): RoutePickerModel => {
        if (!countries) throw new Error("Station handler was not initialized.");

        if (selectedOrigin) {
            let origin = getCityByCode(countries, selectedOrigin);

            if (!origin) origin = getMacBySubstationCode(countries, selectedOrigin);

            return { countries: getPossibleDestinations(countries, origin) };
        }

        return { countries };
    };

    const getMacBySubstationCode = (countries: Country[], cityCode: string): City =>
        getAllCities(countries).find((city) => city.subStations.includes(cityCode));

    const getCityByCode = (countries: Country[], cityCode: string): City =>
        getAllCities(countries).find((city) => city.code === cityCode);

    const getCountryByCityCode = (countries: Country[], cityCode: string): Country =>
        cityCode
            ? countries.find((country) =>
                  country.regions.some((region) => region.cities.some((city) => city.code === cityCode)),
              )
            : undefined;

    const isOnlyOneFilteredCityLeft = (model: RoutePickerModel) =>
        model.countries.length === 1 &&
        model.countries[0].regions.length === 1 &&
        model.countries[0].regions[0].cities.length === 1;

    const getPreselectedCity = (model: RoutePickerModel, code: string) => {
        const cities = model.countries.reduce(
            (allCities: City[], country) =>
                allCities.concat(
                    country.regions.reduce((countryCities: City[], region) => countryCities.concat(region.cities), []),
                ),
            [],
        );

        return cities.find((ci) => ci.code === code) || cities.find((ci) => ci.subStations.includes(code));
    };

    return {
        getCityByCode,
        getCountryByCityCode,
        getDisplayedCityName,
        getFilteredModel,
        getMacBySubstationCode,
        getMacDictionary,
        getPreselectedCity,
        getRouteModel,
        isOnlyOneFilteredCityLeft,
        isPossibleDestination,
    };
};

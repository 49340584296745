import { getTestId, TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { OUTBOUND } from "./../../shared/commonConstants";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { Day, Month, TripCalendar, Week } from "../../component-models/flight-calendar/TripCalendar";
import { FlightCalendarProps } from "./flight-calendar-page";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { isMobile } from "../../shared/common";
import { MonthSelectEvent } from "../../component-models/flight-calendar/FlightCalendarEvents";

export const name = "ac-flight-calendar-journey";

export interface Props {
    calendarIndex: number;
    context: FlightCalendarProps;
    model: TripCalendar;
    selectedOutboundDate: dayjs.Dayjs;
    selectedInboundDate: dayjs.Dayjs;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        calendarIndex: host.calendarIndex,
        context: host.context,
        model: host.model,
        selectedInboundDate: host.selectedInboundDate,
        selectedOutboundDate: host.selectedOutboundDate,
    };

    const isSelected = (month: dayjs.Dayjs) =>
        (props.calendarIndex === OUTBOUND ? props.selectedOutboundDate : props.selectedInboundDate).isSame(
            month,
            "month",
        );

    const handleMonthClick = (date: dayjs.Dayjs) =>
        host.dispatchEvent(new MonthSelectEvent({ date, calendarIndex: props.calendarIndex }));

    const headerTemplate = () => html`
        <div class="ts-error-container"></div>
        <header>
            ${props.calendarIndex === OUTBOUND
                ? i18next.t("Itinerary-OutboundFlightShort")
                : i18next.t("Itinerary-InboundFlight")}
        </header>
    `;

    const monthsTemplate = () => html`
        <div class="months" data-test-id=${getTestId(T.FLIGHT_CALENDAR.MONTH_SELECTOR, { j: props.calendarIndex })}>
            <div class="row no-gutter">
                ${props.model.Months.map(monthTemplate)}
            </div>
        </div>
    `;

    const monthTemplate = (month: Month) => {
        const name = isMobile() ? month.ShortName : month.Name;
        const dataTestId = getTestId(
            isSelected(month.Date) ? T.FLIGHT_CALENDAR.MONTH_SELECTED : T.FLIGHT_CALENDAR.MONTH,
            { j: props.calendarIndex },
        );

        const tempClassMap = classMap({
            "month-tab": true,
            "selected": isSelected(month.Date),
        });

        return html`
            <div class="col-xs-1-7">
                <div
                    class=${tempClassMap}
                    @click=${() => handleMonthClick(month.Date)}
                    data-test-id=${dataTestId}
                    data-test-value=${month.Date.format("YYYY-MM")}
                >
                    ${name}
                    <span>${month.Date.year()}</span>
                </div>
            </div>
        `;
    };

    const weekDaysTemplate = () => {
        const names = isMobile() ? props.context.shortDayNames : props.context.dayNames;

        return html`
            <div class="weekdays">
                <div class="row no-gutter">
                    ${names.map(weekDayTemplate)}
                </div>
            </div>
        `;
    };

    const weekDayTemplate = (dayName: string) => html`
        <div class="col-xs-1-7">${dayName}</div>
    `;

    const weeksTemplate = () =>
        props.model.Months.find((m) => m.Date.isSame(props.model.SelectedDay, "month"))?.Weeks?.map(weekTemplate);

    const weekTemplate = (week: Week) => html`
        <div class="week">
            <div class="row no-gutter">
                ${week.Days.map(dayTemplate)}
            </div>
        </div>
    `;

    const dayTemplate = (day: Day) => html`
        <div class="col-xs-1-7">
            <ac-flight-calendar-day
                .calendarIndex=${props.calendarIndex}
                .day=${day}
                .selectedInboundDate=${props.selectedInboundDate}
                .selectedOutboundDate=${props.selectedOutboundDate}
            >
            </ac-flight-calendar-day>
        </div>
    `;

    return html`
        <div
            class="flight-calendar ts-flight-error-parent"
            data-test-id=${getTestId(T.FLIGHT_CALENDAR.JOURNEY, { j: props.calendarIndex })}
        >
            ${headerTemplate()} ${monthsTemplate()} ${weekDaysTemplate()} ${weeksTemplate()}
        </div>
    `;
};

import i18next from "i18next";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { html } from "lit-html";
import { BoardingPassImageDto, useBoardingPassObtainer } from "../boarding-passes/useBoardingPassObtainer";

export const useShadowDOM = false;
export const name = "ac-boarding-pass";

export const Component: HauntedFunc<BoardingPassImageDto> = (host) => {
    const props: BoardingPassImageDto = {
        item: host.item,
        mobileItem: host.mobileItem,
        model: host.model,
        pnr: host.pnr,
    };

    const { printFull, downloadFull, downloadMobile } = useBoardingPassObtainer();

    // HELPERS

    const isIos = () => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return true;
        }
        return false;
    };

    // EVENTS

    const handlePrintClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        printFull(props);
    };

    const handleDownloadClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        downloadFull(props);
    };

    const handleMobileDownloadClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        downloadMobile(props);
    };

    // TEMPLATES

    const imageBoxTemplate = () => html`
        <div
            class="inner-box"
            data-test-id=${getTestId(T.CHECKIN.BOARDING_PASS, { p: Number(props.model.Passenger.PaxIndex) })}
        >
            <div class="checkin-pax-select ts-error-parent">
                ${props.item
                    ? html`<div class="visible-sm-up"><img style="max-width: 100%;" src=${props.item.image} /></div>
                          <div class="visible-xs"><img style="max-width: 100%;" src=${props.mobileItem.image} /></div>`
                    : ""}
            </div>
        </div>
    `;

    const boardingPassButtonContainerTemplate = () =>
        props.item
            ? html`
                  <div class="boarding-pass-btn-container">
                      <button class="rounded-transaction-btn print" @click=${handlePrintClick}>
                          ${i18next.t("Print-Print")}
                      </button>
                      <button class="rounded-transaction-btn download" class="hidden-xs" @click=${handleDownloadClick}>
                          ${i18next.t("Download")}
                      </button>
                      <button class="rounded-transaction-btn download" @click=${handleMobileDownloadClick}>
                          ${i18next.t("Print-DownloadMobile")}
                      </button>
                  </div>
              `
            : "";

    const iosRowTemplate = () =>
        isIos()
            ? html`
                  <div>
                      <div class="row">
                          <div class="col-xs-1">
                              <div class="full-width-information">
                                  <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                                  <span> ${i18next.t("CheckIn-IOSDownloadInstructions")} </span>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : html``;

    return html`${imageBoxTemplate()} ${boardingPassButtonContainerTemplate()} ${iosRowTemplate()}`;
};

import { OUTBOUND } from "./../shared/commonConstants";
import { CheckinTypeModel } from "../component-models/extras/checkin-type/CheckinTypeModel";
import { ApiExtrasSpaSectionViewModel } from "../component-models/spa/ExtrasSpaSectionViewModel";

export function mapToCheckinTypeModel(model: ApiExtrasSpaSectionViewModel): CheckinTypeModel {
    return {
        Passengers: model.ExtrasModel.CheckinTypePassengers.map((passenger) => {
            return {
                PassengerNumber: passenger.PassengerNumber,
                Type: passenger.PaxType,
                Journeys: passenger.CheckinTypeJourneys.map((journey) => {
                    return {
                        BundleColor:
                            journey.JourneyIndex === OUTBOUND ? model.OutboundBundleColor : model.InboundBundleColor,
                        BundleImg:
                            journey.JourneyIndex === OUTBOUND ? model.OutboundBundleImgUrl : model.InboundBundleImgUrl,
                        PostBookingPrice: journey.PostBookingPrice,
                        FreeOption: journey.FreeOption,
                        PaidOption: journey.PaidOption,
                        JourneyIndex: journey.JourneyIndex,
                        IsInBundle: journey.IsInBundle,
                        DepartureStationName: journey.DepartureStationName,
                        DestinationStationName: journey.DestinationStationName,
                        IsDisabled: journey.IsDisabled,
                    };
                }),
                DisplayedIndex:
                    passenger.PaxType === "ADT"
                        ? passenger.PassengerNumber + 1
                        : passenger.PassengerNumber -
                          model.ExtrasModel.CheckinTypePassengers.filter((p) => p.PaxType === "ADT").length +
                          1,
            };
        }),
    };
}

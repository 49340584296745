export const createFormHtmlElement = (route: string, body: any) => {
    const form = document.createElement("form");
    form.style.display = "none";
    form.action = route;
    form.method = "POST";

    Array.from(Object.keys(body)).forEach((key) => {
        if (body[key as keyof typeof body] === null) return;
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = body[key as keyof typeof body].toString();
        form.appendChild(input);
    });

    document.body.appendChild(form);

    return form;
};

import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import * as DC from "./dc-components";
import { ChangeEvent } from "./dc-checkbox";
import {
    AppliedFilter,
    Column,
    FilterChangeEvent,
    FilterProperty,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    SortOrder,
    ViewModel,
} from "./dc-table-models";
import { isEmpty } from "../component-helpers/stringHelper";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { useEffect, useState } from "../shared/haunted/CustomHooks";
import { createRange } from "../component-helpers/collectionHelper";
import { GridHelper } from "../component-helpers/GridHelper";

export interface Properties {
    vm: ViewModel<any>;
}

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-table";

export const getFilterByFieldName = <T>(appliedFilters: AppliedFilter<T>[], fieldName: T) => {
    const filters = appliedFilters.filter((filter) => filter.field === fieldName);
    if (filters.length > 0) {
        return filters[0];
    } else {
        return undefined;
    }
};

export const mergeAppliedFilter = <T>(appliedFilters: AppliedFilter<T>[], newFilter: AppliedFilter<T>) => {
    let newFilters = [];

    const isDateFilterOn = newFilter.filterProps.max instanceof Date || newFilter.filterProps.min instanceof Date;
    const isNumberFilterOn = !isNaN(newFilter.filterProps.max as number) || !isNaN(newFilter.filterProps.min as number);

    const hasSelectedValues =
        Array.isArray(newFilter.filterProps.selectedValues) && newFilter.filterProps.selectedValues.length > 0;

    const hasSelectedValue =
        typeof newFilter.filterProps.selectedValues === "string" && !isEmpty(newFilter.filterProps.selectedValues);

    if (isDateFilterOn || hasSelectedValues || hasSelectedValue || isNumberFilterOn) {
        let found = false;
        newFilters = appliedFilters.map((filter) => {
            if (filter.field === newFilter.field) {
                found = true;
                return newFilter;
            } else {
                return filter;
            }
        });
        if (!found) {
            newFilters.push(newFilter);
        }
    } else {
        newFilters = appliedFilters.filter((filter) => filter.field !== newFilter.field);
    }

    return newFilters;
};

const DEFAULTS: Properties = {
    vm: {
        forcedNumberOfRows: 0,
        columns: [] as Column<any>[],
        data: [] as any[],
        paging: {
            pageable: true,
            pageSize: 25,
            pageIndex: 0,
            buttonCount: 10,
            pageSizes: [25, 50, 100],
            showInfo: true,
            showPreviousNext: true,
        },
        sorting: {
            orderBy: undefined,
            orderDir: undefined,
            showSorterArrow: true,
        },
        selection: {
            selectable: false,
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: true,
    },
};

const addIsSelectedPropertyToData = (data: any[]): any[] => {
    return data.map((row) => {
        return { ...row, isSelected: false };
    });
};

export const Component: HauntedFunc<Properties> = (host) => {
    const getCellTemplate = <T>(column: Column<T>) =>
        column.cellTemplate
            ? column.cellTemplate
            : (index: number) => {
                  return props.vm.data[index][column.field];
              };

    const getCellClass = <T>(column: Column<T>) =>
        column.cellClass
            ? column.cellClass
            : (_index: number) => {
                  return "";
              };

    const getPaging = () =>
        host.vm.paging !== undefined
            ? {
                  pageable:
                      host.vm.paging.pageable !== undefined ? host.vm.paging.pageable : DEFAULTS.vm.paging.pageable,
                  pageSize:
                      host.vm.paging.pageSize !== undefined ? host.vm.paging.pageSize : DEFAULTS.vm.paging.pageSize,
                  itemCount:
                      host.vm.paging.itemCount !== undefined ? host.vm.paging.itemCount : DEFAULTS.vm.paging.itemCount,
                  pageSizes:
                      host.vm.paging.pageSizes !== undefined ? host.vm.paging.pageSizes : DEFAULTS.vm.paging.pageSizes,
                  pageIndex:
                      host.vm.paging.pageIndex !== undefined ? host.vm.paging.pageIndex : DEFAULTS.vm.paging.pageIndex,
                  buttonCount:
                      host.vm.paging.buttonCount !== undefined
                          ? host.vm.paging.buttonCount
                          : DEFAULTS.vm.paging.buttonCount,
                  showInfo:
                      host.vm.paging.showInfo !== undefined ? host.vm.paging.showInfo : DEFAULTS.vm.paging.showInfo,
                  showPreviousNext:
                      host.vm.paging.showPreviousNext !== undefined
                          ? host.vm.paging.showPreviousNext
                          : DEFAULTS.vm.paging.showPreviousNext,
              }
            : DEFAULTS.vm.paging;

    const getSelection = () =>
        host.vm.selection !== undefined ? { ...DEFAULTS.vm.selection, ...host.vm.selection } : DEFAULTS.vm.selection;

    const getRowCustomization = () =>
        host.vm.rowCustomization !== undefined ? host.vm.rowCustomization : DEFAULTS.vm.rowCustomization;

    const props: Properties = {
        vm:
            host.vm !== undefined
                ? {
                      columns:
                          host.vm.columns !== undefined
                              ? [
                                    ...host.vm.columns.map((column) => {
                                        return {
                                            ...column,
                                            cellTemplate: getCellTemplate(column),
                                            cellClass: getCellClass(column),
                                        };
                                    }),
                                ]
                              : DEFAULTS.vm.columns,
                      bodyTemplate: host.vm.bodyTemplate,
                      rowViewTemplate: host.vm.rowViewTemplate,
                      rowEditTemplate: host.vm.rowEditTemplate,
                      rowDeleteTemplate: host.vm.rowDeleteTemplate,
                      data: host.vm.data !== undefined ? addIsSelectedPropertyToData(host.vm.data) : DEFAULTS.vm.data,
                      paging: getPaging(),
                      sorting: host.vm.sorting ? host.vm.sorting : DEFAULTS.vm.sorting,
                      selection: getSelection(),
                      rowCustomization: getRowCustomization(),
                      appliedFilters:
                          host.vm.appliedFilters !== undefined ? host.vm.appliedFilters : DEFAULTS.vm.appliedFilters,
                      actionCellClass: host.vm.actionCellClass,
                      actionCellContentClass: host.vm.actionCellContentClass,
                      useEllipsis: host.vm.useEllipsis !== undefined ? host.vm.useEllipsis : DEFAULTS.vm.useEllipsis,
                      forcedNumberOfRows: host.vm.forcedNumberOfRows || 0,
                  }
                : DEFAULTS.vm,
    };

    // HELPER

    const hasPinnedContent = () =>
        props.vm.columns.some((c) => c.isPinned) ||
        props.vm.rowViewTemplate ||
        props.vm.rowDeleteTemplate ||
        props.vm.rowEditTemplate;

    const resetSelection = () => {
        setSelectedIndices([]);
    };

    const formatColumnClass = (currentColumnClass: string | string[]): { [key: string]: boolean } => {
        let concreteColumnClasses: { [key: string]: boolean } = {};
        if (currentColumnClass) {
            concreteColumnClasses = (
                typeof currentColumnClass === "string" ? [currentColumnClass] : currentColumnClass
            ).reduce((acc, cur) => {
                cur.split(" ").forEach((curItem) => {
                    acc[curItem] = true;
                });
                return acc;
            }, concreteColumnClasses);
        }
        return concreteColumnClasses;
    };

    const getAppliedFilter = (fieldName: string) => {
        return props.vm.appliedFilters.find((column) => column.field === fieldName);
    };

    const isFilterAppliedOnColumn = (fieldName: string) => {
        const columnFilter = getAppliedFilter(fieldName);
        return columnFilter !== undefined && columnFilter.filterProps !== undefined;
    };

    // EVENT HANDLER

    const onPageChanged = async (e: DC.Pager.PagerChangeEvent) => {
        const pageIndex = e.detail.selectedPageIndex;
        const pageSize = e.detail.selectedPageSize;
        setSelectedPageIndex(pageIndex);
        setSelectedPageSize(pageSize);
        resetSelection();
        host.dispatchEvent(
            new PageChangeEvent({
                selectedPageIndex: pageIndex,
                selectedPageSize: pageSize,
            }),
        );
    };

    const onSortClicked = (field: string, sortOrder: SortOrder) => {
        setOrderDir(sortOrder);
        setOrderBy(field);
        resetSelection();
        host.dispatchEvent(
            new SortChangeEvent({
                orderBy: field,
                orderDir: sortOrder,
            }),
        );
    };

    const onFilterChanged = (field: string, filterProps: FilterProperty) => {
        resetSelection();
        host.dispatchEvent(
            new FilterChangeEvent({
                field,
                filterProps,
            }),
        );
    };

    const onRowSelected = (rowIndex: number, e: ChangeEvent) => {
        let newIndices = [...selectedIndices];
        if (e.detail.checked) {
            if (!selectedIndices.includes(rowIndex)) {
                newIndices.push(rowIndex);
                newIndices.sort();
            }
        } else {
            newIndices = selectedIndices.filter((index) => index !== rowIndex);
        }

        setSelectedIndices(newIndices);
        host.dispatchEvent(
            new RowsSelectedEvent({
                currentSelection: newIndices,
                rowChanged: rowIndex,
            }),
        );
    };

    const allRowsChanged = () => {
        const newIndices = selectedIndices.length === props.vm.data.length ? [] : props.vm.data.map((_, i) => i);
        setSelectedIndices(newIndices);

        host.dispatchEvent(
            new RowsSelectedEvent({
                currentSelection: newIndices,
                rowChanged: props.vm.data.map((_, i) => i),
            }),
        );
    };

    // COMPONENT

    useEffect(() => {
        setSelectedPageIndex(props.vm.paging.pageIndex);
    }, [props.vm.paging.pageIndex]);

    useEffect(() => {
        setSelectedPageSize(props.vm.paging.pageSize);
    }, [props.vm.paging.pageSize]);

    useEffect(() => {
        setOrderBy(props.vm.sorting.orderBy);
    }, [props.vm.sorting.orderBy]);

    useEffect(() => {
        setOrderDir(props.vm.sorting.orderDir);
    }, [props.vm.sorting.orderDir]);

    useEffect(() => {
        if (props.vm.selection.selectedIndices !== undefined) {
            const onlyValidIndices = props.vm.selection.selectedIndices.reduce((aggr: number[], curr) => {
                if (curr >= 0 && curr < props.vm.data.length && !aggr.includes(curr)) {
                    return aggr.concat(curr);
                } else {
                    return aggr;
                }
            }, []);
            onlyValidIndices.sort();
            setSelectedIndices(onlyValidIndices);
        }
    }, [props.vm.selection.selectedIndices]);

    const [selectedPageIndex, setSelectedPageIndex] = useState(DEFAULTS.vm.paging.pageIndex);
    const [selectedPageSize, setSelectedPageSize] = useState(DEFAULTS.vm.paging.pageSize);
    const [orderBy, setOrderBy] = useState<string>("");
    const [orderDir, setOrderDir] = useState<SortOrder>("asc");
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

    // TEMPLATE

    const getHeaderTemplate = () => {
        return html`
            <tr>
                ${props.vm.selection.selectable && props.vm.data.length > 0
                    ? html`
                          <th class="w-10 overflow-hidden">
                              <dc-checkbox
                                  .checked=${selectedIndices.length === props.vm.data.length}
                                  .customClass=${"flex"}
                                  @change=${allRowsChanged}
                              ></dc-checkbox>
                          </th>
                      `
                    : ""}
                ${repeat(
                    props.vm.columns,
                    (column) => column.field,
                    (column) => html`
                        <th
                            class=${classMap({
                                "active": isFilterAppliedOnColumn(column.field),
                                "pinned-on-mobile": column.isPinned,
                                ...formatColumnClass(column.columnClass),
                            })}
                        >
                            <dc-table-header
                                .fieldName=${column.field}
                                .sortable=${column.sortable}
                                .filterable=${column.filterable}
                                .columnType=${column.columnType}
                                .filterDescriptor=${column.filterDescriptor}
                                .label=${column.label}
                                .orderBy=${orderBy}
                                .orderDir=${orderDir}
                                .columnClass=${column.columnClass}
                                .onSortClicked=${onSortClicked}
                                .onFilterChanged=${onFilterChanged}
                                .appliedFilter=${getAppliedFilter(column.field)}
                                .showSorterArrow=${props.vm.sorting.showSorterArrow}
                                .headerTemplate=${column.headerTemplate}
                                .useEllipsis=${props.vm.useEllipsis}
                            ></dc-table-header>
                        </th>
                    `,
                )}
                ${actionCellHeaderTemplates()}
            </tr>
        `;
    };

    const actionCellHeaderTemplates = () => {
        const cellClassMap = GridHelper.getClassMap(props.vm.actionCellClass);
        const cellContentClassMap = GridHelper.getClassMap(props.vm.actionCellContentClass);

        return [props.vm.rowViewTemplate, props.vm.rowEditTemplate, props.vm.rowDeleteTemplate]
            .filter((t) => t)
            .map((_) => html` <th class=${cellClassMap}><div class=${cellContentClassMap}></div></th> `);
    };

    const rowClassMap = (rowIndex: number) =>
        props.vm.rowCustomization.map((row) => {
            return row.index === rowIndex ? row.classes.join(" ") : "";
        });

    const selectableColumnTemplate = (rowIndex: number) =>
        props.vm.selection.selectable && props.vm.data.length > 0
            ? html`
                  <td class="w-10 overflow-hidden">
                      <div class="flex items-center justify-center w-full h-full">
                          <dc-checkbox
                              .checked=${selectedIndices.includes(rowIndex)}
                              @change=${(e: ChangeEvent) => {
                                  onRowSelected(rowIndex, e);
                              }}
                          ></dc-checkbox>
                      </div>
                  </td>
              `
            : "";

    const columnTemplate = (column: Column<any>, rowIndex: number) => html`
        <td class="${column.cellClass(rowIndex)} ${column.isPinned ? "pinned-on-mobile" : ""}">
            <div>${column.cellTemplate(rowIndex)}</div>
        </td>
    `;

    const getBodyTemplate = () => {
        const numOfRows = props.vm.forcedNumberOfRows
            ? props.vm.forcedNumberOfRows - 1 || props.vm.data.length - 1
            : props.vm.data.length - 1;

        return html`
            ${props.vm.bodyTemplate
                ? props.vm.bodyTemplate()
                : props.vm.data.length !== undefined
                ? createRange(0, numOfRows).map((rowIndex: number) => {
                      return props.vm.data.length > rowIndex
                          ? html`
                                <tr class="${rowClassMap(rowIndex)}">
                                    ${selectableColumnTemplate(rowIndex)}
                                    ${props.vm.columns.map((column) => columnTemplate(column, rowIndex))}
                                    ${viewCellTemplate(rowIndex)} ${editCellTemplate(rowIndex)}
                                    ${deleteCellTemplate(rowIndex)}
                                </tr>
                            `
                          : html`
                                <tr>
                                    ${props.vm.columns.map((_) => html` <td></td> `)}
                                </tr>
                            `;
                  })
                : ""}
        `;
    };

    const viewCellTemplate = (i: number) => (props.vm.rowViewTemplate ? html` ${props.vm.rowViewTemplate(i)} ` : "");

    const editCellTemplate = (i: number) => (props.vm.rowEditTemplate ? html` ${props.vm.rowEditTemplate(i)} ` : "");

    const deleteCellTemplate = (i: number) =>
        props.vm.rowDeleteTemplate ? html` ${props.vm.rowDeleteTemplate(i)} ` : "";

    const getTableContentTemplate = () => html`
        <thead>
            ${getHeaderTemplate()}
        </thead>
        <tbody>
            ${getBodyTemplate()}
        </tbody>
    `;

    return html`
        <div class="relative w-full max-w-full">
            <div class="dc-table-container">
                <table class="dc-table ${props.vm.useEllipsis ? "use-ellipsis" : ""}">
                    ${getTableContentTemplate()}
                </table>
            </div>
            <!-- DEVNOTE: With a brilliant trick, we double the table, so that pinned columns can be -->
            <!-- displayed in mobile view, without messing up the line heights (!) - but only if needed -->
            ${hasPinnedContent()
                ? html`
                      <table class="dc-table pinned-cells hidden-sm-up">
                          ${getTableContentTemplate()}
                      </table>
                  `
                : ""}
        </div>
        ${props.vm.paging.pageable
            ? html`
                  <dc-pager
                      .pageIndex=${selectedPageIndex}
                      .pageSize=${selectedPageSize}
                      .pageButtonCount=${props.vm.paging.buttonCount}
                      .pageSizes=${props.vm.paging.pageSizes}
                      .itemCount=${props.vm.paging.itemCount}
                      .showInfo=${props.vm.paging.showInfo}
                      @change=${onPageChanged}
                  ></dc-pager>
              `
            : ""}
    `;
};

import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as dayjs from "dayjs";
import { ApiTripModel } from "../component-models/flight/api/ApiTripModel";
import { DEFAULT_DATE_FORMAT } from "../shared/commonConstants";
import { ExtendedLowFareModel } from "../component-models/flight/ExtendedLowFareModel";
import { ExtendedTripModel } from "../component-models/flight/ExtendedTripModel";
import { ApiFlightPageViewModel } from "../component-models/flight/api/ApiFlightPageViewModel";
import { FlightPageViewModel } from "../component-models/flight/FlightPageViewModel";
import { getParsedProperty } from "../shared/common";
dayjs.extend(CustomParseFormat);

export function mapToExtendedTripModel(model: ApiTripModel): ExtendedTripModel {
    return {
        ...model,
        LowestFares: [
            ...model.LowestFares.map(
                (fare): ExtendedLowFareModel => ({
                    ...fare,
                    DayjsDate: dayjs(fare.FormattedDepartureDate, DEFAULT_DATE_FORMAT),
                    IsDisabled:
                        !fare.LowFareLink || fare.LowestFareAmount === undefined || fare.LowestFareAmount === null,
                }),
            ),
        ],
    };
}

export function mapToFlightPageViewModel(host: ApiFlightPageViewModel): FlightPageViewModel {
    const rawModel = getParsedProperty<ApiFlightPageViewModel>(host as unknown);

    return {
        ...rawModel,
        Journeys: [...rawModel.FlightDataViewModel.Journeys.map(mapToExtendedTripModel)],
    };
}

import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { USA_CULTURE_CODE } from "../../../shared/commonConstants";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../managers/useAppContext";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { useState } from "../../../shared/haunted/CustomHooks";

export interface Props {
    externalSecurityInfoUrl: string;
    isValidated: boolean;
    isColombianFlight: boolean;
    showArgentinaText: boolean;
}

export const useSecurity = (props: Props) => {
    const appContext = useAppContext();
    const [isTermsCheckboxChecked, setIsTermsCheckboxChecked] = useState(false);

    const securityCheckboxLabelTemplate = (id: string) => html`
        <label for=${id} class="checkin-security-terms-label">
            <span class="checkin-security-terms-text">${i18next.t("V2-Checkin-SecurityAccept")}</span>
        </label>
    `;

    const securityCheckbox = useBasicCheckbox({
        isChecked: isTermsCheckboxChecked,
        labelTemplate: securityCheckboxLabelTemplate,
        customWrapperClass: "checkin-security-terms-wrapper",
        inputTestId: T.CHECKIN.SECURITY_CHECKBOX,
        onClick: () => setIsTermsCheckboxChecked(!isTermsCheckboxChecked),
    });

    const group1Template = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="prohibited-title">
                    <div class="prohibited-number">1</div>
                    <div class="prohibited-info">
                        <span>${i18next.t("ProhibitedCategory-1-Line-1")}</span>
                        ${appContext.Culture !== USA_CULTURE_CODE
                            ? html` <span>${i18next.t("ProhibitedCategory-1-Line-2")}</span> `
                            : ""}
                    </div>
                </div>
                <div class="prohibited-category checked-baggage">
                    <div class="category-type">
                        <img src="/Images/Checkin/checked-baggage.svg" />
                    </div>
                    <ul class="category-list">
                        <li>
                            <i class="js-icon-forbidden js-cigarette"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-1-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-1-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-battery"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-2-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-2-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-temperature"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-3-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-3-Line-2")}</span> `
                                : ""}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `;

    const group2Template = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="prohibited-title">
                    <div class="prohibited-number">2</div>
                    <div class="prohibited-info">
                        <span>${i18next.t("ProhibitedCategory-2-Line-1")}</span>
                        ${appContext.Culture !== USA_CULTURE_CODE
                            ? html` <span>${i18next.t("ProhibitedCategory-2-Line-2")}</span> `
                            : ""}
                    </div>
                </div>
                <div class="prohibited-category hand-baggage">
                    <div class="category-type">
                        <img src="/Images/Checkin/hand-baggage.svg" />
                    </div>
                    <ul class="category-list">
                        <li>
                            <i class="js-icon-forbidden js-blade"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-4-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-4-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-sharp"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-5-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-5-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-pliers"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-6-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-6-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-scissors"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-7-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-7-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-firearm"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-8-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-8-Line-2")}</span> `
                                : ""}
                        </li>
                        <li>
                            <i class="js-icon-forbidden js-tool"></i>
                            <i class="js-icon-forbidden js-forbidden-circle"></i>
                            <span>${i18next.t("ProhibitedItem-9-Line-1")}</span>
                            ${appContext.Culture !== USA_CULTURE_CODE
                                ? html` <span>${i18next.t("ProhibitedItem-9-Line-2")}</span> `
                                : ""}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `;

    const group3ListTemplate = () => html`
            <ul class="category-list">
                <li>
                    <i class="js-icon-forbidden js-general1"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general2"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general3"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general4"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general5"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general6"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general7"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general8"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general9"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general10"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li>
                    <i class="js-icon-forbidden js-general11"></i>
                    <i class="js-icon-forbidden js-forbidden-circle"></i>
                </li>
                <li class="general-items-info">${i18next.t("ProhibitedCategory-3-Info-Line-1")}</li>
                ${
                    appContext.Culture !== USA_CULTURE_CODE
                        ? html` <li class="general-items-info">${i18next.t("ProhibitedCategory-3-Info-Line-2")}</li> `
                        : ""
                }
            </ul>
        </div>
    `;

    const group3ColombianListTemplate = () => html`
        <ul class="category-list pictogram">
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-flamable"></i>
                <span>${i18next.t("ProhibitedSubstance-1-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-1-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-health-hazard"></i>
                <span>${i18next.t("ProhibitedSubstance-2-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-2-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-oxidising"></i>
                <span>${i18next.t("ProhibitedSubstance-3-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-3-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-compressed-gas"></i>
                <span>${i18next.t("ProhibitedSubstance-4-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-4-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-corrosive"></i>
                <span>${i18next.t("ProhibitedSubstance-5-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-5-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-toxic"></i>
                <span>${i18next.t("ProhibitedSubstance-6-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-6-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-danger"></i>
                <span>${i18next.t("ProhibitedSubstance-7-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-7-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-environmental-hazard"></i>
                <span>${i18next.t("ProhibitedSubstance-8-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-8-Line-2")}</span> `
                    : ""}
            </li>
            <li>
                <i class="js-new js-n-pictogram-outline"></i>
                <i class="js-new js-n-pictogram-explosive"></i>
                <span>${i18next.t("ProhibitedSubstance-9-Line-1")}</span>
                ${appContext.Culture !== USA_CULTURE_CODE
                    ? html` <span>${i18next.t("ProhibitedSubstance-9-Line-2")}</span> `
                    : ""}
            </li>
            <li class="general-items-info">${i18next.t("Colombian-ProhibitedCategory-3-Info-Line-1")}</li>
            <li class="general-items-info">${i18next.t("Colombian-ProhibitedCategory-3-Info-Line-2")}</li>
            <li class="general-items-info">${i18next.t("Colombian-ProhibitedCategory-3-Info-Line-3")}</li>
        </ul>
    `;

    const group3Template = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="prohibited-title">
                    <div class="prohibited-number">3</div>
                    <div class="prohibited-info">
                        <span>${i18next.t("ProhibitedCategory-3-Line-1")}</span>
                        ${appContext.Culture !== USA_CULTURE_CODE
                            ? html` <span>${i18next.t("ProhibitedCategory-3-Line-2")}</span> `
                            : ""}
                    </div>
                </div>
                <div class="prohibited-category hand-and-checked-baggage">
                    <div class="category-type">
                        <img src="/Images/Checkin/hand-and-checked-baggage.svg" />
                    </div>
                    ${props.isColombianFlight ? group3ColombianListTemplate() : group3ListTemplate()}
                </div>
            </div>
        </div>
    `;

    const checkboxTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">${securityCheckbox.htmlTemplate()}</div>
        </div>
    `;

    const errorTemplate = () =>
        props.isValidated && !isTermsCheckboxChecked
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container text-center">
                              <div class="form-error-message">${i18next.t("V2-PendingInfoError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const argentinaExtraTextTemplate = () =>
        props.showArgentinaText
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="md:text-md text-xs leading-normal sm:text-sm">
                              ${unsafeHTML(
                                  i18next.t(
                                      "REGLAMENTO DE SEGURIDAD AVIACIÓN NRO. 25, más información {{-aStart}} AQUÍ.{{-aEnd}}",
                                      {
                                          aStart: `<a target="_blank" href=${props.externalSecurityInfoUrl}>`,
                                          aEnd: "</a>",
                                      },
                                  ),
                              )}
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div id="mainContentWrapper" class="checkin-passengers">
            <section class="booking-wrapper ts-error-container">
                <header>
                    <span class="js-no js-icon title-icon"></span>
                    <div class="title">
                        <h2 class="main-title">${i18next.t("V2-Checkin-ProhibitedSubstances")}</h2>
                    </div>
                </header>
                <div class="inner-box ts-passenger-details prohibited">
                    <div class="inner-deep-box padded checkin-pax-select ts-error-parent">
                        ${group1Template()} ${group2Template()} ${group3Template()} ${checkboxTemplate()}
                        ${errorTemplate()} ${argentinaExtraTextTemplate()}
                    </div>
                </div>
            </section>
        </div>
    `;

    return { htmlTemplate, isTermsCheckboxChecked };
};

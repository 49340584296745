import { useEffect, useState } from "./../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import {
    getAntiForgeryTokenFromHtml,
    getAntiforgerySegment,
    getRequestBodyFromNamedInputs,
    handleCugLoader,
    isKeyNumeric,
    sanitizePhoneNumber,
} from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { UpdateCUG2ProfileViewModel } from "../../../component-models/UpdateCUG2ProfileViewModel";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";
import { classMap } from "lit-html/directives/class-map";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { CugUserPhoneType, FAKE_DATE_OF_BIRTH } from "../../../shared/commonConstants";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export const name = "ac-cug-user-edit";

export interface Properties {
    antiForgeryToken: string;
    canEditUser: boolean;
    hasCompanyModel: boolean;
    userModel: UpdateCUG2ProfileViewModel;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canEditUser: host.canEditUser,
        hasCompanyModel: host.hasCompanyModel,
        userModel: host.userModel,
    };

    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    const { ajaxRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const userFormElement = useRef<HTMLFormElement>(undefined);

    const [editUserMode, setEditUserMode] = useState<boolean>(true);

    const form = useForm({
        customAttributes: [...commonValidationRules()],
        noScroll: true,
    });

    useEffect(() => form.init(userFormElement.current), [userFormElement.current]);
    useEffect(init, []);

    // EVENT LISTENERS

    const handlePhoneInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        target.value = sanitizePhoneNumber(target.value, true);
    };

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, true)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    const handleUserSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = await form.validate();

        if (isValid) {
            handleCugLoader(userFormElement.current, "editUser");

            await ajaxRequest({
                form: userFormElement.current,
                body: getRequestBodyFromNamedInputs(userFormElement.current),
                onResponseCode: {
                    200: () => setEditUserMode(false),
                },
            });

            handleCugLoader(userFormElement.current, "editUser");
        }
    };

    const handleNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "accepted-text-chars");
    };

    const handleEmailInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "e-mail");
    };

    const handleUserNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "user-name");
    };

    // TEMPLATES

    const saveMessageTemplate = () =>
        !editUserMode
            ? html`
                  <div class="cug2b-save-message" data-test-id=${T.CUG2_USER_EDIT.SAVE_MESSAGE}>
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const inputTemplate = (
        data: {
            label: string;
            name: string;
            value: string;
            isDisabled?: boolean;
            validationRule?: string;
            testId?: string;
        },
        onInput: (e: KeyboardEvent) => void,
    ) => {
        const tempClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "disabled": data.isDisabled,
        });

        const tabIndex = data.isDisabled ? "-1" : null;
        const validationRule = data.validationRule ? data.validationRule : null;
        const dataTestId = data.testId ? data.testId : null;

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
                data-upgraded=",MaterialTextfield"
            >
                <label class="mdl-textfield__label">${data.label}</label>
                <input
                    type="text"
                    class=${tempClassMap}
                    tabindex=${tabIndex}
                    autocomplete="cc-exp"
                    name=${data.name}
                    value=${data.value}
                    data-required
                    data-validation=${validationRule}
                    data-test-id=${dataTestId}
                    @input=${(e: KeyboardEvent) => onInput(e)}
                    @blur=${(e: KeyboardEvent) => onInput(e)}
                />
            </div>
        `;
    };

    const countryOptionsTemplate = (selectedCountry: string) =>
        cug2AppContext.Countries.map(
            (country) => html`
                <option value=${country.Value} .selected=${country.Value === selectedCountry}>${country.Text}</option>
            `,
        );

    const firstNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Nombres"),
                name: "updateAgencyProfile.Member.Name.First",
                value: props.userModel.Member.Name.First,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.FIRST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const lastNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Apellidos"),
                name: "updateAgencyProfile.Member.Name.Last",
                value: props.userModel.Member.Name.Last,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.LAST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const nationalityTemplate = () => html`
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow is-dirty is-upgraded"
            data-upgraded=",MaterialTextfield"
        >
            <label class="mdl-textfield__label">${i18next.t("Código país")}</label>
            <select
                name="updateAgencyProfile.Member.Nationality"
                class="mdl-textfield__input js-input js-select disabled"
                data-required
                value=${props.userModel.Member.Nationality}
                data-test-id=${T.CUG2_USER_EDIT.COUNTRY_SELECTOR}
            >
                <option value=""></option>
                ${countryOptionsTemplate(props.userModel.Member.Nationality)}
            </select>
        </div>
    `;

    const phoneTemplate = (label: string, type: CugUserPhoneType) => {
        const tempClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "disabled": !editUserMode || !props.canEditUser,
        });

        let inputName;
        let value;
        let dataTestId;

        switch (type) {
            case "general":
                inputName = "updateAgencyProfile.Member.HomePhoneNumber.Number";
                value = props.userModel.Member.HomePhoneNumber.Number;
                dataTestId = T.CUG2_USER_EDIT.PHONE_INPUT_FIELD;
                break;
            case "office":
                inputName = "updateAgencyProfile.Member.HomePhoneNumber.Number";
                value = props.userModel.Member.HomePhoneNumber.Number;
                dataTestId = T.PERU_COMPRA_USER_EDIT.OFFICE_PHONE_INPUT_FIELD;
                break;
            case "mobile":
                inputName = "updateAgencyProfile.Member.MobilePhoneNumber.Number";
                value = props.userModel.Member.MobilePhoneNumber.Number;
                dataTestId = T.PERU_COMPRA_USER_EDIT.MOBILE_PHONE_INPUT_FIELD;
                break;
        }

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
                data-upgraded=",MaterialTextfield"
            >
                <label class="mdl-textfield__label">${label}</label>
                <input
                    type="text"
                    name=${inputName}
                    class=${tempClassMap}
                    autocomplete="cc-exp"
                    data-required
                    data-validation="AdminForm.Phone"
                    value=${value}
                    data-test-id=${dataTestId}
                    @input=${handlePhoneInput}
                    @keydown=${handlePhoneChange}
                />
            </div>
        `;
    };

    const userEmailTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Correo electrónico"),
                name: "updateAgencyProfile.Member.PersonalEmailAddress.EmailAddress",
                value: props.userModel.Member.PersonalEmailAddress.EmailAddress,
                validationRule: "AdminForm.Email",
                isDisabled: !editUserMode || !props.canEditUser,
                testId: T.CUG2_USER_EDIT.EMAIL_INPUT_FIELD,
            },
            handleEmailInput,
        );

    const userNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("User Name"),
                name: "updateAgencyProfile.Member.UserName",
                value: props.userModel.Member.Username,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.USER_NAME,
            },
            handleUserNameInput,
        );

    const dniTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("DNI"),
                name: "updateAgencyProfile.Member.UserName",
                value: props.userModel.Member.Username,
                isDisabled: true,
                testId: T.PERU_COMPRA_USER_EDIT.DNI,
            },
            handleUserNameInput,
        );

    const userEditorTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-profile-edit": true,
            "cursor-default": editUserMode,
            "cursor-pointer": !editUserMode,
        });

        const onClick = !editUserMode ? () => setEditUserMode(true) : null;

        return props.canEditUser && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <div class=${tempClassMap} @click=${onClick}>
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";
    };

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !editUserMode || !props.canEditUser,
        });

        return html`
            <div class="mt-8 flex w-full justify-end">
                <button @click=${handleUserSubmit} class=${tempClassMap} data-test-id=${T.CUG2_USER_EDIT.SAVE_BUTTON}>
                    ${i18next.t("Guardar")}
                </button>
            </div>
        `;
    };

    const cugUserTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${nationalityTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Teléfono"), "general")}</div>
                      <div class="col-xs-1">${userEmailTemplate()}</div>
                      <div class="col-xs-1">${userNameTemplate()}</div>
                  </div>
              `
            : "";

    const peruCompraUserTemplate = () =>
        userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${dniTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Office Phone"), "office")}</div>
                      <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Teléfono móvil"), "mobile")}</div>
                      <div class="col-xs-1 col-sm-1-2">${userEmailTemplate()}</div>
                  </div>
              `
            : "";

    const mainClassMap = classMap({
        "cug2b-edit-profile-form": true,
        "ts-error-parent": true,
        "mt-8": props.hasCompanyModel,
        "md:mt-16": props.hasCompanyModel,
    });

    return props.userModel
        ? html`
              <form
                  ref=${ref(userFormElement)}
                  action=${ROUTES.Cug2BUserProfile}
                  class=${mainClassMap}
                  method="post"
                  novalidate="novalidate"
              >
                  <h1>${i18next.t("Usuario")} ${userEditorTemplate()}</h1>
                  ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)} ${cugUserTemplate()}
                  ${peruCompraUserTemplate()}

                  <input
                      value=${props.userModel.Member.DateOfBirth || FAKE_DATE_OF_BIRTH}
                      type="hidden"
                      name="updateAgencyProfile.Member.DateOfBirth"
                  />
                  <input type="hidden" name="updateAgencyProfile.Member.Gender" value="1" />

                  ${submitButtonTemplate()}
              </form>
          `
        : html``;
};

import { useState } from "../../shared/haunted/CustomHooks";
import { html, useRef, useEffect as hauntedUseEffect } from "haunted";
import { convertLoginResultToUserContext, showLoader } from "../../shared/common";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { CLASS_NAMES } from "../../shared/classNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ref } from "../../directives/ref";
import i18next from "i18next";
import { useAppContext } from "../../managers/useAppContext";
import { UrlHelper } from "../../shared/UrlHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import {
    PERU_COMPRA_CURRENCY,
    COOKIE_NAMES,
    DEFAULT_ORG_CODE,
    ACTION_NAMES,
    MINIMUM_TODOSUMA_POINTS,
    ACTIONS_WITH_BANCO_ESTADO_BAR,
} from "../../shared/commonConstants";
import { deleteCookie, setSessionCookie } from "../../shared/cookieHandling";
import classNames from "classnames";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    profileUrl: string;
    shouldReloadOnLogin: boolean;
}

export const useLoginStatus = (props: Props) => {
    const root = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const tealiumManager = useAuthenticationTealiumManager();

    const { triggers } = usePubSub();
    const { formatNumber } = useNumberFormatter();

    const [userContext, setUserContext] = useReduxState("userContext");
    const [isBancoEstadoBarOpened, setIsBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    const [mobileMenuOpeners, setMobileMenuOpeners] = useState<HTMLInputElement[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    // Helpers

    const isLoggedIn = () => userContext.isLoggedIn || userContext?.isLoggedIn;
    const name = () =>
        userContext?.userFirstName && userContext?.userLastName
            ? unsafeHTML(`${userContext.userFirstName} ${userContext.userLastName}`)
            : "";
    const orgName = () => (userContext?.cug.orgName ? unsafeHTML(userContext?.cug.orgName) : "");
    const orgCode = () => userContext?.cug.orgCode || "";
    const currentTodosumaPoints = () => userContext?.bancoEstado.todosumaPoints;
    const isBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const isPeruCompraAdmin = () => userContext.peruCompra.isAdmin || bookingContext.isPeruCompraBooking;

    const isPeruCompraMember = () => userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking;

    const isStaff = () => userContext?.isStaff || userContext.isStaff;

    const closeAllMobileMenus = () => {
        for (const opener of mobileMenuOpeners) {
            if (opener.checked) opener.click();
        }
    };

    const getAvailableAmount = () =>
        userContext?.peruCompra.availableAmount ? Number(userContext?.peruCompra.availableAmount) : 0;

    const getFormattedAvailableAmount = () =>
        formatNumber({ amount: getAvailableAmount(), currency: PERU_COMPRA_CURRENCY });

    const showBarOpener = () =>
        ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action?.toLowerCase()) > -1 &&
        isBancoEstadoCategory([5, 6]) &&
        flowContext.isBookingFlow;

    const isBancoEstadoBarVisibleAndOpened = () => isBancoEstadoBarOpened && showBarOpener();

    const hasTodosumaPoints = () => currentTodosumaPoints() > 0;

    const hasEnoughTodosumaPoints = () => currentTodosumaPoints() >= MINIMUM_TODOSUMA_POINTS;

    const todosumaPoints = () => formatNumber({ amount: currentTodosumaPoints(), leadingSign: false });

    const todosumaMoney = () => formatNumber({ amount: currentTodosumaPoints(), leadingSign: true });

    // Event handlers

    const init = () => {
        document.addEventListener("click", onClickOutside, true);
        setMobileMenuOpeners(
            DomCrawlingHelper.getArrayOfClass<HTMLInputElement>(document.body, CLASS_NAMES.mobileNavigation),
        );

        const handler = triggers.login.reloadPageOnLogin.subscribe(() => {
            if (props.shouldReloadOnLogin) {
                showLoader({});
                window.location.reload();
            }
        });

        return () => handler.unsubscribe();
    };

    // Event handlers

    const toggleBancoEstadoBar = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsBancoEstadoBarOpened(!isBancoEstadoBarOpened);
        deleteCookie(COOKIE_NAMES.IsBancoEstadoBarOpen);
        setSessionCookie(COOKIE_NAMES.IsBancoEstadoBarOpen, !isBancoEstadoBarOpened ? "1" : "0");
        document.body.classList.toggle(CLASS_NAMES.bancoEstadoBarOpen);
    };

    const onClickOutside = (e: any) => {
        if (!root.current?.contains(e.target)) setIsDropdownOpen(false);
    };

    const openDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleLogin = () => {
        closeAllMobileMenus();
        setIsDropdownOpen(false);

        // DEVNOTE No nicer way to determine, as the URL is the same
        const isFlightCalendar = Array.from(document.body.querySelectorAll("ac-flight-calendar-journey")).length > 0;

        if (flowContext.action === ACTION_NAMES.FLIGHT.toLowerCase() && !isFlightCalendar) {
            triggers.flight.flightPageOpenLoginInMenubar.publish({});
        } else {
            triggers.login.openLoginModal.publish({
                callback: async (e) => {
                    const newUserContext = convertLoginResultToUserContext(e);
                    setUserContext(newUserContext);
                },
            });
        }
    };

    const handleLogout = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        tealiumManager.logUserLogout();

        deleteCookie(COOKIE_NAMES.Tealium);

        window.setTimeout(() => (window.location.href = ROUTES.Logout), 500);
    };

    hauntedUseEffect(init, []);

    // Templates

    const memberTemplate = () => {
        const tempClassNames = classNames({
            "be-pointer-5": isBancoEstadoCategory([5]),
            "be-pointer-6": isBancoEstadoCategory([6]),
            "opened": isDropdownOpen,
            "pointer-squash": !isBancoEstadoBarVisibleAndOpened(),
        });

        return html`
            <li class=${tempClassNames} data-test-id=${T.HEADER_MENU.CONTAINER}>
                ${staffNameTemplate()} ${bancoEstado12347NameTemplate()} ${bancoEstado56NameTemplate()}
                ${regularMemberTemplate()} ${peruCompraTemplate()} ${memberDropdownTemplate()}
            </li>
        `;
    };

    const peruCompraTemplate = () =>
        isPeruCompraAdmin() || isPeruCompraMember()
            ? html`
                  <a class="menu-button" tabindex="-1" @click=${openDropdown}>
                      <span>${name()}</span>
                      <span class="smaller">
                          ${isPeruCompraAdmin() ? i18next.t("Gestor") : i18next.t("Delegado")} /
                          ${i18next.t("Monto {{availableAmount}}", {
                              availableAmount: getFormattedAvailableAmount(),
                          })}
                      </span>
                  </a>
              `
            : "";

    const staffNameTemplate = () =>
        isStaff()
            ? html`
                  <a class="menu-button" tabindex="-1" @click=${openDropdown}>
                      <span>${name()}</span>
                      <span class="smaller">${i18next.t("Team JetSMART")}</span>
                  </a>
              `
            : "";

    const bancoEstado12347NameTemplate = () =>
        isBancoEstadoCategory([1, 2, 3, 4, 7])
            ? html`
                  <a class="menu-button" tabindex="-1" @click=${openDropdown}>
                      <span class="be-header-username">${name()}</span>
                      <span class="smaller">${i18next.t("BE-OrgName")}</span>
                  </a>
              `
            : "";

    const bancoEstado56NameTemplate = () => {
        const tempClassNames = classNames("banco-estado-phase-2-header-username", {
            "category-5": isBancoEstadoCategory([5]),
        });

        return isBancoEstadoCategory([5, 6])
            ? html`
                  <a class="menu-button" tabindex="-1" @click=${openDropdown}>
                      <span class=${tempClassNames}>
                          ${bancoEstadoCardImageTemplate()} ${name()} ${bancoEstadoBarOpenerTemplate()}
                      </span>

                      ${todoSumaTemplate()}
                  </a>
              `
            : "";
    };

    const bancoEstadoCardImageTemplate = () => {
        const imgSrc = isBancoEstadoCategory([6])
            ? "/Images/BancoEstado/header-logo-smart-plus.png"
            : "/Images/BancoEstado/header-logo-smart.png";

        return html` <img src=${imgSrc} /> `;
    };

    const bancoEstadoBarOpenerTemplate = () => {
        const tempClassNames = classNames("hidden-sm-down js-icon js-chevron-right point-down", {
            "point-up": !isBancoEstadoBarVisibleAndOpened(),
        });

        return showBarOpener() ? html` <i @click=${toggleBancoEstadoBar} class=${tempClassNames}></i> ` : "";
    };

    const todoSumaTemplate = () =>
        hasTodosumaPoints() && !appContext.isFeatureSwitchActive("DisableTodoSuma")
            ? hasEnoughTodosumaPoints()
                ? html`
                      <span class="banco-estado-phase-2-header-todosuma">
                          ${todosumaMoney()} ${i18next.t("BE-HeaderTodosuma-Money")}
                      </span>
                  `
                : html`
                      <span class="banco-estado-phase-2-header-todosuma">
                          ${todosumaPoints()} ${i18next.t("BE-HeaderTodosuma-Points")}
                      </span>
                  `
            : "";

    const regularMemberTemplate = () =>
        isBancoEstadoCategory([0]) && !isStaff() && !isPeruCompraAdmin() && !isPeruCompraMember()
            ? html`
                  <a class="menu-button" tabindex="-1" @click=${openDropdown} data-test-id=${T.HEADER_MENU.USERNAME}>
                      <span>${name()}</span>
                      ${orgNameTemplate()}
                  </a>
              `
            : "";

    const orgNameTemplate = () =>
        orgName() && orgCode() !== DEFAULT_ORG_CODE ? html` <span class="smaller">${orgName()}</span> ` : "";

    const helpCenterListOptionTemplate = () =>
        !isPeruCompraAdmin() && !isPeruCompraMember()
            ? html`
                  <a
                      class="help-center-item menu-button"
                      href="https://jetsmart.com${UrlHelper.getCultureInUrlFormat(appContext.Culture)}centro-de-ayuda"
                      tabindex="-1"
                      target="_blank"
                  >
                      <span>${i18next.t("Footer-HelpCenter")}</span>
                  </a>
              `
            : "";

    const memberDropdownTemplate = () => html`
        <div class=${classNames("dropdown", { closed: !isDropdownOpen })}>
            <a href=${props.profileUrl} tabindex="-1" class="menu-button">
                <span data-test-id="my-profile-button">${i18next.t("Header-MyProfileButton")}</span>
            </a>
            <a @click=${handleLogout} tabindex="-1" class="menu-button" data-test-id=${T.HEADER_MENU.LOG_OUT_BUTTON}>
                <span>${i18next.t("Header-LogoutButton")}</span>
            </a>
            ${helpCenterListOptionTemplate()}
        </div>
    `;

    const anonymousTemplate = () => html`
        <div class="desktop-anonymus-btn-template">
            <li data-test-id="login-button-container">
                <a class="menu-button" tabindex="-1" @click=${handleLogin}>
                    <span>${i18next.t("Header-AccountSignIn")}</span>
                </a>
            </li>
            <li data-test-id="register-button-container">
                <a
                    class="menu-button"
                    tabindex="-1"
                    href=${ROUTES.Register}
                    data-test-id=${T.HEADER_MENU.REGISTER_BUTTON}
                >
                    <span>${i18next.t("Header-WantToJoin")}</span>
                </a>
            </li>
            <li class="help-center-item">
                <a
                    href="https://jetsmart.com${UrlHelper.getCultureInUrlFormat(appContext.Culture)}centro-de-ayuda"
                    tabindex="-1"
                    target="_blank"
                >
                    <span>${i18next.t("Footer-HelpCenter")}</span>
                </a>
            </li>
        </div>
        <div class="mobile-anonymus-btn-template pointer-squash">
            <div class=${classNames("dropdown", { closed: !isDropdownOpen })}>
                <a class="menu-button" tabindex="-1" data-test-id="login-button-container" @click=${handleLogin}>
                    <span>${i18next.t("Header-AccountSignIn")}</span>
                </a>
                <a class="menu-button" tabindex="-1" data-test-id="register-button-container" href=${ROUTES.Register}>
                    <span>${i18next.t("Header-WantToJoin")}</span>
                </a>
                <a
                    class="help-center-item menu-button"
                    href="https://jetsmart.com${UrlHelper.getCultureInUrlFormat(appContext.Culture)}centro-de-ayuda"
                    target="_blank"
                >
                    <span>${i18next.t("Footer-HelpCenter")}</span>
                </a>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <div data-test-id=${T.BOOKING_LOGIN.STATUS_BUTTON}>
            <div ref=${ref(root)}>
                <div>
                    <ul class="login-container">
                        ${isLoggedIn() ? memberTemplate() : anonymousTemplate()}
                    </ul>
                </div>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import { TravelPartnerInfo } from "./../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "./../../../component-models/CUG2b/Partner";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useEffect, useState } from "haunted";

export const name = "ac-edit-travel-partner";

export interface Properties {
    partner: Partner;
    travelPartnerInfo: TravelPartnerInfo;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        partner: host.partner,
        travelPartnerInfo: host.travelPartnerInfo,
    };

    // HELPERS

    // COMPONENT

    // EVENT LISTENERS

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.partner) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [props.partner]);

    // TEMPLATES

    const headerTemplate = () => html`
        <span class="cug2b-header-edit-travel-partner-modal"
            >${i18next.t("Editar información pasajero frecuente")}</span
        >
    `;

    const editTravelPartnerModalContentTemplate = () => html`
        <div class="cug2b-travel-partner-container" @click=${(e: MouseEvent) => e.stopPropagation()}>
            <ac-travel-partner
                .model=${props.travelPartnerInfo}
                .showCancelButton=${false}
                .vm=${props.partner}
                @update=${() => setIsOpen(false)}
            ></ac-travel-partner>
        </div>
    `;

    return html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${editTravelPartnerModalContentTemplate()}
            .customClass=${"cug2b-edit-travel-partner"}
            .header=${headerTemplate()}
            .isOpen=${isOpen}
            @close=${() => setIsOpen(false)}
        ></ac-cug-modal>
    `;
};

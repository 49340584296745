import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getTestId, TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { FlightTooltipModel } from "../component-models/flight/FlightTooltipModel";
import { useMultisegmentModal } from "../components/shared/useMultisegmentModal";
import { useMultilegModal } from "../components/shared/useMultilegModal";

// DEVNOTE This is based on the assumption that no flight can be multisegment AND multileg,
// and that the last segment of a multisegment flight must be displayed as a direct flight.
export const useFlightTooltip = (props: FlightTooltipModel) => {
    const multisegmentModal = useMultisegmentModal({
        segments: props.segments,
    });

    const multilegModal = useMultilegModal({ legDetails: props.legDetails });

    const operatorTemplate = (operatingCarrier: string) => html`
        <span>
            ${unsafeHTML(
                i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                    operatingCarrier,
                }),
            )}
        </span>
    `;

    const directFlightTemplate = () => {
        return html`
            <div class="direct-flight-info-container">
                <ac-tooltip
                    .icon=${"?"}
                    .testId=${getTestId(T.FLIGHT.TOOLTIP_OPENER, {
                        j: props.journeyIndex,
                        i: props.flightIndex,
                    })}
                    .tooltipTestId=${getTestId(T.FLIGHT.TOOLTIP, {
                        j: props.journeyIndex,
                        i: props.flightIndex,
                    })}
                    .tooltip=${html`
                        <div class="direct-flight-tooltip">
                            ${i18next.t("V2-FlightLabel")} ${props.flightNumber}
                            <span>
                                ${i18next.t("V2-FlightTimeTooltip {{hours}} {{label}} {{minutes}}", {
                                    hours: props.hours,
                                    label: props.hours === 1 ? i18next.t("Stop-Hour") : i18next.t("Stop-Hours"),
                                    minutes: props.minutes,
                                })}
                            </span>

                            ${operatorTemplate(props.legDetails[0].CarrierNameToDisplay)}
                            ${props.showAnacTooltip
                                ? html` <span>${i18next.t("V2-BrasilFlightTimeTooltip")}</span> `
                                : ""}
                        </div>
                    `}
                ></ac-tooltip>
                <span class="direct-flight-caption">${i18next.t("Stop-DirectFlightCaption")}</span>
            </div>
        `;
    };

    const multilegFlightTemplate = () => html`
        <div
            class="multileg-icon"
            @click=${(e: MouseEvent) => showLegDetailsModal(e)}
            data-test-id=${getTestId(T.CHECKIN.MULTILEG_FLIGHT_MODAL_OPENER, {
                j: props.journeyIndex,
                i: props.flightIndex,
            })}
        >
            &plus;${props.legDetails.length - 1}
            <div
                class="multileg-link"
                data-test-id=${T.CHECKIN.MULTILEG_FLIGHT_LINK}
                @click=${(e: MouseEvent) => showLegDetailsModal(e)}
            >
                ${props.legDetails.length - 1}
                ${props.legDetails.length === 2 ? i18next.t("Stop-Stop") : i18next.t("Stop-Stops")} /<br />
                ${i18next.t("V2-WaitOnBoard")}
            </div>
        </div>
        ${multilegModal.htmlTemplate()}
    `;

    const multisegmentFlightTemplate = () => html`
        <div
            data-test-id=${T.CHECKIN.MULTISEGMENT_FLIGHT_MODAL_OPENER}
            @click=${(e: MouseEvent) => handleMultisegmentFlightTooltipOpen(e)}
            class="multileg-icon"
        >
            ?
            <div
                data-test-id=${T.CHECKIN.MULTISEGMENT_FLIGHT_LINK}
                @click=${(e: MouseEvent) => handleMultisegmentFlightTooltipOpen(e)}
                class="multileg-link"
            >
                ${i18next.t("1 escala")}<br />${i18next.t("Cambio de avión")}
            </div>
        </div>
        ${multisegmentModal.htmlTemplate()}
    `;

    const handleMultisegmentFlightTooltipOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        multisegmentModal.open();
    };

    const showLegDetailsModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.legDetails) multilegModal.open();
    };

    const htmlTemplate = () =>
        props.segments.length > 1
            ? multisegmentFlightTemplate()
            : props.legDetails.length > 1
              ? multilegFlightTemplate()
              : directFlightTemplate();

    return { htmlTemplate };
};

import { ACTION_NAMES } from "./../shared/commonConstants";
import i18next from "i18next";
import { useState } from "./../shared/haunted/CustomHooks";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html, useEffect as hauntedUseEffect } from "haunted";
import { showLoader } from "../shared/common";
import { PromoCodeResult } from "../component-models/PromoCodeResult";
import { PromoCodeInvalidResult } from "./PromoCodeInvalidResult";
import { usePubSub } from "../pub-sub-service/usePubSub";
import { useFlowContext } from "../managers/useFlowContext";

export const name = "ac-promo-code-invalid-modal";

export const Component: HauntedFunc<{}> = () => {
    const init = () => {
        const handler = triggers.flight.showInvalidPromoCodeModal.subscribe((result: PromoCodeResult) => {
            if (!result.isValid || result.invalidPromoCodeMessage) {
                setIsOpen(true);
                setMessage(result.invalidPromoCodeMessage);

                if (result.shouldReloadSidebar) triggers.sidebar.bookingChanged.publish({});
            }
        });

        return () => handler.unsubscribe();
    };

    const handleClose = () => {
        setIsOpen(false);
        setMessage(undefined);

        if (flowContext.action === ACTION_NAMES.PAYMENT) {
            showLoader({});
            window.location.reload();
        }
    };

    const flowContext = useFlowContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>(undefined);

    const { triggers } = usePubSub();

    hauntedUseEffect(init, []);

    const transformErrorReason = (reason: PromoCodeInvalidResult) => {
        switch (reason) {
            case "PromotionCodeNotValid":
                return i18next.t("El código de promoción no es válido.");
            case "PromotionNotAllowed":
                return i18next.t("El código promocional no está permitido para la reserva actual.");
            case "PromotionRestrictions":
                return i18next.t(
                    "El código promocional no se puede aplicar debido a algunas restricciones en la reserva.",
                );
            case "PromotionPartiallyApplied":
                return i18next.t("PromotionPartiallyApplied");
            case "PromotionCustomerProgramError":
            case "PromotionRoleCodeError":
                return i18next.t(
                    "El código promocional ingresado no ha podido ser aplicado con tu cuenta, intenta con otro código o revisa las condiciones.",
                );
            case "PromotionCultureError":
                return i18next.t(
                    "El código promocional ingresado no ha podido ser aplicado, debido a que no aplica en el país donde estás realizando la reserva.",
                );
            case "PromotionCurrencyError":
                return i18next.t(
                    "El código promocional ingresado no ha podido ser aplicado, debido a que no aplica para la moneda seleccionada en tu reserva. Revisa la moneda de pago e inténtalo nuevamente.",
                );
            case "PromotionAncillaryError":
                return i18next.t(
                    "El código promocional ingresado no ha podido ser aplicado, debido a que no aplica para el opcional seleccionado.",
                );
            case "PromotionPaymentMethodError":
                return i18next.t(
                    "El código promocional ingresado no ha podido ser aplicado, debido a que no aplica para método de pago seleccionado.",
                );
        }
    };

    return isOpen && message
        ? html`
              <div class="modal invalid-promo-code-modal">
                  <div class="modal-content">
                      <div class="modal-header">
                          ${i18next.t("Importante")}
                          <button @click=${handleClose}>&times;</button>
                      </div>
                      <div class="modal-body">${transformErrorReason(message as PromoCodeInvalidResult)}</div>
                  </div>
              </div>
          `
        : html``;
};

import { TravelPartnerInfo } from "./../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { html } from "lit-html";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import {
    DeletePartnersModalNoEvent,
    DeletePartnersModalYesEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";

export const name = "ac-delete-partners-modal";

export interface Properties {
    partnersToDelete: number[];
    travelPartnerInfo: TravelPartnerInfo;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        partnersToDelete: host.partnersToDelete,
        travelPartnerInfo: host.travelPartnerInfo,
    };

    // HELPERS

    // COMPONENT

    // EVENT LISTENERS

    const handleNo = () => host.dispatchEvent(new DeletePartnersModalNoEvent());

    const handleYes = () => host.dispatchEvent(new DeletePartnersModalYesEvent());

    // TEMPLATES

    const warningTemplate = () =>
        props.partnersToDelete?.length > 1
            ? i18next.t(
                  "Estos pasajeros se eliminaran de forma permanente, por lo que no podrás encontrarlos en tus listados, ni grupos.",
              )
            : i18next.t(
                  "Este pasajero se eliminaran de forma permanente, por lo que no podrás encontrarlo en tus listados, ni grupos.",
              );

    return html`
        <div class="cug2b-are-you-sure-modal-content">
            <div class="cug2b-are-you-sure-modal-table-header">
                <span>${i18next.t("Nombre y Apellidos")}</span>
                <span>${i18next.t("RUT / DNI / Pasaporte")}</span>
                <span>${i18next.t("Fecha de nacimiento")}</span>
                <span>${i18next.t("Alias")}</span>
                <span>${i18next.t("Grupo")}</span>
            </div>
            ${props.partnersToDelete.map((id) => {
                const partner = props.travelPartnerInfo.Partners.find((p) => p.Id === id);

                return html`
                    <div class="cug2b-are-you-sure-modal-table-row">
                        <span>${partner.FirstName} ${partner.LastName}</span>
                        <span>${partner.DocumentId}</span>
                        <span>${partner.DOB.format("DD-MM-YYYY")}</span>
                        <span>${partner.Alias}</span>
                        <span>${TravelPartnerHelper.getGroupNameForPartner(props.travelPartnerInfo, partner.Id)}</span>
                    </div>
                `;
            })}
            <div class="cug2b-are-you-sure-modal-info">
                ${warningTemplate()}
            </div>
            <div class="mt-4 sm:mt-8 flex flex-col sm:flex-row items-center justify-center">
                <button class="rounded-secondary-btn with-arrow cug2b-white mb-4 sm:mb-0 sm:mr-4" @click=${handleNo}>
                    ${i18next.t("No, no quiero eliminarlos")}
                </button>
                <button class="rounded-primary-btn" @click=${handleYes}>
                    ${i18next.t("Si, estoy seguro")}
                </button>
            </div>
        </div>
    `;
};

import { AppContext } from "../component-models/app/AppContext";
import { useMemo } from "../shared/haunted/CustomHooks";
import { FeatureSwitch } from "../component-models/app/FeatureSwitches";

export const useAppContext = () => {
    const isFeatureSwitchActive = (featureSwitch: FeatureSwitch) => {
        return window.JetSmart.FeatureSwitch.some((fs) => fs.Name === featureSwitch && fs.IsActive);
    };

    const context: AppContext = useMemo(
        () =>
            window.JetSmart.AppContext
                ? {
                      AbTestSettings: window.JetSmart.AppContext.abTestSettings,
                      AntiForgeryToken: window.JetSmart.AppContext.antiForgeryToken,
                      BancoEstadoBins: window.JetSmart.AppContext.bancoEstadoBins?.split("|") || [],
                      CorporateBaseUrl: window.JetSmart.AppContext.corporateBaseUrl,
                      Countries: window.JetSmart.AppContext.countries || [],
                      Country: window.JetSmart.AppContext.culture.substring(3, 5),
                      Cug2BSettings: window.JetSmart.AppContext.cug2BSettings,
                      Culture: window.JetSmart.AppContext.culture.toLowerCase(),
                      CultureSettings: window.JetSmart.AppContext.cultureSettings,
                      IbeRelease: window.JetSmart.AppContext.ibeRelease,
                      Language: window.JetSmart.AppContext.culture.substring(0, 2).toLowerCase(),
                      LocalCurrencyDisplaySettings: window.JetSmart.AppContext.localCurrencyDisplaySettings,
                      PhonePrefixes: window.JetSmart.AppContext.phonePrefixes || [],
                      variants: window.JetSmart.AppContext.variants || [],
                  }
                : ({} as AppContext),
        [],
    );

    return { ...context, isFeatureSwitchActive };
};

import i18next from "i18next";
import { html } from "lit-html";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { BaggageStateJourney, BaggageStateJourneyPassenger } from "../../../../component-models/baggage/BaggageState";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { perJourneyPerPaxViewPaxCaption, paxLabel, paxAmount } from "../../../../component-helpers/BaggageHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import {
    BACKPACK_MOBILE_IMG,
    CHECKED_BAGGAGE_IMG,
    PAID_CABIN_MOBILE_IMG,
    WITHOUT_CHECKED_BAGGAGE_IMG,
} from "../../spa-container";
import { useBaggageIllustrations } from "../common/useBaggageIllustrations";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { usePerJourneyPerPaxSoldOut } from "./usePerJourneyPerPaxSoldOut";
import { usePerJourneyPerPaxBundle } from "./usePerJourneyPerPaxBundle";
import classNames from "classnames";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    pageState: BaggagePageState;
}

export const useMobilePerJourneyPerPaxTable = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const bundle = usePerJourneyPerPaxBundle({
        baggageSection: props.baggageSection,
        context: props.context,
        isMobile: false,
    });

    const soldOut = usePerJourneyPerPaxSoldOut({
        baggageSection: props.baggageSection,
        context: props.context,
        isMobile: false,
    });

    const { backpackTooltipTemplate, regularBagTooltipTemplate, zippedBagTooltipTemplate } = useBaggageIllustrations({
        baggageSection: props.baggageSection,
        context: props.context,
    });

    const unformattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: isMinimum,
            original: isOriginal,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const formattedLowestNexPrice = (isOriginal: boolean, isMinimum: boolean) =>
        formatNumber({ amount: unformattedLowestNextPrice(isOriginal, isMinimum), leadingSign: true });

    const showMinimumCabinOriginalPrice = () =>
        props.context.isDcBooking &&
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: true,
            original: true,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const showMinimumCheckedOriginalPrice = () =>
        props.context.isDcBooking &&
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: true,
            original: true,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const ribbonText = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? i18next.t("¡El más vendido!")
            : props.baggageSection.bagType === "CheckedBaggage"
              ? i18next.t("¡Recomendado para ti!")
              : "";

    const isAnyPaxOpenInThisJourney = (journey: BaggageStateJourney) => journey.passengers.some((p) => p.isOpen);

    // Event handlers

    const handleJourneyClick = (e: MouseEvent, journey: BaggageStateJourney) => {
        e.preventDefault();
        e.stopPropagation();

        if (journey.isOpen) {
            props.baggageSection.handlers.closeJourney(journey.index);
        } else {
            props.baggageSection.handlers.openJourney(journey.index, props.baggageSection.bagType === "CabinBaggage");
        }
    };

    const handleViewSwitch = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // FIXME reset is async, so the order is important here
        props.baggageSection.handlers.closePerJourneyPerPaxView();
        props.baggageSection.handlers.reset();
    };

    // Templates

    const viewSwitchButtonTemplate = () => {
        const buttonText =
            paxAmount(props.baggageSection.state) === 1
                ? i18next.t("Volver a la selección para todos los tramos")
                : i18next.t("Volver a la selección para todos los pasajeros");

        return props.baggageSection.perBooking.isResetAvailable
            ? html`
                  <div
                      class="b2-reset-button"
                      data-test-id=${getTestId(T.BAGGAGE.CLOSE_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
                          c: props.baggageSection.bagType,
                          m: true,
                      })}
                      @click=${handleViewSwitch}
                  >
                      ${buttonText}
                  </div>
              `
            : "";
    };

    const journeysTemplate = () => html`
        <div class="b2m-journeys-container">
            <div class="b2m-journeys">${props.baggageSection.state.journeys.map(journeyTemplate)}</div>
        </div>
    `;

    const journeyTemplate = (stateJourney: BaggageStateJourney) => {
        const contextJourney = props.context.journeys.find((journey) => journey.index === stateJourney.index);
        const directionText = stateJourney.index === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta");

        return html`
            <div
                class=${classNames("b2m-journey-heading", { open: stateJourney.isOpen })}
                data-test-value=${stateJourney.isOpen}
                data-test-id=${getTestId(T.BAGGAGE.JOURNEY_OPENER, {
                    j: stateJourney.index,
                    c: props.baggageSection.bagType,
                })}
                @click=${(e: MouseEvent) => handleJourneyClick(e, stateJourney)}
            >
                <i class="js-icon-bag js-bag-plane-take-off"></i>
                <span class="uppercase">${directionText}</span>
                <span>&rarr;</span>
                <span>${contextJourney.departureStationName} - ${contextJourney.arrivalStationName}</span>
                <i class="js-icon js-circle-chevron-right"></i>
            </div>
            <div
                class="b2m-journey-details"
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGERS, {
                    j: stateJourney.index,
                    c: props.baggageSection.bagType,
                    m: true,
                })}
            >
                ${soldOut.htmlTemplate(contextJourney)} ${journeyBundleTemplate(stateJourney)}
                ${stateJourney.passengers.map((pax) => passengerTemplate(stateJourney, pax))}
            </div>
        `;
    };

    const journeyBundleTemplate = (journey: BaggageStateJourney) =>
        isAnyPaxOpenInThisJourney(journey) ? bundle.htmlTemplate(journey.index) : "";

    const passengerTemplate = (journey: BaggageStateJourney, pax: BaggageStateJourneyPassenger) => {
        const dto: PerJourneyPerPaxPassengerDto = {
            baggageSection: props.baggageSection,
            context: props.context,
            isMobile: true,
            journeyIndex: journey.index,
            pageState: props.pageState,
            passengerIndex: pax.index,
        };

        return html` <ac-per-journey-per-pax-passenger .dto=${dto}></ac-per-journey-per-pax-passenger> `;
    };

    const perJourneyPerPaxCheckedFreeTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <div class="b2m-per-pax-section padded">
                      <div class="b2m-illustration">
                          <img class="b2-free-checked-img" src=${WITHOUT_CHECKED_BAGGAGE_IMG} />
                      </div>
                      <div class="b2m-bag-name longer">${i18next.t("No quiero llevar equipaje facturado")}</div>
                  </div>
              `
            : "";

    const perJourneyPerPaxCheckedPaidTemplate = () => {
        const tempClassMap = classNames("b2m-bag-name longer", {
            dc: showMinimumCheckedOriginalPrice(),
        });

        return props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <div class="b2m-per-pax-section">
                      <div class="b2-illustration-ribbon">${ribbonText()}</div>
                      <div class="b2m-illustration">
                          <img class="b2-paid-checked-img" src=${CHECKED_BAGGAGE_IMG} />
                          <div class="b2m-weight">23kg.</div>
                          <div class="b2m-tooltip checked">${zippedBagTooltipTemplate()}</div>
                      </div>
                      <div class=${tempClassMap}>${i18next.t("Equipaje facturado")}</div>
                      ${checkedBagPricesTemplate()}
                  </div>
              `
            : "";
    };

    const checkedBagPricesTemplate = () => {
        const tempClassMap = classNames("b2-per-booking-next-price", {
            dc: showMinimumCheckedOriginalPrice(),
        });

        return html`
            ${minimumCheckedOriginalPriceTemplate()}
            <div class=${tempClassMap}>
                ${i18next.t("Desde")}
                <span
                    class="b2-amount"
                    data-test-value=${unformattedLowestNextPrice(false, true)}
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                        c: props.baggageSection.bagType,
                        m: true,
                    })}
                >
                    ${formattedLowestNexPrice(false, false)}
                </span>
            </div>
        `;
    };

    const minimumCheckedOriginalPriceTemplate = () =>
        showMinimumCheckedOriginalPrice()
            ? html`
                  <div class="b2-per-booking-next-price reduced">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                              m: true,
                          })}
                      >
                          ${formattedLowestNexPrice(true, false)}
                      </span>
                  </div>
              `
            : "";

    const perJourneyPerPaxCabinFreeTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <div class="b2m-per-pax-section">
                      <div class="b2m-illustration">
                          <img class="b2-free-cabin-img" src=${BACKPACK_MOBILE_IMG} />
                          <div class="b2m-weight">10kg.</div>
                          <div class="b2m-tooltip">${backpackTooltipTemplate()}</div>
                      </div>
                      <div class="b2m-bag-name">${i18next.t("Mochila o artículo personal")}</div>
                      <div class="b2-per-booking-next-price">${i18next.t("Incluido con tu tarifa")}</div>
                  </div>
              `
            : "";

    const perJourneyPerPaxCabinPaidTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <div class="b2m-per-pax-section">
                      <div class="b2-illustration-ribbon">${ribbonText()}</div>
                      <div class="">
                          <div>
                              <img class="b2-paid-cabin-img-mb" src=${PAID_CABIN_MOBILE_IMG} />
                          </div>
                          <div class="weight-container">
                              <div class="b2m-weight">10kg.</div>
                              <div class="b2m-weight">10kg.</div>
                          </div>
                          <div class="b2m-tooltips backpack-and-bag-tooltips">
                              ${backpackTooltipTemplate()} ${regularBagTooltipTemplate()}
                          </div>
                      </div>
                      <div class="b2m-bag-name">${i18next.t("Mochila o artículo personal y Equipaje de mano")}</div>
                      ${cabinBagPricesTemplate()}
                  </div>
              `
            : "";

    const cabinBagPricesTemplate = () => html`
        ${minimumOriginalCabinPriceTemplate()}
        <div class="b2-per-booking-next-price">${cabinPriceTemplate()} ${cabinIncludedTemplate()}</div>
    `;

    const cabinIncludedTemplate = () =>
        props.context.journeys.every((journey) => journey.cabinBundleType !== "None") ? i18next.t("V2-Included") : "";

    const cabinPriceTemplate = () =>
        props.context.journeys.some((journey) => journey.cabinBundleType === "None")
            ? html`
                  ${i18next.t("Desde")}
                  <span
                      class="b2-amount"
                      data-test-value=${unformattedLowestNextPrice(false, true)}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                          c: props.baggageSection.bagType,
                          m: true,
                      })}
                  >
                      ${formattedLowestNexPrice(false, true)}
                  </span>
              `
            : "";

    const minimumOriginalCabinPriceTemplate = () =>
        showMinimumCabinOriginalPrice()
            ? html`
                  <div class="b2-per-booking-next-price reduced">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                              m: true,
                          })}
                      >
                          ${formattedLowestNexPrice(true, true)}
                      </span>
                  </div>
              `
            : "";

    const illustrationTemplate = () =>
        props.baggageSection.bagType !== "OversizedBaggage"
            ? html`
                  <div class="b2m-per-pax-container">
                      ${perJourneyPerPaxCabinFreeTemplate()} ${perJourneyPerPaxCheckedFreeTemplate()}
                      ${perJourneyPerPaxCabinPaidTemplate()} ${perJourneyPerPaxCheckedPaidTemplate()}
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        props.baggageSection.state.perJourneyPerPaxState === "open"
            ? html`
                  ${illustrationTemplate()}
                  <div class="b2m-view per-pax">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">
                              ${perJourneyPerPaxViewPaxCaption(paxAmount(props.baggageSection.state))}
                          </div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(props.baggageSection.state)}
                              ${paxLabel(paxAmount(props.baggageSection.state))}
                          </div>
                      </div>
                      ${viewSwitchButtonTemplate()}
                  </div>
                  ${journeysTemplate()}
              `
            : html``;

    return { htmlTemplate };
};

import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import i18next from "i18next";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { Column, GridState, PageChangeEvent, SortChangeEvent, ViewModel } from "../../../dc-components/dc-table-models";
import { StaffBenefitFamilyItem } from "../../../component-models/staff-benefit/StaffBenefitFamilyItem";
import { useModal } from "../../shared/useModal";

export interface Props {
    familyMembers: StaffBenefitFamilyItem[];
    onSelect: (member: StaffBenefitFamilyItem) => void;
}

export const useFamilyMemberSelectorModal = (props: Props) => {
    // HELPERS

    const updatePageability = () =>
        props.familyMembers?.length > 0
            ? setGridState({ ...gridState, pageSize: props.familyMembers.length < 6 ? props.familyMembers.length : 5 })
            : null;

    const orderedModel = () =>
        props.familyMembers ? GridHelper.getOrderedFilteredModel(props.familyMembers, gridState) : [];

    const initialGridState = (): GridState<keyof StaffBenefitFamilyItem> => ({
        pageIndex: 0,
        appliedFilters: [],
        pageSize: props.familyMembers?.length < 6 ? props.familyMembers.length : 5,
        orderBy: "LastName",
        orderDir: "asc",
        globalFilterExpression: "",
    });

    // EVENT HANDLERS

    const handlePageChange = (e: PageChangeEvent) => {
        setGridState({ ...gridState, pageIndex: e.detail.selectedPageIndex, pageSize: e.detail.selectedPageSize });
    };

    const handleSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof StaffBenefitFamilyItem,
            orderDir: e.detail.orderDir,
        });
    };

    const handleFilterChange = (e: Event) => {
        setGridState({
            ...gridState,
            pageIndex: 0,
            globalFilterExpression: (e.target as HTMLInputElement).value,
        });
    };

    const handleSelect = (index: number) => {
        props.onSelect(orderedModel()[index]);
        modal.close();
    };

    const handleClose = () => {
        setGridState(initialGridState());
    };

    // COMPONENT

    const [gridState, setGridState] = useState<GridState<keyof StaffBenefitFamilyItem>>(initialGridState());

    useEffect(updatePageability, [props.familyMembers?.length]);

    // TEMPLATES

    const nameCellTemplate = (index: number) => {
        const row = orderedModel()[index];

        return html`
            <div class="hover:text-red cursor-pointer" @click=${() => handleSelect(index)}>
                ${row.FirstName} ${row.LastName}
            </div>
        `;
    };

    const filterTemplate = () => html`
        <div class="family-member-filter">
            <input
                placeholder=${i18next.t("Buscar beneficiario")}
                autocomplete="cc-exp"
                .value=${null}
                @input=${handleFilterChange}
            />
            <i class="js-icon js-search"></i>
        </div>
    `;

    const vm: ViewModel<keyof StaffBenefitFamilyItem> = {
        data: orderedModel(),
        forcedNumberOfRows: gridState.pageSize,
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: nameCellTemplate,
            } as Column<keyof StaffBenefitFamilyItem>,
            {
                field: "Id",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof StaffBenefitFamilyItem>,
        ],
        paging: {
            pageable: props.familyMembers
                ? GridHelper.getFilteredTotalNumber(props.familyMembers, gridState) > 5
                : false,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [],
            itemCount: props.familyMembers ? GridHelper.getFilteredTotalNumber(props.familyMembers, gridState) : 0,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: false,
            // selectedIndices: Array.from(selectedIds.values())
            //     .filter((id) => orderedModel().some((item) => item.Id === id))
            //     .map((id) =>
            //         orderedModel()
            //             .map((i) => i.Id)
            //             .indexOf(id),
            //     ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: false,
    };

    const headerTemplate = () => html`
        <i class="js-circle-user js-icon"></i> ${i18next.t("Beneficiarios Conéctate SMART")}
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            ${filterTemplate()}
            <dc-table .vm=${vm} @onSortChange=${handleSortChange} @onPageChange=${handlePageChange}></dc-table>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true, onClose: handleClose },
        content: { classNames: "family-member-selector-modal", template: htmlTemplate },
        header: { template: headerTemplate },
        scrolling: { isScrollable: true },
    });

    return modal;
};

import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { INVOICE, RECEIPT } from "../../shared/commonConstants";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useFlowContext } from "../../managers/useFlowContext";

export interface Props {
    model: PaymentPageViewModel;
    onSelect: () => void;
}

export const useBillingTypeSelector = (props: Props) => {
    const flowContext = useFlowContext();

    const showInvoiceCheckbox = () =>
        props.model?.BillingViewModel.CanGetInvoice && !flowContext.isFlightlessPurchaseFlow;

    const initialType = () =>
        showInvoiceCheckbox() &&
        (props.model.BillingViewModel.PreCheckInvoice || !props.model.BillingViewModel.CanGetReceipt)
            ? INVOICE
            : RECEIPT;

    const [selectedBilling, setSelectedBilling] = useState<typeof INVOICE | typeof RECEIPT>(undefined);

    const handleInvoiceSelect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedBilling(INVOICE);

        props.onSelect();
    };

    const handleReceiptSelect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedBilling(RECEIPT);

        props.onSelect();
    };

    useEffect(() => {
        if (!props.model || selectedBilling) return;
        setSelectedBilling(initialType());
    }, [props.model]);

    // YOU CANNOT RE-USE THIS CSS ANYWHERE ELSE!!!
    const receiptCheckboxTemplate = () =>
        props.model.BillingViewModel.CanGetReceipt
            ? html`
                  <div class="vstbs-checkbox-container">
                      <label data-test-id="payment-billing-receipt-select" @click=${handleReceiptSelect}>
                          <span>
                              ${props.model.BillingViewModel.ShowArgentinianLabels
                                  ? i18next.t("V2-ArReceipt")
                                  : i18next.t("V2-Receipt")}
                          </span>
                          <div class="vsbts-checkbox ${selectedBilling === RECEIPT ? "checked" : ""}"></div>
                      </label>
                  </div>
              `
            : "";

    const argentinaInvoiceCheckboxTooltip = () =>
        props.model.BillingViewModel.ShowArgentinianLabels
            ? html`
                  ${i18next.t("V2-ArInvoice")}
                  <ac-tooltip
                      icon="?"
                      custom-class=${"ar-invoice-tooltip-opener"}
                      tooltip=${i18next.t("CUG-TT-ArgentinaCompanyInfo")}
                      tooltip-custom-class="ar-invoice-tooltip"
                  ></ac-tooltip>
              `
            : "";

    const nonArgentinaInvoiceCheckboxTooltip = () =>
        !props.model.BillingViewModel.ShowArgentinianLabels
            ? html`<span data-test-id="invoice-checkbox"> ${i18next.t("V2-Invoice")} </span>`
            : "";

    // YOU CANNOT RE-USE THIS CSS ANYWHERE ELSE!!!
    const invoiceCheckboxTemplate = () =>
        showInvoiceCheckbox()
            ? html`
                  <div class="vstbs-checkbox-container">
                      <label data-test-id=${T.PAYMENT_INVOICE_FORM.SELECT} @click=${handleInvoiceSelect}>
                          <span> ${argentinaInvoiceCheckboxTooltip()} ${nonArgentinaInvoiceCheckboxTooltip()} </span>
                          <div class="vsbts-checkbox ${selectedBilling === INVOICE ? "checked" : ""}"></div>
                      </label>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">${receiptCheckboxTemplate()} ${invoiceCheckboxTemplate()}</div>
        </div>
    `;

    return {
        isInvoiceSelected: selectedBilling === INVOICE,
        htmlTemplate,
    };
};

import { TemplateResult } from "lit-html";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { InputMap } from "../../../shared/customHooks/useForm/useForm";
import { Messages } from "../../../validator/Validation";
import { WidgetSectionType } from "./useProfilePersonWidget";
import { RefundBankVM } from "./useRefundBankSection";

export interface WidgetSectionStrategyDataObject {
    component: UseFormComponent | ValidatorComponent;
    bodyToPost: any;
    postUrl: string;
}

export interface WidgetSection {
    validate: () => Promise<boolean>;
    submit: () => Promise<void>;
}

interface UseFormComponent {
    init: (formElem?: HTMLElement) => void;
    validate: (fieldsToValidate?: InputMap) => Promise<boolean>;
    reset: () => void;
    errorIds: string[];
}

interface ValidatorComponent {
    messages: Messages<string>;
    getMessage: (field: keyof RefundBankVM) => string | TemplateResult;
    getFormMessages: () => (string | TemplateResult)[];
    isValid: (field: keyof RefundBankVM) => boolean;
    validate: () => Promise<boolean>;
    validateAndDispatch: () => Promise<void>;
}

export const useWidgetSectionHelper = () => {
    const { ajaxRequest } = useAjax();

    const isUseFormComponent = (component: UseFormComponent | ValidatorComponent): component is UseFormComponent =>
        (component as UseFormComponent).init !== undefined;

    const selectWidgetSectionStrategy = (
        sectionStrategies: Map<WidgetSectionType, WidgetSectionStrategyDataObject>,
        currentSection: WidgetSectionType,
    ): WidgetSection => {
        const params = sectionStrategies.get(currentSection);

        const submit = async () => {
            await ajaxRequest({
                body: params.bodyToPost,
                url: params.postUrl,
                method: "POST",
            });
        };

        const validate = async () =>
            isUseFormComponent(params.component)
                ? Promise.resolve(params.component.validate())
                : params.component.validate();

        return {
            submit,
            validate,
        };
    };

    return { selectWidgetSectionStrategy };
};

import { ROUTES } from "../../../shared/apiRoutes";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useFlowContext } from "../../../managers/useFlowContext";
import { ApiReview } from "../../../component-models/review/ApiReview";
import { baggageReview } from "./baggageReview";
import { passengerReview } from "./passengerReview";
import { extrasReview } from "./extrasReview";
import { seatReview } from "./seatReview";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { contactReview } from "./contactReview";
import { emergencyContactReview } from "./emergencyContactReview";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import classNames from "classnames";
import { usePubSub } from "../../../pub-sub-service/usePubSub";

export interface Props {
    onForward: () => void;
}

export const useReviewPage = (props: Props): SpaContent => {
    const flowContext = useFlowContext();

    const { ajaxJsonRequest } = useAjax();
    const { triggers } = usePubSub();

    const [antiForgeryToken] = useReduxState("antiForgeryToken");
    const [activeSpaSection] = useReduxState("spa.activeSection");

    const [model, setModel] = useState<ApiReview>(undefined);

    const baggage = baggageReview(model);
    const contact = contactReview(model);
    const emergencyContact = emergencyContactReview(model);
    const extras = extrasReview(model);
    const passengers = passengerReview(model);
    const seats = seatReview(model);

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        raiseBookingFlowContinueEvent(true);
        await ajaxJsonRequest({
            method: "POST",
            url: ROUTES.BookingFinalizeChanges,
            body: {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
            },
        });

        props.onForward();
    };

    useEffect(() => BookingFlowHandler.storeCurrentStep(BookingSteps.Review), []);

    const buttonsTemplate = () => html`
        <form class="continue-button">
            <div class="review-button-container">
                <a
                    href=${ROUTES.BookingReset}
                    class=${classNames("rounded-secondary-btn mr-3", { disabled: activeSpaSection !== "Review" })}
                >
                    ${flowContext.isCheckinFlow ? i18next.t("Checkin-UndoChanges") : i18next.t("Booking-UndoChanges")}
                </a>

                <button
                    class=${classNames("rounded-primary-btn", { disabled: activeSpaSection !== "Review" })}
                    data-test-id=${T.MODIFY_BOOKING.REVIEW_SUBMIT_BUTTON}
                    @click=${handleSubmit}
                >
                    ${i18next.t("Continuar")}
                </button>
            </div>
        </form>
    `;

    const headerTemplate = () => html`
        <header>
            <div class="title full-width-title">
                <h2 class="main-title">${i18next.t("V2-Confirm-FollowingChangesMade")}</h2>
            </div>
        </header>
    `;

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const result = await ajaxJsonRequest<{ Data: ApiReview }>({ method: "GET", url: ROUTES.ApiRoutes.Review });

            if (result.redirectionUrl) return { type: "redirect", redirectionUrl: result.redirectionUrl };

            if (!result.data.Data.HasChanges) return { type: "redirect" };

            setModel(result.data.Data);

            triggers.sidebar.bookingChanged.publish({});

            return { type: "success" };
        } catch (e) {
            return { type: "error" };
        }
    };

    const htmlTemplate = () =>
        model?.HasChanges
            ? html`
                  <div id="mainContentWrapper">
                      <div id="mainContentReview">
                          <div class="review-page">
                              <section class="booking-wrapper no-breadcrumb">
                                  ${headerTemplate()}
                                  <div class="row">
                                      <div class="col-xs-1">
                                          ${passengers.htmlTemplate()} ${contact.htmlTemplate()}
                                          ${emergencyContact.htmlTemplate()} ${baggage.htmlTemplate()}
                                          ${extras.htmlTemplate()} ${seats.htmlTemplate()}
                                      </div>
                                  </div>
                              </section>
                          </div>
                          ${buttonsTemplate()}
                      </div>
                  </div>
              `
            : "";

    return { build, htmlTemplate };
};

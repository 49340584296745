import i18next from "i18next";
import { BookingContext } from "../../component-models/app/BookingContext";
import { TravelDocument } from "../../component-models/checkin/TravelDocument";
import { ApiSpecialAssistanceSsrModel } from "../../component-models/passengers/ApiSpecialAssistanceSsrModel";
import { PassengerFormVM } from "../../component-models/passengers/PassengerFormVM";
import { PassengerUpdateEvent } from "../../components/spa/passengers/usePassengersPage";
import { COLOMBIA_COUNTRY_CODE } from "../../shared/commonConstants";

export const passengerHelpers = () => {
    const updatePaxArray = (
        array: PassengerFormVM[],
        eventDetails: PassengerUpdateEvent,
        documents: TravelDocument[],
    ): PassengerFormVM[] =>
        array
            .map((pax): PassengerFormVM => {
                const newDocument = documents.find(
                    (document) =>
                        document.TypeCode === eventDetails.value &&
                        document.PassengerNumber ===
                            (pax.type === "INF" ? pax.attachedPassengerNumber : pax.passengerNumber) &&
                        document.DocSuffix === (pax.type === "INF" ? "I" : " "),
                );

                const documentNumber =
                    eventDetails.key === "documentType" ? newDocument?.Number || "" : pax.documentNumber;

                const isDocumentReadonly =
                    eventDetails.key === "documentType" ? newDocument?.IsReadonly || false : pax.isDocumentReadonly;

                return pax.uniqueIndex === eventDetails.passengerUniqueIndex
                    ? {
                          ...pax,
                          documentNumber,
                          isDocumentReadonly,
                          [eventDetails.key]: eventDetails.value,
                      }
                    : pax;
            })
            .sort((a, b) => a.passengerNumber - b.passengerNumber);

    // TODO Only works during checkin
    const isJourneyInternationalToColombia = (bookingContext: BookingContext) =>
        bookingContext.originCountryCode !== COLOMBIA_COUNTRY_CODE &&
        bookingContext.destinationCountryCode === COLOMBIA_COUNTRY_CODE;

    const getAssistanceOptionPaxNumber = (option: ApiSpecialAssistanceSsrModel) =>
        Number(option.SsrAddKeys[0].SsrKey.split("|")[0]);

    const getGenericPaxName = (index: number, isInfant: boolean) =>
        `${isInfant ? i18next.t("V2-Infant") : i18next.t("V2-PassengerLabel")} ${index + 1}`;

    return { getAssistanceOptionPaxNumber, getGenericPaxName, isJourneyInternationalToColombia, updatePaxArray };
};

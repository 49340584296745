import { PaxType } from "../../shared/commonConstants";
import { TravelDocumentType } from "./TravelDocument";
import * as dayjs from "dayjs";

export interface CheckinPaxCheckboxChangeEventDetail {
    paxNumber: number;
}

export class CheckinPaxCheckboxChangeEvent extends CustomEvent<CheckinPaxCheckboxChangeEventDetail> {
    constructor(detail: CheckinPaxCheckboxChangeEventDetail) {
        super("paxCheckboxChange", { detail });
    }
}
export interface CheckinPaxOxyChangeEventDetail {
    hasOxy: boolean;
    paxNumber: number;
}

export class CheckinPaxOxyChangeEvent extends CustomEvent<CheckinPaxOxyChangeEventDetail> {
    constructor(detail: CheckinPaxOxyChangeEventDetail) {
        super("changeOxy", { detail });
    }
}

export interface CheckinFrequentFlyerNumberChangeEventDetail {
    frequentFlyerNumber: string;
    paxIndex: number;
    paxType: PaxType;
}

export class CheckinFrequentFlyerNumberChangeEvent extends CustomEvent<CheckinFrequentFlyerNumberChangeEventDetail> {
    constructor(detail: CheckinFrequentFlyerNumberChangeEventDetail) {
        super("changeFrequentFlyerNumber", { detail });
    }
}

export interface CheckinPaxDOBChangeEventDetail {
    dateOfBirth: dayjs.Dayjs;
    paxIndex: number;
    paxType: PaxType;
}

export class CheckinPaxDOBChangeEvent extends CustomEvent<CheckinPaxDOBChangeEventDetail> {
    constructor(detail: CheckinPaxDOBChangeEventDetail) {
        super("changeDateOfBirth", { detail });
    }
}

export interface CheckinPaxExpirationDateChangeEventDetail {
    expirationDate: dayjs.Dayjs;
    paxIndex: number;
    paxType: PaxType;
}

export class CheckinPaxExpirationDateChangeEvent extends CustomEvent<CheckinPaxExpirationDateChangeEventDetail> {
    constructor(detail: CheckinPaxExpirationDateChangeEventDetail) {
        super("changeExpirationDate", { detail });
    }
}

export interface ChangeEditedDocumentTypeEventDetail {
    docTypeCode: TravelDocumentType;
    paxIndex: number;
    paxType: PaxType;
}

export class ChangeEditedDocumentTypeEvent extends CustomEvent<ChangeEditedDocumentTypeEventDetail> {
    constructor(detail: ChangeEditedDocumentTypeEventDetail) {
        super("changeEditedDocumentType", { detail });
    }
}

export interface ChangeDocNumberEventDetail {
    docNumber: string;
    paxIndex: number;
    paxType: PaxType;
}

export class ChangeDocNumberEvent extends CustomEvent<ChangeDocNumberEventDetail> {
    constructor(detail: ChangeDocNumberEventDetail) {
        super("changeDocNumber", { detail });
    }
}

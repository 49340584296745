import { mapToSeatmapModel } from "./SeatmapMappers";
import { SpaContainerViewModel } from "../component-models/spa/SpaContainerViewModel";
import { ApiSpaContainerViewModel } from "../component-models/spa/ApiSpaContainerViewModel";
import { FlowContext } from "../component-models/app/FlowContext";

export function mapSpaContainerViewModel(data: {
    flowContext: FlowContext;
    model: ApiSpaContainerViewModel;
    isBancoEstado56: boolean;
}): SpaContainerViewModel {
    const seatmapModel = data.model.SeatmapSpaSectionViewModel.SeatmapModel
        ? mapToSeatmapModel({
              model: data.model.SeatmapSpaSectionViewModel.SeatmapModel,
              isCategory56: data.isBancoEstado56,
              isBookingFlow: data.flowContext.isBookingFlow,
          })
        : undefined;

    return {
        ...data.model,

        BaggageModel: data.model.BaggageModel,

        ExtrasModel: data.model.ExrasModel,

        FlatBookingData: data.model.FlatBookingData || seatmapModel.FlattenedBookingData,

        Segments: data.model.Segments ?? [],
    };
}

import { html } from "lit-html";
import i18next from "i18next";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { useMemo } from "../../shared/haunted/CustomHooks";

export interface AirportWarningProps {
    context: FlightPageContext;
}

export const useAirportWarning = (props: AirportWarningProps) => {
    const showAirportDifferenceWarning = useMemo(() => {
        if (!props.context?.flightState) return false;

        const outbound = props.context.flightState.selectedOutboundFlight?.FlightSelectModel;
        const inbound = props.context.flightState.selectedInboundFlight?.FlightSelectModel;

        return (
            outbound &&
            inbound &&
            (outbound.OriginCity !== inbound.DestinationCity || outbound.DestinationCity !== inbound.OriginCity)
        );
    }, [props.context]);

    const htmlTemplate = () =>
        showAirportDifferenceWarning
            ? html`
                  <div
                      class="mx-auto my-5 flex w-[1005px] max-w-[95%] items-center rounded-lg bg-brand-tertiary px-6 py-3 text-lg leading-relaxed text-white"
                      data-test-id=${T.FLIGHT.AIRPORT_WARNING}
                  >
                      <i class="js-icon js-dc-exclamation mr-3 text-2xl"></i>
                      <span>${i18next.t("AirportDifferenceWarning")}</span>
                  </div>
              `
            : "";

    return { htmlTemplate };
};

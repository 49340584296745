import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import * as DC from "./dc-components";
import { ChangeEvent } from "./dc-checkbox";
import {
    AppliedFilter,
    Column,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    SortOrder,
    ViewModel,
} from "./dc-table-models";
import { isEmpty } from "../component-helpers/stringHelper";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { useEffect, useState } from "../shared/haunted/CustomHooks";
import { createRange } from "../component-helpers/collectionHelper";
import { GridHelper } from "../component-helpers/GridHelper";
import { usePubSub } from "../pub-sub-service/usePubSub";

export interface Properties {
    vm: ViewModel<any>;
}

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-table-grid";

export const getFilterByFieldName = <T>(appliedFilters: AppliedFilter<T>[], fieldName: T) => {
    const filters = appliedFilters.filter((filter) => filter.field === fieldName);
    if (filters.length > 0) {
        return filters[0];
    } else {
        return undefined;
    }
};

export const mergeAppliedFilter = <T>(appliedFilters: AppliedFilter<T>[], newFilter: AppliedFilter<T>) => {
    let newFilters = [];

    const isDateFilterOn = newFilter.filterProps.max instanceof Date || newFilter.filterProps.min instanceof Date;
    const isNumberFilterOn = !isNaN(newFilter.filterProps.max as number) || !isNaN(newFilter.filterProps.min as number);

    const hasSelectedValues =
        Array.isArray(newFilter.filterProps.selectedValues) && newFilter.filterProps.selectedValues.length > 0;

    const hasSelectedValue =
        typeof newFilter.filterProps.selectedValues === "string" && !isEmpty(newFilter.filterProps.selectedValues);

    if (isDateFilterOn || hasSelectedValues || hasSelectedValue || isNumberFilterOn) {
        let found = false;
        newFilters = appliedFilters.map((filter) => {
            if (filter.field === newFilter.field) {
                found = true;
                return newFilter;
            } else {
                return filter;
            }
        });
        if (!found) {
            newFilters.push(newFilter);
        }
    } else {
        newFilters = appliedFilters.filter((filter) => filter.field !== newFilter.field);
    }

    return newFilters;
};

const DEFAULTS: Properties = {
    vm: {
        columns: [] as Column<any>[],
        data: [] as any[],
        paging: {
            pageable: true,
            pageSize: 25,
            pageIndex: 0,
            buttonCount: 10,
            pageSizes: [25, 50, 100],
            showInfo: true,
            showPreviousNext: true,
        },
        sorting: {
            orderBy: undefined,
            orderDir: undefined,
            showSorterArrow: true,
        },
        selection: {
            selectable: false,
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: true,
    },
};

const addIsSelectedPropertyToData = (data: any[]): any[] => {
    return data.map((row) => {
        return { ...row, isSelected: false };
    });
};

export const Component: HauntedFunc<Properties> = (host) => {
    const getCellTemplate = <T>(column: Column<T>) =>
        column.cellTemplate
            ? column.cellTemplate
            : (index: number) => {
                  return props.vm.data[index][column.field];
              };

    const getCellClass = <T>(column: Column<T>) =>
        column.cellClass
            ? column.cellClass
            : (_index: number) => {
                  return "";
              };

    const getPaging = () =>
        host.vm.paging !== undefined
            ? {
                  pageable:
                      host.vm.paging.pageable !== undefined ? host.vm.paging.pageable : DEFAULTS.vm.paging.pageable,
                  pageSize:
                      host.vm.paging.pageSize !== undefined ? host.vm.paging.pageSize : DEFAULTS.vm.paging.pageSize,
                  itemCount:
                      host.vm.paging.itemCount !== undefined ? host.vm.paging.itemCount : DEFAULTS.vm.paging.itemCount,
                  pageSizes:
                      host.vm.paging.pageSizes !== undefined ? host.vm.paging.pageSizes : DEFAULTS.vm.paging.pageSizes,
                  pageIndex:
                      host.vm.paging.pageIndex !== undefined ? host.vm.paging.pageIndex : DEFAULTS.vm.paging.pageIndex,
                  buttonCount:
                      host.vm.paging.buttonCount !== undefined
                          ? host.vm.paging.buttonCount
                          : DEFAULTS.vm.paging.buttonCount,
                  showInfo:
                      host.vm.paging.showInfo !== undefined ? host.vm.paging.showInfo : DEFAULTS.vm.paging.showInfo,
                  showPreviousNext:
                      host.vm.paging.showPreviousNext !== undefined
                          ? host.vm.paging.showPreviousNext
                          : DEFAULTS.vm.paging.showPreviousNext,
              }
            : DEFAULTS.vm.paging;

    const getSelection = () =>
        host.vm.selection !== undefined ? { ...DEFAULTS.vm.selection, ...host.vm.selection } : DEFAULTS.vm.selection;

    const getRowCustomization = () =>
        host.vm.rowCustomization !== undefined ? host.vm.rowCustomization : DEFAULTS.vm.rowCustomization;

    const props: Properties = {
        vm:
            host.vm !== undefined
                ? {
                      columns:
                          host.vm.columns !== undefined
                              ? [
                                    ...host.vm.columns.map((column) => {
                                        return {
                                            ...column,
                                            cellTemplate: getCellTemplate(column),
                                            cellClass: getCellClass(column),
                                        };
                                    }),
                                ]
                              : DEFAULTS.vm.columns,
                      bodyTemplate: host.vm.bodyTemplate,
                      rowViewTemplate: host.vm.rowViewTemplate,
                      rowEditTemplate: host.vm.rowEditTemplate,
                      rowDeleteTemplate: host.vm.rowDeleteTemplate,
                      data: host.vm.data !== undefined ? addIsSelectedPropertyToData(host.vm.data) : DEFAULTS.vm.data,
                      paging: getPaging(),
                      sorting: host.vm.sorting ? host.vm.sorting : DEFAULTS.vm.sorting,
                      selection: getSelection(),
                      rowCustomization: getRowCustomization(),
                      appliedFilters:
                          host.vm.appliedFilters !== undefined ? host.vm.appliedFilters : DEFAULTS.vm.appliedFilters,
                      actionCellClass: host.vm.actionCellClass,
                      actionCellContentClass: host.vm.actionCellContentClass,
                      useEllipsis: host.vm.useEllipsis !== undefined ? host.vm.useEllipsis : DEFAULTS.vm.useEllipsis,
                  }
                : DEFAULTS.vm,
    };

    // HELPER

    const hasPinnedContent = () =>
        props.vm.columns.some((c) => c.isPinned) ||
        props.vm.rowViewTemplate ||
        props.vm.rowDeleteTemplate ||
        props.vm.rowEditTemplate;

    const resetSelection = () => {
        setSelectedIndices([]);
    };

    const columnClassMapObject = (currentColumnClass: string | string[]) => {
        if (!currentColumnClass) {
            return {};
        }

        return (typeof currentColumnClass === "string" ? [currentColumnClass] : currentColumnClass).reduce(
            (classMapObject, currentClass) => {
                currentClass.split(" ").forEach((curItem) => {
                    classMapObject[curItem] = true;
                });
                return classMapObject;
            },
            {} as { [key: string]: boolean },
        );
    };

    const validateIndices = () => {
        if (props.vm.selection.selectedIndices !== undefined) {
            const onlyValidIndices = props.vm.selection.selectedIndices.reduce((aggr: number[], curr) => {
                if (curr >= 0 && curr < props.vm.data.length && !aggr.includes(curr)) {
                    return aggr.concat(curr);
                } else {
                    return aggr;
                }
            }, []);
            onlyValidIndices.sort();
            setSelectedIndices(onlyValidIndices);
        }
    };

    // EVENT HANDLER

    const onPageChanged = async (e: DC.Pager.PagerChangeEvent) => {
        const pageIndex = e.detail.selectedPageIndex;
        const pageSize = e.detail.selectedPageSize;
        setSelectedPageIndex(pageIndex);
        setSelectedPageSize(pageSize);
        resetSelection();
        host.dispatchEvent(
            new PageChangeEvent({
                selectedPageIndex: pageIndex,
                selectedPageSize: pageSize,
            }),
        );
    };

    const onSortClicked = (field: string, sortOrder: SortOrder) => {
        setOrderDir(sortOrder);
        setOrderBy(field);
        resetSelection();
        host.dispatchEvent(
            new SortChangeEvent({
                orderBy: field,
                orderDir: sortOrder,
            }),
        );
    };

    const onRowSelected = (rowIndex: number, e: ChangeEvent) => {
        let newIndices = [...selectedIndices];
        if (e.detail.checked) {
            if (!selectedIndices.includes(rowIndex)) {
                newIndices.push(rowIndex);
                newIndices.sort();
            }
        } else {
            newIndices = selectedIndices.filter((index) => index !== rowIndex);
        }

        setSelectedIndices(newIndices);
        host.dispatchEvent(
            new RowsSelectedEvent({
                currentSelection: newIndices,
                rowChanged: rowIndex,
            }),
        );
    };

    const allRowsChanged = () => {
        const newIndices = selectedIndices.length === props.vm.data.length ? [] : props.vm.data.map((_, i) => i);
        setSelectedIndices(newIndices);

        host.dispatchEvent(
            new RowsSelectedEvent({
                currentSelection: newIndices,
                rowChanged: props.vm.data.map((_, i) => i),
            }),
        );
    };

    // COMPONENT

    const [selectedPageIndex, setSelectedPageIndex] = useState(DEFAULTS.vm.paging.pageIndex);
    const [selectedPageSize, setSelectedPageSize] = useState(DEFAULTS.vm.paging.pageSize);
    const [orderBy, setOrderBy] = useState<string>("");
    const [orderDir, setOrderDir] = useState<SortOrder>("asc");
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

    const { triggers } = usePubSub();

    useEffect(() => setSelectedPageIndex(props.vm.paging.pageIndex), [props.vm.paging.pageIndex]);

    useEffect(() => setSelectedPageSize(props.vm.paging.pageSize), [props.vm.paging.pageSize]);

    useEffect(() => setOrderBy(props.vm.sorting.orderBy), [props.vm.sorting.orderBy]);

    useEffect(() => setOrderDir(props.vm.sorting.orderDir), [props.vm.sorting.orderDir]);

    useEffect(validateIndices, [props.vm.selection.selectedIndices]);

    // TEMPLATE

    const headerSelectionCellTemplate = () =>
        props.vm.selection.selectable && props.vm.data.length > 0
            ? html`
                  <div class="dctg-header-cell">
                      <dc-checkbox
                          .checked=${selectedIndices.length === props.vm.data.length && props.vm.data.length !== 0}
                          .readonly=${!props.vm.selection.selectable || props.vm.data.length === 0}
                          @change=${allRowsChanged}
                      ></dc-checkbox>
                  </div>
              `
            : "";

    const headerCellTemplate = (column: Column<any>) => {
        const tempClassMap = classMap({
            "dctg-header-cell": true,
            "pinned": column.isPinned,
            ...columnClassMapObject(column.columnClass),
        });

        return html`
            <div class=${tempClassMap}>
                <dc-table-grid-header
                    .fieldName=${column.field}
                    .headerTemplate=${column.headerTemplate}
                    .label=${column.label}
                    .orderBy=${orderBy}
                    .orderDir=${orderDir}
                    .sortable=${column.sortable}
                    .useEllipsis=${props.vm.useEllipsis}
                    .onSortClicked=${onSortClicked}
                ></dc-table-grid-header>
            </div>
        `;
    };

    const tableHeaderTemplate = () => html`
        ${headerSelectionCellTemplate()} ${repeat(props.vm.columns, (column) => column.field, headerCellTemplate)}
        ${actionCellHeaderTemplates()}
    `;

    const actionCellHeaderTemplates = () => {
        const cellClassMap = GridHelper.getClassMap(
            Array.isArray(props.vm.actionCellClass)
                ? [...props.vm.actionCellClass, "dctg-header-cell"]
                : [props.vm.actionCellClass, "dctg-header-cell"],
        );

        const cellContentClassMap = GridHelper.getClassMap(props.vm.actionCellContentClass);

        return props.vm.data.length > 0
            ? [props.vm.rowViewTemplate, props.vm.rowEditTemplate, props.vm.rowDeleteTemplate]
                  .filter((t) => t)
                  .map((_) => html` <div class=${cellClassMap}><div class=${cellContentClassMap}></div></div> `)
            : "";
    };

    const selectableColumnTemplate = (rowIndex: number) =>
        props.vm.selection.selectable && props.vm.data.length > 0
            ? html`
                  <div class="dctg-body-cell">
                      <div class="flex h-full w-full items-center justify-center">
                          <dc-checkbox
                              .checked=${selectedIndices.includes(rowIndex)}
                              @change=${(e: ChangeEvent) => onRowSelected(rowIndex, e)}
                          ></dc-checkbox>
                      </div>
                  </div>
              `
            : "";

    const columnTemplate = (column: Column<any>, rowIndex: number) => {
        const tempClassMap = classMap({
            "dctg-body-cell": true,
            "pinned": column.isPinned,
            ...columnClassMapObject(column.cellClass(rowIndex)),
        });

        return html` <div class=${tempClassMap}>${column.cellTemplate(rowIndex)}</div> `;
    };

    const tableBodyTemplate = () => html`
        ${props.vm.bodyTemplate
            ? props.vm.bodyTemplate()
            : props.vm.data.length !== undefined
              ? createRange(0, props.vm.data.length - 1).map(rowTemplate)
              : ""}
    `;

    const rowTemplate = (rowIndex: number) => html`
        ${selectableColumnTemplate(rowIndex)} ${props.vm.columns.map((column) => columnTemplate(column, rowIndex))}
        ${viewCellTemplate(rowIndex)} ${editCellTemplate(rowIndex)} ${deleteCellTemplate(rowIndex)}
    `;

    const viewCellTemplate = (i: number) => (props.vm.rowViewTemplate ? html` ${props.vm.rowViewTemplate(i)} ` : "");

    const editCellTemplate = (i: number) => (props.vm.rowEditTemplate ? html` ${props.vm.rowEditTemplate(i)} ` : "");

    const deleteCellTemplate = (i: number) =>
        props.vm.rowDeleteTemplate ? html` ${props.vm.rowDeleteTemplate(i)} ` : "";

    const contentTemplate = () => html` ${tableHeaderTemplate()} ${tableBodyTemplate()} `;

    const pagerTemplate = () =>
        props.vm.paging.pageable
            ? html`
                  <dc-pager
                      .pageIndex=${selectedPageIndex}
                      .pageSize=${selectedPageSize}
                      .pageButtonCount=${props.vm.paging.buttonCount}
                      .pageSizes=${props.vm.paging.pageSizes}
                      .itemCount=${props.vm.paging.itemCount}
                      .showInfo=${props.vm.paging.showInfo}
                      @change=${onPageChanged}
                  ></dc-pager>
              `
            : "";

    const scrollingTableTemplate = () => {
        const tempClassMap = classMap({
            "dc-table-grid": true,
            "use-ellipsis": props.vm.useEllipsis,
            "no-select": !props.vm.selection?.selectable || props.vm.data.length === 0,
            [`cols-${props.vm.columns.length}`]: true,
        });

        return html` <div class=${tempClassMap}>${contentTemplate()}</div> `;
    };

    const pinnedCellsTemplate = () => {
        const tempClassMap = classMap({
            "dc-table-grid": true,
            "pinned-cells": true,
            "no-select": !props.vm.selection?.selectable || props.vm.data.length === 0,
            [`cols-${props.vm.columns.length}`]: true,
        });

        return hasPinnedContent()
            ? html`
                  <!-- DEVNOTE: With a brilliant trick, we double the table, so that pinned columns can be -->
                  <!-- displayed fixed, without messing up the line heights -->
                  <div class=${tempClassMap}>${contentTemplate()}</div>
              `
            : "";
    };

    return html`
        <div class="dc-table-grid-shell">
            <div class="dc-table-grid-container" @scroll=${() => triggers.shared.windowWasScrolled.publish({})}>
                ${scrollingTableTemplate()}
            </div>
            ${pinnedCellsTemplate()}
        </div>
        ${pagerTemplate()}
    `;
};

import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { toBoolean } from "../../shared/common";
import { useMainMenu } from "../layout/useMainMenu";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";
import { useEffect } from "../../shared/haunted/CustomHooks";

export const observedAttributes: (keyof Attributes)[] = ["should-reload", "profile-url"];
export const name = "ac-pcra-header";

export interface Attributes {
    "should-reload": string;
    "profile-url": string;
}
export interface Properties {
    shouldReload: boolean;
    profileUrl: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        shouldReload: toBoolean(host.shouldReload),
        profileUrl: host.profileUrl,
    };

    const userContextManager = useUserContextBuilder();

    const menu = useMainMenu({
        profileUrl: props.profileUrl,
        shouldReloadOnLogin: props.shouldReload,
    });

    useEffect(userContextManager.init, []);

    return html`
        <header class="main-header">
            <div class="nav-bg">
                <section class="nav-container full-width">
                    <a class="jetsmart-logo" href="/">
                        <img src="/Images/Header/jetsmart-logo-colored.svg" />
                    </a>
                    ${menu.htmlTemplate()}
                </section>
            </div>
        </header>
    `;
};

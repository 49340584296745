import { InputElement } from "../useForm";
import { InputFieldAttribute, UDF_ATTR_MIN } from "../InputFieldAttribute";

export const minLength = (input: InputElement): InputFieldAttribute => ({
    name: UDF_ATTR_MIN,
    validators: [
        {
            errorMessage: {
                id: "minLengthError",
                message:
                    window.formResources.fieldMinimumLengthIs +
                    `${input.getAttribute(UDF_ATTR_MIN)} ` +
                    window.formResources.characters,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result =
                    field.value.length === 0 || field.value.length >= Number(field.getAttribute(UDF_ATTR_MIN));

                return Promise.resolve(result);
            },
        },
    ],
});

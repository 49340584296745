import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../shared/useModal";

export const useInvalidPeruCompraModal = () => {
    const redirect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = "/V2/Login?pcompra=1";
    };

    const headerTemplate = () => html`
        <i class="fas fa-exclamation-circle notification-icon"></i>
        ${i18next.t("Atención")}
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <span class="leading-normal text-brand-secondary">
                ${i18next.t(
                    "Si deseas Administrar tu Reserva o hacer Check in, debes iniciar sesión con tu cuenta Perú Compras.",
                )}
            </span>
            <div class="modal-button-container modification-buttons-container">
                <button class="rounded-primary-btn mr-4" @click=${redirect}>${i18next.t("Aceptar")}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        header: { template: headerTemplate },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
    });

    return modal;
};

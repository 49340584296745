import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PetInCabinOptionClickedEvent } from "../../../../component-models/extras/pet-in-cabin/PetInCabinEvents";
import { PetInCabinJourneyPassengerModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyPassengerModel";
import { PetInCabinJourneyModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { getPassengerName, showLoader } from "../../../../shared/common";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ref } from "../../../../directives/ref";
import { PassengerSelection } from "../../../../component-models/extras/PassengerSelection";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import BookingData from "../../../../shared/BookingData";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const name = "ac-pet-in-cabin";

export interface Props {
    journey: PetInCabinJourneyModel;
    passengerOption: PetInCabinJourneyPassengerModel;
    passengerSelections: PassengerSelection[];
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        journey: host.journey,
        passengerOption: host.passengerOption,
        passengerSelections: host.passengerSelections,
        setExtrasModel: host.setExtrasModel,
    };

    const isSelected = () =>
        props.passengerSelections?.some(
            (ps) =>
                ps.journeyIndex === props.journey.JourneyIndex &&
                ps.paxIndex === props.passengerOption.PassengerNumber &&
                ps.selected,
        );

    const isPassengerDisabled = () => props.journey.JourneyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound;

    const isButtonDisabled = () =>
        props.passengerOption.IsDisabled ||
        (props.passengerSelections?.filter((ps) => ps.journeyIndex === props.journey.JourneyIndex && ps.selected)
            .length >= props.journey.MaxCount &&
            !isSelected());

    const tealiumLogSelection = (type: "add" | "remove") => {
        tealiumManager.logBinaryExtraSelected(
            "pet",
            props.passengerOption.Type === "ADT" ? "A" : "C",
            props.passengerOption.PassengerNumber,
            props.journey.JourneyIndex,
            type,
            props.passengerOption.Amount,
        );
    };

    const handlePetInCabinButtonClick = async () => {
        if (!props.passengerOption.SsrAddKey) {
            return;
        }

        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.petInCabin);
        const loader = showLoader({
            name: LOADER_CLASS_NAMES.Pet,
            container: parent.parentElement,
            noPlane: true,
        });

        const container = DomCrawlingHelper.getElemByClass(parent, CLASS_NAMES.errorContainer);

        const body = {
            "selectedJourneySsrs[0]": !isSelected()
                ? props.passengerOption.SsrAddKey
                : props.passengerOption.SsrDeleteKey,
        };

        const response = await bookingManager.postExtrasPageSsrUpdate<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasModel;
        }>(body, container, loader);

        props.setExtrasModel(response.ExtrasModel);

        tealiumLogSelection(!isSelected() ? "add" : "remove");

        host.dispatchEvent(
            new PetInCabinOptionClickedEvent({
                paxIndex: props.passengerOption.PassengerNumber,
                journeyIndex: props.journey.JourneyIndex,
                selected: !isSelected(),
            }),
        );
    };

    const bookingContext = useBookingContext();

    const bookingManager = useBookingManager();
    const tealiumManager = useExtrasTealiumManager();

    const root = useRef<HTMLDivElement>(undefined);

    const [passengerNames] = useReduxState("booking.passengerNames");

    const buttonTemplate = () => {
        const tempClassMap = classMap({
            "btn-boarding": true,
            "select-dependent-text": true,
            "selected": isSelected(),
            "disabled": isButtonDisabled(),
        });

        return html`
            <div class="col-xs-1-3">
                <div
                    class=${tempClassMap}
                    data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.BUTTON, {
                        p: props.passengerOption.PassengerNumber,
                        j: props.journey.JourneyIndex,
                    })}
                    @click=${handlePetInCabinButtonClick}
                >
                    <span>${i18next.t("V2-Add")}</span>
                    <span>${i18next.t("V2-Remove")}</span>
                </div>
            </div>
        `;
    };

    const labelTemplate = () => html`
        <div class="col-xs-2-3">
            <div class="pet-container">
                ${warningTemplate()}
                <div
                    class="extras-binary-name pet"
                    data-test-value=${props.passengerOption.Amount}
                    data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.BUTTON_CAPTION, {
                        p: props.passengerOption.PassengerNumber,
                        j: props.journey.JourneyIndex,
                    })}
                >
                    ${getPassengerName(passengerNames, props.passengerOption.PassengerNumber)}
                    ${props.passengerOption.FormattedAmount}
                </div>
            </div>
        </div>
    `;

    const warningTemplate = () =>
        !isSelected() && isButtonDisabled()
            ? html` <div class="no-more-pets-warning">${i18next.t("PetInCabin-Full")}</div> `
            : "";

    return html`
        <div
            class="inner-border-box"
            ref=${ref(root)}
            data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.PASSENGER_OPTION, {
                p: props.passengerOption.PassengerNumber,
                j: props.journey.JourneyIndex,
            })}
        >
            <div class=${isPassengerDisabled() ? "is-past" : ""}>
                <div class="row">${labelTemplate()} ${buttonTemplate()}</div>
            </div>
        </div>
    `;
};

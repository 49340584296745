import { getTestId, TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import { ACTION_NAMES } from "../../shared/commonConstants";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { useFlowContext } from "../../managers/useFlowContext";

export const name = "ac-breadcrumbs-booking";

export const Component: HauntedFunc<{}> = () => {
    // Helpers

    const showBreadcrumbs = () => {
        if (flowContext.isCheckinFlow) {
            return false;
        }

        return (
            [
                ACTION_NAMES.FLIGHT,
                ACTION_NAMES.PASSENGERS,
                ACTION_NAMES.BAGGAGE,
                ACTION_NAMES.SEATMAP,
                ACTION_NAMES.EXTRAS,
                ACTION_NAMES.REVIEW,
                ACTION_NAMES.PAYMENT,
                ACTION_NAMES.DC_REGISTER,
                ACTION_NAMES.DC_ITINERARY,
                ACTION_NAMES.GIFTCARD_ITINERARY,
                ACTION_NAMES.GIFTCARD_PAYMENT,
                ACTION_NAMES.GIFTCARD_SELECT,
            ].indexOf(flowContext.action) > -1
        );
    };

    const isFlightActive = () => flowContext.action === ACTION_NAMES.FLIGHT;

    const isGiftcardSelectActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_SELECT;

    const isGiftcardSelectPast = () => flowContext.action !== ACTION_NAMES.GIFTCARD_SELECT;

    const isGiftcardPaymentActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_PAYMENT;

    const isGiftcardPaymentPast = () => isGiftcardItineraryActive();

    const isGiftcardItineraryActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_ITINERARY;

    const isFlightPast = () => isExtrasActive() || isPaymentActive();

    const isExtrasPast = () => isPaymentActive();

    const isExtrasActive = () =>
        [
            ACTION_NAMES.PASSENGERS,
            ACTION_NAMES.BAGGAGE,
            ACTION_NAMES.SEATMAP,
            ACTION_NAMES.EXTRAS,
            ACTION_NAMES.REVIEW,
        ].indexOf(flowContext.action) > -1;

    const isPaymentActive = () => flowContext.action === ACTION_NAMES.PAYMENT;

    const isItineraryActive = () => flowContext.action === ACTION_NAMES.DC_ITINERARY;

    // Event handlers

    // Component

    const flowContext = useFlowContext();

    // Templates

    const giftcardPurchaseBreadcrumbsTemplate = () => {
        const selectClassMap = classMap({
            step: true,
            active: isGiftcardSelectActive(),
            past: isGiftcardSelectPast(),
        });
        const paymentClassMap = classMap({
            step: true,
            active: isGiftcardPaymentActive(),
            past: isGiftcardPaymentPast(),
        });
        const receiveClassMap = classMap({
            step: true,
            active: isGiftcardItineraryActive(),
        });

        return flowContext.isGiftcardPurchaseFlow
            ? html`
                  <div class="sidebar-breadcrumbs">
                      <div class=${selectClassMap}>
                          <span>1</span>
                          <span>${i18next.t("Gift-BreadcrumbsSelectGiftcard")}</span>
                      </div>
                      <div class=${paymentClassMap}>
                          <span>2</span>
                          <span>${i18next.t("Gift-BreadcrumbsPayment")}</span>
                      </div>
                      <div class=${receiveClassMap}>
                          <span>3</span>
                          <span>${i18next.t("Gift-BreadcrumbsReceiveGiftcard")}</span>
                      </div>
                      <div class="fly">
                          <span><i class="js-icon js-plane-x"></i></span>
                          <span>${i18next.t("V2-Fly")}</span>
                      </div>
                  </div>
              `
            : "";
    };

    const regularBookingFlowBreadcrumbsTemplate = () => {
        const flightClassMap = classMap({
            step: true,
            active: isFlightActive(),
            past: isFlightPast(),
        });
        const extrasClassMap = classMap({
            step: true,
            active: isExtrasActive(),
            past: isExtrasPast(),
        });
        const paymentClassMap = classMap({
            step: true,
            active: isPaymentActive(),
        });

        return !flowContext.isFlightlessPurchaseFlow
            ? html`
                  <div class="sidebar-breadcrumbs" data-test-id=${T.SIDEBAR.BOOKING_BREADCRUMBS}>
                      <div class=${flightClassMap} data-test-id=${getTestId(T.SIDEBAR.BOOKING_BREADCRUMB, { i: 0 })}>
                          <span>1</span>
                          <span>${i18next.t("V2-SelectFlight")}</span>
                      </div>
                      <div class=${extrasClassMap} data-test-id=${getTestId(T.SIDEBAR.BOOKING_BREADCRUMB, { i: 1 })}>
                          <span>2</span>
                          <span>${i18next.t("V2-Extras")}</span>
                      </div>
                      <div class=${paymentClassMap} data-test-id=${getTestId(T.SIDEBAR.BOOKING_BREADCRUMB, { i: 2 })}>
                          <span>3</span>
                          <span>${i18next.t("V2-Payment")}</span>
                      </div>
                      <div class="fly">
                          <span
                              ><i data-test-id=${T.SIDEBAR.BOOKING_BREADCRUMB_PLANE_ICON} class="js-icon js-plane-x"></i
                          ></span>
                          <span>${i18next.t("V2-Fly")}</span>
                      </div>
                  </div>
              `
            : "";
    };

    const standaloneDcBreadcrumbsTemplate = () => {
        const dataClassMap = classMap({
            step: true,
            active: !isPaymentActive() && !isItineraryActive(),
            past: isPaymentActive() || isItineraryActive(),
        });

        const paymentClassMap = classMap({
            step: true,
            active: isPaymentActive(),
            past: isItineraryActive(),
        });

        const itineraryClassMap = classMap({
            fly: true,
            active: isItineraryActive(),
        });

        return flowContext.isDcStandaloneFlow
            ? html`
                  <div class="sidebar-breadcrumbs">
                      <div class=${dataClassMap}>
                          <span>1</span>
                          <span>${i18next.t("V2-EnterDataForDc")}</span>
                      </div>
                      <div class=${paymentClassMap}>
                          <span>2</span>
                          <span>${i18next.t("DcStandalone-Pay")}</span>
                      </div>
                      <div class=${itineraryClassMap}>
                          <span><i class="js-icon js-plane-x"></i></span>
                          <span>${i18next.t("V2-Fly")}</span>
                      </div>
                  </div>
              `
            : "";
    };

    const mainClassMap = classMap({
        "hidden-sm-down": !flowContext.isFlightlessPurchaseFlow,
    });

    return !flowContext.isCheckinFlow && showBreadcrumbs()
        ? html`
              <div class=${mainClassMap}>
                  <!-- /Views/V2/PartialViews/Sidebar/_SidebarBreadcrumbs.cshtml -->
                  ${giftcardPurchaseBreadcrumbsTemplate()} ${regularBookingFlowBreadcrumbsTemplate()}
                  ${standaloneDcBreadcrumbsTemplate()}
              </div>
          `
        : html``;
};

import { html } from "haunted";
import i18next from "i18next";
import { ProfileSearchWidgetViewModel } from "../../component-models/profile/ProfileSearchWidgetViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: ProfileSearchWidgetViewModel;
}

export const useProfileSearchWidget = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const searchFlightsForStaffMemberTemplate = () =>
        userContext.isStaff
            ? html`
                  <div class="search-flights-button-container">
                      <a class="rounded-primary-btn" href="/" target="_blank">${i18next.t("Buscar vuelos")}</a>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="profile-widget profile-search-widget">
            <div class="search-widget-container">
                <div class="search-widget-content">
                    ${searchFlightsForStaffMemberTemplate()}
                    <div class="sw-actual-content">
                        <div class="swc-main">
                            <i class="js-icon-mp js-mp-search"></i>
                            <span>${i18next.t("MP-SearchSmart")}</span>
                        </div>
                        <div class="swc-info">
                            ${i18next.t("MP-SearchInfo")}
                            <a href=${props.model.SmartSearchUrl} target="_blank">
                                ${i18next.t("MP-SearchFlights")} <i class="js-icon js-circle-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { html, useCallback, useState } from "haunted";
import i18next from "i18next";
import { BundleSsrViewModel, allPossibleBundleSsrs } from "../../component-models/AllPossibleBundleSsrs";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { CustomizedBundle } from "../../component-models/flight/BundleOffersV2Model";
import classNames from "classnames";
import {
    AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES,
    AllSeatsInBundlePlaceholder,
    FLEXI_FEE_CODES,
    ForcedFlexiFeePlaceholderCode,
} from "../../shared/commonConstants";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { bundleSsrHelper } from "../../component-helpers/flight/BundleSsrHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export interface Props {
    bundle: CustomizedBundle;
    context: FlightPageContext;
    isSelected: boolean;
    journeyIndex: number;
}

export const useBundleSsrItems = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const { hasCommonElement, isSsrInBundle, isSsrFreePriorityBoarding } = bundleSsrHelper();

    const [listOpen, setIsAllListShown] = useState<boolean>(false);

    const isPeruCompra = useMemo(
        () =>
            bookingContext?.isPeruCompraBooking || userContext?.peruCompra.isAdmin || userContext?.peruCompra.isMember,
        [bookingContext, userContext],
    );

    const availableSsrs = useMemo(() => {
        const allPossibleSsrs = allPossibleBundleSsrs(
            appContext.isFeatureSwitchActive("ForceFlexiFeeForAll"),
            props.context.bundleState?.concatenateBags,
        );

        return props.context.ssrIdsInOrder?.length
            ? props.context.ssrIdsInOrder.map((id) => allPossibleSsrs.find((ssr) => ssr.Id === id))
            : allPossibleSsrs;
    }, [props.context.bundleState?.concatenateBags, props.context.ssrIdsInOrder, appContext]);

    const isSsrIncluded = useCallback(
        (ssrCodes: string[]) =>
            isSsrInBundle(
                props.bundle,
                ssrCodes,
                userContext?.bancoEstado.category,
                appContext.isFeatureSwitchActive("PeruCompraFreeAirportCheckin") && isPeruCompra,
            ),
        [props.bundle, userContext?.bancoEstado.category, appContext, isPeruCompra],
    );

    // HELPERS

    const getDataTestId = (bundleSsr: BundleSsrViewModel, isName = false) =>
        getTestId(isName ? T.BUNDLE.SSR_ITEM_NAME : T.BUNDLE.SSR_ITEM, {
            j: props.journeyIndex,
            c: `${props.bundle.BundleType.toLowerCase()}|${bundleSsr.SsrCodes.join("|")}`,
        });

    const getDataTestValue = (bundleSsr: BundleSsrViewModel) => (isSsrIncluded(bundleSsr.SsrCodes) ? "1" : "0");

    const isSsrAllSeats = (ssrCodes: string[]) => ssrCodes.includes(AllSeatsInBundlePlaceholder);

    const isSsrFlexiFee = (ssrCodes: string[]) =>
        hasCommonElement(ssrCodes, [...ForcedFlexiFeePlaceholderCode, ...FLEXI_FEE_CODES]);

    const isSsrFrequentFlyerNumberFee = (ssrCodes: string[]) =>
        hasCommonElement(ssrCodes, [...AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES]);

    // TODO ChileCompra - implement this
    const isChileCompra = () => false;

    const isPeruCompraFlexiFee = (ssrCodes: string[]) => isPeruCompra && isSsrFlexiFee(ssrCodes);

    const showSsrItem = (bundleSsr: BundleSsrViewModel) => {
        if (!appContext.isFeatureSwitchActive("MileageAccrual") && isSsrFrequentFlyerNumberFee(bundleSsr.SsrCodes)) {
            return false;
        }

        if (
            (isChileCompra() || isPeruCompra) &&
            appContext.isFeatureSwitchActive("MileageAccrual") &&
            isSsrFrequentFlyerNumberFee(bundleSsr.SsrCodes)
        ) {
            return false;
        }

        if (bundleSsr.IsFee && props.context.bundleState?.bundlesMode !== "PerLeg") {
            return false;
        }

        return true;
    };

    // TEMPLATES

    const ssrsTemplate = () =>
        props.bundle.BundleType
            ? html`
                  <ul
                      data-test-id=${getTestId(T.BUNDLE.SSRS, {
                          j: props.journeyIndex,
                          c: props.bundle.BundleType.toLowerCase(),
                      })}
                  >
                      ${availableSsrs.map(nonSelectedDetailsTemplate)}
                  </ul>
              `
            : "";

    const mobileTemplate = () =>
        props.bundle.BundleType
            ? html`
                  <div
                      class=${classNames("extra-line-container", {
                          hidden: !listOpen || props.bundle.BundleType === "Full",
                      })}
                      @click=${() => setIsAllListShown(false)}
                  >
                      <div class="extra-line-text">${i18next.t("Mostrar menos beneficios")}</div>
                      <i class="js-icon-bundle js-bundle-circle-x"></i>
                  </div>
                  ${ssrsTemplate()}
                  <div
                      class=${classNames("extra-line-container", {
                          hidden: listOpen || props.bundle.BundleType === "Full",
                      })}
                      @click=${() => setIsAllListShown(true)}
                  >
                      <div class="extra-line-text">${i18next.t("Ver más beneficios")}</div>
                      <i class="js-icon-bundle js-bundle-circle-plus"></i>
                  </div>
              `
            : "";

    const tooltipTextTemplate = (bundleSsr: BundleSsrViewModel) => html`
        <div
            data-test-id=${getTestId(T.BUNDLE.TOOLTIP_INFO, {
                j: props.journeyIndex,
                c: bundleSsr.SsrCodes.join("|"),
            })}
        >
            ${isPeruCompraFlexiFee(bundleSsr.SsrCodes)
                ? isSsrIncluded(bundleSsr.SsrCodes)
                    ? i18next.t(
                          "En JetSmart, puedes cambiar fecha vuelo, ruta y nombre libre de penalidad, pagando solamente diferencia tarifaria, si es que la hubiera. Los cambios son por pasajero y tramo, vía plataforma Perú Compras. No se permitten cambios en el aeropuerto.",
                      )
                    : i18next.t(
                          "En JetSmart, puedes cambiar fecha vuelo, ruta y nombre pagando un cargo. Los cambios son por pasajero y tramo, vía plataforma Perú Compras. No se permitten cambios en el aeropuerto.",
                      )
                : bundleSsr.TooltipInfo}
        </div>
    `;

    const tooltipTitleTemplate = (bundleSsr: BundleSsrViewModel) => html`
        <h3
            data-test-id=${getTestId(T.BUNDLE.TOOLTIP_TITLE, {
                j: props.journeyIndex,
                c: bundleSsr.SsrCodes.join("|"),
            })}
        >
            ${isPeruCompraFlexiFee(bundleSsr.SsrCodes) ? i18next.t("Cambios") : bundleSsr.SsrName}
        </h3>
    `;

    const tooltipTemplate = (bundleSsr: BundleSsrViewModel) =>
        bundleSsr.TooltipInfo
            ? html`
                  <div
                      class="bundle-tooltip"
                      data-test-id=${getTestId(T.BUNDLE.TOOLTIP, {
                          j: props.journeyIndex,
                          c: bundleSsr.SsrCodes.join("|"),
                      })}
                  >
                      <i class="${bundleSsr.IconName}"></i>
                      <div class="bundle-tooltip-text">
                          ${tooltipTitleTemplate(bundleSsr)} ${tooltipTextTemplate(bundleSsr)}
                      </div>
                  </div>
              `
            : "";

    const seatSsrTemplate = (bundleSsr: BundleSsrViewModel) =>
        isSsrAllSeats(bundleSsr.SsrCodes)
            ? html`
                  <div
                      class="ssr-line-name italic"
                      data-test-id=${getDataTestId(bundleSsr, true)}
                      data-test-value=${getDataTestValue(bundleSsr)}
                  >
                      ${bundleSsr.SsrName}
                      <span class="allseats-extra">${i18next.t("o donde quieras")}</span>
                  </div>
              `
            : "";

    const noCostTemplate = (bundleSsr: BundleSsrViewModel) =>
        isSsrFlexiFee(bundleSsr.SsrCodes) ||
        isSsrFreePriorityBoarding(userContext?.bancoEstado.category, bundleSsr.SsrCodes)
            ? html` <span> ${`-${i18next.t("costo $0")}!`} </span> `
            : "";

    const nonSeatSsrTemplate = (bundleSsr: BundleSsrViewModel) =>
        !isSsrAllSeats(bundleSsr.SsrCodes) &&
        !isPeruCompraFlexiFee(bundleSsr.SsrCodes) &&
        !isSsrFrequentFlyerNumberFee(bundleSsr.SsrCodes)
            ? html`
                  <div
                      class="ssr-line-name"
                      data-test-id=${getDataTestId(bundleSsr, true)}
                      data-test-value=${getDataTestValue(bundleSsr)}
                  >
                      ${bundleSsr.SsrName} ${noCostTemplate(bundleSsr)}
                  </div>
              `
            : "";

    const peruCompraFlexiTemplate = (bundleSsr: BundleSsrViewModel) =>
        isPeruCompraFlexiFee(bundleSsr.SsrCodes)
            ? html`
                  <div
                      class="ssr-line-name"
                      data-test-id=${getDataTestId(bundleSsr, true)}
                      data-test-value=${getDataTestValue(bundleSsr)}
                  >
                      ${isSsrIncluded(bundleSsr.SsrCodes)
                          ? i18next.t("Cambio sin cargo")
                          : i18next.t("Cambio con cargo")}
                  </div>
              `
            : "";

    const frequentFlyerTemplate = (bundleSsr: BundleSsrViewModel) =>
        isSsrFrequentFlyerNumberFee(bundleSsr.SsrCodes)
            ? html`
                  <div
                      class="ssr-line-name"
                      data-test-id=${getDataTestId(bundleSsr, true)}
                      data-test-value=${getDataTestValue(bundleSsr)}
                  >
                      ${unsafeHTML(
                          i18next.t("Acumulación de Millas AAdvantage {{-reg}}", {
                              reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                          }),
                      )}
                  </div>
              `
            : "";

    const tickIconTemplate = (bundleSsr: BundleSsrViewModel, index: number) =>
        isSsrIncluded(bundleSsr.SsrCodes) || isPeruCompraFlexiFee(bundleSsr.SsrCodes)
            ? html`
                  <i
                      class="js-icon js-flight-tick"
                      data-test-id=${getTestId(T.BUNDLE.SSR_TICK, { j: props.journeyIndex, i: index })}
                  ></i>
              `
            : "";

    const xIconTemplate = (bundleSsr: BundleSsrViewModel) =>
        !isSsrIncluded(bundleSsr.SsrCodes) && !isPeruCompraFlexiFee(bundleSsr.SsrCodes)
            ? html` <i class="js-icon-bundle js-bundle-circle-x-full"></i> `
            : "";

    const iconTemplate = (bundleSsr: BundleSsrViewModel, index: number) =>
        html`${tickIconTemplate(bundleSsr, index)} ${xIconTemplate(bundleSsr)}`;

    const selectedDetailsTemplate = (bundleSsr: BundleSsrViewModel, index: number) =>
        showSsrItem(bundleSsr)
            ? html`
                  <li data-test-id=${getDataTestId(bundleSsr)} data-test-value=${getDataTestValue(bundleSsr)}>
                      <div class="ssr-line">
                          <i class=${bundleSsr.IconName}></i>
                          ${seatSsrTemplate(bundleSsr)} ${nonSeatSsrTemplate(bundleSsr)}
                          ${peruCompraFlexiTemplate(bundleSsr)} ${frequentFlyerTemplate(bundleSsr)}
                      </div>
                      ${iconTemplate(bundleSsr, index)}
                  </li>
              `
            : "";

    const nonSelectedDetailsTemplate = (bundleSsr: BundleSsrViewModel, index: number) => {
        const tempClassNames = classNames({
            "hidden-xs": !isSsrIncluded(bundleSsr.SsrCodes) && !listOpen && !isPeruCompraFlexiFee(bundleSsr.SsrCodes),
        });

        return showSsrItem(bundleSsr)
            ? html`
                  <li
                      class=${tempClassNames}
                      data-test-id=${getDataTestId(bundleSsr)}
                      data-test-value=${getDataTestValue(bundleSsr)}
                  >
                      <div class="ssr-line">
                          <i class="${bundleSsr.IconName}"></i>
                          ${seatSsrTemplate(bundleSsr)} ${nonSeatSsrTemplate(bundleSsr)}
                          ${peruCompraFlexiTemplate(bundleSsr)} ${frequentFlyerTemplate(bundleSsr)}
                          ${tooltipTemplate(bundleSsr)}
                      </div>
                      ${iconTemplate(bundleSsr, index)}
                  </li>
              `
            : "";
    };

    const unselectedTemplate = () =>
        !props.isSelected
            ? html`
                  <div class="hidden-xs">${ssrsTemplate()}</div>
                  <div class="hidden-sm-up">${mobileTemplate()}</div>
              `
            : "";

    const selectedTemplate = () =>
        props.isSelected
            ? html`
                  <ul class=${classNames("hidden-xs selected-bundle", { taller: availableSsrs.length > 8 })}>
                      ${availableSsrs.map(selectedDetailsTemplate)}
                  </ul>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="bundle-ssr-items">${selectedTemplate()} ${unselectedTemplate()}</div>
    `;

    return { htmlTemplate };
};

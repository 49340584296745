import i18next from "i18next";
import { html } from "lit-html";
import { useBoardingPassImageRepository } from "../boarding-passes/useBoardingPassImageRepository";
import { ApiBoardingPassesViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassesViewModel";
import classNames from "classnames";

export interface BoardingPassesDownloadLinkProps {
    model: ApiBoardingPassesViewModel;
    pushDown: boolean;
    showModal: boolean;
}

export const useBoardingPassesDownloadLink = (props: BoardingPassesDownloadLinkProps) => {
    const { downloadModal, handleDownloadAllIntent } = useBoardingPassImageRepository({
        areCardsDisplayed: false,
        model: props.model,
        showModal: props.showModal,
    });

    const htmlTemplate = () => {
        const tempClassNames = classNames("multi-download-passengers", {
            "push-down": props.pushDown,
        });

        return html`
            <div class=${tempClassNames}>
                ${i18next.t("Do you want to download boarding passes for all passengers? Click")}
                <a @click=${handleDownloadAllIntent}>${i18next.t("here")}</a>
            </div>
            ${downloadModal.htmlTemplate()}
        `;
    };

    return { htmlTemplate };
};

import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { ACTION_NAMES, CONTROLLER_NAMES } from "../../shared/commonConstants";
import i18next from "i18next";
import { ROUTES } from "../../shared/apiRoutes";
import classNames from "classnames";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-breadcrumbs";

export const Component: HauntedFunc<{}> = () => {
    const [userContext] = useReduxState("userContext");
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [isBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    // Helpers

    const isFlightActive = () => flowContext.action === ACTION_NAMES.FLIGHT;

    const isGiftcardSelectActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_SELECT;

    const isGiftcardPaymentActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_PAYMENT;

    const isGiftcardItineraryActive = () =>
        flowContext.action === ACTION_NAMES.GIFTCARD_ITINERARY ||
        flowContext.action === ACTION_NAMES.GIFTCARD_POSTCOMMIT;

    const isSpaActive = () =>
        [
            ACTION_NAMES.PASSENGERS,
            ACTION_NAMES.BAGGAGE,
            ACTION_NAMES.SEATMAP,
            ACTION_NAMES.CHECKIN_SEATMAP,
            ACTION_NAMES.EXTRAS,
            ACTION_NAMES.REVIEW,
        ].indexOf(flowContext.action) > -1;

    const isPaymentActive = () => flowContext.action === ACTION_NAMES.PAYMENT;

    const isTransactionActive = () => flowContext.action === ACTION_NAMES.TRANSACTION;

    const isCheckinBoardingCardActive = () => flowContext.action === ACTION_NAMES.PRINT;

    const showCheckinBreadcrumbs = () =>
        flowContext.isCheckinFlow &&
        ![ACTION_NAMES.PROFILE, ACTION_NAMES.PASSENGERS, ACTION_NAMES.ADDITIONAL_INFO, ACTION_NAMES.PRINT].includes(
            flowContext.action,
        );

    const showBreadcrumbs = () =>
        !flowContext.isCheckinFlow &&
        ![CONTROLLER_NAMES.FLIGHT_MOVE, CONTROLLER_NAMES.GIFTCARD, CONTROLLER_NAMES.CUG].includes(
            flowContext.controller,
        ) &&
        ![
            ACTION_NAMES.CHECKIN_SEATMAP,
            ACTION_NAMES.FORGOT_PASSWORD,
            ACTION_NAMES.INDEX,
            ACTION_NAMES.ITINERARY,
            ACTION_NAMES.LOGIN,
            ACTION_NAMES.MOBILE_MENU,
            ACTION_NAMES.PASSWORD_RESET_SUCCESS,
            ACTION_NAMES.POST_REGISTER,
            ACTION_NAMES.PROFILE,
            ACTION_NAMES.REGISTER,
            ACTION_NAMES.REGISTERBANCOESTADO,
            ACTION_NAMES.REVIEW,
            ACTION_NAMES.SELECT_DATE,
            ACTION_NAMES.STAFF_BENEFIT,
            ACTION_NAMES.STAFF_FAMILY_MEMBERS,
            ACTION_NAMES.STANDALONE_DC_ITINERARY,
            ACTION_NAMES.TRANSACTION,
            ACTION_NAMES.TUTORIAL,
        ].includes(flowContext.action);

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const showBancoEstadoRibbon = () => {
        return (
            !flowContext.isCheckinFlow &&
            isCurrentBancoEstadoCategory([1, 2, 3, 4, 7]) &&
            [
                ACTION_NAMES.FLIGHT,
                ACTION_NAMES.PASSENGERS,
                ACTION_NAMES.BAGGAGE,
                ACTION_NAMES.SEATMAP,
                ACTION_NAMES.EXTRAS,
                ACTION_NAMES.REVIEW,
                ACTION_NAMES.PAYMENT,
            ].indexOf(flowContext.action) > -1
        );
    };

    // Event handlers

    const handleFlightClick = () => {
        if (isFlightActive()) {
            return;
        }

        window.location.href = ROUTES.PageFlight;
    };

    const handleExtrasClick = () => {
        if (!isPaymentActive()) {
            return;
        }

        window.location.href = ROUTES.PagePassengers;
    };

    // Templates

    const giftcardPurchaseTemplate = () => {
        const selectClassMap = classMap({
            "booking-step": true,
            "step-one": true,
            "active": isGiftcardSelectActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isGiftcardPaymentActive(),
        });

        const postcommitClassMap = classMap({
            "booking-step": true,
            "step-three": true,
            "active": isGiftcardItineraryActive(),
        });

        return flowContext.isGiftcardPurchaseFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-3">
                          <div class="breadcrumb-section">
                              <div class=${selectClassMap}>1</div>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-center">
                          <div class="breadcrumb-section text-center">
                              <div class=${paymentClassMap}>2</div>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-right">
                          <div class="breadcrumb-section text-right">
                              <div class=${postcommitClassMap}>3</div>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const standAloneDcTemplate = () => {
        const dataClassMap = classMap({
            "booking-step": true,
            "step-one": true,
            "active": !isPaymentActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isPaymentActive(),
        });

        return flowContext.isDcStandaloneFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-2">
                          <div class="breadcrumb-section">
                              <div class=${dataClassMap}>1</div>
                              <span class="visible-md-up step-one">${i18next.t("V2-EnterDataForDc")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-2 text-right">
                          <div class="breadcrumb-section text-right">
                              <div class=${paymentClassMap}>2</div>
                              <span class="visible-md-up">${i18next.t("V2-Payment")}</span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const regularBookingFlowBreadcrumbsTemplate = () => {
        const flightClassMap = classMap({
            "booking-step": true,
            "step-one": true,
            "active": isFlightActive(),
        });

        const spaClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isSpaActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-three": true,
            "active": isPaymentActive(),
        });

        return !flowContext.isFlightlessPurchaseFlow && !flowContext.isCheckinFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-3">
                          <div class="breadcrumb-section" @click=${handleFlightClick}>
                              <div class=${flightClassMap}>1</div>
                              <span class="visible-md-up step-one">${i18next.t("V2-SelectFlight")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-center">
                          <div class="breadcrumb-section text-center" @click=${handleExtrasClick}>
                              <div class=${spaClassMap}>2</div>
                              <span class="visible-md-up">${i18next.t("V2-Extras")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-right">
                          <div class="breadcrumb-section text-right">
                              <div class=${paymentClassMap}>3</div>
                              <span class="visible-md-up">${i18next.t("V2-Payment")}</span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const checkinFlowBreadcrumbsTemplate = () => {
        const spaClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isSpaActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isPaymentActive() || isTransactionActive(),
        });

        const additionalInfoClassMap = classMap({
            "booking-step": true,
            "step-three": true,
            "active": flowContext.action === ACTION_NAMES.ADDITIONAL_INFO,
        });

        const boardingCardClassMap = classMap({
            "booking-step": true,
            "step-three": true,
            "active": isCheckinBoardingCardActive(),
        });

        return flowContext.isCheckinFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-4">
                          <div class="breadcrumb-section" data-test-id="checkin-breadcrumbs">
                              <div class=${spaClassMap}>1</div>
                              <span class="visible-sm-up step-one">${i18next.t("V2-CheckinExtras")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-4">
                          <div class="breadcrumb-section">
                              <div class=${paymentClassMap}>2</div>
                              <span class="visible-sm-up">${i18next.t("V2-CheckinPayment")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-4">
                          <div class="breadcrumb-section">
                              <div class=${additionalInfoClassMap}>3</div>
                              <span class="visible-sm-up">${i18next.t("V2-CheckinCheckin")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-4">
                          <div class="breadcrumb-section">
                              <div class=${boardingCardClassMap}>4</div>
                              <span class="visible-sm-up">${i18next.t("V2-CheckinBoardingCard")}</span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const wrapperClassMap = classNames(
        "relative z-0 mx-auto h-[60px] max-w-[calc(100%-130px)] bg-[#f7f7f7] common-transition",
        "md:mx-0 md:h-[80px] md:max-w-full",
        "sm:z-40",
        {
            "top-[75px]": isBancoEstadoBarOpened,
            "top-[20px] md:top-0": showBancoEstadoRibbon(),
            "banco-estado-breadcrumbs": bookingContext.isBancoEstadoBooking && !flowContext.isCheckinFlow,
            "push-down": bookingContext.isBancoEstadoBooking && !flowContext.isCheckinFlow,
            "hidden-md-up": showBreadcrumbs(),
        },
    );

    return showCheckinBreadcrumbs() || showBreadcrumbs()
        ? html`
              <div class=${wrapperClassMap}>
                  <div class="breadcrumbs-bg">
                      <div class="breadcrumbs"></div>
                      ${regularBookingFlowBreadcrumbsTemplate()} ${checkinFlowBreadcrumbsTemplate()}
                      ${standAloneDcTemplate()} ${giftcardPurchaseTemplate()}
                  </div>
              </div>
          `
        : html``;
};

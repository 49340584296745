import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BACKPACK_AND_CABIN_BAG_IMG, BACKPACK_IMG, CHECKED_BAGGAGE_IMG } from "../../spa-container";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useBaggagePriceBreakdown } from "./useBaggagePriceBreakdown";
import { usePerBookingQuantityButtons } from "./usePerBookingQuantityButtons";
import { useBaggageIllustrations } from "../common/useBaggageIllustrations";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
}

export const usePerBookingPaidOption = (props: Props) => {
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const paidBagSelected = useMemo(
        () =>
            props.baggageSection.perBooking.quantity > 0 && props.baggageSection.state.perJourneyPerPaxState !== "open",
        [props.baggageSection.perBooking.quantity, props.baggageSection.state.perJourneyPerPaxState],
    );

    const priceBreakdown = useBaggagePriceBreakdown({
        context: props.context,
        isShown: paidBagSelected,
        staffBaggage: props.staffBaggage,
    });

    const quantityButtons = usePerBookingQuantityButtons({
        baggageSection: props.baggageSection,
        isMobile: false,
    });

    const { backpackTooltipTemplate, regularBagTooltipTemplate, zippedBagTooltipTemplate } = useBaggageIllustrations({
        baggageSection: props.baggageSection,
        context: props.context,
    });

    const noBagSelected = useMemo(
        () =>
            !props.pageState.isLoading &&
            props.baggageSection.perBooking.quantity === 0 &&
            props.baggageSection.state.journeys.every((journey) =>
                journey.passengers.every((passenger) => passenger.hasSelected),
            ),
        [props.baggageSection.perBooking.quantity, props.baggageSection.state.journeys, props.pageState.isLoading],
    );

    const showOriginalPrice = useMemo(
        () =>
            flowContext.isBookingFlow &&
            props.context.isDcBooking &&
            props.baggageSection.perBooking.getLowestNextPrice({
                contextJourneys: props.context.journeys,
                minimum: true,
                original: true,
                stateJourneys: props.baggageSection.state.journeys,
            }) &&
            !paidBagSelected,
        [
            flowContext.isBookingFlow,
            paidBagSelected,
            props.baggageSection.perBooking.getLowestNextPrice,
            props.baggageSection.state.journeys,
            props.context.isDcBooking,
            props.context.journeys,
        ],
    );

    const unformattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: isMinimum,
            original: isOriginal,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const formattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        formatNumber({ amount: unformattedLowestNextPrice(isOriginal, isMinimum), leadingSign: true });

    const ribbonText = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? i18next.t("¡El más vendido!")
            : props.baggageSection.bagType === "CheckedBaggage"
              ? i18next.t("¡Recomendado para ti!")
              : "";

    const handleAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.baggageSection.state.perJourneyPerPaxState !== "open" && !paidBagSelected) {
            props.baggageSection.handlers.add();
        }
    };

    const priceTemplate = () =>
        props.context.journeys.every(
            (j) =>
                (props.baggageSection.bagType === "CabinBaggage" && j.cabinBundleType === "None") ||
                (props.baggageSection.bagType === "CheckedBaggage" && j.checkedBundleType === "None"),
        )
            ? html` ${noBundleOriginalPriceTemplate()} ${noBundlePriceTemplate()} `
            : "";

    const noBundleOriginalPriceTemplate = () =>
        showOriginalPrice
            ? html`
                  <div class="b2-per-booking-next-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                          })}
                      >
                          ${formattedLowestNextPrice(true, true)}
                      </span>
                  </div>
              `
            : "";

    const noBundlePriceTemplate = () =>
        !paidBagSelected
            ? html`
                  <div class="b2-per-booking-next-price">
                      ${i18next.t("Desde")}
                      <span
                          class="b2-amount"
                          data-test-value=${unformattedLowestNextPrice(false, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                          })}
                      >
                          ${formattedLowestNextPrice(false, true)}
                      </span>
                  </div>
              `
            : "";

    const addCabinButtonDisabledTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage" && paidBagSelected
            ? html` <button disabled class="b2-primary-button">${i18next.t("Agregado")}</button>
                  ${props.baggageSection.bagType === "CabinBaggage" ? priceBreakdown.htmlTemplate() : ""}`
            : "";

    const addCabinButtonEnabledTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage" && !paidBagSelected
            ? html`
                  <button
                      class="b2-primary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                          c: props.baggageSection.bagType,
                      })}
                      @click=${handleAdd}
                  >
                      ${i18next.t("Agregar")}
                  </button>
              `
            : "";

    const addCheckedButtonEnabledTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage" && (noBagSelected || !paidBagSelected)
            ? html`
                  <button
                      class="b2-primary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                          c: props.baggageSection.bagType,
                      })}
                      @click=${handleAdd}
                  >
                      ${i18next.t("Agregar")}
                  </button>
              `
            : "";

    const addCheckedButtonDisabledTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage" && paidBagSelected
            ? html`
                  <div class="flex w-full items-center justify-around">
                      <button disabled class="b2-primary-button">${i18next.t("Agregado")}</button>
                      ${quantityButtons.htmlTemplate()}
                  </div>

                  ${addAnotherCheckedButtonTemplate()}
              `
            : "";

    const addAnotherCheckedButtonTemplate = () => {
        const addClassMap = classNames("b2-add-another", {
            "padded-top": props.context.isDcBooking && flowContext.isBookingFlow,
        });

        return props.baggageSection.perBooking.isAddAvailable
            ? html`
                  <div class=${addClassMap}>
                      ${i18next.t("Agrega otro desde")}
                      <span class="relative">
                          ${checkedOriginalPriceTemplate()}
                          <span
                              class="b2-amount"
                              data-test-value=${unformattedLowestNextPrice(false, false)}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, {
                                  c: props.baggageSection.bagType,
                              })}
                          >
                              ${formattedLowestNextPrice(false, false)}
                          </span>
                      </span>
                  </div>
              `
            : "";
    };

    const checkedOriginalPriceTemplate = () =>
        props.context.isDcBooking && flowContext.isBookingFlow
            ? html`
                  <div
                      class="b2-per-booking-next-price original-price non-breaking-price"
                      data-test-value=${unformattedLowestNextPrice(true, false)}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_NEXT_PRICE, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      ${formattedLowestNextPrice(true, false)}
                  </div>
              `
            : "";

    const cabinIllustrationTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <div class="flex w-full items-center justify-center">
                      <img class="b2-paid-cabin-img" src=${BACKPACK_IMG} />
                      <img class="b2-paid-cabin-img" src=${BACKPACK_AND_CABIN_BAG_IMG} />
                  </div>
                  <div class="b2-weight-container-dt">
                      <div
                          class="b2-weight"
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                              c: props.baggageSection.bagType,
                          })}
                      >
                          10kg.
                      </div>
                      <div
                          class="b2-weight"
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                              c: props.baggageSection.bagType,
                          })}
                      >
                          10kg.
                      </div>
                  </div>
                  <div class="b2-dimension-container-dt">
                      <div class="b2-dimensions">35cm &times; 25cm &times; 45cm ${backpackTooltipTemplate()}</div>
                      <div class="b2-dimensions">35cm &times; 25cm &times; 55cm ${regularBagTooltipTemplate()}</div>
                  </div>
                  <div
                      class="b2-bag-names-container "
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_NAME, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      <div class="b2-bag-names">
                          ${unsafeHTML(i18next.t("Mochila o artículo {{-newLine}} personal", { newLine: "<br />" }))}
                      </div>
                      <div class="b2-bag-names">
                          ${unsafeHTML(i18next.t("Equipaje de {{-newLine}} mano", { newLine: "<br />" }))}
                      </div>
                  </div>
              `
            : "";

    const checkedIllustrationTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <img
                      class="b2-paid-checked-img"
                      src=${CHECKED_BAGGAGE_IMG}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_IMAGE, {
                          c: props.baggageSection.bagType,
                      })}
                  />
                  <div
                      class="b2-weight"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      23kg.
                  </div>
                  <div
                      class="b2-dimensions"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_DIMENSIONS, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      158cm ${i18next.t("lineales")} ${zippedBagTooltipTemplate()}
                  </div>
                  <div
                      class="b2-bag-name"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_NAME, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      ${props.baggageSection.bagType === "CheckedBaggage"
                          ? i18next.t("Equipaje facturado")
                          : i18next.t("Equipaje sobredimensionado")}
                  </div>
              `
            : "";

    const footerTemplate = () => {
        const tempClassMap = classNames("b2-paid-option-btn-container b2-openable", {
            "taller": props.baggageSection.bagType === "CheckedBaggage",
            "b2-opened": props.baggageSection.state.perJourneyPerPaxState !== "open",
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_BUTTON_CONTAINER, {
                    c: props.baggageSection.bagType,
                })}
            >
                ${priceTemplate()} ${addCabinButtonEnabledTemplate()} ${addCabinButtonDisabledTemplate()}
                ${addCheckedButtonEnabledTemplate()} ${addCheckedButtonDisabledTemplate()}
            </div>
        `;
    };

    const illustrationTemplate = () => {
        const tempClassMap = classNames("b2-illustration", {
            taller: props.baggageSection.bagType === "CheckedBaggage",
        });

        return html`
            <div class=${tempClassMap}>
                <div
                    class="b2-illustration-ribbon"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                        c: props.baggageSection.bagType,
                    })}
                >
                    ${ribbonText()}
                </div>
                ${cabinIllustrationTemplate()} ${checkedIllustrationTemplate()}
            </div>
        `;
    };

    const containerClassMap = classNames("b2-paid-bag-option", {
        "selected": paidBagSelected,
        "per-pax": props.baggageSection.state.perJourneyPerPaxState === "open",
        "hoverable": props.baggageSection.state.perJourneyPerPaxState !== "open" && !paidBagSelected,
    });

    const htmlTemplate = () => html`
        <div
            class=${containerClassMap}
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION, { c: props.baggageSection.bagType })}
            @click=${handleAdd}
        >
            ${illustrationTemplate()} ${footerTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

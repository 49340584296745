import { SelectOption } from "../../shared/common";
import { commonDebug } from "../../bootstrap";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { gatewayGetDropdownTexts } from "../../component-helpers/payment/InstallmentsMercadoPagoHelper";
import { useInstallmentsCommonTemplate } from "./useInstallmentsCommonTemplate";
import { ApiMercadoPagoInstallmentOption } from "../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useAppContext } from "../../managers/useAppContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";

export interface Props {
    isActive: boolean;
    model: PaymentPageViewModel;
    selectedInstallmentsNumber: number;
    getApiResponse: () => Promise<ApiMercadoPagoInstallmentOption[]>;
    handleInstallmentChange: (selectedInstallmentNumber: number, newTotal: number) => void;
    handleInstallmentInfoChange: (merchantAccountId: string, paymentMethodOptionId: string) => void;
    tealiumLogMercadoPagoInstallmentChange: (value: number) => void;
}

// DEVNOTE A "branch" is an object in the API response. They have "branch ids" with payment method codes.
// Branches contain the selectable installment numbers for different route types (domestic, inter, both).
// We have to filter branches for the selected card payment method code.
// Branches have "agreements" which have "category ids".
// We need to filter branches, and so category ids are equipped with pre- and postfixes.
// For example "base" is a default, while "dom" is for domestic flights only.
// This is very procedural, as requested by JS.
export const useInstallmentsMercadoPagoGateway = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const { getRelevantMercadoPagoGatewayApiOption, isInstallmentsDisabledForFlow } = paymentHelper();

    const [cardData] = useReduxState("payment.cardData");

    const [dropdownOptions, setDropdownOptions] = useState<SelectOption[]>([]);

    const installmentsCommonTemplate = useInstallmentsCommonTemplate({
        options: dropdownOptions,
        selectedInstallmentsNumber: props.selectedInstallmentsNumber,
        handleNumberOfInstallmentsChange: (e, numberOfInstallments) =>
            handleNumberOfInstallmentsChange(e, numberOfInstallments),
    });

    // HELPERS

    const handleApiResponse = (apiOptions: ApiMercadoPagoInstallmentOption[]) => {
        const apiOption = getRelevantMercadoPagoGatewayApiOption({ apiOptions, model: props.model, cardData });

        const merchantAccountID = apiOption?.MerchantAccountId;
        const paymentMethodOptionID = apiOption?.Agreements?.find(
            (agreement) => agreement.MerchantAccounts?.length > 0,
        )?.MerchantAccounts.find((merchantAccount) => merchantAccount.PaymentMethodOptionId)?.PaymentMethodOptionId;
        props.handleInstallmentInfoChange(merchantAccountID, paymentMethodOptionID);

        if (apiOption) {
            updateOptionsDropdown(apiOption);
        } else {
            disableOptionsDropdown();
        }
    };

    const getApiResponse = async () => {
        const apiOptions = await props.getApiResponse();

        if (!apiOptions) {
            disableOptionsDropdown();
            return;
        }

        const relevantApiOptions = apiOptions.filter((option) => option.ProcessingMode === "gateway");

        handleApiResponse(relevantApiOptions);
    };

    const updateOptionsDropdown = (apiOption: ApiMercadoPagoInstallmentOption) => {
        if (isInstallmentsDisabledForFlow(appContext, flowContext)) return;

        try {
            const selectOptions = gatewayGetDropdownTexts(apiOption);
            setDropdownOptions(selectOptions);

            const isSelectedValueAvailableInDropdown =
                props.selectedInstallmentsNumber &&
                selectOptions?.some((option) => option.Value === props.selectedInstallmentsNumber.toString());

            if (!isSelectedValueAvailableInDropdown) {
                handleNumberOfInstallmentsChange(undefined, 1);
            }
        } catch (e) {
            commonDebug.error("Could not parse MercadoPago installments.");
            disableOptionsDropdown();
        }
    };

    const disableOptionsDropdown = () => {
        setDropdownOptions([]);
        handleNumberOfInstallmentsChange(undefined, 1);
        installmentsCommonTemplate.removeMessage();
    };

    const showSelector = () =>
        props.isActive && dropdownOptions?.length > 0 && !isInstallmentsDisabledForFlow(appContext, flowContext);

    const handleInstallmentChange = (selectedInstallmentNumber: number) => {
        props.handleInstallmentChange(selectedInstallmentNumber, 0);
    };

    // EVENT HANDLERS

    const handleNumberOfInstallmentsChange = async (e?: MouseEvent, numberOfInstallments?: number) => {
        const selectedInstallmentNumber = numberOfInstallments
            ? numberOfInstallments
            : Number((e.target as HTMLSelectElement).value);

        handleInstallmentChange(selectedInstallmentNumber);

        props.tealiumLogMercadoPagoInstallmentChange(selectedInstallmentNumber);
    };

    const handleCardChange = () => {
        if (!props.isActive) return;

        if (cardData?.CardNumber && cardData.CardType && cardData.CardValidationStatus === "valid") {
            getApiResponse();
        } else {
            setDropdownOptions([]);
        }
    };

    useEffect(handleCardChange, [
        cardData?.CardNumber,
        cardData?.CardType,
        cardData?.CardValidationStatus,
        props.isActive,
    ]);

    // TEMPLATES

    const htmlTemplate = () =>
        showSelector()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow is-dirty is-upgraded"
                          >
                              ${installmentsCommonTemplate.htmlTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

import { html, TemplateResult } from "lit-html";

export const name = "ac-add-travel-partners-to-booking-container";

export interface Props {
    isOpen: boolean;
    modalContentTemplate: TemplateResult;
    modalHeaderTemplate: TemplateResult;
    onClose: () => void;
}

export const useAddTravelPartnersToBookingModal = (props: Props) => {
    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${props?.modalContentTemplate || ""}
            .customClass=${"cug2b-add-travel-partners-to-booking-modal"}
            .header=${props?.modalHeaderTemplate || ""}
            .isOpen=${props?.isOpen}
            .showDecor=${true}
            @close=${props?.onClose}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};

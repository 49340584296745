import { getAntiForgeryTokenFromHtml, handleCugLoader } from "./../../../shared/common";
import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { Group } from "../../../component-models/CUG2b/Group";
import { Partner } from "../../../component-models/CUG2b/Partner";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import {
    GridState,
    PageChangeEvent,
    SortChangeEvent,
    RowsSelectedEvent,
    ViewModel,
    Column,
} from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { URL_VARS } from "../../../shared/commonConstants";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { UpdatePartnerEvent } from "../../../component-models/CUG2b/Cug2PortalEvents";
import { ROUTES } from "../../../shared/apiRoutes";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export const name = "ac-edit-travel-group-page";
export interface Attributes {
    "anti-forgery-token": string;
}
export interface Properties {
    antiForgeryToken: string;
}

const actionCellClass = ["w-16", "sm:w-16", "md:w-32", "p-0", "pinned-on-mobile"];
const actionCellContentClass = ["w-16", "sm:w-16", "md:w-32", "action-cell-user"];

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const result = await getTravelPartnerInfo();

        setTravelPartnerInfo(result);

        const urlParams = new URLSearchParams(window.location.search);
        const groupId = decodeURIComponent(urlParams.get(URL_VARS.FFLYER_IDS));

        setGroup(result.Groups.find((g) => g.Id === Number(groupId)));
    };

    const deletePartners = async () => {
        const newTravelPartnerInfo = TravelPartnerHelper.deletePartners(travelPartnerInfo, partnersToDelete);

        setPartnersToDelete(undefined);
        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const initialGridState = (): GridState<keyof Partner> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        };
    };

    const orderedModel = () =>
        travelPartnerInfo
            ? TravelPartnerHelper.getOrderedFilteredModel(
                  travelPartnerInfo.Partners.filter((p) => p.GroupId === group.Id),
                  gridState,
              )
            : [];

    const isAnythingSelected = () => Array.from(selectedIds?.values()).length > 0;

    // COMPONENT

    useEffect(init, []);

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState());
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [partnersToDelete, setPartnersToDelete] = useState<number[]>(undefined);
    const [group, setGroup] = useState<Group>(undefined);
    const [partnerToEdit, setPartnerToEdit] = useState<Partner>(undefined);

    // EVENT LISTENERS

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(GridHelper.getSelectedIds(event, selectedIds, orderedModel()) as Set<number>);
    };

    const handleSingleDelete = async (row: Partner) => {
        setPartnersToDelete([row.Id]);
    };

    const handleMultiDelete = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPartnersToDelete(Array.from(selectedIds.values()));
    };

    const handleEditSubmit = async (e: UpdatePartnerEvent) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, e.detail.partner);
        setPartnerToEdit(undefined);

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const handleEdit = (e: MouseEvent, row: Partner) => {
        e.preventDefault();
        e.stopPropagation();
        setPartnerToEdit(row);
    };

    // TEMPLATES

    const areYouSureModalHeaderTemplate = () => html`
        <span>
            ${partnersToDelete?.length > 1
                ? i18next.t("¿Estás seguro que quieres eliminar a estos pasajeros?")
                : i18next.t("¿Estás seguro que quieres eliminar a este pasajero?")}
        </span>
    `;

    const areYouSureModalContentTemplate = () => html`
        <ac-delete-partners-modal
            .partnersToDelete=${partnersToDelete}
            .travelPartnerInfo=${travelPartnerInfo}
            @selectYes=${deletePartners}
            @selectNo=${() => setPartnersToDelete(undefined)}
        ></ac-delete-partners-modal>
    `;

    const areYouSureModalTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${areYouSureModalContentTemplate()}
            .customClass=${"cug2b-are-you-sure-group-modal"}
            .header=${areYouSureModalHeaderTemplate()}
            .isOpen=${partnersToDelete}
            @close=${() => setPartnersToDelete(undefined)}
        ></ac-cug-modal>
    `;

    const editPartnerTemplate = (id: number) => {
        return partnerToEdit?.Id === id
            ? html`
                  <ac-edit-travel-partner
                      .partner=${partnerToEdit}
                      .travelPartnerInfo=${travelPartnerInfo}
                      @close=${() => setPartnerToEdit(undefined)}
                      @update=${handleEditSubmit}
                  ></ac-edit-travel-partner>
              `
            : "";
    };

    const breadcrumbsTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
            >
                ${i18next.t("Grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BAdminTravelGroupsPage)}
            >
                ${i18next.t("Administrar grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${group.Name}</div>
        </div>
    `;

    const staticStuffTemplate = () => html`
        ${breadcrumbsTemplate()}
        <div class="travel-partner-title pull-up">
            <h2>${i18next.t("Administrar grupos")}</h2>
        </div>
        <div class="travel-partner-text-and-button">
            ${i18next.t(
                "Aquí podrás revisar toda la información de los grupos creados, administrar pasajeros frecuentes, editar y eliminar grupos.",
            )}
        </div>
        <div class="mb-4 flex flex-col justify-between sm:flex-row sm:items-end md:mb-8">
            <div>
                <div class="travel-partner-title pull-up">
                    <h2>${i18next.t("Editar grupo")}</h2>
                </div>
                <div class="travel-partner-group-name mt-4">
                    <h2>${group.Name}</h2>
                </div>
                <div class="travel-partner-edit-group-info">
                    ${i18next.t("Cantidad de pasajeros")}:
                    <span class="font-bold"
                        >${travelPartnerInfo.Partners.filter((p) => p.GroupId === group.Id).length}</span
                    >
                </div>
            </div>
            <div class="text-center sm:text-left">
                <div
                    class="cug2b-pill mr-4"
                    @click=${() =>
                        (window.location.href = `${ROUTES.Cug2BAddPartnerPage}?${URL_VARS.FFLYER_GROUP_ID}=${group.Id}`)}
                >
                    <i class="js-icon-cug js-cug-man-and-plus"></i>${i18next.t("Agregar Pasajero")}
                </div>
                <div class="cug2b-pill warning ${!isAnythingSelected() ? "disabled" : ""}" @click=${handleMultiDelete}>
                    <i class="js-icon-cug js-cug-bin"></i>${i18next.t("Eliminar")}
                </div>
            </div>
        </div>
    `;

    const rowEditTemplate = (index: number) => {
        const row = orderedModel()[index];

        return html`
            <td class=${GridHelper.getClassMap(actionCellClass)}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i class="js-icon-cug js-cug-edit relative mr-4" @click=${(e: MouseEvent) => handleEdit(e, row)}>
                        ${editPartnerTemplate(row.Id)}
                    </i>
                    <i class="js-icon-cug js-cug-bin" @click=${() => handleSingleDelete(row)}></i>
                </div>
            </td>
        `;
    };

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => `${orderedModel()[index].FirstName} ${orderedModel()[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "DOB",
                columnType: "string",
                label: i18next.t("Fecha de Nacimiento"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => orderedModel()[index].DOB.format("DD-MM-YYYY"),
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel().some((item) => item.Id === id))
                .map((id) =>
                    orderedModel()
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    return travelPartnerInfo
        ? html`
              <div ref=${ref(root)}>
                  ${staticStuffTemplate()}
                  <dc-table
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onRowsSelect=${onRowsSelect}
                  ></dc-table>
                  ${areYouSureModalTemplate()}
              </div>
          `
        : html``;
};

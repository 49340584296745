import i18next from "i18next";
import { html } from "lit-html";
import { sanitizeCuit } from "../../shared/common";
import { useReduxState } from "../../shared/redux/useReduxState";
import { Payer } from "./usePayer";
import classNames from "classnames";

export interface Props {
    isValidated: boolean;
    payer: Payer;
}

export const useCuitInInvoice = (props: Props) => {
    const [payerData, setPayerData] = useReduxState("payment.payer");

    const handleCuitInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        const value = sanitizeCuit(target.value);

        setPayerData({ ...payerData, Cuit: value });
    };

    const cuitLengthErrorTemplate = () =>
        props.isValidated && !props.payer.IsCuitLengthValid
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-CuitLengthError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const mainClassMap = classNames("mdl-textfield__input js-input", {
        invalid: props.isValidated && !props.payer.IsCuitLengthValid,
    });

    const htmlTemplate = () => html`
        <input
            name="jetSmartInvoiceDomestic.AddressLine2"
            class=${mainClassMap}
            autocomplete="off"
            data-required
            data-test-id="regular-invoice-address-line-2"
            max="52"
            .value=${payerData.Cuit}
            @input=${handleCuitInput}
        />

        ${cuitLengthErrorTemplate()}
    `;

    return { htmlTemplate };
};

import { CheckinTypeSelection } from "../../../components/spa/extras/checkin-type/useCheckinType";

interface CheckinOptionClickedEventDetail {
    checkinTypeSelection: CheckinTypeSelection;
    journeyIndex: number;
    paxNumber: number;
    previousPrice: number;
    price: number;
    ssrAddKey: string;
    ssrRemoveKey: string;
}

export class CheckinOptionClickedEvent extends CustomEvent<CheckinOptionClickedEventDetail> {
    constructor(detail: CheckinOptionClickedEventDetail) {
        super("select", { detail, bubbles: true });
    }
}

import { DEFAULT_DATE_FORMAT } from "./../shared/commonConstants";
import { TripCalendar } from "../component-models/flight-calendar/TripCalendar";
import { ApiTripCalendar } from "./../component-models/flight-calendar/ApiTripCalendar";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);

export function mapToTripCalendar(calendar: ApiTripCalendar): TripCalendar {
    return {
        CalendarIndex: calendar.CalendarIndex,
        SelectedDay: dayjs(calendar.SelectedDayAsString, DEFAULT_DATE_FORMAT),
        IsDeparture: calendar.IsDeparture,
        Months: calendar.Months.map((month) => ({
            Date: dayjs(month.DateAsString, DEFAULT_DATE_FORMAT),
            IsSelectedMonth: month.IsSelectedMonth,
            TabLink: month.TabLink,
            Name: month.Name,
            ShortName: month.ShortName.toUpperCase().replace(".", ""),
            Weeks: month.Weeks.map((week) => ({
                Days: week.Days.map((day) => ({
                    Date: dayjs(day.DayAsString, DEFAULT_DATE_FORMAT),
                    NoFlights: day.NoFlights,
                    HasAmount: day.HasAmount,
                    Amount: day.Amount,
                    CurrencyCode: day.CurrencyCode,
                    InputName: day.InputName,
                    IsCheapest: day.IsCheapest,
                    InputId: day.InputId,
                    FormattedAmount: day.FormattedAmount,
                })),
            })),
        })),
    };
}

import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageContext, BaggageContextJourney } from "../../../../component-models/baggage/BaggageContext";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { perJourneyPerPaxViewPaxCaption, paxLabel, paxAmount } from "../../../../component-helpers/BaggageHelper";
import { useBaggageEditButton } from "../common/useBaggageEditButton";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { usePerJourneyPerPaxSoldOut } from "./usePerJourneyPerPaxSoldOut";
import { usePerJourneyPerPaxBundle } from "./usePerJourneyPerPaxBundle";
import classNames from "classnames";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    pageState: BaggagePageState;
}

export const usePerJourneyPerPaxTable = (props: Props) => {
    const bundle = usePerJourneyPerPaxBundle({
        baggageSection: props.baggageSection,
        context: props.context,
        isMobile: false,
    });

    const soldOut = usePerJourneyPerPaxSoldOut({
        baggageSection: props.baggageSection,
        context: props.context,
        isMobile: false,
    });

    const editButton = useBaggageEditButton({
        dataTestId: "",
        isDisabled: true,
        onClick: () => null,
    });

    const handleViewSwitch = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // FIXME reset is async, so the order is important here
        await props.baggageSection.handlers.reset();
        props.baggageSection.handlers.closePerJourneyPerPaxView();
    };

    const journeyTemplate = (journey: BaggageContextJourney) => {
        const directionText = journey.index === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta");

        return html`
            <div class="b2-journey-heading">
                <i class="js-icon-bag js-bag-plane-take-off"></i>
                <span class="uppercase">${directionText}</span>
                <span>&rarr;</span>
                <span>${journey.departureStationName} - ${journey.arrivalStationName}</span>
            </div>
            ${soldOut.htmlTemplate(journey)} ${bundle.htmlTemplate(journey.index)}
            <div
                class="b2-journey-pax-grid-container"
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGERS, {
                    j: journey.index,
                    c: props.baggageSection.bagType,
                })}
            >
                ${passengersTemplate(journey)}
            </div>
        `;
    };

    const passengersTemplate = (journey: BaggageContextJourney) =>
        journey.passengers.map((pax) => {
            const dto: PerJourneyPerPaxPassengerDto = {
                baggageSection: props.baggageSection,
                context: props.context,
                isMobile: false,
                journeyIndex: journey.index,
                pageState: props.pageState,
                passengerIndex: pax.index,
            };

            return html` <ac-per-journey-per-pax-passenger .dto=${dto}></ac-per-journey-per-pax-passenger> `;
        });

    const resetButtonTemplate = () => {
        if (!props.baggageSection.perBooking.isResetAvailable) return "";

        const buttonText =
            paxAmount(props.baggageSection.state) === 1
                ? i18next.t("Volver a la selección para todos los tramos")
                : i18next.t("Volver a la selección para todos los pasajeros");

        return html`
            <div
                class="b2-reset-button"
                data-test-id=${getTestId(T.BAGGAGE.CLOSE_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
                    c: props.baggageSection.bagType,
                })}
                @click=${handleViewSwitch}
            >
                ${buttonText}
            </div>
        `;
    };

    const viewClassMap = classNames(
        "b2-per-pax-grid b2-openable hidden-xs",
        props.context.classesToScrollToOnOpen.get(props.baggageSection.bagType),
        {
            "b2-opened": props.baggageSection.state.perJourneyPerPaxState === "open",
        },
    );

    const htmlTemplate = () => html`
        <div
            class=${viewClassMap}
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_VIEW, { c: props.baggageSection.bagType })}
        >
            <div class="b2-per-pax-view-switch b2-view-switch">
                <div class="b2-view-info">${perJourneyPerPaxViewPaxCaption(paxAmount(props.baggageSection.state))}</div>
                <div class="b2-pax-amount">
                    ${paxAmount(props.baggageSection.state)} ${paxLabel(paxAmount(props.baggageSection.state))}
                </div>
                ${editButton.htmlTemplate()}
            </div>
            <div class="b2-per-pax-reset">${resetButtonTemplate()}</div>
            <div class="b2-per-pax">${props.context.journeys.map(journeyTemplate)}</div>
        </div>
    `;

    return { htmlTemplate };
};

import { ApiSeatmapJourneySegment } from "../component-models/seatmap/ApiSeatmapJourneySegment";
import { INBOUND, OUTBOUND } from "../shared/commonConstants";
import { ApiSeatmapJourney } from "../component-models/seatmap/ApiSeatmapJourney";
import { ApiSeatmapModel } from "../component-models/seatmap/ApiSeatmapModel";
import { SeatmapModel } from "../component-models/seatmap/SeatmapModel";
import { SeatmapJourney } from "../component-models/seatmap/SeatmapJourney";
import { PlaneType } from "../component-models/seatmap/PlaneType";
import { SeatmapJourneySegment } from "../component-models/seatmap/SeatmapJourneySegment";
import { ApiSeat, ExtendedApiSeat } from "../component-models/seatmap/ApiSeat";
import ApiJourneyAutoSeatAssignmentResponse from "../component-models/seatmap/ApiJourneyAutoSeatAssignmentResponse";
import { AutoAssignedSeating } from "../component-models/seatmap/AutoAssignedSeating";

const mapToSeatmapJourney = (data: {
    model: ApiSeatmapModel;
    journey: ApiSeatmapJourney;
    journeyIndex: number;
    isCategory56: boolean;
    isBookingFlow: boolean;
}): SeatmapJourney => ({
    ...data.journey,
    AreAllSeatsInBundle:
        data.journeyIndex === OUTBOUND ? data.model.OutBoundAllSeatsInBundle : data.model.InBoundAllSeatsInBundle,
    JourneyIndex: data.journeyIndex,
    Segments: data.journey.Segments.map((segment, segmentIndex) =>
        mapToSeatmapSegment({ ...data, segment, segmentIndex }),
    ),
});

const mapToSeatmapSegment = (data: {
    segment: ApiSeatmapJourneySegment;
    journeyIndex: number;
    segmentIndex: number;
    isCategory56: boolean;
    isBookingFlow: boolean;
}): SeatmapJourneySegment => ({
    ...data.segment,
    PlaneType: data.segment.AirplaneType as PlaneType,
    Seats: getApiSeats(data),
    SegmentIndex: data.segmentIndex,
});

const getShownJourneys = (model: ApiSeatmapModel): number[] => {
    const journeys = [];

    if (model.ShowOutbound && !model.ShowOnlyInbound) journeys.push(OUTBOUND);

    if (model.ShowInbound && !model.ShowOnlyOutbound) journeys.push(INBOUND);

    return journeys;
};

const getApiSeats = (data: {
    segment: ApiSeatmapJourneySegment;
    journeyIndex: number;
    segmentIndex: number;
    isCategory56: boolean;
    isBookingFlow: boolean;
}): ExtendedApiSeat[] =>
    data.segment
        ? data.segment.Seats.map((seat) =>
              mapApiSeat({
                  ...data,
                  seat,
                  flightKey: data.segment.FlightKey,
                  recommendedPassengerNumber: data.segment.SeatMapRecommendation
                      ? data.segment.SeatMapRecommendation.indexOf(`${seat.Row}${seat.Column}`)
                      : -1,
              }),
          )
        : [];

const mapApiSeat = (data: {
    seat: ApiSeat;
    journeyIndex: number;
    segmentIndex: number;
    flightKey: string;
    recommendedPassengerNumber: number;
    isCategory56: boolean;
    isBookingFlow: boolean;
}): ExtendedApiSeat => ({
    ...data.seat,
    FlightKey: data.flightKey,
    IsAutoAssigned: false,
    IsDiscounted: false,
    IsFree: data.seat.IsBancoEstadoFreeSeatDuringBooking && data.isCategory56,
    JourneyIndex: data.journeyIndex,
    OriginalPassengerNumber: data.isBookingFlow ? undefined : data.seat.PassengerNumber,
    RecommendedPassengerNumber: data.recommendedPassengerNumber,
    SegmentIndex: data.segmentIndex,
});

// EXPORTS

export const mapToAutoAssignedSeatings = (result: ApiJourneyAutoSeatAssignmentResponse): AutoAssignedSeating[] =>
    result.JourneySeatings.reduce(
        (allSeatings, seatings) =>
            allSeatings.concat(
                seatings.Seatings.map(
                    (seating): AutoAssignedSeating => ({
                        Column: seating.Column,
                        JourneyIndex: seatings.JourneyIndex,
                        PaxIndex: seating.PaxIndex,
                        Row: seating.Row,
                        SegmentIndex: seatings.SegmentIndex,
                    }),
                ),
            ),
        [],
    );

export const mapToSeatmapModel = (data: {
    model: ApiSeatmapModel;
    isCategory56: boolean;
    isBookingFlow: boolean;
}): SeatmapModel => ({
    ...data.model,
    Journeys: data.model.SeatMap.Journeys.map((journey, journeyIndex) =>
        mapToSeatmapJourney({ ...data, journey, journeyIndex }),
    ),
    Passengers: data.model.SeatMap.Passengers,
    ShownJourneys: getShownJourneys(data.model),
});

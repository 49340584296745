import { Cug2bDocumentType, TravelDocumentType } from "../component-models/checkin/TravelDocument";
import {
    COLOMBIAN_DNI_MAX_LENGTH,
    ECUADORIAN_CULTURE_CODE,
    ECUADOR_DNI_MAX_LENGTH,
    PASSPORT_MAX_LENGTH,
} from "../shared/commonConstants";

export type DniType = "colombian-station" | "ecuadorian-culture" | "default" | "none";

export const getDniType = (
    hasFlightColombianStation: boolean,
    documentType: TravelDocumentType | Cug2bDocumentType,
    culture: string,
): DniType =>
    documentType !== "DNI"
        ? "none"
        : hasFlightColombianStation
          ? "colombian-station"
          : culture === ECUADORIAN_CULTURE_CODE
            ? "ecuadorian-culture"
            : "default";

export const getMaxLengthOfPassport = () => PASSPORT_MAX_LENGTH;

export const getMaxLengthOfDni = (dniType: DniType) =>
    dniType === "ecuadorian-culture"
        ? ECUADOR_DNI_MAX_LENGTH
        : dniType === "colombian-station"
          ? COLOMBIAN_DNI_MAX_LENGTH
          : "";

export const getMaxLengthOfDocument = (documentType: string, dniType: DniType) =>
    documentType === "P" ? getMaxLengthOfPassport() : documentType === "DNI" ? getMaxLengthOfDni(dniType) : "";

import { html } from "lit-html";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { PaymentMode } from "../../component-models/payment/PaymentMode";

export interface Props {
    paymentMode: PaymentMode;
    vm: PaymentPageViewModel;
    onSubmit: () => void;
}

export const useDgCash = (props: Props) => {
    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const isPeruCompra = useMemo(
        () => userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking,
        [userContext, bookingContext],
    );

    const htmlTemplate = () =>
        props.vm.IsTest && !isPeruCompra && props.paymentMode !== "none"
            ? html`
                  <div class="inner-deep-box">
                      <div class="row">
                          <div class="col-xs-1">
                              <p>Cash payment - TEST ONLY!</p>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-xs-1" id="cashTestPaymentDiv">
                              <button data-test-id="payment-execute-test-payment-button" @click=${props.onSubmit}>
                                  EXECUTE TEST PAYMENT
                              </button>
                          </div>
                      </div>
                  </div>
              `
            : "";

    return { htmlTemplate };
};

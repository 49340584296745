import { CLASS_NAMES } from "./classNames";
import { attributeNames, isElementVisible } from "./form-validation";
import DomCrawlingHelper from "./DomCrawlingHelper";
import { PAYMENT_CLASS_NAMES } from "../V2/booking/payment/PaymentClassNames";
import { FULL_EMAIL_REGEX } from "./commonConstants";

export enum ErrorType {
    Required,
    InvalidAge,
    InvalidPromoCode,
    EmailFormat,
    InvalidLength,
}

export function resetErrorMessages(container: HTMLElement): void {
    if (!container) {
        return;
    }

    const fields = Array.from(
        container.querySelectorAll("select, input, ." + CLASS_NAMES.baggageCompulsory),
    ) as HTMLElement[];
    for (const field of fields) {
        field.classList.remove(CLASS_NAMES.invalid);
    }
    const ageError = container.querySelector("." + CLASS_NAMES.ageErrorMessage);
    if (ageError) {
        ageError.classList.add(CLASS_NAMES.Hidden);
    }

    const emailError = DomCrawlingHelper.getArrayOfClass(container, CLASS_NAMES.invalidEmailFormat);
    emailError.forEach((e) => e.classList.add(CLASS_NAMES.Hidden));

    const lengthError = DomCrawlingHelper.getArrayOfClass(container, CLASS_NAMES.fieldLengthMessage);
    lengthError.forEach((e) => e.classList.add(CLASS_NAMES.Hidden));

    const requiredError = Array.from(
        container.querySelectorAll(
            "." +
                CLASS_NAMES.requiredMessage +
                ", ." +
                CLASS_NAMES.ageErrorMessage +
                ", ." +
                PAYMENT_CLASS_NAMES.CardErrorMessage,
        ),
    );
    if (requiredError) {
        requiredError.forEach((e) => {
            e.classList.add(CLASS_NAMES.Hidden);
        });
    }
}

export function resetFieldError(elem: HTMLElement): void {
    elem.classList.remove(CLASS_NAMES.invalid);
}

export function markInvalidFields(container: HTMLElement, errorType: ErrorType): void {
    switch (errorType) {
        case ErrorType.EmailFormat:
            markInvalidEmailFields(container);
            break;
        case ErrorType.InvalidAge:
            markInvalidAgeGroup(container);
            break;
        case ErrorType.InvalidLength:
            markInvalidLength(container);
            break;
        case ErrorType.InvalidPromoCode:
            break;
        case ErrorType.Required:
            markMissingRequiredFields(container);
            break;
    }
}

export function validateRequiredFields(container: HTMLElement): boolean {
    const invalidFields = (
        Array.from(container.querySelectorAll("input:not([type=hidden]), select")) as HTMLInputElement[]
    ).filter((i) => isElementVisible(i) && i.attributes.getNamedItem(attributeNames.required) && !i.value);
    return invalidFields.length === 0;
}

export function validateEmailFields(container: HTMLElement): boolean {
    const invalidFields = (Array.from(container.querySelectorAll("[data-email]")) as HTMLInputElement[]).filter(
        (i) => isElementVisible(i) && i.value && !FULL_EMAIL_REGEX.test(i.value),
    );
    return invalidFields.length === 0;
}

function markMissingRequiredFields(container: HTMLElement) {
    const fields = (
        Array.from(container.querySelectorAll("input:not([type=hidden]), select")) as HTMLInputElement[]
    ).filter(
        (i) =>
            (isElementVisible(i) && i.attributes.getNamedItem(attributeNames.required) && !i.value) ||
            (i.attributes.getNamedItem(attributeNames.slimSelectRequired) && !i.parentElement.dataset.slimselectvalue),
    );
    if (fields.length === 0) {
        return;
    }
    for (const field of fields) {
        field.classList.add(CLASS_NAMES.invalid);
    }
    const messages = Array.from(container.querySelectorAll("." + CLASS_NAMES.requiredMessage)) as HTMLDivElement[];
    messages.forEach((m) => m.classList.remove(CLASS_NAMES.Hidden));
}

function markInvalidLength(container: HTMLElement) {
    const fields = (
        Array.from(container.querySelectorAll("input:not([type=hidden]), select")) as HTMLInputElement[]
    ).filter(
        (i) =>
            isElementVisible(i) &&
            i.attributes.getNamedItem(attributeNames.max) &&
            i.value &&
            i.value.length > Number(i.attributes.getNamedItem(attributeNames.max).value),
    );
    if (fields.length === 0) {
        return;
    }
    for (const field of fields) {
        field.classList.add(CLASS_NAMES.invalid);
        const message = field.parentElement.parentElement.querySelector("." + CLASS_NAMES.fieldLengthMessage);
        if (message) {
            message.classList.remove(CLASS_NAMES.Hidden);
        }
    }
}

function markInvalidEmailFields(container: HTMLElement) {
    const fields = (Array.from(container.querySelectorAll("[data-email]")) as HTMLInputElement[]).filter(
        (i) => isElementVisible(i) && i.value && !FULL_EMAIL_REGEX.test(i.value),
    );
    if (fields.length === 0) {
        return;
    }
    for (const field of fields) {
        field.classList.add(CLASS_NAMES.invalid);
    }
    const message = container.querySelector("." + CLASS_NAMES.invalidEmailFormat);
    if (message) {
        message.classList.remove(CLASS_NAMES.Hidden);
    }
}

function markInvalidAgeGroup(container: HTMLElement) {
    const fields = Array.from(container.querySelectorAll("select")) as HTMLSelectElement[];
    for (const field of fields) {
        field.classList.add(CLASS_NAMES.invalid);
    }
    const message = container.querySelector("." + CLASS_NAMES.ageErrorMessage);
    if (message) {
        message.classList.remove(CLASS_NAMES.Hidden);
    }
}

import { Debug } from "./shared/Debug";
// The following line provides polyfills for web components
import "@webcomponents/custom-elements";
import "../assets/css/bundle.scss";
import smoothscroll from "smoothscroll-polyfill";
import { EventBus } from "./shared/eventbus/eventbus";
import DomCrawlingHelper from "./shared/DomCrawlingHelper";
import { CLASS_NAMES } from "./shared/classNames";
import "./shared/redux/ReduxContext";
import { initCustomElements } from "./components";
import {
    handleAmplitudeTealiumCookies,
    initGlobalScrollListener,
    initLocalization,
} from "./component-helpers/InitHelper";
import { ROUTES } from "./shared/apiRoutes";

interface AmplitudeExposureRequestItem {
    ExperimentId: string;
    Variant: string;
}

// 3rd party

export const commonDebug = new Debug("js", "common");

(window as any).__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

if (typeof BechApiPayment === "function") (window as any).bechPay = new BechApiPayment();

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/Service_Workers/SalesManagoServiceWorker.js", { scope: "/Service_Workers/" })
        .then(() => {
            // eslint-disable-next-line no-console
            console.log("Service Worker Registered");
        });
}

if (
    DomCrawlingHelper.getElemByClass(document.body, CLASS_NAMES.BackendErrorsContanier) &&
    "scrollRestoration" in history
) {
    history.scrollRestoration = "manual";
}

// autorun

document.addEventListener("DOMContentLoaded", () => {
    if (!window.eventBus) window.eventBus = new EventBus();
    window.exposeToAmplitude = sendAmplitudeExposure;
});

const sendAmplitudeExposure = async (items: AmplitudeExposureRequestItem[]): Promise<Response> => {
    try {
        const baseUrl = ROUTES.ApiRoutes.AmplitudeExpose;
        const fetchOptions: RequestInit = {
            method: "POST",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
            mode: "cors",
            body: JSON.stringify(items),
        };

        const fetchParameters = {
            options: fetchOptions,
            url: baseUrl,
        };

        return fetch(fetchParameters.url, fetchParameters.options);
    } catch (e) {
        commonDebug.error("Could not send exposure to Amplitude.", items);
        return Promise.reject();
    }
};

// init

// DEVNOTE We have to init custom elements after i18next init, so that i18 strings appear
// right from the start. So we use this as callback of i18next.init
initLocalization(initCustomElements);
handleAmplitudeTealiumCookies();
initGlobalScrollListener();

import i18next from "i18next";
import { html } from "lit-html";
import { finishedSelection } from "../../../../component-helpers/BaggageHelper";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { baggageErrorClass } from "../useBaggagePage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";

export interface Props {
    baggageSection: BaggageSection;
    pageState: BaggagePageState;
}

export const usePerBookingValidationError = (props: Props) => {
    const isValid = useMemo(
        () =>
            props.baggageSection.state.journeys.every((journey) =>
                journey.passengers.every((passenger) =>
                    props.baggageSection.handlers.isPassengerValid({
                        journey,
                        passenger,
                    }),
                ),
            ),
        [props.baggageSection.state, props.baggageSection.handlers],
    );

    const isJourneyValid = useMemo(
        () =>
            props.baggageSection.bagType === "OversizedBaggage"
                ? finishedSelection(props.baggageSection.state)
                : isValid,
        [props.baggageSection.bagType, isValid],
    );

    const showError = useMemo(
        () =>
            props.baggageSection.state.perJourneyPerPaxState !== "open" &&
            props.pageState.lastValidationTimestamp &&
            !isJourneyValid,
        [props.baggageSection.state.perJourneyPerPaxState, props.pageState.lastValidationTimestamp, isJourneyValid],
    );

    const htmlTemplate = (isMobile: boolean) => {
        return showError
            ? html`
                  <div
                      class=${baggageErrorClass}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          c: props.baggageSection.bagType,
                          m: isMobile,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};

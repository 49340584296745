import { TemplateResult } from "lit-html";

export type ColumnType = "string" | "enum" | "date" | "number";

export type SortOrder = "asc" | "desc" | "none";

export interface DateInput {
    from: Date;
    to: Date;
}

export interface NumberInput {
    from: number;
    to: number;
}

export interface ColumnFilterDescriptor {
    type: "string" | "select" | "date" | "number";
}

// tslint:disable-next-line: no-empty-interface
export interface TextFilterDescriptor extends ColumnFilterDescriptor {}

export interface SelectFilterDescriptor extends ColumnFilterDescriptor {
    dataSource: string[] | { label: string; value: any }[];
    multiSelect?: boolean;
    searchable: boolean;
    searchProps: {
        type: any;
        // type?: FilterType;
        ignoreCase?: boolean;
    };
}

export interface DateFilterDescriptor extends ColumnFilterDescriptor {
    from: boolean;
    to: boolean;
}

export interface NumberFilterDescriptor extends ColumnFilterDescriptor {
    from: boolean;
    to: boolean;
}

export interface FilterProperty {
    operator: "in" | "eq" | "neq" | "gt" | "gte" | "lt" | "lte" | "between";
    selectedValues?: string[] | string;
    min?: string | number | Date;
    max?: string | number | Date;
}

export interface GridState<T> {
    pageIndex: number;
    pageSize: number;
    orderBy: T;
    orderDir: SortOrder;
    appliedFilters: AppliedFilter<T>[];
    globalFilterExpression: string;
}

export interface Column<FieldType> {
    field: FieldType;
    label?: string;
    columnClass?: string | string[];
    sortable?: boolean;
    columnType?: ColumnType;
    filterable?: boolean;
    filterDescriptor?: TextFilterDescriptor | SelectFilterDescriptor | DateFilterDescriptor | NumberFilterDescriptor;
    isPinned?: boolean;
    headerTemplate?: TemplateResult;
    cellTemplate?: (index: number) => TemplateResult | string;
    cellClass?: (index: number) => string;
}

export interface Selection {
    selectable: boolean;
    selectedIndices?: number[];
}

export interface Sorting {
    orderBy: string;
    orderDir: SortOrder;
    showSorterArrow: boolean;
}

export interface AppliedFilter<FieldType> {
    field: FieldType;
    filterProps: FilterProperty;
}

export interface RowCustomization {
    index: number;
    classes: string[];
}

export interface Paging {
    pageable?: boolean;
    pageSize?: number;
    itemCount?: number;
    pageSizes?: number[];
    pageIndex?: number;
    buttonCount?: number;
    showInfo?: boolean;
    showPreviousNext?: boolean;
}

export interface ViewModel<FieldType> {
    actionCellClass?: string | string[];
    actionCellContentClass?: string | string[];
    appliedFilters?: AppliedFilter<string>[];
    columns: Column<FieldType>[];
    data?: any[];
    forcedNumberOfRows?: number;
    paging?: Paging;
    rowCustomization?: RowCustomization[];
    selection?: Selection;
    sorting?: Sorting;
    useEllipsis?: boolean;
    bodyTemplate?: () => TemplateResult;
    rowDeleteTemplate?: (index: number) => TemplateResult;
    rowEditTemplate?: (index: number) => TemplateResult;
    rowViewTemplate?: (index: number) => TemplateResult;
}

export interface SortChangeEventDetail {
    orderBy: string;
    orderDir: SortOrder;
}

export interface PageChangeEventDetail {
    selectedPageIndex?: number;
    selectedPageSize?: number;
}

export interface FilterChangeEventDetail {
    field: string;
    filterProps: FilterProperty;
}

export interface RowsSelectedEventDetail {
    currentSelection: number[];
    rowChanged: number | number[];
}

export class SortChangeEvent extends CustomEvent<SortChangeEventDetail> {
    constructor(detail: SortChangeEventDetail) {
        super("onSortChange", { detail, bubbles: true });
    }
}

export class FilterChangeEvent extends CustomEvent<FilterChangeEventDetail> {
    constructor(detail: FilterChangeEventDetail) {
        super("onFilterChange", { detail });
    }
}

export class RowsSelectedEvent extends CustomEvent<RowsSelectedEventDetail> {
    constructor(detail: RowsSelectedEventDetail) {
        super("onRowsSelect", { detail, bubbles: true });
    }
}

export class PageChangeEvent extends CustomEvent<PageChangeEventDetail> {
    constructor(detail: PageChangeEventDetail) {
        super("onPageChange", { detail, bubbles: true });
    }
}

import i18next from "i18next";
import { html } from "haunted";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    designator: string;
    formattedPrice: string;
    isDiscounted: boolean;
    isFree: boolean;
    isMobile: boolean;
    handleSeatClick: (e: MouseEvent) => void;
}

export const useSeatTooltip = (props: Props) => {
    const handleClick = (e: MouseEvent) => props.isMobile && props.handleSeatClick(e);

    const { formatNumber } = useNumberFormatter();

    const freeTemplate = () =>
        props.isFree
            ? html`
                  <div class="mt-1 flex items-center">
                      <span class="be2stt-seat-price"> ${formatNumber({ amount: 0 })} </span>
                      <span class="be2stt-discount">${i18next.t("Gratis")} </span>
                  </div>
              `
            : "";

    const discountedTemplate = () =>
        props.isDiscounted && !props.isFree
            ? html`
                  <div class="mt-1 flex items-center">
                      <span class="be2stt-seat-price">${props.formattedPrice}</span>
                      <span class="be2stt-discount">40% <span>DCTO</span> </span>
                  </div>
              `
            : "";

    const bancoEstadoLogoTemplate = () => html`
        <div class="be2stt-client">
            ${i18next.t("Cliente")}
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.12 19.04">
                <path
                    fill="#999999"
                    d="M35.07,11.24l-1.65,7.41A24.4,24.4,0,0,1,25.57,20c-4.83,0-6.84-1.69-6.23-4.4s3.89-4.32,7.85-4.29a13,13,0,0,1,3.92.51l.17-.77c.37-1.63-.73-2.6-3.35-2.6a10.69,10.69,0,0,0-4.82,1.2,2.11,2.11,0,0,1-1.33-2.09,13.73,13.73,0,0,1,7-1.91C33.14,5.64,36,7.24,35.07,11.24Zm-8.28,6.23A14.43,14.43,0,0,0,30.25,17l.64-2.9a10.34,10.34,0,0,0-3.58-.54c-1.92,0-3.35.54-3.63,1.82S24.39,17.47,26.79,17.47Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M145.87,11.24l-1.65,7.41A24.45,24.45,0,0,1,136.37,20c-4.83,0-6.84-1.69-6.24-4.4S134,11.27,138,11.3a13,13,0,0,1,3.92.51l.17-.77c.36-1.63-.73-2.6-3.35-2.6a10.65,10.65,0,0,0-4.82,1.2,2.11,2.11,0,0,1-1.33-2.09,13.68,13.68,0,0,1,7-1.91C143.94,5.64,146.76,7.24,145.87,11.24Zm-8.28,6.23a14.56,14.56,0,0,0,3.46-.43l.64-2.9a10.36,10.36,0,0,0-3.59-.54c-1.92,0-3.34.54-3.63,1.82S135.18,17.47,137.59,17.47Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M54,12.84c1-4.34,5.33-7.23,10.27-7.23A8.23,8.23,0,0,1,69.4,7.15a5.64,5.64,0,0,1-2.61,2.32,4.75,4.75,0,0,0-3.15-1.06,5.55,5.55,0,0,0-5.7,4.4c-.52,2.32.51,4.38,3.78,4.38A6.85,6.85,0,0,0,65.6,16a2.71,2.71,0,0,1,1.58,2.21A11.48,11.48,0,0,1,61.06,20C55.86,20,53,17.36,54,12.84Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M68.28,12.81c.94-4.2,5.3-7.2,10.33-7.2s7.93,3,7,7.2S80.31,20,75.28,20,67.34,17.05,68.28,12.81Zm13.39,0c.58-2.6-.82-4.4-3.75-4.4a5.59,5.59,0,0,0-5.7,4.4c-.58,2.58.83,4.35,3.76,4.35A5.56,5.56,0,0,0,81.67,12.81Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M102.08,18.3a5,5,0,0,1,2.29-2.23,7.51,7.51,0,0,0,4.73,1.35c2.32,0,3.3-.72,3.51-1.63s-.62-1.43-3.47-1.8-5.37-1.26-4.74-4.12c.56-2.52,3.67-4.23,7.81-4.23A11,11,0,0,1,118.06,7a4.65,4.65,0,0,1-2,2.32,9.92,9.92,0,0,0-4.57-1c-2.07,0-3.09.54-3.28,1.4s.43,1.32,3.15,1.69c3.45.45,5.81,1.46,5.21,4.14S112.94,20,108.46,20C104.8,20,102.86,19,102.08,18.3Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M164.63,12.81c.93-4.2,5.29-7.2,10.32-7.2s7.93,3,7,7.2S176.66,20,171.62,20,163.69,17.05,164.63,12.81Zm13.38,0c.58-2.6-.81-4.4-3.75-4.4a5.6,5.6,0,0,0-5.7,4.4c-.57,2.58.83,4.35,3.76,4.35A5.56,5.56,0,0,0,178,12.81Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M161.39,2.07l-.89,4a9.74,9.74,0,0,0-3.22-.43c-4.9,0-9.51,2.77-10.51,7.23S148.85,20,154.23,20a21.5,21.5,0,0,0,7.37-1.34l3.9-17.52Zm-3.26,14.66a10.77,10.77,0,0,1-3.33.43c-3,0-4.64-1.66-4.05-4.32a6.73,6.73,0,0,1,9.07-3.71Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M130.9,6.21h-6.11l.79-3.53-4.07.94-2.62,11.77C118.17,18.65,120.46,20,124,20a10,10,0,0,0,5.22-1.46,2.56,2.56,0,0,0-1.15-2.26,5.67,5.67,0,0,1-2.93.92c-1.62,0-2.65-.51-2.2-2.52l1.29-5.8h6.11a5.24,5.24,0,0,0,.47-1.34A4.34,4.34,0,0,0,130.9,6.21Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M104.57,1.21H89l.79,2.08a1.13,1.13,0,0,1,.06.32.88.88,0,0,1,0,.17L86.31,19.56H100.8a5.15,5.15,0,0,0,.57-1.51,3.69,3.69,0,0,0,.08-1.4H90.86l1.11-5h9.15a6.57,6.57,0,0,0,.53-1.45,5.54,5.54,0,0,0,.11-1.46H92.62l1-4.55h10.28a5.56,5.56,0,0,0,.53-1.46A5,5,0,0,0,104.57,1.21Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M46.39,5.61A17.65,17.65,0,0,0,42,6.18a1.14,1.14,0,0,1-.33.05,1.34,1.34,0,0,1-.35-.05h0L38.59,5.5l-.5,2.25h0L35.46,19.56a10.19,10.19,0,0,0,1.9.14,11.15,11.15,0,0,0,2-.14L41.63,9.33a9.86,9.86,0,0,1,3.79-.75c2.52,0,3.76.95,3.21,3.44L47,19.56a10.51,10.51,0,0,0,1.93.14,11.61,11.61,0,0,0,2-.14l1.85-8.32C53.42,8.13,51.67,5.61,46.39,5.61Z"
                    transform="translate(-1 -0.98)"
                />
                <path
                    fill="#999999"
                    d="M19.14,6.07C19.85,2.84,17.71,1,11.88,1h-.14L3.57,1l.89,2.21a.84.84,0,0,1,.07.35.85.85,0,0,1,0,.23L1,19.53A40.7,40.7,0,0,0,7.79,20c6.25,0,9.73-2.14,10.5-5.63.52-2.32-.63-3.69-2.83-4.35A5.32,5.32,0,0,0,19.14,6.07ZM14.37,14.3c-.45,2.06-2.18,3-5.83,3a30.67,30.67,0,0,1-3.11-.14l1.24-5.57h3.72C13.36,11.56,14.78,12.47,14.37,14.3Zm1-8.15c-.38,1.72-1.73,2.69-4.63,2.69H7.27L8.4,3.78c1.09-.09,2.13-.11,3.13-.11C14.39,3.67,15.67,4.58,15.32,6.15Z"
                    transform="translate(-1 -0.98)"
                />
            </svg>
        </div>
    `;

    const buttonTemplate = () => html`
        <div class="hidden-lg-up">
            <button class="be-mobile-seat-tooltip-btn" @click=${handleClick}>Ok</button>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="be2-seat-tooltip">
            <span class="be2stt-seat-number">${props.designator}</span>
            ${freeTemplate()} ${discountedTemplate()} ${bancoEstadoLogoTemplate()} ${buttonTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import { TravelDocument } from "../../component-models/checkin/TravelDocument";
import { PassengerFormVM } from "../../component-models/passengers/PassengerFormVM";

export const passengerMapperHelpers = () => {
    const concatenateString = (parts: string[]): string =>
        parts
            .map((part) => part || "")
            .join(" ")
            .trim();

    const splitString = (text: string, defaultFirst = ""): string[] =>
        text ? text.split(" ").map((part) => part || "") : [defaultFirst, ""];

    const getPassengerNumber = (passenger: PassengerFormVM): number =>
        passenger.type === "INF" ? passenger.attachedPassengerNumber : passenger.passengerNumber;

    const getSuffix = (passenger: PassengerFormVM): string => (passenger.type === "INF" ? "I" : " ");

    const filterPassports = (passenger: PassengerFormVM, documents: TravelDocument[]): TravelDocument[] => {
        return documents.filter(
            (document) =>
                document.PassengerNumber === getPassengerNumber(passenger) &&
                document.DocSuffix === getSuffix(passenger) &&
                document.TypeCode &&
                document.Number,
        );
    };

    return { concatenateString, filterPassports, splitString };
};

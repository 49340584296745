import { LoadingQueueItem } from "./LoadingQueueItem";

const queue: Map<number, LoadingQueueItem> = new Map();

export const loadingQueueHandler = () => {
    const handleAllLoadingFinished = (): void => {
        Array.from(queue.entries()).forEach(([id, item]) => {
            if (shouldHandleAllLoadingFinished(item)) item.onAllLoadingFinished();

            queue.delete(id);
        });
    };

    const shouldHandleAllLoadingFinished = (item: LoadingQueueItem): boolean =>
        item.status === "ok" && typeof item.onAllLoadingFinished === "function";

    const isLoading = (): boolean => Array.from(queue.values()).some((i) => i.status === "pending");

    const cancelPreviousRequests = (url: string) => {
        const partialUrl = url.split("?")[0];

        const entry = Array.from(queue.entries()).find(
            ([_, item]) =>
                item.status === "pending" &&
                item.url.substring(0, partialUrl.length).toLowerCase() === partialUrl.toLowerCase(),
        );

        if (!entry) return;

        const [alreadyLoadingId, alreadyLoadingItem] = entry;

        if (!alreadyLoadingItem) return;

        alreadyLoadingItem.abortController.abort();
        queue.delete(alreadyLoadingId);
    };

    // EXPORTS

    const addToQueue = (url: string, nonCancellable?: boolean): LoadingQueueItem => {
        if (!url) return undefined;

        if (!nonCancellable) cancelPreviousRequests(url);

        const newItem: LoadingQueueItem = {
            id: Date.now(),
            abortController: new AbortController(),
            status: "pending",
            url,
        };

        queue.set(newItem.id, newItem);

        return newItem;
    };

    const handleQueueItemFinished = (id: number, isResponseOk: boolean, onAllLoadingFinished: () => void) => {
        const finishedItem: LoadingQueueItem = {
            ...queue.get(id),
            status: isResponseOk ? "ok" : "error",
            onAllLoadingFinished,
        };

        queue.set(id, finishedItem);

        if (!isLoading()) handleAllLoadingFinished();
    };

    return { addToQueue, handleQueueItemFinished };
};

import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { LoginType, PERUVIAN_CULTURE_CODE, PERU_COMPRA_PARTIAL_STRING } from "../../shared/commonConstants";
import { LoginVM } from "./login-model";
import { ValidatorPartialMethods } from "./login-page";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { makeNewUrl } from "../../shared/common";
import { useEffect } from "haunted";
import { useAppContext } from "../../managers/useAppContext";

export const name = "ac-peru-compra-login";

export interface Properties {
    vm: LoginVM;
    validatorPartialMethods: ValidatorPartialMethods;
    setVm: (vm: LoginVM) => void;
    handleSubmit: (e: MouseEvent) => Promise<void>;
    setLoginType: (state: LoginType) => void;
}

const PERU_COMPRA_OFFICIAL_HOMEPAGE = "https://www.gob.pe/perucompras";

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        vm: host.vm,
        validatorPartialMethods: host.validatorPartialMethods,
        setVm: host.setVm,
        handleSubmit: host.handleSubmit,
        setLoginType: host.setLoginType,
    };

    // HELPERS

    // EVENT LISTENERS

    const handleContactButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        window.location.href = PERU_COMPRA_OFFICIAL_HOMEPAGE;
    };

    // COMPONENT
    const appContext = useAppContext();

    const formErrors = useErrorMessage({ errorMessage: props.validatorPartialMethods.getFormMessages() });

    useEffect(() => {
        if (appContext.Culture === PERUVIAN_CULTURE_CODE) {
            return;
        }
        window.location.href = makeNewUrl(PERUVIAN_CULTURE_CODE);
    }, []);

    // TEMPLATES

    const loginTypeSelectorTemplate = () => html`
        <ac-login-type-selector
            .showBancoEstado=${false}
            .showCustomerAndAgencyTabs=${false}
            .showPeruCompraTab=${true}
            .loginType=${"PC"}
            .setLoginType=${props.setLoginType}
        ></ac-login-type-selector>
    `;

    const formTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${props.validatorPartialMethods.getMessage("username")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("username")}
                        .label=${i18next.t("DNI")}
                        .autoComplete=${"cc-exp"}
                        .testId=${T.CUSTOMER_LOGIN.DNI}
                        .value=${props.vm.username}
                        .onInput=${(value: string) => props.setVm({ ...props.vm, username: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${props.validatorPartialMethods.getMessage("password")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("password")}
                        .type=${"password"}
                        .label=${i18next.t("Header-PasswordLabel")}
                        .autoComplete=${"new-password"}
                        .testId=${T.CUSTOMER_LOGIN.PASSWORD}
                        .value=${props.vm.password}
                        .onInput=${(value: string) => props.setVm({ ...props.vm, password: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const forgotPasswordTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <a href=${`${ROUTES.ForgotPassword}${`?${PERU_COMPRA_PARTIAL_STRING}=1`}`}
                    >${i18next.t("Olvidé mi contraseña")}</a
                >
            </div>
        </div>
    `;

    const loginButtonContainerTemplate = () => html`
        <div class="login-button-container">
            <button
                @click=${props.handleSubmit}
                class="rounded-primary-btn"
                data-test-id=${T.CUSTOMER_LOGIN.SUBMIT_BUTTON}
            >
                ${i18next.t("Application-Login")}
            </button>
        </div>
    `;

    const contactButtonContainerTemplate = () => html`
        <div class="contact-button-container">
            <button class="rounded-secondary-btn" @click=${handleContactButtonClick}>${i18next.t("Contactar")}</button>
        </div>
    `;

    const peruCompraFormTemplate = () => html`
        <div class="peru-compra-form">
            <h1>${i18next.t("Iniciar Sesión")}</h1>
            ${loginTypeSelectorTemplate()} ${formTemplate()} ${formErrors.htmlTemplate()} ${forgotPasswordTemplate()}
            ${loginButtonContainerTemplate()}
            <div class="contact-text-container">
                ${i18next.t("Si deseas convertirte en miembro, te invitamos a ponerte en contacto con Perú Compras")}
            </div>
            ${contactButtonContainerTemplate()}
        </div>
    `;

    const loginBannerTemplate = () => html`
        <div class="peru-compras-login-banner hidden-sm-down"></div>
        <div class="peru-compras-login-banner hidden-md-up hidden-xs"></div>
        <div class="peru-compras-login-banner hidden-sm-up"></div>
    `;

    return appContext.Culture === PERUVIAN_CULTURE_CODE
        ? html`
              <div class="peru-compras-login-content"></div>
              <div id="mainContentWrapper" class="login-page push-down">
                  <section class="booking-wrapper ts-error-parent ts-error-container no-breadcrumb peru-compra">
                      <div class="row">${loginBannerTemplate()} ${peruCompraFormTemplate()}</div>
                  </section>
              </div>
          `
        : html``;
};

import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { html } from "haunted";
import i18next from "i18next";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { StationSettings } from "../../component-models/StationSettings";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useFlightItineraryInfo } from "../shared/flight-itinerary-info/useFlightItineraryInfo";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { useMemo } from "../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { usePunctualityPerformanceModal } from "./punctuality-performance-modal/usePunctualityPerformanceModal";

export interface Props {
    context: FlightPageContext;
    flightOption: FlightOptionModel;
    isInFlightMove: boolean;
    stationSettings: StationSettings;
}

export const useFlightInfo = (props: Props) => {
    const modal = usePunctualityPerformanceModal({
        cancelPercentage: props.flightOption.FlightSelectModel.PunctualityPerformance?.PercentOfCancellations,
        delayPercentage: props.flightOption.FlightSelectModel.PunctualityPerformance?.PercentOfDelaysGreaterThan30Min,
        flightNumber: props.flightOption.FlightSelectModel.FlightNumber,
        operator: props.flightOption.FlightSelectModel.Segments[0].Legs[0].CarrierNameToDisplay,
    });

    const inFlightOption = useMemo(
        () =>
            (props.flightOption.JourneyIndex === OUTBOUND && !props.context.bundleState?.selectedOutboundBundle) ||
            (props.flightOption.JourneyIndex === INBOUND && !props.context.bundleState?.selectedInboundBundle) ||
            (props.context?.bundleState?.bundleOffers?.length || 0) === 0,
        [props.context.bundleState, props.flightOption.JourneyIndex],
    );

    const flightItineraryInfo = useFlightItineraryInfo({
        fillSpace: inFlightOption,
        isBundleSelected: false,
        isForCheckin: false,
        isInFlightMove: props.isInFlightMove,
        itineraryData: {
            ArrivalDate: props.flightOption.ArrivalDate,
            ArrivalTime: props.flightOption.FlightSelectModel.Sta24H,
            DepartureDate: props.flightOption.DepartureDate,
            DepartureTime: props.flightOption.FlightSelectModel.Std24H,
            DestinationAirport: props.flightOption.FlightSelectModel.DestinationAirport,
            DestinationCity: props.flightOption.FlightSelectModel.DestinationCity,
            DestinationIata: props.flightOption.FlightSelectModel.DestinationIata,
            FlightIndex: props.flightOption.Index,
            FlightNumber: props.flightOption.FlightSelectModel.FlightNumber,
            Hours: props.flightOption.Hours,
            JourneyIndex: props.flightOption.JourneyIndex,
            LegDetails: props.flightOption.LegDetails,
            Minutes: props.flightOption.Minutes,
            OriginAirport: props.flightOption.FlightSelectModel.OriginAirport,
            OriginCity: props.flightOption.FlightSelectModel.OriginCity,
            OriginIata: props.flightOption.FlightSelectModel.OriginIata,
            PunctualityPerformance: props.flightOption.FlightSelectModel.PunctualityPerformance,
            Segments: props.flightOption.FlightSelectModel.Segments,
        },
        stationSettings: props.stationSettings,
    });

    const isBrazilianStationSelected = useMemo(
        () =>
            props.stationSettings.Countries.find((country) => country.Code === "BR").Regions.some((region) =>
                region.Stations.some(
                    (station) =>
                        station.Code === props.flightOption.FlightSelectModel.OriginIata ||
                        station.Code === props.flightOption.FlightSelectModel.DestinationIata,
                ),
            ),
        [props.stationSettings, props.flightOption],
    );

    const isANACInfoAvailable = useMemo(
        () =>
            props.flightOption.FlightSelectModel.PunctualityPerformance?.PercentOfCancellations ||
            props.flightOption.FlightSelectModel.PunctualityPerformance?.PercentOfDelaysGreaterThan30Min ||
            props.flightOption.FlightSelectModel.PunctualityPerformance?.PercentOfDelaysGreaterThan60Min,
        [props.flightOption.FlightSelectModel.PunctualityPerformance],
    );

    const hideANACLink = useMemo(
        () =>
            (props.context.flightState?.selectedInboundFlight && props.flightOption.JourneyIndex === INBOUND) ||
            (props.context.flightState?.selectedOutboundFlight && props.flightOption.JourneyIndex === OUTBOUND),
        [props.context.flightState, props.flightOption],
    );

    const showSelectedView = useMemo(
        () => props.context.showSelectedView(props.flightOption),
        [props.flightOption, props.context.showSelectedView],
    );

    // EVENT LISTENERS

    const handleOpenPunctualityModalClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.open();
    };

    // TEMPLATES

    const operatorTemplate = () => html`
        <span>
            ${unsafeHTML(
                i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                    operatingCarrier: props.flightOption.FlightSelectModel.Segments[0].Legs[0].CarrierNameToDisplay,
                }),
            )}
        </span>
    `;

    const governmentApprovalTemplate = () =>
        props.flightOption.SubjectToGovtApproval
            ? html`
                  <div
                      class=${classNames(
                          "relative top-1 ml-[10px] text-center text-xs text-brand-secondary",
                          "before:content-['*_']",
                          "sm:static sm:top-auto sm:text-left",
                      )}
                  >
                      ${props.context.model.FlightDataViewModel.SubjectToGovtApprovalText}
                  </div>
              `
            : "";

    const carrierInfoTemplate = () =>
        !showSelectedView
            ? html`
                  <div
                      class=${classNames(
                          "relative top-1 ml-[10px] mt-7 text-center text-xs text-brand-secondary",
                          "before:content-['*_']",
                          "sm:static sm:top-auto sm:mt-3 sm:text-left",
                      )}
                      data-test-id=${getTestId(T.FLIGHT.OPERATOR, {
                          j: props.flightOption.JourneyIndex,
                          i: props.flightOption.Index,
                      })}
                  >
                      ${operatorTemplate()}
                  </div>
                  ${governmentApprovalTemplate()}
              `
            : "";

    const ANACInfoTemplate = () =>
        !showSelectedView && isBrazilianStationSelected && isANACInfoAvailable && !hideANACLink
            ? html`
                  <div class="h-5">
                      <span class="ppm-opener-link" @click=${handleOpenPunctualityModalClick}
                          >${i18next.t("Desempeño de puntualidad")}</span
                      >
                  </div>
              `
            : "";

    const selectedFlightHeaderTemplate = () =>
        showSelectedView
            ? html`
                  <div
                      class="direction pb-4"
                      data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO_CONTENT, {
                          j: props.flightOption.JourneyIndex,
                          i: props.flightOption.Index,
                      })}
                  >
                      <span>
                          ${props.flightOption.JourneyIndex === OUTBOUND
                              ? i18next.t("PriceDisplay-DepartingFlight")
                              : i18next.t("PriceDisplay-ReturningFlight")}
                      </span>
                      ${props.flightOption.FormattedFlightDate}
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        ${selectedFlightHeaderTemplate()}
        <div class="pt-2 sm:pb-6">${flightItineraryInfo.htmlTemplate()}</div>
        ${ANACInfoTemplate()} ${carrierInfoTemplate()} ${modal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

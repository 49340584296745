import i18next from "i18next";
import { CHILE_COUNTRY_CODE } from "../../shared/commonConstants";
import { ContactPassenger } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { useAppContext } from "../../managers/useAppContext";

export interface Props {
    model: ItineraryPageViewModel;
    contactPassengers: ContactPassenger[];
    setIsDataProvided: (isDataProvided: boolean) => void;
}

export const useItineraryPageTabsPassengersNonEditableForm = (props: Props) => {
    const appContext = useAppContext();

    // HELPERS

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const passengerNameTemplate = (pax: ContactPassenger) => {
        const passengerNameClassMap = classMap({
            "i2-passengers-divider": true,
            "block": pax.PaxIndex > 0,
            "hidden": pax.PaxIndex === 0,
        });

        return html`
            <div class=${passengerNameClassMap}></div>
            <div class="i2-passengers-index">${i18next.t("Pasajero")} ${pax.PaxIndex + 1}</div>
            <div class="i2-passengers-name">${pax.Name} <i class="js-icon js-flight-tick"></i></div>
        `;
    };

    const emailInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-7-12">
            <div class="i2-passengers-provided-label">${i18next.t("Correo electrónico")}</div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_EMAIL, { c: "read", p: pax.PaxIndex })}
            >
                ${pax.PaxAddress.Email}
            </div>
        </div>
    `;

    const phonePrefixTemplate = (pax: ContactPassenger) => html`
        <div class="i2-passengers-phone-prefix">
            <div class="i2-passengers-provided-label">${i18next.t("Código país")}</div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_PHONE_PREFIX, { c: "read", p: pax.PaxIndex })}
            >
                +${pax.PaxAddress?.PhonePrefix || ""}
            </div>
        </div>
    `;

    const phoneNumberTemplate = (pax: ContactPassenger) => html`
        <div class="i2-passengers-phone-number">
            <div class="i2-passengers-provided-label">${i18next.t("Teléfono")}</div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_PHONE_NUMBER, { c: "read", p: pax.PaxIndex })}
            >
                ${pax.PaxAddress?.PhoneNumber || ""}
            </div>
        </div>
    `;

    const phoneInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-5-12">
            <div class="i2-passengers-phone-container">${phonePrefixTemplate(pax)} ${phoneNumberTemplate(pax)}</div>
        </div>
    `;

    const countryInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-4-12">
            <div class="i2-passengers-provided-label">${i18next.t("País")}</div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_COUNTRY, { c: "read", p: pax.PaxIndex })}
            >
                ${appContext.Countries.find((country) => country.Value === pax.PaxAddress.Country)?.Text || ""}
            </div>
        </div>
    `;

    const comunaInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-3-12">
            <div class="i2-passengers-provided-label">
                ${pax.PaxAddress.Country === CHILE_COUNTRY_CODE ? i18next.t("Comuna") : i18next.t("Ciudad")}
            </div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_CITY, { c: "read", p: pax.PaxIndex })}
            >
                ${pax.PaxAddress?.City || ""}
            </div>
        </div>
    `;

    const addressInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-5-12">
            <div class="i2-passengers-provided-label">${i18next.t("Dirección")}</div>
            <div
                class="i2-passengers-provided-data"
                data-test-id=${getTestId(T.ITINERARY.PAX_ADDRESS, { c: "read", p: pax.PaxIndex })}
            >
                ${pax.PaxAddress?.Address || ""}
            </div>
        </div>
    `;

    const contactPassengerTemplate = (pax: ContactPassenger) => html`
        ${passengerNameTemplate(pax)}
        <div class="i2-passengers-form">
            <div class="row">${emailInputTemplate(pax)} ${phoneInputTemplate(pax)}</div>
            <div class="row">${countryInputTemplate(pax)} ${comunaInputTemplate(pax)} ${addressInputTemplate(pax)}</div>
        </div>
    `;

    const contentTemplate = () =>
        html`
            <div class="i2-passengers-container">
                ${props.contactPassengers.map(contactPassengerTemplate)}
            </form>
        `;

    const htmlTemplate = () => html`
        <div class="passengers-tab">
            <div class="i2-tab-title">
                <i class="js-icon-refund js-refund-rw-passengers"></i>
                <div>${i18next.t("Información de Pasajeros")}</div>
            </div>
            <div class="i2-tab-content">${contentTemplate()}</div>
        </div>
        <div class="modal-button-container">
            <button
                class="rounded-primary-btn white-blue"
                data-test-id=${T.ITINERARY.PAX_FORM_EDIT_BUTTON}
                @click=${() => props.setIsDataProvided(false)}
            >
                ${i18next.t("Editar")}
            </button>
        </div>
    `;

    return { htmlTemplate };
};

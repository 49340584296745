import { commonDebug } from "../../bootstrap";
import { WarningStatus } from "../../component-helpers/flight/FlightHelper";
import { UserContext } from "../../component-models/app/UserContext";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";

const localStorageItemIdPrefix = "jsdc";

export interface DcMembershipExtensionHandlerProps {
    model: FlightPageViewModel;
}

export const useDcMembershipExtensionHandler = (props: DcMembershipExtensionHandlerProps) => {
    const showWarning15 = (userContext: UserContext) => userContext.dc.showWarning15 || props.model.DiscountClubViewModel.ShowWarning15;

    const showWarning30 = (userContext: UserContext) => userContext.dc.showWarning30 || props.model.DiscountClubViewModel.ShowWarning30;

    const localStorageItemId = (userContext: UserContext) => {
        const programNumber = userContext.dc.programNumber || props.model.DiscountClubViewModel.ProgramNumber;
        return `${localStorageItemIdPrefix}${programNumber}`;
    };

    // HELPERS

    const showLongTermWarning = (warningStatus: WarningStatus, userContext: UserContext) =>
        warningStatus.longTermWarningShown === 0 && showWarning30(userContext) && !showWarning15(userContext);

    const showShortTermWarning = (warningStatus: WarningStatus, userContext: UserContext) =>
        warningStatus.shortTermWarningShown === 0 && showWarning15(userContext);

    const storeExtensionWarningStatus = (warningStatus: WarningStatus, userContext: UserContext) =>
        localStorage.setItem(localStorageItemId(userContext), JSON.stringify(warningStatus));

    // EXPORTS

    const handleExtensionWarning = (userContext: UserContext) => {
        try {
            const warningStatusRaw = localStorage.getItem(localStorageItemId(userContext));

            const warningStatus = warningStatusRaw
                ? (JSON.parse(warningStatusRaw) as WarningStatus)
                : {
                      programNumber: props.model.DiscountClubViewModel.ProgramNumber,
                      longTermWarningShown: 0,
                      shortTermWarningShown: 0,
                  };

            if (showLongTermWarning(warningStatus, userContext)) {
                warningStatus.longTermWarningShown = 1;
                storeExtensionWarningStatus(warningStatus, userContext);
            }

            if (showShortTermWarning(warningStatus, userContext)) {
                warningStatus.longTermWarningShown = 1;
                warningStatus.shortTermWarningShown = 1;
                storeExtensionWarningStatus(warningStatus, userContext);
            }
        } catch (e) {
            commonDebug.error("Could not place membership extension cookie.");
        }
    };
    const displayExtensionWarning = (userContext: UserContext) => {
        try {
            const warningStatusRaw = localStorage.getItem(localStorageItemId(userContext));

            if (!warningStatusRaw) return true;

            const warningStatus = JSON.parse(warningStatusRaw) as WarningStatus;

            return showLongTermWarning(warningStatus, userContext) || showShortTermWarning(warningStatus, userContext);
        } catch (e) {
            commonDebug.error("Could not offer membership extension.");
        }

        return false;
    };

    return {
        showWarning15,
        showWarning30,
        displayExtensionWarning,
        handleExtensionWarning,
    };
};

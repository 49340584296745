import { TemplateResult } from "lit-html";
import { commonDebug } from "../../../bootstrap";
import { useTealiumManager } from "../../../managers/Tealium/useTealiumManager";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import DomCrawlingHelper from "../../DomCrawlingHelper";
import { CLASS_NAMES } from "../../classNames";
import { getCoords, getHtmlElementAttributeNames, getRenderString } from "../../common";
import { useEffect, useState } from "../../haunted/CustomHooks";
import { FormError } from "../useForm/FormError";
import { InputFieldAttribute, UDF_ATTR_REQUIRED } from "../useForm/InputFieldAttribute";
import { InputFieldAttributeValidator } from "../useForm/InputFieldAttributeValidator";
import { emailFormat } from "../useForm/custom-attributes/emailFormat";
import { exactLength } from "../useForm/custom-attributes/exactLength";
import { maxLength } from "../useForm/custom-attributes/maxLength";
import { minLength } from "../useForm/custom-attributes/minLength";
import { noSpecialCharacters } from "../useForm/custom-attributes/noSpecialCharacters";
import { required } from "../useForm/custom-attributes/required";
import { UDF_FIELD_ERROR_CLASS, UDF_FORM_ERROR_CONTAINER_CLASS } from "../useForm/useForm";
import { createRange } from "../../../component-helpers/collectionHelper";

export interface SuperFormProps {
    formElem: HTMLElement;
    customAttributes: InputFieldAttribute[];
    formSectionContainerClassName?: string;
    noScroll?: boolean;
}

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export const useSuperForm = (props: SuperFormProps) => {
    const getInputFields = (sectionNumber?: number): InputElement[] => {
        const elem =
            props.formSectionContainerClassName && sectionNumber !== undefined
                ? formSections()[sectionNumber]
                : props.formElem;

        return elem ? Array.from(elem.querySelectorAll("select, input, textarea")) as InputElement[] : [];
    };

    const formSections = () =>
        Array.from(props.formElem.querySelectorAll(`.${props.formSectionContainerClassName}`)) as HTMLElement[];

    const getCustomAttributeByName = (input: InputElement, attribute: string) =>
        getCustomAttributes(input).find((a) => a.name === attribute);

    const getCustomAttributes = (input: InputElement) => [
        exactLength(input),
        emailFormat(),
        maxLength(input),
        minLength(input),
        noSpecialCharacters(),
        required(),
        ...props.customAttributes,
    ];

    const getGoverningAttributeNames = (input: InputElement): string[] =>
        getCustomAttributes(input)
            .filter((a) => getHtmlElementAttributeNames(input).includes(a.name))
            .map((customAttribute) => customAttribute.governingFieldAttributeName)
            .filter((item) => item);

    const nextParentInDOM = (element: HTMLElement): HTMLElement =>
        element !== props.formElem && element.parentElement && element.parentElement !== document.body
            ? element.parentElement
            : undefined;

    const hasOneSiblingWithAttribute = (element: HTMLElement, htmlAttribute: string): boolean =>
        Array.from(element.querySelectorAll(querySelectorStringForAttribute(htmlAttribute))).length === 1;

    const getSiblingInputFieldWithAttribute = (input: InputElement, htmlAttributeName: string): InputElement => {
        let parent = input.parentElement;

        while (!hasOneSiblingWithAttribute(parent, htmlAttributeName)) {
            parent = nextParentInDOM(parent);

            if (!parent) {
                commonDebug.error(
                    `There is NOT exactly ONE sibling element with the attribute "${htmlAttributeName}".`,
                );
                return undefined;
            }
        }

        return getSiblingWithAttribute(parent, htmlAttributeName);
    };

    const getSiblingWithAttribute = (parent: HTMLElement, htmlAttributeName: string): InputElement =>
        parent.querySelector(querySelectorStringForAttribute(htmlAttributeName)) as InputElement;

    const querySelectorStringForAttribute = (htmlAttributeName: string): string =>
        `input[${htmlAttributeName}], select[${htmlAttributeName}], textarea[${htmlAttributeName}]`;

    const isFieldInRightRelation = (
        subject: InputElement,
        verb: "governs" | "isGovernedBy",
        object: InputElement,
    ): boolean => {
        const mainInput = verb === "governs" ? subject : object;
        const subordinateInput = verb === "governs" ? object : subject;

        return getHtmlElementAttributeNames(mainInput).some((superiorAttribute) =>
            getGoverningAttributeNames(subordinateInput).some(
                (subordinateAttribute) =>
                    superiorAttribute === subordinateAttribute &&
                    getSiblingInputFieldWithAttribute(
                        subordinateInput,
                        verb === "governs" ? superiorAttribute : subordinateAttribute,
                    ) === mainInput,
            ),
        );
    };

    const getGoverningRelations = (fields: InputElement[], input: InputElement) => {
        const fieldsGoverningThis = fields.filter(
            (otherInput) => otherInput !== input && isFieldInRightRelation(otherInput, "governs", input),
        );

        const fieldsGovernedByThis = fields.filter(
            (otherInput) => otherInput !== input && isFieldInRightRelation(otherInput, "isGovernedBy", input),
        );

        return { fieldsGoverningThis, fieldsGovernedByThis };
    };

    const validateForValidator = async (
        fields: InputElement[],
        input: InputElement,
        validator: InputFieldAttributeValidator,
        governingFieldAttributeName?: string,
    ): Promise<FormError> => {
        const governingField = governingFieldAttributeName
            ? getGoverningRelations(fields, input).fieldsGoverningThis.find((governingField) =>
                  getHtmlElementAttributeNames(governingField).includes(governingFieldAttributeName),
              )
            : undefined;

        const isValid = await validator.validate(input, governingField);

        return isValid ? undefined : validator.errorMessage;
    };

    const removeFieldErrors = (input: InputElement): void => {
        if (input.type === "hidden") return;

        const errorsToRemove = DomCrawlingHelper.getArrayOfClass(
            input
                ? input.type === "checkbox"
                    ? input.parentElement.parentElement
                    : input.parentElement
                : props.formElem,
            UDF_FIELD_ERROR_CLASS,
        );

        errorsToRemove.forEach((e) => e.remove());
    };

    const addInvalidClass = (input: InputElement): void =>
        (input.type === "checkbox" ? input.parentElement : input).classList.add(CLASS_NAMES.invalid);

    const removeInvalidClass = (input: InputElement): void =>
        (input.type === "checkbox" ? input.parentElement : input).classList.remove(CLASS_NAMES.invalid);

    const updateField = async (
        fields: InputElement[],
        input: InputElement,
    ): Promise<{ isValid: boolean; errorMessages: FormError[] }> => {
        removeFieldErrors(input);

        let isValid = true;

        const errorMessages: FormError[] = [];

        if (shouldSkipValidation(input)) {
            return { isValid, errorMessages };
        }

        for (const attributeName of getHtmlElementAttributeNames(input)) {
            const customAttribute = getCustomAttributeByName(input, attributeName);
            const validators = customAttribute?.validators || [];

            for (const validator of validators) {
                const error = await validateForValidator(
                    fields,
                    input,
                    validator,
                    customAttribute.governingFieldAttributeName,
                );

                if (error) {
                    errorMessages.push(error);
                    isValid = false;
                }
            }
        }

        if (isValid) {
            removeInvalidClass(input);
        } else {
            addFieldErrors(input, errorMessages);
            addInvalidClass(input);
        }

        return { isValid, errorMessages };
    };

    const addFieldErrors = (input: InputElement, errorMessages: FormError[]): void =>
        errorMessages
            .filter((message) => message.scope === "field")
            .forEach((message) => {
                const newError = document.createElement("DIV");
                newError.classList.add(UDF_FIELD_ERROR_CLASS);
                if (message.message instanceof TemplateResult) {
                    newError.innerHTML = getRenderString(message.message);
                } else {
                    newError.classList.add(CLASS_NAMES.ErrorMessageContainer);

                    const newSpan = document.createElement("SPAN");
                    newSpan.dataset.testId = getTestId(T.COMMON.FORM_FIELD_ERROR, { c: message.id });
                    newSpan.textContent = message.message as string;

                    newError.appendChild(newSpan);
                }

                if (input.type === "checkbox") {
                    input.parentElement.parentElement.appendChild(newError);
                } else {
                    input.parentElement.appendChild(newError);
                }
            });

    const validate = async (formSectionNumber?: number): Promise<boolean> => {
        let isValid = true;
        const fields = getInputFields(formSectionNumber);

        setValidatedSections(
            formSectionNumber === undefined
                ? createRange(0, formSections().length - 1)
                : Array.from(new Set<number>([...validatedSections, formSectionNumber]).values()),
        );

        setLastValidation(Date.now());

        let errorMessages: FormError[] = [];

        for (const input of fields) {
            const fieldValidationResult = await updateField(fields, input);
            isValid = fieldValidationResult.isValid && isValid;
            errorMessages = errorMessages.concat(fieldValidationResult.errorMessages);
        }

        updateFormErrors(errorMessages);

        const messages = [...new Set(errorMessages.filter((e) => e.scope === "form"))];

        window.setTimeout(() => scrollToFirstFormError(), 0);

        try {
            if (messages.length > 0) {
                tealiumManager.logValidationError(messages.map((m) => m.message) as string[]);
            }
        } catch (e) {
            commonDebug.error(e);
        }

        return isValid;
    };

    const shouldSkipValidation = (input: InputElement): boolean => !isVisible(input) || isDisabled(input);

    const isVisible = (input: InputElement): boolean =>
        (input.offsetHeight > 0 || input.type === "checkbox") && DomCrawlingHelper.isElementVisible(input);

    const isDisabled = (input: InputElement): boolean => DomCrawlingHelper.isElementDisabled(input);

    const scrollToFirstFormError = (): void => {
        if (props.noScroll) return;

        window.setTimeout(() => {
            const firstError = props.formElem.querySelector(
                `.${CLASS_NAMES.invalid}, .${CLASS_NAMES.stickyInvalid}, .${CLASS_NAMES.error}`,
            ) as HTMLInputElement;

            if (firstError) {
                const topOfElement = getCoords(firstError).top - 250;
                window.scroll({
                    top: topOfElement,
                    behavior: "smooth",
                });
            }
        }, 0);
    };

    const removeFormErrors = (): void => {
        if (!props.formElem.parentElement) return;

        DomCrawlingHelper.getElemByClass(props.formElem.parentElement, UDF_FORM_ERROR_CONTAINER_CLASS)?.remove();
    };

    const updateFormErrors = (errorMessages: FormError[]): void => {
        if (!props.formElem.parentElement) return;

        removeFormErrors();

        const messages = [...new Set(errorMessages.filter((e) => e.scope === "form"))];
        const distinctFormErrorMessages: string[] = getDistinctFormErrorMessages(messages);

        if (distinctFormErrorMessages.length > 0) {
            const newErrorContainer = addFormErrorContainerToDom();
            distinctFormErrorMessages.forEach((errorMessage) => {
                addFormErrorMessageToDom(newErrorContainer, errorMessage);
            });
        }
    };

    const addFormErrorMessageToDom = (errorContainer: HTMLElement, errorMessage: string): void => {
        const newError = document.createElement("SPAN");
        newError.textContent = errorMessage;
        errorContainer.appendChild(newError);
    };

    const addFormErrorContainerToDom = (): HTMLDivElement => {
        const newErrorContainer = document.createElement("DIV") as HTMLDivElement;
        newErrorContainer.classList.add(UDF_FORM_ERROR_CONTAINER_CLASS, CLASS_NAMES.ErrorMessageContainer);
        newErrorContainer.dataset.testId = T.COMMON.FORM_ERROR;
        insertAfter(newErrorContainer, props.formElem);

        return newErrorContainer;
    };

    const insertAfter = (newElement: HTMLElement, afterElement: HTMLElement) =>
        afterElement.parentNode.insertBefore(newElement, afterElement.nextSibling);

    const getDistinctFormErrorMessages = (errors: FormError[]): string[] =>
        Array.from(new Set<string>(errors.reduce((aggr, curr) => aggr.concat([curr.message]), [])).values());

    const validateGovernedFields = async (fields: InputElement[], input: InputElement): Promise<void> => {
        for (const governedField of getGoverningRelations(fields, input).fieldsGovernedByThis) {
            await updateField(fields, governedField);
        }
    };

    const validateForOneAttribute = async (
        fields: InputElement[],
        input: InputElement,
        validator: InputFieldAttributeValidator,
    ): Promise<FormError> => {
        if (shouldSkipValidation(input)) {
            return undefined;
        }

        return validateForValidator(fields, input, validator);
    };

    const revalidateRequiredFields = async (fields: InputElement[]): Promise<FormError[]> => {
        const errorMessages: FormError[] = [];

        for (const input of fields) {
            if (input.hasAttribute(UDF_ATTR_REQUIRED) && !shouldSkipValidation(input)) {
                const result = await validateForOneAttribute(
                    fields,
                    input,
                    getCustomAttributes(input).find((a) => a.name === UDF_ATTR_REQUIRED).validators[0],
                );

                if (result) {
                    errorMessages.push(result);
                }
            }
        }

        return errorMessages;
    };

    const handleBlur = async (e: Event) => {
        const input = e.target as InputElement;

        const sectionNumber = props.formSectionContainerClassName
            ? formSections().indexOf(DomCrawlingHelper.findParentByClass(input, props.formSectionContainerClassName))
            : undefined;

        if (sectionNumber !== undefined && !validatedSections.includes(sectionNumber)) return;

        const fields = getInputFields(sectionNumber);

        await updateField(fields, input);
        await validateGovernedFields(fields, input);

        const errorMessages = await revalidateRequiredFields(fields);

        updateFormErrors(errorMessages);
    };

    const reAddBlurListener = (input: InputElement) => {
        if (input instanceof HTMLSelectElement) {
            input.removeEventListener("change", handleBlur);
            input.addEventListener("change", handleBlur);
        } else {
            input.removeEventListener("blur", handleBlur);
            input.addEventListener("blur", handleBlur);
        }
    };

    const reAddBlurListeners = () =>
        props.formSectionContainerClassName
            ? validatedSections.forEach((section) => getInputFields(section).forEach(reAddBlurListener))
            : getInputFields().forEach(reAddBlurListener);

    const tealiumManager = useTealiumManager();

    const [validatedSections, setValidatedSections] = useState<number[]>([]);
    const [lastValidation, setLastValidation] = useState<number>(0);

    useEffect(reAddBlurListeners, [lastValidation, validatedSections]);

    return {
        validate,
    };
};

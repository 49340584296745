import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html } from "haunted";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);

export const name = "ac-time-zone-adapter";

export const observedAttributes: (keyof Attributes)[] = ["time"];

export interface Attributes {
    time: string;
}

export interface Props {
    time: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        time: host.time,
    };

    const getTime = () => {
        const offset = new Date().getTimezoneOffset() * -1;
        return dayjs(props.time)
            .utcOffset(offset)
            .format("DD/MM/YYYY hh:mm a");
    };

    return props.time
        ? html`
              <span>${getTime()}</span>
          `
        : html``;
};

import i18next from "i18next";
import { html } from "haunted";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { MultilegFlightLegInfo } from "../../component-models/shared/MultilegFlightLegInfo";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useModal } from "./useModal";

export interface Props {
    legDetails: MultilegFlightLegInfo[];
}

export const useMultilegModal = (props: Props) => {
    const isLastStop = (i: number) => props.legDetails.length === i + 1;

    const nonLastStopTemplate = (leg: MultilegFlightLegInfo, i: number) => html`
        ${i === 0
            ? html`
                  <div class="multileg-place">
                      <span class="multileg-time">${leg.Departure}</span>
                      <span class="multileg-city">${leg.OriginCity}</span>
                      <span class="multileg-iata">(${leg.OriginIata})</span>
                  </div>
              `
            : ""}

        <div class="multileg-route">
            <i class="js-icon js-flight-plane"></i>
            <div class="multileg-details">
                <span class="multileg-flight"> ${i18next.t("V2-FlightNumber")} ${leg.FlightNumber} </span>
                <span class="multileg-duration">
                    ${i18next.t("Stop-FlightTime {{-hours}} {{-label}} {{-minutes}}", {
                        hours: leg.FlightTimeHours,
                        label: Number(leg.FlightTimeHours) === 1 ? i18next.t("Stop-Hour") : i18next.t("Stop-Hours"),
                        minutes: leg.FlightTimeMinutes,
                    })}
                </span>
                <span class="multileg-carrier">
                    ${unsafeHTML(
                        i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                            operatingCarrier: leg.CarrierNameToDisplay,
                        }),
                    )}
                </span>
            </div>
        </div>
        <div class="multileg-place">
            <span class="multileg-time">
                ${leg.Arrival}
                <span class="multileg-place-icon">+1</span>
            </span>
            <span class="multileg-city">${leg.DestinationCity}</span>
            <span class="multileg-iata">(${leg.DestinationIata})</span>
            <span class="multileg-break visible-xs"></span>
            <span class="multileg-wait">
                <span>---</span>
                <i class="js-icon js-terminal-watch"></i>
                <span>
                    ${i18next.t("Stop-StopTime {{-hours}} {{-label}} {{-minutes}}", {
                        hours: leg.StopTimeHours,
                        label: Number(leg.StopTimeHours) === 1 ? i18next.t("Stop-Hour") : i18next.t("Stop-Hours"),
                        minutes: leg.StopTimeMinutes,
                    })}
                </span>
            </span>
        </div>
    `;

    const lastStopTemplate = (leg: MultilegFlightLegInfo) => html`
        <div class="multileg-route">
            <i class="js-icon js-flight-plane"></i>
            <div class="multileg-details">
                <span class="multileg-flight"> ${i18next.t("V2-FlightNumber")} ${leg.FlightNumber} </span>
                <span class="multileg-duration">
                    ${i18next.t("Stop-FlightTime {{-hours}} {{-label}} {{-minutes}}", {
                        hours: leg.FlightTimeHours,
                        label: Number(leg.FlightTimeHours) === 1 ? i18next.t("Stop-Hour") : i18next.t("Stop-Hours"),
                        minutes: leg.FlightTimeMinutes,
                    })}
                </span>
                <span class="multileg-carrier">
                    ${unsafeHTML(
                        i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                            operatingCarrier: leg.CarrierNameToDisplay,
                        }),
                    )}
                </span>
            </div>
        </div>
        <div class="multileg-place">
            <span class="multileg-time">${leg.Arrival}</span>
            <span class="multileg-city">${leg.DestinationCity}</span>
            <span class="multileg-iata">(${leg.DestinationIata})</span>
        </div>
    `;

    const htmlTemplate = () =>
        html` <div class="modal-body">
            ${props.legDetails.map((leg, i) => (isLastStop(i) ? lastStopTemplate(leg) : nonLastStopTemplate(leg, i)))}
        </div>`;

    const modal = useModal({
        closing: { isClosable: true },
        content: { template: htmlTemplate },
        header: { template: () => i18next.t("Stop-ModalTitle") },
        overlay: { classNames: "multileg-modal", testId: T.MULTILEG_MODAL.CONTAINER },
    });

    return modal;
};

export const LOADER_CLASS_NAMES = {
    Baggage: "inner-package-box",
    CommonLoaderWrapper: "booking-wrapper",
    Cug: "cug-content",
    CugUsers: "users-grid-container",
    CugModal: "cug-border-box",
    CugModal2: "modal-content",
    CugParent: "cug-grid-container",
    CugParent2: "cug-form-page",
    ExtrasPage: "extras-page",
    ExtrasCheckin: "extras-step",
    ExtrasFlexi: "ts-flexi-fee",
    ExtrasPet: "ts-pet-in-cabin",
    ExtrasPriorityBoarding: "ts-priority-boarding",
    Generic: "for-loader",
    Modal: "modal-body",
    RetargetingTemporaryLoaderParent: "RTloader-parentoverflow",
    Row: "row",
    SpaSection: "spa-content",
    SpaIntro: "content-intro",
    Standalone: "standalone-loader",
    Pet: "pet-loader",
    TemporaryLoaderParent: "DG_Loader_Container_Class",
};

import { InputFieldAttribute, UDF_ATTR_LENGTH } from "../InputFieldAttribute";
import { InputElement } from "../useForm";

export const exactLength = (input: InputElement): InputFieldAttribute =>
     ({
        name: UDF_ATTR_LENGTH,
        validators: [
            {
                errorMessage: {
                    id: "lengthError",
                    message:
                        window.formResources.fieldMustHave +
                        `${input.getAttribute(UDF_ATTR_LENGTH)}` +
                        window.formResources.charactersLength,
                    scope: "field",
                },

                validate: (field: InputElement) => {
                    const result =
                        field.value.length === 0 || field.value.length === Number(field.getAttribute(UDF_ATTR_LENGTH));

                    return Promise.resolve(result);
                },
            },
        ],
    });


import i18next from "i18next";
import { ApiBoardingPassFlightStop } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";

export const useOperatorAndStopoverInfo = () => {
    const operatorInfo = (model: ApiBoardingPassFlightStop) =>
        `*${i18next.t("Vuelo operado por")} ${model.Details[0].OperationInfo}`;

    const commonPlaceholder = (model: ApiBoardingPassFlightStop) => ({
        arrivalStationCode: model.Details[0].ArrivalStationCode,
        arrivalStationShortName: model.Details[0].ArrivalStationShortName,
        numberOfStops: model.NumberOfStops,
        plural: model.Details[0].StoppingHours !== 1 ? "s" : "",
        stoppingHours: model.Details[0].StoppingHours,
        stoppingMinutes: model.Details[0].StoppingMinutes,
    });

    const multiLegFlightLines = (isMobile: boolean, model: ApiBoardingPassFlightStop) => {
        if (!model.IsMultileg) return [];

        return isMobile
            ? [
                  i18next.t("Stop-MobileOneStopStopoverInfo1"),
                  i18next.t(
                      "Stop-MobileOneStopStopoverInfo2 {{arrivalStationShortName}}{{arrivalStationCode}}{{stoppingHours}}{{plural}}{{stoppingMinutes}}",
                      commonPlaceholder(model),
                  ),
              ]
            : [
                  i18next.t(
                      "Stop-BoardingPassStopover {{numberOfStops}}{{arrivalStationShortName}}{{arrivalStationCode}}{{stoppingHours}}{{plural}}{{stoppingMinutes}}",
                      commonPlaceholder(model),
                  ),
              ];
    };

    const connectedFlightFirstPassLines = (
        arrivalStationCode: string,
        isMobile: boolean,
        model: ApiBoardingPassFlightStop,
    ) => {
        if (!model.IsMultisegment || model.Details[0].ArrivalStationCode !== arrivalStationCode) return [];

        return isMobile
            ? [
                  `${i18next.t("Stop-MobileMultisegmentInfo1")} / ${i18next.t(
                      "Stop-MobileMultisegmentInfo2 {{arrivalStationShortName}}{{arrivalStationCode}}",
                      commonPlaceholder(model),
                  )}`,
                  i18next.t(
                      "Stop-MobileMultisegmentInfo3 {{stoppingHours}}{{plural}}{{stoppingMinutes}}",
                      commonPlaceholder(model),
                  ),
              ]
            : [
                  i18next.t(
                      "Stop-BoardingPassMultisegment {{numberOfStops}}{{arrivalStationShortName}}{{arrivalStationCode}}{{stoppingHours}}{{plural}}{{stoppingMinutes}}",
                      commonPlaceholder(model),
                  ),
              ];
    };

    // EXPORTS

    const getOperationAndStopoverInfo = (data: {
        arrivalStationCode: string;
        isMobile: boolean;
        model: ApiBoardingPassFlightStop;
    }) => [
        ...multiLegFlightLines(data.isMobile, data.model),
        ...connectedFlightFirstPassLines(data.arrivalStationCode, data.isMobile, data.model),
        operatorInfo(data.model),
    ];

    return { getOperationAndStopoverInfo };
};

import i18next from "i18next";
import { MaterialLabelElement, updateMdl } from "../../shared/common";
import { html, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { useTodosumaModal12347 } from "./useTodosumaModal12347";
import { ApiPaymentPageViewModel } from "../../component-models/payment/ApiPaymentPageViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAppContext } from "../../managers/useAppContext";

export interface Props {
    model: ApiPaymentPageViewModel;
}

export const useTodosumaPuntos = (props: Props) => {
    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");
    const [selectedMethod] = useReduxState("payment.paymentMethod");
    const [payerData, setPayerData] = useReduxState("payment.payer");

    const todosumaSelect = useRef<MaterialLabelElement>(undefined);
    const todosumaCheckbox = useRef<HTMLInputElement>(undefined);

    const isVisible = () =>
        selectedMethod?.PaymentMethodType === "Credit" &&
        ![0, 5, 6].includes(userContext?.bancoEstado.category) &&
        !appContext.isFeatureSwitchActive("DisableTodoSuma");

    const modal = useTodosumaModal12347({
        model: props.model,
        onAccept: () => {
            todosumaSelect.current?.MaterialCheckbox.check();
            setPayerData({ ...payerData, IsTodosumaConsentAccepted: true });
        },
        onCancel: () => handleCancel(),
    });

    const handleCancel = () => {
        todosumaSelect.current?.MaterialCheckbox.uncheck();
        setPayerData({ ...payerData, IsTodosumaConsentAccepted: false });
    };

    const handleTodosumaSelect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // DEVNOTE Google MDL bug, the "if" must be inverted.
        if (!todosumaCheckbox.current.checked) {
            handleCancel();
        } else {
            modal.open();
        }
    };

    const htmlTemplate = () =>
        isVisible()
            ? html`
                  <div class="todosuma-puntos-stripe">
                      <div class="mdl-checkbox-wrapper payment todosuma">
                          <label
                              ref=${ref(todosumaSelect)}
                              class="mdl-checkbox mdl-js-checkbox"
                              @change=${handleTodosumaSelect}
                          >
                              <input
                                  ref=${ref(todosumaCheckbox)}
                                  type="checkbox"
                                  class="mdl-checkbox__input"
                                  tabindex="-1"
                              />
                              <span class="mdl-checkbox__label cb-amount-label">
                                  <span class="use-points">${i18next.t("BE-UseTodosumaPuntos")}</span>
                                  <img class="todosuma-cb-logo" src="/Images/BancoEstado/todosuma-puntos-logo-v2.svg" />
                              </span>
                          </label>
                      </div>
                  </div>
                  ${updateMdl()} ${modal.htmlTemplate()}
              `
            : html``;

    return { htmlTemplate };
};

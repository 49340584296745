import { JetSmartEvent } from "./JetSmartEvent";
import { Subscription } from "./subscription";

export class EventBus {
    private events: JetSmartEvent[] = new Array<JetSmartEvent>();
    private subscriptions: Subscription[] = new Array<Subscription>();

    public raiseEvent(event: JetSmartEvent): void {
        this.events.push(event);

        // FIX ME Remove before going to prod!
        // commonDebug.info("Raise event", event.name);
        // commonDebug.info(event.params);

        for (const subscription of this.subscriptions) {
            subscription.callback(event);
        }
    }

    public subscribe(subscription: Subscription): void {
        this.subscriptions.push(subscription);
        for (const event of this.events) {
            subscription.callback(event);
        }
    }

    public unsubscribe(name: string): void {
        this.subscriptions = this.subscriptions.filter((item) => item.name !== name);
    }
}

import { DEFAULT_DATE_FORMAT } from "./../../../shared/commonConstants";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import {
    CancelBookingEvent,
    EditRowEvent,
    GlobalFilterChangeEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";
import { useEffect } from "./../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useState } from "../../../shared/haunted/CustomHooks";
import { GridState, PageChangeEvent, RowsSelectedEvent, SortChangeEvent } from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { ROUTES } from "../../../shared/apiRoutes";
import {
    SelectOption,
    getAntiForgeryTokenFromHtml,
    getParsedProperty,
    handleCugLoader,
    toBoolean,
} from "../../../shared/common";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import {
    ApiPeruCompraReservationModel,
    ApiPeruCompraReservationsModel,
    PCRABookingStatus,
} from "../../../component-models/peru-compra/ApiPeruCompraReservationModel";
import { IconClickEvent } from "../../../component-models/CUG2b/PeruCompraPortalEvents";
import { PeruCompraReservationModel } from "../../../component-models/peru-compra/PeruCompraReservationModel";
import { CLASS_NAMES } from "../../../shared/classNames";
import {
    mapToPeruCompraCancelledReservations,
    mapToPeruCompraReservations,
} from "../../../component-mappers/PeruCompraReservationMapper";
import { PeruCompraCancelledReservationModel } from "../../../component-models/peru-compra/PeruCompraCancelledReservationModel";
import {
    ApiPeruCompraCancelledReservationModel,
    ApiPeruCompraCancelledReservationsModel,
} from "../../../component-models/peru-compra/ApiPeruCompraCancelledReservationModel";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "can-export",
    "can-see-reports",
    "org-code",
    "agents-in-organization",
];
export const name = "ac-peru-compra-reservations-page";

export interface Attributes {
    "anti-forgery-token": string;
    "can-export": string;
    "can-see-reports": string;
    "org-code": string;
    "agents-in-organization": string;
}
export interface Properties {
    antiForgeryToken: string;
    canExport: boolean;
    canSeeReports: boolean;
    orgCode: string;
    agentsInOrganization: SelectOption[];
}

export const Component: HauntedFunc<Properties> = (host) => {
    const getAgentsInOrganizationProp = () =>
        typeof host.agentsInOrganization === "string" && (host.agentsInOrganization as string).trim().length > 0
            ? getParsedProperty<SelectOption[]>(host.agentsInOrganization).map(
                  (item: any): SelectOption => ({ Text: item.Name, Value: item.Id, IsSelected: item.Selected }),
              )
            : [];

    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canExport: toBoolean(host.canExport),
        canSeeReports: toBoolean(host.canSeeReports),
        orgCode: host.orgCode,
        agentsInOrganization: getAgentsInOrganizationProp(),
    };

    // HELPERS

    const remapToApiPeruCompraReservationModelProperties = (
        gridState: GridState<keyof PeruCompraReservationModel>,
    ): keyof ApiPeruCompraReservationModel => {
        return gridState.orderBy === "Id"
            ? "DepartureDate"
            : gridState.orderBy === "AdminId"
              ? "DNI"
              : gridState.orderBy === "MemberId"
                ? "RUC"
                : gridState.orderBy === "OperationType"
                  ? "BookingType"
                  : gridState.orderBy === "PNR"
                    ? "ReservationCode"
                    : gridState.orderBy === "TotalAmount"
                      ? "FormattedTotalAmount"
                      : gridState.orderBy === "FirstName"
                        ? "PassengerFirstName"
                        : gridState.orderBy === "LastName"
                          ? "PassengerLastName"
                          : gridState.orderBy === "PassengerDocumentId"
                            ? "PassengerId"
                            : gridState.orderBy;
    };

    const remapToApiPeruCompraCancelledReservationModelProperties = (
        gridState: GridState<keyof PeruCompraCancelledReservationModel>,
    ): keyof ApiPeruCompraCancelledReservationModel => {
        return gridState.orderBy === "Id"
            ? "DepartureDate"
            : gridState.orderBy === "AdminId"
              ? "DNI"
              : gridState.orderBy === "MemberId"
                ? "RUC"
                : gridState.orderBy === "OperationType"
                  ? "BookingType"
                  : gridState.orderBy === "PNR"
                    ? "ReservationCode"
                    : gridState.orderBy === "RefundAmount"
                      ? "FormattedRefundAmount"
                      : gridState.orderBy === "FirstName"
                        ? "PassengerFirstName"
                        : gridState.orderBy === "LastName"
                          ? "PassengerLastName"
                          : gridState.orderBy === "PassengerDocumentId"
                            ? "PassengerId"
                            : gridState.orderBy;
    };

    const getConfirmedData = async (
        data: {
            bookingStatus: PCRABookingStatus;
            gridState: GridState<keyof PeruCompraReservationModel>;
        },
        filterForDates = hasSubmittedDateFiltering,
        filterForUser = hasSubmittedUserFiltering,
    ) => {
        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        const considerDateFiltering = data.bookingStatus === "Confirmed" && filterForDates;
        const considerUserFiltering = data.bookingStatus === "Confirmed" && filterForUser;

        const result = await ajaxJsonRequest<ApiPeruCompraReservationsModel>({
            nonCancellable: true,
            container: root.current,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.PeruCompraReservations}/` +
                `?bookingStatus=${data.bookingStatus}` +
                `&pageSize=${data.gridState.pageSize}` +
                `&page=${data.gridState.pageIndex}` +
                (considerUserFiltering && filterUserId ? `&agentId=${filterUserId}` : ``) +
                (considerDateFiltering && filterFlightDateFrom
                    ? `&journeyDateFrom=${filterFlightDateFrom.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterFlightDateTo
                    ? `&journeyDateTo=${filterFlightDateTo.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                `&orderBy=${remapToApiPeruCompraReservationModelProperties(data.gridState)}` +
                `&orderDirection=${data.gridState.orderDir}` +
                `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}`,
        });

        return mapToPeruCompraReservations(result?.data);
    };

    const getCancelledData = async (data: { gridState: GridState<keyof PeruCompraCancelledReservationModel> }) => {
        const result = await ajaxJsonRequest<ApiPeruCompraCancelledReservationsModel>({
            nonCancellable: true,
            container: root.current,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.PeruCompraCancelledReservations}/` +
                `?bookingStatus=Closed` +
                `&pageSize=${data.gridState.pageSize}` +
                `&page=${data.gridState.pageIndex}` +
                `&orderBy=${remapToApiPeruCompraCancelledReservationModelProperties(data.gridState)}` +
                `&orderDirection=${data.gridState.orderDir}` +
                `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}`,
        });

        return mapToPeruCompraCancelledReservations({
            OriginalParams: result?.data.OriginalParams,
            TotalCount: result?.data.TotalCount,
            Journeys: result?.data.Journeys.map((item) => {
                return { ...item, Id: `${item.TicketNumber}${item.Origin}${item.Destination}` };
            }),
        });
    };

    const initialGridState = (): GridState<keyof PeruCompraReservationModel> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "CreatedDate",
            orderDir: "desc",
            globalFilterExpression: "",
        };
    };

    const initialCancelledGridState = (): GridState<keyof PeruCompraCancelledReservationModel> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "CreatedDate",
            orderDir: "desc",
            globalFilterExpression: "",
        };
    };

    const loadConfirmedData = async (
        filterForDates = hasSubmittedDateFiltering,
        filterForUser = hasSubmittedUserFiltering,
    ) => {
        handleCugLoader(confirmedGrid.current, "loadConfirmedData");

        const { Journeys, TotalCount } = await getConfirmedData(
            { bookingStatus: "Confirmed", gridState: confirmedGridState },
            filterForDates,
            filterForUser,
        );

        setConfirmedData(Journeys);
        setTotalConfirmedItemCount(TotalCount);

        handleCugLoader(confirmedGrid.current, "loadConfirmedData");
    };

    const loadCancelledData = async () => {
        handleCugLoader(cancelledGrid.current, "loadCancelledData");

        const { Journeys, TotalCount } = await getCancelledData({ gridState: cancelledGridState });

        setCancelledData(Journeys);
        setTotalCancelledItemCount(TotalCount);

        handleCugLoader(cancelledGrid.current, "loadCancelledData");
    };

    const init = () => {
        const doIt = async () => {
            await Promise.all([loadConfirmedData(), loadCancelledData()]);
        };

        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        doIt();
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);
    const confirmedGrid = useRef<HTMLDivElement>(undefined);
    const cancelledGrid = useRef<HTMLDivElement>(undefined);

    const { ajaxJsonRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [confirmedGridState, setConfirmedGridState] =
        useState<GridState<keyof PeruCompraReservationModel>>(initialGridState());
    const [cancelledGridState, setCancelledGridState] =
        useState<GridState<keyof PeruCompraCancelledReservationModel>>(initialCancelledGridState());
    const [selectedConfirmedIds, setSelectedConfirmedIds] = useState<Set<string>>(new Set<string>());
    const [confirmedData, setConfirmedData] = useState<PeruCompraReservationModel[]>(undefined);
    const [totalConfirmedItemCount, setTotalConfirmedItemCount] = useState<number>(0);

    const [selectedCancelledIds, setSelectedCancelledIds] = useState<Set<string>>(new Set<string>());
    const [cancelledData, setCancelledData] = useState<PeruCompraCancelledReservationModel[]>(undefined);
    const [totalCancelledItemCount, setTotalCancelledItemCount] = useState<number>(0);

    const [filterFlightDateFrom, setFilterFlightDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterFlightDateTo, setFilterFlightDateTo] = useState<dayjs.Dayjs>(undefined);
    const [filterUserId, setFilterUserId] = useState<number>(-1);

    const [detailsModalData, setDetailsModalData] = useState<
        PeruCompraReservationModel | PeruCompraCancelledReservationModel
    >(undefined);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);

    // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
    const [hasSubmittedDateFiltering, setHasSubmittedDateFiltering] = useState<boolean>(false);
    const [hasSubmittedUserFiltering, setHasSubmittedUserFiltering] = useState<boolean>(false);

    useEffect(init, []);
    useEffect(() => (confirmedData ? loadConfirmedData() : null), [confirmedGridState]);
    useEffect(() => (cancelledData ? loadCancelledData() : null), [cancelledGridState]);

    // FIXME There is a bug with the no scroll class, this is a quick fix for the demo
    useEffect(() => {
        if (isDetailsModalOpen) {
            document.body.classList.add(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScroll);
        }
    }, [isDetailsModalOpen]);

    // EVENT LISTENERS

    const onCancelBooking = (e: CancelBookingEvent) => {
        handleCugLoader(root.current, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${confirmedData[e.detail.index].PrimaryContact}&rl=${
            confirmedData[e.detail.index].PNR
        }&tab=Modify`;
        window.location.href = url;
    };

    const onEditConfirmedRow = (e: EditRowEvent) => {
        handleCugLoader(root.current, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${confirmedData[e.detail.index].PrimaryContact}&rl=${
            confirmedData[e.detail.index].PNR
        }${e.detail.toExtras ? "&toExtras=true" : ""}`;
        window.location.href = url;
    };

    const onClearFilters = () => {
        loadConfirmedData(false, false);
        setHasSubmittedDateFiltering(false);
        setHasSubmittedUserFiltering(false);
    };

    const onConfirmedGlobalFilterChange = (e: GlobalFilterChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            pageIndex: 0,
            globalFilterExpression: e.detail.filterExpression,
        });
    };

    const onConfirmedFilterByDate = async () => {
        const filterForDates = Boolean(filterFlightDateFrom) || Boolean(filterFlightDateTo);
        const filterForUsers = Boolean(filterUserId) && filterUserId !== -1;

        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        setHasSubmittedDateFiltering(filterForDates);
        setHasSubmittedUserFiltering(filterForUsers);

        setConfirmedGridState({ ...confirmedGridState, pageIndex: 0 });
    };

    const onConfirmedPageChange = (e: PageChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onConfirmedRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedConfirmedIds(GridHelper.getSelectedIds(event, selectedConfirmedIds, confirmedData) as Set<string>);
    };

    const onConfirmedSortChange = (e: SortChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            orderBy: e.detail.orderBy as keyof PeruCompraReservationModel,
            orderDir: e.detail.orderDir,
        });
    };

    const onIconClicked = (e: IconClickEvent) => {
        if (e.detail.openDetails) {
            setIsDetailsModalOpen(true);
            setDetailsModalData(confirmedData[e.detail.index]);
        }
    };

    const onCancelledPageChange = (e: PageChangeEvent) => {
        setCancelledGridState({
            ...cancelledGridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onCancelledRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedCancelledIds(GridHelper.getSelectedIds(event, selectedCancelledIds, cancelledData) as Set<string>);
    };

    const onCancelledSortChange = (e: SortChangeEvent) => {
        setCancelledGridState({
            ...cancelledGridState,
            orderBy: e.detail.orderBy as keyof PeruCompraCancelledReservationModel,
            orderDir: e.detail.orderDir,
        });
    };

    const onCancelledIconClicked = (e: IconClickEvent) => {
        if (e.detail.openDetails) {
            setIsDetailsModalOpen(true);
            setDetailsModalData(cancelledData[e.detail.index]);
        }
    };

    const handleDownload = () => {
        window.location.href = ROUTES.ApiRoutes.PeruCompraReservationsCsv;
    };

    // DEVNOTE We return a number based on the number of flights
    // that have the same TicketNumber. 1 means one way, 2 means round trip
    const getFlightType = (reservation: PeruCompraReservationModel) =>
        confirmedData.filter((currentReservation) => currentReservation.TicketNumber === reservation?.TicketNumber)
            .length;

    // TEMPLATES

    const confirmedReservationsGridTemplate = () => {
        const tempClassMap = classMap({
            "min-h-1/4": !confirmedData,
            "w-full": !confirmedData,
            "relative": !confirmedData,
        });

        return html`
            <div ref=${ref(confirmedGrid)} class=${tempClassMap}>
                <ac-peru-compra-confirmed-reservations
                    .canExport=${props.canExport}
                    .data=${confirmedData}
                    .filterFlightDateFrom=${filterFlightDateFrom}
                    .filterFlightDateTo=${filterFlightDateTo}
                    .filterUserId=${filterUserId}
                    .gridState=${confirmedGridState}
                    .selectedIds=${Array.from(selectedConfirmedIds.values())}
                    .totalItemCount=${totalConfirmedItemCount}
                    .setFilterFlightDateFrom=${setFilterFlightDateFrom}
                    .setFilterFlightDateTo=${setFilterFlightDateTo}
                    .setFilterUserId=${setFilterUserId}
                    .agentsInOrganization=${props.agentsInOrganization}
                    @onSortChange=${onConfirmedSortChange}
                    @onRowsSelect=${onConfirmedRowsSelect}
                    @onPageChange=${onConfirmedPageChange}
                    @onGlobalFilterChange=${onConfirmedGlobalFilterChange}
                    @onFilterByDate=${onConfirmedFilterByDate}
                    @onClearFilterByDate=${onClearFilters}
                    @onIconClick=${onIconClicked}
                    @onEditRow=${onEditConfirmedRow}
                    @onCancel=${onCancelBooking}
                ></ac-peru-compra-confirmed-reservations>
            </div>
        `;
    };

    const cancelledReservationsGridTemplate = () => {
        const tempClassMap = classMap({
            // TODO: Remove this class if download should be enabled
            "pcra-hide-select-box": true,
            "min-h-1/4": !cancelledData,
            "w-full": !cancelledData,
            "relative": !cancelledData,
        });

        return html`
            <div ref=${ref(cancelledGrid)} class=${tempClassMap}>
                <ac-peru-compra-cancelled-reservations
                    .data=${cancelledData}
                    .gridState=${cancelledGridState}
                    .totalItemCount=${totalCancelledItemCount}
                    @onSortChange=${onCancelledSortChange}
                    @onRowsSelect=${onCancelledRowsSelect}
                    @onPageChange=${onCancelledPageChange}
                    @onIconClick=${onCancelledIconClicked}
                ></ac-peru-compra-cancelled-reservations>
            </div>
        `;
    };

    const detailsModalTemplate = () =>
        html`<ac-peru-compra-details-modal
            .isOpen=${isDetailsModalOpen}
            .data=${detailsModalData}
            .setIsDetailsModalOpen=${setIsDetailsModalOpen}
            .getFlightType=${getFlightType}
            @close=${() => setIsDetailsModalOpen(false)}
        ></ac-peru-compra-details-modal>`;

    const exportTemplate = () =>
        props.canExport
            ? html`
                  <div class="mt-8 flex flex-col items-center justify-end sm:flex-row">
                      <button
                          @click=${() => handleDownload()}
                          class="rounded-primary-btn cug2b-btn mb-4 sm:mb-0 sm:mr-3"
                      >
                          ${i18next.t("CUG-Download")}
                      </button>
                  </div>
              `
            : "";

    return html`
        <div ref=${ref(root)} class="w-full">
            <div class="cug2b-title-container">
                <i class="js-icon-cug js-cug-finger"></i>
                <div>
                    <h1>${i18next.t("Registro de movimientos")}</h1>

                    <h2>${i18next.t("Reservas confirmadas")}</h2>
                </div>
            </div>

            ${confirmedReservationsGridTemplate()} ${cancelledReservationsGridTemplate()} ${exportTemplate()}
            ${detailsModalTemplate()}
        </div>
    `;
};

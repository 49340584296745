import { html } from "haunted";
import i18next from "i18next";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useModal } from "../shared/useModal";

export const usePeruCompraLowBalanceModal = () => {
    const handleClose = () => (window.location.href = "/");

    const htmlTemplate = () => html`
        <div class="modal-body pcra-low-balance-modal-body">${i18next.t("PeruCompra-LowBalance")}</div>
        <div class="pcra-low-balance-modal-footer">
            <a
                class="rounded-primary-btn"
                data-test-id=${T.PERU_COMPRA_PAYMENT.PERU_COMPRA_LOW_BALANCE_MODAL_ACCEPT_BUTTON}
                @click=${handleClose}
            >
                ${i18next.t("Aceptar")}
            </a>
        </div>
    `;

    const modal = useModal({
        closing: {
            buttonTestId: T.PERU_COMPRA_PAYMENT.PERU_COMPRA_LOW_BALANCE_MODAL_CLOSE_BUTTON,
            isClosable: true,
            onClose: handleClose,
        },
        content: { classNames: "pcra-low-balance-modal-content", template: htmlTemplate },
        header: { classNames: "pcra-low-balance-modal-header", template: () => i18next.t("Atención") },
        overlay: { classNames: "pcra-low-balance-modal" },
        scrolling: { isScrollable: true },
    });

    return modal;
};

import { useLayoutEffect } from "haunted";
import { useMemo, useState } from "../haunted/CustomHooks";

export const useWindowSize = () => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const size = useMemo<"XS" | "SM" | "MD" | "LG" | "XL" | "2XL">(() => {
        if (width >= 1536) return "2XL";
        if (width >= 1280) return "XL";
        if (width >= 1024) return "LG";
        if (width >= 768) return "MD";
        if (width >= 640) return "SM";

        return "XS";
    }, [width, height]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return { width, height, size };
};

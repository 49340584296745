import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { html } from "lit-html";
import i18next from "i18next";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useWindowSize } from "../../../../shared/customHooks/useWindowSize";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const bannerClassName = "ts-scroll-to-banner";

export interface Props {
    context: SeatmapContext;
    isForMobile: boolean;
    seatmapState: SeatmapState;
    hasFlightColombianStation: boolean;
    handleApplyRecommendedSeats: (e: MouseEvent, isMobile: boolean) => void;
    handleNoSelection: () => void;
}

export const useSeatRecommendator = (props: Props) => {
    // HELPERS

    const isMobile = () => windowSize?.width < 1200;

    const [recommendatorLine1, recommendatorLine2, recommendatorLine3] = SeatmapHelper.recommendatorLines(
        props.context,
        props.seatmapState,
    );

    const isRecommendatorOnForCurrentSegment = () =>
        props.seatmapState &&
        SeatmapHelper.isRecommendatorOnForSegment(
            props.seatmapState.CurrentJourney,
            props.seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 0,
            flowContext.isBookingFlow,
            props.hasFlightColombianStation,
        );

    // EVENT LISTENERS

    // COMPONENT

    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const windowSize = useWindowSize();

    // TEMPLATES

    const desktopTemplate = () =>
        !isMobile() && !props.isForMobile && !props.context.Model.IsReadOnly
            ? html`
                  <div class="hidden-md-down mt-4 flex items-center justify-between px-4">
                      <div class="w-full">
                          <button
                              class="rounded-primary-btn seat-select"
                              data-test-id=${getTestId(T.SEATMAP.APPLY_RECOMMENDATION_BUTTON_DESKTOP, {
                                  j: props.seatmapState.CurrentJourney.JourneyIndex,
                                  s: props.seatmapState.CurrentSegment.SegmentIndex,
                              })}
                              @click=${(e: MouseEvent) => props.handleApplyRecommendedSeats(e, props.isForMobile)}
                          >
                              ${i18next.t("Aplicar recomendación")}
                          </button>
                      </div>
                  </div>
              `
            : "";

    const mobileTemplate = () =>
        isMobile() && props.isForMobile && !props.context.Model.IsReadOnly
            ? html` <div class="hidden-lg-up">${mobileBannerTemplate()} ${mobileButtonsTemplate()}</div> `
            : "";

    const mobileBannerTemplate = () => html`
        <div class="seatmap-recommendator-banner ${bannerClassName}">
            ${recommendatorLine1} ${recommendatorLine2 ? html` <span>${recommendatorLine2}</span> ` : ""}
            <span class="mt-2">${recommendatorLine3}</span>
        </div>
    `;

    const mobileButtonsTemplate = () => html`
        <div class="seatmap-recommendator-buttons">
            <div
                class="seatmap-recommendator-button"
                data-test-id=${getTestId(T.SEATMAP.NO_SEAT_SELECTION_BUTTON, {
                    j: props.seatmapState.CurrentJourney.JourneyIndex,
                    s: props.seatmapState.CurrentSegment.SegmentIndex,
                    m: props.isForMobile,
                })}
                @click=${props.handleNoSelection}
            >
                ${window.seatmapResources.noSeatText}
            </div>
            <div
                class="seatmap-recommendator-button"
                data-test-id=${getTestId(T.SEATMAP.APPLY_RECOMMENDATION_BUTTON_MOBILE, {
                    j: props.seatmapState.CurrentJourney.JourneyIndex,
                    s: props.seatmapState.CurrentSegment.SegmentIndex,
                })}
                @click=${(e: MouseEvent) => props.handleApplyRecommendedSeats(e, props.isForMobile)}
            >
                ${i18next.t("Aplicar recomendación")}
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        isRecommendatorOnForCurrentSegment() ? html` ${desktopTemplate()} ${mobileTemplate()} ` : html``;

    return {
        isRecommendatorOn: isRecommendatorOnForCurrentSegment(),
        htmlTemplate,
    };
};

export interface ChangedFlightsResponse {
    ChangedFlights: ChangedFlightsModel[];
}

export interface ChangedFlightsModel {
    ChangedJourneyIndex: number;
    DepartureStation: string;
    DepartureStationCode: string;
    DestinationStation: string;
    DestinationStationCode: string;
    FlightNumber: string;
    FormattedDepartureTime: string;
    FormattedArrivalTime: string;
    PrimaryContactEmail: string;
    Pnr: string;
    Type: ChangedFlightType;
}

export enum ChangedFlightType {
    Change = "Change",
    Cancellation = "Cancellation",
}

import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ItineraryFlightlessPageViewModel } from "../../component-models/itinerary/ItineraryFlightlessPageViewModel";
import { PaymentResult } from "../../component-models/PaymentResult";
import { html } from "lit-html";
import { usePendingPaymentBanner } from "../payment/usePendingPaymentBanner";
import { getParsedProperty } from "../../shared/common";

export const name = "ac-itinerary-flightless-page";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
    model: string;
}

export interface Props {
    model: ItineraryFlightlessPageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: getParsedProperty<ItineraryFlightlessPageViewModel>(host.model),
    };

    // HELPERS

    // EVENT HANDLERS

    const handleClose = () => (window.location.href = props.model.SummaryUrl);

    // COMPONENTS

    const pendingPaymentBanner = usePendingPaymentBanner({
        isActive: !props.model.IsPaymentSuccessful,
        isDeclined: props.model.IsDeclined,
        isFlightless: true,
        paymentDescription: props.model.PaymentDescription,
        paymentId: props.model.PaymentId,
        pnr: props.model.Pnr,
        refreshSeconds: props.model.RefreshSeconds,
        retrieveUrl: props.model.RetrieveUrl,
    });

    // TEMPLATES

    const headerTemplate = () => html`
        <header class="transactions-header">
            <div @click=${handleClose} class="close-transaction">&times;</div>
            <i class="js-circle-tick js-icon title-icon"></i>
            <div class="title full-width-title">
                <h2 class="main-title">${i18next.t("SuccessDepositHeaderTitle")}</h2>
            </div>
        </header>
    `;

    const transactionTemplate = () => html`
        <div class="transaction-container">
            <div class="row">
                <div class="col-xs-1">
                    <header class="transaction-header">
                        <div class="title full-width-title">
                            <h2 class="main-title">
                                ${i18next.t("CUG-NewBalance {{-amount}}", { amount: props.model.Amount })}
                            </h2>
                        </div>
                    </header>
                </div>
            </div>
        </div>
    `;

    const transactionLoggerTemplate = (paymentResult: PaymentResult) => html`
        <ac-transaction-logger
            .logWithTealium=${true}
            .paymentResultData=${paymentResult}
            .tealiumProperties=${props.model.TealiumProperties}
        ></ac-transaction-logger>
    `;

    const transactionsTemplate = () =>
        props.model.PaymentResults.map(
            (paymentResult) => html`
                ${paymentResult.PaymentType ? transactionTemplate() : ""} ${transactionLoggerTemplate(paymentResult)}
            `,
        );

    const buttonTemplate = () => html`
        <div class="transaction-button-container">
            <a href=${props.model.SummaryUrl} class="rounded-secondary-btn deposit">${i18next.t("V2-ReturnLabel")}</a>
        </div>
    `;

    return props.model.IsPaymentSuccessful
        ? html`
              <div id="mainContentWrapper">
                  <section class="booking-wrapper transaction-wrapper">
                      ${headerTemplate()} ${transactionsTemplate()} ${buttonTemplate()}
                  </section>
              </div>
          `
        : pendingPaymentBanner.htmlTemplate();
};

import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { useState } from "./../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { capitalizeAndRemoveDot, getParsedProperty, toBoolean } from "../../shared/common";
import dayjs from "dayjs";
import { ChangeSearchViewModel } from "../../component-models/ChangeSearchViewModel";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useFlowContext } from "../../managers/useFlowContext";
import classNames from "classnames";

export const name = "ac-flight-itinerary";

export const observedAttributes: (keyof Attributes)[] = [
    "base-url",
    "change-search-model",
    "departure-station-name",
    "destination-station-name",
    "include-monetary",
    "selected-currency",
    "resident-country",
];

export interface Attributes {
    "base-url": string;
    "change-search-model": string;
    "departure-station-name": string;
    "destination-station-name": string;
    "include-monetary": string;
    "selected-currency": string;
    "resident-country": string;
}

export interface Props {
    baseUrl: string;
    changeSearchModel: ChangeSearchViewModel;
    departureStationName: string;
    destinationStationName: string;
    includeMonetary: boolean;
    selectedCurrency: string;
    residentCountry: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        baseUrl: host.baseUrl,
        selectedCurrency: host.selectedCurrency,
        departureStationName: host.departureStationName,
        destinationStationName: host.destinationStationName,
        includeMonetary: toBoolean(host.includeMonetary),
        residentCountry: host.residentCountry,
        changeSearchModel: getParsedProperty<ChangeSearchViewModel>(host.changeSearchModel),
    };

    const isBancoEstadoRibbonDisplayed = () =>
        !flowContext.isCheckinFlow && [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category);

    const goToCalendar = () => {
        window.location.href = calendarLink();
    };

    const handleEdit = () => {
        setShowSearchBox(!showSearchbox);
    };

    const calendarLink = () => {
        const url = props.baseUrl + "?";
        const minDate = "0001-01-01";
        const params = [];

        params.push("kpc=true");
        params.push("c=true");
        params.push("cur=" + props.selectedCurrency);

        if (props.changeSearchModel.Destination) {
            params.push("o1=" + props.changeSearchModel.Origin);
        }
        if (props.changeSearchModel.Destination) {
            params.push("d1=" + props.changeSearchModel.Destination);
        }
        if (toBoolean(!props.changeSearchModel.IsOneWay)) {
            params.push("r=" + !props.changeSearchModel.IsOneWay);
        }
        if (toBoolean(props.includeMonetary)) {
            params.push("mon=" + props.includeMonetary);
        }
        if (props.changeSearchModel.ToDate && props.changeSearchModel.ToDate !== minDate) {
            params.push("dd2=" + props.changeSearchModel.ToDate);
        }
        if (props.changeSearchModel.FromDate && props.changeSearchModel.FromDate !== minDate) {
            params.push("dd1=" + props.changeSearchModel.FromDate);
        }
        params.push("ADT=" + props.changeSearchModel.AdultNumber);
        params.push("CHD=" + props.changeSearchModel.ChildNumber);
        params.push("inl=" + props.changeSearchModel.InfantNumber);
        params.push("rc=" + props.residentCountry);
        params.push("pc=" + bookingContext.promoCode);
        return url + params.join("&");
    };

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const [userContext] = useReduxState("userContext");

    const [showSearchbox, setShowSearchBox] = useState<boolean>(false);

    // TEMPLATE

    const flightSwitchTemplate = () => html`
        <div
            class="flight-switch hidden-xs sm:flex items-center justify-center gap-2 p-2"
            data-test-id=${T.FLIGHT.SWITCH_TO_CALENDAR_CONTAINER}
        >
            <div class="flex flex-col">
                <div class="flex justify-between items-center gap-2">
                    <svg class="-ml-1" width="40" height="24.2" viewBox="0 0 86 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M73.3161 41.9968H13.6839C12.8618 41.9968 12.3447 41.1444 12.7491 40.4574L13.4154 39.3315L14.6916 37.1719C14.8772 36.857 14.8772 36.4689 14.6916 36.1541L13.4154 33.9976L12.1392 31.8412C11.9536 31.5263 11.9536 31.1383 12.1392 30.8234L13.4154 28.6638L14.6916 26.5073C14.8772 26.1924 14.8772 25.8044 14.6916 25.4895L13.4154 23.3331L12.1392 21.1734C11.9536 20.8586 11.9536 20.4705 12.1392 20.1557L13.4154 17.996L14.6916 15.8396C14.8772 15.5247 14.8772 15.1367 14.6916 14.8218L13.4154 12.6653L12.7491 11.5394C12.3414 10.8524 12.8585 10 13.6839 10H73.3161C74.1382 10 74.6553 10.8524 74.2509 11.5394L73.5846 12.6653L72.3084 14.8218C72.1228 15.1367 72.1228 15.5247 72.3084 15.8396L73.5846 17.996L74.8608 20.1557C75.0464 20.4705 75.0464 20.8586 74.8608 21.1734L73.5846 23.3331L72.3084 25.4895C72.1228 25.8044 72.1228 26.1924 72.3084 26.5073L73.5846 28.6638L74.8608 30.8234C75.0464 31.1383 75.0464 31.5263 74.8608 31.8412L73.5846 34.0008L72.3084 36.1572C72.1228 36.4721 72.1228 36.8602 72.3084 37.175L73.5846 39.3347L74.2509 40.4606C74.6586 41.1476 74.1415 42 73.3161 42V41.9968Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                        <path
                            d="M27.5 11V17.7308"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                        <path
                            d="M28 35V41.7308"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                        <path
                            d="M27.5979 20.7588V22.049"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                        <path
                            d="M27.5979 25.8287V31.6608"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                        <path
                            d="M33.3741 33.1294L36.021 35.9405C36.5979 36.5524 37.3671 36.9475 38.2028 37.0559L43.1608 37.7028C43.8462 37.7937 44.5455 37.6853 45.1748 37.3916L66.5245 27.4161C67.958 26.7447 68.7308 25.1713 68.3881 23.6259C67.9196 21.5384 65.6538 20.4091 63.7063 21.2937L53.5385 25.9021C53.3636 25.9825 53.1608 25.986 52.9825 25.9091L42.9545 21.6049C41.9615 21.1783 40.8182 21.2657 39.9021 21.8426L38.8427 22.507C38.2343 22.8881 38.2203 23.7692 38.8147 24.1713L45.9091 28.9685C46.0979 29.0979 46.0769 29.3846 45.8671 29.479L40.6573 31.8846C40.493 31.9615 40.3077 31.9685 40.1364 31.9056L36.8881 30.6923C36.1364 30.4475 35.3077 30.6084 34.7028 31.1154L33.4371 32.1748C33.1469 32.4196 33.1189 32.8601 33.3811 33.1363L33.3741 33.1294Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                        />
                    </svg>

                    <span class="whitespace-nowrap font-semibold">${i18next.t("Busca otras").toUpperCase()}</span>
                </div>
                <span class="whitespace-nowrap font-semibold">${i18next.t("Fechas y precios").toUpperCase()}</span>
            </div>
            <div
                class="hidden-xs sm:flex h-7 max-w-fit cursor-pointer select-none rounded-md border border-solid border-brand-secondary bg-white px-2 py-1 font-semibold text-brand-secondary hover:bg-brand-secondary hover:text-white"
                data-test-id=${T.FLIGHT.SWITCH_TO_CALENDAR}
                @click=${goToCalendar}
            >
                ${i18next.t("AQUÍ")}
            </div>
        </div>
    `;

    const flightSwitchMobileTemplate = () => html`
        <div
            class="flight-switch flex flex-col items-center justify-center gap-2 p-2 hidden-sm-up"
            data-test-id=${T.FLIGHT.SWITCH_TO_CALENDAR_CONTAINER}
        >
            <div>${i18next.t("Más precios")}</div>
            <div
                class="flex h-7 max-w-fit cursor-pointer select-none rounded-md border border-solid border-brand-secondary bg-white px-2 py-1 font-semibold text-brand-secondary hover:bg-brand-secondary hover:text-white"
                data-test-id=${T.FLIGHT.SWITCH_TO_CALENDAR}
                @click=${goToCalendar}
            >
                ${i18next.t("AQUÍ")}
            </div>
        </div>
    `;

    return html`
        <div
            class=${classNames("flight-itinerary", { "push-down": isBancoEstadoRibbonDisplayed() })}
            data-test-id=${T.FLIGHT.ITINERARY}
        >
            <div
                class="station"
                data-test-id=${T.FLIGHT.ITINERARY_ORIGIN}
                data-test-value=${props.changeSearchModel.Origin}
            >
                <span>${props.departureStationName}</span>
                <span>${props.changeSearchModel.Origin}</span>
            </div>
            <div class="flight-date" data-test-id=${T.FLIGHT.ITINERARY_OUTBOUND_DATE}>
                ${capitalizeAndRemoveDot(dayjs(props.changeSearchModel.FromDate).format("ddd DD-MM"))}
                ${!props.changeSearchModel.IsOneWay
                    ? html`
                          <span class="visible-xs" data-test-id=${T.FLIGHT.ITINERARY_INBOUND_DATE_MOBILE}>
                              ${capitalizeAndRemoveDot(dayjs(props.changeSearchModel.ToDate).format("ddd DD-MM"))}
                          </span>
                      `
                    : ""}
            </div>
            <div
                class="station"
                data-test-id=${T.FLIGHT.ITINERARY_DESTINATION}
                data-test-value=${props.changeSearchModel.Destination}
            >
                <span>${props.destinationStationName}</span>
                <span>${props.changeSearchModel.Destination}</span>
            </div>
            ${!props.changeSearchModel.IsOneWay
                ? html`
                      <div class="flight-date hidden-xs" data-test-id=${T.FLIGHT.ITINERARY_INBOUND_DATE}>
                          ${capitalizeAndRemoveDot(dayjs(props.changeSearchModel.ToDate).format("ddd DD-MM"))}
                      </div>
                      <div class="station hidden-xs">
                          <span>${props.departureStationName}</span>
                          <span>${props.changeSearchModel.Origin}</span>
                      </div>
                  `
                : ""}

            <div class="flight-edit">
                <i @click=${handleEdit} class="js-icon js-edit" data-test-id=${T.FLIGHT.OPEN_CHANGE_SEARCH}></i>
            </div>
            ${flightSwitchTemplate()}${flightSwitchMobileTemplate()}
        </div>

        <div class=${!showSearchbox ? "hidden" : ""}>
            <!-- DEVNOTE: The ac-change-search element is still used in a .cshtml file that is why we need to use the unparsed model from host-->
            <ac-change-search model=${host.changeSearchModel}> </ac-change-search>
        </div>
    `;
};

import i18next from "i18next";
import { html } from "haunted";
import { UrlHelper } from "../../shared/UrlHelper";
import { useAppContext } from "../../managers/useAppContext";
import { useModal } from "../shared/useModal";

export interface Props {
    flightDesignators: string;
}

export const useCancelledFlightsModal = (props: Props) => {
    const appContext = useAppContext();

    const getCancelButtonPath = () => {
        return `${UrlHelper.getCultureInUrlFormat(appContext.Culture)}cambios-y-devoluciones`;
    };

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <div class="modal-description-title">
                    ${i18next.t("Itinerary-CancelledFlights-YourFlight")}
                    <span>${props.flightDesignators}</span>
                    ${i18next.t("Itinerary-CancelledFlights-HasBeenCancelled")}
                </div>
            </div>
            <div class="primary-modal-button-container">
                <a class="rounded-primary-btn" href=${`${appContext.CorporateBaseUrl}${getCancelButtonPath()}`}>
                    ${i18next.t("Itinerary-CancelledFlights-RequestReturnButton")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => i18next.t("Itinerary-CancelledFlights-FlightStatusHeader") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { html } from "haunted";
import { chunk } from "../../../../shared/common";
import { useModal } from "../../../shared/useModal";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export interface Props {
    freeSeatsAsBackendKnows: number;
    freeSeatsInBooking: number;
}

const chunkSize = 8;

export const useBancoEstadoFreeSeatsModal = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const remainingFreeSeats = userContext.bancoEstado.remainingFreeSeats;

    const [callback, setCallback] = useState<() => () => void>(undefined);
    const [freeSeatIdentifiers, setFreeSeatIdentifiers] = useState<string[]>([]);

    const seatArrays = () => chunk([...Array(remainingFreeSeats + props.freeSeatsAsBackendKnows)], chunkSize);

    const confirmSeats = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        callback();
        modal.close();
    };

    const open = (freeSeatIdentifiersForJourney: string[], callback: () => void) => {
        setCallback(() => callback);
        setFreeSeatIdentifiers(freeSeatIdentifiersForJourney);
        modal.open();
    };

    const headerTemplate = () => html`
        ${i18next.t("Confirmación uso de asientos gratis")}
        <div class="smart-plus-seating-logo">${i18next.t("Beneficio")}<span>${i18next.t("SMART+")}</span></div>
    `;

    const statsTemplate = () => html`
        <div class="spsm-stats">
            <i class="js-icon-be2 js-be2-seats"></i>
            <div>
                <div class="spsm-stats-item">
                    ${i18next.t("Asientos gratis disponibles")}:
                    <span>${remainingFreeSeats + props.freeSeatsAsBackendKnows}</span>
                </div>
                <div class="spsm-stats-item">
                    ${i18next.t("Asientos gratis seleccionados en reserva actual")}:
                    <span>${props.freeSeatsInBooking}</span>
                </div>
                <div class="spsm-stats-item">
                    ${i18next.t("Asientos gratis restantes")}:
                    <span> ${remainingFreeSeats + props.freeSeatsAsBackendKnows - props.freeSeatsInBooking} </span>
                </div>
            </div>
        </div>
        <div class="spsm-reminder">
            <span> ${i18next.t("Recuerda que adicionalmente tienes desde un 40% dcto en la zona")} </span>
            ${i18next.t("BancoEstado")}
        </div>
    `;

    const iconsTemplate = <T>(seatArray: T[][], index: number) => {
        const tempClassMap = classMap({
            "spsm-seats": true,
            "too-many": seatArrays().length > 1,
        });

        return html`
            <div class=${tempClassMap}>
                ${seatArray.map((_, j) => {
                    const identifier = freeSeatIdentifiers[index * chunkSize + j];

                    return html`
                        <span class="spsm-seat">
                            <i class="js-icon-be2 js-be2-first-seat"></i>
                            ${identifier ? html` <span class="spsm-seat-number">${identifier}</span> ` : ""}
                        </span>
                    `;
                })}
            </div>
        `;
    };

    const buttonsTemplate = () => html`
        <div class="spsm-button-container">
            <a class="secondary-link" @click=${modal.close}>${i18next.t("Editar selección")}</a>
            <a class="cta" data-test-id="confirm-be2-seating" @click=${confirmSeats}>
                ${i18next.t("Confirmar asientos")}
                <i class="js-icon js-circle-chevron-right"></i>
            </a>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            ${statsTemplate()}

            <div class="spsm-seats-container">${seatArrays().map(iconsTemplate)}</div>

            ${buttonsTemplate()}
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "smart-plus-seating-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
    });

    return { ...modal, open };
};

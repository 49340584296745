import { commonDebug } from "./../../bootstrap";
import { AbTestSettings } from "../../component-models/abTesting/AbTestSettings";
import { AmplitudeVariant } from "../customHooks/useAjax/AmplitudeVariant";

export const amplitudeDontForceDcBannerSelection = (settings: AbTestSettings, variants: AmplitudeVariant[]) => {
    try {
        const setting = settings.BookingSettings.RemoveDCForceSelection;

        return variants.some(
            (variant) => variant.ExperimentId === setting.ExperimentId && variant.Key === setting.Variant,
        );
    } catch (e) {
        commonDebug.error("Could not get Amplitude experiment for RemoveForceDcSelection.", e);
        return false;
    }
};

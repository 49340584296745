import { TemplateResult, html } from "lit-html";

type ReviewTable = Map<string, (string | TemplateResult)[]>;

export const reviewTableTemplate = () => {
    const columns = (table: ReviewTable) => Array.from(table.keys());

    const numberOfRows = (table: ReviewTable) => [...Array(table.get(columns(table)[0]).length)];

    const desktopHeaderTemplate = (table: ReviewTable) => html`
        <div class="flight-chart-head">
            ${columns(table).map((column) => html`<div class="flight-chart-header">${column}</div>`)}
        </div>
    `;

    const desktopRowsTemplate = (table: ReviewTable) =>
        numberOfRows(table).map(
            (_, index) => html`
                <div class="flight-chart-body">
                    ${columns(table).map(
                        (column) => html` <div class="flight-chart-cell">${table.get(column)[index]}</div> `,
                    )}
                </div>
            `,
        );

    const desktopReviewTable = (table: ReviewTable) => html`
        <div class="flight-chart">${desktopHeaderTemplate(table)} ${desktopRowsTemplate(table)}</div>
    `;

    const mobileRecordTemplate = (key: string, value: string | TemplateResult) => html`
        <div class="flight-chart-row">
            <div class="flight-chart-cell">${key}</div>
        </div>
        <div class="flight-chart-row">
            <div class="flight-chart-cell">${value}</div>
        </div>
    `;

    const mobileReviewTable = (table: ReviewTable) =>
        numberOfRows(table).map(
            (_, index) => html`
                <div class="flight-mobile-chart">
                    ${columns(table).map((key) => mobileRecordTemplate(key, table.get(key)[index]))}
                </div>
            `,
        );

    return { desktopReviewTable, mobileReviewTable };
};

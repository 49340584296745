import { html } from "lit-html";
import { useModal } from "../../../shared/useModal";
import i18next from "i18next";

export interface Props {
    onClose: (e?: MouseEvent) => Promise<void>;
}

export const useBlockedSeatingModal = (props: Props) => {
    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                ${i18next.t(
                    "Existen limitaciones en la selección de asientos actual. Por favor vuelva a intentarlo al momento de hacer tu checkin.",
                )}
            </div>
            <div class="primary-modal-button-container">
                <button class="rounded-primary-btn" @click=${modal.close}>${i18next.t("V2-Continue")}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: false, onClose: props.onClose },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => html`<span>${i18next.t("Importante")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

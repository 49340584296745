import { html } from "lit-html";
import i18next from "i18next";
import { useModal } from "../../shared/useModal";

export const useFamilyMemberErrorModal = () => {
    const handleClose = () => (window.location.href = "/");

    const headerTemplate = () => html` <i class="js-circle-user js-icon"></i> ${i18next.t("Importante")} `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div>
                ${i18next.t(
                    "Aún no tienes beneficiarios inscritos. Recuerda que para que tus beneficiarios puedan hacer uso de los descuentos, debes inscribirlos escribiendo a conectatesmart@jetsmart.com. Una vez esté validada la información podrás verlos en la sección de beneficiarios. A partir de ahí, podrán hacer uso de los descuentos.",
                )}
            </div>
            <div class="my-8 flex w-full items-center justify-center">
                <button class="rounded-primary-btn" @click=${handleClose}>OK</button>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true, onClose: handleClose },
        content: { classNames: "family-member-error-modal", template: htmlTemplate },
        header: { template: headerTemplate },
    });

    return modal;
};

import { useAppContext } from "../useAppContext";
import { useCug2AppContext } from "../useCug2AppContext";
import { useTealiumManager } from "./useTealiumManager";

export const useCugTealiumManager = () => {
    const commonObject = () => ({
        culture: appContext.Culture || cug2AppContext.Culture || "n/a",
        device: tealiumManager.getTealiumDeviceType(),
    });

    const logPortalLogin = (username: string, loginType: string) =>
        tealiumManager.log({
            EventName: "portal_login",
            EventParams: {
                ...commonObject(),
                type: loginType.toLowerCase(),
                username,
            },
        });

    const appContext = useAppContext();
    const cug2AppContext = useCug2AppContext();
    const tealiumManager = useTealiumManager();

    return {
        logPortalLogin,
    };
};

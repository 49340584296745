import { SelectOption } from "./../../../shared/common";
import { html, TemplateResult } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import classNames from "classnames";
import { ref } from "../../../directives/ref";
import { useMemo, useRef } from "haunted";
import { useLabel } from "../label/useLabel";
import { useErrorMessage } from "../error-message/useErrorMessage";

export const name = "ac-select";

export interface Props {
    autoComplete: string;
    customClasses: string[];
    isLabelSmaller: boolean;
    errorMessage: string;
    isDisabled: boolean;
    isInvalid: boolean;
    label: string | TemplateResult;
    name: string;
    options: SelectOption[];
    placeholder: string;
    tabIndexAttr: string;
    testId: string;
    onBlur: () => void;
    onSelect: (value: string) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        autoComplete: host.autoComplete || "off",
        customClasses: host.customClasses,
        isLabelSmaller: host.isLabelSmaller,
        errorMessage: host.errorMessage,
        isDisabled: host.isDisabled,
        isInvalid: host.isInvalid,
        label: host.label,
        name: host.name || "",
        options: host.options || [],
        placeholder: host.placeholder || "",
        tabIndexAttr: host.tabIndexAttr || "",
        testId: host.testId || "",
        onBlur: host.onBlur,
        onSelect: host.onSelect,
    };

    const handleChange = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        props.onSelect(inputElem.current.value);
    };

    const inputElem = useRef<HTMLSelectElement>(undefined);

    const hasSelectedOption = useMemo(() => props.options.some((option) => option.IsSelected), [props.options]);

    const label = useLabel({
        isDirty: props.options.some((option) => option.IsSelected && option.Value) || Boolean(props.placeholder),
        isDisabled: props.isDisabled,
        label: props.label,
        isSmaller: props.isLabelSmaller,
    });

    const error = useErrorMessage({
        errorMessage: props.errorMessage,
    });

    const optionsTemplate = () =>
        props.options.map(
            (option) => html`
                <option
                    class="text-[16px] leading-[16px] text-brand-secondary"
                    value=${option.Value}
                    ?selected=${option.IsSelected}
                    ?disabled=${option.IsDisabled}
                    data-test-id=${option.TestId || ""}
                >
                    ${option.Text}
                </option>
            `,
        );

    return html`
        <div
            class=${classNames(
                "relative w-full leading-none",
                "after:pointer-events-none after:absolute after:right-[15px] after:block after:text-[12px] after:text-[#818a91] after:content-['▼']",
                {
                    "after:top-[21px]": !props.isLabelSmaller,
                    "after:top-[16px]": props.isLabelSmaller,
                },
            )}
        >
            <select
                class=${classNames(props.customClasses, {
                    "peer m-0 block h-[52px] w-full cursor-pointer appearance-none rounded-[5px] border border-solid pb-[3px] pl-[14px] pr-[4px] pt-[8px] text-left leading-[52px] common-transition":
                        !props.customClasses,
                    "text-[14px] text-[#818a91]": !props.customClasses && props.placeholder && !hasSelectedOption,
                    "text-[20px]  text-brand-secondary":
                        (!props.customClasses && !props.placeholder) || hasSelectedOption,
                    "focus:border-be-cyan focus:text-brand-secondary": !props.customClasses,
                    "disabled": props.isDisabled,
                    "border-brand-secondary bg-white": !props.isInvalid,
                    "border-[#e299a1] bg-[#f9d4d8]": props.isInvalid,
                })}
                data-test-id=${props.testId}
                name=${host.name}
                value=${props.options.find((o) => o.IsSelected)?.Value || ""}
                ref=${ref(inputElem)}
                tabindex=${props.tabIndexAttr}
                ?disabled=${props.isDisabled}
                @blur=${props.onBlur}
                @change=${handleChange}
            >
                ${props.placeholder && !hasSelectedOption
                    ? html`<option disabled selected value="">${props.placeholder}</option>`
                    : ""}
                ${optionsTemplate()}
            </select>
            ${label.htmlTemplate()} ${error.htmlTemplate()}
        </div>
    `;
};

import { OUTBOUND } from "./../shared/commonConstants";
import { PriorityBoardingJourneyModel } from "./../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { PriorityBoardingModel } from "./../component-models/extras/priority-boarding/PriorityBoardingModel";
import { ApiExtrasSpaSectionViewModel } from "../component-models/spa/ExtrasSpaSectionViewModel";

export function mapToPriorityBoardingModel(model: ApiExtrasSpaSectionViewModel): PriorityBoardingModel {
    const journeys: PriorityBoardingJourneyModel[] = model.ExtrasModel.PriorityBoardingJourneys.map((journey) => ({
        Passengers: journey.Passengers.map((passenger) => ({
            Amount: passenger.Amount,
            FormattedAmount: passenger.FormattedAmount,
            IsDisabled: passenger.IsDisabled,
            IsSold: passenger.IsSold,
            PassengerNumber: passenger.PassengerNumber,
            SsrAddKey: passenger.SsrAddKey,
            SsrDeleteKey: passenger.SsrDeleteKey,
            Type: passenger.Type,
        })),
        JourneyIndex: journey.JourneyIndex,
        IsInBundle: journey.IsInBundle,
        BundleColor: journey.JourneyIndex === OUTBOUND ? model.OutboundBundleColor : model.InboundBundleColor,
        BundleImg: journey.JourneyIndex === OUTBOUND ? model.OutboundBundleImgUrl : model.InboundBundleImgUrl,
        DepartureStationName: journey.DepartureStationName,
        DestinationStationName: journey.DestinationStationName,
    }));

    return {
        Journeys: journeys,
    };
}

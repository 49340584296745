import { classMap } from "lit-html/directives/class-map";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import i18next from "i18next";
import {
    COLOMBIAN_BOARDING_FEE_CODE,
    COLOMBIAN_SALES_TAX_CODE,
    COLOMBIAN_INTERNATIONAL_TOURISM_ARRIVAL_TAX_CODE,
    COLOMBIAN_STAMP_TAX_CODE,
    COLOMBIAN_TAX_CODES,
    FLEXI_FEE_CODES,
    AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES,
} from "../../shared/commonConstants";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
export const name = "ac-breakdown-line";

const [leftSideUnits, rightSideUnits] = [6, 3];

export interface Props {
    chargeCode?: string;
    count: number;
    dataTestIdName: string;
    dataTestIdPrice: string;
    displayName: string;
    inTotal: boolean;
    labelIndex: string;
    price: string;
    showGiftcard: boolean;
    unformattedPrice: number;
    handleBundleOpenerChange: (id: string) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        chargeCode: host?.chargeCode,
        count: host.count,
        dataTestIdName: host.dataTestIdName,
        dataTestIdPrice: host.dataTestIdPrice,
        displayName: host.displayName,
        inTotal: host.inTotal,
        labelIndex: host.labelIndex,
        price: host.price,
        showGiftcard: host.showGiftcard,
        unformattedPrice: host.unformattedPrice,
        handleBundleOpenerChange: host.handleBundleOpenerChange,
    };

    // Helpers

    const leftSideClassName = `col-xs-${leftSideUnits}-${leftSideUnits + rightSideUnits}`;
    const rightSideClassName = `col-xs-${rightSideUnits}-${leftSideUnits + rightSideUnits}`;

    const isBundleOpener = () => props.labelIndex && typeof props.handleBundleOpenerChange === "function";

    const isColombianTax = () => COLOMBIAN_TAX_CODES.includes(props?.chargeCode);

    const isPeruCompraFlexi = () =>
        (userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking) &&
        FLEXI_FEE_CODES.includes(props?.chargeCode);

    const isAmericanAirlinesMiles = () => AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES.includes(props?.chargeCode);

    const amount = () => (typeof props.count === "number" && props.count > 1 ? props.count : undefined);

    // Event handlers

    // Component

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    // Templates

    const leftClassMap = classMap({
        [leftSideClassName]: true,
        "in-total": props.inTotal,
    });

    const rightClassMap = classMap({
        [rightSideClassName]: true,
        "in-total": props.inTotal,
    });

    const bundleOpenerClassMap = classMap({
        "bd-bundle-opener": true,
        "open": !(
            userContext.peruCompra.isAdmin ||
            userContext.peruCompra.isMember ||
            bookingContext.isPeruCompraBooking
        ),
    });

    const bundleOpenerTemplate = () => html`
        <label class=${bundleOpenerClassMap} @click=${props.handleBundleOpenerChange} for=${props.labelIndex}>
            <span> ${props.displayName} </span>
        </label>
    `;

    const tooltipTextTemplate = () => {
        switch (props.chargeCode) {
            case COLOMBIAN_BOARDING_FEE_CODE:
                return i18next.t(
                    "La tasa aeroportuaria es la remuneración que se cobra a los usuarios viajeros por el uso de las instalaciones y servicios de las terminales de pasajeros.",
                );
            case COLOMBIAN_STAMP_TAX_CODE:
                return i18next.t("Origina la salida al exterior de nacionales y extranjeros residentes en Colombia.");
            case COLOMBIAN_INTERNATIONAL_TOURISM_ARRIVAL_TAX_CODE:
                return i18next.t(
                    "Tasa que aplica a extranjeros que ingresen al territorio Colombiano por vía aérea en vuelos regulares.",
                );
            case COLOMBIAN_SALES_TAX_CODE:
                return i18next.t("Tarifa que se le suma al valor bruto de un bien o servicio.");
            default:
                return "";
        }
    };

    const tooltipTemplate = () => html`<ac-tooltip .icon=${"?"} .tooltip=${tooltipTextTemplate()}></ac-tooltip>`;

    const amountTemplate = () =>
        isAmericanAirlinesMiles()
            ? html`<span>
                  ${unsafeHTML(
                      i18next.t("Millas AAdvantage {{-reg}}", {
                          reg: `<span class="relative top-[-1px] text-sm">&reg;</span>`,
                      }),
                  )}
              </span>`
            : isPeruCompraFlexi()
              ? i18next.t("Cambio sin cargo")
              : isColombianTax()
                ? html` <div class="in-total-tooltip">
                      ${amount()?.toString() || ""} ${props.displayName} ${tooltipTemplate()}
                  </div>`
                : html` ${amount()?.toString() || ""} ${props.displayName}`;

    const leftSideTemplate = () => html`
        <div class=${leftClassMap} data-test-id=${props.dataTestIdName} data-test-value=${amount() || ""}>
            ${isBundleOpener() ? bundleOpenerTemplate() : amountTemplate()}
        </div>
    `;

    const rightSideTemplate = () =>
        html` <div class=${rightClassMap}>
            <span
                class="non-breaking-price"
                data-test-id=${props.dataTestIdPrice}
                data-test-value=${props.unformattedPrice}
                >${props.price}</span
            >
        </div>`;

    return html`
        <div class=${flowContext.isGiftcardPurchaseFlow && props.showGiftcard ? "giftcard-sb-push-down" : ""}>
            <div class="row">${leftSideTemplate()}${rightSideTemplate()}</div>
        </div>
    `;
};

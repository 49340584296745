import { useCallback, useEffect as hauntedUseEffect } from "haunted";
import { useState } from "../shared/haunted/CustomHooks";
import { AbstractValidation, Messages } from "./Validation";
import { unique } from "../shared/IterableHelpers";
import { debounceCallback } from "../shared/common";
import { useDebouncedFunc } from "../shared/haunted/useDebouncedFunc";

export interface FluentValidatorProps<FN extends string, VM> {
    validated: boolean | Set<`${keyof VM & string}.${number}`>;
    validations: AbstractValidation<FN, VM>[];
    vm: VM;
    dispatcher?: () => void;
}

export const useFluentValidator = <FN extends string, VM>(props: FluentValidatorProps<FN, VM>) => {
    const isFieldValid = (field: FN) => {
        if (props.validated === undefined) return true;

        if (typeof props.validated === "boolean") return !props.validated || !messages[field];

        const validatedPrefixes = Array.from((props.validated as Set<string>).values());

        return !Object.keys(messages).some((messageKey) => {
            return validatedPrefixes.some((validatedPrefix) => {
                return (
                    field === `${validatedPrefix}.${messageKey.split(".")[2]}` && messageKey.startsWith(validatedPrefix)
                );
            });
        });
    };

    // DEVNOTE We debounce the validation to let debounced inputs finish
    const getValidationResult = useCallback(async (): Promise<boolean> => {
        const newMessages = {} as Messages<string>;
        for (const validation of props.validations) {
            const result = await validation.validate(props.vm);
            for (const res of result) {
                newMessages[res.field] = res.message;
            }
        }

        setMessages(newMessages);

        return Object.keys(newMessages).length === 0;
    }, [props.vm, props.validations]);

    // DEVNOTE We debounce the validation to let debounced inputs finish
    const validate = useCallback(
        async (): Promise<boolean> => debounceCallback<boolean>(getValidationResult),
        [getValidationResult],
    );

    const validateAndDispatch = async (): Promise<void> => {
        const result = await validate();

        if (result && props.dispatcher) props.dispatcher();
    };

    const getMessage = (field: FN) =>
        !isFieldValid(field) && messages[field]?.scope !== "form" ? messages[field]?.text : undefined;

    const getFormMessages = () => {
        if (typeof props.validated === "boolean") {
            if (!props.validated) return [];

            const formMessages = Array.from(Object.keys(messages))
                .filter((key) => messages[key].scope === "form")
                .map((key) => messages[key].text);

            return unique(formMessages);
        }

        if (!props.validated?.size) return [];

        const formMessages = Array.from(Object.keys(messages))
            .filter((key) => {
                const paxKeyItems = key.split(".");
                const paxKey = `${paxKeyItems[0]}.${paxKeyItems[1]}`;

                return messages[key].scope === "form" && (props.validated as Set<string>).has(paxKey);
            })
            .map((key) => messages[key].text);

        return unique(formMessages);
    };

    const isValid = (field: FN) => !props.validated || !messages[field];

    const [messages, setMessages] = useState({} as Messages<string>);

    const debouncedValidate = useDebouncedFunc(validate);

    // DEVNOTE Debounce needed for ajax/onInput debounce and also Chrome autofill bugs
    hauntedUseEffect(debouncedValidate, [props.vm]);

    return {
        messages,
        getMessage,
        getFormMessages,
        isFieldValid,
        isValid,
        validate,
        validateAndDispatch,
    };
};

import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import i18next from "i18next";

export const name = "ac-no-peru-compra-dc";

export const Component: HauntedFunc<{}> = () => {
    return html`
        <div class="modal primary-modal">
            <div class="modal-content booking-modal-content peru-compra">
                <div class="modal-header text-center">${i18next.t("Importante")}</div>
                <div class="modal-body text-center leading-loose text-be-blue">
                    ${i18next.t(
                        "La compra de membresía de Club de Descuentos no está disponible para usuarios PerúCompras.",
                    )}
                </div>
                <div class="my-8 flex items-center justify-center">
                    <button class="rounded-primary-btn" @click=${() => (window.location.href = "/")}>
                        ${i18next.t("Volver a Jetsmart.com")}
                    </button>
                </div>
            </div>
        </div>
    `;
};

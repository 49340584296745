import { PaymentFlowFinishCallbackType } from "../../component-models/payment/PaymentFlowFinishCallbackType";
import { PaymentIntentFlow } from "../../component-models/payment/PaymentIntentFlow";
import { PaymentIntentFlowType } from "../../component-models/payment/PaymentIntentFlowType";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";

export class PaymentIntentFlowHelper {
    public static getPaymentIntentFlow(
        mode: PaymentIntentFlowType,
        callbackMap: Map<
            PaymentFlowFinishCallbackType,
            (type: PaymentIntentFlowType, loader: JsLoader, voucherResult: ApiVoucherResult) => void
        >,
    ): PaymentIntentFlow {
        const paymentIntentFlowMap = new Map<PaymentIntentFlowType, PaymentIntentFlow>([
            [
                "afterCommission",
                {
                    CallbackOnFinish: undefined,
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "never",
                    SubmitForms: "none",
                    ValidateForms: "none",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "agency",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLog"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: true,
                },
            ],
            [
                "beforeCommission",
                {
                    CallbackOnFinish: undefined,
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: false,
                    StopIfInvalid: "always",
                    SubmitForms: "none",
                    ValidateForms: "none",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "bn",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLog"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "bp",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLog"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "cat1234CreditShellGetInfo",
                {
                    CallbackOnFinish: callbackMap.get("commit1234CreditShell"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: true,
                    KeepLoaderAtEnd: false,
                    StopIfInvalid: "conditional",
                    SubmitForms: "none",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "conditional",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "cat1234CreditShellPay",
                {
                    CallbackOnFinish: callbackMap.get("commit"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "cat56CreditShellGetInfo",
                {
                    CallbackOnFinish: callbackMap.get("commit56CreditShell"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: true,
                    KeepLoaderAtEnd: false,
                    StopIfInvalid: "always",
                    SubmitForms: "none",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "cat56CreditShellPay",
                {
                    CallbackOnFinish: callbackMap.get("commit"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "creditShellCoversBalance",
                {
                    CallbackOnFinish: callbackMap.get("commit"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "checkoutPro",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLogAndAntifraud"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "safetyPay",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLogAndAntifraud"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "compraqui",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLogAndAntifraud"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: true,
                },
            ],
            [
                "et",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLogAndAntifraud"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "hold",
                {
                    CallbackOnFinish: callbackMap.get("commit"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactOnly",
                    ValidateForms: "contactOnly",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "peruCompra",
                {
                    CallbackOnFinish: callbackMap.get("commitPeruCompra"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "test",
                {
                    CallbackOnFinish: callbackMap.get("commit"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "never",
                    SubmitForms: "none",
                    ValidateForms: "none",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],

            [
                "voucherGetInfo",
                {
                    CallbackOnFinish: undefined,
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: false,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "voucherPay",
                {
                    CallbackOnFinish: callbackMap.get("commitVoucher"),
                    CheckGiftcardSession: false,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "none",
                    ValidateForms: "cuitOnly",
                    ValidateTerms: "never",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "voucherCoversBalance",
                {
                    CallbackOnFinish: callbackMap.get("commitVoucher"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "none",
                    ValidateForms: "cuitOnly",
                    ValidateTerms: "always",
                    ValidateXmlPayment: false,
                },
            ],
            [
                "xml",
                {
                    CallbackOnFinish: callbackMap.get("commitWithLogAndAntifraud"),
                    CheckGiftcardSession: true,
                    CheckPromoCode: false,
                    KeepLoaderAtEnd: true,
                    StopIfInvalid: "always",
                    SubmitForms: "contactAndInvoice",
                    ValidateForms: "contactAndInvoiceAndCuit",
                    ValidateTerms: "always",
                    ValidateXmlPayment: true,
                },
            ],
        ]);

        return paymentIntentFlowMap.get(mode);
    }
}

import { SeatmapHelper } from "../../../component-helpers/seatmap/SeatmapHelper";
import { ExtendedApiSeat } from "../../../component-models/seatmap/ApiSeat";
import { ApiSeatmapPassenger } from "../../../component-models/seatmap/ApiSeatmapPassenger";
import { AutoAssignedSeating } from "../../../component-models/seatmap/AutoAssignedSeating";
import { CurrentSeatings } from "../../../component-models/seatmap/CurrentSeatings";
import { SeatmapJourneySegment } from "../../../component-models/seatmap/SeatmapJourneySegment";
import { SeatmapModel } from "../../../component-models/seatmap/SeatmapModel";
import { SeatmapState } from "../../../component-models/seatmap/SeatmapState";
import { useSeatmapTealiumManager } from "../../../managers/Tealium/useSeatmapTealiumManager";
import { useFlowContext } from "../../../managers/useFlowContext";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { OUTBOUND, INBOUND, COOKIE_NAMES } from "../../../shared/commonConstants";
import { setSessionCookie } from "../../../shared/cookieHandling";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface Props {
    model: SeatmapModel;
    seatmapState: SeatmapState;
    hasFlightColombianStation: boolean;
}

export function useCurrentSeatings(props: Props): CurrentSeatings {
    const [userContext] = useReduxState("userContext");
    const flowContext = useFlowContext();

    const tealiumManager = useSeatmapTealiumManager();
    const { triggers } = usePubSub();

    const [currency] = useReduxState("booking.currency");

    const [passengerSeats, setPassengerSeats] = useState<ExtendedApiSeat[]>([]);
    const [initialPassengerSeats, setInitialPassengerSeats] = useState<ExtendedApiSeat[]>(undefined);

    const init = () => {
        const seats = getInitialPassengerSeats();
        setPassengerSeats(seats);
        setInitialPassengerSeats(seats);
    };

    const canRecommendSeatsForSegment = (segment: SeatmapJourneySegment) =>
        segment.Seats.every((seat) => seat.PassengerNumber === -1) && segment.SeatMapRecommendation?.length > 0;

    const getInitialPassengerSeats = () =>
        props.model.Journeys.reduce(
            (allSeats: ExtendedApiSeat[], journey) =>
                allSeats.concat(
                    journey.Segments.reduce(
                        (journeySeats: ExtendedApiSeat[], segment) =>
                            journeySeats.concat(
                                segment.Seats.map((segmentSeat) => ({
                                    ...segmentSeat,
                                    PassengerNumber:
                                        !flowContext.isPostBookingFlow &&
                                        canRecommendSeatsForSegment(segment) &&
                                        SeatmapHelper.isRecommendatorOnForSegment(
                                            journey,
                                            segment.SegmentIndex,
                                            userContext.bancoEstado.category === 0,
                                            flowContext.isBookingFlow,
                                            props.hasFlightColombianStation,
                                        )
                                            ? segmentSeat.RecommendedPassengerNumber
                                            : segmentSeat.PassengerNumber,
                                })),
                            ),
                        [],
                    ),
                ),
            [],
        );

    const passengerIsSeatedForSegment = (passengerNumber: number) =>
        passengerSeats.some(
            (seating) =>
                seating.PassengerNumber === passengerNumber &&
                seating.JourneyIndex === props.seatmapState?.CurrentJourney.JourneyIndex &&
                seating.SegmentIndex === props.seatmapState.CurrentSegment.SegmentIndex,
        );

    const isPassengerWithInfantAndNoSeat = (passenger: ApiSeatmapPassenger) =>
        passenger.HasInfant && !passengerIsSeatedForSegment(passenger.PassengerNumber);

    const isPassengerWithInfantAndSeat = (passenger: ApiSeatmapPassenger) =>
        passenger.HasInfant && passengerIsSeatedForSegment(passenger.PassengerNumber);

    const isSeatingBlocked = useMemo(() => {
        if (flowContext.isCheckinFlow || !props.seatmapState) return false;

        const maxInfant = props.seatmapState.CurrentSegment.AvailableInfantRowCount;

        if (maxInfant === 0 && props.model.Passengers.some(isPassengerWithInfantAndNoSeat)) {
            return true;
        }
        const passengersNotSeatedWithInfant = props.model.Passengers.filter(isPassengerWithInfantAndNoSeat).length;
        const passengersSeatedWithInfant = props.model.Passengers.filter(isPassengerWithInfantAndSeat).length;

        return passengersNotSeatedWithInfant > 0 && passengersSeatedWithInfant === maxInfant;
    }, [flowContext.isCheckinFlow, props.seatmapState?.CurrentSegment?.AvailableInfantRowCount, passengerSeats]);

    // PUBLIC

    const areRecommendationsSelected = (): boolean => {
        const seatsForSegment = passengerSeats.filter(
            (seat) =>
                seat.JourneyIndex === props.seatmapState?.CurrentJourney.JourneyIndex &&
                seat.SegmentIndex === props.seatmapState.CurrentSegment.SegmentIndex &&
                seat.PassengerNumber >= 0,
        );

        return (
            userContext.bancoEstado.category === 0 &&
            flowContext.isBookingFlow &&
            props.seatmapState?.CurrentSegment.SeatMapRecommendation?.length > 0 &&
            seatsForSegment.length === props.seatmapState.CurrentSegment.SeatMapRecommendation.length &&
            seatsForSegment.every((seat) => seat.PassengerNumber === seat.RecommendedPassengerNumber)
        );
    };

    const updateOnAutoAssignment = (autoAssignedSeatings: AutoAssignedSeating[]) => {
        const newSeats = passengerSeats.map((seat) =>
            seat.JourneyIndex === journeyIndex() && seat.SegmentIndex === segmentIndex()
                ? {
                      ...seat,
                      IsAutoAssigned: Boolean(seatInAutoAssignedSeatings(autoAssignedSeatings, seat)),
                      PassengerNumber: seatInAutoAssignedSeatings(autoAssignedSeatings, seat)?.PaxIndex,
                  }
                : { ...seat },
        );

        setPassengerSeatsWithFreePropertyUpdate(newSeats);
    };

    const updateOnReset = () => {
        const newSeats = passengerSeats.map((s) => ({
            ...s,
            PassengerNumber: shouldFullyResetSeat(s) ? -1 : s.OriginalPassengerNumber,
            IsFree: shouldFullyResetSeat(s) ? false : s.IsFree,
        }));

        setPassengerSeatsWithFreePropertyUpdate(newSeats);

        return newSeats;
    };

    const updateOnTakeRecommended = () => {
        const newSeats = passengerSeats.map((s) => {
            const isSameSegment = s.JourneyIndex === journeyIndex() && s.SegmentIndex === segmentIndex();

            const newPassengerNumber = isSameSegment ? s.RecommendedPassengerNumber : s.PassengerNumber;

            return { ...s, PassengerNumber: newPassengerNumber };
        });

        newSeats
            .filter(
                (s) => s.JourneyIndex === journeyIndex() && s.SegmentIndex === segmentIndex() && s.PassengerNumber >= 0,
            )
            .forEach((s) => {
                const tealiumOutboundSeats = newSeats
                    .filter((s) => s.JourneyIndex === OUTBOUND && s.PassengerNumber >= 0)
                    .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

                const tealiumInboundSeats = newSeats
                    .filter((s) => s.JourneyIndex === INBOUND && s.PassengerNumber >= 0)
                    .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

                tealiumManager.logSeatSelected(
                    props.seatmapState.CurrentPassenger.Type === "ADT" ? "A" : "C",
                    props.seatmapState.CurrentPassenger.PassengerNumber,
                    s,
                    undefined,
                    tealiumOutboundSeats,
                    tealiumInboundSeats,
                );
            });

        setPassengerSeatsWithFreePropertyUpdate(newSeats);

        return newSeats;
    };

    // TODO CFS Only works with one segment
    const updateOnTakeSameForReturn = () => {
        const outboundSeats = passengerSeats.filter((s) => s.JourneyIndex === OUTBOUND && s.PassengerNumber >= 0);

        const newSeats = passengerSeats.reduce((transformedSeats: ExtendedApiSeat[], seat: ExtendedApiSeat) => {
            const outboundPair = outboundSeats.find(
                (outboundSeat) =>
                    outboundSeat.Row === seat.Row &&
                    outboundSeat.Column === seat.Column &&
                    outboundSeat.PassengerNumber >= 0,
            );

            return transformedSeats.concat([
                seat.JourneyIndex === INBOUND
                    ? {
                          ...seat,

                          PassengerNumber:
                              outboundPair?.PassengerNumber >= 0 ? outboundPair.PassengerNumber : seat.PassengerNumber,
                      }
                    : { ...seat },
            ]);
        }, new Array<ExtendedApiSeat>());

        setPassengerSeatsWithFreePropertyUpdate(newSeats);

        newSeats
            .filter((seating) => seating.JourneyIndex === INBOUND && seating.PassengerNumber > -1)
            .forEach((seating) => {
                // TODO CFS Only works with one segment
                const tealiumOutboundSeats = newSeats
                    .filter((s) => s.JourneyIndex === OUTBOUND && s.PassengerNumber >= 0)
                    .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

                const tealiumInboundSeats = newSeats
                    .filter((s) => s.JourneyIndex === INBOUND && s.PassengerNumber >= 0)
                    .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

                const paxType =
                    props.model.Passengers.find((p) => p.PassengerNumber === seating.PassengerNumber).Type === "ADT"
                        ? "A"
                        : "C";

                tealiumManager.logSameSeatSelectedInbound(
                    paxType,
                    seating.PassengerNumber,
                    `${seating.Row}${seating.Column}`,
                    seating.Price,
                    tealiumOutboundSeats,
                    tealiumInboundSeats,
                );
            });
    };

    const updateOnClick = (seat: ExtendedApiSeat) => {
        const paxNumber = props.seatmapState.CurrentPassenger.PassengerNumber;
        const newSeats = passengerSeats.map((s) => {
            const isOldSeat =
                s.PassengerNumber === paxNumber &&
                s.JourneyIndex === journeyIndex() &&
                s.SegmentIndex === segmentIndex();
            const isNewSeat =
                s.JourneyIndex === journeyIndex() &&
                s.SegmentIndex === segmentIndex() &&
                s.Row === seat.Row &&
                s.Column === seat.Column;

            return {
                ...s,
                IsAutoAssigned: isNewSeat ? false : s.IsAutoAssigned,
                PassengerNumber: isOldSeat ? -1 : isNewSeat ? paxNumber : s.PassengerNumber,
                IsFree: false,
                IsDiscounted: false,
            };
        });

        setPassengerSeatsWithFreePropertyUpdate(newSeats);

        return newSeats;
    };

    const updateOnDelete = (passengerNumber: number) => {
        if (!flowContext.isChangeFlow) {
            removePaxForSegmentFromSeats(passengerNumber);
        } else {
            resetSeat(passengerNumber);
        }
    };

    // PRIVATE

    const setPassengerSeatsWithFreePropertyUpdate = (seats: ExtendedApiSeat[]) => {
        const newSeats = seats
            .sort((a, b) => sortByJourneyIndexThenSegmentIndexThenPaxIndex(a, b))
            .reduce(
                (data: { transformedSeats: ExtendedApiSeat[]; remainingFreeSeats: number }, s) => {
                    const isFree =
                        s.PassengerNumber >= 0 &&
                        data.remainingFreeSeats > 0 &&
                        isPossibleBancoEstadoFreeSeat(s) &&
                        !props.model.Journeys[s.JourneyIndex].AreAllSeatsInBundle;
                    return {
                        transformedSeats: data.transformedSeats.concat([
                            { ...s, IsFree: isFree, IsDiscounted: isClickedSeatDiscounted(s) },
                        ]),
                        remainingFreeSeats: isFree ? data.remainingFreeSeats - 1 : data.remainingFreeSeats,
                    };
                },
                {
                    transformedSeats: [],
                    remainingFreeSeats: userContext.bancoEstado.remainingFreeSeats + props.model.FreeSeatsInBooking,
                },
            );

        setPassengerSeats(newSeats.transformedSeats);
        updateFreeSeatsUsedInBooking(newSeats.transformedSeats);
        updateDiscountedSeatsUsedInBooking(newSeats.transformedSeats);
    };

    const seatInAutoAssignedSeatings = (autoAssignedSeatings: AutoAssignedSeating[], seat: ExtendedApiSeat) =>
        autoAssignedSeatings.find(
            (aas) =>
                aas.JourneyIndex === seat.JourneyIndex &&
                aas.SegmentIndex === seat.SegmentIndex &&
                aas.Row === seat.Row &&
                aas.Column === seat.Column,
        );

    const removePaxForSegmentFromSeats = (passengerNumber: number) => {
        const newSeats = passengerSeats.map((s) =>
            s.JourneyIndex === journeyIndex() &&
            s.SegmentIndex === segmentIndex() &&
            s.PassengerNumber === passengerNumber
                ? {
                      ...s,
                      PassengerNumber: -1,
                  }
                : { ...s },
        );

        setPassengerSeatsWithFreePropertyUpdate(newSeats);
    };

    const resetSeat = (passengerNumber: number) => {
        const newSeats = passengerSeats.map((s) => {
            const isCurrentSeat =
                s.PassengerNumber === passengerNumber &&
                s.JourneyIndex === journeyIndex() &&
                s.SegmentIndex === segmentIndex();
            const isOriginalSeat =
                s.OriginalPassengerNumber === passengerNumber &&
                s.JourneyIndex === journeyIndex() &&
                s.SegmentIndex === segmentIndex();

            return {
                ...s,
                PassengerNumber: isOriginalSeat ? s.OriginalPassengerNumber : isCurrentSeat ? -1 : s.PassengerNumber,
            };
        });

        setPassengerSeatsWithFreePropertyUpdate(newSeats);
    };

    const isPossibleBancoEstadoFreeSeat = (seat: ExtendedApiSeat) => {
        return (seat.IsBancoEstado || seat.IsBancoEstadoSecondary) && [5, 6].includes(userContext.bancoEstado.category);
    };

    const sortByJourneyIndexThenSegmentIndexThenPaxIndex = (a: ExtendedApiSeat, b: ExtendedApiSeat) => {
        return (a.JourneyIndex + 1) * 16384 + (a.SegmentIndex + 1) * 512 + a.PassengerNumber >
            (b.JourneyIndex + 1) * 16384 + (b.SegmentIndex + 1) * 512 + b.PassengerNumber
            ? 1
            : -1;
    };

    const updateDiscountedSeatsUsedInBooking = (seats: ExtendedApiSeat[]) => {
        const numberOfDiscountedSeatsUsedInBooking = SeatmapHelper.numberOfDiscountedSeatsUsedInBooking(seats);
        triggers.seatmap.updateDiscountedSeatsUsed.publish(numberOfDiscountedSeatsUsedInBooking);

        setSessionCookie(
            COOKIE_NAMES.DiscountedSeatsUsedInBooking,
            `${flowContext.bsid}||${numberOfDiscountedSeatsUsedInBooking}`,
        );
    };

    const updateFreeSeatsUsedInBooking = (seats: ExtendedApiSeat[]) => {
        const numberOfFreeSeatsUsedInBooking = SeatmapHelper.getFreeSeatsUsedInBooking(seats);
        triggers.seatmap.updateFreeSeatsUsed.publish(numberOfFreeSeatsUsedInBooking);

        setSessionCookie(
            COOKIE_NAMES.FreeSeatsUsedInBooking,
            `${flowContext.bsid}||${numberOfFreeSeatsUsedInBooking.toString()}`,
        );
    };

    const isClickedSeatDiscounted = (seat: ExtendedApiSeat) => {
        if (props.model.Journeys.find((j) => j.JourneyIndex === seat.JourneyIndex).AreAllSeatsInBundle) {
            return false;
        }

        return [5, 6].includes(userContext.bancoEstado.category) && seat.IsBancoEstado;
    };

    // const isClickedSeatFree = (seat: ExtendedApiSeat, seats: ExtendedApiSeat[]) => {
    //     if (props.seatmapState.CurrentJourney.AreAllSeatsInBundle) {
    //         return false;
    //     }

    //     return (
    //         (SeatmapHelper.remainingAvailableFreeSeatsForBooking(props.model.FreeSeatsInBooking, seats) > 0 &&
    //             (seat.IsBancoEstado || seat.IsBancoEstadoSecondary)) ||
    //         (seat.OriginalPassengerNumber === props.seatmapState.CurrentPassenger.PassengerNumber &&
    //             seat.IsBancoEstadoFreeSeatDuringBooking)
    //     );
    // };

    const shouldFullyResetSeat = (s: ExtendedApiSeat) => s.PassengerNumber >= 0 && !flowContext.isPostBookingFlow;

    const journeyIndex = () => props.seatmapState?.CurrentJourney.JourneyIndex;

    const segmentIndex = () => props.seatmapState?.CurrentSegment.SegmentIndex;

    const isMultisegment = () => props.seatmapState?.CurrentJourney?.Segments.length > 1;

    const getDesignatorsForFirstSegment = () =>
        passengerSeats
            .filter(
                (seating) =>
                    seating.PassengerNumber >= 0 &&
                    seating.SegmentIndex === 0 &&
                    seating.JourneyIndex === props.seatmapState?.CurrentJourney.JourneyIndex,
            )
            .map((seating) => `${seating.Row}${seating.Column}`);

    const isPlaneTypeTheSameOnAllSegments = () =>
        new Set(props.seatmapState?.CurrentJourney.Segments.map((s) => s.PlaneType)).size === 1;

    const areSameSeatsAvailableForNextSegment = () =>
        getDesignatorsForFirstSegment().every((designator) =>
            passengerSeats.some(
                (seating) =>
                    designator === `${seating.Row}${seating.Column}` &&
                    seating.PassengerNumber === -1 &&
                    seating.IsAvailable &&
                    seating.SegmentIndex === 1 &&
                    seating.JourneyIndex === props.seatmapState?.CurrentJourney.JourneyIndex,
            ),
        );

    const isAnyoneSeatingOnNextSegment = () =>
        passengerSeats.some(
            (seating) =>
                seating.JourneyIndex === props.seatmapState?.CurrentJourney.JourneyIndex &&
                seating.SegmentIndex === 1 &&
                seating.PassengerNumber > -1,
        );

    const isSecondSegment = () => props.seatmapState?.CurrentSegment.SegmentIndex === 1;

    const canAddSameSeatsToNextSegment = () =>
        isMultisegment() &&
        isSecondSegment() &&
        !props.model.IsReadOnly &&
        !isAnyoneSeatingOnNextSegment() &&
        areSameSeatsAvailableForNextSegment() &&
        isPlaneTypeTheSameOnAllSegments();

    useEffect(() => {
        if (userContext?.userRole && props.model && props.seatmapState && initialPassengerSeats === undefined) init();
    }, [userContext?.userRole, props.model, props.seatmapState, initialPassengerSeats]);

    return {
        canAddSameSeatsToNextSegment: canAddSameSeatsToNextSegment(),
        initialPassengerSeats,
        isSeatingBlocked,
        value: passengerSeats,
        areRecommendationsSelected,
        updateOnAutoAssignment,
        updateOnClick,
        updateOnDelete,
        updateOnReset,
        updateOnTakeRecommended,
        updateOnTakeSameForReturn,
    };
}

import { classMap } from "lit-html/directives/class-map";
import { html, TemplateResult } from "lit-html";
import { SortOrder } from "./dc-table-models";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";

export const name = "dc-table-grid-header";

const DEFAULTS: Properties = {
    fieldName: "",
    headerTemplate: undefined,
    label: "",
    orderBy: "",
    orderDir: "none",
    sortable: false,
    useEllipsis: true,
    onSortClicked: undefined,
};

export interface Properties {
    fieldName: string;
    headerTemplate: TemplateResult;
    label?: string;
    orderBy?: string;
    orderDir?: SortOrder;
    sortable: boolean;
    useEllipsis: boolean;
    onSortClicked?: (fieldName: string, sortOrder: SortOrder, e: Event) => void;
}

// tslint:disable-next-line: cyclomatic-complexity
export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        fieldName: host.fieldName !== undefined ? host.fieldName : DEFAULTS.fieldName,
        headerTemplate: host.headerTemplate !== undefined ? host.headerTemplate : DEFAULTS.headerTemplate,
        label: host.label !== undefined ? host.label : DEFAULTS.label,
        orderBy: host.orderBy !== undefined ? host.orderBy : DEFAULTS.orderBy,
        orderDir: host.orderDir !== undefined ? host.orderDir : DEFAULTS.orderDir,
        sortable: host.sortable !== undefined ? host.sortable : DEFAULTS.sortable,
        useEllipsis: host.useEllipsis !== undefined ? host.useEllipsis : DEFAULTS.useEllipsis,
        onSortClicked: host.onSortClicked !== undefined ? host.onSortClicked : DEFAULTS.onSortClicked,
    };

    // EVENT HANDLERS

    const handleClick = (e: MouseEvent) => {
        if (!props.sortable) {
            return;
        }

        const sortOrder = props.orderBy === props.fieldName ? props.orderDir : "none";
        props.onSortClicked(props.fieldName, sortOrder === "none" || sortOrder === "desc" ? "asc" : "desc", e);
    };

    // STATES

    // TEMPLATES

    const contentTemplate = () => (props.headerTemplate ? props.headerTemplate : props.label);

    const mainClassMap = classMap({
        "flex": true,
        "justify-center": true,
        "w-full": true,
        "h-full": true,
        "items-center": true,
        "cursor-pointer": props.sortable,
        "whitespace-nowrap": props.useEllipsis,
        "overflow-hidden": props.useEllipsis,
    });

    return html`
        <div class=${mainClassMap} @click=${handleClick}>
            ${contentTemplate()}
        </div>
    `;
};

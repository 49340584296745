import i18next from "i18next";
import { CustomizedBundle } from "../component-models/flight/BundleOffersV2Model";
import { ApiItineraryViewModelV2 } from "../component-models/itinerary/ApiItineraryViewModelV2";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import { FlightPageViewModel } from "../component-models/flight/FlightPageViewModel";
import {
    AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES,
    DEFAULT_DATE_FORMAT,
    OUTBOUND,
} from "../shared/commonConstants";
import { ExtendedFlightDateViewModel, FlightMoveTripModel } from "../component-models/FlightMoveTripModel";
import { ExtendedLowFareModel } from "../component-models/flight/ExtendedLowFareModel";
import { ExtendedTripModel } from "../component-models/flight/ExtendedTripModel";

// TODO OCH It is not much of a mapper really, but will be when voluntary change comes
// TODO OCH Map real code and display name
export function mapToFakeBundleOffer(
    bundleColor: string,
    bundleImg: string,
    isMileageAccrualActive: boolean,
): CustomizedBundle {
    return {
        BundleCode: "BND0",
        BundleDiscountPercentage: 0,
        BundlePrice: 0,
        BundleSSRs: isMileageAccrualActive ? [AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES[0]] : [],
        BundleType: "None",
        Color: bundleColor,
        ContainsFreeAnySeat: false,
        ContainsFreeRegularSeat: false,
        DesktopPosition: 1,
        DisplayName: i18next.t("Vuela Ligero"),
        FooterImgUrl: "",
        FormattedPrice: "$0",
        HeaderImgUrl: bundleImg,
        MobilePosition: 1,
        NonDiscountedPrice: 0,
        SsrInfoImgUrl: "",
        SumOfSsrPricesWithoutBundle: 0,
        UnFormattedPrice: 0,
        UpgradeImgMobileUrl: "",
        UpgradeImgUrl: "",
    };
}

export function mapToFakeFlightPageViewModel(data: {
    trips: FlightMoveTripModel[];
    selectedJourneys: number[];
    itineraryModel: ApiItineraryViewModelV2;
    selectedOutboundDate: dayjs.Dayjs;
    disabledOutboundDates: dayjs.Dayjs[];
    selectedInboundDate: dayjs.Dayjs;
    disabledInboundDates: dayjs.Dayjs[];
    lastOutboundDate: dayjs.Dayjs;
    lastInboundDate: dayjs.Dayjs;
}): FlightPageViewModel {
    const journeys = data.trips.map((trip) =>
        mapToExtendedTripModel(
            trip,
            data.selectedJourneys,
            data.itineraryModel,
            data.selectedOutboundDate,
            data.disabledOutboundDates,
            data.selectedInboundDate,
            data.disabledInboundDates,
            data.lastOutboundDate,
            data.lastInboundDate,
        ),
    );

    const isSameDay =
        data.trips.length === 2 &&
        dayjs(data.trips[0].FlightDates[0].DepartureDate).isSame(
            dayjs(data.trips[1].FlightDates[0].DepartureDate),
            "day",
        );

    return {
        BundleViewModel: {
            BundlesMode: "PerLeg",
            FullBundleImgUrl: undefined,
            IsBundlesActive: true,
            NoBundleImgUrl: undefined,
            SimpleBundleImgUrl: undefined,
        },
        DiscountClubViewModel: {
            BaggageMin: undefined,
            CanBuyMembership: false,
            DiscountClubSettings: undefined,
            DiscountMin: undefined,
            DiscountOver: undefined,
            GroupCode: undefined,
            GroupPrice: undefined,
            GroupPriceUnformatted: undefined,
            HasGroupDcMembership: false,
            HasStandardDcMembership: false,
            IsBuyingGroupMembership: false,
            IsBuyingStandardMembership: false,
            IsDcEligible: false,
            IsDcFeeSelectedInBooking: false,
            IsDiscountRequested: false,
            IsExtendingMembership: false,
            IsUpgradingMembership: false,
            MembershipDaysRemaining: undefined,
            PrivacyUrl: "",
            ProgramNumber: undefined,
            ShowBancoEstadoUpgradeBubble: false,
            ShowTooManyPaxModal: false,
            ShowUpgradeMembershipInfo: false,
            ShowWarning15: false,
            ShowWarning30: false,
            StandardCode: undefined,
            StandardPrice: undefined,
            StandardPriceUnformatted: undefined,
            TermsUrl: "",
            UpgradeCode: undefined,
            UpgradePrice: undefined,
        },
        FarelockViewModel: {
            IsFarelockShown: false,
            MinimumPriceForFareLock24: 0,
            MinimumPriceForFareLock48: 0,
            SelectedFareLock: undefined,
            SellKeyForFareLock24: undefined,
            SellKeyForFareLock48: undefined,
        },
        FlightDataViewModel: {
            AdultNumber: undefined,
            ChildNumber: undefined,
            Destination: undefined,
            InboundDate: undefined,
            InfantNumber: undefined,
            IsOneWay: data.selectedJourneys.length === 1,
            IsSameDay: isSameDay,
            Journeys: journeys, // DEVNOTE: consider to remove this or the other Journeys below
            NumberOfPax: 9,
            Origin: undefined,
            OutboundDate: undefined,
            SellKeys: undefined,
            StationSettings: undefined,
            SubjectToGovtApprovalText: "",
            TermsUrl: undefined,
            UnformattedInboundDate: undefined,
            UnformattedOutboundDate: undefined,
        },
        Availability: [],
        Currency: undefined,
        HasBooking: true,
        IsCugAdminOrSupervisor: false,
        PromoCode: "",
        PromoCodeErrorMessage: "",
        SelectedCurrency: undefined,
        Journeys: journeys,
    };
}

export function getCalendar(data: {
    journeyIndex: number;
    selectedOutboundDate: dayjs.Dayjs;
    disabledOutboundDates: dayjs.Dayjs[];
    selectedInboundDate: dayjs.Dayjs;
    disabledInboundDates: dayjs.Dayjs[];
    firstOutboundDate: dayjs.Dayjs;
    firstInboundDate: dayjs.Dayjs;
    lastOutboundDate: dayjs.Dayjs;
    lastInboundDate: dayjs.Dayjs;
}) {
    const calendar: ExtendedLowFareModel[] = [];

    const selectedDay =
        data.journeyIndex === OUTBOUND ? dayjs(data.selectedOutboundDate) : dayjs(data.selectedInboundDate);
    const disabledDates = data.journeyIndex === OUTBOUND ? data.disabledOutboundDates : data.disabledInboundDates;
    const maxDate = data.journeyIndex === OUTBOUND ? dayjs(data.lastOutboundDate) : dayjs(data.lastInboundDate);
    const minDate =
        data.journeyIndex === OUTBOUND
            ? data.firstOutboundDate || dayjs()
            : data.selectedOutboundDate
              ? dayjs(data.selectedOutboundDate)
              : data.firstInboundDate || dayjs();

    let currentDay = dayjs(minDate);

    while (currentDay.isSameOrBefore(maxDate, "day")) {
        const isDisabled =
            disabledDates.some((d) => d.isSame(currentDay, "day")) || currentDay.isBefore(minDate, "day");

        calendar.push({
            DayjsDate: dayjs(currentDay),
            DisplayDayHeader: currentDay.format("ddd DD-MM").replace(".", ""),
            FormattedDepartureDate: currentDay.format(DEFAULT_DATE_FORMAT),
            IsDisabled: isDisabled,
            IsInActiveDayRange: !isDisabled,
            IsSelectedDay: currentDay.isSame(selectedDay, "day"),
            LowestFareAmount: !isDisabled ? 0 : undefined,
            LowestFarePriceString: !isDisabled ? "$0" : "x",
            LowFareLink: "",
        });

        currentDay = currentDay.add(1, "day");
    }

    return calendar;
}

function mapToExtendedTripModel(
    trip: FlightMoveTripModel,
    selectedJourneys: number[],
    itineraryModel: ApiItineraryViewModelV2,
    selectedOutboundDate: dayjs.Dayjs,
    disabledOutboundDates: dayjs.Dayjs[],
    selectedInboundDate: dayjs.Dayjs,
    disabledInboundDates: dayjs.Dayjs[],
    lastOutboundDate: dayjs.Dayjs,
    lastInboundDate: dayjs.Dayjs,
): ExtendedTripModel {
    const lowestFares = getCalendar({
        journeyIndex: trip.JourneyIndex,
        selectedOutboundDate,
        disabledOutboundDates,
        selectedInboundDate,
        disabledInboundDates,
        firstInboundDate: null,
        firstOutboundDate: null,
        lastOutboundDate,
        lastInboundDate,
    });

    const tripDate =
        trip.JourneyIndex === OUTBOUND && selectedJourneys.includes(OUTBOUND) && !itineraryModel.Journeys[0].IsFlown
            ? selectedOutboundDate.format(DEFAULT_DATE_FORMAT)
            : selectedInboundDate.format(DEFAULT_DATE_FORMAT);

    return {
        // DEVNOTE If the first leg is flown, NVTR returns the second trip with trip index 0
        FlightDetails: trip.FlightDates[0].FlightDetails,
        Flights: [],
        FlightsAvailable: trip.FlightDates[0].FlightCount > 0,
        LowestFares: lowestFares,
        MarketFareKeys: undefined,
        StationNameDestination: itineraryModel.Journeys[0].ArrivalStationName,
        StationNameOrigin: itineraryModel.Journeys[0].DepartureStationName,
        Trip: { FlightDates: trip.FlightDates.map(mapDepartureDate) },
        TripDate: tripDate,
        TripIndex: itineraryModel.Journeys[0].IsFlown ? 1 : trip.JourneyIndex,
    };
}

function mapDepartureDate(item: ExtendedFlightDateViewModel) {
    return {
        DepartureDate: dayjs(item.FlightDetails[0].DepartureDate).format("ddd DD-MM").replace(".", ""),
    };
}

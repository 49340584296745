import {
    PRIORITISED_COUNTRIES,
    REFUND_INFO_ACCOUNT_TYPES,
    REFUND_INFO_BANK_NAMES,
    REFUND_INFO_DOC_TYPES,
} from "./RefundBankInfoHelper";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { html, useMemo, useRef } from "haunted";
import {
    getAntiForgeryTokenFromHtml,
    getAntiforgerySegment,
    isMobile,
    sanitizeRutFieldValue,
    showLoader,
    updateMdl,
} from "../../shared/common";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { RefundWidgetType } from "./useRefundWidget";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../directives/ref";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { CHILE_COUNTRY_CODE } from "../../shared/commonConstants";
import { validateRut } from "../../shared/form-validation";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { ApiRefundBankInfoViewModel } from "../../component-models/itinerary/ApiRefundBankInfoViewModel";
import { useModal } from "../shared/useModal";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    antiForgeryToken: string;
    isFlightDeparted: boolean;
    model: ApiRefundBankInfoViewModel;
    refundWidgetType: RefundWidgetType;
    isJustRegistered: boolean;
}

const RUT_ID = "4";

interface RefundBankVM {
    country: string;
    documentType: string;
    documentNumber: string;
    bank: string;
    accountType: string;
    accountNumber: string;
}

type FieldNames = keyof RefundBankVM;

export const useRefundBankInfoModal = (props: Props) => {
    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        if (isMobile()) {
            ScrollHelper.addPerfectScrollbar("refund-bank-info-scroller");
        }

        // DEVNOTE: doctype must be initialized before document number
        setVm({
            ...vm,
            documentType: props.model.DocType || (vm.country === "CL" ? "4" : ""),
        });
    };

    const isRefundInputReadonly = () =>
        props.isFlightDeparted || props.model.IsRefundInfoProvided || isRefundInfoUpdated;

    const isSelectedCountryPrioritised = () => PRIORITISED_COUNTRIES.includes(vm.country);

    const isDocTypeRut = () => isSelectedCountryPrioritised() && vm.documentType === RUT_ID;

    // EVENT HANDLERS

    const handleClose = () => {
        triggers.itinerary.closeRefundModal.publish(false);
    };

    const sanitizeDocNumberInput = (e: KeyboardEvent) =>
        isDocTypeRut()
            ? sanitizeRutFieldValue(e.target as HTMLInputElement)
            : sanitizeInputFieldValue(e, "document-number");

    const handleSubmit = async () => {
        setIsValidated(true);
        const isValid = await validator.validate();

        if (isValid) {
            const bodyToPost = {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
                countryCode: vm.country,
                documentType: vm.documentType,
                documentNumberField: vm.documentNumber,
                bankNameField: vm.bank,
                cardTypeField: vm.accountType,
                accountNumberField: vm.accountNumber,
            };

            setIsCloseButtonVisible(false);
            removeAjaxErrorMessage({
                container: root.current,
            });
            const loader = showLoader({ name: "refund-bank-info-modal", container: root.current });

            await ajaxRequest({
                loader,
                container: root.current,
                body: bodyToPost,
                url: "/V2XHR/SetRefundData",
                method: "POST",
                onResponseCode: {
                    200: () => {
                        modal.close();
                        setIsRefundInfoUpdated(true);
                    },
                },
            });
        }
    };

    const appContext = useAppContext();

    const DEFAULT_REFUND_BANK_VM = useMemo<RefundBankVM>(
        () => ({
            country: props.model.Country || appContext.Country,
            documentType: "",
            documentNumber: props.model.RutValue,
            bank: props.model.BankName,
            accountType: props.model.BankAccountType,
            accountNumber: props.model.BankAccountNumber,
        }),
        [],
    );

    const root = useRef<HTMLDivElement>(undefined);

    const { triggers } = usePubSub();

    const { ajaxRequest, removeAjaxErrorMessage } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [isCloseButtonVisible, setIsCloseButtonVisible] = useState<boolean>(true);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<RefundBankVM>(DEFAULT_REFUND_BANK_VM);
    const [isRefundInfoUpdated, setIsRefundInfoUpdated] = useState<boolean>(false);

    const validator = useFluentValidator<FieldNames, RefundBankVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("accountNumber", (vm: RefundBankVM) => vm.accountNumber).isRequired(),
            Validation.ruleFor("accountType", (vm: RefundBankVM) => vm.accountType).isRequired(),
            Validation.ruleFor("bank", (vm: RefundBankVM) => vm.bank).isRequired(),
            Validation.ruleFor("documentNumber", (vm: RefundBankVM) => vm.documentNumber).isRequired(),
            Validation.ruleFor("documentNumber", (vm: RefundBankVM) => vm.documentNumber)
                .when((vm) => vm.country === CHILE_COUNTRY_CODE && vm.documentType === "4")
                .isRequired()
                .fulfils(
                    async (docNumber: string) => docNumber && validateRut(docNumber),
                    i18next.t("BE-RutFormatError"),
                ),
            Validation.ruleFor("documentType", (vm: RefundBankVM) => vm.documentType).isRequired(),
            Validation.ruleFor("country", (vm: RefundBankVM) => vm.country).isRequired(),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const accountTypeOptions = useMemo(
        () =>
            REFUND_INFO_ACCOUNT_TYPES.filter((at) => at.Country === vm.country).map((at) => ({
                Text: at.AccountType,
                Value: at.AccountType,
                IsSelected: at.AccountType === vm.accountType,
            })),

        [appContext.Countries, vm.country, vm.accountType, props.model.IsRefundInfoProvided],
    );

    const bankNameOptions = useMemo(
        () =>
            REFUND_INFO_BANK_NAMES.filter((b) => b.Country === vm.country).map((b) => ({
                Text: b.BankName,
                Value: b.BankName,
                IsSelected: b.BankName === vm.bank,
            })),

        [appContext.Countries, vm.bank, vm.country, props.model.IsRefundInfoProvided],
    );

    const documentTypeOptions = useMemo(
        () =>
            REFUND_INFO_DOC_TYPES.filter((dt) => dt.Country === vm.country).map((dt) => ({
                Text: dt.IdDocType,
                Value: dt.Id.toString(),
                IsSelected: dt.Id.toString() === vm.documentType,
            })),

        [appContext.Countries, vm.country, vm.documentType, props.model.IsRefundInfoProvided],
    );

    const countryOptions = useMemo(
        () =>
            appContext.Countries.map((country) => ({
                Text: country.Text,
                Value: country.Value,
                IsSelected: country.Value === vm.country,
            })),
        [appContext.Countries, vm.country, props.model.IsRefundInfoProvided],
    );

    useEffect(init, []);

    // TEMPLATES

    const headerTemplate = () =>
        props.isJustRegistered
            ? html` <i class="js-icon-refund js-refund-rw-profile"></i>
                  <span class="refund-bank-info-header-text"> ${i18next.t("V2-RefundBankInfo-RegisterTitle")} </span>`
            : html` <span class="refund-bank-info-header-text"> ${i18next.t("V2-RefundBankInfo-LoginTitle")} </span> `;

    const bankInfoDescriptionTemplate = () => html`
        <div class="bank-info-description">${i18next.t("V2-RefundBankInfo-Description")}</div>
    `;

    const countryInputTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-select
                    .errorMessage=${validator.getMessage("country")}
                    .isInvalid=${!validator.isValid("country")}
                    .isDisabled=${isRefundInputReadonly()}
                    .label=${i18next.t("V2-CountryLabel")}
                    .placeholder=${i18next.t("V2-CountryLabel")}
                    .name=${"countryCode"}
                    .options=${countryOptions}
                    .testId=${T.REFUND_BANK_INFO_MODAL.COUNTRY_INPUT}
                    .onSelect=${(value: string) =>
                        setVm({
                            ...vm,
                            country: value,
                            documentType: "",
                            bank: "",
                            accountType: "",
                            documentNumber: "",
                        })}
                >
                </ac-select>
            </div>
        </div>
    `;

    const docTypeInputTemplate = () =>
        isSelectedCountryPrioritised()
            ? html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-select
                              .errorMessage=${validator.getMessage("documentType")}
                              .isInvalid=${!validator.isValid("documentType")}
                              .label=${i18next.t("V2-RefundBankInfo-DocumentType")}
                              .name=${"documentType"}
                              .options=${documentTypeOptions}
                              .isDisabled=${isRefundInputReadonly()}
                              .placeholder=${i18next.t("V2-RefundBankInfo-DocumentType")}
                              .testId=${T.REFUND_BANK_INFO_MODAL.DOCUMENT_TYPE_INPUT}
                              .onSelect=${(value: string) => setVm({ ...vm, documentType: value, documentNumber: "" })}
                          >
                          </ac-select>
                      </div>
                  </div>
              `
            : html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("documentType")}
                              .isDisabled=${!vm.country}
                              .isInvalid=${!validator.isValid("documentType")}
                              .isReadonly=${isRefundInputReadonly()}
                              .label=${i18next.t("V2-RefundBankInfo-DocumentType")}
                              .maxLength=${32}
                              .minLength=${10}
                              .name=${"documentType"}
                              .placeholder=${i18next.t("V2-RefundBankInfo-DocumentType")}
                              .testId=${T.REFUND_BANK_INFO_MODAL.DOCUMENT_TYPE_INPUT}
                              .value=${vm.documentType}
                              .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                              .onInput=${(value: string) => setVm({ ...vm, documentType: value })}
                          ></ac-input>
                      </div>
                  </div>
              `;

    const documentNumberInputTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("documentNumber")}
                    .isInvalid=${!validator.isValid("documentNumber")}
                    .isReadonly=${isRefundInputReadonly()}
                    .name=${"documentNumberField"}
                    .maxLength=${32}
                    .label=${i18next.t("V2-DocumentNumber")}
                    .testId=${T.REFUND_BANK_INFO_MODAL.DOCUMENT_NUMBER_INPUT}
                    .placeholder=${i18next.t("V2-DocumentNumber")}
                    .value=${vm.documentNumber}
                    .sanitizer=${(e: KeyboardEvent) => sanitizeDocNumberInput(e)}
                    .onInput=${(value: string) => setVm({ ...vm, documentNumber: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const bankNameInputTemplate = () =>
        isSelectedCountryPrioritised()
            ? html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-select
                              .errorMessage=${validator.getMessage("bank")}
                              .isInvalid=${!validator.isValid("bank")}
                              .isDisabled=${isRefundInputReadonly()}
                              .label=${i18next.t("V2-RefundBankInfo-Bank")}
                              .placeholder=${i18next.t("V2-RefundBankInfo-Bank")}
                              .name=${"bankNameField"}
                              .options=${bankNameOptions}
                              .testId=${T.REFUND_BANK_INFO_MODAL.BANK_NAME_INPUT}
                              .onSelect=${(value: string) => setVm({ ...vm, bank: value })}
                          >
                          </ac-select>
                      </div>
                  </div>
              `
            : html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("bank")}
                              .isInvalid=${!validator.isValid("bank")}
                              .isReadonly=${isRefundInputReadonly()}
                              .name=${"bankNameField"}
                              .maxLength=${32}
                              .label=${i18next.t("V2-RefundBankInfo-Bank")}
                              .placeholder=${i18next.t("V2-RefundBankInfo-Bank")}
                              .testId=${T.REFUND_BANK_INFO_MODAL.CARD_TYPE_INPUT}
                              .value=${vm.bank}
                              .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                              .onInput=${(value: string) => setVm({ ...vm, bank: value })}
                          ></ac-input>
                      </div>
                  </div>
              `;

    const accountTypeTemplate = () =>
        isSelectedCountryPrioritised()
            ? html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-select
                              .errorMessage=${validator.getMessage("accountType")}
                              .isInvalid=${!validator.isValid("accountType")}
                              .label=${i18next.t("V2-RefundBankInfoModal-AccountType")}
                              .placeholder=${i18next.t("V2-RefundBankInfoModal-AccountType")}
                              .name=${"cardTypeField"}
                              .isDisabled=${isRefundInputReadonly()}
                              .options=${accountTypeOptions}
                              .tabIndexAttr=${-1}
                              .testId=${T.REFUND_BANK_INFO_MODAL.CARD_TYPE_INPUT}
                              .onSelect=${(value: string) => setVm({ ...vm, accountType: value })}
                          >
                          </ac-select>
                      </div>
                  </div>
              `
            : html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("accountType")}
                              .isInvalid=${!validator.isValid("accountType")}
                              .isReadonly=${isRefundInputReadonly()}
                              .name=${"accountNumberField"}
                              .maxLength=${32}
                              .label=${i18next.t("V2-RefundBankInfoModal-AccountType")}
                              .placeholder=${i18next.t("V2-RefundBankInfoModal-AccountType")}
                              .testId=${T.REFUND_BANK_INFO_MODAL.CARD_TYPE_INPUT}
                              .value=${vm.accountType}
                              .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                              .onInput=${(value: string) => setVm({ ...vm, accountType: value })}
                          ></ac-input>
                      </div>
                  </div>
              `;

    const bankAccountNumberTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("accountNumber")}
                    .isInvalid=${!validator.isValid("accountNumber")}
                    .isReadonly=${isRefundInputReadonly()}
                    .name=${"accountNumberField"}
                    .maxLength=${32}
                    .label=${i18next.t("V2-RefundBankInfo-BankAccountNumber")}
                    .placeholder=${"xxxxxxxx-xxxxxxxx"}
                    .testId=${T.REFUND_BANK_INFO_MODAL.ACCOUNT_NUMBER_INPUT}
                    .value=${vm.accountNumber}
                    .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "bank-account-number")}
                    .onInput=${(value: string) => setVm({ ...vm, accountNumber: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const formTemplate = () => html`
        <div>
            ${getAntiforgerySegment(props.antiForgeryToken)}
            <div class="row">${countryInputTemplate()} ${docTypeInputTemplate()}</div>
            <div class="row">${documentNumberInputTemplate()} ${bankNameInputTemplate()}</div>
            <div class="row">${accountTypeTemplate()} ${bankAccountNumberTemplate()}</div>
        </div>
    `;

    const submitButtonTemplate = () => {
        const submitButtonClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": isRefundInputReadonly(),
        });

        return html`
            <div class="refund-bank-info-btn-container">
                <a
                    @click=${handleSubmit}
                    class=${submitButtonClassMap}
                    data-test-id=${T.REFUND_BANK_INFO_MODAL.SUBMIT_BUTTON}
                >
                    ${i18next.t("V2-RefundBankInfo-Send")}
                </a>
            </div>
        `;
    };

    const htmlTemplate = () => html`
        <div class="refund-bank-info-scroller">
            <div ref=${ref(root)} class="modal-body">
                ${bankInfoDescriptionTemplate()} ${formTemplate()} ${formErrors.htmlTemplate()}
                ${submitButtonTemplate()}
            </div>
        </div>
        ${updateMdl()}
    `;

    const modal = useModal({
        closing: {
            isClosable: isCloseButtonVisible,
            buttonTestId: T.REFUND_BANK_INFO_MODAL.CLOSE_BUTTON,
            onClose: handleClose,
        },
        content: { classNames: "booking-modal-content refund-bank-info-modal", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "secondary-modal", testId: T.REFUND_BANK_INFO_MODAL.CONTAINER },
        onOpen: () => {
            setIsCloseButtonVisible(true);
        },
    });

    return modal;
};

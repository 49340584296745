import { Dayjs } from "dayjs";
import { AlternateCode, InputFieldRequirements } from "../PaymentMethodDescriptors";
import { CardIssuerType } from "../PaymentDescriptors";
import { CardValidationStatus } from "../../components/payment/useCard";
import { CreditCardValidationResult } from "./CreditCardValidationResult";

export interface CardData {
    AlternateCode: AlternateCode;
    CardholderName: string;
    CardNumber: string;
    CardType: CardIssuerType;
    CardValidationStatus: CardValidationStatus;
    Cvv: string;
    Expiry: Dayjs;
    FieldRequirements: InputFieldRequirements;
    FopBrand: string;
    FormattedExpiry: string;
    IsCvvNeeded: boolean;
    IsNameNeeded: boolean;
    MerchantAccountId: string;
    PaymentMethodCodeToSubmit: string;
    PaymentMethodOptionId: string;
    Ruc: string;
    SelectedInstallmentsNumber: number;
    ValidationResult: CreditCardValidationResult;
}

export const DEFAULT_CARD_DATA: CardData = {
    AlternateCode: undefined,
    CardholderName: "",
    CardNumber: "",
    CardType: undefined,
    CardValidationStatus: "unknown",
    Cvv: "",
    Expiry: undefined,
    FieldRequirements: undefined,
    FopBrand: undefined,
    FormattedExpiry: "",
    IsCvvNeeded: false,
    IsNameNeeded: false,
    MerchantAccountId: undefined,
    PaymentMethodCodeToSubmit: "",
    PaymentMethodOptionId: undefined,
    Ruc: "",
    SelectedInstallmentsNumber: 1,
    ValidationResult: undefined,
};

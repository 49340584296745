import { InputFieldAttributeValidator } from "./InputFieldAttributeValidator";

export interface InputFieldAttribute {
    governingFieldAttributeName?: string;
    name: string;
    validators: InputFieldAttributeValidator[];
}

export const UDF_ATTR_EMAIL = "email";
export const UDF_ATTR_LENGTH = "length";
export const UDF_ATTR_MAX = "max";
export const UDF_ATTR_MIN = "min";
export const UDF_ATTR_NO_SPEC = "no-spec";
export const UDF_ATTR_REQUIRED = "data-required";

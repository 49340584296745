import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { SeatmapJourneySegment } from "../../../../component-models/seatmap/SeatmapJourneySegment";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";

export interface Props {
    seatmapState: SeatmapState;
    handleSegmentClick: (segmentIndex: number) => void;
}

export const useMobileSegmentSelector = (props: Props) => {
    const showSegmentSelector = () => props.seatmapState?.CurrentJourney.Segments.length > 1;

    const transferTemplate = () => html`
        <div class="transfer-information-container w-full">
            <i class="far fa-clock"></i>
            <span
                >${i18next.t("Tiempo estimado de escala en {{arrivalStation}} {{hours}}h {{minutes}}min.", {
                    // DEVNOTE Here the waiting time is listed as BEFORE the segment
                    hours: props.seatmapState.CurrentJourney.Segments[1].StopDurationHours,
                    minutes: props.seatmapState.CurrentJourney.Segments[1].StopDurationMinutes,
                    arrivalStation: props.seatmapState.CurrentJourney.Segments[0].ArrivalStationName,
                })}</span
            >
        </div>
    `;

    const segmentTabletTemplate = (segment: SeatmapJourneySegment) => html`
        <span class="cf-mobile-segment-endpoint hidden-xs">
            <span class="font-bold">${segment.DepartureStationName}</span>&nbsp; (${segment.DepartureStationCode})
            <i class="seatmap-pax-plane js-icon js-flight-plane"></i>
            <span class="font-bold">${segment.ArrivalStationName}</span>&nbsp; (${segment.ArrivalStationCode})
        </span>
    `;

    const segmentMobileTemplate = (segment: SeatmapJourneySegment) => html`
        <span class="cf-mobile-segment-endpoint hidden-sm-up">
            <span class="font-bold">${segment.DepartureStationName}</span> (${segment.DepartureStationCode}) -
        </span>
        <span class="cf-mobile-segment-endpoint hidden-sm-up">
            <span class="font-bold">${segment.ArrivalStationName}</span> (${segment.ArrivalStationCode})
        </span>
    `;
    const segmentTemplate = (segment: SeatmapJourneySegment) => {
        const isActive = props.seatmapState.CurrentSegment.SegmentIndex === segment.SegmentIndex;

        const tempClassMap = classMap({
            "cf-mobile-header-segment": true,
            "active": isActive,
        });

        const onClick = isActive
            ? null
            : segment.SegmentIndex === 0
              ? () => props.handleSegmentClick(0)
              : () => props.handleSegmentClick(1);

        return html`
            <div class=${tempClassMap} @click=${onClick}>
                <span class="cf-mobile-segment-flight-number ">
                    ${i18next.t("Vuelo")} ${segment.CarrierCode} ${segment.FlightNumber}
                </span>
                ${segmentTabletTemplate(segment)} ${segmentMobileTemplate(segment)}
            </div>
        `;
    };

    const htmlTemplate = () =>
        showSegmentSelector()
            ? html`
                  <div class="cf-mobile-header-segments">
                      ${segmentTemplate(props.seatmapState.CurrentJourney.Segments[0])}
                      ${segmentTemplate(props.seatmapState.CurrentJourney.Segments[1])}
                  </div>
                  ${transferTemplate()}
              `
            : "";

    return { htmlTemplate };
};

import { BaggageContext } from "../../../component-models/baggage/BaggageContext";
import { useEffect } from "../../../shared/haunted/CustomHooks";
import { OUTBOUND } from "../../../shared/commonConstants";
import i18next from "i18next";
import { html } from "haunted";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { useBaggageIllustrations } from "./common/useBaggageIllustrations";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerBookingBundle } from "./perBookingView/usePerBookingBundle";
import { usePerBookingViewSwitch } from "./perBookingView/usePerBookingViewSwitch";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { usePerBookingPaidOption } from "./perBookingView/usePerBookingPaidOption";
import { usePerBookingFreeOption } from "./perBookingView/usePerBookingFreeOption";
import { useMobilePerBookingOptions } from "./perBookingView/useMobilePerBookingOptions";
import { useBaggageSection } from "./common/useBaggageSection";
import { StaffBaggage } from "./common/useStaffBaggage";
import { BagType } from "./useBaggagePage";
import { usePerBookingValidationError } from "./perBookingView/usePerBookingValidationError";

export interface Props {
    context: BaggageContext;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useCabinBags = (props: Props) => {
    const cabinBag = useBaggageSection({
        bagType: "CabinBaggage",
        context: props.context,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const validationError = usePerBookingValidationError({ baggageSection: cabinBag, pageState: props.pageState });

    const { perBookingDcTemplate, tooManyPaxForDcTemplate } = useBaggageIllustrations({
        baggageSection: cabinBag,
        context: props.context,
    });

    const perBookingBundle = usePerBookingBundle({
        baggageState: cabinBag.state,
        bagType: "CabinBaggage",
        context: props.context,
    });

    const viewSwitch = usePerBookingViewSwitch({
        baggageSection: cabinBag,
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: cabinBag,
        context: props.context,
        pageState: props.pageState,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: cabinBag,
        context: props.context,
        pageState: props.pageState,
    });

    const perBookingFreeOption = usePerBookingFreeOption({
        baggageSection: cabinBag,
        context: props.context,
        pageState: props.pageState,
    });

    const perBookingPaidOption = usePerBookingPaidOption({
        baggageSection: cabinBag,
        context: props.context,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerBookingOptions = useMobilePerBookingOptions({
        baggageSection: cabinBag,
        context: props.context,
        staffBaggage: props.staffBaggage,
    });

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;

        const journeyAndPaxWithError = cabinBag.handlers.findJourneyAndPaxWithError();

        if (!journeyAndPaxWithError) return;

        cabinBag.handlers.openPax({
            JourneyIndices: [journeyAndPaxWithError.journeyIndex],
            PaxIndices: [journeyAndPaxWithError.paxIndex],
        });
    };

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    useEffect(() => {
        if (!cabinBag.state.hasPerJourneyPerPaxStateChanged) return;

        const element = DomCrawlingHelper.getElemByClass(
            document.body,
            cabinBag.state.perJourneyPerPaxState === "open"
                ? props.context.classesToScrollToOnOpen.get("CabinBaggage")
                : props.context.classesToScrollToOnClose.get("CabinBaggage"),
        );
        ScrollHelper.scrollToElementAndHideNav({
            element,
            yOffset: 0,
            timeOffset: 500,
        });
    }, [cabinBag.state.perJourneyPerPaxState]);

    const headerTemplate = () => html`
        <header
            class="b2-section-header ${props.context.classesToScrollToOnClose.get("CabinBaggage")}"
            data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_HEADER, { c: "CabinBaggage" })}
        >
            <div class="flex items-center">
                <span
                    class="b2-section-number"
                    data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_NUMBER, { c: "CabinBaggage" })}
                    >1</span
                >
                <div>
                    <h3>${i18next.t("Selecciona tu equipaje de cabina")}</h3>
                    <h4>${i18next.t("Es el equipaje que llevas a bordo del avión.")}</h4>
                </div>
            </div>
            <div class="flex flex-col items-start">
                ${props.context.isDcBooking ? perBookingDcTemplate() : ""} ${perBookingBundle.htmlTemplate()}
            </div>
        </header>
    `;

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "CabinBaggage" })}>
            ${headerTemplate()}
            <div
                class="b2-bag-grid b2-cabin-grid hidden-xs"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_DT, { c: "CabinBaggage" })}
            >
                ${viewSwitch.htmlTemplate()}
                <div class="ac-per-booking-free-option">${perBookingFreeOption.htmlTemplate()}</div>
                <div class="ac-per-booking-paid-option">${perBookingPaidOption.htmlTemplate()}</div>
                <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                ${validationError.htmlTemplate(true)}
            </div>
            <div
                class="b2m-checked-bag-container hidden-sm-up"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_MB, { c: "CabinBaggage" })}
            >
                ${mobilePerBookingOptions.htmlTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                ${validationError.htmlTemplate(false)}
            </div>
            ${tooManyPaxForDcTemplate(cabinBag.state.journeys[OUTBOUND]?.passengers.length || 0)}
        </div>
    `;

    return {
        availability: cabinBag.perJourneyPerPax,
        bagType: "CabinBaggage" as BagType,
        handlers: cabinBag.handlers,
        state: cabinBag.state,
        htmlTemplate,
    };
};

import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useRef } from "haunted";
import { useEffect, useState } from "./../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import {
    getAntiForgeryTokenFromHtml,
    getAntiforgerySegment,
    handleCugLoader,
    maskCurrenciesForDisplay,
    toBoolean,
} from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { ApiInitAutoTopUpCreditModel } from "../../../component-models/payment/ApiInitAutoTopUpCredit";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { SummaryWidgetsCugType } from "../../../component-models/CUG2b/SummaryWidgets";
import { GENERAL_CURRENCY_SIGN, PERUVIAN_SOL_CODE, PERUVIAN_SOL_CURRENCY_SIGN } from "../../../shared/commonConstants";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = [
    "active-user-count",
    "anti-forgery-token",
    "can-edit-company",
    "can-top-up",
    "can-view-users-page",
    "confirmed-reservations-count",
    "currency-code",
    "formatted-balance",
    "max-payment-amount",
    "max-payment-amount-formatted",
    "name",
    "org-name",
    "pending-reservations-count",
    "warnings",
];

export const name = "ac-summary-page";

export interface Attributes {
    "active-user-count": string;
    "anti-forgery-token": string;
    "can-edit-company": string;
    "can-top-up": string;
    "can-view-users-page": string;
    "confirmed-reservations-count": string;
    "currency-code": string;
    "formatted-balance": string;
    "max-payment-amount": string;
    "max-payment-amount-formatted": string;
    "name": string;
    "org-name": string;
    "pending-reservations-count": string;
    "warnings": string;
}
export interface Properties {
    activeUserCount: number;
    antiForgeryToken: string;
    canEditCompany: boolean;
    canTopUp: boolean;
    canViewUsersPage: boolean;
    confirmedReservationsCount: number;
    currencyCode: string;
    formattedBalance: string;
    maxPaymentAmount: number;
    maxPaymentAmountFormatted: string;
    name: string;
    orgName: string;
    pendingReservationsCount: number;
    warnings: number;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        activeUserCount: Number(host.activeUserCount),
        antiForgeryToken: host.antiForgeryToken,
        canEditCompany: toBoolean(host.canEditCompany),
        canTopUp: toBoolean(host.canTopUp),
        canViewUsersPage: toBoolean(host.canViewUsersPage),
        confirmedReservationsCount: Number(host.confirmedReservationsCount),
        currencyCode: host.currencyCode,
        formattedBalance: host.formattedBalance,
        maxPaymentAmount: Number(host.maxPaymentAmount.toString().replace(",", ".")),
        maxPaymentAmountFormatted: host.maxPaymentAmountFormatted,
        name: host.name,
        orgName: host.orgName,
        pendingReservationsCount: Number(host.pendingReservationsCount),
        warnings: Number(host.warnings),
    };

    // HELPERS

    const init = () => {
        const doIt = async () => {
            if (!(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)) {
                await loadData();
            }
        };

        doIt();
    };

    const loadData = async () => {
        const response = await ajaxJsonRequest<ApiInitAutoTopUpCreditModel>({
            url: ROUTES.ApiRoutes.InitialTopUpCredit,
            method: "GET",
        });

        if (response.statusCode === 200) {
            setAutoTopUpCredit(response?.data);
        }
    };

    // DEVNOTE The quirks here are caused by the requirements. If an invalid amount is submitted, we display an error.
    // However, if we submit an invalid amount AGAIN, a DIFFERENT error message must be displayed.
    const validate = (alreadyValidated?: boolean) => {
        const value = Number(amountInput.current.value);
        const isValid = /^[0-9]+$/.test(amountInput.current.value) && value > 0;

        if (!isValid) {
            setShowAmountError(true);
            return false;
        }

        setShowAmountError(false);

        if (!props.maxPaymentAmount || value <= props.maxPaymentAmount) {
            setShowMaxAmountError1(false);
            setShowMaxAmountError2(false);
            return true;
        }

        if (alreadyValidated || showMaxAmountError2) {
            return false;
        }

        if (showMaxAmountError1) {
            setShowMaxAmountError1(false);
            setShowMaxAmountError2(true);
        } else {
            setShowMaxAmountError1(true);
            setShowMaxAmountError2(false);
        }

        return false;
    };

    const getAmountForOverpaymentError = () =>
        `${props.maxPaymentAmountFormatted} ${maskCurrenciesForDisplay(props.currencyCode)}`;

    // EVENT LISTENERS

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            submitForm();
        }
    };

    const handleInput = () => {
        if (showAmountError || showMaxAmountError1 || showMaxAmountError2) {
            validate(true);
        }
    };

    const submitForm = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (validate()) {
            handleCugLoader(form.current, "loadData");
            form.current.submit();
        }
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();
    const [userContext] = useReduxState("userContext");

    const { ajaxJsonRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const form = useRef<HTMLFormElement>(undefined);
    const amountInput = useRef<HTMLInputElement>(undefined);

    const [isToppingUp, setIsToppingUp] = useState<boolean>(false);
    const [showAmountError, setShowAmountError] = useState<boolean>(false);
    const [showMaxAmountError1, setShowMaxAmountError1] = useState<boolean>(false);
    const [showMaxAmountError2, setShowMaxAmountError2] = useState<boolean>(false);
    const [autoTopUpCredit, setAutoTopUpCredit] = useState<ApiInitAutoTopUpCreditModel>(undefined);

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    // TEMPLATES

    const topupModalTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${topupModalContentTemplate()}
            .customClass=${"cug2b-topup-modal"}
            .header=${topupModalHeaderTemplate()}
            .isOpen=${isToppingUp}
            @close=${() => {
                setIsToppingUp(false);
                setShowAmountError(false);
                setShowMaxAmountError1(false);
                setShowMaxAmountError2(false);
            }}
        ></ac-cug-modal>
    `;

    const topupModalHeaderTemplate = () => i18next.t("Abono");

    const amountErrorTemplate = () =>
        showAmountError ? html` <span class="amount-error">${i18next.t("CUG2-AmountError")}</span> ` : "";

    const overpaymentErrorMessage1Template = () => {
        const overpaymentErrorMessageAmount = getAmountForOverpaymentError();
        return showMaxAmountError1
            ? html`
                  <span class="amount-error" data-test-id=${T.CUG2_SUMMARY.AMOUNT_ERROR_ON_MODAL_1}>
                      ${unsafeHTML(
                          i18next.t("CUG2-MaxAmountError1 {{-amount}}", {
                              amount: `<span>${overpaymentErrorMessageAmount}</span>`,
                          }),
                      )}
                  </span>
              `
            : "";
    };

    const overpaymentErrorMessage2Template = () => {
        const overpaymentErrorMessageAmount = getAmountForOverpaymentError();
        return showMaxAmountError2
            ? html`
                  <span class="amount-error" data-test-id=${T.CUG2_SUMMARY.AMOUNT_ERROR_ON_MODAL_2}>
                      ${unsafeHTML(
                          i18next.t("CUG2-MaxAmountError2 {{-amount}}", {
                              amount: `<span>${overpaymentErrorMessageAmount}</span>`,
                          }),
                      )}
                  </span>
              `
            : "";
    };

    const overpaymentErrorTemplate = () =>
        props.maxPaymentAmount > 0
            ? html` ${overpaymentErrorMessage1Template()} ${overpaymentErrorMessage2Template()} `
            : "";

    const topupModalContentTemplate = () => {
        const currencySignForPlaceholder =
            cug2AppContext.Currency === PERUVIAN_SOL_CODE ? PERUVIAN_SOL_CURRENCY_SIGN : GENERAL_CURRENCY_SIGN;
        return html`
            <form action=${ROUTES.Cug2BTopup} method="POST" ref=${ref(form)} class="cug2b-topup-form">
                ${getAntiforgerySegment(props.antiForgeryToken)}
                <div class="cug2b-topup-balance">
                    ${i18next.t("Saldo disponible")}:
                    <span>${maskCurrenciesForDisplay(props.currencyCode)} ${props.formattedBalance}</span>
                </div>
                <div class="cug2b-topup-input">
                    <label>${i18next.t("Monto a abonar")}</label>
                    <input
                        ref=${ref(amountInput)}
                        name="Amount"
                        type="text"
                        placeholder=${i18next.t("Ingresa un monto superior a {{-currency}}1", {
                            currency: currencySignForPlaceholder,
                        })}
                        data-test-id=${T.CUG2_SUMMARY.AMOUNT_TO_PAY_INPUT_FIELD}
                        @keypress=${handleKeyPress}
                        @input=${handleInput}
                    />
                </div>
                ${amountErrorTemplate()} ${overpaymentErrorTemplate()}
                <button
                    class="rounded-primary-btn mx-auto mt-6"
                    data-test-id=${T.CUG2_SUMMARY.MODAL_PAY_BUTTON}
                    @click=${submitForm}
                >
                    ${i18next.t("Abonar")}
                </button>
                <div class="cug2b-redirection-warning">${i18next.t("Serás redirigido a la página de pagos")}</div>
            </form>
        `;
    };

    const topUpButtonTemplate = () => {
        const buttonClassMap = classMap({
            "rounded-primary-btn": true,
            "cug-widget-white": true,
            "narrow": true,
            "initial-amount-ribbon":
                autoTopUpCredit?.Amount && autoTopUpCredit.Amount > 0 && autoTopUpCredit.RemainingAmount > 0,
        });

        return props.canTopUp && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <button
                      class=${buttonClassMap}
                      data-test-id=${T.CUG2_SUMMARY.PAY_BUTTON}
                      @click=${() => setIsToppingUp(true)}
                  >
                      ${i18next.t("Abonar")}
                  </button>
              `
            : "";
    };

    const agencyInitialAmountRibbonTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember) &&
        autoTopUpCredit?.Amount &&
        autoTopUpCredit.Amount > 0 &&
        autoTopUpCredit.RemainingAmount > 0
            ? html`
                  <div class="cug2b-initial-amount-ribbon">
                      <span class="js-icon-covid js-cv-dollar-sign"></span
                      ><span class="font-extrabold">${autoTopUpCredit?.Amount}</span>
                      <span>${i18next.t("abonados")}</span>
                  </div>
              `
            : "";

    const balanceTemplate = () => {
        const balanceContainerClassMap = classMap({
            "cug2b-summary-balance-container": true,
            "initial-amount-ribbon":
                autoTopUpCredit?.Amount && autoTopUpCredit.Amount > 0 && autoTopUpCredit.RemainingAmount > 0,
        });

        return html`
            <div class=${balanceContainerClassMap}>
                <div class="cug2b-summary-balance">
                    <div class="cug2b-summary-balance-label">${i18next.t("Saldo disponible")}</div>
                    <div class="cug2b-summary-balance-amount">
                        ${maskCurrenciesForDisplay(props.currencyCode)} ${props.formattedBalance}
                    </div>
                    ${topUpButtonTemplate()}
                </div>
                ${agencyInitialAmountRibbonTemplate()}
            </div>
        `;
    };
    const mobileCompanyEditorTemplate = () =>
        props.canEditCompany && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
                  <span
                      class="hidden-sm-up"
                      data-test-id=${getTestId(T.CUG2_SUMMARY.EDIT_BUTTON, { m: true })}
                      @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                  >
                      <i class="js-icon-cug js-cug-edit"></i> ${i18next.t("Editar")}
                  </span>
              `
            : "";

    const desktopCompanyEditorTemplate = () =>
        props.canEditCompany && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
                  <div class="cug2b-summary-edit hidden-xs">
                      <span
                          @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                          data-test-id=${T.CUG2_SUMMARY.EDIT_BUTTON}
                      >
                          <i class="js-icon-cug js-cug-edit"></i> ${i18next.t("Editar")}
                      </span>
                  </div>
              `
            : "";

    const companyInfoBarTemplate = () => html`
        <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
        <div class="cug2b-summary-company-info-bar">
            <div class="cug2b-summary-greeting-container">
                <i class="js-icon-cug js-cug-man-and-plane"></i>
                <div class="cug2b-summary-greeting">
                    <h1 class="hidden-xs">${i18next.t("¡Hola!")}</h1>
                    <h2>${props.name}</h2>
                    <h3>${props.orgName}</h3>
                    ${mobileCompanyEditorTemplate()}
                </div>
            </div>

            ${balanceTemplate()} ${desktopCompanyEditorTemplate()}
        </div>
    `;

    const dataBarTemplate = () => html`
        <div class="cug2b-summary-data-bar">
            ${usersTemplate()} ${confirmedBookingsTemplate()} ${pendingBookingsTemplate()}
        </div>
    `;

    const usersTemplate = () => {
        const onClick = () => (props.canViewUsersPage ? (window.location.href = ROUTES.Cug2BUsersPage) : null);

        const tempClassMap = classMap({
            "cug2b-summary-data": true,
            "peru-compra": userContext.peruCompra.isAdmin || userContext.peruCompra.isMember,
        });

        return html`
            <div class=${tempClassMap} @click=${onClick}>
                <h2>${i18next.t("Usuarios Activos")}</h2>
                <ac-tooltip
                    .icon=${"?"}
                    .tooltip=${i18next.t("CUG-TT-UsersTip")}
                    onclick="event.stopPropagation();"
                ></ac-tooltip>

                <span class="cug2b-summary-data-number">${props.activeUserCount}</span>
            </div>
        `;
    };

    const confirmedBookingsTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-summary-data": true,
            "peru-compra": userContext.peruCompra.isAdmin || userContext.peruCompra.isMember,
        });

        return html`
            <div class=${tempClassMap} @click=${() => (window.location.href = ROUTES.Cug2BReservationsPage)}>
                <h2>${i18next.t("Reservas Confirmadas")}</h2>
                <ac-tooltip
                    .icon=${"?"}
                    .tooltip=${i18next.t("CUG-TT-ReservationsTip")}
                    onclick="event.stopPropagation();"
                ></ac-tooltip>
                <span class="cug2b-summary-data-number">${props.confirmedReservationsCount}</span>
            </div>
        `;
    };

    const reservationsWarningTemplate = () =>
        props.warnings > 0
            ? html`
                  <div class="cug2b-summary-warning">
                      <i class="js-icon js-danger"></i> ${i18next.t("CUG-ReservationWarning {{amount}}", {
                          amount: props.warnings,
                      })}
                  </div>
              `
            : "";

    const pendingBookingsTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <div class="cug2b-summary-data" @click=${() => (window.location.href = ROUTES.Cug2BReservationsPage)}>
                      <h2>${i18next.t("Reservas Pendientes")}</h2>
                      <ac-tooltip
                          .icon=${"?"}
                          .tooltip=${i18next.t("CUG-TT-PendingReservationsTip")}
                          onclick="event.stopPropagation();"
                      ></ac-tooltip>
                      <span class="cug2b-summary-data-number">${props.pendingReservationsCount}</span>
                      ${reservationsWarningTemplate()}
                  </div>
              `
            : "";

    const cug2WidgetsTemplate = (cugType: SummaryWidgetsCugType) => {
        const containerClassMap = classMap({
            "cug-summary-widget-container": true,
            "first-inactive": !cugType.Widget_1.Enabled && cugType.Widget_2.Enabled,
            "second-inactive": cugType.Widget_1.Enabled && !cugType.Widget_2.Enabled,
        });

        return html`
            <div>
                <div class=${containerClassMap}>
                    <ac-summary-page-widget
                        .model=${cugType.Widget_1}
                        .customClass=${"cug-summary-widget-1"}
                    ></ac-summary-page-widget>
                    <ac-summary-page-widget
                        .model=${cugType.Widget_2}
                        .customClass=${"cug-summary-widget-2"}
                    ></ac-summary-page-widget>
                    <ac-summary-page-widget
                        .model=${cugType.Widget_3}
                        .customClass=${"cug-summary-widget-3"}
                    ></ac-summary-page-widget>
                    <ac-summary-page-widget
                        .model=${cugType.Widget_4}
                        .customClass=${"cug-summary-widget-4"}
                    ></ac-summary-page-widget>
                </div>
            </div>
        `;
    };

    const agencySummaryTemplate = () =>
        cug2AppContext.Type === "agency" && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  ${companyInfoBarTemplate()} ${dataBarTemplate()}
                  ${cug2WidgetsTemplate(cug2AppContext.Cug2BSettings.AgencySummaryWidgets)} ${topupModalTemplate()}
              `
            : "";

    const companySummaryTemplate = () =>
        cug2AppContext.Type === "company" && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  ${companyInfoBarTemplate()} ${dataBarTemplate()}
                  ${cug2WidgetsTemplate(cug2AppContext.Cug2BSettings.CompanySummaryWidgets)} ${topupModalTemplate()}
              `
            : "";

    const peruCompraSummaryTemplate = () =>
        userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
            ? html`
                  ${companyInfoBarTemplate()} ${dataBarTemplate()}
                  ${cug2WidgetsTemplate(cug2AppContext.Cug2BSettings.PeruCompraSummaryWidgets)} ${topupModalTemplate()}
              `
            : "";

    return html` ${agencySummaryTemplate()} ${companySummaryTemplate()} ${peruCompraSummaryTemplate()} `;
};

import { useEffect } from "./../../../shared/haunted/CustomHooks";
import { UpdatePartnerEvent } from "../../../component-models/CUG2b/Cug2PortalEvents";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useRef, useState } from "haunted";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { Partner } from "../../../component-models/CUG2b/Partner";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import {
    GridState,
    PageChangeEvent,
    SortChangeEvent,
    RowsSelectedEvent,
    ViewModel,
    Column,
} from "../../../dc-components/dc-table-models";
import { getAntiForgeryTokenFromHtml, handleCugLoader } from "../../../shared/common";
import { ROUTES } from "../../../shared/apiRoutes";
import { URI_DIVIDER_CHARACTER, URL_VARS } from "../../../shared/commonConstants";
import { ref } from "../../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export const name = "ac-travel-partners-page";

export interface Attributes {
    "anti-forgery-token": string;
}
export interface Properties {
    antiForgeryToken: string;
}

const actionCellClass = ["w-16", "sm:w-16", "md:w-32", "p-0", "pinned-on-mobile"];
const actionCellContentClass = ["w-16", "sm:w-16", "md:w-32", "action-cell-user"];

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const result = await getTravelPartnerInfo();

        setTravelPartnerInfo(result);
    };

    const initialGridState = (): GridState<keyof Partner> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        };
    };

    const deletePartners = async () => {
        const newTravelPartnerInfo = TravelPartnerHelper.deletePartners(travelPartnerInfo, partnersToDelete);

        setPartnersToDelete(undefined);
        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const isAnythingSelected = () => Array.from(selectedIds?.values()).length > 0;

    const orderedModel = () =>
        travelPartnerInfo ? TravelPartnerHelper.getOrderedFilteredModel(travelPartnerInfo.Partners, gridState) : [];

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState());
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [partnerToEdit, setPartnerToEdit] = useState<Partner>(undefined);
    const [partnersToDelete, setPartnersToDelete] = useState<number[]>(undefined);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
    const [filterExpression, setFilterExpression] = useState<string>("");

    useEffect(init, []);

    // EVENT LISTENERS

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            setGridState({
                ...gridState,
                pageIndex: 0,
                globalFilterExpression: filterExpression,
            });
        }
    };

    const handleFilterChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });

        ScrollHelper.scrollToTop();
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(GridHelper.getSelectedIds(event, selectedIds, orderedModel()) as Set<number>);
    };

    const handleSingleDelete = async (row: Partner) => {
        setPartnersToDelete([row.Id]);
    };

    const handleMultiDelete = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPartnersToDelete(Array.from(selectedIds.values()));
    };

    const handleEditSubmit = async (e: UpdatePartnerEvent) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, e.detail.partner);
        setPartnerToEdit(undefined);

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");

        if (result.Partners?.length > 0) {
            setIsSuccessModalOpen(true);
        }
    };

    const handleGrouping = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        handleCugLoader(root.current, "loadData");

        const uriParams = `${URL_VARS.FFLYER_IDS}=${Array.from(selectedIds.values()).join(URI_DIVIDER_CHARACTER)}`;
        const newUrl = `${ROUTES.Cug2BAddGroupPage}?${uriParams}`;

        window.location.href = newUrl;
    };

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-star"></i>
            <div>
                <h1>${i18next.t("Pasajeros frecuentes")}</h1>
                <h2>
                    ${unsafeHTML(
                        i18next.t("frequent-flyers-subtitle {{-spanStart}} {{-spanEnd}}", {
                            spanStart: "<span>",
                            spanEnd: "</span>",
                        }),
                    )}
                    <div class="mt-4 text-xs md:text-base">
                        * ${i18next.t("Recuerda que tus pasajeros frecuentes deben ser adultos (mayores de 18 años).")}
                    </div>
                </h2>
            </div>
        </div>
    `;

    const searchAndButtonsTemplate = () => {
        const groupingClassMap = classMap({
            "cug2b-pill": true,
            "disabled": !isAnythingSelected(),
        });

        const deleteClassMap = classMap({
            "cug2b-pill": true,
            "warning": true,
            "disabled": !isAnythingSelected(),
        });

        return html`
            <div class="cug2b-search-and-buttons">
                <div class="cug2b-searchbox">
                    <input
                        value=${gridState.globalFilterExpression}
                        autocomplete="cc-exp"
                        placeholder=${i18next.t("Buscar")}
                        @keydown=${handleKeyDown}
                        @input=${handleFilterChange}
                    />
                    <i class="js-icon-cug js-cug-search"></i>
                </div>
                <div class="flex w-full flex-wrap items-center justify-center sm:w-auto">
                    <div
                        class="cug2b-pill"
                        data-test-id=${T.CUG2_TRAVEL_PARTNERS.ADD_PASSENGER_BUTTON}
                        @click=${() => (window.location.href = `${ROUTES.Cug2BAddPartnerPage}`)}
                    >
                        <i class="js-icon-cug js-cug-man-and-plus"></i>${i18next.t("Agregar Pasajero")}
                    </div>
                    <div
                        class="cug2b-pill hidden-sm-up"
                        @click=${() => (window.location.href = `${ROUTES.Cug2BTravelGroupsPage}`)}
                    >
                        <i
                            class="js-icon-cug js-cug-big-group"
                            data-test-id=${T.CUG2_TRAVEL_PARTNERS.BIG_GROUP_BUTTON}
                        ></i
                        >${i18next.t("Grupos")}
                    </div>
                    <div class=${groupingClassMap} @click=${handleGrouping}>
                        <i
                            class="js-icon-cug js-cug-grouping"
                            data-test-id=${T.CUG2_TRAVEL_PARTNERS.GROUP_PASSENGERS_BUTTON}
                        ></i
                        >${i18next.t("Agrupar pasajeros")}
                    </div>
                    <div class=${deleteClassMap} @click=${handleMultiDelete}>
                        <i class="js-icon-cug js-cug-bin" data-test-id=${T.CUG2_TRAVEL_PARTNERS.REMOVE_BUTTON}></i
                        >${i18next.t("Eliminar")}
                    </div>
                </div>
            </div>
        `;
    };

    const areYouSureModalHeaderTemplate = () => html`
        <span>
            ${partnersToDelete?.length > 1
                ? i18next.t("¿Estás seguro que quieres eliminar a estos pasajeros?")
                : i18next.t("¿Estás seguro que quieres eliminar a este pasajero?")}
        </span>
    `;

    const areYouSureModalContentTemplate = () => html`
        <ac-delete-partners-modal
            .partnersToDelete=${partnersToDelete}
            .travelPartnerInfo=${travelPartnerInfo}
            @selectYes=${deletePartners}
            @selectNo=${() => setPartnersToDelete(undefined)}
        ></ac-delete-partners-modal>
    `;

    const areYouSureModalTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${areYouSureModalContentTemplate()}
            .customClass=${"cug2b-are-you-sure-group-modal"}
            .header=${areYouSureModalHeaderTemplate()}
            .isOpen=${partnersToDelete}
            @close=${() => setPartnersToDelete(undefined)}
        ></ac-cug-modal>
    `;

    const editPartnerTemplate = (id: number) => {
        return partnerToEdit?.Id === id
            ? html`
                  <ac-edit-travel-partner
                      .partner=${partnerToEdit}
                      .travelPartnerInfo=${travelPartnerInfo}
                      @close=${() => setPartnerToEdit(undefined)}
                      @update=${handleEditSubmit}
                  ></ac-edit-travel-partner>
              `
            : "";
    };

    const saveSuccessModalContentTemplate = () => html`
        <div class="p-8">
            <div class="cug2b-modal-question mb-4 sm:mb-8">
                ${i18next.t("La información ha sido guardada exitosamente.")}
            </div>
            <div class="cug2-button-container-in-success-modal">
                <button class="rounded-primary-btn" @click=${() => setIsSuccessModalOpen(false)}>
                    ${i18next.t("OK")}
                </button>
            </div>
        </div>
    `;

    const successModalHeaderTemplate = () => html`
        <i class="js-icon js-broken-circle-exclamation big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("Importante")}</span>
    `;

    const saveSuccessModalTemplate = () => {
        return html`
            <ac-cug-modal
                .canBeClosed=${true}
                .content=${saveSuccessModalContentTemplate()}
                .customClass=${"cug2b-save-success-modal"}
                .header=${successModalHeaderTemplate()}
                .isOpen=${isSuccessModalOpen}
                @close=${() => setIsSuccessModalOpen(false)}
            ></ac-cug-modal>
        `;
    };

    const rowEditTemplate = (index: number) => {
        const row = orderedModel()[index];

        return html`
            <td class=${GridHelper.getClassMap(actionCellClass)}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i class="js-icon-cug js-cug-edit relative" @click=${() => setPartnerToEdit(row)}> </i>
                    ${editPartnerTemplate(row.Id)}
                    <i class="js-icon-cug js-cug-bin ml-2" @click=${() => handleSingleDelete(row)}></i>
                </div>
            </td>
        `;
    };

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => `${orderedModel()[index].FirstName} ${orderedModel()[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "DOB",
                columnType: "string",
                label: i18next.t("Fecha de Nacimiento"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => orderedModel()[index].DOB.format("DD-MM-YYYY"),
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
            } as Column<keyof Partner>,
            {
                field: "GroupId",
                columnType: "string",
                label: i18next.t("Grupo"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) =>
                    orderedModel()[index].GroupId !== undefined
                        ? travelPartnerInfo.Groups.find((g) => g.Id === orderedModel()[index].GroupId)?.Name
                        : "",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel().some((item) => item.Id === id))
                .map((id) =>
                    orderedModel()
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: true,
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
    };

    return travelPartnerInfo
        ? html`
              <div ref=${ref(root)}>
                  ${staticStuffTemplate()} ${searchAndButtonsTemplate()}
                  <dc-table
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onRowsSelect=${onRowsSelect}
                  ></dc-table>
                  ${areYouSureModalTemplate()}
              </div>
              ${saveSuccessModalTemplate()}
          `
        : html``;
};

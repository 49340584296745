import { useEffect, useRef, useState } from "haunted";

interface Props {
    minutes: number;
    seconds: number;
    interval: number;
}

export function useCountdown(props: Props) {
    const [count, setCount] = useState<number>(props.minutes * 60 + props.seconds);
    const timerId = useRef<number>(undefined);

    useEffect(() => {
        if (count > 0) {
            timerId.current = window.setTimeout(() => {
                setCount(count - 1);
            }, props.interval);
        }

        return () => {
            window.clearTimeout(timerId.current);
        };
    }, [count, props.interval]);

    const resetCountdown = () => {
        setCount(props.minutes * 60 + props.seconds);
    };

    return {
        isCountdownFinished: count === 0,
        remainingMinutes: Math.floor(count / 60),
        remainingSeconds: count % 60,
        resetCountdown,
    };
}

import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import { ENGLISH_LANGUAGE_CODE, PORTUGESE_LANGUAGE_CODE, SPANISH_LANGUAGE_CODE } from "../../../shared/commonConstants";
import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { ref } from "../../../directives/ref";
import { showLoader, hideLoader } from "../../../shared/common";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../managers/useAppContext";
import { setTestCircuitDomValue, YesNo } from "../../../testing-helpers/TestCircuitHelper";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useExtendMembershipBanner } from "./useExtendMembershipBanner";
import { useUpgradeMembershipBanner } from "./useUpgradeMembershipBanner";
import { useGetMembershipBanner } from "./useGetMembershipBanner";
import { useReduxState } from "../../../shared/redux/useReduxState";

export type BannerType = "GetMembershipForWA" | "GetMembershipForMember" | "ExtendMembership" | "UpgradeMembership";
export type SelectedDcMembershipType = "None" | "Standard" | "Group" | "Upgrade" | "Extend";
export type TermsAcceptance = "Unneeded" | "NotAccepted" | "Accepted";

const loaderAnimationClass = "flight-banner-animation";

export interface Props {
    context: FlightPageContext;
    isLoading: boolean;
    onLogin: () => void;
}

export const useDiscountClubBanner = (props: Props) => {
    const bannerType = props.context?.bannerState?.bannerVM?.bannerType;
    const selectedOption = props.context?.bannerState?.selectedOption;

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const appContext = useAppContext();

    const loaderContainer = useRef<HTMLDivElement>(undefined);
    const banner = useRef<HTMLDivElement>(undefined);

    const [userContext] = useReduxState("userContext");

    const [loader, setLoader] = useState<JsLoader>(undefined);
    const [showBannerError, setShowBannerError] = useState<boolean>(undefined);

    const showBanner = useMemo(
        () =>
            props.context.showBanner({
                farelockState: props.context.farelockState,
                isLoading: props.isLoading,
            }),
        [props.context.farelockState, props.context.bannerState, props.isLoading, userContext],
    );

    const sideCardLogoSrc = useMemo(() => {
        switch (appContext.Language) {
            case SPANISH_LANGUAGE_CODE:
                return "/Images/DcStandalone/dc-logo-2-blue.png";
            case PORTUGESE_LANGUAGE_CODE:
                return "/Images/DcStandalone/dc-logo-2-blue-pt.png";
            case ENGLISH_LANGUAGE_CODE:
                return "/Images/DcStandalone/dc-logo-2-blue-en.png";
            default:
                return "";
        }
    }, [appContext.Language]);

    const shouldScrollToBannerUponInit = useMemo(
        () => bannerType === "ExtendMembership" || bannerType === "UpgradeMembership",
        [props.context?.bannerState?.bannerVM],
    );

    const extendBanner = useExtendMembershipBanner({ context: props.context });

    const upgradeBanner = useUpgradeMembershipBanner({ context: props.context });

    const getMembershipBanner = useGetMembershipBanner({ context: props.context, onLogin: props.onLogin });

    // Helpers

    const scrollToBanner = () => {
        ScrollHelper.scrollToElementAndHideNav({
            element: banner.current,
            yOffset: 30,
            timeOffset: 250,
        });
    };

    const handleLoader = () => {
        if (!loader) {
            const container = loaderContainer.current;
            setLoader(
                showLoader({
                    container,
                    noPlane: true,
                    name: loaderAnimationClass,
                }),
            );
            return;
        }

        if (props.context?.bannerState.bannerVM) {
            hideLoader(loader);
            setLoader(undefined);
            scrollToBanner();
        }
    };

    // Component

    useEffect(() => setShowBannerError(false), [bannerType, selectedOption]);

    useEffect(handleLoader, [props.isLoading]);

    useEffect(() => {
        if (shouldScrollToBannerUponInit) scrollToBanner();
    }, []);

    // Templates

    const sideCardTemplate = () =>
        isCurrentBancoEstadoCategory([0])
            ? html`
                  <div class="dc-banner-sidecard">
                      <img src=${sideCardLogoSrc} />
                      <img class="hidden-sm-up" src="/Images/DcStandalone/mobile-banner.png" />
                  </div>
              `
            : html` <div class="dc-banner-sidecard banco-estado"></div> `;

    const extensionBannerTemplate = () => (bannerType === "ExtendMembership" ? extendBanner.htmlTemplate() : "");

    const upgradeBannerTemplate = () => (bannerType === "UpgradeMembership" ? upgradeBanner.htmlTemplate() : "");

    const getMembershipBannerTemplate = () =>
        bannerType === "GetMembershipForMember" || bannerType === "GetMembershipForWA"
            ? getMembershipBanner.htmlTemplate()
            : "";

    const bannerErrorTemplate = () =>
        showBannerError
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message">
                          ${i18next.t("Debes seleccionar una opción antes de continuar.")}
                      </div>
                  </div>
              `
            : "";

    const termsErrorTemplate = () =>
        props.context.showTermsError
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message">
                          ${i18next.t(
                              "Por favor acepta los Términos y Condiciones del Club de Descuentos y luego haz click en Continuar.",
                          )}
                      </div>
                  </div>
              `
            : "";

    const contentTemplate = () =>
        !props.isLoading
            ? html`
                  <div class="dc-banner-container" ref=${ref(banner)} data-test-id=${T.FLIGHT_DC_BANNER.CONTAINER}>
                      ${sideCardTemplate()} ${extensionBannerTemplate()} ${upgradeBannerTemplate()}
                      ${getMembershipBannerTemplate()}
                  </div>
              `
            : "";

    const loaderTemplate = () =>
        props.isLoading
            ? html`
                  <div class="banner-container-loader" ref=${ref(loaderContainer)}>
                      <div class="${loaderAnimationClass} h-full w-full"></div>
                  </div>
              `
            : "";

    const htmlTemplate = () => {
        setTestCircuitDomValue<YesNo>("DcBannerToBeOpened", showBanner ? "YES" : "NO");
        return showBanner
            ? html`
                  <div class="relative mx-auto mt-5 w-[1005px] max-w-[90%]">
                      ${contentTemplate()} ${loaderTemplate()}
                  </div>
                  ${bannerErrorTemplate()} ${termsErrorTemplate()}
              `
            : "";
    };

    return {
        isLoading: showBanner && props.isLoading,
        htmlTemplate,
        showError: () => setShowBannerError(true),
    };
};

import { DEFAULT_DATE_FORMAT, OUTBOUND } from "../../shared/commonConstants";
import { StationSettings } from "../../component-models/StationSettings";
import { html, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { GetTimeTable, GetTimeTableResponse, Offer } from "../../component-models/GetTimetable";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import * as dayjs from "dayjs";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import { getMomentInstance } from "../../shared/common";
import { maxBy } from "../../shared/IterableHelpers";
import { useChangeSearchTealiumManager } from "../../managers/Tealium/useChangeSearchTealiumManager";
import { useRouteSelector } from "./useRouteSelector";
import { useDateSelector } from "./useDateSelector";
import { Dayjs } from "dayjs";
import { stationUtils } from "./stationUtils";
import { useCountryManager } from "../../managers/useCountryManager";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface RouteAndDateSelectorProps {
    culture: string;
    currency?: string;
    destination?: string;
    fixedInboundDate?: boolean;
    fixedOutboundDate?: boolean;
    inboundDate?: dayjs.Dayjs;
    isOneWay: boolean;
    maxInboundDate?: dayjs.Dayjs;
    maxOutboundDate?: dayjs.Dayjs;
    minInboundDate?: dayjs.Dayjs;
    minOutboundDate?: dayjs.Dayjs;
    origin?: string;
    outboundDate?: dayjs.Dayjs;
    sameCitiesOnly?: boolean;
    stationSettings: StationSettings;
    useDatepickerCaptions: boolean;
    onChange: (origin: string, destination: string, outbound: dayjs.Dayjs, inbound: dayjs.Dayjs) => void;
}

export const useRouteAndDateSelector = (props: RouteAndDateSelectorProps) => {
    const countryManager = useCountryManager({ stationSettings: props.stationSettings });
    const tealiumManager = useChangeSearchTealiumManager();

    const [countries] = useReduxState("countries");

    const { getCityByCode, getMacBySubstationCode } = stationUtils();

    const root = useRef<HTMLDivElement>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedOrigin, setSelectedOrigin] = useState<string>(undefined);
    const [selectedDestination, setSelectedDestination] = useState<string>(undefined);
    const [disabledOutboundDates, setDisabledOutboundDates] = useState<dayjs.Dayjs[]>([]);
    const [disabledInboundDates, setDisabledInboundDates] = useState<dayjs.Dayjs[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [selectedOutboundDate, setSelectedOutboundDate] = useState<dayjs.Dayjs>(undefined);
    const [selectedInboundDate, setSelectedInboundDate] = useState<dayjs.Dayjs>(undefined);
    const [maxOutboundDate, setMaxOutboundDate] = useState<dayjs.Dayjs>(getMomentInstance(props.maxOutboundDate));
    const [maxInboundDate, setMaxInboundDate] = useState<dayjs.Dayjs>(getMomentInstance(props.maxInboundDate));
    const [minOutboundDate, setMinOutboundDate] = useState<dayjs.Dayjs>(getMomentInstance(props.minOutboundDate));
    const [minInboundDate, setMinInboundDate] = useState<dayjs.Dayjs>(getMomentInstance(props.minInboundDate));

    const handleRouteSelect = (origin: string, destination: string) => {
        setSelectedOrigin(origin);
        setSelectedDestination(destination);
    };

    const routeSelector = useRouteSelector({
        destination: props.destination,
        origin: props.origin,
        root: root.current,
        sameCitiesOnly: props.sameCitiesOnly,
        stationSettings: props.stationSettings,
        onSelect: handleRouteSelect,
    });

    const handleDateSelect = (journeyIndex: number, selectedDates: Dayjs[]) => {
        if (journeyIndex === OUTBOUND) {
            handleOutboundSelect(selectedDates);
        } else {
            handleInboundSelect(selectedDates);
        }
    };

    const dateSelector = useDateSelector({
        destination: selectedDestination,
        disabledInboundDates,
        disabledOutboundDates,
        fixedInboundDate: props.fixedInboundDate,
        fixedOutboundDate: props.fixedOutboundDate,
        inboundDate: selectedInboundDate,
        isLoading,
        isOneWay: props.isOneWay,
        maxInboundDate,
        maxOutboundDate,
        minInboundDate,
        minOutboundDate,
        origin: selectedOrigin,
        outboundDate: selectedOutboundDate,
        root: root.current,
        useCaptions: props.useDatepickerCaptions,
        onSelect: handleDateSelect,
    });

    const initPreselection = async () => {
        if (props.origin && props.destination) {
            // DEVNOTE This is to make sure that if the selected endpoint is a MAC substation,
            // we change the code to the parent MAC code
            const newOriginCode =
                getCityByCode(countries, props.origin)?.code || getMacBySubstationCode(countries, props.origin)?.code;
            const newDestinationCode =
                getCityByCode(countries, props.destination)?.code ||
                getMacBySubstationCode(countries, props.destination)?.code;
            setSelectedOrigin(newOriginCode);
            setSelectedDestination(newDestinationCode);

            if (props.outboundDate) {
                await updateDatepickersOnRouteChange(true, newOriginCode, newDestinationCode);

                tealiumManager.logAvailability(dayjs(props.outboundDate), maxInboundDate, disabledInboundDates);
            } else {
                let minInboundDate: dayjs.Dayjs = dayjs();

                if (props.minInboundDate && props.minInboundDate.isSameOrAfter(minInboundDate, "day")) {
                    minInboundDate = props.minInboundDate;
                }

                setMinInboundDate(dayjs(minInboundDate));
            }
        }

        // DEVNOTE JET-10052, This needs to be here to ensure that the datepickers are initialized with
        // the correct dates if the user has selected dates earlier
        setSelectedOutboundDate(getMomentInstance(props.outboundDate));
        setSelectedInboundDate(getMomentInstance(props.inboundDate));

        setIsInitialized(true);
    };

    const getTimeTable = async (originCode: string, destinationCode: string): Promise<GetTimeTable> => {
        const headers = new Headers({ Accept: "application/json" });

        const url = createTimetableUrl(originCode, destinationCode);

        const response = await fetch(url, { headers });

        if (!response.ok) {
            return undefined;
        }

        const timetable = (await response.json()) as GetTimeTableResponse;

        return {
            inbound: timetable.Inbound.map(
                (o) =>
                    ({
                        currency: o.Currency,
                        departureDate: dayjs(o.DepartureDate, "DD-MM-YYYY"),
                        price: o.Price,
                    }) as Offer,
            ),
            outbound: timetable.Outbound.map(
                (o) =>
                    ({
                        currency: o.Currency,
                        departureDate: dayjs(o.DepartureDate, "DD-MM-YYYY"),
                        price: o.Price,
                    }) as Offer,
            ),
        };
    };

    const createTimetableUrl = (originCode: string, destinationCode: string) => {
        const url = `${ROUTES.ApiRoutes.RetrieveSchedule}?from=${originCode}&to=${destinationCode}&startDate=${dayjs().startOf("month").format("YYYY-MM-DD")}&return=${true}`;
        return props.currency ? `${url}&currency=${props.currency}` : url;
    };

    const handleOutboundSelect = (selectedDates: Dayjs[]) => {
        const [selectedDate] = selectedDates;

        if (!selectedDate) return;

        setSelectedOutboundDate(dayjs(selectedDate));

        if (!props.fixedInboundDate) {
            let minInbound = dayjs(selectedDate);

            if (props.minInboundDate?.isAfter(minInbound, "day")) minInbound = dayjs(props.minInboundDate);

            setMinInboundDate(minInbound);

            if (selectedInboundDate?.isBefore(selectedDate, "day")) setSelectedInboundDate(undefined);
        }
    };

    const handleInboundSelect = (selectedDates: Dayjs[]) => {
        const [rangeStartDate, selectedDate] = selectedDates;
        const dateToSet = selectedDate || rangeStartDate;

        if (dateToSet) setSelectedInboundDate(dayjs(dateToSet));
    };

    const updateDatepickersOnRouteChange = async (isInit: boolean, origin: string, destination: string) => {
        if (!origin || !destination) return;

        setIsLoading(true);

        const timetable = await getTimeTable(origin, destination);
        const outbound = digestTimetable(
            timetable.outbound,
            props.minOutboundDate,
            props.maxOutboundDate,
            "outbound_availability_days",
        );

        setDisabledOutboundDates(outbound.disabled);
        setMaxOutboundDate(getMomentInstance(outbound.max || outbound.min));
        setMinOutboundDate(getMomentInstance(outbound.min));

        const inbound = digestTimetable(timetable.inbound, props.minInboundDate, props.maxInboundDate, undefined);

        const isOutboundDateLaterThanMinInboundDate =
            isInit && props.outboundDate && inbound.min.isSameOrBefore(props.outboundDate, "day");

        setDisabledInboundDates(inbound.disabled);
        setMaxInboundDate(getMomentInstance(inbound.max));
        setMinInboundDate(
            isOutboundDateLaterThanMinInboundDate
                ? getMomentInstance(props.outboundDate)
                : getMomentInstance(inbound.min),
        );

        setIsLoading(false);
    };

    const digestTimetable = (info: Offer[], minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs, tealiumName: string) => {
        let min: dayjs.Dayjs = dayjs();
        let max: dayjs.Dayjs;
        let disabled: dayjs.Dayjs[] = [];

        info = info.sort((a, b) => (a.departureDate.isAfter(b.departureDate) ? 1 : -1));

        max = maxBy(info, (i) => i.departureDate.unix())?.departureDate;

        if (minDate && minDate.isSameOrAfter(min, "day")) min = minDate;

        if (maxDate && (!max || maxDate.isSameOrBefore(max, "day"))) max = maxDate;

        disabled = findMissingDaysBetween(
            info.map((i) => i.departureDate),
            min,
            max,
        );

        if (tealiumName) tealiumManager.logTimetableLoad(min, max, disabled, tealiumName);

        return { min, max, disabled };
    };

    const findMissingDaysBetween = (days: dayjs.Dayjs[], minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs) => {
        const missingDays: dayjs.Dayjs[] = [];

        let currentDate = minDate.startOf("day");
        while (currentDate.isSameOrBefore(maxDate)) {
            if (!days.some((day) => day.isSame(currentDate, "day"))) {
                missingDays.push(currentDate);
            }
            currentDate = currentDate.add(1, "day");
        }

        return missingDays;
    };

    const resetInboundDateForOneWay = () => {
        if (props.isOneWay) {
            setSelectedInboundDate(undefined);

            if (props.onChange) props.onChange(selectedOrigin, selectedDestination, selectedOutboundDate, undefined);
        }
    };

    const applyOutsideOutboundDateChange = () => {
        if (props.outboundDate && !selectedOutboundDate && !props.outboundDate.isSame(selectedOutboundDate, "day")) {
            setSelectedOutboundDate(dayjs(props.outboundDate));
        }
    };

    const applyOutsideInboundDateChange = () => {
        if (props.inboundDate && !selectedInboundDate && !props.inboundDate.isSame(selectedInboundDate, "day")) {
            setSelectedInboundDate(dayjs(props.inboundDate));
        }
    };

    // TODO OCH Cache timetable results on frontend?
    const getNewTimetableOnEndpointChange = () => {
        const doIt = async () => {
            setSelectedOutboundDate(undefined);
            setSelectedInboundDate(undefined);

            if (selectedOrigin && selectedDestination) {
                await updateDatepickersOnRouteChange(false, selectedOrigin, selectedDestination);
            }

            if (props.onChange) {
                props.onChange(selectedOrigin, selectedDestination, undefined, undefined);
            }
        };

        // DEVNOTE It is needed so that initial values are not erased if all 4 are present (from, to, date1, date2)
        if (isInitialized) doIt();
    };

    const dispatchChangeEvent = () =>
        props.onChange
            ? props.onChange(selectedOrigin, selectedDestination, selectedOutboundDate, selectedInboundDate)
            : null;

    // FIXME This should not be needed. Prop changes should be detected. But they are not...
    const reset = () => {
        applyOutsideOutboundDateChange();
        applyOutsideInboundDateChange();
    };

    useEffect(dispatchChangeEvent, [selectedOrigin, selectedDestination, selectedOutboundDate, selectedInboundDate]);

    useEffect(getNewTimetableOnEndpointChange, [selectedOrigin, selectedDestination]);

    useEffect(applyOutsideOutboundDateChange, [props.outboundDate?.format(DEFAULT_DATE_FORMAT)]);

    useEffect(applyOutsideInboundDateChange, [props.inboundDate?.format(DEFAULT_DATE_FORMAT)]);

    useEffect(resetInboundDateForOneWay, [props.isOneWay]);

    useEffect(initPreselection, [countries?.length]);

    useEffect(countryManager.initCountries, []);

    const htmlTemplate = html`
        <div ref=${ref(root)}>${routeSelector.htmlTemplate()} ${dateSelector.htmlTemplate()}</div>
    `;

    return {
        disabledInboundDates,
        disabledOutboundDates,
        htmlTemplate,
        maxInboundDate,
        maxOutboundDate,
        minInboundDate,
        minOutboundDate,
        selectedDestination,
        selectedDestinationCountry: routeSelector.selectedDestinationCountry,
        selectedInboundDate,
        selectedOrigin,
        selectedOriginCountry: routeSelector.selectedOriginCountry,
        selectedOutboundDate,
        setSelectedInboundDate,
        setSelectedOutboundDate,
        reset,
    };
};

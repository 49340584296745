import {
    BRASILIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    DC_GROUP_MEMBERSHIP_QUERY_SUBSTRING,
    DC_MEMBERSHIP_GROUP,
    DC_MEMBERSHIP_STANDARD,
    FAKE_DATE_OF_BIRTH,
    FULL_EMAIL_REGEX,
    NAME_INPUT_MAX_LENGTH,
    USA_CULTURE_CODE,
} from "./../../shared/commonConstants";
import {
    SelectOption,
    getAntiForgeryTokenFromHtml,
    getParsedProperty,
    hideLoader,
    showLoader,
} from "./../../shared/common";
import { normalizeCulture } from "../../shared/localeHelper";
import { useMemo, useState } from "./../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef, useEffect as hauntedUseEffect } from "haunted";
import { getAntiforgerySegment } from "../../shared/common";
import i18next from "i18next";
import { ref } from "../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { ROUTES } from "../../shared/apiRoutes";
import { RegisterHelper } from "./RegisterHelper";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { ApiRegisterPageViewModel } from "../../component-models/register-page/ApiRegisterPageViewModel";
import { useTealiumManager } from "../../managers/Tealium/useTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { useFluentValidator } from "../../validator/FluentValidator";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { createFormHtmlElement } from "../../component-helpers/FormHelper";
import { Validation } from "../../validator/Validation";
import { validatePassword } from "../../validator/validation-helper";
import { useAvailabilityValidator } from "../../validator/useAvailabilityValidator";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useReduxState } from "../../shared/redux/useReduxState";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const name = "ac-register";

const captchaContainerClass = "ac-captcha-container";

interface RegisterVM {
    confirmEmail: string;
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    selectedMembership: typeof DC_MEMBERSHIP_STANDARD | typeof DC_MEMBERSHIP_GROUP;
    termsAccepted: boolean;
}

type FieldNames = keyof RegisterVM;

export interface Region {
    [name: string]: string[];
}

export interface ChileRegion {
    comunas: string[];
    name: string;
}

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model", "predefined-email-address"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
    "predefined-email-address": string;
}

export interface Properties {
    antiForgeryToken: string;
    model: ApiRegisterPageViewModel;
    predefinedEmailAddress: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ApiRegisterPageViewModel>(host.model),
        predefinedEmailAddress: host.predefinedEmailAddress,
    };

    const DEFAULT_VM: RegisterVM = {
        confirmEmail: "",
        confirmPassword: "",
        email: props.predefinedEmailAddress || "",
        firstName: "",
        lastName: "",
        password: "",
        selectedMembership: DC_MEMBERSHIP_STANDARD,
        termsAccepted: false,
    };

    let tabIndexCounter = 1;

    const getTabIndex = () => tabIndexCounter++;

    const resetTabIndexCounter = () => {
        tabIndexCounter = 1;
        return "";
    };

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        if (window.location.href.indexOf(DC_GROUP_MEMBERSHIP_QUERY_SUBSTRING) > -1) {
            setVm({ ...vm, selectedMembership: DC_MEMBERSHIP_GROUP });
        }

        initCaptcha();

        const handler = triggers.shared.currencyChanged.subscribe((currencyCode: string) =>
            setPreselectedCurrencyCode(currencyCode),
        );
        return () => handler.unsubscribe();
    };

    const initCaptcha = () => {
        if (!appContext.isFeatureSwitchActive("Captcha")) return;

        const grecaptchaInterval = window.setInterval(() => {
            const captchaContainer = document.body.querySelector(`.${captchaContainerClass} div`) as HTMLDivElement;

            if (window.grecaptcha?.render && captchaContainer) {
                setCaptchaId(
                    window.grecaptcha.render(captchaContainer, {
                        sitekey: props.model.GRecaptchaSiteKey,
                    }),
                );

                window.clearInterval(grecaptchaInterval);
                window.clearTimeout(grecaptchaTimeLimit);
            }
        }, 100);

        const grecaptchaTimeLimit = window.setTimeout(() => {
            window.clearInterval(grecaptchaInterval);
            setShowCaptchaLoadError(true);
        }, 3000);
    };

    const validateEmail = async (value: string) => {
        if (props.predefinedEmailAddress || props.model.IsBancoEstado || !FULL_EMAIL_REGEX.test(value)) {
            return true;
        }

        if (emailField.current?.contains(document.activeElement)) return true;

        const loader = showLoader({});
        const result = await emailAvailabilityValidator.validate(value);
        hideLoader(loader);
        return result;
    };

    const submit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        let isValid = await validator.validate();

        if (
            appContext.isFeatureSwitchActive("Captcha") &&
            captchaId !== undefined &&
            window.grecaptcha.getResponse(captchaId).length === 0
        ) {
            isValid = false;
            setShowCaptchaError(true);
            tealiumManager.logValidationError(["captcha not validated"]);
        } else {
            setShowCaptchaError(false);
        }

        if (isValid) {
            const loader = showLoader({});
            await sendFormsToEmarsys({
                email: vm.email,
                firstName: vm.firstName,
                isBancoEstado: props.model.IsBancoEstado,
                isForDc: props.model.IsForDc,
                lastName: vm.lastName,
                rut: props.model.UserName,
                selectedMembership: vm.selectedMembership,
            });

            const captchaResponse = appContext.isFeatureSwitchActive("Captcha")
                ? window.grecaptcha.getResponse(captchaId)
                : null;

            const formToPost = createFormHtmlElement(props.model.IsForDc ? ROUTES.RegisterDcPost : ROUTES.Register, {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
                "g-recaptcha-response": captchaResponse,
                "feeCode": vm.selectedMembership || null,
                "selectedCurrency": props.model.IsForDc ? preselectedCurrencyCode : null,
                "loginType": props.model.IsBancoEstado
                    ? props.model.BancoEstadoLoginType
                    : props.model.PersonalLoginType,

                "registerMember.Member.DateOfBirth": FAKE_DATE_OF_BIRTH,
                "registerMember.Member.HomeAddress.AddressLine1": " ",
                "registerMember.Member.Name.First": vm.firstName,
                "registerMember.Member.Name.Last": vm.lastName,
                "registerMember.Member.NewPasswordConfirmation": vm.confirmPassword,
                "registerMember.Member.Password": vm.password,
                "registerMember.Member.Person.CultureCode": normalizeCulture(appContext.Culture),
                "registerMember.Member.PersonalEmailAddress.EmailAddress": vm.email,
                "registerMember.Member.Username": props.predefinedEmailAddress
                    ? props.predefinedEmailAddress
                    : props.model.IsBancoEstado
                      ? props.model.UserName
                      : vm.email,
            });

            formToPost.submit();
            hideLoader(loader);
        }
    };

    const handleCancelButton = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        window.location.href = "/";
    };

    const root = useRef<HTMLDivElement>(undefined);
    const emailField = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();
    const tealiumManager = useTealiumManager();

    const { triggers } = usePubSub();
    const { sendFormsToEmarsys } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [captchaId, setCaptchaId] = useState<any>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [preselectedCurrencyCode, setPreselectedCurrencyCode] = useState<string>(props.model.PreselectedCurrencyCode);
    const [showCaptchaError, setShowCaptchaError] = useState<boolean>(false);
    const [showCaptchaLoadError, setShowCaptchaLoadError] = useState<boolean>(false);
    const [vm, setVm] = useState<RegisterVM>(DEFAULT_VM);

    const emailAvailabilityValidator = useAvailabilityValidator<{ EmailAddress: string }, { IsUserExist: boolean }>({
        bodyKey: "EmailAddress",
        container: root.current,
        method: "POST",
        responseKey: "IsUserExist",
        url: `${ROUTES.ApiRoutes.CheckUserExist}`,
    });

    const validator = useFluentValidator<FieldNames, RegisterVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("password", (vm: RegisterVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => validatePassword(password),
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                ),
            Validation.ruleFor("lastName", (vm: RegisterVM) => vm.lastName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("firstName", (vm: RegisterVM) => vm.firstName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("termsAccepted", (vm: RegisterVM) => vm.termsAccepted)
                .fulfils((_) => Promise.resolve(false), i18next.t("Breakdown-AcceptTermsFirst"))
                .when((model: RegisterVM) => !model.termsAccepted),
            Validation.ruleFor("confirmPassword", (vm: RegisterVM) => vm.confirmPassword)
                .isRequired()
                .fulfils(
                    async (confirmPassword: string) => confirmPassword === vm.password,
                    i18next.t("El campo de confirmación de contraseña debe coincidir con el campo de contraseña."),
                ),
            Validation.ruleFor("confirmEmail", (vm: RegisterVM) => vm.confirmEmail)
                .isRequired()
                .fulfils(
                    async (confirmEmail: string) => confirmEmail === vm.email,
                    i18next.t("V2DC-EmailConfirmMismatch"),
                )
                .when(() => !props.model.IsForGiftcard),
            Validation.ruleFor("email", (vm: RegisterVM) => vm.email)
                .isRequired()
                .isEmail()
                .fulfils(validateEmail, i18next.t("RegisterEmailAlreadyRegistered")),
        ],
    });

    const registerTermsCheckbox = useBasicCheckbox({
        customInputClass: !validator.isValid("termsAccepted") ? "invalid" : "",
        customWrapperClass: ["h-10", "mt-4"],
        inputTestId: T.REGISTER.ACCEPT_TERMS_CHECKBOX,
        isChecked: vm.termsAccepted,
        labelTemplate: (id: string) => registerTermsLabelTemplate(id),
        onClick: () => setVm({ ...vm, termsAccepted: !vm.termsAccepted }),
    });

    const membershipOptions = useMemo(
        (): SelectOption[] => [
            {
                Text: i18next.t("DC-StandardMembership"),
                Value: DC_MEMBERSHIP_STANDARD,
                IsSelected: vm.selectedMembership === DC_MEMBERSHIP_STANDARD,
            },
            {
                Text: i18next.t("DC-GroupMembership"),
                Value: DC_MEMBERSHIP_GROUP,
                IsSelected: vm.selectedMembership === DC_MEMBERSHIP_GROUP,
            },
        ],
        [vm.selectedMembership],
    );

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    hauntedUseEffect(init, []);

    const captchaTemplate = () =>
        appContext.isFeatureSwitchActive("Captcha")
            ? html`
                  <div class="col-xs-1 col-md-1-2 mt-6">
                      <div class="row">
                          <div class="col-xs-1">
                              <div class=${captchaContainerClass}>
                                  <div></div>
                              </div>
                          </div>
                          ${captchaErrorTemplate()}
                      </div>
                  </div>
              `
            : "";

    const captchaErrorTemplate = () =>
        showCaptchaError
            ? html`
                  <div class="col-xs-1">
                      <div class="error-message-container elevated-error text-left">
                          <div class="form-error-message">${i18next.t("CUG2-CaptchaError")}</div>
                      </div>
                  </div>
              `
            : "";

    const captchaLoadErrorTemplate = () =>
        showCaptchaLoadError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="captcha-load-error">${i18next.t("CaptchaLoadError")}</div>
                      </div>
                  </div>
              `
            : "";

    const bancoEstadoBannerTemplate = () =>
        props.model.IsBancoEstado
            ? html`
                  <div class="be-register-logo">
                      <div class="row">
                          <div class="col-xs-1 text-center">
                              <img src="/Images/BancoEstado/register-banner.png" />
                          </div>
                      </div>
                  </div>
                  <section class="banco-estado-register-breadcrumbs">
                      <span>1</span>
                      <span>2</span>
                  </section>
              `
            : "";

    const dcHeaderTemplate = () => {
        const imageUrl =
            appContext.Culture === BRASILIAN_CULTURE_CODE
                ? "/Images/DcStandalone/dc-logo-2-pt.png"
                : appContext.Culture === USA_CULTURE_CODE
                  ? "/Images/DcStandalone/dc-logo-2-en.png"
                  : "/Images/DcStandalone/dc-logo-2.png";

        return props.model.IsForDc
            ? html`
                  <img src=${imageUrl} />
                  <div class="dc-standalone-register-info">${i18next.t("DcStandaloneRegisterInfo")}</div>
              `
            : "";
    };

    const nonDcHeaderTemplate = () => {
        const tempClassMap = classMap({
            "main-title": true,
            "banco-estado-color": props.model.IsBancoEstado,
        });

        const text = props.model.IsBancoEstado ? i18next.t("BE-RegisterTitle") : i18next.t("Register-Title");

        return !props.model.IsForDc
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <header>
                              <h2 class=${tempClassMap} data-test-id=${T.REGISTER.MAIN_TITLE}>${text}</h2>
                          </header>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-xs-1">${slogansTemplate()}</div>
                  </div>
              `
            : "";
    };

    const slogansTemplate = () =>
        props.model.IsBancoEstado
            ? html` <p>${i18next.t("BE-RegisterBenefits")}</p> `
            : html`
                  <p>${i18next.t("Register-MarketingBodyText")}</p>
                  <ul data-test-id=${T.REGISTER.REGISTER_DESCRIPTIONS_LIST}>
                      <li>${i18next.t("Register-Benefit1")}</li>
                      <li>${i18next.t("Register-Benefit2")}</li>
                      <li>${i18next.t("Register-Benefit3")}</li>
                  </ul>
              `;

    const bancoEstadoUsernameTemplate = () =>
        props.model.IsBancoEstado
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .autoComplete=${"cc-exp"}
                                  .isDisabled=${true}
                                  .label=${`${i18next.t("V2-Rut")}*`}
                                  .value=${props.model.UserName}
                              ></ac-input>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const emailTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2" ref=${ref(emailField)}>
            <div class="mt-[20px]">
                <ac-input
                    .autoComplete=${"cc-exp"}
                    .errorMessage=${validator.getMessage("email")}
                    .isDisabled=${props.model.IsForGiftcard}
                    .isInvalid=${!validator.isValid("email")}
                    .label=${`${i18next.t("V2-EmailLabel")}*`}
                    .tabIndex=${getTabIndex()}
                    .testId=${T.REGISTER.EMAIL}
                    .value=${vm.email ?? ""}
                    .onInput=${(value: string) => setVm({ ...vm, email: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const confirmEmailTemplate = () =>
        !props.model.IsForGiftcard
            ? html`
                  <div class="col-xs-1 col-sm-1-2">
                      <div class="mt-[20px]">
                          <ac-input
                              .autoComplete=${"cc-exp"}
                              .errorMessage=${validator.getMessage("confirmEmail")}
                              .isInvalid=${!validator.isValid("confirmEmail")}
                              .label=${`${i18next.t("V2-ConfirmEmail")}*`}
                              .tabIndex=${getTabIndex()}
                              .testId=${T.REGISTER.EMAIL_CONFIRMATION}
                              .value=${vm.confirmEmail ?? ""}
                              .onInput=${(value: string) => setVm({ ...vm, confirmEmail: value })}
                          ></ac-input>
                      </div>
                  </div>
              `
            : "";

    const passwordTemplate = () => {
        return html`
            <div class="col-xs-1 col-sm-1-2">
                <div class="relative mt-[20px]">
                    <ac-input
                        .autoComplete=${"new-password"}
                        .errorMessage=${validator.getMessage("password")}
                        .isInvalid=${!validator.isValid("password")}
                        .label=${`${i18next.t("V2-PasswordPlaceholder")}*`}
                        .tabIndex=${getTabIndex()}
                        .testId=${T.REGISTER.PASSWORD}
                        .type=${"password"}
                        .value=${vm.password ?? ""}
                        .onInput=${(value: string) => setVm({ ...vm, password: value })}
                    ></ac-input>
                    <span class="absolute right-[10px] top-[15px]">
                        <ac-tooltip
                            .icon=${"?"}
                            .tooltip="${i18next.t("V2DC-PasswordTooltip")}"
                            .tooltipCustomClass=${props.model.IsBancoEstado ? "banco-estado-pw-tooltip" : ""}
                        ></ac-tooltip>
                    </span>
                </div>
            </div>
        `;
    };

    const confirmPasswordTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("confirmPassword")}
                    .isInvalid=${!validator.isValid("confirmPassword")}
                    .label=${`${i18next.t("V2-ConfirmPassword")}*`}
                    .tabIndex=${getTabIndex()}
                    .testId=${T.REGISTER.PASSWORD_CONFIRMATION}
                    .type=${"password"}
                    .value=${vm.confirmPassword ?? ""}
                    .onInput=${(value: string) => setVm({ ...vm, confirmPassword: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const userTitleTemplate = () =>
        !props.model.IsBancoEstado
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <h1>${i18next.t("Register-UserInformationTitle")}</h1>
                      </div>
                  </div>
              `
            : "";

    const contactTitleTemplate = () => {
        const tempClassMap = classMap({
            "banco-estado-color": props.model.IsBancoEstado,
        });

        return html`
            <div class="row">
                <div class="col-xs-1">
                    <h1 class=${tempClassMap}>${i18next.t("Register-ContactInformationTitle")}</h1>
                </div>
            </div>
        `;
    };

    const firstNameTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("firstName")}
                    .isInvalid=${!validator.isValid("firstName")}
                    .label=${`${i18next.t("V2-FirstNameLabel")}*`}
                    .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                    .tabIndex=${getTabIndex()}
                    .testId=${T.REGISTER.FIRST_NAME}
                    .value=${vm.firstName ?? ""}
                    .onInput=${(value: string) => setVm({ ...vm, firstName: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("lastName")}
                    .isInvalid=${!validator.isValid("lastName")}
                    .label=${`${i18next.t("V2-LastNameLabel")}*`}
                    .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                    .tabIndex=${getTabIndex()}
                    .testId=${T.REGISTER.LAST_NAME}
                    .value=${vm.lastName ?? ""}
                    .onInput=${(value: string) => setVm({ ...vm, lastName: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const membershipTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2">
            <div class="mt-[20px]">
                <ac-select
                    .label=${i18next.t("V2DC-TypeOfMembershipLabel")}
                    .name="feeCode"
                    .options=${membershipOptions}
                    .tabIndex=${getTabIndex()}
                    .onSelect=${(value: typeof DC_MEMBERSHIP_GROUP | typeof DC_MEMBERSHIP_STANDARD) =>
                        setVm({ ...vm, selectedMembership: value })}
                >
                </ac-select>
            </div>
        </div>
    `;

    const registerTermsLabelTemplate = (id: string) => html`
        <label for=${id}>
            ${appContext.Culture === COLOMBIAN_CULTURE_CODE
                ? html`<span class="register-terms-text">
                      ${i18next.t("RegisterPageAcceptTermsStartingText")}
                      ${RegisterHelper.getTermsLink(props.model, false)}
                  </span>`
                : html`<span class="register-terms-text">
                      ${i18next.t("RegisterPageAcceptTermsStartingText")}
                      ${RegisterHelper.getTermsLink(props.model, true)}
                  </span>`}
        </label>
    `;

    const termsErrorTemplate = () =>
        !validator.isValid("termsAccepted")
            ? html`
                  <div class="error-message-container elevated-error text-left">
                      <div class="form-error-message">${i18next.t("Breakdown-AcceptTermsFirst")}</div>
                  </div>
              `
            : "";

    const dcButtonTemplate = () =>
        props.model.IsForDc
            ? html`
                  <div class="sa-dc-register-button-container">
                      <button @click=${handleCancelButton} class="rounded-secondary-btn">
                          ${i18next.t("V2-ReturnLabel")}
                      </button>
                      <button @click=${submit} class="rounded-primary-btn" data-test-id="registration-continue-button">
                          ${i18next.t("V2-ApplyLabel")}
                      </button>
                  </div>
              `
            : "";

    const nonDcButtonTemplate = () => {
        const buttonText = props.model.IsBancoEstado ? i18next.t("BE-Enter") : i18next.t("V2-Submit");

        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "banco-estado-color": props.model.IsBancoEstado,
        });

        return !props.model.IsForDc
            ? html`
                  <div class="register-button-container">
                      <button @click=${submit} class=${tempClassMap} data-test-id=${T.REGISTER.SUBMIT_BUTTON}>
                          ${buttonText}
                      </button>
                  </div>
              `
            : "";
    };

    const nameRowTemplate = () => html` <div class="row">${firstNameTemplate()} ${lastNameTemplate()}</div> `;

    const emailRowTemplate = () => html` <div class="row">${emailTemplate()} ${confirmEmailTemplate()}</div> `;

    const passwordRowTemplate = () => html` <div class="row">${passwordTemplate()} ${confirmPasswordTemplate()}</div> `;

    const dcRegisterTemplate = () =>
        props.model.IsForDc
            ? html`
                  ${nameRowTemplate()}
                  <div class="row">${membershipTemplate()}</div>
                  ${passwordRowTemplate()} ${emailRowTemplate()}
                  <div class="col-xs-1">${registerTermsCheckbox.htmlTemplate()} ${termsErrorTemplate()}</div>
                  ${captchaLoadErrorTemplate()}
              `
            : "";

    const regularRegisterTemplate = () =>
        !props.model.IsForDc
            ? html`
                  ${bancoEstadoUsernameTemplate()} ${userTitleTemplate()} ${emailRowTemplate()} ${passwordRowTemplate()}
                  ${contactTitleTemplate()} ${nameRowTemplate()}
                  <div class="row">
                      ${captchaTemplate()}
                      <div class="col-xs-1 col-md-1-2">
                          ${registerTermsCheckbox.htmlTemplate()} ${termsErrorTemplate()}
                      </div>
                  </div>
                  ${captchaLoadErrorTemplate()}
              `
            : "";

    const mainContentTemplate = () => {
        const tempClassMap = classMap({
            "no-breadcrumb": true,
            "ts-error-container": true,
            "booking-wrapper": !props.model.IsForGiftcard,
        });

        return html`
            <section class=${tempClassMap}>
                ${!props.model.IsForGiftcard ? html`${dcHeaderTemplate()} ${nonDcHeaderTemplate()}` : ""}

                <form data-test-id=${T.REGISTER.FORM}>
                    ${getAntiforgerySegment(props.antiForgeryToken)} ${dcRegisterTemplate()}
                    ${regularRegisterTemplate()} ${formErrors.htmlTemplate()} ${nonDcButtonTemplate()}
                    ${dcButtonTemplate()}
                </form>
            </section>
        `;
    };

    const mainClassMap = classMap({
        "sa-dc-register-page": props.model.IsForDc,
        "register-page": !props.model.IsForDc,
        "banco-estado": props.model.IsBancoEstado,
    });

    return html`
        <div
            id="mainContentWrapper"
            class=${mainClassMap}
            ref=${ref(root)}
            data-test-id=${T.REGISTER.MAIN_CONTENT_CONTAINER}
        >
            ${bancoEstadoBannerTemplate()} ${mainContentTemplate()}
        </div>
        ${resetTabIndexCounter()}
    `;
};

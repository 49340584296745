import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useCallback } from "haunted";
import { getCoords, getPassengerName, showLoader } from "../../../shared/common";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import {
    BaggageContext,
    BaggageContextJourneyPassengerBaggage,
    BaggageContextPassenger,
} from "../../../component-models/baggage/BaggageContext";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { getContextJourneys } from "../../../component-mappers/BaggageMappers";
import { DEFAULT_TIMESTAMP_FORMAT } from "../../../shared/commonConstants";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import { useBookingManager } from "../../../managers/useBookingManager";
import { SpaContainerViewModel } from "../../../component-models/spa/SpaContainerViewModel";
import { useRunOnce } from "../../useRunOnce";
import { YesNo, setTestCircuitDomValue } from "../../../testing-helpers/TestCircuitHelper";
import { useCabinBags } from "./useCabinBags";
import { useCheckedBags } from "./useCheckedBags";
import { useOversizedBags } from "./useOversizedBags";
import { useTealiumManager } from "../../../managers/Tealium/useTealiumManager";
import { useStaffBaggage } from "./common/useStaffBaggage";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../../managers/useBookingContext";
dayjs.extend(CustomParseFormat);

export const pageClass = "b2-baggage-page";
export const loaderClass = "b2-loader";

export const baggageErrorClass = "b2-error";
export const baggagePerJourneyErrorClass = "b2-error-per-journey";

export interface BaggagePage extends SpaContent {
    setBaggageModel: (model: ApiBaggageViewModel) => void;
}

export interface Props {
    model: SpaContainerViewModel;
    onForward: () => void;
    spaModalStart: (submitCallback: () => Promise<void>) => void;
}

export type BagType = "CabinBaggage" | "CheckedBaggage" | "OversizedBaggage";

export const useBaggagePage = (props: Props): BaggagePage => {
    const bookingContext = useBookingContext();

    const bookingManager = useBookingManager();
    const runOnce = useRunOnce();

    const { ajaxJsonRequest } = useAjax();
    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");
    const [total] = useReduxState("booking.total");
    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [passengerNames] = useReduxState("booking.passengerNames");
    const isSidebarLoaded = useReduxState("isSidebarLoaded");

    const [baggageModel, setBaggageModel] = useState<ApiBaggageViewModel>(props.model.BaggageModel);
    const [pageState, setPageState] = useState<BaggagePageState>({
        isLoading: false,
        lastValidationTimestamp: undefined,
    });

    const contextJourneys = useMemo(
        () =>
            getContextJourneys(
                baggageModel.Journeys,
                props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
                props.model.SpaGlobalDataModel.InboundBundleImgUrl,
            ),
        [baggageModel.Journeys, props.model],
    );

    const isActive = useMemo(() => currentSpaSection === "Baggage", [currentSpaSection]);

    const getContextJourneyPassengerBaggage = useCallback(
        (data: {
            bagType: BagType;
            journeyIndex: number;
            passengerIndex: number;
        }): BaggageContextJourneyPassengerBaggage =>
            contextJourneys
                .find((j) => j.index === data.journeyIndex)
                ?.passengers.find((p) => p.index === data.passengerIndex)[
                data.bagType === "CabinBaggage"
                    ? "cabinBaggage"
                    : data.bagType === "CheckedBaggage"
                      ? "checkedBaggage"
                      : "oversizedBaggage"
            ],
        [contextJourneys],
    );

    const pageContext = useMemo(
        (): BaggageContext => ({
            isDcBooking: bookingContext.isDiscountBooking,
            classesToScrollToOnOpen: new Map<BagType, string>([
                ["CabinBaggage", "b2-cabin-scroll-to-on-open"],
                ["CheckedBaggage", "b2-checked-scroll-to-on-open"],
                ["OversizedBaggage", "b2-oversized-scroll-to-on-open"],
            ]),
            classesToScrollToOnClose: new Map<BagType, string>([
                ["CabinBaggage", "b2-cabin-scroll-to-on-close"],
                ["CheckedBaggage", "b2-checked-scroll-to-on-close"],
                ["OversizedBaggage", "b2-oversized-scroll-to-on-close"],
            ]),
            journeys: contextJourneys,
            passengers: baggageModel.Journeys[0].Passengers.map(
                (p): BaggageContextPassenger => ({
                    index: p.PaxIndex,
                    name: getPassengerName(passengerNames, p.PaxIndex),
                    type: p.PaxType,
                }),
            ),
            getContextJourneyPassengerBaggage,
        }),
        [passengerNames, baggageModel, props.model, contextJourneys, getContextJourneyPassengerBaggage],
    );

    const staffBaggage = useStaffBaggage({ context: pageContext });

    const cabinBags = useCabinBags({
        context: pageContext,
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const checkedBags = useCheckedBags({
        context: pageContext,
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const oversizedBags = useOversizedBags({
        context: pageContext,
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const tealiumManager = useTealiumManager();

    const isThereNotEnoughOversizedForAllPaxOneJourney = useMemo(
        () => !baggageModel.Journeys.every((j) => j.AvailableOversizedBaggageQuantity >= pageContext.passengers.length),
        [baggageModel, pageContext],
    );

    const isPageValid = useMemo(
        () =>
            !oversizedBags.showOversizedErrors &&
            [cabinBags, checkedBags].every((bag) =>
                bag.state.journeys.every((journey) =>
                    journey.passengers.every((passenger) => {
                        const contextJourneyPassengerBaggage = getContextJourneyPassengerBaggage({
                            bagType: bag.bagType,
                            journeyIndex: journey.index,
                            passengerIndex: passenger.index,
                        });
                        return (
                            passenger.hasSelected ||
                            contextJourneyPassengerBaggage.isSoldOut ||
                            !bag.availability.isAddAvailable({
                                journeyIndex: journey.index,
                                passengerIndex: passenger.index,
                            })
                        );
                    }),
                ),
            ),
        [
            cabinBags.availability,
            cabinBags.state,
            checkedBags.availability,
            checkedBags.state,
            oversizedBags.showOversizedErrors,
            getContextJourneyPassengerBaggage,
        ],
    );

    const init = () => BookingFlowHandler.storeCurrentStep(BookingSteps.Baggage);

    const isButtonDisabled = () => !isActive || pageState.isLoading;

    const getFirstError = () => {
        const errors = Array.from(
            DomCrawlingHelper.getElemByClass(document.body, pageClass).querySelectorAll(`.${baggageErrorClass}`),
        ) as HTMLDivElement[];

        return errors.find((e) => e.offsetHeight > 0);
    };

    const scrollToFirstError = () => {
        // DEVNOTE Settimeout needed for errors to render
        window.setTimeout(() => {
            const firstError = getFirstError();

            if (!firstError) return;

            const topOfElement = getCoords(firstError).top - 150;
            window.scroll({
                top: topOfElement,
                behavior: "smooth",
            });
        }, 100);
    };

    const handleSubmitClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPageState((currentState) => ({
            ...currentState,
            lastValidationTimestamp: dayjs().format(DEFAULT_TIMESTAMP_FORMAT),
        }));

        if (isPageValid) {
            if (typeof props.spaModalStart === "function") {
                props.spaModalStart(handleSubmit);
            } else {
                handleSubmit();
            }
        } else {
            // DEVNOTE Settimeout is needed for error message to be displayed
            window.setTimeout(() => {
                scrollToFirstError();
            }, 0);

            raiseBookingFlowContinueEvent(false);
        }
    };

    const handleSubmit = async () => {
        const loader = showLoader({ container: document.body, name: loaderClass });
        const body = { stePassed: "true" };
        await bookingManager.postBaggageStepPassed(body, document.body, loader);

        setPageState((currentState) => ({ ...currentState, isLoading: true }));
        raiseBookingFlowContinueEvent(true);
        checkedBags.handlers.setResetInsuranceOnChange(true);
        oversizedBags.handlers.setResetInsuranceOnChange(true);
        tealiumManager.logContinueClicked();
        props.onForward();
        setPageState((currentState) => ({ ...currentState, isLoading: false, lastValidationTimestamp: undefined }));
    };

    useEffect(
        () => setPageState((currentState) => ({ ...currentState, lastValidationTimestamp: undefined })),
        [baggageModel],
    );

    const logDomLoad = () => {
        tealiumManager.logDomLoaded("baggage", {
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });
    };

    useEffect(() => {
        if (isActive && isSidebarLoaded && total) runOnce.run(() => logDomLoad());
        if (!isActive) runOnce.reset();
    }, [isActive, total, isSidebarLoaded]);

    useEffect(init, []);

    const headerTemplate = () => html`
        <header class="b2-header">
            <span class="js-circle-baggage js-icon title-icon" data-test-id=${T.BAGGAGE.MAIN_ICON}></span>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.BAGGAGE.HEADER_MAIN_TITLE}>${i18next.t("Equipaje")}</h2>
                <div class="subtitle" data-test-id=${T.BAGGAGE.HEADER_SUBTITLE}>
                    ${i18next.t("Si compras online, te saldrá más barato que en el aeropuerto.")}
                </div>
            </div>
        </header>
    `;

    const staffBenefitMessageTemplate = () =>
        userContext.isStaff
            ? html`
                  <div class="b2-staff-benefit-message">
                      ${i18next.t(
                          "Recuerda que el beneficio es que pagas por el primer equipaje $1 (cabina o bodega) para todos los pasajeros de tu reserva y tramos, pero el segundo equipaje tiene costo.",
                      )}
                  </div>
              `
            : "";

    const oversizedBagTemplate = () => {
        setTestCircuitDomValue<YesNo>(
            "PerJourneyPerPaxOpened",
            isThereNotEnoughOversizedForAllPaxOneJourney ? "YES" : "NO",
        );

        return html` <div class="ac-select-oversized-bag">${oversizedBags.htmlTemplate()}</div> `;
    };

    const buttonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "booking": true,
            "disabled": isButtonDisabled(),
        });

        return html`
            <div class="flex w-full justify-end">
                <button class=${tempClassMap} data-test-id=${T.BAGGAGE.SUBMIT_BUTTON} @click=${handleSubmitClick}>
                    ${i18next.t("Continuar")}
                </button>
            </div>
        `;
    };

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const response = await ajaxJsonRequest<ApiBaggageViewModel>({
                method: "GET",
                noCors: true,
                nonCancellable: true,
                url: ROUTES.AjaxBaggageModel,
            });

            setBaggageModel(response.data);
            window.setTimeout(() => triggers.baggage.reset.publish({}), 100);

            return { type: "success" };
        } catch (e) {
            return { type: "error" };
        }
    };

    const htmlTemplate = () => html`
        <div
            class="${LOADER_CLASS_NAMES.CommonLoaderWrapper} ${pageClass} ${loaderClass}"
            data-test-id=${T.BAGGAGE.MAIN_CONTAINER}
        >
            ${headerTemplate()} ${staffBenefitMessageTemplate()} ${cabinBags.htmlTemplate()}
            ${checkedBags.htmlTemplate()} ${oversizedBagTemplate()}
        </div>
        ${buttonTemplate()}
    `;
    return { build, htmlTemplate, setBaggageModel };
};

import { URL_VARS } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { getUrlVars } from "../../shared/common";

export const useFlightMoveResultBanner = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const urlVars = getUrlVars();

        if (urlVars && urlVars[URL_VARS.FLIGHT_MOVE_RESULT] && urlVars[URL_VARS.FLIGHT_MOVE_RESULT].length > 0) {
            setIsVisible(urlVars[URL_VARS.FLIGHT_MOVE_RESULT][0] === "successful");
        }
    }, []);

    const htmlTemplate = () =>
        isVisible
            ? html`
                  <div class="flight-move-result-banner no-print">
                      <i class="js-icon js-tick-broken-circle"></i>
                      <div>${i18next.t("¡El cambio de tu itinerario de vuelo se ha realizado exitosamente!")}</div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

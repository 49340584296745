import { DEFAULT_DATE_FORMAT } from "./../../../shared/commonConstants";
import { classMap } from "lit-html/directives/class-map";
import { DeleteRowEvent } from "./../../../component-models/CUG2b/Cug2PortalEvents";
import i18next from "i18next";
import { EditRowEvent, GlobalFilterChangeEvent } from "../../../component-models/CUG2b/Cug2PortalEvents";
import { useEffect } from "./../../../shared/haunted/CustomHooks";
import { CugBookings, CugReservation } from "../../../component-models/CUG2b/CugBookings";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useState } from "../../../shared/haunted/CustomHooks";
import { GridState, PageChangeEvent, RowsSelectedEvent, SortChangeEvent } from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { ROUTES } from "../../../shared/apiRoutes";
import { getAntiForgeryTokenFromHtml, handleCugLoader, toBoolean } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
dayjs.extend(CustomParseFormat);

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "can-export",
    "can-see-reports",
    "org-code",
    "url",
];
export const name = "ac-reservations-page";

export interface Attributes {
    "anti-forgery-token": string;
    "can-export": string;
    "can-see-reports": string;
    "org-code": string;
    "url": string;
}
export interface Properties {
    antiForgeryToken: string;
    canExport: boolean;
    canSeeReports: boolean;
    orgCode: string;
    url: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canExport: toBoolean(host.canExport),
        canSeeReports: toBoolean(host.canSeeReports),
        orgCode: host.orgCode,
        url: host.url,
    };

    // HELPERS

    const getReportUrl = () => {
        const url = props.url
            .replace("{0}", cug2AppContext.Country.toLowerCase())
            .replace("{1}", cug2AppContext.Language)
            .replace("{2}", encodeURIComponent(btoa(props.orgCode)));

        return props.canSeeReports ? url : "";
    };

    const loadData = async (
        data: {
            bookingStatus: "Confirmed" | "Hold";
            gridState: GridState<keyof CugReservation>;
        },
        filterForDates = hasSubmittedDateFiltering,
    ) => {
        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        const considerDateFiltering = data.bookingStatus === "Confirmed" && filterForDates;

        const result = await ajaxJsonRequest<CugBookings>({
            nonCancellable: true,
            container: root.current,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.AgencyReservations}/` +
                `?bookingStatus=${data.bookingStatus}` +
                `&pageSize=${data.gridState.pageSize}` +
                `&page=${data.gridState.pageIndex}` +
                (data.gridState.globalFilterExpression
                    ? `&searchExpression=${data.gridState.globalFilterExpression}`
                    : ``) +
                (considerDateFiltering && filterFlightDateFrom
                    ? `&flightDateFrom=${filterFlightDateFrom.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterFlightDateTo
                    ? `&flightDateTo=${filterFlightDateTo.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterPurchaseDateFrom
                    ? `&purchaseDateFrom=${filterPurchaseDateFrom.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterPurchaseDateTo
                    ? `&purchaseDateTo=${filterPurchaseDateTo.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                `&orderBy=${data.gridState.orderBy === "FormattedFlightDate" ? "FlightDate" : data.gridState.orderBy}` +
                `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}`,
        });

        return {
            totalCount: result?.data.TotalCount,
            newData: result?.data.Bookings.map((item) => {
                return { ...item, Id: item.ReservationCode };
            }),
        };
    };

    const initialGridState = (): GridState<keyof CugReservation> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "FormattedFlightDate",
            orderDir: "desc",
            globalFilterExpression: "",
        };
    };

    const onEditPendingRow = (e: EditRowEvent) => {
        handleCugLoader(root.current, "toItinerary");
        window.location.href = `${ROUTES.BookingRetrieve}/?em=${pendingData[e.detail.index].Contact}&rl=${
            pendingData[e.detail.index].ReservationCode
        }`;
    };

    const onEditConfirmedRow = (e: EditRowEvent) => {
        handleCugLoader(root.current, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${confirmedData[e.detail.index].Contact}&rl=${
            confirmedData[e.detail.index].ReservationCode
        }${e.detail.toExtras ? "&toExtras=true" : ""}`;
        window.location.href = url;
    };

    const handlePendingReservationDelete = async (e: DeleteRowEvent) => {
        handleCugLoader(pendingGrid.current, "loadPendingData");

        await ajaxRequest({
            body: { RecordLocator: e.detail.spnr },
            container: root.current,
            url: ROUTES.ApiRoutes.BookingCancel,
        });

        handleCugLoader(pendingGrid.current, "loadPendingData");

        loadPendingData();
    };

    const loadPendingData = async () => {
        handleCugLoader(pendingGrid.current, "loadPendingData");

        const { newData, totalCount } = await loadData({ bookingStatus: "Hold", gridState: pendingGridState });

        setPendingData(newData);
        setTotalPendingItemCount(totalCount);

        handleCugLoader(pendingGrid.current, "loadPendingData");
    };

    const loadConfirmedData = async (filterForDates = hasSubmittedDateFiltering) => {
        handleCugLoader(confirmedGrid.current, "loadConfirmedData");

        const { newData, totalCount } = await loadData(
            { bookingStatus: "Confirmed", gridState: confirmedGridState },
            filterForDates,
        );

        setConfirmedData(newData);
        setTotalConfirmedItemCount(totalCount);

        handleCugLoader(confirmedGrid.current, "loadConfirmedData");
    };

    const init = () => {
        const doIt = async () => {
            await Promise.all([loadPendingData(), loadConfirmedData()]);
        };

        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        doIt();
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);
    const pendingGrid = useRef<HTMLDivElement>(undefined);
    const confirmedGrid = useRef<HTMLDivElement>(undefined);

    const cug2AppContext = useCug2AppContext();

    const { ajaxRequest, ajaxJsonRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [confirmedGridState, setConfirmedGridState] = useState<GridState<keyof CugReservation>>(initialGridState());
    const [pendingGridState, setPendingGridState] = useState<GridState<keyof CugReservation>>(initialGridState());
    const [selectedConfirmedIds, setSelectedConfirmedIds] = useState<Set<string>>(new Set<string>());
    const [confirmedData, setConfirmedData] = useState<CugReservation[]>(undefined);
    const [totalConfirmedItemCount, setTotalConfirmedItemCount] = useState<number>(0);
    const [selectedPendingIds, setSelectedPendingIds] = useState<Set<string>>(new Set<string>());
    const [pendingData, setPendingData] = useState<CugReservation[]>(undefined);
    const [totalPendingItemCount, setTotalPendingItemCount] = useState<number>(0);

    const [filterFlightDateFrom, setFilterFlightDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterFlightDateTo, setFilterFlightDateTo] = useState<dayjs.Dayjs>(undefined);
    const [filterPurchaseDateFrom, setFilterPurchaseDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterPurchaseDateTo, setFilterPurchaseDateTo] = useState<dayjs.Dayjs>(undefined);

    // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
    const [hasSubmittedDateFiltering, setHasSubmittedDateFiltering] = useState<boolean>(false);

    useEffect(() => (confirmedData ? loadConfirmedData() : null), [confirmedGridState]);
    useEffect(() => (pendingData ? loadPendingData() : null), [pendingGridState]);

    useEffect(init, []);

    // EVENT LISTENERS

    const onClearDateFilters = () => {
        loadConfirmedData(false);
        setHasSubmittedDateFiltering(false);
    };

    const onPendingGlobalFilterChange = (e: GlobalFilterChangeEvent) => {
        setPendingGridState({
            ...pendingGridState,
            pageIndex: 0,
            globalFilterExpression: e.detail.filterExpression,
        });
    };

    const onConfirmedGlobalFilterChange = (e: GlobalFilterChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            pageIndex: 0,
            globalFilterExpression: e.detail.filterExpression,
        });
    };

    const onConfirmedFilterByDate = async () => {
        const filterForDates =
            Boolean(filterFlightDateFrom) ||
            Boolean(filterFlightDateTo) ||
            Boolean(filterPurchaseDateFrom) ||
            Boolean(filterPurchaseDateTo);

        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        setHasSubmittedDateFiltering(filterForDates);
        setConfirmedGridState({ ...confirmedGridState, pageIndex: 0 });
    };

    const onPendingPageChange = (e: PageChangeEvent) => {
        setPendingGridState({
            ...pendingGridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onConfirmedPageChange = (e: PageChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onPendingRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedPendingIds(GridHelper.getSelectedIds(event, selectedPendingIds, pendingData) as Set<string>);
    };

    const onConfirmedRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedConfirmedIds(GridHelper.getSelectedIds(event, selectedConfirmedIds, confirmedData) as Set<string>);
    };

    const onPendingSortChange = (e: SortChangeEvent) => {
        setPendingGridState({
            ...pendingGridState,
            orderBy: e.detail.orderBy as keyof CugReservation,
            orderDir: e.detail.orderDir,
        });
    };

    const onConfirmedSortChange = (e: SortChangeEvent) => {
        setConfirmedGridState({
            ...confirmedGridState,
            orderBy: e.detail.orderBy as keyof CugReservation,
            orderDir: e.detail.orderDir,
        });
    };

    // TEMPLATES

    const pendingReservationsGridTemplate = () => {
        const tempClassMap = classMap({
            "min-h-1/4": !pendingData,
            "w-full": !pendingData,
            "relative": !pendingData,
        });

        return html`
            <div ref=${ref(pendingGrid)} class=${tempClassMap}>
                <ac-cug-pending-reservations
                    .canExport=${props.canExport}
                    .data=${pendingData}
                    .gridState=${pendingGridState}
                    .selectedIds=${Array.from(selectedPendingIds.values())}
                    .totalItemCount=${totalPendingItemCount}
                    @onSortChange=${onPendingSortChange}
                    @onRowsSelect=${onPendingRowsSelect}
                    @onPageChange=${onPendingPageChange}
                    @onEditRow=${onEditPendingRow}
                    @onGlobalFilterChange=${onPendingGlobalFilterChange}
                    @delete=${handlePendingReservationDelete}
                ></ac-cug-pending-reservations>
            </div>
        `;
    };

    const confirmedReservationsGridTemplate = () => {
        const tempClassMap = classMap({
            "min-h-1/4": !confirmedData,
            "w-full": !confirmedData,
            "relative": !confirmedData,
        });

        return html`
            <div ref=${ref(confirmedGrid)} class=${tempClassMap}>
                <ac-cug-confirmed-reservations
                    .canExport=${props.canExport}
                    .data=${confirmedData}
                    .filterFlightDateFrom=${filterFlightDateFrom}
                    .filterFlightDateTo=${filterFlightDateTo}
                    .filterPurchaseDateFrom=${filterPurchaseDateFrom}
                    .filterPurchaseDateTo=${filterPurchaseDateTo}
                    .gridState=${confirmedGridState}
                    .url=${getReportUrl()}
                    .selectedIds=${Array.from(selectedConfirmedIds.values())}
                    .totalItemCount=${totalConfirmedItemCount}
                    .setFilterFlightDateFrom=${setFilterFlightDateFrom}
                    .setFilterFlightDateTo=${setFilterFlightDateTo}
                    .setFilterPurchaseDateFrom=${setFilterPurchaseDateFrom}
                    .setFilterPurchaseDateTo=${setFilterPurchaseDateTo}
                    @onSortChange=${onConfirmedSortChange}
                    @onRowsSelect=${onConfirmedRowsSelect}
                    @onPageChange=${onConfirmedPageChange}
                    @onEditRow=${onEditConfirmedRow}
                    @onGlobalFilterChange=${onConfirmedGlobalFilterChange}
                    @onFilterByDate=${onConfirmedFilterByDate}
                    @onClearFilterByDate=${onClearDateFilters}
                ></ac-cug-confirmed-reservations>
            </div>
        `;
    };

    return html`
        <div ref=${ref(root)} class="w-full">
            <div class="cug2b-title-container">
                <i class="js-icon-cug js-cug-finger"></i>
                <div>
                    <h1>${i18next.t("Reservas")}</h1>

                    <h2>${i18next.t("Reservas con pago pendiente / Reservas confirmadas")}</h2>
                </div>
            </div>

            ${pendingReservationsGridTemplate()} ${confirmedReservationsGridTemplate()}
        </div>
    `;
};

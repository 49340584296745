import i18next from "i18next";
import { CLASS_NAMES } from "../../classNames";

export const ajaxErrorMessageCreator = () => {
    const createTitle = (title: string): HTMLHeadingElement => {
        const titleElem = document.createElement("H4") as HTMLHeadingElement;
        titleElem.classList.add(CLASS_NAMES.AlertHeading);
        titleElem.innerHTML = title || i18next.t("Ha ocurrido un error.");

        return titleElem;
    };

    const createListItem = (message: string): HTMLLIElement => {
        const listItemElem = document.createElement("LI") as HTMLLIElement;
        listItemElem.innerHTML = message;

        return listItemElem;
    };

    const createList = (messages: string[]): HTMLUListElement => {
        const listElem = document.createElement("UL") as HTMLUListElement;
        const listItemElems = messages?.length
            ? messages.reduce((list, message) => [...list, createListItem(message)], [])
            : [createListItem("Lo sentimos, pero hubo un error. Por favor, inténtelo de nuevo más tarde.")];

        for (const listItemElem of listItemElems) {
            listElem.appendChild(listItemElem);
        }

        return listElem;
    };

    const createContent = (title?: string, messages?: string[]): HTMLDivElement => {
        const contentElem = document.createElement("DIV") as HTMLDivElement;
        contentElem.classList.add(CLASS_NAMES.ErrorMessage, CLASS_NAMES.Alert);

        const titleElem = createTitle(title);
        contentElem.appendChild(titleElem);

        const listElem = createList(messages);
        contentElem.appendChild(listElem);

        return contentElem;
    };

    const createWrapper = (): HTMLDivElement => {
        const wrapperElem = document.createElement("DIV") as HTMLDivElement;
        wrapperElem.classList.add(CLASS_NAMES.ErrorWrapper, CLASS_NAMES.Condensed);

        return wrapperElem;
    };

    // EXPORTS

    const createAjaxErrorMessage = (title?: string, messages?: string[]) => {
        const wrapperElem = createWrapper();
        const contentElem = createContent(title, messages);

        wrapperElem.appendChild(contentElem);

        return wrapperElem;
    };

    return { createAjaxErrorMessage };
};

import { useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { GIFTCARD_CHARS_REGEX } from "../../shared/commonConstants";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { useMessageLengthModal } from "./useMessageLengthModal";

const MESSAGE_MAX_LENGTH = 140;

export const useGiftcardMessageEditor = () => {
    const showError = () => (message && !GIFTCARD_CHARS_REGEX.test(message)) || isMessageTooLong();

    const isMessageTooLong = () => message.length > MESSAGE_MAX_LENGTH;

    const isMessageMaxed = () => message.length === MESSAGE_MAX_LENGTH;

    const getMessageLength = () => `${message.length}/${MESSAGE_MAX_LENGTH}`;

    const handleMessageKeydown = (e: KeyboardEvent) => {
        const isCtrlKeyPressed = e.ctrlKey;

        if (
            message.length >= MESSAGE_MAX_LENGTH &&
            !isCtrlKeyPressed &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowUp" &&
            e.key !== "ArrowRight" &&
            e.key !== "ArrowDown" &&
            e.key !== "Delete"
        ) {
            e.preventDefault();
            messageLengthModal.open();
            return false;
        }

        return true;
    };

    const handleMessageInput = (e: KeyboardEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setMessage(value.replace("…", "...").replace(/\r?\n/g, "\n"));
    };

    const [message, setMessage] = useState<string>("");

    const messageLengthModal = useMessageLengthModal();

    useEffect(() => {
        if (isMessageTooLong()) {
            setMessage(message.substring(0, MESSAGE_MAX_LENGTH));
            messageLengthModal.open();
        }
    }, [message]);

    const hiddenMessageTemplate = () => html`
        <input name="Message" type="hidden" .value=${message.replace(/[\r\n]+/gm, " ").trim()} />
    `;

    const messageLengthTemplate = () => {
        const tempClassMap = classMap({
            "message-length-counter": true,
            "warning": isMessageMaxed(),
            "error": isMessageTooLong(),
        });

        return html`<div class=${tempClassMap}>${getMessageLength()}</div>`;
    };

    const messageTemplate = () => {
        const tempClassMap = classMap({
            "giftcard-user-message-container": true,
            "invalid": showError(),
        });

        return html`
            <div class="row">
                <div class="col-xs-1">
                    <div class=${tempClassMap}>
                        <div class="giftcard-user-message-label">${i18next.t("Gift-MessagePlaceholder")}</div>
                        <textarea
                            class="giftcard-user-message"
                            .value=${message}
                            @keydown=${handleMessageKeydown}
                            @input=${handleMessageInput}
                        ></textarea>
                        ${hiddenMessageTemplate()} ${messageLengthTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    const messageErrorTemplate = () =>
        showError()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="giftcard-error">${i18next.t("Gift-MessageError")}</div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        html`${messageTemplate()} ${messageErrorTemplate()} ${messageLengthModal.htmlTemplate()}`;

    return {
        isValid: !isMessageTooLong() && (!Boolean(message) || GIFTCARD_CHARS_REGEX.test(message)),
        message,
        clearMessage: () => setMessage(""),
        htmlTemplate,
    };
};

import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";

export interface NoDiscountUrlProps {
    context: FlightPageContext;
}

export const useNoDiscountUrl = (props: NoDiscountUrlProps) => {
    const bannerType = props.context?.bannerState?.bannerVM?.bannerType;
    const selectedOption = props.context?.bannerState?.selectedOption;

    const handleNoDc = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedOption === "None") return;

        props.context.selectDcOption("None", "Unneeded");
    };

    const htmlTemplate = () => {
        const tempClassNames = classNames("no-discount-url-container", {
            get: bannerType === "GetMembershipForMember" || bannerType === "GetMembershipForWA",
        });

        return html`
            <div class=${tempClassNames}>
                <a
                    class=${classNames({ selected: selectedOption === "None" })}
                    data-test-id=${T.FLIGHT_DC_BANNER.NO_DISCOUNT_LINK}
                    @click=${handleNoDc}
                >
                    ${i18next.t("No quiero ahorrar")}
                </a>
            </div>
        `;
    };

    return { htmlTemplate };
};

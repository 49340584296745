import { InputFieldAttributeValidator } from "./customHooks/useForm/InputFieldAttributeValidator";
import { InputFieldAttribute } from "./customHooks/useForm/InputFieldAttribute";
import { toBoolean } from "./common";
import { FULL_EMAIL_REGEX, NO_SPECIAL_CHAR_REGEX, NUMERIC_REGEX, TRAVEL_DOCUMENT_ID_REGEX } from "./commonConstants";
import { InputElement } from "./customHooks/useForm/useForm";

export function commonValidationRules(): InputFieldAttribute[] {
    const formResources = window.formResources;

    const maxLengthRules = window.JetSmart.ValidationRules.filter((r) => r.MaxLength && Number(r.MaxLength > 0));
    const minLengthRules = window.JetSmart.ValidationRules.filter((r) => r.MinLength && Number(r.MinLength >= 0));
    const emailRules = window.JetSmart.ValidationRules.filter((r) => toBoolean(r.IsEmail));
    const numericRules = window.JetSmart.ValidationRules.filter((r) => toBoolean(r.IsNumeric));
    const noSpecCharRules = window.JetSmart.ValidationRules.filter((r) => toBoolean(r.NoSpec));
    const DocNumberRules = window.JetSmart.ValidationRules.filter((r) => toBoolean(r.DocNumber));

    const validators: InputFieldAttributeValidator[] = [];

    const maxLengthAttributes = maxLengthRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_Max_Length_" + r.Name,
                message: formResources.fieldMaximumLengthIs + ` ${r.MaxLength} ` + formResources.characters,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length <= r.MaxLength;

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    const minLengthAttributes = minLengthRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_Min_Length_" + r.Name,
                message: formResources.fieldMinimumLengthIs + ` ${r.MinLength} ` + formResources.characters,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length >= r.MinLength || field.value.length === 0;

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    const emailAttributes = emailRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_Email_Format_" + r.Name,
                message: formResources.emailIsNotValid,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length === 0 || FULL_EMAIL_REGEX.test(field.value);

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    const numericAttributes = numericRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_Numeric_Only_" + r.Name,
                message: formResources.numbersAllowed,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length === 0 || NUMERIC_REGEX.test(field.value);

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    const noSpecCharAttributes = noSpecCharRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_No_Spec_Char_" + r.Name,
                message: formResources.noSpecialCharactersNumbersAllowed,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length === 0 || NO_SPECIAL_CHAR_REGEX.test(field.value);

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    const docNumberAttributes = DocNumberRules.map(
        (r): InputFieldAttributeValidator => ({
            errorMessage: {
                id: "NVTR_Doc_Number" + r.Name,
                message: formResources.invalidFormat,
                scope: "field",
            },

            validate: (field: InputElement) => {
                if (field.dataset.validation === r.Name) {
                    const result = field.value.length === 0 || TRAVEL_DOCUMENT_ID_REGEX.test(field.value);

                    return Promise.resolve(result);
                } else {
                    return Promise.resolve(true);
                }
            },
        }),
    );

    validators.push(...maxLengthAttributes);
    validators.push(...minLengthAttributes);
    validators.push(...emailAttributes);
    validators.push(...numericAttributes);
    validators.push(...noSpecCharAttributes);
    validators.push(...docNumberAttributes);

    return [
        {
            name: "data-validation",
            validators,
        },
    ];
}

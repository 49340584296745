import { useState } from "../../../../shared/haunted/CustomHooks";
import { CheckinPassengerJourneyModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerJourneyModel";
import { CheckinPassengerModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerModel";
import { classMap } from "lit-html/directives/class-map";
import { PassengerSelection } from "../../../../component-models/extras/checkin-type/PassengerSelection";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { getPassengerName, getPaxLabel } from "../../../../shared/common";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { journeyBundleInfoTemplate, journeyRouteTemplate } from "../../../../common-templates/ExtrasCommonTemplates";
import { useBasicCheckbox } from "../../../ui/basic-checkbox/useBasicCheckbox";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const name = "ac-checkin-type-passenger";

export interface Props {
    isValidated: boolean;
    moreThanOnePassenger: boolean;
    parentRoot: HTMLDivElement;
    passenger: CheckinPassengerModel;
    passengerSelections: PassengerSelection[];
    isCheckinCopySelected: boolean;
    setIsCheckinCopySelected: (value: boolean) => void;
    handleCheckinCopy: () => Promise<void>;
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        isValidated: host.isValidated,
        moreThanOnePassenger: host.moreThanOnePassenger,
        parentRoot: host.parentRoot,
        passenger: host.passenger,
        passengerSelections: host.passengerSelections,
        isCheckinCopySelected: host.isCheckinCopySelected,
        setIsCheckinCopySelected: host.setIsCheckinCopySelected,
        handleCheckinCopy: host.handleCheckinCopy,
        setExtrasModel: host.setExtrasModel,
    };

    const showCopyButton = () =>
        (flowContext.isBookingFlow || flowContext.isFarelockRoundTwo) &&
        props.moreThanOnePassenger &&
        props.passenger.PassengerNumber === 0;

    const uncheckCopy = () => {
        props.setIsCheckinCopySelected(false);
    };

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [isCopyDisabled, setIsCopyDisabled] = useState<boolean>(false);

    const [passengerNames] = useReduxState("booking.passengerNames");

    // TEMPLATES

    const paxTitleTemplate = () => html`
        <h3
            class="passenger-title"
            data-test-id=${getTestId(T.EXTRAS_CHECKIN.PASSENGER_TITLE, { p: props.passenger.PassengerNumber })}
        >
            ${i18next.t("V2-PassengerLabel")} ${getPaxLabel(props.passenger.Type)} - ${props.passenger.DisplayedIndex} -
            ${getPassengerName(passengerNames, props.passenger.PassengerNumber)}
        </h3>
    `;

    const bundleHeadersTemplate = () =>
        props.passenger.Journeys.map((journey) => {
            const tempClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.passenger.Journeys.length === 1,
            });

            return html` <div class=${tempClassMap}>${bundleHeaderTemplate(journey)}</div> `;
        });

    const bundleHeaderTemplate = (journey: CheckinPassengerJourneyModel) =>
        journey.IsInBundle
            ? journeyBundleInfoTemplate(
                  journey.BundleColor,
                  journey.BundleImg,
                  T.EXTRAS_CHECKIN.JOURNEY_BUNDLE,
                  props.passenger.PassengerNumber,
                  journey.JourneyIndex,
                  false,
                  bookingContext.isGdsBooking,
              )
            : "";

    const routeHeadersTemplate = () =>
        props.passenger.Journeys.map((journey) => {
            const checkinTypeSelection =
                props.passengerSelections?.find(
                    (ps) =>
                        ps.journeyIndex === journey.JourneyIndex && ps.paxNumber === props.passenger.PassengerNumber,
                )?.checkinTypeSelection || "none";

            const headerClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.passenger.Journeys.length === 1,
                "filled-column": props.passenger.Journeys.length !== 1,
                [CLASS_NAMES.invalid]: props.isValidated && checkinTypeSelection === "none",
            });

            const bodyClassMap = classMap({
                "checkin-form": true,
                "upper-half": true,
                [CLASS_NAMES.invalid]: props.isValidated && checkinTypeSelection === "none",
            });

            return html`
                <div class=${headerClassMap}>
                    <div class=${bodyClassMap}>
                        <div class="inner-box upper-half">
                            <h3
                                class="package-title"
                                data-test-id=${getTestId(T.EXTRAS_CHECKIN.PACKAGE_TITLE, {
                                    p: props.passenger.PassengerNumber,
                                    j: journey.JourneyIndex,
                                })}
                            >
                                ${journeyRouteTemplate(journey)}
                            </h3>
                        </div>
                    </div>
                </div>
            `;
        });

    const journeysTemplate = () =>
        props.passenger.Journeys.map((journey) => {
            const checkinTypeSelection =
                props.passengerSelections?.find(
                    (ps) =>
                        ps.journeyIndex === journey.JourneyIndex && ps.paxNumber === props.passenger.PassengerNumber,
                )?.checkinTypeSelection || "none";

            const tempClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.passenger.Journeys.length === 1,
                "is-past": bookingContext.isCheckinClosedOutbound && journey.JourneyIndex === 0,
                [CLASS_NAMES.invalid]: props.isValidated && checkinTypeSelection === "none",
            });

            return html`
                <div class=${tempClassMap}>
                    <ac-checkin-type-passenger-journey
                        .model=${journey}
                        .passenger=${props.passenger}
                        .checkinTypeSelection=${checkinTypeSelection}
                        .isValidated=${props.isValidated}
                        .setIsCopyDisabled=${setIsCopyDisabled}
                        .uncheckCopy=${uncheckCopy}
                        .setExtrasModel=${props.setExtrasModel}
                    >
                    </ac-checkin-type-passenger-journey>
                </div>
            `;
        });

    const copyCheckinTypeTemplate = (id: string) => {
        return html` <label for=${id} class="checkin-type-copy-label" data-test-id=${T.EXTRAS_CHECKIN.COPY_CHECKBOX}>
            <div class="checkin-type-copy-text">
                <span class="cb-title">${i18next.t("V2-CopyBoardingPasses")}</span>
            </div>
        </label>`;
    };

    const copyCheckbox = useBasicCheckbox({
        isChecked: props.isCheckinCopySelected,
        customWrapperClass: ["mt-4", isCopyDisabled ? "disabled" : ""],
        disabled: isCopyDisabled,
        labelTemplate: copyCheckinTypeTemplate,
        onClick: props.handleCheckinCopy,
    });

    const copyButtonTemplate = () => (showCopyButton() ? html` ${copyCheckbox.htmlTemplate()} ` : "");

    return html`
        <div
            class="outer-box"
            data-test-id=${getTestId(T.EXTRAS_CHECKIN.PASSENGER, { p: props.passenger.PassengerNumber })}
        >
            ${paxTitleTemplate()}
            <div>
                <div class="row hidden-xs">${bundleHeadersTemplate()}</div>
                <div class="row hidden-xs">${routeHeadersTemplate()}</div>
                <div class="row">${journeysTemplate()}</div>
            </div>
            ${copyButtonTemplate()}
        </div>
    `;
};

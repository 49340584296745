import i18next from "i18next";
import { ChileRegion, Region } from "./register";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { html } from "lit-html";
import { ApiRegisterPageViewModel } from "../../component-models/register-page/ApiRegisterPageViewModel";

export class RegisterHelper {
    public static getTermsLink = (model: ApiRegisterPageViewModel, forColombia: boolean) => {
        const link1Text = model.IsBancoEstado
            ? i18next.t("RegisterPageAcceptTerms01be")
            : model.IsForDc
              ? i18next.t("V2DC-AcceptTermsLink02")
              : i18next.t("RegisterPageAcceptTerms02");

        const link1 = html`<a target="_blank" href="${model.TermsUrl}" @click=${(e: MouseEvent) => e.stopPropagation()}
            >${link1Text}</a
        >`;
        const link2 = html`<a
            target="_blank"
            href="${model.PrivacyUrl}"
            @click=${(e: MouseEvent) => e.stopPropagation()}
            >${i18next.t("RegisterPageAcceptTerms03")}</a
        >`;
        const link3 = html`<a
            target="_blank"
            href="https://jetsmart.com/co/es/tyc/datos-personales"
            @click=${(e: MouseEvent) => e.stopPropagation()}
            >${i18next.t("RegisterPageAcceptTerms04")}</a
        >`;

        return forColombia
            ? html`${link1} ${i18next.t("y")} ${link2}`
            : html`${link1}, ${link2} ${i18next.t("y")} ${link3}`;
    };

    public static getChileRegions(apiRegions: Region) {
        return Object.keys(apiRegions)
            .sort((a, b) => (a > b ? 1 : -1))
            .map((key) => {
                return {
                    name: key,
                    comunas: apiRegions[key].sort((a, b) => (a > b ? 1 : -1)),
                };
            });
    }

    public static comunas(region: string, chileRegions: ChileRegion[]) {
        return region ? chileRegions.find((r) => r.name === region)?.comunas || [] : [];
    }
}

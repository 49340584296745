import i18next from "i18next";
import { html } from "haunted";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useDeclinedPaymentModal } from "../itinerary/useDeclinedPaymentModal";

export interface Props {
    isActive: boolean;
    isDeclined: boolean;
    isFlightless: boolean;
    paymentDescription: string;
    paymentId: string;
    pnr: string;
    refreshSeconds: number;
    retrieveUrl: string;
}

export const usePendingPaymentBanner = (props: Props) => {
    const [isPending, setIsPending] = useState<boolean>(false);

    const declinedModal = useDeclinedPaymentModal({ isFlightless: props.isFlightless });

    //HELPERS:

    const init = () => {
        const isDeclined = props.isDeclined;

        setIsPending(!isDeclined);

        if (!isDeclined) {
            window.setTimeout(() => {
                window.location.href = props.retrieveUrl;
            }, props.refreshSeconds * 1000);
        } else {
            declinedModal.open();
        }
    };

    useEffect(() => (props.isActive ? init() : null), [props.isActive]);

    const paymentDescriptionTemplate = () => (isPending ? html` <span>${props.paymentDescription}</span> ` : html``);

    const pendingTemplate = () => (isPending ? html` <div>${i18next.t("Payment-PendingMessage")}</div> ` : html``);

    const htmlTemplate = () =>
        props.isActive && !props.isDeclined
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information">
                              <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                              ${paymentDescriptionTemplate()}&nbsp;${pendingTemplate()}
                          </div>
                      </div>
                  </div>
                  ${declinedModal.htmlTemplate()}
              `
            : declinedModal.htmlTemplate();

    return { htmlTemplate };
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import {
    ItineraryPax,
    PaxFaresAndFees,
    PaxFareOrFee,
    ItineraryJourney,
    ItineraryJourneyPaxSsr,
} from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export interface Props {
    model: ItineraryPageViewModel;
    isMobile: boolean;
}

export const useItineraryTabsDetails = (props: Props) => {
    const accordionContentClassName = "i2-details-accordion-content";
    const scrollbarName = "itinerary-details-scrollbar";

    // HELPERS

    const init = () => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[scrollbarName] = ScrollHelper.addPerfectScrollbar(accordionContentClassName) as any;
    };

    const orderedPassengers = () => {
        const nonInfants = props.model.ItineraryModelV2.Passengers.filter((p) => p.Type !== "INF").sort(
            (a, b) => a.PassengerNumber - b.PassengerNumber,
        );
        const infants = props.model.ItineraryModelV2.Passengers.filter((p) => p.Type === "INF").sort(
            (a, b) => a.RelatedPassengerNumber - b.RelatedPassengerNumber,
        );

        return [...nonInfants, ...infants];
    };

    const updateScrollbar = () => {
        if (window.acPerfectScrollbars[scrollbarName]) {
            window.acPerfectScrollbars[scrollbarName].forEach((scroller) => {
                scroller.update();
            });
        }
    };

    // EVENT HANDLERS

    const handleChangePassenger = (pax: ItineraryPax, paxIndex: number) => {
        if (pax.Type !== "INF") {
            const newIndex = paxIndex === openDetailsAccordion ? undefined : paxIndex;
            setOpenDetailsAccordion(newIndex);
            setOpenBreakdownTab("fares");
        }
    };

    // COMPONENT

    const [openDetailsAccordion, setOpenDetailsAccordion] = useState<number>(0);
    const [openBreakdownTab, setOpenBreakdownTab] = useState<"fares" | "extras">("fares");

    useEffect(updateScrollbar, [openDetailsAccordion, openBreakdownTab]);

    useEffect(init, []);

    // TEMPLATES

    const paxFaresAndFeesTemplate = (pax: ItineraryPax) =>
        pax.FaresAndTaxes?.map((fareGroup) => paxFareAndFeeGroupTemplate(fareGroup, pax.PassengerNumber)) || "";

    const paxFareAndFeeGroupTemplate = (fareGroup: PaxFaresAndFees, paxNumber: number) => html`
        <div class="i2-fare-group">
            <span data-test-id=${getTestId(T.ITINERARY.BUNDLE_NAME_PER_PAX, { p: paxNumber, m: props.isMobile })}>
                ${fareGroup.GroupScreenName}
            </span>
            <span
                data-test-id=${getTestId(T.ITINERARY.BUNDLE_PRICE_PER_PAX, { p: paxNumber, m: props.isMobile })}
                data-test-value=${fareGroup.GroupPrice.toString()}
            >
                ${fareGroup.GroupFormattedPrice}
            </span>
        </div>
        ${paxFareAndFeeItemsTemplate(fareGroup)}
    `;

    const paxFareAndFeeItemsTemplate = (fareGroup: PaxFaresAndFees) =>
        fareGroup.Items?.length > 1 ? fareGroup.Items.map((item) => paxFareAndFeeItemTemplate(item)) : "";

    // FIXME Should not be based on ScreenName
    const paxFareAndFeeItemTemplate = (item: PaxFareOrFee) => html`
        <div class="i2-fare-item">
            ${item.ScreenName?.includes("AAdvantage")
                ? html`
                      <span>
                          ${unsafeHTML(
                              i18next.t("Millas AAdvantage {{-reg}}", {
                                  reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                              }),
                          )}
                      </span>
                  `
                : html`<span>${item.ScreenName || item.ChargeCode}</span>`}
            ${item.Note ? html` <span>${item.Note}</span> ` : html` <span>${item.FormattedPrice}</span> `}
        </div>
    `;

    const paxSsrsTemplate = (pax: ItineraryPax) => {
        if (
            !pax.PerBookingOptionals ||
            !Array.isArray(pax.PerBookingOptionals) ||
            pax.PerBookingOptionals.length === 0
        ) {
            return props.model.ItineraryModelV2.Journeys.map((journey) => paxJourneySsrsTemplate(journey, pax));
        }

        return html`
            <div class="mb-2 flex justify-between">
                <div class="i2-fare-journey"></div>
                <div class="w-full">${pax.PerBookingOptionals.map((ssr) => paxSsrItemTemplate(ssr))}</div>
            </div>

            ${props.model.ItineraryModelV2.Journeys.map((journey) => paxJourneySsrsTemplate(journey, pax))}
        `;
    };

    const paxJourneySsrsTemplate = (journey: ItineraryJourney, pax: ItineraryPax) => {
        const journeyPassenger = journey.JourneyPassengers.find((p) => p.PassengerNumber === pax.PassengerNumber);

        if (!journeyPassenger || !journeyPassenger.Optionals || journeyPassenger.Optionals.length === 0) {
            return "";
        }

        const ssrClassMap = classMap({
            "flex": true,
            "justify-between": true,
            "mt-2": journey.JourneyIndex === INBOUND,
        });

        return html`
            <div class=${ssrClassMap}>
                <div class="i2-fare-journey">
                    ${journey.JourneyIndex === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta")}
                </div>
                <div class="w-full">${journeyPassenger.Optionals.map(paxSsrItemTemplate)}</div>
            </div>
        `;
    };

    const paxSsrItemTemplate = (ssr: ItineraryJourneyPaxSsr) => html`
        <div class="i2-ssr-item">
            <span>${ssr.Quantity} - ${ssr.ScreenName?.includes("AAdvantage")
                    ? unsafeHTML(
                          i18next.t("Millas AAdvantage {{-reg}}", {
                              reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                          }),
                      )
                    : ssr.ScreenName || ssr.ChargeCode}</span>
            ${ssr.Note ? html` <span>${ssr.Note}</span> ` : ""}
            <span>${ssr.FormattedPrice}</span>
        </div>
    `;

    const paxHeaderTemplate = (pax: ItineraryPax, i: number) => {
        const headerClassMap = classMap({
            "i2-details-accordion-header": true,
            "cursordefault": pax.Type === "INF",
            "open": i === openDetailsAccordion,
        });

        return html`
            <div
                class=${headerClassMap}
                data-test-id=${getTestId(T.ITINERARY.DETAILS_ACCORDION_PER_PAX, {
                    p: pax.PassengerNumber,
                    m: props.isMobile,
                })}
                @click=${() => handleChangePassenger(pax, i)}
            >
                <div class="flex flex-col items-start sm:flex-row sm:items-center">
                    <span class="i2-details-pax-header-number">${i18next.t("Pasajero")} ${i + 1}</span>
                    <span
                        class="i2-details-pax-header-name"
                        data-test-id=${getTestId(T.ITINERARY.DETAILS_PAX_NAME, {
                            p: pax.PassengerNumber,
                            m: props.isMobile,
                        })}
                    >
                        ${pax.FirstName} ${pax.LastName}
                    </span>
                </div>
                <div class="i2-details-pax-header-total">
                    <div class="flex flex-col sm:block">
                        <span>${i18next.t("Total")}</span>
                        <span
                            data-test-value=${pax.Type === "INF" ? "0" : pax.Total.toString()}
                            data-test-id=${getTestId(T.ITINERARY.TOTAL_PRICE_PER_PAX, {
                                p: pax.PassengerNumber,
                                m: props.isMobile,
                            })}
                        >
                            ${pax.Type === "INF" ? "$0" : pax.FormattedTotal}
                        </span>
                    </div>
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const paxLeftPaneTemplate = (pax: ItineraryPax) => {
        const paneClassMap = classMap({
            "i2-details-left-pane": true,
            "dotted-right": true,
            "hidden-xs": openBreakdownTab !== "fares",
        });

        return html`
            <div class=${paneClassMap}>
                <div class="hidden-xs i2-details-pane-title">${i18next.t("Viaje")}</div>
                ${paxFaresAndFeesTemplate(pax)}
                <div class="i2-fare-total">
                    <span>${i18next.t("Total")}</span>
                    <span>${i18next.t(pax.FormattedFaresAndTaxesTotal)}</span>
                </div>
            </div>
        `;
    };

    const paxRightPaneTemplate = (pax: ItineraryPax) => {
        const paneClassMap = classMap({
            "i2-details-right-pane": true,
            "hidden-xs": openBreakdownTab !== "extras",
        });

        return html`
            <div class=${paneClassMap}>
                <div class="hidden-xs i2-details-pane-title">${i18next.t("Opcionales")}</div>
                ${paxSsrsTemplate(pax)}
                <div class="flex justify-between">
                    <div class="i2-fare-journey">&nbsp;</div>
                    <div class="i2-ssr-total">
                        <span>${i18next.t("Total")}</span>
                        <span>${pax.FormattedOptionalsTotal || "$0"}</span>
                    </div>
                </div>
            </div>
        `;
    };

    const mobilePaxPaneSwitcher = () => {
        const faresClassMap = classMap({
            "i2-details-pane-title": true,
            "active": openBreakdownTab === "fares",
        });

        const extrasClassMap = classMap({
            "i2-details-pane-title": true,
            "active": openBreakdownTab === "extras",
        });

        return html`
            <div class="hidden-sm-up flex items-center">
                <div class=${faresClassMap} @click=${() => setOpenBreakdownTab("fares")}>${i18next.t("Viaje")}</div>
                <div class=${extrasClassMap} @click=${() => setOpenBreakdownTab("extras")}>
                    ${i18next.t("Opcionales")}
                </div>
            </div>
        `;
    };

    const paxContentTemplate = (pax: ItineraryPax, i: number) => {
        const contentClassMap = classMap({
            [accordionContentClassName]: true,
            last: i === props.model.ItineraryModelV2.Passengers.length - 1,
        });

        const paneClassMap = classMap({
            "flex": true,
            "items-stretch": true,
            "hidden": pax.Type === "INF",
        });

        return html`
            <div class=${contentClassMap}>
                ${mobilePaxPaneSwitcher()}
                <div class=${paneClassMap}>${paxLeftPaneTemplate(pax)} ${paxRightPaneTemplate(pax)}</div>
            </div>
        `;
    };

    const detailsPassengerTemplate = (pax: ItineraryPax, i: number) => html`
        ${paxHeaderTemplate(pax, i)} ${paxContentTemplate(pax, i)}
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money"></i>
                <div>${i18next.t("Detalle de tu reserva")}</div>
            </div>
            ${orderedPassengers().map((pax, i) => detailsPassengerTemplate(pax, i))}
        </div>
    `;

    return { htmlTemplate };
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { ContactPassenger } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { Tab, TabSelectEvent } from "../../dc-components/dc-tabs";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { isDataFullyProvided } from "../../component-helpers/itinerary/ItineraryHelper";
import { useItineraryTabsDetails } from "./useItineraryTabsDetails";
import { useItineraryTabsOverview } from "./useItineraryTabsOverview";
import { useItineraryTabsOptionals } from "./useItineraryTabsOptionals";
import { useItineraryTabsTransactions } from "./useItineraryTabsTransactions";
import { useItineraryTabsModifications } from "./useItineraryTabsModifications";
import { useItineraryPageTabsPassengersEditableForm } from "./useItineraryPageTabsPassengersEditableForm";
import { useItineraryPageTabsPassengersNonEditableForm } from "./useItineraryPageTabsPassengersNonEditableForm";
import { PREFIX_CODES } from "../../shared/commonConstants";
import { useAppContext } from "../../managers/useAppContext";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { itineraryTransactionsAccordionClassName, itineraryTransactionsTabClassName } from "../../shared/ScrollHelper";
import classNames from "classnames";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";

export interface Props {
    model: ItineraryPageViewModel;
    cancelledJourneyIndices: number[];
    togglePrintingTransaction: () => void;
}

// DEVNOTE Array version is needed for typeof tests.
export const tabHeads = ["Overview", "Details", "Transactions", "Modify", "Passengers", "Optionals"];
// DEVNOTE TabHead  type is a string literal
type TabHead = (typeof tabHeads)[number];

export const useItineraryTabs = (props: Props) => {
    // HELPERS

    const selectedItineraryTab = (): TabHead => {
        if (props.model.IsGdsOnHold) {
            return "Transactions";
        }

        const urlParams = new URLSearchParams(window.location.search);
        const paxTabUrlParam = urlParams.get("tab");

        return tabHeads.includes(paxTabUrlParam) ? (paxTabUrlParam as TabHead) : undefined;
    };

    const getInitialContactPassengers = () =>
        props.model.ContactPassengers.map(
            (pax): ContactPassenger =>
                pax.PaxAddress
                    ? pax
                    : {
                          Name: pax.Name,
                          PaxIndex: pax.PaxIndex,
                          PaxAddress: {
                              Address: "",
                              City: "",
                              Country: "",
                              Email: "",
                              PhoneNumber: "",
                              PhonePrefix: PREFIX_CODES.get(appContext.Culture),
                          },
                      },
        );

    const getInitialSelectedTab = () =>
        flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid
            ? "Transactions"
            : selectedItineraryTab() !== undefined
              ? selectedItineraryTab()
              : "Overview";
    // EVENT HANDLERS

    const handleAccordionOpen = (tabName: string) =>
        tabName === openMobileAccordion
            ? setOpenMobileAccordion(undefined)
            : setOpenMobileAccordion(tabName as TabHead);

    const handleTabSelect = (e: TabSelectEvent) => {
        setSelectedTab(e.detail.name as TabHead);
    };

    // COMPONENT

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [openMobileAccordion, setOpenMobileAccordion] = useState<TabHead>(undefined);
    const [isPassengerDataProvided, setIsPassengerDataProvided] = useState<boolean>(false);
    const [contactPassengers, setContactPassengers] = useState<ContactPassenger[]>(getInitialContactPassengers());
    const [selectedTab, setSelectedTab] = useState<TabHead>(getInitialSelectedTab());

    const mobileDetails = useItineraryTabsDetails({
        model: props.model,
        isMobile: true,
    });

    const details = useItineraryTabsDetails({
        model: props.model,
        isMobile: false,
    });

    const overview = useItineraryTabsOverview({
        model: props.model,
        cancelledJourneyIndices: props.cancelledJourneyIndices,
    });

    const optionals = useItineraryTabsOptionals({
        model: props.model,
    });

    const transactions = useItineraryTabsTransactions({
        model: props.model,
        togglePrintingTransaction: props.togglePrintingTransaction,
    });

    const modifications = useItineraryTabsModifications({
        model: props.model,
    });

    const editableForm = useItineraryPageTabsPassengersEditableForm({
        model: props.model,
        contactPassengers,
        isDataProvided: isPassengerDataProvided,
        setContactPassengers,
    });

    // DEVNOTE: This is needed because otherwise the same form checkbox with
    // the same guid would be in the DOM twice.
    const mobileEditableForm = useItineraryPageTabsPassengersEditableForm({
        model: props.model,
        contactPassengers,
        isDataProvided: isPassengerDataProvided,
        setContactPassengers,
    });

    const nonEditableForm = useItineraryPageTabsPassengersNonEditableForm({
        model: props.model,
        contactPassengers,
        setIsDataProvided: setIsPassengerDataProvided,
    });

    useEffect(() => {
        setIsPassengerDataProvided(isDataFullyProvided(props.model.ContactPassengers));

        setOpenMobileAccordion(
            flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid
                ? "Transactions"
                : selectedItineraryTab() !== undefined
                  ? selectedItineraryTab()
                  : "Overview",
        );
    }, []);

    // TEMPLATES

    const mobileAccordionItemTemplate = (tab: Tab, i: number) => {
        const accordionItemClassMap = classNames("i2-accordion-header no-print", {
            first: i === 0,
            last: i === tabs(true).length - 1,
            open: tab.name === openMobileAccordion,
            highlighted: tab.name === "Passengers" && !isPassengerDataProvided,
        });

        return html`
            <div
                class=${accordionItemClassMap}
                data-test-id=${getTestId(T.ITINERARY.TAB, { c: tab.name, m: true })}
                @click=${() => handleAccordionOpen(tab.name)}
            >
                <span>
                    ${tab.label}
                    ${tab.name === "Passengers" && !isPassengerDataProvided
                        ? html`
                              <i
                                  class="js-icon-refund js-refund-rw-highlight-icon-mobile"
                                  data-test-id=${getTestId(T.ITINERARY.UNFILLED_FORM_HIGHLIGHT_ICON, { m: true })}
                              ></i>
                          `
                        : ""}
                </span>
                <i class="js-icon js-circle-chevron-right"></i>
            </div>
            <div class="i2-accordion-content ${tab.classToScrollTo ? tab.classToScrollTo : ""}">
                ${(tab.template as Function)()}
            </div>
        `;
    };

    const mobileAccordionTemplate = () => tabs(true).map(mobileAccordionItemTemplate);

    const externallyMadeBookingInfoTemplate = () =>
        props.model.IsGds || props.model.IsDigitalApi
            ? html`
                  <div class="i2-gds-optionals-info no-print">
                      ${i18next.t(
                          "Si quieres revisar el listado de todos los opcionales (equipaje, asiento, entre otros) incluidos en tu reserva, haz click en “Opcionales”.",
                      )}
                  </div>
              `
            : "";

    const editableFormTemplate = (isMobile: boolean) =>
        isMobile ? mobileEditableForm.htmlTemplate() : editableForm.htmlTemplate();

    const tabs = (isMobile?: boolean): Tab[] =>
        flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid
            ? [
                  {
                      classToScrollTo: isMobile
                          ? itineraryTransactionsAccordionClassName
                          : itineraryTransactionsTabClassName,
                      name: "Transactions",
                      label: i18next.t("Transacciones"),
                      template: transactions.htmlTemplate,
                  },
              ]
            : [
                  {
                      name: "Overview",
                      label: i18next.t("Tu itinerario"),
                      template: overview.htmlTemplate,
                  },
                  props.model.IsGds || props.model.IsDigitalApi
                      ? {
                            name: "Optionals",
                            label: i18next.t("Opcionales"),
                            template: optionals.htmlTemplate,
                        }
                      : {
                            name: "Details",
                            label: i18next.t("Detalle de tu reserva"),
                            template: isMobile ? mobileDetails.htmlTemplate : details.htmlTemplate,
                        },
                  {
                      classToScrollTo: isMobile
                          ? itineraryTransactionsAccordionClassName
                          : itineraryTransactionsTabClassName,
                      name: "Transactions",
                      label: i18next.t("Transacciones"),
                      withWarning: props.model.IsGdsOnHold && bookingContext.isGdsBooking,
                      template: transactions.htmlTemplate,
                  },
                  {
                      name: "Modify",
                      label: i18next.t("Modifica tu reserva"),
                      template: modifications.htmlTemplate,
                  },
                  appContext.isFeatureSwitchActive("ProConsumidor")
                      ? {
                            name: "Passengers",
                            label: i18next.t("Pasajeros"),
                            isHighlighted: !isPassengerDataProvided,
                            template: () =>
                                isPassengerDataProvided
                                    ? nonEditableForm.htmlTemplate()
                                    : editableFormTemplate(isMobile),
                        }
                      : undefined,
              ].filter((tab) => tab);

    const htmlTemplate = () => {
        const tabClassMap = classNames("i2-itinerary-section i2-tabs", {
            "divide-four": tabs().length === 4,
            "divide-five": tabs().length === 5,
            "divide-six": tabs().length === 6,
        });

        return html`
            ${externallyMadeBookingInfoTemplate()}
            <div class=${tabClassMap}>
                <dc-tabs
                    class="hidden-xs"
                    data-test-id="itinerary-data-tabs"
                    .data=${tabs()}
                    .selectedTab=${selectedTab}
                    @tabSelect=${handleTabSelect}
                >
                </dc-tabs>
                <div class="visible-xs" data-test-id="itinerary-data-mobile-accordion">
                    ${mobileAccordionTemplate()}
                </div>
            </div>
        `;
    };

    return { htmlTemplate, setOpenMobileAccordion, setSelectedTab };
};

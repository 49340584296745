import { html } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";

export const name = "ac-cug-register-bar";

export interface Properties {
    currentPage: number;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        currentPage: host.currentPage,
    };

    const pageTitles = [i18next.t("Inscripción de Empresas y Agencias"), i18next.t("Usuario"), i18next.t("Resumen")];

    const stepBackgroundTemplate = (index: number) => {
        const bgClassMap = classMap({
            "aux-bg": true,
            "darker": props.currentPage > index + 1,
        });

        return props.currentPage > index ? html` <div class=${bgClassMap}></div> ` : "";
    };

    const tickTemplate = (index: number) => {
        return props.currentPage > index ? html` <div class="cug2b-register-tick"></div> ` : "";
    };

    const stepTemplate = (index: number) => {
        const tempClassMap = classMap({
            "cug2b-register-bar-section": true,
            "current": props.currentPage === index,
            "past": props.currentPage > index,
        });

        return html`
            <div class=${tempClassMap} data-test-id=${getTestId(T.CUG2_REGISTER.BAR_SECTION, { i: index })}>
                ${stepBackgroundTemplate(index)}
                <div class="cug2b-register-bar-number">${index + 1}</div>
                <div class="hidden-xs">${pageTitles[index]}</div>
                ${tickTemplate(index)}
            </div>
        `;
    };

    return html`
        <div class="cug2b-register-bar" data-test-id=${T.CUG2_REGISTER.BAR}>
            ${[...Array(3)].map((_, i) => stepTemplate(i))}
        </div>
        <div class="hidden-sm-up cug2b-breadcrumb-title">${pageTitles[props.currentPage]}</div>
    `;
};

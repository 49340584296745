import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ARGENTINA_COUNTRY_CODE } from "../../shared/commonConstants";
import { sanitizeCuit } from "../../shared/common";
import { Payer } from "./usePayer";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAppContext } from "../../managers/useAppContext";

export interface Props {
    isValidated: boolean;
    payer: Payer;
}

export const useCuitInBilling = (props: Props) => {
    const appContext = useAppContext();

    const [payerData, setPayerData] = useReduxState("payment.payer");

    const handleCuitCountrySelectorChange = (e: MouseEvent) => {
        const country = (e.target as HTMLSelectElement).value;

        setPayerData({ ...payerData, CuitCountry: country });
    };

    const handleCuitInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        const value = sanitizeCuit(target.value);

        setPayerData({ ...payerData, Cuit: value });
    };

    const countryOptionsTemplate = (selectedCountry: string) =>
        appContext.Countries.map(
            (country) => html`
                <option value=${country.Value} ?selected=${country.Value === selectedCountry}>${country.Text}</option>
            `,
        );

    const cuitCountryTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
            <label for="cuitSelector" class="mdl-textfield__label">${i18next.t("CUIT-CountryLabel")} </label>
            <select class="mdl-textfield__input js-input js-select" @change=${handleCuitCountrySelectorChange}>
                ${countryOptionsTemplate(payerData?.CuitCountry)}
            </select>
        </div>
    `;

    const cuitErrorTemplate = () =>
        props.isValidated && !props.payer.IsCuitValid
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-PleaseFillAllFields")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const cuitLengthErrorTemplate = () =>
        props.isValidated && !props.payer.IsCuitLengthValid
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-CuitLengthError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const cuitNumberTemplate = () => {
        const tempClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "invalid": props.isValidated && (!props.payer.IsCuitValid || !props.payer.IsCuitLengthValid),
        });

        return payerData?.CuitCountry === ARGENTINA_COUNTRY_CODE
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                          <label class="mdl-textfield__label">CUIT/CUIL</label>
                          <input
                              class=${tempClassMap}
                              data-required
                              data-test-id=${T.PAYMENT.CUIT_INPUT}
                              .value=${payerData.Cuit}
                              @input=${handleCuitInput}
                          />
                      </div>
                      ${cuitErrorTemplate()} ${cuitLengthErrorTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-md-1-2">${cuitCountryTemplate()}</div>
            ${cuitNumberTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import { BagType } from "../../../components/spa/baggage/useBaggagePage";
import { TravelDocumentType } from "../../checkin/TravelDocument";

interface ChangeDocTypeEventDetail {
    docType: TravelDocumentType;
    paxIndex: number;
}

export class ChangeDocTypeEvent extends CustomEvent<ChangeDocTypeEventDetail> {
    constructor(detail: ChangeDocTypeEventDetail) {
        super("changeDocType", { detail, bubbles: true });
    }
}

interface ChangeDocNumberEventDetail {
    docNumber: string;
    paxIndex: number;
}

export class ChangeDocNumberEvent extends CustomEvent<ChangeDocNumberEventDetail> {
    constructor(detail: ChangeDocNumberEventDetail) {
        super("changeDocNumber", { detail, bubbles: true });
    }
}

interface AddBaggageInsuranceEventDetail {
    paxIndex: number;
    bagType: BagType;
}

export class AddBaggageInsuranceEvent extends CustomEvent<AddBaggageInsuranceEventDetail> {
    constructor(detail: AddBaggageInsuranceEventDetail) {
        super("addBaggageInsurance", { detail, bubbles: true });
    }
}

interface RemoveBaggageInsuranceEventDetail {
    paxIndex: number;
    bagType: BagType;
}

export class RemoveBaggageInsuranceEvent extends CustomEvent<RemoveBaggageInsuranceEventDetail> {
    constructor(detail: RemoveBaggageInsuranceEventDetail) {
        super("removeBaggageInsurance", { detail, bubbles: true });
    }
}

interface ToggleBaggageInsuranceOpenForPaxEventDetail {
    paxIndex: number;
}

export class ToggleBaggageInsuranceOpenForPaxEvent extends CustomEvent<ToggleBaggageInsuranceOpenForPaxEventDetail> {
    constructor(detail: ToggleBaggageInsuranceOpenForPaxEventDetail) {
        super("toggleBaggageInsuranceOpenForPax", { detail, bubbles: true });
    }
}

export class TogglePaxInsuranceOpenEvent extends CustomEvent<{}> {
    constructor() {
        super("togglePaxInsuranceOpen", { bubbles: true });
    }
}

export class ToggleBaggageInsuranceOpenEvent extends CustomEvent<{}> {
    constructor() {
        super("toggleBaggageInsuranceOpen", { bubbles: true });
    }
}

import { TealiumInfo } from "../../shared/TealiumInfo";
import { COOKIE_NAMES } from "../../shared/commonConstants";
import { getCookie, deleteCookie } from "../../shared/cookieHandling";
import { useAppContext } from "../useAppContext";
import { useCug2AppContext } from "../useCug2AppContext";
import { useTealiumManager } from "./useTealiumManager";

export const useAuthenticationTealiumManager = () => {
    const commonObject = () => ({
        culture: appContext.Culture || cug2AppContext.Culture || "n/a",
        device: tealiumManager.getTealiumDeviceType(),
    });

    const logCugRegister = (loginType: string) =>
        tealiumManager.log({
            EventName: "signup_portal",
            EventParams: {
                ...commonObject(),
                type: loginType,
            },
        });

    const logUserLogout = () =>
        tealiumManager.log({
            EventName: "user_logout",
            EventParams: {},
        });

    const logRegister = (ibe: string, org: string, role: string, programs: string, level: string, category: string) => {
        tealiumManager.log({
            EventName: "user_register",
            EventParams: {
                ibe_release: ibe,
                organization: org,
                user_be_level: category,
                user_dc_level: level,
                user_programs: programs,
                user_role: role,
            },
        });
    };

    const logUserLoginRegister = () => {
        if (
            !appContext.isFeatureSwitchActive("TealiumClientSide") &&
            !cug2AppContext.isFeatureSwitchActive("TealiumClientSide")
        ) {
            return;
        }

        const tealiumInfoRaw = getCookie(COOKIE_NAMES.Tealium);

        if (!tealiumInfoRaw) return;

        try {
            const decodedTealiumInfo = JSON.parse(decode(atob(tealiumInfoRaw))) as TealiumInfo;

            tealiumManager.log({
                EventName: decodedTealiumInfo.Action.toLowerCase() === "login" ? "user_login" : "user_register",
                EventParams: {
                    ibe_release: decodedTealiumInfo.IBE_Release,
                    organization: decodedTealiumInfo.Organization,
                    user_be_level: decodedTealiumInfo.User_BE_Level,
                    user_dc_level: decodedTealiumInfo.User_DC_Level,
                    user_programs: decodedTealiumInfo.User_Programs.join("|"),
                    user_role: decodedTealiumInfo.User_Role,
                },
            });

            deleteCookie(COOKIE_NAMES.Tealium);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Unable to parse tealium info cookie.");
        }
    };

    const decode = (input: string) => input.replace(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec));

    const appContext = useAppContext();
    const cug2AppContext = useCug2AppContext();
    const tealiumManager = useTealiumManager();

    return {
        logCugRegister,
        logRegister,
        logUserLoginRegister,
        logUserLogout,
        logValidationError: tealiumManager.logValidationError,
    };
};

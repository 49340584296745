import { CLASS_NAMES } from "./classNames";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Content } from "pdfmake/interfaces";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export default class PdfDownloader {
    public static async downloadDiv(container: HTMLElement, fileName: string): Promise<void> {
        const allContent: Content[] = [];

        const items: HTMLElement[] = Array.from(
            container.querySelectorAll(
                `.${CLASS_NAMES.downloadTitle}, .${CLASS_NAMES.downloadItemKey}, .${CLASS_NAMES.dowloadItemValue}`,
            ),
        );

        items.forEach((item, i) => {
            if (this.isTitle(item)) {
                if (i !== 0) {
                    allContent.push(this.getSpacerContent());
                }
                allContent.push(this.getTitleContent(item));
                allContent.push(this.getSpacerContent());
            } else if (this.isContent(item, items[i + 1])) {
                allContent.push(this.getContentContent(item, items[i + 1]));
            }
        });

        pdfMake
            .createPdf({
                content: allContent.filter((e) => e),
            })
            .download(`${fileName}.pdf`);
    }

    private static isTitle(item: HTMLElement): boolean {
        return item.classList.contains(CLASS_NAMES.downloadTitle);
    }

    private static isContent(key: HTMLElement, value: HTMLElement): boolean {
        return (
            key.classList.contains(CLASS_NAMES.downloadItemKey) &&
            value.classList.contains(CLASS_NAMES.dowloadItemValue)
        );
    }

    private static getSpacerContent(): Content {
        return { text: " ", lineHeight: 2 };
    }

    private static getTitleContent(item: HTMLElement): Content {
        return {
            text: item.textContent.trim(),
            bold: true,
            fontSize: 20,
            lineHeight: 1,
        };
    }

    private static getContentContent(key: HTMLElement, value: HTMLElement): Content {
        return {
            text: [{ text: key.textContent.trim(), bold: true }, { text: ` ${value.textContent.trim()}` }],
            bold: false,
            fontSize: 16,
            lineHeight: 1,
        };
    }
}

import { CustomizedBundle } from "../../component-models/flight/BundleOffersV2Model";
import {
    AIRPORT_CHECKIN_CODES,
    ForcedFlexiFeePlaceholderCode,
    ALWAYS_AVAILABLE_SSR_CODE,
    StandardSeatsInBundlePlaceholder,
    AllSeatsInBundlePlaceholder,
    PRIORITY_BOARDING_CODES,
} from "../../shared/commonConstants";

export const bundleSsrHelper = () => {
    const canGetStandardSeatsInBundle = (bundle: CustomizedBundle, ssrCodes: string[]) =>
        ssrCodes.includes(StandardSeatsInBundlePlaceholder) && bundle.ContainsFreeRegularSeat;

    const canGetAllSeatsInBundle = (bundle: CustomizedBundle, ssrCodes: string[]) =>
        ssrCodes.includes(AllSeatsInBundlePlaceholder) && bundle.ContainsFreeAnySeat;

    const isSsrFreeAirportCheckin = (isPeruCompraFreeCheckinAdded: boolean, codes: string[]) =>
        isPeruCompraFreeCheckinAdded && hasCommonElement(codes, AIRPORT_CHECKIN_CODES);

    // EXPORTS

    const hasCommonElement = (codes1: string[], codes2: string[]) => codes1.some((code) => codes2.includes(code));

    const isSsrFreePriorityBoarding = (bancoEstadoCategory: number, codes: string[]) =>
        [1, 2, 5, 6].indexOf(bancoEstadoCategory) > -1 && hasCommonElement(codes, PRIORITY_BOARDING_CODES);

    const isSsrInBundle = (
        bundle: CustomizedBundle,
        ssrCodes: string[],
        bancoEstadoCategory: number,
        isPeruCompraFreeCheckinAdded: boolean,
    ) => {
        if (!bundle || !ssrCodes) return false;

        if (Array.isArray(bundle.BundleSSRs) && hasCommonElement(bundle.BundleSSRs, ssrCodes)) return true;

        if (ssrCodes.includes(ForcedFlexiFeePlaceholderCode) || ssrCodes.includes(ALWAYS_AVAILABLE_SSR_CODE)) {
            return true;
        }

        return (
            isSsrFreePriorityBoarding(bancoEstadoCategory, ssrCodes) ||
            isSsrFreeAirportCheckin(isPeruCompraFreeCheckinAdded, ssrCodes) ||
            canGetStandardSeatsInBundle(bundle, ssrCodes) ||
            canGetAllSeatsInBundle(bundle, ssrCodes)
        );
    };

    return { hasCommonElement, isSsrInBundle, isSsrFreePriorityBoarding };
};

import PdfDownloader from "../../shared/pdf-downloader";

export function useMediaObtainer(getArea: () => HTMLDivElement, filename: string) {
    const print = () => {
        window.print();
    };

    const download = async () => {
        if (!getArea() || !filename) {
            return;
        }

        await PdfDownloader.downloadDiv(getArea(), filename);
    };

    return {
        download,
        print,
    };
}

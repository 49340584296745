import { ApiExtrasModel } from "./../component-models/extras/ApiExtrasModel";
import { PetInCabinJourneyModel } from "./../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { PetInCabinModel } from "../component-models/extras/pet-in-cabin/PetInCabinModel";

export function mapToPetInCabinModel(apiModel: ApiExtrasModel): PetInCabinModel {
    const journeys: PetInCabinJourneyModel[] = apiModel.PetInCabinJourneys.map((journey) => ({
        DepartureStationName: journey.DepartureStationName,
        DestinationStationName: journey.DestinationStationName,
        JourneyIndex: journey.JourneyIndex,
        MaxCount: journey.MaxCount,
        Passengers: journey.Passengers.map((passenger) => ({
            Amount: passenger.Amount,
            FormattedAmount: passenger.FormattedAmount,
            IsDisabled: passenger.IsDisabled,
            IsSold: passenger.IsSold,
            PassengerNumber: passenger.PassengerNumber,
            SsrAddKey: passenger.SsrAddKey,
            SsrDeleteKey: passenger.SsrDeleteKey,
            Type: passenger.Type,
        })),
    }));

    return {
        Journeys: journeys,
    };
}

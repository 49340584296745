import i18next from "i18next";
import { html } from "haunted";
import { ROUTES } from "../../shared/apiRoutes";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { ScrollHelper } from "../../shared/ScrollHelper";
import PerfectScrollbar from "perfect-scrollbar";
import { ApiItineraryPayment } from "../../component-models/itinerary/ApiItineraryPayment";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { maskCurrenciesForDisplay } from "../../shared/common";
import { useTransactionDetailsModal } from "./useTransactionDetailsModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";
import { spaSectionOrderFactory } from "../spa/spaSectionOrderFactory";

export interface Props {
    model: ItineraryPageViewModel;
    togglePrintingTransaction: () => void;
}

export const useItineraryTabsTransactions = (props: Props) => {
    // HELPERS

    const visiblePaymentButtonType = (): "None" | "Pay" | "PayFarelockFull" => {
        // FIXME The order is important, which should not be

        if (props.model.IsGdsOnHold) {
            return "None";
        }

        // Farelock paid but not full amount
        if (flowContext.isFarelockRoundTwo && !props.model.ItineraryModelV2.IsTotalPending) {
            return "PayFarelockFull";
        }

        if (props.model.IsCugOnHold || props.model.ItineraryModelV2.IsLastPaymentDeclined) {
            return "Pay";
        }

        if (props.model.ItineraryModelV2.IsTotalPending) {
            return "None";
        }

        if (props.model.HasBalanceDue) {
            return "Pay";
        }

        // Failed to pay farelock
        if (props.model.IsFarelockAmountNotFullyPaid) {
            return "Pay";
        }

        return "None";
    };

    const addScrollBar = () => {
        const x = ScrollHelper.getWindowWidth();
        if (x < 768) {
            const scrollBar = ScrollHelper.addPerfectScrollbar("i2-transaction-container", {
                wheelPropagation: false,
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: false,
                useBothWheelAxes: true,
            })[0];
            setScrollbar(scrollBar);
        }
    };

    const [openedTransactionDetailsIndex, setOpenedTransactionDetailsIndex] = useState<number>(-1);

    const modal = useTransactionDetailsModal({
        farelockExpiry: props.model.FarelockExpiry,
        filename: "transaction-details",
        paymentResult:
            openedTransactionDetailsIndex >= 0
                ? props.model.ItineraryPayments[openedTransactionDetailsIndex].PaymentResult
                : undefined,
        tealiumProperties: props.model.TealiumProperties,
        showTransactionBox: props.model.ItineraryModelV2.ShowTransactionBox,
        onClose: () => {
            props.togglePrintingTransaction();
            setOpenedTransactionDetailsIndex(-1);
        },
    });

    // EVENT HANDLERS

    const handlePayClick = () => {
        window.location.href = ROUTES.PagePayment;
    };

    const handlePayFarelockFullClick = () => {
        const order = getSpaSectionOrder({
            flowType: "FarelockRoundTwo",
            isStaff: userContext.isStaff,
        });
        const firstPageIndex = Math.min(...order.map((x) => x.index));

        window.location.href = `/${order.find((o) => o.index === firstPageIndex).section.uri}`;
    };

    const handleClickOnTransaction = () => window.setTimeout(scrollbar?.update, 200);

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();
    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const [userContext] = useReduxState("userContext");

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);

    useEffect(addScrollBar, []);

    // TEMPLATES
    const transactionTemplate = (payment: ApiItineraryPayment, index: number) => html`
        <tr>
            <td><img src=${payment.ImgSource} /></td>
            <td>${unsafeHTML(payment.ExpiryHtml)}</td>
            <td>
                ${userContext.peruCompra.isAdmin ||
                userContext.peruCompra.isMember ||
                bookingContext.isPeruCompraBooking
                    ? i18next.t("Cuenta Perú Compras")
                    : payment.PaymentMethodInfo}
            </td>
            <td>
                ${formatNumber({
                    amount: payment.UnformattedQuotedAmount,
                    currency: payment.CurrencyCode,
                    leadingSign: true,
                })}
                ${maskCurrenciesForDisplay(payment.CurrencyCode)}
            </td>
            <td>${unsafeHTML(payment.InstallmentsHtml)}</td>
            <td>
                <a
                    class="cursor-pointer"
                    @click=${() => {
                        props.togglePrintingTransaction();
                        setOpenedTransactionDetailsIndex(index);
                        modal.open();
                    }}
                >
                    ${i18next.t("Ver detalle")}
                </a>
            </td>
            <td><span>${payment.Status}</span></td>
        </tr>
    `;

    const payButtonTemplate = () =>
        visiblePaymentButtonType() === "Pay"
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayClick} class="rounded-primary-btn">${i18next.t("Pagar")}</a>
                  </div>
              `
            : "";

    const payFarelockFullAmountButtonTemplate = () =>
        visiblePaymentButtonType() === "PayFarelockFull"
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayFarelockFullClick} class="rounded-primary-btn"
                          >${i18next.t("Pagar monto total")}</a
                      >
                  </div>
              `
            : "";

    const gdsOnHoldWarningMessageTemplate = () =>
        props.model.IsGdsOnHold && bookingContext.isGdsBooking
            ? html`
                  <div
                      class="mb-3.5 mt-7 w-full bg-brand-primary px-5 py-6 font-body text-sm text-white sm:mb-0 sm:rounded-xl sm:text-base"
                  >
                      <div>
                          <span class="font-bold">${i18next.t("Atención")}:</span>
                          ${unsafeHTML(
                              i18next.t("gds-itinerary-warning-line-one {{-spanStart}} {{-spanEnd}}", {
                                  spanStart: "<span class='underline'>",
                                  spanEnd: "</span>",
                              }),
                          )}
                      </div>
                      <div class="mt-4">${i18next.t("gds-itinerary-warning-line-two")}</div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money-list"></i>
                <div>${i18next.t("Transacciones")}</div>
            </div>
            <div class="i2-transaction-container">
                <table class="i2-transactions-table" @click=${handleClickOnTransaction}>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>${i18next.t("Fecha")}</th>
                            <th>${i18next.t("Medio de pago")}</th>
                            <th>${i18next.t("Monto")}</th>
                            <th>${i18next.t("Nr. Cuotas")}</th>
                            <th>${i18next.t("Detalle")}</th>
                            <th>${i18next.t("Estado")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${props.model.ItineraryPayments
                            ? props.model.ItineraryPayments.map((payment, index) => transactionTemplate(payment, index))
                            : ""}
                    </tbody>
                </table>
            </div>
            ${gdsOnHoldWarningMessageTemplate()} ${payButtonTemplate()} ${payFarelockFullAmountButtonTemplate()}
        </div>
        ${modal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

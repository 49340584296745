import { commonDebug } from "../../bootstrap";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useRef, useEffect as hauntedUseEffect } from "haunted";
import {
    convertLoginResultToUserContext,
    getAntiForgeryTokenFromHtml,
    getAntiforgerySegment,
    showLoader,
    updateMdl,
} from "../../shared/common";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { CLASS_NAMES } from "../../shared/classNames";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import PerfectScrollbar from "perfect-scrollbar";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { classMap } from "lit-html/directives/class-map";
import { FULL_EMAIL_REGEX, NAME_INPUT_MAX_LENGTH, OrgType, WEBANONYMOUS } from "../../shared/commonConstants";
import LoginResult from "../../component-models/LoginResult";
import { TestIdDictionary } from "../../testing-helpers/TestIdHelper";
import { ApiRefundWidgetViewModel } from "../../component-models/itinerary/ApiRefundWidgetViewModel";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { Validation } from "../../validator/Validation";
import { validatePassword } from "../../validator/validation-helper";
import { useFluentValidator } from "../../validator/FluentValidator";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { useRefundBankInfoModal } from "./useRefundBankInfoModal";
import { ApiRefundBankInfoViewModel } from "../../component-models/itinerary/ApiRefundBankInfoViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";

export type RefundWidgetType = "register" | "login";

export interface Props {
    antiForgeryToken: string;
    isFlightDeparted: boolean;
    model: ApiRefundWidgetViewModel;
    refundBankInfoModel: ApiRefundBankInfoViewModel;
}

interface RefundWidgetRegisterVM {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    termsAccepted: boolean;
}

type FieldNames = keyof RefundWidgetRegisterVM;

const scrollerClassName = "rw-content";

export const useRefundWidget = (props: Props) => {
    const DEFAULT_REGISTER_VM: RefundWidgetRegisterVM = {
        firstName: props.model.ContactFirstName,
        lastName: props.model.ContactLastName,
        email: props.model.ContactEmail,
        password: "",
        termsAccepted: false,
    };

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        addScrollBar();

        const handler = triggers.itinerary.closeRefundModal.subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                setLoginHappened(true);
            } else {
                setIsOpen(false);
                setIsVisible(true);
            }
        });

        return () => handler.unsubscribe();
    };

    const addScrollBar = () => {
        if (scrollbar) {
            scrollbar.destroy();
        }

        const x = getWindowWidth();
        if (x < 768) {
            const tempScrollbar = ScrollHelper.addPerfectScrollbar(scrollerClassName)[0];
            setScrollbar(tempScrollbar);
        } else {
            setScrollbar(undefined);
        }
    };

    const scrollToTop = () => {
        window.setTimeout(() => {
            const scroller = DomCrawlingHelper.getArrayOfClass(document.body, scrollerClassName)[0];
            if (scrollbar && scroller) {
                scroller.scrollTop = 0;
                scrollbar.update();
            }
        }, 250);
    };

    const register = async () => {
        const loader = showLoader({ name: "rw-content-container" });
        removeAjaxErrorMessage({
            container: rootElement.current,
        });

        const bodyToPost = {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
            firstname: registerVm.firstName,
            lastname: registerVm.lastName,
            email: registerVm.email,
            pwd: registerVm.password,
            pwdconfirm: registerVm.password ?? "",
            username: registerVm.email ?? "",
        };

        try {
            const result = await ajaxJsonRequest<LoginResult>({
                loader,
                container: rootElement.current,
                body: bodyToPost,
                url: `${ROUTES.RegisterInRefundWidget}`,
                method: "POST",
            });

            if (result?.statusCode === 200) {
                if (result.data?.IsLoggedIn) {
                    setIsOpen(false);
                    setIsVisible(true);
                    setLoginHappened(true);
                    setIsJustRegistered(true);
                    setUserContext(convertLoginResultToUserContext(result.data));
                } else {
                    commonDebug.error("Unable to login after registration.");
                }
            }
        } catch (e) {
            window.location.reload();
            commonDebug.error("Error during registration.");
        }
    };

    const userInfo = () => {
        return {
            isLoggedIn: userContext?.isLoggedIn || userContext.isLoggedIn,
            loggedInUserName: userContext?.userUserName || props.model.LoggedInUserName,
            memberOrgCode: userContext?.cug.orgCode || props.model.MemberOrgCode,
            isCug2Member: userContext?.cug.isMember,
            isCug2Agent: userContext?.cug.isAgent,
            IsCugAdminOrSupervisor: userContext?.cug.isAdminOrSupervisor,
        };
    };

    const getWindowWidth = () => {
        const w = window;
        const doc = document;
        const e = doc.documentElement;
        const g = doc.getElementsByTagName("body")[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;

        return x;
    };

    const isCreatorWebAnonymous = () => !props.model.CreatorUserName || props.model.CreatorUserName === WEBANONYMOUS;

    // TODO Remove slice
    const isCreatedByLoggedInUser = () =>
        userInfo().isLoggedIn &&
        userInfo().loggedInUserName &&
        userInfo().loggedInUserName.slice(0, 16).toLowerCase() ===
            props.model.CreatorUserName?.slice(0, 16).toLowerCase();

    const isCreatedBySameCugOrg = () =>
        userInfo().IsCugAdminOrSupervisor &&
        userInfo().memberOrgCode &&
        userInfo().memberOrgCode === props.model.BookingOrgCode;

    const isInfoAddedByLoggedInUser = () =>
        isCreatorWebAnonymous() &&
        props.model.IsRefundDataProvided &&
        userInfo().isLoggedIn &&
        userInfo().loggedInUserName &&
        userInfo().loggedInUserName.toLowerCase() === props.model.RefundAgentUserName?.toLowerCase();

    const canAddNewBankInfo = () =>
        !props.model.IsRefundDataProvided &&
        !userInfo().isCug2Agent &&
        userInfo().isLoggedIn &&
        (isCreatorWebAnonymous() || isCreatedByLoggedInUser() || isCreatedBySameCugOrg());

    const canSeeOrEditBankInfo = () =>
        !userInfo().isCug2Agent &&
        (isInfoAddedByLoggedInUser() || isCreatedByLoggedInUser() || isCreatedBySameCugOrg());

    const showInCorrectUserError = () =>
        !userInfo().isCug2Agent && userInfo().isLoggedIn && !canAddNewBankInfo() && !canSeeOrEditBankInfo();

    const isInitiallyVisible = () =>
        props.model.IsVisible &&
        (!userInfo().isCug2Member ||
            isCreatedBySameCugOrg() ||
            isInfoAddedByLoggedInUser() ||
            (userInfo().isCug2Agent && !props.model.IsRefundDataProvided) ||
            userInfo().IsCugAdminOrSupervisor);

    const canUserRegister = () =>
        isCreatorWebAnonymous() && !userInfo().isLoggedIn && !props.model.IsRefundDataProvided;

    const initialWidgetType = () => (canUserRegister() ? "register" : "login");

    const showOpener = () => isVisible && props.model.ContactEmail?.length > 0;

    const validateEmail = async (value: string) => {
        if (!value || !FULL_EMAIL_REGEX.test(value)) {
            return true;
        }

        let isEmailAvailable = false;
        await ajaxRequest({
            method: "POST",
            url: `${ROUTES.ApiRoutes.CheckUserExist}`,
            body: {
                EmailAddress: value,
            },
            isJsonData: true,
            onResponseCode: {
                200: (result) => {
                    isEmailAvailable = !JSON.parse(result).IsUserExist;
                },
            },
        });

        return isEmailAvailable;
    };

    // Event listeners

    const handleWidgetTypeChange = (newType: RefundWidgetType) => {
        setRefundWidgetType(newType);
        scrollToTop();
    };

    const toggleOpen = () => {
        if (canAddNewBankInfo() || canSeeOrEditBankInfo()) {
            setIsOpen(false);
            setIsVisible(false);
            refundBankInfoModal.open();
        } else {
            setIsOpen(!isOpen);
        }
    };

    const handleLoginButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!showInCorrectUserError() && !userInfo().isCug2Agent) {
            openLoginModal();
        }
    };

    const openLoginModal = () => {
        setIsOpen(false);
        setIsVisible(false);
        triggers.itinerary.openLoginModalForRefund.publish(
            isCreatorWebAnonymous() ? undefined : (props.model.OrgType as OrgType),
        );
    };

    const handleRegistration = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);
        const isFormValid = await validator.validate();

        if (isFormValid) {
            await register();
        }
    };

    // Component

    const [userContext, setUserContext] = useReduxState("userContext");

    const { triggers } = usePubSub();
    const { ajaxRequest, ajaxJsonRequest, removeAjaxErrorMessage } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const rootElement = useRef<HTMLDivElement>(undefined);

    const [isVisible, setIsVisible] = useState<boolean>(isInitiallyVisible());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [refundWidgetType, setRefundWidgetType] = useState<RefundWidgetType>(initialWidgetType());
    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [loginHappened, setLoginHappened] = useState<boolean>(false);
    const [isJustRegistered, setIsJustRegistered] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [registerVm, setRegisterVm] = useState<RefundWidgetRegisterVM>(DEFAULT_REGISTER_VM);

    const refundBankInfoModal = useRefundBankInfoModal({
        antiForgeryToken: props.antiForgeryToken,
        isFlightDeparted: props.isFlightDeparted,
        model: props.refundBankInfoModel,
        refundWidgetType,
        isJustRegistered,
    });

    const validator = useFluentValidator<FieldNames, RefundWidgetRegisterVM>({
        vm: registerVm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("password", (vm: RefundWidgetRegisterVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => validatePassword(password),
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                ),
            Validation.ruleFor("lastName", (vm: RefundWidgetRegisterVM) => vm.lastName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("firstName", (vm: RefundWidgetRegisterVM) => vm.firstName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("termsAccepted", (vm: RefundWidgetRegisterVM) => vm.termsAccepted)
                .fulfils((_) => Promise.resolve(false), i18next.t("Por favor acepte los términos y condiciones."))
                .when((registerVm: RefundWidgetRegisterVM) => !registerVm.termsAccepted),
            Validation.ruleFor("email", (vm: RefundWidgetRegisterVM) => vm.email)
                .isRequired()
                .isEmail()
                .fulfils(validateEmail, i18next.t("Ya tienes una cuenta en JetSMART.com, por favor inicia sesión")),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const refundWidgetCheckboxLabelTemplate = (id: string) => {
        const link1 = html`<a
            class="rw-terms-link"
            href="${props.model.TermsUrl}"
            target="_blank"
            @click=${(e: MouseEvent) => e.stopPropagation()}
            >${i18next.t("Términos y Condiciones")}</a
        >`;
        const link2 = html`<a
            class="rw-terms-link"
            href="${props.model.PrivacyUrl}"
            target="_blank"
            @click=${(e: MouseEvent) => e.stopPropagation()}
            >${i18next.t("Política de Privacidad")}</a
        >`;

        return html`
            <label
                class="rw-checkbox-label"
                for=${id}
                data-test-id=${TestIdDictionary.ITINERARY.REFUND_WIDGET_CHECKBOX_LABEL}
            >
                <span>${i18next.t("Acepto los")} ${link1} ${i18next.t("y")} ${link2} ${i18next.t("de JetSMART")}.</span>
            </label>
        `;
    };

    const refundWidgetCheckbox = useBasicCheckbox({
        isChecked: registerVm.termsAccepted,
        customInputClass: !validator.isValid("termsAccepted") ? "invalid" : "",
        customWrapperClass: "mb-4",
        inputTestId: TestIdDictionary.ITINERARY.REFUND_WIDGET_REGISTER_TERM_CHECKBOX,
        labelTemplate: refundWidgetCheckboxLabelTemplate,
        onClick: () => setRegisterVm({ ...registerVm, termsAccepted: !registerVm.termsAccepted }),
    });

    hauntedUseEffect(init, []);

    useEffect(() => {
        if (loginHappened) {
            setIsOpen(false);
            setIsVisible(true);

            if (userInfo().isLoggedIn && (canAddNewBankInfo() || canSeeOrEditBankInfo())) {
                toggleOpen();
            }
        }
    }, [loginHappened]);

    useEffect(() => {
        addScrollBar();
    }, [window]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add(CLASS_NAMES.noScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.noScroll);
        }
    }, [isOpen]);

    // Templates

    const openerTemplate = () => {
        const tempClassMap = classMap({
            "rw-opener": true,
            "opened": isOpen,
        });

        const text = refundWidgetType === "register" ? i18next.t("Ingresa") : i18next.t("Actualiza");

        return html`
            <div
                class=${tempClassMap}
                @click=${toggleOpen}
                data-test-id=${TestIdDictionary.ITINERARY.REFUND_WIDGET_OPENER}
            >
                <i class="js-icon-refund js-refund-refund-person"></i>
                <div class="rw-opener-text">
                    ${text}
                    <div class="font-bold">${i18next.t("tus datos")}</div>
                </div>
                ${closerButtonTemplate()}
            </div>
        `;
    };

    const closerButtonTemplate = () => {
        const onClick = (e: MouseEvent) => {
            e.stopPropagation();
            setIsOpen(false);
        };

        return isOpen
            ? html`
                  >>
                  <div class="rwc-close-btn" @click=${onClick}>&times;</div>
              `
            : "<<";
    };

    const registrationData = () => html`
        <div class="rw-registration-content">
            <div class="row">
                <div class="col-xs-1">${firstNameFieldTemplate()}</div>
            </div>
            <div class="row">
                <div class="col-xs-1">${lastNameFieldTemplate()}</div>
            </div>
            <div class="row">
                <div class="col-xs-1">${emailFieldTemplate()}</div>
            </div>
            <div class="row">
                <div class="col-xs-1">${passwordFieldTemplate()}</div>
            </div>
        </div>
    `;

    const firstNameFieldTemplate = () => html`
        <div class="mt-[10px]">
            <ac-input
                .errorMessage=${validator.getMessage("firstName")}
                .isInvalid=${!validator.isValid("firstName")}
                .label=${`${i18next.t("Nombres")}`}
                .autoComplete=${"off"}
                .name=${"firstName"}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .tabIndex=${"1"}
                .testId=${TestIdDictionary.ITINERARY.REFUND_WIDGET_CONTACT_FIRST_NAME}
                .value=${registerVm.firstName ?? ""}
                .onInput=${(value: string) => setRegisterVm({ ...registerVm, firstName: value })}
            ></ac-input>
        </div>
    `;

    const lastNameFieldTemplate = () => html`
        <div class="mt-[10px]">
            <ac-input
                .errorMessage=${validator.getMessage("lastName")}
                .isInvalid=${!validator.isValid("lastName")}
                .label=${`${i18next.t("Apellidos")}`}
                .autoComplete=${"off"}
                .name=${"lastName"}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .tabIndex=${"2"}
                .testId=${TestIdDictionary.ITINERARY.REFUND_WIDGET_CONTACT_FIRST_NAME}
                .value=${registerVm.lastName ?? ""}
                .onInput=${(value: string) => setRegisterVm({ ...registerVm, lastName: value })}
            ></ac-input>
        </div>
    `;

    const emailFieldTemplate = () => html`
        <div class="mt-[10px]">
            <ac-input
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .name=${"email"}
                .autoComplete=${"off"}
                .tabIndex=${"3"}
                .label=${`${i18next.t("Correo electrónico")}`}
                .testId=${TestIdDictionary.ITINERARY.REFUND_WIDGET_EMAIL_FIELD}
                .value=${registerVm.email ?? ""}
                .onInput=${(value: string) => setRegisterVm({ ...registerVm, email: value })}
            ></ac-input>
        </div>
    `;

    const passwordFieldTemplate = () => html`
        <div class="relative mt-[10px]">
            <ac-input
                .errorMessage=${validator.getMessage("password")}
                .isInvalid=${!validator.isValid("password")}
                .type=${"password"}
                .name=${"pwd"}
                .autoComplete=${"new-password"}
                .label=${`${i18next.t("Crea tu contraseña")}`}
                .tabIndex=${"4"}
                .testId=${TestIdDictionary.ITINERARY.REFUND_WIDGET_PASSWORD_FIELD}
                .value=${registerVm.password ?? ""}
                .onInput=${(value: string) => setRegisterVm({ ...registerVm, password: value })}
            ></ac-input>
            ${passwordTooltipTemplate()}
        </div>
    `;

    const passwordTooltipTemplate = () => html`
        <span class="icon absolute right-[5px] top-[18px]" tabindex="0">
            <ac-tooltip
                .icon=${"?"}
                .tooltip=${i18next.t("La contraseña debe contener entre 8 y 16 caracteres e incluir letras y números.")}
            ></ac-tooltip>
        </span>
    `;

    const loginTemplate = () =>
        refundWidgetType === "login"
            ? html`
                  ${loginHeaderTemplate()}
                  ${userInfo().isCug2Agent ? cug2AgentWarningTemplate() : loginButtonTemplate()}
                  ${incorrectUserErrorTemplate()} ${linkToRegisterTemplate()}
              `
            : "";

    const loginHeaderTemplate = () => html`
        <div class="rw-title">${i18next.t("¡Actualiza tus datos!")}</div>
        <div class="rw-description">
            ${i18next.t(
                "Podrás ingresar desde ya los datos de tu cuenta bancaria a fin de que podamos efectuar la devolución de tasas, cargos o derechos aeronáuticos conforme a lo dispuesto en el Artículo 133C del Código Aeronáutico de Chile, en caso de que proceda dicha devolución. Para ello, debes iniciar sesión en tu cuenta y agregar la información requerida.",
            )}
        </div>
    `;

    const loginButtonTemplate = () =>
        !showInCorrectUserError()
            ? html`
                  <div class="rw-primary-btn-container">
                      <button
                          class="rounded-primary-btn"
                          data-test-id=${TestIdDictionary.ITINERARY.REFUND_WIDGET_LOGIN_BUTTON}
                          @click=${handleLoginButtonClick}
                      >
                          ${i18next.t("Iniciar sesión")}
                      </button>
                  </div>
                  ${forgottenPasswordLinkTemplate()}
              `
            : "";

    const forgottenPasswordLinkTemplate = () =>
        !userInfo().isLoggedIn
            ? html`
                  <a class="rw-forgot-pw" href=${ROUTES.ForgotPassword}>${i18next.t("¿Olvidaste tu contraseña?")}</a>
              `
            : "";

    const cug2AgentWarningTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="error-message-container">
                    <div class="form-error-message">
                        ${i18next.t(
                            "Contact your organization Administrator/Supervisor to manage the refund of the fees.",
                        )}
                    </div>
                </div>
            </div>
        </div>
    `;

    const linkToRegisterTemplate = () =>
        canUserRegister()
            ? html`
                  <div class="rw-bottom-container">
                      ${i18next.t("¿No tienes cuenta?")}
                      <a class="rw-bottom-link" @click=${() => handleWidgetTypeChange("register")}
                          >${i18next.t("Registrate aquí")}</a
                      >
                  </div>
              `
            : "";

    const incorrectUserErrorTemplate = () =>
        showInCorrectUserError()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">
                                  ${i18next.t(
                                      "The booking was created with a different username. Please log out first.",
                                  )}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const registerTemplate = () =>
        refundWidgetType === "register"
            ? html`
                  ${registerHeaderTemplate()}
                  <div class="profile-form">
                      <div>
                          ${getAntiforgerySegment(props.antiForgeryToken)} ${registrationData()}
                          ${formErrors.htmlTemplate()}
                      </div>
                      ${termsTemplate()} ${registerButtonTemplate()}
                  </div>
                  ${linkToLoginTemplate()}
              `
            : "";

    const registerHeaderTemplate = () => html`
        <div class="rw-title">
            <div class="rw-title-line">${i18next.t("Crea tu cuenta en JetSMART")}</div>
            <div class="rw-title-line">${i18next.t("¡ahorra tiempo y obtén más beneficios!")}</div>
        </div>
        <div class="rw-description">
            ${i18next.t(
                "Solo debes completar la información requerida y tu cuenta será creada inmediatamente. Luego, solicitaremos los datos de tu cuenta bancaria, los que podrás ingresar desde ya, a fin de que podamos efectuar la devolución de tasas, cargos o derechos aeronáuticos conforme a lo dispuesto en el Artículo 133C del Código Aeronáutico de Chile, en caso de que proceda dicha devolución.",
            )}
        </div>
    `;

    const registerButtonTemplate = () => html`
        <div class="rw-primary-btn-container">
            <button
                class="rounded-primary-btn"
                tabindex="6"
                @click=${handleRegistration}
                data-test-id=${TestIdDictionary.ITINERARY.REFUND_WIDGET_REGISTER_CONTINUE_BUTTON}
            >
                ${i18next.t("Continuar")}
            </button>
        </div>
    `;

    const termsTemplate = () => html` ${refundWidgetCheckbox.htmlTemplate()} ${acceptanceErrorTemplate()} `;

    const linkToLoginTemplate = () => html`
        <div class="rw-bottom-container">
            ${i18next.t("Ya tienes cuenta, ingresa aquí")}
            <button
                class="rounded-primary-btn blue-white"
                data-test-id=${TestIdDictionary.ITINERARY.REFUND_WIDGET_LOGIN_HERE_BUTTON}
                @click=${() => handleWidgetTypeChange("login")}
            >
                ${i18next.t("Iniciar sesión")}
            </button>
        </div>
    `;

    const acceptanceErrorTemplate = () =>
        !validator.isValid("termsAccepted")
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div
                              class="error-message-container"
                              data-test-id=${TestIdDictionary.COMMON.ACCEPT_TERMS_AND_CONDITIONS_CHECKBOX}
                          >
                              <div class="form-error-message">
                                  ${i18next.t("Por favor acepte los términos y condiciones.")}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const overlayTemplate = () =>
        isOpen ? html` <div class="modal no-print" @click=${() => setIsOpen(false)}></div> ` : html``;

    const mainClassMap = classMap({
        "no-print": true,
        "inline": showOpener(),
        "hidden": !showOpener(),
    });

    const scrollerClassMap = classMap({
        [scrollerClassName]: true,
        open: isOpen,
    });

    const htmlTemplate = () => html`
        <div ref=${ref(rootElement)} class=${mainClassMap}>
            <div class=${scrollerClassMap}>
                <div class="rw-content-container">${registerTemplate()} ${loginTemplate()}</div>
            </div>
            ${openerTemplate()}
        </div>
        ${updateMdl()} ${overlayTemplate()} ${refundBankInfoModal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

import i18next from "i18next";
import { html } from "haunted";
import { showLoader } from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../shared/apiRoutes";
import { useModal } from "../shared/useModal";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    onCancel: () => void;
    onLogout: () => void;
}

export const useGiftcardActivationModal = (props: Props) => {
    const { ajaxRequest } = useAjax();

    const [userContext] = useReduxState("userContext");

    // EVENT HANDLERS

    const handleClickYes = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.hide();

        showLoader({});
        window.location.href = "/V2/Profile?giftCard=success";
    };

    const handleClickNo = async () => {
        modal.hide();

        const loader = showLoader({});
        const response = await ajaxRequest({
            loader,
            url: ROUTES.LogoutInline,
            method: "GET",
        });

        // FIXME Add type
        const isSuccess = JSON.parse(response.data).isLogoutSuccessful;

        if (isSuccess) props.onLogout();
    };

    const headerTemplate = () => html`
        <div class="giftcard-header">
            <i class="js-icon js-gift-card1"></i>
            ${i18next.t("Gift-Title-1")}
            <span>${i18next.t("Gift-Title-2")}</span>
            <div class="giftcard-header-logo">
                <img src="/Images/Header/jetsmart-logo-colored.svg" />
            </div>
        </div>
    `;

    const contentTemplate = () => html`
        <div class="giftcard-modal-title">
            ${i18next.t("Gift-ActivationModalTitle{{-userName}}", {
                userName: userContext.userFirstName,
            })}
        </div>
    `;

    const buttonContainerTemplate = () => html`
        <div class="giftcard-modal-btn-container">
            <button class="rounded-secondary-btn" data-test-id="gc-no-add-to-account" @click=${handleClickNo}>
                ${i18next.t("Gift-OtherAccount")}
            </button>
            <button class="rounded-primary-btn" data-test-id="gc-add-to-existing-account" @click=${handleClickYes}>
                ${i18next.t("Gift-MyAccount")}
            </button>
        </div>
    `;

    const htmlTemplate = () => html` ${headerTemplate()} ${contentTemplate()} ${buttonContainerTemplate()} `;

    const modal = useModal({
        overlay: { classNames: "giftcard-activation-modal ts-error-parent" },
        content: { template: htmlTemplate },
        closing: { buttonClassNames: "modal-close", isClosable: true, onClose: props.onCancel },
    });

    return modal;
};

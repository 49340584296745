import { TemplateResult, html } from "lit-html";
import {
    ApiJourneyServiceChange,
    ApiPassengerServiceChanges,
    ApiReview,
} from "../../../component-models/review/ApiReview";
import i18next from "i18next";
import { reviewTableTemplate } from "./reviewTableTemplate";
import { commonReviewTemplates } from "./commonReviewTemplates";

export const seatReview = (model: ApiReview) => {
    const { desktopReviewTable, mobileReviewTable } = reviewTableTemplate();
    const { paxLabelTemplate, paxNameTemplate, routeTemplate } = commonReviewTemplates();

    const previousTemplate = (paxChange: ApiPassengerServiceChanges) =>
        html`${paxChange.SsrChanges.map((change) =>
            change.RemovedSsrName !== "N/A"
                ? html` <span>${change.RemovedSsrName}</span> `
                : html`<span>${i18next.t("Review-NoSeats")}</span>`,
        )}`;

    const currentTemplate = (paxChange: ApiPassengerServiceChanges) =>
        html`${paxChange.SsrChanges.map((change) =>
            change.AddedSsrName !== "N/A"
                ? html` <span>${change.AddedSsrName}</span> `
                : html`<span>${i18next.t("Review-NoSeats")}</span>`,
        )}`;

    const getTable = (paxChange: ApiPassengerServiceChanges) =>
        new Map<string, (string | TemplateResult)[]>([
            [paxLabelTemplate(paxChange), [paxNameTemplate(paxChange)]],
            [i18next.t("ReviewChanges-PreviousSeats"), [previousTemplate(paxChange)]],
            [i18next.t("ReviewChanges-NewSeats"), [currentTemplate(paxChange)]],
        ]);

    const desktopTemplate = (paxChange: ApiPassengerServiceChanges) => html`${desktopReviewTable(getTable(paxChange))}`;

    const mobileTemplate = (paxChange: ApiPassengerServiceChanges) => html`${mobileReviewTable(getTable(paxChange))}`;

    const journeyTemplate = (journey: ApiJourneyServiceChange) => html`
        <div class="inner-deep-box">
            <div class="flight-container hidden-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleSeat")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map(desktopTemplate)}
            </div>

            <div class="flight-container visible-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleSeat")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map(mobileTemplate)}
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        model.SeatChanges?.length
            ? html`
                  <div>
                      <div class="inner-deep-box-header">${i18next.t("ChangesInSeats")}</div>
                  </div>
                  ${model.SeatChanges.map(journeyTemplate)}
              `
            : "";

    return { htmlTemplate };
};

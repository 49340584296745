import i18next from "i18next";
import { html } from "lit-html";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    model: PaymentPageViewModel;
    handlePaymentIntent: () => Promise<boolean>;
}

export function useCreditShellCat1234(props: Props) {
    const { formatNumber } = useNumberFormatter();

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.handlePaymentIntent();
    };

    const unusedCreditMessageTemplate = () =>
        !props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html` <div class="credit-shell-message">${i18next.t("BE-CreditShellMessageNew")}</div> `
            : "";

    const unusedAmountTemplate = () =>
        !props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html`
                  <div class="credit-shell-amount">
                      <span>${i18next.t("BE-CreditShellAmountLabel")}</span>
                      ${formatNumber({
                          amount: props.model.CreditShellViewModel.Cat12347CreditShellAvailableAmount,
                          leadingSign: true,
                      })}
                  </div>
              `
            : "";

    const unusedCaptionTemplate = () =>
        !props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html`<span class="credit-shell-subcaption"> ${i18next.t("BE-CreditShellCaption-2")} </span>`
            : "";

    const modalOpenerTemplate = () =>
        !props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html`
                  <button @click=${handleClick} class="credit-shell-button">${i18next.t("BE-CreditShellBtn")}</button>
              `
            : "";

    const usedTickTemplate = () =>
        props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html`
                  <div class="credit-shell-applied">
                      <span>${i18next.t("BE-CreditShellApplied")}</span>
                      <i class="js-icon js-flight-tick"></i>
                  </div>
              `
            : "";

    const usedCreditInfoTemplate = () => {
        const amount =
            props.model.CreditShellViewModel.AutoCreditFileAmount !== 0
                ? props.model.CreditShellViewModel.AutoCreditFileAmount
                : formatNumber({
                      amount: props.model.CreditShellViewModel.Cat12347CreditShellUsedAmount,
                      leadingSign: true,
                  });

        return props.model.CreditShellViewModel.Cat12347CreditShellIsUsed
            ? html`
                  <div class="credit-shell-used-info">
                      ${i18next.t("BE-CreditShellUsedInfo {{amount}}", { amount })}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        ${unusedCreditMessageTemplate()}
        <div class="inner-deep-box">
            <div class="credit-shell">
                <div class="credit-shell-section">
                    <img src="/Images/BancoEstado/be-logo-w.svg" />
                    <div class="credit-shell-caption">
                        <span class="credit-shell-maincaption"> ${i18next.t("BE-CreditShellCaption-1")} </span>
                        ${unusedCaptionTemplate()}
                    </div>
                    ${unusedAmountTemplate()}
                </div>
                <div class="credit-shell-section">${modalOpenerTemplate()} ${usedTickTemplate()}</div>
            </div>

            ${usedCreditInfoTemplate()}
        </div>
    `;

    return { htmlTemplate };
}

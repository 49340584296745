import { ApiInsurancePassenger } from "../../../../component-models/extras/insurance/ApiInsurancePassenger";
import { InsuranceTermsToShow, passengerListScrollerClassName, useInsuranceTerms } from "./useInsuranceTerms";
import { ScrollHelper } from "../../../../shared/ScrollHelper";
import {
    AddBaggageInsuranceEvent,
    RemoveBaggageInsuranceEvent,
    ToggleBaggageInsuranceOpenForPaxEvent,
} from "../../../../component-models/extras/insurance/InsuranceEvents";
import { html, useRef, useState } from "haunted";
import { showLoader, hideLoader } from "../../../../shared/common";
import i18next from "i18next";
import { ref } from "../../../../directives/ref";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { useEffect } from "../../../../shared/haunted/CustomHooks";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { InsurancePassengerModel } from "../../../../component-models/extras/insurance/InsurancePassengerModel";
import {
    mapChangeAllBaggage,
    mapChangeOneBaggage,
    mapToInsurancePassengerModel,
} from "../../../../component-mappers/InsuranceMappers";
import { SelectBagInsurancePostModel } from "../../../../component-models/extras/insurance/SelectBagInsurancePostModel";
import { SearchInsuranceResult } from "../../../../component-models/spa/SearchInsuranceResult";
import { baggageScrollerClassName } from "./insurance-baggage-container";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import BookingData from "../../../../shared/BookingData";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { BagType } from "../../baggage/useBaggagePage";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";

export type InsuranceSelection = "pax" | "baggage";

export interface Props {
    model: ApiExtrasSpaSectionViewModel;
    insurance: SearchInsuranceResult;
    insurancePassengers: ApiInsurancePassenger[];
    isPassengerInsuranceSelected: boolean;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const useInsurance = (props: Props) => {
    const root = useRef<HTMLDivElement>(undefined);

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const tealiumManager = useExtrasTealiumManager();
    const bookingManager = useBookingManager();

    const [currentSpaSection] = useReduxState("spa.activeSection");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [passengers, setPassengers] = useState<InsurancePassengerModel[]>([]);
    const [isPaxInsuranceSelected, setIsPaxInsuranceSelected] = useState<boolean>(props.isPassengerInsuranceSelected);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [isBaggageInsuranceOpen, setIsBaggageInsuranceOpen] = useState<boolean>(false);
    const [insuranceSelection, setInsuranceSelection] = useState<Set<InsuranceSelection>>(new Set());
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const insuranceTermsToShow = () =>
        [insuranceSelection.has("baggage") ? "baggage" : "", insuranceSelection.has("pax") ? "travel" : ""].filter(
            (i) => i,
        ) as InsuranceTermsToShow[];

    const insuranceTerms = useInsuranceTerms({
        insuranceTermsToShow: insuranceTermsToShow(),
        isValidated,
        handleBaggageTermsClick: () => window.open(props.model?.BaggageInsuranceTermsUrl, "_blank"),
        handleTermsClick: () => window.open(props.model?.InsuranceTermsUrl, "_blank"),
    });

    // HELPERS

    const isBaggageInsuranceAvailable = () =>
        bookingContext.isBaggageInsuranceAvailable &&
        props.insurance?.BaggageCost &&
        props.insurance?.BaggageCostCurrency;

    const init = async () => {
        const pax = initPassengers();
        setPassengers(pax);
        setIsBaggageInsuranceOpen(isBaggageInsuranceAvailable() && isBaggageInsuranceEnabledForPax(pax));
        setIsInitialized(true);
    };

    const initPassengers = () => {
        return Array.isArray(props.insurancePassengers)
            ? props.insurancePassengers.map((pax) =>
                  mapToInsurancePassengerModel(
                      pax,
                      props.insurancePassengers.filter((passenger) => passenger.Type !== "INF").length,
                  ),
              )
            : [];
    };

    const isBaggageOnly = () => !isPaxInsuranceSelected && passengerIndicesForDocumentsForInsurance().length > 0;

    const storeSelection = () => {
        const newSelection = new Set(insuranceSelection);

        if (isPaxInsuranceSelected) {
            newSelection.add("pax");
        } else {
            newSelection.delete("pax");
        }

        if (passengers.some((p) => p.isBaggageInsuranceOpened)) {
            newSelection.add("baggage");
        } else {
            newSelection.delete("baggage");
        }

        setInsuranceSelection(newSelection);
    };

    const validateInsurance = () => {
        setIsValidated(true);

        // DEVNOTE if we already have insurance in the post booking flow, we don't need to validate anything
        if (props.model?.ExtrasModel.HasInsuranceFeeInBooking && !props.model?.ExtrasModel.HasUnpaidInsuranceFee) {
            return true;
        }

        if ((isPaxInsuranceSelected || isBaggageInsuranceOpen) && insuranceTermsToShow().length > 0) {
            return insuranceTerms.isPaxInsuranceTermsChecked;
        } else {
            return true;
        }
    };

    const passengerIndicesForDocumentsForInsurance = () => {
        return passengers
            .filter((passenger) => isPaxInsuranceSelected || passenger.isBaggageInsuranceOpened)
            .map((passenger) => passenger.displayedIndex);
    };

    const isBaggageInsuranceEnabledForPax = (pax: InsurancePassengerModel[]) =>
        pax.some((p) => p.isCheckedBaggageEnabled || p.isOversizedBaggageEnabled);

    const selectPassengerInsurance = async (): Promise<void> => {
        const parent = DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.Generic);
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: parent, noPlane: true });

        const response = await bookingManager.postAddPassengerInsurance<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasSpaSectionViewModel;
        }>(root.current, loader);
        props.setExtrasModel(response.ExtrasModel);
    };

    const deselectPassengerInsurance = async (): Promise<void> => {
        const parent = DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.Generic);
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: parent, noPlane: true });

        const response = await bookingManager.postRemovePassengerInsurance<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasSpaSectionViewModel;
        }>(root.current, loader);
        props.setExtrasModel(response.ExtrasModel);
    };

    const submitBagAmount = async (items: SelectBagInsurancePostModel[]): Promise<void> => {
        if (items.length > 0) {
            const parent = DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.Generic);
            const loader = isLoading
                ? showLoader({
                      name: LOADER_CLASS_NAMES.CommonLoaderWrapper,
                      container: parent,
                      noPlane: true,
                  })
                : null;

            const body: any = {};

            items.forEach((item, index) => {
                body[`parameters[${index}].PassengerNumber`] = item.paxIndex.toString();
                body[`parameters[${index}].Increment`] = (item.newAmount - item.oldAmount).toString();
                body[`parameters[${index}].BaggageType`] =
                    item.bagType === "CheckedBaggage" ? "Checked" : "SportEquipment";
            });

            const response = await bookingManager.postAddBaggageInsurance<{
                BookingSummary: BookingData;
                ExtrasModel: ApiExtrasSpaSectionViewModel;
            }>(body, root.current, loader);
            props.setExtrasModel(response.ExtrasModel);
        }
    };

    const changeOneBaggageInsurance = async (
        paxIndex: number,
        bagType: BagType,
        changeType: "increase" | "decrease",
    ): Promise<void> => {
        const pax = passengers.find((passenger) => passenger.displayedIndex === paxIndex);
        const maxAmount = bagType === "CheckedBaggage" ? pax.maxCheckedBaggage : pax.maxOversizedBaggage;

        const oldAmount =
            bagType === "CheckedBaggage" ? pax.checkedBaggageWithInsurance : pax.oversizedBaggageWithInsurance;
        const newAmount = changeType === "increase" ? oldAmount + 1 : oldAmount - 1;

        if ((changeType === "decrease" && oldAmount === 0) || (changeType === "increase" && oldAmount === maxAmount)) {
            return;
        }

        const newPassengers = mapChangeOneBaggage(passengers, paxIndex, bagType, newAmount);

        await submitBagAmount([{ paxIndex, bagType, oldAmount, newAmount }]);
        setPassengers(newPassengers);
    };

    const changeAllBaggageInsurance = async (passengerIndicesToChange: number[], type: "add" | "remove") => {
        const newPassengers = mapChangeAllBaggage(passengers, passengerIndicesToChange, type);

        await postAllBaggageChangeRequest(passengerIndicesToChange, type);

        setPassengers(newPassengers);
    };

    const postAllBaggageChangeRequest = async (passengerIndicesToChange: number[], type: "add" | "remove") => {
        const request = passengers
            .filter(
                (passenger) =>
                    passengerIndicesToChange.includes(passenger.displayedIndex) &&
                    (passenger.isCheckedBaggageEnabled || passenger.isOversizedBaggageEnabled),
            )
            .reduce((postItems: SelectBagInsurancePostModel[], passenger) => {
                return postItems.concat([
                    {
                        paxIndex: passenger.displayedIndex,
                        bagType: "CheckedBaggage",
                        oldAmount: type === "add" ? 0 : passenger.checkedBaggageWithInsurance,
                        newAmount: type === "add" ? passenger.maxCheckedBaggage : 0,
                    },
                    {
                        paxIndex: passenger.displayedIndex,
                        bagType: "OversizedBaggage",
                        oldAmount: type === "add" ? 0 : passenger.oversizedBaggageWithInsurance,
                        newAmount: type === "add" ? passenger.maxOversizedBaggage : 0,
                    },
                ]);
            }, []);

        const realChanges = request.filter((item) => item.oldAmount !== item.newAmount);

        if (realChanges.length > 0) {
            await submitBagAmount(realChanges);
        }
    };

    const scrollToBaggageInsurance = () => {
        const element = DomCrawlingHelper.getElemByClass(document.body, baggageScrollerClassName);
        ScrollHelper.scrollToElementAndHideNav({ element, yOffset: 0, timeOffset: 250 });
    };

    const scrollToPassengerList = () => {
        const element = DomCrawlingHelper.getElemByClass(document.body, passengerListScrollerClassName);
        ScrollHelper.scrollToElementAndHideNav({ element, yOffset: 0, timeOffset: 250 });
    };

    // EVENT HANDLERS

    const handleModalAddInsurance = () => {
        setIsValidated(false);
        setIsBaggageInsuranceOpen(true);
        addInsurance();
    };

    const addInsurance = async () => {
        if (isPaxInsuranceSelected) {
            return;
        }

        await selectPassengerInsurance();
        setIsPaxInsuranceSelected(true);

        tealiumManager.logBinaryExtraSelected("insurance", "N/A", "N/A", "N/A", "add", props.insurance?.CostAmount);

        if (isBaggageInsuranceAvailable() && isBaggageInsuranceEnabledForPax(passengers) && passengers?.length > 0) {
            scrollToBaggageInsurance();
        } else {
            scrollToPassengerList();
        }
    };

    const removeInsurance = async () => {
        if (!isPaxInsuranceSelected) {
            return;
        }

        await deselectPassengerInsurance();
        setIsPaxInsuranceSelected(false);

        tealiumManager.logBinaryExtraSelected("insurance", "N/A", "N/A", "N/A", "remove", props.insurance?.CostAmount);
    };

    const handleInsuranceButtonClick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();

        if (isPaxInsuranceSelected) {
            await removeInsurance();
        } else {
            await addInsurance();
        }
    };

    const handleBaggageInsuranceAmountOpenerClick = async (e: ToggleBaggageInsuranceOpenForPaxEvent): Promise<void> => {
        const parent = DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.Generic);
        const loader = showLoader({
            name: LOADER_CLASS_NAMES.CommonLoaderWrapper,
            container: parent,
            noPlane: true,
        });
        setIsLoading(true);

        const pax = passengers.find((p) => e.detail.paxIndex === p.displayedIndex);

        if (pax.isBaggageInsuranceOpened) {
            await changeAllBaggageInsurance([pax.displayedIndex], "remove");

            tealiumManager.logMultiOptionExtraSelected(
                "checkedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                0,
                props.insurance?.BaggageCostAmount * pax.checkedBaggageWithInsurance,
                0,
                pax.checkedBaggageWithInsurance,
            );

            tealiumManager.logMultiOptionExtraSelected(
                "oversizedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                0,
                props.insurance?.BaggageCostAmount * pax.oversizedBaggageWithInsurance,
                0,
                pax.oversizedBaggageWithInsurance,
            );
        } else {
            await changeAllBaggageInsurance([pax.displayedIndex], "add");

            tealiumManager.logMultiOptionExtraSelected(
                "checkedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * pax.maxCheckedBaggage,
                0,
                pax.maxCheckedBaggage,
                0,
            );

            tealiumManager.logMultiOptionExtraSelected(
                "oversizedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * pax.maxOversizedBaggage,
                0,
                pax.maxOversizedBaggage,
                0,
            );
        }

        hideLoader(loader);
        setIsLoading(false);
    };

    const handleBaggageInsuranceDetailsClick = async (): Promise<void> => {
        if (isBaggageInsuranceOpen) {
            await changeAllBaggageInsurance(
                passengers
                    .filter((passenger) => passenger.isBaggageInsuranceOpened)
                    .map((passenger) => passenger.displayedIndex),
                "remove",
            );

            passengers.forEach((pax) => {
                tealiumManager.logMultiOptionExtraSelected(
                    "checkedBaggageInsurance",
                    pax.type === "ADT" ? "A" : "C",
                    pax.displayedIndex,
                    0,
                    pax.checkedBaggageWithInsurance * props.insurance?.BaggageCostAmount,
                    0,
                    pax.checkedBaggageWithInsurance,
                );

                tealiumManager.logMultiOptionExtraSelected(
                    "oversizedBaggageInsurance",
                    pax.type === "ADT" ? "A" : "C",
                    pax.displayedIndex,
                    0,
                    pax.oversizedBaggageWithInsurance * props.insurance?.BaggageCostAmount,
                    0,
                    pax.oversizedBaggageWithInsurance,
                );
            });
        }

        setIsBaggageInsuranceOpen(!isBaggageInsuranceOpen);
    };

    const handleAddBaggage = async (e: AddBaggageInsuranceEvent): Promise<void> => {
        await changeOneBaggageInsurance(e.detail.paxIndex, e.detail.bagType, "increase");

        const pax = passengers.find((p) => e.detail.paxIndex === p.displayedIndex);

        if (e.detail.bagType === "CheckedBaggage") {
            tealiumManager.logMultiOptionExtraSelected(
                "checkedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * (pax.checkedBaggageWithInsurance + 1),
                props.insurance?.BaggageCostAmount * pax.checkedBaggageWithInsurance,
                pax.checkedBaggageWithInsurance + 1,
                pax.checkedBaggageWithInsurance,
            );
        } else {
            tealiumManager.logMultiOptionExtraSelected(
                "oversizedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * (pax.oversizedBaggageWithInsurance + 1),
                props.insurance?.BaggageCostAmount * pax.oversizedBaggageWithInsurance,
                pax.oversizedBaggageWithInsurance + 1,
                pax.oversizedBaggageWithInsurance,
            );
        }
    };

    const handleRemoveBaggage = async (e: RemoveBaggageInsuranceEvent): Promise<void> => {
        await changeOneBaggageInsurance(e.detail.paxIndex, e.detail.bagType, "decrease");

        const pax = passengers.find((p) => e.detail.paxIndex === p.displayedIndex);

        if (e.detail.bagType === "CheckedBaggage") {
            tealiumManager.logMultiOptionExtraSelected(
                "checkedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * (pax.checkedBaggageWithInsurance - 1),
                props.insurance?.BaggageCostAmount * pax.checkedBaggageWithInsurance,
                pax.checkedBaggageWithInsurance - 1,
                pax.checkedBaggageWithInsurance,
            );
        } else {
            tealiumManager.logMultiOptionExtraSelected(
                "oversizedBaggageInsurance",
                pax.type === "ADT" ? "A" : "C",
                pax.displayedIndex,
                props.insurance?.BaggageCostAmount * (pax.oversizedBaggageWithInsurance - 1),
                props.insurance?.BaggageCostAmount * pax.oversizedBaggageWithInsurance,
                pax.oversizedBaggageWithInsurance - 1,
                pax.oversizedBaggageWithInsurance,
            );
        }
    };

    // COMPONENT

    useEffect(storeSelection, [
        isPaxInsuranceSelected,
        isBaggageInsuranceOpen,
        passengers.map((p) => p.isBaggageInsuranceOpened).join("|"),
    ]);

    useEffect(() => {
        if (currentSpaSection === "Extras" && !isInitialized && props.model && props.insurancePassengers) {
            init();
        }
    }, [currentSpaSection, isInitialized, props.model, props.insurancePassengers]);

    useEffect(
        () => setIsPaxInsuranceSelected(props.isPassengerInsuranceSelected),
        [props.isPassengerInsuranceSelected],
    );

    // TEMPLATES

    const travelInsuranceTemplate = () => html`
        <ac-insurance-passenger-insurance-selector
            .insurancePerPaxPrice=${props.insurance?.CostAmount}
            .insurancePerPaxCurrency=${props.insurance?.CostCurrency}
            .showInfantWarning=${props.insurancePassengers.some((p) => p.Type === "INF")}
            .isSelected=${isPaxInsuranceSelected}
            @togglePaxInsuranceOpen=${handleInsuranceButtonClick}
        ></ac-insurance-passenger-insurance-selector>
    `;

    const baggageInsuranceTemplate = () =>
        isBaggageInsuranceAvailable() &&
        isBaggageInsuranceEnabledForPax(passengers) &&
        passengers?.length > 0 &&
        !flowContext.isPostBookingFlow
            ? html`
                  <ac-insurance-baggage-container
                      .bagPriceCurrency=${props.insurance?.BaggageCostCurrency}
                      .insurancePerBagPrice=${props.insurance?.BaggageCostAmount}
                      .isBaggageInsuranceOpen=${isBaggageInsuranceOpen}
                      .passengers=${passengers.filter((p) => p.type !== "INF")}
                      @toggleBaggageInsuranceOpenForPax=${handleBaggageInsuranceAmountOpenerClick}
                      @toggleBaggageInsuranceOpen=${handleBaggageInsuranceDetailsClick}
                      @addBaggageInsurance=${handleAddBaggage}
                      @removeBaggageInsurance=${handleRemoveBaggage}
                  ></ac-insurance-baggage-container>
              `
            : "";

    const insuranceHeaderSubtitleTextTemplate = () =>
        isBaggageInsuranceAvailable()
            ? html`${i18next.t("Seguro de viaje y equiapaje entregado por")}`
            : html`${i18next.t("Seguro de viaje entregado por")}`;

    const headerTemplate = () => html`
        <div class="extras-insurance-header" data-test-id=${T.EXTRAS.INSURANCE_HEADER}>
            <i class="js-icon-covid js-cv-covid-shield-plane insurance-title-icon"></i>
            <div class="extras-insurance-title-box">
                <div class="insurance-title">${i18next.t("Viaja siempre seguro con nosotros")}</div>
                <div class="insurance-subtitle" data-test-id=${T.EXTRAS.INSURANCE_HEADER_SUBTITLE}>
                    ${insuranceHeaderSubtitleTextTemplate()}
                    <img
                        class="subtitle-chubb-logo"
                        src="/Images/Insurance/chubb-logo-transparent.png"
                        data-test-id=${T.EXTRAS.INSURANCE_HEADER_SUBTITLE_CHUBB_LOGO}
                    />
                </div>
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        !props.model?.ExtrasModel.HasInsuranceFeeInBooking || props.model?.ExtrasModel.HasUnpaidInsuranceFee
            ? html`
                  <section
                      class="booking-wrapper insurance-container ts-error-container"
                      ref=${ref(root)}
                      data-test-id=${T.EXTRAS.INSURANCE_CONTAINER}
                  >
                      <div class="ts-error-container">
                          ${headerTemplate()} ${travelInsuranceTemplate()} ${baggageInsuranceTemplate()}
                          ${insuranceSelection.size ? insuranceTerms.htmlTemplate() : ""}
                      </div>
                  </section>
              `
            : "";

    return {
        insuranceSelection,
        passengerIndicesForDocumentsForInsurance,
        addInsurance,
        handleModalAddInsurance,
        htmlTemplate,
        isBaggageOnly,
        validateInsurance,
    };
};

import { ROUTES } from "./../../../shared/apiRoutes";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { Partner } from "../../../component-models/CUG2b/Partner";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { FAKE_CUG2_GROUP_NAME, URI_DIVIDER_CHARACTER, URL_VARS } from "../../../shared/commonConstants";
import { Group } from "../../../component-models/CUG2b/Group";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { getAntiForgeryTokenFromHtml, handleCugLoader, updateMdl } from "../../../shared/common";
import { classMap } from "lit-html/directives/class-map";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export const name = "ac-add-travel-group-page";

export interface Attributes {
    "anti-forgery-token": string;
}
export interface Properties {
    antiForgeryToken: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const urlParams = new URLSearchParams(window.location.search);

        const result = await getTravelPartnerInfo();

        setTravelPartnerInfo(result);

        const idsInUrl = urlParams
            .get(URL_VARS.FFLYER_IDS)
            ?.split(URI_DIVIDER_CHARACTER)
            .map((id) => Number(id));
        setSelectedIds(idsInUrl ? new Set<number>(idsInUrl) : new Set<number>());

        const groupNameInUrl =
            window.location.href.indexOf(URL_VARS.FFLYER_GROUP_NAME) > -1
                ? decodeURIComponent(urlParams.get(URL_VARS.FFLYER_GROUP_NAME))
                : undefined;

        if (groupNameInUrl) {
            setGroup(result.Groups.find((g) => g.Name === groupNameInUrl));
            setGroupName(groupNameInUrl);

            return;
        }

        setGroupName(FAKE_CUG2_GROUP_NAME);
    };

    const initialGridState = (): GridState<keyof Partner> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        };
    };

    const isNameUnique = () =>
        !travelPartnerInfo.Groups.some(
            (g) => g.Name.toLowerCase().trim() === groupName.toLowerCase().trim() && g.Id !== group?.Id,
        );

    const showNonUniqueError = () => isValidated && groupName && !isNameUnique();

    const showRequiredError = () => isValidated && !groupName;

    const orderedModel = () =>
        travelPartnerInfo ? TravelPartnerHelper.getOrderedFilteredModel(travelPartnerInfo.Partners, gridState) : [];

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [group, setGroup] = useState<Group>(undefined);
    const [groupName, setGroupName] = useState<string>(undefined);
    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState());
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [isValidated, setIsValidated] = useState<boolean>(undefined);

    useEffect(init, []);

    // EVENT LISTENERS

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(GridHelper.getSelectedIds(event, selectedIds, orderedModel()) as Set<number>);
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        if (!groupName || !isNameUnique()) {
            return;
        }

        let newTravelPartnerInfo = travelPartnerInfo;
        let editedGroup = group;

        if (!editedGroup) {
            newTravelPartnerInfo = TravelPartnerHelper.updateGroup(newTravelPartnerInfo, {
                Id: undefined,
                Name: groupName,
            });

            handleCugLoader(root.current, "createGroup");
            newTravelPartnerInfo = await postTravelPartnerInfo(newTravelPartnerInfo);
            handleCugLoader(root.current, "createGroup");

            editedGroup = newTravelPartnerInfo.Groups.find((g) => g.Name === groupName);
        }

        newTravelPartnerInfo = TravelPartnerHelper.addPartnersToGroup(
            newTravelPartnerInfo,
            editedGroup.Id,
            Array.from(selectedIds.values()),
        );

        newTravelPartnerInfo = TravelPartnerHelper.updateGroup(newTravelPartnerInfo, {
            ...editedGroup,
            Name: groupName,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        window.location.href = ROUTES.Cug2BAdminTravelGroupsPage;
    };

    const handleGroupNameInput = (e: KeyboardEvent) => {
        const sanitizedInput = sanitizeInputFieldValue(e, "alphanumeric");
        setGroupName(sanitizedInput);
    };

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
            >
                ${i18next.t("Grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Crear un grupo")}</div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-grouping"></i>
            <h2>${i18next.t("Crear un Grupo")}</h2>
        </div>
    `;

    const nonUniqueErrorTemplate = () =>
        showNonUniqueError()
            ? html`
                  <div class="error-message-container">
                      <span>${i18next.t("Este nombre de grupo ya existe.")}</span>
                  </div>
              `
            : "";

    const requiredErrorTemplate = () =>
        showRequiredError()
            ? html`
                  <div class="error-message-container">
                      <span>${i18next.t("Este campo es requerido.")}</span>
                  </div>
              `
            : "";

    const groupNameTemplate = () => html`
        <div class="travel-partner-input-and-button pull-up">
            <div class="flex flex-col items-center sm:flex-row">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Nombre del grupo")}</label>
                    <input
                        class="mdl-textfield__input js-input"
                        maxlength="16"
                        value=${group?.Name || groupName}
                        @input=${handleGroupNameInput}
                        @blur=${handleGroupNameInput}
                    />
                </div>
            </div>
            ${nonUniqueErrorTemplate()} ${requiredErrorTemplate()}
        </div>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !groupName,
        });

        return html`
            <button class=${tempClassMap} data-test-id="create-group-submit-btn" @click=${handleSubmit}>
                ${i18next.t("Agregar al grupo")}
            </button>
        `;
    };

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => `${orderedModel()[index].FirstName} ${orderedModel()[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners?.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel().some((item) => item.Id === id))
                .map((id) =>
                    orderedModel()
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: false,
    };

    return groupName?.length >= 0 && travelPartnerInfo
        ? html`
              <div ref=${ref(root)}>
                  ${staticStuffTemplate()}
                  <form class="travel-partner-form">${groupNameTemplate()}</form>
                  <div class="travel-partner-stripe">
                      ${i18next.t("Selecciona los pasajeros que desear agregar a este grupo")}
                  </div>
                  <dc-table
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onRowsSelect=${onRowsSelect}
                  ></dc-table>

                  <div class="mt-8 flex w-full flex-col justify-end sm:flex-row md:mt-16">
                      ${submitButtonTemplate()}
                  </div>
                  <div class="hidden-xs mt-16 flex w-full justify-end sm:mt-32">${backButtonTemplate()}</div>
              </div>
              ${updateMdl()}
          `
        : html``;
};

import { JourneyLeg } from "../../component-models/shared/JourneyLeg";
import i18next from "i18next";
import { html } from "haunted";
import { JourneySegment } from "../../component-models/shared/JourneySegment";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import dayjs from "dayjs";
import { useModal } from "./useModal";

export interface Props {
    segments: JourneySegment[];
}

export const useMultisegmentModal = (props: Props) => {
    const firstSegment = () => (props.segments ? props.segments[0] : undefined);

    const firstSegmentFirstLeg = () => firstSegment()?.Legs[0];
    const firstSegmentLastLeg = () => firstSegment()?.Legs[firstSegment().Legs.length - 1];

    const secondSegment = () => (props.segments ? props.segments[props.segments.length - 1] : undefined);

    const secondSegmentFirstLeg = () => secondSegment()?.Legs[0];

    const getCityNameFromStationData = (station: string) =>
        station.includes(",") ? station.substring(0, station.indexOf(",")) : station;

    const getAirportNameFromStationData = (station: string) =>
        station.includes(",") ? station.substring(station.indexOf(",") + 2) : "";

    // DEVNOTE Sometimes the carrier code is already added to the
    // flight number, so we need to check if it's already there
    const failsafeFlightNumber = (leg: JourneyLeg) =>
        /[a-zA-Z]/.test(leg.FlightNumber) ? leg.FlightNumber : `${leg.CarrierCode} ${leg.FlightNumber}`;

    const takeoffTemplate = (segment: JourneySegment) => html`
        <div class="msm-endpoint-header">
            <i class="js-icon-covid js-cv-plane-up"></i>
            <div class="msm-endpoint-station">
                <div class="msm-endpoint-station-city">${getCityNameFromStationData(segment.DepartureStationName)}</div>
                <div class="msm-endpoint-station-airport">
                    ${getAirportNameFromStationData(segment.DepartureStationName)}
                </div>
                <div class="msm-endpoint-station-code">(${segment.DepartureStation})</div>
            </div>
        </div>
        <div class="msm-time ${segment.FormattedEstimatedDepartureTime ? "changed" : ""}">
            ${i18next.t("Salida")}:&nbsp;
            <div class="msm-time-value">
                <span class="relative">
                    ${dayjs(segment.Legs[0].FormattedStandardTimeOfDeparture).format("HH:mm")}
                    ${oneMoreDayTakeoffTemplate(segment)}
                </span>

                ${segment.FormattedEstimatedDepartureTime
                    ? html`
                          <div class="cf-journey-segment-time">
                              <i class="far fa-clock"></i>${dayjs(segment.FormattedEstimatedDepartureTime).format(
                                  "HH:mm",
                              )}
                              ${oneMoreDayChangedTakeoffTemplate(segment)}
                          </div>
                      `
                    : ""}
            </div>
        </div>
    `;

    const landingTemplate = (segment: JourneySegment) => html`
        <div class="msm-endpoint-header">
            <i class="js-icon-covid js-cv-plane-down"></i>
            <div class="msm-endpoint-station">
                <div class="msm-endpoint-station-city">${getCityNameFromStationData(segment.ArrivalStationName)}</div>
                <div class="msm-endpoint-station-airport">
                    ${getAirportNameFromStationData(segment.ArrivalStationName)}
                </div>
                <div class="msm-endpoint-station-code">(${segment.ArrivalStation})</div>
            </div>
        </div>
        <div class="msm-time ${segment.FormattedEstimatedArrivalTime ? "changed" : ""}">
            ${i18next.t("Llegada")}:&nbsp;
            <div class="msm-time-value">
                <span class="relative">
                    ${dayjs(segment.Legs[0].FormattedStandardTimeOfArrival).format("HH:mm")}
                    ${oneMoreDayLandingTemplate(segment)}
                </span>
                ${segment.FormattedEstimatedArrivalTime
                    ? html`
                          <div class="cf-journey-segment-time">
                              <i class="far fa-clock"></i>${dayjs(segment.FormattedEstimatedArrivalTime).format(
                                  "HH:mm",
                              )}
                              ${oneMoreDayChangedLandingTemplate(segment)}
                          </div>
                      `
                    : ""}
            </div>
        </div>
    `;

    const oneMoreDayTakeoffTemplate = (segment: JourneySegment) =>
        segment.FormattedDepartureDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayLandingTemplate = (segment: JourneySegment) =>
        segment.FormattedArrivalDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayChangedTakeoffTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedDepartureDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayChangedLandingTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedArrivalDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const flightTemplate = (leg: JourneyLeg) => html`
        <div class="msm-flight-number">${i18next.t("Vuelo")} ${failsafeFlightNumber(leg)}</div>
        <div class="msm-flight-time">
            <i class="far fa-clock"></i> ${i18next.t("{{hours}}h {{minutes}}min aprox.", {
                hours: leg.FlightDurationHours,
                minutes: leg.FlightDurationMinutes,
            })}
        </div>
        <div class="msm-operator">
            ${unsafeHTML(
                i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                    operatingCarrier: leg.CarrierNameToDisplay,
                }),
            )}
        </div>
    `;

    const transferTemplate = () => html`
        <div class="">
            <div class="msm-transfer-change">${i18next.t("Cambio de avión")}</div>
            <div class="msm-transfer-divider">
                <span>${i18next.t("Escala")}</span>
            </div>
            <div class="msm-transfer-info">
                <i class="js-icon-covid js-cv-waiting"></i>
                <div class="msm-transfer-details">
                    ${i18next.t(
                        "Tiempo estimado de espera en tu escala en {{station}} es de {{hours}}h {{minutes}}min.",
                        {
                            station: firstSegmentLastLeg().DestinationCity,
                            hours: secondSegment().StopDurationHours,
                            minutes: secondSegment().StopDurationMinutes,
                        },
                    )}
                </div>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="msm-segment">
                ${takeoffTemplate(firstSegment())} ${flightTemplate(firstSegmentFirstLeg())}
                ${landingTemplate(firstSegment())}
            </div>
            <div class="msm-transfer">${transferTemplate()}</div>
            <div class="msm-segment">
                ${takeoffTemplate(secondSegment())} ${flightTemplate(secondSegmentFirstLeg())}
                ${landingTemplate(secondSegment())}
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { template: htmlTemplate },
        header: { template: () => i18next.t("Itinerario de vuelo") },
        overlay: { classNames: "multisegment-modal" },
        scrolling: { isScrollable: true },
    });

    return modal;
};

import { JourneySegment } from "../../../component-models/shared/JourneySegment";
import { JourneyLeg } from "../../../component-models/shared/JourneyLeg";
import i18next from "i18next";
import { html } from "lit-html";
import { ItineraryJourney } from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { ACTION_NAMES, OUTBOUND } from "../../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { classMap } from "lit-html/directives/class-map";
import dayjs from "dayjs";
import { getLegDetails } from "../../../component-mappers/MultilegFlightLegInfoMapper";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { useMultisegmentModal } from "../useMultisegmentModal";
import { useMultilegModal } from "../useMultilegModal";
import { useFlowContext } from "../../../managers/useFlowContext";
import classNames from "classnames";

// DEVNOTE This is based on the assumption that no flight can be multisegment AND multileg,
export interface Props {
    isSelected?: boolean;
    journey: ItineraryJourney;
    showPill: boolean;
    showTimeChange: boolean;
    onCheckboxClick?: (index: number) => void;
}

interface StationData {
    airport: string;
    city: string;
}

export const useFlightJourneySummary = (props: Props) => {
    const flowContext = useFlowContext();

    // Helpers

    const firstSegment = () => props.journey?.Segments[0];

    const firstSegmentFirstLeg = () => firstSegment().Legs[0];

    const lastSegment = () => props.journey?.Segments[props.journey?.Segments.length - 1];

    const lastSegmentFirstLeg = () => lastSegment().Legs[0];

    const lastSegmentLastLeg = () => lastSegment().Legs[lastSegment().Legs.length - 1];

    const segmentEstimatedDepartureTime = (segment: JourneySegment) =>
        dayjs(segment.FormattedEstimatedDepartureTime).format("HH:mm");

    const segmentEstimatedArrivalTime = (segment: JourneySegment) =>
        dayjs(segment.FormattedEstimatedArrivalTime).format("HH:mm");

    const getCityAndAirportNameFromStationData = (station: string): StationData => {
        const [city, airport] = station.split(",");

        return {
            city: airport ? `${city}, ` : city,
            airport: airport?.trim() || "",
        };
    };

    // Event handlers

    const handleMultisegmentFlightTooltipOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        multisegmentModal.open();
    };

    const handleMultilegFlightTooltipOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        multilegModal.open();
    };

    // COMPONENT

    const multilegModal = useMultilegModal({
        legDetails: firstSegment()?.Legs.map((leg, i) => getLegDetails(firstSegment(), leg, i)) || [],
    });

    const multisegmentModal = useMultisegmentModal({
        segments: props.journey?.Segments || [],
    });

    const basicCheckbox = useBasicCheckbox({
        customWrapperClass: "flight-journey-summary-checkbox-wrapper",
        customLabelClass: "flight-journey-summary-checkbox-label",
        isChecked: props.isSelected,
        labelText: props.journey?.JourneyIndex === OUTBOUND ? i18next.t("Vuelo de IDA") : i18next.t("Vuelo de VUELTA"),
        onClick: () => props.onCheckboxClick(props.journey?.JourneyIndex),
    });

    // Common templates

    const originStationTemplate = (segment: JourneySegment, leg: JourneyLeg) => html`
        <div class="flight-journey-flight-left">
            <div>${i18next.t("Origen")}</div>
            <div
                class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}
                data-test-id=${getTestId(T.ITINERARY.DEPARTURE_STATION_CODE, { j: props.journey?.JourneyIndex })}
            >
                ${leg.OriginIata}
            </div>
            <div class="fjs-endpoint-station-container">
                <div class="fjs-endpoint-station-city">
                    ${getCityAndAirportNameFromStationData(leg.OriginCity).city}
                </div>
                <div class="fjs-endpoint-station-airport">
                    ${getCityAndAirportNameFromStationData(leg.OriginCity).airport}
                </div>
            </div>
            ${departureTemplate(segment)}
        </div>
    `;

    const destinationStationTemplate = (segment: JourneySegment, leg: JourneyLeg) => html`
        <div class="flight-journey-flight-right">
            <div>${i18next.t("Destino")}</div>
            <div
                class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}
                data-test-id=${getTestId(T.ITINERARY.ARRIVAL_STATION_CODE, { j: props.journey?.JourneyIndex })}
            >
                ${leg.DestinationIata}
            </div>
            <div>${leg.DestinationCity}</div>
            ${arrivalTemplate(segment)}
        </div>
    `;

    const departureTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedDepartureTime && props.showTimeChange
            ? html`
                  <div class="flight-journey-time changed">${segment.FormattedDepartureTime}</div>
                  <div class="cf-journey-segment-new-time">${i18next.t("Nueva hora")}</div>
                  <div class="cf-journey-segment-time">
                      <i class="far fa-clock"></i>${segmentEstimatedDepartureTime(segment)}
                      ${oneMoreDayChangedDepartureTemplate(segment)}
                  </div>
              `
            : html`
                  <div
                      class="flight-journey-time"
                      data-test-id=${getTestId(T.ITINERARY.DEPARTURE_TIME, { j: props.journey?.JourneyIndex })}
                  >
                      ${segment.FormattedDepartureTime} ${oneMoreDayDepartureTemplate(segment)}
                  </div>
              `;

    const arrivalTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedArrivalTime && props.showTimeChange
            ? html`
                  <div class="flight-journey-time changed">${segment.FormattedArrivalTime}</div>
                  <div class="cf-journey-segment-new-time">${i18next.t("Nueva hora")}</div>
                  <div class="cf-journey-segment-time">
                      <i class="far fa-clock"></i>${segmentEstimatedArrivalTime(segment)}
                      ${oneMoreDayChangedArrivalTemplate(segment)}
                  </div>
              `
            : html`
                  <div
                      class="flight-journey-time"
                      data-test-id=${getTestId(T.ITINERARY.ARRIVAL_TIME, { j: props.journey?.JourneyIndex })}
                  >
                      ${segment.FormattedArrivalTime} ${oneMoreDayArrivalTemplate(segment)}
                  </div>
              `;

    const oneMoreDayDepartureTemplate = (segment: JourneySegment) =>
        segment.FormattedDepartureDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayArrivalTemplate = (segment: JourneySegment) =>
        segment.FormattedArrivalDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayChangedDepartureTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedDepartureDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayChangedArrivalTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedArrivalDate !== firstSegment().FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const pillTemplate = () => html`
        <div class="flight-journey-pill">
            ${props.journey?.JourneyIndex === OUTBOUND ? i18next.t("Viaje de ida") : i18next.t("Viaje de vuelta")}
        </div>
    `;

    // One segment flight templates

    const directTooltipOpenerTemplate = () => html`
        <ac-tooltip .icon=${"?"} .isLight=${true} .tooltip=${directFlightTooltipTemplate()}></ac-tooltip>
        <div class="flight-journey-stops-number">${i18next.t("Directo")}</div>
    `;

    const multilegTooltipOpenerTemplate = () => {
        const legCount = firstSegment().Legs.length;

        return html`
            <div @click=${handleMultilegFlightTooltipOpen} class="flight-journey-stops-tooltip-opener">
                ${`+${legCount - 1}`}
            </div>
            <div @click=${handleMultilegFlightTooltipOpen} class="flight-journey-stops-number">
                ${i18next.t("{{amount}} parada{{plural}}", {
                    amount: legCount - 1,
                    plural: legCount > 2 ? "s" : "",
                })}
            </div>
        `;
    };

    const directFlightTooltipTemplate = () => html`
        <span class="direct-flight-tooltip">
            ${i18next.t("Vuelo")} ${firstSegmentFirstLeg().FlightNumber}
            <span>
                ${i18next.t("Tiempo estimado de vuelo {{hours}} hora{{hourLabel}} y {{minutes}} minutos", {
                    hours: firstSegmentFirstLeg().FlightDurationHours,
                    hourLabel: firstSegmentFirstLeg().FlightDurationHours === 1 ? "" : "s",
                    minutes: firstSegmentFirstLeg().FlightDurationMinutes,
                })}
            </span>
            <span>
                ${unsafeHTML(
                    i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                        operatingCarrier: firstSegmentFirstLeg().CarrierNameToDisplay,
                    }),
                )}
            </span>
        </span>
    `;

    const oneSegmentFlightTooltipOpenerTemplate = () =>
        firstSegment().Legs.length > 1 ? multilegTooltipOpenerTemplate() : directTooltipOpenerTemplate();

    const oneSegmentFlightPlanTemplate = () => html`
        ${originStationTemplate(firstSegment(), firstSegmentFirstLeg())}
        <div class="flight-journey-stops-info">
            <i class="js-icon js-flight-plane"></i>
            ${oneSegmentFlightTooltipOpenerTemplate()}
        </div>
        ${destinationStationTemplate(lastSegment(), lastSegmentLastLeg())}
    `;

    const oneSegmentTemplate = () =>
        props.journey?.Segments.length === 1
            ? html`
                  <div class="one-segment flex w-full flex-col md:w-2/3 lg:w-full">
                      ${!props.showPill ? html`<div>${basicCheckbox.htmlTemplate()}</div>` : ""}
                      <div class="flex flex-col sm:flex-row">
                          <div class="flight-journey-summary one-segment">
                              ${props.showPill ? pillTemplate() : ""}

                              <div class="flight-journey-summary-info">
                                  <div class="flight-journey-overview-info">
                                      ${i18next.t("Vuelo")} <span>${firstSegmentFirstLeg().FlightNumber}</span>
                                  </div>
                                  <div class="flight-journey-overview-info">
                                      ${i18next.t("Fecha")}
                                      <span>${dayjs(firstSegment().DepartureDate).format("DD MMM. YY")}</span>
                                  </div>
                              </div>
                          </div>
                          <div class="flight-journey-flight-plan one-segment">${oneSegmentFlightPlanTemplate()}</div>
                      </div>
                  </div>
              `
            : "";

    // Multisegment templates

    const multisegmentHeaderTemplate = () => html`
        <div class="cf-multisegment-journey-endpoint-header origin">
            <div class="fjs-endpoint-station-container">
                <div class="fjs-endpoint-station-city">
                    ${getCityAndAirportNameFromStationData(firstSegment().DepartureStationName).city}
                </div>
                <div class="fjs-endpoint-station-airport">
                    ${getCityAndAirportNameFromStationData(firstSegment().DepartureStationName).airport}
                </div>
            </div>
            <i class="js-icon js-chevron-right"></i>
            <div class="fjs-endpoint-station-container">
                <div class="fjs-endpoint-station-city">
                    ${getCityAndAirportNameFromStationData(firstSegment().ArrivalStationName).city}
                </div>
                <div class="fjs-endpoint-station-airport">
                    ${getCityAndAirportNameFromStationData(firstSegment().ArrivalStationName).airport}
                </div>
            </div>
        </div>
        <div class="cf-multisegment-journey-transfer-header">
            <i class="js-icon-covid js-cv-waiting"></i>
            <span>${i18next.t("Escala")}</span>
        </div>
        <div class="cf-multisegment-journey-endpoint-header destination">
            <div class="fjs-endpoint-station-container">
                <div class="fjs-endpoint-station-city">
                    ${getCityAndAirportNameFromStationData(lastSegment().DepartureStationName).city}
                </div>
                <div class="fjs-endpoint-station-airport">
                    ${getCityAndAirportNameFromStationData(lastSegment().DepartureStationName).airport}
                </div>
            </div>
            <i class="js-icon js-chevron-right"></i>
            <div class="fjs-endpoint-station-container">
                <div class="fjs-endpoint-station-city">
                    ${getCityAndAirportNameFromStationData(lastSegment().ArrivalStationName).city}
                </div>
                <div class="fjs-endpoint-station-airport">
                    ${getCityAndAirportNameFromStationData(lastSegment().ArrivalStationName).airport}
                </div>
            </div>
        </div>
    `;

    const multisegmentEndpointTemplate = (segment: JourneySegment, leg: JourneyLeg, type: "start" | "end") => {
        const tempClassMap = classMap({
            "cf-multisegment-journey-endpoint": true,
            "origin": type === "start",
            "destination": type === "end",
        });

        const journeyTimeClassMap = classMap({
            "absolute": true,
            "bottom-0": true,
            "hidden-xs": true,
            "right-0": type === "start",
            "left-0": type === "end",
        });

        const mobileOnlyDepartureStationClassMap = classMap({
            "hidden-sm-up": type === "end",
        });

        const mobileOnlyDestinationStationClassMap = classMap({
            "hidden-sm-up": type === "start",
        });

        const segmentNumberContent = type === "start" ? i18next.t("Primer tramo") : i18next.t("Segundo tramo");

        return html`
            <div class=${tempClassMap}>
                <div class="cf-multisegment-journey-info">
                    <span>
                        ${i18next.t("Vuelo")}
                        <span> ${leg.FlightNumber} </span>
                    </span>
                    <span>
                        ${i18next.t("Fecha")}
                        <span> ${dayjs(segment.DepartureDate).format("DD MMM. YY")} </span>
                    </span>
                </div>
                <div class="flex w-full items-stretch justify-between">
                    <div class=${mobileOnlyDepartureStationClassMap}>${originStationTemplate(segment, leg)}</div>
                    <div class="cf-multisegment-journey-decor">
                        <div class="cf-decor-plane">
                            <i class="js-icon js-flight-plane"></i>
                        </div>
                        <span class="cf-decor-info">${segmentNumberContent}</span>
                        <div class=${journeyTimeClassMap}>
                            ${type === "start" ? arrivalTemplate(segment) : departureTemplate(segment)}
                        </div>
                    </div>
                    <div class=${mobileOnlyDestinationStationClassMap}>${destinationStationTemplate(segment, leg)}</div>
                </div>
            </div>
        `;
    };

    const multisegmentTransferTemplate = () => html`
        <div class="cf-multisegment-journey-transfer">
            <div>
                <div
                    class=${classNames("cf-multisegment-journey-transfer-iata", {
                        notranslate: flowContext.action === ACTION_NAMES.ITINERARY,
                    })}
                >
                    ${firstSegment().ArrivalStation}
                </div>
                <div class="cf-multisegment-journey-transfer-name">${firstSegment().ArrivalStationName}</div>
            </div>
            <div>
                <div class="cf-multisegment-journey-stops">
                    <div
                        class="cf-multisegment-journey-stops-tooltip-opener"
                        @click=${handleMultisegmentFlightTooltipOpen}
                    >
                        ?
                    </div>
                    ${i18next.t("Cambio de avión")}
                </div>
                <div class="cf-multisegment-journey-transfer-time">
                    ${unsafeHTML(
                        i18next.t("Tiempo estimado de espera: <span>{{hours}}h {{minutes}}min</span>", {
                            hours: lastSegment().StopDurationHours,
                            minutes: lastSegment().StopDurationMinutes,
                        }),
                    )}
                </div>
            </div>
        </div>
    `;

    const multisegmentTemplate = () =>
        props.journey?.Segments.length > 1
            ? html`
                  <div class="multisegment">
                      ${props.showPill ? pillTemplate() : html`<div>${basicCheckbox.htmlTemplate()}</div>`}
                      <div class="cf-multisegment-journey-grid">
                          ${multisegmentHeaderTemplate()}
                          ${multisegmentEndpointTemplate(firstSegment(), firstSegmentFirstLeg(), "start")}
                          ${multisegmentTransferTemplate()}
                          ${multisegmentEndpointTemplate(lastSegment(), lastSegmentFirstLeg(), "end")}
                      </div>
                  </div>
              `
            : "";

    // DEVNOTE This is based on the assumption that no flight can be multisegment AND multileg,
    const htmlTemplate = () =>
        props.journey
            ? html`
                  ${oneSegmentTemplate()} ${multisegmentTemplate()} ${multisegmentModal.htmlTemplate()}
                  ${multilegModal.htmlTemplate()}
              `
            : "";

    return { htmlTemplate, isModalOpen: multisegmentModal.isOpen || multilegModal.isOpen };
};

import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { UserInfo } from "../../../component-models/UserInfo";
import { PeruCompraMemberData } from "../../../component-models/PeruCompraMemberData";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const name = "ac-cug-user-info";
export interface Properties {
    model: UserInfo;
    peruCompraModel: PeruCompraMemberData;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        model: host.model,
        peruCompraModel: host.peruCompraModel,
    };

    const [userContext] = useReduxState("userContext");

    const fullNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Nombre y Apellido")}</header>
            <article>${props.model.Name}</article>
        </div>
    `;

    const phoneTemplate = () =>
        html` <div class="cug2b-profile-item">
            <header>${i18next.t("Teléfono")}</header>
            <article>${props.model.HomePhome}</article>
        </div>`;

    const emailTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Correo electrónico")}</header>
            <article class="break-all">${props.model.Email}</article>
        </div>
    `;

    const userNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("User Name")}</header>
            <article class="break-all">${props.model.UserName}</article>
        </div>
    `;

    const passwordTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Contraseña")}</header>
            <article>************</article>
        </div>
    `;

    const dniTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("DNI")}</header>
            <article class="break-all">${props.model.UserName}</article>
        </div>
    `;

    const officePhoneTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Office Phone")}</header>
            <article class="break-all">${props.peruCompraModel?.BusinessPhone?.Number}</article>
        </div>
    `;

    const mobilePhoneTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Teléfono móvil")}</header>
            <article class="break-all">${props.peruCompraModel?.MobilePhone?.Number}</article>
        </div>
    `;

    return userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
        ? html`<div class="cug2b-profile-table">
              <div class="cug2b-profile-line">${fullNameTemplate()} ${dniTemplate()}</div>
              <div class="cug2b-profile-line">${officePhoneTemplate()} ${mobilePhoneTemplate()}</div>
              <div class="cug2b-profile-line">${emailTemplate()} ${passwordTemplate()}</div>
          </div>`
        : html`
              <div class="cug2b-profile-table">
                  <div class="cug2b-profile-line">${fullNameTemplate()} ${phoneTemplate()}</div>
                  <div class="cug2b-profile-line">${emailTemplate()} ${userNameTemplate()} ${passwordTemplate()}</div>
              </div>
          `;
};

import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import i18next from "i18next";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useSeatPassengerHelper } from "../../../../component-helpers/seatmap/useSeatPassengerHelper";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const name = "ac-mobile-seat-passenger";

export interface Props {
    apiPassenger: ApiSeatmapPassenger;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    journeyIndex: number;
    seatmapState: SeatmapState;
    segmentIndex: number;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    onPassengerSelect: (paxNumber: number) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        apiPassenger: host.apiPassenger,
        context: host.context,
        currentSeats: host.currentSeats,
        journeyIndex: host.journeyIndex,
        seatmapState: host.seatmapState,
        segmentIndex: host.segmentIndex,
        handleSeatDelete: host.handleSeatDelete,
        onPassengerSelect: host.onPassengerSelect,
    };

    const { getSeatNumberForPaxForSegment } = useSeatPassengerHelper({
        context: props.context,
        currentSeats: props.currentSeats,
        seatmapState: props.seatmapState,
    });

    // HELPERS

    const isSeatForPaxForSegment = (seat: ExtendedApiSeat) =>
        seat.PassengerNumber === props.apiPassenger.PassengerNumber &&
        seat.JourneyIndex === props.journeyIndex &&
        seat.SegmentIndex === props.segmentIndex;

    const isSeatFreeForPax = () =>
        !props.seatmapState?.CurrentJourney?.AreAllSeatsInBundle &&
        props.currentSeats?.some((s) => isSeatForPaxForSegment(s) && s.IsFree);

    const isSeatDiscountedForPax = () =>
        !props.seatmapState?.CurrentJourney?.AreAllSeatsInBundle &&
        props.currentSeats?.some((s) => isSeatForPaxForSegment(s) && s.IsDiscounted && !s.IsFree);

    const seatNumber = () => getSeatNumberForPaxForSegment(props.apiPassenger);

    const getPaxName = () => (passengerNames ? passengerNames[props.apiPassenger.PassengerNumber] : "");

    // COMPONENT

    const flowContext = useFlowContext();

    const [passengerNames] = useReduxState("booking.passengerNames");

    // EVENT LISTENERS

    const handleClickPassenger = () => {
        if (props.context.Model.IsReadOnly) {
            return;
        }

        props.onPassengerSelect(props.apiPassenger.PassengerNumber);
    };

    // TEMPLATES

    const mobileDiscountedSeatTemplate = () =>
        isSeatDiscountedForPax() ? html` <div class="mobs-discount-info">${i18next.t("40% Dcto")}</div> ` : "";

    const mobileFreeSeatTemplate = () =>
        isSeatFreeForPax() ? html` <div class="mobs-discount-info">${i18next.t("Gratis")}</div> ` : "";

    const numberClassMap = classMap({
        "mobs-seat": true,
        "selected": seatNumber(),
    });

    const nameDataTestId = getTestId(T.SEATMAP.MOBILE_PASSENGER_NAME_AND_SEAT_CONTAINER, {
        p: props.apiPassenger.PassengerNumber,
        j: props.journeyIndex,
        s: props.segmentIndex,
        m: true,
    });

    const numberDataTestId = getTestId(T.SEATMAP.MOBILE_PASSENGER_SEAT_NUMBER, {
        p: props.apiPassenger.PassengerNumber,
        j: props.journeyIndex,
        s: props.segmentIndex,
        m: true,
    });

    return html`
        <div class="hidden-lg-up h-full w-full" @click=${!flowContext.isCheckinFlow && handleClickPassenger}>
            <div class="mobs-name" data-test-id=${nameDataTestId} data-test-value=${seatNumber()}>${getPaxName()}</div>
            <div class=${numberClassMap} data-test-id=${numberDataTestId}>${seatNumber()}</div>
            ${mobileFreeSeatTemplate()} ${mobileDiscountedSeatTemplate()}
        </div>
    `;
};

import { useEffect } from "./../shared/haunted/CustomHooks";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html } from "haunted";

export const name = "ac-page-reloader";

export const observedAttributes: (keyof Attributes)[] = ["seconds"];

export interface Attributes {
    seconds: string;
}

export interface Props {
    seconds: number;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        seconds: Number(host.seconds),
    };

    useEffect(() => {
        const timer = window.setTimeout(() => {
            window.location.reload();
        }, props.seconds * 1000);

        return () => window.clearTimeout(timer);
    }, []);

    return html``;
};

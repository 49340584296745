import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PetInCabinOptionClickedEvent } from "../../../../component-models/extras/pet-in-cabin/PetInCabinEvents";
import { PetInCabinJourneyModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { PetInCabinModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinModel";
import { useEffect } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { html } from "lit-html";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { desktopJourneyRoutesTemplate, journeyRouteTemplate } from "../../../../common-templates/ExtrasCommonTemplates";
import { PassengerSelection } from "../../../../component-models/extras/PassengerSelection";
import { mapToPassengerSelections } from "../../../../component-mappers/ExtrasCommonMappers";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";

export interface Props {
    model: PetInCabinModel;
    termsUrl: string;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const usePetInCabin = (props: Props) => {
    const handleClick = (e: PetInCabinOptionClickedEvent) => {
        const newState = [
            ...passengerSelections.filter(
                (ps) => ps.journeyIndex !== e.detail.journeyIndex || ps.paxIndex !== e.detail.paxIndex,
            ),
            { ...e.detail },
        ];

        setPassengerSelections(newState);
    };

    const [passengerSelections, setPassengerSelections] = useState<PassengerSelection[]>(undefined);

    useEffect(() => setPassengerSelections(mapToPassengerSelections(props.model?.Journeys)), [
        JSON.stringify(props.model?.Journeys),
    ]);

    const headerTemplate = () => html`
        <header>
            <i class="js-pet js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_PET_IN_CABIN.TITLE}>${i18next.t("V2-PetInCabinTitle")}</h2>
                <div class="subtitle" data-test-id=${T.EXTRAS_PET_IN_CABIN.SUBTITLE}>
                    ${i18next.t("V2-PetInCabinInfo")}
                </div>
            </div>
        </header>
    `;

    const journeysTemplate = () =>
        props.model?.Journeys.map((journey) => {
            const tempClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.model?.Journeys.length === 1,
            });

            return html`
                <div
                    class=${tempClassMap}
                    data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.JOURNEY, { j: journey.JourneyIndex })}
                >
                    <div class="inner-box bottom-half padded-bottom-half ts-error-parent ts-error-container">
                        <h3 class="package-title visible-xs">
                            ${journeyRouteTemplate(journey)}
                        </h3>

                        ${passengersTemplate(journey)}
                    </div>
                </div>
            `;
        });

    const passengersTemplate = (journey: PetInCabinJourneyModel) =>
        journey.Passengers.map(
            (passenger) =>
                html`
                    <ac-pet-in-cabin
                        .journey=${journey}
                        .passengerOption=${passenger}
                        .passengerSelections=${passengerSelections}
                        .setExtrasModel=${props.setExtrasModel}
                        @select=${handleClick}
                    ></ac-pet-in-cabin>
                `,
        );

    const footerTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <a class="pet-terms-link" href=${props.termsUrl} target="_blank" data-test-id=${T.EXTRAS_PET_IN_CABIN.TERMS_LINK}>
                    ${i18next.t("PetTermsLink")}
                </a>
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        html`
            <section
                class="booking-wrapper extras-step ts-pet-in-cabin ts-error-container ${LOADER_CLASS_NAMES.Pet}"
                data-test-id=${T.EXTRAS_PET_IN_CABIN.CONTAINER}
            >
                ${headerTemplate()}

                <div class="row hidden-xs">
                    ${desktopJourneyRoutesTemplate(props.model?.Journeys)}
                </div>
                <div class="row">
                    ${journeysTemplate()}
                </div>
                ${footerTemplate()}
            </section>
        `;

    return { htmlTemplate };
};

import dayjs from "dayjs";
import { JourneyLeg } from "../component-models/shared/JourneyLeg";
import { MultilegFlightLegInfo } from "../component-models/shared/MultilegFlightLegInfo";
import { JourneySegment } from "../component-models/shared/JourneySegment";

export const getLegDetails = (segment: JourneySegment, leg: JourneyLeg, i: number): MultilegFlightLegInfo => ({
    Arrival: dayjs(leg.FormattedEstimatedTimeOfArrival || leg.FormattedStandardTimeOfArrival).format("HH:mm"),
    CarrierNameToDisplay: leg.CarrierNameToDisplay,
    Departure: dayjs(leg.FormattedEstimatedTimeOfDeparture || leg.FormattedStandardTimeOfDeparture).format("HH:mm"),
    DestinationCity: leg.DestinationCity,
    DestinationIata: leg.DestinationIata,
    FlightNumber: failsafeFlightNumber(leg),
    FlightTimeHours: leg.FlightDurationHours.toString(),
    FlightTimeMinutes: leg.FlightDurationMinutes.toString(),
    OriginCity: leg.OriginCity,
    OriginIata: leg.OriginIata,
    StopTimeHours: i < segment.Legs.length - 1 ? segment.Legs[i + 1].StopDurationHours.toString() : undefined,
    StopTimeMinutes: i < segment.Legs.length - 1 ? segment.Legs[i + 1].StopDurationMinutes.toString() : undefined,
});

// DEVNOTE Sometimes the carrier code is already added to the
// flight number, so we need to check if it's already there
const failsafeFlightNumber = (leg: JourneyLeg) =>
    /[a-zA-Z]/.test(leg.FlightNumber) ? leg.FlightNumber : `${leg.CarrierCode} ${leg.FlightNumber}`;

import { useEffect, useState } from "../../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { ROUTES } from "../../../../shared/apiRoutes";
import { getAbbreviatedNameByPaxNumber } from "../../../../shared/common";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import i18next from "i18next";
import { useTealiumManager } from "../../../../managers/Tealium/useTealiumManager";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { useSeatmapWarning } from "../side-panel/useSeatmapWarning";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { useMobileJourneySelector } from "../passengers/useMobileJourneySelector";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    confirmMobileSeats: () => void;
    handleMobileNoSeats: (e: MouseEvent) => void;
    mobileSeatReviewClose: () => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const useMobileReviewSeatmap = (props: Props) => {
    // HELPERS

    const displayedJourney = () => props.context.Model?.Journeys.find((j) => j.JourneyIndex === displayedJourneyIndex);

    const termsNotAccepted = () =>
        !seatmapWarning.isTermsAcceptanceCheckboxChecked &&
        SeatmapHelper.isAnySelectedSeatInExitRow(props.currentSeats);

    const checkinUrl = () => `${ROUTES.PassengersCheckin}?sk=${props.context.Model.SellKeyForCheckinUrl}`;

    const showNoSeatsButton = () => props.context.CheckinFlowType === "none";

    const totalPrice = () =>
        props.currentSeats
            .filter((seat) => !seat.IsFree && !seat.IsAutoAssigned && seat.PassengerNumber >= 0)
            .reduce((aggr, curr) => aggr + curr.Price, 0);

    const totalPriceDifference = () =>
        SeatmapHelper.journeyTotalPriceDifference(
            props.currentSeats,
            props.context.Model.Journeys[OUTBOUND],
            userContext.bancoEstado.category === 6,
        ) +
        SeatmapHelper.journeyTotalPriceDifference(
            props.currentSeats,
            props.context.Model.Journeys[INBOUND],
            userContext.bancoEstado.category === 6,
        );

    const formattedTotalPrice = () => formatNumber({ amount: props.currentSeats ? totalPrice() : 0 });

    const formattedTotalPriceDifference = () =>
        formatNumber({ amount: props.currentSeats ? totalPriceDifference() : 0 });

    const logTealiumError = () => {
        tealiumManager.logValidationError(["emergency exit seat terms not accepted"]);
    };

    // COMPONENT

    const flowContext = useFlowContext();

    const tealiumManager = useTealiumManager();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [passengerNames] = useReduxState("booking.passengerNames");

    const [displayedJourneyIndex, setDisplayedJourneyIndex] = useState<number>(undefined);

    const seatmapWarning = useSeatmapWarning({
        context: props.context,
        currentSeats: props.currentSeats,
        isMobile: true,
        seatmapState: props.seatmapState,
    });

    const journeySelector = useMobileJourneySelector({
        context: props.context,
        seatmapState: { ...props.seatmapState, CurrentJourney: displayedJourney() },
        handleInboundSelect: () => setDisplayedJourneyIndex(INBOUND),
        handleOutboundSelect: () => setDisplayedJourneyIndex(OUTBOUND),
    });

    // EVENT LISTENERS

    const confirmMobileSeats = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (termsNotAccepted()) {
            seatmapWarning.showAcceptanceWarning();
            logTealiumError();
            return;
        }

        props.confirmMobileSeats();
    };

    useEffect(() => {
        if (props.context.Model && displayedJourneyIndex === undefined) {
            setDisplayedJourneyIndex(
                props.context.Model.CheckedJourneyIndex > -1 ? props.context.Model.CheckedJourneyIndex : 0,
            );
        }
    }, [props.context.Model, displayedJourneyIndex]);

    // TEMPLATES

    const tabletButtonsTemplate = () => html`
        <div class="row mobile-review-tablet-buttons">
            <div class="col-sm-1-3">${totalTemplate()}</div>
            <div class="col-sm-1-3 text-center">${noSeatsButtonTemplate()}</div>
            <div class="col-sm-1-3 flex justify-end">${continueButtonTemplate()}</div>
        </div>
    `;

    const mobileButtonsTemplate = () => html`
        <div class="row mobile-review-mobile-buttons">
            <div class="col-xs-1 text-center">${totalTemplate()}</div>
            <div class="col-xs-1 flex justify-center">${continueButtonTemplate()}</div>
            <div class="col-xs-1 text-center">${noSeatsButtonTemplate()}</div>
        </div>
    `;

    const totalTemplate = () =>
        !props.context.Model.IsReadOnly
            ? html`
                  <span
                      class="seat-total"
                      data-test-id=${T.SEATMAP.REVIEW_SEAT_TOTAL_MOBILE}
                      data-test-value=${flowContext.isPostBookingFlow ? totalPriceDifference() : totalPrice()}
                  >
                      ${window.seatmapResources.seatsTotalMobileText}:
                      ${flowContext.isPostBookingFlow ? formattedTotalPriceDifference() : formattedTotalPrice()}
                  </span>
              `
            : "";

    const noSeatsButtonTemplate = () =>
        !props.context.Model.IsReadOnly && showNoSeatsButton()
            ? html`
                  <a @click=${props.handleMobileNoSeats} data-test-id=${T.SEATMAP.REVIEW_NO_SEAT_BUTTON_MOBILE}>
                      ${window.seatmapResources.noSeatsMobileText}
                  </a>
              `
            : "";

    const continueButtonTemplate = () =>
        props.context.Model.IsReadOnly
            ? html`
                  <a
                      class="rounded-primary-btn review-seats"
                      data-test-id=${T.SEATMAP.REVIEW_MAP_CONTINUE_BUTTON_MOBILE}
                      href=${checkinUrl()}
                  >
                      ${window.seatmapResources.continueText}
                  </a>
              `
            : html`
                  <button
                      class="rounded-primary-btn review-seats"
                      data-test-id=${T.SEATMAP.REVIEW_SEATS_CONFIRM_BUTTON_MOBILE}
                      @click=${confirmMobileSeats}
                  >
                      ${window.seatmapResources.confirmText}
                  </button>
              `;

    const warningTemplate = () => (!props.context.Model.IsReadOnly ? seatmapWarning.htmlTemplate() : "");

    const tableHeaderTemplate = () => html`
        <div class="mobile-review-row">
            <div class="mobile-review-col">${i18next.t("Pasajeros")}</div>
            ${displayedJourney().Segments.map(
                (segment) => html`
                    <div class="mobile-review-col">
                        <div class="flex flex-col items-center justify-center">
                            <div class="mobile-review-flight-number">
                                ${i18next.t("Vuelo")} ${segment.CarrierCode} ${segment.FlightNumber}
                            </div>
                            <div class="mobile-review-segment-endpoints">
                                ${segment.DepartureStationCode} - ${segment.ArrivalStationCode}
                            </div>
                        </div>
                    </div>
                `,
            )}
        </div>
    `;

    const tableRowsTemplate = () =>
        props.context.Model.Passengers.map(
            (pax) => html`
                <div class="mobile-review-row">
                    <div class="mobile-review-col">
                        ${getAbbreviatedNameByPaxNumber(passengerNames, pax.PassengerNumber)}
                    </div>
                    ${displayedJourney().Segments.map((segment) => {
                        const seatForPax = props.currentSeats.find(
                            (seat) =>
                                seat.PassengerNumber === pax.PassengerNumber &&
                                seat.JourneyIndex === displayedJourneyIndex &&
                                seat.SegmentIndex === segment.SegmentIndex,
                        );
                        return html`
                            <div class="mobile-review-col">${seatForPax?.Row || "--"}${seatForPax?.Column}</div>
                        `;
                    })}
                </div>
            `,
        );

    const mainClassMap = classMap({
        "mobile-review-seatmap": true,
        "banco-estado-seat-review": [1, 2, 3, 7].includes(userContext.bancoEstado.category),
        "pushdown": !props.context.Model?.IsReadOnly,
    });

    const htmlTemplate = () => html`
        <div>
            <div class=${mainClassMap}>
                <div>
                    <header>
                        <span
                            class="direction-switch"
                            data-test-id=${T.SEATMAP.REVIEW_BACK_BUTTON_MOBILE}
                            @click=${props.mobileSeatReviewClose}
                        >
                            &#10096;
                        </span>
                        <span> ${window.seatmapResources.reviewText} </span>
                        <span></span>
                    </header>
                    <div class="mobile-review-journey-selector">${journeySelector.htmlTemplate()}</div>
                    <div class="mobile-review-table">${tableHeaderTemplate()} ${tableRowsTemplate()}</div>

                    ${warningTemplate()} ${mobileButtonsTemplate()} ${tabletButtonsTemplate()}
                </div>
            </div>
        </div>
    `;

    return {
        isTermsAcceptanceCheckboxChecked: seatmapWarning.isTermsAcceptanceCheckboxChecked,
        htmlTemplate,
        showAcceptanceWarning: seatmapWarning.showAcceptanceWarning,
    };
};

import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { sanitizeRutFieldValue, showLoader } from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { validateRut } from "../../shared/form-validation";
import { useEffect } from "../../shared/haunted/CustomHooks";

export interface Props {
    isActive: boolean;
    parentElement: HTMLDivElement;
    handleOnRutBack: () => void;
    handleOnExistingUser: () => void;
}

export const useGiftcardActivationRUT = (props: Props) => {
    // HELPERS
    const autoFormatRut = () => sanitizeRutFieldValue(rut.current);

    const isRutValid = () => rut.current?.value && validateRut(rut.current?.value);

    // EVENT HANDLERS
    const handleRutInput = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleRutSubmit();
        }
    };

    const handleRutSubmit = async () => {
        setShowRUTFormatError(false);

        if (isRutValid()) {
            await handleValidRutSubmit();
        } else {
            setShowRUTFormatError(true);
        }
    };

    const handleValidRutSubmit = async () => {
        const loader = showLoader({});
        await ajaxRequest({
            loader,
            container: props.parentElement,
            method: "POST",
            url: `${ROUTES.ApiRoutes.CheckUserExist}`,
            body: {
                Rut: rut.current?.value,
            },
            isJsonData: true,
            onResponseCode: {
                200: async (result) => {
                    const doesUserExist = JSON.parse(result).IsUserExist;

                    if (doesUserExist) {
                        props.handleOnExistingUser();
                    } else {
                        await handleNewBancoEstadoUser(loader);
                    }
                },
            },
        });
    };

    const handleNewBancoEstadoUser = async (loader: JsLoader) => {
        await ajaxRequest({
            loader,
            container: props.parentElement,
            method: "GET",
            url: `${ROUTES.LoginBancoEstado}?rut=${rut.current?.value}`,
            onResponseCode: {
                200: (result) => {
                    const redirectUrl = JSON.parse(result).BaseUrl;
                    const loginEndPoint = JSON.parse(result).LoginEndpoint;
                    const jwt = JSON.parse(result).JWT;
                    if (redirectUrl) {
                        window.location.href = `${redirectUrl}${loginEndPoint}${jwt}`;
                    }
                },
            },
        });
    };

    // COMPONENT
    const root = useRef<HTMLDivElement>(undefined);
    const rut = useRef<HTMLInputElement>(undefined);

    const { ajaxRequest } = useAjax();

    const [showRUTFormatError, setShowRUTFormatError] = useState<boolean>(false);

    useEffect(() => (props.isActive ? rut.current?.focus() : null), [props.isActive]);

    // TEMPLATES
    const rutFormatErrorTemplate = () => html` <div class="gca-error">${i18next.t("CUG-TT-RutFormatTip")}</div> `;

    const emailLinkTemplate = () => html`
        <div class="gca-container">
            <div class="gca-email-link disabled">${i18next.t("Gift-RutBeLink")}</div>
        </div>
    `;

    const emailWarningTemplate = () => html`
        <div class="gca-email-warning">
            ${unsafeHTML(
                i18next.t("Gift-RutWarning {{-spanStart}}{{-spanEnd}}{{-br}}", {
                    spanStart: "<span>",
                    spanEnd: "</span>",
                    br: "<br />",
                }),
            )}
        </div>
    `;

    const buttonContainerTemplate = () => html`
        <div class="gca-container">
            <div class="gca-btn-container">
                <button class="rounded-secondary-btn mr-2" @click=${props.handleOnRutBack}>
                    ${i18next.t("Gift-Cancel")}
                </button>
                <button
                    class="rounded-primary-btn"
                    data-test-id="gc-activate-rut-submit-button"
                    @click=${handleRutSubmit}
                >
                    ${i18next.t("Gift-Continue")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "step-form": true,
            "gca-enter-rut": true,
            "active": props.isActive,
        });

        return html`
            <div ref=${ref(root)} class=${mainClassMap}>
                <div class="giftcard-activation-step-info">${i18next.t("Gift-StepInfo-2B")}</div>
                <div class="gca-container">
                    <div class="gca-enter-code-label visible-xs">${i18next.t("Gift-StepInfo-2B")}</div>
                    <input
                        ref=${ref(rut)}
                        type="text"
                        placeholder=${i18next.t("Gift-EnterRutPlaceholder")}
                        data-test-id="gc-activate-rut"
                        @input=${autoFormatRut}
                        @keydown=${handleRutInput}
                    />
                </div>

                ${showRUTFormatError ? rutFormatErrorTemplate() : ""} ${emailLinkTemplate()} ${emailWarningTemplate()}
                ${buttonContainerTemplate()}
            </div>
        `;
    };

    return { htmlTemplate, rut: rut.current };
};

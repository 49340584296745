export class JetSmartEvent {
    public static IncreaseBaggageCount = "IncreaseBaggageCount";
    public static DecreaseBaggageCount = "DecreaseBaggageCount";
    public static PromoCodeAdded = "PromoCodeAdded";
    public static ContinueClick = "ContinueClick";
    public static SidebarReloaded = "SidebarReloaded";

    public name: string;
    public params: any;
}

import { html } from "haunted";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { GridHelper } from "../../component-helpers/GridHelper";
import { StaffBenefitFamilyItem } from "../../component-models/staff-benefit/StaffBenefitFamilyItem";
import { Column, GridState, PageChangeEvent, SortChangeEvent, ViewModel } from "../../dc-components/dc-table-models";
import { useState } from "../../shared/haunted/CustomHooks";
import { ROUTES } from "../../shared/apiRoutes";
import { StaffBenefitFamily } from "../../component-models/staff-benefit/StaffBenefitFamily";
import { getParsedProperty } from "../../shared/common";

export const name = "ac-staff-family-members";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
    model: string;
}

export interface Props {
    model: StaffBenefitFamily;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model:
            typeof host.model === "string" && (host.model as unknown as string).trim().length > 0
                ? getParsedProperty<StaffBenefitFamily>(host.model)
                : { FamilyMembers: [], UserId: "", ErrorMessage: "" },
    };

    // HELPERS

    const orderedModel = () =>
        props.model.FamilyMembers ? GridHelper.getOrderedFilteredModel(props.model.FamilyMembers, gridState) : [];

    const initialGridState = (): GridState<keyof StaffBenefitFamilyItem> => ({
        pageIndex: 0,
        appliedFilters: [],
        pageSize: props.model.FamilyMembers?.length < 6 ? props.model.FamilyMembers.length : 5,
        orderBy: "LastName",
        orderDir: "asc",
        globalFilterExpression: "",
    });

    // EVENT HANDLERS

    const handlePageChange = (e: PageChangeEvent) => {
        setGridState({ ...gridState, pageIndex: e.detail.selectedPageIndex, pageSize: e.detail.selectedPageSize });
    };

    const handleSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof StaffBenefitFamilyItem,
            orderDir: e.detail.orderDir,
        });
    };

    // COMPONENT

    const [gridState, setGridState] = useState<GridState<keyof StaffBenefitFamilyItem>>(initialGridState());

    // TEMPLATE

    const employeDiscountHeaderTemplate = () => html`
        <div class="sb-header">
            <div class="sb-header-text">
                <div>${i18next.t("Conéctate")}</div>
                <div class="sb-header-smart">SMART</div>
            </div>
        </div>
    `;

    const nonMobileHeaderTemplate = () => html`
        <div class="sb-family-title hidden-xs">
            <i class="js-circle-user js-icon"></i>
            <div>
                <h1>${i18next.t("Beneficiarios")}</h1>
                <div>
                    ${i18next.t(
                        "Revisa aquí a todos los beneficiarios que están incluídos para el beneficio. Con esto, cuando hagas una reserva, solo deberás elegir qué pasajeros volarán y ¡listo!",
                    )}
                    <span class="font-bold">${i18next.t("¿SMART, verdad?")}</span>
                </div>
            </div>
        </div>
    `;

    const mobileHeaderTemplate = () => html`
        <div class="sb-family-title hidden-sm-up">
            <i class="js-circle-user js-icon"></i>
            <h1>${i18next.t("Beneficiarios")}</h1>
        </div>
        <div class="sb-family-title-description hidden-sm-up">
            ${i18next.t(
                "Revisa aquí a todos los beneficiarios que están incluídos para el beneficio. Con esto, cuando hagas una reserva, solo deberás elegir qué pasajeros volarán y ¡listo!",
            )}
            <span class="font-bold">${i18next.t("¿SMART, verdad?")}</span>
        </div>
    `;

    const employeDiscountContentTemplate = () => html`
        <div class="sb-content">
            <div class="sb-family-container">
                <div class="sb-breadcrumbs">
                    <span>${i18next.t("Conéctate SMART!")}</span>&nbsp;/&nbsp;
                    <span>${i18next.t("Beneficiarios")}</span>
                </div>
                ${nonMobileHeaderTemplate()} ${mobileHeaderTemplate()} ${tableTemplate()}
            </div>
            <div class="sb-family-back-button-container">
                <a class="rounded-reverse-btn" href=${ROUTES.PageStaffBenefit}> ${i18next.t("Volver")} </a>
            </div>
        </div>
    `;

    const nameCellTemplate = (index: number) => {
        const row = orderedModel()[index];

        return html` <div class="hover:text-red cursor-pointer" }>${row.FirstName} ${row.LastName}</div> `;
    };

    const vm: ViewModel<keyof StaffBenefitFamilyItem> = {
        data: orderedModel(),
        forcedNumberOfRows: gridState.pageSize,
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: nameCellTemplate,
            } as Column<keyof StaffBenefitFamilyItem>,
            {
                field: "Id",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof StaffBenefitFamilyItem>,
        ],
        paging: {
            pageable: props.model.FamilyMembers
                ? GridHelper.getFilteredTotalNumber(props.model.FamilyMembers, gridState) > 5
                : false,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [],
            itemCount: props.model.FamilyMembers
                ? GridHelper.getFilteredTotalNumber(props.model.FamilyMembers, gridState)
                : 0,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: false,
            // selectedIndices: Array.from(selectedIds.values())
            //     .filter((id) => orderedModel().some((item) => item.Id === id))
            //     .map((id) =>
            //         orderedModel()
            //             .map((i) => i.Id)
            //             .indexOf(id),
            //     ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: false,
    };

    const tableTemplate = () => html`
        <dc-table .vm=${vm} @onSortChange=${handleSortChange} @onPageChange=${handlePageChange}></dc-table>
    `;

    return html`
        <div id="mainContentWrapper">
            <section class="booking-wrapper no-breadcrumb staff-benefit-container">
                ${employeDiscountHeaderTemplate()} ${employeDiscountContentTemplate()}
            </section>
        </div>
    `;
};

import { classMap } from "lit-html/directives/class-map";
import { useEffect, useMemo, useState } from "./../../../shared/haunted/CustomHooks";
import { UserEditSubmissionEvent } from "../../../component-models/CUG2b/Cug2PortalEvents";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import {
    BodyToPost,
    SelectOption,
    getFilteredBodyToPost,
    getTaxnumberCodeByCountry,
    sanitizeRutFieldValue,
} from "../../../shared/common";
import { User } from "../../../component-models/CUG2b/User";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import { NAME_INPUT_MAX_LENGTH } from "../../../shared/commonConstants";
import { validateRut } from "../../../shared/form-validation";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export const name = "ac-cug-edit-user";

export interface Properties {
    userToEdit: User;
}

interface CugEditUserVM {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    mobilePhoneNumber: string;
    role: string;
    nationality: string;
    taxNumber: string;
}

type FieldNames = keyof CugEditUserVM;

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        userToEdit: host.userToEdit,
    };

    // HELPERS

    const taxNumberSanitizer = (e: KeyboardEvent) =>
        getTaxnumberCodeByCountry(vm.nationality, true) === "RUT"
            ? sanitizeRutFieldValue(e.target as HTMLInputElement)
            : sanitizeInputFieldValue(e, "travel-document-id");

    const getBodyToPost = () => {
        const unfilteredBodyToPost: BodyToPost = {
            UserId: props.userToEdit.UserName,
            FirstName: vm.firstName,
            LastName: vm.lastName,
            EmailAddress: vm.email,
            PhoneNumber: vm.phoneNumber,
            MobilePhoneNumber: vm.mobilePhoneNumber,
            Role: vm.role,
            NationalityCountryCode: vm.nationality,
            TaxNumber: vm.taxNumber,
        };

        return getFilteredBodyToPost(unfilteredBodyToPost);
    };

    // EVENT LISTENERS

    const handleEditSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);
        const isFormValid = await validator.validate();

        if (isFormValid) {
            const bodyToPost: BodyToPost = getBodyToPost();

            host.dispatchEvent(
                new UserEditSubmissionEvent({
                    form: bodyToPost,
                }),
            );
        }
    };

    const handleLastNameInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, lastName: value });
    };

    const handleFirstNameInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, firstName: value });
    };

    const handleRoleChange = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, role: value });
    };

    const handleNationalityChange = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, nationality: value, taxNumber: "" });
    };

    const handlePhoneNumberInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, phoneNumber: value });
    };

    const handleMobilePhoneNumberInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, mobilePhoneNumber: value });
    };

    const handleEmailInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, email: value });
    };

    const handleTaxNumberInput = (value: string) => {
        setHasChanged(true);
        setVm({ ...vm, taxNumber: value });
    };

    // COMPONENT

    const DEFAULT_VM: CugEditUserVM = {
        firstName: props.userToEdit.FirstName,
        lastName: props.userToEdit.LastName,
        email: props.userToEdit.EmailAddress,
        phoneNumber: props.userToEdit.PhoneNumber,
        mobilePhoneNumber: props.userToEdit.MobilePhoneNumber,
        role: props.userToEdit.Role,
        nationality: props.userToEdit.NationalityCountryCode,
        taxNumber: props.userToEdit.TaxNumber,
    };

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<CugEditUserVM>(DEFAULT_VM);

    const roleOptions: SelectOption[] = useMemo(
        () =>
            props.userToEdit.AvailableRoles.map((role) => {
                return {
                    Text: role.FriendlyName,
                    Value: role.Code,
                    IsSelected: role.Code === vm.role,
                };
            }),
        [props.userToEdit.AvailableRoles, props.userToEdit.Role],
    );

    const nationalityOptions: SelectOption[] = useMemo(
        () =>
            cug2AppContext.Countries.map((country) => ({
                Value: country.Value.toString(),
                Text: country.Text,
                IsSelected: country.Value === vm.nationality,
            })),
        [cug2AppContext.Countries],
    );

    const validator = useFluentValidator<FieldNames, CugEditUserVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("lastName", (vm: CugEditUserVM) => vm.lastName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("firstName", (vm: CugEditUserVM) => vm.firstName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("role", (vm: CugEditUserVM) => vm.role).isRequired(),
            Validation.ruleFor("nationality", (vm: CugEditUserVM) => vm.nationality)
                .when((_) => !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember))
                .isRequired(),
            Validation.ruleFor("phoneNumber", (vm: CugEditUserVM) => vm.phoneNumber)
                .isRequired()
                .fulfils(
                    async (phoneNumber: string) => Promise.resolve(phoneNumber.length <= 17),
                    `${window.formResources.fieldMaximumLengthIs} 17 ${window.formResources.characters}`,
                ),
            Validation.ruleFor("mobilePhoneNumber", (vm: CugEditUserVM) => vm.mobilePhoneNumber)
                .when((_) => userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
                .isRequired()
                .fulfils(
                    async (phoneNumber: string) => Promise.resolve(phoneNumber.length <= 17),
                    `${window.formResources.fieldMaximumLengthIs} 17 ${window.formResources.characters}`,
                ),
            Validation.ruleFor("email", (vm: CugEditUserVM) => vm.email)
                .when((_) => userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
                .isRequired()
                .isEmail()
                .max(266),
            Validation.ruleFor("taxNumber", (vm: CugEditUserVM) => vm.taxNumber)
                .when((_) => !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember))
                .isRequired()
                .max(12),
            Validation.ruleFor("taxNumber", (vm: CugEditUserVM) => vm.taxNumber)
                .when((_) => !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember))
                .isRequired()
                .when((vm) => getTaxnumberCodeByCountry(vm.nationality, true) === "RUT")
                .fulfils(
                    async (taxNumber: string) => taxNumber && validateRut(taxNumber),
                    i18next.t("BE-RutFormatError"),
                ),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    useEffect(() => {
        if (props.userToEdit) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [props.userToEdit]);

    // TEMPLATES

    const firstNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("firstName")}
                .isInvalid=${!validator.isValid("firstName")}
                .name=${"FirstName"}
                .label=${i18next.t("V2-FirstNameLabel")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${"edit-user-first-name"}
                .value=${vm.firstName ?? ""}
                .onInput=${handleFirstNameInput}
            ></ac-input>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("lastName")}
                .isInvalid=${!validator.isValid("lastName")}
                .name=${"LastName"}
                .label=${i18next.t("V2-LastNameLabel")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${"edit-user-last-name"}
                .value=${vm.lastName ?? ""}
                .onInput=${handleLastNameInput}
            ></ac-input>
        </div>
    `;

    const roleTemplate = () => html`
        <div class="mt-[20px]">
            <ac-select
                .errorMessage=${validator.getMessage("role")}
                .isInvalid=${!validator.isValid("role")}
                .label=${i18next.t("CUG-RoleLabel")}
                .isDisabled=${userContext.peruCompra.isAdmin || userContext.peruCompra.isMember}
                .name=${"Role"}
                .options=${roleOptions}
                .testId=${"edit-user-role"}
                .onSelect=${handleRoleChange}
            >
            </ac-select>
        </div>
    `;

    const nationalityTemplate = () => html`
        <div class="mt-[20px]">
            <ac-select
                .errorMessage=${validator.getMessage("nationality")}
                .isInvalid=${!validator.isValid("nationality")}
                .label=${i18next.t("CUG-CountryCode")}
                .name=${"NationalityCountryCode"}
                .options=${nationalityOptions}
                .testId=${"edit-user-nationality"}
                .onSelect=${handleNationalityChange}
            >
            </ac-select>
        </div>
    `;

    const phoneTemplate = (type: "general" | "mobile") => {
        const label =
            type === "general"
                ? i18next.t("V2-PhoneLabel")
                : type === "mobile"
                  ? i18next.t("Teléfono móvil")
                  : i18next.t("Office phone");

        return type === "general"
            ? html`
                  <div class="mt-[20px]">
                      <ac-input
                          .errorMessage=${validator.getMessage("phoneNumber")}
                          .isInvalid=${!validator.isValid("phoneNumber")}
                          .name=${"PhoneNumber"}
                          .label=${label}
                          .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric")}
                          .value=${vm.phoneNumber ?? ""}
                          .onInput=${handlePhoneNumberInput}
                      ></ac-input>
                  </div>
              `
            : html`
                  <div class="mt-[20px]">
                      <ac-input
                          .errorMessage=${validator.getMessage("mobilePhoneNumber")}
                          .isInvalid=${!validator.isValid("mobilePhoneNumber")}
                          .name=${"MobilePhoneNumber"}
                          .label=${label}
                          .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric")}
                          .value=${vm.mobilePhoneNumber ?? ""}
                          .onInput=${handleMobilePhoneNumberInput}
                      ></ac-input>
                  </div>
              `;
    };

    const emailTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .name=${"EmailAddress"}
                .label=${i18next.t("V2-EmailLabel")}
                .testId=${"edit-user-email"}
                .value=${vm.email ?? ""}
                .onInput=${handleEmailInput}
            ></ac-input>
        </div>
    `;

    const taxNumberTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("taxNumber")}
                .isInvalid=${!validator.isValid("taxNumber")}
                .name=${"TaxNumber"}
                .sanitizer=${(e: KeyboardEvent) => taxNumberSanitizer(e)}
                .label=${getTaxnumberCodeByCountry(vm.nationality, true) || i18next.t("CUG-TaxNumber")}
                .testId=${"edit-user-tax-number"}
                .value=${vm.taxNumber ?? ""}
                .onInput=${handleTaxNumberInput}
            ></ac-input>
        </div>
    `;

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !hasChanged,
        });

        return html`
            <button class=${tempClassMap} data-test-id="modal-edit-user-button" @click=${handleEditSubmit}>
                ${i18next.t("Guardar")}
            </button>
        `;
    };

    const headerTemplate = () => html`
        <span class="cug2b-header-edit-user-modal">${i18next.t("Editar datos del usuario")}</span>
    `;

    const editUserModalContentTemplate = () => html`
        <div class="cug2b-edit-user-container" onclick="event.stopPropagation();">
            <div>
                <div class="row">
                    <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                    <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                    ${userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
                        ? html`
                              <div class="col-xs-1 col-sm-1-2">${emailTemplate()}</div>
                              <div class="col-xs-1 col-sm-1-2">${phoneTemplate("general")}</div>
                              <div class="col-xs-1 col-sm-1-2">${phoneTemplate("mobile")}</div>
                              <div class="col-xs-1 col-sm-1-2">${roleTemplate()}</div>
                          `
                        : html`<div class="col-xs-1 col-sm-1-2">${roleTemplate()}</div>
                              <div class="col-xs-1 col-sm-1-2">${nationalityTemplate()}</div>
                              <div class="col-xs-1 col-sm-1-2">${phoneTemplate("general")}</div>
                              <div class="col-xs-1 col-sm-1-2">${taxNumberTemplate()}</div>`}
                </div>
                ${formErrors.htmlTemplate()}
            </div>

            <div class="mt-4 flex w-full justify-end sm:mt-8">${submitButtonTemplate()}</div>
        </div>
    `;

    return html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${editUserModalContentTemplate()}
            .customClass=${userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
                ? "peru-compra-edit-user-modal"
                : "cug2b-edit-user"}
            .header=${headerTemplate()}
            .isOpen=${isOpen}
            @close=${() => setIsOpen(false)}
        ></ac-cug-modal>
    `;
};

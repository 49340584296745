import { html } from "haunted";
import { useAppContext } from "../../managers/useAppContext";
import { classMap } from "lit-html/directives/class-map";
import { BRASILIAN_CULTURE_CODE, USA_CULTURE_CODE } from "../../shared/commonConstants";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ProfileDcWidgetViewModel } from "../../component-models/profile/ProfileDcWidgetViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: ProfileDcWidgetViewModel;
}

export const useProfileDcWidget = (props: Props) => {
    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");

    const logoTemplate = () =>
        appContext.Culture === USA_CULTURE_CODE
            ? html`<img src="/Images/DcStandalone/dc-logo-2-en.png" />`
            : appContext.Culture === BRASILIAN_CULTURE_CODE
              ? html`<img src="/Images/DcStandalone/dc-logo-2-pt.png" />`
              : html`<img src="/Images/DcStandalone/dc-logo-2.png" />`;

    const noMembershipTemplate = () => {
        const bancoEstadoCat5SpanClassMap = classMap({
            "hidden-xs": true,
            "font-body": true,
            "text-white": true,
            "hidden": userContext.bancoEstado.category !== 5,
        });

        const notBancoEstadoCat5SpanClassMap = classMap({
            "hidden-xs": true,
            "font-body": true,
            "text-white": true,
            "hidden": userContext.bancoEstado.category === 5,
        });

        return !userContext.dc.hasMembership
            ? html`
                  <span class="font-body font-black text-white">${i18next.t("MP-BuyDcInfo-1")}</span>
                  <span class=${bancoEstadoCat5SpanClassMap}>${i18next.t("MP-BuyDcInfo-2")}</span>
                  <span class="bg-white font-body font-black text-be-blue sm:bg-transparent"
                      >${unsafeHTML(
                          i18next.t("MP-BuyDcInfo-3 {{-discountMin}}", { discountMin: props.model.DiscountMinutes }),
                      )}</span
                  >
                  <span class=${notBancoEstadoCat5SpanClassMap}>&nbsp;</span>
                  <span class="hidden-sm-up font-body text-white">${i18next.t("MP-BuyDcInfo-4")}</span>

                  <a href=${props.model.PurchaseDcMembershipUrl} target="_blank"
                      >${i18next.t("MP-BuyMembership")}<i class="js-icon js-circle-chevron-right"></i
                  ></a>
              `
            : "";
    };

    const membershipTypeTemplate = () =>
        userContext.dc.hasGroupMembership
            ? html`<span>${i18next.t("MP-Group")}:</span> <span>${i18next.t("MP-GroupInfo")}:</span>`
            : html`<span>${i18next.t("MP-Standard")}:</span> <span>${i18next.t("MP-StandardInfo")}:</span>`;

    const membershipExpirationDateTemplate = () =>
        (userContext.bancoEstado.category !== 6 && userContext.bancoEstado.category !== 1) ||
        userContext.dc.hasGroupMembership
            ? html`
                  <div class="dcwc-expiration">
                      <span>${i18next.t("MP-DcExpiryDate")}:</span>
                      <span class="font-semibold">${props.model.ExpiryDate}</span>
                  </div>
              `
            : "";

    const membershipExpirationWarningTemplate = () =>
        props.model.IsMembershipExpiring &&
        ((userContext.bancoEstado.category !== 6 && userContext.bancoEstado.category !== 1) ||
            userContext.dc.hasGroupMembership)
            ? html` <div class="dcwc-expiration-warning">${i18next.t("MP-DcExpiryWarning")}</div> `
            : "";

    const hasMembershipTemplate = () => {
        const savingsClassMap = classMap({
            "dcwc-savings": true,
            "english": appContext.Culture === USA_CULTURE_CODE,
            "portuguese": appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return userContext.dc.hasMembership
            ? html`
                  <div class="dcwc-details">
                      <span>${i18next.t("MP-DcType")}</span>
                      ${membershipTypeTemplate()}
                  </div>

                  ${membershipExpirationDateTemplate()}

                  <div class=${savingsClassMap}>
                      <span>${i18next.t("MP-DcSavingsAmount")}:</span>&nbsp;
                      <span>${props.model.DcSavings}</span>
                  </div>

                  ${membershipExpirationWarningTemplate()}
              `
            : "";
    };

    const mainClassMap = classMap({
        "profile-widget": true,
        "profile-dc-widget": true,
        "compressed": userContext.dc.hasMembership,
    });

    const htmlTemplate = () => html`
        <div class=${mainClassMap}>
            <div class="dc-widget-container">
                <div class="dc-widget-content">
                    ${logoTemplate()} ${noMembershipTemplate()} ${hasMembershipTemplate()}
                </div>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { classMap } from "lit-html/directives/class-map";
import { useEffect } from "haunted";
import { html, TemplateResult } from "lit-html";
import { useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ScrollHelper } from "../../../shared/ScrollHelper";

export const name = "ac-tooltip-balloon";

export interface TooltipBalloonProps {
    id: string;
    top: number;
    left: number;
    template: TemplateResult | string;
    customClass: string;
}

const balloonContentClassName = "ac-tooltip-balloon-content";

export const Component: HauntedFunc<TooltipBalloonProps> = (host) => {
    const props: TooltipBalloonProps = {
        id: host.id,
        top: host.top,
        left: host.left,
        template: host.template,
        customClass: host.customClass,
    };

    const init = () => {
        // DEVNOTE Has to be done after render, and a little delay looks good as well
        window.setTimeout(() => {
            setIsInitialized(true);

            if (isOverflowingWindowOnTop()) {
                setIsDown(true);
            }

            if (isOverflowingWindowOnRight()) {
                setIsLeft(true);
            }

            if (isOverflowingWindowOnTop() && isOverflowingWindowOnBottom()) {
                setIsDown(false);
                setIsLeft(true);
            }

            if (isOverflowingWindowOnLeft()) {
                setIsRight(true);
            }
        }, 100);
    };

    const isOverflowingWindowOnLeft = () => props.left - getElementWidth() / 2 < 0;

    const isOverflowingWindowOnRight = () => props.left + getElementWidth() / 2 > ScrollHelper.getWindowWidth();

    const isOverflowingWindowOnTop = () => props.top - getElementHeight() < 30;

    const isOverflowingWindowOnBottom = () => props.top + getElementHeight() > ScrollHelper.getWindowHeight() - 50;

    // DEVNOTE You cannot get the height of a fixed element, that's why we use a child element
    const getElementHeight = () =>
        (document.body.querySelector(`.${balloonContentClassName}`) as HTMLDivElement)?.getBoundingClientRect().height;

    const getElementWidth = () =>
        (document.body?.querySelector(`.${balloonContentClassName}`) as HTMLDivElement)?.getBoundingClientRect().width;

    const [isDown, setIsDown] = useState<boolean>(false);
    const [isLeft, setIsLeft] = useState<boolean>(false);
    const [isRight, setIsRight] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(init, []);

    const mainClassMap = classMap({
        "ac-tooltip-balloon": true,
        "down": isDown,
        "left": isLeft,
        "right": isRight,
        "opacity-0": !isInitialized,
        [props.customClass]: props.customClass,
    });

    const contentClassMap = classMap({
        "relative": true,
        "h-full": true,
        [balloonContentClassName]: true,
    });

    return html`
        <div id=${props.id} class=${mainClassMap} style=${`top: ${props.top}px; left: ${props.left}px;`}>
            <!-- DEVNOTE You cannot get the height of a fixed element, that's why we use a child element -->
            <div class=${contentClassMap}>
                ${props.template instanceof TemplateResult ? props.template : unsafeHTML(props.template)}
            </div>
        </div>
    `;
};

import { usePubSub } from "../pub-sub-service/usePubSub";
import BookingData from "../shared/BookingData";
import { hideLoader } from "../shared/common";
import { useAjax } from "../shared/customHooks/useAjax/useAjax";
import { ACTION_NAMES } from "../shared/commonConstants";
import { useBookingDataManager } from "./useBookingDataManager";
import { useFlowContext } from "./useFlowContext";
import { AjaxResponse } from "../shared/customHooks/useAjax/AjaxResponse";

export const useBookingManager = () => {
    interface PostBody {
        [key: string]: string;
    }

    const ROUTES = {
        AddCommission: "/api/Agency/AddCommission",
        AjaxPagePassengers: "/V2XHR/Passengers",
        AjaxPageSeatmapPost: "/api/Seat/SetSeats",
        ApplySsr: "/SsrXHR/Apply",
        ClearPromoCode: "/V2XHR/ClearPromotionCode",
        EliminateOxyPassengers: "/V2Checkin/EliminateOxygenConcentratorPaxFromCheckIn",
        FarelockBuy: "/Farelock/BuyFareLock",
        FarelockCancel: "/Farelock/CancelFareLock",
        FeesApplyInterest: "/api/Fees/ApplyInterest",
        FeesApplyInterestMPA: "/api/Fees/ApplyInterestMPA",
        FlexiFeeAdd: "/SsrXHR/ApplyFlexi",
        FlexiFeeRemove: "/SsrXHR/RemoveFlexi",
        InsuranceDeselect: "/ChubbXHR/DeselectPassengerInsurance",
        InsuranceDocumentUpdate: "/ChubbXHR/UpdateTravelingDocument",
        InsuranceSelect: "/ChubbXHR/SelectPassengerInsurance",
        PassedBaggagePage: "/V2XHR/BaggageStepPassed",
        PaymentPostContactForm: "/Contact/Update",
        PostInvoice: "/Billing/Update",
        PostNewPassengers: "/api/Passengers/Add",
        ResetBaggageInsurance: "/ChubbXHR/ResetBaggageInsurance",
        SelectBaggageInsurance: "/ChubbXHR/SelectBaggageInsurance",
        PostEmergencyContact: "/api/Contacts/UpdateEmergencyContact",
    };

    type PostType =
        | "addBaggageInsurance"
        | "addCommission"
        | "addFarelock"
        | "addFlexiFee"
        | "addPassengerInsurance"
        | "applyInterestFee"
        | "applyMercadoPagoInterestFee"
        | "baggageStepPassed"
        | "cancelFarelock"
        | "eliminateOxy"
        | "emergencyContact"
        | "insuranceDocumentUpdate"
        | "invoice"
        | "passengers"
        | "passengersUpdate"
        | "paymentContact"
        | "removeFlexiFee"
        | "removePassengerInsurance"
        | "resetBaggageInsurance"
        | "seatSelection"
        | "ssrUpdate"
        | "ssrUpdateNonCancellable"
        | "ssrUpdateTmp"
        | "ssrUpdateNonCancellableTmp";
    interface PostTypeProperties {
        EmitChange?: boolean;
        NonCancellable?: boolean;
        OnStatusCode?: { [code: number]: (result: Response) => void };
        Url: string;
    }

    const POST_TYPE_PROPERTY_MAP = new Map<PostType, PostTypeProperties>([
        ["addBaggageInsurance", { EmitChange: true, NonCancellable: true, Url: ROUTES.SelectBaggageInsurance }],
        ["addCommission", { Url: ROUTES.AddCommission }],
        ["addFarelock", { EmitChange: true, Url: ROUTES.FarelockBuy }],
        ["addFlexiFee", { EmitChange: true, Url: ROUTES.FlexiFeeAdd }],
        ["addPassengerInsurance", { EmitChange: true, Url: ROUTES.InsuranceSelect }],
        ["applyInterestFee", { EmitChange: true, Url: ROUTES.FeesApplyInterest }],
        ["applyMercadoPagoInterestFee", { EmitChange: true, Url: ROUTES.FeesApplyInterestMPA }],
        ["baggageStepPassed", { Url: ROUTES.PassedBaggagePage }],
        ["cancelFarelock", { EmitChange: true, Url: ROUTES.FarelockCancel }],
        ["eliminateOxy", { Url: ROUTES.EliminateOxyPassengers }],
        ["emergencyContact", { Url: ROUTES.PostEmergencyContact }],
        ["insuranceDocumentUpdate", { Url: ROUTES.InsuranceDocumentUpdate }],
        ["invoice", { Url: ROUTES.PostInvoice }],
        ["passengers", { EmitChange: true, Url: ROUTES.PostNewPassengers }],
        ["paymentContact", { Url: ROUTES.PaymentPostContactForm }],
        ["removeFlexiFee", { EmitChange: true, Url: ROUTES.FlexiFeeRemove }],
        ["removePassengerInsurance", { EmitChange: true, Url: ROUTES.InsuranceDeselect }],
        ["resetBaggageInsurance", { EmitChange: true, Url: ROUTES.ResetBaggageInsurance }],
        ["seatSelection", { EmitChange: true, Url: ROUTES.AjaxPageSeatmapPost }],
        ["ssrUpdate", { EmitChange: true, Url: ROUTES.ApplySsr }],
        ["ssrUpdateNonCancellable", { EmitChange: true, NonCancellable: true, Url: ROUTES.ApplySsr }],
    ]);

    const postAddFarelock = async (body: PostBody, container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "addFarelock", body, container, loader });

    const postCancelFarelock = async (container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "cancelFarelock", body: {}, container, loader });

    const postApplyInterestFee = async (body: PostBody, loader: JsLoader) =>
        handlePost({ postType: "applyInterestFee", body, loader });

    const postApplyMercadoPagoInterestFee = async (body: PostBody, loader: JsLoader) =>
        handlePost({ postType: "applyMercadoPagoInterestFee", body, loader });

    const postEliminateOxy = async (loader: JsLoader) => handlePost({ postType: "eliminateOxy", body: {}, loader });

    const postAddCommission = async (body: PostBody, container: HTMLElement) =>
        handlePost({ postType: "addCommission", body, container });

    const postInvoice = async (body: PostBody, container: HTMLElement) =>
        handlePost({ postType: "invoice", body, container });

    const postPaymentContact = async (body: PostBody, container: HTMLElement) =>
        handlePost({ postType: "paymentContact", body, container });

    const postSeatSelection = async (body: PostBody, container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "seatSelection", body, container, loader });

    const postSpecialAssistanceSsrUpdate = async (body: PostBody, container: HTMLElement) =>
        handlePost({ postType: "ssrUpdateNonCancellable", body, container });

    const postEmergencyContact = async (body: PostBody, container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "emergencyContact", body, container, loader });

    const postPassengers = async (body: PostBody, container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "passengers", body, container, loader, method: "PUT" });

    const postExtrasPageSsrUpdate = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "ssrUpdate", body, container, loader });

    const postAddFlexiFee = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "addFlexiFee", body, container, loader });

    const postRemoveFlexiFee = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "removeFlexiFee", body, container, loader });

    const postAddPassengerInsurance = async <T = { BookingSummary: BookingData }>(
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "addPassengerInsurance", body: {}, container, loader });

    const postRemovePassengerInsurance = async <T = { BookingSummary: BookingData }>(
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "removePassengerInsurance", body: {}, container, loader });

    const postAddBaggageInsurance = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost({ postType: "addBaggageInsurance", body, container, loader });

    const postBaggageStepPassed = async (body: PostBody, container: HTMLElement, loader: JsLoader) =>
        handlePost({ postType: "baggageStepPassed", body, container, loader });

    const postAirportCheckinSsrUpdate = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader?: JsLoader,
    ): Promise<T> => handlePost({ postType: "ssrUpdateNonCancellable", body, container, loader });

    const postResetBaggageInsurance = async () => handlePost({ postType: "resetBaggageInsurance", body: {} });

    const postSsrUpdate = async <T = { BookingSummary: BookingData }>(
        body: PostBody,
        container: HTMLElement,
        loader: JsLoader,
    ): Promise<T> => handlePost<T>({ postType: "ssrUpdate", body, container, loader });

    const handlePost = async <T = { BookingSummary: BookingData }>(data: {
        body: PostBody;
        container?: HTMLElement;
        loader?: JsLoader;
        method?: "POST" | "GET" | "PUT" | "DELETE" | "PATCH";
        postType: PostType;
        onResponseCode?: { [code: number]: (data?: string) => void };
    }): Promise<T> => {
        try {
            const postProperties = POST_TYPE_PROPERTY_MAP.get(data.postType);
            const result = await ajaxJsonRequest<T>({
                body: data.body,
                container: data.container,
                loader: data.loader,
                method: data.method || "POST",
                nonCancellable: postProperties.NonCancellable,
                url: postProperties.Url,
                onResponseCode: data.onResponseCode,
            });

            return handlePostResult({ result, emitChange: postProperties.EmitChange });
        } catch (e) {
            hideLoader(data.loader);
            return false as T;
        }
    };

    const handlePostResult = async <T = { BookingSummary: BookingData }>(data: {
        emitChange: boolean;
        result: AjaxResponse<T>;
    }): Promise<T> => {
        if (data.result?.statusCode !== 200) {
            return undefined as T;
        }

        bookingDataManager.setBookingData((data.result.data as { BookingSummary: BookingData }).BookingSummary);

        if (data.emitChange && flowContext.action !== ACTION_NAMES.FLIGHT) {
            triggers.sidebar.bookingChanged.publish({});
        }

        return data.result.data;
    };

    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();
    const { triggers } = usePubSub();

    const { ajaxJsonRequest } = useAjax();

    return {
        postAddBaggageInsurance,
        postAddCommission,
        postAddFarelock,
        postAddFlexiFee,
        postAddPassengerInsurance,
        postAirportCheckinSsrUpdate,
        postApplyInterestFee,
        postApplyMercadoPagoInterestFee,
        postBaggageStepPassed,
        postCancelFarelock,
        postEliminateOxy,
        postEmergencyContact,
        postExtrasPageSsrUpdate,
        postInvoice,
        postPassengers,
        postPaymentContact,
        postRemoveFlexiFee,
        postRemovePassengerInsurance,
        postResetBaggageInsurance,
        postSeatSelection,
        postSpecialAssistanceSsrUpdate,
        postSsrUpdate,
    };
};

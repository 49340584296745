import { GridState } from "./../dc-components/dc-table-models";
import { Group } from "../component-models/CUG2b/Group";
import { Partner } from "../component-models/CUG2b/Partner";
import { TravelPartnerInfo } from "../component-models/CUG2b/TravelPartnerInfo";

export class TravelPartnerHelper {
    public static getAdminGroupPageContent(
        travelPartnerInfo: TravelPartnerInfo,
        currentPage: number,
        groupsPerPage: number,
    ): Group[] {
        return travelPartnerInfo.Groups.sort((a, b) => this.sortByTravelPartnersInGroup(a, b, travelPartnerInfo)).slice(
            currentPage * groupsPerPage,
            (currentPage + 1) * groupsPerPage,
        );
    }

    public static getOrderedFilteredModel<T>(model: T[], gridState: GridState<keyof T>): T[] {
        if (!model) {
            return [];
        }

        const splitSearchExpression = gridState.globalFilterExpression?.split(" ");

        return [...model]
            .filter((row) => this.filterByExpressions(row, splitSearchExpression))
            .sort((a, b) => this.sortByGridState(a, b, gridState))
            .slice(gridState.pageIndex * gridState.pageSize, (gridState.pageIndex + 1) * gridState.pageSize);
    }

    public static getNumberOfPartners(travelPartnerInfo: TravelPartnerInfo, id: number): number {
        return travelPartnerInfo.Partners.filter((p) => p.GroupId === id).length;
    }

    public static updateGroup(travelPartnerInfo: TravelPartnerInfo, group: Group): TravelPartnerInfo {
        const isGroupNew = group.Id === undefined || !travelPartnerInfo.Groups.some((g) => g.Id === group.Id);

        if (isGroupNew) {
            return {
                ...travelPartnerInfo,
                Groups: [...travelPartnerInfo.Groups, { ...group, Id: this.getNextId(travelPartnerInfo.Groups) }],
            };
        }

        return { ...travelPartnerInfo, Groups: [...travelPartnerInfo.Groups.filter((g) => g.Id !== group.Id), group] };
    }

    public static deleteGroups(
        travelPartnerInfo: TravelPartnerInfo,
        groupIds: number[],
        deletePartners: boolean,
    ): TravelPartnerInfo {
        return {
            ...travelPartnerInfo,
            Groups: [...travelPartnerInfo.Groups.filter((g) => !groupIds.includes(g.Id))],
            Partners: deletePartners
                ? travelPartnerInfo.Partners.filter((p) => !groupIds.includes(p.GroupId))
                : travelPartnerInfo.Partners.map((p) => {
                      return { ...p, GroupId: !groupIds.includes(p.GroupId) ? p.GroupId : undefined };
                  }),
        };
    }

    public static updatePartner(travelPartnerInfo: TravelPartnerInfo, partner: Partner): TravelPartnerInfo {
        const isPartnerNew = partner.Id === undefined || !travelPartnerInfo.Partners.some((p) => p.Id === partner.Id);

        if (isPartnerNew) {
            return {
                ...travelPartnerInfo,
                Partners: [
                    ...travelPartnerInfo.Partners,
                    { ...partner, Id: this.getNextId(travelPartnerInfo.Partners) },
                ],
            };
        }

        return {
            ...travelPartnerInfo,
            Partners: [...travelPartnerInfo.Partners.filter((p) => p.Id !== partner.Id), partner],
        };
    }

    public static deletePartners(travelPartnerInfo: TravelPartnerInfo, partnerIds: number[]): TravelPartnerInfo {
        return {
            ...travelPartnerInfo,
            Partners: [...travelPartnerInfo.Partners.filter((p) => !partnerIds.includes(p.Id))],
        };
    }

    public static addPartnersToGroup(
        travelPartnerInfo: TravelPartnerInfo,
        groupId: number,
        partnerIds: number[],
    ): TravelPartnerInfo {
        return {
            ...travelPartnerInfo,
            Partners: [
                ...travelPartnerInfo.Partners.map((p) => {
                    return { ...p, GroupId: partnerIds.includes(p.Id) ? groupId : p.GroupId };
                }),
            ],
        };
    }

    public static getGroupNameForPartner(travelPartnerInfo: TravelPartnerInfo, id: number): string {
        try {
            const partner = travelPartnerInfo.Partners.find((p) => p.Id === id);
            const groupName = travelPartnerInfo.Groups.find((g) => g.Id === partner.GroupId).Name;

            return groupName;
        } catch (e) {
            return "";
        }
    }

    private static getNextId(itemArray: (Partner | Group)[]): number {
        return Array.isArray(itemArray)
            ? itemArray.reduce((highestId: number, item) => (item.Id > highestId ? item.Id : highestId), -1) + 1
            : 0;
    }

    private static filterByExpressions<T>(row: T, searchExpressions: string[]): boolean {
        if (!searchExpressions || searchExpressions.length === 0) {
            return true;
        }

        const rowKeys = Array.from(Object.keys(row));

        return searchExpressions.every((expression) =>
            rowKeys.some((key) => {
                const value = row[key as keyof typeof row];
                return typeof value === "string" ? value.toLowerCase().indexOf(expression.toLowerCase()) > -1 : false;
            }),
        );
    }

    private static sortByGridState<T>(a: T, b: T, gridState: GridState<keyof T>): number {
        if (gridState.orderDir === "desc") {
            return a[gridState.orderBy] > b[gridState.orderBy] ? -1 : 1;
        } else {
            return a[gridState.orderBy] > b[gridState.orderBy] ? 1 : -1;
        }
    }

    private static sortByTravelPartnersInGroup(a: Group, b: Group, travelPartnerInfo: TravelPartnerInfo): number {
        return TravelPartnerHelper.getNumberOfPartners(travelPartnerInfo, a.Id) >
            TravelPartnerHelper.getNumberOfPartners(travelPartnerInfo, b.Id)
            ? -1
            : 1;
    }
}

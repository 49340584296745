import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { Column, GridState, ViewModel } from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { updateMdl } from "../../../shared/common";
import { IconClickEvent } from "../../../component-models/CUG2b/PeruCompraPortalEvents";
import { PeruCompraCancelledReservationModel } from "../../../component-models/peru-compra/PeruCompraCancelledReservationModel";

export const name = "ac-peru-compra-cancelled-reservations";

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell", "pcra-action-cell"];

export interface Props {
    data: PeruCompraCancelledReservationModel[];
    gridState: GridState<keyof PeruCompraCancelledReservationModel>;
    selectedIds: string[];
    totalItemCount: number;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        data: host.data,
        gridState: host.gridState,
        selectedIds: host.selectedIds,
        totalItemCount: host.totalItemCount,
    };

    // HELPERS

    // COMPONENT

    // EVENT LISTENERS

    const handleDetailsClick = (e: MouseEvent, index: number) => {
        e.preventDefault();
        host.dispatchEvent(new IconClickEvent({ index, openDetails: true }));
    };

    // TEMPLATES

    const detailsIconTemplate = (index: number) => {
        return html`<i
            class="js-icon-covid js-cv-file-icon"
            @click=${(e: MouseEvent) => handleDetailsClick(e, index)}
        ></i>`;
    };

    const actionIconsTemplate = (index: number) => html`
        <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
            <div class=${GridHelper.getClassMap(actionCellContentClass)}>${detailsIconTemplate(index)}</div>
        </div>
    `;

    const dummyCellTemplate = () => html` <span>n/a</span> `;

    const cellTemplate = (index: number, field: keyof PeruCompraCancelledReservationModel) => {
        const row = props.data[index];

        if (field) {
            return html` <span title=${row[field]?.toString() || ""}>${row[field]?.toString() || ""}</span> `;
        }

        return "";
    };

    const dayJsCellTemplate = (index: number, field: keyof PeruCompraCancelledReservationModel) => {
        const row = props.data[index];
        const cellValue = row[field] as dayjs.Dayjs;
        const formattedDate = cellValue ? dayjs(cellValue).format("DD/MM/YYYY") : "";

        return field ? html` <span title=${formattedDate}>${formattedDate}</span> ` : "";
    };

    const descriptionTemplate = () => {
        return html`
            <div class="cug2b-search-and-title push-down">
                <div class="cug2b-page-subtitle">${i18next.t("Reembolsos y Anulaciones")}</div>
            </div>
        `;
    };

    if (!props.gridState || !props.data) {
        return html``;
    }

    const vm: ViewModel<keyof PeruCompraCancelledReservationModel> = {
        columns: [
            {
                field: "CreatedDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Oper."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "CreatedDate"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "PNR",
                columnType: "string",
                label: i18next.t("Código de reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "PNR"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "RUC",
                columnType: "string",
                label: i18next.t("RUC Entidad"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "RUC"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "AdminId",
                columnType: "string",
                label: i18next.t("Gestor"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "AdminId"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "MemberId",
                columnType: "string",
                label: i18next.t("Delegado"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "MemberId"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "OperationType",
                columnType: "string",
                label: i18next.t("Tipo Oper."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "OperationType"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                // DUMMY CELL, requested by client
                field: "OperationType",
                columnType: "number",
                label: i18next.t("C. Boletos"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: dummyCellTemplate,
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "ServiceType",
                columnType: "string",
                label: i18next.t("Tipo Serv."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ServiceType"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "TicketNumber",
                columnType: "string",
                label: i18next.t("Boleto"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "TicketNumber"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "NumberOfLegs",
                columnType: "string",
                label: i18next.t("Tramo"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "NumberOfLegs"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "FlightNumber",
                columnType: "string",
                label: i18next.t("N° Vuelo"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FlightNumber"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "Origin",
                columnType: "string",
                label: i18next.t("Origen"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Origin"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "DepartureDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Origen"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "DepartureDate"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "Destination",
                columnType: "string",
                label: i18next.t("Destino"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "Destination"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "ArrivalDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Destino"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "ArrivalDate"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "PassengerDocumentId",
                columnType: "string",
                label: i18next.t("Doc. Id. Pasajero"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "PassengerDocumentId"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Apellidos"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "LastName"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "FirstName",
                columnType: "string",
                label: i18next.t("Nombre"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "FirstName"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
            {
                field: "RefundAmount",
                columnType: "string",
                label: i18next.t("Importe Tramo"),
                sortable: false,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "RefundAmount"),
            } as Column<keyof PeruCompraCancelledReservationModel>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate: actionIconsTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    return html`
        ${updateMdl()} ${descriptionTemplate()}
        <div class="cug2b-confirmed-reservation-scrolling-tip">
            ${i18next.t("Deslizando hacia la derecha en la tabla podrás visualizar más campos.")}
        </div>
        <dc-table-grid .vm=${vm}></dc-table-grid>
    `;
};

import { CHILE_COUNTRY_CODE } from "../../shared/commonConstants";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { updateMdl } from "../../shared/common";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { uniqueByKey } from "../../shared/IterableHelpers";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAppContext } from "../../managers/useAppContext";

export const useInputIssuerCountry = () => {
    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");
    const [payerData, setPayerData] = useReduxState("payment.payer");

    const [showCountrySelector, setShowCountrySelector] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(undefined);

    const updateDropdownVisibility = () => {
        setShowDropdown(payerData?.CurrentCardIssuerCountry !== CHILE_COUNTRY_CODE);
    };

    useEffect(updateDropdownVisibility, [payerData?.CurrentCardIssuerCountry]);

    const handleCountrySelectorChange = (e: MouseEvent) => {
        const value = (e.target as HTMLSelectElement).value;
        setPayerData({ ...payerData, CurrentCardIssuerCountry: value });
    };

    // TODO Use the country list
    const countryListTemplate = () =>
        uniqueByKey(appContext.PhonePrefixes, "CountryCode").map(
            (option) => html`
                <option
                    value="${option.CountryCode}"
                    .selected=${option.CountryCode === payerData?.CurrentCardIssuerCountry}
                >
                    ${option.CountryName}
                </option>
            `,
        );

    const dropdownTemplate = () =>
        showCountrySelector && showDropdown
            ? html`
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                      <label class="mdl-textfield__label">${i18next.t("Payment-CardIssuerCountry")}</label>
                      <select
                          class="mdl-textfield__input js-input js-select"
                          data-test-id=${T.PAYMENT.CARD_ISSUER_COUNTRY}
                          value=${payerData?.CurrentCardIssuerCountry || ""}
                          @change=${handleCountrySelectorChange}
                      >
                          ${countryListTemplate()}
                      </select>
                  </div>
                  ${updateMdl()}
              `
            : "";

    const linkTemplate = () =>
        showCountrySelector && !showDropdown
            ? html`
                  <div class="non-chile-link">
                      <a @click=${() => setShowDropdown(true)} data-test-id=${T.PAYMENT.NON_CHILE_LINK}>
                          ${i18next.t("Payment-CardIsNotChilean")}
                      </a>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        userContext.bancoEstado.category === 0 ? html` ${dropdownTemplate()} ${linkTemplate()} ` : html``;

    return { htmlTemplate, setShowCountrySelector };
};

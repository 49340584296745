import i18next from "i18next";
import { html } from "lit-html";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useModal } from "../../../shared/useModal";

export const useSeatsForAllModal = () => {
    const open = (callback: () => void) => {
        setCallback(() => callback);
        modal.open();
    };

    const handleNoForSeatingAll = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();
        callback();
    };

    const [callback, setCallback] = useState<() => () => void>(undefined);

    const htmlTemplate = () => html`
        <div class="modal-body text-center">
            <span class="brand-secondary-text">${i18next.t("V2-NotAllSeated")}</span>
            <div class="modal-button-container modification-buttons-container">
                <button class="rounded-secondary-btn seat-for-all" @click=${modal.close}>
                    ${i18next.t("V2-Cancel")}
                </button>
                <button
                    class="rounded-primary-btn seat-for-all"
                    data-test-id=${T.SEATMAP.MODAL_CONTINUE_BUTTON}
                    @click=${handleNoForSeatingAll}
                >
                    ${i18next.t("V2-Continue")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("V2-AreYouSure") },
        overlay: { testId: T.SEATMAP.SEAT_FOR_ALL_ARE_YOU_SURE_MODAL },
    });

    return {
        open,
        htmlTemplate: modal.htmlTemplate,
    };
};

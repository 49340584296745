import { useEffect as hauntedUseEffect, useMemo as hauntedUseMemo, useRef, useState as hauntedUseState } from "haunted";

export const useState = hauntedUseState;
export const useMemo = hauntedUseMemo;

export const useEffect = (
    callback: () => Promise<(() => void) | void> | ((() => void) | void),
    values?: unknown[] | undefined,
): void => {
    hauntedUseEffect(() => {
        // setTimeout is used, because useEffect can cascade between embedded
        // components and can cause an update skipped (such as an "initial render")
        // const result = callback();
        window.setTimeout(() => {
            const result = callback();
            if (result instanceof Promise) {
                result.catch(async (reason) => {
                    // eslint-disable-next-line no-console
                    console.log(reason);
                });
            }
        }, 0);
    }, values);
};

type StateType<T> = T extends (...args: any[]) => infer R ? R : T;

export const useVolatileState = <T>(
    duration: number,
): readonly [StateType<T>, (value: StateType<T> | ((previousState?: StateType<T>) => StateType<T>)) => void] => {
    const [state, setState] = useState<T>(undefined);
    const timeOutRef = useRef<number>(undefined);

    useEffect(() => {
        if (state !== undefined) {
            if (timeOutRef.current) {
                window.clearTimeout(timeOutRef.current);
            }
            timeOutRef.current = window.setTimeout(() => {
                setState(undefined);
            }, duration);
        }
    }, [state]);

    return [state, setState];
};

export interface IconClickEventDetail {
    index: number;
    openDetails: boolean;
}

export class IconClickEvent extends CustomEvent<IconClickEventDetail> {
    constructor(detail: IconClickEventDetail) {
        super("onIconClick", { detail });
    }
}

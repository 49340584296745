import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../shared/useModal";

export const useSameDateModal = () => {
    const headerTemplate = () => html`
        <i class="fas fa-exclamation-circle notification-icon"></i>
        ${i18next.t("Importante")}
    `;

    const htmlTemplate = () => html`
        <div class="modal-body leading-loose">
            ${i18next.t(
                "Estás seleccionando la misma fecha para ambos tramos y no es posible. Por favor verifica tus fechas de vuelo.",
            )}
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "flight-move", template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import dayjs, { Dayjs } from "dayjs";
import { getCalendar, mapToFakeBundleOffer } from "../../component-mappers/FlightMoveMapper";
import { FlightPageBundleState } from "../../component-models/flight/contexts/FlightPageBundleState";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { useMemo, useState } from "../../shared/haunted/CustomHooks";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { FlightPageFlightState } from "../../component-models/flight/contexts/FlightPageFlightState";
import { clone } from "../../shared/common";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { FlightSelectDTO } from "../flight-select/contexts/useFlightPageContext";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { DcState } from "../../component-models/flight/contexts/DcState";
import { DcBannerState } from "../../component-models/flight/contexts/DcBannerState";
import { ExtendedTripModel } from "../../component-models/flight/ExtendedTripModel";
import { useAppContext } from "../../managers/useAppContext";

export interface FlightMovePageContextProps {
    bundleColor: string;
    bundleImg: string;
    currentInboundWeekday: Dayjs;
    currentOutboundWeekday: Dayjs;
    disabledInboundDates: Dayjs[];
    disabledOutboundDates: Dayjs[];
    maxInboundDate: Dayjs;
    maxOutboundDate: Dayjs;
    minInboundDate: Dayjs;
    minOutboundDate: Dayjs;
    rootElem: HTMLFormElement;
    selectedJourneys: number[];
    viewModel: FlightPageViewModel;
}

// DEVNOTE It is what it is because we mimick the flight select page in preparation for voluntary change
// DEVNOTE Therefore unused props, etc
export const useFlightMovePageContext = (props: FlightMovePageContextProps): FlightPageContext => {
    const appContext = useAppContext();

    const mockBannerState: DcBannerState = {
        bannerVM: undefined,
        selectedOption: undefined,
        termsAcceptance: "Unneeded",
    };

    const mockDcState: DcState = {
        applyDiscount: false,
        hasMembershipOnBooking: false,
        isTooManyPaxModalAlreadyShown: false,
        selectedMembershipType: "None",
    };

    const [flightState, setFlightState] = useState<FlightPageFlightState>({
        selectedFeeType: "Smart",
        selectedInboundFlight: undefined,
        selectedOutboundFlight: undefined,
    });

    const [bundleState, setBundleState] = useState<FlightPageBundleState>({
        bundleOffers: [{ DCOffers: [], NormalOffers: [], SellKey: undefined }],
        bundlesMode: "PerLeg",
        concatenateBags: true,
        selectedInboundBundle: undefined,
        selectedOutboundBundle: undefined,
    });

    const mapToExtendedTripModel = (flight: ExtendedTripModel) => ({
        ...flight,
        LowestFares: getCalendar({
            journeyIndex: flight.TripIndex,
            selectedOutboundDate: props.currentOutboundWeekday,
            disabledOutboundDates: props.disabledOutboundDates,
            selectedInboundDate: props.currentInboundWeekday,
            disabledInboundDates: props.disabledInboundDates,
            firstOutboundDate: dayjs(props.minOutboundDate),
            firstInboundDate: dayjs(props.minInboundDate),
            lastOutboundDate: dayjs(props.maxOutboundDate),
            lastInboundDate: dayjs(props.maxInboundDate),
        }),
    });

    const journeys = useMemo<ExtendedTripModel[]>(
        () =>
            props.viewModel?.Journeys?.filter((item) => props.selectedJourneys.includes(item.TripIndex)).map(
                mapToExtendedTripModel,
            ) || [],
        [props],
    );

    const areAllFlightsSelected = useMemo(
        () =>
            Boolean(
                (flightState.selectedOutboundFlight || !props.selectedJourneys.includes(OUTBOUND)) &&
                    (flightState.selectedInboundFlight || !props.selectedJourneys.includes(INBOUND)),
            ),
        [flightState, props.selectedJourneys],
    );

    const selectFlight = (data: FlightSelectDTO) => {
        const newFlightState = clone(flightState);
        const newBundleState = clone(bundleState);
        const mappedBundle = mapToFakeBundleOffer(
            props.bundleColor,
            props.bundleImg,
            appContext.isFeatureSwitchActive("MileageAccrual"),
        );

        if (data.flightOption.JourneyIndex === OUTBOUND) {
            newFlightState.selectedOutboundFlight = data.flightOption;
            newBundleState.selectedOutboundBundle = mappedBundle;
        } else {
            newFlightState.selectedInboundFlight = data.flightOption;
            newBundleState.selectedInboundBundle = mappedBundle;
        }

        setFlightState(newFlightState);
        setBundleState(newBundleState);

        window.setTimeout(() => ScrollHelper.scrollToWeekSelector(newBundleState, data.referenceObject), 0);

        return Promise.resolve();
    };

    const resetFlight = (journeyIndex: number) => {
        setFlightState((prevFlightState) => ({
            ...prevFlightState,
            selectedOutboundFlight: journeyIndex === OUTBOUND ? undefined : prevFlightState.selectedOutboundFlight,
            selectedInboundFlight: journeyIndex === INBOUND ? undefined : prevFlightState.selectedInboundFlight,
        }));

        setBundleState((prevBundleState) => ({
            ...prevBundleState,
            selectedOutboundBundle: journeyIndex === OUTBOUND ? undefined : prevBundleState.selectedOutboundBundle,
            selectedInboundBundle: journeyIndex === INBOUND ? undefined : prevBundleState.selectedInboundBundle,
        }));
    };

    const showSelectedView = (flightOption: FlightOptionModel) => {
        if (!flightState) return false;

        return flightOption.JourneyIndex === OUTBOUND
            ? flightState.selectedOutboundFlight?.SellKey === flightOption.SellKey
            : flightState.selectedInboundFlight?.SellKey === flightOption.SellKey;
    };

    return {
        // REAL
        areAllBundlesSelected: areAllFlightsSelected,
        areAllFlightsSelected,
        bundleState,
        container: props.rootElem,
        flightState,
        journeys,
        model: props.viewModel,
        isThisFeeSelected: showSelectedView,
        resetFlight,
        selectFlight,
        showSelectedView,

        // MOCK
        bannerState: mockBannerState,
        dcState: mockDcState,
        farelockState: undefined,
        showTermsError: false,
        ssrIdsInOrder: [],
        tooManyPax: false,
        changeTermsAcceptance: () => null,
        dcPreventFarelock: () => false,
        getUpgradedBundle: () => null,
        handleLogin: () => null,
        handleRegister: () => null,
        openFarelockSelector: () => null,
        removeFarelock: () => null,
        resetDcMembership: () => null,
        selectBundle: () => null,
        selectDcOption: () => null,
        selectFarelockType: () => null,
        showBanner: () => false,
        showBundleUpgradeOffer: () => false,
        showFlightSelectError: () => false,
        showTooManyPaxModal: () => false,
        submitFarelockType: () => null,
        upgradeBundle: () => null,
    };
};

import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import {
    ChangeUserStatusEvent,
    EditUserEvent,
    GlobalFilterChangeEvent,
    ResetUserPasswordEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { Column, GridState, ViewModel } from "../../../dc-components/dc-table-models";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { SelectOption, updateMdl } from "../../../shared/common";
import { User } from "../../../component-models/CUG2b/User";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { PeruCompraUser } from "../../../component-models/CUG2b/PeruCompraUserData";
import { CLASS_NAMES } from "../../../shared/classNames";
import { usePubSub } from "../../../pub-sub-service/usePubSub";

export const name = "ac-peru-compra-users-grid";

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell"];

export interface Properties {
    availableRoles: SelectOption[];
    canAdd: boolean;
    currentUser: string;
    data: PeruCompraUser[];
    gridState: GridState<keyof PeruCompraUser>;
    totalItemCount: number;
    userToEdit: User;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        availableRoles: host.availableRoles,
        canAdd: host.canAdd,
        currentUser: host.currentUser,
        data: host.data,
        gridState: host.gridState,
        totalItemCount: host.totalItemCount,
        userToEdit: host.userToEdit,
    };

    // HELPERS

    // COMPONENT

    const { triggers } = usePubSub();

    const inputField = useRef<HTMLInputElement>(undefined);

    const [userToEdit, setUserToEdit] = useState<PeruCompraUser>(undefined);
    const [isAddingUser, setIsAddingUser] = useState<boolean>(false);

    useEffect(() => {
        if (isAddingUser) {
            setUserToEdit(undefined);
        }
    }, [isAddingUser]);

    useEffect(() => {
        if (userToEdit) {
            setIsAddingUser(false);
        }
    }, [userToEdit]);

    useEffect(() => {
        if (isAddingUser) {
            document.body.classList.add(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScroll);
        }
    }, [isAddingUser]);

    // EVENT LISTENERS

    const handleFilterChange = (e: Event) => {
        host.dispatchEvent(new GlobalFilterChangeEvent({ filterExpression: (e.target as HTMLInputElement).value }));
    };

    const handleResetPassword = (row: PeruCompraUser) => {
        if (row.Username === props.currentUser) {
            return;
        }

        host.dispatchEvent(new ResetUserPasswordEvent({ user: row }));
    };

    const handleEdit = (row: PeruCompraUser) => {
        if (row.Username === props.currentUser) {
            return;
        }

        setUserToEdit(row);
        host.dispatchEvent(new EditUserEvent({ user: row }));
    };

    const handleUserStatus = (row: PeruCompraUser) => {
        if (row.Username === props.currentUser) {
            return;
        }

        host.dispatchEvent(new ChangeUserStatusEvent({ user: row }));
    };

    // TEMPLATES

    const editUserTemplate = () => {
        return props.userToEdit ? html` <ac-cug-edit-user .userToEdit=${props.userToEdit}></ac-cug-edit-user> ` : "";
    };

    const rowEditTemplate = (index: number) => {
        const row = props.data[index];

        return html`
            <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    ${row.Username !== props.currentUser
                        ? html`
                              <ac-tooltip
                                  .allowClickThrough=${true}
                                  .iconClasses=${"js-icon-cug|js-cug-lock|cug2b-reset-password-icon"}
                                  .tooltip=${html`
                                      ${i18next.t("Resetear contraseña")}
                                      ${i18next.t(
                                          "Con ésta opción la contraseña actual cadurá y enviaremos una contraseña temporal al correo del usuario seleccionado.",
                                      )}
                                  `}
                                  @click=${() => {
                                      handleResetPassword(row);
                                      triggers.cug.openModal.publish({});
                                  }}
                              ></ac-tooltip>

                              <i class="js-icon-cug js-cug-edit relative" @click=${() => handleEdit(row)}> </i>
                              ${userToEdit?.Username === row.Username ? editUserTemplate() : ""}
                          `
                        : ""}
                </div>
            </div>
        `;
    };

    const cellTemplate = (index: number, field: keyof PeruCompraUser) => {
        const row = props.data[index];

        if (field && field !== "IsInActiveStatus") {
            return html` <span title=${row[field]?.toString() || ""}>${row[field]?.toString() || ""}</span> `;
        }

        if (field && field === "IsInActiveStatus") {
            return html`
                <span
                    class="flex cursor-pointer items-center justify-center hover:text-cug-red hover:underline"
                    @click=${() => handleUserStatus(row)}
                >
                    ${row[field] ? i18next.t("Activo") : i18next.t("Inactivo")}
                </span>
            `;
        }

        return "";
    };

    const addUserModalTemplate = () =>
        isAddingUser
            ? html`
                  <ac-cug-add-user-modal
                      .availableRoles=${props.availableRoles}
                      @addSubmission=${() => setIsAddingUser(false)}
                      @close=${() => setIsAddingUser(false)}
                  ></ac-cug-add-user-modal>
              `
            : "";

    const addUserButtonTemplate = () =>
        props.canAdd
            ? html`
                  <div class="flex w-full flex-wrap items-center justify-center sm:w-auto">
                      <div
                          class="cug2b-pill"
                          data-test-id=${T.CUG2_USERS.ADD_USER_BUTTON}
                          @click=${() => setIsAddingUser(true)}
                      >
                          <i class="js-icon-cug js-cug-man-and-plus"></i>${i18next.t("Agregar usuario")}
                          ${addUserModalTemplate()}
                      </div>
                  </div>
              `
            : "";

    const filterTemplate = () => {
        return html`
            <div class="cug2b-search-and-buttons">
                <div class="cug2b-searchbox">
                    <input
                        ref=${ref(inputField)}
                        placeholder=${i18next.t("Buscar")}
                        autocomplete="cc-exp"
                        type="text"
                        value=${props.gridState.globalFilterExpression}
                        @input=${handleFilterChange}
                    />
                    <i class="js-icon-cug js-cug-search"></i>
                </div>
                ${addUserButtonTemplate()}
            </div>
        `;
    };

    if (!props.gridState || !props.data) {
        return html``;
    }

    const vm: ViewModel<keyof PeruCompraUser> = {
        columns: [
            {
                field: "FirstName",
                columnType: "string",
                label: i18next.t("Nombres"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => cellTemplate(index, "FirstName"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Apellido"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => cellTemplate(index, "LastName"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "Username",
                columnType: "string",
                label: i18next.t("DNI"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => cellTemplate(index, "Username"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "Email",
                columnType: "string",
                label: i18next.t("Email"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Email"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "OfficePhone",
                columnType: "string",
                label: i18next.t("Office phone"),
                sortable: true,
                columnClass: "pcompra-whitespace-normal",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "OfficePhone"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "MobilePhone",
                columnType: "string",
                label: i18next.t("Teléfono móvil"),
                sortable: true,
                columnClass: "pcompra-whitespace-normal",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "MobilePhone"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "Role",
                columnType: "string",
                label: i18next.t("Rol"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Role"),
            } as Column<keyof PeruCompraUser>,
            {
                field: "IsInActiveStatus",
                columnType: "string",
                label: i18next.t("Estado"),
                sortable: true,
                cellClass: () => "text-center arrow-after",
                cellTemplate: (index: number) => cellTemplate(index, "IsInActiveStatus"),
            } as Column<keyof PeruCompraUser>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: false,
        },
        rowCustomization: [],
        appliedFilters: [],
        actionCellClass,
        actionCellContentClass,
        useEllipsis: true,
        rowEditTemplate,
    };

    return html` ${updateMdl()} ${filterTemplate()}<dc-table-grid .vm=${vm}></dc-table-grid> `;
};

import { classMap } from "lit-html/directives/class-map";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useState } from "../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "../../../component-models/CUG2b/Partner";
import i18next from "i18next";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { SubmitAddTravelPartnerModalEvent } from "../../../component-models/passengers/PassengerEvents";
import { useFlowContext } from "../../../managers/useFlowContext";

export const name = "ac-add-travel-partners-to-booking";

export interface Properties {
    adultPaxCount: number;
    travelPartnerInfo: TravelPartnerInfo;
    onClose: () => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        adultPaxCount: host.adultPaxCount,
        travelPartnerInfo: host.travelPartnerInfo,
        onClose: host.onClose,
    };

    // HELPERS

    const orderedModel = () =>
        props.travelPartnerInfo
            ? TravelPartnerHelper.getOrderedFilteredModel(props.travelPartnerInfo?.Partners, gridState)
            : [];

    const tooManyPax = () => selectedIds.size > props.adultPaxCount;

    // COMPONENT

    const [gridState, setGridState] = useState<GridState<keyof Partner>>({
        pageIndex: 0,
        appliedFilters: [],
        pageSize: 10,
        orderBy: undefined,
        orderDir: "none",
        globalFilterExpression: "",
    });

    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [filterExpression, setFilterExpression] = useState<string>("");

    // EVENT LISTENERS
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            setGridState({
                ...gridState,
                pageIndex: 0,
                globalFilterExpression: filterExpression,
            });
        }
    };

    const handleFilterChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value);
    };

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({ ...gridState, pageIndex: e.detail.selectedPageIndex, pageSize: e.detail.selectedPageSize });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(GridHelper.getSelectedIds(event, selectedIds, orderedModel()) as Set<number>);
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({ ...gridState, orderBy: e.detail.orderBy as keyof Partner, orderDir: e.detail.orderDir });
    };

    const handleSubmit = () => {
        setSelectedIds(new Set<number>());
        setFilterExpression("");
        host.dispatchEvent(
            new SubmitAddTravelPartnerModalEvent({
                partners: orderedModel().filter((p) => selectedIds.has(p.Id)),
            }),
        );
    };

    const handleClose = () => {
        setSelectedIds(new Set<number>());
        setFilterExpression("");
        props.onClose();
    };

    // TEMPLATES

    const filterTemplate = () => html`
        <div class="cug2b-search-travel-partner-booking">
            <input
                placeholder=${i18next.t("Buscar")}
                autocomplete="cc-exp"
                type="text"
                .value=${null}
                @keydown=${handleKeyDown}
                @input=${handleFilterChange}
            />
            <i class="js-icon-cug js-cug-search"></i>
        </div>
    `;

    const buttonsTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": tooManyPax(),
        });

        const tabIndex = tooManyPax() ? "-1" : null;

        return html`
            <div class="mb-0 mt-8 flex w-full flex-col items-center justify-center sm:flex-row md:mb-16">
                <button class="rounded-secondary-btn with-arrow cug2b-white mb-4 sm:mb-0 sm:mr-4" @click=${handleClose}>
                    ${i18next.t("Volver")}
                </button>
                <button class=${tempClassMap} tabindex=${tabIndex} @click=${handleSubmit}>
                    ${i18next.t("Continuar")}
                </button>
            </div>
        `;
    };

    const quantityErrorTemplate = () =>
        tooManyPax()
            ? flowContext.isFarelockRoundOne
                ? html`
                      <div class="cug2b-quantity-error">
                          <i class="js-icon js-broken-circle-exclamation"></i>
                          ${i18next.t(
                              "Ya agregaste la cantidad pasajeros frecuentes permitida según la reserva que estás realizando, si quieres agregar más pasajeros deberás volver a la página anterior y modificarlo.",
                          )}
                      </div>
                  `
                : html`
                      <div class="cug2b-quantity-error">
                          <i class="js-icon js-broken-circle-exclamation"></i>
                          ${unsafeHTML(
                              i18next.t(
                                  "Tu reserva es solo para {{-start}}{{amount}} pasajero{{plural}}{{-end}} y recuerda que no puedes seleccionar más pasajeros de los que contiene tu búsqueda.",
                                  {
                                      start: "<span>",
                                      end: "</span>",
                                      amount: props.adultPaxCount,
                                      plural: props.adultPaxCount !== 1 ? "s" : "",
                                  },
                              ),
                          )}
                      </div>
                  `
            : "";

    const flowContext = useFlowContext();

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) => `${orderedModel()[index].FirstName} ${orderedModel()[index].LastName}`,
            } as Column<keyof Partner>,

            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "DOB",
                columnType: "string",
                label: i18next.t("Fecha de Nacimiento"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => orderedModel()[index].DOB.format("DD-MM-YYYY"),
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "GroupId",
                columnType: "string",
                label: i18next.t("Grupo"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) =>
                    orderedModel()[index].GroupId
                        ? props.travelPartnerInfo.Groups.find((g) => g.Id === orderedModel()[index].GroupId)?.Name
                        : "",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.travelPartnerInfo?.Partners.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel().some((item) => item.Id === id))
                .map((id) =>
                    orderedModel()
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: true,
    };

    return props.travelPartnerInfo
        ? html`
              <div class="m-8 block h-full lg:m-16">
                  ${filterTemplate()} ${quantityErrorTemplate()}
                  <dc-table
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onRowsSelect=${onRowsSelect}
                  ></dc-table>
                  ${buttonsTemplate()}
              </div>
          `
        : html``;
};

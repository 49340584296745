import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { ChangeDateEvent } from "./../../../dc-components/dc-datepicker";
import { CHILEAN_CULTURE_CODE, DEFAULT_DATE_FORMAT } from "./../../../shared/commonConstants";
import {
    ClearFilterByDateEvent,
    ClickFilterByDateEvent,
    EditRowEvent,
    GlobalFilterChangeEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";
import i18next from "i18next";
import { CugReservation } from "../../../component-models/CUG2b/CugBookings";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { Column, GridState, ViewModel } from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { updateMdl } from "../../../shared/common";
import { classMap } from "lit-html/directives/class-map";
import { ROUTES } from "../../../shared/apiRoutes";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useDatepicker } from "../../ui/useDatepicker/useDatepicker";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export const name = "ac-cug-confirmed-reservations";

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell"];

export interface Properties {
    canExport: boolean;
    data: CugReservation[];
    filterFlightDateFrom: dayjs.Dayjs;
    filterFlightDateTo: dayjs.Dayjs;
    filterPurchaseDateFrom: dayjs.Dayjs;
    filterPurchaseDateTo: dayjs.Dayjs;
    gridState: GridState<keyof CugReservation>;
    selectedIds: string[];
    totalItemCount: number;
    url: string;
    setFilterFlightDateFrom: (newState: dayjs.Dayjs) => void;
    setFilterFlightDateTo: (newState: dayjs.Dayjs) => void;
    setFilterPurchaseDateFrom: (newState: dayjs.Dayjs) => void;
    setFilterPurchaseDateTo: (newState: dayjs.Dayjs) => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        canExport: host.canExport,
        data: host.data,
        filterFlightDateFrom: host.filterFlightDateFrom,
        filterFlightDateTo: host.filterFlightDateTo,
        filterPurchaseDateFrom: host.filterPurchaseDateFrom,
        filterPurchaseDateTo: host.filterPurchaseDateTo,
        gridState: host.gridState,
        selectedIds: host.selectedIds,
        totalItemCount: host.totalItemCount,
        url: host.url,
        setFilterFlightDateFrom: host.setFilterFlightDateFrom,
        setFilterFlightDateTo: host.setFilterFlightDateTo,
        setFilterPurchaseDateFrom: host.setFilterPurchaseDateFrom,
        setFilterPurchaseDateTo: host.setFilterPurchaseDateTo,
    };

    // HELPERS

    const isDownloadSelectionDisabled = () => {
        return props.selectedIds.length === 0;
    };

    const getToDate = () =>
        !props.filterFlightDateFrom ||
        !props.filterFlightDateTo ||
        props.filterFlightDateFrom.isBefore(props.filterFlightDateTo, "day")
            ? props.filterFlightDateTo
            : props.filterFlightDateFrom;

    const getToPurchaseDate = () =>
        !props.filterPurchaseDateFrom ||
        !props.filterPurchaseDateTo ||
        props.filterPurchaseDateFrom.isBefore(props.filterPurchaseDateTo, "day")
            ? props.filterPurchaseDateTo
            : props.filterPurchaseDateFrom;

    // COMPONENT

    const inputField = useRef<HTMLInputElement>(undefined);

    const cug2AppContext = useCug2AppContext();

    const [filterExpression, setFilterExpression] = useState<string>("");

    const fromDatePicker = useDatepicker({
        classes: [],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-from"],
        placeHolder: i18next.t("Desde"),
        value: props.filterFlightDateFrom,
        onChange: (e: ChangeDateEvent) => props.setFilterFlightDateFrom(e.detail.date),
    });

    const toDatePicker = useDatepicker({
        classes: ["sm-align-right", "xs-align-right"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-to"],
        placeHolder: i18next.t("Hasta"),
        value: props.filterFlightDateFrom
            ? [
                  props.filterFlightDateFrom.format(DEFAULT_DATE_FORMAT) || "",
                  getToDate()?.format(DEFAULT_DATE_FORMAT) || "",
              ]
            : getToDate()?.format(DEFAULT_DATE_FORMAT),
        min: props.filterFlightDateFrom || undefined,
        isRange: Boolean(props.filterFlightDateFrom),
        onChange: (e: ChangeDateEvent) =>
            props.setFilterFlightDateTo(Boolean(props.filterFlightDateFrom) ? e.detail.toDate : e.detail.date),
    });

    const purchaseFromDatePicker = useDatepicker({
        classes: ["align-right", "sm-align-left", "xs-align-left"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-from"],
        placeHolder: i18next.t("Desde"),
        value: props.filterPurchaseDateFrom,
        onChange: (e: ChangeDateEvent) => props.setFilterPurchaseDateFrom(e.detail.date),
    });

    const purchaseToDatePicker = useDatepicker({
        classes: ["align-right", "sm-align-right", "xs-align-right"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-to"],
        placeHolder: i18next.t("Hasta"),
        value: props.filterPurchaseDateFrom
            ? [
                  props.filterPurchaseDateFrom.format(DEFAULT_DATE_FORMAT) || "",
                  getToPurchaseDate()?.format(DEFAULT_DATE_FORMAT) || "",
              ]
            : getToPurchaseDate()?.format(DEFAULT_DATE_FORMAT),
        min: props.filterPurchaseDateFrom || undefined,
        isRange: Boolean(props.filterPurchaseDateFrom),
        onChange: (e: ChangeDateEvent) =>
            props.setFilterPurchaseDateTo(Boolean(props.filterPurchaseDateFrom) ? e.detail.toDate : e.detail.date),
    });

    // EVENT LISTENERS

    const handleReport = () => {
        if (!props.url) {
            return;
        }

        window.open(props.url);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            inputField.current?.blur();
            host.dispatchEvent(new GlobalFilterChangeEvent({ filterExpression }));
        }
    };

    const handleFilterByDateClick = () => {
        host.dispatchEvent(new ClickFilterByDateEvent());
    };

    const emptyDateFilters = () => {
        props.setFilterFlightDateFrom(undefined);
        props.setFilterFlightDateTo(undefined);
        props.setFilterPurchaseDateFrom(undefined);
        props.setFilterPurchaseDateTo(undefined);
        host.dispatchEvent(new ClearFilterByDateEvent());
    };

    const handleFilterChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const editRow = (index: number, toExtras = false) => {
        host.dispatchEvent(new EditRowEvent({ index, toExtras }));
    };

    const handleDownload = (selectedOnly: boolean) => {
        window.location.href =
            ROUTES.ApiRoutes.AgencyReservationsCsv +
            `?bookingStatus=Confirmed` +
            `&pageSize=1000` +
            `&page=0` +
            `&orderBy=FlightDate` +
            `&orderDirection=asc` +
            `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}` +
            `&IsCsvRequested=true` +
            (selectedOnly ? `&spnrs=${Array.from(props.selectedIds.values()).join("|")}` : "");
    };

    // TEMPLATES

    const checkinButtonTemplate = (index: number) => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "white-red": true,
            "agency-reservation-checkin-btn": true,
            "disabled": !props.data[index].CanCheckIn,
        });

        return html` <button class=${tempClassMap} @click=${() => editRow(index)}>${i18next.t("Check in")}</button> `;
    };

    const extrasButtonTemplate = (index: number) => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "white-blue": true,
            "agency-reservation-extras-btn": true,
            "disabled": !props.data[index].ShowExtrasLink,
        });

        return html`
            <button class=${tempClassMap} @click=${() => editRow(index, true)}>${i18next.t("V2-Extras")}</button>
        `;
    };

    const rowEditTemplate = (index: number) =>
        html`
            <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i class="js-icon-cug js-cug-edit" @click=${() => editRow(index)}></i>
                    <div class="agency-reservations-button-container">
                        ${checkinButtonTemplate(index)} ${extrasButtonTemplate(index)}
                    </div>
                </div>
            </div>
        `;

    const cellTemplate = (index: number, field: keyof CugReservation) => {
        const row = props.data[index];

        if (field) {
            return html` <span title=${row[field]?.toString() || ""}>${row[field]?.toString() || ""}</span> `;
        }

        return "";
    };

    const filterTemplate = () => {
        return html`
            <div class="cug2b-search-and-title push-down">
                <div class="cug2b-page-subtitle">${i18next.t("Reservas confirmadas")}</div>
                <div class="cug2b-searchbox">
                    <input
                        placeholder=${i18next.t("Buscar")}
                        ref=${ref(inputField)}
                        value=${props.gridState.globalFilterExpression}
                        autocomplete="cc-exp"
                        type="text"
                        name="filter"
                        @keydown=${handleKeyDown}
                        @input=${handleFilterChange}
                    />
                    <i class="js-icon-cug js-cug-search"></i>
                </div>
            </div>
        `;
    };

    const filterByDateTemplate = () => {
        return html`
            <div class="cug2-filter-by-date-container">
                <div class="cug2-filter-by-date push-down">
                    <div class="cug2-label-and-datepickers">
                        <label>
                            ${unsafeHTML(
                                i18next.t("Filtrar por {{-spanStart}}fecha de vuelo:{{-spanEnd}}", {
                                    spanStart: '<span class="font-bold">',
                                    spanEnd: "</span>",
                                }),
                            )}
                        </label>
                        <div class="cug2-datepickers">
                            ${fromDatePicker.htmlTemplate()} ${toDatePicker.htmlTemplate()}
                        </div>
                    </div>
                    <div class="cug2-label-and-datepickers">
                        <label>
                            ${unsafeHTML(
                                i18next.t("Filtrar por {{-spanStart}}fecha de compra:{{-spanEnd}}", {
                                    spanStart: '<span class="font-bold">',
                                    spanEnd: "</span>",
                                }),
                            )}
                        </label>
                        <div class="cug2-datepickers">
                            ${purchaseFromDatePicker.htmlTemplate()} ${purchaseToDatePicker.htmlTemplate()}
                        </div>
                    </div>
                    <div class="cug2-filter-button-container">
                        <button class="rounded-primary-btn cug2b-btn" @click=${handleFilterByDateClick}>
                            ${i18next.t("Buscar")}
                        </button>
                    </div>
                </div>
                <div class="flex justify-end">
                    <div class="cug2-empty-filters" @click=${emptyDateFilters}>${i18next.t("Limpiar Filtros")}</div>
                </div>
            </div>
        `;
    };

    const exportInvoicesTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "cug2b-btn": true,
            "sm:mr-3": true,
            "mb-4": props.canExport,
            "sm:mb-0": props.canExport,
        });

        return props.url && cug2AppContext.Culture === CHILEAN_CULTURE_CODE
            ? html` <button @click=${handleReport} class=${tempClassMap}>${i18next.t("Ver facturas")}</button> `
            : "";
    };

    const exportReservationsTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "cug2b-btn": true,
            "disabled": isDownloadSelectionDisabled(),
        });

        return props.canExport
            ? html`
                  <button
                      @click=${() => handleDownload(false)}
                      class="rounded-primary-btn cug2b-btn mb-4 sm:mb-0 sm:mr-3"
                  >
                      ${i18next.t("CUG-Download")}
                  </button>
                  <button @click=${() => handleDownload(true)} class=${tempClassMap}>
                      ${i18next.t("CUG-DownloadSelected")}
                  </button>
              `
            : "";
    };

    const exportTemplate = () =>
        props.canExport
            ? html`
                  <div class="flex items-center flex-col sm:flex-row justify-end mt-8">
                      ${exportInvoicesTemplate()} ${exportReservationsTemplate()}
                  </div>
              `
            : "";

    if (!props.gridState || !props.data) {
        return html``;
    }

    const vm: ViewModel<keyof CugReservation> = {
        columns: [
            {
                field: "ReservationCode",
                columnType: "string",
                label: i18next.t("Código reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ReservationCode"),
            } as Column<keyof CugReservation>,
            {
                field: "Route",
                columnType: "string",
                label: i18next.t("Ruta"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Route"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedFlightDate",
                columnType: "string",
                label: i18next.t("Fecha de vuelo"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha de vuelo")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG2-FlightDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedFlightDate"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedPassengers",
                columnType: "string",
                label: i18next.t("Cant. de pasajeros"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedPassengers"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedBy",
                columnType: "string",
                label: i18next.t("Creado por"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedBy"),
            } as Column<keyof CugReservation>,
            {
                field: "LatestPaymentAddedBy",
                columnType: "string",
                label: i18next.t("Pagado por"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "LatestPaymentAddedBy"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedByAgent",
                columnType: "string",
                label: i18next.t("Usuario"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedByAgent"),
            } as Column<keyof CugReservation>,
            {
                field: "Contact",
                columnType: "string",
                label: i18next.t("Contacto"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "Contact"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedAgencyFee",
                columnType: "string",
                label: i18next.t("Fee de agencia"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedAgencyFee"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedTotalAmount",
                columnType: "string",
                label: i18next.t("Monto pagado"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedTotalAmount"),
            } as Column<keyof CugReservation>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(props.selectedIds.values()).map((id) =>
                props.data.map((i) => i.Id).indexOf(id),
            ),
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    return html`
        ${updateMdl()} ${filterTemplate()} ${filterByDateTemplate()}
        <div class="cug2b-confirmed-reservation-scrolling-tip">
            ${i18next.t("Deslizando hacia la derecha en la tabla podrás visualizar más campos.")}
        </div>
        <dc-table-grid .vm=${vm}></dc-table-grid>${exportTemplate()}
    `;
};

import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../shared/useModal";

export const useAntifraudResultModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body leading-loose text-be-blue">
            ${i18next.t(
                "Tu transacción ha sido rechazada. Por favor contáctate con tu banco o intenta nuevamente con otra tarjeta o método de pago disponible.",
            )}
            <div class="mt-8 flex items-center justify-center">
                <button class="rounded-primary-btn" @click=${modal.close}>${i18next.t("Reintentar")}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => i18next.t("Importante") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";

export const chilePhoneLength = (): InputFieldAttribute => ({
    name: "chile-length",
    governingFieldAttributeName: "phone-prefix",
    validators: [
        {
            errorMessage: {
                id: "chileLength",
                message: window.formResources.fieldMustHave + "9" + window.formResources.charactersLength,
                scope: "field",
            },

            validate: (field: InputElement, governingField: InputElement) => {
                const result = governingField.value !== "56" || field.value.length === 0 || field.value.length === 9;

                return Promise.resolve(result);
            },
        },
    ],
});

import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { TestIdDictionary } from "../testing-helpers/TestIdHelper";
import { PERUVIAN_CULTURE_CODE } from "../shared/commonConstants";

// DEVNOTE Sorry, this has to be hard coded, you cannot put these lists in the XML
export const peruOfflineBankLogosTemplate = (isOffline: boolean, culture: string) =>
    isOffline && culture.toLowerCase() === PERUVIAN_CULTURE_CODE.toLowerCase()
        ? html`
              <div class="peru-payment-banks">
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-3 col-md-1-4">
                          <div class="peru-payment-info">
                              <h2>${i18next.t("OfflinePaymentInfoPeruTitle")}</h2>
                              <p>${i18next.t("OfflinePaymentInfoPeru")}</p>
                          </div>
                      </div>
                      <div class="col-xs-1 col-sm-2-3 col-md-3-4">
                          <div class="peru-payment-logos">
                              <img src="/Images/Icons/bank-logos/agentesKasnet.png" />
                              <img src="/Images/Icons/bank-logos/bancoripley.png" />
                              <img src="/Images/Icons/bank-logos/bbva.png" />
                              <img src="/Images/Icons/bank-logos/bcp.png" />
                              <img src="/Images/Icons/bank-logos/interbank.png" />
                              <img src="/Images/Icons/bank-logos/scotiabank.png" />
                              <img src="/Images/Icons/bank-logos/cajaarequipa.png" />
                              <img src="/Images/Icons/bank-logos/cajahuancayo.png" />
                              <img src="/Images/Icons/bank-logos/cajapaita.png" />
                              <img src="/Images/Icons/bank-logos/cajatacna.png" />
                              <img src="/Images/Icons/bank-logos/cajatrujillo.png" />
                              <img src="/Images/Icons/bank-logos/tambo.png" />
                              <img src="/Images/Icons/bank-logos/westernunion.png" />
                          </div>
                      </div>
                  </div>
              </div>
          `
        : "";

// DEVNOTE Sorry, this has to be hard coded, you cannot put these lists in the XML
export const peruBankTransferLogosTemplate = (isPeruTransfer: boolean, culture: string) =>
    isPeruTransfer && culture.toLowerCase() === PERUVIAN_CULTURE_CODE.toLowerCase()
        ? html`
              <div class="peru-payment-banks">
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-3 col-md-1-4">
                          <div class="peru-payment-info">
                              <h2>${i18next.t("BankTransfer")}</h2>
                              <p>${i18next.t("BankTransferInfoPeru")}</p>
                          </div>
                      </div>
                      <div class="col-xs-1 col-sm-2-3 col-md-3-4">
                          <div class="peru-payment-logos">
                              <img src="/Images/Icons/peru-transfer/bcp.png" />
                              <img src="/Images/Icons/peru-transfer/bbva.png" />
                              <img src="/Images/Icons/peru-transfer/interbank.png" />
                              <img src="/Images/Icons/peru-transfer/scotiabank.png" />
                              <img src="/Images/Icons/peru-transfer/cajaarequipa.png" />
                              <img src="/Images/Icons/peru-transfer/cajahuancayo.png" />
                              <img src="/Images/Icons/peru-transfer/cajatacna.png" />
                              <img src="/Images/Icons/peru-transfer/cajatrujillo.png" />
                          </div>
                      </div>
                  </div>
              </div>
          `
        : "";

export const voucherTooltipTemplate = (isReduced: boolean) => {
    const tempClassMap = classMap({ "voucher-tooltip": true, "reduced": isReduced });

    return html`
        <div class=${tempClassMap} data-test-id=${TestIdDictionary.PAYMENT.VOUCHER_TOOLTIP}>
            ${i18next.t("Payment-GiftCardInfoLink")}
            <div class="voucher-tooltip-info">
                <div class="arrow-box">
                    <span>${i18next.t("Payment-GiftCardInfo")}</span>
                </div>
            </div>
        </div>
    `;
};

export const voucherHeaderTemplate = () => html`
    <h2 class="hidden-xs main-title gift-card-logo" data-test-id="voucher-gift-card-title">
        ${unsafeHTML(
            i18next.t("V2-PayGiftCard-New {{-spanStart}}{{-spanEnd}}", {
                spanStart: "<span>",
                spanEnd: "</span>",
            }),
        )}
        <img src="/Images/Header/jetsmart-logo-colored.svg" />
    </h2>
`;

export const voucherMobileHeaderTemplate = () => html`
    <h2 class="hidden-sm-up main-title gift-card-logo">
        ${i18next.t("V2-PayGiftCard-New-Mobile")}
        <img src="/Images/Giftcard/giftcard-mobile-logo.png" />
    </h2>
`;

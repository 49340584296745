import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import classNames from "classnames";
import { TemplateResult, html } from "lit-html";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useLayoutEffect, useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { isInViewport, isMobile } from "../../../shared/common";

export const name = "ac-dropdown";

export interface Props {
    isOpen: boolean;
    maxWidth?: number;
    opensToModal?: boolean;
    template: TemplateResult;
    title?: string;
    onClose?: () => void;
}

const MIN_WIDTH = 240;
const MAX_HEIGHT = 256;

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        isOpen: host.isOpen,
        maxWidth: host.maxWidth,
        opensToModal: host.opensToModal,
        template: host.template,
        title: host.title,
        onClose: host.onClose,
    };

    const [fitsDownwards, setFitsDownwards] = useState<boolean>(true);

    const root = useRef<HTMLDivElement>(undefined);

    useLayoutEffect(() => {
        if (!root.current) return;

        setFitsDownwards(isInViewport(root.current, isMobile() ? 100 : 0));
    }, [props.isOpen]);

    return html`
        ${props.isOpen && props.opensToModal
            ? html`<div class="fixed inset-0 z-[100000000000] bg-black bg-opacity-50" @click=${props.onClose}></div>`
            : ""}
        <div
            ref=${ref(root)}
            style=${`min-width: ${MIN_WIDTH}px;max-width:${props.maxWidth || 9999}px;max-height:${props.isOpen ? `${MAX_HEIGHT + (props.opensToModal ? 80 : 0)}px` : "0px"};`}
            class=${classNames(
                "z-[100000000001] w-full select-none overflow-hidden rounded-md border-solid border-brand-secondary bg-white",
                {
                    "border shadow-lg": props.isOpen,
                    "absolute left-0": !props.opensToModal,
                    "top-[100%] mt-px": !props.opensToModal && fitsDownwards,
                    "bottom-[100%] mb-px": !props.opensToModal && !fitsDownwards,
                    "fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] transform": props.opensToModal,
                },
            )}
        >
            ${props.opensToModal && props.title
                ? html`
                      <div class="bg-brand-secondary px-6 py-4 text-center font-bold text-white">${props.title}</div>
                  `
                : ""}
            <div class=${classNames({ "px-6 py-4": props.opensToModal })}>${props.template}</div>
        </div>
    `;
};

import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { DEFAULT_DATE_FORMAT, INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { Day } from "../../component-models/flight-calendar/TripCalendar";
import { DaySelectEvent } from "../../component-models/flight-calendar/FlightCalendarEvents";

export const name = "ac-flight-calendar-day";

export interface Props {
    calendarIndex: number;
    day: Day;
    selectedInboundDate: dayjs.Dayjs;
    selectedOutboundDate: dayjs.Dayjs;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        calendarIndex: host.calendarIndex,
        day: host.day,
        selectedInboundDate: host.selectedInboundDate,
        selectedOutboundDate: host.selectedOutboundDate,
    };

    const isSelected = (month = false) =>
        (props.calendarIndex === OUTBOUND ? props.selectedOutboundDate : props.selectedInboundDate).isSame(
            props.day.Date,
            month ? "month" : "day",
        );

    const isDisabled = () =>
        props.calendarIndex === INBOUND && props.day.Date.isBefore(props.selectedOutboundDate, "day");

    const handleClick = () => {
        host.dispatchEvent(
            new DaySelectEvent({
                day: props.day,
                calendarIndex: props.calendarIndex,
            }),
        );
    };

    const dataTestId = (unavailable: boolean, disabled: boolean) => {
        const prefix = unavailable
            ? T.FLIGHT_CALENDAR.DAY_CONTENT_UNAVAILABLE
            : disabled
            ? T.FLIGHT_CALENDAR.DAY_CONTENT_DISABLED
            : T.FLIGHT_CALENDAR.DAY_CONTENT;
        return getTestId(prefix, {
            j: props.calendarIndex,
            c: props.day.Date.format(DEFAULT_DATE_FORMAT),
        });
    };

    const unavailableTemplate = () =>
        !isSelected(true)
            ? html`
                  <div class="day unavailable" data-test-id=${dataTestId(true, false)}></div>
              `
            : "";

    const noFlightsTemplate = () =>
        isSelected(true) && props.day.NoFlights
            ? html`
                  <div class="day disabled ${isSelected() ? "selected" : ""} ">
                      &times;
                      <span class="date">${props.day.Date.format("D")}</span>
                  </div>
              `
            : "";

    const hasFlightsTemplate = () => {
        const labelClassMap = classMap({
            "non-pico": true,
            "disabled": isDisabled(),
        });

        const mainClassMap = classMap({
            "day": true,
            "disabled": isDisabled(),
            "selected": isSelected(),
            "with-label": props.day.IsCheapest && !isSelected(),
            // TODO Let's not use a special class for testing
            "cy-cheapest": props.day.IsCheapest,
        });

        return isSelected(true) && !props.day.NoFlights && props.day.HasAmount
            ? html`
                  <input
                      type="radio"
                      class="calendar-selector"
                      id=${props.day.InputId}
                      name=${props.day.InputName}
                      ?checked=${isSelected()}
                      @change=${handleClick}
                  />
                  <label class=${labelClassMap} for=${props.day.InputId}>
                      <div
                          class=${mainClassMap}
                          data-test-id=${dataTestId(false, isDisabled())}
                          data-test-value=${props.day.Amount}
                      >
                          ${props.day.FormattedAmount}
                          <span class="date">${props.day.Date.format("D")}</span>
                          ${selectedDayPriceTemplate()} ${cheapestDayPriceTemplate()}
                      </div>
                  </label>
              `
            : "";
    };

    // TODO Let's not use a special class for testing
    const cheapestDayPriceTemplate = () =>
        !isSelected() && props.day.IsCheapest
            ? html`
                  <label
                      class="cy-cheapest"
                      data-test-id=${dataTestId(false, isDisabled())}
                      for=${props.day.InputId}
                      data-test-value=${props.day.Amount}
                  >
                      ${i18next.t("V2-BestPriceLabel")}
                  </label>
              `
            : "";

    // TODO Let's not use a special class for testing
    const selectedDayPriceTemplate = () =>
        isSelected()
            ? html`
                  <label
                      class=${props.day.IsCheapest ? "cy-cheapest" : ""}
                      data-test-id=${dataTestId(false, false)}
                      for=${props.day.InputId}
                      data-test-value=${props.day.Amount}
                  >
                      ${i18next.t("V2-TarifaJetSmart")}
                  </label>
              `
            : "";

    return html`
        ${unavailableTemplate()} ${noFlightsTemplate()} ${hasFlightsTemplate()}
    `;
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "lit-html";
import { sanitizeRutFieldValue } from "../../shared/common";
import { LoginType } from "../../shared/commonConstants";
import { LoginVM } from "./login-model";
import { ValidatorPartialMethods } from "./login-page";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { useAppContext } from "../../managers/useAppContext";

export interface Properties {
    vm: LoginVM;
    setVm: (vm: LoginVM) => void;
    validatorPartialMethods: ValidatorPartialMethods;
    acceptedRut: string;
    gRecaptchaSiteKey: string;
    loginType: LoginType;
    showCustomerAndAgencyTabs: boolean;
    showPeruCompraTab: boolean;

    handleSubmit: (e: MouseEvent) => Promise<void>;
    setLoginType: (state: LoginType) => void;
}

const captchaContainerClass = "ac-captcha-container";

export const useBancoEstadoLogin = (props: Properties) => {
    const appContext = useAppContext();

    // HELPERS

    const initCaptcha = () => {
        if (!appContext.isFeatureSwitchActive("Captcha")) {
            return;
        }

        const grecaptchaInterval = window.setInterval(() => {
            const captchaContainer = document.body.querySelector(`.${captchaContainerClass} div`) as HTMLDivElement;

            if (window.grecaptcha && captchaContainer) {
                setCaptchaId(
                    window.grecaptcha.render(captchaContainer, {
                        sitekey: props.gRecaptchaSiteKey,
                    }),
                );

                window.clearInterval(grecaptchaInterval);
                window.clearTimeout(grecaptchaTimeLimit);
            }
        }, 100);

        const grecaptchaTimeLimit = window.setTimeout(() => {
            window.clearInterval(grecaptchaInterval);
            setShowCaptchaLoadError(true);
        }, 3000);
    };

    // COMPONENT
    const formErrors = useErrorMessage({ errorMessage: props.validatorPartialMethods.getFormMessages() });

    const [captchaId, setCaptchaId] = useState<any>(undefined);
    const [showCaptchaError, setShowCaptchaError] = useState<boolean>(undefined);
    const [showCaptchaLoadError, setShowCaptchaLoadError] = useState<boolean>(undefined);
    const [showCaptchaBackendError, setShowCaptchaBackendError] = useState<boolean>(undefined);

    useEffect(() => {
        if (props.loginType === "BE") {
            initCaptcha();
        }
    }, [props.loginType]);

    // EVENT LISTENERS

    // TEMPLATES

    const loginTypeSelectorTemplate = () => html`
        <ac-login-type-selector
            .showBancoEstado=${true}
            .showCustomerAndAgencyTabs=${props.showCustomerAndAgencyTabs}
            .showPeruCompraTab=${props.showPeruCompraTab}
            .loginType=${props.loginType}
            .setLoginType=${props.setLoginType}
        ></ac-login-type-selector>
    `;

    const nonAgencyBannerTemplate = () => html`
        <div class="col-xs-1 col-md-1-2">
            <div>
                <div class="banco-estado-login-benefits">${i18next.t("BE-LoginBenefits")}</div>
                <p class="banco-estado-login-benefits-banner">
                    <img src="/Images/BancoEstado/login-banner-2.png" />
                </p>
            </div>
        </div>
    `;

    const nonAgencyHeaderTemplate = () => html`
        <header class="be-login-page-header">${i18next.t("BE-LoginPageHeader")}</header>
    `;

    const bancoEstadoFormTemplate = () =>
        props.acceptedRut
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mt-[20px]">
                              <ac-input
                                  .isBancoEstado=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("password")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("password")}
                                  .type=${"password"}
                                  .label=${i18next.t("BE-EnterPasswordLabel")}
                                  .autoComplete=${"new-password"}
                                  .value=${props.vm.password}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, password: value })}
                              ></ac-input>
                          </div>
                      </div>
                  </div>
                  ${formErrors.htmlTemplate()}
                  <div class="row mt-[20px]">
                      <div class="col-xs-1">
                          <div class="be-login-page-pw-warning">
                              ${unsafeHTML(
                                  i18next.t("BE-LoginPagePasswordWarning {{-start}}{{-end}}", {
                                      start: "<span>",
                                      end: "</span>",
                                  }),
                              )}
                          </div>
                      </div>
                  </div>
              `
            : html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mt-[20px]">
                              <ac-input
                                  .isBancoEstado=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("username")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("username")}
                                  .label=${i18next.t("BE-EnterRutLabel")}
                                  .autoComplete=${"cc-exp"}
                                  .sanitizer=${(e: KeyboardEvent) =>
                                      sanitizeRutFieldValue(e.target as HTMLInputElement)}
                                  .value=${props.vm.username}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, username: value })}
                              ></ac-input>
                          </div>
                      </div>
                  </div>
                  ${formErrors.htmlTemplate()} ${captchaTemplate()}
              `;

    const captchaTemplate = () => html`
            ${
                appContext.isFeatureSwitchActive("Captcha")
                    ? html`
                          <div class="form-captcha-container ${captchaContainerClass} mt-[20px]">
                              <div></div>
                          </div>
                          <div>
                              ${showCaptchaError
                                  ? html`
                                        <div class="error-message-container elevated-error text-left">
                                            <div class="form-error-message">
                                                ${i18next.t("Debes validar la confirmación captcha para continuar")}
                                            </div>
                                        </div>
                                    `
                                  : ""}
                              ${showCaptchaLoadError
                                  ? html`
                                        <div class="row">
                                            <div class="col-xs-1">
                                                <div class="captcha-load-error">
                                                    ${i18next.t(
                                                        "Google ReCaptcha no se pudo cargar. Por favor, inténtelo de nuevo más tarde.",
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    `
                                  : ""}
                              ${showCaptchaBackendError
                                  ? html`
                                        <div class="col-xs-1">
                                            <div
                                                ref="beCaptchaError"
                                                class="error-message-container elevated-error text-left"
                                            >
                                                <div class="form-error-message">${i18next.t("CUG2-CaptchaError")}</div>
                                            </div>
                                        </div>
                                    `
                                  : ""}
                          </div>
                      `
                    : ""
            }
                </div>
        `;

    const forgotPasswordTemplate = () => html`
        ${props.acceptedRut
            ? html`
                  <a
                      href="/V2/ForgotPasswordBancoEstado"
                      class="mt-2 text-center text-lg text-be-orange sm:text-medium md:text-lg"
                  >
                      ${i18next.t("Header-ForgotPasswordButton")}
                  </a>
              `
            : ""}
    `;

    const submitButtonTemplate = () => html`
        ${props.acceptedRut
            ? html`
                  <button @click=${props.handleSubmit} class="rounded-primary-btn banco-estado-color">
                      ${i18next.t("BE-Enter")}
                  </button>
              `
            : html`
                  <button @click=${props.handleSubmit} class="rounded-primary-btn banco-estado-color">
                      ${i18next.t("V2-Continue")}
                  </button>
              `}
    `;

    const bancoEstadoFooterTemplate = () => html`
        ${props.acceptedRut ? html` <div class="be-login-page-warning">${i18next.t("BE-LoginPageWarning")}</div> ` : ""}

        <div class="login-footer-image-container">
            <img src="/Images/BancoEstado/be-logo-plus-name.svg" />
            <img src="/Images/Header/jetsmart-logo-colored.svg" />
        </div>
    `;

    const nonAgencyFormTemplate = () => html`
        <div class="col-xs-1 col-md-1-2">
            ${nonAgencyHeaderTemplate()} ${loginTypeSelectorTemplate()} ${bancoEstadoFormTemplate()}

            <div class="mt-[20px] flex w-full justify-center sm:justify-end">
                <div class="flex w-3/4 flex-col justify-around sm:w-[180px]">
                    ${submitButtonTemplate()}${forgotPasswordTemplate()}
                </div>
            </div>
            ${bancoEstadoFooterTemplate()}
        </div>
    `;

    return {
        captchaId,
        setShowCaptchaError,
        setShowCaptchaBackendError,
        htmlTemplate: html`
            <div id="mainContentWrapper" class="login-page">
                <section class="booking-wrapper ts-error-parent ts-error-container no-breadcrumb banco-estado">
                    <div class="row">${nonAgencyBannerTemplate()} ${nonAgencyFormTemplate()}</div>
                </section>
            </div>
        `,
    };
};

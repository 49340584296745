import { ApiMercadoPagoInstallmentOption } from "../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { SelectOption } from "../../shared/common";

export const gatewayGetDropdownTexts = (option: ApiMercadoPagoInstallmentOption): SelectOption[] =>
    option.PayerCosts.map((payerCost) => {
        let text = "1";

        if (payerCost.Installments > 1) {
            switch (payerCost.InstallmentRateCollector ? payerCost.InstallmentRateCollector[0] : "") {
                case "THIRD_PARTY":
                    text = payerCost.Installments.toString();
                    break;
                case "MERCADOPAGO":
                    text = `${payerCost.Installments} cuotas sin interés`;
                    break;
                default:
                    text = payerCost.RecommendedMessage;
                    break;
            }
        }

        return {
            Text: text,
            Value: payerCost.Installments.toString(),
        };
    });

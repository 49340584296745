import { TemplateResult, html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getGuid } from "../../../shared/common";
import { classMap } from "lit-html/directives/class-map";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { useEffect } from "haunted";

export interface Props {
    customWrapperClass?: string | string[];
    customInputClass?: string | string[];
    customLabelClass?: string | string[];
    inputTestId?: string;
    wrapperTestId?: string;
    isChecked: boolean;
    labelTestId?: string;
    labelText?: string;
    readonly?: boolean;
    disabled?: boolean;
    tabindex?: number;
    labelTemplate?: (id: string) => TemplateResult;
    onClick: () => void;
}

export function useBasicCheckbox(props: Props) {
    const id = useMemo(() => getGuid(), []);

    const getClasses = (defaultClass: string, customClasses: string | string[]) => {
        const classes = Array.isArray(customClasses) ? customClasses : [customClasses];

        return classes
            .filter((e) => e)
            .reduce(
                (previousValue, currentValue) => {
                    previousValue[currentValue] = true;
                    return previousValue;
                },
                { [defaultClass]: true } as any,
            );
    };

    const handleClick = (e: MouseEvent) => {
        if (props.disabled || props.readonly) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        props.onClick();
    };

    const update = () => {
        window.setTimeout(() => {
            const input = document.getElementById(id) as HTMLInputElement;
            if (input) {
                input.checked = props.isChecked;
            }
        }, 0);
    };

    useEffect(update, [props.isChecked]);

    // TEMPLATE

    const htmlTemplate = () => {
        const wrapperCustomClassMap = () => classMap(getClasses("basic-checkbox-wrapper", props.customWrapperClass));

        const inputCustomClassMap = () => classMap(getClasses("basic-checkbox-input", props.customInputClass));

        const labelCustomClassMap = () => classMap(getClasses("basic-checkbox-label", props.customLabelClass));

        return html`
            <div class=${wrapperCustomClassMap()} data-test-id=${props.wrapperTestId ?? ""} @click=${handleClick}>
                <input
                    class=${inputCustomClassMap()}
                    type="checkbox"
                    autocomplete="off"
                    id=${id}
                    tabindex=${props.tabindex ? props.tabindex : -1}
                    ?readonly=${props.readonly}
                    ?disabled=${props.disabled}
                    data-test-id=${props.inputTestId ? props.inputTestId : ""}
                    @click=${(e: MouseEvent) => e.preventDefault()}
                />
                ${props.labelTemplate
                    ? props.labelTemplate(id)
                    : html`
                          <label
                              class=${labelCustomClassMap()}
                              for=${id}
                              data-test-id=${props.labelTestId ? props.labelTestId : ""}
                              >${unsafeHTML(props.labelText ? props.labelText : "")}</label
                          >
                      `}
            </div>
        `;
    };

    return { htmlTemplate, update };
}

import dayjs, { Dayjs } from "dayjs";
import { DEFAULT_DATE_FORMAT } from "../../shared/commonConstants";
import { useTealiumManager } from "./useTealiumManager";
import { useAppContext } from "../useAppContext";
import { normalizeCulture } from "../../shared/localeHelper";
import { useBookingContext } from "../useBookingContext";

export const useChangeSearchTealiumManager = () => {
    const logAvailability = (minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs, disabledDates: dayjs.Dayjs[]) =>
        tealiumManager.log({
            UdoParams: {
                inbound_availability_days: get3MonthAvailability(minDate, maxDate, disabledDates),
            },
        });

    const logTimetableLoad = (
        minDate: dayjs.Dayjs,
        maxDate: dayjs.Dayjs,
        disabledDates: dayjs.Dayjs[],
        tealiumName: string,
    ) =>
        tealiumManager.log({
            UdoParams: {
                [tealiumName]: get3MonthAvailability(minDate, maxDate, disabledDates),
            },
        });

    const logSearchClicked = (data: {
        departureJourneyDate: Dayjs;
        destinationIata: string;
        market: string;
        numberOfAdults: number;
        numberOfChildren: number;
        numberOfInfants: number;
        originIata: string;
        promoCode: string;
        returnJourneyDate?: Dayjs;
    }) =>
        tealiumManager.log({
            EventName: "search_clicked",
            EventParams: {
                culture: normalizeCulture(appContext.Culture),
                departure_journey_date: data.departureJourneyDate.format(DEFAULT_DATE_FORMAT),
                destination_iata: data.destinationIata,
                device: tealiumManager.getTealiumDeviceType(),
                market: data.market,
                origin_iata: data.originIata,
                pax_adult_count: bookingContext.adultsCount.toString(),
                pax_children_count: bookingContext.childrenCount.toString(),
                pax_infant_count: bookingContext.infantsCount.toString(),
                promocode: data.promoCode || bookingContext.promoCode || "n/a",
                return_journey_date: data.returnJourneyDate?.format(DEFAULT_DATE_FORMAT) || "n/a",
                trip_type: data.returnJourneyDate ? "R" : "O",
            },
        });

    const get3MonthAvailability = (minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs, disabledDates: dayjs.Dayjs[]) => {
        let date = dayjs(minDate);
        const limit3Months = dayjs().add(3, "months");
        const availableDates: string[] = [];

        while (date.isSameOrBefore(maxDate) && date.isSameOrBefore(limit3Months)) {
            if (!disabledDates.some((dd) => dd.format(DEFAULT_DATE_FORMAT) === date.format(DEFAULT_DATE_FORMAT))) {
                availableDates.push(date.format(DEFAULT_DATE_FORMAT));
            }
            date = date.add(1, "day");
        }

        return availableDates;
    };

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const tealiumManager = useTealiumManager();

    return {
        logAvailability,
        logSearchClicked,
        logTimetableLoad,
    };
};

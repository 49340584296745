import { CustomizedBundle, CustomizedBundleOffers } from "./../../component-models/flight/BundleOffersV2Model";
import { SelectedFlightFeeType } from "../../components/flight-select/flight-page";
import BookingData from "../../shared/BookingData";
import { useBookingDataManager } from "../useBookingDataManager";
import { useTealiumManager } from "./useTealiumManager";
import { useAppContext } from "../useAppContext";
import { FlightPageFlightState } from "../../component-models/flight/contexts/FlightPageFlightState";
import dayjs from "dayjs";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { FlightPageBundleState } from "../../component-models/flight/contexts/FlightPageBundleState";
import {
    AIRPORT_CHECKIN_CODES,
    CABIN_BAG_CODES,
    CHECKED_BAG_CODES,
    FLEXI_FEE_CODES,
    PRIORITY_BOARDING_CODES,
    SEAT_CODES,
    SEAT_FEE_CODES,
} from "../../shared/commonConstants";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { BundleType } from "../../component-models/flight/BundleOffersV2Model";
import { normalizeCulture } from "../../shared/localeHelper";
import { useBookingContext } from "../useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const useFlightTealiumManager = () => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const bookingDataManager = useBookingDataManager();
    const tealiumManager = useTealiumManager();

    const [total] = useReduxState("booking.total");
    const [currency] = useReduxState("booking.currency");

    const logDcMembershipTypeSelected = (type: "A" | "B", price: string) =>
        tealiumManager.log({
            EventName: "dc_membership_type_clicked",
            EventParams: {
                membership_type: type,
                currency,
                price,
            },
        });

    const logDcMembershipContinueClicked = (type: "A" | "B", price: string) =>
        tealiumManager.log({
            EventName: "dc_membership_creation_continue_clicked",
            EventParams: {
                membership_type: type,
                currency,
                price,
            },
        });

    const getFlightPrice = (
        selectedFlightFeeType: SelectedFlightFeeType,
        flightOption: FlightOptionModel,
        bundle: CustomizedBundleOffers,
    ) =>
        selectedFlightFeeType === "Smart"
            ? bundle.NormalOffers?.find((offer) => offer.BundleType === "None")?.UnFormattedPrice ||
              flightOption.UnformattedSmartPrice
            : bundle.DCOffers?.find((offer) => offer.BundleType === "None")?.UnFormattedPrice ||
              flightOption.UnformattedClubPrice;

    const logOutboundFlightSelected = (
        bundleState: FlightPageBundleState,
        fareType: "N" | "D",
        flightOption: FlightOptionModel,
        flightState: FlightPageFlightState,
    ) => {
        const bundle = bundleState.bundleOffers.find((bundle) => bundle.SellKey === flightOption.SellKey);
        const price = getFlightPrice(flightState.selectedFeeType, flightOption, bundle);
        const usdPrice =
            flightState.selectedFeeType === "Smart"
                ? flightOption.UnformattedSmartPriceInUsd
                : flightOption.UnformattedClubPriceInUsd;
        const flight_number = flightOption.FlightSelectModel.FlightNumber;

        const eventParams = {
            currency,
            departure_date: dayjs(flightOption.DepartureDate).format("YYYY-MM-DD"),
            destination_iata: flightOption.FlightSelectModel.DestinationIata,
            fare_class: flightOption.FlightSelectModel.FareClass,
            fare_price_usd: usdPrice,
            fare_price: price,
            fare_type: fareType,
            flight_number,
            is_connected: flightOption.FlightSelectModel.IsConnected ? "1" : "0",
            operating_carrier: flightOption.FlightSelectModel.OperatingCarrier,
            origin_iata: flightOption.FlightSelectModel.OriginIata,
        };

        tealiumManager.log({
            EventName: "outbound_flight_selected",
            EventParams: eventParams,
            UdoParams: {
                outbound_departure_iata: flightOption.FlightSelectModel.OriginIata,
                outbound_arrival_iata: flightOption.FlightSelectModel.DestinationIata,
                outbound_fare_amount: price,
                outbound_fare_currency_code: currency,
            },
        });
    };

    const logInboundFlightSelected = (
        bundleState: FlightPageBundleState,
        fareType: "N" | "D",
        flightOption: FlightOptionModel,
        selectedFlightFeeType: SelectedFlightFeeType,
    ) => {
        const bundle = bundleState.bundleOffers.find((bundle) => bundle.SellKey === flightOption.SellKey);
        const price = getFlightPrice(selectedFlightFeeType, flightOption, bundle);
        const usdPrice =
            selectedFlightFeeType === "Smart"
                ? flightOption.UnformattedSmartPriceInUsd
                : flightOption.UnformattedClubPriceInUsd;
        const flight_number = flightOption.FlightSelectModel.FlightNumber;

        const eventParams = {
            currency,
            departure_date: dayjs(flightOption.DepartureDate).format("YYYY-MM-DD"),
            destination_iata: flightOption.FlightSelectModel.DestinationIata,
            fare_class: flightOption.FlightSelectModel.FareClass,
            fare_price_usd: usdPrice,
            fare_price: price,
            fare_type: fareType,
            flight_number,
            is_connected: flightOption.FlightSelectModel.IsConnected ? "1" : "0",
            operating_carrier: flightOption.FlightSelectModel.OperatingCarrier,
            origin_iata: flightOption.FlightSelectModel.OriginIata,
        };

        tealiumManager.log({
            EventName: "inbound_flight_selected",
            EventParams: eventParams,
            UdoParams: {
                inbound_departure_iata: flightOption.FlightSelectModel.OriginIata,
                inbound_arrival_iata: flightOption.FlightSelectModel.DestinationIata,
                inbound_fare_amount:
                    selectedFlightFeeType === "Smart"
                        ? flightOption.UnformattedSmartPrice
                        : flightOption.UnformattedClubPrice,
                inbound_fare_currency_code: currency,
            },
        });
    };

    const logFlightPageLoaded = () => {
        window.setTimeout(() => tealiumManager.logDomLoaded("flight", { UpdateRealUdo: true }), 1000);
    };

    const updateTealiumParameters = (flightState: FlightPageFlightState) =>
        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            tealiumManager.log({
                UdoParams: getBookingRelatedUdoParams(flightState, bookingData),
            });
        });

    const getOutboundFare = (flightState: FlightPageFlightState) => {
        if (!flightState.selectedOutboundFlight) return 0;

        return flightState.selectedFeeType === "Club"
            ? flightState.selectedOutboundFlight.UnformattedClubPrice
            : flightState.selectedOutboundFlight.UnformattedSmartPrice;
    };

    const getInboundFare = (flightState: FlightPageFlightState) => {
        if (!flightState.selectedInboundFlight) return 0;

        return flightState.selectedFeeType === "Club"
            ? flightState.selectedInboundFlight.UnformattedClubPrice
            : flightState.selectedInboundFlight.UnformattedSmartPrice;
    };

    const getBookingRelatedUdoParams = (flightState: FlightPageFlightState, bookingData: BookingData) => ({
        breakdown_fare_amount: getOutboundFare(flightState) + getInboundFare(flightState),
        breakdown_ancillaries_amount:
            bookingData && bookingData.TotalAncillaryLocal ? bookingData.TotalAncillaryLocal.toString() : "0",
        breakdown_currency: bookingData && bookingData.TotalPriceCurrencyLocal,
        breakdown_tax_amount: bookingData && bookingData.TotalTaxLocal ? bookingData.TotalTaxLocal.toString() : "0",
        breakdown_total_amount:
            bookingData && bookingData.TotalPriceLocal ? bookingData.TotalPriceLocal.toString() : "0",
        promocode: bookingData && bookingData.PromotionCode ? bookingData.PromotionCode : "n/a",
    });

    const getBundleCount = (context: FlightPageContext, bookingData: BookingData, bundleType: BundleType) => {
        const bundlesOfType = [
            context.bundleState.selectedOutboundBundle,
            context.bundleState.selectedInboundBundle,
        ].filter((bundle) => bundle && bundle.BundleType === bundleType);

        return bundlesOfType.length * paxCount(bookingData);
    };

    const bundleContainsSsr = (bundle: CustomizedBundle, chargeCodes: string[]) =>
        bundle && chargeCodes.some((chargeCode) => bundle.BundleSSRs.includes(chargeCode));

    const isSsrInBooking = (context: FlightPageContext, chargeCodes: string[]) => {
        const bundlesWithSsrs = [
            context.bundleState.selectedOutboundBundle,
            context.bundleState.selectedInboundBundle,
        ].some((bundle) => bundleContainsSsr(bundle, chargeCodes));

        return bundlesWithSsrs ? "1" : "0";
    };

    const paxCount = (bookingData: BookingData) =>
        (bookingData.PassengersAdultCount || 0) + (bookingData.PassengersChildCount || 0);

    const getAncillaryAmountByChargeCodes = (
        context: FlightPageContext,
        bookingData: BookingData,
        chargeCodes: string[],
    ) => {
        const outboundBundleHasSsr = bundleContainsSsr(context.bundleState.selectedOutboundBundle, chargeCodes);
        const inboundBundleHasSsr =
            context.bundleState.selectedInboundBundle &&
            bundleContainsSsr(context.bundleState.selectedInboundBundle, chargeCodes);
        const outboundSegments = context.flightState.selectedOutboundFlight.FlightSelectModel.Segments.length;
        const inboundSegments = context.flightState.selectedInboundFlight?.FlightSelectModel.Segments.length || 0;

        const allSegments = (outboundBundleHasSsr ? outboundSegments : 0) + (inboundBundleHasSsr ? inboundSegments : 0);

        return allSegments * paxCount(bookingData);
    };

    const getTotalFare = (context: FlightPageContext, bookingData: BookingData, inUsd: boolean) => {
        const outboundFlightNumber = context.flightState.selectedOutboundFlight.FlightSelectModel.FlightNumber;
        const inboundFlightNumber = !bookingContext.isOneWay
            ? context.flightState.selectedInboundFlight.FlightSelectModel.FlightNumber
            : "";

        const selectedOutboundFlightInBookingData = bookingData.AvailableOutboundJourneys.find((journey) =>
            journey.FlightNumber.includes(outboundFlightNumber),
        );

        const selectedInboundFlightInBookingData = inboundFlightNumber
            ? bookingData.AvailableReturnJourneys.find((journey) => journey.FlightNumber.includes(inboundFlightNumber))
            : undefined;

        if (inUsd) {
            return (
                (selectedOutboundFlightInBookingData.FareAmountUSD +
                    (selectedInboundFlightInBookingData?.FareAmountUSD || 0)) *
                paxCount(bookingData)
            );
        }

        return (
            (selectedOutboundFlightInBookingData.FareAmount + (selectedInboundFlightInBookingData?.FareAmount || 0)) *
            paxCount(bookingData)
        );
    };

    const logContinueClicked = async (context: FlightPageContext) => {
        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            if (!bookingData) return;

            const eventParams = {
                airportcheckin: isSsrInBooking(context, AIRPORT_CHECKIN_CODES),
                bundle_full_count: getBundleCount(context, bookingData, "Full").toString(),
                bundle_smart_count: getBundleCount(context, bookingData, "Simple").toString(),
                cabin_bag_count: getAncillaryAmountByChargeCodes(context, bookingData, CABIN_BAG_CODES).toString(),
                checked_bag_count: getAncillaryAmountByChargeCodes(context, bookingData, CHECKED_BAG_CODES).toString(),
                culture: normalizeCulture(appContext.Culture),
                currency,
                departure_journey_date: context.journeys[0].FlightDetails[0].DepartureDate.substring(0, 10),
                destination_iata: context.journeys[0].FlightDetails[0].DestinationIata,
                device: tealiumManager.getTealiumDeviceType(),
                flexismart: isSsrInBooking(context, FLEXI_FEE_CODES),
                insurance: "0",
                market: bookingData.RouteMarket,
                origin_iata: context.journeys[0].FlightDetails[0].OriginIata,
                oversize_bag_count: "0",
                pax_adult_count: bookingContext.adultsCount.toString(),
                pax_children_count: bookingContext.childrenCount.toString(),
                pax_infant_count: bookingContext.infantsCount.toString(),
                pet_on_board: "0",
                priority_boarding: isSsrInBooking(context, PRIORITY_BOARDING_CODES),
                q_pax: paxCount(bookingData).toString(),
                quoted_total: total || "0",
                return_journey_date: context.journeys[1]?.FlightDetails[0].DepartureDate
                    ? context.journeys[1].FlightDetails[0].DepartureDate.substring(0, 10)
                    : "n/a",
                seats_count: getAncillaryAmountByChargeCodes(context, bookingData, [
                    ...SEAT_CODES,
                    ...SEAT_FEE_CODES,
                ]).toString(),
                total_ancillary_usd: "0",
                total_ancillary: "0",
                total_fare_usd: getTotalFare(context, bookingData, true).toString(),
                total_fare: getTotalFare(context, bookingData, false).toString(),
                trip_type: bookingContext.isOneWay ? "O" : "R",
            };

            tealiumManager.log({
                EventName: "continue_clicked",
                EventParams: eventParams,
                UpdateCommonUdo: true,
                UpdateRealUdo: true,
            });
        });
    };

    return {
        logContinueClicked,
        logDcMembershipContinueClicked,
        logDcMembershipTypeSelected,
        logFlightPageLoaded,
        logInboundFlightSelected,
        logOutboundFlightSelected,
        updateTealiumParameters,
    };
};

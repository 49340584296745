import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { ref } from "../../directives/ref";

export interface FlightContinueButtonProps {
    dataTestId: string;
    onClick: () => void;
}

export const useFlightContinueButton = (props: FlightContinueButtonProps) => {
    const submitButton = useRef<HTMLButtonElement>(undefined);

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick();
    };

    const htmlTemplate = () => html`
        <div class="relative mx-auto mt-10 flex w-[90%] max-w-[95%] justify-end text-center sm:w-[95%] md:w-[1005px]">
            <button
                ref=${ref(submitButton)}
                class="rounded-primary-btn booking"
                data-test-id=${props.dataTestId}
                @click=${handleClick}
            >
                ${i18next.t("V2-Continue")}
            </button>
        </div>
    `;

    return { buttonElem: submitButton.current, htmlTemplate };
};

import i18next from "i18next";
import { html } from "haunted";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../../managers/useAppContext";
import { useModal } from "../../../shared/useModal";
import useImagePreloader from "../../../../shared/customHooks/useImagePreloader/useImagePreloader";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { SearchInsuranceResult } from "../../../../component-models/spa/SearchInsuranceResult";

export interface Props {
    insurance: SearchInsuranceResult;
    onContinue: () => void;
    onCancel: () => void;
}

const modalHeaderImageMap = new Map([
    [
        "en",
        {
            desktop:
                "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/1153d464-9304-47a2-a747-8bda9a9bb52d/Seguros_CHUB_EN_Desktop.png",
            mobile: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/1c2522a2-d63c-4198-a169-e2b401c28dee/Seguros_CHUB_EN_Mobile.png",
            tablet: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/1153d464-9304-47a2-a747-8bda9a9bb52d/Seguros_CHUB_EN_Desktop.png",
        },
    ],
    [
        "es",
        {
            desktop:
                "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/59ec4894-8397-4d81-b929-d0930d3cc23d/Seguros_v3.png",
            mobile: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/98ed6144-3f40-4421-9a7f-026da77ce40d/Seguros_v1.png",
            tablet: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/59ec4894-8397-4d81-b929-d0930d3cc23d/Seguros_v3.png",
        },
    ],
    [
        "pt",
        {
            desktop:
                "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/feb5a478-1dd8-4a86-af4d-fc13c7d4d489/Seguros_CHUB_PT_Desktop.png",
            mobile: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/c910c550-4445-4314-8107-e7880b0fdbd7/Seguros_CHUBB_PT_mobile.png",
            tablet: "https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/feb5a478-1dd8-4a86-af4d-fc13c7d4d489/Seguros_CHUB_PT_Desktop.png",
        },
    ],
]);

export const useInsuranceModal = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const cultureSpecificImageSet = useMemo(
        () => modalHeaderImageMap.get(appContext.Culture.substring(0, 2).toLowerCase()),
        [appContext.Culture],
    );

    useImagePreloader(Object.values(cultureSpecificImageSet));

    const handleContinue = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        modal.hide();
        props.onContinue();
    };

    const baggageInsuranceListTemplate = () =>
        bookingContext.isBaggageInsuranceAvailable &&
        props.insurance?.BaggageCost &&
        props.insurance?.BaggageCostCurrency
            ? html`
                  ${i18next.t("Seguro de equipaje")}:
                  <ul>
                      <li>
                          ${i18next.t(
                              "En caso de pérdida recibe $0000 si tu equipaje no ha sido devuelto en 96 horas.",
                          )}
                      </li>
                      <li>${i18next.t("Hasta $0.000 por retraso o daño de tu equipaje. ")}</li>
                  </ul>
              `
            : html``;

    const headerTemplate = () => html`
        <img class="visible-xs" src=${cultureSpecificImageSet?.mobile} />
        <img class="visible-sm" src=${cultureSpecificImageSet?.tablet} />
        <img class="visible-md-up" src=${cultureSpecificImageSet?.desktop} />
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="eic-ribbon">
                <i class="js-icon-covid js-cv-covid-shield-plane ribbon-icon"></i>
                <div class="eic-ribbon-text">
                    <div class="eic-ribbon-title">${i18next.t("¡Disfruta en tu destino sin preocupaciones!")}</div>
                    ${i18next.t("V2-ExtrasInsuranceRibbon")}:
                </div>
            </div>
            <div class="insurance-modal-list-continer">
                ${i18next.t("Seguro de viaje")}:
                <ul>
                    <li>${i18next.t("Hasta $5.000 USD en gastos médicos")}</li>
                    <li>${i18next.t("Asistencia en español las 24 horas, los 365 días del año.")}</li>
                    <li>${i18next.t("Cobertura en gastos médicos por accidente o enfermedad.")}</li>
                    <li>${i18next.t("Cancelación de viaje")}</li>
                </ul>
                ${baggageInsuranceListTemplate()}
            </div>
            <button
                class="rounded-primary-btn"
                @click=${handleContinue}
                data-test-id=${T.COMMON.INSURANCE_MODAL_CONFIRM_BUTTON}
            >
                ${i18next.t("¡Lo quiero!")}
            </button>
        </div>
    `;

    const modal = useModal({
        closing: { buttonTestId: T.COMMON.INSURANCE_MODAL_CLOSE_BUTTON, isClosable: true, onClose: props.onCancel },
        content: { template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "extras-insurance-modal", testId: T.COMMON.INSURANCE_MODAL },
    });

    return modal;
};

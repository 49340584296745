import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { html, useCallback, useRef } from "haunted";
import { useClickOutside } from "../../../shared/haunted/useClickOutside";
import { ref } from "../../../directives/ref";
import { useCompactDatePickerList } from "./useCompactDatePickerList";
import { padWithLeadingZeros, titleCase } from "../../../shared/common";
import { CLASS_NAMES } from "../../../shared/classNames";
import { TemplateResult } from "lit-html";
import { TestIdDictionary as T, getTestId } from "../../../testing-helpers/TestIdHelper";
import { DatepickerType, DEFAULT_DATE_FORMAT } from "../../../shared/commonConstants";
import i18next from "i18next";

export interface Props {
    customClass?: string;
    disabled?: boolean;
    firstYearIsDefault?: boolean;
    index?: number;
    isInvalid?: boolean;
    isRequired?: boolean;
    label: string | TemplateResult;
    maxDate?: Dayjs;
    minDate?: Dayjs;
    opensToModal?: boolean;
    type?: DatepickerType;
    value?: Dayjs;
    onChange: (value: Dayjs) => void;
}

export const useCompactDatepicker = (props: Props) => {
    const handleClose = useCallback(() => setIsOpen(false), []);

    const root = useClickOutside<HTMLDivElement>(handleClose);

    const yearElem = useRef<HTMLDivElement>(null);
    const monthElem = useRef<HTMLDivElement>(null);
    const dayElem = useRef<HTMLDivElement>(null);
    const inputField = useRef<HTMLInputElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [year, setYear] = useState<number | undefined>(props.value ? dayjs(props.value).year() : undefined);
    const [month, setMonth] = useState<number | undefined>(props.value ? dayjs(props.value).month() : undefined);
    const [day, setDay] = useState<number | undefined>(props.value ? dayjs(props.value).date() : undefined);
    const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);

    const displayedYears = useMemo(() => {
        const years: number[] = [];
        const lastYear = (props.maxDate || dayjs().add(100, "year")).year();
        let year = (props.minDate || dayjs().subtract(100, "year")).year();

        while (year <= lastYear) {
            years.push(year);
            year++;
        }

        return years;
    }, [props.minDate, props.maxDate]);

    const yearTemplate = useMemo(
        () =>
            html`${displayedYears.map((inputYear) => {
                const dataTestId =
                    props?.index >= 0 && props.type
                        ? props.type === "date-of-birth"
                            ? getTestId(T.PASSENGERS.DOB_YEAR, { i: props.index, c: inputYear.toString() })
                            : getTestId(T.PASSENGERS.DOC_EXP_YEAR, { i: props.index, c: inputYear.toString() })
                        : "";

                return html`
                    <ac-compact-datepicker-time-unit
                        .dataTestId=${dataTestId}
                        .isInvalid=${validationMessage}
                        .isSelected=${year === inputYear}
                        .template=${inputYear.toString()}
                        .onClick=${() => handleYearClick(inputYear)}
                    >
                    </ac-compact-datepicker-time-unit>
                `;
            })}`,
        [displayedYears, year, validationMessage],
    );

    const yearListDataTestId = () =>
        props?.index >= 0 && props.type
            ? props.type === "date-of-birth"
                ? getTestId(T.PASSENGERS.DOB_YEAR_LIST, { i: props.index })
                : getTestId(T.PASSENGERS.DOC_EXP_YEAR_LIST, { i: props.index })
            : "";

    const yearList = useCompactDatePickerList({
        dataTestId: yearListDataTestId(),
        innerRef: yearElem,
        isPadded: Boolean(year),
        isVisible: isOpen,
        length: displayedYears.length,
        template: yearTemplate,
    });

    const monthTemplate = useMemo(
        () =>
            html`${[...Array(12)].map((_, index) => {
                const dataTestId =
                    props?.index >= 0 && props.type
                        ? props.type === "date-of-birth"
                            ? getTestId(T.PASSENGERS.DOB_MONTH, { i: props.index, c: index.toString() })
                            : getTestId(T.PASSENGERS.DOC_EXP_MONTH, { i: props.index, c: index.toString() })
                        : "";

                return html`
                    <ac-compact-datepicker-time-unit
                        .data-test-id=${dataTestId}
                        .isInvalid=${validationMessage}
                        .isSelected=${index === month}
                        .template=${titleCase(dayjs().month(index).format("MMMM"))}
                        .onClick=${() => handleMonthClick(index)}
                    >
                    </ac-compact-datepicker-time-unit>
                `;
            })}`,
        [year, month, props.minDate, props.maxDate, validationMessage],
    );

    const monthListDataTestId = () =>
        props?.index >= 0 && props.type
            ? props.type === "date-of-birth"
                ? getTestId(T.PASSENGERS.DOB_MONTH_LIST, { i: props.index })
                : getTestId(T.PASSENGERS.DOC_EXP_MONTH_LIST, { i: props.index })
            : "";

    const monthList = useCompactDatePickerList({
        dataTestId: monthListDataTestId(),
        innerRef: monthElem,
        isPadded: month !== undefined,
        isVisible: isOpen,
        length: 12,
        template: monthTemplate,
    });

    const dayTemplate = useMemo(
        () => html`
            ${[...Array(31)].map((_, index) => {
                const dataTestId =
                    props?.index >= 0 && props.type
                        ? props.type === "date-of-birth"
                            ? getTestId(T.PASSENGERS.DOB_DAY, { i: props.index, c: (index + 1).toString() })
                            : getTestId(T.PASSENGERS.DOC_EXP_DAY, { i: props.index, c: (index + 1).toString() })
                        : "";

                return html`
                    <ac-compact-datepicker-time-unit
                        .dataTestId=${dataTestId}
                        .isInvalid=${validationMessage}
                        .isSelected=${day === index + 1}
                        .template=${(index + 1).toString()}
                        .onClick=${() => handleDayClick(index + 1)}
                    >
                    </ac-compact-datepicker-time-unit>
                `;
            })}
        `,
        [year, month, day, props.maxDate, props.minDate, validationMessage],
    );

    const dayListDataTestId = () =>
        props?.index >= 0 && props.type
            ? props.type === "date-of-birth"
                ? getTestId(T.PASSENGERS.DOB_DAY_LIST, { i: props.index })
                : getTestId(T.PASSENGERS.DOC_EXP_DAY_LIST, { i: props.index })
            : "";

    const dayList = useCompactDatePickerList({
        dataTestId: dayListDataTestId(),
        innerRef: dayElem,
        isPadded: Boolean(day),
        isVisible: isOpen,
        length: 31,
        template: dayTemplate,
    });

    const calculateScrollPosition = (container: HTMLElement, selectedElem: HTMLElement) =>
        selectedElem.offsetTop + selectedElem.offsetHeight / 2 - container.parentElement!.offsetHeight / 2;

    const scrollToValue = (container: HTMLDivElement, scrollToLastIfNoValue?: boolean) => {
        window.setTimeout(() => {
            if (!container) return;

            const selectedElem = container.querySelector(`.${CLASS_NAMES.selected}`) as HTMLLIElement;

            const allElems = Array.from(container.querySelectorAll("li:not(.pointer-events-none)")) as HTMLLIElement[];

            if (!selectedElem && allElems.length === 0) return;

            const firstElem = allElems[0];
            const lastElem = allElems[allElems.length - 1];

            const top = selectedElem
                ? calculateScrollPosition(container, selectedElem)
                : scrollToLastIfNoValue
                  ? calculateScrollPosition(container, lastElem)
                  : calculateScrollPosition(container, firstElem);

            container.scrollTo({
                behavior: scrollToLastIfNoValue ? "auto" : "smooth",
                left: 0,
                top,
            });
        }, 100);
    };

    const toggleDesktopOptions = (e: MouseEvent) => {
        if (isOpen) (e.target as HTMLInputElement).blur();
        setIsOpen(!isOpen);
    };

    const handleMonthClick = (month: number) => {
        setMonth(month);
    };

    const handleYearClick = (year: number) => {
        setYear(year);
    };

    const handleDayClick = (day: number) => {
        setDay(day);
    };

    const handleDateSelection = () => {
        setValidationMessage(undefined);

        if (!year || month === undefined || !day) return;

        const dateAsString = `${year}-${padWithLeadingZeros((month + 1).toString())}-${padWithLeadingZeros(day.toString())}`;

        if (!dayjs(dateAsString, DEFAULT_DATE_FORMAT, true).isValid()) {
            setValidationMessage(i18next.t("Date is invalid."));
            return;
        }

        const newDate = dayjs().year(year).month(month).date(day);

        if (props.minDate && newDate.isBefore(props.minDate, "day")) {
            setValidationMessage(
                i18next.t("The minimum date is {{date}}.", { date: titleCase(props.minDate.format("D MMMM YYYY")) }),
            );
            return;
        }

        if (props.maxDate && newDate.isAfter(props.maxDate, "day")) {
            setValidationMessage(
                i18next.t("The maximum date is {{date}}.", { date: titleCase(props.maxDate.format("D MMMM YYYY")) }),
            );
            return;
        }

        props.onChange(newDate);
        setIsOpen(false);
    };

    useEffect(handleDateSelection, [year, month, day]);

    useEffect(() => {
        if (props.value) {
            setYear(dayjs(props.value).year());
            setMonth(dayjs(props.value).month());
            setDay(dayjs(props.value).date());
        } else {
            setYear(undefined);
            setMonth(undefined);
            setDay(undefined);
        }
    }, [isOpen, props.value]);

    useEffect(() => {
        scrollToValue(yearElem.current, !props.firstYearIsDefault);
        scrollToValue(monthElem.current);
        scrollToValue(dayElem.current);
    }, [isOpen, year, month, day]);

    const errorMessageTemplate = () =>
        validationMessage
            ? html`
                  <div
                      class="absolute bottom-0 left-0 flex h-6 w-full items-center bg-be-red px-2 text-xs text-white sm:text-sm"
                  >
                      ${validationMessage}
                  </div>
              `
            : "";

    const contentTemplate = () => html`
        <div class="flex items-stretch justify-between bg-white">
            <div class="basis-3/12">${dayList.htmlTemplate()}</div>
            <div class="basis-5/12">${monthList.htmlTemplate()}</div>
            <div class="basis-4/12">${yearList.htmlTemplate()}</div>
        </div>
        ${errorMessageTemplate()}
    `;

    const datepickerDataTestId = () =>
        props?.index >= 0 && props.type
            ? props.type === "date-of-birth"
                ? getTestId(T.PASSENGERS.DOB_SELECTOR, { i: props.index })
                : getTestId(T.PASSENGERS.DOC_EXP_SELECTOR, { i: props.index })
            : "";

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class=${classNames("relative select-none", props.customClass)} data-test-id=${datepickerDataTestId()}>
            <div
                class=${classNames("mdl-textfield  mdl-js-textfield mdl-textfield--floating-label cursor-pointer", {
                    "is-dirty is-upgraded": props.value || isOpen,
                })}
            >
                <label class="mdl-textfield__label cursor-pointer">${props.label}</label>
                <input
                    ref=${ref(inputField)}
                    class=${classNames("mdl-textfield__input js-input cursor-pointer ", {
                        [CLASS_NAMES.stickyInvalid]: props.isInvalid,
                        [CLASS_NAMES.disabled]: props.disabled,
                    })}
                    readonly
                    value=${titleCase(props.value?.format("D MMMM YYYY")) || ""}
                    ?disabled=${props.disabled}
                    ?data-required=${props.isRequired}
                    @focus=${toggleDesktopOptions}
                />

                <ac-dropdown
                    .isOpen=${isOpen}
                    .maxWidth=${320}
                    .opensToModal=${props.opensToModal}
                    .template=${contentTemplate()}
                    .title=${props.label}
                    .onClose=${handleClose}
                ></ac-dropdown>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import { InputType, NUMERIC_REGEX, RegExpForInputTypes } from "../shared/commonConstants";

export const sanitizeInputFieldValue = (e: KeyboardEvent, inputType: InputType) => {
    const target = e.target as HTMLInputElement;
    let value = Array.from(target.value)
        .filter((char) => RegExpForInputTypes.get(inputType).test(char))
        .join("")
        .replace(/[\t\n\r]/g, " ");

    // DEVNOTE JET-7635 Only disallow space on blur, otherwise space cannot be typed ever.
    if (e.type === "blur") {
        value = value.trim();
    }

    target.value = value;

    return value;
};

export const sanitizeChileanPhoneInput = (e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    let value = Array.from(target.value)
        .filter((char) => NUMERIC_REGEX.test(char))
        .join("")
        .replace(/[\t\n\r]/g, " ");

    if (value.length > 9) {
        value = value.substring(0, 9);
    }

    target.value = value;

    return value;
};

export const sanitizeValue = (value: string, inputType: InputType) => {
    return Array.from(value)
        .filter((char) => RegExpForInputTypes.get(inputType).test(char))
        .join("");
};

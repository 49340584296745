import i18next from "i18next";
import { html } from "lit-html";
import { paxAmount, displayedBundleJourneyIndex } from "../../../../component-helpers/BaggageHelper";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useMemo } from "../../../../shared/haunted/CustomHooks";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    isMobile: boolean;
}

export const usePerJourneyPerPaxBundle = (props: Props) => {
    const isOnePax = useMemo(() => paxAmount(props.baggageSection.state), [props.baggageSection.state]);

    const bundleText = useMemo(
        () =>
            props.baggageSection.bagType === "CabinBaggage"
                ? isOnePax
                    ? i18next.t("Recuerda que tu llevas equipaje de mano porque compraste un")
                    : i18next.t("Recuerda que llevan equipaje de mano porque compraron un")
                : props.baggageSection.bagType === "CheckedBaggage"
                  ? isOnePax
                      ? i18next.t("Recuerda que tu llevas equipaje facturado porque compraste un")
                      : i18next.t("Recuerda que llevan equipaje facturado porque compraron un")
                  : "",
        [props.baggageSection.bagType, isOnePax],
    );
    const htmlTemplate = (journeyIndex: number) => {
        if (props.baggageSection.bagType === "OversizedBaggage") return "";

        const journey = props.context.journeys.find((journey) => journey.index === journeyIndex);

        const oneWayBundleDirection = displayedBundleJourneyIndex(props.context.journeys, props.baggageSection.bagType);
        const oneWayBundleText =
            journey.index === OUTBOUND ? i18next.t("para el vuelo de ida") : i18next.t("para el vuelo de vuelta");

        const imgSrc = journey.bundleImageUrl;

        return (props.baggageSection.bagType === "CabinBaggage" && journey.cabinBundleType !== "None") ||
            (props.baggageSection.bagType === "CheckedBaggage" && journey.checkedBundleType !== "None")
            ? html`
                  <div
                      class="b2-baggage-bundle-per-pax-info"
                      data-test-value=${props.baggageSection.bagType === "CabinBaggage"
                          ? journey.cabinBundleType
                          : journey.checkedBundleType}
                      data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_BUNDLE_MESSAGE, {
                          c: props.baggageSection.bagType,
                          j: journey.index,
                          m: props.isMobile,
                      })}
                  >
                      <i class="js-icon js-broken-circle-exclamation"></i>
                      <div>
                          <span>${bundleText}</span>
                          <img src=${imgSrc} />
                          ${oneWayBundleDirection !== undefined ? html` <span>${oneWayBundleText}</span> ` : ""}
                      </div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};

import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { URL_VARS } from "../../../shared/commonConstants";
import i18next from "i18next";
import { html } from "lit-html";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { ROUTES } from "../../../shared/apiRoutes";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { getAntiForgeryTokenFromHtml, handleCugLoader } from "../../../shared/common";
import { ref } from "../../../directives/ref";
import { useRef } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const name = "ac-travel-groups-page";
export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];

export interface Attributes {
    "anti-forgery-token": string;
}
export interface Props {
    antiForgeryToken: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const result = await getTravelPartnerInfo();
        setTravelPartnerInfo(result);
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [newGroupName, setNewGroupName] = useState<string>("");
    const [isValidated, setIsValidated] = useState<boolean>(undefined);

    useEffect(init, []);

    // EVENT LISTENERS

    const handleCreateGroup = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        if (!newGroupName || !isNameUnique()) {
            return;
        }

        const newTravelPartnerInfo = TravelPartnerHelper.updateGroup(travelPartnerInfo, {
            Id: undefined,
            Name: newGroupName,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        const searchExpression = `${URL_VARS.FFLYER_GROUP_NAME}=${encodeURIComponent(newGroupName)}`;
        window.location.href = `${ROUTES.Cug2BAddGroupPage}?${searchExpression}`;
    };

    const isNameUnique = () =>
        !travelPartnerInfo.Groups.some((g) => g.Name.toLowerCase() === newGroupName.toLowerCase());

    const showNonUniqueError = () => isValidated && newGroupName && !isNameUnique();

    const showRequiredError = () => isValidated && !newGroupName;

    const handleGroupNameInput = (e: KeyboardEvent) => {
        const sanitizedName = sanitizeInputFieldValue(e, "alphanumeric");
        setNewGroupName(sanitizedName);
    };

    // TEMPLATES

    const mobileNonUniqueErrorTemplate = () =>
        showNonUniqueError()
            ? html`
                  <div class="error-message-container hidden-sm-up">
                      <span>${i18next.t("Este nombre de grupo ya existe.")}</span>
                  </div>
              `
            : "";

    const desktopNonUniqueErrorTemplate = () =>
        showNonUniqueError()
            ? html`
                  <div class="error-message-container hidden-xs">
                      <span>${i18next.t("Este nombre de grupo ya existe.")}</span>
                  </div>
              `
            : "";

    const mobileRequiredFieldErrorTemplate = () =>
        showRequiredError()
            ? html`
                  <div class="error-message-container hidden-sm-up">
                      <span>${i18next.t("Este campo es requerido.")}</span>
                  </div>
              `
            : "";

    const desktopRequiredFieldErrorTemplate = () =>
        showRequiredError()
            ? html`
                  <div class="error-message-container hidden-xs">
                      <span>${i18next.t("Este campo es requerido.")}</span>
                  </div>
              `
            : "";

    const groupNameTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !newGroupName,
        });

        return html`
            <div class="travel-partner-input-and-button">
                <div class="flex flex-col items-center sm:flex-row">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                        <label class="mdl-textfield__label">${i18next.t("Nombre del grupo")}</label>
                        <input
                            class="mdl-textfield__input js-input"
                            data-test-id="add-travel-group-name"
                            placeholder=${i18next.t("Grupo Nuevo")}
                            maxlength="16"
                            autocomplete="off"
                            @input=${handleGroupNameInput}
                            @blur=${handleGroupNameInput}
                        />
                    </div>
                    ${mobileNonUniqueErrorTemplate()} ${mobileRequiredFieldErrorTemplate()}
                    <button class=${tempClassMap} @click=${handleCreateGroup}>${i18next.t("Crear grupo")}</button>
                </div>
                ${desktopNonUniqueErrorTemplate()} ${desktopRequiredFieldErrorTemplate()}
            </div>
        `;
    };
    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Grupos")}</div>
        </div>
        <div class="travel-partner-hero">
            <h1>${i18next.t("Grupos")}</h1>
            <div>
                ${i18next.t(
                    "En ésta sección podrás agrupar tus pasajeros frecuentes como tu quieras. Además puedes crear nuevos grupos, editarlos, agregar pasajeros a un grupo existente y ¡mucho más!",
                )}
            </div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-grouping"></i>
            <h2>${i18next.t("Crear un Grupo")}</h2>
        </div>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    return html`
        <div ref=${ref(root)}>
            ${staticStuffTemplate()}
            <form class="travel-partner-form">${groupNameTemplate()}</form>
            <div class="travel-partner-title pull-up">
                <h2>${i18next.t("Administrar grupos")}</h2>
            </div>
            <div class="travel-partner-text-and-button">
                <div>
                    ${i18next.t(
                        "Aquí podrás editar tus grupos existentes, modificar los pasajeros asignados a cada grupo, agregar o eliminar pasajeros y eliminar grupos. ",
                    )}
                </div>
                <button
                    class="rounded-primary-btn"
                    @click=${() => (window.location.href = ROUTES.Cug2BAdminTravelGroupsPage)}
                >
                    ${i18next.t("Ver Grupos")}
                </button>
            </div>
            <div class="hidden-xs mt-16 flex w-full justify-end sm:mt-32">${backButtonTemplate()}</div>
        </div>
    `;
};

import i18next from "i18next";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";
import { validatePassword } from "../../../../validator/validation-helper";

export const passwordFormat = (): InputFieldAttribute => ({
    name: "password-format",
    validators: [
        {
            errorMessage: {
                id: "passwordFormat",
                message: i18next.t(
                    "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                ),
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = validatePassword(field.value);

                return Promise.resolve(result);
            },
        },
    ],
});

import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { useAppContext } from "../../../managers/useAppContext";
import { getCultureDependentStringValue } from "../../../shared/common";
import { BRASILIAN_CULTURE_CODE, USA_CULTURE_CODE } from "../../../shared/commonConstants";
import i18next from "i18next";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export const name = "ac-dc-info-modal";

export interface Props {
    isOpen: boolean;
    vm: FlightPageViewModel;
    onClose: () => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        isOpen: host.isOpen,
        vm: host.vm,
        onClose: host.onClose,
    };

    const appContext = useAppContext();

    const { formatNumber } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    const logoUrl = useMemo(
        () =>
            appContext.Culture === USA_CULTURE_CODE
                ? "/Images/DcStandalone/dc-logo-2-blue-en.png"
                : appContext.Culture === BRASILIAN_CULTURE_CODE
                  ? "/Images/DcStandalone/dc-logo-2-blue-pt.png"
                  : "/Images/DcStandalone/dc-logo-2-blue.png",
        [appContext.Culture],
    );

    const img1Url = useMemo(
        () =>
            getCultureDependentStringValue(
                props.vm?.DiscountClubViewModel.DiscountClubSettings?.FlightModalSettings?.Image1Url,
                appContext.Culture,
            ),
        [props.vm, appContext.Culture],
    );

    const img2Url = useMemo(
        () =>
            getCultureDependentStringValue(
                props.vm?.DiscountClubViewModel.DiscountClubSettings?.FlightModalSettings?.Image2Url,
                appContext.Culture,
            ),
        [props.vm, appContext.Culture],
    );

    const img3Url = useMemo(
        () =>
            getCultureDependentStringValue(
                props.vm?.DiscountClubViewModel.DiscountClubSettings?.FlightModalSettings?.Image3Url,
                appContext.Culture,
            ),
        [props.vm, appContext.Culture],
    );

    const img4Url = useMemo(
        () =>
            getCultureDependentStringValue(
                props.vm?.DiscountClubViewModel.DiscountClubSettings?.FlightModalSettings?.Image4Url,
                appContext.Culture,
            ),
        [props.vm, appContext.Culture],
    );

    const formattedStandardPrice = useMemo(
        () =>
            formatNumber({
                amount: Number(props.vm?.DiscountClubViewModel.StandardPriceUnformatted),
                leadingSign: true,
            }).replace(" ", ""),
        [props.vm, appContext.Culture, currency],
    );

    const formattedGroupPrice = useMemo(
        () =>
            formatNumber({
                amount: Number(props.vm?.DiscountClubViewModel.GroupPriceUnformatted),
                leadingSign: true,
            }).replace(" ", ""),
        [props.vm, appContext.Culture, currency],
    );

    const handleCloseButton = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClose();
    };

    return props.isOpen
        ? html`
              <div class="modal">
                  <div class="fixed w-[355px] rounded-xl bg-white all-center sm:w-[522px]">
                      <div class="relative h-[142px] w-[355px] rounded-t-xl bg-[#ffa100] sm:h-[142px] sm:w-[522px]">
                          <img
                              src="/Images/DcStandalone/laptop-guy.png"
                              class="absolute bottom-0 right-1 w-[180px] sm:right-10 sm:w-auto"
                          />
                          <img src=${logoUrl} class="absolute left-8 w-[152px] vertical-center" />
                          <div
                              class="absolute right-3 top-3 flex h-8 w-8 cursor-pointer select-none appearance-none items-center justify-center rounded-full border-none bg-cug-dark-gray pb-px text-2xl leading-none text-white hover:bg-brand-secondary hover:text-white"
                              @click=${handleCloseButton}
                          >
                              &times;
                          </div>
                      </div>
                      <div class="p-4">
                          <div class="text-center font-body text-sm font-bold uppercase leading-none text-new-ui-blue">
                              ${i18next.t("Tipos de membresía")}
                          </div>
                          <div class="mt-4 flex flex-col sm:flex-row sm:justify-between">
                              <div class="flex items-center">
                                  <i class="js-new js-n-dc-standard mr-3 text-[48px] leading-none text-[#ffa100]"></i>
                                  <div>
                                      <div class="text-base font-bold uppercase italic leading-none text-new-ui-blue">
                                          ${i18next.t("Membresía estándar")}
                                      </div>
                                      <div class="font-bold text-new-ui-blue">
                                          ${formattedStandardPrice}
                                          <span class="text-sm font-bold italic text-new-ui-blue">
                                              /${i18next.t("año")}
                                          </span>
                                      </div>
                                  </div>
                              </div>
                              <div class="mt-3 flex items-center sm:mt-0">
                                  <i class="js-new js-n-dc-group mr-3 text-[48px] leading-none text-[#ffa100]"></i>
                                  <div>
                                      <div class="text-base font-bold uppercase italic leading-none text-new-ui-blue">
                                          ${i18next.t("Membresía grupal")}
                                      </div>
                                      <div class="font-bold text-new-ui-blue">
                                          ${formattedGroupPrice}
                                          <span class="text-sm font-bold italic text-new-ui-blue">
                                              /${i18next.t("año")}
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div
                              class="mt-6 text-center font-body text-sm font-bold uppercase leading-none text-new-ui-blue"
                          >
                              ${i18next.t("Conoce tus beneficios")}
                          </div>
                          <div class="mt-4 grid grid-cols-2 gap-4">
                              <img src=${img1Url} class="block h-[55px] w-[154px] sm:h-[90px] sm:w-[237px]" />
                              <img src=${img2Url} class="block h-[55px] w-[154px] sm:h-[90px] sm:w-[237px]" />
                              <img src=${img3Url} class="block h-[55px] w-[154px] sm:h-[90px] sm:w-[237px]" />
                              <img src=${img4Url} class="block h-[55px] w-[154px] sm:h-[90px] sm:w-[237px]" />
                          </div>
                      </div>
                  </div>
              </div>
          `
        : html``;
};

import { useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useModal } from "../shared/useModal";

export interface Props {
    privacyUrl: string;
    termsUrl: string;
}

export const useCreditShellConfirmationModal = (props: Props) => {
    const { triggers } = usePubSub();

    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [showAcceptTermsError, setShowAcceptTermsError] = useState<boolean>(false);

    const handleContinue = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (validateTermsAcceptance()) {
            modal.close();
            triggers.payment.confirmationModalContinue.publish({});
        }
    };

    const handleTermsClick = () => {
        setIsTermsAccepted(!isTermsAccepted);
        setShowAcceptTermsError(false);
    };

    const validateTermsAcceptance = () => {
        if (!isTermsAccepted) setShowAcceptTermsError(true);

        return isTermsAccepted;
    };

    const termsCheckboxLabelTemplate = (id: string) => {
        const link1 = html`
            <a target="_blank" href="${props.termsUrl}" @click=${(e: MouseEvent) => e.stopPropagation()}>
                ${i18next.t("PaymentAcceptTerms02")}
            </a>
        `;

        const link2 = html`
            <a target="_blank" href="${props.privacyUrl}" @click=${(e: MouseEvent) => e.stopPropagation()}>
                ${i18next.t("PaymentAcceptTerms03")}
            </a>
        `;

        return html`
            <label for=${id} @change=${handleTermsClick}>
                <span class="cb-title">
                    ${i18next.t("Acepto los")} ${link1} ${i18next.t("y")} ${link2},
                    ${i18next.t(
                        "y declaro que soy mayor de edad o cuento con el consentimiento expreso de quién tiene capacidad legal para representarme.",
                    )}
                </span>
            </label>
        `;
    };

    const acceptTermsCheckbox = useBasicCheckbox({
        customInputClass: showAcceptTermsError ? "invalid" : "",
        customWrapperClass: "payment-confirmation-modal-terms-wrapper",
        isChecked: isTermsAccepted,
        labelTemplate: termsCheckboxLabelTemplate,
        onClick: handleTermsClick,
    });

    // TEMPLATES

    const acceptTermsErrorTemplate = () => html`
        <div class="error-message-container elevated-error text-left">
            <div class="form-error-message">${i18next.t("Breakdown-AcceptTermsFirst")}</div>
        </div>
    `;

    const confirmPaymentButton = () => html`
        <div class="secondary-modal-button-container">
            <button class="rounded-primary-btn" @click=${handleContinue}>${i18next.t("PayNow")}</button>
        </div>
    `;

    const modifyReservationUrlTemplate = () => html`
        <div class="payment-confirmation-modal-url-container">
            <span class="payment-confirmation-modal-url" @click=${modal.close}>
                ${i18next.t("V2-PaymentConfirmationModal-Mofdify")}
            </span>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">${i18next.t("V2-PaymentConfirmationModal-Description")}</div>
            ${acceptTermsCheckbox.htmlTemplate()} ${showAcceptTermsError ? acceptTermsErrorTemplate() : ""}
            ${confirmPaymentButton()} ${modifyReservationUrlTemplate()}
        </div>
    `;

    const modalHeaderTemplate = () => html`
        <span class="secondary-modal-header-text">
            ${i18next.t("V2-PaymentConfirmationModal-PaymentCinformation")}
        </span>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: modalHeaderTemplate },
        overlay: { classNames: "secondary-modal" },
    });

    return modal;
};

import { GridState, RowsSelectedEvent } from "./../dc-components/dc-table-models";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { SortOrder } from "../dc-components/dc-table-models";
import { classMap } from "lit-html/directives/class-map";

export class GridHelper {
    public static getOrderedFilteredModel<T>(model: T[], gridState: GridState<keyof T>): T[] {
        if (!model) {
            return [];
        }

        const splitSearchExpression = gridState.globalFilterExpression?.split(" ");

        return [...model]
            .filter((row) => this.filterByExpressions(row, splitSearchExpression))
            .sort((a, b) => this.sortByGridState(a, b, gridState))
            .slice(gridState.pageIndex * gridState.pageSize, (gridState.pageIndex + 1) * gridState.pageSize);
    }

    public static getFilteredTotalNumber<T>(model: T[], gridState: GridState<keyof T>): number {
        const splitSearchExpression = gridState.globalFilterExpression?.split(" ");

        return [...model].filter((row) => this.filterByExpressions(row, splitSearchExpression)).length;
    }

    public static getSelectedIds = <T extends { Id: string | number }>(
        event: RowsSelectedEvent,
        selectedIds: Set<string | number>,
        displayData: T[],
    ) => {
        const newSelectedIds = new Set(selectedIds);

        if (Array.isArray(event.detail.rowChanged)) {
            event.detail.rowChanged.forEach((row) => {
                if (event.detail.currentSelection.length === 0) {
                    newSelectedIds.delete(displayData[row].Id);
                } else {
                    newSelectedIds.add(displayData[row].Id);
                }
            });
        } else {
            if (newSelectedIds.has(displayData[event.detail.rowChanged].Id)) {
                newSelectedIds.delete(displayData[event.detail.rowChanged].Id);
            } else {
                newSelectedIds.add(displayData[event.detail.rowChanged].Id);
            }
        }

        return newSelectedIds;
    };

    public static getClassMap = (classes: string | string[]) => {
        if (!classes) {
            return classMap({});
        }

        classes = Array.isArray(classes) ? classes : [classes];
        const obj: any = {};
        classes.forEach((className) => (obj[className] = true));

        return classMap(obj);
    };

    public static stringSorter = <T>(objArray: T[], property: keyof T, direction: SortOrder) =>
        direction === "asc"
            ? objArray.sort((a, b) => (a[property] < b[property] ? -1 : 0))
            : objArray.sort((a, b) => (a[property] > b[property] ? -1 : 0));

    public static momentSorter = <T>(objArray: T[], property: keyof T, direction: SortOrder) =>
        direction === "asc"
            ? objArray.sort((a, b) => (dayjs(a[property]).isBefore(dayjs(b[property])) ? -1 : 0))
            : objArray.sort((a, b) => (dayjs(b[property]).isBefore(dayjs(a[property])) ? -1 : 0));

    public static displayData = <T>(gridState: GridState<keyof T>, data: T[]) => {
        let newData = GridHelper.getFilteredData(gridState, data);

        newData = dayjs.isDayjs(data[0][gridState.orderBy])
            ? GridHelper.momentSorter(newData, gridState.orderBy, gridState.orderDir)
            : GridHelper.stringSorter(newData, gridState.orderBy, gridState.orderDir);

        return newData.slice(
            gridState.pageIndex * gridState.pageSize,
            gridState.pageIndex * gridState.pageSize + gridState.pageSize,
        );
    };

    public static getFilteredData = <T>(gridState: GridState<keyof T>, data: T[]) =>
        [...data].filter(
            (row) =>
                !gridState.globalFilterExpression ||
                Object.keys(row).some(
                    (key) =>
                        typeof row[key as keyof typeof row] === "string" &&
                        ((row[key as keyof typeof row] as unknown) as string)
                            .toLowerCase()
                            .indexOf(gridState.globalFilterExpression.toLowerCase()) > -1,
                ),
        );

    private static filterByExpressions<T>(row: T, searchExpressions: string[]): boolean {
        if (!searchExpressions || searchExpressions.length === 0) {
            return true;
        }

        const rowKeys = Array.from(Object.keys(row));

        return searchExpressions.every((expression) =>
            rowKeys.some((key) => {
                const value = row[key as keyof typeof row];
                return typeof value === "string" ? value.toLowerCase().indexOf(expression.toLowerCase()) > -1 : false;
            }),
        );
    }

    private static sortByGridState<T>(a: T, b: T, gridState: GridState<keyof T>): number {
        if (gridState.orderDir === "desc") {
            return a[gridState.orderBy] > b[gridState.orderBy] ? -1 : 1;
        } else {
            return a[gridState.orderBy] > b[gridState.orderBy] ? 1 : -1;
        }
    }
}

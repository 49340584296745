import { ApiVoucherWithAmount } from "../../component-models/payment/ApiVoucherWithAmount";
import i18next from "i18next";
import { hideLoader, showLoader } from "../../shared/common";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { ApiCustomVoucherInfo } from "../../component-models/payment/ApiCustomVoucherInfo";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { VOUCHER_PAYMENT_METHOD_CODE } from "../../shared/commonConstants";
import PerfectScrollbar from "../../../../node_modules/perfect-scrollbar";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import {
    voucherHeaderTemplate,
    voucherMobileHeaderTemplate,
    voucherTooltipTemplate,
} from "../../common-templates/PaymentCommonTemplates";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { usePaymentCancelsPromoCodeModal } from "./usePaymentCancelsPromoCodeModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import classNames from "classnames";

export interface Props {
    model: PaymentPageViewModel;
    handleGiftcardClick: (e: MouseEvent, voucher: ApiVoucherWithAmount) => void;
    nonPromoCodeCallback: () => Promise<void>;
    setShowGiftcardSelector: (newState: boolean) => void;
}

export const useGiftcard = (props: Props) => {
    const bookingContext = useBookingContext();

    const { triggers } = usePubSub();
    const { checkPromoCodeAvailable } = useAjax();

    const root = useRef<HTMLDivElement>(undefined);
    const scroller = useRef<HTMLDivElement>(undefined);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [promoCodeCallback, setPromoCodeCallback] = useState<() => () => void>(undefined);

    const blockPromoCodeModal = usePaymentCancelsPromoCodeModal({
        body: i18next.t(
            "It’s not possible to use your promotional code and a gift card for the same booking. Please select the option you want to use:",
        ),
        nonPromoCodeBtnText: i18next.t("Gift Card"),
        promoCodeBtnText: i18next.t("Promo code"),
        nonPromoCodeCallback: props.nonPromoCodeCallback,
        promoCodeCallback,
    });

    const initScrollbar = () => {
        if (scrollbar) scrollbar.destroy();

        if (!scroller.current) return;

        scroller.current.removeEventListener("ps-scroll-y", closeTooltipsOnScroll);

        setScrollbar(
            new PerfectScrollbar(scroller.current.parentElement, {
                wheelPropagation: false,
                wheelSpeed: 2,
                swipeEasing: true,
                suppressScrollX: true,
            }),
        );

        scroller.current.addEventListener("ps-scroll-y", closeTooltipsOnScroll);
    };

    const closeTooltipsOnScroll = () => triggers.shared.windowWasScrolled.publish({});

    const canUsePromoCode = async () => {
        const loader = showLoader({
            container: DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.CommonLoaderWrapper)
                .parentElement,
        });
        const canUse = await checkPromoCodeAvailable(VOUCHER_PAYMENT_METHOD_CODE, false, true);
        hideLoader(loader);

        return canUse;
    };

    const handleGiftcardVsPromoCodeModal = async () => {
        const canUseThisPromoCode = await canUsePromoCode();

        if (!canUseThisPromoCode) blockPromoCodeModal.open();
    };

    const toggleOpen = async () => {
        setIsOpen(!isOpen);

        if (bookingContext.promoCode) {
            setPromoCodeCallback(() => () => setIsOpen(false));
            handleGiftcardVsPromoCodeModal();
        }
    };

    const toggleToVoucher = () => {
        props.setShowGiftcardSelector(false);

        if (bookingContext.promoCode) {
            setPromoCodeCallback(() => () => props.setShowGiftcardSelector(true));
            handleGiftcardVsPromoCodeModal();
        }
    };

    useEffect(initScrollbar, [scroller.current]);

    const headerTemplate = () => html`
        <header>
            <span class="js-gift-card js-icon title-icon"></span>
            <div class="title">
                ${voucherHeaderTemplate()} ${voucherMobileHeaderTemplate()} ${voucherTooltipTemplate(false)}
            </div>
        </header>
    `;

    const linkToVoucherTemplate = () =>
        !props.model.VoucherAndGiftcardViewModel.AnyNonDeclinedVoucherPayment
            ? html`
                  <span @click=${toggleToVoucher} class="gift-card-opener-toggle">
                      ${i18next.t("Gift-ToggleOtherGiftCardInput")}
                  </span>
              `
            : "";

    const openerTemplate = () => {
        const tempClassMap = classNames("js-circle-chevron-right js-icon", {
            open: isOpen,
        });

        const numberOfGiftcards = props.model.VoucherAndGiftcardViewModel.NumberOfGiftcards;

        return html`
            <span class="giftcard-switches-container">
                <span
                    @click=${toggleOpen}
                    class="gift-card-opener-info"
                    data-test-id=${T.GIFTCARD.PAYMENT_TOGGLE_ACTIVE_GIFTCARDS}
                >
                    ${i18next.t("Gift-GiftcardOpenerInfo {{-amount}}{{-plural}}", {
                        amount: numberOfGiftcards,
                        plural: numberOfGiftcards !== 1 ? "s" : "",
                    })}

                    <span class="open-icon">
                        <i class=${tempClassMap}></i>
                    </span>
                </span>
                ${linkToVoucherTemplate()}
            </span>
        `;
    };

    const specialFieldsTemplate = (voucher: ApiCustomVoucherInfo) =>
        voucher.BonusVoucher
            ? html`
                  <div></div>
                  <div></div>
              `
            : html`
                  <div class="with-tick">${i18next.t("Gift-PaymentTableFees")}</div>
                  <div class="with-tick">${i18next.t("Gift-PaymentTableTaxes")}</div>
              `;

    const giftcardTableRowTemplate = (voucher: ApiVoucherWithAmount) => html`
        <div class="gpl-row">
            <div class="amount">
                ${i18next.t("Gift-PaymentTableAmount")}: $${voucher.Voucher.FormattedFaceValueInBookingCurrency}
            </div>
            <div class="with-tick first">${i18next.t("Gift-PaymentTableFare")}</div>
            <div class="with-tick">${i18next.t("Gift-PaymentTableExtras")}</div>
            ${specialFieldsTemplate(voucher.Voucher)}

            <div
                class="utilize"
                data-test-id=${T.GIFTCARD.PAYMENT_UTILIZE_GIFTCARD}
                @click=${(e: MouseEvent) => props.handleGiftcardClick(e, voucher)}
            >
                <span>${i18next.t("Gift-PaymentTableUtilize")} <i class="js-circle-chevron-right js-icon"></i></span>
            </div>
        </div>
    `;

    const giftcardTableTemplate = () => {
        const tempClassMap = classNames("new-giftcard-content", {
            open: isOpen,
        });

        return html`
            <div class=${tempClassMap}>
                <div ref=${ref(scroller)} class="giftcard-payment-list">
                    ${props.model.VoucherAndGiftcardViewModel.Vouchers.map(giftcardTableRowTemplate)}
                </div>
            </div>
        `;
    };

    const mixedCardWarningTemplate = () =>
        props.model.VoucherAndGiftcardViewModel.ShowMixedGiftcardWarning && isOpen
            ? html`
                  <div class="full-width-information voucher-result">${i18next.t("Gift-MixedCardtypesWarning")}</div>
              `
            : "";

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class="inner-deep-box new-voucher-form-box">
            <div class="new-giftcard-wrapper">
                <div class="new-giftcard-opener">${headerTemplate()} ${openerTemplate()}</div>

                ${giftcardTableTemplate()}
            </div>

            ${mixedCardWarningTemplate()}
        </div>
        ${blockPromoCodeModal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

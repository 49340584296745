import { html } from "lit-html";
import { OUTBOUND, INBOUND, ACTION_NAMES } from "../../shared/commonConstants";
import { JourneySegment } from "../../component-models/shared/JourneySegment";
import dayjs from "dayjs";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { capitalizeAndRemoveDot, groupBy } from "../../shared/common";
import { TestIdDictionary as T, getTestId } from "./../../testing-helpers/TestIdHelper";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import classNames from "classnames";

export interface Props {
    segments: JourneySegment[];
}

export const useSidebarSegments = (props: Props) => {
    // HELPERS

    const hasSecondJourney = () => props.segments.some((s) => s.JourneyIndex === 1);

    const firstSegmentOfJourney = (journeyIndex: number) => journeyByIndex(journeyIndex)[0];

    const journeys = () => groupBy(props.segments, "JourneyIndex");

    const journeyByIndex = (journeyIndex: number) => journeys()[journeyIndex];

    const showChange = (segment: JourneySegment) =>
        (!flowContext.isBookingFlow && segment.FormattedEstimatedArrivalTime) ||
        (segment.FormattedEstimatedDepartureTime &&
            ((!bookingContext.isCheckinClosedOutbound && segment.JourneyIndex === OUTBOUND) ||
                (!bookingContext.isCheckinClosedInbound && segment.JourneyIndex === INBOUND)));

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    // TEMPLATES

    const journeyTemplate = (journeyIndex: number) => {
        const containerTestId = getTestId(T.SIDEBAR.BOOKING_DETAILS_JOURNEY_TIME, { j: journeyIndex });
        const contentTestId = getTestId(T.SIDEBAR.BOOKING_DETAILS_JOURNEY_DATE, { j: journeyIndex });
        const departureDate = dayjs(firstSegmentOfJourney(journeyIndex).DepartureDate).format("ddd DD-MM");
        const normalizedDepartureDate = capitalizeAndRemoveDot(departureDate);

        return html`
            <div data-test-id=${containerTestId}>
                <div class="cf-journey-date" data-test-id=${contentTestId}>
                    <span
                        >${journeyIndex === OUTBOUND
                            ? i18next.t("SidebarBookingDetails-Outbound")
                            : i18next.t("SidebarBookingDetails-Inbound")}</span
                    >

                    ${normalizedDepartureDate}
                </div>

                ${segmentsTemplate(journeyIndex)}
            </div>
        `;
    };

    const segmentsTemplate = (journeyIndex: number) =>
        props.segments
            .filter((s) => s.JourneyIndex === journeyIndex)
            .map((segment, segmentIndex) => segmentTemplate(segment, segmentIndex, journeyIndex));

    const transferInformationTemplate = (journeyIndex: number, segmentIndex: number) => {
        if (segmentIndex >= journeyByIndex(journeyIndex).length - 1) {
            return "";
        }

        const nextSegment = journeyByIndex(journeyIndex)[segmentIndex + 1];

        return html`
            <div class="transfer-information-container">
                <i class="far fa-clock"></i>
                <span>
                    ${i18next.t("Tiempo estimado de escala en {{arrivalStation}} {{hours}}h {{minutes}}min.", {
                        hours: nextSegment.StopDurationHours,
                        minutes: nextSegment.StopDurationMinutes,
                        arrivalStation: nextSegment.DepartureStationName,
                    })}
                </span>
            </div>
        `;
    };

    const segmentTemplate = (segment: JourneySegment, segmentIndex: number, journeyIndex: number) => {
        const tempClassMap = classMap({
            "cf-sidebar-segment-container": true,
            "mt-2": segmentIndex > 0,
        });

        const contentClassMap = classMap({
            "cf-sidebar-segment-times": true,
            "changed-times": showChange(segment),
        });

        return html`
            <div class=${tempClassMap}>
                <div class=${contentClassMap}>
                    <div class="cf-sidebar-segment-endpoint">
                        <span
                            class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}
                            data-test-id=${getTestId(T.SIDEBAR.DEPARTURE_STATION_CODE, { j: journeyIndex })}
                        >
                            ${segment.DepartureStation}
                        </span>
                        <i
                            class="far fa-clock"
                            data-test-id=${getTestId(T.SIDEBAR.DEPARTURE_TIME, { j: journeyIndex })}
                            data-test-value=${segment.FormattedDepartureTime}
                        ></i>
                        <span> ${segment.FormattedDepartureTime} ${oneMoreDayDepartureTemplate(segment)} </span>
                    </div>
                    <i class="js-icon-covid js-cv-filled-plane-up decor"></i>
                    <div class="cf-sidebar-segment-endpoint">
                        <span
                            class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}
                            data-test-id=${getTestId(T.SIDEBAR.ARRIVAL_STATION_CODE, { j: journeyIndex })}
                        >
                            ${segment.ArrivalStation}
                        </span>
                        <i
                            class="far fa-clock"
                            data-test-id=${getTestId(T.SIDEBAR.ARRIVAL_TIME, { j: journeyIndex })}
                            data-test-value=${segment.FormattedArrivalTime}
                        ></i>
                        <span> ${segment.FormattedArrivalTime} ${oneMoreDayArrivalTemplate(segment)} </span>
                    </div>
                </div>
                ${segmentChangeTemplate(segment)}
            </div>
            ${transferInformationTemplate(journeyIndex, segmentIndex)}
        `;
    };

    const oneMoreDayDepartureTemplate = (segment: JourneySegment) =>
        segment.FormattedDepartureDate !== firstSegmentOfJourney(segment.JourneyIndex).FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayArrivalTemplate = (segment: JourneySegment) =>
        segment.FormattedArrivalDate !== firstSegmentOfJourney(segment.JourneyIndex).FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const segmentChangeTemplate = (segment: JourneySegment) => {
        const displayedDepartureTime = segment.FormattedEstimatedDepartureTime
            ? dayjs(segment.FormattedEstimatedDepartureTime).format("HH:mm")
            : segment.FormattedDepartureTime;

        const displayedArrivalTime = segment.FormattedEstimatedArrivalTime
            ? dayjs(segment.FormattedEstimatedArrivalTime).format("HH:mm")
            : segment.FormattedArrivalTime;

        return showChange(segment)
            ? html`
                  <div class="cf-sidebar-new-time">${i18next.t("Nuevo horario")}</div>
                  <div class="cf-sidebar-segment-times">
                      <div class="cf-sidebar-segment-endpoint">
                          <span class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}>
                              ${segment.DepartureStation}
                          </span>
                          <i class="far fa-clock"></i>

                          <span> ${displayedDepartureTime} </span>
                          ${oneMoreDayChangedDepartureTemplate(segment)}
                      </div>
                      <i class="js-icon-covid js-cv-filled-plane-up decor"></i>
                      <div class="cf-sidebar-segment-endpoint">
                          <span class=${classNames({ notranslate: flowContext.action === ACTION_NAMES.ITINERARY })}>
                              ${segment.ArrivalStation}
                          </span>
                          <i class="far fa-clock"></i>
                          <span> ${displayedArrivalTime} </span>
                          ${oneMoreDayChangedArrivalTemplate(segment)}
                      </div>
                  </div>
              `
            : "";
    };

    const oneMoreDayChangedDepartureTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedDepartureDate !== firstSegmentOfJourney(segment.JourneyIndex).FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const oneMoreDayChangedArrivalTemplate = (segment: JourneySegment) =>
        segment.FormattedEstimatedArrivalDate !== firstSegmentOfJourney(segment.JourneyIndex).FormattedDepartureDate
            ? html`<span class="one-more-day">+1</span>`
            : "";

    const htmlTemplate = () => html`
        <div class="cf-segments-container">
            <h3 class="cf-segments-title" data-test-id=${T.SIDEBAR.BOOKING_DETAILS_TITLE}>
                <span class="js-flight js-icon"></span> ${i18next.t("V2-PaymentDetailsTitle")}:
            </h3>
            ${props.segments?.length > 0
                ? html` ${journeyTemplate(OUTBOUND)} ${hasSecondJourney() ? journeyTemplate(INBOUND) : ""} `
                : html``}
        </div>
    `;

    return { htmlTemplate };
};

import { html } from "lit-html";
import i18next from "i18next";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import * as dayjs from "dayjs";
import { PeruCompraReservationModel } from "../../../component-models/peru-compra/PeruCompraReservationModel";
import { PeruCompraCancelledReservationModel } from "../../../component-models/peru-compra/PeruCompraCancelledReservationModel";
import { getFormattedTimeDiff } from "../../../shared/common";

export const name = "ac-peru-compra-details-modal";

export interface Properties {
    data: PeruCompraReservationModel;
    isOpen: boolean;
    getFlightType: (reservation: PeruCompraReservationModel | PeruCompraCancelledReservationModel) => number;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        data: host.data,
        isOpen: host.isOpen,
        getFlightType: host.getFlightType,
    };

    // TEMPLATES
    const peruCompraDetailsModalHeaderTemplate = () =>
        html` <div class="modal-header pcra-modal-header">${i18next.t("Detalle de movimiento")}</div>`;

    const rowTemplate = (label: string, value: string, force?: boolean) =>
        value || force
            ? html` <div class="row"><span>${label}</span><span class="pcra-row-value">${value}</span></div> `
            : "";

    const peruCompraDetailsModalContentTemplate = () => html`
        <div class="modal-body">
            <div class="pcra-details-content">
                <div class="column">
                    ${rowTemplate(i18next.t("RUC Entidad"), props.data.RUC)}
                    ${rowTemplate(i18next.t("Gestor"), props.data.AdminId, true)}
                    ${rowTemplate(i18next.t("Delegado"), props.data.MemberId, true)}
                    ${rowTemplate(i18next.t("Tipo Oper."), props.data.OperationType)}
                    ${rowTemplate(i18next.t("C. Boletos"), props.data.InvoiceQuantity?.toString())}
                    ${rowTemplate(i18next.t("Tipo Serv."), props.data.ServiceType)}
                    ${rowTemplate(i18next.t("Fec. Hora Oper."), dayjs(props.data.CreatedDate).format("DD/MM/YYYY"))}
                    ${rowTemplate(i18next.t("Boleto"), props.data.TicketNumber)}
                    ${rowTemplate(i18next.t("Tramo"), props.data.NumberOfLegs?.toString())}
                    ${rowTemplate(i18next.t("N° Vuelo"), props.data.FlightNumber)}
                </div>
                <div class="column">
                    ${rowTemplate(i18next.t("Origen"), props.data.Origin)}
                    ${rowTemplate(i18next.t("Fec. Hora Origen"), dayjs(props.data.DepartureDate).format("DD/MM/YYYY"))}
                    ${rowTemplate(i18next.t("Destino"), props.data.Destination)}
                    ${rowTemplate(i18next.t("Fec. Hora Destino"), dayjs(props.data.ArrivalDate).format("DD/MM/YYYY"))}
                    ${rowTemplate(i18next.t("Doc. Id. Pasajero"), props.data.PassengerDocumentId)}
                    ${rowTemplate(i18next.t("Apellidos"), props.data.LastName)}
                    ${rowTemplate(i18next.t("Nombre"), props.data.FirstName)}
                    ${rowTemplate(
                        i18next.t("Importe Tramo"),
                        props.data.TotalAmount ||
                            (props.data as unknown as PeruCompraCancelledReservationModel).RefundAmount,
                    )}
                    ${rowTemplate(
                        i18next.t("Duración"),
                        getFormattedTimeDiff(dayjs(props.data.ArrivalDate), dayjs(props.data.DepartureDate)),
                    )}
                </div>
            </div>
        </div>
        ${props.data.PdfUrl
            ? html`
                  <div class="pcra-modal-footer">
                      <a href=${props.data.PdfUrl} class="rounded-primary-btn">${i18next.t("Descargar PDF")}</a>
                  </div>
              `
            : ""}
    `;

    return props.isOpen
        ? html` <ac-cug-modal
              .canBeClosed=${true}
              .header=${peruCompraDetailsModalHeaderTemplate()}
              .content=${peruCompraDetailsModalContentTemplate()}
              .isOpen=${props.isOpen}
              .customClass=${"pcra-modal"}
          ></ac-cug-modal>`
        : html``;
};

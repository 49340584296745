import { ApiBoardingPassViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";
import { useImageFromDomElement } from "../../../shared/customHooks/useImageFromDomElement";
import { useDesktopBoardingCard } from "./useDesktopBoardingCard";
import { useMobileBoardingCard } from "./useMobileBoardingCard";

export const BOARDING_PASS_WIDTH = 1070;
export const BOARDING_PASS_HEIGHT = 690;
export const MOBILE_BOARDING_PASS_WIDTH = 400;
export const MOBILE_BOARDING_PASS_HEIGHT = 700;

export interface BoardingPassImage {
    image: string;
    index: number;
    paxName: string;
    size: "desktop" | "mobile";
}

export const useBoardingPassImageCreator = () => {
    const { renderImgFromDomElem } = useImageFromDomElement();
    const { renderDesktopBoardingCard } = useDesktopBoardingCard();
    const { renderMobileBoardingCard } = useMobileBoardingCard();

    const getBoardingCardImagesAsync = async (models: ApiBoardingPassViewModel[]): Promise<BoardingPassImage[]> => {
        const allPromises = models.flatMap((model) => [
            renderImgFromDomElem({
                canvasHeight: BOARDING_PASS_HEIGHT,
                canvasWidth: BOARDING_PASS_WIDTH,
                id: model.Passenger.PaxName,
                index: model.Index,
                size: "desktop",
                template: renderDesktopBoardingCard(model),
            }),
            renderImgFromDomElem({
                canvasHeight: MOBILE_BOARDING_PASS_HEIGHT,
                canvasWidth: MOBILE_BOARDING_PASS_WIDTH,
                id: model.Passenger.PaxName,
                index: model.Index,
                size: "mobile",
                template: renderMobileBoardingCard(model),
            }),
        ]);

        const result = await Promise.all(allPromises);

        return result.map(
            (item): BoardingPassImage => ({
                image: item.image,
                index: item.index,
                paxName: item.id,
                size: item.size,
            }),
        );
    };

    return { getBoardingCardImagesAsync };
};

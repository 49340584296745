import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { PagerChangeEvent } from "../../../dc-components/dc-pager";
import { ChangeEvent } from "../../../dc-components/dc-checkbox";
import { getAntiForgeryTokenFromHtml, handleCugLoader } from "../../../shared/common";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { URL_VARS } from "../../../shared/commonConstants";
import { classMap } from "lit-html/directives/class-map";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export const name = "ac-admin-travel-groups-page";

export interface Attributes {
    "anti-forgery-token": string;
}
export interface Properties {
    antiForgeryToken: string;
}

const groupsPerPage = 8;

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const result = await getTravelPartnerInfo();

        setTravelPartnerInfo(result);
    };

    const deleteGroups = async () => {
        setGroupsToDelete(undefined);

        const newTravelPartnerInfo = TravelPartnerHelper.deleteGroups(
            travelPartnerInfo,
            Array.from(selectedIds.values()),
            deletionMode === "groupsAndPartners",
        );

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);
        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const isAnythingSelected = () => Array.from(selectedIds?.values()).length > 0;

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [groupsToDelete, setGroupsToDelete] = useState<number[]>(undefined);
    const [deletionMode, setDeletionMode] = useState<"none" | "groupsOnly" | "groupsAndPartners">("none");

    useEffect(init, []);

    // EVENT LISTENERS

    const handleEditGroup = (id: number) => {
        const searchExpression = `${URL_VARS.FFLYER_IDS}=${encodeURIComponent(id)}`;
        window.location.href = `${ROUTES.Cug2BEditTravelGroupPage}?${searchExpression}`;
    };

    const handleMultiDelete = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setGroupsToDelete(Array.from(selectedIds.values()));
    };

    const handlePagerChange = (e: PagerChangeEvent) => {
        setCurrentPage(e.detail.selectedPageIndex);
    };

    const handleSelection = (e: ChangeEvent, id: number) => {
        const clonedSet = new Set(selectedIds);

        if (e.detail.checked) {
            clonedSet.add(id);
        } else {
            clonedSet.delete(id);
        }

        setSelectedIds(clonedSet);
    };

    const handleModalClose = () => {
        setGroupsToDelete(undefined);
        setDeletionMode("none");
    };

    // TEMPLATES

    const staticStuffTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-pill": true,
            "warning": true,
            "disabled": !isAnythingSelected(),
        });

        return html`
            <div class="travel-partner-breadcrumb-container">
                <div
                    class="travel-partner-breadcrumb past"
                    @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
                >
                    ${i18next.t("Pasajeros frecuentes")}
                </div>
                <div class="travel-partner-breadcrumb divider">/</div>
                <div
                    class="travel-partner-breadcrumb past"
                    @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
                >
                    ${i18next.t("Grupos")}
                </div>
                <div class="travel-partner-breadcrumb divider">/</div>
                <div class="travel-partner-breadcrumb">${i18next.t("Administrar grupos")}</div>
            </div>
            <div class="travel-partner-title pull-up">
                <h2>${i18next.t("Administrar grupos")}</h2>
            </div>
            <div class="travel-partner-text-and-button">
                <div>
                    ${i18next.t(
                        "Aquí podrás editar tus grupos existentes, modificar los pasajeros asignados a cada grupo, agregar o eliminar pasajeros y eliminar grupos.",
                    )}
                </div>
                <button class=${tempClassMap} @click=${handleMultiDelete}>
                    <i class="js-icon-cug js-cug-bin"></i>${i18next.t("Eliminar Grupo(s)")}
                </button>
            </div>
        `;
    };

    const areYouSureModalHeaderTemplate = () => html`
        <span>${i18next.t("¿Estás seguro que quieres eliminar este grupo?")}</span>
    `;

    const warningTemplate = () =>
        deletionMode === "groupsAndPartners"
            ? i18next.t(
                  "Esta grupo se eliminará de forma permanente incluyendo los pasajeros que son parte de él y no podrás volver atrás.",
              )
            : i18next.t("Este grupo se eliminará de forma permanente y no podrás volver atrás.");

    const groupsToDeleteTemplate = () =>
        groupsToDelete?.map((id) => {
            const group = travelPartnerInfo.Groups.find((g) => g.Id === id);

            return group
                ? html`
                      <div class="cug2b-are-you-sure-modal-group-list-item">
                          <h1>${group.Name}</h1>
                          <h2>
                              ${i18next.t("Cantidad de pasajeros")}:
                              <span class="font-bold">
                                  ${TravelPartnerHelper.getNumberOfPartners(travelPartnerInfo, id)}
                              </span>
                          </h2>
                      </div>
                  `
                : "";
        });

    const areYouSureModalContentTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-are-you-sure-modal-group-list": true,
            "solo-item": groupsToDelete?.length === 1,
        });

        return html`
            <div class="cug2b-are-you-sure-modal-group-delete">
                <div class=${tempClassMap}>${groupsToDeleteTemplate()}</div>
                <div class="cug2b-are-you-sure-modal-group-list-info">${warningTemplate()}</div>
                <div class="mt-4 flex flex-col items-center justify-center sm:mt-8 sm:flex-row">
                    <button
                        class="rounded-secondary-btn with-arrow cug2b-white mb-4 sm:mb-0 sm:mr-4"
                        @click=${handleModalClose}
                    >
                        ${i18next.t("No, no quiero eliminarlo")}
                    </button>
                    <button class="rounded-primary-btn" @click=${deleteGroups}>
                        ${i18next.t("Si, eliminar grupo")}
                    </button>
                </div>
            </div>
        `;
    };

    const deletionModeModalHeaderTemplate = () => html`
        <span>${i18next.t("¿Quieres eliminar también a los pasajeros del grupo?")}</span>
    `;

    const deletionModeModalContentTemplate = () => {
        return html`
            <div class="p-8">
                <div class="cug2b-modal-question mb-4 text-center sm:mb-8">
                    ${i18next.t("¿Quieres eliminar solo el grupo o también todos los pasajeros del grupo?")}
                </div>
                <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                    <button
                        class="rounded-secondary-btn with-arrow mb-4 sm:mb-0 sm:mr-4"
                        @click=${() => setDeletionMode("groupsOnly")}
                    >
                        ${i18next.t("Eliminar grupo, mantener pasajeros")}
                    </button>
                    <button class="rounded-primary-btn" @click=${() => setDeletionMode("groupsAndPartners")}>
                        ${i18next.t("Eliminar grupo y todos los pasajeros")}
                    </button>
                </div>
            </div>
        `;
    };

    const areYouSureModalTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${areYouSureModalContentTemplate()}
            .customClass=${"cug2b-are-you-sure-group-modal"}
            .header=${areYouSureModalHeaderTemplate()}
            .isOpen=${groupsToDelete && deletionMode !== "none"}
            @close=${handleModalClose}
        ></ac-cug-modal>
    `;

    const deletionModeModalTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${deletionModeModalContentTemplate()}
            .customClass=${["cug2b-are-you-sure-group-modal", "delete-only-group-or-also-members"]}
            .header=${deletionModeModalHeaderTemplate()}
            .isOpen=${groupsToDelete && deletionMode === "none"}
            @close=${handleModalClose}
        ></ac-cug-modal>
    `;

    const currentPageTemplate = () => {
        const pageItems = TravelPartnerHelper.getAdminGroupPageContent(travelPartnerInfo, currentPage, groupsPerPage);

        return html`
            <div class="cug2b-group-admin-group-container">
                ${pageItems.map((group) => {
                    return html`
                        <div class="cug2b-group-admin-group">
                            <div class="cug2b-group-admin-checkbox">
                                <dc-checkbox
                                    .checked=${selectedIds.has(group.Id)}
                                    @change=${(e: ChangeEvent) => handleSelection(e, group.Id)}
                                ></dc-checkbox>
                            </div>
                            <h1>${group.Name}</h1>
                            <h2>
                                ${i18next.t("Cantidad de pasajeros")}:
                                <span>${TravelPartnerHelper.getNumberOfPartners(travelPartnerInfo, group.Id)}</span>
                            </h2>
                            <div class="cug2b-group-admin-link" @click=${() => handleEditGroup(group.Id)}>
                                ${i18next.t("Ver listado de personas")} <span>&rsaquo;</span>
                            </div>
                            <i class="js-icon-cug js-cug-edit" @click=${() => handleEditGroup(group.Id)}></i>
                        </div>
                    `;
                })}
            </div>
        `;
    };

    const paginatorTemplate = () => html`
        <dc-pager
            .pageSize=${groupsPerPage}
            .pageIndex=${currentPage}
            .itemCount=${travelPartnerInfo.Groups.length}
            .pageButtonCount=${3}
            .showInfo=${false}
            .showPreviousNext=${true}
            @change=${handlePagerChange}
        ></dc-pager>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    return travelPartnerInfo
        ? html`
              <div ref=${ref(root)}>
                  ${staticStuffTemplate()} ${currentPageTemplate()} ${paginatorTemplate()} ${backButtonTemplate()}
                  ${deletionModeModalTemplate()} ${areYouSureModalTemplate()}
              </div>
          `
        : html``;
};

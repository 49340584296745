import { commonDebug } from "../../bootstrap";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { PromoCodeFlightSelectionCookie } from "../../component-models/flight/PromoCodeFlightSelectionCookie";
import { DcState } from "../../component-models/flight/contexts/DcState";
import { FlightPageBundleState } from "../../component-models/flight/contexts/FlightPageBundleState";
import { FlightPageFlightState } from "../../component-models/flight/contexts/FlightPageFlightState";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";
import { getUrlVars, getUrlVarsAsQueryString } from "../../shared/common";
import { CHANGING_CURRENCY_URL_ELEMENT, COOKIE_NAMES, URL_VARS } from "../../shared/commonConstants";
import { getCookie, setFiveMinuteCookie } from "../../shared/cookieHandling";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";
import { SelectedDcMembershipType } from "./dc-banner/useDiscountClubBanner";
import { SelectedFlightFeeType } from "./flight-page";

export interface FlightHttpContextHandlerProps {
    model: FlightPageViewModel;
}

export const useFlightHttpContextHandler = (props: FlightHttpContextHandlerProps) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const userContextManager = useUserContextBuilder();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const init = () => userContextManager.init(props.model);

    // HELPERS

    const getCustomUrlParams = () =>
        getUrlVarsAsQueryString([
            URL_VARS.CURRENCY,
            URL_VARS.SELL_KEYS,
            URL_VARS.BUNDLE_CODES,
            URL_VARS.PRICE_TYPE,
            URL_VARS.SELECTED_MEMBERSHIP,
        ]);

    const getPriceTypeUrlParamValue = () => {
        const currentUrlVars = getUrlVars();
        return currentUrlVars && currentUrlVars[URL_VARS.PRICE_TYPE]
            ? (currentUrlVars[URL_VARS.PRICE_TYPE] as SelectedFlightFeeType[])[0]
            : undefined;
    };

    const getSellKeysForQueryString = (flightState: FlightPageFlightState) =>
        flightState.selectedOutboundFlight || flightState.selectedInboundFlight
            ? [flightState.selectedOutboundFlight, flightState.selectedInboundFlight].map((f) => {
                  const key = f && f.SellKey ? encodeURIComponent(btoa(f.SellKey)) : "";
                  return `&${URL_VARS.SELL_KEYS}=${key}`;
              })
            : [...Array(2)].map(() => `&${URL_VARS.SELL_KEYS}=`);

    const getPromoCodeInCookie = () => {
        if (bookingContext.promoCode) {
            try {
                return JSON.parse(
                    atob(getCookie(COOKIE_NAMES.PromoCodeFlightSelection)),
                ) as PromoCodeFlightSelectionCookie;
            } catch (e) {
                commonDebug.error("Could not pick up promo code cookie", e);
            }
        }

        return undefined;
    };

    const getSelectedMembershipUrlParamValue = () => {
        const currentUrlVars = getUrlVars();
        return currentUrlVars && currentUrlVars[URL_VARS.SELECTED_MEMBERSHIP]
            ? (currentUrlVars[URL_VARS.SELECTED_MEMBERSHIP] as SelectedDcMembershipType[])[0]
            : undefined;
    };

    const getPreselectedMembershipFromUrl = () => {
        const urlParam = getSelectedMembershipUrlParamValue();

        if (["Group", "Standard", "Upgrade"].includes(urlParam)) return urlParam;

        return undefined;
    };

    const getPreselectedMembershipFromModel = () => {
        if (props.model.DiscountClubViewModel.IsBuyingGroupMembership) return "Group";
        if (props.model.DiscountClubViewModel.IsBuyingStandardMembership) return "Standard";
        if (props.model.DiscountClubViewModel.IsUpgradingMembership) return "Upgrade";

        if (props.model.DiscountClubViewModel.IsExtendingMembership) {
            return userContext.dc.hasStandardMembership ? "Standard" : "Group";
        }

        return undefined;
    };

    const getBundleCodesForQueryString = (bundleState: FlightPageBundleState) => {
        if (!props.model.BundleViewModel.IsBundlesActive || !bundleState) {
            return [];
        }

        return [
            `&${URL_VARS.BUNDLE_CODES}=${bundleState.selectedOutboundBundle?.BundleCode || ""}`,
            `&${URL_VARS.BUNDLE_CODES}=${bundleState.selectedInboundBundle?.BundleCode || ""}`,
        ];
    };

    // EXPORTS

    const getPreselectedFlightFeeType = () => {
        const pcCookie = getPromoCodeInCookie();
        const selectedPriceInUrl = getPriceTypeUrlParamValue();
        const selectedPriceInCookie = pcCookie?.selectedFeeType;

        const eligibleForPrice: SelectedFlightFeeType =
            userContext.dc.hasMembership ||
            (props.model.HasBooking && props.model.DiscountClubViewModel.IsDcFeeSelectedInBooking) ||
            props.model.DiscountClubViewModel.IsDcEligible ||
            userContext.bancoEstado.category === 6
                ? "Club"
                : "Smart";

        return selectedPriceInCookie || selectedPriceInUrl || eligibleForPrice;
    };

    const getPreselectedSellKeys = () => {
        const pcCookie = getPromoCodeInCookie();

        if (pcCookie?.outboundFlightSellKey || pcCookie?.inboundFlightSellKey) {
            return [pcCookie.outboundFlightSellKey, pcCookie.inboundFlightSellKey];
        }

        const urlParams = new URLSearchParams(window.location.search);

        return urlParams.getAll(URL_VARS.SELL_KEYS).map((sk) => atob(decodeURIComponent(sk)));
    };

    const isCurrentlyChangingCurrency = () => window.location.href.indexOf(CHANGING_CURRENCY_URL_ELEMENT) > -1;

    const getSellKeysAndBundlesUrlQueryParams = (sellKeys: string[], bundleCodes: string[]) => {
        const sellKeyParams = [sellKeys[0], sellKeys[1]]
            .filter((sk) => sk)
            .map((sk) => encodeURIComponent(btoa(sk)))
            .join(`&${URL_VARS.SELL_KEYS}=`);

        const bundleCodeParams = [bundleCodes[0], bundleCodes[1]].join(`&${URL_VARS.BUNDLE_CODES}=`);

        return `${URL_VARS.SELL_KEYS}=${sellKeyParams}&${URL_VARS.BUNDLE_CODES}=${bundleCodeParams}`;
    };

    const updateUrl = (data: {
        bundleState: FlightPageBundleState;
        flightState: FlightPageFlightState;
        dcState: DcState;
    }) => {
        if (!data.bundleState || !data.flightState || !data.dcState) return;

        const sellKeys = getSellKeysForQueryString(data.flightState).join("");
        const bundles = getBundleCodesForQueryString(data.bundleState).join("");

        const query =
            `${URL_VARS.CURRENCY}=${currency}` +
            sellKeys +
            bundles +
            `&${URL_VARS.PRICE_TYPE}=${data.flightState.selectedFeeType}` +
            `&${URL_VARS.SELECTED_MEMBERSHIP}=${data.dcState.selectedMembershipType || ""}`;
        const baseUrl = window.location.href.split("?")[0];
        history.replaceState({}, null, `${baseUrl}?${query}&${getCustomUrlParams()}`);
    };

    const removeCurrencyChangingMarkFromUrl = () => {
        const [baseUrl, queryString] = window.location.href.split("?");

        const newQuery = queryString
            .split("&")
            .filter(
                (elem) =>
                    elem !== CHANGING_CURRENCY_URL_ELEMENT &&
                    (!Array.isArray(elem.split("=")) || elem.split("=")[0] !== URL_VARS.CURRENCY_CHANGE_USER),
            )
            .join("&");

        const newUrl = newQuery ? `${baseUrl}?${newQuery}` : baseUrl;

        history.replaceState({}, null, newUrl);
    };

    const getPreselectedBundleCodes = () => {
        const pcCookie = getPromoCodeInCookie();

        if (pcCookie?.outboundBundleCode || pcCookie?.inboundBundleCode) {
            return [pcCookie.outboundBundleCode, pcCookie.inboundBundleCode];
        }

        const urlParams = new URLSearchParams(window.location.search);

        return urlParams.getAll(URL_VARS.BUNDLE_CODES);
    };

    const getPreselectedMembership = () => {
        const selectedMembershipFromUrl = getPreselectedMembershipFromUrl();
        const selectedMembershipFromModel = getPreselectedMembershipFromModel();

        if (!props.model.HasBooking) return selectedMembershipFromUrl;

        return selectedMembershipFromModel || selectedMembershipFromUrl;
    };

    const updatePromoCodeCookie = (flightState: FlightPageFlightState, bundleState: FlightPageBundleState) => {
        const currentFeeType = flightState?.selectedFeeType;
        const currentInboundBundleCode = bundleState?.selectedInboundBundle?.BundleCode;
        const currentInboundSellKey = flightState?.selectedInboundFlight?.SellKey;
        const currentOutboundBundleCode = bundleState?.selectedOutboundBundle?.BundleCode;
        const currentOutboundSellKey = flightState?.selectedOutboundFlight?.SellKey;

        const newCookie: PromoCodeFlightSelectionCookie = {
            bsid: flowContext.bsid,
            inboundBundleCode: currentInboundBundleCode,
            inboundFlightSellKey: currentInboundSellKey || "",
            outboundBundleCode: currentOutboundBundleCode,
            outboundFlightSellKey: currentOutboundSellKey || "",
            selectedFeeType: currentFeeType,
        };

        setFiveMinuteCookie(COOKIE_NAMES.PromoCodeFlightSelection, btoa(JSON.stringify(newCookie)));
    };

    useEffect(init, []);

    return {
        getPreselectedBundleCodes,
        getPreselectedFlightFeeType,
        getPreselectedMembership,
        getPreselectedSellKeys,
        getSellKeysAndBundlesUrlQueryParams,
        isCurrentlyChangingCurrency,
        removeCurrencyChangingMarkFromUrl,
        updatePromoCodeCookie,
        updateUrl,
    };
};

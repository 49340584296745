import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { useEffect, useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { BACKPACK_IMG, WITHOUT_CHECKED_BAGGAGE_IMG } from "../../spa-container";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useBaggageIllustrations } from "../common/useBaggageIllustrations";
import classNames from "classnames";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    pageState: BaggagePageState;
}

export const usePerBookingFreeOption = (props: Props) => {
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const { backpackTooltipTemplate } = useBaggageIllustrations({
        baggageSection: props.baggageSection,
        context: props.context,
    });

    const noBagSelected = useMemo(
        () =>
            !props.pageState.isLoading &&
            props.baggageSection.state.journeys.every((journey) =>
                journey.passengers.every(
                    (passenger) =>
                        !props.context.getContextJourneyPassengerBaggage({
                            bagType: props.baggageSection.bagType,
                            journeyIndex: journey.index,
                            passengerIndex: passenger.index,
                        }).quantity &&
                        (passenger.hasSelected ||
                            !props.baggageSection.perJourneyPerPax.isAddAvailable({
                                journeyIndex: journey.index,
                                passengerIndex: passenger.index,
                            })),
                ),
            ),
        [
            props.pageState.isLoading,
            props.baggageSection.bagType,
            props.baggageSection.perJourneyPerPax.isAddAvailable,
            props.baggageSection.state.journeys,
            props.context.getContextJourneyPassengerBaggage,
        ],
    );

    const paidBagSelected = useMemo(
        () =>
            !props.pageState.isLoading &&
            props.baggageSection.state.journeys.every((journey) =>
                journey.passengers.every(
                    (passenger) =>
                        props.context.getContextJourneyPassengerBaggage({
                            bagType: props.baggageSection.bagType,
                            journeyIndex: journey.index,
                            passengerIndex: passenger.index,
                        }).quantity > 0,
                ),
            ) &&
            props.baggageSection.state.perJourneyPerPaxState !== "open",
        [
            props.pageState.isLoading,
            props.baggageSection.bagType,
            props.baggageSection.state.journeys,
            props.baggageSection.state.perJourneyPerPaxState,
            props.context.getContextJourneyPassengerBaggage,
        ],
    );

    const startWarningCountdown = () => {
        if (!showWarning) window.setTimeout(() => setShowWarning(true), 3000);
    };

    // Event handlers

    const handleRemoveAll = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            props.baggageSection.state.perJourneyPerPaxState !== "open" &&
            props.baggageSection.perBooking.isRemoveAllAvailable
        ) {
            startWarningCountdown();
            props.baggageSection.handlers.reset();
        }
    };

    useEffect(() => {
        if (props.baggageSection.state.perJourneyPerPaxState === "open") setShowWarning(false);
    }, [props.baggageSection.state.perJourneyPerPaxState]);

    useEffect(() => {
        if (props.baggageSection.perBooking.quantity > 0) setShowWarning(false);
    }, [props.baggageSection.perBooking.quantity]);

    // Templates

    const warningTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage" && showWarning
            ? html`
                  <div class="b2-button-tooltip" data-test-id=${T.BAGGAGE.CABIN_BAG_WARNING}>
                      ${i18next.t(
                          "Si tu mochila o artículo personal pesa más de 10 kg o mide más de 35cm x 25cm x 45cm, deberás pagar una tarifa más alta que si compras tu equipaje online.",
                      )}
                  </div>
              `
            : "";

    const removeAllButtonTemplate = () =>
        !noBagSelected
            ? html`
                  <button
                      class="b2-secondary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_RESET_BUTTON, { c: props.baggageSection.bagType })}
                      .disabled=${!props.baggageSection.perBooking.isRemoveAllAvailable}
                      @click=${handleRemoveAll}
                  >
                      ${i18next.t("Baggage-RemoveAllPerBookingButtonText")}
                  </button>
              `
            : "";

    const noBagSelectedButtonTemplate = () =>
        noBagSelected ? html` <button disabled class="b2-secondary-button">${i18next.t("Agregado")}</button> ` : "";

    const cabinBagIllustrationTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <img
                      class="b2-free-cabin-img"
                      src=${BACKPACK_IMG}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, {
                          c: props.baggageSection.bagType,
                      })}
                  />
                  <div
                      class="b2-weight"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_WEIGHT, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      10kg.
                  </div>
                  <div
                      class="b2-dimensions"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      35cm &times; 25cm &times; 45cm ${backpackTooltipTemplate()}
                  </div>
                  <div
                      class="b2-bag-name"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_NAME, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      ${unsafeHTML(i18next.t("Mochila o artículo {{-newLine}} personal", { newLine: "<br />" }))}
                  </div>
              `
            : "";

    const checkedBagIllustrationTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <img
                      src=${WITHOUT_CHECKED_BAGGAGE_IMG}
                      class="b2-free-checked-img"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, {
                          c: props.baggageSection.bagType,
                      })}
                  />
                  <div class="b2-weight">&nbsp;</div>
                  <div
                      class="b2-bag-name bigger"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_NAME, {
                          c: props.baggageSection.bagType,
                      })}
                  >
                      ${i18next.t("No quiero llevar equipaje facturado")}
                  </div>
              `
            : "";

    const includedTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <div
                      class="b2-free-option-info"
                      data-test-id=${getTestId(T.BAGGAGE.IS_INCLUDED, { c: props.baggageSection.bagType })}
                  >
                      ${i18next.t("Incluido con tu tarifa")}
                  </div>
              `
            : "";

    const mainClassMap = classNames("b2-free-bag-option", {
        "selected": noBagSelected,
        "unselected": paidBagSelected,
        "per-pax": props.baggageSection.state.perJourneyPerPaxState === "open",
        "hoverable":
            props.baggageSection.state.perJourneyPerPaxState !== "open" &&
            !noBagSelected &&
            props.baggageSection.perBooking.isRemoveAllAvailable,
    });

    const buttonClassMap = classNames("b2-free-option-btn-container b2-openable", {
        "taller": props.baggageSection.bagType === "CheckedBaggage",
        "b2-opened": props.baggageSection.state.perJourneyPerPaxState !== "open",
    });

    const illustrationClassMap = classNames("b2-illustration", {
        taller: props.baggageSection.bagType === "CheckedBaggage",
    });

    const htmlTemplate = () => html`
        <div
            class=${mainClassMap}
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION, { c: props.baggageSection.bagType })}
            @click=${handleRemoveAll}
        >
            <div class=${illustrationClassMap}>
                ${cabinBagIllustrationTemplate()} ${checkedBagIllustrationTemplate()}
            </div>
            <div class=${buttonClassMap}>
                ${includedTemplate()} ${noBagSelectedButtonTemplate()} ${removeAllButtonTemplate()}
            </div>
            ${warningTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { useDcTermsTemplate } from "./useDcTermsTemplate";
import { useBenefitsTemplate } from "./useBenefitsTemplate";
import { useNoDiscountUrl } from "./useNoDiscountUrl";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface ExtendMembershipBannerProps {
    context: FlightPageContext;
}

export const useExtendMembershipBanner = (props: ExtendMembershipBannerProps) => {
    const [userContext] = useReduxState("userContext");

    const selectedOption = props.context?.bannerState?.selectedOption;
    const daysRemaining = props.context?.bannerState?.bannerVM?.membershipDaysRemaining;

    const terms = useDcTermsTemplate({ context: props.context });
    const benefits = useBenefitsTemplate({ vm: props.context.model.DiscountClubViewModel });
    const noDiscountUrl = useNoDiscountUrl({ context: props.context });

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const standardPrice = useMemo(
        () =>
            userContext?.isLoggedIn &&
            !isCurrentBancoEstadoCategory([0]) &&
            userContext.dc.standardPriceFormatted
                ? userContext.dc.standardPriceFormatted
                : props.context?.bannerState.bannerVM?.standardPrice,
        [userContext, props.context?.bannerState.bannerVM?.standardPrice],
    );

    const groupPrice = useMemo(
        () =>
            userContext?.isLoggedIn &&
            !isCurrentBancoEstadoCategory([0]) &&
            userContext.dc.groupPriceFormatted
                ? userContext.dc.groupPriceFormatted
                : props.context?.bannerState.bannerVM?.groupPrice,
        [userContext, props.context?.bannerState.bannerVM?.groupPrice],
    );

    const upgradePrice = useMemo(
        () =>
            userContext?.isLoggedIn &&
            !isCurrentBancoEstadoCategory([0]) &&
            userContext.dc.upgradePriceFormatted
                ? userContext.dc.upgradePriceFormatted
                : props.context?.bannerState.bannerVM?.upgradePrice,
        [userContext, props.context?.bannerState.bannerVM?.upgradePrice],
    );

    const priceText = () => {
        if (isCurrentBancoEstadoCategory([1])) {
            return i18next.t("Precio membresía grupal {{- groupAmount}}", { groupAmount: upgradePrice });
        }

        if (isCurrentBancoEstadoCategory([6])) {
            return i18next.t("Precio membresía grupal {{- groupAmount}}", { groupAmount: groupPrice });
        }

        return i18next.t("Precio membresía estándar {{- standardAmount}} / Precio membresía grupal {{- groupAmount}}", {
            standardAmount: standardPrice,
            groupAmount: groupPrice,
        });
    };

    const handleExtension = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedOption === "Extend") return;

        props.context.selectDcOption("Extend", terms.termsAccepted ? "Accepted" : "NotAccepted");
    };

    const bodyTemplate = () => {
        const tempClassMap = classNames("rounded-primary-btn dc-yellow", {
            selected: selectedOption === "Extend",
        });

        const buttonText = userContext?.dc.hasStandardMembership
            ? i18next.t("Renovar membresía estándar")
            : i18next.t("Renovar membresía grupal");

        return html`
            ${benefits.htmlTemplate()} ${terms.htmlTemplate()}
            <div class="extend-membership-description hidden-xs">* ${priceText()}</div>
            <div class="dc-membership-btn-container">
                <button class=${tempClassMap} @click=${handleExtension}>${buttonText}</button>
            </div>
            <div class="extend-membership-description hidden-sm-up">* ${priceText()}</div>
            ${noDiscountUrl.htmlTemplate()}
        `;
    };

    const headerTemplate = () => html`
        <i class="hidden-xs js-icon js-flight-plane"></i>
        <div class="dc-banner-content-header-text">
            ${unsafeHTML(
                i18next.t(
                    "¡Tu membresía expira en {{amount}} día{{plural}}! Renueva hoy tu membresía y continúa ahorrando por un año más",
                    { amount: daysRemaining, plural: daysRemaining !== 1 ? "s" : "" },
                ),
            )}
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="dc-banner-content">
            <div class="dc-banner-content-header bg-be-red-2">${headerTemplate()}</div>
            <div class="dc-banner-content-body">${bodyTemplate()}</div>
        </div>
    `;

    return { htmlTemplate };
};

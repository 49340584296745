import { html } from "haunted";

interface Props {
    customClass?: string;
    isOpen: boolean;
    onClose: () => void;
}

export const useProfileBancoeOverlay = (props: Props) => {
    // HELPERS

    // EVENT HANDLERS

    // COMPONENTS

    // TEMPLATES

    const htmlTemplate = () =>
        props.isOpen ? html` <div class=${props.customClass} @click=${props.onClose}></div> ` : html``;

    return { htmlTemplate };
};

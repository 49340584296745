import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const frequentFlyerHelper = () => {
    const frequentFlyerNumberTooltip = () => html`
        <ac-tooltip
            .icon=${"?"}
            .tooltip=${html`
                <div class="flex min-w-[200px] items-center" onclick="event.stopPropagation">
                    <div
                        class="mr-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-new-ui-blue pr-1 text-xl font-bold italic text-new-ui-blue"
                    >
                        i
                    </div>
                    <div class="text-left">
                        ${unsafeHTML(
                            i18next.t("FrequentFlyerNumberTooltip {{-link}} {{-reg}}", {
                                reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                                link: "<a class='!text-new-ui-blue underline' href='https://www.aa.com' target='_blank'>www.aa.com</a>",
                            }),
                        )}
                    </div>
                </div>
            `}
            onclick="event.stopPropagation();"
        ></ac-tooltip>
    `;

    return { frequentFlyerNumberTooltip };
};

import { useEffect } from "./../../shared/haunted/CustomHooks";
import {
    AGENCY_URL_PARTIAL_STRING,
    CHILEAN_CULTURE_CODE,
    COMPANY_URL_PARTIAL_STRING,
    LoginType,
    PERUVIAN_CULTURE_CODE,
    PERU_COMPRA_PARTIAL_STRING,
} from "./../../shared/commonConstants";
import { useState } from "../../shared/haunted/CustomHooks";
import { html, TemplateResult } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { getParsedProperty, showLoader } from "../../shared/common";
import CryptoJS from "crypto-js";
import { validateRut } from "../../shared/form-validation";
import { ROUTES } from "../../shared/apiRoutes";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useBancoEstadoLogin } from "./use-banco-estado-login";
import { ApiLoginPageViewModel } from "../../component-models/loginPage/ApiLoginPageViewModel";
import { useTealiumManager } from "../../managers/Tealium/useTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useFluentValidator } from "../../validator/FluentValidator";
import { DEFAULT_LOGIN_VM, LoginVM } from "./login-model";
import { Validation } from "../../validator/Validation";
import i18next from "i18next";
import { isPasswordLengthValid } from "../../validator/validation-helper";
import { createFormHtmlElement } from "../../component-helpers/FormHelper";

export const observedAttributes: (keyof Attributes)[] = ["extracted-anti-forgery-token", "model"];
export const name = "ac-login-page";

export interface Attributes {
    "extracted-anti-forgery-token": string;
    "model": string;
}

export interface Properties {
    extractedAntiForgeryToken: string;
    model: ApiLoginPageViewModel;
}

export interface ValidatorPartialMethods {
    isValid: (field: keyof LoginVM) => boolean;
    getMessage: (field: keyof LoginVM) => string | TemplateResult;
    getFormMessages: () => string | TemplateResult | (string | TemplateResult)[];
}

type FieldNames = keyof LoginVM;

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        extractedAntiForgeryToken: host.extractedAntiForgeryToken,
        model: getParsedProperty<ApiLoginPageViewModel>(host.model),
    };

    // HELPERS

    const init = () => {
        let type: LoginType = "JA";

        if (window.location.href.indexOf(PERU_COMPRA_PARTIAL_STRING) > -1) {
            type = "PC";
            setShowCustomerAndAgencyTabs(false);
        }

        if (window.location.href.indexOf(AGENCY_URL_PARTIAL_STRING) > -1) {
            type = "AGENCY";
            setShowPeruCompraTab(false);
        }

        if (window.location.href.indexOf(COMPANY_URL_PARTIAL_STRING) > -1) {
            type = "COMPANY";
            setShowPeruCompraTab(false);
        }

        if (
            isBancoeUrlParameterProvided() &&
            appContext.isFeatureSwitchActive("BancoEstado") &&
            appContext.Culture === CHILEAN_CULTURE_CODE
        ) {
            type = "BE";
            setShowCustomerAndAgencyTabs(false);
            setShowPeruCompraTab(false);
        }

        setLoginType(type);
    };

    const peruCompraTabInitialState = () =>
        appContext.Culture?.toLowerCase() === PERUVIAN_CULTURE_CODE &&
        appContext.isFeatureSwitchActive("PeruCompraInlineLogin");

    const isBancoeUrlParameterProvided = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const bancoeUrlParam = urlParams.get("bancoe");

        return bancoeUrlParam === "1";
    };

    const showBancoEstado = () =>
        appContext.isFeatureSwitchActive("BancoEstado") && appContext.Culture === CHILEAN_CULTURE_CODE;

    const submitForm = async () => {
        const isValid = await validator.validate();

        if (isValid) {
            const bodyToPost = {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.extractedAntiForgeryToken,
                loginType,
                username: vm.username,
                password: vm.password,
                redirect: "true",
            };

            showLoader({});
            const formToPost = createFormHtmlElement(ROUTES.PageLogin, bodyToPost);
            formToPost.submit();
        }
    };

    const handleBancoEstadoLogin = async () => {
        if (acceptedRut) {
            submitForm();
        } else {
            const errors: string[] = [];
            let isValid = await validator.validate();

            const captchaResponse =
                appContext.isFeatureSwitchActive("Captcha") && bancoEstadoLogin.captchaId !== undefined
                    ? window.grecaptcha.getResponse(bancoEstadoLogin.captchaId)
                    : undefined;

            if (appContext.isFeatureSwitchActive("Captcha") && bancoEstadoLogin.captchaId !== undefined) {
                if (captchaResponse.length === 0) {
                    bancoEstadoLogin.setShowCaptchaBackendError(true);
                    isValid = false;
                    errors.push("[captcha not validated]");
                } else {
                    bancoEstadoLogin.setShowCaptchaBackendError(false);
                }
            }

            if (isValid) {
                const loader = showLoader({});
                let doesUserExist = false;
                let invalidCaptcha = false;

                const key = CryptoJS.enc.Utf8.parse(props.model.AesKey);
                const iv = CryptoJS.enc.Utf8.parse(props.model.AesIv);

                const aesRut = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(vm.username), key, {
                    keySize: 128 / 8,
                    iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                });

                await ajaxRequest({
                    loader,
                    method: "GET",
                    url: `${ROUTES.ApiRoutes.CheckBeUserExist}?rut=${aesRut.toString()}${
                        captchaResponse ? `&g-recaptcha-response=${captchaResponse}` : ""
                    }`,
                    onResponseCode: {
                        200: (result) => {
                            doesUserExist = JSON.parse(result).IsUserExist;
                        },
                        400: () => {
                            bancoEstadoLogin.setShowCaptchaBackendError(true);
                            invalidCaptcha = true;
                        },
                    },
                });

                if (doesUserExist) {
                    setAcceptedRut(vm.username);
                } else if (!invalidCaptcha) {
                    await ajaxRequest({
                        loader: showLoader({}),
                        method: "GET",
                        url: `${ROUTES.LoginBancoEstado}?rut=${vm.username}`,
                        onResponseCode: {
                            200: (result) => {
                                const redirectUrl = JSON.parse(result).BaseUrl;
                                const loginEndPoint = JSON.parse(result).LoginEndpoint;
                                const jwt = JSON.parse(result).JWT;

                                if (redirectUrl) {
                                    window.location.href = `${redirectUrl}${loginEndPoint}${jwt}`;
                                }
                            },
                        },
                    });
                }
            } else {
                setAcceptedRut(undefined);
            }

            if (errors.length > 0) {
                tealiumManager.logValidationError(errors);
            }
        }
    };

    // EVENT LISTENERS

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);
        const isValid = await validator.validate();

        if (!isValid) {
            return;
        }

        bancoEstadoLogin.setShowCaptchaError(false);

        if (loginType === "BE") {
            handleBancoEstadoLogin();
        } else {
            submitForm();
        }
    };

    // COMPONENT

    const tealiumManager = useTealiumManager();
    const appContext = useAppContext();

    const { ajaxRequest } = useAjax();

    const [loginType, setLoginType] = useState<LoginType>(undefined);
    const [showCustomerAndAgencyTabs, setShowCustomerAndAgencyTabs] = useState<boolean>(true);
    const [showPeruCompraTab, setShowPeruCompraTab] = useState<boolean>(peruCompraTabInitialState());
    const [acceptedRut, setAcceptedRut] = useState<string>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<LoginVM>(DEFAULT_LOGIN_VM);

    const validator = useFluentValidator<FieldNames, LoginVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("username", (vm: LoginVM) => vm.username).isRequired(),
            Validation.ruleFor("username", (vm: LoginVM) => vm.username)
                .when((_) => loginType === "BE")
                .isRequired()
                .fulfils(async (username: string) => username && validateRut(username), i18next.t("BE-RutFormatError")),
            Validation.ruleFor("password", (vm: LoginVM) => vm.password)
                .when((_) => loginType !== "BE" || acceptedRut !== undefined)
                .isRequired()
                .fulfils(
                    async (password: string) =>
                        !(
                            (acceptedRut || loginType === "JA" || loginType === "PC") &&
                            !isPasswordLengthValid(password)
                        ),
                    i18next.t("La contraseña debe incluir entre 8 y 16 caracteres y, debe contener letras y números."),
                ),
        ],
    });

    const validatorPartials: ValidatorPartialMethods = {
        isValid: (field: keyof LoginVM) => validator.isValid(field),
        getMessage: (field: keyof LoginVM) => validator.getMessage(field),
        getFormMessages: () => validator.getFormMessages(),
    };

    useEffect(() => {
        setVm(DEFAULT_LOGIN_VM);
        setIsValidated(false);
    }, [loginType]);

    useEffect(init, []);

    // TEMPLATES

    const bancoEstadoTemplate = () => (loginType === "BE" ? bancoEstadoLogin.htmlTemplate : "");

    const peruCompraTemplate = () =>
        loginType === "PC"
            ? html`
                  <ac-peru-compra-login
                      .vm=${vm}
                      .validatorPartialMethods=${validatorPartials}
                      .setVm=${setVm}
                      .setLoginType=${setLoginType}
                      .handleSubmit=${handleSubmit}
                  ></ac-peru-compra-login>
              `
            : "";

    const customerTemplate = () =>
        loginType === "JA"
            ? html`
                  <ac-customer-login
                      .loginType=${loginType}
                      .vm=${vm}
                      .validatorPartialMethods=${validatorPartials}
                      .showBancoEstado=${showBancoEstado()}
                      .showPeruCompraTab=${showPeruCompraTab}
                      .setLoginType=${setLoginType}
                      .setVm=${setVm}
                      .handleSubmit=${handleSubmit}
                  ></ac-customer-login>
              `
            : "";

    const bancoEstadoLogin = useBancoEstadoLogin({
        vm,
        validatorPartialMethods: validatorPartials,
        acceptedRut,
        gRecaptchaSiteKey: props.model.GRecaptchaSiteKey,
        loginType,
        showCustomerAndAgencyTabs,
        showPeruCompraTab,
        setVm: (vm: LoginVM) => setVm(vm),
        handleSubmit,
        setLoginType,
    });

    return html`
        <div class="ts-error-parent">${bancoEstadoTemplate()} ${customerTemplate()} ${peruCompraTemplate()}</div>
    `;
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { ProfileTodosumaWidgetViewModel } from "../../component-models/profile/ProfileTodosumaWidgetViewModel";
import { classMap } from "lit-html/directives/class-map";
import { useProfileBancoeOverlay } from "./useProfileBancoeOverlay";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    model: ProfileTodosumaWidgetViewModel;
}

export const useProfileTodosumaWidget = (props: Props) => {
    const scrollerClassName = "tswc-scroller";

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onClose = () => setIsOpen(false);

    const bancoeOverlay = useProfileBancoeOverlay({
        isOpen,
        onClose,
    });

    useEffect(() => {
        ScrollHelper.addPerfectScrollbar(scrollerClassName);
    }, []);

    const points = userContext.bancoEstado.todosumaPoints || 0;

    const amount = formatNumber({ amount: points, leadingSign: true });

    const todosumaPointsTemplate = () =>
        points > 0
            ? html`<div class="tswc-container ${isOpen ? "open" : ""}">
                      <div class="tswc-opener">
                          <div class="flex flex-col items-start justify-center">
                              <span class="tswc-info">
                                  ${i18next.t("Tienes {{amount}} en Puntos TodoSuma", {
                                      amount,
                                  })}
                              </span>
                              <span class="tswc-date">
                                  ${i18next.t("Última actualización: {{- date}}", {
                                      date: props.model.Date,
                                  })}
                              </span>
                          </div>
                      </div>
                      <div class="tswc-table-header">
                          <span>${i18next.t("Fecha")}</span>
                          <span>${i18next.t("Monto (1,25 x Puntos)")}</span>
                          <span>${i18next.t("Estado")}</span>
                      </div>
                      <ul class="${scrollerClassName}">
                          <li>
                              <span>12-24-2021</span>
                              <span>$60.000</span>
                              <span>Activo</span>
                          </li>
                          <li>
                              <span>02-01-2021</span>
                              <span>$30.000</span>
                              <span>Promoción B</span>
                          </li>
                          <li>
                              <span>11-12-2020</span>
                              <span>$39.000</span>
                              <span>Promoción A</span>
                          </li>
                          <li>
                              <span>24-11-2020</span>
                              <span>$40.000</span>
                              <span>Promoción D</span>
                          </li>
                          <li>
                              <span>12-24-2021</span>
                              <span>$60.000</span>
                              <span>Promoción C</span>
                          </li>
                          <li>
                              <span>02-01-2021</span>
                              <span>$30.000</span>
                              <span>Promoción B</span>
                          </li>
                          <li>
                              <span>11-12-2020</span>
                              <span>$39.000</span>
                              <span>Promoción A</span>
                          </li>
                          <li>
                              <span>24-11-2020</span>
                              <span>$40.000</span>
                              <span>Promoción D</span>
                          </li>
                      </ul>
                  </div>
                  <div class="twsc-container">
                      <div class="tswc-opener">
                          <div class="tswc-slogan">
                              <i class="js-icon-mp js-mp-percent-label"></i>
                              ${i18next.t("¡Acumula y Canjea tus Puntos en JetSMART!")}
                          </div>
                      </div>
                  </div>`
            : html`<div
                  class=".border-b .border-solid .border-be-gray-9 relative flex h-16 w-full items-center justify-center overflow-hidden font-semibold text-be-blue"
              >
                  ${i18next.t("Puntos TodoSuma no disponibles")}
              </div>`;

    const mainClassMap = classMap({
        "todosuma-widget-container": true,
        "open": isOpen,
    });

    const htmlTemplate = () => html`
        <div class="profile-widget profile-todosuma-widget">
            <div class=${mainClassMap}>
                <div class="todosuma-widget-content">
                    <div class="tswc-header">${i18next.t("Puntos TodoSuma")}</div>
                    ${todosumaPointsTemplate()}
                </div>
            </div>
        </div>
        ${bancoeOverlay.htmlTemplate()}
    `;

    return { htmlTemplate, isOpen };
};

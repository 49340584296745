import i18next from "i18next";
import { html } from "haunted";
import { ROUTES } from "../../shared/apiRoutes";
import { useModal } from "../shared/useModal";
import { useAppContext } from "../../managers/useAppContext";

export const useGiftcardSessionErrorModal = () => {
    const appContext = useAppContext();

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <span>${i18next.t("V2-GiftcardSessionErrorModal-Description1")}</span>
                <a target="_blank" href=${appContext.CorporateBaseUrl}>
                    ${i18next.t("V2-GiftcardSessionErrorModal-DescriptionLink")}
                </a>
                <span>${i18next.t("V2-GiftcardSessionErrorModal-Description2")}</span>
            </div>
            <div class="primary-modal-button-container">
                <a class="rounded-primary-btn" href=${ROUTES.GiftcardSelect}>
                    ${i18next.t("V2-GiftcardSessionErrorModal-Ok")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => html`<span>${i18next.t("V2-GiftcardSessionErrorModal-Important")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

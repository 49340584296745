import { ROUTES } from "../../../../shared/apiRoutes";
import i18next from "i18next";
import { html } from "lit-html";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useModal } from "../../../shared/useModal";

export const useSeatSelectionProblemModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="mb-8 font-body leading-normal text-be-blue">
                ${i18next.t(
                    "No hemos podido realizar tu check-in online, por favor el día de tu vuelo dirígete al counter de aeropuerto para realizar tu check-in sin costo adicional.",
                )}
            </div>
            <div class="flex justify-center">
                <button
                    class="rounded-primary-btn"
                    data-test-id=${T.SEATMAP.NO_AUTO_ASSIGNED_SEATS_MODAL_OK_BUTTON}
                    @click=${() => (window.location.href = ROUTES.PageItinerary)}
                >
                    ${i18next.t("Ok")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: {
            classNames: "booking-modal-content",
            testId: T.SEATMAP.NO_AUTO_ASSIGNED_SEATS_MODAL,
            template: htmlTemplate,
        },
        header: { classNames: "text-center", template: () => i18next.t("Importante") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { amplitudeDontForceDcBannerSelection } from "./../../shared/aBTesting/amplitudeHandler";
import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import {
    COOKIE_NAMES,
    DEFAULT_DATE_FORMAT,
    COLOMBIA_COUNTRY_CODE,
    COLOMBIAN_CULTURE_CODE,
    BE_BUNDLE_0_CODE,
    BE_DC_BUNDLE_0_CODE,
    DC_BUNDLE_0_CODE,
    SMART_BUNDLE_0_CODE,
    PERUCOMPRA_BUNDLE_0_CODE,
    CHILECOMPRA_BUNDLE_0_CODE,
} from "./../../shared/commonConstants";
import { useMemo, useState } from "./../../shared/haunted/CustomHooks";
import { useEffect, useRef, useEffect as hauntedUseEffect } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import BookingFlowHandler from "../../shared/BookingFlowHandler";
import { BookingSteps } from "../../shared/BookingSteps";
import { CLASS_NAMES } from "../../shared/classNames";
import { showLoader, getAntiforgerySegment, redoSearch, getAntiForgeryTokenFromHtml } from "../../shared/common";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { raiseBookingFlowContinueEvent } from "../../shared/eventbus/raiseBookingFlowContinueEvent";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { FlightHelper, flightOnlyInCountries } from "../../component-helpers/flight/FlightHelper";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { setFiveMinuteCookie, setSessionCookie } from "../../shared/cookieHandling";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import BookingData from "../../shared/BookingData";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { useFlightTealiumManager } from "../../managers/Tealium/useFlightTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useFlightJourney } from "./useFlightJourney";
import { useBundleSelectedPerLeg } from "./useBundleSelectedPerLeg";
import { useDiscountClubBanner } from "./dc-banner/useDiscountClubBanner";
import { useDcFeeForm } from "./useDcFeeForm";
import { useAirportWarning } from "./useAirportWarning";
import { useFarelock } from "./useFarelock";
import { useFlightPageContext } from "./contexts/useFlightPageContext";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { useFlightContinueButton } from "./useFlightContinueButton";
import { useFlightLoginModal } from "./useFlightLoginModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useInfantLimitationModal } from "./useInfantLimitationModal";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { mapToFlightPageViewModel } from "../../component-mappers/FlightPagePropsMapper";
import { spaSectionOrderFactory } from "../spa/spaSectionOrderFactory";
import { FlowType } from "../../managers/useFlowContext";
dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);
dayjs.extend(CustomParseFormat);

export const name = "ac-flight-page";

export type SelectedFlightFeeType = "Smart" | "Club";
export type BundlesMode = "PerLeg" | "Legacy";

export const defaultScrollTimeOffsetA = 600;

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: FlightPageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = { antiForgeryToken: host.antiForgeryToken, model: mapToFlightPageViewModel(host.model) };

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const userContextManager = useUserContextBuilder();

    const { triggers } = usePubSub();
    const bookingDataManager = useBookingDataManager();
    const tealiumManager = useFlightTealiumManager();
    const { ajaxRequest } = useAjax();
    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const [userContext] = useReduxState("userContext");
    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [currency] = useReduxState("booking.currency");

    const rootElement = useRef<HTMLFormElement>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(undefined);

    const context = useFlightPageContext({
        container: rootElement.current,
        model: props.model,
        scrollToButton: () => ScrollHelper.scrollToElementAndHideNav({ element: submitButton.buttonElem }),
        setIsLoading,
    });

    const dcFeeForm = useDcFeeForm({ antiForgeryToken: props.antiForgeryToken, context });

    const airportWarning = useAirportWarning({ context });

    const flightLoginModal = useFlightLoginModal({ onLogin: context.handleLogin });

    const infantLimitationModal = useInfantLimitationModal();

    const discountClubBanner = useDiscountClubBanner({
        context,
        isLoading,
        onLogin: () => flightLoginModal.open(),
    });

    const outboundJourney = useFlightJourney({
        context,
        inboundDate: props.model.FlightDataViewModel.InboundDate
            ? dayjs(props.model.FlightDataViewModel.UnformattedInboundDate, DEFAULT_DATE_FORMAT)
            : undefined,
        isValidated,
        outboundDate: dayjs(props.model.FlightDataViewModel.UnformattedOutboundDate, DEFAULT_DATE_FORMAT),
        stationSettings: props.model.FlightDataViewModel.StationSettings,
        journeyIndex: OUTBOUND,
        onSelect: context?.selectFlight,
    });

    const inboundJourney = useFlightJourney({
        context,
        inboundDate: props.model.FlightDataViewModel.InboundDate
            ? dayjs(props.model.FlightDataViewModel.UnformattedInboundDate, DEFAULT_DATE_FORMAT)
            : undefined,
        isValidated,
        outboundDate: dayjs(props.model.FlightDataViewModel.UnformattedOutboundDate, DEFAULT_DATE_FORMAT),
        stationSettings: props.model.FlightDataViewModel.StationSettings,
        journeyIndex: INBOUND,
        onSelect: context?.selectFlight,
    });

    const selectedOutboundBundle = useBundleSelectedPerLeg({
        context,
        journeyIndex: OUTBOUND,
        stationSettings: props.model.FlightDataViewModel.StationSettings,
        onReset: () => context?.resetFlight(OUTBOUND, outboundJourney.rootElem.current),
    });

    const selectedInboundBundle = useBundleSelectedPerLeg({
        context,
        journeyIndex: INBOUND,
        stationSettings: props.model.FlightDataViewModel.StationSettings,
        onReset: () => context?.resetFlight(INBOUND, inboundJourney.rootElem.current),
    });

    const farelock = useFarelock({ context, isBannerLoading: discountClubBanner.isLoading });

    const isDomesticColombiaFlight = useMemo(
        () => flightOnlyInCountries(props.model, [COLOMBIA_COUNTRY_CODE]),
        [props.model.FlightDataViewModel.Journeys],
    );

    // HELPERS

    const init = async (): Promise<any> => {
        userContextManager.init(props.model);
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        if (shouldRedoSearchOnPageLoad()) {
            redoSearchOnFlight();
            return;
        }

        setFiveMinuteCookie(COOKIE_NAMES.PromoCodeFlightSelection, "");

        BookingFlowHandler.storeCurrentStep(BookingSteps.FlightSelect);

        if (props.model.PromoCodeErrorMessage) {
            window.setTimeout(
                () =>
                    triggers.flight.showInvalidPromoCodeModal.publish({
                        isValid: false,
                        invalidPromoCodeMessage: props.model.PromoCodeErrorMessage,
                        shouldReloadSidebar: true,
                    }),
                0,
            );
        }
    };

    const bundleCodeToPost = (bundleCode: string) =>
        bundleCode &&
        props.model.BundleViewModel.IsBundlesActive &&
        context.bundleState &&
        ![
            SMART_BUNDLE_0_CODE,
            DC_BUNDLE_0_CODE,
            BE_DC_BUNDLE_0_CODE,
            BE_BUNDLE_0_CODE,
            PERUCOMPRA_BUNDLE_0_CODE,
            CHILECOMPRA_BUNDLE_0_CODE,
        ]?.includes(bundleCode)
            ? bundleCode
            : "";

    const submitForm = async () => {
        const body = {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
            "availability.BundleCodes[0]": bundleCodeToPost(context?.bundleState?.selectedOutboundBundle?.BundleCode),
            "availability.BundleCodes[1]": bundleCodeToPost(context?.bundleState?.selectedInboundBundle?.BundleCode),
            "availability.MarketFareKeys[0]": context?.flightState?.selectedOutboundFlight?.SellKey || null,
            "availability.MarketFareKeys[1]": context?.flightState?.selectedInboundFlight?.SellKey || null,
            "jetSmartPassengerDiscount.ApplyPaxDiscount": context?.dcState?.applyDiscount,
        };

        await tealiumManager.logContinueClicked(context);

        const result = await ajaxRequest({ url: ROUTES.PageFlight, body });

        if (result.redirectionUrl) {
            window.location.href = result.redirectionUrl;
            return;
        }

        const flowType: FlowType =
            context.farelockState.selectedType === "Duration24" || context.farelockState.selectedType === "Duration48"
                ? "FarelockRoundOne"
                : "Booking";

        const order = getSpaSectionOrder({
            flowType,
            isStaff: userContext.isStaff,
        });
        const firstPageIndex = Math.min(...order.map((x) => x.index));

        window.location.href = `/${order.find((o) => o.index === firstPageIndex).section.uri}`;
    };

    const shouldRedoSearchOnPageLoad = () => {
        // DEVNOTE JET-6084 After the user has a booking and goes back to the flight page
        // we should create a new booking to handle BancoE free seats, ssrs, etc.

        // DEVNOTE JET-6505 But not if there is a backend error rendered

        const backendErrorContainer = DomCrawlingHelper.getElemByClass(
            document.body,
            CLASS_NAMES.BackendErrorsContanier,
        );

        return props.model.HasBooking && !backendErrorContainer;
    };

    const redoSearchOnFlight = (promoCode = bookingContext.promoCode) => {
        setSessionCookie(COOKIE_NAMES.FreeSeatsUsedInBooking, "");
        showLoader({ name: CLASS_NAMES.sidebarLoader, noPlane: true });
        redoSearch({
            adults: props.model.FlightDataViewModel.AdultNumber.toString(),
            children: props.model.FlightDataViewModel.ChildNumber.toString(),
            culture: appContext.Culture,
            currency,
            destination: props.model.FlightDataViewModel.Destination,
            infants: props.model.FlightDataViewModel.InfantNumber.toString(),
            isOneWay: props.model.FlightDataViewModel.IsOneWay,
            origin: props.model.FlightDataViewModel.Origin,
            promoCode,
            unformattedInboundDate: props.model.FlightDataViewModel.UnformattedInboundDate,
            unformattedOutboundDate: props.model.FlightDataViewModel.UnformattedOutboundDate,
        });
    };

    const showUpgradeWarning = () =>
        props.model.DiscountClubViewModel.ShowUpgradeMembershipInfo &&
        [0, 1, 2, 3, 4, 7].indexOf(userContext?.bancoEstado.category) > -1;

    const isFormValid = () =>
        (context.areAllBundlesSelected || context.bundleState?.bundlesMode === "Legacy") &&
        context.areAllFlightsSelected;

    const showDomesticColombiaChildDiscountInformation = () =>
        appContext.Culture === COLOMBIAN_CULTURE_CODE &&
        isDomesticColombiaFlight &&
        props.model.FlightDataViewModel.ChildNumber >= 1 &&
        context.areAllBundlesSelected;

    const validateInfantNumber = (journeyIndex: number) => {
        const selectedFlight =
            journeyIndex === OUTBOUND
                ? context?.flightState?.selectedOutboundFlight
                : context?.flightState?.selectedInboundFlight;
        const isValid = !selectedFlight || selectedFlight.HasEnoughSeatsForInfants;

        if (!isValid) {
            infantLimitationModal.open();
            context.resetFlight(
                journeyIndex,
                journeyIndex === OUTBOUND ? outboundJourney.rootElem.current : inboundJourney.rootElem.current,
            );
        }

        return isValid;
    };

    const subscribeToPromoCodeEntered = () => {
        const handler = triggers.sidebar.promoCodeEntered.subscribe(redoSearchOnFlight);

        return () => handler.unsubscribe();
    };

    // EVENT LISTENERS

    const handleSubmit = async () => {
        setFiveMinuteCookie(COOKIE_NAMES.PromoCodeFlightSelection, "");

        setIsValidated(true);

        let isValid = isFormValid();

        if (
            context?.bannerState.bannerVM &&
            !context?.bannerState.selectedOption &&
            !amplitudeDontForceDcBannerSelection(appContext.AbTestSettings, appContext.variants)
        ) {
            isValid = false;
            discountClubBanner.showError();
        }

        if (context?.bannerState.termsAcceptance === "NotAccepted") isValid = false;

        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            if (bookingData.PromotionCode) FlightHelper.raisePromoCodeAddedEvent(bookingData);
        });

        if (!isValid) {
            ScrollHelper.scrollToFirstFlightPageError();
            raiseBookingFlowContinueEvent(false, [`.${CLASS_NAMES.flightSelectError}`]);

            return;
        }

        const tooManyInfantsOutbound = !validateInfantNumber(OUTBOUND);
        const tooManyInfantsInbound = !validateInfantNumber(INBOUND);

        if (tooManyInfantsOutbound || tooManyInfantsInbound) return;

        showLoader({});

        if (context?.dcState.selectedMembershipType && !context?.dcState.hasMembershipOnBooking) {
            await dcFeeForm.submit();
        }

        raiseBookingFlowContinueEvent(true);

        submitForm();
    };

    // EFFECTS

    useEffect(
        () => setIsValidated(false),
        [
            context?.bundleState?.selectedOutboundBundle?.BundleCode,
            context?.bundleState?.selectedInboundBundle?.BundleCode,
            context?.flightState?.selectedOutboundFlight?.SellKey,
            context?.flightState?.selectedInboundFlight?.SellKey,
            context?.flightState?.selectedFeeType,
            context?.dcState?.selectedMembershipType,
        ],
    );

    hauntedUseEffect(subscribeToPromoCodeEntered, []);

    useEffect(init, []);

    const submitButton = useFlightContinueButton({
        dataTestId: T.FLIGHT.CONTINUE_BUTTON,
        onClick: handleSubmit,
    });

    // TEMPLATES

    const selectedAllBundlesTemplate = () =>
        context.bundleState?.bundlesMode === "PerLeg" && context.areAllBundlesSelected && context.areAllFlightsSelected
            ? html`
                  <div class="mx-auto w-[1005px] max-w-[90%] sm:max-w-[95%]">
                      <div class="row">
                          <div class="col-xs-1 col-sm-1-2">${selectedOutboundBundle.htmlTemplate()}</div>
                          ${!props.model.FlightDataViewModel.IsOneWay
                              ? html` <div class="col-xs-1 col-sm-1-2">${selectedInboundBundle.htmlTemplate()}</div> `
                              : ""}
                      </div>
                  </div>
              `
            : "";

    const upgradeWarningTemplate = () =>
        showUpgradeWarning()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div
                              class="upgrade-warning mx-auto mb-10 mt-5 w-[90%] max-w-full text-sm leading-snug sm:w-[1005px] md:w-full lg:w-[1005px]"
                          >
                              ${i18next.t("V2DC-UpgradeWarning")}
                          </div>
                      </div>
                  </div>
              `
            : "";

    const domesticColombiaChildDiscountInformationTemplate = () =>
        showDomesticColombiaChildDiscountInformation()
            ? html`
                  <div class="text-center">
                      <span>
                          ${i18next.t(
                              "Con base en la legislación colombiana, para vuelos nacionales, el descuento en la tarifa sólo aplica para los menores de 12 años.",
                          )}
                      </span>
                  </div>
              `
            : "";

    return context
        ? html`
              <div class="select-none" data-test-id=${T.FLIGHT.CONTAINER}>
                  <form action=${ROUTES.PageFlight} method="POST" ref=${ref(rootElement)}>
                      ${getAntiforgerySegment(props.antiForgeryToken)}

                      <div class="ts-error-parent ts-flight-error-parent">
                          <section
                              class="${LOADER_CLASS_NAMES.CommonLoaderWrapper} no-bottom-margin ts-error-container"
                          >
                              ${outboundJourney.htmlTemplate()} ${inboundJourney.htmlTemplate()}
                              ${selectedAllBundlesTemplate()}
                          </section>
                      </div>

                      ${upgradeWarningTemplate()} ${airportWarning.htmlTemplate()} ${discountClubBanner.htmlTemplate()}
                      ${farelock.htmlTemplate()} ${domesticColombiaChildDiscountInformationTemplate()}
                      ${context.farelockState.selectorState !== "Open" ? submitButton.htmlTemplate() : ""}
                  </form>

                  ${dcFeeForm.htmlTemplate()}
              </div>
              ${flightLoginModal.htmlTemplate()} ${infantLimitationModal.htmlTemplate()}
          `
        : html``;
};

import { html, TemplateResult } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useLayoutEffect, useRef } from "haunted";
import { ref } from "../../../directives/ref";
import classNames from "classnames";
import { useLabel } from "../label/useLabel";
import { useErrorMessage } from "../error-message/useErrorMessage";

export const name = "ac-input";

export interface Props {
    autoComplete: string;
    customClasses: string[];
    isLabelSmaller: boolean;
    errorMessage: string;
    getFocusOnRender: boolean;
    isBancoEstado: boolean;
    isDisabled: boolean;
    isInvalid: boolean;
    isReadonly: boolean;
    label: string | TemplateResult;
    name: string;
    placeholder: string;
    tabIndexAttr: string;
    testId: string;
    type: "text" | "password";
    value: string;
    maxLength: string;
    onBlur?: (e: FocusEvent) => void;
    onInput: (value: string) => void;
    onKeyUp?: (e: KeyboardEvent) => void;
    sanitizer: (e: KeyboardEvent) => string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        autoComplete: host.autoComplete || "off",
        customClasses: host.customClasses,
        isLabelSmaller: host.isLabelSmaller,
        errorMessage: host.errorMessage,
        getFocusOnRender: host.getFocusOnRender,
        isBancoEstado: host.isBancoEstado,
        isDisabled: host.isDisabled,
        isInvalid: host.isInvalid,
        isReadonly: host.isReadonly,
        label: host.label,
        name: host.name || "",
        placeholder: host.placeholder || "",
        tabIndexAttr: host.tabIndexAttr || "",
        testId: host.testId || "",
        type: host.type || "text",
        value: host.value || "",
        maxLength: host.maxLength || "",
        onBlur: host.onBlur,
        onInput: host.onInput,
        onKeyUp: host.onKeyUp,
        sanitizer: host.sanitizer,
    };

    // FIXME Feel free to do this nicer - this is to notify the input that it was autofilled
    // DEVNOTE Chrome does not trigger any event when autofilling an input
    const shouldHandleAutofill = () => {
        if (!inputElem.current) return false;

        const currentBackgroundColor = getComputedStyle(inputElem.current).backgroundColor;
        const nonAutofilledBackgrounds = ["rgb(255, 255, 255)", "rgb(249, 212, 216)"];

        return !nonAutofilledBackgrounds.includes(currentBackgroundColor) && inputElem.current?.value !== props.value;
    };

    const addFocusOnRender = () => {
        if (props.getFocusOnRender) window.setTimeout(() => inputElem.current?.focus(), 100);
    };

    const handleInput = (e: KeyboardEvent) => {
        inputElem.current.value = typeof props.sanitizer === "function" ? props.sanitizer(e) : inputElem.current?.value;
        props.onInput(inputElem.current?.value);
    };

    const handleKeyup = (e: KeyboardEvent) => {
        if (props.onKeyUp) props.onKeyUp(e);
    };

    const handleChromeAutofill = () => {
        if (wasAutofilled.current) return;

        if (shouldHandleAutofill()) {
            wasAutofilled.current = true;
            props.onInput(inputElem.current?.value);
        }
    };

    const inputElem = useRef<HTMLInputElement>(undefined);
    const wasAutofilled = useRef<boolean>(false);

    const label = useLabel({
        isDirty: Boolean(inputElem.current?.value) || Boolean(props.placeholder),
        isDisabled: props.isDisabled,
        isReadOnly: props.isReadonly,
        label: props.label,
        isSmaller: props.isLabelSmaller,
    });

    const error = useErrorMessage({ errorMessage: props.errorMessage });

    useLayoutEffect(() => {
        inputElem.current.value = props.value;
    }, [props.value]);

    useLayoutEffect(addFocusOnRender, []);

    useLayoutEffect(handleChromeAutofill);

    return html`
        <div class="relative w-full leading-none">
            <input
                autocomplete=${props.autoComplete}
                class=${classNames(props.customClasses, {
                    "peer m-0 block h-[52px] w-full appearance-none rounded-[5px] border border-solid pb-[3px] pl-[14px] pr-[4px] text-left text-[20px] leading-[52px] text-brand-secondary common-transition":
                        !props.customClasses,
                    "placeholder:text-[14px] placeholder:text-[#818a91]": !props.customClasses,
                    "focus:border-be-cyan focus:text-brand-secondary": !props.customClasses,
                    "disabled": props.isDisabled,
                    "pt-[21px]": props.label,
                    "pt-[5px]": !props.label,
                    "border-brand-secondary bg-white": !props.isInvalid && !props.isBancoEstado,
                    "border-be-orange bg-white": !props.isInvalid && props.isBancoEstado,
                    "border-[#e299a1] bg-[#f9d4d8]": props.isInvalid,
                    "opacity-50": props.isReadonly,
                })}
                data-test-id=${props.testId}
                name=${host.name}
                placeholder=${props.placeholder}
                ?readonly=${props.isReadonly}
                ref=${ref(inputElem)}
                tabindex=${props.tabIndexAttr}
                type=${props.type}
                value=${props.value}
                maxlength=${props.maxLength}
                @blur=${props.onBlur}
                @input=${handleInput}
                @keyup=${handleKeyup}
            />
            ${label.htmlTemplate()} ${error.htmlTemplate()}
        </div>
    `;
};

/* eslint-disable complexity */
import { html, TemplateResult } from "lit-html";

import * as DC from "./dc-components";

import {
    AppliedFilter,
    DateFilterDescriptor,
    FilterProperty,
    NumberFilterDescriptor,
    NumberInput,
    SelectFilterDescriptor,
    SortOrder,
    TextFilterDescriptor,
} from "./dc-table-models";
import { useEffect } from "haunted";
import { ClickEvent } from "./dc-step-progressbar";
import { useState } from "../shared/haunted/CustomHooks";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { isEmpty } from "../component-helpers/stringHelper";

export const observedAttributes: (keyof Properties)[] = ["appliedFilter"];

export const name = "dc-table-header";

const DEFAULTS: Properties = {
    fieldName: "",
    sortable: false,
    filterable: false,
    columnType: "",
    filterDescriptor: undefined,
    label: "",
    orderBy: "",
    orderDir: "none",
    onSortClicked: undefined,
    onFilterChanged: undefined,
    appliedFilter: undefined,
    showSorterArrow: true,
    headerTemplate: undefined,
    useEllipsis: true,
};

export interface Properties {
    sortable: boolean;
    fieldName: string;
    filterable?: boolean;
    columnType?: string;
    filterDescriptor?: TextFilterDescriptor | SelectFilterDescriptor | DateFilterDescriptor | NumberFilterDescriptor;
    label?: string;
    orderBy?: string;
    orderDir?: SortOrder;
    onSortClicked?: (fieldName: string, sortOrder: SortOrder, e: Event) => void;
    onFilterChanged?: (fieldName: string, filterProps: FilterProperty, e: Event) => void;
    appliedFilter: AppliedFilter<string>;
    showSorterArrow: boolean;
    headerTemplate: TemplateResult;
    useEllipsis: boolean;
}

// tslint:disable-next-line: cyclomatic-complexity
export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        fieldName: host.fieldName !== undefined ? host.fieldName : DEFAULTS.fieldName,
        sortable: host.sortable !== undefined ? host.sortable : DEFAULTS.sortable,
        filterable: host.filterable !== undefined ? host.filterable : DEFAULTS.filterable,
        columnType: host.columnType !== undefined ? host.columnType : DEFAULTS.columnType,
        filterDescriptor: host.filterDescriptor !== undefined ? host.filterDescriptor : DEFAULTS.filterDescriptor,
        label: host.label !== undefined ? host.label : DEFAULTS.label,
        orderBy: host.orderBy !== undefined ? host.orderBy : DEFAULTS.orderBy,
        orderDir: host.orderDir !== undefined ? host.orderDir : DEFAULTS.orderDir,
        onSortClicked: host.onSortClicked !== undefined ? host.onSortClicked : DEFAULTS.onSortClicked,
        onFilterChanged: host.onFilterChanged !== undefined ? host.onFilterChanged : DEFAULTS.onFilterChanged,
        appliedFilter: host.appliedFilter !== undefined ? host.appliedFilter : DEFAULTS.appliedFilter,
        showSorterArrow: host.showSorterArrow !== undefined ? host.showSorterArrow : DEFAULTS.showSorterArrow,
        headerTemplate: host.headerTemplate !== undefined ? host.headerTemplate : DEFAULTS.headerTemplate,
        useEllipsis: host.useEllipsis !== undefined ? host.useEllipsis : DEFAULTS.useEllipsis,
    };
    const sortOrder = props.orderBy === props.fieldName ? props.orderDir : "none";

    const filterIsSet = () => {
        return (
            props.appliedFilter?.filterProps.max !== undefined ||
            props.appliedFilter?.filterProps.min !== undefined ||
            props.appliedFilter?.filterProps.selectedValues !== undefined
        );
    };

    // EVENT HANDLERS

    const onEnumValuesChanged = (e: DC.Select.SelectChangeEvent) => {
        if (!enumValues.includes(e.detail.selectedValue)) {
            setEnumValues((previousSearchArray) => [...previousSearchArray, e.detail.selectedValue]);

            enumValues.push(e.detail.selectedValue);
        }
    };

    // STATES

    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState<boolean>(false);
    const [stringValue, setStringValue] = useState<string>(undefined);
    const [enumValues, setEnumValues] = useState<string[]>([]);
    const [numberValue, setNumberValue] = useState<NumberInput>({ to: undefined, from: undefined });

    const init = () => {
        const onClickedOutside = (e: Event) => {
            if (!host.contains(e.target as Node)) {
                setIsSearchBoxOpen(false);
            }
        };
        const onCloseOnEscKey = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setIsSearchBoxOpen(false);
            }
        };
        document.addEventListener("keyup", onCloseOnEscKey, true);
        document.addEventListener("click", onClickedOutside);
        return () => {
            document.removeEventListener("keyup", onCloseOnEscKey);
            document.removeEventListener("click", onClickedOutside);
        };
    };

    useEffect(init, []);

    useEffect(() => {
        if (props.filterDescriptor) {
            if (props.filterDescriptor.type === "string") {
                setStringValue(props.appliedFilter?.filterProps.selectedValues as string);
            } else if (props.filterDescriptor.type === "select") {
                if (typeof props.appliedFilter?.filterProps.selectedValues === "string") {
                    setEnumValues([props.appliedFilter?.filterProps.selectedValues]);
                } else {
                    setEnumValues(props.appliedFilter?.filterProps.selectedValues);
                }
            } else if (props.filterDescriptor.type === "number") {
                setNumberValue({
                    from: props.appliedFilter?.filterProps.min as number,
                    to: props.appliedFilter?.filterProps.max as number,
                });
            }
        }
    }, [props.appliedFilter]);

    // TEMPLATES

    const templateButtonBar = (onFilter: (e: Event) => void, onClear: (e: Event) => void, isEmptyBB: () => boolean) => {
        return html`
            <div class="flex justify-between w-full">
                ${isEmptyBB()
                    ? html` <div></div> `
                    : html`
                          <div>
                              <button
                                  class="btn btn-sm btn-primary"
                                  @click=${(e: DC.Input.DcInputEvent) => {
                                      setIsSearchBoxOpen(false);
                                      onClear(e);
                                  }}
                              >
                                  Clear
                              </button>
                          </div>
                      `}

                <button
                    class="btn btn-sm btn-primary"
                    @click=${(e: ClickEvent) => {
                        setIsSearchBoxOpen(false);
                        onFilter(e);
                    }}
                >
                    Filter
                </button>
            </div>
        `;
    };

    const templateStringSearchBox = () => {
        return html`
            <dc-input
                @input=${(e: DC.Input.DcInputEvent) => {
                    setStringValue(e.detail.value);
                }}
                .value=${stringValue}
                @keyup=${(e: KeyboardEvent) => {
                    if (e.key === "Enter") {
                        setIsSearchBoxOpen(false);
                        e.preventDefault();
                        props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: stringValue }, e);
                    }
                }}
            ></dc-input>
            ${templateButtonBar(
                (e) =>
                    props.onFilterChanged(
                        props.fieldName,
                        {
                            operator: "eq",
                            selectedValues: stringValue === "" ? undefined : stringValue,
                        } as FilterProperty,
                        e,
                    ),
                (e) => {
                    setStringValue(undefined);
                    props.onFilterChanged(
                        props.fieldName,
                        { operator: "eq", selectedValues: undefined } as FilterProperty,
                        e,
                    );
                },
                () => isEmpty(stringValue),
            )}
        `;
    };

    const templateEnumSearchBox = () => {
        const selectFilterDescriptor: SelectFilterDescriptor = props.filterDescriptor as SelectFilterDescriptor;
        selectFilterDescriptor.multiSelect =
            selectFilterDescriptor.multiSelect === undefined ? false : selectFilterDescriptor.multiSelect;
        selectFilterDescriptor.searchable =
            selectFilterDescriptor.searchable === undefined ? false : selectFilterDescriptor.searchable;
        if (!selectFilterDescriptor.multiSelect) {
            return html`
                <div class="mt-4">
                    <dc-select
                        .dataSource=${selectFilterDescriptor.dataSource}
                        .searchable=${selectFilterDescriptor.searchable}
                        .searchProps=${selectFilterDescriptor.searchProps}
                        .selectedValues=${enumValues}
                        @change=${(e: DC.Select.SelectChangeEvent) => {
                            if (e.detail !== undefined) {
                                setEnumValues([e.detail.selectedValue]);
                            }
                        }}
                    ></dc-select>
                    ${templateButtonBar(
                        (e) =>
                            props.onFilterChanged(
                                props.fieldName,
                                { operator: "eq", selectedValues: enumValues?.length > 0 ? enumValues[0] : undefined },
                                e,
                            ),
                        (e) => {
                            setEnumValues(undefined);
                            props.onFilterChanged(
                                props.fieldName,
                                { operator: "eq", selectedValues: undefined } as FilterProperty,
                                e,
                            );
                        },
                        () => enumValues === undefined || enumValues.length === 0,
                    )}
                </div>
            `;
        } else {
            return html`
                <div class="mt-4">
                    <dc-select
                        class="w-32"
                        .dataSource=${selectFilterDescriptor.dataSource}
                        .selectedValues=${enumValues}
                        .multiselect=${true}
                        @change=${onEnumValuesChanged}
                    ></dc-select>
                    ${templateButtonBar(
                        (e) =>
                            props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: enumValues }, e),
                        (e) => {
                            setEnumValues(undefined);
                            props.onFilterChanged(
                                props.fieldName,
                                { operator: "eq", selectedValues: undefined } as FilterProperty,
                                e,
                            );
                        },
                        () => enumValues === undefined || enumValues.length === 0,
                    )}
                </div>
            `;
        }
    };

    const templateNumberSearchBox = () => {
        return html`
            <dc-input
                .value=${numberValue.from}
                .label=${"From"}
                .type=${"number"}
                @change=${(e: DC.Input.DcInputEvent) => {
                    setNumberValue({ ...numberValue, from: Number(e.detail.value) });
                }}
            ></dc-input>
            <dc-input
                class="mb-1"
                .value=${numberValue.to}
                .label=${"To"}
                @change=${(e: DC.Input.DcInputEvent) => {
                    setNumberValue({ ...numberValue, to: Number(e.detail.value) });
                }}
            ></dc-input>
            ${templateButtonBar(
                (e) =>
                    props.onFilterChanged(
                        props.fieldName,
                        {
                            operator: "eq",
                            min: numberValue.from,
                            max: numberValue.to,
                        },
                        e,
                    ),
                (e) => {
                    setNumberValue({ from: undefined, to: undefined });
                    props.onFilterChanged(
                        props.fieldName,
                        { operator: "eq", min: undefined, max: undefined } as FilterProperty,
                        e,
                    );
                },
                () => numberValue.from === undefined && numberValue.to === undefined,
            )}
        `;
    };

    const searchBoxTemplate = () => {
        let innerTemplate;
        if (props.filterDescriptor.type === "string" && props.columnType === "string") {
            innerTemplate = templateStringSearchBox;
        } else if (props.filterDescriptor.type === "select" && props.columnType === "enum") {
            innerTemplate = templateEnumSearchBox;
        } else if (props.filterDescriptor.type === "number" && props.columnType === "number") {
            innerTemplate = templateNumberSearchBox;
        } else {
            throw new Error(`Unknown Filter Descriptor!`);
        }

        if (isSearchBoxOpen) {
            return html`
                <div
                    class="absolute top-0 left-0 bg-white z-40 p-2 border-solid border-2 border-gray-400 font-normal"
                    style="min-width: 150px"
                >
                    ${innerTemplate()}
                </div>
            `;
        } else {
            return "";
        }
    };

    return html`
        <div
            class="flex ${props.filterDescriptor ? "justify-between" : "justify-center"} ${props.sortable
                ? "cursor-pointer"
                : ""} w-full h-full items-center"
            @click=${props.sortable
                ? (e: Event) => {
                      props.onSortClicked(
                          props.fieldName,
                          sortOrder === "none" || sortOrder === "desc" ? "asc" : "desc",
                          e,
                      );
                  }
                : null}
        >
            ${props.sortable && props.showSorterArrow
                ? html`
                      <div>
                          <div class="flex">
                              <div>
                                  ${sortOrder !== "none"
                                      ? html` <i class="fa fa-chevron-${sortOrder === "asc" ? "up" : "down"}"></i> `
                                      : ""}
                              </div>
                              ${props.headerTemplate ? props.headerTemplate : html` <div>${props.label}</div> `}
                          </div>
                      </div>
                  `
                : html` <div>${props.headerTemplate ? props.headerTemplate : html` <div>${props.label}</div> `}</div> `}
            ${props.filterDescriptor
                ? html`
                      <div class="relative cursor-pointer">
                          <div
                              class="fa fa-filter ${filterIsSet() ? "filter-set" : ""}"
                              @click=${() => {
                                  setIsSearchBoxOpen(!isSearchBoxOpen);
                              }}
                          ></div>
                          ${searchBoxTemplate()}
                      </div>
                  `
                : ""}
        </div>
    `;
};

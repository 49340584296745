import i18next from "i18next";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { html, useMemo } from "haunted";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { StaffBenefitDiscountItem } from "../../../component-models/staff-benefit/StaffBenefitDiscountItem";
import { FluentValidatorMethodsPartial } from "../../../validator/Validation";
import { SelectOption } from "../../../shared/common";

export const name = "ac-employee-discount-selector";

export interface Props {
    appliedCode: string;
    discounts: StaffBenefitDiscountItem[];
    validator: FluentValidatorMethodsPartial;
    setAppliedCode: (code: string) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        appliedCode: host.appliedCode,
        discounts: host.discounts,
        validator: host.validator,
        setAppliedCode: host.setAppliedCode,
    };

    const discountOptions = useMemo(
        (): SelectOption[] => [
            { Value: "", Text: "", IsSelected: !props.appliedCode, IsDisabled: true },
            ...props.discounts.map((discount) => ({
                Value: discount.DiscountCode,
                Text: `${discount.DiscountPercentage * 100}%`,
                IsSelected: discount.DiscountCode === props.appliedCode,
            })),
        ],
        [props.discounts, props.appliedCode],
    );

    // TEMPLATES

    const headerTemplate = () => html`
        <header>
            <span class="js-circle-user js-icon title-icon"></span>
            <div class="title">
                <h2 class="main-title">${i18next.t("V2-SmartConnect")}</h2>
                <div class="subtitle">${i18next.t("V2-SmartConnectInfo")}</div>
            </div>
        </header>
    `;

    return props.discounts
        ? html`
              <section class="booking-wrapper employee-discount-selector">
                  ${headerTemplate()}
                  <div class="inner-box ts-passenger-details">
                      <p class="passenger-subtitle">
                          ${i18next.t("V2-ConnectSmartLink")}
                          <a href="mailto:conectatesmart@jetsmart.com">conectatesmart@jetsmart.com</a>.
                      </p>
                      <h3 class="passenger-title">${i18next.t("V2-StaffDiscount")}</h3>
                      <div class="inner-deep-box">
                          <div class="row">
                              <div class="col-xs-1 col-sm-1-2 col-md-1-2 col-lg-1-3 col-xl-1-3">
                                  <div class="mt-[5px] w-full">
                                      <ac-select
                                          .errorMessage=${props.validator?.getMessage("selectedStaffDiscoundCode")}
                                          .isInvalid=${!props.validator?.isFieldValid("selectedStaffDiscoundCode")}
                                          .label=${i18next.t("V2-ChooseDiscount")}
                                          .options=${discountOptions}
                                          .testId=${T.PASSENGERS.DISCOUNT_SELECTOR}
                                          .onSelect=${props.setAppliedCode}
                                      ></ac-select>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          `
        : html``;
};

import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { CLASS_NAMES } from "../../shared/classNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { classMap } from "lit-html/directives/class-map";
import { getTestId } from "../../testing-helpers/TestIdHelper";

export const name = "ac-breadcrumbs-checkin";

export interface Props {
    activeIndex: number;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        activeIndex: host.activeIndex,
    };

    // Helpers

    // Event handlers

    const toggleAccordion = (e: MouseEvent) => {
        const parent = DomCrawlingHelper.findParentByClass(
            e.target as HTMLLabelElement,
            CLASS_NAMES.CheckinSidebarAccordion,
        );
        if (parent) {
            const list = DomCrawlingHelper.getElemByClass(parent, CLASS_NAMES.CheckinSidebarAccordionContent);
            list.classList.toggle(CLASS_NAMES.CheckinSidebarAccordionOpen);
            parent.classList.toggle(CLASS_NAMES.CheckinSidebarAccordionOpen);
        }
    };

    // Component

    // Templates

    const checkinClassMap = classMap({
        openable: true,
        open: props.activeIndex < 3,
        active: props.activeIndex < 3,
        past: props.activeIndex > 2,
    });

    const checkinAccordionClassMap = classMap({
        accordion: true,
        open: props.activeIndex < 3,
    });

    const passengersClassMap = classMap({
        active: props.activeIndex === 0,
        past: props.activeIndex > 0,
    });

    const additionalInfoClassMap = classMap({
        active: props.activeIndex === 2,
        past: props.activeIndex > 2,
    });

    const boardingCardClassMap = classMap({
        active: props.activeIndex === 3,
    });

    return html`
        <ul class="checkin-destination-breadcrumbs" data-test-id=${T.SIDEBAR.CHECKIN_BREADCRUMBS}>
            <li class="openable past">
                <div class="bc-title" data-test-id=${getTestId(T.SIDEBAR.CHECKIN_BREADCRUMB, { i: 0 })}>
                    <span>1</span>
                    <label @click=${toggleAccordion}>${i18next.t("V2-Extras")}</label>
                </div>
                <ul class="accordion">
                    <li>
                        ${i18next.t("V2-Baggage")}
                    </li>
                    <li>
                        ${i18next.t("V2-SeatmapLabel")}
                    </li>
                    <li>
                        ${i18next.t("V2-Extras")}
                    </li>
                </ul>
            </li>
            <li class="past">
                <div class="bc-title" data-test-id=${getTestId(T.SIDEBAR.CHECKIN_BREADCRUMB, { i: 1 })}>
                    <span>2</span>
                    ${i18next.t("V2-Payment")}
                </div>
            </li>
            <li class=${checkinClassMap}>
                <div class="bc-title" data-test-id=${getTestId(T.SIDEBAR.CHECKIN_BREADCRUMB, { i: 2 })}>
                    <span>3</span>
                    <label @click=${toggleAccordion}>${i18next.t("V2-CheckinLabel")}</label>
                </div>
                <ul class=${checkinAccordionClassMap}>
                    <li class=${passengersClassMap}>
                        ${i18next.t("V2-CheckinMenuPassengers")}
                    </li>
                    <li class=${additionalInfoClassMap}>
                        ${i18next.t("V2-CheckinMenuInfo")}
                    </li>
                </ul>
            </li>
            <li class=${boardingCardClassMap}>
                <div class="bc-title" data-test-id=${getTestId(T.SIDEBAR.CHECKIN_BREADCRUMB, { i: 3 })}>
                    <span>4</span>
                    ${i18next.t("V2-CheckinBoardingCard")}
                </div>
            </li>
            <li>
                <div class="bc-title no-bg">
                    <span><i class="js-icon js-plane-x"></i></span>
                    ${i18next.t("V2-Fly")}
                </div>
            </li>
        </ul>
    `;
};

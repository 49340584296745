import { JetSmartEvent } from "./JetSmartEvent";
import BookingFlowHandler from "../BookingFlowHandler";

export function raiseBookingFlowContinueEvent(success: boolean, errorClasses?: string[]): void {
    window.eventBus.raiseEvent({
        name: JetSmartEvent.ContinueClick,
        params: {
            current_step: BookingFlowHandler.getCurrentStep(),
            previous_step: BookingFlowHandler.getPreviousStep(),
            id: "continue",
            event: "click",
            status: success ? "success" : "failed",
            data: {
                errors: collectErrorsFromDom(errorClasses),
            },
        },
    });
}

function collectErrorsFromDom(errorClasses?: string[]): string[] {
    const classesToFind: string[] = errorClasses
        ? errorClasses
        : [
            ".form-error-message",
            ".error-msg",
            ".ts-age-error-msg",
        ];

    let getInvisibleErrors = false;
    if (errorClasses && errorClasses.length > 0) {
        getInvisibleErrors = true;
    }

    const uniqueErrorMessages = (Array
        .from(document.querySelectorAll(classesToFind.join(", "))) as HTMLElement[])
        .filter((e) => getInvisibleErrors || e.offsetHeight > 0)
        .map((e) => getErrorText(e))
        .filter((v, i, a) => a.indexOf(v) === i);
    return uniqueErrorMessages;
}

function getErrorText(elem: Element): string {
    while (elem.children.length > 0) {
        elem = elem.children[0];
    }

    return elem.textContent;
}

import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { maskCurrenciesForDisplay } from "../../../../shared/common";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { InsurancePassengerModel } from "../../../../component-models/extras/insurance/InsurancePassengerModel";
import { classMap } from "lit-html/directives/class-map";
import { ToggleBaggageInsuranceOpenEvent } from "../../../../component-models/extras/insurance/InsuranceEvents";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../../managers/useAppContext";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { USA_CULTURE_CODE, BRASILIAN_CULTURE_CODE } from "../../../../shared/commonConstants";

export const name = "ac-insurance-baggage-container";

export const baggageScrollerClassName = "ts-new-insurance-baggage-container";

export interface Props {
    bagPriceCurrency: string;
    insurancePerBagPrice: number;
    isBaggageInsuranceOpen: boolean;
    passengers: InsurancePassengerModel[];
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        bagPriceCurrency: host.bagPriceCurrency,
        insurancePerBagPrice: host.insurancePerBagPrice,
        isBaggageInsuranceOpen: host.isBaggageInsuranceOpen,
        passengers: host.passengers,
    };

    // HELPERS

    const bagTotal = () =>
        props.passengers
            .filter((p) => p.isBaggageInsuranceOpened)
            .reduce(
                (aggr, pax) =>
                    aggr +
                    (pax.checkedBaggageWithInsurance + pax.oversizedBaggageWithInsurance) * props.insurancePerBagPrice,
                0,
            );

    // EVENT HANDLERS

    const handleBaggageInsuranceDetailsClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        host.dispatchEvent(new ToggleBaggageInsuranceOpenEvent());
    };

    // COMPONENT

    const appContext = useAppContext();

    const { formatNumber } = useNumberFormatter();

    // TEMPLATES

    const passengerTemplate = (pax: InsurancePassengerModel) => html`
        <ac-insurance-baggage
            .bagPriceCurrency=${props.bagPriceCurrency}
            .insurancePerBagPrice=${props.insurancePerBagPrice}
            .pax=${pax}
        ></ac-insurance-baggage>
    `;

    const detailsTemplate = () => {
        const tempClassMap = classMap({
            "insurance-opened-container": true,
            "lost-bags": true,
            "open": props.isBaggageInsuranceOpen,
        });

        return html`
            <div class=${tempClassMap}>
                <div class="baggage-insurance-data-description">
                    ${i18next.t("Porfavor rellena la información para todos los pasajeros de tu reserva")}:
                </div>
                ${props.passengers.map(passengerTemplate)} ${totalTemplate()}
            </div>
        `;
    };

    const totalTemplate = () => html`
        <div class="insurance-baggage-insurance-total-price">
            ${i18next.t("Total")} ${formatNumber({ amount: bagTotal(), currency: props.bagPriceCurrency })}
        </div>
    `;

    const openerButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "with-x-logo": props.isBaggageInsuranceOpen,
            "blue-white": props.isBaggageInsuranceOpen,
            "white-blue": !props.isBaggageInsuranceOpen,
        });

        return html`
            <div class="data-opener-button-container">
                <button class=${tempClassMap} @click=${handleBaggageInsuranceDetailsClick}>
                    ${props.isBaggageInsuranceOpen ? i18next.t("Quitar") : i18next.t("Agregar")}
                </button>
            </div>
        `;
    };

    const headerTemplate = () => {
        const formattedAmount = `${formatNumber({
            amount: props.insurancePerBagPrice,
            currency: props.bagPriceCurrency,
        }).replace(" ", "")} ${maskCurrenciesForDisplay(props.bagPriceCurrency)}`;

        return html`
            <header class="insurance-title">
                <i class="js-airplane-on-shield js-icon title-icon"></i>
                <div class="title">
                    <h2 class="main-title" data-test-id=${T.EXTRAS_BAGGAGE_INSURANCE.TITLE}>
                        ${i18next.t("Protección por pérdida de equipaje")}
                    </h2>
                    <div class="subtitle" data-test-id=${T.EXTRAS_BAGGAGE_INSURANCE.SUBTITLE}>
                        ${i18next.t(
                            "Asegura tu equipaje facturado y sobredimensionado desde {{bagPrice}} por pasajero.",
                            {
                                bagPrice: formattedAmount,
                            },
                        )}
                    </div>
                </div>
            </header>
        `;
    };

    const illustrationTemplate = () => html`
        <div class="insurance-description">
            <i class="js-icon-covid js-cv-covid-shield-tick-suitcase"></i>
            <ul class="insurance-description-list">
                <li>${i18next.t("En caso de pérdida recibe $0000 si tu equipaje no ha sido devuelto en 96 horas.")}</li>
                <li>${i18next.t("Hasta $0000 por retraso o daño de tu equipaje.")}</li>
            </ul>
        </div>
    `;

    const openerTemplate = () => {
        const tempClassMap = classMap({
            "insurance-data-opener": true,
            "open": props.isBaggageInsuranceOpen,
        });

        const formattedAmount = formatNumber({
            amount: props.insurancePerBagPrice,
            currency: props.bagPriceCurrency,
        });

        const openerText = i18next.t(
            "Asegura tu equipaje desde {{-boldstart}}{{bagPerPaxPrice}} por pasajero.{{-boldend}}",
            {
                boldstart: `<span class="font-bold">`,
                boldend: "</span>",
                bagPerPaxPrice: `${formattedAmount.replace(" ", "")} ${props.bagPriceCurrency}`,
            },
        );

        return html`
            <div class=${tempClassMap}>
                <div class="data-opener-title">
                    <div>${unsafeHTML(openerText)}</div>
                    ${appContext.Culture !== USA_CULTURE_CODE && appContext.Culture !== BRASILIAN_CULTURE_CODE
                        ? html` <div>${i18next.t("Precio varía según tipo de equipaje.")}</div> `
                        : ""}
                </div>
                ${openerButtonTemplate()}
            </div>
        `;
    };

    return html`
        <div
            class="insurance-content-container baggage-insurance-container ${baggageScrollerClassName}"
            data-test-id=${T.EXTRAS_BAGGAGE_INSURANCE.CONTAINER}
        >
            ${headerTemplate()} ${illustrationTemplate()} ${openerTemplate()} ${detailsTemplate()}
        </div>
    `;
};

import i18next from "i18next";
import { html } from "haunted";
import { ROUTES } from "../../shared/apiRoutes";
import { classMap } from "lit-html/directives/class-map";
import { updateMdl } from "../../shared/common";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { useFlightMoveDevolutionModal } from "./useFlightMoveDevolutionModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { spaSectionOrderFactory } from "../spa/spaSectionOrderFactory";

export interface Props {
    model: ItineraryPageViewModel;
}

export const useItineraryTabsModifications = (props: Props) => {
    // HELPERS

    const isChangePaxButtonDisabled = () =>
        props.model.ItineraryModelV2.Journeys.some((journey) => !journey.IsModificationPossible) ||
        props.model.CancelledFlightDesignators;

    // EVENT HANDLERS

    const handleChangeClick = () => {
        if (isChangePaxButtonDisabled()) {
            return;
        }

        const orders = getSpaSectionOrder({
            flowType: "Change",
            isStaff: userContext.isStaff,
        });

        window.location.href = `/${orders.find((o) => o.section.type === "Passengers").section.uri}`;
    };

    // COMPONENT

    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const modal = useFlightMoveDevolutionModal({ url: props.model.FlightMoveDevolutionUrl });

    // TEMPLATES

    const disabledChangePaxButtonInfoTemplate = () =>
        isChangePaxButtonDisabled()
            ? html`
                  <span class="i2-button-tooltip-opener pull-out">
                      <ac-tooltip
                          .icon=${"?"}
                          .isTransparent=${true}
                          .tooltip=${i18next.t(
                              "Ya no es posible hacer el traspaso de tu ticket debido a la hora de tu vuelo.",
                          )}
                      ></ac-tooltip>
                  </span>
              `
            : "";

    const changePassengersButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "i2-btn": true,
            "i2-btn-blue": true,
            "disabled": isChangePaxButtonDisabled() || userContext.isStaff,
        });

        return props.model.ItineraryModelV2.ShowChangePassengerDataButton &&
            !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking)
            ? html`
                  <div class="relative flex flex-col items-center justify-between sm:flex-row">
                      <div class="i2-modify-container">
                          <div>${i18next.t("Cambio de nombre de los pasajeros")}</div>
                          <div>
                              ${i18next.t(
                                  "Traspasa tu pasaje a otra persona o modifica el nombre de uno o más pasajeros.",
                              )}
                          </div>
                      </div>
                      ${disabledChangePaxButtonInfoTemplate()}
                      <a class=${tempClassMap} data-test-id="change-passenger-data-button" @click=${handleChangeClick}>
                          ${i18next.t("Traspaso")}
                      </a>
                  </div>
                  <div class="i2-modify-divider"></div>
                  ${updateMdl()}
              `
            : "";
    };

    const flightMoveButtonTemplate = () => {
        const onClick =
            props.model.ItineraryModelV2.InvoluntaryChangeStatus === "AllowedOnline"
                ? () => (window.location.href = ROUTES.FlightMoveSelectLegs)
                : () => modal.open();

        return html`
            <a class="rounded-primary-btn i2-btn i2-btn-blue" @click=${onClick}> ${i18next.t("Cambio de vuelo")} </a>
        `;
    };

    const flightChangeButtonTemplate = () =>
        props.model.ItineraryModelV2.InvoluntaryChangeStatus !== "NotAllowed"
            ? html`
                  <div class="flex flex-col items-center justify-between sm:flex-row">
                      <div class="i2-modify-container">
                          <div>${i18next.t("Cambio de vuelo")}</div>
                          <div>${i18next.t("Solicita el cambio fecha o  ruta de tu vuelo.")}</div>
                      </div>
                      ${flightMoveButtonTemplate()}
                  </div>
                  <div class="i2-modify-divider"></div>
              `
            : "";

    const revocationButtonTemplate = () => html`
        <div class="flex flex-col items-center justify-between sm:flex-row">
            <div class="i2-modify-container">
                <div>
                    ${userContext.peruCompra.isAdmin ||
                    userContext.peruCompra.isMember ||
                    bookingContext.isPeruCompraBooking
                        ? i18next.t("Anulación de ticket")
                        : i18next.t("Solicitud de devolución ")}
                </div>
                <div>
                    ${userContext.peruCompra.isAdmin ||
                    userContext.peruCompra.isMember ||
                    bookingContext.isPeruCompraBooking
                        ? i18next.t("Ingresa tu solicitud de anulación de ticket.")
                        : i18next.t("Ingresa tu solicitud de devolución de reserva.")}
                </div>
            </div>
            <a class="rounded-primary-btn i2-btn i2-btn-blue" href=${props.model.DevolutionUrl}>
                ${userContext.peruCompra.isAdmin ||
                userContext.peruCompra.isMember ||
                bookingContext.isPeruCompraBooking
                    ? i18next.t("Anulación de ticket")
                    : i18next.t("Solicitud de devolución ")}
            </a>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-edit"></i>
                <div>${i18next.t("Modifica tu reserva")}</div>
            </div>
            ${changePassengersButtonTemplate()} ${flightChangeButtonTemplate()} ${revocationButtonTemplate()}
        </div>
        ${modal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

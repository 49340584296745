import { REQUIRED_FORM_FIELD_ERROR_ID } from "../../../commonConstants";
import { InputElement } from "../useForm";
import { InputFieldAttribute, UDF_ATTR_REQUIRED } from "../InputFieldAttribute";

export const required = (): InputFieldAttribute => ({
    name: UDF_ATTR_REQUIRED,
    validators: [
        {
            errorMessage: {
                id: REQUIRED_FORM_FIELD_ERROR_ID,
                message: window.formResources.fillAllFields,
                scope: "form",
            },

            validate: (field: InputElement) => {
                const result =
                    field.type === "checkbox" ? (field as HTMLInputElement).checked : field.value.trim() !== "";

                return Promise.resolve(result);
            },
        },
    ],
});

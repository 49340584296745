import { TestIdDictionary as T } from "./../testing-helpers/TestIdHelper";
import { useEffect } from "./../shared/haunted/CustomHooks";
import {
    CULTURES_WITH_SUNDAY_AS_START_OF_WEEK,
    DEFAULT_DATE_FORMAT,
    INBOUND,
    OUTBOUND,
} from "./../shared/commonConstants";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { useState } from "haunted";

import { html } from "lit-html";

import * as dayjs from "dayjs";
import * as ObjectSupport from "dayjs/plugin/objectSupport";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as Weekday from "dayjs/plugin/weekday";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
dayjs.extend(ObjectSupport);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
dayjs.extend(Weekday);
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { convertToMoment } from "../shared/common";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-datepicker";

const DEFAULTS: Properties = {
    classes: [],
    culture: "es-CL",
    disabledDates: [],
    isRange: false,
    max: dayjs("2100-12-31", DEFAULT_DATE_FORMAT),
    min: dayjs("1900-01-01", DEFAULT_DATE_FORMAT),
    value: undefined,
    setIsOpen: undefined,
};

export interface Properties {
    canDeselectRangeEnd?: boolean;
    canModifyStartInRange?: boolean;
    classes: string[];
    culture: string;
    disabledDates: dayjs.Dayjs[];
    isRange?: boolean;
    max?: dayjs.Dayjs;
    min?: dayjs.Dayjs;
    value: dayjs.Dayjs | dayjs.Dayjs[];
    setIsOpen: (newState: boolean) => void;
}

interface ChangeEventDetail {
    date: dayjs.Dayjs;
    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
}

export class ChangeDateEvent extends CustomEvent<ChangeEventDetail> {
    constructor(detail: ChangeEventDetail) {
        super("change", { detail });
    }
}

const mapProperties = (host: Element & Properties) => {
    const props: Properties = {
        value: host.value !== undefined ? convertToMoment(host.value) : DEFAULTS.value,
        min: host.min !== undefined ? (convertToMoment(host.min) as dayjs.Dayjs) : DEFAULTS.min,
        max: host.max !== undefined ? (convertToMoment(host.max) as dayjs.Dayjs) : DEFAULTS.max,
        disabledDates: host.disabledDates !== undefined ? host.disabledDates : DEFAULTS.disabledDates,
        isRange: Boolean(host.isRange),
        classes: host.classes || [],
        culture: host.culture !== undefined ? host.culture : DEFAULTS.culture,
        setIsOpen: host.setIsOpen,
    };

    return props;
};

export const Component: HauntedFunc<Properties> = (host) => {
    const props = mapProperties(host);

    // HELPERS

    const weekdays = () => {
        const days = [
            i18next.t("mo"),
            i18next.t("tu"),
            i18next.t("we"),
            i18next.t("th"),
            i18next.t("fr"),
            i18next.t("sa"),
            i18next.t("su"),
        ];

        if (doesWeekStartOnSunday()) {
            days.unshift(days.pop());
        }

        return days;
    };

    const move = (direction: number) => {
        setCurrentDate(currentDate.add(direction, "month"));
    };

    const doesWeekStartOnSunday = () => CULTURES_WITH_SUNDAY_AS_START_OF_WEEK.includes(props.culture.toLowerCase());

    const getNewRangeFromOneDate = (existingDay: dayjs.Dayjs, newDay: dayjs.Dayjs) => {
        if (existingDay && newDay.isSame(existingDay, "day")) {
            return [undefined, undefined];
        }

        if (existingDay && newDay.isAfter(existingDay, "day")) {
            return [existingDay, newDay];
        }

        return [newDay, existingDay];
    };

    const getShorterRange = (day: dayjs.Dayjs) => {
        const [start, end] = selectedDate as dayjs.Dayjs[];
        const differenceInDaysFromStart = Math.abs(day.diff(start, "day"));
        const differenceInDaysFromEnd = Math.abs(day.diff(end, "day"));

        if (differenceInDaysFromStart < differenceInDaysFromEnd) {
            return [day, end];
        }

        return [start, day];
    };

    const getNewRangeDates = (day: dayjs.Dayjs) => {
        const [start, end] = selectedDate as dayjs.Dayjs[];

        if (!start && props.canModifyStartInRange) {
            return getNewRangeFromOneDate(end, day);
        }

        if (!end && props.canModifyStartInRange) {
            return getNewRangeFromOneDate(start, day);
        }

        if (start && day.isSame(start, "day")) {
            if (props.canModifyStartInRange) {
                return [undefined, end];
            }

            return [start, start];
        }

        if (end && day.isSame(end, "day")) {
            if (props.canDeselectRangeEnd) {
                return [start, undefined];
            }

            return [start, end];
        }

        if (start && day.isBefore(start, "day") && props.canModifyStartInRange) {
            return [day, end];
        }

        if (end && day.isAfter(end, "day")) {
            return [start, day];
        }

        if (props.canModifyStartInRange) {
            return getShorterRange(day);
        }

        return [start, day];
    };

    const minDate = () => (dayjs.isDayjs(props.min) ? props.min : dayjs(props.min, DEFAULT_DATE_FORMAT));

    const maxDate = () => (dayjs.isDayjs(props.max) ? props.max : dayjs(props.max, DEFAULT_DATE_FORMAT));

    const isSelectedDayAfterHoveredDay = (day: dayjs.Dayjs) =>
        props.isRange &&
        Array.isArray(selectedDate) &&
        selectedDate[INBOUND] &&
        day.isSame(selectedDate[INBOUND], "day") &&
        hoveredDay &&
        hoveredDay.isBefore(day);

    const isDaySelected = (day: dayjs.Dayjs) => {
        if (!selectedDate || isSelectedDayAfterHoveredDay(day)) {
            return false;
        }

        if (Array.isArray(selectedDate)) {
            return selectedDate.some((date) => date && date.isSame(day, "day"));
        }

        return day.isSame(selectedDate as dayjs.Dayjs, "day");
    };

    const isDayRangeStart = (day: dayjs.Dayjs) => {
        if (!props.isRange || !selectedDate || !Array.isArray(selectedDate) || !selectedDate[OUTBOUND]) {
            return false;
        }

        return day.isSame(selectedDate[OUTBOUND], "day");
    };

    const isDayRangeEnd = (day: dayjs.Dayjs) => {
        if (!props.isRange || !selectedDate || !Array.isArray(selectedDate) || !selectedDate[INBOUND]) {
            return false;
        }

        return day.isSame(selectedDate[INBOUND], "day") && !day.isSame(selectedDate[OUTBOUND], "day");
    };

    const isDayToday = (day: dayjs.Dayjs) => day.isSame(dayjs(), "date");

    const isDayDisabled = (day: dayjs.Dayjs) =>
        day.isBefore(minDate(), "date") ||
        day.isAfter(maxDate(), "date") ||
        props.disabledDates.some((date) => {
            const momentDate = dayjs.isDayjs(date) ? date : dayjs(date, DEFAULT_DATE_FORMAT);
            return momentDate.isSame(day, "date");
        });

    const isDayInHoveredRange = (day: dayjs.Dayjs) => {
        return (
            props.isRange &&
            Array.isArray(selectedDate) &&
            selectedDate[OUTBOUND] &&
            day.isAfter(selectedDate[OUTBOUND], "day") &&
            day.isBefore(hoveredDay, "day")
        );
    };

    const isDayInRange = (day: dayjs.Dayjs) =>
        Array.isArray(selectedDate) &&
        selectedDate[OUTBOUND] &&
        selectedDate[INBOUND] &&
        day.isAfter(selectedDate[OUTBOUND], "day") &&
        day.isBefore(selectedDate[INBOUND], "day");

    const isBackOneMonthDisabled = () =>
        minDate().year() === currentDate.year() && minDate().month() >= currentDate.month();

    const isForwardOneMonthDisabled = () =>
        maxDate().year() === currentDate.year() && maxDate().month() <= currentDate.month();

    const addPlacerholderDaysInFront = (index: number) =>
        Array.from(Array(doesWeekStartOnSunday() ? (index + 7) % 7 : index));

    const addPlacerholderDaysAfter = (index: number) =>
        Array.from(Array(6 - (doesWeekStartOnSunday() ? (index + 7) % 7 : index)));

    const getCalendarForMonth = (offset: number): dayjs.Dayjs[][] => {
        const offsetDate = dayjs(currentDate).add(offset, "month");

        const firstDayIndex = dayjs(offsetDate).startOf("month").weekday();
        let days: dayjs.Dayjs[] = addPlacerholderDaysInFront(firstDayIndex);

        let day = dayjs(offsetDate).startOf("month");
        const endOfMonth = dayjs(offsetDate).endOf("month");

        while (day.isSameOrBefore(endOfMonth)) {
            days.push(dayjs(day));
            day = dayjs(day.add(1, "day"));
        }

        const lastDayIndex = dayjs(offsetDate).endOf("month").weekday();
        days = days.concat(addPlacerholderDaysAfter(lastDayIndex));

        const retVal = days.reduce((weeks, weekDay, i) => {
            const weekIndex = Math.floor(i / 7);
            weeks[weekIndex] = [].concat(weeks[weekIndex] || [], weekDay);
            return weeks;
        }, []);

        return retVal;
    };

    const getInitialSelectedDate = () => {
        if (props.isRange && props.value && !Array.isArray(props.value)) {
            throw new Error("If the datepicker is set to Range, the value should be an array.");
        }

        if (!props.isRange && props.value && Array.isArray(props.value)) {
            throw new Error("If the datepicker is NOT set to Range, the value should NOT be an array.");
        }

        if (!props.value) {
            return props.isRange ? [undefined, undefined] : undefined;
        }

        if (Array.isArray(props.value)) {
            return props.value.reduce(
                (aggr, day) =>
                    aggr.concat(day ? (dayjs.isDayjs(day) ? dayjs(day) : dayjs(day, DEFAULT_DATE_FORMAT)) : undefined),
                [],
            );
        }

        return dayjs.isDayjs(props.value) ? dayjs(props.value) : dayjs(props.value, DEFAULT_DATE_FORMAT);
    };

    const getInitialCurrentDate = () => {
        const value = Array.isArray(props.value) ? props.value[OUTBOUND] : props.value;

        return value
            ? dayjs.isDayjs(value)
                ? value
                : dayjs(value, DEFAULT_DATE_FORMAT)
            : dayjs().isSameOrAfter(minDate(), "date") && dayjs().isSameOrBefore(maxDate(), "date")
            ? dayjs()
            : minDate();
    };

    // EVENT HANDLERS

    const handleClose = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof props.setIsOpen === "function") {
            props.setIsOpen(false);
        }
    };

    // DEVNOTE All this checking if day is of type dayjs.Dayjs is because sometimes strange change events are fired
    // TODO Try to eliminate unwanted change events
    const dispatchChange = (value: dayjs.Dayjs | dayjs.Dayjs[]) => {
        const date = !Array.isArray(value) && dayjs.isDayjs(value) ? value : undefined;
        const fromDate =
            Array.isArray(value) && value.every((d) => !d || dayjs.isDayjs(d)) && value.length > 0
                ? value[OUTBOUND]
                : undefined;
        const toDate =
            Array.isArray(value) && value.every((d) => !d || dayjs.isDayjs(d)) && value.length > 1
                ? value[INBOUND]
                : undefined;

        host.dispatchEvent(
            new ChangeDateEvent({
                date,
                fromDate,
                toDate,
            }),
        );
    };

    const handleMouseEnter = (day: dayjs.Dayjs) => {
        if (hoveredDay?.isSame(day, "day")) {
            return;
        }

        setHoveredDay(day);
    };

    const handleMouseLeave = (day: dayjs.Dayjs) => {
        if (!hoveredDay?.isSame(day, "day")) {
            return;
        }

        setHoveredDay(undefined);
    };

    const handleBack = (e: MouseEvent) => {
        e.stopPropagation();
        move(-1);
    };

    const handleForward = (e: MouseEvent) => {
        e.stopPropagation();
        move(1);
    };

    const handleDateClick = (e: MouseEvent, day: dayjs.Dayjs) => {
        e.stopPropagation();

        if (Array.isArray(selectedDate)) {
            const [start, end] = getNewRangeDates(day);
            const newValues = [start ? dayjs(start) : undefined, end ? dayjs(end) : undefined];
            setSelectedDate(newValues);
            dispatchChange(newValues);
        } else {
            const newValue = dayjs(day);
            setSelectedDate(newValue);
            dispatchChange(newValue);
        }

        setCurrentDate(dayjs(day));
    };

    // COMPONENT

    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | dayjs.Dayjs[]>(getInitialSelectedDate());
    const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(getInitialCurrentDate());
    const [hoveredDay, setHoveredDay] = useState<dayjs.Dayjs>(undefined);

    useEffect(() => {
        setSelectedDate(getInitialSelectedDate());
        setCurrentDate(getInitialCurrentDate());
    }, [JSON.stringify(props.value), props.isRange]);

    // TEMPLATES

    const navigationPlaceholderTemplate = () => {
        const tempClassMap = classMap({
            "dg-dp-square": true,
            "dg-dp-navigation": true,
            "dg-dp-disabled": true,
            "hidden-sm-down": true,
        });

        return html` <span class=${tempClassMap}></span> `;
    };

    const forwardOneMonthTemplate = (testId: string, hiddenOnDesktop = false) => {
        const tempClassMap = classMap({
            "dg-dp-month-forward": true,
            "dg-dp-square": true,
            "dg-dp-navigation": true,
            "dg-dp-disabled": isForwardOneMonthDisabled(),
            "hidden-md-up": hiddenOnDesktop,
        });

        return html` <span class=${tempClassMap} @click=${handleForward} data-test-id=${testId}></span> `;
    };

    const backOneMonthTemplate = (testId: string, hiddenOnDesktop = false) => {
        const tempClassMap = classMap({
            "dg-dp-month-back": true,
            "dg-dp-square": true,
            "dg-dp-navigation": true,
            "dg-dp-disabled": isBackOneMonthDisabled(),
            "hidden-md-up": hiddenOnDesktop,
        });

        return html` <span class=${tempClassMap} @click=${handleBack} data-test-id=${testId}></span> `;
    };

    const firstMonthNavigationTemplate = () => html`
        <div class="w-full grid grid-cols-3 items-center justify-center">
            ${backOneMonthTemplate(T.DATE.FIRST_MONTH_NAVIGATION_MOVE_BACK)}
            <span class="dg-dp-unit-nav">
                <span
                    class="font-bold"
                    data-test-id=${T.DATE.FIRST_MONTH_NAVIGATION_NAME}
                    data-test-value=${currentDate.format("YYYY-MM")}
                    >${dayjs(currentDate).format("MMMM")}&nbsp;</span
                >
                ${dayjs(currentDate).format("YYYY")}
            </span>
            ${navigationPlaceholderTemplate()}
            ${forwardOneMonthTemplate(T.DATE.FIRST_MONTH_NAVIGATION_MOVE_FORWARD, true)}
        </div>
    `;

    const secondMonthNavigationTemplate = () => {
        const dateToDisplay = dayjs(currentDate).add(1, "month");

        return html`
            <div class="w-full grid grid-cols-3 items-center justify-center">
                ${backOneMonthTemplate(T.DATE.SECOND_MONTH_NAVIGATION_MOVE_BACK, true)}
                ${navigationPlaceholderTemplate()}
                <span class="dg-dp-unit-nav">
                    <span
                        class="font-bold"
                        data-test-id=${T.DATE.SECOND_MONTH_NAVIGATION_NAME}
                        data-test-value=${dateToDisplay.format("YYYY-MM")}
                        >${dateToDisplay.format("MMMM")}&nbsp;</span
                    >
                    ${dateToDisplay.format("YYYY")}
                </span>
                ${forwardOneMonthTemplate(T.DATE.SECOND_MONTH_NAVIGATION_MOVE_FORWARD)}
            </div>
        `;
    };

    const weekdaysHeaderTemplate = () => html`
        <div class="w-full grid grid-cols-7">
            ${weekdays().map((weekday) => html` <span class="dg-dp-square dg-dp-col-header">${weekday}</span> `)}
        </div>
    `;

    const weekTemplate = (week: dayjs.Dayjs[]) =>
        html` <div class="w-full grid grid-cols-7">${week.map(dayTemplate)}</div> `;

    // TOD Fake, to be removed
    const showPrices = () => window.location.href.toLowerCase().indexOf("?prices") > -1;

    const dayTemplate = (day: dayjs.Dayjs) => {
        const index = day ? (Number(day.format("DD")[0]) + Number(day.format("DD")[1])) % 4 : 0;
        const price = day ? ["$1.200", "$5.000", "$10.000", "$12.000"][index] : "";

        return day
            ? html`
                  <span
                      class="${classMap({
                          "dg-dp-square": true,
                          "dg-dp-date": true,
                          "dg-dp-selected": isDaySelected(day),
                          "dg-dp-today": isDayToday(day),
                          "dg-dp-disabled": isDayDisabled(day),
                          "dg-dp-range-start": isDayRangeStart(day),
                          "dg-dp-in-range": (!hoveredDay && isDayInRange(day)) || isDayInHoveredRange(day),
                          "dg-dp-range-end": !hoveredDay && isDayRangeEnd(day),
                          "dg-dp-hover-dg-dp-range-end": props.isRange,
                          [`color-${index}`]: showPrices(),
                      })}"
                      @mouseenter=${() => handleMouseEnter(day)}
                      @mouseleave=${() => handleMouseLeave(day)}
                      @click=${(e: MouseEvent) => handleDateClick(e, day)}
                      data-test-id=${T.DATE.DATE}
                      data-test-value=${day.format(DEFAULT_DATE_FORMAT)}
                  >
                      ${day.format("DD")} ${showPrices() ? html` <span>${price}</span> ` : ""}
                  </span>
              `
            : html` <span class="dg-dp-square">&nbsp;</span> `;
    };

    const weeksTemplate = (offset = 0) => html` ${getCalendarForMonth(offset).map(weekTemplate)} `;

    const mainClassMap = classMap({
        ...props.classes
            .filter((i) => i)
            .reduce((obj, className) => {
                obj[className] = true;
                return obj;
            }, {} as any),
        "dc-datepicker": true,
    });

    return html`
        <div class=${mainClassMap}>
            <div class="dg-dp-months-container">
                <div class="hidden-sm-up dg-dp-closer" @click=${handleClose}>&times;</div>
                <div class="dg-dp-month" data-test-id=${T.DATE.FIRST_MONTH_NAVIGATION_CONTAINER}>
                    ${firstMonthNavigationTemplate()} ${weekdaysHeaderTemplate()} ${weeksTemplate()}
                </div>
                <div class="dg-dp-month hidden-sm-down" data-test-id=${T.DATE.SECOND_MONTH_NAVIGATION_CONTAINER}>
                    ${secondMonthNavigationTemplate()} ${weekdaysHeaderTemplate()} ${weeksTemplate(1)}
                </div>
            </div>
        </div>
    `;
};

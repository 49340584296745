import { PetInCabinJourneyModel } from "./../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { PriorityBoardingJourneyModel } from "./../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { PassengerSelection } from "../component-models/extras/PassengerSelection";

export function mapToPassengerSelections(
    journeys: (PriorityBoardingJourneyModel | PetInCabinJourneyModel)[],
): PassengerSelection[] {
    return journeys?.reduce(
        (selections: PassengerSelection[], journey) =>
            selections.concat(
                journey.Passengers.reduce(
                    (paxSelections: PassengerSelection[], pax) =>
                        paxSelections.concat([
                            {
                                paxIndex: pax.PassengerNumber,
                                journeyIndex: journey.JourneyIndex,
                                selected: pax.IsSold,
                            },
                        ]),
                    [],
                ),
            ),
        [],
    );
}

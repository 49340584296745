import { mapToAutoAssignedSeatings } from "../../../component-mappers/SeatmapMappers";
import { ApiAutoAssignmentResponse } from "../../../component-models/seatmap/ApiAutoAssignmentResponse";
import { AutoAssignedSeating } from "../../../component-models/seatmap/AutoAssignedSeating";
import { ROUTES } from "../../../shared/apiRoutes";
import { showLoader } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { useState } from "../../../shared/haunted/CustomHooks";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";

export interface Props {
    rootElem: HTMLElement;
}

export function useAutoAssignedSeatsCache(props: Props) {
    const getAutoAssignedSeats = async (journeyIndex: number, segmentIndex: number): Promise<AutoAssignedSeating[]> => {
        const parent = DomCrawlingHelper.findParentByClass(props.rootElem, LOADER_CLASS_NAMES.SpaSection);
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: parent });
        const result = await ajaxJsonRequest<ApiAutoAssignmentResponse>({
            body: { journeyIndex: journeyIndex.toString(), segmentIndex: segmentIndex.toString() },
            container: props.rootElem,
            loader,
            url: ROUTES.ApiRoutes.GetAutoAssignedSeats,
        });

        return mapToAutoAssignedSeatings(result.data.Assignments);
    };

    const { ajaxJsonRequest } = useAjax();

    const [cachedAutoAssignment, setCachedAutoAssignment] = useState<AutoAssignedSeating[]>([]);

    const getValuesForSegment = async (journeyIndex: number, segmentIndex: number): Promise<AutoAssignedSeating[]> => {
        let retVal = cachedAutoAssignment.filter(
            (caa) => caa.JourneyIndex === journeyIndex && caa.SegmentIndex === segmentIndex,
        );

        if (retVal?.length > 0) {
            return retVal;
        }

        retVal = await getAutoAssignedSeats(journeyIndex, segmentIndex);

        if (retVal?.length > 0) {
            setCachedAutoAssignment([...cachedAutoAssignment, ...retVal]);
            return retVal;
        }

        return [];
    };

    return { getValuesForSegment };
}

import { PaxType } from "../../shared/commonConstants";
import { BundleInfo } from "../flight/BundleOffersV2Model";
import { JourneySegment } from "../shared/JourneySegment";

export interface ApiItineraryViewModelV2 {
    AllFlightsInPast: boolean;
    BannerUrls: ItineraryBannerUrls;
    BoughtDcInFlow: boolean;
    CanBuyMoreAirportCheckin: boolean;
    CanBuyMoreCabinBag: boolean;
    CanBuyMoreCheckedInBag: boolean;
    CanBuyMoreOversizedBag: boolean;
    CanBuyMorePetInCabin: boolean;
    CanBuyMorePriorityBoarding: boolean;
    CanBuyMoreSeatSelection: boolean;
    HasAdditionalPaymentCheck: boolean;
    HotelPopunderLink: string;
    Insurance: {
        CanBuyInsurance: boolean;
        ExistingInsuranceDetails: {
            FirstName: string;
            FormattedValidFrom: string;
            FormattedValidTo: string;
            HasBaggageInsurance: boolean;
            HasInsuranceInBooking: boolean;
            HasTravelInsurance: boolean;
            LastName: string;
            Policy: string;
        }
    };
    InvoluntaryChangeStatus: InvoluntaryChangeStatus;
    IsCancelled: boolean;
    IsFlightDetailsOpen: boolean;
    IsGiftCardAvailable: boolean;
    IsLastPaymentDeclined: boolean;
    IsTotalPending: boolean;
    Journeys: ItineraryJourney[];
    LogFullBooking: boolean;
    MinimumPrices: ItineraryMinimumPrice[];
    Passengers: ItineraryPax[];
    PaymentDescription: string;
    PendingPaymentId: string;
    Pnr: string;
    PollingInterval: number;
    ScrollToCheckin: boolean;
    ShowChangePassengerDataButton: boolean;
    ShowCugHoldBookingWarning: boolean;
    ShowGdsHoldBookingWarning: boolean;
    ShowPaymentErrorWarning: boolean;
    ShowPaymentSection: boolean;
    ShowRevocationButton: boolean;
    ShowTransactionBox: boolean;
    UserEmail: string;
    UserLastName: string;
}

export interface ItineraryPax {
    FaresAndTaxes: PaxFaresAndFees[];
    FirstName: string;
    FormattedFaresAndTaxesTotal: string;
    FormattedOptionalsTotal: string;
    FormattedTotal: string;
    LastName: string;
    PassengerNumber: number;
    PerBookingOptionals: ItineraryJourneyPaxSsr[];
    RelatedPassengerNumber: number;
    Total: number;
    Type: "INF" | "ADT" | "CHD";
}

export interface PaxFaresAndFees {
    GroupFormattedPrice: string;
    GroupPrice: number;
    GroupScreenName: string;
    Items: PaxFareOrFee[];
}

export interface PaxFareOrFee {
    FormattedPrice: string;
    Note: string;
    ScreenName: string;
    ChargeCode: string;
}

export interface ItineraryJourney {
    AmountOfUncheckedPax: number;
    ArrivalStationCode: string;
    ArrivalStationName: string;
    BundleCode: string;
    BundleInfo: BundleInfo;
    BundleType: string;
    CheckinBeforeTime: string;
    DepartureStationCode: string;
    DepartureStationName: string;
    EncodedSellKey: string;
    FlightNumber: string;
    CheckinWindowOpenDateUtc: string;
    IsFlown: boolean;
    IsModificationPossible: boolean;
    JourneyIndex: number;
    JourneyPassengers: ItineraryJourneyPassenger[];
    Segments: JourneySegment[];
    CheckinStatus: CheckinStatus;
}

type CheckinStatus =
    | "AdckNeeded"
    | "AllCheckedIn"
    | "DepartedWithCheckedInPax"
    | "DepartedWithNoPax"
    | "DepartureTooClose"
    | "DepartureTooFar"
    | "DisabledSsrs"
    | "Enabled"
    | "PendingPayment"
    | "SkipPostBooking"
    | "NotAvailable";

export interface ItineraryJourneyPassenger {
    AirportCheckin: ItineraryPurchase;
    CabinBag: ItineraryPurchase;
    CheckedInBag: ItineraryPurchase;
    FirstName: string;
    FormattedOptionalsTotal: string;
    Insurance: ItineraryPurchase;
    LastName: string;
    Optionals: ItineraryJourneyPaxSsr[];
    OversizedBaggage: ItineraryPurchase;
    PassengerNumber: number;
    PetInCabin: ItineraryPurchase;
    PriorityBoarding: ItineraryPurchase;
    SeatSelection: ItineraryPurchase;
    Ssrs: ItineraryJourneyPaxSsrV2[];
    Type: PaxType;
}

export interface ItineraryJourneyPaxSsrV2 {
    FeeCode: string;
    IsInBundle: boolean;
    Note: string;
    Quantity: number;
    ScreenName: string;
    SsrCode: string;
}

export interface ItineraryPurchase {
    MobileText: string;
    PurchasedCount: number;
    Text: string;
}

export interface ItineraryJourneyPaxSsr {
    FormattedPrice: string;
    Note: string;
    Price: number;
    Quantity: string;
    ScreenName: string;
    ChargeCode: string;
}

export interface ItineraryMinimumPrice {
    FormattedPrice: string;
    Type: ItineraryAncillaryType;
}

export interface ItineraryBannerUrls {
    NonSmart: string;
    NonSmartMobile: string;
    Smart: string;
    SmartMobile: string;
}

export enum ItineraryAncillaryType {
    None = "None",
    AirportCheckin = "AirportCheckin",
    PriorityBoarding = "PriorityBoarding",
    CabinBag = "CabinBag",
    CheckedInBag = "CheckedInBag",
    OversizedBag = "OversizedBag",
    SeatSelection = "SeatSelection",
    PetInCabin = "PetInCabin",
    Insurance = "Insurance",
    GiftCard = "GiftCard",
}
export interface ContactPassenger {
    Name: string;
    PaxAddress: PassengerAddress;
    PaxIndex: number;
}
export interface PassengerAddress {
    Address: string;
    City: string;
    Country: string;
    Email: string;
    PhoneNumber: string;
    PhonePrefix: string;
}

export type InvoluntaryChangeStatus = "NotAllowed" | "AllowedOnline" | "AllowedManual";

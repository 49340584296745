import { ThreeDSecureHelper } from "../../component-helpers/payment/ThreeDSecureHelper";
import { PaymentMethod } from "../../component-models/PaymentMethodDescriptors";
import { UserContext } from "../../component-models/app/UserContext";
import { CardData } from "../../component-models/payment/CardData";
import { PaymentIntentFlowType } from "../../component-models/payment/PaymentIntentFlowType";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PayerData } from "../../component-models/payment/PaymentUserData";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";
import { AGENCY_PAYMENT_FOP_CODE, BANCO_ESTADO_ADDRESS_PREFIX, JS_ADDRESS } from "../../shared/commonConstants";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../shared/customHooks/useAjax/useAjax";

export interface BookingCommitPostFactoryDto {
    antiForgeryToken: string;
    bookingCurrency: string;
    cardData: CardData;
    displayedTotal: number;
    extraFieldValue: string;
    model: PaymentPageViewModel;
    payerData: PayerData;
    selectedAgencyPaymentAmount: string;
    selectedCurrency: string;
    selectedPaymentMethod: PaymentMethod;
    type: PaymentIntentFlowType;
    userContext: UserContext;
    voucherResult: ApiVoucherResult;
}

export const bookingCommitPostFactory = () => {
    const is3DSecureNeeded = (data: BookingCommitPostFactoryDto) =>
        data.model.Settings3DS.CountrySettings.some(
            (countrySetting) =>
                countrySetting.CardIssuerCountry === data.payerData?.CurrentCardIssuerCountry &&
                countrySetting.Settings3DS.some(
                    (setting) =>
                        (setting.PaymentMethodCode === data.cardData.PaymentMethodCodeToSubmit ||
                            setting.PaymentMethodCode === data.selectedPaymentMethod.PaymentMethodCode) &&
                        setting.IsActive,
                ),
        );

    const getCommonSingleFields = (data: BookingCommitPostFactoryDto): Map<string, string> => {
        const antiFraudCardType =
            data.selectedPaymentMethod?.PaymentMethodType === "Credit"
                ? "CreditCard"
                : data.selectedPaymentMethod?.PaymentMethodType === "Debit"
                  ? "DebitCard"
                  : "";

        const paymentMethodCode =
            data.cardData.PaymentMethodCodeToSubmit || data.selectedPaymentMethod.PaymentMethodCode;

        const paymentText = data.payerData?.IsTodosumaConsentAccepted
            ? `Puntos Todos selected :: ${data.userContext.bancoEstado.todosumaPoints}`
            : data.selectedPaymentMethod.PaymentMethodType;

        return new Map<string, string>([
            ["antifraudCardBrand", data.cardData?.CardType || ""],
            ["antifraudCardType", antiFraudCardType],
            ["antifraudInstallments", data.cardData?.SelectedInstallmentsNumber?.toString() || "1"],
            ["antifraudBin", data.cardData?.CardNumber?.slice(0, 6) || ""],
            ["antifraudLastFourDigits", data.cardData?.CardNumber?.slice(-4) || ""],
            ["jetSmartExternalPayment.PaymentText", paymentText],
            ["jetSmartExternalPayment.QuotedAmount", data.displayedTotal.toString()],
            ["jetSmartExternalPayment.QuotedCurrencyCode", data.selectedCurrency],
            ["jetSmartPaymentMethod", data.selectedPaymentMethod?.PaymentMethodCode || ""],
            ["PaymentExtraType", data.payerData?.Cuit ? "CUIT" : data.cardData.Ruc ? "RUC" : ""],
            ["PaymentExtraValue", data.payerData?.Cuit || data.cardData.Ruc || ""],
            ["PaymentMethodCode", paymentMethodCode],
            ["PaymentMethodType", "ExternalAccount"],
            ["ToDoSumaConsentAccepted", data.payerData?.IsTodosumaConsentAccepted ? "true" : "false"],
        ]);
    };

    const getCardSingleFields = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.selectedPaymentMethod.AllowedCards.length > 0
            ? new Map([
                  ["jetSmartExternalPayment.AccountNumber", data.cardData.CardNumber],
                  ["jetSmartExternalPayment.CardholderName", data.cardData.CardholderName],
                  ["jetSmartExternalPayment.CVV", data.cardData.Cvv],
                  ["jetSmartExternalPayment.Expiration", data.cardData.FormattedExpiry],
              ])
            : new Map();

    const getInstallmentsSingleFields = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.cardData.FieldRequirements.InstallmentType !== "None" ||
        data.selectedPaymentMethod?.ClientSideMethod === "BancoEstado"
            ? new Map([
                  ["jetSmartExternalPayment.Installments", data.cardData.SelectedInstallmentsNumber.toString()],
                  ["jetSmartExternalPayment.InstallmentsPriceCurrency", "CLP"],
                  ["jetSmartExternalPayment.numberOfPayments", data.cardData.SelectedInstallmentsNumber.toString()],
              ])
            : new Map();

    /* SINGLE FIELDS FOR SPECIFIC PAYMENTS */

    const getSingleFieldMap = (data: BookingCommitPostFactoryDto) =>
        new Map<PaymentIntentFlowType[], Map<string, string>>([
            [
                ["agency", "peruCompra"],
                new Map<string, string>([
                    ["jetSmartCreditFilePayment.AccountNumber", data.model.AgencyViewModel.AccountNumber],
                    ["jetSmartCreditFilePayment.QuotedCurrencyCode", data.bookingCurrency],
                    ["PaymentExtraType", "CUIT"],
                    ["PaymentExtraValue", ""],
                    ["PaymentMethodCode", AGENCY_PAYMENT_FOP_CODE],
                    ["PaymentMethodType", "AgencyAccount"],
                    ["ToDoSumaConsentAccepted", ""],
                    ["jetSmartCreditFilePayment.QuotedAmount", data.selectedAgencyPaymentAmount.replace(",", ".")],
                ]),
            ],
            [
                ["creditShellCoversBalance", "cat1234CreditShellPay", "cat56CreditShellPay"],
                new Map<string, string>([
                    ["jetSmartCreditFilePayment.QuotedCurrencyCode", data.bookingCurrency],
                    ["PaymentMethodCode", "CF"],
                    ["PaymentMethodType", "CustomerAccount"],
                ]),
            ],
            [
                ["hold"],
                new Map<string, string>([
                    ["jetSmartHoldBooking.CommitWithoutPayment", "true"],
                    ["PaymentMethodType", "Custom"],
                ]),
            ],
            [
                ["test"],
                new Map<string, string>([
                    ["PaymentMethodCode", "DG"],
                    ["PaymentMethodType", "PrePaid"],
                    ["testCashPayment.QuotedAmount", Number(data.model.BookingViewModel.BalanceDue).toString()],
                    ["testCashPayment.QuotedCurrencyCode", data.bookingCurrency],
                ]),
            ],
            [
                ["voucherPay", "voucherCoversBalance"],
                new Map<string, string>([
                    ["PaymentExtraType", data.payerData?.Cuit ? "CUIT" : ""],
                    ["PaymentExtraValue", data.payerData?.Cuit || ""],
                    ["PaymentMethodCode", data.voucherResult?.paymentMethodCode],
                    ["PaymentMethodType", data.voucherResult?.paymentMethodType],
                    ["VoucherCode", data.voucherResult?.accountNumber],
                    ["voucherPayment.AccountNumber", data.voucherResult?.accountNumber],
                    ["voucherPayment.QuotedAmount", data.voucherResult?.unformattedQuotedAmount.toString()],
                ]),
            ],
        ]);

    const getDoubleInputHtmlElementsFromMap = (map: Map<string, string>): HTMLInputElement[] =>
        Array.from(map.entries()).reduce((aggr, [key, value], index) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = `jetSmartExternalPayment.PaymentFields[${index}].FieldName`;
            input.value = key;

            const input2 = document.createElement("input");
            input2.type = "hidden";
            input2.name = `jetSmartExternalPayment.PaymentFields[${index}].FieldValue`;
            input2.value = value;

            return aggr.concat([input, input2]);
        }, [] as HTMLInputElement[]);

    const getCommonDoubleFields = (data: BookingCommitPostFactoryDto) => {
        const address = `${
            // DEVNOTE This is to tell Ingenico that the card used is a BancoEstado card :-)
            data.userContext.bancoEstado.category !== 0 && data.selectedPaymentMethod?.PaymentMethodType === "Debit"
                ? BANCO_ESTADO_ADDRESS_PREFIX
                : ""
        }${JS_ADDRESS}`;

        // DEVNOTE If more need occurs, not only forced Transbank, then we should introduce a
        // fake country code in the XML under AlternateCode, but for now, this will do fine.
        // It is "CL" because Transbank (the only current alternative code) only accepts this.
        // TODO Currently there is no Transbank (no forced alternate payment), but the possibility remains.
        const country =
            data.cardData.AlternateCode?.ForcedCurrencies?.length > 0
                ? "CL"
                : data.payerData?.CurrentCardIssuerCountry || "";

        return new Map<string, string>([
            ["Amount", data.displayedTotal.toString()],
            ["AMT", data.displayedTotal.toString()],
            ["Avs::Address1", address],
            ["Avs::City", "Santiago"],
            ["Avs::Country", country],
            ["BillTo::IPAddress", data.model.Ip],
            ["IPAddress", data.model.Ip],
            ["PostalCode", "8581151"],
        ]);
    };

    const getCardDoubleFields = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.selectedPaymentMethod.AllowedCards.length > 0
            ? new Map([
                  ["CC::AccountHolderName", data.cardData.CardholderName],
                  ["CC::VerificationCode", data.cardData.Cvv],
                  ["EXPDAT", data.cardData.FormattedExpiry],
              ])
            : new Map();

    const getInstallmentsDoubleField = (data: BookingCommitPostFactoryDto): Map<string, string> => {
        const installments =
            data.cardData.FieldRequirements.InstallmentType !== "None" ||
            data.selectedPaymentMethod?.ClientSideMethod === "BancoEstado"
                ? data.cardData.SelectedInstallmentsNumber.toString()
                : "1";

        return new Map([["CC::Installments", installments]]);
    };

    const getCallbackUrlDoubleField = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.cardData.FieldRequirements.IsCallbackUrlNeeded
            ? new Map([
                  ["WEB::CANCELURL", data.model.MethodsViewModel.CallbackUrl],
                  ["WEB::DECLINEURL", data.model.MethodsViewModel.CallbackUrl],
                  ["WEB::EXCEPTIONURL", data.model.MethodsViewModel.CallbackUrl],
                  ["WEB::RETURNURL", data.model.MethodsViewModel.CallbackUrl],
              ])
            : new Map();

    const getDeviceTokenDoubleField = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.cardData.FieldRequirements.IsMMDeviceTokenNeeded
            ? new Map([["DeviceToken", window.MP_DEVICE_SESSION_ID ? window.MP_DEVICE_SESSION_ID : null]])
            : new Map();

    const getThreeDSecureDoubleFields = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        is3DSecureNeeded(data)
            ? new Map([
                  ["Browser::AcceptHeader", data.model.AcceptHeaders],
                  ["Browser::ColorDepth", ThreeDSecureHelper.threeDSBrowserColorDepth()],
                  ["Browser::JavaEnabled", ThreeDSecureHelper.threeDSBrowserJavaEnabled()],
                  ["Browser::Language", ThreeDSecureHelper.threeDSBrowserLanguage()],
                  ["Browser::ScreenHeight", ThreeDSecureHelper.threeDSBrowserScreenHeight()],
                  ["Browser::ScreenWidth", ThreeDSecureHelper.threeDSBrowserScreenWidth()],
                  ["Browser::TimeZone", ThreeDSecureHelper.threeDSBrowserTimeZone()],
                  ["Browser::UserAgent", ThreeDSecureHelper.threeDSBrowserUserAgent()],
              ])
            : new Map();

    const getMercadoPagoDoubleFields = (data: BookingCommitPostFactoryDto): Map<string, string> =>
        data.cardData.MerchantAccountId && data.cardData.PaymentMethodOptionId
            ? new Map([
                  ["MerchantAccountID", data.cardData.MerchantAccountId],
                  ["PaymentMethodOptionID", data.cardData.PaymentMethodOptionId],
              ])
            : new Map();

    const getCompraquiDoubleField = (type: PaymentIntentFlowType, extraFieldValue: string): Map<string, string> =>
        type === "compraqui" ? new Map([["BE:Token", extraFieldValue]]) : new Map();

    const getCheckoutProDoubleField = (type: PaymentIntentFlowType, extraFieldValue: string): Map<string, string> =>
        type === "checkoutPro" ? new Map([["MP:Token", extraFieldValue]]) : new Map();

    const getSafetyPayDoubleField = (type: PaymentIntentFlowType, extraFieldValue: string): Map<string, string> =>
        type === "safetyPay" ? new Map([["SafetyPay:OpId", extraFieldValue]]) : new Map();

    const getSingleInputHtmlElements = (data: BookingCommitPostFactoryDto) => [
        ...getSingleInputHtmlElementsFromMap(getCommonSingleFields(data)),
        ...getSingleInputHtmlElementsFromMap(getCardSingleFields(data)),
        ...getSingleInputHtmlElementsFromMap(getInstallmentsSingleFields(data)),
    ];

    /* SINGLE FIELDS FOR REGULAR PAYMENTS */
    // DEVNOTE Single field: one hidden input field with name and value

    const getSingleInputHtmlElementsFromMap = (map: Map<string, string>): HTMLInputElement[] =>
        Array.from(map.entries()).map(([key, value]) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;

            return input;
        });

    /* DOUBLE FIELDS */
    // DEVNOTE One input element for the field name, and another input element for the field value

    const getDoubleInputHtmlElements = (data: BookingCommitPostFactoryDto) => [
        ...getDoubleInputHtmlElementsFromMap(
            new Map([
                ...getCommonDoubleFields(data).entries(),
                ...getMercadoPagoDoubleFields(data).entries(),
                ...getThreeDSecureDoubleFields(data).entries(),
                ...getDeviceTokenDoubleField(data).entries(),
                ...getCallbackUrlDoubleField(data).entries(),
                ...getInstallmentsDoubleField(data).entries(),
                ...getCardDoubleFields(data).entries(),
                ...getCompraquiDoubleField(data.type, data.extraFieldValue || "").entries(),
                ...getCheckoutProDoubleField(data.type, data.extraFieldValue || "").entries(),
                ...getSafetyPayDoubleField(data.type, data.extraFieldValue || "").entries(),
            ]),
        ),
    ];

    const getInputHtmlElements = (data: BookingCommitPostFactoryDto): HTMLInputElement[] => {
        // DEVNOTE If the map key is an array, there is no use trying .get() method on the map
        const maps = Array.from(getSingleFieldMap(data).entries());
        const specialInputFieldsMap = maps.find(([key, _]) => key.includes(data.type));

        return specialInputFieldsMap
            ? getSingleInputHtmlElementsFromMap(specialInputFieldsMap[1])
            : [...getSingleInputHtmlElements(data), ...getDoubleInputHtmlElements(data)];
    };

    // EXPORT

    const createFormPost = (data: BookingCommitPostFactoryDto) => {
        const form = document.createElement("form");
        form.style.display = "none";

        const body = {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: data.antiForgeryToken,
        };

        Array.from(Object.keys(body)).forEach((key) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = body[key as keyof typeof body];
            form.appendChild(input);
        });

        getInputHtmlElements(data).forEach((field) => form.appendChild(field));

        document.body.appendChild(form);

        return form;
    };

    return { createFormPost };
};

import { useMemo, useState } from "./../../shared/haunted/CustomHooks";
import {
    CHILEAN_CULTURE_CODE,
    ECUADORIAN_CULTURE_CODE,
    ECUADOR_COUNTRY_CODE,
    ECUADOR_RUC_MAX_LENGTH,
    PREFIX_CODES,
} from "./../../shared/commonConstants";
import i18next from "i18next";
import {
    getTaxnumberCodeByCountry,
    handleCugLoader,
    mapCountryCallingCodeListToSelectOptions,
    sanitizeRutFieldValue,
    SelectOption,
} from "./../../shared/common";
import { html, useRef } from "haunted";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import { ref } from "../../directives/ref";
import { validateEcuadorianRuc, validateRut } from "../../shared/form-validation";
import { sanitizeChileanPhoneInput, sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { validatePassword } from "../../validator/validation-helper";
import { useAvailabilityValidator } from "../../validator/useAvailabilityValidator";
import { ROUTES } from "../../shared/apiRoutes";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";

export const name = "ac-cug-register-admin";

export interface Properties {
    vm: AdminVM;
    goBack: () => void;
    goForward: () => void;
    setVm: (newState: AdminVM) => void;
}

export interface AdminVM {
    confirmPassword: string;
    countryCode: string;
    documentNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
    phonePrefix: string;
    userName: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        vm: host.vm,
        goBack: host.goBack,
        goForward: host.goForward,
        setVm: host.setVm,
    };

    const validateUserName = async (value: string) => {
        if (userName.current?.contains(document.activeElement)) return true;

        const loaderName = `loadData-${Date.now()}`;
        handleCugLoader(root.current, loaderName);
        const result = await userNameValidator.validate(value);
        handleCugLoader(root.current, loaderName);
        return result;
    };

    const sanitizeDocumentNumber = (e: KeyboardEvent) => {
        if (cug2AppContext.Culture === ECUADORIAN_CULTURE_CODE) {
            return sanitizeInputFieldValue(e, "numeric");
        }

        if (getTaxnumberCodeByCountry(props.vm.countryCode, true).toLowerCase() !== "rut") {
            return sanitizeInputFieldValue(e, "travel-document-id");
        }

        return sanitizeRutFieldValue(e.target as HTMLInputElement);
    };

    // EVENT HANDLERS

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        await validator.validateAndDispatch();
    };

    const handleCountryChange = (value: string) => props.setVm({ ...props.vm, countryCode: value, documentNumber: "" });

    const handleDocumentNumberInput = (value: string) => props.setVm({ ...props.vm, documentNumber: value });

    const handlePhonePrefixSelect = (value: string) =>
        props.setVm({ ...props.vm, phonePrefix: value, phoneNumber: "" });

    const handlePhoneInput = (value: string) => props.setVm({ ...props.vm, phoneNumber: value });

    const handleFirstNameInput = (value: string) => props.setVm({ ...props.vm, firstName: value });

    const handleLastNameInput = (value: string) => props.setVm({ ...props.vm, lastName: value });

    const handlePasswordInput = (value: string) => props.setVm({ ...props.vm, password: value });

    const handleConfirmPasswordInput = (value: string) => props.setVm({ ...props.vm, confirmPassword: value });

    const handleEmailInput = (value: string) => props.setVm({ ...props.vm, email: value });

    const handleUserNameInput = (value: string) => props.setVm({ ...props.vm, userName: value });

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);
    const userName = useRef<HTMLInputElement>(null);

    const [isValidated, setIsValidated] = useState<boolean>(false);

    const cug2AppContext = useCug2AppContext();

    const userNameValidator = useAvailabilityValidator<{ UserName: string }, { IsUserExist: boolean }>({
        bodyKey: "UserName",
        container: root.current,
        method: "POST",
        responseKey: "IsUserExist",
        url: ROUTES.ApiRoutes.CheckUserExist,
    });

    const validator = useFluentValidator<keyof AdminVM, AdminVM>({
        vm: props.vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("firstName", (vm: AdminVM) => vm.firstName)
                .isRequired()
                .max(32),
            Validation.ruleFor("lastName", (vm: AdminVM) => vm.lastName)
                .isRequired()
                .max(32),
            Validation.ruleFor("email", (vm: AdminVM) => vm.email)
                .isRequired()
                .isEmail()
                .max(266),
            Validation.ruleFor("userName", (vm: AdminVM) => vm.userName)
                .isRequired()
                .max(266)
                .fulfils((value) => validateUserName(value), i18next.t("This username is not available.")),
            Validation.ruleFor("countryCode", (vm: AdminVM) => vm.countryCode).isRequired(),
            Validation.ruleFor("documentNumber", (vm: AdminVM) => vm.documentNumber).isRequired(),
            Validation.ruleFor("documentNumber", (vm: AdminVM) => vm.documentNumber)
                .when((vm: AdminVM) => getTaxnumberCodeByCountry(vm.countryCode, true).toLowerCase() === "rut")
                .fulfils(
                    (documentNumber: string) => Promise.resolve(validateRut(documentNumber)),
                    i18next.t(
                        "Rut inválido, por favor verifica que el rut es correcto y que el formato sea válido. (Ej: 76472472-0)",
                    ),
                ),
            Validation.ruleFor("documentNumber", (vm: AdminVM) => vm.documentNumber)
                .when((vm: AdminVM) => vm.countryCode === ECUADOR_COUNTRY_CODE)
                .max(ECUADOR_RUC_MAX_LENGTH)
                .fulfils(
                    (documentNumber: string) => Promise.resolve(validateEcuadorianRuc(documentNumber)),
                    i18next.t("RUC invalido"),
                ),
            Validation.ruleFor("documentNumber", (vm: AdminVM) => vm.documentNumber)
                .when((vm: AdminVM) => vm.countryCode !== ECUADOR_COUNTRY_CODE)
                .max(12),
            Validation.ruleFor("phonePrefix", (vm: AdminVM) => vm.phonePrefix).isRequired(),
            Validation.ruleFor("phoneNumber", (vm: AdminVM) => vm.phoneNumber)
                .isRequired()
                .max(12),
            Validation.ruleFor("password", (vm: AdminVM) => vm.password)
                .isRequired(
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                    "field",
                )
                .fulfils(
                    async (password: string) => validatePassword(password),
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                ),
            Validation.ruleFor("confirmPassword", (vm: AdminVM) => vm.confirmPassword)
                .isRequired(
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                    "field",
                )
                .fulfils(
                    async (confirmPassword: string) => confirmPassword === props.vm.password,
                    i18next.t("El campo de confirmación de contraseña debe coincidir con el campo de contraseña."),
                ),
        ],
        dispatcher: () => {
            props.goForward();
        },
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const countryOptions = useMemo(
        (): SelectOption[] => [
            { Text: "", Value: "", IsSelected: !props.vm.countryCode },
            ...cug2AppContext.Countries.map((country) => ({
                IsSelected: country.Value === props.vm.countryCode,
                Text: country.Text,
                Value: country.Value,
            })),
        ],
        [cug2AppContext.Countries, props.vm.countryCode],
    );

    const phonePrefixOptions = useMemo(
        (): SelectOption[] => [
            { Text: "", Value: "", IsSelected: !props.vm.phonePrefix },
            ...mapCountryCallingCodeListToSelectOptions(
                cug2AppContext.PhonePrefixes,
                cug2AppContext.Culture,
                props.vm.phonePrefix,
            ),
        ],
        [cug2AppContext.PhonePrefixes, props.vm.phonePrefix],
    );

    // TEMPLATES

    const countryTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/2">
            <ac-select
                .errorMessage=${validator.getMessage("countryCode")}
                .isInvalid=${!validator.isValid("countryCode")}
                .label=${i18next.t("País Origen")}
                .options=${countryOptions}
                .testId=${T.CUG2_REGISTER.ADMIN_COUNTRY}
                .onSelect=${(value: string) => handleCountryChange(value)}
            ></ac-select>
        </div>
    `;

    const docNumberTemplate = () => html`
        <div class="relative mt-[5px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("documentNumber")}
                .isInvalid=${!validator.isValid("documentNumber")}
                .label=${getTaxnumberCodeByCountry(props.vm.countryCode, true)}
                .sanitizer=${sanitizeDocumentNumber}
                .testId=${T.CUG2_REGISTER.ADMIN_DOCUMENT_NUMBER}
                .value=${props.vm.documentNumber}
                .onInput=${(value: string) => handleDocumentNumberInput(value)}
            ></ac-input>
            ${chileanRutTooltipTemplate()}
        </div>
    `;

    const chileanRutTooltipTemplate = () =>
        cug2AppContext.Culture === CHILEAN_CULTURE_CODE
            ? html`
                  <div class="cug-info-icon cug-register">
                      <ac-tooltip
                          .icon=${"?"}
                          .tooltip=${i18next.t(
                              "Para RUT utilice el formato: 11111111-1. Si es otro, favor ingréselo sin puntos ni caracteres especiales.",
                          )}
                      ></ac-tooltip>
                  </div>
              `
            : "";

    const phonePrefixTemplate = () => html`
        <div class="mr-2 mt-[5px] w-1/4 sm:mr-4">
            <ac-select
                .errorMessage=${validator.getMessage("phonePrefix")}
                .isInvalid=${!validator.isValid("phonePrefix")}
                .label=${i18next.t("Código País")}
                .options=${phonePrefixOptions}
                .testId=${T.CUG2_REGISTER.ADMIN_PHONE_PREFIX}
                .onSelect=${(value: string) => handlePhonePrefixSelect(value)}
            ></ac-select>
        </div>
    `;

    const phoneNumberTemplate = () => html`
        <div class="mr-2 mt-[5px] w-3/4">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("phoneNumber")}
                .isInvalid=${!validator.isValid("phoneNumber")}
                .label=${i18next.t("Teléfono")}
                .sanitizer=${(e: KeyboardEvent) =>
                    props.vm.phonePrefix === PREFIX_CODES.get(CHILEAN_CULTURE_CODE)
                        ? sanitizeChileanPhoneInput(e)
                        : sanitizeInputFieldValue(e, "numeric")}
                .testId=${T.CUG2_REGISTER.ADMIN_PHONE_NUMBER}
                .value=${props.vm.phoneNumber}
                .onInput=${(value: string) => handlePhoneInput(value)}
            ></ac-input>
        </div>
    `;

    const phoneTemplate = () => {
        return html` <div class="flex w-full sm:w-1/2">${phonePrefixTemplate()} ${phoneNumberTemplate()}</div> `;
    };

    const firstNameTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("firstName")}
                .isInvalid=${!validator.isValid("firstName")}
                .label=${i18next.t("Nombres")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${T.CUG2_REGISTER.ADMIN_FIRST_NAME}
                .value=${props.vm.firstName}
                .onInput=${(value: string) => handleFirstNameInput(value)}
            ></ac-input>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="mt-[5px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("lastName")}
                .isInvalid=${!validator.isValid("lastName")}
                .label=${i18next.t("Apellidos")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${T.CUG2_REGISTER.ADMIN_LAST_NAME}
                .value=${props.vm.lastName}
                .onInput=${(value: string) => handleLastNameInput(value)}
            ></ac-input>
        </div>
    `;

    const passwordTemplate = () => html`
        <div class="relative mt-[5px] w-full sm:mr-4 sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("password")}
                .isInvalid=${!validator.isValid("password")}
                .label=${i18next.t("Contraseña")}
                .testId=${T.CUG2_REGISTER.ADMIN_PASSWORD}
                .type=${"password"}
                .value=${props.vm.password}
                .onInput=${(value: string) => handlePasswordInput(value)}
            ></ac-input>
            <div class="cug-info-icon cug-register">
                <ac-tooltip
                    .icon=${"?"}
                    .tooltip=${i18next.t(
                        "La contraseña debe contener entre 8 y 16 caracteres e incluir letras y números.",
                    )}
                ></ac-tooltip>
            </div>
        </div>
    `;

    const passwordConfirmationTemplate = () => html`
        <div class="mt-[5px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("confirmPassword")}
                .isInvalid=${!validator.isValid("confirmPassword")}
                .label=${i18next.t("Confirma tu contraseña")}
                .testId=${T.CUG2_REGISTER.ADMIN_CONFIRM_PASSWORD}
                .type=${"password"}
                .value=${props.vm.confirmPassword}
                .onInput=${(value: string) => handleConfirmPasswordInput(value)}
            ></ac-input>
        </div>
    `;

    const emailTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .label=${i18next.t("Correo electrónico administrador / usuario")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail")}
                .testId=${T.CUG2_REGISTER.ADMIN_EMAIL}
                .value=${props.vm.email}
                .onInput=${(value: string) => handleEmailInput(value)}
            ></ac-input>
        </div>
    `;

    const userNameTemplate = () => html`
        <div class="group relative mt-[5px] w-full sm:w-1/2" ref=${ref(userName)}>
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("userName")}
                .isInvalid=${!validator.isValid("userName")}
                .label=${i18next.t("User name")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "user-name")}
                .testId=${T.CUG2_REGISTER.ADMIN_USERNAME}
                .value=${props.vm.userName}
                .onInput=${(value: string) => handleUserNameInput(value)}
            ></ac-input>
            <div class="username-tooltip group-focus-within:block">
                <div class="arrow-box">
                    ${i18next.t(
                        "Este nombre de usuario será utilizado para ingresar a la cuenta de tu organización, recuerda que solo debe contener letras.",
                    )}
                </div>
            </div>
        </div>
    `;

    const buttonsTemplate = () => html`
        <div class="cug2b-register-btn-container">
            <div class="cug2b-register-back-btn" @click=${props.goBack}>
                <i class="js-icon js-circle-chevron-right"></i>
                <span>${i18next.t("Volver")}</span>
            </div>
            <button
                class="rounded-primary-btn"
                @click=${handleSubmit}
                data-test-id=${T.CUG2_REGISTER.ADMIN_CONTINUE_BUTTON}
            >
                ${i18next.t("Continuar")}
            </button>
        </div>
    `;

    const headerTemplate = () => html`
        <div class="cug2b-register-header" data-test-id=${T.CUG2_REGISTER.ADMIN_HEADER}>
            <i class="js-icon-cug js-cug-user"></i>
            <div>
                <h1>${i18next.t("Usuario")}</h1>
                <h2>
                    ${i18next.t(
                        "Ingresa toda la información del administrador de la empresa o agencia. ¡No te preocupes, podrás agregar más administradores, supervidores y agentes, si lo necesitas, una vez ya registrada la organización en el portal. ",
                    )}
                </h2>
            </div>
        </div>
    `;

    return html`
        <div ref=${ref(root)}>
            ${headerTemplate()}
            <div class="cug2b-register-form" data-test-id=${T.CUG2_REGISTER.FORM}>
                <form>
                    <div class="sm:flex md:mb-4">${firstNameTemplate()} ${lastNameTemplate()}</div>
                    <div class="sm:flex md:mb-4">${emailTemplate()} ${userNameTemplate()}</div>
                    <div class="sm:flex md:mb-4">${countryTemplate()} ${docNumberTemplate()}</div>
                    <div class="sm:flex md:mb-4">${phoneTemplate()}</div>
                    <div class="sm:flex md:mb-4">${passwordTemplate()} ${passwordConfirmationTemplate()}</div>
                </form>
                ${formErrors.htmlTemplate()} ${buttonsTemplate()}
            </div>
        </div>
    `;
};

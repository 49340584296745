import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { showLoader } from "../../shared/common";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { XmlInstallmentDropdownOption } from "../../component-models/XmlInstallmentOption";
import { useInstallmentsCommonTemplate } from "./useInstallmentsCommonTemplate";
import { ApiMercadoPagoInstallmentOption } from "../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useBookingManager } from "../../managers/useBookingManager";
import { useAppContext } from "../../managers/useAppContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    amount: number;
    isActive: boolean;
    model: PaymentPageViewModel;
    selectedInstallmentsNumber: number;
    getApiResponse: () => Promise<ApiMercadoPagoInstallmentOption[]>;
    handleInstallmentChange: (selectedInstallmentNumber: number, newTotal: number) => void;
    tealiumLogMercadoPagoInstallmentChange: (value: number) => void;
}

export const useInstallmentsMercadoPagoAggregator = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const { getRelevantMercadoPagoAggregatorApiOption, isInstallmentsDisabledForFlow } = paymentHelper();
    const { formatNumber } = useNumberFormatter();

    const [cardData] = useReduxState("payment.cardData");
    const [payerData] = useReduxState("payment.payer");
    const [selectedCurrency] = useReduxState("payment.selectedCurrency");

    const [dropdownOptions, setDropdownOptions] = useState<XmlInstallmentDropdownOption[]>([]);

    const installmentsCommonTemplate = useInstallmentsCommonTemplate({
        options: dropdownOptions.map((o) => ({ Value: o.Value.toString(), Text: o.Text })),
        selectedInstallmentsNumber: props.selectedInstallmentsNumber,
        handleNumberOfInstallmentsChange: (e, numberOfInstallments) =>
            handleNumberOfInstallmentsChange(e, numberOfInstallments),
    });

    // HELPERS

    const getPostBody = (installments: number) => ({
        "addInterestFee.CardBin": cardData?.CardNumber.substring(0, 6),
        "addInterestFee.CardIssuerCountryCode": payerData?.CurrentCardIssuerCountry,
        "addInterestFee.CurrencyCode": selectedCurrency,
        "addInterestFee.PaymentMethodCode": cardData?.PaymentMethodCodeToSubmit,
        "addInterestFee.SelectedInstallmentCount": installments.toString(),
    });

    // DEVNOTE See JET-7052 for these filters
    // Basically we have to allow installment numbers that are BOTH in the MercadoPago
    // API response and the XML resource as set up by JS, which further filters it for
    // routes, bins, stations and date range.
    // The filters are kept separately for debugging.
    const handleApiResponse = (apiOptions: ApiMercadoPagoInstallmentOption[]) => {
        const optionsToDisplay = getRelevantMercadoPagoAggregatorApiOption({
            amount: props.amount,
            apiOptions,
            cardData,
            culture: appContext.Culture,
            model: props.model,
            selectedCurrency,
            formatNumber,
        });

        setDropdownOptions(optionsToDisplay);
        installmentsCommonTemplate.removeMessage();
    };

    const getApiResponse = async () => {
        const installmentApiOptions = await props.getApiResponse();

        if (!installmentApiOptions) {
            disableOptionsDropdown();
            return;
        }

        const aggregatorOptions = installmentApiOptions.filter(
            (apiOption) => apiOption.ProcessingMode === "aggregator",
        );

        handleApiResponse(aggregatorOptions);
    };

    const disableOptionsDropdown = () => {
        setDropdownOptions([]);
        handleNumberOfInstallmentsChange(undefined, 1);
        installmentsCommonTemplate.removeMessage();
    };

    const showSelector = () =>
        props.isActive && dropdownOptions.length > 0 && !isInstallmentsDisabledForFlow(appContext, flowContext);

    // EVENT HANDLERS

    const handleNumberOfInstallmentsChange = async (e?: MouseEvent, numberOfInstallments?: number) => {
        const selectedInstallmentNumber = numberOfInstallments
            ? numberOfInstallments
            : Number((e.target as HTMLSelectElement).value);

        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });
        const body = getPostBody(selectedInstallmentNumber);

        await bookingManager.postApplyMercadoPagoInterestFee(body, loader);

        const newTotal = dropdownOptions.find((o) => o.Value === selectedInstallmentNumber)?.Total;

        props.handleInstallmentChange(selectedInstallmentNumber, newTotal || 0);

        props.tealiumLogMercadoPagoInstallmentChange(selectedInstallmentNumber);
    };

    // COMPONENT

    useEffect(() => {
        if (!props.isActive) return;

        if (cardData?.CardNumber && cardData.CardType && cardData.CardValidationStatus === "valid") {
            getApiResponse();
        } else {
            setDropdownOptions([]);
        }
    }, [cardData?.CardNumber, cardData?.CardType, cardData?.CardValidationStatus, props.isActive]);

    // TEMPLATES

    const htmlTemplate = () =>
        showSelector()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow is-dirty is-upgraded"
                          >
                              ${installmentsCommonTemplate.htmlTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

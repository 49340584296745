import { ScrollHelper } from "../../shared/ScrollHelper";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { ProfileBancoEstadoWidgetViewModel } from "../../component-models/profile/ProfileBancoEstadoWidgetViewModel";
import { classMap } from "lit-html/directives/class-map";
import { useProfileBancoeOverlay } from "./useProfileBancoeOverlay";

export interface Props {
    model: ProfileBancoEstadoWidgetViewModel;
}

export const useProfileBancoeWidget = (props: Props) => {
    const scrollerClassName1 = "bewc-scroller";
    const scrollerClassName2 = "bewc-scroller-2";

    const availableBenefits =
        props.model.BancoeWidgetModel && props.model.BancoeWidgetModel.AccountCredits
            ? props.model.BancoeWidgetModel.AccountCredits.filter((credit) => credit.UnformattedAvailableAmount > 0)
            : [];

    const usedBenefits =
        props.model.BancoeWidgetModel && props.model.BancoeWidgetModel.AccountCredits
            ? props.model.BancoeWidgetModel.AccountCredits.filter(
                  (credit) => credit.UnformattedAvailableAmount === 0 || credit.IsExpired,
              )
            : [];

    // HELPERS

    // EVENT HANDLERS

    const onClose = () => {
        setIsAvailableOpen(false);
        setIsUsedOpen(false);
    };

    // COMPONENT

    const [isAvailableOpen, setIsAvailableOpen] = useState<boolean>(false);
    const [isUsedOpen, setIsUsedOpen] = useState<boolean>(false);

    const bancoeOverlay = useProfileBancoeOverlay({
        isOpen: isAvailableOpen || isUsedOpen,
        onClose,
    });

    useEffect(() => {
        ScrollHelper.addPerfectScrollbar(scrollerClassName1);
        ScrollHelper.addPerfectScrollbar(scrollerClassName2);
    }, []);

    // TEMPLATES

    const mainClassMap = classMap({
        "bancoe-widget-container": true,
        "open": isAvailableOpen || isUsedOpen,
    });

    const htmlTemplate = () =>
        props.model
            ? html`
                  <div class="profile-widget profile-bancoe-widget">
                      <div class=${mainClassMap}>
                          <div class="bancoe-widget-content">
                              <div class="bewc-header">${i18next.t("Beneficios BancoEstado")}</div>
                              <div class="bewc-container ${isAvailableOpen ? "open" : ""}">
                                  <div class="bewc-opener" @click=${() => setIsAvailableOpen(!isAvailableOpen)}>
                                      <span>${i18next.t("Beneficios Disponibles")}</span>
                                      <div class="flex items-center">
                                          <span class="bewc-label">${i18next.t("Total")}:</span>
                                          <span class="bewc-amount"
                                              >${props.model.BancoeWidgetModel.AvailableAmountFormatted}</span
                                          >
                                          <i class="js-icon js-circle-chevron-right"></i>
                                      </div>
                                  </div>
                                  <div class="bewc-table-header">
                                      <span>${i18next.t("Promoción")}</span>
                                      <span>${i18next.t("Monto")}</span>
                                      <span>${i18next.t("Fecha expiración")}</span>
                                  </div>
                                  <ul class="${scrollerClassName1}">
                                      ${availableBenefits.map(
                                          (benefit) => html`
                                              <li>
                                                  <span
                                                      ><i class="js-icon-be2 js-be2-circle-star"></i>
                                                      ${benefit.Description}</span
                                                  >
                                                  <span>${benefit.AvailableAmount}</span>
                                                  <span>${benefit.Expiry}</span>
                                              </li>
                                          `,
                                      )}
                                  </ul>
                              </div>
                              <div class="bewc-container ${isUsedOpen ? "open" : ""}">
                                  <div class="bewc-opener" @click=${() => setIsUsedOpen(!isUsedOpen)}>
                                      <span>${i18next.t("Beneficios Utilizados y Expirados")}</span>
                                      <div class="flex items-center">
                                          <span class="bewc-label">${i18next.t("Total")}:</span>
                                          <span class="bewc-amount"
                                              >${props.model.BancoeWidgetModel
                                                  .FormattedSumOfUsedAndExpiredCreditFilePayments}</span
                                          >
                                          <i class="js-icon js-circle-chevron-right"></i>
                                      </div>
                                  </div>
                                  <div class="bewc-table-header">
                                      <span>${i18next.t("Promoción")}</span>
                                      <span>${i18next.t("Monto")}</span>
                                  </div>
                                  <ul class="${scrollerClassName2}">
                                      ${usedBenefits.map(
                                          (benefit) => html`
                                              <li>
                                                  <span
                                                      ><i class="js-icon-be2 js-be2-circle-star"></i>
                                                      ${benefit.Description}</span
                                                  >
                                                  <span>${benefit.OriginalAmount}</span>
                                              </li>
                                          `,
                                      )}
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  ${bancoeOverlay.htmlTemplate()}
              `
            : html``;

    return { htmlTemplate, isAvailableOpen, isUsedOpen };
};

import i18next from "i18next";
import { html } from "haunted";
import { TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { useState } from "../shared/haunted/CustomHooks";
import { useModal } from "./shared/useModal";
import { TemplateResult } from "lit-html";

export interface ModifyBookingModal {
    htmlTemplate: () => TemplateResult;
    open: (yesCallback: () => void, noCallback: () => void) => void;
}

export const useModifyBookingModal = (): ModifyBookingModal => {
    const [yesCallback, setYesCallback] = useState<() => () => void>(undefined);
    const [noCallback, setNoCallback] = useState<() => () => void>(undefined);

    const open = (yesCallback: () => void, noCallback: () => void) => {
        setYesCallback(() => yesCallback);
        setNoCallback(() => noCallback);
        modal.open();
    };

    const handleYes = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        modal.close();
        yesCallback();
    };

    const handleNo = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        modal.close();
        noCallback();
    };

    const htmlTemplate = () => html`
        <div class="modal-body">
            ${i18next.t("V2-ModificationInfo")}
            <div class="modal-button-container modification-buttons-container">
                <a
                    @click=${handleYes}
                    class="rounded-primary-btn"
                    data-test-id=${T.COMMON.MODIFY_BOOKING_MODAL_MODIFY_BUTTON}
                >
                    ${i18next.t("V2-Modify")}
                </a>
                <a @click=${handleNo} class="rounded-secondary-btn"> ${i18next.t("V2-Cancel")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        overlay: { testId: T.COMMON.MODIFY_BOOKING_MODAL },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: {
            template: () => html`
                <i class="fas fa-exclamation-circle notification-icon"></i>
                ${i18next.t("V2-ModificationTitle")}
            `,
        },
    });

    return { htmlTemplate: modal.htmlTemplate, open };
};

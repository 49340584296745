import i18next from "i18next";
import { ApiCardDescriptor, ApiPaymentDescriptors } from "../component-models/ApiPaymentDescriptors";
import {
    ApiPaymentMethod,
    ApiCard,
    ApiAlternateCode,
} from "../component-models/ApiPaymentMethodDescriptors";
import {
    CardDescriptor,
    CardIssuerType,
    XmlMercadoPagoInstallmentOption,
    XmlMercadoPagoInterestFee,
    PaymentSettings,
} from "../component-models/PaymentDescriptors";
import {
    PaymentMethodDescriptors,
    PaymentMethod,
    Card,
    AlternateCode,
} from "../component-models/PaymentMethodDescriptors";
import { XmlInstallmentDropdownOption } from "../component-models/XmlInstallmentOption";
import { ApiPaymentPageViewModel } from "../component-models/payment/ApiPaymentPageViewModel";
import { PaymentPageViewModel } from "../component-models/payment/PaymentPageViewModel";
import dayjs from "dayjs";
import { ApiVoucherWithAmount } from "../component-models/payment/ApiVoucherWithAmount";
import { VOUCHER_PAYMENT_METHOD_CODE, VOUCHER_PAYMENT_METHOD_TYPE, VOUCHER_AVAILABLE } from "../shared/commonConstants";
import { ApiVoucherResult } from "../component-models/payment/VoucherResult";
import { ApiPaymentPageMethodsViewModel } from "../component-models/payment/ApiPaymentPageMethodsViewModel";
import { ApiCultureSetting } from "../component-models/payment/ApiCultureSetting";
import { maskCurrenciesForDisplay } from "../shared/common";
import ApiPaymentInstallmentOptions from "../component-models/payment/ApiPaymentInstallmentOptions";

const installmentFee = (amount: number, interestRate: number): number => (amount * interestRate) / 100;

const generateInstallmentText = (data: {
    amount: number;
    currency: string;
    interestFee: XmlMercadoPagoInterestFee;
    interestText: string;
    noInterestText: string;
    formatNumber: (data: {
        amount: number;
        leadingSign?: boolean;
        omitGrouping?: boolean;
        currency?: string;
    }) => string;
}): string =>
    data.interestFee.Text
        ? data.interestFee.Text
        : `${data.interestFee.Number} &times; ${amountPerMonth(
              data.interestFee,
              data.amount,
              data.currency,
              data.formatNumber,
          )} ${maskCurrenciesForDisplay(data.currency)}(${
              data.interestFee.Number === 1
                  ? data.noInterestText
                  : `${data.interestText}: ${data.interestFee.InterestRate}%`
          })`;

const amountPerMonth = (
    intf: XmlMercadoPagoInterestFee,
    amount: number,
    currency: string,
    formatNumber: (data: {
        amount: number;
        leadingSign?: boolean;
        omitGrouping?: boolean;
        currency?: string;
    }) => string,
): string =>
    formatNumber({
        amount: (amount + installmentFee(amount, intf.InterestRate)) / intf.Number,
        leadingSign: true,
        currency,
    });

const mapCardDescriptor = (descriptor: ApiCardDescriptor): CardDescriptor => ({
    ...descriptor,
    Type: descriptor.Type as CardIssuerType,
});

const filterDisabledPaymentMethods = (descriptor: ApiPaymentMethod): boolean => !descriptor.IsDisabled;

const shouldCheckForAntifraud = (cultureSettings: ApiCultureSetting[], culture: string, method: ApiPaymentMethod) =>
    cultureSettings.some(
        (cultureSetting) =>
            cultureSetting.Culture.toLowerCase() === culture.toLowerCase() &&
            cultureSetting.AntifraudCheckMethods.includes(method.PaymentMethodCode),
    );

const isSafeForAntifraud = (cultureSettings: ApiCultureSetting[], culture: string, method: ApiPaymentMethod) => {
    const isSettingForCulture = (cultureSetting: ApiCultureSetting) =>
        cultureSetting.Culture.toLowerCase() === culture.toLowerCase();

    const noSettingsForCulture = !cultureSettings.some(isSettingForCulture);

    const safeForCulture = cultureSettings.some(
        (cultureSetting) =>
            isSettingForCulture(cultureSetting) && cultureSetting.SafeMethods.includes(method.PaymentMethodCode),
    );

    return noSettingsForCulture || safeForCulture;
};

const mapPaymentDescriptor = (
    cultureSettings: ApiCultureSetting[],
    culture: string,
    method: ApiPaymentMethod,
): PaymentMethod => {
    if (
        isSafeForAntifraud(cultureSettings || [], culture, method) &&
        shouldCheckForAntifraud(cultureSettings || [], culture, method)
    ) {
        throw new Error(
            `Payment method ${method.PaymentMethodCode} is both safe and unsafe (should be checked for antifraud).`,
        );
    }

    return {
        ...method,
        AllowedCards: method.AllowedCards.filter(filterDisabledCardTypes).map(mapAllowedCardType),
        InputFieldRequirements: {
            ...method.InputFieldRequirements,
            InstallmentType:
                method.InputFieldRequirements.InstallmentType === "None"
                    ? "None"
                    : method.InputFieldRequirements.InstallmentType === "MercadoPago"
                      ? "MercadoPago"
                      : method.InputFieldRequirements.InstallmentType === "XML"
                        ? "XML"
                        : "Compraqui",
        },
        IsSafeForAntifraud: isSafeForAntifraud(cultureSettings || [], culture, method),
        PaymentMethodType: method.PaymentMethodType,
        ShouldCheckForAntifraud: shouldCheckForAntifraud(cultureSettings || [], culture, method),
    };
};

const filterDisabledCardTypes = (type: ApiCard): boolean => !type.IsDisabled;

const mapAllowedCardType = (type: ApiCard): Card => ({
    ...type,
    Type: type.Type as CardIssuerType,
    AlternateCodes: type.AlternateCodes.map(mapAlternateCode),
});

const mapAlternateCode = (altCode: ApiAlternateCode): AlternateCode => ({
    ...altCode,
    InputFieldRequirements: altCode.InputFieldRequirements
        ? {
              ...altCode.InputFieldRequirements,
              InstallmentType:
                  altCode.InputFieldRequirements.InstallmentType === "None"
                      ? "None"
                      : altCode.InputFieldRequirements.InstallmentType === "MercadoPago"
                        ? "MercadoPago"
                        : altCode.InputFieldRequirements.InstallmentType === "XML"
                          ? "XML"
                          : "Compraqui",
          }
        : undefined,
});

const mapInterestFeeToXmlInstallmentOption = (data: {
    amount: number;
    currency: string;
    interestFee: XmlMercadoPagoInterestFee;
    interestText: string;
    noInterestText: string;
    formatNumber: (data: {
        amount: number;
        leadingSign?: boolean;
        omitGrouping?: boolean;
        currency?: string;
    }) => string;
}): XmlInstallmentDropdownOption => ({
    Value: data.interestFee.Number,
    Message: i18next.t("Installment-InterestMessage {{-ph0}}{{-ph1}}{{-ph2}}{{-ph3}}", {
        ph0: maskCurrenciesForDisplay(data.currency),
        ph1: data.formatNumber({
            amount: installmentFee(data.amount, data.interestFee.InterestRate),
            currency: data.currency,
            leadingSign: true,
        }),
        ph2: `${data.interestFee.InterestRate}%`,
        ph3: amountPerMonth(data.interestFee, data.amount, data.currency, data.formatNumber),
    }),
    Fee: installmentFee(data.amount, data.interestFee.InterestRate),
    Rate: data.interestFee.InterestRate,
    Currency: data.currency,
    Text: generateInstallmentText({
        amount: data.amount,
        currency: data.currency,
        interestFee: data.interestFee,
        interestText: data.interestText,
        noInterestText: data.noInterestText,
        formatNumber: data.formatNumber,
    }),
    Total: data.amount + installmentFee(data.amount, data.interestFee.InterestRate),
});

// DEVNOTE Strictly speaking, the type conversions are not needed, but the Api.. ones are enums from the
// backend, so this helps identify problems if the backend changes.

const mapPaymentDescriptors = (descriptor: ApiPaymentDescriptors): PaymentSettings => ({
    CardDescriptors: descriptor.CardDescriptors.map(mapCardDescriptor),
    FopOverrides: descriptor.FopOverrides,
    MercadoPagoInstallmentBranchIds: descriptor.MercadoPagoInstallmentBranchIds,
    MercadoPagoAggregatorInstallments: descriptor.MercadoPagoAggregatorInstallments,
    NoInterestFeeText: descriptor.NoInterestFeeText,
    InterestFeeText: descriptor.InterestFeeText,
});

const mapPaymentMethodDescriptors = (
    methodsViewModel: ApiPaymentPageMethodsViewModel,
    culture: string,
): PaymentMethodDescriptors => ({
    PaymentMethods: methodsViewModel.PaymentMethods.PaymentMethods.filter(filterDisabledPaymentMethods).map((method) =>
        mapPaymentDescriptor(methodsViewModel.AntifraudSettings.CultureSettings, culture, method),
    ),
});

// EXPORTS

export const defaultInstallmentOptions = () => [
    {
        Value: 1,
        Message: "",
        Fee: 0,
        Rate: 0,
        Currency: "",
        Text: "1",
        Total: 0,
    },
];

export const mapToInstallmentOptions = (options: ApiPaymentInstallmentOptions): XmlInstallmentDropdownOption[] => {
    if (!options?.Options?.length) {
        return defaultInstallmentOptions();
    }
    return options.Options.map(
        (o): XmlInstallmentDropdownOption => ({
            Value: o.InstallmentCount,
            Message: o.InstallmentMessage,
            Fee: o.UnformattedFee,
            Rate: o.UnformattedRate,
            Currency: o.CurrencyCode,
            Text: o.Text,
            Total: o.TotalAmount,
        }),
    );
};

export const mapToVoucherResult = (voucherWithAmount: ApiVoucherWithAmount): ApiVoucherResult => ({
    accountNumber: voucherWithAmount.Voucher.VoucherCode,
    paymentMethodCode: VOUCHER_PAYMENT_METHOD_CODE,
    paymentMethodType: VOUCHER_PAYMENT_METHOD_TYPE,
    quotedAmount: voucherWithAmount.VoucherAmount.FormattedRedeemableAmount,
    unformattedQuotedAmount: Number(voucherWithAmount.VoucherAmount.UnFormattedRedeemableAmount),
    voucherAmount: voucherWithAmount.VoucherAmount.FormattedRedeemableAmount,
    voucherStatus: VOUCHER_AVAILABLE,
    voucherUnformattedAmount: voucherWithAmount.VoucherAmount.UnFormattedRedeemableAmount,
});

export const mapToPaymentPageViewModel = (
    apiModel: ApiPaymentPageViewModel,
    culture: string,
): PaymentPageViewModel => ({
    ...apiModel,
    BookingViewModel: {
        ...apiModel.BookingViewModel,
        StartDate: apiModel.BookingViewModel.FormattedStartDate
            ? dayjs(apiModel.BookingViewModel.FormattedStartDate)
            : undefined,
        EndDate: apiModel.BookingViewModel.FormattedEndDate
            ? dayjs(apiModel.BookingViewModel.FormattedEndDate)
            : undefined,
    },
    MethodsViewModel: {
        ...apiModel.MethodsViewModel,
        PaymentMethods: mapPaymentMethodDescriptors(apiModel.MethodsViewModel, culture),
        PaymentSettings: mapPaymentDescriptors(apiModel.MethodsViewModel.PaymentSettings),
    },
    ContactViewModel: {
        ...apiModel.ContactViewModel,
    },
});

export const mapXmlMercadoPagoApiOptionsToXmlDropdownOptions = (data: {
    installmentOptions: XmlMercadoPagoInstallmentOption[];
    amount: number;
    currency: string;
    interestText: string;
    noInterestText: string;
    culture: string;
    formatNumber: (data: {
        amount: number;
        leadingSign?: boolean;
        omitGrouping?: boolean;
        currency?: string;
    }) => string;
}): XmlInstallmentDropdownOption[] =>
    data.installmentOptions.reduce(
        (aggr: XmlInstallmentDropdownOption[], item) =>
            aggr.concat(
                item.InterestFees.map((interestFee) =>
                    mapInterestFeeToXmlInstallmentOption({
                        amount: data.amount,
                        currency: data.currency,
                        interestFee,
                        interestText: data.interestText,
                        noInterestText: data.noInterestText,
                        formatNumber: data.formatNumber,
                    }),
                ),
            ),
        [],
    );

import { TemplateResult, html } from "lit-html";
import { ApiJourneyServiceInfo, ApiPassengerServiceInfo, ApiReview } from "../../../component-models/review/ApiReview";
import i18next from "i18next";
import { reviewTableTemplate } from "./reviewTableTemplate";
import { commonReviewTemplates } from "./commonReviewTemplates";

export const baggageReview = (model: ApiReview) => {
    const { desktopReviewTable, mobileReviewTable } = reviewTableTemplate();
    const { paxLabelTemplate, paxNameTemplate, previousTemplate, currentTemplate, routeTemplate } =
        commonReviewTemplates();

    const getTable = (paxChange: ApiPassengerServiceInfo) =>
        new Map<string, (string | TemplateResult)[]>([
            [paxLabelTemplate(paxChange), [paxNameTemplate(paxChange)]],
            [i18next.t("Confirm-Bag-PreviousLabel"), [previousTemplate(paxChange, i18next.t("Review-NoBaggage"))]],
            [i18next.t("Confirm-Bag-CurrentLabel"), [currentTemplate(paxChange, i18next.t("Review-NoBaggage"))]],
        ]);

    const desktopTemplate = (paxChange: ApiPassengerServiceInfo) => html`${desktopReviewTable(getTable(paxChange))}`;

    const mobileTemplate = (paxChange: ApiPassengerServiceInfo) => html`${mobileReviewTable(getTable(paxChange))}`;

    const journeyTemplate = (journey: ApiJourneyServiceInfo) => html`
        <div class="inner-deep-box">
            <div class="flight-container hidden-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleBaggage")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map(desktopTemplate)}
            </div>

            <div class="flight-container visible-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleBaggage")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map(mobileTemplate)}
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        model.BaggageChanges?.length
            ? html`
                  <div>
                      <div class="inner-deep-box-header">${i18next.t("ChangesInBaggage")}</div>
                  </div>
                  ${model.BaggageChanges.map(journeyTemplate)}
              `
            : "";

    return { htmlTemplate };
};

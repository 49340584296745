import { StaffBenefitDiscount } from "./../../component-models/staff-benefit/StaffBenefitDiscount";
import { classMap } from "lit-html/directives/class-map";
import { ROUTES } from "./../../shared/apiRoutes";
import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { STAFF_DISCOUNT_80 } from "../../shared/commonConstants";
import { getParsedProperty } from "../../shared/common";

export const name = "ac-staff-benefit";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
    model: string;
}

export interface Props {
    model: StaffBenefitDiscount;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model:
            typeof host.model === "string" && (host.model as unknown as string).trim().length > 0
                ? getParsedProperty<StaffBenefitDiscount>(host.model)
                : undefined,
    };

    // HELPERS

    const getAll80PercentTrips = () =>
        props.model.AvailableDiscounts.filter((d) => d.DiscountCode === STAFF_DISCOUNT_80).reduce(
            (aggr, discount) => aggr + discount.PassengerSegmentAvailable + discount.PassengerSegmentUsed,
            0,
        );

    const getUsed80PercentTrips = () =>
        props.model.AvailableDiscounts.filter((d) => d.DiscountCode === STAFF_DISCOUNT_80).reduce(
            (aggr, discount) => aggr + discount.PassengerSegmentUsed,
            0,
        );

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATE

    const employeDiscountHeaderTemplate = () => html`
        <div class="sb-header">
            <div class="sb-header-text">
                <div>${i18next.t("Conéctate")}</div>
                <div class="sb-header-smart">SMART</div>
            </div>
        </div>
    `;

    const bannerButtonTemplate = () => html`
        <div class="sb-banner-button-container">
            <a class="rounded-primary-btn blue-white" href=${ROUTES.PageStaffFamilyMembers}>
                ${i18next.t("Ver más staff benefit")}
            </a>
        </div>
    `;

    const bannerInfoDescriptionTemplate = () => html`
        <div class="sb-banner-info-description-bottom">
            ${i18next.t(
                "Revisa aquí a todos los beneficiarios que están incluídos para el beneficio. Con esto, cuando hagas una reserva, solo deberás elegir qué pasajeros volarán y ¡listo!",
            )}
            <span class="font-bold">${i18next.t("¿SMART, verdad?")}</span>
        </div>
    `;

    const bannerInfoTitleTemplate = () => html` <div class="sb-banner-info-title">${i18next.t("Beneficiarios")}</div> `;

    const bannerInfoIconTemplate = () => html`
        <div class="sb-banner-icon">
            <i class="js-circle-user js-icon"></i>
        </div>
    `;

    const mobileBannerTemplate = () => html`
        <div class="sb-banner hidden-sm-up">
            <div class="sb-banner-title">${bannerInfoIconTemplate()} ${bannerInfoTitleTemplate()}</div>
            <div class="sb-banner-info">${bannerInfoDescriptionTemplate()}</div>
            ${bannerButtonTemplate()}
        </div>
    `;

    const nonMobileBannerTemplate = () => html`
        <div class="sb-banner hidden-xs">
            ${bannerInfoIconTemplate()}
            <div class="sb-banner-info">${bannerInfoTitleTemplate()} ${bannerInfoDescriptionTemplate()}</div>
            ${bannerButtonTemplate()}
        </div>
    `;

    const fiftyPercentTemplate = () => {
        const id = "fiftyPercentBenefit";

        return html`
            <input id=${id} type="checkbox" class="hidden" />
            <div class="sb-list-elem">
                <label for=${id} class="hidden-sm-up"> ${viewMoreTemplate()} </label>
                <i class="sb-list-icon js-icon-employee-discount js-ed-two-passengers-in-cicle"></i>
                <div class="sb-list-info-title">${i18next.t("Descuento 50% en pasajes ilimitado")}</div>
                <div class="sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Descuento del 50% ilimitado en pasajes para todos los destinos JetSMART, para ti y tu grupo familiar o beneficiario inscrito.",
                        )}
                    </p>
                </div>
                <div class="sb-list-benefit-container">
                    <div class="sb-fifty-percent-container">
                        <div class="sb-fifty-percent-left">
                            <img
                                class="sb-fifty-percent-img"
                                src="/Images/EmployeeDiscount/ed-fifty-percent-dcto.png"
                            />
                        </div>
                        <div class="sb-fifty-percent-right">
                            ${i18next.t("Ilimitado para ti, tu familiar y/o beneficiario inscrito")}
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const discountedTripsTemplate = () => {
        const tempClassMap = classMap({
            "sb-list-elem": true,
        });

        const id = "tripsBenefit";

        return html`
            <input id=${id} type="checkbox" class="hidden" />
            <div class=${tempClassMap}>
                <label for=${id}> ${viewMoreTemplate()} </label>
                <i class="sb-list-icon js-icon-employee-discount js-ed-circling-planes"></i>
                <div class="sb-list-info-title">
                    ${i18next.t("Descuento 80% en pasajes en {{all}} tramos", {
                        all: getAll80PercentTrips().toString(),
                    })}
                </div>
                <div class="sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Para tí y tus beneficiarios inscritos tendrán 24 tramos en total por todo el grupo familiar en cada año calendario con un 80% de descuento en las tarifas",
                        )}
                        <span class="italic">${i18next.t("No acumulable con el 50% de descuento.")}</span>
                    </p>
                </div>
                <div class="sb-list-benefit-container">
                    <div class="sb-discounted-trips-container">
                        <div>${i18next.t("Has utlizado")}:</div>
                        <div>
                            ${i18next.t("{{used}} de {{all}} tramos anuales", {
                                used: getUsed80PercentTrips().toString(),
                                all: getAll80PercentTrips().toString(),
                            })}
                        </div>
                        <div>*${i18next.t("No se consideran vuelos con paradas")}</div>
                    </div>
                </div>
                <div class="sb-list-extra-description sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Este descuento se renueva cada año calendario y los pasajes restantes no utilizados no serán acumulables para el año siguiente, para el cual aplicarán solo los 12 pasajes anuales de rigor por grupo familiar.",
                        )}
                    </p>
                    <p>
                        ${i18next.t(
                            "El trabajador y sus beneficiarios inscritos podrán usar el beneficio en forma independiente. Sólo en el caso del acompañante anual debe viajar siempre junto al colaborador, ambos en calidad de pasajeros, y no aplica el beneficio cuando el trabajador esté trabajando o en servicio en el vuelo.",
                        )}
                    </p>
                    <p>
                        ${i18next.t(
                            "Ambos descuentos no incluyen tasas de embarque, lo que implica que éstos serán siempre de cargo de cada trabajador.",
                        )}
                    </p>
                </div>
                <div class="sb-list-extra-image">
                    <img class="sb-list-discount-family-img" src="/Images/EmployeeDiscount/ed-family.png" />
                </div>
            </div>
        `;
    };

    const giftCardTemplate = () => {
        const id = "giftCardBenefit";

        return html`
            <input id=${id} type="checkbox" class="hidden" />
            <div class="sb-list-elem">
                <label for=${id} class="hidden-sm-up"> ${viewMoreTemplate()} </label>
                <i class="sb-list-icon js-icon-it js-it-giftcard"></i>
                <div class="sb-list-info-title">${i18next.t("Gift card cumpleaños")}</div>
                <div class="sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Adicionalmente, cada año en el día de tu cumpleaños recibirás una Gift Card para compras de pasajes y opcionales, equivalente a 150 USD, la cual tendrá de vigencia de 1 año.",
                        )}
                    </p>
                </div>
                <div class="sb-list-benefit-container">
                    <div class="sb-gift-card-container">
                        <div>
                            ${i18next.t("Tienes 1")}
                            <img class="sb-gift-card-img" src="/Images/EmployeeDiscount/ed-giftcard.png" />
                        </div>
                        <div>
                            <span class="sb-gift-card-pill">${i18next.t("de 150 USD")}</span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const bagTemplate = () => {
        const id = "bagBenefit";

        return html`
            <input id=${id} type="checkbox" class="hidden" />
            <div class="sb-list-elem">
                <label for=${id} class="hidden-sm-up"> ${viewMoreTemplate()} </label>
                <i class="sb-list-icon js-icon-bag js-bag-broken-circle-bag"></i>
                <div class="sb-list-info-title">${i18next.t("Equipaje facturado a $1")}</div>
                <div class="sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Tendrás un (1) equipaje facturado a $1 por cada beneficiario inscrito en las compras realizadas en el sitio web en todos los destinos JetSMART.  A partir del segundo (2) equipaje será costeado por el colaborador con un 50% de descuento.",
                        )}
                    </p>
                </div>
                <div class="sb-list-benefit-container xs-left">
                    <div class="sb-bag-container">
                        <div class="sb-bag-left">
                            <i class="js-icon-bag js-bag-zipper-baggage"></i>
                        </div>
                        <div class="sb-bag-right">
                            <div class="sb-bag-right-top">${i18next.t("Tiene 1 equipaje facturado a $1")}</div>
                            <div class="sb-bag-right-bottom">
                                ${unsafeHTML(
                                    i18next.t(
                                        "{{-spanStart}}y {{- imageTemplate}}{{-spanEnd}} {{-spanStart}}{{-spanStart}}en la 2da{{-spanEnd}}{{-spanStart}}unidad{{-spanEnd}}{{-spanEnd}}",
                                        {
                                            spanStart: "<span>",
                                            spanEnd: "</span>",
                                            imageTemplate: `<img class="sb-bag-text-image" src="/Images/EmployeeDiscount/ed-fifty-percent-dcto.png">`,
                                        },
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const petTemplate = () => {
        const id = "petBenefit";

        return html`
            <input id=${id} type="checkbox" class="hidden" />
            <div class="sb-list-elem">
                <label for=${id} class="hidden-sm-up"> ${viewMoreTemplate()} </label>
                <i class="sb-list-icon js-pet js-icon"></i>
                <div class="sb-list-info-title">${i18next.t("Mascota a bordo a $1")}</div>
                <div class="sb-list-info-description">
                    <p>
                        ${i18next.t(
                            "Podrás llevar a $1 una (1) mascota por tramo, de acuerdo con las condiciones indicadas referente a mascota a bordo vigente en JetSMART.com en la sección de Opcionales.",
                        )}
                    </p>
                </div>
                <div class="sb-list-benefit-container">
                    <div class="sb-pet-container">
                        <div class="sb-pet-left">
                            <i class="js-pet js-icon"></i>
                        </div>
                        <div class="sb-pet-right">
                            ${i18next.t("¡Mascota a bordo a {{-spanStart}}$1{{-spanEnd}} en los viajes que quieras!")}
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const viewMoreTemplate = () => html`
        <span class="closed hidden-sm-up">${i18next.t("Ver más")}</span>
        <span class="opened hidden-sm-up">${i18next.t("Ver menos")}</span>
        <i class="sb-list-opener js-icon js-circle-chevron-right"></i>
    `;

    const employeDiscountContentTemplate = () => html`
        <div class="sb-content">
            <div class="sb-title-container">
                <div class="sb-title">
                    ${i18next.t("Revisa todos los beneficios")}
                    <div class="sb-subtitle">${i18next.t("Conéctate smart")}</div>
                </div>
            </div>
            ${mobileBannerTemplate()} ${nonMobileBannerTemplate()} ${fiftyPercentTemplate()}
            ${discountedTripsTemplate()} ${giftCardTemplate()} ${bagTemplate()} ${petTemplate()}
            <div class="sb-back-button-container">
                <a class="rounded-reverse-btn" href=${ROUTES.PageProfile}> ${i18next.t("Volver")} </a>
            </div>
        </div>
    `;

    return html`
        <div id="mainContentWrapper">
            <section class="booking-wrapper no-breadcrumb staff-benefit-container">
                ${employeDiscountHeaderTemplate()} ${employeDiscountContentTemplate()}
            </section>
        </div>
    `;
};

import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import i18next from "i18next";
import { SeatmapJourneySegment } from "../../../component-models/seatmap/SeatmapJourneySegment";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export const name = "ac-seat-categories-legend";

export interface Props {
    seatmapSegment: SeatmapJourneySegment;
    isMobile: boolean;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        seatmapSegment: host.seatmapSegment,
        isMobile: host.isMobile,
    };

    // HELPERS

    const getSmallestSeatPriceByCategory = (catNumber: number) => {
        const seatWithCategory = props.seatmapSegment?.Seats.filter((seat) => seat.CategoryNumber === catNumber) || [];

        const smallestPrice =
            seatWithCategory.length > 0
                ? seatWithCategory.reduce(
                      (currentMinimum, currSeat) => (currSeat.Price < currentMinimum ? currSeat.Price : currentMinimum),
                      Number.POSITIVE_INFINITY,
                  )
                : -1;

        return smallestPrice;
    };

    const formatSmallestSeatPriceCategory = (catNumber: number) =>
        getSmallestSeatPriceByCategory(catNumber) !== -1
            ? `${i18next.t("DesdeV2")} ${formatNumber({ amount: getSmallestSeatPriceByCategory(catNumber) }).replace(" ", "")}`
            : "Not available";

    // EVENT LISTENERS

    // COMPONENT

    const { formatNumber } = useNumberFormatter();

    // TEMPLATES

    const seatCategoryTemplate = (catNumber: number, catName: string, isXL: boolean) => {
        const iconClassMap = classMap({
            "js-icon-covid": true,
            "js-cv-cat-xl-seat": isXL,
            "js-cv-cat1-seat": !isXL,
        });

        return html`
            <div class="seat-category category-${catNumber}">
                <div class="flex flex-row">
                    <div class="seat-categories-icon-container">
                        <i class=${iconClassMap}></i>
                    </div>
                    <div class="category-description">
                        <span>${i18next.t(catName)}</span>
                        <div class="category-price">
                            <span> ${formatSmallestSeatPriceCategory(catNumber)} </span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const seatCategoryDisabled = () => html`
        <div class="seat-category category-disabled">
            <div class="flex flex-row">
                <div class="seat-categories-icon-container">
                    <i class="js-icon-covid js-cv-cat1-seat"></i>
                </div>
                <div class="category-description">
                    <span>${i18next.t("No Disponible")}</span>
                </div>
            </div>
        </div>
    `;

    const seatCategoriesTemplate = () => html`
        <div class="seat-categories">
            ${seatCategoryTemplate(1, i18next.t("Primera Fila"), true)}
            ${seatCategoryTemplate(4, i18next.t("Full Comodidad"), true)}
            ${seatCategoryTemplate(2, i18next.t("Smart"), false)}
            ${seatCategoryTemplate(3, i18next.t("Salida Rápida"), false)}
            ${seatCategoryTemplate(5, i18next.t("Estandár"), false)} ${seatCategoryDisabled()}
        </div>
    `;

    const id = props.isMobile ? "mobile-categories" : "categories";

    return html`
        <div class="seat-categories-container">
            <input type="checkbox" id=${id} />
            <label for=${id} class="seat-categories-label"
                >${i18next.t("Tipos de Asientos")}
                <span class="open-icon"> <i class="js-circle-chevron-right js-icon"></i> </span
            ></label>
            ${seatCategoriesTemplate()}
        </div>
    `;
};

import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { html } from "haunted";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import i18next from "i18next";
import { useCug2AppContext } from "../../managers/useCug2AppContext";

export const name = "ac-cug-register-result";

export interface Properties {
    bgDesktopImage: string;
    bgTabletImage: string;
    bgMobileImage: string;
    success: boolean;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        bgDesktopImage: host.bgDesktopImage.trim(),
        bgTabletImage: host.bgTabletImage.trim(),
        bgMobileImage: host.bgMobileImage.trim(),
        success: host.success,
    };

    // HELPERS

    const link = () => {
        const country = cug2AppContext.Country.toLowerCase();
        const language = cug2AppContext.Language;
        return `https://jetsmart.com/${country}/${language}/destinos`;
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    // EVENT HANDLERS

    // TEMPLATES

    const buttonTemplate = () => html`
        <div class="cug2b-register-btn-container center pull-up">
            <button class="rounded-primary-btn" @click=${() => (window.location.href = link())}>
                ${i18next.t("Ver destinos")}
            </button>
        </div>
    `;

    const successTemplate = () => html`
        <div class="cug2b-register-success-container">
            <div
                class="cug2b-register-success-banner hidden-sm-down"
                style="background: url('${props.bgDesktopImage}') no-repeat center center"
            ></div>
            <div
                class="cug2b-register-success-banner hidden-md-up hidden-xs"
                style="background: url('${props.bgTabletImage}') no-repeat center center"
            ></div>
            <div
                class="cug2b-register-success-banner hidden-sm-up"
                style="background: url('${props.bgMobileImage}') no-repeat center center"
            ></div>
            <div class="cug2b-register-success-main">
                <i class="js-icon-cug js-cug-man-and-plane"></i>
                <h1 class="uppercase">${i18next.t("¡Felicitaciones!")}</h1>
                <h2>${i18next.t("Tu empresa o agencia ha sido registrada")}</h2>
                <p>
                    ${unsafeHTML(
                        i18next.t(
                            "Recibiras un correo para que confirmes el registro y puedas ingresar al {{-start}}Portal JETSMART Empresas y Agencias{{-end}}",
                            {
                                start: "<span class='font-bold'>",
                                end: "</span>",
                            },
                        ),
                    )}
                </p>
                <div class="cug2b-register-success-footnote">${i18next.t("¡Revisa todos nuestros destinos!")}</div>
                ${buttonTemplate()}
            </div>
        </div>
    `;

    return props.success ? successTemplate() : html``;
};

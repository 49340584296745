import { CLASS_NAMES } from "../../shared/classNames";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { handleFieldBlur } from "../../shared/form-validation";
import { City, Country, Region, RoutePickerModel } from "../../component-models/RoutePickerModel";
import { classMap } from "lit-html/directives/class-map";
import PerfectScrollbar from "perfect-scrollbar";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";

export interface Props {
    activeCountry: Country;
    dataTestId: string;
    inputTestId: string;
    isActive: boolean;
    isDisabled: boolean;
    isTyping: boolean;
    journeyIndex: number;
    model: RoutePickerModel;
    optionsDataTestId: string;
    placeHolder: string;
    root: HTMLDivElement;
    selectedCityCode: string;
    value: string;
    onCityClick: (city: City) => void;
    onClose: () => void;
    onCountryClick: (country: Country) => void;
    onKeydown: (value: string) => void;
    onKeyup: (e: KeyboardEvent, value: string) => void;
    onOpen: () => void;
}

export const useRouteSelectorField = (props: Props) => {
    const init = () => {
        document.addEventListener("click", handleClickOutside);

        return () => document.removeEventListener("click", handleClickOutside);
    };

    const getAllAvailableCities = () =>
        props.model?.countries?.reduce(
            (allCities: City[], country) =>
                allCities.concat(
                    country.regions.reduce((allCountryCities, region) => allCountryCities.concat(region.cities), []),
                ),
            [],
        );

    const handleClickOutside = (e: any) => {
        const path = e.path || (e.composedPath && e.composedPath());

        const isClickInside = path.indexOf(inputField.current) > -1 || path.indexOf(optionsContainer.current) > -1;

        if (!isClickInside) {
            props.onClose();
            inputField.current?.blur();
        }
    };

    const scrollToActiveCountry = (mobile: boolean) => {
        if (!mobile) {
            return;
        }

        window.setTimeout(() => {
            const elem = optionsContainer.current?.querySelector(".mobile-scroller") as HTMLDivElement;
            const scrollTop = (elem?.querySelector(".active") as HTMLDivElement)?.offsetTop ?? undefined;

            if (scrollTop !== undefined) {
                elem.scrollTo({ top: scrollTop, behavior: "smooth" });
            }
        }, 500);
    };

    const handleCountryClick = (country: Country, mobile = false) => {
        props.onCountryClick(country);
        scrollToActiveCountry(mobile);
    };

    const handleFieldClick = (e: MouseEvent) => {
        (e.target as HTMLInputElement).select();
        (e.target as HTMLInputElement).focus();
        props.onOpen();
    };

    const handleKeydown = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        const value = target.value;

        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
        }

        props.onKeydown(value);
    };

    const handleKeyup = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        let value = target.value;

        if (e.key === "Escape") {
            value = "";
            target.value = props.value;
            target.blur();
        }

        props.onKeyup(e, value);
    };

    const handleBlur = () => {
        const parent = DomCrawlingHelper.findParentByClass(props.root, CLASS_NAMES.errorContainer);

        if (parent && inputField.current) {
            handleFieldBlur(inputField.current, parent);
        }
    };

    const inputField = useRef<HTMLInputElement>(undefined);
    const optionsContainer = useRef<HTMLDivElement>(undefined);

    const [scrollerRegion, setScrollerRegion] = useState<PerfectScrollbar>(undefined);
    const [scrollerCountry, setScrollerCountry] = useState<PerfectScrollbar>(undefined);

    useEffect(() => {
        if (scrollerCountry) {
            scrollerCountry.destroy();
        }

        const elem = optionsContainer.current?.querySelector(".from-to-countries") as HTMLDivElement;

        if (elem) {
            setScrollerCountry(
                new PerfectScrollbar(elem, {
                    wheelPropagation: false,
                    wheelSpeed: 2,
                    swipeEasing: true,
                    suppressScrollX: true,
                }),
            );
        }
    }, [props.isActive]);

    useEffect(() => {
        if (scrollerRegion) {
            scrollerRegion.destroy();
        }

        const elem = optionsContainer.current?.querySelector(".from-to-cities") as HTMLDivElement;

        if (elem) {
            setScrollerRegion(
                new PerfectScrollbar(elem, {
                    wheelPropagation: false,
                    wheelSpeed: 2,
                    swipeEasing: true,
                    suppressScrollX: true,
                }),
            );
        }
    }, [props.activeCountry?.code]);

    useEffect(() => (props.value ? handleBlur() : null), [props.value]);

    useEffect(() => (props.isActive ? inputField.current.focus() : null), [props.isActive]);

    useEffect(init, []);

    const inputFieldTemplate = () => {
        const tempClassMap = classMap({
            "options-opener": true,
            "active": props.isActive,
        });

        return html`
            <input
                ref=${ref(inputField)}
                class=${tempClassMap}
                autocomplete="off"
                placeholder=${props.placeHolder}
                data-required
                data-test-id=${props.inputTestId}
                .value=${props.value}
                ?disabled=${props.isDisabled}
                @keydown=${handleKeydown}
                @keyup=${handleKeyup}
                @focus=${handleFieldClick}
                @blur=${handleBlur}
            />
            <div class="options-opener-arrow">&#10095;</div>
        `;
    };

    const countryLabelTemplate = () =>
        !props.isTyping ? html` <label class="country-label">${i18next.t("V2-CountryLabel")}</label> ` : "";

    const cityLabelTemplate = () =>
        !props.isTyping ? html` <label class="city-label">${i18next.t("SS-CityLabel")}</label> ` : "";

    const regionNameTemplate = (region: Region) =>
        region.name ? html` <span class="region-name">${region.name}</span> ` : "";

    const newLabelTemplate = (city: City) =>
        city.isNew ? html` <span class="station-new">*${i18next.t("SS-NewStationLabel")}</span> ` : "";

    const cityTemplate = (city: City, isMobile = false) => {
        const tempClassMap = classMap({
            "station-opt": true,
            "selected": props.selectedCityCode === city.code,
        });

        return html`
            <div
                class=${tempClassMap}
                @click=${() => props.onCityClick(city)}
                data-test-id=${getTestId(T.SEARCH.CITY, { j: props.journeyIndex, c: city.code, m: isMobile })}
                data-value=${city.code}
            >
                ${city.name} (${city.code}) ${newLabelTemplate(city)}
            </div>
        `;
    };

    const regionTemplate = (region: Region, isMobile = false) => {
        const tempClassMap = classMap({
            "region-opt": true,
            "no-label": !region.name,
        });

        return html`
            <div class=${tempClassMap}>
                ${regionNameTemplate(region)} ${region.cities.map((city) => cityTemplate(city, isMobile))}
            </div>
        `;
    };

    const regionsTemplate = (country: Country, isMobile = false) =>
        country?.regions.filter((region) => region.cities.length > 0).map((region) => regionTemplate(region, isMobile));

    const countryTemplate = (country: Country) => {
        const tempClassMap = classMap({
            "country-opt": true,
            "active": props.activeCountry?.code === country.code,
            "no-hide": props.isTyping,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${getTestId(T.SEARCH.COUNTRY, { j: props.journeyIndex, c: country.code })}
                data-test-value=${country.code}
                @click=${() => handleCountryClick(country)}
            >
                <span class="country-name">${country.name}</span>
            </div>
        `;
    };

    const mobileCountryTemplate = (country: Country) => {
        const tempClassMap = classMap({
            "country-opt": true,
            "active": props.activeCountry?.code === country.code || props.isTyping,
            "no-hide": props.isTyping,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${getTestId(T.SEARCH.COUNTRY, { j: props.journeyIndex, c: country.code, m: true })}
                data-test-value=${country.code}
                @click=${() => handleCountryClick(country, true)}
            >
                <span class="country-name">${country.name}</span>
            </div>
            <div class="mobile-regions">${regionsTemplate(country, true)}</div>
        `;
    };

    const desktopCountriesTemplate = () =>
        props.model?.countries
            .filter((country) => country.regions.filter((r) => r.cities.length > 0).length > 0)
            .map(countryTemplate);

    const desktopCitiesTemplate = () =>
        props.isTyping
            ? getAllAvailableCities().map((city) => cityTemplate(city))
            : regionsTemplate(props.model?.countries?.find((c) => c.code === props.activeCountry?.code));

    const desktopTemplate = () => html`
        <div class="hidden-sm-down">
            ${countryLabelTemplate()} ${cityLabelTemplate()}
            <div class="from-to-countries">${desktopCountriesTemplate()}</div>
            <div class="from-to-cities">${desktopCitiesTemplate()}</div>
        </div>
    `;

    const mobileTemplate = () => html`
        <div class="hidden-md-up mobile-scroller">${props.model?.countries?.map(mobileCountryTemplate)}</div>
    `;

    const optionsTemplate = () => {
        const tempClassMap = classMap({
            "options-container": true,
            "no-hide": props.isTyping,
        });

        return html`
            <div ref=${ref(optionsContainer)} class=${tempClassMap} data-test-id=${props.optionsDataTestId}>
                ${desktopTemplate()} ${mobileTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () => html`
        <div class="form-group from-to-input-container" data-test-id=${props.dataTestId}>
            ${inputFieldTemplate()} ${optionsTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import { useEffect } from "./../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { UpgradeCompanyInfoModel } from "../../../component-models/UpgradeCompanyInfoModel";
import { CommonRegion, toBoolean, getParsedProperty, getAntiForgeryTokenFromHtml } from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { UpdateCUG2ProfileViewModel } from "../../../component-models/UpdateCUG2ProfileViewModel";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "can-edit-company",
    "can-edit-user",
    "can-edit-password",
    "company-model",
    "regions",
    "user-model",
];
export const name = "ac-cug-edit-profile-page";
export interface Attributes {
    "anti-forgery-token": string;
    "can-edit-company": string;
    "can-edit-user": string;
    "can-edit-password": string;
    "company-model": string;
    "regions": string;
    "user-model": string;
}
export interface Properties {
    antiForgeryToken: string;
    canEditCompany: boolean;
    canEditPassword: boolean;
    canEditUser: boolean;
    companyModel: UpgradeCompanyInfoModel;
    regions: CommonRegion[];
    userModel: UpdateCUG2ProfileViewModel;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canEditCompany: toBoolean(host.canEditCompany),
        canEditPassword: toBoolean(host.canEditPassword),
        canEditUser: toBoolean(host.canEditUser),
        companyModel:
            host.companyModel && typeof host.companyModel === "string"
                ? getParsedProperty<UpgradeCompanyInfoModel>(host.companyModel)
                : undefined,
        regions:
            host.regions && typeof host.regions === "string"
                ? getParsedProperty<CommonRegion[]>(host.regions)
                : undefined,
        userModel:
            host.userModel && typeof host.userModel === "string"
                ? getParsedProperty<UpdateCUG2ProfileViewModel>(host.userModel)
                : undefined,
    };

    // HELPERS

    // COMPONENT

    const [userContext] = useReduxState("userContext");

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    // EVENT LISTENERS

    // TEMPLATES

    const companyFormTemplate = () =>
        props.canEditCompany && !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html`
                  <ac-cug-company-edit
                      .antiForgeryToken=${props.antiForgeryToken}
                      .canEditCompany=${props.canEditCompany}
                      .companyModel=${props.companyModel}
                      .regions=${props.regions}
                  >
                  </ac-cug-company-edit>
              `
            : "";

    const userFormTemplate = () => html`
        <ac-cug-user-edit
            .antiForgeryToken=${props.antiForgeryToken}
            .canEditUser=${props.canEditUser}
            .hasCompanyModel=${Boolean(props.companyModel)}
            .userModel=${props.userModel}
        >
        </ac-cug-user-edit>
    `;

    const passwordFormTemplate = () => html`
        <ac-cug-password-edit
            .antiForgeryToken=${props.antiForgeryToken}
            .canEditPassword=${props.canEditPassword}
            .hasCompanyModel=${Boolean(props.companyModel)}
            .hasUserModel=${Boolean(props.userModel)}
        >
        </ac-cug-password-edit>
    `;

    const headerInfoTemplate = () =>
        userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
            ? html`<h2>
                  ${i18next.t("Revisa, edita y actualiza toda la información referente a tu cuenta de usuario.")}
              </h2>`
            : html` <h2>
                  ${i18next.t(
                      "Revisa, edita y actualiza toda la información referente a tu empresa/agencia y a tu cuenta de usuario.",
                  )}
              </h2>`;

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>${i18next.t("Mi Perfil")}</h1>
                ${headerInfoTemplate()}
            </div>
        </div>
    `;

    return html` ${headerTemplate()} ${companyFormTemplate()} ${userFormTemplate()} ${passwordFormTemplate()} `;
};

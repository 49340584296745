import { getAntiForgeryTokenFromHtml, getParsedProperty } from "./../../shared/common";
import { useEffect, useMemo, useState } from "./../../shared/haunted/CustomHooks";
import { html } from "haunted";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import { CommonRegion } from "../../shared/common";
import { getStringForCulture } from "../../component-helpers/stringHelper";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { OrganizationVM } from "./cug-register-company";
import { AdminVM } from "./cug-register-admin";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-cug-register";

export interface Properties {
    antiForgeryToken: string;
    currencies: { [countryCode: string]: string };
    gRecapcthaSiteKey: string;
    privacyLink: string;
    regions: CommonRegion[];
    termsLink: string;
}
export interface Attributes {
    "anti-forgery-token": string;
    "currencies": string;
    "g-recapctha-site-key": string;
    "privacy-link": string;
    "regions": string;
    "terms-link": string;
}

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "currencies",
    "regions",
    "g-recapctha-site-key",
    "privacy-link",
    "terms-link",
];

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        gRecapcthaSiteKey: host.gRecapcthaSiteKey,
        privacyLink: host.privacyLink,
        termsLink: host.termsLink,
        regions: typeof host.regions === "string" ? getParsedProperty<CommonRegion[]>(host.regions) : undefined,
        currencies:
            typeof host.currencies === "string"
                ? getParsedProperty<{ [countryCode: string]: string }>(host.currencies)
                : undefined,
    };

    const cug2AppContext = useCug2AppContext();

    const DEFAULT_ORG_MODEL: OrganizationVM = useMemo(
        () => ({
            address: "",
            city: "",
            countryCode: cug2AppContext.Country,
            currencyCode: "",
            email: "",
            file: undefined,
            giro: "",
            name: "",
            region: "",
            rntExpirationDate: undefined,
            taxNumber: "",
            type: "",
        }),
        [cug2AppContext.Country],
    );

    const DEFAULT_ADMIN_MODEL: AdminVM = useMemo(
        () => ({
            firstName: "",
            lastName: "",
            email: "",
            userName: "",
            countryCode: cug2AppContext.Country,
            documentNumber: "",
            phonePrefix: cug2AppContext.PhonePrefixes.find((p) => p.CountryCode === cug2AppContext.Country)?.Code || "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
        }),
        [cug2AppContext.PhonePrefixes, cug2AppContext.Country],
    );

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [organizationVm, setOrganizationVm] = useState<OrganizationVM>(DEFAULT_ORG_MODEL);
    const [adminVm, setAdminVm] = useState<AdminVM>(DEFAULT_ADMIN_MODEL);

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    const goForward = () => {
        if (currentPage < 4) {
            setCurrentPage(currentPage + 1);
        } else {
            throw new Error("Invalid page");
        }
    };

    const goBack = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        } else {
            window.history.back();
        }
    };

    const goToError = () => {
        setCurrentPage(4);
    };

    const pageTemplate = () => {
        switch (currentPage) {
            case 0:
                return html`
                    <ac-cug-register-company
                        .currencies=${props.currencies}
                        .regions=${props.regions}
                        .vm=${organizationVm}
                        .goBack=${goBack}
                        .goForward=${goForward}
                        .setVm=${setOrganizationVm}
                    ></ac-cug-register-company>
                `;
            case 1:
                return html`
                    <ac-cug-register-admin
                        .vm=${adminVm}
                        .goBack=${goBack}
                        .goForward=${goForward}
                        .setVm=${setAdminVm}
                    ></ac-cug-register-admin>
                `;
            case 2:
                return html`
                    <ac-cug-register-summary
                        .adminVM=${adminVm}
                        .gRecapcthaSiteKey=${props.gRecapcthaSiteKey}
                        .organizationVM=${organizationVm}
                        .privacyLink=${props.privacyLink}
                        .termsLink=${props.termsLink}
                        .goForward=${goForward}
                        .goToError=${goToError}
                    ></ac-cug-register-summary>
                `;
            case 3:
                return html`
                    <ac-cug-register-result
                        .success=${true}
                        .bgDesktopImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.DesktopImageUrl,
                            cug2AppContext.Culture,
                        )}
                        .bgTabletImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.TabletImageUrl,
                            cug2AppContext.Culture,
                        )}
                        .bgMobileImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.MobileImageUrl,
                            cug2AppContext.Culture,
                        )}
                    ></ac-cug-register-result>
                `;
            case 4:
                return html`
                    <ac-cug-register-result
                        .success=${false}
                        .bgDesktopImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.DesktopImageUrl,
                            cug2AppContext.Culture,
                        )}
                        .bgTabletImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.TabletImageUrl,
                            cug2AppContext.Culture,
                        )}
                        .bgMobileImage=${getStringForCulture(
                            cug2AppContext.Cug2BSettings.Register.ImageUrl.MobileImageUrl,
                            cug2AppContext.Culture,
                        )}
                    ></ac-cug-register-result>
                `;
            default:
                throw new Error("Invalid page.");
        }
    };

    const topBarTemplate = () =>
        currentPage < 3 ? html` <ac-cug-register-bar .currentPage=${currentPage}></ac-cug-register-bar> ` : "";

    return html` ${topBarTemplate()} ${pageTemplate()} `;
};

import { BaggageContextJourney } from "../component-models/baggage/BaggageContext";
import i18next from "i18next";
import { BagType } from "../components/spa/baggage/useBaggagePage";
import { INBOUND, OUTBOUND } from "../shared/commonConstants";
import { BaggageState } from "../component-models/baggage/BaggageState";

export const paxAmount = (baggageState: BaggageState) => baggageState.journeys[0]?.passengers.length || 0;

export const displayedBundleJourneyIndex = (journeys: BaggageContextJourney[], bagType: BagType): number => {
    if (journeys.length < 2) return undefined;

    const outboundBundleType =
        bagType === "CabinBaggage" ? journeys[OUTBOUND].cabinBundleType : journeys[OUTBOUND].checkedBundleType;

    const inboundBundleType =
        bagType === "CabinBaggage" ? journeys[INBOUND].cabinBundleType : journeys[INBOUND].checkedBundleType;

    if (outboundBundleType !== "None" && inboundBundleType === "None") return OUTBOUND;

    if (outboundBundleType === "None" && inboundBundleType !== "None") return INBOUND;

    return undefined;
};

export const paxLabel = (paxLength: number): string =>
    i18next.t("pasajero{{plural}}", { plural: paxLength !== 1 ? "s" : "" });

export const perJourneyPerPaxViewPaxCaption = (paxAmount: number): string =>
    paxAmount === 1
        ? i18next.t("Selección personalizada por tramo")
        : i18next.t("Selección personalizada por pasajero y tramo");

export const getInputId = (
    bagType: BagType,
    journeyIndex: number,
    paxIndex: number,
    isMobile: boolean,
    isFree: boolean,
): string =>
    `${bagType}_${journeyIndex === undefined ? "per_booking" : `journey_${journeyIndex}_pax_${paxIndex}`}_${
        isFree ? "free" : "paid"
    }${isMobile ? "_mb" : ""}`;

export const getInputName = (bagType: BagType, journeyIndex: number, paxIndex: number, isMobile: boolean): string =>
    `${bagType}-${journeyIndex === undefined ? "per-booking" : `journey-${journeyIndex}-pax-${paxIndex}`}${
        isMobile ? "-mb" : ""
    }`;

export const finishedSelection = (state: BaggageState): boolean =>
    state.journeys.every((j) => j.passengers.every((p) => p.hasSelected)) ||
    state.journeys.every((j) => j.passengers.every((p) => !p.hasSelected));

import { ROUTES } from "./../../../shared/apiRoutes";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { UpdatePartnerEvent } from "../../../component-models/CUG2b/Cug2PortalEvents";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { html } from "lit-html";
import { getAntiForgeryTokenFromHtml, handleCugLoader } from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { URL_VARS } from "../../../shared/commonConstants";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const name = "ac-add-travel-partner-page";
export interface Attributes {
    "anti-forgery-token": string;
}

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export interface Properties {
    antiForgeryToken: string;
}
export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = async () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const result = await getTravelPartnerInfo();
        setTravelPartnerInfo(result);
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);

    useEffect(init, []);

    // EVENT LISTENERS

    const handleSave = async (e: UpdatePartnerEvent) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, {
            ...e.detail.partner,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        window.location.href =
            e.detail.partner.GroupId > -1
                ? `${ROUTES.Cug2BEditTravelGroupPage}?${URL_VARS.FFLYER_IDS}=${encodeURIComponent(
                      e.detail.partner.GroupId,
                  )}`
                : ROUTES.Cug2BTravelPartnersPage;
    };

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Agregar pasajeros")}</div>
        </div>
        <div class="travel-partner-hero">
            <h1>${i18next.t("Pasajeros Nuevos")}</h1>
            <div>
                ${i18next.t(
                    "Aquí deberás ingresar la información del o los nuevos pasajeros frecuentes. ¡No te preocupes! esta información se podrá editar en cualquier momento.",
                )}
            </div>
            <div class="mt-4 text-xs md:text-base">
                * ${i18next.t("Recuerda que tus pasajeros frecuentes deben ser adultos (mayores de 18 años).")}
            </div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-man-and-plus"></i>
            <h2>${i18next.t("Agregar pasajero")}</h2>
        </div>
    `;

    const formTemplate = () => html`
        <ac-travel-partner
            .model=${travelPartnerInfo}
            .showCancelButton=${true}
            .showBackButton=${true}
            .vm=${undefined}
            @update=${handleSave}
        ></ac-travel-partner>
    `;

    return travelPartnerInfo ? html` <div ref=${ref(root)}>${staticStuffTemplate()} ${formTemplate()}</div> ` : html``;
};

import { DEFAULT_DATE_FORMAT } from "./../shared/commonConstants";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { ApiPartner } from "../component-models/CUG2b/ApiPartner";
import { ApiTravelPartnerInfo } from "../component-models/CUG2b/ApiTravelPartnerInfo";
import { Partner } from "../component-models/CUG2b/Partner";
import { TravelPartnerInfo } from "../component-models/CUG2b/TravelPartnerInfo";
import {
    FAKE_GROUP_FOR_UNGROUPED_TRAVEL_PARTNERS,
    FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS,
} from "../shared/commonConstants";
import { Cug2bDocumentType } from "../component-models/checkin/TravelDocument";

export function mapToTravelPartnerInfo(apiTravelPartnerInfo: ApiTravelPartnerInfo): TravelPartnerInfo {
    return {
        Groups: apiTravelPartnerInfo?.Groups
            ? apiTravelPartnerInfo.Groups.filter((g) => g.Id !== FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS)
            : [],
        LastModified: apiTravelPartnerInfo?.LastModified,
        Partners: apiTravelPartnerInfo?.Partners ? apiTravelPartnerInfo.Partners.map(mapToPartner) : [],
    };
}

export function mapToApiTravelPartnerInfo(travelPartnerInfo: TravelPartnerInfo): ApiTravelPartnerInfo {
    return {
        Groups: [...travelPartnerInfo.Groups, FAKE_GROUP_FOR_UNGROUPED_TRAVEL_PARTNERS],
        LastModified: travelPartnerInfo.LastModified,
        Partners: travelPartnerInfo.Partners.map(mapToApiPartner),
    };
}

function mapToPartner(apiPartner: ApiPartner): Partner {
    return {
        ...apiPartner,
        GroupId: apiPartner.GroupId !== FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS ? apiPartner.GroupId : undefined,
        DOB: dayjs(apiPartner.DOB, DEFAULT_DATE_FORMAT),
        DocumentType: apiPartner.DocumentType as unknown as Cug2bDocumentType,
        Gender: apiPartner.Gender as any, // TODO FASZOM
    };
}

function mapToApiPartner(partner: Partner): ApiPartner {
    return {
        ...partner,
        GroupId: partner.GroupId !== undefined ? partner.GroupId : FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS,
        DOB: partner.DOB.format(DEFAULT_DATE_FORMAT),
        DocumentType: partner.DocumentType?.toString(),
    };
}

import { useCallback } from "haunted";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { clone } from "../../../../shared/common";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { BagType } from "../useBaggagePage";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";

interface StaffBaggagePerJourneyPerPax {
    journeyIndex: number;
    passengerNumber: number;
    used: "None" | "Cabin" | "Checked";
}

export interface StaffBaggage {
    state: StaffBaggagePerJourneyPerPax[];
    canGetFirstBaggageStaffDiscount: (data: { bagType: BagType; journeyIndex: number; paxIndex: number }) => boolean;
    update: (data: {
        bagType: BagType;
        journeyIndices: number[];
        paxIndices: number[];
        type: "add" | "remove" | "reset";
    }) => void;
}

export interface Props {
    context: BaggageContext;
}

export const useStaffBaggage = (props: Props): StaffBaggage => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const [state, setState] = useState<StaffBaggagePerJourneyPerPax[]>(
        props.context.journeys.flatMap((j) =>
            j.passengers.flatMap(
                (p): StaffBaggagePerJourneyPerPax => ({
                    passengerNumber: p.index,
                    journeyIndex: j.index,
                    used: p.cabinBaggage.quantity > 0 ? "Cabin" : p.checkedBaggage.quantity > 0 ? "Checked" : "None",
                }),
            ),
        ),
    );

    const update = (data: {
        bagType: BagType;
        journeyIndices: number[];
        paxIndices: number[];
        type: "add" | "remove" | "reset";
    }) => {
        setState(
            state.map((item): StaffBaggagePerJourneyPerPax => {
                if (data.journeyIndices.includes(item.journeyIndex) && data.paxIndices.includes(item.passengerNumber)) {
                    const newItem = clone(item);

                    if (data.bagType === "CabinBaggage") {
                        switch (data.type) {
                            case "add":
                                newItem.used = newItem.used === "None" ? "Cabin" : newItem.used;
                                break;
                            case "remove":
                            case "reset":
                                const journeyPassengers = props.context.journeys.find(
                                    (journey) => journey.index === item.journeyIndex,
                                ).passengers;
                                const journeyPassenger = journeyPassengers.find(
                                    (pax) => pax.index === item.passengerNumber,
                                );
                                newItem.used = journeyPassenger.checkedBaggage.quantity > 0 ? "Checked" : "None";
                                break;
                        }
                    }

                    if (data.bagType === "CheckedBaggage") {
                        switch (data.type) {
                            case "add":
                                newItem.used = newItem.used === "None" ? "Checked" : newItem.used;
                                break;
                            case "remove":
                            case "reset":
                                const journeyPassengers = props.context.journeys.find(
                                    (journey) => journey.index === item.journeyIndex,
                                ).passengers;
                                const journeyPassenger = journeyPassengers.find(
                                    (pax) => pax.index === item.passengerNumber,
                                );

                                if (data.type === "remove") {
                                    // DEVNOTE 1 is used because the state is not updated at this point
                                    // FIXME Fix this
                                    newItem.used =
                                        journeyPassenger.checkedBaggage.quantity > 1
                                            ? newItem.used
                                            : journeyPassenger.cabinBaggage.quantity > 0
                                              ? "Cabin"
                                              : "None";
                                } else {
                                    newItem.used = journeyPassenger.cabinBaggage.quantity > 0 ? "Cabin" : "None";
                                }
                                break;
                        }
                    }

                    return newItem;
                }

                return item;
            }),
        );
    };

    const canGetFirstBaggageStaffDiscount = useCallback(
        (data: { bagType: BagType; journeyIndex: number; paxIndex: number }) => {
            const usedStaffBaggage = state.find(
                (u) => u.journeyIndex === data.journeyIndex && u.passengerNumber === data.paxIndex,
            );

            if (
                !userContext.isStaff ||
                (!flowContext.isBookingFlow && !bookingContext.canGetStaffDiscount) ||
                data.bagType === "OversizedBaggage" ||
                !usedStaffBaggage
            ) {
                return false;
            }

            return usedStaffBaggage.used === "None";
        },
        [userContext.isStaff, flowContext.isBookingFlow, bookingContext.canGetStaffDiscount, state],
    );

    return {
        state,
        canGetFirstBaggageStaffDiscount,
        update,
    };
};

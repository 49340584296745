import { useEffect, useRef, useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { GiftcardActivationStep } from "../../component-models/giftcard/GiftcardActivationStep";
import { useGiftcardActivationPassword } from "./useGiftcardActivationPassword";
import { useGiftcardActivationCode } from "./useGiftcardActivationCode";
import { useGiftcardActivationEmail } from "./useGiftcardActivationEmail";
import { useGiftcardActivationRUT } from "./useGiftcardActivationRut";
import { ROUTES } from "../../shared/apiRoutes";
import { BRASILIAN_CULTURE_CODE } from "../../shared/commonConstants";
import { ApiGiftCardActivationViewModel } from "../../component-models/giftcard/ApiGiftcardActivationPageViewModel";
import { useAppContext } from "../../managers/useAppContext";
import { getParsedProperty } from "../../shared/common";
import { useGiftcardFlowBlockedModal } from "./useGiftcardFlowBlockedModal";

export const name = "ac-giftcard-activation-container";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ApiGiftCardActivationViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ApiGiftCardActivationViewModel>(host.model),
    };

    //HELPERS

    const handleLogout = () =>
        (window.location.href = `${ROUTES.GiftcardActivate}?activationCode=${enterActivationCode.code.value}`);

    const initBrowserBackButton = () => {
        window.onpopstate = () => {
            switch (activeStep) {
                case "Code":
                    window.location.href = "/";
                    break;
                case "Email":
                    handleEmailBack();
                    break;
                case "Password":
                    handlePasswordBack();
                    break;
                case "RUT":
                    handleRutBack();
                    break;
                case "Register":
                    setActiveStep("Email");
                    pushHistoryData("Email");
                    break;
            }
        };
    };

    const isStep1Active = () => activeStep === "Code";

    const isStep2AActive = () => activeStep === "Email";

    const isStep2BActive = () => activeStep === "RUT";

    const isStep3AActive = () => activeStep === "Register";

    const isStep3BActive = () => activeStep === "Password";

    const breadcrumbStepOnePast = () => isStep2AActive() || isStep2BActive() || isStep3AActive() || isStep3BActive();

    const breadcrumbStepTwoPast = () => isStep3AActive() || isStep3BActive();

    const pushHistoryData = (page: GiftcardActivationStep) => {
        const data = {
            page,
            name: page,
            uri: page + window.location.search,
        };

        history.pushState({ page: data }, data.name, data.uri);
    };

    //EVENT-HANDLERS:
    const handleCodeSubmit = () => {
        setActiveStep("Email");
        pushHistoryData("Email");
    };

    const handleEmailSubmit = () => {
        setActiveStep("Register");
        pushHistoryData("Register");
    };

    const handleEmailBack = () => {
        setActiveStep("Code");
        pushHistoryData("Code");
    };

    const handleExistingUser = () => {
        setActiveStep("Password");
        pushHistoryData("Password");
    };

    const handleRutBack = () => {
        setActiveStep("Email");
        pushHistoryData("Email");
    };

    const handleBancoEstado = () => {
        setActiveStep("RUT");
        pushHistoryData("RUT");
        setIsBancoEstado(true);
    };

    const handlePasswordBack = () => {
        isBancoEstado ? setActiveStep("RUT") : setActiveStep("Email");
        pushHistoryData(isBancoEstado ? "RUT" : "Email");
    };

    //COMPONENTS
    const root = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();

    const [activeStep, setActiveStep] = useState<GiftcardActivationStep>("Code");
    const [isBancoEstado, setIsBancoEstado] = useState<boolean>(false);

    const blockedModal = useGiftcardFlowBlockedModal();

    useEffect(() => {
        window.onpopstate = null;
        initBrowserBackButton();
    });

    const enterActivationCode = useGiftcardActivationCode({
        gRecaptchaSiteKey: props.model.RegisterPageViewModel.GRecaptchaSiteKey,
        isActive: isStep1Active(),
        locatorAndPin: props.model.GiftCardActivationInfo.RecordLocatorAndPin,
        parentRoot: root.current,
        handleOnCodeSubmit: handleCodeSubmit,
        handleLogout,
    });

    const enterActivationEmail = useGiftcardActivationEmail({
        isActive: isStep2AActive(),
        isBancoEstadoUser: isBancoEstado,
        parentElement: root.current,
        handleOnBancoEstado: handleBancoEstado,
        handleOnEmailBack: handleEmailBack,
        handleOnEmailSubmit: handleEmailSubmit,
        handleOnExistingUser: handleExistingUser,
    });

    const enterActivationRUT = useGiftcardActivationRUT({
        isActive: isStep2BActive(),
        parentElement: root.current,
        handleOnExistingUser: handleExistingUser,
        handleOnRutBack: handleRutBack,
    });

    const enterActivationPassword = useGiftcardActivationPassword({
        bancoEstadoUserLoginType: props.model.RegisterPageViewModel.BancoEstadoLoginType,
        emailElement: enterActivationEmail.email,
        isActive: isStep3BActive(),
        isBancoEstadoUser: isBancoEstado,
        personalUserLoginType: props.model.RegisterPageViewModel.PersonalLoginType,
        rutElement: enterActivationRUT.rut,
        handleOnBancoEstado: handleBancoEstado,
        handleOnPasswordBack: handlePasswordBack,
    });

    //TEMPLATE
    const giftcardActivationHeaderTemplate = () => {
        const headerClassMap = classMap({
            "giftcard-activation-header": true,
            "portuguese": appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return html`
            <div class=${headerClassMap}>
                <i class="js-icon js-gift-card1"></i>
                <span>${i18next.t("Gift-ActivationTitle")}</span>
                <span>${i18next.t("Gift-Title-1")}</span>
                <span>${i18next.t("Gift-Title-2")}</span>
                <div class="giftcard-header-logo">
                    <img src="/Images/Header/jetsmart-logo-colored.svg" />
                </div>
            </div>
        `;
    };

    const giftcardActivationBreadcrumbsTemplate = () => {
        const breadcrumpsStep1ClassMap = classMap({
            step: true,
            active: isStep1Active(),
            past: breadcrumbStepOnePast(),
        });
        const breadcrumpsStep2ClassMap = classMap({
            step: true,
            active: isStep2AActive() || isStep2BActive(),
            past: breadcrumbStepTwoPast(),
        });
        const breadcrumpsStep3ClassMap = classMap({
            step: true,
            active: isStep3AActive() || isStep3BActive(),
        });

        return html`
            <div class="giftcard-activation-breadcrumbs">
                <span class=${breadcrumpsStep1ClassMap}>1</span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span class=${breadcrumpsStep2ClassMap}>2</span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span class=${breadcrumpsStep3ClassMap}>3</span>
            </div>
        `;
    };

    const giftcardActivationRegisterTemplate = () => html`
        <div class="step-form gca-register active">
            <div class="giftcard-activation-step-info pull-up">${i18next.t("Gift-StepInfo-3A")}</div>

            <ac-register
                .antiForgeryToken=${props.antiForgeryToken}
                .model=${JSON.stringify(props.model.RegisterPageViewModel)}
                .predefinedEmailAddress=${enterActivationEmail.email.value}
            >
            </ac-register>
        </div>
    `;

    return html`
        <div ref=${root} id="mainContentWrapper">
            <section class="booking-wrapper no-breadcrumb ts-error-container">
                ${giftcardActivationHeaderTemplate()} ${giftcardActivationBreadcrumbsTemplate()}

                <!-- Enter activation code -->
                ${enterActivationCode.htmlTemplate()}

                <!-- Enter email address -->
                ${enterActivationEmail.htmlTemplate()}

                <!-- Enter RUT -->
                ${enterActivationRUT.htmlTemplate()}

                <!-- Register -->
                ${isStep3AActive() ? giftcardActivationRegisterTemplate() : ""}

                <!-- Enter password -->
                ${enterActivationPassword.htmlTemplate()}
            </section>
        </div>
        ${blockedModal.htmlTemplate()}
    `;
};

import { chilePhoneLength } from "../../shared/customHooks/useForm/custom-attributes/chilePhoneLength";
import { ROUTES } from "../../shared/apiRoutes";
import i18next from "i18next";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import { isKeyNumeric, mapCountryCallingCodeListToHtmlOptions, sanitizePhoneNumber } from "../../shared/common";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../directives/ref";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useForm } from "../../shared/customHooks/useForm/useForm";
import { CHILEAN_CULTURE_CODE, PREFIX_CODES } from "../../shared/commonConstants";
import { sanitizeChileanPhoneInput } from "../../component-helpers/InputSanitizerHelper";
import { useModal } from "../shared/useModal";
import { useAppContext } from "../../managers/useAppContext";

export const useGdsDataModal = () => {
    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, false)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    const handlePhoneNumberInput = (e: KeyboardEvent) => {
        const sanitizedValue =
            phonePrefix === PREFIX_CODES.get(CHILEAN_CULTURE_CODE)
                ? sanitizeChileanPhoneInput(e)
                : sanitizePhoneNumber((e.target as HTMLInputElement).value, false);

        setPhoneNumber(sanitizedValue);
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowServerError(false);

        const isFormValid = await form.validate();

        if (isFormValid) {
            await ajaxRequest({
                url: ROUTES.PrimaryContactEmail,
                body: {
                    emailAddress: email,
                    homePhone: `${phonePrefix} ${phoneNumber}`,
                },
                onResponseCode: {
                    200: () => {
                        modal.close();
                    },
                    500: () => {
                        setShowServerError(true);
                    },
                },
            });
        }
    };

    const appContext = useAppContext();

    const formElem = useRef<HTMLFormElement>(undefined);

    const { ajaxRequest } = useAjax();

    const [showServerError, setShowServerError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [phonePrefix, setPhonePrefix] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    const form = useForm({
        customAttributes: [chilePhoneLength()],
        noScroll: true,
    });

    useEffect(() => form.init(formElem.current), [formElem.current]);

    const phonePrefixOptionsTemplate = () =>
        mapCountryCallingCodeListToHtmlOptions(appContext.PhonePrefixes, appContext.Culture, phonePrefix);

    const phonePrefixTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 col-md-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("V2-PhoneCountryLabel")}*</label>

                <select
                    class="mdl-textfield__input js-input js-select"
                    tabindex="3"
                    phone-prefix
                    data-required
                    .value=${phonePrefix}
                    @change=${(e: MouseEvent) => setPhonePrefix((e.target as HTMLSelectElement).value)}
                >
                    ${phonePrefixOptionsTemplate()}
                </select>
            </div>
        </div>
    `;

    const phoneNumberTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 col-md-2-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <input
                    type="text"
                    name="phone_number"
                    class="mdl-textfield__input js-input"
                    autocomplete="off"
                    tabindex="4"
                    data-required
                    chile-length
                    data-test-id="register-phone-number"
                    @keydown=${handlePhoneChange}
                    @input=${handlePhoneNumberInput}
                />
                <label class="mdl-textfield__label">${i18next.t("V2-PhoneLabel")}*</label>
            </div>
        </div>
    `;

    const emailTemplate = () => {
        const tempClassMap = classMap({
            "mdl-textfield": true,
            "mdl-js-textfield": true,
            "mdl-textfield--floating-label": true,
            "is-dirty": email,
            "is-upgraded": email,
        });

        const inputClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
        });

        return html`
            <div class="col-xs-1">
                <div class=${tempClassMap}>
                    <label class="mdl-textfield__label">${i18next.t("V2-EmailLabel")}*</label>
                    <input
                        type="text"
                        name=""
                        class=${inputClassMap}
                        autocomplete="cc-exp"
                        tabindex="1"
                        email
                        data-required
                        data-test-id="register-email"
                        .value=${email ?? ""}
                        @input=${(e: KeyboardEvent) => setEmail((e.target as HTMLInputElement).value)}
                    />
                </div>
            </div>
        `;
    };

    const confirmEmailTemplate = () => html`
        <div class="col-xs-1">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("V2-ConfirmEmail")}*</label>
                <input
                    type="text"
                    class="mdl-textfield__input js-input"
                    tabindex="2"
                    email-confirm
                    data-required
                    data-test-id="register-email-confirmation"
                />
            </div>
        </div>
    `;

    const serverErrorTemplate = () =>
        showServerError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Server error")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="gds-data-modal-ribbon">
            <div>${i18next.t("Por favor ingresa tu correo electrónico y teléfono de contacto.")}</div>
            <div>${i18next.t("Estos quedarán como contacto para la reserva.")}</div>
        </div>
        <form class="gds-data-modal-form" ref=${ref(formElem)}>
            <div class="row">${emailTemplate()}</div>
            <div class="row">${confirmEmailTemplate()}</div>
            <div class="row">${phonePrefixTemplate()} ${phoneNumberTemplate()}</div>
        </form>
        <div class="gds-data-modal-button-container">
            <button class="rounded-primary-btn" @click=${handleSubmit}>${i18next.t("Guardar")}</button>
        </div>
        ${serverErrorTemplate()}
    `;

    const modal = useModal({
        content: { classNames: "gds-data-modal-content", template: htmlTemplate },
        header: {
            classNames: "gds-data-modal-header",
            template: () => html`<span class="gds-data-modal-title">${i18next.t("Importante")}</span>`,
        },
        scrolling: { isScrollable: true },
    });

    return modal;
};

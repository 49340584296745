import i18next from "i18next";
import { html } from "lit-html";
import { ROUTES } from "../../shared/apiRoutes";
import { showLoader } from "../../shared/common";
import { normalizeCulture } from "../../shared/localeHelper";
import { useAppContext } from "../../managers/useAppContext";
import { useModal } from "../shared/useModal";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const useDcInconsistencyModal = () => {
    const appContext = useAppContext();

    const { ajaxJsonRequest } = useAjax();

    const handleCloseSession = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const loader = showLoader({});
        const response = await ajaxJsonRequest<{ isLogoutSuccessful: boolean }>({
            loader,
            url: ROUTES.LogoutInline,
            method: "GET",
        });

        if (response.data.isLogoutSuccessful) {
            window.location.href = `${ROUTES.RegisterDc}/?culture=${normalizeCulture(appContext.Culture)}`;
        }
    };

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">${i18next.t("V2-DcFlowInconsistencyModal-Description")}</div>
            <div class="primary-modal-button-container">
                <button class="rounded-primary-btn blue-white" @click=${handleCloseSession}>
                    ${i18next.t("V2-DcFlowInconsistencyModal-CloseSession")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        overlay: { classNames: "primary-modal" },
        header: { template: () => i18next.t("V2-Important") },
    });

    return modal;
};

import { html } from "lit-html";
import i18next from "i18next";
import { Journey } from "../../../component-models/checkin/ApiCheckinJourneyViewModel";
import { ApiPassengerServiceChanges, ApiPassengerServiceInfo } from "../../../component-models/review/ApiReview";

export const commonReviewTemplates = () => {
    const paxNameTemplate = (paxChange: ApiPassengerServiceChanges | ApiPassengerServiceInfo) => html`
        <span class="first-name"> ${paxChange.Passenger.Name.First} </span>
        <span class="last-name"> ${paxChange.Passenger.Name.Last} </span>
    `;

    const previousTemplate = (paxChange: ApiPassengerServiceInfo, noneLabel: string) =>
        paxChange.PreviousSsrs.length === 0 || !paxChange.PreviousSsrs.some((ssr) => ssr.DisplayText)
            ? html` <span>${noneLabel}</span> `
            : html`${paxChange.PreviousSsrs.map((ssr) => html` <span>${ssr.DisplayText}</span> `)}`;

    const currentTemplate = (paxChange: ApiPassengerServiceInfo, noneLabel: string) =>
        paxChange.ActualSsrs.length === 0 || !paxChange.ActualSsrs.some((ssr) => ssr.DisplayText)
            ? html` <span>${noneLabel}</span> `
            : html`${paxChange.ActualSsrs.map((ssr) => html` <span>${ssr.DisplayText}</span> `)}`;

    const paxLabelTemplate = (paxChange: ApiPassengerServiceInfo | ApiPassengerServiceChanges) =>
        `${i18next.t("V2-PassengerLabel")} ${paxChange.Passenger.PassengerNumber + 1}`;

    const routeTemplate = (journey: Journey) =>
        html`${journey.Segments[0].DepartureStation} <i class="js-icon js-flight-plane"></i> ${journey.Segments[
                journey.Segments.length - 1
            ].ArrivalStation}`;

    return {
        currentTemplate,
        paxLabelTemplate,
        paxNameTemplate,
        previousTemplate,
        routeTemplate,
    };
};

export interface ProfileGiftcard {
    Amount: string;
    Available: string;
    AvailableInBookingCurrency: number;
    BaseVoucher: boolean;
    BonusVoucher: boolean;
    ConfigurationCode: string;
    CurrencyCode: string;
    Expiry: string;
    FormattedAmount: string;
    FormattedAvailable: string;
    FormattedAvailableInBookingCurrency: string;
    FormattedFaceValueInBookingCurrency: string;
    Message: string;
    Status: string;
    VoucherCode: string;
    FormattedAlreadyUsed: string;
}

export enum ProfileGiftcardStatus {
    Active = "Active",
    Expired = "Expired",
}

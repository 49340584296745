import { Partner } from "../CUG2b/Partner";


interface AssistanceCheckboxChangeEventDetail {
    keys: string[];
}

export class AssistanceCheckboxChangeEvent extends CustomEvent<AssistanceCheckboxChangeEventDetail> {
    constructor(detail: AssistanceCheckboxChangeEventDetail) {
        super("checkboxChange", { detail });
    }
}

interface SubmitAddTravelPartnerModalEventDetail {
    partners: Partner[];
}

export class SubmitAddTravelPartnerModalEvent extends CustomEvent<SubmitAddTravelPartnerModalEventDetail> {
    constructor(detail: SubmitAddTravelPartnerModalEventDetail) {
        super("submit", { detail });
    }
}

import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import {
    BaggageState,
    BaggageStateJourney,
    BaggageStateJourneyPassenger,
} from "../../../../component-models/baggage/BaggageState";
import { BaggageSection, StateUpdater } from "../../../../component-models/baggage/BaggageSection";
import { showLoader } from "../../../../shared/common";
import { useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { BagType, loaderClass, pageClass } from "../useBaggagePage";
import { CLASS_NAMES } from "../../../../shared/classNames";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useBaggageTealiumManager } from "../../../../managers/Tealium/useBaggageTealiumManager";
import { useCallback, useEffect } from "haunted";
import { ApiBaggageViewModel } from "../../../../component-models/baggage/ApiBaggageViewModel";
import { mapToBaggageJourney } from "../../../../component-mappers/BaggageMappers";
import BookingData from "../../../../shared/BookingData";
import { usePerJourneyPerPaxAvailability } from "./usePerJourneyPerPaxAvailability";
import { usePerBookingAvailability } from "./usePerBookingAvailability";
import { StaffBaggage } from "./useStaffBaggage";
import { ApiBaggageJourney } from "../../../../component-models/baggage/ApiBaggageJourney";
import { OUTBOUND, INBOUND } from "../../../../shared/commonConstants";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    bagType: BagType;
    context: BaggageContext;
    model: ApiBaggageViewModel;
    staffBaggage: StaffBaggage;
    setModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useBaggageSection = (props: Props): BaggageSection => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const [state, setState] = useState<BaggageState>({
        hasPerJourneyPerPaxStateChanged: false,
        perJourneyPerPaxState: "close",
        journeys: [],
    });

    const [resetInsuranceOnChange, setResetInsuranceOnChange] = useState<boolean>(true);

    const tealiumManager = useBaggageTealiumManager({
        bagType: props.bagType,
        context: props.context,
        staffBaggage: props.staffBaggage,
        state,
    });

    const perJourneyPerPax = usePerJourneyPerPaxAvailability({
        bagType: props.bagType,
        context: props.context,
        staffBaggage: props.staffBaggage,
    });

    const perBooking = usePerBookingAvailability({
        bagType: props.bagType,
        context: props.context,
        staffBaggage: props.staffBaggage,
        state,
    });

    const isThereNotEnoughOversizedForAllPaxOneJourney = useMemo(
        () => !props.context.journeys.every((j) => j.availableOversizedBaggage >= props.context.passengers.length),
        [props.context.journeys],
    );

    const init = () => {
        setState({
            ...state,
            perJourneyPerPaxState: getInitialPerJourneyPerPaxState(props.bagType),
            journeys: props.model.Journeys.map((j) => mapToBaggageJourney(j, props.bagType)),
        });
    };

    const isOnlyOneJourneyInBundle = (journeys: ApiBaggageJourney[]) => {
        if (journeys.length < 2) return false;

        return (
            (journeys[OUTBOUND].BundleType && !journeys[INBOUND].BundleType) ||
            (journeys[INBOUND].BundleType && !journeys[OUTBOUND].BundleType)
        );
    };

    const getSsrKeysForResetPax = useCallback(
        (passengers: BaggageStateJourneyPassenger[], journeyIndex: number) =>
            passengers.reduce((paxSsrKeys, passenger) => {
                const baggage = props.context.getContextJourneyPassengerBaggage({
                    bagType: props.bagType,
                    journeyIndex,
                    passengerIndex: passenger.index,
                });

                return paxSsrKeys.concat([...Array(baggage.quantity - baggage.min)].map((_) => baggage.ssrRemoveKey));
            }, []),
        [props.context.getContextJourneyPassengerBaggage, props.bagType],
    );

    const getSsrKeysForAddOrRemove = useCallback(
        (passengers: BaggageStateJourneyPassenger[], journeyIndex: number, type: "add" | "remove") =>
            passengers.map((passenger): string => {
                const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                    bagType: props.bagType,
                    journeyIndex,
                    passengerIndex: passenger.index,
                });

                return type === "add"
                    ? contextJourneyPassengerBaggage.ssrAddKey
                    : contextJourneyPassengerBaggage.ssrRemoveKey;
            }),
        [props.context.getContextJourneyPassengerBaggage],
    );

    const getSsrKeys = useCallback(
        (journeyIndices: number[], paxIndices: number[], type: "add" | "remove" | "reset") => {
            const journeys = state.journeys.filter((journey) => journeyIndices.includes(journey.index));
            const ssrKeys = journeys.reduce((journeySsrKeys, journey) => {
                const passengers = journey.passengers.filter((passenger) => paxIndices.includes(passenger.index));

                return journeySsrKeys.concat(
                    type === "reset"
                        ? getSsrKeysForResetPax(passengers, journey.index)
                        : getSsrKeysForAddOrRemove(passengers, journey.index, type),
                );
            }, []);

            return ssrKeys.filter((key) => key);
        },
        [state.journeys, getSsrKeysForResetPax, getSsrKeysForAddOrRemove],
    );

    const sendSsrRequest = useCallback(
        async (journeyIndices: number[], paxIndices: number[], type: "add" | "remove" | "reset") => {
            const ssrKeys = getSsrKeys(journeyIndices, paxIndices, type);

            if (ssrKeys.length > 0) {
                const parentElement = DomCrawlingHelper.getElemByClass(document.body, pageClass);
                const loader = showLoader({ name: loaderClass });
                const body = ssrKeys.reduce(
                    (bodyObj, key, i) => {
                        bodyObj[`selectedJourneySsrs[${i}]`] = key;
                        return bodyObj;
                    },
                    {} as { [key: string]: string },
                );

                const container = DomCrawlingHelper.getElemByClass(parentElement, CLASS_NAMES.errorContainer);
                props.setPageState((currentState: BaggagePageState) => ({ ...currentState, isLoading: true }));

                const response = await bookingManager.postSsrUpdate<{
                    BookingSummary: BookingData;
                    BaggageModel: ApiBaggageViewModel;
                }>(body, container, loader);

                if (response?.BaggageModel) {
                    props.setModel(response.BaggageModel);
                }

                props.setPageState((currentState: BaggagePageState) => ({ ...currentState, isLoading: false }));
            }
        },
        [getSsrKeys, props.setModel],
    );

    const getIndices = (updater?: StateUpdater) => {
        const journeyIndices = updater ? updater.JourneyIndices : state.journeys.map((journey) => journey.index);
        const paxIndices = updater
            ? updater.PaxIndices
            : state.journeys[0].passengers.map((passenger) => passenger.index);

        return { journeyIndices, paxIndices };
    };

    const isOnePaxOneJourney = () =>
        props.context.journeys.length === 1 && props.context.journeys[0].passengers.length === 1;

    const getInitialPerJourneyPerPaxState = useCallback(
        (bagType: BagType): "open" | "close" => {
            if (
                bookingContext.isCheckinClosedInbound ||
                bookingContext.isCheckinClosedOutbound ||
                (isOnlyOneJourneyInBundle(props.model.Journeys) && bagType !== "OversizedBaggage")
            ) {
                return "open";
            }

            if (bagType === "OversizedBaggage" && isOnePaxOneJourney()) return "open";

            if (bagType === "OversizedBaggage" && isThereNotEnoughOversizedForAllPaxOneJourney) return "open";

            const firstPaxBaggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: OUTBOUND,
                passengerIndex: 0,
            });

            return props.context.journeys.some((journey) =>
                journey.passengers.some((passenger) => {
                    const baggage = props.context.getContextJourneyPassengerBaggage({
                        bagType: props.bagType,
                        journeyIndex: journey.index,
                        passengerIndex: passenger.index,
                    });

                    return baggage.quantity !== firstPaxBaggage.quantity || baggage.isSoldOut;
                }),
            )
                ? "open"
                : "close";
        },
        [
            bookingContext.isCheckinClosedInbound,
            bookingContext.isCheckinClosedOutbound,
            isThereNotEnoughOversizedForAllPaxOneJourney,
            props.context.getContextJourneyPassengerBaggage,
            props.context.journeys,
            props.model.Journeys,
        ],
    );

    const resetBaggageInsurance = () => {
        if (resetInsuranceOnChange && flowContext.isBookingFlow && bookingContext.isBaggageInsuranceAvailable) {
            setResetInsuranceOnChange(false);
            bookingManager.postResetBaggageInsurance();
        }
    };

    const add = useCallback(
        async (updater?: StateUpdater) => {
            if (!updater && !perBooking.isAddAvailable) throw new Error("Cannot add bag to booking.");

            if (
                updater &&
                state.journeys.some((journey) =>
                    journey.passengers.some(
                        (passenger) =>
                            updater.JourneyIndices.includes(journey.index) &&
                            updater.PaxIndices.includes(passenger.index) &&
                            !perJourneyPerPax.isAddAvailable({
                                journeyIndex: journey.index,
                                passengerIndex: passenger.index,
                            }),
                    ),
                )
            ) {
                throw new Error("Cannot add bag to pax.");
            }

            const { journeyIndices, paxIndices } = getIndices(updater);

            const newState: BaggageState = {
                ...state,
                journeys: [
                    ...state.journeys.map(
                        (journey): BaggageStateJourney =>
                            journeyIndices.includes(journey.index)
                                ? {
                                      ...journey,
                                      passengers: [
                                          ...journey.passengers.map(
                                              (pax): BaggageStateJourneyPassenger =>
                                                  paxIndices.includes(pax.index)
                                                      ? {
                                                            ...pax,
                                                            hasSelected: true,
                                                        }
                                                      : pax,
                                          ),
                                      ],
                                  }
                                : journey,
                    ),
                ],
            };

            setState(newState);

            tealiumManager.logBaggageAdd({
                bagType: props.bagType,
                context: props.context,
                journeyIndices,
                paxIndices,
                state,
            });

            resetBaggageInsurance();

            await sendSsrRequest(journeyIndices, paxIndices, "add");

            props.staffBaggage.update({ type: "add", journeyIndices, paxIndices, bagType: props.bagType });
        },
        [
            perBooking.isAddAvailable,
            perJourneyPerPax.isAddAvailable,
            props.context,
            props.staffBaggage,
            state,
            tealiumManager,
            sendSsrRequest,
        ],
    );

    const remove = useCallback(
        async (updater?: StateUpdater) => {
            if (!updater && !perBooking.isRemoveAvailable) throw new Error("Cannot remove bag from booking.");

            if (
                updater &&
                state.journeys.some((journey) =>
                    journey.passengers.some(
                        (pax) =>
                            updater.JourneyIndices.includes(journey.index) &&
                            updater.PaxIndices.includes(pax.index) &&
                            !perJourneyPerPax.isRemoveAvailable({
                                journeyIndex: journey.index,
                                passengerIndex: pax.index,
                            }),
                    ),
                )
            ) {
                throw new Error("Cannot remove bag from pax.");
            }

            const { journeyIndices, paxIndices } = getIndices(updater);

            const newState: BaggageState = {
                ...state,
                journeys: [
                    ...state.journeys.map(
                        (journey): BaggageStateJourney =>
                            journeyIndices.includes(journey.index)
                                ? {
                                      ...journey,
                                      passengers: [
                                          ...journey.passengers.map(
                                              (pax): BaggageStateJourneyPassenger =>
                                                  paxIndices.includes(pax.index)
                                                      ? {
                                                            ...pax,
                                                            hasSelected: true,
                                                        }
                                                      : pax,
                                          ),
                                      ],
                                  }
                                : journey,
                    ),
                ],
            };

            setState(newState);

            tealiumManager.logBaggageRemove({ journeyIndices, paxIndices });

            resetBaggageInsurance();

            await sendSsrRequest(journeyIndices, paxIndices, "remove");

            props.staffBaggage.update({ type: "remove", journeyIndices, paxIndices, bagType: props.bagType });
        },
        [
            perBooking.isRemoveAvailable,
            perJourneyPerPax.isRemoveAvailable,
            props.context,
            props.staffBaggage,
            state,
            tealiumManager,
            sendSsrRequest,
        ],
    );

    const reset = useCallback(
        async (updater?: StateUpdater) => {
            if (!updater && !perBooking.isResetAvailable) throw new Error("Cannot reset bag for booking.");

            if (
                updater &&
                state.journeys.some((journey) =>
                    journey.passengers.some(
                        (pax) =>
                            updater.JourneyIndices.includes(journey.index) &&
                            updater.PaxIndices.includes(pax.index) &&
                            !perJourneyPerPax.isResetAvailable({
                                journeyIndex: journey.index,
                                passengerIndex: pax.index,
                            }),
                    ),
                )
            ) {
                throw new Error("Cannot reset bag for pax.");
            }

            const { journeyIndices, paxIndices } = getIndices(updater);

            const newState: BaggageState = {
                ...state,
                journeys: [
                    ...state.journeys.map(
                        (journey): BaggageStateJourney =>
                            journeyIndices.includes(journey.index)
                                ? {
                                      ...journey,
                                      passengers: [
                                          ...journey.passengers.map(
                                              (pax): BaggageStateJourneyPassenger =>
                                                  paxIndices.includes(pax.index)
                                                      ? {
                                                            ...pax,
                                                            hasSelected: true,
                                                        }
                                                      : pax,
                                          ),
                                      ],
                                  }
                                : journey,
                    ),
                ],
            };

            setState(newState);

            tealiumManager.logBaggageReset({ journeyIndices, paxIndices });

            resetBaggageInsurance();

            await sendSsrRequest(journeyIndices, paxIndices, "reset");

            props.staffBaggage.update({ type: "reset", journeyIndices, paxIndices, bagType: props.bagType });
        },
        [
            perBooking.isResetAvailable,
            perJourneyPerPax.isResetAvailable,
            props.context,
            props.staffBaggage,
            state,
            sendSsrRequest,
        ],
    );

    const open = useCallback(
        (updater?: StateUpdater, toggle?: boolean) => {
            if (!updater) {
                setState((currentState) => ({
                    ...currentState,
                    perJourneyPerPaxState: "open",
                    hasPerJourneyPerPaxStateChanged: true,
                }));
            } else {
                setState((currentState) => ({
                    ...currentState,
                    journeys: currentState.journeys.map(
                        (journey): BaggageStateJourney =>
                            updater.JourneyIndices.includes(journey.index)
                                ? {
                                      ...journey,
                                      isOpen: true,
                                      passengers: journey.passengers.map(
                                          (passenger): BaggageStateJourneyPassenger =>
                                              updater.PaxIndices.includes(passenger.index)
                                                  ? { ...passenger, isOpen: true }
                                                  : { ...passenger, isOpen: toggle ? false : passenger.isOpen },
                                      ),
                                  }
                                : {
                                      ...journey,
                                  },
                    ),
                }));
            }
        },
        [state],
    );

    const close = useCallback(
        (updater?: StateUpdater) => {
            if (!updater) {
                setState((currentState) => ({
                    ...currentState,
                    perJourneyPerPaxState: "close",
                    hasPerJourneyPerPaxStateChanged: true,
                }));
            } else {
                setState((currentState) => ({
                    ...currentState,
                    journeys: currentState.journeys.map(
                        (journey): BaggageStateJourney =>
                            updater.JourneyIndices.includes(journey.index)
                                ? {
                                      ...journey,
                                      isOpen: updater.PaxIndices.length === 0 ? false : journey.isOpen,
                                      passengers: journey.passengers.map(
                                          (passenger): BaggageStateJourneyPassenger =>
                                              updater.PaxIndices.includes(passenger.index)
                                                  ? { ...passenger, isOpen: false }
                                                  : {
                                                        ...passenger,
                                                        isOpen:
                                                            updater.PaxIndices.length === 0 ? false : passenger.isOpen,
                                                    },
                                      ),
                                  }
                                : {
                                      ...journey,
                                  },
                    ),
                }));
            }
        },
        [state],
    );

    const isPassengerValid = useCallback(
        (data: { journey: BaggageStateJourney; passenger: BaggageStateJourneyPassenger }): boolean => {
            const baggage = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journey.index,
                passengerIndex: data.passenger.index,
            });

            return (
                data.passenger.hasSelected ||
                baggage.quantity > 0 ||
                !perJourneyPerPax.isAddAvailable({
                    journeyIndex: data.journey.index,
                    passengerIndex: data.passenger.index,
                })
            );
        },
        [perJourneyPerPax.isAddAvailable, props.context.getContextJourneyPassengerBaggage, props.bagType],
    );

    const findJourneyAndPaxWithError = useCallback(() => {
        const journeyWithError = state.journeys.find((journey) =>
            journey.passengers.some((passenger) => !isPassengerValid({ journey, passenger })),
        );

        if (journeyWithError) {
            const paxWithError = journeyWithError.passengers.find(
                (passenger) =>
                    !isPassengerValid({
                        journey: journeyWithError,
                        passenger,
                    }),
            );

            if (paxWithError) return { journeyIndex: journeyWithError.index, paxIndex: paxWithError.index };
        }

        return undefined;
    }, [isPassengerValid, state.journeys]);

    useEffect(init, []);

    return {
        bagType: props.bagType,
        handlers: {
            add,
            closeJourney: (journeyIndex: number) => close({ JourneyIndices: [journeyIndex], PaxIndices: [] }),
            closePax: (updater: StateUpdater) => close(updater),
            closePerJourneyPerPaxView: () => close(),
            findJourneyAndPaxWithError,
            isPassengerValid,
            openJourney: (journeyIndex: number, toggle?: boolean) =>
                open({ JourneyIndices: [journeyIndex], PaxIndices: [] }, toggle),
            openPax: (updater: StateUpdater) => open(updater, true),
            openPerJourneyPerPaxView: () => open(),
            remove,
            reset,
            setResetInsuranceOnChange,
        },
        perBooking,
        perJourneyPerPax,
        state,
    };
};

import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../../../directives/ref";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { getPassengerName, showLoader } from "../../../../shared/common";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { PriorityBoardingJourneyPassengerModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyPassengerModel";
import { PriorityBoardingJourneyModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PassengerSelection } from "../../../../component-models/extras/PassengerSelection";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import BookingData from "../../../../shared/BookingData";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { PriorityBoardingOptionClickedEvent } from "../../../../component-models/extras/priority-boarding/PriorityBoardingEvents";
import { OUTBOUND } from "../../../../shared/commonConstants";

export const name = "ac-priority-boarding";

export interface Props {
    journey: PriorityBoardingJourneyModel;
    passengerOption: PriorityBoardingJourneyPassengerModel;
    formattedBancoEstadoSavedAmountPriorityBoarding: string;
    passengerSelections: PassengerSelection[];
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        journey: host.journey,
        passengerOption: host.passengerOption,
        formattedBancoEstadoSavedAmountPriorityBoarding: host.formattedBancoEstadoSavedAmountPriorityBoarding,
        passengerSelections: host.passengerSelections,
        setExtrasModel: host.setExtrasModel,
    };

    const isFreeForBancoEstado12 = () =>
        [1, 2].includes(userContext.bancoEstado.category) && props.passengerOption.Amount === 0;

    const notFree = () =>
        ![1, 2, 5, 6].includes(userContext.bancoEstado.category) || props.passengerOption.Amount !== 0;

    const displayAmount = () => notFree() && !props.journey.IsInBundle;

    const isSelected = () =>
        props.passengerSelections?.some(
            (ps) =>
                ps.journeyIndex === props.journey.JourneyIndex &&
                ps.paxIndex === props.passengerOption.PassengerNumber &&
                ps.selected,
        );

    const isPassengerDisabled = () => props.journey.JourneyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound;

    const tealiumLogSelection = (type: "add" | "remove") => {
        tealiumManager.logBinaryExtraSelected(
            "priorityBoarding",
            props.passengerOption.Type === "ADT" ? "A" : "C",
            props.passengerOption.PassengerNumber,
            props.journey.JourneyIndex,
            type,
            props.passengerOption.Amount,
        );
    };

    const handlePriorityBoardingButtonClick = async () => {
        if (!props.passengerOption.SsrAddKey) {
            return;
        }

        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.priorityBoarding);
        const loader = showLoader({
            name: LOADER_CLASS_NAMES.ExtrasPriorityBoarding,
            container: parent.parentElement,
            noPlane: true,
        });

        const container = DomCrawlingHelper.getElemByClass(parent, CLASS_NAMES.errorContainer);
        const body = {
            "selectedJourneySsrs[0]": !isSelected()
                ? props.passengerOption.SsrAddKey
                : props.passengerOption.SsrDeleteKey,
        };

        const response = await bookingManager.postExtrasPageSsrUpdate<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasModel;
        }>(body, container, loader);

        props.setExtrasModel(response.ExtrasModel);

        tealiumLogSelection(!isSelected() ? "add" : "remove");

        host.dispatchEvent(
            new PriorityBoardingOptionClickedEvent({
                paxIndex: props.passengerOption.PassengerNumber,
                journeyIndex: props.journey.JourneyIndex,
                selected: !isSelected(),
            }),
        );
    };

    const root = useRef<HTMLDivElement>(undefined);

    const bookingContext = useBookingContext();
    const [userContext] = useReduxState("userContext");

    const tealiumManager = useExtrasTealiumManager();
    const bookingManager = useBookingManager();

    const [passengerNames] = useReduxState("booking.passengerNames");

    const labelTemplate = () => {
        const tempClassMap = classMap({
            "col-md-2-3": true,
            "col-xs-1-2": isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category),
            "col-xs-2-3": !isFreeForBancoEstado12() && ![5, 6].includes(userContext.bancoEstado.category),
        });

        const label = isFreeForBancoEstado12()
            ? props.formattedBancoEstadoSavedAmountPriorityBoarding
            : displayAmount()
              ? props.passengerOption.FormattedAmount
              : "";

        return html`
            <div
                class=${tempClassMap}
                data-test-value=${props.passengerOption.Amount}
                data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUTTON_CAPTION, {
                    p: props.passengerOption.PassengerNumber,
                    j: props.journey.JourneyIndex,
                })}
            >
                <div class="extras-binary-name">
                    ${getPassengerName(passengerNames, props.passengerOption.PassengerNumber)} ${label}
                </div>
            </div>
        `;
    };

    const nonBancoEstadoButtonTemplate = () => {
        const tempClassMap = classMap({
            "btn-boarding": true,
            "select-dependent-text": true,
            "disabled": props.passengerOption.IsDisabled,
            "selected": isSelected(),
        });

        return ![5, 6].includes(userContext.bancoEstado.category) && !isFreeForBancoEstado12()
            ? html`
                  <div
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUTTON, {
                          p: props.passengerOption.PassengerNumber,
                          j: props.journey.JourneyIndex,
                      })}
                      @click=${handlePriorityBoardingButtonClick}
                  >
                      <span>${i18next.t("V2-Add")}</span>
                      <span>${i18next.t("V2-Remove")}</span>
                  </div>
              `
            : "";
    };

    const bancoEstadoButtonTemplate = () => {
        const tempClassMap = classMap({
            "btn-boarding": true,
            "selected": true,
            "disabled": true,
            "banco-estado": isFreeForBancoEstado12(),
        });

        return isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BANCO_ESTADO_INCLUDED_LABEL, {
                          p: props.passengerOption.PassengerNumber,
                          j: props.journey.JourneyIndex,
                      })}
                  >
                      <span>
                          ${[1, 2].includes(userContext.bancoEstado.category)
                              ? i18next.t("BE-IncludedLabel")
                              : i18next.t("V2-Included")}
                      </span>
                  </div>
              `
            : "";
    };

    const buttonTemplate = () => {
        const tempClassMap = classMap({
            "col-md-1-3": true,
            "col-xs-1-2": isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category),
            "col-xs-1-3": !isFreeForBancoEstado12() && ![5, 6].includes(userContext.bancoEstado.category),
        });

        return html`
            <div class=${tempClassMap}>${bancoEstadoButtonTemplate()} ${nonBancoEstadoButtonTemplate()}</div>
        `;
    };

    return html`
        <div
            class="inner-border-box"
            ref=${ref(root)}
            data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.PASSENGER_OPTION, {
                p: props.passengerOption.PassengerNumber,
                j: props.journey.JourneyIndex,
            })}
        >
            <div class=${isPassengerDisabled() ? "is-past" : ""}>
                <div class="row">${labelTemplate()} ${buttonTemplate()}</div>
            </div>
        </div>
    `;
};

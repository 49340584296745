import { classMap } from "lit-html/directives/class-map";
import { SeatmapJourneySegment } from "../../../../component-models/seatmap/SeatmapJourneySegment";
import { ScrollHelper } from "../../../../shared/ScrollHelper";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { useEffect, useState } from "../../../../shared/haunted/CustomHooks";
import PerfectScrollbar from "../../../../../../node_modules/perfect-scrollbar";
import { html } from "lit-html";
import { useRef } from "haunted";
import { ref } from "../../../../directives/ref";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import { desktopPassengerClassName } from "./seat-passenger";
import i18next from "i18next";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    segment: SeatmapJourneySegment;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    handleSegmentClick: (segmentIndex: number) => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const useSeatPassengerSegment = (props: Props) => {
    // HELPERS

    const isMultisegment = () => props.seatmapState?.CurrentJourney?.Segments.length > 1;

    const isOpen = () => props.segment?.SegmentIndex === props.seatmapState?.CurrentSegment?.SegmentIndex;

    const paxNumber = () => props.seatmapState.CurrentPassenger.PassengerNumber;

    const scrollToTop = () => {
        if (!isCurrentlyVisible()) {
            return;
        }

        scroller.current.scrollTop = 0;
    };

    const initScrollbar = () => {
        if (!scroller.current) {
            return;
        }

        scrollbar?.destroy();

        setScrollbar(
            new PerfectScrollbar(scroller.current, {
                wheelPropagation: false,
                wheelSpeed: 2,
                swipeEasing: true,
                suppressScrollX: true,
            }),
        );
    };

    const scrollToPassenger = () => {
        if (!isCurrentlyVisible() || !props.seatmapState.CurrentPassenger) {
            return;
        }

        ScrollHelper.scrollToChildElement(scroller.current, desktopPassengerClassName, paxNumber());
    };

    const isCurrentlyVisible = () => scroller.current?.offsetHeight > 0;

    // COMPONENT

    const [userContext] = useReduxState("userContext");

    const scroller = useRef<HTMLDivElement>(undefined);

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);

    useEffect(initScrollbar, [scroller.current]);

    // EVENT LISTENERS

    const handleSelectPassenger = (passengerNumber: number) => {
        props.updateState({
            PassengerIndex: passengerNumber,
        });
    };

    const handleSegmentClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.seatmapState.CurrentJourney.Segments.length === 1) {
            return;
        }

        props.handleSegmentClick(props.segment?.SegmentIndex);
    };

    // TEMPLATES

    const passengersTemplate = () =>
        props.context.Model?.Passengers.map((passenger) =>
            [5, 6].includes(userContext.bancoEstado.category)
                ? html`
                      <ac-banco-estato-56-seat-passenger
                          .apiPassenger=${passenger}
                          .context=${props.context}
                          .currentSeats=${props.currentSeats}
                          .journeyIndex=${props.seatmapState?.CurrentJourney?.JourneyIndex}
                          .seatmapState=${props.seatmapState}
                          .segmentIndex=${props.segment?.SegmentIndex}
                          .handleSeatDelete=${props.handleSeatDelete}
                          .onPassengerSelect=${handleSelectPassenger}
                      >
                      </ac-banco-estato-56-seat-passenger>
                  `
                : html`
                      <ac-seat-passenger
                          .apiPassenger=${passenger}
                          .context=${props.context}
                          .currentSeats=${props.currentSeats}
                          .journeyIndex=${props.seatmapState?.CurrentJourney?.JourneyIndex}
                          .seatmapState=${props.seatmapState}
                          .segmentIndex=${props.segment?.SegmentIndex}
                          .handleSeatDelete=${props.handleSeatDelete}
                          .onPassengerSelect=${handleSelectPassenger}
                      >
                      </ac-seat-passenger>
                  `,
        );

    const segmentOpenerTemplate = () =>
        isMultisegment() ? html` <i class="seatmap-pax-opener js-icon js-circle-chevron-right"></i> ` : "";

    const segmentHeaderTemplate = () => {
        const tempClassMap = classMap({
            "seatmap-pax-route": true,
            "cursor-pointer": isMultisegment(),
        });

        return props.segment
            ? html`
                  <div class=${tempClassMap} @click=${handleSegmentClick}>
                      <div class="seatmap-pax-flight-number">
                          ${i18next.t("Vuelo")} ${props.segment.CarrierCode} ${props.segment.FlightNumber}
                      </div>
                      <div class="seatmap-pax-station">
                          <span>${props.segment.DepartureStationName}</span>&nbsp;(${props.segment
                              .DepartureStationCode})
                      </div>
                      <i class="seatmap-pax-plane js-icon js-flight-plane"></i>
                      <div class="seatmap-pax-station">
                          <span>${props.segment.ArrivalStationName}</span>&nbsp;(${props.segment.ArrivalStationCode})
                      </div>
                      ${segmentOpenerTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "seatmap-pax-container": true,
            "open": isOpen(),
        });

        return html`
            <div class=${mainClassMap}>
                ${segmentHeaderTemplate()}
                <div class="seatmap-pax-scroller" ref=${ref(scroller)}>${passengersTemplate()}</div>
            </div>
        `;
    };

    return {
        htmlTemplate,
        scrollToPassenger,
        scrollToTop,
    };
};

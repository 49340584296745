import { ApiCountry, ApiStation } from "../component-models/ApiCountry";
import { City, Country, Region } from "../component-models/RoutePickerModel";
import { Station as XmlStation, Region as XmlRegion, Country as XmlCountry } from "../component-models/StationSettings";
import { MacDictionary } from "../managers/useCountryManager";
import { getLocalizedStringValue } from "../shared/common";

const mapXmlMacToCity = (data: {
    allApiStations: ApiStation[];
    subStationCodes: string[];
    xmlStation: XmlStation;
}): City => {
    if (data.subStationCodes.length === 0) return undefined;

    const apiSubstations = data.subStationCodes.reduce(
        (allSubStations: ApiStation[], subStationCode) =>
            allSubStations.concat(data.allApiStations.filter((c) => c.code === subStationCode)),
        [],
    );

    return {
        code: data.xmlStation.Code,
        isNew: data.xmlStation.IsNew,
        markets: [
            ...Array.from(
                new Set<string>(apiSubstations.reduce((allMarkets, station) => allMarkets.concat(station.markets), [])),
            ),
        ],
        name: apiSubstations[0].value,
        subStations: data.subStationCodes,
    };
};

const mapXmlStationToCity = (data: {
    allApiStations: ApiStation[];
    subStationCodes: string[];
    xmlStation: XmlStation;
}): City => {
    const apiStation = data.allApiStations.find((c) => c.code === data.xmlStation.Code);

    return apiStation
        ? {
              code: data.xmlStation.Code,
              isNew: data.xmlStation.IsNew,
              markets: apiStation.markets,
              name: apiStation.value,
              subStations: data.subStationCodes,
          }
        : undefined;
};

const mapXmlRegionToRegion = (data: {
    allApiStations: ApiStation[];
    culture: string;
    macDictionary: MacDictionary;
    xmlRegion: XmlRegion;
}): Region => ({
    name: getLocalizedStringValue(data.xmlRegion.Name, data.culture),
    cities: data.xmlRegion.Stations.filter((xmlStation) => !xmlStation.IsHidden)
        .map((xmlStation) => {
            const subStationCodes = data.macDictionary.has(xmlStation.Code)
                ? Array.from(data.macDictionary.get(xmlStation.Code).values())
                : [];

            return (
                mapXmlStationToCity({ xmlStation, subStationCodes, allApiStations: data.allApiStations }) ||
                mapXmlMacToCity({ xmlStation, subStationCodes, allApiStations: data.allApiStations })
            );
        })
        .filter((city) => cityHasMarket(city, data.allApiStations)),
});

const cityHasMarket = (city: City, allApiStations: ApiStation[]): boolean =>
    city && allApiStations.some((apiStation) => isPossibleDestination(city, apiStation));

// DEVNOTE This is needed for stuff like "Peru, Republic of"
const getCountryName = (apiCountries: ApiCountry[], countryCode: string): string =>
    apiCountries.find((apiCountry) => apiCountry.code.toLowerCase() === countryCode.toLowerCase())?.value.split(",")[0];

// EXPORTS

export const isPossibleDestination = (originCity: City, destinationCity: City | ApiStation): boolean =>
    destinationCity.markets.includes(originCity.code) ||
    destinationCity.markets.some((marketCode) => originCity.subStations.includes(marketCode));

export const mapXmlCountryToCountry = (data: {
    allApiStations: ApiStation[];
    apiCountries: ApiCountry[];
    culture: string;
    macDictionary: MacDictionary;
    xmlCountry: XmlCountry;
}): Country => ({
    code: data.xmlCountry.Code,
    isAvailableForPeruCompras: data.xmlCountry.IsAvailableForPeruCompras,
    name: getCountryName(data.apiCountries, data.xmlCountry.Code),
    regions: data.xmlCountry.Regions.map((xmlRegion) => mapXmlRegionToRegion({ ...data, xmlRegion })),
});

import { html } from "haunted";
import i18next from "i18next";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { OUTBOUND, FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, INBOUND } from "../../../../shared/commonConstants";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface BaggagePriceBreakdownProps {
    context: BaggageContext;
    isShown: boolean;
    staffBaggage: StaffBaggage;
}

export const useBaggagePriceBreakdown = (props: BaggagePriceBreakdownProps) => {
    const bookingContext = useBookingContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const usedStaffBaggageOutbound = useMemo(() => {
        const number = userContext.isStaff
            ? props.staffBaggage.state.reduce(
                  (sum, usb) => (usb.journeyIndex === OUTBOUND && usb.used === "Cabin" ? sum + 1 : sum),
                  0,
              )
            : 0;
        return number * FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
    }, [props.staffBaggage.state, userContext.isStaff]);

    const usedStaffBaggageInbound = useMemo(() => {
        const number = userContext.isStaff
            ? props.staffBaggage.state.reduce(
                  (sum, usb) => (usb.journeyIndex === INBOUND && usb.used === "Cabin" ? sum + 1 : sum),
                  0,
              )
            : 0;
        return number * FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
    }, [props.staffBaggage.state, userContext.isStaff]);

    const pricePerPersonOutbound = useMemo(
        () =>
            usedStaffBaggageOutbound ||
            (props.context.journeys[0].cabinBundleType === "None"
                ? props.context.journeys[0].passengers[0].cabinBaggage.prices[0]
                : 0),
        [
            props.context.journeys[0].cabinBundleType,
            usedStaffBaggageOutbound,
            props.context.journeys[0].passengers[0].cabinBaggage,
        ],
    );

    const pricePerPersonInbound = useMemo(
        () =>
            usedStaffBaggageInbound ||
            (props.context.journeys[1]?.cabinBundleType === "None"
                ? props.context.journeys[1]?.passengers[0].cabinBaggage.prices[0] || 0
                : 0),
        [
            props.context.journeys[1]?.cabinBundleType,
            usedStaffBaggageInbound,
            props.context.journeys[1]?.passengers[0].cabinBaggage,
        ],
    );

    const formatValue = (amount: number) => {
        const formattedAmount = formatNumber({ amount, leadingSign: true }).replace(" ", "");

        return amount === 0 ? "+ 0" : formattedAmount;
    };

    const dotsTemplate = () => html`
        <span class="mx-1 inline-flex justify-end overflow-hidden whitespace-nowrap text-right">
            ${[...Array(100)].map(() => ". ")}
        </span>
    `;

    const labelTemplate = (label: string) => html`
        <span class="flex-1 whitespace-nowrap text-[11px] font-semibold sm:text-sm">${label}</span>
    `;

    const priceTemplate = (price: number) => html`
        <span class="flex-1 whitespace-nowrap text-right text-[11px] font-semibold sm:text-sm">
            ${formatValue(price)}
        </span>
    `;

    const routeTemplate = (label: string, price: number) => html`
        <div class="flex w-full items-end text-[11px] sm:w-[300px] sm:text-sm">
            ${labelTemplate(label)} ${dotsTemplate()} ${priceTemplate(price)}
        </div>
    `;

    const htmlTemplate = () =>
        props.isShown &&
        props.context.journeys.length > 1 &&
        props.context.journeys[0].cabinBundleType === props.context.journeys[1].cabinBundleType
            ? html`<div
                  class=${classNames(
                      "absolute bottom-0 left-4 right-4 mb-[5px] ml-[2px] mt-[4px] text-be-blue",
                      "sm:static sm:bottom-auto sm:left-auto sm:right-auto sm:mx-[10px] sm:mb-0 sm:mt-[10px] sm:text-white",
                  )}
              >
                  ${routeTemplate(i18next.t("Precio vuelo de ida"), pricePerPersonOutbound)}
                  ${!bookingContext.isOneWay
                      ? routeTemplate(i18next.t("Precio vuelo de vuelta"), pricePerPersonInbound)
                      : ""}
                  <div class="text-right text-[11px] font-semibold sm:text-xs">*${i18next.t("Por pasajero")}</div>
              </div>`
            : "";

    return { htmlTemplate };
};

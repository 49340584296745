import { ROUTES } from "./../../shared/apiRoutes";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { getStringForCulture } from "../../component-helpers/stringHelper";
import { AGENCY_URL_PARTIAL_STRING, COMPANY_URL_PARTIAL_STRING, LoginType } from "../../shared/commonConstants";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { LoginVM } from "./login-model";
import { ValidatorPartialMethods } from "./login-page";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";

export const name = "ac-agency-login";

export interface Properties {
    vm: LoginVM;
    validatorPartialMethods: ValidatorPartialMethods;
    increaseUpperMargin: boolean;
    loginType: LoginType;
    responseError: string;
    setVm: (vm: LoginVM) => void;
    handleSubmit: (e: MouseEvent) => Promise<void>;
    handleKeyup: (e: KeyboardEvent) => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        vm: host.vm,
        setVm: host.setVm,
        validatorPartialMethods: host.validatorPartialMethods,
        increaseUpperMargin: host.increaseUpperMargin,
        loginType: host.loginType,
        responseError: host.responseError,
        handleSubmit: host.handleSubmit,
        handleKeyup: host.handleKeyup,
    };

    // HELPERS

    const getReversedCugUriComponent = () =>
        props.loginType === "AGENCY" ? COMPANY_URL_PARTIAL_STRING : AGENCY_URL_PARTIAL_STRING;

    const getCugUriComponent = () =>
        props.loginType === "AGENCY" ? AGENCY_URL_PARTIAL_STRING : COMPANY_URL_PARTIAL_STRING;

    const bgDesktopImage = () =>
        getStringForCulture(
            cug2AppContext.Cug2BSettings.Login[props.loginType === "AGENCY" ? "AgencyImageUrl" : "CompanyImageUrl"]
                .DesktopImageUrl,
            cug2AppContext.Culture,
        );

    const bgTabletImage = () =>
        getStringForCulture(
            cug2AppContext.Cug2BSettings.Login[props.loginType === "AGENCY" ? "AgencyImageUrl" : "CompanyImageUrl"]
                .TabletImageUrl,
            cug2AppContext.Culture,
        );

    const bgMobileImage = () =>
        getStringForCulture(
            cug2AppContext.Cug2BSettings.Login[props.loginType === "AGENCY" ? "AgencyImageUrl" : "CompanyImageUrl"]
                .MobileImageUrl,
            cug2AppContext.Culture,
        );

    const getBackgroundImageCssStyleByResolution = (resolution: "desktop" | "tablet" | "mobile") => {
        switch (resolution) {
            case "tablet":
                return `background: url('${bgTabletImage().trim()}'); background-size: cover; background-repeat: no-repeat; background-position: center center;`;
            case "mobile":
                return `background: url('${bgMobileImage().trim()}'); background-size: cover; background-repeat: no-repeat; background-position: center center;`;
            default:
                return `background: url('${bgDesktopImage().trim()}'); background-size: cover; background-repeat: no-repeat; background-position: center center;`;
        }
    };

    // COMPONENT

    const tealiumManager = useAuthenticationTealiumManager();
    const cug2AppContext = useCug2AppContext();
    const formErrors = useErrorMessage({ errorMessage: props.validatorPartialMethods.getFormMessages() });

    // EVENT LISTENERS

    const handleRegister = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        tealiumManager.logCugRegister(props.loginType);

        window.location.href = `${ROUTES.Cug2BRegisterPage}?${getCugUriComponent()}=1`;
    };

    // TEMPLATES

    const loginTypeSelectorTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="logintype-tabs">
                    <label class="mdl-radio mdl-js-radio" for="option1b">
                        <input
                            type="radio"
                            id="option1b"
                            value=${props.loginType.toUpperCase()}
                            name="loginType"
                            class="mdl-radio__button"
                            checked
                        />
                        <span class="mdl-radio__label"
                            >${props.loginType === "AGENCY" ? i18next.t("Agencies") : i18next.t("Companies")}</span
                        >
                    </label>
                </div>
            </div>
        </div>
    `;

    const submitButtonTemplate = () => html`
        <div class="mt-4 flex justify-end">
            <button class="rounded-primary-btn" data-test-id=${T.CUG2_LOGIN.BUTTON} @click=${props.handleSubmit}>
                ${i18next.t("Iniciar sesión")}
            </button>
        </div>
    `;

    const invalidAgentTypeErrorTemplate = () =>
        props.responseError === "Error_InvalidAgentType"
            ? html`
                  ${props.loginType === "AGENCY"
                      ? i18next.t("You are trying to log in with an organization that is a company, please log in")
                      : i18next.t("You are trying to log in with an organization that is an agency, please log in")}<a
                      href="/V2/Login?${getReversedCugUriComponent()}=1"
                      data-test-id=${T.CUG2_LOGIN.INVALID_AGENT_TYPE_REDIRECT_LINK}
                  >
                      &nbsp;${i18next.t("here")}
                  </a>
              `
            : "";

    const usernameAndPasswordMismatchTemplate = () =>
        props.responseError === "InvalidLoginCredentials"
            ? i18next.t(
                  "El correo electrónico, nombre de usuario y/o contraseña es incorrecta. Por favor, Intentalo de nuevo.",
              )
            : "";

    const loginResponseErrorMessageTemplate = () =>
        props.responseError &&
        props.responseError !== "Error_InvalidAgentType" &&
        props.responseError !== "InvalidLoginCredentials"
            ? html`${props.responseError}`
            : "";

    const responseErrorTemplate = () =>
        props.responseError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">
                                  ${invalidAgentTypeErrorTemplate()} ${usernameAndPasswordMismatchTemplate()}
                                  ${loginResponseErrorMessageTemplate()}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const customerRegisterButtonTemplate = () => html`
        <div class="cug2b-login-register">
            <h1>
                ${props.loginType === "AGENCY"
                    ? i18next.t("¿Aún no eres miembro del portal de agencias?")
                    : i18next.t("¿Aún no eres miembro del portal de empresas?")}
            </h1>
            <p>
                ${props.loginType === "AGENCY"
                    ? i18next.t("¡Regístra hoy a tu agencia y ahorra!")
                    : i18next.t("¡Regístra hoy a tu empresa y ahorra!")}
            </p>
            <div class="flex justify-end">
                <button
                    @click=${handleRegister}
                    class="rounded-secondary-btn with-arrow"
                    data-test-id=${T.CUG2_LOGIN.REGISTER_BUTTON}
                >
                    ${i18next.t("Inscríbete")}
                </button>
            </div>
        </div>
    `;

    return html`
        <div class="cug2b-login-content"></div>
        <div class="cug2b-login-main ${props.increaseUpperMargin ? "push-down" : ""}">
            <div
                class="cug2b-login-banner hidden-sm-down"
                style=${getBackgroundImageCssStyleByResolution("desktop")}
            ></div>
            <div
                class="cug2b-login-banner hidden-md-up hidden-xs"
                style=${getBackgroundImageCssStyleByResolution("tablet")}
            ></div>
            <div
                class="cug2b-login-banner hidden-sm-up"
                style=${getBackgroundImageCssStyleByResolution("mobile")}
            ></div>
            <div class="cug2b-login-form" data-test-id=${T.CUG2_LOGIN.FORM}>
                <div class="cug2b-login-header">
                    <i class="js-icon-cug js-cug-man-and-plane"></i>
                    <h1>${i18next.t("Iniciar Sesión")}</h1>
                </div>
                ${loginTypeSelectorTemplate()}
                <div class="row">
                    <div class="col-xs-1">
                        <div class="mt-[20px]">
                            <ac-input
                                .errorMessage=${props.validatorPartialMethods.getMessage("username")}
                                .isInvalid=${!props.validatorPartialMethods.isValid("username")}
                                .label=${i18next.t("Header-EmailAddressAndUsernamePlaceholder")}
                                .autoComplete=${"cc-exp"}
                                .testId=${T.CUG2_LOGIN.EMAIL_OR_USERNAME_INPUT}
                                .value=${props.vm.username}
                                .onInput=${(value: string) => props.setVm({ ...props.vm, username: value })}
                            ></ac-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-1">
                        <div class="mt-[20px]">
                            <ac-input
                                .errorMessage=${props.validatorPartialMethods.getMessage("password")}
                                .isInvalid=${!props.validatorPartialMethods.isValid("password")}
                                .type=${"password"}
                                .label=${i18next.t("Header-PasswordLabel")}
                                .autoComplete=${"new-password"}
                                .testId=${T.CUG2_LOGIN.PASSWORD_INPUT}
                                .value=${props.vm.password}
                                .onInput=${(value: string) => props.setVm({ ...props.vm, password: value })}
                                .onKeyUp=${(e: KeyboardEvent) => props.handleKeyup(e)}
                            ></ac-input>
                        </div>
                    </div>
                </div>
                ${responseErrorTemplate()} ${formErrors.htmlTemplate()}
                <div class="row">
                    <div class="col-xs-1">
                        <a href=${`${ROUTES.ForgotPassword}${`?${getCugUriComponent()}=1`}`}
                            >${i18next.t("Olvidé mi contraseña")}</a
                        >
                    </div>
                </div>
                ${submitButtonTemplate()} ${customerRegisterButtonTemplate()}
            </div>
        </div>
    `;
};

import { html } from "lit-html";
import { useInstallmentsCommonTemplate } from "./useInstallmentsCommonTemplate";
import { useAppContext } from "../../managers/useAppContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";

export interface Props {
    isActive: boolean;
    selectedInstallmentsNumber: number;
    handleInstallmentChange: (selectedInstallmentNumber: number, newTotal: number) => void;
    tealiumLogCompraquiInstallmentChange: (value: number) => void;
}

export const useInstallmentsCompraqui = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const { isInstallmentsDisabledForFlow } = paymentHelper();

    const [userContext] = useReduxState("userContext");

    const showSelector = () => !isInstallmentsDisabledForFlow(appContext, flowContext) && props.isActive;

    const handleNumberOfInstallmentsChange = (e: MouseEvent) => {
        const selectedNumber = Number((e.target as HTMLSelectElement).value);
        props.handleInstallmentChange(selectedNumber, 0);
        props.tealiumLogCompraquiInstallmentChange(props.selectedInstallmentsNumber);
    };

    const installmentsCommonTemplate = useInstallmentsCommonTemplate({
        options:
            userContext.bancoEstado.category === 0
                ? [
                      { Value: "1", Text: "1" },
                      { Value: "3", Text: "3" },
                      { Value: "6", Text: "6" },
                      { Value: "9", Text: "9" },
                      { Value: "12", Text: "12" },
                  ]
                : [
                      { Value: "1", Text: "1" },
                      { Value: "6", Text: "6" },
                      { Value: "12", Text: "12" },
                  ],
        selectedInstallmentsNumber: props.selectedInstallmentsNumber,
        handleNumberOfInstallmentsChange,
    });

    const htmlTemplate = () =>
        showSelector()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                              ${installmentsCommonTemplate.htmlTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : "";

    return { htmlTemplate };
};

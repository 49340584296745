export interface UserContext {
    bancoEstado: {
        category: number;
        remainingFreeSeats: number;
        todosumaPoints: number;
    };
    cug: {
        isAdminOrSupervisor: boolean;
        isAgent: boolean;
        isMember: boolean;
        orgCode: string;
        orgCurrency: string;
        orgName: string;
    };
    dc: {
        canBuyMembership: boolean;
        dcLevel: string;
        groupPriceFormatted: string;
        hasGroupMembership: boolean;
        hasMembership: boolean;
        hasStandardMembership: boolean;
        membershipDaysRemaining: number;
        programNumber: string;
        showWarning15: boolean;
        showWarning30: boolean;
        standardPriceFormatted: string;
        upgradePriceFormatted: string;
    };
    peruCompra: {
        availableAmount: number;
        isAdmin: boolean;
        isMember: boolean;
    };
    isFarelockDisabled: boolean;
    isLoggedIn: boolean;
    isStaff: boolean;
    userFirstName: string;
    userLastName: string;
    userUserName: string;
    userProgram: string;
    userRole: string;
}

export const DEFAULT_USER_CONTEXT: UserContext = {
    bancoEstado: {
        category: 0,
        remainingFreeSeats: 0,
        todosumaPoints: 0,
    },
    cug: {
        isAdminOrSupervisor: false,
        isAgent: false,
        isMember: false,
        orgCode: "",
        orgCurrency: "",
        orgName: "",
    },
    dc: {
        canBuyMembership: false,
        dcLevel: "",
        groupPriceFormatted: "",
        hasGroupMembership: false,
        hasMembership: false,
        hasStandardMembership: false,
        membershipDaysRemaining: 0,
        programNumber: "",
        showWarning15: false,
        showWarning30: false,
        standardPriceFormatted: "",
        upgradePriceFormatted: "",
    },
    peruCompra: {
        availableAmount: 0,
        isAdmin: false,
        isMember: false,
    },
    isFarelockDisabled: false,
    isLoggedIn: false,
    isStaff: false,
    userFirstName: "",
    userLastName: "",
    userUserName: "",
    userProgram: "",
    userRole: "",
};

import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../shared/useModal";

export const useDcBlockedForCugModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body leading-loose text-be-blue">
            ${i18next.t("No es posible comprar la membresía del Club de descuentos para empresas/agencias.")}
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => i18next.t("Importante") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { ROUTES } from "./../shared/apiRoutes";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { ref } from "../directives/ref";
import { handleFieldBlur, validate } from "../shared/form-validation";
import { TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { FULL_EMAIL_REGEX } from "../shared/commonConstants";

export const name = "ac-booking-opener";

export const Component: HauntedFunc<{}> = () => {
    const handleValidate = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = validate(form.current);

        if (isValid) {
            form.current.submit();
        }
    };

    const form = useRef<HTMLFormElement>(undefined);

    const [isEmail, setIsEmail] = useState<Boolean>(false);

    const handleInput = (e: Event) => {
        const input = e.target as HTMLInputElement;
        const value = input.value.trim();
        setIsEmail(FULL_EMAIL_REGEX.test(value));
    };

    return html`
        <form action=${ROUTES.BookingRetrieve} method="GET" novalidate ref=${ref(form)}>
            <div class="change-search ts-error-container ts-error-parent">
                <header>${i18next.t("V2Home-MyTripTitle")}</header>
                <div class="row">
                    <div class="col-xs-1 col-sm-1-2">
                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                            <label class="mdl-textfield__label">
                                ${i18next.t("V2Home-MyTripPassengerNameOrEmail")}
                            </label>
                            <input
                                autocomplete="off"
                                class="mdl-textfield__input js-input"
                                name=${isEmail ? "em" : "ln"}
                                type="text"
                                data-required
                                data-test-id=${T.BOOKING_OPENER.EMAIL_INPUT_FIELD}
                                @input=${handleInput}
                                @blur=${(e: MouseEvent) => handleFieldBlur(e.target as HTMLInputElement, form.current)}
                            />
                        </div>
                    </div>
                    <div class="col-xs-1 col-sm-1-2">
                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                            <label class="mdl-textfield__label"> ${i18next.t("V2Home-MyTripRecordLocator")} </label>
                            <input
                                autocomplete="off"
                                class="mdl-textfield__input js-input"
                                name="rl"
                                type="text"
                                data-required
                                data-test-id=${T.BOOKING_OPENER.PNR_INPUT_FIELD}
                                @blur=${(e: MouseEvent) => handleFieldBlur(e.target as HTMLInputElement, form.current)}
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-1">
                        <div class="error-message-container">
                            <div class="form-error-message ts-required-error-message hidden">
                                ${i18next.t("V2-RequiredFieldsWarning")}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-1">
                        <div class="error-message-container">
                            <div class="form-error-message ts-invalid-email hidden">
                                ${i18next.t("V2-InvalidEmailFormat")}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <button
                        @click=${handleValidate}
                        class="rounded-primary-btn cta-my-trip"
                        data-test-id=${T.BOOKING_OPENER.SUBMIT_CHECKIN_BUTTON}
                    >
                        ${i18next.t("V2-Continue")}
                    </button>
                </div>
            </div>
        </form>
    `;
};

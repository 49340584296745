import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { useDcTermsTemplate } from "./useDcTermsTemplate";
import { useNoDiscountUrl } from "./useNoDiscountUrl";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface UpgradeMembershipBannerProps {
    context: FlightPageContext;
}

export const useUpgradeMembershipBanner = (props: UpgradeMembershipBannerProps) => {
    const [userContext] = useReduxState("userContext");

    const selectedOption = props.context?.bannerState?.selectedOption;

    const terms = useDcTermsTemplate({ context: props.context });
    const noDiscountUrl = useNoDiscountUrl({ context: props.context });

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const upgradePrice = useMemo(
        () =>
            userContext?.isLoggedIn &&
            !isCurrentBancoEstadoCategory([0]) &&
            userContext.dc.upgradePriceFormatted
                ? userContext.dc.upgradePriceFormatted
                : props.context?.bannerState.bannerVM?.upgradePrice,
        [userContext, props.context?.bannerState.bannerVM?.upgradePrice],
    );

    const handleUpgrade = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedOption === "Upgrade") return;

        props.context.selectDcOption("Upgrade", terms.termsAccepted ? "Accepted" : "NotAccepted");
    };

    const upgradeWarningTemplate = () => html`
        <div class="dc-group-membership-description">
            <span class="attention-text">${i18next.t("¡Importante!")}</span>
            ${i18next.t(
                "Es necesario que adquieras la membresía grupal, para que toda la reserva pueda disfrutar de los beneficios del Club de Descuentos.",
            )}
        </div>
    `;

    const bodyTemplate = () => {
        const tempClassMap = classNames("rounded-primary-btn dc-yellow", {
            selected: selectedOption === "Upgrade",
        });

        return html`
            <div class="upgrade-membership-description">
                ${i18next.t(
                    "Tu membresía actual aplica solo para 2 pasajeros. Compra tu membresía grupal y obtén los beneficios del Club de Descuentos para más pasajeros.",
                )}
            </div>
            ${terms.htmlTemplate()}
            <div class="hidden-xs">${upgradeWarningTemplate()}</div>
            <div class="dc-membership-btn-container">
                <button class=${tempClassMap} @click=${handleUpgrade}>${i18next.t("Agregar")}</button>
            </div>
            <div class="hidden-sm-up">${upgradeWarningTemplate()}</div>
            ${noDiscountUrl.htmlTemplate()}
        `;
    };

    const headerTemplate = () => html`
        <i class="hidden-xs js-icon js-flight-plane"></i>
        <div class="dc-banner-content-header-text">
            ${unsafeHTML(
                i18next.t("¡Amplía tu membresía para tí y hasta 5 acompañantes por solo {{-amount}}!", {
                    amount: `<span class="upgrade-price-emphasis">${upgradePrice} </span>`,
                }),
            )}
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="dc-banner-content">
            <div class="dc-banner-content-header bg-be-cyan-2">${headerTemplate()}</div>
            <div class="dc-banner-content-body">${bodyTemplate()}</div>
        </div>
    `;

    return { htmlTemplate };
};

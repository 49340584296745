import { NO_SPECIAL_CHAR_REGEX } from "../../../commonConstants";
import { InputElement } from "../useForm";
import { InputFieldAttribute, UDF_ATTR_NO_SPEC } from "../InputFieldAttribute";

export const noSpecialCharacters = (): InputFieldAttribute => ({
    name: UDF_ATTR_NO_SPEC,
    validators: [
        {
            errorMessage: {
                id: "specialCharactersError",
                message: window.formResources.noSpecialCharactersNumbersAllowed,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = NO_SPECIAL_CHAR_REGEX.test(field.value);

                return Promise.resolve(result);
            },
        },
    ],
});

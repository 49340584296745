import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useSeatPassengerHelper } from "../../../../component-helpers/seatmap/useSeatPassengerHelper";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const desktopPassengerClassName = "ts-seat-passenger";

export const name = "ac-seat-passenger";

export interface Props {
    apiPassenger: ApiSeatmapPassenger;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    journeyIndex: number;
    seatmapState: SeatmapState;
    segmentIndex: number;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    onPassengerSelect: (paxNumber: number) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        apiPassenger: host.apiPassenger,
        context: host.context,
        currentSeats: host.currentSeats,
        journeyIndex: host.journeyIndex,
        seatmapState: host.seatmapState,
        segmentIndex: host.segmentIndex,
        handleSeatDelete: host.handleSeatDelete,
        onPassengerSelect: host.onPassengerSelect,
    };

    const { canShowSeatDeleteButton, isSeatDisabled, isSeatSelected, getSeatNumberForPaxForSegment } =
        useSeatPassengerHelper({
            context: props.context,
            currentSeats: props.currentSeats,
            seatmapState: props.seatmapState,
        });

    // HELPERS

    const seatForPaxForSegment = () => props.currentSeats.find((s) => isSeatForPaxForSegment(s));

    const isSeatForPaxForSegment = (seat: ExtendedApiSeat) =>
        seat.PassengerNumber === props.apiPassenger.PassengerNumber &&
        seat.JourneyIndex === props.journeyIndex &&
        seat.SegmentIndex === props.segmentIndex;

    const isLegacyBancoEstadoSeat = () =>
        [1, 2, 3, 7].includes(userContext.bancoEstado.category) && seatForPaxForSegment()?.IsBancoEstado;

    const showSeatDeleteButton = () => canShowSeatDeleteButton(props.apiPassenger);

    const isDisabled = () => isSeatDisabled();

    const isSelected = () => isSeatSelected(props.apiPassenger);

    const seatNumber = () => getSeatNumberForPaxForSegment(props.apiPassenger);

    const getSeatCategoryForPaxForSegment = () => seatForPaxForSegment()?.CategoryNumber;

    const getPaxName = () => (passengerNames ? passengerNames[props.apiPassenger.PassengerNumber] : "");

    // COMPONENT

    const [userContext] = useReduxState("userContext");
    const [passengerNames] = useReduxState("booking.passengerNames");

    // EVENT LISTENERS

    const handleClickPassenger = () => {
        if (props.context.Model.IsReadOnly) {
            return;
        }

        props.onPassengerSelect(props.apiPassenger.PassengerNumber);
    };

    // TEMPLATES

    const desktopTemplate = () => {
        const tempClassMap = classMap({
            "hidden-md-down": true,
            "seat-pax": true,
            "is-past": isDisabled(),
        });

        const nameClassMap = classMap({
            "seat-pax-name": true,
            "selected": !props.context.Model.IsReadOnly && isSelected(),
            "banco-estado-color": isLegacyBancoEstadoSeat(),
        });

        const numberClassMap = classMap({
            "subsection": true,
            "category-1": getSeatCategoryForPaxForSegment() === 1,
            "category-2": getSeatCategoryForPaxForSegment() === 2,
            "category-3": getSeatCategoryForPaxForSegment() === 3,
            "category-4": getSeatCategoryForPaxForSegment() === 4,
            "category-5": getSeatCategoryForPaxForSegment() === 5,
            "banco-estado-color": isLegacyBancoEstadoSeat(),
            "selected": seatNumber(),
        });

        const numberInsideClassMap = classMap({
            "seat-number": true,
            "condensed": !showSeatDeleteButton(),
            "opacity-0": props.seatmapState?.CurrentSegment?.SegmentIndex !== props.segmentIndex,
        });

        const nameDataTestId = getTestId(T.SEATMAP.NON_BANCO_ESTADO_56_PASSENGER_NAME_AND_SEAT_CONTAINER, {
            p: props.apiPassenger.PassengerNumber,
            j: props.journeyIndex,
            s: props.segmentIndex,
        });

        const numberDataTestId = getTestId(T.SEATMAP.NON_BANCO_ESTADO_56_PASSENGER_SEAT_NUMBER, {
            p: props.apiPassenger.PassengerNumber,
            j: props.journeyIndex,
            s: props.segmentIndex,
        });

        return html`
            <div class=${tempClassMap}>
                <div
                    class=${nameClassMap}
                    data-test-value=${seatNumber()}
                    data-test-id=${nameDataTestId}
                    @click=${handleClickPassenger}
                >
                    ${getPaxName()}
                    <div class=${numberClassMap}>
                        <div class=${numberInsideClassMap} data-test-id=${numberDataTestId}>
                            ${seatNumber() || "--"}
                        </div>
                        ${desktopSeatDeleteButtonTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    const desktopSeatDeleteButtonTemplate = () =>
        !props.context.Model.IsReadOnly && showSeatDeleteButton()
            ? html`
                  <div
                      class="delete-seat"
                      data-test-id=${getTestId(T.SEATMAP.NON_BANCO_ESTADO_56_SEAT_DELETE_BUTTON, {
                          p: props.apiPassenger.PassengerNumber,
                          j: props.journeyIndex,
                          s: props.segmentIndex,
                      })}
                      @click=${() => props.handleSeatDelete(props.apiPassenger)}
                  >
                      &times;
                  </div>
              `
            : "";

    return html` <div class="${desktopPassengerClassName} h-full">${desktopTemplate()}</div> `;
};

import dayjs from "dayjs";
import i18next from "i18next";
import { html } from "lit-html";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { AlternateCode, Card as ApiCard } from "../../component-models/PaymentMethodDescriptors";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { padWithLeadingZeros } from "../../shared/common";
import { AGENCY_PAYMENT_FOP_CODE, DEFAULT_DATE_FORMAT, PARAGUAYAN_CULTURE_CODE } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useInputCardNumber } from "./useInputCardNumber";
import { useInstallments } from "./useInstallments";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { CreditCardValidationResult } from "../../component-models/payment/CreditCardValidationResult";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";

export type CardValidationStatus = "unknown" | "valid" | "invalid";

export interface Props {
    isValidated: boolean;
    model: PaymentPageViewModel;
    handleInstallmentNumberChange: (newTotal: number) => void;
}

export const useCard = (props: Props) => {
    const appContext = useAppContext();

    const { isCardNumberLengthValid, validateCreditCard, isCardInvalidForCountryAndCurrency } = paymentHelper();

    const [userContext] = useReduxState("userContext");
    const [payerData] = useReduxState("payment.payer");
    const [selectedPaymentMethod] = useReduxState("payment.paymentMethod");
    const [selectedCurrency, setSelectedCurrency] = useReduxState("payment.selectedCurrency");
    const [cardData, setCardData] = useReduxState("payment.cardData");

    const [year, setYear] = useState<number>(undefined);
    const [month, setMonth] = useState<number>(undefined);

    const inputCardNumber = useInputCardNumber({
        isCardBancoEstado: cardData?.ValidationResult?.IsBancoEstado,
        isValidated: props.isValidated,
        model: props.model,
        setCardNumber: (value) => handleCardNumberChange(value),
    });

    const installments = useInstallments({
        amount: selectedCurrency && props.model ? props.model.AmountPerCurrency[selectedCurrency].Amount : 0,
        installmentType: selectedPaymentMethod?.InputFieldRequirements?.InstallmentType,
        model: props.model,
        handleInstallmentNumberChange: props.handleInstallmentNumberChange,
    });

    const isNameOnCardNeeded = () => cardData?.ValidationResult?.Card?.IsNameOnCardNeeded;

    const handleCardNumberChange = (value: string) => {
        const validationResult = validateCreditCard(
            props.model,
            selectedPaymentMethod,
            value,
            appContext.Culture,
            appContext.BancoEstadoBins,
        );
        const alternateCode = getAlternatePaymentCode(validationResult);
        const fieldRequirements = getFieldRequirements(alternateCode);

        setCardData({
            ...cardData,
            CardNumber: value,
            AlternateCode: alternateCode,
            CardType: validationResult?.Card?.Type,
            FieldRequirements: fieldRequirements,
            IsNameNeeded: isNameOnCardNeeded(),
            CardValidationStatus: getIsCardValid(value, validationResult) ? "valid" : "invalid",
            FopBrand: validationResult?.FopBrand,
            IsCvvNeeded: validationResult?.Card?.IsCvvNeeded,
            PaymentMethodCodeToSubmit: alternateCode?.Code || validationResult?.PaymentMethodCodeToSubmit,
            ValidationResult: validationResult,
        });
    };

    const handleAlternatePaymentCodeChange = () => {
        if (cardData?.AlternateCode?.ForcedCurrencies?.length > 0) {
            setSelectedCurrency(cardData?.AlternateCode.ForcedCurrencies[0]);
        }
    };

    const noCountryBlacklistForCode = (code: AlternateCode) =>
        !code.CardIssuerCountryBlacklist || code.CardIssuerCountryBlacklist.length === 0;

    const noCountryWhitelistForCode = (code: AlternateCode) =>
        !code.CardIssuerCountryWhitelist || code.CardIssuerCountryWhitelist.length === 0;

    const noCurrencyConstraintForCode = (code: AlternateCode) =>
        !code.SelectedCurrencies || code.SelectedCurrencies.length === 0;

    const doesCurrencyAllowAlternateCode = (code: AlternateCode) =>
        noCurrencyConstraintForCode(code) ||
        (code.SelectedCurrencies && code.SelectedCurrencies.includes(selectedCurrency));

    const doesCountryWhitelistAllowAlternateCode = (code: AlternateCode) =>
        noCountryWhitelistForCode(code) ||
        code.CardIssuerCountryWhitelist.includes(payerData?.CurrentCardIssuerCountry);

    const doesCountryBlacklistAllowAlternateCode = (code: AlternateCode) =>
        noCountryBlacklistForCode(code) ||
        !code.CardIssuerCountryBlacklist.includes(payerData?.CurrentCardIssuerCountry);

    const codeHasNoRuleset = (code: AlternateCode) =>
        noCountryBlacklistForCode(code) && noCountryWhitelistForCode(code) && noCurrencyConstraintForCode(code);

    const getCodeBasedOnCurrencyAndCardIssuerCountry = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                doesCountryWhitelistAllowAlternateCode(code) &&
                doesCountryBlacklistAllowAlternateCode(code) &&
                doesCurrencyAllowAlternateCode(code)
            );
        });

    const getCodeBasedOnCardIssuerOnly = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                noCurrencyConstraintForCode(code) &&
                doesCountryBlacklistAllowAlternateCode(code) &&
                doesCountryWhitelistAllowAlternateCode(code)
            );
        });

    const getCodeBasedOnCurrencyOnly = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                noCountryBlacklistForCode(code) &&
                noCountryWhitelistForCode(code) &&
                code.SelectedCurrencies &&
                code.SelectedCurrencies.includes(selectedCurrency)
            );
        });

    const getAlternatePaymentCode = (validationResult: CreditCardValidationResult): AlternateCode => {
        if (
            !payerData?.CurrentCardIssuerCountry ||
            !selectedCurrency ||
            !validationResult?.Card?.Type ||
            !selectedPaymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode
        ) {
            return undefined;
        }

        const currentCard = selectedPaymentMethod?.AllowedCards.find(
            (card) => card.Type === validationResult?.Card?.Type,
        );

        if (!currentCard) return undefined;

        return (
            getCodeBasedOnCurrencyAndCardIssuerCountry(currentCard) ||
            getCodeBasedOnCardIssuerOnly(currentCard) ||
            getCodeBasedOnCurrencyOnly(currentCard) ||
            undefined
        );
    };

    const isRequiredBancoEstadoCardError = (cardNumber: string, validationResult: CreditCardValidationResult) =>
        isCardNumberLengthValid(selectedPaymentMethod?.PaymentMethodCode, validationResult?.Card?.Type, cardNumber) &&
        [1, 2, 3, 5, 6, 7].includes(userContext.bancoEstado.category) &&
        !validationResult?.IsBancoEstado;

    const noCardValidationNeeded = () =>
        selectedPaymentMethod?.AllowedCards.length === 0 ||
        selectedPaymentMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE ||
        selectedPaymentMethod?.AllowUnrecognizedCardTypes;

    const getIsCardValid = (cardNumber: string, validationResult: CreditCardValidationResult) => {
        if (noCardValidationNeeded()) return true;

        if (
            !validationResult?.Card?.Type ||
            !validationResult?.IsValid ||
            isRequiredBancoEstadoCardError(cardNumber, validationResult) ||
            !isCardNumberLengthValid(
                selectedPaymentMethod?.PaymentMethodCode,
                validationResult?.Card?.Type,
                cardNumber,
            ) ||
            isCardInvalidForCountryAndCurrency(
                validationResult?.Card?.Type,
                selectedPaymentMethod?.AllowedCards,
                payerData?.CurrentCardIssuerCountry,
            )
        ) {
            return false;
        }

        return true;
    };

    const getFieldRequirements = (alternateCode: AlternateCode) =>
        alternateCode?.InputFieldRequirements
            ? {
                  ...selectedPaymentMethod?.InputFieldRequirements,
                  ...alternateCode.InputFieldRequirements,
              }
            : selectedPaymentMethod?.InputFieldRequirements;

    const isCvvTooLong = (cvv: string) =>
        (cardData?.ValidationResult?.Card?.Type === "AmEx" && cvv.length > 4) ||
        (cardData?.ValidationResult?.Card?.Type !== "AmEx" && cvv.length > 3);

    const isParaguay = () => appContext.Culture === PARAGUAYAN_CULTURE_CODE;

    const isCvvValid = () => !cardData?.ValidationResult?.Card?.IsCvvNeeded || cardData?.Cvv?.length > 0;

    const isNameValid = () => !isNameOnCardNeeded() || cardData?.CardholderName?.length > 0;

    const isRucValid = () => !isParaguay() || !isNameOnCardNeeded() || cardData?.Ruc?.length > 0;

    const isExpiryValid = () => month !== undefined && year !== undefined;

    const validate = () =>
        getIsCardValid(cardData.CardNumber, cardData.ValidationResult) &&
        isCvvValid() &&
        isNameValid() &&
        isRucValid() &&
        isExpiryValid();

    // EVENT LISTENERS

    const handleRucInput = (e: KeyboardEvent) =>
        setCardData({ ...cardData, Ruc: (e.target as HTMLInputElement).value.trim() });

    const handleCardHolderNameInput = (e: KeyboardEvent) =>
        setCardData({ ...cardData, CardholderName: sanitizeInputFieldValue(e, "accepted-text-chars") });

    const handleCvvInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;

        if (isCvvTooLong(target.value)) {
            target.value = cardData?.Cvv || "";
            return;
        }
        setCardData({ ...cardData, Cvv: sanitizeInputFieldValue(e, "numeric") });
    };

    const handleMonthChange = (e: MouseEvent) => {
        setMonth(Number((e.target as HTMLSelectElement).value));
    };

    const handleYearChange = (e: MouseEvent) => {
        setYear(Number((e.target as HTMLSelectElement).value));
    };

    useEffect(
        () => handleCardNumberChange(""),
        [selectedPaymentMethod?.PaymentMethodCode, payerData?.CurrentCardIssuerCountry],
    );

    useEffect(handleAlternatePaymentCodeChange, [cardData?.AlternateCode]);

    useEffect(() => {
        setCardData({
            ...cardData,
            PaymentMethodOptionId: installments.paymentMethodOptionId,
            MerchantAccountId: installments.merchantAccountId,
            SelectedInstallmentsNumber: installments.selectedInstallmentsNumber,
        });
    }, [installments.paymentMethodOptionId, installments.merchantAccountId, installments.selectedInstallmentsNumber]);

    useEffect(() => {
        const expiry =
            month !== undefined && year
                ? dayjs({ year, month, day: dayjs({ year, month, day: 1 }).endOf("month").daysInMonth() })
                : undefined;

        setCardData({ ...cardData, Expiry: expiry, FormattedExpiry: expiry?.format(DEFAULT_DATE_FORMAT) || "" });
    }, [year, month]);

    // TEMPLATES

    const inputCvvTemplate = () => {
        const tempClassMap = classNames("mdl-textfield__input js-input", {
            invalid: props.isValidated && !isCvvValid(),
        });

        return cardData?.ValidationResult?.Card?.IsCvvNeeded
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                          <label class="mdl-textfield__label">${i18next.t("V2-CVV")} *</label>
                          <input
                              class=${tempClassMap}
                              autocomplete="off"
                              data-test-id=${getTestId(T.PAYMENT.CARD_CVV, {
                                  c: selectedPaymentMethod?.PaymentMethodCode,
                              })}
                              @input=${(e: KeyboardEvent) => handleCvvInput(e)}
                              @blur=${(e: KeyboardEvent) => handleCvvInput(e)}
                          />
                          <div class="cvv-tooltip">
                              ${i18next.t("V2-CVVInfo")}
                              <div class="cvv-tooltip-info">
                                  <div class="arrow-box">
                                      <img src="/Images/Icons/payment-types/payment-cvv-help.png" />
                                      <span>${i18next.t("V2-CVVTooltip")}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const inputCardHolderNameTemplate = () => {
        const tempClassMap = classNames("col-xs-1", { "col-md-1-2": !isParaguay(), "col-md-1-3": isParaguay() });

        const inputClassMap = classNames("mdl-textfield__input js-input", {
            invalid: props.isValidated && !isNameValid(),
        });

        const dataTestId = getTestId(T.PAYMENT.CARD_HOLDER_NAME, { c: selectedPaymentMethod?.PaymentMethodCode });

        return isNameOnCardNeeded()
            ? html`
                  <div class=${tempClassMap}>
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label payment-input-pushdown">
                          <label class="mdl-textfield__label">${i18next.t("V2-NameOnCard")} *</label>
                          <input
                              autocomplete="off"
                              class=${inputClassMap}
                              data-test-id=${dataTestId}
                              @input=${(e: KeyboardEvent) => handleCardHolderNameInput(e)}
                              @blur=${(e: KeyboardEvent) => handleCardHolderNameInput(e)}
                          />
                      </div>
                  </div>
              `
            : "";
    };

    const monthTemplate = () => {
        const inputClassMap = classNames("mdl-textfield__input js-input js-select", {
            invalid: props.isValidated && month === undefined,
        });

        return html`
            <label class="group-label">${i18next.t("V2-Validity")} *</label>
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow payment-input-pushdown"
            >
                <label class="mdl-textfield__label">${i18next.t("V2-Month")}</label>
                <select
                    class=${inputClassMap}
                    autocomplete="cc-exp"
                    data-test-id=${getTestId(T.PAYMENT.EXPIRY_MONTH, {
                        c: selectedPaymentMethod?.PaymentMethodCode,
                    })}
                    @change=${handleMonthChange}
                    @blur=${handleMonthChange}
                >
                    <option value="" disabled ?selected=${month === undefined}></option>
                    ${[...Array(12)].map(
                        (_, i) => html`
                            <option ?selected=${i === month} value=${i}>
                                ${padWithLeadingZeros((i + 1).toString())}
                            </option>
                        `,
                    )}
                </select>
            </div>
        `;
    };

    const yearTemplate = () => {
        const inputClassMap = classNames("mdl-textfield__input js-input js-select", {
            invalid: props.isValidated && year === undefined,
        });

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow payment-input-pushdown"
            >
                <label class="mdl-textfield__label">${i18next.t("V2-Year")}</label>
                <select
                    class=${inputClassMap}
                    autocomplete="cc-exp"
                    data-test-id=${getTestId(T.PAYMENT.EXPIRY_YEAR, {
                        c: selectedPaymentMethod?.PaymentMethodCode,
                    })}
                    @change=${handleYearChange}
                    @blur=${handleYearChange}
                >
                    <option value="" disabled ?selected=${year === undefined}></option>
                    ${[...Array(30)].map(
                        (_, i) => html`
                            <option ?selected=${dayjs().year() + i === year} value=${dayjs().year() + i}>
                                ${dayjs().year() + i}
                            </option>
                        `,
                    )}
                </select>
            </div>
        `;
    };

    const inputExpiryTemplate = () => {
        const tempClassMap = classNames("col-xs-1", { "col-md-1-2": !isParaguay(), "col-md-1-3": isParaguay() });

        return html`
            <div class=${tempClassMap}>
                <div class="row">
                    <div class="col-xs-1-2">${monthTemplate()}</div>
                    <div class="col-xs-1-2">${yearTemplate()}</div>
                </div>
            </div>
        `;
    };

    const inputRucTemplate = () => {
        const inputClassMap = classNames("mdl-textfield__input js-input", {
            invalid: props.isValidated && !isRucValid(),
        });

        return isParaguay() && isNameOnCardNeeded()
            ? html`
                  <div class="col-xs-1 col-md-1-3">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label payment-input-pushdown">
                          <label class="mdl-textfield__label">${i18next.t("ParaguayRucLabel")} *</label>
                          <input
                              class=${inputClassMap}
                              autocomplete="off"
                              data-test-id="paraguayRuc"
                              @input=${handleRucInput}
                          />
                      </div>
                  </div>
              `
            : "";
    };
    const htmlTemplate = () =>
        selectedPaymentMethod?.AllowedCards.length > 0
            ? html`
                  <form>
                      ${inputCardNumber.htmlTemplate()}
                      <div class="row">
                          ${inputCardHolderNameTemplate()} ${inputRucTemplate()} ${inputExpiryTemplate()}
                      </div>
                      <div class="row">${inputCvvTemplate()} ${installments.htmlTemplate()}</div>
                  </form>
              `
            : "";

    return {
        htmlTemplate,
        validate,
    };
};

import { html } from "haunted";
import i18next from "i18next";
import { ProfileRockStarWidgetViewModel } from "../../component-models/profile/ProfileRockstarWidgetViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: ProfileRockStarWidgetViewModel;
}

export const useProfileRockstarWidget = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const handleClick = () => (window.location.href = props.model.StaffInfoUrl);

    const htmlTemplate = () =>
        userContext.isStaff
            ? html`
                  <div class="profile-rockstar-ad">
                      <img class="hidden-xs" src="/Images/BancoEstado/rockstar.png" />
                      <div class="rock-info">
                          <span>${i18next.t("MP-RockstarInfo-1")}</span>
                          <span>${i18next.t("MP-RockstarInfo-2")}</span>
                      </div>
                      <button @click=${handleClick}>
                          ${i18next.t("MP-RockstarButton")}

                          <i class="js-icon js-circle-chevron-right"></i>
                      </button>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

import { html, TemplateResult } from "lit-html";
import { newUniqueId } from "../../../component-helpers/stringHelper";
import { useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { TooltipBalloonProps } from "./tooltip-balloon";
import { removeAllTooltips, toBoolean } from "../../../shared/common";
import { ref } from "../../../directives/ref";
import { useRef, useEffect as hauntedUseEffect } from "haunted";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import classNames from "classnames";

export const name = "ac-tooltip";

export const observedAttributes: (keyof Attributes)[] = [
    "allow-click-through",
    "custom-class",
    "icon",
    "icon-classes",
    "is-light",
    "is-transparent",
    "test-id",
    "tooltip",
    "tooltip-custom-class",
    "tooltip-test-id",
];

export interface Attributes {
    "allow-click-through": string;
    "custom-class": string;
    "icon": string;
    "icon-classes": string;
    "is-light": string;
    "is-transparent": string;
    "test-id": string;
    "tooltip": string;
    "tooltip-custom-class": string;
    "tooltip-test-id": string;
}

export interface Props {
    allowClickThrough: boolean;
    customClass: string;
    icon: TemplateResult | string;
    iconClasses: string[];
    isLight: boolean;
    isTransparent: boolean;
    testId: string;
    tooltip: TemplateResult | string;
    tooltipCustomClass: string;
    tooltipTestId: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        allowClickThrough: toBoolean(host.allowClickThrough),
        customClass: host.customClass,
        icon: host.icon,
        iconClasses: (host.iconClasses as unknown as string)?.split("|") || [],
        isLight: toBoolean(host.isLight),
        isTransparent: toBoolean(host.isTransparent),
        testId: host.testId,
        tooltip: host.tooltip,
        tooltipCustomClass: host.tooltipCustomClass,
        tooltipTestId: host.tooltipTestId,
    };

    const init = () => {
        setId(newUniqueId());
        const handler1 = triggers.shared.windowWasScrolled.subscribe(() => handleMouseLeave());
        const handler2 = triggers.cug.openModal.subscribe(() => handleMouseLeave());

        return () => {
            handler1.unsubscribe();
            handler2.unsubscribe();
        };
    };

    const isCustom = () => !props.icon || props.customClass;

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();

        if (!props.allowClickThrough) {
            e.stopPropagation();
        }

        if (!tooltipElem) {
            handleMouseEnter(e);
        }
    };

    const handleMouseEnter = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (tooltipElem) {
            return;
        }

        const pos = document.getElementById(id).getBoundingClientRect();
        const tooltip = document.createElement("ac-tooltip-balloon") as HTMLElement & TooltipBalloonProps;
        tooltip.top = pos.top;
        tooltip.left = pos.left + pos.width / 2;
        tooltip.dataset.testId = props.tooltipTestId || "";
        tooltip.template = props.tooltip;
        tooltip.customClass = props.tooltipCustomClass;
        document.body.appendChild(tooltip);
        setTooltipElem(tooltip);
    };

    const handleMouseLeave = (e?: MouseEvent) => {
        e?.stopPropagation();
        (e?.target as HTMLSpanElement)?.blur();
        tooltipElem?.remove();
        setTooltipElem(undefined);
        removeAllTooltips();
    };

    const root = useRef<HTMLSpanElement>(undefined);

    const { triggers } = usePubSub();

    const [id, setId] = useState<string>(undefined);
    const [tooltipElem, setTooltipElem] = useState<HTMLElement>(undefined);

    hauntedUseEffect(init, []);

    const mainClassMap = classNames({
        "ac-tooltip": !props.isLight && !props.isTransparent && !isCustom(),
        "flex h-6 w-6 items-center justify-center bg-transparent": props.isTransparent && !isCustom(),
        "flight-journey-stops-tooltip-opener": props.isLight && !isCustom(),
        [props.customClass]: props.customClass,
    });

    return html`
        <span
            ref=${ref(root)}
            class="leading-none"
            @mouseenter=${handleMouseEnter}
            @mouseleave=${handleMouseLeave}
            @click=${handleClick}
        >
            <span id=${id} data-test-id=${props.testId || ""} class=${mainClassMap}>
                ${props.icon || html` <i class="${props.iconClasses.join(" ")}"></i> `}
            </span>
        </span>
    `;
};

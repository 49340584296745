import i18next from "i18next";
import { html, TemplateResult } from "lit-html";
import { useModal } from "../shared/useModal";

export interface Props {
    body: TemplateResult | string;
    nonPromoCodeBtnText: string;
    promoCodeBtnText: string;
    nonPromoCodeCallback?: () => void;
    promoCodeCallback?: () => void;
}

export const usePaymentCancelsPromoCodeModal = (props: Props) => {
    const handlePromoCode = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();

        if (typeof props.promoCodeCallback === "function") props.promoCodeCallback();
    };

    const handleNonPromoCode = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();

        if (typeof props.nonPromoCodeCallback === "function") props.nonPromoCodeCallback();
    };

    const htmlTemplate = () => html`
        <div class="modal-body leading-loose text-be-blue">
            ${props.body}
            <div class="mt-8 flex items-center justify-center">
                <button class="rounded-primary-btn mr-2" @click=${handleNonPromoCode}>
                    ${props.nonPromoCodeBtnText}
                </button>
                <button class="rounded-primary-btn ml-2" @click=${handlePromoCode}>${props.promoCodeBtnText}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => i18next.t("Importante") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

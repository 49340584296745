import i18next from "i18next";
import { html } from "lit-html";
import { CompanyInfo } from "../../../component-models/CompanyInfo";
import { getTaxnumberCodeByCountry } from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export const name = "ac-cug-company-info";
export interface Properties {
    model: CompanyInfo;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        model: host.model,
    };

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    const countryName = () => cug2AppContext.Countries.find((c) => c.Value === props.model.Address.CountryCode)?.Text;

    const taxNumberLabel = () =>
        getTaxnumberCodeByCountry(props.model.Address.CountryCode) || i18next.t("CUG-TaxNumber");

    const giroProfileItemTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember)
            ? html` <div class="cug2b-profile-item">
                  <header>${i18next.t("Giro comercial")}</header>
                  <article>${props.model.Giro}</article>
              </div>`
            : "";

    return html`
        <div class="cug2b-profile-table">
            <div class="cug2b-profile-line">
                <div class="cug2b-profile-item">
                    <header>${i18next.t("País")}</header>
                    <article>${countryName()}</article>
                </div>
                <div class="cug2b-profile-item">
                    <header>${i18next.t("Razón Social")}</header>
                    <article>${props.model.Name}</article>
                </div>
                <div class="cug2b-profile-item">
                    <header>${taxNumberLabel()}</header>
                    <article>${props.model.TaxNumber}</article>
                </div>
            </div>
            <div class="cug2b-profile-line">
                ${giroProfileItemTemplate()}
                <div class="cug2b-profile-item">
                    <header>${i18next.t("Correo de facturación")}</header>
                    <article>${props.model.Email}</article>
                </div>
            </div>
        </div>
    `;
};

import { BoardingPassImageDto, useBoardingPassObtainer } from "./useBoardingPassObtainer";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { BoardingPassImage, useBoardingPassImageCreator } from "./useBoardingPassImageCreator";
import { ApiBoardingPassesViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassesViewModel";
import { useBookingDataManager } from "../../../managers/useBookingDataManager";
import { hideLoader, showLoader } from "../../../shared/common";
import { useMassBoardingPassDownloadModal } from "../useMassBoardingPassDownloadModal";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export interface BoardingPassImageRepositoryProps {
    areCardsDisplayed: boolean;
    model: ApiBoardingPassesViewModel;
    showModal: boolean;
}

export const useBoardingPassImageRepository = (props: BoardingPassImageRepositoryProps) => {
    const bookingDataManager = useBookingDataManager();

    const { downloadAll } = useBoardingPassObtainer();
    const { getBoardingCardImagesAsync } = useBoardingPassImageCreator();
    const { ajaxJsonRequest } = useAjax();

    const [images, setImages] = useState<BoardingPassImage[]>([]);

    const init = () => {
        bookingDataManager.fetchAndUpdate(ajaxJsonRequest);

        if (!props.areCardsDisplayed) return;

        const doIt = async () => {
            const result = await render([props.model.BoardingPasses[0].Passenger.PaxName]);
            setImages(result);
        };

        doIt();
    };

    const render = async (paxNames: string[]): Promise<BoardingPassImage[]> => {
        let newImages: BoardingPassImage[] = [];
        const loader = showLoader({});

        for (const paxName of paxNames) {
            const items = props.model.BoardingPasses.filter((item) => item.Passenger.PaxName === paxName);
            const results = await getBoardingCardImagesAsync(items);

            newImages = [...newImages, ...results];
        }

        hideLoader(loader);

        return newImages;
    };

    const handleImageRequestChange = async (imageIds: string[]) => {
        const newImages = await render(imageIds);
        setImages([...images, ...newImages]);
    };

    const handleDownloadAllIntent = () => {
        if (props.showModal) {
            downloadModal.open();
        } else {
            downloadAllPasses();
        }
    };

    const downloadAllPasses = async () => {
        const missingImagePaxNames = Array.from(
            new Set(
                props.model.BoardingPasses.filter(
                    (item) =>
                        !images.some((image) => image.paxName === item.Passenger.PaxName && image.size === "desktop"),
                ).map((item) => item.Passenger.PaxName),
            ),
        );

        const newImages = await render(missingImagePaxNames);

        const allImages = [...images, ...newImages];

        setImages(allImages);

        const imagesToDownload = allImages.filter((image) => image.size === "desktop");

        downloadAll(
            imagesToDownload.map(
                (item): BoardingPassImageDto => ({
                    item,
                    mobileItem: null,
                    model: props.model.BoardingPasses.find(
                        (boardingPass) =>
                            boardingPass.Passenger.PaxName === item.paxName && boardingPass.Index === item.index,
                    ),
                    pnr: props.model.Pnr,
                }),
            ),
        );
    };

    useEffect(init, []);

    const downloadModal = useMassBoardingPassDownloadModal({ callback: downloadAllPasses });

    return { images, downloadModal, handleDownloadAllIntent, handleImageRequestChange };
};

import { getUrlVarsAsQueryString, hideLoader, showLoader } from "../../shared/common";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import BookingFlowHandler from "../../shared/BookingFlowHandler";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { useFlowContext } from "../../managers/useFlowContext";
import { SpaSection } from "../../component-models/spa/SpaSection";
import { useSpaHistory } from "./useSpaHistory";
import { useEffect, useMemo } from "../../shared/haunted/CustomHooks";
import { ModifyBookingModal } from "../useModifyBookingModal";
import { useReduxState } from "../../shared/redux/useReduxState";
import { SpaSectionType } from "./spa-container";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { spaSectionOrderFactory } from "./spaSectionOrderFactory";
import { GetBuildPageResult } from "../../component-models/spa/SpaContent";

export interface Props {
    modificationModal: ModifyBookingModal;
    sections: SpaSection[];
}

export const useSpaNavigation = (props: Props) => {
    const flowContext = useFlowContext();

    const { triggers } = usePubSub();
    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const [userContext] = useReduxState("userContext");
    const [spaState, setSpaState] = useReduxState("spa");

    const spaSectionOrder = useMemo(
        () =>
            getSpaSectionOrder({
                flowType: flowContext.flowType,
                isStaff: userContext?.isStaff,
            }),
        [userContext?.isStaff, flowContext.flowType],
    );

    const startingSectionOrderIndex = useMemo(
        () =>
            spaSectionOrder.find(
                (sectionIndex) =>
                    window.location.href.toLowerCase().indexOf(sectionIndex.section.uri.toLowerCase()) > -1,
            ).index,
        [spaSectionOrder],
    );

    const spaHistory = useSpaHistory();

    const init = async () => {
        const startingSection = getSectionByIndex(startingSectionOrderIndex);

        if (!startingSection) throw new Error("No section found for first step.");

        const displayed = spaSectionOrder
            .filter((sectionIndex) => sectionIndex.index < startingSectionOrderIndex)
            .map((sectionIndex) => sectionIndex.section.type);

        const displayedSections = Array.from(new Set([...displayed, startingSection.type]).values());

        updateHistory(startingSection.type);

        const loader = showLoader({ name: "default-loader-wrapper" });

        // DEVNOTE We load the previous sections first, so that the loader animation can be displayed
        // on the desired starting section afterwards
        await obtainPreviousSectionsContent(startingSection, displayedSections);
        hideLoader(loader);

        setSpaState({ activeSection: startingSection.type, displayedSections, isLoading: true, isPosting: false });

        // DEVNOTE We load the desired starting section after the previous sections have been loaded
        // DEVNOTE Settimeout so that loader can render
        window.setTimeout(() => obtainSectionContent(startingSection, displayedSections), 0);
    };

    const obtainSectionContent = async (targetSection: SpaSection, displayedSections: SpaSectionType[]) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.SpaIntro });
        const getResult = await targetSection.content.build();

        if (getResult.type === "redirect") return handleRedirection(getResult);

        // TODO Come on...
        if (getResult.type === "error") alert("Error loading section. Please try again.");

        // DEVNOTE So that content can render before displaying it
        window.setTimeout(() => {
            hideLoader(loader);

            setSpaState({ activeSection: targetSection.type, displayedSections, isLoading: false, isPosting: false });

            triggers.shared.pageLoad.publish({});
        }, 100);
    };

    const obtainPreviousSectionsContent = async (startingSection: SpaSection, displayedSections: SpaSectionType[]) => {
        const allContent = props.sections
            .filter((section) => displayedSections.includes(section.type) && section.type !== startingSection.type)
            .map((section) => section.content.build());
        return Promise.all(allContent);
    };

    const getIndexByType = (type: SpaSectionType) =>
        spaSectionOrder.find((sectionIndex) => sectionIndex.section.type === type).index;

    const getTypeByIndex = (index: number) =>
        index !== undefined
            ? spaSectionOrder.find((sectionIndex) => sectionIndex.index === index)?.section.type
            : undefined;

    const getSectionByIndex = (index: number) =>
        index !== undefined ? props.sections?.find((section) => section.type === getTypeByIndex(index)) : undefined;

    const getSectionByType = (type: SpaSectionType) => props.sections?.find((section) => section.type === type);

    const isTryingToMoveForward = (targetIndex: number) => targetIndex >= getIndexByType(spaState.activeSection);

    const handleRedirection = (result: GetBuildPageResult) => {
        if (result.redirectionUrl) {
            window.location.href = result.redirectionUrl;
        } else {
            forward(true);
        }
    };

    const updateHistory = (type: SpaSectionType) => {
        const indexItem = spaSectionOrder.find((sectionIndex) => sectionIndex.section.type === type);
        const newUrl = `/${indexItem.section.uri}?${getUrlVarsAsQueryString([])}`;

        if (
            spaHistory.history.length === 0 ||
            spaHistory.history[spaHistory.history.length - 1] !== getIndexByType(type)
        ) {
            spaHistory.add(getIndexByType(type));
            history.pushState({ page: indexItem.section.type }, type, newUrl);
        }
    };

    const back = (section: SpaSection, onSectionClick: boolean) => {
        setSpaState({ ...spaState, activeSection: section.type });

        if (onSectionClick) {
            updateHistory(section.type);
        } else {
            spaHistory.remove();
        }

        if (typeof section.content.back === "function") {
            window.setTimeout(section.content.back, 0);
        }
    };

    const handleBackButtonClick = () => {
        // DEVNOTE In the checkin flow, all back button clicks must redirect to the itinerary page.
        if (flowContext.isCheckinFlow) {
            showLoader({});
            window.location.href = ROUTES.PageItinerary;
            return;
        }

        // DEVNOTE Mobile seatmap review is in fact a modal, not a page, so we have to capture
        // the back button and simply hide the modal.
        const mobileSeatReviewElem = DomCrawlingHelper.getElemByClass(document.body, "mobile-review-seatmap");
        if (mobileSeatReviewElem && mobileSeatReviewElem.offsetHeight > 0) {
            window.history.forward();
            triggers.seatmap.closeMobileReview.publish({});
            return;
        }

        if (spaHistory.history?.length < 2) {
            window.history.back();
            return;
        }

        const targetIndex = spaHistory.history[spaHistory.history.length - 2];
        const targetSection = getSectionByIndex(targetIndex);

        // DEVNOTE If the user goes back to a later page, we don't need the Are you sure? modal.
        if (targetIndex > getIndexByType(spaState.activeSection)) {
            back(targetSection, false);
            return;
        }

        props.modificationModal.open(
            // DEVNOTE On yes
            () => back(targetSection, false),
            // DEVNOTE On no
            //  If the user clicks "cancel" in the Are you sure? modal, we have to undo the popstate
            // with forward(). We have to release the button, because history manipulation triggers popstate.
            () => {
                releaseBrowserBackButton();
                window.history.forward();
                window.setTimeout(() => captureBrowserBackButton(), 100);
            },
        );
    };

    const captureBrowserBackButton = () => (window.onpopstate = handleBackButtonClick);

    const releaseBrowserBackButton = (): void => (window.onpopstate = null);

    const updateBrowserBackButton = () => {
        releaseBrowserBackButton();
        captureBrowserBackButton();
    };

    useEffect(updateBrowserBackButton, [spaState, spaHistory.history]);

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    // EXPORTS

    const forward = async (skip?: boolean) => {
        const newPageIndex = getIndexByType(spaState.activeSection) + (skip ? 2 : 1);
        const targetSection = getSectionByIndex(newPageIndex);

        if (!targetSection) throw new Error("No section found for the next step.");

        const displayedSections = Array.from(new Set([...spaState.displayedSections, targetSection.type]).values());

        setSpaState({
            activeSection: targetSection.type,
            displayedSections,
            isLoading: true,
            isPosting: false,
        });

        updateHistory(targetSection.type);

        // DEVNOTE So that loader can render
        window.setTimeout(() => obtainSectionContent(targetSection, displayedSections), 0);
    };

    const handleSectionClick = (targetType: SpaSectionType) => {
        const section = getSectionByType(targetType);

        // DEVNOTE The user cannot move forward by clicking an inactive SPA section.
        if (isTryingToMoveForward(getIndexByType(targetType))) return;

        // DEVNOTE First, we have to remove the onpopstate, so that we don't trigger the back button behaviour.
        releaseBrowserBackButton();

        props.modificationModal.open(
            // DEVNOTE On yes
            () => {
                back(section, true);
                BookingFlowHandler.storeCurrentStep(section.bookingFlowStep);

                // DEVNOTE And we let the back button do its thing again.
                captureBrowserBackButton();
            },
            // DEVNOTE On no
            // If the user clicks "Cancel" in the "Are you sure?" modal, we just
            // let the back button do its thing again.
            () => captureBrowserBackButton(),
        );
    };

    const sectionsInOrder = useMemo(
        () =>
            props.sections
                .filter((section) => spaSectionOrder.some((sectionIndex) => sectionIndex.section.type === section.type))
                .sort((a, b) => getIndexByType(a.type) - getIndexByType(b.type)),
        [spaSectionOrder, props.sections],
    );

    return {
        sectionsInOrder,
        forward,
        handleSectionClick,
    };
};

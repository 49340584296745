import i18next from "i18next";
import { html } from "lit-html";
import { useAppContext } from "../../managers/useAppContext";
import { useMemo } from "../../shared/haunted/CustomHooks";
import {
    ARGENTINIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    USA_CULTURE_CODE,
} from "../../shared/commonConstants";
import { useModal } from "../shared/useModal";

export const useAdckModal = () => {
    const appContext = useAppContext();

    const phoneNumber = useMemo(() => {
        switch (appContext.Culture) {
            case BRASILIAN_CULTURE_CODE:
                return "(+55 11) 3042 1183";
            case USA_CULTURE_CODE:
                return "600 600 1311";
            case PERUVIAN_CULTURE_CODE:
                return "01 311 0005";
            case COLOMBIAN_CULTURE_CODE:
                return "(1) 3489581";
            case ARGENTINIAN_CULTURE_CODE:
                return "11 2206 7799";
            default:
                return "600 600 1311";
        }
    }, [appContext.Culture]);

    const headerTemplate = () => html`
        <i class="js-icon js-triangle-exclamation"></i>
        <span>${i18next.t("V2-Attention")}</span>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="row">
                <div class="col-xs-1">
                    <div class="adck-warning">
                        <span>
                            ${i18next.t("ADCK-Warning {{-phoneNumber}}", {
                                phoneNumber,
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "adck-modal-overlay" },
    });

    return modal;
};

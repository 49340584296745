import { classMap } from "lit-html/directives/class-map";
import { useRef } from "haunted";
import { html } from "lit-html";
import { ref } from "../../../directives/ref";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";

export const name = "ac-cug-widget-carousel";

export interface CarouselSlide {
    desktopImage: string;
    tabletImage: string;
    mobileImage: string;
    url: string;
}

export interface Properties {
    slides: CarouselSlide[];
}

type Resolution = "md-up" | "sm" | "xs";
type Direction = "left" | "right";

// DEVNOTE The available tailwindcss transition durations
const slideTransitionTime: 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000 = 1000;
const autoscrollTime = 4000;
const restartAutoscrollAfter = 10000;

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        slides: host.slides,
    };

    // HELPERS

    const init = () => {
        window.onresize = () => {
            updateSlideWidth();
            setCurrentIndex(0);
        };

        return (): void => {
            window.onresize = null;
        };
    };

    const imageForResolution = () =>
        new Map<Resolution, keyof CarouselSlide>([
            ["md-up", "desktopImage"],
            ["sm", "tabletImage"],
            ["xs", "mobileImage"],
        ]);

    const isAnimating = () => targetIndex !== currentIndex;

    const maxIndex = () => props.slides.length - 1;

    const getSlideWidth = () => root?.current?.getBoundingClientRect().width;

    const nextIndexIfScroll = (direction: Direction) => {
        switch (direction) {
            case "left":
                return currentIndex < maxIndex() ? currentIndex + 1 : 0;
            case "right":
                return currentIndex > 0 ? currentIndex - 1 : maxIndex();
        }
    };

    const getXOffset = () => {
        switch (scrollDirection) {
            case "left":
                return slideWidth * -1 - (isAnimating() ? slideWidth : 0);
            case "right":
                return slideWidth * -1 + (isAnimating() ? slideWidth : 0);
        }
    };

    const restartAutoscroll = () => {
        window.clearTimeout(restartAutoScrollTimer);

        if (!isAutoscrollOn) {
            setRestartAutoScrollTimer(window.setTimeout(() => setIsAutoscrollOn(true), restartAutoscrollAfter));
        }
    };

    const doScroll = (direction: Direction) => {
        const nextIndex = nextIndexIfScroll(direction);
        setTargetIndex(nextIndex);
        setSlideTransitionTimer(
            window.setTimeout(() => {
                setCurrentIndex(nextIndex);
            }, slideTransitionTime),
        );
    };

    const doAutoscroll = () => {
        if (isAutoscrollOn) {
            window.clearTimeout(autoScrollTimer);
            setAutoScrollTimer(
                window.setTimeout(() => {
                    doScroll(scrollDirection);
                }, autoscrollTime),
            );
        }
    };

    const updateSlideWidth = () => {
        setSlideWidth(getSlideWidth());
    };

    const switchOffAutoscroll = () => {
        window.clearTimeout(slideTransitionTimer);
        window.clearTimeout(autoScrollTimer);
        setIsAutoscrollOn(false);
    };

    const navigateManually = (direction: Direction) => {
        if (!isAnimating()) {
            switchOffAutoscroll();
            setScrollDirection(direction);
            doScroll(direction);
        }
    };

    // EVENT LISTENERS

    const handleNextRight = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        navigateManually("right");
    };

    const handleNextLeft = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        navigateManually("left");
    };

    const handleDotClick = (e: MouseEvent, index: number) => {
        e.preventDefault();
        e.stopPropagation();

        switchOffAutoscroll();
        setCurrentIndex(index);
        setTargetIndex(index);
    };

    const handleSlideClick = (slideIndex: number) =>
        props.slides[slideIndex].url ? (window.location.href = props.slides[slideIndex].url) : undefined;

    const handleTouchstart = (e: TouchEvent) => {
        if (isAnimating()) {
            return;
        }

        setTouchstartX(e.changedTouches[0].screenX);
    };

    const handleTouchend = (e: TouchEvent) => {
        if (isAnimating()) {
            return;
        }

        const touchendX = e.changedTouches[0].screenX;

        if (touchendX < touchstartX) {
            navigateManually("left");
        }

        if (touchendX > touchstartX) {
            navigateManually("right");
        }
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const [slideWidth, setSlideWidth] = useState<number>(getSlideWidth());
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [targetIndex, setTargetIndex] = useState<number>(0);
    const [scrollDirection, setScrollDirection] = useState<Direction>("left");
    const [slideTransitionTimer, setSlideTransitionTimer] = useState<any>(undefined);
    const [autoScrollTimer, setAutoScrollTimer] = useState<any>(undefined);
    const [restartAutoScrollTimer, setRestartAutoScrollTimer] = useState<any>(undefined);
    const [isAutoscrollOn, setIsAutoscrollOn] = useState<boolean>(true);
    const [touchstartX, setTouchstartX] = useState<number>(0);

    useEffect(updateSlideWidth, [root.current]);

    useEffect(doAutoscroll, [currentIndex, isAutoscrollOn]);

    useEffect(restartAutoscroll, [isAutoscrollOn]);

    useEffect(init, []);

    // TEMPLATES

    const slideTemplateForResolution = (resolution: Resolution, slideIndex: number) => {
        const url = props.slides[slideIndex][imageForResolution().get(resolution)];

        return html`
            <li
                class="widget-carousel-slide visible-${resolution}"
                style=${`width: ${slideWidth}px; background-image: url("${url.trim()}")`}
                @click=${() => handleSlideClick(slideIndex)}
            ></li>
        `;
    };

    const slideTemplate = (slideIndex: number) =>
        Array.from(imageForResolution().keys()).map((resolution) => slideTemplateForResolution(resolution, slideIndex));

    const dotTemplate = (dotIndex: number) => {
        const tempClassMap = classMap({
            "widget-carousel-dot": true,
            "bg-be-red": targetIndex === dotIndex,
            "bg-be-blue": targetIndex !== dotIndex,
        });

        return html`
            <li class=${tempClassMap} @click=${(e: MouseEvent) => handleDotClick(e, dotIndex)}></li>
        `;
    };

    const dotNavigationTemplate = () => html`
        <ul class="widget-carousel-dot-navigation">
            ${props.slides.map((_, i) => dotTemplate(i))}
        </ul>
    `;

    const leftArrowTemplate = () => html`
        <div class="widget-carousel-arrow-navigation left" @click=${handleNextRight}></div>
    `;

    const rightArrowTemplate = () => html`
        <div class="widget-carousel-arrow-navigation right" @click=${handleNextLeft}></div>
    `;

    const mainClassMap = classMap({
        "widget-carousel-slide-container": true,
        "transition-margin": isAnimating(),
        [`duration-${slideTransitionTime}`]: isAnimating(),
    });

    return props.slides
        ? html`
              <div
                  ref=${ref(root)}
                  class="cug2b-widget overflow-hidden h-full"
                  @touchstart=${handleTouchstart}
                  @touchend=${handleTouchend}
              >
                  <ul class=${mainClassMap} style="margin-left: ${getXOffset()}px;">
                      ${slideTemplate(nextIndexIfScroll("right"))} ${slideTemplate(currentIndex)}
                      ${slideTemplate(nextIndexIfScroll("left"))}
                  </ul>
                  ${leftArrowTemplate()} ${rightArrowTemplate()} ${dotNavigationTemplate()}
              </div>
          `
        : html``;
};

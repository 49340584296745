import { useState } from "../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { useReduxState } from "../shared/redux/useReduxState";

export const useBundlesBar = () => {
    const isCurrentBancoEstadoCategory = (cats: number[]) => {
        return (
            userContext?.bancoEstado.category !== undefined &&
            cats.includes(userContext.bancoEstado.category)
        );
    };

    // COMPONENT

    const [userContext] = useReduxState("userContext");

    const [isOpen, setIsOpen] = useState<boolean>(false);

    // TEMPLATES

    const detailsCategory6 = html`
        <div class="bbbd-section">
            <i class="js-icon-be2 js-be2-money"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Tienes $5.000")} <span>${i18next.t("CLP")}</span></div>
                <div class="bbbd-body">
                    ${i18next.t("de descuento en tu tarifa por ser Miembro del Club  de Descuentos")}
                </div>
            </div>
        </div>
        <div class="bbbd-section">
            <i class="js-icon-be2 js-be2-circle-baggage"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Tienes $1.000")} <span>${i18next.t("CLP")}</span></div>
                <div class="bbbd-body">${i18next.t("de descuento por cada equipaje")}</div>
            </div>
        </div>
        <div class="bbbd-section">
            <i class="js-icon-be2 js-be2-circle-seat"></i>
            <div>
                <div class="bbbd-header">
                    ${i18next.t("Tienes")} ${userContext?.bancoEstado.remainingFreeSeats}
                </div>
                <div class="bbbd-header">${i18next.t("Asientos Gratis")}</div>
                <div class="bbbd-body">${i18next.t("en la Zona Banco Estado")}</div>
            </div>
        </div>

        <div class="bbbd-section">
            <i class="js-icon-be2 js-be2-priority"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Embarque")}</div>
                <div class="bbbd-header">${i18next.t("Prioritario")}</div>
            </div>
        </div>
        <div class="bbbd-section">
            <i class="js-icon-be2 js-be2-percent"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Aprovecha 6 o 12")}</div>
                <div class="bbbd-header">${i18next.t("cuotas sin interés")}</div>
            </div>
        </div>
    `;

    const detailsCategory5 = html`
        <div class="bbbd-section category-5">
            <i class="js-icon-be2 js-be2-money"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Precio preferente")}</div>
                <div class="bbbd-header">${i18next.t("Club de Descuentos")}</div>
            </div>
        </div>
        <div class="bbbd-section category-5">
            <i class="js-icon-be2 js-be2-circle-seat"></i>
            <div>
                <div class="bbbd-header">${i18next.t("40% DCTO")}</div>
                <div class="bbbd-header">${i18next.t("en los Asientos")}</div>
                <div class="bbbd-body">${i18next.t("en la Zona Banco Estado")}</div>
            </div>
        </div>

        <div class="bbbd-section category-5">
            <i class="js-icon-be2 js-be2-priority"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Embarque")}</div>
                <div class="bbbd-header">${i18next.t("Prioritario")}</div>
            </div>
        </div>
        <div class="bbbd-section category-5">
            <i class="js-icon-be2 js-be2-percent"></i>
            <div>
                <div class="bbbd-header">${i18next.t("Aprovecha 6 o 12")}</div>
                <div class="bbbd-header">${i18next.t("cuotas sin interés")}</div>
            </div>
        </div>
    `;

    const cardTypeImage = isCurrentBancoEstadoCategory([6])
        ? html` <img src="/Images/BancoEstado/be-smart-plus-text-logo-white.svg" /> `
        : html` <img src="/Images/BancoEstado/be-smart-text-logo-white.svg" /> `;

    const htmlTemplate = () => html`
        <div
            class="be-bundles-bar ${isCurrentBancoEstadoCategory([6]) ? "bg-be-gray" : "bg-be-orange"} ${isOpen
                ? "open"
                : ""}"
            @click=${() => setIsOpen(!isOpen)}
        >
            <div class="be-bundles-bar-caption">
                ${i18next.t("Tú ya tienes estos beneficios con tu tarjeta")} ${cardTypeImage}
            </div>
            <div class="bbb-benefit-icons ${isCurrentBancoEstadoCategory([6]) ? "" : "narrow"}">
                <i class="js-icon-be2 js-be2-money"></i>
                ${isCurrentBancoEstadoCategory([6]) ? html` <i class="js-icon-be2 js-be2-circle-baggage"></i> ` : ""}

                <i
                    class="js-icon-be2 ${isCurrentBancoEstadoCategory([6])
                        ? "js-be2-circle-seat"
                        : "js-be2-circle-seat-more"}"
                ></i>
                <i class="js-icon-be2 js-be2-priority"></i>
                <i class="js-icon-be2 js-be2-percent"></i>
            </div>
            <div class="bbb-opener ${isCurrentBancoEstadoCategory([6]) ? "" : "smaller"}">
                <i
                    class="js-icon ${isCurrentBancoEstadoCategory([6])
                        ? "js-circle-chevron-right"
                        : "js-chevron-right"}"
                ></i>
            </div>
        </div>
        <div class="be-bundles-bar-details ${isCurrentBancoEstadoCategory([5]) ? "be-category-5" : ""}">
            ${isCurrentBancoEstadoCategory([6]) ? detailsCategory6 : detailsCategory5}
        </div>
    `;

    return { htmlTemplate };
};

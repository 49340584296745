import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { getAntiForgeryTokenFromHtml, hideLoader, showLoader } from "../../shared/common";
import { ref } from "../../directives/ref";
import { useRef } from "haunted";
import { AGENCY_URL_PARTIAL_STRING, COMPANY_URL_PARTIAL_STRING, LoginType } from "../../shared/commonConstants";
import i18next from "i18next";
import { useLogin } from "./useLogin";
import { DEFAULT_LOGIN_VM, LoginVM } from "./login-model";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { ValidatorPartialMethods } from "./login-page";
import { isPasswordLengthValid } from "../../validator/validation-helper";
import { useReduxState } from "../../shared/redux/useReduxState";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];
export const name = "ac-agency-login-page";

export interface Attributes {
    "anti-forgery-token": string;
}

export interface Properties {
    antiForgeryToken: string;
}

type FieldNames = keyof LoginVM;

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
    };

    // HELPERS

    const init = () => {
        if (window.location.href.indexOf(AGENCY_URL_PARTIAL_STRING) > -1) {
            setType("AGENCY");
        }

        if (window.location.href.indexOf(COMPANY_URL_PARTIAL_STRING) > -1) {
            setType("COMPANY");
        }
    };

    const submitForm = async () => {
        setIsValidated(true);
        setError("");
        const isValid = await validator.validate();

        if (!isValid) {
            return;
        }

        const loader = showLoader({ name: "cug2b-login-main" });
        const result = await authenticator.login({
            Username: vm.username,
            Password: vm.password,
            LoginType: type,
            Redirect: true,
        });

        if (result.IsLoggedIn === false) {
            setError(result.ErrorMessage);
            hideLoader(loader);
            setIsValidated(false);
        }
    };

    // EVENT LISTENERS
    const handleKeyup = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            submitForm();
        }
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        submitForm();
    };

    // COMPONENT

    const form = useRef<HTMLFormElement>(undefined);

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [error, setError] = useState<string>("");
    const [type, setType] = useState<LoginType>("AGENCY");
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<LoginVM>(DEFAULT_LOGIN_VM);

    const authenticator = useLogin();
    const validator = useFluentValidator<FieldNames, LoginVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("username", (vm: LoginVM) => vm.username).isRequired(),
            Validation.ruleFor("password", (vm: LoginVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => isPasswordLengthValid(password),
                    i18next.t("La contraseña debe incluir entre 8 y 16 caracteres y, debe contener letras y números."),
                ),
        ],
    });

    const validatorPartials: ValidatorPartialMethods = {
        isValid: (field: keyof LoginVM) => validator.isValid(field),
        getMessage: (field: keyof LoginVM) => validator.getMessage(field),
        getFormMessages: () => validator.getFormMessages(),
    };

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    useEffect(init, []);

    // TEMPLATES

    const agencyTemplate = () => html`
        <ac-agency-login
            .vm=${vm}
            .validatorPartialMethods=${validatorPartials}
            .setVm=${setVm}
            .increaseUpperMargin=${true}
            .loginType=${type}
            .handleSubmit=${handleSubmit}
            .handleKeyup=${handleKeyup}
            .responseError=${error}
        ></ac-agency-login>
    `;

    return html`
        <div ref=${ref(form)} class="content-wrapper condensed pull-up ts-error-parent">
                ${agencyTemplate()}
            </div>
        </div>
    `;
};

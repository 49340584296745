import { useEffect, useState } from "./../shared/haunted/CustomHooks";
import "./dc-pager.scss";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { createRange } from "../component-helpers/collectionHelper";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-pager";

const DEFAULTS: Properties = {
    pageSize: 20,
    pageIndex: 0,
    itemCount: undefined as number,
    pageButtonCount: 10,
    pageSizes: [] as number[],
    showInfo: true,
    showPreviousNext: true,
};

export interface Properties {
    pageSize?: number;
    pageIndex?: number;
    itemCount?: number;
    pageButtonCount?: number;
    pageSizes: number[];
    showInfo: boolean;
    showPreviousNext: boolean; // first and last too
}

interface ChangeEventDetail {
    selectedPageIndex?: number;
    selectedPageSize?: number;
}

export class PagerChangeEvent extends CustomEvent<ChangeEventDetail> {
    constructor(detail: ChangeEventDetail) {
        super("change", { detail });
    }
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        pageSize: host.pageSize !== undefined ? host.pageSize : DEFAULTS.pageSize,
        pageIndex: host.pageIndex !== undefined ? host.pageIndex : DEFAULTS.pageIndex,
        itemCount: host.itemCount !== undefined ? host.itemCount : DEFAULTS.itemCount,
        pageButtonCount: host.pageButtonCount !== undefined ? host.pageButtonCount : DEFAULTS.pageButtonCount,
        pageSizes: host.pageSizes !== undefined ? host.pageSizes : DEFAULTS.pageSizes,
        showInfo: host.showInfo !== undefined ? host.showInfo : DEFAULTS.showInfo,
        showPreviousNext: host.showPreviousNext !== undefined ? host.showPreviousNext : DEFAULTS.showPreviousNext,
    };

    const getPagerModel = () => {
        const { firstPage, lastPage, firstItem, lastItem, noOfPages } = calculatePagingRange(
            selectedPageIndex,
            selectedPageSize,
            props.pageButtonCount,
            props.itemCount,
        );

        const showNextEllipsis = props.itemCount === undefined || lastPage < noOfPages;
        const showPreviousEllipsis = firstPage > 1;

        return {
            firstPage,
            lastPage,
            range: createRange(firstPage, lastPage),
            showPreviousEllipsis,
            showNextEllipsis,
            isFirstShown: props.showPreviousNext && showPreviousEllipsis,
            isFirstEnabled: selectedPageIndex !== 0,
            isPreviousShown: props.showPreviousNext && noOfPages > 1,
            isPreviousEnabled: selectedPageIndex !== 0,
            isNextShown: props.showPreviousNext && noOfPages > 1,
            isNextEnabled: props.itemCount === undefined || selectedPageIndex < noOfPages - 1,
            isLastShown: props.showPreviousNext && props.itemCount !== undefined && showNextEllipsis,
            isLastEnabled: selectedPageIndex < noOfPages - 1 && props.itemCount !== undefined,
            firstItem,
            lastItem,
            noOfPages,
        };
    };

    // Event Handlers

    const onPageIndexSelected = (pageIndex: number) => {
        return (_e: Event) => {
            if (pageIndex !== selectedPageIndex) {
                setSelectedPageIndex(pageIndex);
                host.dispatchEvent(
                    new PagerChangeEvent({
                        selectedPageIndex: pageIndex,
                        selectedPageSize,
                    }),
                );
            }
        };
    };

    // const onPageSizeChanged = (e: DC.Select.SelectChangeEvent) => {
    const onPageSizeChanged = (e: any) => {
        const pageSize = parseInt(e.detail.selectedValue, 10);
        setSelectedPageSize(pageSize);
        host.dispatchEvent(
            new PagerChangeEvent({
                selectedPageIndex,
                selectedPageSize: pageSize,
            }),
        );
    };

    // COMPONENT

    const [selectedPageIndex, setSelectedPageIndex] = useState(DEFAULTS.pageIndex);
    const [selectedPageSize, setSelectedPageSize] = useState(DEFAULTS.pageSize);

    useEffect(() => {
        // PageSize bound checked
        let pageSize = DEFAULTS.pageSize;
        if (props.pageSize) {
            if (props.pageSize > 0) {
                pageSize = props.pageSize;
            }
            setSelectedPageSize(pageSize);
        }

        // PageIndex bound checked
        if (props.pageIndex !== undefined) {
            if (props.pageIndex < 0) {
                setSelectedPageIndex(0);
            } else {
                const noOfPages =
                    props.itemCount !== undefined ? Math.ceil(props.itemCount / props.pageSize) : undefined;
                if (noOfPages !== undefined) {
                    if (props.pageIndex > noOfPages - 1) {
                        setSelectedPageIndex(noOfPages - 1);
                    } else {
                        setSelectedPageIndex(props.pageIndex);
                    }
                } else {
                    setSelectedPageIndex(props.pageIndex);
                }
            }
        }
    }, [props.pageIndex, props.pageSize]);

    // TEMPLATE

    const pagerModel = getPagerModel();

    const templatePagerItems = () => {
        return html`
            <div class="dc-pager-items">
                ${pagerModel.isFirstShown
                    ? pagerModel.isFirstEnabled
                        ? html`
                              <div @click=${onPageIndexSelected(0)} class="dc-pager-control">&laquo;</div>
                          `
                        : html`
                              <div class="disabled dc-pager-control">&laquo;</div>
                          `
                    : ""}
                ${pagerModel.isPreviousShown
                    ? pagerModel.isPreviousEnabled
                        ? html`
                              <div @click=${onPageIndexSelected(selectedPageIndex - 1)} class="dc-pager-control">
                                  &lsaquo;
                              </div>
                          `
                        : html`
                              <div class="disabled dc-pager-control">&lsaquo;</div>
                          `
                    : ""}
                ${pagerModel.showPreviousEllipsis
                    ? html`
                          <div @click=${onPageIndexSelected(pagerModel.firstPage - 2)} class="dc-pager-control">
                              ...
                          </div>
                      `
                    : ""}
                ${pagerModel.range.map(
                    (i) => html`
                        <div
                            @click=${onPageIndexSelected(i - 1)}
                            class=${classMap({
                                "dc-pager-number": true,
                                "current-page": i === selectedPageIndex + 1,
                            })}
                        >
                            ${i}
                        </div>
                    `,
                )}
                ${pagerModel.showNextEllipsis
                    ? html`
                          <div @click=${onPageIndexSelected(pagerModel.lastPage)} class="dc-pager-control">...</div>
                      `
                    : ""}
                ${pagerModel.isNextShown
                    ? pagerModel.isNextEnabled
                        ? html`
                              <div @click=${onPageIndexSelected(selectedPageIndex + 1)} class="dc-pager-control">
                                  &rsaquo;
                              </div>
                          `
                        : html`
                              <div class="disabled dc-pager-control">&rsaquo;</div>
                          `
                    : ""}
                ${pagerModel.isLastShown
                    ? pagerModel.isLastEnabled
                        ? html`
                              <div @click=${onPageIndexSelected(pagerModel.noOfPages - 1)} class="dc-pager-control">
                                  &raquo;
                              </div>
                          `
                        : html`
                              <div class="disabled dc-pager-control">&raquo;</div>
                          `
                    : ""}
            </div>
        `;
    };

    const templateSizeAndInfo = () => {
        return html`
            <div>
                ${props.pageSizes.length > 0
                    ? html`
                          <dc-select
                              class="dc-pager-page-size-selector"
                              .dataSource=${props.pageSizes}
                              .selectedValues=${selectedPageSize}
                              @change=${onPageSizeChanged}
                          >
                          </dc-select>
                      `
                    : ""}
            </div>
            <div class="pager-show-info">
                ${props.showInfo
                    ? html`
                          Showing ${pagerModel.firstItem}-${pagerModel.lastItem}
                          ${props.itemCount !== undefined
                              ? html`
                                    of ${props.itemCount}
                                `
                              : ""}
                      `
                    : ""}
            </div>
        `;
    };

    return html`
        <div class="dc-pager">${templatePagerItems()} ${templateSizeAndInfo()}</div>
    `;
};

// Helpers

export const calculatePagingRange = (
    pPageIndex: number,
    pageSize: number,
    pageNumberCount: number,
    itemCount: number,
): { firstPage: number; lastPage: number; noOfPages?: number; firstItem: number; lastItem: number } => {
    let pageIndex = pPageIndex < 0 ? 0 : pPageIndex;

    if (itemCount === undefined) {
        const noOfPagesBeforeUnlimited = Math.floor(pageIndex / pageNumberCount) * pageNumberCount;

        return {
            firstPage: noOfPagesBeforeUnlimited + 1,
            lastPage: noOfPagesBeforeUnlimited + pageNumberCount,
            firstItem: pageIndex * pageSize + 1,
            lastItem: (pageIndex + 1) * pageSize,
        };
    } else {
        const noOfPages = Math.max(1, Math.ceil(itemCount / pageSize));
        pageIndex = pageIndex > noOfPages - 1 ? noOfPages - 1 : pageIndex;
        const noOfPagesBefore = Math.floor(pageIndex / pageNumberCount) * pageNumberCount;

        return {
            firstPage: noOfPagesBefore + 1,
            lastPage: Math.min(noOfPagesBefore + pageNumberCount, noOfPages),
            noOfPages,
            firstItem: Math.min(pageIndex * pageSize + 1, itemCount),
            lastItem: Math.min((pageIndex + 1) * pageSize, itemCount),
        };
    }
};

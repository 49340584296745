import i18next from "i18next";
import { html } from "lit-html";
import { ROUTES } from "../../shared/apiRoutes";
import { useModal } from "../shared/useModal";

export const useFareClassFullModal = () => {
    const handleRedirectButtonClick = () => (window.location.href = ROUTES.PageFlight);

    const headerTemplate = () => html`
        <i class="js-icon js-triangle-exclamation absolute left-2 vertical-center"></i>
        <span>${i18next.t("V2-Important")}</span>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body text-center leading-normal">
            <div>${i18next.t("FareClassFull-Text")}</div>
            <div class="mt-8 flex justify-center">
                <a class="rounded-primary-btn" @click=${handleRedirectButtonClick}> ${i18next.t("V2-ReturnLabel")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { FULL_EMAIL_REGEX } from "../../../commonConstants";
import { InputElement } from "../useForm";
import { InputFieldAttribute, UDF_ATTR_EMAIL } from "../InputFieldAttribute";

export const emailFormat = (): InputFieldAttribute => ({
    name: UDF_ATTR_EMAIL,
    validators: [
        {
            errorMessage: {
                id: "invalidEmailFormat",
                message: window.formResources.emailIsNotValid,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = field.value ? FULL_EMAIL_REGEX.test(field.value) : true;

                return Promise.resolve(result);
            },
        },
    ],
});

import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { ARGENTINA_COUNTRY_CODE } from "../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { PayerData as PayerData } from "../../component-models/payment/PaymentUserData";
import { useAppContext } from "../../managers/useAppContext";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";

export interface Payer {
    IsCuitLengthValid: boolean;
    IsCuitValid: boolean;
}

export interface Props {
    model: PaymentPageViewModel;
}

export const usePayer = (props: Props): Payer => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const { getCountryForCulture } = paymentHelper();

    const [userContext] = useReduxState("userContext");
    const [payerData, setPayerData] = useReduxState("payment.payer");
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const IsCuitValid = useMemo(() => {
        const isValid = props.model
            ? Boolean(
                  !props.model.BillingViewModel.IsCuitFieldNeeded ||
                      payerData?.CuitCountry !== ARGENTINA_COUNTRY_CODE ||
                      payerData?.Cuit ||
                      userContext.peruCompra.isAdmin ||
                      userContext.peruCompra.isMember ||
                      bookingContext.isPeruCompraBooking,
              )
            : true;

        return isValid;
    }, [props.model, payerData]);

    const IsCuitLengthValid = useMemo(() => {
        if (!props.model) return true;

        if (
            !props.model.BillingViewModel.IsCuitFieldNeeded ||
            userContext.peruCompra.isAdmin ||
            userContext.peruCompra.isMember ||
            bookingContext.isPeruCompraBooking ||
            payerData?.CuitCountry !== ARGENTINA_COUNTRY_CODE
        ) {
            return true;
        }

        const isValid = payerData.Cuit.replace(/\s/g, "").length === 11;

        return isValid;
    }, [props.model, payerData]);

    useEffect(() => {
        if (userContext?.userRole && props.model && !isInitialized) {
            const initialPayerData: PayerData = {
                Cuit: props.model.BillingViewModel.ExtendedInvoiceModel.CompanyId,
                CuitCountry: userContext.cug.isMember ? props.model.AgencyViewModel.OrgCountry : ARGENTINA_COUNTRY_CODE,
                CurrentCardIssuerCountry: getCountryForCulture(appContext.Culture),
                IsTodosumaConsentAccepted: false,
            };

            setPayerData(initialPayerData);
            setIsInitialized(true);
        }
    }, [userContext?.userRole, props.model, isInitialized]);

    return {
        IsCuitLengthValid,
        IsCuitValid,
    };
};

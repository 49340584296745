import { TemplateResult, html } from "lit-html";
import { ApiReview } from "../../../component-models/review/ApiReview";
import i18next from "i18next";
import { reviewTableTemplate } from "./reviewTableTemplate";

export const passengerReview = (model: ApiReview) => {
    const { desktopReviewTable, mobileReviewTable } = reviewTableTemplate();

    const getTable = () =>
        new Map<string, (string | TemplateResult)[]>([
            [
                i18next.t("ReviewChanges-Passenger"),
                model.PassengerChanges.flatMap(
                    (passengerChanges) =>
                        passengerChanges.Changes.flatMap(
                            (_) => html`
                                ${passengerChanges.Passenger.Names[0].FirstName}
                                ${passengerChanges.Passenger.Names[0].LastName}
                            `,
                        ),
                    [],
                ),
            ],
            [
                i18next.t("ReviewChanges-Updating"),
                model.PassengerChanges.flatMap((passengerChanges) =>
                    passengerChanges.Changes.map((change) => change.PropertyName),
                ),
            ],
            [
                i18next.t("ReviewChanges-Previous"),
                model.PassengerChanges.flatMap((passengerChanges) =>
                    passengerChanges.Changes.flatMap((change) => change.OriginalValue),
                ),
            ],
            [
                i18next.t("ReviewChanges-Current"),
                model.PassengerChanges.flatMap((passengerChanges) =>
                    passengerChanges.Changes.flatMap((change) => change.CurrentValue),
                ),
            ],
        ]);

    const htmlTemplate = () => {
        const content = getTable();

        return model.PassengerChanges?.length
            ? html`
                  <div>
                      <div class="inner-deep-box-header">${i18next.t("Difference-PassengerChanges")}</div>
                  </div>
                  <div class="inner-deep-box">
                      <div class="flight-container hidden-xs">${desktopReviewTable(content)}</div>
                      <div class="visible-xs">${mobileReviewTable(content)}</div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};

import {
    ARGENTINIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    ECUADORIAN_CULTURE_CODE,
    PARAGUAYAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    URUGUAYAN_CULTURE_CODE,
    USA_CULTURE_CODE,
} from "./../shared/commonConstants";
import { MultiCultureString } from "./../component-models/CUG2b/MultiCultureString";
import i18next from "i18next";

export function insensitiveEquals(string1: string, string2: string): boolean {
    return string1?.trim().toLowerCase() === string2?.trim().toLowerCase();
}

export function getStringForCulture(inputString: MultiCultureString, culture: string): string {
    switch (culture?.toLowerCase()) {
        case USA_CULTURE_CODE:
            return inputString.EN_US || inputString.ES_CL;
        case ARGENTINIAN_CULTURE_CODE:
            return inputString.ES_AR || inputString.ES_CL;
        case COLOMBIAN_CULTURE_CODE:
            return inputString.ES_CO || inputString.ES_CL;
        case PERUVIAN_CULTURE_CODE:
            return inputString.ES_PE || inputString.ES_CL;
        case PARAGUAYAN_CULTURE_CODE:
            return inputString.ES_PY || inputString.ES_CL;
        case URUGUAYAN_CULTURE_CODE:
            return inputString.ES_UY || inputString.ES_CL;
        case BRASILIAN_CULTURE_CODE:
            return inputString.PT_BR || inputString.ES_CL;
        case ECUADORIAN_CULTURE_CODE:
            return inputString.ES_EC || inputString.ES_CL;
        default:
            return inputString.ES_CL;
    }
}

export function padLeft(num: number, size = 2): string {
    let text = String(Math.abs(num));
    while (text.length < size) {
        text = `0${text}`;
    }

    return num < 0 ? `-${text}` : text;
}

export function padRight(num: number, size = 2) {
    let text = String(Math.abs(num));
    while (text.length < size) {
        text = `${text}0`;
    }

    return num < 0 ? `-${text}` : text;
}

export function isEmpty(text: string) {
    return text === undefined || text.length === 0;
}

export function newUniqueId() {
    return Math.random().toString(36).substr(2, 9);
}

export function onlyAlpha(val: string): boolean {
    const regex = RegExp(/^[a-zA-Z]*$/);
    return regex.test(val);
}

export function onlyAlphaNum(val: string): boolean {
    const regex = RegExp(/^[a-zA-Z0-9]*$/);
    return regex.test(val);
}

export const INVALID_MESSAGE = i18next.t("Invalid");
export const REQUIRED_MESSAGE = i18next.t("Required");
export const ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE = i18next.t("Only Latin characters are allowed");
export const ONLY_EUROPEAN_CHARS_REGEX = RegExp(/^[a-zA-Z\u00C0-\u024F\u0020-\u0040]*$/);

export function onlyEuropeanCharacters(val: string): boolean {
    const regex = ONLY_EUROPEAN_CHARS_REGEX;
    return regex.test(val);
}

export const startsWithLetter = (val: string) => {
    const regex = /^[A-Za-z]/;
    return regex.test(val);
};

export const leftPad = (formattable: number | string, length: number, character: string): string => {
    if (!formattable) return "";
    if (formattable.toString().length >= length) return formattable.toString();

    const count = length - formattable.toString().length + 1;
    return `${Array(count).join(character)}${formattable}`;
};

export const convertPascalCaseToKebabCase = (name: string) => {
    name.replace(/([A-Z]+)/g, (y) => "-" + y.toLowerCase()).replace(/^-/, "");
};

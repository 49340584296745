import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { html } from "lit-html";

export interface Props {
    baggageSection: BaggageSection;
    isMobile: boolean;
}

export const usePerBookingQuantityButtons = (props: Props) => {
    const handleAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.baggageSection.handlers.add();
    };

    const handleRemove = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.baggageSection.handlers.remove();
    };

    const addButtonTemplate = () => html`
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_BUTTON, {
                c: props.baggageSection.bagType,
                m: props.isMobile,
            })}
            .disabled=${!props.baggageSection.perBooking.isAddAvailable}
            @click=${handleAdd}
        >
            &plus;
        </button>
    `;

    const removeButtonTemplate = () => html`
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_REMOVE_BUTTON, {
                c: props.baggageSection.bagType,
                m: props.isMobile,
            })}
            .disabled=${!props.baggageSection.perBooking.isRemoveAvailable}
            @click=${handleRemove}
        >
            &minus;
        </button>
    `;

    const htmlTemplate = () => html`
        <div class="b2-per-booking-quantity-container">
            ${removeButtonTemplate()}
            <span
                class="b2-per-booking-quantity"
                data-test-value=${props.baggageSection.perBooking.quantity}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_QUANTITY, {
                    c: props.baggageSection.bagType,
                    m: props.isMobile,
                })}
            >
                ${props.baggageSection.perBooking.quantity}
            </span>
            ${addButtonTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export interface Props {
    onCancel: () => void;
    onContinue: () => void;
}

export const useOxyModalContinue = (props: Props) => {
    const headerTemplate = () => html`<span>${i18next.t("V2-CheckinSanitary-Important")}</span>`;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <p class="text-center">
                    ${i18next.t(
                        "Has seleccionado una opción que requiere hacer check-in en el aeropuerto. Por favor, por favor diríjase al counter del aeropuerto y haremos tu check-in sin costo adicional.",
                    )}
                </p>
                <p>
                    ${i18next.t(
                        "Si continúa, actualizaremos tu reserva y el check-in online sólo estará disponible para el resto de pasajeros (si los hubiera).",
                    )}
                </p>
            </div>
            <div class="primary-modal-button-container">
                <button class="rounded-secondary-btn" @click=${props.onCancel}>${i18next.t("V2-Cancel")}</button>
                <button class="rounded-primary-btn ml-4" @click=${props.onContinue}>${i18next.t("V2-Continue")}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import * as dayjs from "dayjs";
import { Day } from "./TripCalendar";

interface DaySelectEventDetail {
    calendarIndex: number;
    day: Day;
}

export class DaySelectEvent extends CustomEvent<DaySelectEventDetail> {
    constructor(detail: DaySelectEventDetail) {
        super("selectDay", { detail, bubbles: true });
    }
}

interface MonthSelectEventDetail {
    calendarIndex: number;
    date: dayjs.Dayjs;
}

export class MonthSelectEvent extends CustomEvent<MonthSelectEventDetail> {
    constructor(detail: MonthSelectEventDetail) {
        super("selectMonth", { detail, bubbles: true });
    }
}

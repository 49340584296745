import i18next from "i18next";
import { SelectOption, getLocalizedStringValue } from "../../shared/common";
import { INSTALLMENT_PLACEHOLDER } from "../../shared/commonConstants";
import { html } from "lit-html";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    options: SelectOption[];
    withoutInterest?: boolean;
}

export const useInstallmentDropdownOptions = (props: Props) => {
    const appContext = useAppContext();

    const [paymentMethod] = useReduxState("payment.paymentMethod");

    const getLocalizedXmlText = (selectedInstallmentNumber: number) =>
        getLocalizedStringValue(
            selectedInstallmentNumber > 1
                ? paymentMethod?.DropdownInstallmentNumberText
                : paymentMethod?.DropdownNoInstallmentText,
            appContext.Culture,
        )?.replace(INSTALLMENT_PLACEHOLDER, selectedInstallmentNumber.toString());

    const defaultText = (selectedInstallmentNumber: number) =>
        selectedInstallmentNumber > 1
            ? defaultInstallmentsText(selectedInstallmentNumber)
            : defaultNoInstallmentsText();

    const defaultInstallmentsText = (selectedInstallmentNumber: number) =>
        `${selectedInstallmentNumber} ${defaultInstallmentsPostfix()}`;

    const defaultNoInstallmentsText = () => `1 ${i18next.t("cuota")}`;

    const defaultInstallmentsPostfix = () =>
        props.withoutInterest ? i18next.t("cuotas sin interés") : i18next.t("cuotas con interés");

    const getInstallmentDropdownText = (selectedInstallmentNumber: number, textFromApi: string) => {
        switch (paymentMethod?.DropdownTextSource) {
            case "XML":
                return getLocalizedXmlText(selectedInstallmentNumber) || defaultText(selectedInstallmentNumber);
            case "API":
                return textFromApi || defaultText(selectedInstallmentNumber);
            default:
                return defaultText(selectedInstallmentNumber);
        }
    };

    const htmlTemplate = () =>
        props.options.map(
            (option) => html`
                <option value=${option.Value}>${getInstallmentDropdownText(Number(option.Value), option.Text)}</option>
            `,
        );

    return { htmlTemplate };
};

import i18next from "i18next";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { PaymentResult, PaymentType } from "../../component-models/PaymentResult";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useMediaObtainer } from "./useMediaObtainer";
import { useRef } from "haunted";
import { useState } from "../../shared/haunted/CustomHooks";
import { getTransactionTitleText } from "../../component-helpers/itinerary/ItineraryHelper";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { spaSectionOrderFactory } from "../spa/spaSectionOrderFactory";

export interface Props {
    isPrintingTransaction: boolean;
    model: ItineraryPageViewModel;
    filename: string;
    handleMoreTransactionsButtonClick: () => void;
}

export const useItineraryPaymentTransactions = (props: Props) => {
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const printedArea = useRef<HTMLDivElement>(undefined);

    const [isShown, setIsShown] = useState<boolean>(true);

    const getArea = () => printedArea?.current;

    const mediaObtainer = useMediaObtainer(getArea, props?.filename);

    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const hide = () => {
        setIsShown(false);
    };

    //TEMPLATES

    const fareLockIconClassMap = classMap({
        "js-fare-lock": flowContext.isFarelockRoundTwo,
        "js-circle-tick": !flowContext.isFarelockRoundTwo,
        "js-icon": true,
        "title-icon": true,
    });

    const fareLockExpiryDateTitleTemplate = (index: number) =>
        index === 0
            ? html`
                  <h2
                      class="main-title ts-download-title fare-lock no-print"
                      data-test-id=${T.ITINERARY.FARE_LOCK_ITINERARY_INFO}
                  >
                      ${unsafeHTML(
                          i18next
                              .t("FareLockItineraryInfo")
                              .replace("{0}", "<span>")
                              .replace("{1}", props.model.FarelockExpiry)
                              .replace("{2}", "</span>"),
                      )}
                  </h2>
              `
            : "";

    const paymentTitleIconTemplate = (paymentType: PaymentType) =>
        paymentType === "Cash"
            ? html`<i class="js-icon-it js-it-money title-icon"></i>`
            : html`<i class="js-circle-payment js-icon title-icon"></i>`;

    const fareLockTemplate = (index: number) =>
        flowContext.isFarelockRoundTwo ? html` ${fareLockExpiryDateTitleTemplate(index)} ` : "";

    const noFareLockTitleTemplate = (paymentResult: PaymentResult) =>
        !flowContext.isFarelockRoundTwo
            ? html`
                  ${paymentTitleIconTemplate(paymentResult?.PaymentType)}
                  <div class="title full-width-title">
                      <h2 class="main-title ts-download-title">
                          ${getTransactionTitleText(paymentResult.PaymentType)}
                      </h2>
                  </div>
              `
            : "";

    const transactionContainerTemplate = (paymentResult: PaymentResult, index: number) => html`
        <div class="transaction-container">
            <div class="row">
                <div class="col-xs-1">
                    <header class="transaction-header">
                        ${fareLockTemplate(index)} ${noFareLockTitleTemplate(paymentResult)}
                    </header>
                </div>
            </div>
        </div>
    `;

    const lastPaymentResultTemplate = () => {
        const indexOfLastPaymentResult = props.model.PaymentResults.length - 1;
        const paymentResult = props.model.PaymentResults[indexOfLastPaymentResult];

        return html`
            ${transactionContainerTemplate(paymentResult, indexOfLastPaymentResult)}
            <div ref=${ref(printedArea)} class="ts-printed-area printed-area">
                <div class="hidden">
                    <h2 class="main-title ts-download-title">${getTransactionTitleText(paymentResult.PaymentType)}</h2>
                </div>
                <ac-transaction-logger
                    .logWithTealium=${true}
                    .paymentResultData=${paymentResult}
                    .tealiumProperties=${props.model.TealiumProperties}
                ></ac-transaction-logger>
            </div>
        `;
    };

    const headerMainTitleTemplate = () =>
        flowContext.isFarelockRoundTwo
            ? i18next.t("FareLockPaymentHeaderTitle")
            : i18next.t("SuccessPaymentHeaderTitle");

    const headerTemplate = () => html`
        <header class="transactions-header" data-test-id=${T.ITINERARY.SUCCESSFUL_TRAVEL}>
            <div @click=${hide} class="close-transaction no-print">&times;</div>
            <i class=${fareLockIconClassMap}></i>
            <div class="title full-width-title">
                <h2 class="main-title">${headerMainTitleTemplate()}</h2>
            </div>
        </header>
    `;

    const fareLockPayNowButtonClassMap = classMap({
        "rounded-primary-btn": true,
        "banco-estado-color": userContext.bancoEstado.category !== 0,
    });

    const handleFarlockRoundTwoStartButton = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const order = getSpaSectionOrder({
            flowType: "FarelockRoundTwo",
            isStaff: userContext.isStaff,
        });
        const firstPageIndex = Math.min(...order.map((x) => x.index));

        window.location.href = `/${order.find((o) => o.index === firstPageIndex).section.uri}`;
    };

    const fareLockPayNowButtonTemplate = () =>
        flowContext.isFarelockRoundTwo
            ? html`
                  <a
                      class=${fareLockPayNowButtonClassMap}
                      data-test-id=${T.ITINERARY.FARE_LOCK_PAY_NOW_BUTTON}
                      @click=${handleFarlockRoundTwoStartButton}
                  >
                      ${i18next.t("ItineraryPayNow")}
                  </a>
              `
            : "";

    const printButtonClassMap = classMap({
        "rounded-transaction-btn": true,
        "print": true,
        "light-blue": userContext.bancoEstado.category === 0,
        "banco-estado-color": userContext.bancoEstado.category !== 0,
    });

    const downloadButtonClassMap = classMap({
        "rounded-transaction-btn": true,
        "download": true,
        "light-blue": userContext.bancoEstado.category === 0,
        "banco-estado-color": userContext.bancoEstado.category !== 0,
    });

    const openTransactionsTabButtonClassMap = classMap({
        "more-transactions-button": true,
        "md:ml-5": true,
        "rounded-transaction-btn": true,
        "light-blue": userContext.bancoEstado.category === 0,
        "banco-estado-color": userContext.bancoEstado.category !== 0,
    });

    const linksTemplate = () => {
        return html`
            <div class="transaction-button-container flex-wrap gap-y-2">
                <a
                    class=${printButtonClassMap}
                    data-test-id=${T.ITINERARY.TRANSACTIONS_PRINT_BUTTON}
                    @click=${() => mediaObtainer?.print()}
                    >${i18next.t("Print")}</a
                >
                <a
                    class=${downloadButtonClassMap}
                    data-test-id=${T.ITINERARY.TRANSACTIONS_DOWNLOAD_BUTTON}
                    @click=${() => mediaObtainer?.download()}
                    >${i18next.t("Download")}</a
                >
                <a
                    class=${openTransactionsTabButtonClassMap}
                    data-test-id=${T.ITINERARY.MORE_TRANSACTIONS_BUTTON}
                    @click=${props.handleMoreTransactionsButtonClick}
                    >${i18next.t("Más transacciones")}</a
                >
                ${fareLockPayNowButtonTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () => {
        const paymentTransactionsSectionClassMap = classMap({
            "booking-wrapper": true,
            "transaction-wrapper": true,
            "banco-estado": userContext.bancoEstado.category !== 0,
            "no-print": props.isPrintingTransaction,
        });

        return isShown
            ? html`
                  <section class=${paymentTransactionsSectionClassMap}>
                      ${headerTemplate()} ${lastPaymentResultTemplate()} ${linksTemplate()}
                  </section>
              `
            : html``;
    };

    return { htmlTemplate };
};

import { OUTBOUND } from "../../shared/commonConstants";
import { html } from "haunted";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    allBundlesSelected: boolean;
    context: FlightPageContext;
    isUpgradingBundle: boolean;
    journeyIndex: number;
}

export const useBundleSelectedMobile = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const bundle = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.bundleState?.selectedOutboundBundle
                : props.context?.bundleState?.selectedInboundBundle,
        [props.context?.bundleState, props.journeyIndex],
    );

    const isBundlesModePerLeg = () => props.context.bundleState?.bundlesMode === "PerLeg";

    // TEMPLATES

    const priceTemplate = () => {
        const price = formatNumber({ amount: bundle.UnFormattedPrice, leadingSign: false });

        return html`
            <div
                class="absolute right-[15px] top-1/2 translate-y-[calc(-50%+3px)] transform whitespace-nowrap text-center font-emphasis text-2xl leading-none text-be-blue"
                style="color: ${bundle.Color.toLowerCase() === "#ffffff" ? "#1c355e" : "#ffffff"};"
            >
                ${price}
            </div>
        `;
    };

    const headerTemplate = () => html`
        <div
            class=${classNames("hidden-sm-up flex flex-row items-center justify-around overflow-hidden rounded-t-xl", {
                "relative h-20": !props.allBundlesSelected && !isBundlesModePerLeg(),
                "absolute left-5 top-[13px] z-[1] h-auto": props.allBundlesSelected,
                "absolute left-[13px] top-[13px] z-[1] h-auto": isBundlesModePerLeg(),
            })}
        >
            <img
                class=${classNames({
                    "max-h-[25px]": !props.allBundlesSelected,
                    "max-h-[23px]": props.allBundlesSelected,
                })}
                src=${bundle.HeaderImgUrl}
            />
        </div>
    `;

    const tickTemplate = () => html`
        <i class=${classNames("js-icon js-flight-tick absolute right-[-17px] top-[-63px] text-3xl text-be-blue")}></i>
    `;

    const htmlTemplate = () => html`
        <div
            class=${classNames("selected-bundle-container", { reduced: props.isUpgradingBundle })}
            style="background-color: ${bundle.Color};"
            data-test-id=${getTestId(T.BUNDLE.SELECTED_CONTAINER, { j: props.journeyIndex, m: true })}
            data-test-value=${bundle.BundleType}
        >
            ${headerTemplate()}

            <div
                class=${classNames("hidden-sm-up relative min-h-[50px]", {
                    "flex items-center justify-between px-[10px] pb-[20px]": !props.allBundlesSelected,
                    "block p-[20px]": props.allBundlesSelected,
                })}
            >
                ${priceTemplate()} ${tickTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate };
};

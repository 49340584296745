import html2canvas from "html2canvas";
import { TemplateResult, render } from "lit-html";

export interface TransformedImage {
    id: string;
    image: string;
    index: number;
    size: "desktop" | "mobile";
}

export const useImageFromDomElement = () => {
    const getHtml2CanvasStyleHack = () => {
        // DEVNOTE Fix for html2canvas font problem
        // https://github.com/niklasvh/html2canvas/issues/2775 - boompikachu commented on Nov 16, 2022
        const style = document.createElement("style");
        document.head.appendChild(style);
        // DEVNOTE This has to be last, because sheet is a readonly prop that only works after appending
        style.sheet?.insertRule("body > div:last-child img { display: inline-block; }");

        return style;
    };

    const createContainer = () => {
        const container = document.createElement("div");
        container.style.width = "0px";
        container.style.height = "0px";
        container.style.overflow = "hidden";

        return container;
    };

    const createDomElements = (template: TemplateResult) => {
        const container = createContainer();
        const content = document.createElement("div");
        container.appendChild(content);
        document.body.appendChild(container);
        render(template, content);

        return { container, content };
    };

    const renderCanvas = async (data: {
        canvasHeight: number;
        canvasWidth: number;
        content: HTMLDivElement;
    }): Promise<HTMLCanvasElement> =>
        html2canvas(data.content, {
            height: data.canvasHeight,
            scale: 1,
            width: data.canvasWidth,
            ignoreElements: (element: Element) => (element as HTMLElement)?.localName.includes("ac-"),
        });

    const renderImgFromDomElem = async (data: {
        canvasHeight: number;
        canvasWidth: number;
        id: string;
        index: number;
        size: "desktop" | "mobile";
        template: TemplateResult;
    }): Promise<TransformedImage> => {
        const styleHack = getHtml2CanvasStyleHack();
        const { container, content } = createDomElements(data.template);

        const renderResult = await renderCanvas({
            canvasHeight: data.canvasHeight,
            canvasWidth: data.canvasWidth,
            content,
        });

        content.remove();
        container.remove();
        styleHack.remove();

        return {
            id: data.id,
            index: data.index,
            size: data.size,
            image: renderResult.toDataURL(),
        };
    };

    return { renderImgFromDomElem };
};

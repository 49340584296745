import { Floorplan } from "./Floorplan";
import { PlaneType } from "./PlaneType";

export const FloorplanDictionary = new Map<PlaneType, Floorplan>([
    [
        "A321",
        {
            GapBeforeRows: [2, 3, 4, 19, 20, 30, 31],
            SeatsOffsetBackward: [
                "31D",
                "31E",
                "31F",
                "32D",
                "32E",
                "32F",
                "33D",
                "33E",
                "33F",
                "34D",
                "34E",
                "34F",
                "35D",
                "35E",
                "35F",
                "36D",
                "36E",
                "36F",
                "37D",
                "37E",
                "37F",
                "38D",
                "38E",
                "38F",
                "39D",
                "39E",
                "39F",
                "40D",
                "40E",
                "40F",
                "41D",
                "41E",
                "41F",
                "42D",
                "42E",
                "42F",
            ],
            SeatsOffsetForward: [],
            Toilets: {
                FrontLeft: 1,
                FrontRight: 0,
                BackLeft: 2,
                BackRight: 0,
            },
            WingStartsAtRow: 19,
        },
    ],
    [
        "A320",
        {
            GapBeforeRows: [12, 14],
            SeatsOffsetBackward: [],
            SeatsOffsetForward: [],
            Toilets: {
                FrontLeft: 1,
                FrontRight: 0,
                BackLeft: 2,
                BackRight: 0,
            },
            WingStartsAtRow: 12,
        },
    ],
]);

import i18next from "i18next";
import { MaterialLabelElement, updateMdl } from "../../shared/common";
import { html, useRef, useState } from "haunted";
import { ref } from "../../directives/ref";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useModal } from "../shared/useModal";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useReduxState } from "../../shared/redux/useReduxState";
import classNames from "classnames";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    model: PaymentPageViewModel;
    onAccept: () => void;
    onCancel: () => void;
}

export const useTodosumaModal56 = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const acceptTermCheckbox = useRef<HTMLInputElement>(undefined);

    const [isShowCheckboxWarning, setIsShowCheckboxWarning] = useState<boolean>(false);

    const plusSign = () => (userContext.bancoEstado.category === 6 ? "+" : "");

    const handleAcceptTermsCheckboxClick = () => setIsShowCheckboxWarning(false);

    const handleSubmitClick = () => {
        const isValid = acceptTermCheckbox.current.checked;

        if (!isValid) {
            setIsShowCheckboxWarning(true);
            return;
        }

        modal.close();
        props.onAccept();
    };

    const handleCloseClick = () => {
        modal.close();
        (acceptTermCheckbox.current?.parentElement as MaterialLabelElement)?.MaterialCheckbox?.uncheck();
        props.onCancel();
    };

    const checkboxTemplate = () => html`
        <div class="todosuma56-checkbox">
            <div class="mdl-checkbox-wrapper">
                <label class="mdl-checkbox mdl-js-checkbox" @click=${handleAcceptTermsCheckboxClick}>
                    <input
                        type="checkbox"
                        tabindex="-1"
                        class="mdl-checkbox__input"
                        data-test-id="payment-todosuma56-checkbox-input"
                        ref=${ref(acceptTermCheckbox)}
                    />
                    <span class="mdl-checkbox__label cb-amount-label" data-test-id="payment-todosuma56-checkbox-label">
                        <span class="cb-title"> ${i18next.t("BE2-Todosuma56Checkbox")} </span>
                    </span>
                </label>
            </div>
            ${errorMessageTemplate()}
        </div>
    `;

    const errorMessageTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="error-message-container elevated-error text-left">
                    <div class=${classNames("form-error-message", { hidden: !isShowCheckboxWarning })}>
                        ${i18next.t("BE2-AcceptTodosumaConditions")}
                    </div>
                </div>
            </div>
        </div>
    `;

    const buttonTemplate = () => html`
        <div class="todosuma56-button-container">
            <button class="todosuma56-button" data-test-id="payment-todosuma56-yes-btn" @click=${handleSubmitClick}>
                ${i18next.t("BE2-Todosuma56YesButton")}
            </button>
            <a class="select-none" data-test-id="payment-todosuma56-no-btn" @click=${handleCloseClick}>
                ${i18next.t("BE2-Todosuma56NoButton")}
            </a>
        </div>
    `;

    const headerTemplate = () => html`
        <div class="todosuma56-header">
            ${i18next.t("BE2-RedeemTodosumaTitle")}
            <div class="todosuma56-img-container">
                <img src="/Images/BancoEstado/todosuma-puntos-logo-v3.png" />
            </div>
        </div>
    `;

    const contentTemplate = () => {
        const amount = formatNumber({ amount: userContext.bancoEstado.todosumaPoints, leadingSign: true });

        return html`
            <div class="todosuma56-amount">
                ${i18next.t("BE2-Todosuma56Amount {{amount}}", {
                    amount,
                })}
            </div>
            <div class="todosuma56-update-date">
                ${i18next.t("BE2-Todosuma56Update {{date}}", { date: props.model.TodosumaDate })}
            </div>
            <div class="todosuma56-note">${i18next.t("BE2-Todosuma56Note")}</div>
            <div class="todosuma56-body">
                ${unsafeHTML(
                    i18next.t("BE2-Todosuma56Body {{-start}} {{plus}} {{-end}}", {
                        start: "<span>",
                        plus: plusSign(),
                        end: "</span>",
                    }),
                )}
            </div>
            ${checkboxTemplate()} ${buttonTemplate()}
        `;
    };

    const htmlTemplate = () => html` ${contentTemplate()} ${updateMdl()} `;

    const modal = useModal({
        content: { classNames: "todosuma56-modal", template: htmlTemplate },
        header: { template: headerTemplate },
    });

    return modal;
};

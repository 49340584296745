import { useReduxState } from "./../../shared/redux/useReduxState";
import { html, useEffect, useState } from "haunted";
import { WOMPI_PSE_PAYMENT_METHOD_CODE } from "../../shared/commonConstants";
import { ROUTES } from "../../shared/apiRoutes";
import { WompiPseFinancialInstitutionResponse } from "../../component-models/payment/WompiPseFinancialInstitutionResponse";
import { WompiLegalIdType, WompiUserType } from "../../component-models/payment/WompiPseFormData";
import i18next from "i18next";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useMemo } from "../../shared/haunted/CustomHooks";

export const useWompiPse = () => {
    const { ajaxJsonRequest } = useAjax();

    const [selectedPaymentMethod] = useReduxState("payment.paymentMethod");
    const [wompiPseFormData, setWompiPseFormData] = useReduxState("payment.wompiPseFormData");

    const [institutions, setInstitutions] = useState<WompiPseFinancialInstitutionResponse[]>([]);

    const isPaymentMethodActive = useMemo(
        () => selectedPaymentMethod?.PaymentMethodCode === WOMPI_PSE_PAYMENT_METHOD_CODE,
        [selectedPaymentMethod],
    );

    const loadFinancialInstitutions = async () => {
        if (!isPaymentMethodActive) {
            setInstitutions([]);
            setWompiPseFormData({ ...wompiPseFormData, instituionCode: null });
            return;
        }

        const res = await ajaxJsonRequest<WompiPseFinancialInstitutionResponse[]>({
            url: ROUTES.ApiRoutes.WompiPseInstituions,
            method: "GET",
        });

        if (res.statusCode !== 200) {
            setInstitutions([]);
            setWompiPseFormData({ ...wompiPseFormData, instituionCode: null });
            alert("Failed to get PSE financial institutions from Wompi");
            return;
        }

        setInstitutions(res.data);
        setWompiPseFormData({ ...wompiPseFormData, instituionCode: res.data[0].FinancialInstitutionCode });
    };

    const handleInstitutionChange = async (e: KeyboardEvent) => {
        const newValue = (e.target as HTMLInputElement).value;
        setWompiPseFormData({ ...wompiPseFormData, instituionCode: newValue });
    };

    const handleLegalIdTypeChange = async (e: KeyboardEvent) => {
        const newValue = (e.target as HTMLInputElement).value;
        setWompiPseFormData({ ...wompiPseFormData, legalIdType: newValue as WompiLegalIdType });
    };

    const handleLegalIdChange = async (e: KeyboardEvent) => {
        const newValue = (e.target as HTMLInputElement).value;
        setWompiPseFormData({ ...wompiPseFormData, legalId: newValue });
    };

    const handleUserTypeChange = async (e: KeyboardEvent) => {
        const newValue = (e.target as HTMLInputElement).value;
        setWompiPseFormData({ ...wompiPseFormData, userType: newValue as WompiUserType });
    };

    useEffect(loadFinancialInstitutions, [isPaymentMethodActive]);

    const institutionOptionTemplate = (curr: WompiPseFinancialInstitutionResponse) => html`
        <option
            value=${curr.FinancialInstitutionCode}
            ?selected=${curr.FinancialInstitutionCode === wompiPseFormData?.instituionCode}
        >
            ${curr.FinancialInstitutionName}
        </option>
    `;

    const institutionsListTemplate = () =>
        institutions.length > 0
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                              <label class="mdl-textfield__label">${i18next.t("WompiPseInstitution")}</label>
                              <select
                                  class=${`mdl-textfield__input js-input js-select`}
                                  value=${wompiPseFormData?.instituionCode || ""}
                                  @change=${handleInstitutionChange}
                              >
                                  ${institutions.map(institutionOptionTemplate)}
                              </select>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        isPaymentMethodActive
            ? html`
                  ${institutionsListTemplate()}
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-3 col-md-1-3">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                              <label class="mdl-textfield__label">${i18next.t("WompiPseUserType")}</label>
                              <select
                                  class="mdl-textfield__input  js-input js-select"
                                  value=${wompiPseFormData?.userType || "PERSON"}
                                  @change=${handleUserTypeChange}
                              >
                                  <option value="PERSON" ?selected=${wompiPseFormData?.userType === "PERSON"}>
                                      ${i18next.t("WompiPsePerson")}
                                  </option>
                                  <option value="BUSINESS" ?selected=${wompiPseFormData?.userType === "BUSINESS"}>
                                      ${i18next.t("WompiPseBusiness")}
                                  </option>
                              </select>
                          </div>
                      </div>

                      <div class="col-xs-1 col-sm-1-3 col-md-1-3">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                              <label class="mdl-textfield__label">${i18next.t("WompiPseLegalId")}</label>
                              <select
                                  class="mdl-textfield__input  js-input js-select"
                                  value=${wompiPseFormData?.legalIdType || "CC"}
                                  @change=${handleLegalIdTypeChange}
                              >
                                  <option value="CC" ?selected=${wompiPseFormData?.legalIdType === "CC"}>CC</option>
                                  <option value="CE" ?selected=${wompiPseFormData?.legalIdType === "CE"}>CE</option>
                                  <option value="NIT" ?selected=${wompiPseFormData?.legalIdType === "NIT"}>NIT</option>
                              </select>
                          </div>
                      </div>

                      <div class="col-xs-1 col-sm-1-3 col-md-1-3">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                              <label class="mdl-textfield__label">${i18next.t("WompiPseLegalId")}</label>
                              <input
                                  class="mdl-textfield__input  js-input js-text"
                                  type="text"
                                  value=${wompiPseFormData?.legalId || ""}
                                  @change=${handleLegalIdChange}
                              />
                          </div>
                      </div>
                  </div>
              `
            : "";

    return { htmlTemplate, isPaymentMethodActive };
};

import { html } from "lit-html";
import { ApiBoardingPassViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";
import { BOARDING_PASS_WIDTH, BOARDING_PASS_HEIGHT } from "./useBoardingPassImageCreator";
import classNames from "classnames";
import i18next from "i18next";
import { useOperatorAndStopoverInfo } from "./useOperatorAndStopoverInfo";

export const useDesktopBoardingCard = () => {
    const { getOperationAndStopoverInfo } = useOperatorAndStopoverInfo();

    const headerTemplate = (model: ApiBoardingPassViewModel) => html`
        <div class="flex h-full flex-col justify-center gap-2 pt-2">
            <div class="font-news text-sm font-bold leading-none text-[#818a91]">
                ${i18next.t("Pasajero")} / Passenger
            </div>
            <div class="font-news text-xl font-bold leading-none text-be-blue">${model.Passenger.PaxName}</div>
        </div>
        <img width="140" height="34" src="/Images/Header/jetsmart-logo.png" />
    `;

    const barcodeTemplate = (model: ApiBoardingPassViewModel) => html`
        <img class="h-[200px] w-[200px]" src=${model.BarcodeImage} />
    `;

    const ssrTemplate = (ssr: string, smaller: boolean) => {
        const tempClassNames = classNames(
            "flex h-full w-full items-center justify-center rounded-sm bg-be-blue py-1 font-body font-bold leading-none text-white",
            {
                "text-base": !smaller,
                "text-sm": smaller,
            },
        );

        return html`<div class=${tempClassNames}>${ssr}</div>`;
    };

    const ssrsTemplate = (model: ApiBoardingPassViewModel) => {
        const tempClassNames = classNames("grid w-1/3 auto-rows-min gap-2 pr-10", {
            "grid-cols-3": model.Ssrs.length <= 9,
            "grid-cols-4": model.Ssrs.length > 9,
        });

        return html`
            <div class=${tempClassNames}>${model.Ssrs.map((ssr) => ssrTemplate(ssr, model.Ssrs.length > 9))}</div>
        `;
    };

    const footnotesTemplate = (model: ApiBoardingPassViewModel) => html`
        <img class="mr-5 block h-[43px] w-[43px] flex-1" src="/Images/Icons/boarding-card/exclamation-dt.png" />
        <div>
            <div class="mb-5 font-news text-base text-[#818a91]">
                *${i18next.t("GateWarning1 {{hours}}", { hours: model.ShowUpTime })}
            </div>
            <div>
                <span class="font-news text-sm text-be-blue">${i18next.t("¿Pagaste tu equipaje?")}</span>
                <span class="font-news text-sm text-[#818a91]">
                    ${i18next.t(
                        "Tu tarifa SMART incluye mochila o artículo personal (peso máx. 10kg / dimensiones 45 cm x 35 cm x 25 cm) y asiento  estándar. Si llevas equipaje y no lo has pagado te recomendamos comprarlo ahora en JetSMART.com, de lo contrario, en aeropuerto será más caro. ",
                    )}
                </span>
            </div>
        </div>
    `;

    const tableHeaderTemplate = (internationalLabel: string, englishLabel: string, isRed = false) => {
        const tempClassNames = classNames(
            "flex h-11 min-w-20 flex-col items-center justify-center text-center font-body uppercase text-white",
            {
                "bg-be-red-2": isRed,
                "bg-be-blue": !isRed,
            },
        );

        return html`
            <div class=${tempClassNames}>
                <span class="mb-px text-base font-bold leading-none">${internationalLabel}</span>
                <span class="mt-px text-sm leading-none">${englishLabel}</span>
            </div>
        `;
    };

    const cellTemplate = (content: string, isRed = false) => {
        const tempClassNames = classNames(
            "flex h-12 min-w-20 flex-col items-center justify-center text-center font-body text-base font-black uppercase",
            {
                "text-be-blue": !isRed,
                "text-new-ui-red": isRed,
            },
        );

        return html`<div class=${tempClassNames}>${content}</div>`;
    };

    const tablesTemplate = (model: ApiBoardingPassViewModel) => html`
        <div class="flex h-full w-2/3 gap-10">
            <div class="grid h-full w-2/3 grid-cols-4 overflow-hidden rounded-xl border border-solid border-be-blue">
                ${tableHeaderTemplate(i18next.t("Embarque"), "Boarding")}
                ${tableHeaderTemplate(i18next.t("Terminal"), "Terminal")}
                ${tableHeaderTemplate(`${i18next.t("Puerta")}*`, "Gate*")}
                ${tableHeaderTemplate(i18next.t("Asiento"), "Seat")} ${cellTemplate(model.Details.BoardingTime)}
                ${cellTemplate(model.Details.Terminal)} ${cellTemplate(model.Details.Gate)}
                ${cellTemplate(
                    model.Details.IsEmergencyRow ? `${model.Details.Seat} *` : model.Details.Seat,
                    model.Details.IsEmergencyRow,
                )}
            </div>
            <div class="grid h-full w-1/3 grid-cols-2 overflow-hidden rounded-xl border border-solid border-be-red-2">
                ${tableHeaderTemplate(i18next.t("Seq"), "Seq", true)}
                ${tableHeaderTemplate(i18next.t("Zona"), "Zone", true)} ${cellTemplate(model.Details.Sequence)}
                ${cellTemplate(model.Details.Zone)}
            </div>
        </div>
    `;

    const infantTemplate = (model: ApiBoardingPassViewModel) =>
        model.HasInfant
            ? html`
                  <div
                      class="absolute inset-0 flex items-center justify-center font-body text-[405px] font-bold leading-none text-cug-gray-4"
                  >
                      INF
                  </div>
              `
            : "";

    const itineraryHeaderTemplate = (model: ApiBoardingPassViewModel) => html`
        <div
            class="absolute left-0 top-0 flex h-1/3 w-full items-center justify-between border-b border-solid border-[#818a91]"
        >
            ${itineraryHeaderCellTemplate(i18next.t("Código de reserva"), "Booking code", model.Header.Pnr)}
            ${itineraryHeaderCellTemplate(i18next.t("Vuelo"), "Flight", model.Header.FlightNumber)}
            ${itineraryHeaderCellTemplate(i18next.t("Fecha"), "Date", model.Header.DepartureDate)}
        </div>
    `;

    const itineraryHeaderCellTemplate = (internationalLabel: string, englishLabel: string, value: string) => html`
        <div>
            <div class="whitespace-nowrap font-news text-sm text-[#818a91]">
                ${internationalLabel} / ${englishLabel}
            </div>
            <div class="whitespace-nowrap font-news text-xl font-bold uppercase text-be-blue">${value}</div>
        </div>
    `;

    const itineraryExtraInfoLineTemplate = (line: string) => html`
        <div class="font-extra text-[10px] font-normal leading-relaxed text-[#0a355e]">${line}</div>
    `;

    const itineraryExtraInfoTemplate = (model: ApiBoardingPassViewModel) => {
        const lines = getOperationAndStopoverInfo({
            arrivalStationCode: model.Itinerary.Arrival.StationCode,
            isMobile: false,
            model: model.Itinerary.Stops,
        });

        return html`
            <div class="absolute inset-x-0 bottom-1 flex flex-col items-center">
                <div>${lines.map(itineraryExtraInfoLineTemplate)}</div>
            </div>
        `;
    };

    const endpointTemplate = (model: ApiBoardingPassViewModel, type: "origin" | "destination") => {
        const endpoint = type === "origin" ? model.Itinerary.Departure : model.Itinerary.Arrival;
        const label = type === "origin" ? `${i18next.t("Origen")} / Origin` : `${i18next.t("Destino")} / Destination`;

        return html`
            <div class=${classNames("flex w-2/5 flex-col", { "items-end": type === "destination" })}>
                <div class="mb-2 font-news text-base text-[#818a91]">${label}</div>
                <div class="font-news text-6xl font-bold text-[#0a355e]">${endpoint.StationCode}</div>
                <div class="font-news text-2xl font-bold text-[#0a355e]">${endpoint.StationName}</div>
                <div class="font-news text-sm font-light text-[#0a355e]">${endpoint.AirportName}</div>
                <div class="font-news text-lg font-bold text-[#0a355e]">${endpoint.Time}</div>
            </div>
        `;
    };

    const itineraryTemplate = (model: ApiBoardingPassViewModel) => html`
        <div class="flex h-full w-full items-center justify-between">
            ${endpointTemplate(model, "origin")}
            <img src="/Images/Icons/boarding-card/plane.png" />
            ${endpointTemplate(model, "destination")}
        </div>
    `;

    const renderDesktopBoardingCard = (model: ApiBoardingPassViewModel) =>
        model
            ? html`
                  <div
                      class="h-[690px] w-[1070px] rounded-3xl border border-solid border-be-blue bg-white"
                      style=${`width: ${BOARDING_PASS_WIDTH}px; height: ${BOARDING_PASS_HEIGHT}px;`}
                  >
                      <div class="flex h-20 items-center justify-between border-b-4 border-solid border-be-red-2 px-10">
                          ${headerTemplate(model)}
                      </div>
                      <div class="flex h-1/2 w-full px-10">
                          <div class="flex w-1/3 items-center justify-center pr-10">${barcodeTemplate(model)}</div>
                          <div class="relative h-full w-2/3">
                              ${infantTemplate(model)} ${itineraryHeaderTemplate(model)}

                              <div class="absolute inset-x-0 bottom-0 h-2/3 w-full">
                                  ${itineraryTemplate(model)} ${itineraryExtraInfoTemplate(model)}
                              </div>
                          </div>
                      </div>
                      <div class="mb-10 flex h-24 w-full px-10">${ssrsTemplate(model)} ${tablesTemplate(model)}</div>
                      <div class="flex w-full px-10">${footnotesTemplate(model)}</div>
                  </div>
              `
            : html``;

    return { renderDesktopBoardingCard };
};

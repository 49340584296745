import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export interface Props {
    onClose: () => void;
}

export const useBookingBlockedModal = (props: Props) => {
    const handleClose = () => {
        modal.hide();
        props.onClose();
    };

    const htmlTemplate = () => html`
        <div class="modal-body">
            <p>${i18next.t("SpecialNeedsBlockingModalText")}</p>
            <div class="flex justify-center">
                <button target="_blank" class="rounded-secondary-btn" @click=${handleClose}>
                    ${i18next.t("GoBackBtn")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("BookingBlockedTitle") },
    });

    return modal;
};

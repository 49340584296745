import { html } from "lit-html";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { ChangeDateEvent } from "../../dc-components/dc-datepicker";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { useEffect } from "../../shared/haunted/CustomHooks";
import dayjs, { Dayjs } from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { useDatepicker } from "../ui/useDatepicker/useDatepicker";

export interface Props {
    destination: string;
    disabledInboundDates: dayjs.Dayjs[];
    disabledOutboundDates: dayjs.Dayjs[];
    fixedInboundDate: boolean;
    fixedOutboundDate: boolean;
    inboundDate: dayjs.Dayjs;
    isLoading: boolean;
    isOneWay: boolean;
    maxInboundDate: dayjs.Dayjs;
    maxOutboundDate: dayjs.Dayjs;
    minInboundDate: dayjs.Dayjs;
    minOutboundDate: dayjs.Dayjs;
    origin: string;
    outboundDate: dayjs.Dayjs;
    root: HTMLDivElement;
    useCaptions: boolean;
    onSelect: (journeyIndex: number, selectedDates: Dayjs[]) => void;
}

export const useDateSelector = (props: Props) => {
    const isOutboundDisabled = () => props.fixedOutboundDate || !props.origin || !props.destination || props.isLoading;

    const isInboundDisabled = () =>
        props.fixedInboundDate || !props.origin || !props.destination || props.isLoading || !props.outboundDate;

    const getRangeStartDate = () =>
        props.outboundDate && props.outboundDate.isSameOrAfter(dayjs(), "day") && !props.fixedInboundDate
            ? props.outboundDate
            : undefined;

    const autoOpenOutbound = () => {
        // DEVNOTE Auto open outbound after timetable loading is finished
        if (props.origin && props.destination && !props.outboundDate && !props.isLoading) {
            outboundDate.open();
        }
    };

    const autoOpenInbound = () => {
        // DEVNOTE Auto open inbound after outbound date is selected
        if (!props.isOneWay && props.outboundDate && !props.inboundDate) {
            inboundDate.open();
        }
    };

    const outboundDate = useDatepicker({
        classes: [props.useCaptions ? "up" : ""],
        disabledDates: props.disabledOutboundDates,
        inputClasses: ["options-opener"],
        inputTestId: T.SEARCH.OUTBOUND_DATE,
        isDisabled: isOutboundDisabled(),
        isLoading: props.isLoading,
        isRange: false,
        max: props.maxOutboundDate,
        min: props.minOutboundDate,
        placeHolder: i18next.t("V2-DepartureDate"),
        value: props.outboundDate,
        wide: true,
        onChange: (e: ChangeDateEvent) => props.onSelect(OUTBOUND, [e.detail.fromDate || e.detail.date]),
    });

    const inboundDate = useDatepicker({
        classes: ["xs-align-left", "align-right", props.useCaptions ? "up" : ""],
        disabledDates: props.disabledInboundDates,
        inputClasses: ["options-opener"],
        inputTestId: T.SEARCH.INBOUND_DATE,
        isDisabled: isInboundDisabled(),
        isLoading: props.isLoading,
        isRange: true,
        max: props.maxInboundDate,
        min: props.minInboundDate,
        placeHolder: i18next.t("V2-ReturnDate"),
        value: props.outboundDate ? [getRangeStartDate(), props.inboundDate] : undefined,
        wide: true,
        onChange: (e: ChangeDateEvent) => props.onSelect(INBOUND, [e.detail.fromDate, e.detail.toDate]),
    });

    useEffect(autoOpenOutbound, [props.isLoading]);
    useEffect(autoOpenInbound, [props.outboundDate]);

    const outboundCaptionTemplate = () =>
        props.useCaptions
            ? html`
                  <div class="mb-1 mt-1">
                      <div class="row text-be-blue">
                          <div class="col-xs-1-2">${i18next.t("Fecha de salida")}</div>
                      </div>
                  </div>
              `
            : "";

    const inboundCaptionTemplate = () =>
        props.useCaptions && !props.isOneWay
            ? html`
                  <div class="mb-1 mt-1">
                      <div class="row text-be-blue">
                          <div class="col-xs-1-2">${i18next.t("Fecha de regreso")}</div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">${outboundCaptionTemplate()} ${outboundDate.htmlTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">
                ${!props.isOneWay ? html` ${inboundCaptionTemplate()} ${inboundDate.htmlTemplate()} ` : ""}
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { resetFieldError } from "../../shared/errorHandler";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { LoginType } from "../../shared/commonConstants";

export const name = "ac-login-type-selector";

export interface Properties {
    loginType: LoginType;
    showBancoEstado: boolean;
    showCustomerAndAgencyTabs: boolean;
    showPeruCompraTab: boolean;
    setLoginType: (state: LoginType) => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        loginType: host.loginType,
        showBancoEstado: host.showBancoEstado,
        showCustomerAndAgencyTabs: host.showCustomerAndAgencyTabs,
        showPeruCompraTab: host.showPeruCompraTab,
        setLoginType: host.setLoginType,
    };

    // HELPERS

    // COMPONENT

    // EVENT LISTENERS

    // TEMPLATES

    return html`
        <div class="row">
            <div class="col-xs-1">
                <div class="logintype-tabs">
                    ${props.showCustomerAndAgencyTabs
                        ? html`
                              <label class="mdl-radio mdl-js-radio" for="option1b">
                                  <input
                                      type="radio"
                                      id="option1b"
                                      value="JA"
                                      name="loginType"
                                      class="mdl-radio__button"
                                      ?checked=${props.loginType === "JA"}
                                      @click=${() => props.setLoginType("JA")}
                                      @blur=${(e: MouseEvent) => resetFieldError(e.target as HTMLInputElement)}
                                      data-test-id=${T.LOGIN_TYPE_SELECTOR.PERSON_RADIO_BUTTON}
                                  />
                                  <span class="mdl-radio__label">${i18next.t("V2-LoginPersons")}</span>
                              </label>
                          `
                        : ""}
                    ${props.showPeruCompraTab
                        ? html`
                              <label
                                  class="mdl-radio mdl-js-radio"
                                  for="option2b"
                                  data-test-id=${T.LOGIN_TYPE_SELECTOR.PERU_COMPRA_RADIO_BUTTON}
                              >
                                  <input
                                      type="radio"
                                      id="option2b"
                                      value="PC"
                                      name="loginType"
                                      class="mdl-radio__button"
                                      ?checked=${props.loginType === "PC"}
                                      @click=${() => props.setLoginType("PC")}
                                      @blur=${(e: MouseEvent) => resetFieldError(e.target as HTMLInputElement)}
                                  />
                                  <span class="mdl-radio__label">${i18next.t("Perú Compras")}</span>
                              </label>
                          `
                        : ""}
                    ${props.showBancoEstado
                        ? html`
                              <label class="mdl-radio mdl-js-radio" for="option4b">
                                  <input
                                      type="radio"
                                      id="option4b"
                                      value="BE"
                                      name="loginType"
                                      class="mdl-radio__button"
                                      ?checked=${props.loginType === "BE"}
                                      @click=${() => props.setLoginType("BE")}
                                      data-test-id=${T.LOGIN_TYPE_SELECTOR.BANCO_ESTADO_RADIO_BUTTON}
                                  />
                                  <span class="mdl-radio__label nowrap radio-with-img"
                                      >${i18next.t("BE-LoginLabel")} <img src="/Images/BancoEstado/be-name.svg"
                                  /></span>
                              </label>
                          `
                        : ""}
                </div>
            </div>
        </div>
    `;
};

export const maxBy = <T, U>(array: T[], selector: (element: T) => U): T | undefined => {
    if (array.length === 0) return undefined;

    let maxElement = array[0];
    let maxValue = selector(maxElement);

    for (let i = 1; i < array.length; i++) {
        const value = selector(array[i]);

        if (value > maxValue) {
            maxElement = array[i];
            maxValue = value;
        }
    }

    return maxElement;
};

export const unique = <T>(array: T[]): T[] => {
    const set = new Set<T>(array);
    return Array.from(set);
};

export const uniqueByKey = <T>(array: T[], key: keyof T): T[] => [
    ...new Map(array.map((item) => [item[key], item])).values(),
];

import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { getAntiforgerySegment } from "../../shared/common";
import { useRef } from "haunted";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { useFlightPageAjaxHandler } from "./useFlightPageAjaxHandler";

export interface DcFeeFormProps {
    antiForgeryToken: string;
    context: FlightPageContext;
}

export const useDcFeeForm = (props: DcFeeFormProps) => {
    const { submitDcForm } = useFlightPageAjaxHandler();

    const dcFeeForm = useRef<HTMLFormElement>(undefined);

    const dcFeeCode = () => {
        if (!props.context?.dcState || props.context?.dcState.hasMembershipOnBooking) return null;

        switch (props.context.dcState.selectedMembershipType) {
            case "Standard":
                return props.context.model.DiscountClubViewModel.StandardCode;
            case "Group":
                return props.context.model.DiscountClubViewModel.GroupCode;
            case "Upgrade":
                return props.context.model.DiscountClubViewModel.UpgradeCode;
            default:
                return "";
        }
    };

    const submit = async () => {
        await submitDcForm(dcFeeForm.current, props.context?.container);
    };

    const htmlTemplate = () => html`
        <form ref=${ref(dcFeeForm)}>
            ${getAntiforgerySegment(props.antiForgeryToken)}
            <input
                type="hidden"
                name="marketFareKeys[0]"
                value=${props.context?.flightState?.selectedOutboundFlight?.SellKey}
            />
            <input type="hidden" name="feeCode" value=${dcFeeCode()} />
            <input type="hidden" name="passengerNumber" value="0" />
        </form>
    `;

    return { htmlTemplate, submit };
};

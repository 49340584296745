import { OUTBOUND } from "./../shared/commonConstants";
import { FlexiFeeJourneyModel } from "./../component-models/extras/flexi-fee/FlexiFeeJourneyModel";
import { FlexiFeeModel } from "../component-models/extras/flexi-fee/FlexiFeeModel";
import { ApiExtrasSpaSectionViewModel } from "../component-models/spa/ExtrasSpaSectionViewModel";

export function mapToFlexiFeeModel(model: ApiExtrasSpaSectionViewModel): FlexiFeeModel {
    const journeys: FlexiFeeJourneyModel[] = model.ExtrasModel.FlexiFeeJourneys.map((journey) => ({
        Amount: journey.Amount,
        BundleColor: journey.JourneyIndex === OUTBOUND ? model.OutboundBundleColor : model.InboundBundleColor,
        BundleImg: journey.JourneyIndex === OUTBOUND ? model.OutboundBundleImgUrl : model.InboundBundleImgUrl,
        DepartureStationName: journey.DepartureStationName,
        DestinationStationName: journey.DestinationStationName,
        FormattedAmount: journey.FormattedAmount,
        IsDisabled: journey.IsDisabled,
        IsInBundle: journey.IsInBundle,
        IsSold: journey.IsSold,
        JourneyIndex: journey.JourneyIndex,
    }));

    return {
        Journeys: journeys,
    };
}

import { html } from "lit-html";
import i18next from "i18next";
import { showLoader } from "../../../../shared/common";
import { JourneySegment } from "../../../../component-models/shared/JourneySegment";
import { INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useModal } from "../../../shared/useModal";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useFlowContext } from "../../../../managers/useFlowContext";

export interface Props {
    flightSegments: JourneySegment[];
}

export const useEtdChangeModal = (props: Props) => {
    const segmentsWithChangedDepartureOrArrivalTimePerJourney = (journeyIndex: number) =>
        props.flightSegments.filter(
            (segment) =>
                segment.JourneyIndex === journeyIndex &&
                ((!flowContext.isBookingFlow && segment.FormattedEstimatedArrivalTime) ||
                    (segment.FormattedEstimatedDepartureTime &&
                        ((!bookingContext.isCheckinClosedOutbound && segment.JourneyIndex === OUTBOUND) ||
                            (!bookingContext.isCheckinClosedInbound && segment.JourneyIndex === INBOUND)))),
        );

    const hasTimeChangedBothDirection = () =>
        segmentsWithChangedDepartureOrArrivalTimePerJourney(OUTBOUND).length > 0 &&
        segmentsWithChangedDepartureOrArrivalTimePerJourney(INBOUND).length > 0;

    const hasMultipleSegmentsWithChangedTimeOneWay = (journeyIndex: number) =>
        segmentsWithChangedDepartureOrArrivalTimePerJourney(journeyIndex).length > 1;

    const getEstimatedDepartureTime = (segment: JourneySegment) =>
        segment.FormattedEstimatedDepartureTime ?? `${segment.DepartureDate} ${segment.FormattedDepartureTime}`;

    const getEstimatedArrivalTime = (segment: JourneySegment) =>
        segment.FormattedEstimatedArrivalTime ?? `${segment.ArrivalDate} ${segment.FormattedArrivalTime}`;

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    // EVENT LISTENERS
    const handleClickNo = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();

        showLoader({});
        window.location.href = "/";
    };

    const handleClickYes = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();
    };

    // TEMPLATES

    const questionTemplate = (journeyIndex: number) =>
        hasMultipleSegmentsWithChangedTimeOneWay(journeyIndex)
            ? html`<p>${i18next.t("¿Quieres seleccionar estos vuelos?")}</p>`
            : html`<p>${i18next.t("¿Quieres seleccionar este vuelo?")}</p>`;

    const modifiedFlightsBothWaysTemplate = () => html`
        <div class="etd-flights">
            <p class="etd-journey">
                ${i18next.t("Ambos vuelos seleccionados han cambiado de horario, los nuevos horarios son")}
            </p>
            <p class="etd-journey">${i18next.t("Ida")}:</p>
            ${segmentsWithChangedDepartureOrArrivalTimePerJourney(OUTBOUND).map(
                (segment) => html`
                    <span> ${getEstimatedDepartureTime(segment)} - ${getEstimatedArrivalTime(segment)} </span>
                `,
            )}
            <p class="etd-journey">${i18next.t("Vuelta")}:</p>
            ${segmentsWithChangedDepartureOrArrivalTimePerJourney(INBOUND).map(
                (segment) => html`
                    <span> ${getEstimatedDepartureTime(segment)} - ${getEstimatedArrivalTime(segment)} </span>
                `,
            )}
        </div>
        <p>${i18next.t("¿Quieres seleccionar estos vuelos?")}</p>
    `;

    const modifiedFlightsOneWayTemplate = (journeyIndex: number) =>
        segmentsWithChangedDepartureOrArrivalTimePerJourney(journeyIndex).length
            ? html`
                  <div class="etd-flights">
                      ${segmentsWithChangedDepartureOrArrivalTimePerJourney(journeyIndex).map(
                          (segment) =>
                              html` <span>
                                  ${unsafeHTML(
                                      journeyIndex === OUTBOUND
                                          ? i18next.t(
                                                "El vuelo de ida seleccionado ha cambiado de horario, el nuevo horario es {{-depDateTime}} - {{-arrDateTime}}",
                                                {
                                                    depDateTime: getEstimatedDepartureTime(segment),
                                                    arrDateTime: getEstimatedArrivalTime(segment),
                                                },
                                            )
                                          : i18next.t(
                                                "El vuelo de vuelta seleccionado ha cambiado de horario, el nuevo horario es {{-depDateTime}} - {{-arrDateTime}}",
                                                {
                                                    depDateTime: getEstimatedDepartureTime(segment),
                                                    arrDateTime: getEstimatedArrivalTime(segment),
                                                },
                                            ),
                                  )}
                              </span>`,
                      )}
                  </div>
                  ${questionTemplate(journeyIndex)}
              `
            : "";

    const modifiedFlightsTemplate = () =>
        hasTimeChangedBothDirection()
            ? html`${modifiedFlightsBothWaysTemplate()}`
            : html`${modifiedFlightsOneWayTemplate(OUTBOUND)} ${modifiedFlightsOneWayTemplate(INBOUND)}`;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="etd-description-container">${modifiedFlightsTemplate()}</div>
            <div class="etd-modal-btn-container">
                <button class="rounded-secondary-btn" @click=${handleClickNo}>${i18next.t("No, cotizaré otro")}</button>
                <button class="rounded-primary-btn" @click=${handleClickYes}>${i18next.t("Si")}</button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => html`<span>${i18next.t("V2-GiftCardMEssageError-Important")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../shared/useModal";

export interface Props {
    callback: () => void;
}

export const useMassBoardingPassDownloadModal = (props: Props) => {
    const handleYes = () => {
        modal.close();
        props.callback();
    };

    const htmlTemplate = () => html`
        <div class="modal-body text-center">
            <span class="brand-secondary-text">${i18next.t("V2-MassDownloadBpInfo")}</span>
            <div class="modal-button-container">
                <a class="secondary-btn" @click=${modal.close}> ${i18next.t("V2-Cancel")} </a>
                <a class="primary-btn" data-test-id="mass-download-button" @click=${handleYes}>
                    ${i18next.t("V2-Continue")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("V2-MassDownloadBpTitle") },
    });

    return modal;
};

import { TemplateResult, html } from "lit-html";
import { useState } from "../../shared/haunted/CustomHooks";
import classNames from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import { useLayoutEffect, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { usePubSub } from "../../pub-sub-service/usePubSub";

export interface Props {
    closing?: { buttonClassNames?: string; buttonTestId?: string; isClosable: boolean; onClose?: () => void };
    content: { classNames?: string; testId?: string; template: () => TemplateResult | string };
    header?: { classNames?: string; noScroll?: boolean; template?: () => TemplateResult | string };
    overlay?: { classNames?: string; testId?: string };
    scrolling?: { isScrollable: boolean; classNames?: string };
    onOpen?: () => void;
}

export const useModal = (props: Props) => {
    const { triggers } = usePubSub();

    const scroller = useRef<HTMLDivElement>(undefined);

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(true);
        if (typeof props.onOpen === "function") props.onOpen();
    };

    const hide = () => setIsOpen(false);

    const close = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        hide();
        if (typeof props.closing?.onClose === "function") props.closing.onClose();
    };

    const closeTooltipsOnScroll = () => triggers.shared.windowWasScrolled.publish({});

    const initScrollbar = () => {
        if (scrollbar) {
            scrollbar.destroy();
            scroller.current.removeEventListener("ps-scroll-y", closeTooltipsOnScroll);
        }

        setScrollbar(
            new PerfectScrollbar(scroller.current, {
                wheelPropagation: false,
                wheelSpeed: 2,
                swipeEasing: true,
                suppressScrollX: true,
            }),
        );

        scroller.current.addEventListener("ps-scroll-y", closeTooltipsOnScroll);
    };

    useLayoutEffect(() => {
        if (scroller.current) initScrollbar();

        if (isOpen) {
            document.body.classList.add("no-scroll2");
        } else {
            document.body.classList.remove("no-scroll2");
        }
    }, [isOpen]);

    const closeButtonTemplate = () =>
        props.closing?.isClosable
            ? html`
                  <div
                      class=${classNames("modal-header-closing", props.closing.buttonClassNames || "")}
                      data-test-id=${props.closing.buttonTestId || ""}
                      @click=${close}
                  >
                      <div class="flex h-full w-full cursor-pointer items-center justify-center not-italic">
                          &times;
                      </div>
                  </div>
              `
            : "";

    const headerTemplate = () =>
        props.header
            ? html`
                  <div class=${classNames("modal-header", props.header.classNames || "")}>
                      ${props.header.template()} ${closeButtonTemplate()}
                  </div>
              `
            : "";

    const mainTemplate = () => html` ${!props.header?.noScroll ? headerTemplate() : ""} ${props.content.template()} `;

    const contentTemplate = () =>
        props.scrolling?.isScrollable
            ? html`${props.header.noScroll ? headerTemplate() : ""}
                  <div
                      ref=${ref(scroller)}
                      class=${classNames("absolute", props.scrolling.classNames, {
                          "inset-0": !props.scrolling.classNames,
                      })}
                  >
                      ${mainTemplate()}
                  </div> `
            : mainTemplate();

    const htmlTemplate = () =>
        isOpen
            ? html`
                  <div
                      data-test-id=${props.overlay?.testId || ""}
                      class=${classNames("modal common-transition", props.overlay?.classNames || "", {
                          "opacity-0": !isOpen,
                      })}
                  >
                      <div
                          class=${classNames(
                              "modal-content max-h-[95%] sm:max-h-[90%]",
                              props.content.classNames || "",
                          )}
                          data-test-id=${props.content.testId || ""}
                      >
                          ${!props.header ? closeButtonTemplate() : ""} ${contentTemplate()}
                      </div>
                  </div>
              `
            : html``;

    return {
        isOpen,
        scroller: scroller.current,
        close,
        hide,
        htmlTemplate,
        open,
    };
};

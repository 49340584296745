import { SeatmapState } from "./../component-models/seatmap/SeatmapState";
import { SeatmapContext } from "./../component-models/seatmap/SeatmapContext";
import { html } from "lit-html";
import { SeatmapHelper } from "../component-helpers/seatmap/SeatmapHelper";
import i18next from "i18next";
import { ExtendedApiSeat } from "../component-models/seatmap/ApiSeat";

export const seatIconsTemplate = (
    context: SeatmapContext,
    freeSeatsNotOnBooking: number,
    currentSeats: ExtendedApiSeat[],
) =>
    [...Array(4)].map((_, index) => {
        if (!isFreeSeatVisible(context, index + 1, freeSeatsNotOnBooking)) {
            return "";
        }

        return isFreeSeatSelected(currentSeats, index + 1)
            ? html`
                  <i class="js-icon-be2 js-be2-base-seat-selected">
                      <span class="path1"></span>
                      <span class="path2"></span>
                  </i>
              `
            : html` <i class="js-icon-be2 js-be2-base-seat"></i> `;
    });

export const bancoEstadoDiscountedSeatsInfoTemplate = (
    context: SeatmapContext,
    seatmapState: SeatmapState,
    isBookingFlow: boolean,
    isCategory6: boolean,
    isCategory5: boolean,
    freeSeatsNotOnBooking: number,
) =>
    SeatmapHelper.showDiscountedSeatInfo(
        context,
        seatmapState,
        isBookingFlow,
        isCategory6,
        isCategory5,
        freeSeatsNotOnBooking,
    )
        ? html`
              <div class="be2-seats-panel be2-discounted-seats-panel">
                  <i class="js-icon-be2 js-be2-seats"></i>
                  <div class="discounted-seat-info">
                      <div>
                          <span> ${i18next.t("Tienes desde un 40% de descuento")} </span>
                      </div>
                      <div>${i18next.t("en asientos de la zona BancoEstado")}</div>
                  </div>
              </div>
          `
        : "";

export const bancoEstadoFreeSeatsTemplate = (data: {
    context: SeatmapContext;
    seatmapState: SeatmapState;
    freeSeatsNotOnBooking: number;
    isBookingFlow: boolean;
    isCategory6: boolean;
    currentSeats: ExtendedApiSeat[];
}) => {
    if (!data.context.Model) return "";

    const remainingSeats = SeatmapHelper.remainingAvailableFreeSeatsForBooking({
        freeSeatsInBooking: data.context.Model.FreeSeatsInBooking,
        currentSeats: data.currentSeats,
        freeSeatsNotOnBooking: data.freeSeatsNotOnBooking,
    });

    return SeatmapHelper.showFreeSeatInfo(data)
        ? html`
              <div class="be2-seats-panel be2-free-seats-panel">
                  <i class="js-icon-be2 js-be2-seats"></i>
                  <div class="free-seat-info">
                      <span>
                          ${i18next.t("Tienes {{number}} asiento{{plural}} gratis", {
                              number: remainingSeats,
                              plural: remainingSeats !== 1 ? "s" : "",
                          })}
                      </span>
                      ${i18next.t("y además desde un")}
                      <span> ${i18next.t("40% DCTO")} </span>
                      ${i18next.t("en la zona BancoEstado cuando tus asientos gratis se acaben")}
                  </div>
                  <div class="free-seat-images">
                      ${seatIconsTemplate(data.context, data.freeSeatsNotOnBooking, data.currentSeats)}
                  </div>
              </div>
          `
        : "";
};

const isFreeSeatVisible = (context: SeatmapContext, index: number, freeSeatsNotOnBooking: number) =>
    freeSeatsNotOnBooking + context.Model.FreeSeatsInBooking >= index;

const isFreeSeatSelected = (currentSeats: ExtendedApiSeat[], index: number) =>
    SeatmapHelper.getFreeSeatsUsedInBooking(currentSeats) >= index;

import { SelectOption } from "../../shared/common";
import { commonDebug } from "../../bootstrap";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { gatewayGetDropdownTexts } from "../../component-helpers/payment/InstallmentsMercadoPagoHelper";
import { useInstallmentsCommonTemplate } from "./useInstallmentsCommonTemplate";
import { ApiMercadoPagoInstallmentOption } from "../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { useAppContext } from "../../managers/useAppContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";

export interface Props {
    isActive: boolean;
    selectedInstallmentsNumber: number;
    getApiResponse: () => Promise<ApiMercadoPagoInstallmentOption[]>;
    handleInstallmentChange: (selectedInstallmentNumber: number, newTotal: number) => void;
    handleInstallmentInfoChange: (merchantAccountId: string, paymentMethodOptionId: string) => void;
    tealiumLogMercadoPagoInstallmentChange: (value: number) => void;
}

export const useInstallmentsMercadoPagoOld = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const { isInstallmentsDisabledForFlow } = paymentHelper();

    const [cardData] = useReduxState("payment.cardData");

    const [dropdownOptions, setDropdownOptions] = useState<SelectOption[]>(undefined);

    const installmentsCommonTemplate = useInstallmentsCommonTemplate({
        options: dropdownOptions,
        selectedInstallmentsNumber: props.selectedInstallmentsNumber,
        handleNumberOfInstallmentsChange: (e, numberOfInstallments) =>
            handleNumberOfInstallmentsChange(e, numberOfInstallments),
    });

    // HELPERS

    const handleApiResponse = (apiOptions: ApiMercadoPagoInstallmentOption[]) => {
        if (!(apiOptions?.length > 0)) {
            disableOptionsDropdown();
            return;
        }

        // DEVNOTE It's an array and it's always the first element (if present)
        const apiOption = apiOptions[0];
        const merchantAccountID = apiOption?.MerchantAccountId;
        const paymentMethodOptionID = apiOption?.Agreements?.find(
            (agreement) => agreement.MerchantAccounts?.length > 0,
        )?.MerchantAccounts.find((merchantAccount) => merchantAccount.PaymentMethodOptionId)?.PaymentMethodOptionId;

        props.handleInstallmentInfoChange(merchantAccountID, paymentMethodOptionID);
        updateOptionsDropdown(apiOption);
    };

    const getApiResponse = async () => {
        // DEVNOTE We cannot interrupt the flow here if installments are disabled, because we need to know
        // the merchantAccountId and paymentMethodOptionId that only the API provides for 1 installment too.
        const apiOptions = await props.getApiResponse();

        if (!apiOptions) {
            disableOptionsDropdown();
            return;
        }

        handleApiResponse(apiOptions);
    };

    const updateOptionsDropdown = (option: ApiMercadoPagoInstallmentOption) => {
        // DEVNOTE We have to interrupt the flow if needed RIGHT HERE, because we need to know
        // the merchantAccountId and paymentMethodOptionId that only the API provides.
        if (isInstallmentsDisabledForFlow(appContext, flowContext)) {
            return;
        }

        try {
            const selectOptions = gatewayGetDropdownTexts(option);
            setDropdownOptions(selectOptions);

            const isSelectedValueAvailableInDropdown =
                props.selectedInstallmentsNumber &&
                selectOptions?.some((option) => option.Value === props.selectedInstallmentsNumber.toString());

            if (!isSelectedValueAvailableInDropdown) {
                handleNumberOfInstallmentsChange(undefined, 1);
            }
        } catch (e) {
            commonDebug.error("Could not parse MercadoPago installments.");
            disableOptionsDropdown();
        }
    };

    const disableOptionsDropdown = () => {
        setDropdownOptions([]);
        handleNumberOfInstallmentsChange(undefined, 1);
        installmentsCommonTemplate.removeMessage();
    };

    const showSelector = () =>
        props.isActive && dropdownOptions?.length > 0 && !isInstallmentsDisabledForFlow(appContext, flowContext);

    // EVENT HANDLERS

    const handleNumberOfInstallmentsChange = async (e?: MouseEvent, numberOfInstallments?: number) => {
        const selectedInstallmentNumber = numberOfInstallments
            ? numberOfInstallments
            : Number((e.target as HTMLSelectElement).value);

        props.handleInstallmentChange(selectedInstallmentNumber, 0);

        props.tealiumLogMercadoPagoInstallmentChange(selectedInstallmentNumber);
    };

    useEffect(() => {
        if (!props.isActive) return;

        if (cardData?.CardNumber && cardData.CardType && cardData.CardValidationStatus === "valid") {
            getApiResponse();
        } else {
            setDropdownOptions([]);
        }
    }, [cardData?.CardNumber, cardData?.CardType, cardData?.CardValidationStatus, props.isActive]);

    // TEMPLATES

    const htmlTemplate = () =>
        showSelector()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                              ${installmentsCommonTemplate.htmlTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

import {
    CHILEAN_CULTURE_CODE,
    DEFAULT_DATE_FORMAT,
    FAKE_DATE_OF_BIRTH,
    FAKE_DOCUMENT_EXPIRATION_DATE,
    FAKE_NAVITAIRE_DATE_CHUNK,
    FARE_LOCK_DUMMY_PAX_FIRST_NAME,
    PaxType,
} from "./../shared/commonConstants";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { PassengersPageModel } from "../component-models/passengers/PassengersPageModel";
import { getGuid } from "../shared/common";
import { ApiSpaPassengersViewModel } from "../component-models/spa/ApiSpaPassengersViewModel";
import {
    ApiNewPassenger,
    ApiNewPassengersModel,
    ApiPassengerTravelDocument,
} from "../component-models/passengers/ApiNewPassengersModel";
import { PassengerFormVM } from "../component-models/passengers/PassengerFormVM";
import { UserContext } from "../component-models/app/UserContext";
import { passengerMapperHelpers } from "../component-helpers/passengers/passengerMapperHelpers";
import { AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE } from "../component-models/checkin/TravelDocument";
import { FlowContext } from "../component-models/app/FlowContext";

const getDocumentExpiry = (document: ApiPassengerTravelDocument) =>
    document?.ExpirationDate &&
    !document.ExpirationDate.includes(FAKE_NAVITAIRE_DATE_CHUNK) &&
    !document.ExpirationDate.startsWith(FAKE_DOCUMENT_EXPIRATION_DATE)
        ? dayjs(document.ExpirationDate, DEFAULT_DATE_FORMAT)
        : undefined;

const getDateOfBirth = (pax: ApiNewPassenger) =>
    pax?.DateOfBirth &&
    !pax.DateOfBirth.includes(FAKE_NAVITAIRE_DATE_CHUNK) &&
    !pax.DateOfBirth.startsWith(FAKE_DATE_OF_BIRTH) &&
    !pax.DateOfBirth.startsWith(dayjs().format(DEFAULT_DATE_FORMAT)) &&
    pax.Name.FirstName !== FARE_LOCK_DUMMY_PAX_FIRST_NAME
        ? dayjs(pax.DateOfBirth, DEFAULT_DATE_FORMAT)
        : undefined;

const getFirstDocument = (documents: ApiPassengerTravelDocument[]) => {
    const realDocuments = documents.filter((doc) => doc.DocumentType !== AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE);
    return realDocuments.length > 0 ? realDocuments.at(0) : undefined;
};

const getFrequentFlyerNumber = (documents: ApiPassengerTravelDocument[]) =>
    documents.find((doc) => doc.DocumentType === AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE)?.DocumentNumber ?? "";

const getIsNameReadonly = (data: {
    checkedInPassengerIndices: number[];
    isBancoEstado: boolean;
    isDc: boolean;
    isFarelockRoundTwo: boolean;
    isCheckinClosedOutbound: boolean;
    pax: ApiNewPassenger;
    userContext: UserContext;
}) => {
    // DEVNOTE Pax checked in
    if (data.checkedInPassengerIndices.includes(data.pax.PassengerNumber)) return true;
    // DEVNOTE If check in window is closed
    if (data.isCheckinClosedOutbound) return true;
    // DEVNOTE If not checked in, only first pax can be readonly
    if (data.pax.PassengerNumber !== 0) return false;
    // DEVNOTE Only readonly if we can populate the fields
    if (
        (!data.pax.Name.FirstName || !data.pax.Name.LastName) &&
        (!data.userContext.userFirstName || !data.userContext.userLastName)
    ) {
        return false;
    }

    return data.isDc || data.isBancoEstado || data.isFarelockRoundTwo;
};

const getIsDocumentReadonly = (data: {
    checkedInPassengerIndices: number[];
    firstDocument: ApiPassengerTravelDocument;
    isBancoEstado: boolean;
    isBookingFlow: boolean;
    pax: ApiNewPassenger;
}) => {
    // DEVNOTE Only readonly if we can populate the fields
    if (!data.firstDocument?.DocumentNumber || !data.firstDocument.DocumentType) return false;
    // DEVNOTE If checked in
    if (data.checkedInPassengerIndices.includes(data.pax.PassengerNumber)) return true;

    return data.isBancoEstado && data.pax.PassengerNumber === 0;
};

const getIsDocumentReadonlyForInfant = (data: {
    checkedInPassengerIndices: number[];
    firstDocument: ApiPassengerTravelDocument;
    isCheckinClosedOutbound: boolean;
    isInfantCheckedIn: boolean;
    userContext: UserContext;
    flowContext: FlowContext;
}) => {
    // If in checkin flow
    if (data.flowContext.isCheckinFlow) return false;
    // If is already checked in
    if (data.isInfantCheckedIn) return true;
    // If checkin window is closed
    if (data.isCheckinClosedOutbound) return true;
    // If document data is provided and not in booking or change flow
    if (
        data.firstDocument?.DocumentNumber &&
        data.firstDocument.DocumentType &&
        !data.flowContext.isBookingFlow &&
        !data.flowContext.isChangeFlow
    ) {
        return true;
    }

    return false;
};

const getPhoneData = (pax: ApiNewPassenger, defaultPhonePrefix: string) => {
    const [phonePrefix, phoneNumber] = splitString(pax.Address?.Phone, defaultPhonePrefix);
    const [contactPhonePrefix, contactPhoneNumber] = splitString(pax.ContactPersonInfo?.Phone, defaultPhonePrefix);
    const [officePhonePrefix, officePhoneNumber] = splitString(pax.Address?.OfficePhone, defaultPhonePrefix);
    const [contactOfficePhonePrefix, contactOfficePhoneNumber] = splitString(
        pax.ContactPersonInfo?.OfficePhone,
        defaultPhonePrefix,
    );

    return {
        contactOfficePhoneNumber,
        contactOfficePhonePrefix,
        contactPhoneNumber,
        contactPhonePrefix,
        officePhoneNumber,
        officePhonePrefix,
        phoneNumber,
        phonePrefix,
    };
};

const getName = (pax: ApiNewPassenger, userContext: UserContext) => {
    const prefill =
        userContext.userFirstName &&
        userContext.userLastName &&
        !userContext.peruCompra.isMember &&
        !userContext.peruCompra.isAdmin &&
        pax.PassengerNumber === 0;

    const firstName =
        pax.Name.FirstName !== FARE_LOCK_DUMMY_PAX_FIRST_NAME
            ? pax.Name.FirstName || (prefill ? userContext.userFirstName : "")
            : "";

    const lastName =
        pax.Name.FirstName !== FARE_LOCK_DUMMY_PAX_FIRST_NAME
            ? pax.Name.LastName || (prefill ? userContext.userLastName : "")
            : "";

    return { firstName, lastName };
};

const getFamily = (model: ApiSpaPassengersViewModel) =>
    model.Family
        ? {
              ...model.Family,
              FamilyMembers: model.Family.FamilyMembers?.map((member) => ({ ...member, GUID: getGuid() })) || [],
          }
        : undefined;

const { splitString } = passengerMapperHelpers();

const getDefaultDocumentType = (travelDocument: ApiPassengerTravelDocument, culture: string) =>
    travelDocument?.DocumentType || (culture === CHILEAN_CULTURE_CODE ? "N" : "DNI");

// EXPORTS

export const mapToPassengersPageModel = (model: ApiSpaPassengersViewModel): PassengersPageModel => ({
    arrivalDate: model.ArrivalDate,
    assistanceOptions: model.AssistanceOptions || [],
    checkedInPaxIndexes: model.CheckedInPaxIndexes || [],
    departureDate: model.DepartureDate,
    discounts: model.Discounts,
    family: getFamily(model),
    isInternationalFlight: model.IsInternationalFlight,
    memberFirstName: model.MemberFirstName,
    memberLastName: model.MemberLastName,
    oxyOptions: model.OxyOptions || [],
    passengerIndicesWithACAA: model.PassengerIndicesWithACAA || [],
    useEmployeeDiscount: model.UseEmployeeDiscount,
    wantsDiscount: model.WantsDiscount,
});

export const mapToNonInfants = (data: {
    checkedInPassengerIndices: number[];
    culture: string;
    defaultCountry?: string;
    defaultPhonePrefix: string;
    isBancoEstado: boolean;
    isBookingFlow: boolean;
    isDc: boolean;
    isFarelockRoundTwo: boolean;
    isInternationalFlightToColombia: boolean;
    isCheckinClosedOutbound: boolean;
    passengersModel: ApiNewPassengersModel;
    filterType: PaxType;
    userContext: UserContext;
}): PassengerFormVM[] =>
    data.passengersModel.Passengers.filter((pax) => pax.Type === data.filterType).map((pax): PassengerFormVM => {
        const phoneData = getPhoneData(pax, data.defaultPhonePrefix);
        const name = getName(pax, data.userContext);

        const ownTravelDocuments =
            pax.TravelDocuments.filter((doc: ApiPassengerTravelDocument) => doc.DocSuffix === " ") || [];
        const firstDocument = getFirstDocument(ownTravelDocuments);
        const documentExpiry = getDocumentExpiry(firstDocument);
        const frequentFlyerNumber = getFrequentFlyerNumber(ownTravelDocuments);

        const dateOfBirth = getDateOfBirth(pax);

        const [contactFirstName, contactLastName] = splitString(pax.ContactPersonInfo?.CompanyName);

        const isNameReadonly = getIsNameReadonly({ ...data, pax });

        const isDocumentReadonly = getIsDocumentReadonly({ ...data, firstDocument, pax });

        return {
            addressAddressLine1: pax.Address?.AddressLine1 || "",
            addressCity: pax.Address?.City || "",
            addressCountry: pax.Address?.CountryCode || "",
            addressPostalCode: pax.Address?.PostalCode || "",
            attachedPassengerNumber: undefined,
            contactAddressAddressLine1: pax.ContactPersonInfo?.AddressLine1 || "",
            contactAddressCity: pax.ContactPersonInfo?.City || "",
            contactAddressPostalCode: pax.ContactPersonInfo?.PostalCode || "",
            contactEmail: pax.ContactPersonInfo?.EmailAddress || "",
            contactFirstName,
            contactLastName,
            contactOfficePhoneNumber: phoneData.contactOfficePhoneNumber,
            contactOfficePhonePrefix: phoneData.contactOfficePhonePrefix,
            contactPhoneNumber: phoneData.contactPhoneNumber,
            contactPhonePrefix: phoneData.contactPhonePrefix,
            country: pax.Nationality || data.defaultCountry || "",
            dateOfBirth,
            departureStationCode: pax.Address?.StationCode || pax.ContactPersonInfo?.StationCode || "",
            documentExpiry,
            documentIssuer: firstDocument?.IssuedBy || "",
            documentNumber: firstDocument?.DocumentNumber || "",
            documentType: getDefaultDocumentType(firstDocument, data.culture),
            email: pax.Address?.EmailAddress || "",
            firstName: name.firstName,
            frequentFlyerNumber,
            gender: pax.Gender,
            isDocumentReadonly,
            isFrequentFlyerNumberReadonly: frequentFlyerNumber && !data.isBookingFlow,
            isNameReadonly,
            isPassengerCheckedIn: data.checkedInPassengerIndices.includes(pax.PassengerNumber),
            lastName: name.lastName,
            officePhoneNumber: phoneData.officePhoneNumber,
            officePhonePrefix: phoneData.officePhonePrefix,
            passengerNumber: pax.PassengerNumber,
            phoneNumber: phoneData.phoneNumber,
            phonePrefix: phoneData.phonePrefix,
            type: data.filterType,
            uniqueIndex: pax.PassengerNumber,
        };
    });

export const mapToInfants = (data: {
    checkedInPassengerIndices: number[];
    culture: string;
    defaultCountry?: string;
    infantsCount: number;
    isCheckinClosedOutbound: boolean;
    passengersModel: ApiNewPassengersModel;
    userContext: UserContext;
    flowContext: FlowContext;
}): PassengerFormVM[] =>
    (data.passengersModel.Infants.length ? data.passengersModel.Infants : [...Array(data.infantsCount)]).map(
        (pax, index): PassengerFormVM => {
            const dateOfBirth = getDateOfBirth(pax);

            const ownTravelDocuments =
                pax.TravelDocuments.filter((doc: ApiPassengerTravelDocument) => doc.DocSuffix === "I") || [];
            const firstDocument = getFirstDocument(ownTravelDocuments);
            const documentExpiry = getDocumentExpiry(firstDocument);
            const isInfantCheckedIn = data.checkedInPassengerIndices.includes(pax?.AttachedPassengerNumber);

            const isNameReadonly =
                data.checkedInPassengerIndices.includes(pax?.AttachedPassengerNumber) || data.isCheckinClosedOutbound;

            const isDocumentReadonly = getIsDocumentReadonlyForInfant({ ...data, firstDocument, isInfantCheckedIn });

            const firstName = pax?.Name.FirstName !== FARE_LOCK_DUMMY_PAX_FIRST_NAME ? pax?.Name.FirstName || "" : "";

            const lastName = pax?.Name.FirstName !== FARE_LOCK_DUMMY_PAX_FIRST_NAME ? pax?.Name.LastName || "" : "";

            return {
                addressAddressLine1: "",
                addressCity: "",
                addressCountry: "",
                addressPostalCode: "",
                attachedPassengerNumber: pax?.AttachedPassengerNumber || index,
                contactAddressAddressLine1: "",
                contactAddressCity: "",
                contactAddressPostalCode: "",
                contactEmail: "",
                contactFirstName: "",
                contactLastName: "",
                contactOfficePhoneNumber: "",
                contactOfficePhonePrefix: "",
                contactPhoneNumber: "",
                contactPhonePrefix: "",
                country: pax?.Nationality || data.defaultCountry || "",
                dateOfBirth,
                departureStationCode: "",
                documentExpiry,
                documentIssuer: firstDocument?.IssuedBy || "",
                documentNumber: firstDocument?.DocumentNumber || "",
                documentType: getDefaultDocumentType(firstDocument, data.culture),
                email: "",
                firstName,
                frequentFlyerNumber: undefined,
                gender: pax?.Gender,
                isDocumentReadonly,
                isFrequentFlyerNumberReadonly: false,
                isNameReadonly,
                isPassengerCheckedIn: isInfantCheckedIn,
                lastName,
                officePhoneNumber: "",
                officePhonePrefix: "",
                passengerNumber: undefined,
                phoneNumber: "",
                phonePrefix: "",
                type: "INF",
                uniqueIndex: data.passengersModel.Passengers.length + index,
            };
        },
    );

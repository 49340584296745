import { html } from "lit-html";
import { getStringForCulture } from "../../../component-helpers/stringHelper";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { SummaryWidgetModel } from "../../../component-models/CUG2b/SummaryWidgets";
import { classMap } from "lit-html/directives/class-map";

export const name = "ac-summary-page-widget";

export interface Properties {
    model: SummaryWidgetModel;
    customClass: string | string[];
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        model: host.model,
        customClass: host.customClass,
    };

    // HELPERS

    const desktopImage = () => getStringForCulture(props.model.Images.DesktopImageUrl, cug2AppContext.Culture);

    const tabletImage = () => getStringForCulture(props.model.Images.TabletImageUrl, cug2AppContext.Culture);

    const mobileImage = () => getStringForCulture(props.model.Images.MobileImageUrl, cug2AppContext.Culture);

    // EVENT HANDLERS

    const handleClick = () => (window.location.href = getStringForCulture(props.model.LinkUrl, cug2AppContext.Culture));

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    // TEMPLATES

    const desktopClassMap = () => {
        const classes = Array.isArray(props.customClass) ? props.customClass : [props.customClass];

        const retVal = classes
            .filter((e) => e)
            .reduce(
                (previousValue, currentValue) => {
                    previousValue[currentValue] = true;
                    return previousValue;
                },
                { "cug-summary-widget": true, "hidden-sm-down": true } as any,
            );

        return classMap(retVal);
    };

    const tabletSizeClassMap = () => {
        const classes = Array.isArray(props.customClass) ? props.customClass : [props.customClass];

        const retVal = classes
            .filter((e) => e)
            .reduce(
                (previousValue, currentValue) => {
                    previousValue[currentValue] = true;
                    return previousValue;
                },
                { "cug-summary-widget": true, "hidden-xs": true, "hidden-md-up": true } as any,
            );

        return classMap(retVal);
    };

    const mobileSizeClassMap = () => {
        const classes = Array.isArray(props.customClass) ? props.customClass : [props.customClass];

        const retVal = classes
            .filter((e) => e)
            .reduce(
                (previousValue, currentValue) => {
                    previousValue[currentValue] = true;
                    return previousValue;
                },
                { "cug-summary-widget": true, "hidden-sm-up": true } as any,
            );

        return classMap(retVal);
    };

    return props.model.Enabled
        ? html`
              <div
                  class=${desktopClassMap()}
                  style="background-image: url('${desktopImage().trim()}')"
                  @click=${handleClick}
              ></div>
              <div
                  class=${tabletSizeClassMap()}
                  style="background-image: url('${tabletImage().trim()}')"
                  @click=${handleClick}
              ></div>
              <div
                  class=${mobileSizeClassMap()}
                  style="background-image: url('${mobileImage().trim()}')"
                  @click=${handleClick}
              ></div>
          `
        : html``;
};

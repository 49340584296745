import { Content, ContentImage } from "pdfmake/interfaces";
import { ApiBoardingPassViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";
import { useCheckinTealiumManager } from "../../../managers/Tealium/useCheckinTealiumManager";
import { BoardingPassImage } from "./useBoardingPassImageCreator";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface BoardingPassImageDto {
    item: BoardingPassImage;
    mobileItem: BoardingPassImage;
    model: ApiBoardingPassViewModel;
    pnr: string;
}

export const useBoardingPassObtainer = () => {
    const tealiumManager = useCheckinTealiumManager();

    const downloadPdf = (content: Content[], fileName: string) =>
        pdfMake.createPdf({ content, pageOrientation: "portrait", pageSize: "A4" }).download(fileName);

    const getImageContent = (model: BoardingPassImageDto, index = 0): ContentImage => ({
        image: model.item.image,
        width: 510,
        margin: [0, 0, 0, index % 2 === 0 ? 40 : 0],
    });

    const downloadAll = (data: BoardingPassImageDto[]) => {
        data.forEach((model) =>
            tealiumManager.logBoardingCardDownload(model.model.Passenger.PaxIndex, model.model.Passenger.PaxType),
        );

        downloadPdf(
            data.reduce((aggr, model, index) => aggr.concat([getImageContent(model, index)]), new Array<Content>()),
            `${data[0].pnr}_${data[0].model.Itinerary.Departure.StationCode}_${data[data.length - 1].model.Itinerary.Arrival.StationCode}_F_ALL.pdf`,
        );
    };

    const downloadFull = (data: BoardingPassImageDto) => {
        tealiumManager.logBoardingCardDownload(data.model.Passenger.PaxIndex, data.model.Passenger.PaxType);

        downloadPdf(
            [getImageContent(data)],
            `${data.pnr}_${data.item.paxName}_${data.model.Passenger.PaxIndex !== undefined ? data.model.Passenger.PaxIndex : "INF"}_${data.model.Details.Sequence}_F.pdf`,
        );
    };

    const downloadMobile = (data: BoardingPassImageDto) => {
        const link = document.createElement("a");
        link.href = data.mobileItem.image;
        link.download = `${data.pnr}_${data.item.paxName}_${data.model.Passenger.PaxIndex}_${data.model.Details.Sequence}_M.png`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        tealiumManager.logBoardingCardDownload(data.model.Passenger.PaxIndex, data.model.Passenger.PaxType);
    };

    const printFull = (data: BoardingPassImageDto) => {
        // https://stackoverflow.com/questions/2255291/print-the-contents-of-a-div
        const mywindow = window.open("", "PRINT", "height=600,width=800");

        mywindow.document.write("<html><head><title>" + document.title + "</title>");
        mywindow.document.write("<style>.to-print {opacity: 0;} @media only print {.to-print {opacity: 1;}}</style>");
        mywindow.document.write("</head><body class='to-print'>");
        mywindow.document.write("<img width='800' src='" + data.item.image + "' />");
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10

        mywindow.onload = () => {
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            window.setTimeout(() => mywindow.close(), 1);
        };
    };

    return {
        downloadAll,
        downloadFull,
        downloadMobile,
        printFull,
    };
};

import i18next from "i18next";
import { PaymentType } from "../../component-models/PaymentResult";
import { ContactPassenger } from "../../component-models/itinerary/ApiItineraryViewModelV2";

export const isPaxFilled = (pax: ContactPassenger) => {
    const address = pax.PaxAddress;

    if (!address) {
        return false;
    }

    return [
        address.Address,
        address.City,
        address.Country,
        address.Email,
        address.PhoneNumber,
        address.PhonePrefix,
    ].every((value) => value);
};

export const isDataFullyProvided = (paxes: ContactPassenger[]) => paxes.every(isPaxFilled);

export const getTransactionTitleText = (paymentType: PaymentType) => {
    switch (paymentType) {
        case "Cash":
            return i18next.t("Pago con efectivo");
        case "Card":
            return i18next.t("Pago con tarjeta");
        case "CreditFile":
            return i18next.t("Pago con beneficio de Lanzamiento");
        case "CreditFileBancoEstado":
            return i18next.t("Pago con beneficio de BancoEstado");
        case "CardBancoEstado":
            return i18next.t("Pago con tarjeta BancoEstado");
        case "Voucher":
            return i18next.t("Voucher");
        case "AgencyDebit":
            return i18next.t("Se ha realizado el pago descontando el saldo de su cuenta en Jetsmart.");
        case "BankTransfer":
            return i18next.t("Pago con transferencia bancaria");
    }
};

import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { classMap } from "lit-html/directives/class-map";
import { useEffect, useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { INBOUND } from "../../../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useSeatTooltip } from "./useSeatTooltip";
import { useWindowSize } from "../../../../shared/customHooks/useWindowSize";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";

export const name = "ac-seat";

const leftSeats = ["a", "d"];
const rightSeats = ["c", "f"];

export interface Props {
    apiSeat: ExtendedApiSeat;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    handleSeatClick: (seat: ExtendedApiSeat) => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        apiSeat: host.apiSeat,
        context: host.context,
        currentSeats: host.currentSeats,
        seatmapState: host.seatmapState,
        handleSeatClick: host.handleSeatClick,
        updateState: host.updateState,
    };

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const originalPrice = () =>
        SeatmapHelper.findOriginalPriceForPaxForSegment(
            props.currentSeats,
            props.seatmapState.CurrentPassenger
                ? props.seatmapState.CurrentPassenger.PassengerNumber
                : props.context.Model.Passengers[0].PassengerNumber,
            props.seatmapState.CurrentJourney.JourneyIndex,
            props.seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
        );

    const getPriceDifference = () =>
        originalPrice() !== undefined ? props.apiSeat.Price - originalPrice() : props.apiSeat.Price;

    const getFormattedPriceDifference = () => {
        const priceDifference = getPriceDifference();

        return priceDifference > 0 ? formatNumber({ amount: priceDifference }) : window.seatmapResources.freeOfCharge;
    };

    const [formattedPriceDifference, setFormattedPriceDifference] = useState<string>(getFormattedPriceDifference());

    const windowSize = useWindowSize();

    const priceForBancoEstado = () =>
        flowContext.isPostBookingFlow ? getFormattedPriceDifference() : props.apiSeat.FormattedPrice;

    const getAvailableFreeSeats = () => {
        if (
            !(userContext.bancoEstado.remainingFreeSeats + props.context.Model.FreeSeatsInBooking) ||
            !props.currentSeats
        ) {
            return 0;
        }

        return (
            userContext.bancoEstado.remainingFreeSeats +
            props.context.Model.FreeSeatsInBooking -
            props.currentSeats.filter((s) => s.IsFree).length
        );
    };

    const isCurrentSeatFree = () => {
        const seat = props.currentSeats?.find(
            (s) =>
                s.PassengerNumber === props.seatmapState.CurrentPassenger?.PassengerNumber &&
                s.JourneyIndex === props.seatmapState.CurrentJourney.JourneyIndex &&
                s.SegmentIndex === props.seatmapState.CurrentSegment.SegmentIndex,
        );

        return seat && seat.Column && seat.IsFree;
    };

    const isFreeForBancoEstado = () =>
        (props.apiSeat.IsBancoEstado || props.apiSeat.IsBancoEstadoSecondary) &&
        (getAvailableFreeSeats() > 0 || isCurrentSeatFree());

    const isDiscountedForBancoEstado = () =>
        props.apiSeat.IsBancoEstado && [5, 6].includes(userContext.bancoEstado.category);

    const isMobile = () => windowSize?.width < 1200;

    const seatTooltip = useSeatTooltip({
        designator: props.apiSeat.Row + props.apiSeat.Column,
        formattedPrice: priceForBancoEstado(),
        isDiscounted: isDiscountedForBancoEstado(),
        isFree: isFreeForBancoEstado(),
        isMobile: isMobile(),
        handleSeatClick: isMobile() ? (e) => handleSeatClick(e) : null,
    });

    // HELPERS

    const seatDataTestId = () =>
        getTestId(T.SEATMAP.SEAT, {
            c: props.apiSeat.Row + props.apiSeat.Column,
            j: props.apiSeat.JourneyIndex,
            s: props.apiSeat.SegmentIndex,
            m: isMobile(),
        });

    const letterDataTestId = () => getTestId(T.SEATMAP.SEAT_LETTER, { m: isMobile() });

    const canSeatBeClicked = () => isAvailable() && !isSelected && !props.context.Model.IsReadOnly;

    const canCurrentPassengerClickSeat = () =>
        props.seatmapState.CurrentJourney.JourneyIndex === INBOUND || !bookingContext.isCheckinClosedOutbound;

    const isAvailableForCurrentPax = () => {
        const paxIndex = props.seatmapState.CurrentPassenger.PassengerNumber;

        return props.apiSeat.IsAvailableByPax[paxIndex];
    };

    const hasInfant = (pax: ApiSeatmapPassenger) => pax.HasInfant || pax.PassengerNumber < bookingContext.infantsCount;

    const isAvailableForInfant = useMemo(() => {
        if (!hasInfant(props.seatmapState.CurrentPassenger)) return true;

        const otherPaxInRowWithInfant = props.context.Model.Passengers.some(
            (pax) =>
                pax.PassengerNumber !== props.seatmapState.CurrentPassenger.PassengerNumber &&
                hasInfant(pax) &&
                props.currentSeats.some(
                    (seat) =>
                        seat.PassengerNumber === pax.PassengerNumber &&
                        seat.Row === props.apiSeat.Row &&
                        seat.JourneyIndex === props.seatmapState.CurrentJourney.JourneyIndex,
                ),
        );

        return !otherPaxInRowWithInfant;
    }, [
        props.seatmapState.CurrentPassenger,
        props.currentSeats,
        props.context.Model.Passengers,
        bookingContext.infantsCount,
    ]);

    const showNewTooltip = () =>
        !isSelected &&
        [5, 6].includes(userContext.bancoEstado.category) &&
        (isFreeForBancoEstado() || isDiscountedForBancoEstado()) &&
        !props.seatmapState.CurrentJourney.AreAllSeatsInBundle;

    const showOldTooltip = () => !isSelected && !showNewTooltip();

    const isSelected = useMemo(
        () =>
            props.currentSeats?.some(
                (seat) =>
                    props.apiSeat.JourneyIndex === seat.JourneyIndex &&
                    props.apiSeat.SegmentIndex === seat.SegmentIndex &&
                    seat.Row === props.apiSeat.Row &&
                    seat.Column === props.apiSeat.Column &&
                    seat.PassengerNumber >= 0,
            ),
        [props.currentSeats, props.apiSeat],
    );

    const isAvailable = () =>
        !props.seatmapState.IsSelectionBlocked &&
        (!props.apiSeat ||
            !props.seatmapState.CurrentPassenger ||
            (!props.context.Model.IsReadOnly && !isSelected && isAvailableForCurrentPax() && isAvailableForInfant));

    const isLeft = () => leftSeats.indexOf(props.apiSeat.Column.toLowerCase()) > -1;

    const isRight = () => rightSeats.indexOf(props.apiSeat.Column.toLowerCase()) > -1;

    const isMiddle = () => !isLeft() && !isRight();

    const showInfo = () =>
        props.seatmapState?.CurrentSeatMobileTooltip?.JourneyIndex === props.apiSeat.JourneyIndex &&
        props.seatmapState?.CurrentSeatMobileTooltip?.SegmentIndex === props.apiSeat.SegmentIndex &&
        props.seatmapState?.CurrentSeatMobileTooltip?.Row === props.apiSeat.Row &&
        props.seatmapState?.CurrentSeatMobileTooltip?.Column === props.apiSeat.Column;

    // EVENT LISTENERS

    const handleSeatClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!props.context.Model.IsReadOnly) {
            if (canSeatBeClicked() && canCurrentPassengerClickSeat()) {
                props.handleSeatClick(props.apiSeat);
            }
        }
    };

    const toggleMobileTooltip = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isAvailable()) {
            props.updateState({ SeatDesignator: `${props.apiSeat.Row}${props.apiSeat.Column}` });
        }
    };

    useEffect(
        () => setFormattedPriceDifference(getFormattedPriceDifference()),
        [
            props.seatmapState.CurrentPassenger?.PassengerNumber,
            props.seatmapState.CurrentJourney?.JourneyIndex,
            props.seatmapState.CurrentSegment?.SegmentIndex,
        ],
    );

    // TEMPLATES

    const priceTemplate = () => {
        if (props.context.Model.IsReadOnly) {
            return "";
        }

        return flowContext.isPostBookingFlow
            ? html`
                  <span
                      data-test-id=${getTestId(T.SEATMAP.SEAT_TOOLTIP_PRICE_DIFFERENCE, {
                          c: props.apiSeat.Row + props.apiSeat.Column,
                          j: props.apiSeat.JourneyIndex,
                          s: props.apiSeat.SegmentIndex,
                      })}
                  >
                      ${formattedPriceDifference}
                  </span>
              `
            : html`
                  <span
                      data-test-value=${props.apiSeat.FormattedPrice}
                      data-test-id=${getTestId(T.SEATMAP.SEAT_TOOLTIP_PRICE, {
                          c: props.apiSeat.Row + props.apiSeat.Column,
                          j: props.apiSeat.JourneyIndex,
                          s: props.apiSeat.SegmentIndex,
                      })}
                  >
                      - ${props.apiSeat.FormattedPrice}</span
                  >
              `;
    };

    const seatLetterTemplate = () => {
        const tempClassMap = classMap({
            "seat-letter": true,
        });

        return html` <span class=${tempClassMap} data-test-id=${letterDataTestId()}>${props.apiSeat.Column}</span> `;
    };

    const windowlessLabelTemplate = () =>
        props.apiSeat.IsWindowless
            ? html`
                  <span
                      data-test-id=${getTestId(T.SEATMAP.SEAT_WINDOWLESS_WARNING, {
                          c: props.apiSeat.Row + props.apiSeat.Column,
                          j: props.apiSeat.JourneyIndex,
                          s: props.apiSeat.SegmentIndex,
                      })}
                  >
                      ${props.apiSeat.CategoryName}>${window.seatmapResources.windowlessLabel}
                  </span>
              `
            : "";

    const categoryLabelTemplate = () =>
        !props.apiSeat.IsBancoEstado || !props.context.Model.IsBancoEstadoUserEligible
            ? html`
                  <span
                      class="category-name"
                      data-test-id=${getTestId(T.SEATMAP.SEAT_TOOLTIP_CATEGORY, {
                          c: props.apiSeat.Row + props.apiSeat.Column,
                          j: props.apiSeat.JourneyIndex,
                          s: props.apiSeat.SegmentIndex,
                      })}
                  >
                      ${props.apiSeat.CategoryName}
                  </span>
              `
            : "";

    const bancoEstadoTooltipTemplate = () =>
        props.apiSeat.IsBancoEstado && props.context.Model.IsBancoEstadoUserEligible
            ? html` <span class="category-name">${window.seatmapResources.beSeatTooltip}</span> `
            : "";

    const mobileSelectSeatButtonTemplate = () => html`
        <button
            class="mobile-seat-btn"
            data-test-id=${getTestId(T.SEATMAP.SEAT_TOOLTIP_SELECT_BUTTON_MOBILE, {
                c: props.apiSeat.Row + props.apiSeat.Column,
                j: props.apiSeat.JourneyIndex,
                s: props.apiSeat.SegmentIndex,
            })}
            @click=${!props.context.Model.IsReadOnly && handleSeatClick}
        >
            ${window.seatmapResources.okayLabel}
        </button>
    `;

    const mobileOnlyBreakTemplate = () => (isMobile() ? html` <br /> ` : "");

    const mobileOnlyTooltipButtonTemplate = () =>
        isMobile()
            ? html`
                  <br />
                  ${mobileSelectSeatButtonTemplate()}
              `
            : "";

    const oldTooltipTemplate = () => {
        const tempClassMap = classMap({
            "seat-tooltip": true,
            "mobile-seat-info": isMobile(),
            "wide": props.apiSeat.IsWindowless,
        });

        const dataTestId = getTestId(T.SEATMAP.SEAT_TOOLTIP, {
            c: props.apiSeat.Row + props.apiSeat.Column,
            j: props.apiSeat.JourneyIndex,
            s: props.apiSeat.SegmentIndex,
            m: isMobile(),
        });

        return showOldTooltip() && (showInfo || !isMobile()) && !props.context.Model.IsReadOnly
            ? html`
                  <span class=${tempClassMap} data-test-id=${dataTestId}>
                      ${props.apiSeat.Row + props.apiSeat.Column} ${priceTemplate()}
                      <br />
                      ${bancoEstadoTooltipTemplate()} ${categoryLabelTemplate()} ${mobileOnlyBreakTemplate()}
                      ${windowlessLabelTemplate()} ${mobileOnlyTooltipButtonTemplate()}
                  </span>
              `
            : "";
    };

    const tooltipTemplate = () => (showNewTooltip() && (showInfo() || !isMobile()) ? seatTooltip.htmlTemplate() : "");

    const tempClassMap = classMap({
        "cf-seat": true,
        "banco-estado-color": props.context.Model.IsBancoEstadoUserEligible && props.apiSeat.IsBancoEstado,
        "is-banco-estado-category-56": [5, 6].includes(userContext.bancoEstado.category),
        "category-1": props.apiSeat.CategoryNumber === 1,
        "category-2": props.apiSeat.CategoryNumber === 2,
        "category-3": props.apiSeat.CategoryNumber === 3,
        "category-4": props.apiSeat.CategoryNumber === 4,
        "category-5": props.apiSeat.CategoryNumber === 5,
        "desktop": !isMobile(),
        "middle": isMiddle(),
        "mobile": isMobile(),
        "open": isMobile() && showInfo(),
        "s-left": isLeft(),
        "s-right": isRight(),
        "selected": isSelected,
        "unavailable": !isAvailable() && !isSelected,
        "xl": props.apiSeat.HasExtraLegroom,
    });

    return html`
        <div
            class=${tempClassMap}
            data-test-designator=${props.apiSeat.Row + props.apiSeat.Column}
            data-test-id=${seatDataTestId()}
            data-test-value=${props.apiSeat.Price}
            @click=${isMobile() ? toggleMobileTooltip : handleSeatClick}
        >
            ${seatLetterTemplate()} ${oldTooltipTemplate()} ${tooltipTemplate()}
        </div>
    `;
};

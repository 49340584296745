import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../shared/useModal";
import { ApiPaymentPageViewModel } from "../../component-models/payment/ApiPaymentPageViewModel";

export interface Props {
    model: ApiPaymentPageViewModel;
    onAccept: () => void;
    onCancel: () => void;
}

export const useTodosumaModal12347 = (props: Props) => {
    const handleSubmit = () => {
        modal.close();
        props.onAccept();
    };

    const htmlTemplate = () => html`
        <div class="payment-modal-title">
            <img class="be-modal-img" src="/Images/BancoEstado/todosuma-puntos-logo-v2.svg" />
        </div>
        <div class="be-modal-border-box">
            <div class="be-modal-content-box">
                <div class="row">
                    <div class="col-xs-1">
                        <p class="text-center">${i18next.t("BE-TodosumaModalInfo-1")}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-1">
                        <div class="be-modal-link">
                            ${i18next.t("BE-TodosumaLink-1")}
                            <a href=${props.model.BancoEstadoTermsUrl} target="_blank">
                                ${i18next.t("BE-TodosumaLink-2")} </a
                            >.
                        </div>
                    </div>
                </div>
                <div class="be-modal-button-container">
                    <button @click=${handleSubmit} class="rounded-primary-btn">
                        ${i18next.t("BE-TodosumaContinue")}
                    </button>
                </div>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { buttonClassNames: "payment-modal-close", isClosable: true, onClose: props.onCancel },
        content: { classNames: "payment-modal-content todosuma-modal", template: htmlTemplate },
        overlay: { classNames: "payment-modal" },
    });

    return modal;
};

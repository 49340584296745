import { ROUTES } from "./../../shared/apiRoutes";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { LoginType } from "../../shared/commonConstants";
import { LoginVM } from "./login-model";
import { ValidatorPartialMethods } from "./login-page";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";

export const name = "ac-customer-login";

export interface Properties {
    loginType: LoginType;
    vm: LoginVM;
    showBancoEstado: boolean;
    showPeruCompraTab: boolean;
    validatorPartialMethods: ValidatorPartialMethods;
    setLoginType: (state: LoginType) => void;
    setVm: (vm: LoginVM) => void;
    handleSubmit: (e: MouseEvent) => Promise<void>;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        loginType: host.loginType,
        vm: host.vm,
        showBancoEstado: host.showBancoEstado,
        showPeruCompraTab: host.showPeruCompraTab,
        validatorPartialMethods: host.validatorPartialMethods,
        setLoginType: host.setLoginType,
        setVm: host.setVm,
        handleSubmit: host.handleSubmit,
    };

    // HELPERS

    // EVENT LISTENERS

    const handleRegister = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        window.location.href = ROUTES.Register;
    };

    // COMPONENT

    const formErrors = useErrorMessage({ errorMessage: props.validatorPartialMethods.getFormMessages() });

    // TEMPLATES

    const loginTypeSelectorTemplate = () => html`
        <ac-login-type-selector
            .showBancoEstado=${props.showBancoEstado}
            .showCustomerAndAgencyTabs=${true}
            .showPeruCompraTab=${props.showPeruCompraTab}
            .loginType=${props.loginType}
            .setLoginType=${props.setLoginType}
        ></ac-login-type-selector>
    `;

    const nonAgencyBannerTemplate = () => html`
        <div class="col-xs-1 col-md-1-2">
            <div>
                <header>
                    <div class="title full-width-title">
                        <h2 class="main-title" data-test-id=${T.CUSTOMER_LOGIN.TITLE}>${i18next.t("V2-LoginTitle")}</h2>
                    </div>
                </header>
                <div class="login-body" data-test-id=${T.CUSTOMER_LOGIN.SUBTITLE}>${i18next.t("V2-LoginBody")}</div>
            </div>
            <ul class="login-member-list" data-test-id=${T.CUSTOMER_LOGIN.MEMBER_LIST}>
                <li>${i18next.t("Login-Benefit1")}</li>
                <li>${i18next.t("Login-Benefit2")}</li>
                <li>${i18next.t("Login-Benefit3")}</li>
            </ul>
        </div>
    `;

    const nonAgencyHeaderTemplate = () => html`
        <header class="login-image-container">
            <img src="/Images/Header/jetsmart-logo-colored.svg" />
        </header>
    `;

    const customerFormTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${props.validatorPartialMethods.getMessage("username")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("username")}
                        .label=${i18next.t("Header-EmailAddressPlaceholder")}
                        .autoComplete=${"cc-exp"}
                        .testId=${T.CUSTOMER_LOGIN.EMAIL}
                        .value=${props.vm.username}
                        .onInput=${(value: string) => props.setVm({ ...props.vm, username: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${props.validatorPartialMethods.getMessage("password")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("password")}
                        .type=${"password"}
                        .label=${i18next.t("Header-PasswordLabel")}
                        .autoComplete=${"new-password"}
                        .testId=${T.CUSTOMER_LOGIN.PASSWORD}
                        .value=${props.vm.password}
                        .onInput=${(value: string) => props.setVm({ ...props.vm, password: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const forgotPasswordTemplate = () => html`
        <a
            href="/V2/ForgotPassword"
            class="mt-2 text-center text-lg text-brand-secondary sm:text-medium md:text-lg"
            data-test-id=${T.CUSTOMER_LOGIN.FORGOT_PASSWORD_BUTTON}
            >${i18next.t("Header-ForgotPasswordButton")}</a
        >
    `;

    const submitButtonTemplate = () => html`
        <button @click=${props.handleSubmit} class="rounded-primary-btn" data-test-id=${T.CUSTOMER_LOGIN.SUBMIT_BUTTON}>
            ${i18next.t("Application-Login")}
        </button>
    `;

    const customerRegisterButtonTemplate = () => html`
        <div>
            <header>
                <div class="title full-width-title move-down">
                    <h2 class="main-title">${i18next.t("Login-SignUpTodayTitle")}</h2>
                </div>
            </header>
            <div class="well group">
                <p>${i18next.t("Login-SignUpTodayBody")}</p>
                <div class="mt-[20px] flex w-full justify-center sm:justify-end">
                    <div class="flex w-3/4 flex-col justify-around sm:w-[180px]">
                        <button
                            @click=${handleRegister}
                            class="rounded-primary-btn"
                            data-test-id=${T.CUSTOMER_LOGIN.REGISTER_BUTTON}
                        >
                            ${i18next.t("Login-RegisterBtn")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;

    const nonAgencyFormTemplate = () => html`
        <div class="col-xs-1 col-md-1-2">
            ${nonAgencyHeaderTemplate()} ${loginTypeSelectorTemplate()} ${customerFormTemplate()}
            ${formErrors.htmlTemplate()}

            <div class="mt-[20px] flex w-full justify-center sm:justify-end">
                <div class="flex w-3/4 flex-col justify-around sm:w-[180px]">
                    ${submitButtonTemplate()}${forgotPasswordTemplate()}
                </div>
            </div>
            ${customerRegisterButtonTemplate()}
        </div>
    `;

    return html`
        <div id="mainContentWrapper" class="login-page">
            <section class="booking-wrapper ts-error-parent ts-error-container no-breadcrumb ">
                <div class="row">${nonAgencyBannerTemplate()} ${nonAgencyFormTemplate()}</div>
            </section>
        </div>
    `;
};

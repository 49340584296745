import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { useAppContext } from "../../../managers/useAppContext";
import {
    BRASILIAN_CULTURE_CODE,
    MAX_PAX_IN_STANDARD_DC_MEMBERSHIP,
    USA_CULTURE_CODE,
} from "../../../shared/commonConstants";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { TestIdDictionary as T, getTestId } from "../../../testing-helpers/TestIdHelper";
import { useBenefitsTemplate } from "./useBenefitsTemplate";
import { useDcTermsTemplate } from "./useDcTermsTemplate";
import { useNoDiscountUrl } from "./useNoDiscountUrl";
import { useFlightTealiumManager } from "../../../managers/Tealium/useFlightTealiumManager";
import { getRenderString } from "../../../shared/common";
import { useDiscountClubBannerModal } from "./useDiscountClubBannerModal";
import { SelectedDcMembershipType } from "./useDiscountClubBanner";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export interface GetMembershipBannerProps {
    context: FlightPageContext;
    onLogin: () => void;
}

export const useGetMembershipBanner = (props: GetMembershipBannerProps) => {
    const appContext = useAppContext();
    const tealiumManager = useFlightTealiumManager();

    const { formatNumber, getCurrencySign } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const [isMobileBenefitListOpen, setIsMobileBenefitListOpen] = useState<boolean>(false);

    const selectedOption = props.context?.bannerState?.selectedOption;
    const vm = props.context?.bannerState?.bannerVM?.flightPageViewModel.DiscountClubViewModel;
    const currencySign = getCurrencySign(props.context?.bannerState?.bannerVM?.currency);
    const flightVm = props.context?.bannerState?.bannerVM?.flightPageViewModel.FlightDataViewModel;

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const terms = useDcTermsTemplate({ context: props.context });
    const benefits = useBenefitsTemplate({ vm: props.context.model.DiscountClubViewModel });
    const noDiscountUrl = useNoDiscountUrl({ context: props.context });

    const smartAmount = useMemo(
        () => props.context?.bannerState.bannerVM?.currentTotal?.UnformattedNormalTotalPrice || 0,
        [props.context?.bannerState.bannerVM?.currentTotal],
    );

    const clubAmount = useMemo(
        () =>
            flightVm?.NumberOfPax <= MAX_PAX_IN_STANDARD_DC_MEMBERSHIP
                ? props.context?.bannerState.bannerVM?.currentTotal?.UnformattedStandardTotalPrice || 0
                : props.context?.bannerState.bannerVM?.currentTotal?.UnformattedGroupTotalPrice || 0,
        [props.context?.bannerState.bannerVM],
    );

    const savedAmount = useMemo(
        () => formatNumber({ amount: smartAmount - clubAmount, leadingSign: true }).replace(" ", ""),
        [smartAmount, clubAmount, appContext.Culture],
    );

    const formattedSmartAmount = useMemo(
        () => formatNumber({ amount: smartAmount, leadingSign: false }),
        [smartAmount, appContext.Culture],
    );

    const formattedClubAmount = useMemo(
        () => formatNumber({ amount: clubAmount, leadingSign: false }),
        [clubAmount, appContext.Culture],
    );

    const standardPrice = useMemo(
        () =>
            userContext?.isLoggedIn && !isCurrentBancoEstadoCategory([0]) && userContext.dc.standardPriceFormatted
                ? userContext.dc.standardPriceFormatted
                : props.context?.bannerState.bannerVM?.standardPrice,
        [userContext, props.context?.bannerState.bannerVM?.standardPrice],
    );

    const groupPrice = useMemo(
        () =>
            userContext?.isLoggedIn && !isCurrentBancoEstadoCategory([0]) && userContext.dc.groupPriceFormatted
                ? userContext.dc.groupPriceFormatted
                : props.context?.bannerState.bannerVM?.groupPrice,
        [userContext, props.context?.bannerState.bannerVM?.groupPrice],
    );

    const acceptanceStatus = useMemo(
        () => (userContext?.isLoggedIn ? (terms.termsAccepted ? "Accepted" : "NotAccepted") : "Unneeded"),
        [userContext?.isLoggedIn, terms.termsAccepted],
    );

    const dcModal = useDiscountClubBannerModal({
        context: props.context,
        membershipType: "Standard",
        yesCallback: props.context.handleRegister,
        noCallback: props.context.resetDcMembership,
        onMembershipTypeChange: (membershipType: SelectedDcMembershipType) => {
            props.context.selectDcOption(membershipType, acceptanceStatus);
        },
    });

    const getTealiumUnformattedPrice = (type: "Standard" | "Group") =>
        Number(
            type === "Standard"
                ? props.context.model.DiscountClubViewModel.StandardPriceUnformatted
                : props.context.model.DiscountClubViewModel.GroupPriceUnformatted,
        ).toString();

    const handleSelectStandard = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedOption === "Standard") return;

        props.context.selectDcOption("Standard", acceptanceStatus);
        tealiumManager.logDcMembershipTypeSelected("A", getTealiumUnformattedPrice("Standard"));

        if (!userContext.isLoggedIn) dcModal.open("Standard");
    };

    const handleSelectGroup = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedOption === "Group") return;

        props.context.selectDcOption("Group", acceptanceStatus);
        tealiumManager.logDcMembershipTypeSelected("B", getTealiumUnformattedPrice("Group"));

        if (!userContext.isLoggedIn) dcModal.open("Group");
    };

    const handleLogin = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onLogin();
    };

    const tooltipTemplate = () => {
        const text1 = i18next.t("Descuento mínimo de {{-amount}} por tramo, para tarifas sobre {{-amountOver}}", {
            amount: vm.DiscountMin,
            amountOver: vm.DiscountOver,
        });

        const text2 = i18next.t("Descuento mínimo de {{-amount}} en equipaje de mano o facturado", {
            amount: vm.BaggageMin,
        });

        const text3 = i18next.t("Descuentos aplican para ti y acompañantes, según membresía.");
        const text4 = i18next.t("Acceso a promociones especiales");

        return html`
            <span class="dc-tooltip-container hidden-xs" data-test-id=${T.FLIGHT_DC_BANNER.TOOLTIP}>
                <span class="dc-tooltip-opener">?</span>
                <span class="dc-tooltip">
                    <span>${i18next.t("Beneficios del Club")}</span>
                    <ul>
                        <li>${text1}</li>
                        <li>${text2}</li>
                        <li>${text3}</li>
                        <li>${text4}</li>
                    </ul>
                </span>
            </span>
        `;
    };

    const mobileBenefitOpenerTemplate = () => html`
        <div class="dc-view-more hidden-sm-up" @click=${() => setIsMobileBenefitListOpen(!isMobileBenefitListOpen)}>
            <span>${isMobileBenefitListOpen ? i18next.t("Ver menos") : i18next.t("Ver más")}</span>
            <span class=${classNames("dc-view-more-arrow", { open: isMobileBenefitListOpen })}>&rsaquo;</span>
        </div>
    `;
    const mobileBenefitTemplate = () => html`
        <div class=${classNames("dc-benefits hidden-sm-up", { open: isMobileBenefitListOpen })}>
            ${benefits.htmlTemplate()}
        </div>
    `;

    const loginLinkTemplate = () =>
        !userContext?.isLoggedIn
            ? html`
                  <div class="already-member-section">
                      ${i18next.t("Si ya eres parte del Club de Descuentos, inicia sesión")}
                      <a
                          class="already-member-url"
                          data-test-id=${T.FLIGHT_DC_BANNER.MEMBER_LINK}
                          @click=${handleLogin}
                      >
                          ${i18next.t("AQUÍ")}
                      </a>
                  </div>
              `
            : "";

    const youSaveTemplate = () => html`
        <span
            class="dc-saved-price-amount"
            data-test-id=${T.FLIGHT_DC_BANNER.SAVED_AMOUNT}
            data-test-value=${(smartAmount - clubAmount).toString()}
        >
            ${savedAmount}
        </span>
    `;

    const priceWithDcTemplate = () => {
        const tempClassMap = classNames("dc-prices-membership with-dc", {
            english: appContext.Culture === USA_CULTURE_CODE,
            portuguese: appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return html`
            <div class=${tempClassMap} data-test-id=${T.FLIGHT_DC_BANNER.WITH_DC}>
                <div class="dc-prices-title ">${i18next.t("Total con Club de Descuentos")}</div>
                <div class="dc-prices-total whitespace-nowrap">
                    <span class="dollar-sign" data-test-id=${T.FLIGHT_DC_BANNER.WITH_DC_CURRENCY_SIGN}>
                        ${currencySign}
                    </span>
                    <span
                        class="dc-prices-amount"
                        data-test-id=${T.FLIGHT_DC_BANNER.TOTAL_PRICE_WITH_DC}
                        data-test-value=${clubAmount.toString()}
                    >
                        ${formattedClubAmount}${tooltipTemplate()}
                    </span>
                </div>
                <div class="dc-prices-you-save">
                    ${unsafeHTML(
                        i18next.t("Ahorras hoy {{-amount}}", {
                            amount: getRenderString(youSaveTemplate()),
                        }),
                    )}
                </div>
                ${mobileBenefitOpenerTemplate()}
            </div>
        `;
    };

    const priceWithNoDcTemplate = () => {
        const tempClassMap = classNames("dc-prices-membership", {
            english: appContext.Culture === USA_CULTURE_CODE,
            portuguese: appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return html`
            <div class=${tempClassMap} data-test-id=${T.FLIGHT_DC_BANNER.WITHOUT_DC}>
                <div class="dc-prices-title ">${i18next.t("Total Sin Club de Descuentos")}</div>
                <div class="dc-prices-total whitespace-nowrap">
                    <span class="dollar-sign" data-test-id=${T.FLIGHT_DC_BANNER.WITHOUT_DC_CURRENCY_SIGN}>
                        ${currencySign}
                    </span>
                    <span
                        class="dc-prices-amount"
                        data-test-id=${T.FLIGHT_DC_BANNER.TOTAL_PRICE}
                        data-test-value=${smartAmount}
                    >
                        ${formattedSmartAmount}
                    </span>
                </div>
            </div>
        `;
    };

    const pricesTemplateForGetMembership = () =>
        props.context?.bannerState.bannerVM.currentTotal
            ? html`
                  <div class="dc-prices-block">
                      ${priceWithDcTemplate()} ${mobileBenefitTemplate()} ${priceWithNoDcTemplate()}
                  </div>
              `
            : "";

    const bannerTopPartTemplate = () =>
        props.context?.bannerState.bannerVM.showPricesInBanner
            ? pricesTemplateForGetMembership()
            : benefits.htmlTemplate();

    const typeSelectorTitleTemplate = () => html`
        <div class="select-membership-title">
            ${![5, 6].includes(userContext?.bancoEstado.category)
                ? i18next.t("Elige tu membresía:")
                : i18next.t("Elige tu membresía a precios preferentes:")}
        </div>
    `;

    const standardMembershipTitleTemplate = () => {
        const titleClassMap = classNames("membership-type-title", {
            "pull-left": !isCurrentBancoEstadoCategory([0]),
        });

        return html`
            <div class=${titleClassMap}>
                ${!isCurrentBancoEstadoCategory([0]) ? html` <i class="js-icon js-medal"></i> ` : ""}
                ${i18next.t("Membresía Estándar")}
            </div>
        `;
    };

    const standardMembershipButtonTemplate = () => {
        const standardButtonClassMap = classNames("rounded-primary-btn dc-yellow", {
            selected: selectedOption === "Standard",
        });

        return html`
            <button
                class=${standardButtonClassMap}
                @click=${handleSelectStandard}
                data-test-id=${T.FLIGHT_DC_BANNER.STANDARD_BUTTON}
            >
                ${standardPrice} ${i18next.t("al año")}
            </button>
        `;
    };

    const standardMembershipTemplate = () => {
        const standardOptionClassMap = classNames("get-membership-section", {
            disabled: flightVm.NumberOfPax > MAX_PAX_IN_STANDARD_DC_MEMBERSHIP,
        });

        return html`
            <div
                class=${standardOptionClassMap}
                data-test-id=${getTestId(T.FLIGHT_DC_BANNER.MEMBERSHIP_SECTION, { c: "standard" })}
            >
                ${standardMembershipTitleTemplate()}
                <div class="get-membership-description">${i18next.t("Para ti y 1 acompañante")}</div>
                ${standardMembershipButtonTemplate()}
            </div>
        `;
    };

    const groupMembershipTitleTemplate = () => {
        const titleClassMap = classNames("membership-type-title", {
            "pull-left": !isCurrentBancoEstadoCategory([0]),
        });

        return html`
            <div class=${titleClassMap}>
                ${!isCurrentBancoEstadoCategory([0]) ? html` <i class="js-icon js-medal"></i> ` : ""}
                ${i18next.t("Membresía Grupal")}
            </div>
        `;
    };

    const groupMembershipButtonTemplate = () => {
        const groupButtonClassMap = classNames("rounded-primary-btn dc-yellow", {
            selected: selectedOption === "Group",
        });

        return html`
            <button
                class=${groupButtonClassMap}
                data-test-id=${T.FLIGHT_DC_BANNER.GROUP_BUTTON}
                @click=${handleSelectGroup}
            >
                ${groupPrice} ${i18next.t("al año")}
            </button>
        `;
    };

    const groupMembershipTemplate = () => html`
        <div
            class="get-membership-section"
            data-test-id=${getTestId(T.FLIGHT_DC_BANNER.MEMBERSHIP_SECTION, { c: "group" })}
        >
            ${groupMembershipTitleTemplate()}
            <div class="get-membership-description">${i18next.t("Para ti y hasta 5 acompañantes")}</div>
            ${groupMembershipButtonTemplate()}
        </div>
    `;

    const bodyTemplate = () => html`
        ${bannerTopPartTemplate()} ${typeSelectorTitleTemplate()}
        <div class="membership-selector-container" data-test-id=${T.FLIGHT_DC_BANNER.MEMBERSHIP_SECTIONS_CONTAINER}>
            ${standardMembershipTemplate()} ${groupMembershipTemplate()}
        </div>
        ${terms.htmlTemplate()} ${noDiscountUrl.htmlTemplate()} ${loginLinkTemplate()}
    `;

    const headerTemplate = () => html`
        <i class="js-icon js-flight-plane"></i>
        <div class="dc-banner-content-header-text">
            ${unsafeHTML(
                isCurrentBancoEstadoCategory([0])
                    ? i18next.t("¡Sé parte del Club y ahorra en todas tus compras!")
                    : i18next.t("¡Obtén más beneficios y ahorra en todas tus compras!"),
            )}
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="dc-banner-content">
            <div class="dc-banner-content-header bg-be-cyan-2">${headerTemplate()}</div>
            <div class="dc-banner-content-body">${bodyTemplate()}</div>
        </div>
        ${dcModal.htmlTemplate()}
    `;

    return { htmlTemplate };
};

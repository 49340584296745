import { TemplateResult, html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import classNames from "classnames";
import { CLASS_NAMES } from "../../../shared/classNames";

export const name = "ac-compact-datepicker-time-unit";

export interface Props {
    dataTestId?: string;
    isInvalid: boolean;
    isSelected: boolean;
    template: TemplateResult | string;
    onClick: () => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        dataTestId: host.dataTestId || "",
        isInvalid: host.isInvalid,
        isSelected: host.isSelected,
        template: host.template,
        onClick: host.onClick,
    };

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
    };

    return html`
        <li
            class=${classNames(
                "mx-px cursor-pointer py-2 text-center text-sm",
                "sm:hover:bg-brand-secondary sm:hover:bg-opacity-20",
                {
                    "pointer-events-none font-bold": props.isSelected,
                    "bg-brand-secondary text-white": props.isSelected && !props.isInvalid,
                    "border-y border-solid border-be-red text-be-red": props.isSelected && props.isInvalid,
                    [CLASS_NAMES.selected]: props.isSelected,
                },
            )}
            data-test-id=${props.dataTestId}
            @click=${handleClick}
        >
            ${props.template}
        </li>
    `;
};

import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "lit-html";

export const name = "ac-itinerary-offline-payment-page";

export const Component: HauntedFunc<{}> = () => {
    // HELPERS

    // EVENT HANDLERS

    // COMPONENTS

    // TEMPLATES

    const headerTemplate = () =>
        html`
            <header>
                <span>!</span>
                <label>${i18next.t("OfflinePayment-HeaderTitle")}</label>
            </header>
        `;

    const paymentStepsTemplate = () =>
        html`
            <div class="opi-title">${i18next.t("OfflinePayment-Title-1")}</div>
            <div class="row">
                <div class="col-xs-1 col-sm-1-3 text-center">
                    <img class="opi-illustration" src="/Images/OfflinePaymentItinerary/step-1.png" />
                </div>
                <div class="col-xs-1 col-sm-1-3 text-center">
                    <img class="opi-illustration" src="/Images/OfflinePaymentItinerary/step-2.png" />
                </div>
                <div class="col-xs-1 col-sm-1-3 text-center">
                    <img class="opi-illustration" src="/Images/OfflinePaymentItinerary/step-3.png" />
                </div>
            </div>
        `;

    const locationTemplate = () =>
        html`
            <div class="opi-title">${i18next.t("OfflinePayment-Title-2")}</div>
            <div class="opi-subtitle">${i18next.t("OfflinePayment-SubTitle")}</div>
            <div class="row">
                <div class="col-xs-1">
                    <img class="opi-payment-image" src="/Images/OfflinePaymentItinerary/pago-facile.png" />
                </div>
            </div>
        `;

    const warningTemplate = () =>
        html`
            <div class="row">
                <div class="col-xs-1">
                    <div class="opi-warning">
                        <i class="js-dc-exclamation js-icon"></i>
                        <div>
                            <span> ${i18next.t("OfflinePayment-Warning-1")}</span>
                            ${i18next.t("OfflinePayment-Warning-2")}
                        </div>
                    </div>
                </div>
            </div>
        `;

    return html`
        <div id="mainContentWrapper">
            <section class="booking-wrapper offline-payment-itinerary">
                ${headerTemplate()}
                <div class="opi-container">${paymentStepsTemplate()} ${locationTemplate()} ${warningTemplate()}</div>
            </section>
        </div>
    `;
};

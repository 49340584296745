import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { showLoader } from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { CHILEAN_CULTURE_CODE, FULL_EMAIL_REGEX, GIFTCARD_ACTIVATION_STEPS } from "../../shared/commonConstants";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../managers/useAppContext";
export interface Props {
    isActive: boolean;
    isBancoEstadoUser: boolean;
    parentElement: HTMLDivElement;
    handleOnBancoEstado: () => void;
    handleOnEmailBack: () => void;
    handleOnEmailSubmit: () => void;
    handleOnExistingUser: () => void;
}

export const useGiftcardActivationEmail = (props: Props) => {
    // HELPERS
    const isEmailValid = () => FULL_EMAIL_REGEX.test(email.current?.value);

    // EVENT HANDLERS
    const handleEmailInput = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleEmailSubmit();
        }
    };

    const handleValidEmailSubmit = async () => {
        const loader = showLoader({});
        await ajaxRequest({
            loader,
            container: props.parentElement,
            method: "POST",
            url: `${ROUTES.ApiRoutes.CheckUserExist}`,
            body: {
                EmailAddress: email.current?.value,
            },
            isJsonData: true,
            onResponseCode: {
                200: (result) => {
                    const doesUserExist = JSON.parse(result).IsUserExist;

                    if (doesUserExist) {
                        props.handleOnExistingUser();
                        return;
                    }

                    props.handleOnEmailSubmit();
                },
            },
        });
    };

    const handleEmailSubmit = async () => {
        setShowEmailFormatError(false);

        if (isEmailValid()) {
            await handleValidEmailSubmit();
        } else {
            setShowEmailFormatError(true);
        }
    };

    // COMPONENT
    const root = useRef<HTMLDivElement>(undefined);
    const email = useRef<HTMLInputElement>(undefined);

    const appContext = useAppContext();

    const { ajaxRequest } = useAjax();

    const [showEmailFormatError, setShowEmailFormatError] = useState<boolean>(false);

    useEffect(() => (props.isActive ? email.current?.focus() : null), [props.isActive]);

    // TEMPLATES
    const emailFormatErrorTemplate = () => html` <div class="gca-error">${i18next.t("V2-InvalidEmailFormat")}</div> `;

    const bancoEstadoTemplate = () => html`
        <div class="gca-container">
            <div class="gca-email-link" data-test-id="gc-activate-switch-to-bancoe" @click=${props.handleOnBancoEstado}>
                ${i18next.t("Gift-EmailBeLink")}
            </div>
        </div>
    `;

    const emailWarningTemplate = () => html`
        <div class="gca-email-warning">
            ${unsafeHTML(
                i18next.t("Gift-EmailWarning {{-spanStart}}{{-spanEnd}}{{-br}}", {
                    spanStart: "<span>",
                    spanEnd: "</span>",
                    br: '<br class="hidden-sm-down" />',
                }),
            )}
        </div>
    `;

    const buttonContainerTemplate = () => html`
        <div class="gca-container">
            <div class="gca-btn-container">
                <button
                    class="mr-2 rounded-secondary-btn"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_BACK_BUTTON, { c: GIFTCARD_ACTIVATION_STEPS.EMAIL })}
                    @click=${props.handleOnEmailBack}
                >
                    ${i18next.t("Gift-Cancel")}
                </button>
                <button
                    class="rounded-primary-btn"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_NEXT_BUTTON, { c: GIFTCARD_ACTIVATION_STEPS.EMAIL })}
                    @click=${handleEmailSubmit}
                >
                    ${i18next.t("Gift-Continue")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "step-form": true,
            "gca-enter-email": true,
            "active": props.isActive,
        });

        return html`
            <div ref=${ref(root)} class=${mainClassMap}>
                <div class="giftcard-activation-step-info">${i18next.t("Gift-StepInfo-2A")}</div>
                <div class="gca-container">
                    <div class="gca-enter-code-label visible-xs">${i18next.t("Gift-StepInfo-2A")}</div>
                    <input
                        ref=${ref(email)}
                        type="text"
                        placeholder=${i18next.t("Gift-EnterEmailPlaceholder")}
                        data-test-id=${T.GIFTCARD.ACTIVATION_EMAIL}
                        @keydown=${handleEmailInput}
                    />
                </div>

                ${showEmailFormatError ? emailFormatErrorTemplate() : ""}
                ${appContext.Culture === CHILEAN_CULTURE_CODE ? bancoEstadoTemplate() : ""} ${emailWarningTemplate()}
                ${buttonContainerTemplate()}
            </div>
        `;
    };

    return { htmlTemplate, email: email.current };
};

import { html } from "lit-html";
import { useFlightFare } from "./useFlightFare";
import { FlightHelper } from "../../component-helpers/flight/FlightHelper";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { SelectedFlightFeeType } from "./flight-page";
import classNames from "classnames";
import { useAppContext } from "../../managers/useAppContext";
import { OUTBOUND } from "../../shared/commonConstants";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { StationSettings } from "../../component-models/StationSettings";
import { FlightSelectDTO } from "./contexts/useFlightPageContext";
import { useFlightInfo } from "./useFlightInfo";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface FlightOptionProps {
    context: FlightPageContext;
    disabled: boolean;
    flightOption: FlightOptionModel;
    isInFlightMove: boolean;
    journeyIndex: number;
    referenceObject: HTMLElement;
    rootElem: HTMLDivElement;
    stationSettings: StationSettings;
    flightSelect: (data: FlightSelectDTO) => Promise<void>;
}

export const useFlightOption = (props: FlightOptionProps) => {
    const appContext = useAppContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const flightInfo = useFlightInfo({
        context: props.context,
        flightOption: props.flightOption,
        isInFlightMove: props.isInFlightMove,
        stationSettings: props.stationSettings,
    });

    const flightFare = useFlightFare({
        context: props.context,
        disabled: props.disabled,
        flightOption: props.flightOption,
        isInFlightMove: props.isInFlightMove,
        onReset: () => props.context.resetFlight(props.journeyIndex, props.rootElem),
        onSelect: (feeType: SelectedFlightFeeType) =>
            props.flightSelect({
                feeType,
                flightOption: props.flightOption,
                referenceObject: props.referenceObject,
            }),
    });

    const showSelectedView = useMemo(
        () => props.context.showSelectedView(props.flightOption),
        [props.flightOption, props.context.showSelectedView],
    );

    const showSummary = useMemo(
        () => showSelectedView && props.context.bundleState.bundlesMode !== "PerLeg",
        [showSelectedView, props.context.bundleState],
    );

    const formattedAmount = useMemo(() => {
        const displayData = Boolean(
            props.flightOption.JourneyIndex === OUTBOUND
                ? props.context.flightState?.selectedOutboundFlight
                : props.context.flightState?.selectedInboundFlight,
        );

        return displayData
            ? props.context.flightState?.selectedFeeType === "Club"
                ? FlightHelper.clubPrice({
                      context: props.context,
                      culture: appContext.Culture,
                      currency,
                      flightOption: props.flightOption,
                      userData: userContext,
                      formatNumber,
                  })
                : FlightHelper.smartPrice({
                      context: props.context,
                      culture: appContext.Culture,
                      currency,
                      flightOption: props.flightOption,
                      formatNumber,
                  })
            : "";
    }, [props.flightOption, props.context.flightState, appContext.Culture, currency, userContext]);

    const handleEdit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.context.resetFlight(props.journeyIndex, props.rootElem);
    };

    const tickTemplate = () => html`
        <i
            class=${classNames(
                "js-icon js-flight-tick absolute -right-8 bottom-[10px] text-[64px]",
                "sm:bottom-auto sm:right-auto sm:all-center",
                {
                    "text-brand-secondary":
                        userContext?.bancoEstado.category !== 1 ||
                        props.context.flightState?.selectedFeeType === "Smart",
                    "text-be-orange":
                        userContext?.bancoEstado.category === 1 &&
                        props.context.flightState?.selectedFeeType === "Club",
                },
            )}
            data-test-id=${getTestId(T.FLIGHT.TICK, {
                j: props.flightOption.JourneyIndex,
                i: props.flightOption.Index,
            })}
        ></i>
    `;

    const pencilTemplate = () =>
        html`<i
            class="js-icon js-edit absolute right-[10px] top-[10px] z-[1] cursor-pointer text-lg text-brand-secondary hover:text-brand-primary"
            @click=${handleEdit}
        ></i>`;

    const editTemplate = () => html`
        <div class="col-sm-1-6 col-lg-1-5">
            <div class="h-full w-full sm:relative">${tickTemplate()} ${pencilTemplate()}</div>
        </div>
    `;

    const priceTemplate = () => html`
        <div class="col-xs-1 col-sm-2-6 col-lg-2-5 min-h-[6rem]">
            <div
                class="flex h-full w-full items-center justify-center text-4xl font-bold text-brand-secondary lg:text-5xl"
            >
                ${formattedAmount}
            </div>
        </div>
    `;

    const selectedflightOptionForStaffTemplate = () =>
        showSummary ? html` ${priceTemplate()} ${editTemplate()} ` : "";

    const infoTemplate = () => html`
        <div
            class=${classNames("col-xs-1 col-sm-1-2 py-2", {
                "col-lg-1-2": !showSelectedView,
                "col-lg-2-5": showSelectedView,
            })}
            data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO, {
                j: props.flightOption.JourneyIndex,
                i: props.flightOption.Index,
            })}
        >
            ${flightInfo.htmlTemplate()}
        </div>
    `;

    const fareTemplate = () =>
        !showSelectedView ? html` <div class="col-xs-1 col-sm-1-2">${flightFare.htmlTemplate()}</div> ` : "";

    const htmlTemplate = () => {
        return html`${infoTemplate()} ${fareTemplate()} ${selectedflightOptionForStaffTemplate()}`;
    };

    return { htmlTemplate };
};

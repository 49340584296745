import { html, TemplateResult } from "lit-html";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useMemo } from "../../../shared/haunted/CustomHooks";

export interface ErrorMessageProps {
    errorMessage: string | TemplateResult | (string | TemplateResult)[];
}

export const useErrorMessage = (props: ErrorMessageProps) => {
    const displayedErrors = useMemo<(string | TemplateResult)[]>(
        () => (Array.isArray(props.errorMessage) ? props.errorMessage : [props.errorMessage]).filter((error) => error),
        [props.errorMessage],
    );

    const htmlTemplate = () =>
        displayedErrors.length
            ? html`
                  <div class="error-message-container">
                      ${displayedErrors.map(
                          (error) => html` <span data-test-id=${T.COMMON.FORM_FIELD_ERROR}>${error}</span> `,
                      )}
                  </div>
              `
            : "";

    return { htmlTemplate };
};

import i18next from "i18next";
import { html } from "lit-html";
import { BaggageContext, BaggageContextJourney } from "../../../../component-models/baggage/BaggageContext";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    isMobile: boolean;
}

export const usePerJourneyPerPaxSoldOut = (props: Props) => {
    const soldOutIcon = useMemo(
        () =>
            props.baggageSection.bagType === "CabinBaggage"
                ? "js-bag-regular-baggage"
                : props.baggageSection.bagType === "CheckedBaggage"
                  ? "js-bag-zipper-baggage"
                  : "",
        [props.baggageSection.bagType],
    );

    const soldOutText = useMemo(
        () =>
            props.baggageSection.bagType === "CabinBaggage"
                ? i18next.t(
                      "Lo sentimos, la capacidad de equipaje de mano ya ha sido vendida en su totalidad para este tramo.",
                  )
                : props.baggageSection.bagType === "CheckedBaggage"
                  ? i18next.t(
                        "Lo sentimos, la capacidad de equipaje facturado ya ha sido vendida en su totalidad para este tramo.",
                    )
                  : i18next.t(
                        "Lo sentimos, la capacidad de equipaje sobredimensionado ya ha sido vendida en su totalidad para este tramo.",
                    ),
        [props.baggageSection.bagType],
    );

    const htmlTemplate = (journey: BaggageContextJourney) => {
        const anySoldOut = journey.passengers.some(
            (passenger) =>
                props.context.getContextJourneyPassengerBaggage({
                    bagType: props.baggageSection.bagType,
                    journeyIndex: journey.index,
                    passengerIndex: passenger.index,
                }).isSoldOut,
        );

        return anySoldOut
            ? html`
                  <div
                      class=${classNames("b2-baggage-sold-out-per-pax-info", {
                          "rounded-b-xl": props.context.journeys.length === 1,
                      })}
                      data-test-id=${getTestId(T.BAGGAGE.SOLD_OUT_MESSAGE, {
                          c: props.baggageSection.bagType,
                          m: props.isMobile,
                          j: journey.index,
                      })}
                  >
                      <i class="js-icon-bag ${soldOutIcon}"></i>
                      <span>
                          ${soldOutText}
                          <span class="font-semibold">${i18next.t("Este opcional NO")}</span>
                          ${i18next.t("estará disponible para la venta en aeropuerto.")}
                      </span>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};

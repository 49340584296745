import { useEffect } from "./../../../shared/haunted/CustomHooks";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { html } from "lit-html";
import { CompanyInfo } from "../../../component-models/CompanyInfo";
import { UserInfo } from "../../../component-models/UserInfo";
import { ROUTES } from "../../../shared/apiRoutes";
import { getAntiForgeryTokenFromHtml, getParsedProperty, toBoolean } from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { PeruCompraMemberData } from "../../../component-models/PeruCompraMemberData";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "can-edit-company",
    "company-model",
    "user-model",
    "peru-compra-data",
];
export const name = "ac-cug-profile-page";
export interface Attributes {
    "anti-forgery-token": string;
    "can-edit-company": string;
    "company-model": string;
    "user-model": string;
    "peru-compra-data": string;
}
export interface Properties {
    antiForgeryToken: string;
    canEditCompany: boolean;
    companyModel: CompanyInfo;
    userModel: UserInfo;
    peruCompraData: PeruCompraMemberData;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canEditCompany: toBoolean(host.canEditCompany),
        companyModel:
            host.companyModel && typeof host.companyModel === "string"
                ? getParsedProperty<CompanyInfo>(host.companyModel)
                : undefined,
        userModel:
            host.userModel && typeof host.userModel === "string"
                ? getParsedProperty<UserInfo>(host.userModel)
                : undefined,
        peruCompraData:
            host.peruCompraData && typeof host.peruCompraData === "string"
                ? getParsedProperty<PeruCompraMemberData>(host.peruCompraData)
                : undefined,
    };

    // HELPERS

    const isPeruCompra = () => userContext.peruCompra.isMember || userContext.peruCompra.isAdmin;

    const isAgency = () => cug2AppContext.Type === "agency";

    const isCompany = () => cug2AppContext.Type === "company";

    // EVENT LISTENERS

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");
    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    // TEMPLATES

    const headerInfoTemplate = () =>
        isPeruCompra()
            ? html`<h2>
                  ${i18next.t("Revisa, edita y actualiza toda la información referente a tu cuenta de usuario.")}
              </h2>`
            : html` <h2>
                  ${i18next.t(
                      "Revisa, edita y actualiza toda la información referente a tu empresa/agencia y a tu cuenta de usuario.",
                  )}
              </h2>`;

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>${i18next.t("Mi Perfil")}</h1>
                ${headerInfoTemplate()}
            </div>
        </div>
    `;

    const companyEditorTemplate = () =>
        props.canEditCompany && !isPeruCompra()
            ? html`
                  <div
                      class="cug2b-profile-edit cursor-pointer"
                      @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                  >
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";

    const companyTitleTemplate = () =>
        isPeruCompra()
            ? html` <span>${i18next.t("Mi Organización")}</span>`
            : isAgency()
              ? html` <span>${i18next.t("Mi Agencia")}</span>`
              : isCompany()
                ? html` <span>${i18next.t("Mi Empresa")}</span>`
                : "";

    const companyTemplate = () =>
        props.companyModel && !userContext.peruCompra.isMember
            ? html`
                  <div class="relative">
                      <div class="cug2b-profile-title">
                          <i class="js-icon-cug js-cug-company"></i>
                          ${companyTitleTemplate()}
                      </div>
                      ${companyEditorTemplate()}
                      <ac-cug-company-info .model=${props.companyModel}></ac-cug-company-info>
                  </div>
              `
            : "";

    const userInfoTitleTemplate = () =>
        isPeruCompra()
            ? html` <span>${i18next.t("Editar usuario")}</span>`
            : html` <span>${i18next.t("Usuario")}</span>`;

    const userTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-profile-title": true,
            "push-down": Boolean(props.companyModel),
        });

        return props.userModel
            ? html`
                  <div class="relative">
                      <div class=${tempClassMap}>
                          <i class="js-icon-cug js-cug-user"></i>
                          ${userInfoTitleTemplate()}
                      </div>
                      <div
                          class="cug2b-profile-edit cursor-pointer"
                          @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                      >
                          <i class="js-icon-cug js-cug-edit"></i>
                          <span>${i18next.t("Editar")}</span>
                      </div>
                      <ac-cug-user-info
                          .model=${props.userModel}
                          .peruCompraModel=${props.peruCompraData}
                      ></ac-cug-user-info>
                  </div>
              `
            : "";
    };

    return html`
        ${headerTemplate()}
        <div class="cug2b-profile-box">${companyTemplate()} ${userTemplate()}</div>
    `;
};

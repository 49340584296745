import dbg from "debug";

export class Debug {
    private infoInstance: dbg.IDebugger;
    private warningInstance: dbg.IDebugger;
    private errorInstance: dbg.IDebugger;

    constructor(prefix: string, postfix: string) {
        dbg.enable("*");
        this.infoInstance = dbg(`${prefix}:info:${postfix}`);
        this.warningInstance = dbg(`${prefix}:warning:${postfix}`);
        this.errorInstance = dbg(`${prefix}:error:${postfix}`);
    }

    public info(formatter: any, ...args: any[]) {
        this.infoInstance(formatter, args);
    }
    public warning(formatter: any, ...args: any[]) {
        this.warningInstance(formatter, args);
    }
    public error(formatter: any, ...args: any[]) {
        this.errorInstance(formatter, args);
    }
}

import { InputFieldAttribute, UDF_ATTR_MAX } from "../InputFieldAttribute";
import { InputElement } from "../useForm";

export const maxLength = (input: InputElement): InputFieldAttribute => ({
    name: UDF_ATTR_MAX,
    validators: [
        {
            errorMessage: {
                id: "maxLengthError",
                message:
                    window.formResources.fieldMaximumLengthIs +
                    `${input.getAttribute(UDF_ATTR_MAX)}` +
                    window.formResources.characters,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = field.value.length <= Number(field.getAttribute(UDF_ATTR_MAX));

                return Promise.resolve(result);
            },
        },
    ],
});

import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { GiftcardTemplates } from "./GiftcardTemplates";
import { useState } from "../../shared/haunted/CustomHooks";
import { useEffect, useRef } from "haunted";
import { CLASS_NAMES } from "../../shared/classNames";

export interface DesignSelectorProps {
    isOpen: boolean;
    templates: GiftcardTemplates[];
}

const MOBILE_CAROUSEL_CLASSLIST = [
    CLASS_NAMES.giftcardScrolling.firstImage,
    CLASS_NAMES.giftcardScrolling.secondImage,
    CLASS_NAMES.giftcardScrolling.thirdImage,
    CLASS_NAMES.giftcardScrolling.fourthImage,
];

const RIGHT = 1;
const LEFT = -1;

const designTypeInputName = "DesignType";

export const useDesignSelector = (props: DesignSelectorProps) => {
    const lastImageIndex = () => MOBILE_CAROUSEL_CLASSLIST.length - 1;

    const lastImageClassName = () => MOBILE_CAROUSEL_CLASSLIST[lastImageIndex()];

    const mobileScrollDesigns = (offset: number) => {
        const currentClass = MOBILE_CAROUSEL_CLASSLIST.find(
            (item) => item && designContainerElement.current.classList.contains(item),
        );

        const currentIndex = currentClass ? MOBILE_CAROUSEL_CLASSLIST.indexOf(currentClass) + offset : offset;
        designContainerElement.current.classList.remove(currentClass);

        if (MOBILE_CAROUSEL_CLASSLIST[currentIndex]) {
            designContainerElement.current.classList.add(MOBILE_CAROUSEL_CLASSLIST[currentIndex]);
        }

        setShowCarouselLeft(currentIndex > 0);
        setShowCarouselRight(currentIndex < lastImageIndex());
    };

    const handleDesignerScrollLeftTablet = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        designContainerElement.current.classList.remove(lastImageClassName());

        setShowCarouselLeft(false);
        setShowCarouselRight(true);
    };

    const handleDesignerScrollRightTablet = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        designContainerElement.current.classList.add(lastImageClassName());

        setShowCarouselLeft(true);
        setShowCarouselRight(false);
    };

    const handleDesignerScrollLeftMobile = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        mobileScrollDesigns(LEFT);
    };

    const handleDesignerScrollRightMobile = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        mobileScrollDesigns(RIGHT);
    };

    const handleImageClick = (e: MouseEvent, code: string) => {
        e.preventDefault();
        e.stopPropagation();

        const elem = e.target as HTMLImageElement;
        const inputElem = elem.parentElement.querySelector("input") as HTMLInputElement;
        inputElem.checked = true;
        setSelectedDesignCode(code);
    };

    const designContainerElement = useRef<HTMLDivElement>(undefined);

    const [selectedDesignCode, setSelectedDesignCode] = useState<string>("");
    const [showCarouselLeft, setShowCarouselLeft] = useState<boolean>(false);
    const [showCarouselRight, setShowCarouselRight] = useState<boolean>(true);

    useEffect(() => {
        if (selectedDesignCode) {
            setSelectedDesignCode("");
        }
    }, [props.isOpen]);

    const designerScrollControlTemplate = () => {
        return html`
            ${showCarouselLeft
                ? html`
                      <div @click=${handleDesignerScrollLeftTablet} class="visible-sm design-scroll-left"></div>
                      <div @click=${handleDesignerScrollLeftMobile} class="visible-xs design-scroll-left"></div>
                  `
                : ""}
            ${showCarouselRight
                ? html`
                      <div @click=${handleDesignerScrollRightTablet} class="visible-sm design-scroll-right"></div>
                      <div @click=${handleDesignerScrollRightMobile} class="visible-xs design-scroll-right"></div>
                  `
                : ""}
        `;
    };

    const defaultDesignTemplate = (code: string) => html`
        <div class="giftcard-design-option hidden">
            <input id="d-0" name=${designTypeInputName} type="radio" .value=${code} .checked=${!props.isOpen} />
            <label for="d-0"></label>
        </div>
    `;

    const giftDesignTemplate = (template: GiftcardTemplates) => html`
        <div class="giftcard-design-option">
            <img
                @click=${(e: MouseEvent) => handleImageClick(e, template.Code.toString())}
                src=${template.ThumbnailUrl}
            />
            <input
                id="d-${template.Code}"
                name=${designTypeInputName}
                type="radio"
                message
                .value=${template.Code}
                .checked=${template.Code.toString() === selectedDesignCode && props.isOpen}
            />
            <label @click=${() => setSelectedDesignCode(template.Code.toString())} for="d-${template.Code}"></label>
        </div>
    `;

    const htmlTemplate = () => html`
        ${designerScrollControlTemplate()}
        <div class="giftcard-design-selector">
            <div ref=${ref(designContainerElement)} class="giftcard-design-container">
                ${props.templates.map((template, index) =>
                    index === 0 ? defaultDesignTemplate(template.Code.toString()) : giftDesignTemplate(template),
                )}
            </div>
        </div>
    `;

    return { selectedDesignCode, htmlTemplate };
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { commonDebug } from "../../bootstrap";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef } from "haunted";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { ref } from "../../directives/ref";
import RetargetingDestination from "../../V2Checkin/shared/checkin-sidebar-content/RetargetingDestination";
import { classMap } from "lit-html/directives/class-map";
import { getAntiForgeryTokenFromHtml } from "../../shared/common";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-sidebar-checkin";

const SIDEBAR_SCROLLER_CLASS_NAME = "sidebar-scroller-container";

export interface Attributes {
    "active-index": string;
    "anti-forgery-token": string;
    "arrival-city": string;
    "car-link": string;
    "departure-city": string;
    "destination-url": string;
    "hotel-link": string;
}

export const observedAttributes: (keyof Attributes)[] = [
    "active-index",
    "anti-forgery-token",
    "arrival-city",
    "car-link",
    "departure-city",
    "destination-url",
    "hotel-link",
];

export interface Props {
    activeIndex: number;
    antiForgeryToken: string;
    arrivalCity: string;
    carLink: string;
    departureCity: string;
    destinationUrl: string;
    hotelLink: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        activeIndex: Number(host.activeIndex),
        antiForgeryToken: host.antiForgeryToken,
        arrivalCity: host.arrivalCity,
        carLink: host.carLink,
        departureCity: host.departureCity,
        destinationUrl: host.destinationUrl,
        hotelLink: host.hotelLink,
    };

    // Helpers

    const populateModel = (response: string) => {
        try {
            const model = JSON.parse(response) as RetargetingDestination;
            model.weather.temp_max = Math.ceil(Number(model.weather.temp_max));
            model.weather.temp_min = Math.floor(Number(model.weather.temp_min));
            setModel(model);
        } catch (e) {
            commonDebug.error("Error parsing retargeting model.", e);
        }
    };

    // Event handlers

    const handleSidebarOpen = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsOpen(!isOpen);
        document.body.classList.toggle("mobile-sidebar-opened");
    };

    const init = async () => {
        // TODO: check if needed
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        await ajaxRequest({
            container: root.current,
            url: props.destinationUrl,
            method: "GET",
            onResponseCode: {
                200: (response) => populateModel(response),
                400: () => commonDebug.error("No destination loaded..."),
                500: () => commonDebug.error("No destination loaded..."),
            },
        }).catch((e) => commonDebug.error(e));

        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[name] = ScrollHelper.addPerfectScrollbar(SIDEBAR_SCROLLER_CLASS_NAME) as any;
    };

    // Component

    const root = useRef<HTMLDivElement>(undefined);

    const { ajaxRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [model, setModel] = useState<RetargetingDestination>(undefined);

    useEffect(init, []);

    // Templates

    const openerTemplate = () => {
        const tempClassMap = classMap({
            "sidebar-opener": true,
            "destination": true,
            "visible-sm-down": true,
            "small": isOpen,
        });

        return html`
            <section @click=${handleSidebarOpen} class=${tempClassMap}>
                ${isOpen
                    ? html`
                          <div class="total-section ">
                              <i class="js-icon js-plane-x mirrored"></i>
                              <span>${i18next.t("PriceDisplay-TotalSectionMobileTitle")}</span>
                          </div>
                      `
                    : html`
                          <div class="total-section">
                              <i class="js-icon js-plane-x"></i>
                              <span>${i18next.t("PriceDisplay-TotalSectionMobileTitle")}</span>
                          </div>
                      `}
            </section>
        `;
    };

    const destinationTemplate = () => html`
        <div class="sidebar-scroller destination ${SIDEBAR_SCROLLER_CLASS_NAME}">
            <section class="sidebar-content ts-error-container ">
                <div class="prices-wrapper">
                    ${imagesTemplate()} ${routeTemplate()} ${breadcrumbsTemplate()} ${descriptionTemplate()}
                    ${weatherTemplate()}

                    <div class="checkin-destination-title">${i18next.t("V2-DestinationServices")}</div>
                    ${hotelTemplate()} ${carTemplate()}
                </div>
            </section>
        </div>
    `;

    const imagesTemplate = () => {
        const tempClassMap = classMap({
            "checkin-destination-img": true,
            "img-responsive": true,
            "cut": Boolean(model?.dep_image) && Boolean(model?.arr_image),
        });

        return html`
            <div class="destination-images">
                ${model?.dep_image ? html` <img src=${model.dep_image} class=${tempClassMap} /> ` : ""}
                ${model?.arr_image ? html` <img src=${model.arr_image} class=${tempClassMap} /> ` : ""}
            </div>
        `;
    };

    const routeTemplate = () => html`
        <div class="checkin-destination-route">${props.departureCity} - ${props.arrivalCity}</div>
    `;

    const breadcrumbsTemplate = () => html`
        <ac-breadcrumbs-checkin .activeIndex=${props.activeIndex}></ac-breadcrumbs-checkin>
    `;

    const descriptionTemplate = () =>
        model?.arr_description
            ? html`
                  <div class="checkin-destination-title">${i18next.t("V2-DestinationDetails")}:</div>
                  <div class="checkin-destination-subtitle">${props.arrivalCity}</div>
                  <p class="checkin-destination-p">${model.arr_description}</p>
              `
            : "";

    const weatherTemplate = () =>
        model?.weather && model?.weather?.day
            ? html`
                  <div class="checkin-destination-title">
                      ${i18next.t("V2-DestinationWeather")} ${props.arrivalCity}:
                  </div>
                  <div class="weather">
                      <div class="row">
                          <div class="col-xs-1-2 text-center">
                              <img src=${model.weather.image} class="img-responsive checkin-destination-weather" />
                          </div>
                          <div class="col-xs-1-2">
                              <div class="weather-data">
                                  <span>${model.weather.day}</span>
                                  <span>${model.weather.temp_min}&deg; / ${model.weather.temp_max}&deg;</span>
                                  <span>${model.weather.name}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const hotelTemplate = () => html`
        <div class="checkin-services">
            <div class="row">
                <div class="col-xs-1-3 text-center">
                    <i class="js-icon js-checkin-bed"></i>
                </div>
                <div class="col-xs-2-3 text-center">
                    <span> ${i18next.t("V2-DestinationHotel")} </span>
                    <a class="reserve-hotel" href=${props.hotelLink} target="_blank">
                        ${i18next.t("V2-Book")}
                        <i class="js-icon js-circle-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    `;

    const carTemplate = () => html`
        <div class="checkin-services">
            <div class="row">
                <div class="col-xs-1-3 text-center">
                    <i class="js-icon js-checkin-car"></i>
                </div>
                <div class="col-xs-2-3 text-center">
                    <span> ${i18next.t("V2-DestinationCar")} </span>
                    <a href=${props.carLink} target="_blank" class="reserve-car">
                        ${i18next.t("V2-Reserve")}
                        <i class="js-icon js-circle-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    `;

    const wrapperClassMap = classMap({
        "sidebar-wrapper": true,
        "opened": isOpen,
    });

    return html`
        <div ref="wrapper" class=${wrapperClassMap}>
            <div class="sidebar-container ts-sidebar-container">
                <div ref=${ref(root)}>${openerTemplate()} ${destinationTemplate()}</div>
            </div>
        </div>
    `;
};

import { html, TemplateResult } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { getTestId, TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-tabs";

interface TabSelectEventDetail {
    name: string;
}

export class TabSelectEvent extends CustomEvent<TabSelectEventDetail> {
    constructor(detail: TabSelectEventDetail) {
        super("tabSelect", { detail });
    }
}

export interface Tab {
    classToScrollTo?: string;
    name: string;
    label: string;
    tag?: string;
    template?: (() => TemplateResult) | TemplateResult;
    isUnavailable?: boolean;
    isHighlighted?: boolean;
    withWarning?: boolean;
}

const DEFAULTS: Properties = {
    data: [] as Tab[],
    selectedTab: undefined,
};

export interface Properties {
    data: Tab[];
    selectedTab: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        data: host.data !== undefined ? host.data : DEFAULTS.data,
        selectedTab:
            host.selectedTab && host.data.some((tab) => tab.name === host.selectedTab)
                ? host.selectedTab
                : DEFAULTS.selectedTab,
    };

    // HELPERS

    const getVisibleTabs = (tabs: Tab[]): Tab[] => {
        return tabs.filter((tab) => !tab.isUnavailable);
    };

    const getTemplate = (tab: Tab): TemplateResult | string => {
        if (tab.template) {
            if (tab.template instanceof TemplateResult) {
                return tab.template;
            } else {
                return tab.template();
            }
        } else if (tab.tag) {
            const content = `<${tab.tag}></${tab.tag}>`;
            return html` ${unsafeHTML(content)} `;
        } else {
            return "";
        }
    };

    const handleTabSelect = (e: Event) => {
        host.dispatchEvent(new TabSelectEvent({ name: (e.currentTarget as HTMLDivElement).dataset.tabName }));
    };

    // COMPONENT

    return html`
        <ul class="tab-navigation">
            ${getVisibleTabs(props.data).map((tab) => {
                const tabClassMap = classMap({
                    "active": props.selectedTab === tab.name,
                    "highlighted": tab.isHighlighted && props.selectedTab !== tab.name,
                    "with-warning": tab.withWarning,
                });

                return html`
                    <li
                        data-tab-name=${tab.name}
                        class=${tabClassMap}
                        @click=${handleTabSelect}
                        title=${tab.name}
                        data-test-id=${getTestId(T.ITINERARY.TAB, { c: tab.name })}
                    >
                        ${tab.label}
                    </li>
                    ${tab.isHighlighted && props.selectedTab !== tab.name
                        ? html`
                              <span
                                  class="highlight-icon-container"
                                  data-test-id=${T.ITINERARY.UNFILLED_FORM_HIGHLIGHT_ICON}
                              ></span>
                          `
                        : ""}
                `;
            })}
        </ul>
        ${getVisibleTabs(props.data).map((tab) => {
            const visibleTabClassMap = classMap({
                "tab-panel": true,
                "active": tab.name === props.selectedTab,
                [tab.classToScrollTo]: tab.classToScrollTo !== undefined,
            });

            return html` <div class=${visibleTabClassMap}>${getTemplate(tab)}</div> `;
        })}
    `;
};

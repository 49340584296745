import { INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { baggageErrorClass, baggagePerJourneyErrorClass } from "../useBaggagePage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { BaggageStateJourneyPassenger } from "../../../../component-models/baggage/BaggageState";
import { getInputId, getInputName, paxAmount } from "../../../../component-helpers/BaggageHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { getPassengerName, getPaxLabel } from "../../../../shared/common";
import { useCallback } from "haunted";
import classNames from "classnames";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export const name = "ac-per-journey-per-pax-passenger";

export interface PerJourneyPerPaxPassengerDto {
    baggageSection: BaggageSection;
    context: BaggageContext;
    isMobile: boolean;
    journeyIndex: number;
    pageState: BaggagePageState;
    passengerIndex: number;
}

export interface Props {
    dto: PerJourneyPerPaxPassengerDto;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        dto: host.dto,
    };

    const { formatNumber } = useNumberFormatter();

    const [passengerNames] = useReduxState("booking.passengerNames");

    const currentStateJourney = useMemo(
        () => props.dto.baggageSection.state.journeys.find((j) => j.index === props.dto.journeyIndex),
        [props.dto.baggageSection.state.journeys, props.dto.journeyIndex],
    );

    const currentStatePassenger = useMemo(
        () => currentStateJourney?.passengers.find((p) => p.index === props.dto.passengerIndex),
        [currentStateJourney, props.dto.passengerIndex],
    );

    const baggageQuantity = useMemo(
        () =>
            props.dto.context.getContextJourneyPassengerBaggage({
                bagType: props.dto.baggageSection.bagType,
                journeyIndex: props.dto.journeyIndex,
                passengerIndex: props.dto.passengerIndex,
            }).quantity,
        [props.dto.context, props.dto.baggageSection.bagType, props.dto.passengerIndex, props.dto.journeyIndex],
    );

    const unformattedNextPrice = () =>
        props.dto.baggageSection.perJourneyPerPax.getNextPrice({
            isOriginal: false,
            journey: currentStateJourney,
            passenger: currentStatePassenger,
        });

    const formattedNextPrice = () =>
        formatNumber({ amount: unformattedNextPrice(), leadingSign: true }).replace(" ", "");

    const hasUnfinishedSelectionForPax = useMemo((): boolean => {
        if (!props.dto.baggageSection.state.journeys?.length) return false;

        const isSoldOut = props.dto.context.journeys[props.dto.journeyIndex].passengers[0].oversizedBaggage.isSoldOut;

        if (isSoldOut) return false;

        const pax =
            props.dto.baggageSection.state.journeys[props.dto.journeyIndex].passengers[props.dto.passengerIndex];
        const allPassengers: BaggageStateJourneyPassenger[] = props.dto.baggageSection.state.journeys.reduce(
            (allPax, journey) => allPax.concat(journey.passengers),
            [],
        );

        return allPassengers.some((passenger) => passenger.hasSelected) && !pax.hasSelected;
    }, [props.dto.baggageSection.state, props.dto.context, props.dto.journeyIndex, props.dto.passengerIndex]);

    const canAdd = useMemo(
        () =>
            props.dto.baggageSection.perJourneyPerPax.isAddAvailable({
                journeyIndex: props.dto.journeyIndex,
                passengerIndex: props.dto.passengerIndex,
            }),
        [props.dto.baggageSection.perJourneyPerPax.isAddAvailable, props.dto.journeyIndex, props.dto.passengerIndex],
    );

    const isJourneyValid = useMemo(
        () =>
            props.dto.baggageSection.bagType === "OversizedBaggage"
                ? !hasUnfinishedSelectionForPax
                : currentStateJourney &&
                  currentStatePassenger &&
                  props.dto.baggageSection.handlers.isPassengerValid({
                      journey: currentStateJourney,
                      passenger: currentStatePassenger,
                  }),
        [
            props.dto.baggageSection.handlers.isPassengerValid,
            props.dto.baggageSection.bagType,
            hasUnfinishedSelectionForPax,
            currentStateJourney,
            currentStatePassenger,
        ],
    );

    const showError = useMemo(
        () =>
            props.dto.baggageSection.state.perJourneyPerPaxState === "open" &&
            props.dto.pageState.lastValidationTimestamp &&
            !isJourneyValid,
        [
            props.dto.baggageSection.state.perJourneyPerPaxState,
            props.dto.pageState.lastValidationTimestamp,
            isJourneyValid,
        ],
    );

    const isBaggageSoldOut = useMemo(() => {
        const currentBaggage = props.dto.context.getContextJourneyPassengerBaggage({
            bagType: props.dto.baggageSection.bagType,
            journeyIndex: props.dto.journeyIndex,
            passengerIndex: props.dto.passengerIndex,
        });

        return currentBaggage.isSoldOut;
    }, [
        props.dto.context.getContextJourneyPassengerBaggage,
        props.dto.journeyIndex,
        props.dto.passengerIndex,
        props.dto.baggageSection.bagType,
    ]);

    const paxTypeLabel = (pax: BaggageStateJourneyPassenger, context: BaggageContext) => {
        const contextPax = context.passengers.find((p) => p.index === pax?.index);

        if (!contextPax) return "";

        return html` ${i18next.t("Pasajero")} ${contextPax.index + 1} - ${getPaxLabel(contextPax.type)} `;
    };

    const showQuantitySelector = () => props.dto.baggageSection.bagType !== "CabinBaggage";

    const moveOnToNextPax = useCallback(
        (isRemoveAll: boolean) => {
            if (props.dto.baggageSection.bagType !== "CabinBaggage" && !isRemoveAll) return;

            const numberOfPax = paxAmount(props.dto.baggageSection.state);
            const canMoveOnInSameJourney = props.dto.passengerIndex < numberOfPax - 1;

            props.dto.baggageSection.handlers.closePax({
                JourneyIndices: [props.dto.journeyIndex],
                PaxIndices: [props.dto.passengerIndex],
            });

            const journeyToOpen = canMoveOnInSameJourney
                ? props.dto.journeyIndex
                : props.dto.journeyIndex === OUTBOUND && props.dto.context.journeys.length > 1
                  ? INBOUND
                  : undefined;

            if (journeyToOpen !== undefined) {
                props.dto.baggageSection.handlers.openPax({
                    JourneyIndices: [journeyToOpen],
                    PaxIndices: [canMoveOnInSameJourney ? props.dto.passengerIndex + 1 : 0],
                });
            }
        },
        [
            props.dto.baggageSection.bagType,
            props.dto.baggageSection.handlers.closePax,
            props.dto.baggageSection.handlers.openPax,
            props.dto.baggageSection.state,
            props.dto.context.journeys.length,
            props.dto.journeyIndex,
            props.dto.passengerIndex,
        ],
    );

    const handleRemoveAll = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.dto.baggageSection.handlers.reset({
            PaxIndices: [props.dto.passengerIndex],
            JourneyIndices: [props.dto.journeyIndex],
        });
        moveOnToNextPax(true);
    };

    const handleRemove = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.dto.baggageSection.handlers.remove({
            PaxIndices: [props.dto.passengerIndex],
            JourneyIndices: [props.dto.journeyIndex],
        });
        moveOnToNextPax(false);
    };

    const handleAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.dto.baggageSection.handlers.add({
            PaxIndices: [props.dto.passengerIndex],
            JourneyIndices: [props.dto.journeyIndex],
        });
        moveOnToNextPax(false);
    };

    const handlePaxClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            props.dto.baggageSection.state.journeys[props.dto.journeyIndex].passengers[props.dto.passengerIndex].isOpen
        ) {
            props.dto.baggageSection.handlers.closePax({
                JourneyIndices: [props.dto.journeyIndex],
                PaxIndices: [props.dto.passengerIndex],
            });
        } else {
            props.dto.baggageSection.handlers.openPax({
                JourneyIndices: [props.dto.journeyIndex],
                PaxIndices: [props.dto.passengerIndex],
            });
        }
    };

    const freeCellTemplate = (isMobile: boolean) => {
        const id = getInputId(
            props.dto.baggageSection.bagType,
            props.dto.journeyIndex,
            props.dto.passengerIndex,
            isMobile,
            true,
        );
        const inputName = getInputName(
            props.dto.baggageSection.bagType,
            props.dto.journeyIndex,
            props.dto.passengerIndex,
            isMobile,
        );

        const freeBagName =
            props.dto.baggageSection.bagType === "CabinBaggage"
                ? i18next.t("Mochila o artículo personal")
                : props.dto.baggageSection.bagType === "CheckedBaggage"
                  ? i18next.t("No quiero llevar equipaje facturado")
                  : i18next.t("No quiero llevar equipaje sobredimensionado");

        const isChecked =
            !props.dto.pageState.isLoading && baggageQuantity === 0 && (!canAdd || currentStatePassenger?.hasSelected);
        const isDisabled = !props.dto.baggageSection.perJourneyPerPax.isResetAvailable({
            passengerIndex: props.dto.passengerIndex,
            journeyIndex: props.dto.journeyIndex,
        });

        return html`
            <input
                type="radio"
                name=${inputName}
                id=${id}
                ?disabled=${isDisabled}
                ?checked=${isChecked}
                .checked=${isChecked}
            />
            <label
                for=${id}
                class=${classNames({ checked: isChecked, disabled: isDisabled })}
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_RESET_BUTTON, {
                    m: isMobile,
                    c: props.dto.baggageSection.bagType,
                    j: props.dto.journeyIndex,
                    p: props.dto.passengerIndex,
                })}
                @click=${handleRemoveAll}
            >
                ${freeBagName}
            </label>
        `;
    };

    const paidCellInfoTemplate = (isMobile: boolean) => html`
        <div class="b2-pax-option-info ${showQuantitySelector() && baggageQuantity > 0 ? "hidden-sm-up" : ""}">
            ${baggageQuantity > 0 && showQuantitySelector() ? i18next.t("Agregar otro por") : i18next.t("Agregar por")}
            <span
                data-test-value=${unformattedNextPrice()}
                data-test-id=${getTestId(
                    baggageQuantity > 0 && showQuantitySelector()
                        ? T.BAGGAGE.PER_JOURNEY_PER_PAX_NEXT_PRICE
                        : T.BAGGAGE.PER_JOURNEY_PER_PAX_MINIMUM_PRICE,
                    {
                        m: isMobile,
                        j: props.dto.journeyIndex,
                        p: props.dto.passengerIndex,
                        c: props.dto.baggageSection.bagType,
                    },
                )}
            >
                ${formattedNextPrice()}
            </span>
        </div>
    `;

    const paidCellSelectorTemplate = (isMobile: boolean) => {
        const id = getInputId(
            props.dto.baggageSection.bagType,
            props.dto.journeyIndex,
            props.dto.passengerIndex,
            isMobile,
            false,
        );
        const inputName = getInputName(
            props.dto.baggageSection.bagType,
            props.dto.journeyIndex,
            props.dto.passengerIndex,
            isMobile,
        );

        const paidBagName =
            props.dto.baggageSection.bagType === "CabinBaggage"
                ? i18next.t("Mochila o artículo personal y Equipaje de mano")
                : props.dto.baggageSection.bagType === "CheckedBaggage"
                  ? i18next.t("Equipaje facturado")
                  : i18next.t("Equipaje sobredimensionado");

        return html`
            <input
                type="radio"
                name=${inputName}
                id=${id}
                ?disabled=${!canAdd}
                ?checked=${!props.dto.pageState.isLoading && baggageQuantity > 0}
                .checked=${!props.dto.pageState.isLoading && baggageQuantity > 0}
            />
            <label
                for=${id}
                class=${classNames({ disabled: !canAdd, checked: baggageQuantity > 0 })}
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_ADD_FIRST_BUTTON, {
                    m: isMobile,
                    c: props.dto.baggageSection.bagType,
                    j: props.dto.journeyIndex,
                    p: props.dto.passengerIndex,
                })}
                @click=${handleAdd}
            >
                ${paidBagName}
            </label>
        `;
    };

    const quantityButtonsTemplate = (isMobile: boolean) => html`
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_REMOVE_BUTTON, {
                m: isMobile,
                c: props.dto.baggageSection.bagType,
                j: props.dto.journeyIndex,
                p: props.dto.passengerIndex,
            })}
            .disabled=${!props.dto.baggageSection.perJourneyPerPax.isRemoveAvailable({
                journeyIndex: props.dto.journeyIndex,
                passengerIndex: props.dto.passengerIndex,
            })}
            @click=${handleRemove}
        >
            &minus;
        </button>
        <span
            class="b2-per-booking-quantity"
            data-test-value=${baggageQuantity}
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_QUANTITY, {
                m: isMobile,
                c: props.dto.baggageSection.bagType,
                j: props.dto.journeyIndex,
                p: props.dto.passengerIndex,
            })}
        >
            ${baggageQuantity}
        </span>
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_ADD_BUTTON, {
                m: isMobile,
                c: props.dto.baggageSection.bagType,
                j: props.dto.journeyIndex,
                p: props.dto.passengerIndex,
            })}
            .disabled=${!canAdd}
            @click=${handleAdd}
        >
            &plus;
        </button>
    `;

    const quantityChangeTemplate = (isMobile: boolean) => {
        if (!showQuantitySelector() || baggageQuantity === 0) return "";

        return html` <div class="b2-per-pax-quantity">${quantityButtonsTemplate(isMobile)}</div> `;
    };

    const addAnotherButtonTemplate = () =>
        showQuantitySelector() && baggageQuantity > 0 && canAdd
            ? html`
                  <div class="b2-add-another">
                      ${i18next.t("Agrega otro por")}
                      <span
                          data-test-value=${unformattedNextPrice()}
                          data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_NEXT_PRICE, {
                              j: props.dto.journeyIndex,
                              p: props.dto.passengerIndex,
                              c: props.dto.baggageSection.bagType,
                          })}
                      >
                          ${formattedNextPrice()}
                      </span>
                  </div>
              `
            : "";

    const validationTemplate = () =>
        showError
            ? html`
                  <div
                      class=${classNames(baggageErrorClass, baggagePerJourneyErrorClass)}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          c: props.dto.baggageSection.bagType,
                          j: props.dto.journeyIndex,
                          p: props.dto.passengerIndex,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";

    const mobilePaxOpenerTemplate = () =>
        currentStatePassenger.hasSelected ? mobileSelectedPaxTemplate() : mobileNonSelectedPaxTemplate();

    const mobileSelectedPaxTemplate = () => {
        const paxClassMap = classNames("b2m-pax-opener selected", {
            open: currentStatePassenger.isOpen,
        });

        return html`
            <div
                class=${paxClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PAX_OPENER, {
                    j: props.dto.journeyIndex,
                    p: props.dto.passengerIndex,
                    c: props.dto.baggageSection.bagType,
                })}
                @click=${handlePaxClick}
            >
                <div class="b2m-pax-selected-name">${getPassengerName(passengerNames, props.dto.passengerIndex)}</div>
                <div class="b2m-pax-selected-bags">
                    ${baggageQuantity > 0 ? mobileSelectedPaxPaidTemplate() : mobileSelectedPaxFreeTemplate()}
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const mobileSelectedPaxFreeTemplate = () =>
        props.dto.baggageSection.bagType === "CabinBaggage"
            ? html` <i class="js-icon-bag js-bag-backpack solo"></i> `
            : props.dto.baggageSection.bagType === "CheckedBaggage"
              ? html` <i class="js-icon-bag js-bag-crossed-out-baggage"></i> `
              : html``;

    const mobileSelectedPaxPaidTemplate = () =>
        props.dto.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <i class="js-icon-bag js-bag-backpack"></i> <span class="b2m-plus">+</span>
                  <i class="js-icon-bag js-bag-regular-baggage"></i>
              `
            : props.dto.baggageSection.bagType === "CheckedBaggage"
              ? html` <i class="js-icon-bag js-bag-zipper-baggage"></i> `
              : html` <i class="js-icon-bag js-bag-guitar-surf-golf"></i> `;

    const mobileNonSelectedPaxTemplate = () => {
        const paxClassMap = classNames("b2m-pax-opener", {
            open: currentStatePassenger.isOpen,
        });

        return html`
            <div
                class=${paxClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PAX_OPENER, {
                    j: props.dto.journeyIndex,
                    p: props.dto.passengerIndex,
                    c: props.dto.baggageSection.bagType,
                })}
                @click=${handlePaxClick}
            >
                <div class="b2m-pax-type">${paxTypeLabel(currentStatePassenger, props.dto.context)}</div>
                <div class="b2m-pax-name">
                    ${getPassengerName(passengerNames, props.dto.passengerIndex)}
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
                ${currentStatePassenger.isOpen ? mobileValidationTemplate() : ""}
            </div>
        `;
    };

    const mobileValidationTemplate = () =>
        showError
            ? html`
                  <div
                      class=${classNames(baggageErrorClass, baggagePerJourneyErrorClass)}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          m: true,
                          c: props.dto.baggageSection.bagType,
                          j: props.dto.journeyIndex,
                          p: props.dto.passengerIndex,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";

    const mobilePaxContentTemplate = () => html`
        <div class="b2m-pax-content">${mobilePaxFreeCellTemplate()} ${mobilePaxPaidCellTemplate()}</div>
    `;

    const mobilePaxFreeCellTemplate = () => html`
        <div class="b2m-free-cell relative">
            ${mobileFreeIncludedTemplate()}
            <div class="b2-pax-option">${freeCellTemplate(true)}</div>
        </div>
    `;

    // DEVNOTE: JET-9259, This is a temporary solution (<div class="b2-pax-option-info opacity-0">.</div>), please refactor this later
    const mobileFreeIncludedTemplate = () =>
        props.dto.baggageSection.bagType === "CabinBaggage"
            ? html` <div class="b2-pax-option-info">${i18next.t("Incluido con tu tarifa")}</div> `
            : props.dto.baggageSection.bagType === "CheckedBaggage"
              ? html` <div class="b2-pax-option-info opacity-0">.</div> `
              : "";

    const mobilePaxPaidCellTemplate = () => html`
        <div class=${classNames("b2m-paid-cell", { "b2-sold-out": !canAdd })}>
            ${paidCellInfoTemplate(true)}
            <div class="b2-pax-option">${paidCellSelectorTemplate(true)}</div>
            ${quantityChangeTemplate(true)}
        </div>
    `;

    const desktopTemplate = () => {
        const isResetAvailable = props.dto.baggageSection.perJourneyPerPax.isResetAvailable({
            passengerIndex: props.dto.passengerIndex,
            journeyIndex: props.dto.journeyIndex,
        });

        return !isBaggageSoldOut
            ? html`
                  <div
                      class="b2-pax-grid hidden-xs"
                      data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGER, {
                          c: props.dto.baggageSection.bagType,
                          j: props.dto.journeyIndex,
                          p: props.dto.passengerIndex,
                      })}
                  >
                      <div class="b2-pax-cell">
                          <div class="b2-pax-info">${paxTypeLabel(currentStatePassenger, props.dto.context)}</div>
                          <div class="b2-pax-name">${getPassengerName(passengerNames, props.dto.passengerIndex)}</div>
                      </div>
                      <div class=${classNames("b2-free-cell relative", { "b2-sold-out": !isResetAvailable })}>
                          ${freeIncludedTemplate()}
                          <div class="b2-pax-option">${freeCellTemplate(false)}</div>
                      </div>
                      <div class=${classNames("b2-paid-cell relative", { "b2-sold-out": !canAdd })}>
                          ${paidCellInfoTemplate(false)}
                          <div class="text-right">
                              <div class="b2-pax-option">
                                  ${paidCellSelectorTemplate(false)} ${quantityChangeTemplate(false)}
                              </div>
                              ${addAnotherButtonTemplate()}
                          </div>
                      </div>
                      ${validationTemplate()}
                  </div>
              `
            : html``;
    };

    const freeIncludedTemplate = () =>
        props.dto.baggageSection.bagType === "CabinBaggage"
            ? html` <div class="b2-free-option-info">${i18next.t("Incluido con tu tarifa")}</div> `
            : "";

    const mobileTemplate = () =>
        !isBaggageSoldOut
            ? html`
                  <div
                      class="b2m-pax-grid hidden-sm-up"
                      data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGER, {
                          c: props.dto.baggageSection.bagType,
                          j: props.dto.journeyIndex,
                          p: props.dto.passengerIndex,
                          m: true,
                      })}
                  >
                      ${mobilePaxOpenerTemplate()} ${mobilePaxContentTemplate()}
                  </div>
              `
            : html``;

    return props.dto.isMobile ? mobileTemplate() : desktopTemplate();
};

interface Subscription {
    unsubscribe(): void;
}

type EventHandler<T> = (value: T) => void;

const EMPTY_SUBSCRIPTION: Subscription = {
    unsubscribe: () => undefined,
};

export class PubSub<T> {
    private handlers: Map<object, EventHandler<T>> = new Map<object, EventHandler<T>>();

    public subscribe(handler: EventHandler<T>): Subscription {
        if (!handler) {
            return EMPTY_SUBSCRIPTION;
        }
        const handlerKey = Object.create(null);
        this.handlers.set(handlerKey, handler);
        return {
            unsubscribe: () => {
                if (this.handlers && handlerKey) {
                    this.handlers.delete(handlerKey);
                }
            },
        };
    }

    public publish(value: T): void {
        if (this.handlers.size === 0) {
            return;
        }
        this.handlers.forEach((handler) => handler(value));
    }

    public clear(): void {
        this.handlers.clear();
        this.handlers = new Map<object, EventHandler<T>>();
    }
}

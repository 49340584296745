import i18next from "i18next";
import { html } from "haunted";
import { showLoader } from "../../../../shared/common";
import { ROUTES } from "../../../../shared/apiRoutes";
import { useModal } from "../../../shared/useModal";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { useAjax } from "../../../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export interface Props {
    onCancel: () => void;
    onContinue: (seat: ExtendedApiSeat) => void;
}

export const useBancoEstadoAgentDifferenceModal = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const { ajaxRequest } = useAjax();

    const [seat, setSeat] = useState<ExtendedApiSeat>(undefined);

    const open = (seat: ExtendedApiSeat) => {
        setSeat(seat);
        modal.open();
    };

    const handleContinue = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();
        props.onContinue(seat);
    };

    const handleLogin = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (userContext.isLoggedIn) {
            const loader = showLoader({});
            const response = await ajaxRequest({
                loader,
                method: "GET",
                url: ROUTES.LogoutInline,
            });

            // FIXME Add type
            const isSuccess = JSON.parse(response.data).isLogoutSuccessful;

            if (isSuccess) {
                window.location.href = "/V2/Login?bancoe=1";
            }
        } else {
            window.location.href = "/V2/Login?bancoe=1";
        }
    };

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        modal.close();
        props.onCancel();
    };

    //TEMPLATES:

    const buttonContainerTemplate = () => html`
        <div class="modal-button-container modification-buttons-container">
            <button class="rounded-secondary-btn seat-for-all" @click=${handleLogin}>${i18next.t("BE2-LogIn")}</button>
            <button
                class="rounded-primary-btn seat-for-all"
                data-test-id="agent-difference-continue-button"
                @click=${handleContinue}
            >
                ${i18next.t("V2-Continue")}
            </button>
        </div>
    `;

    const cancelLinkTemplate = () => html`
        <div class="cancel-link-container">
            <a class="cancel-link" @click=${handleCancel}>${i18next.t("V2-Cancel")}</a>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body text-center">
            <span class="brand-secondary-text">${i18next.t("BE2-AgentDifferenceWarning")}</span>
            ${buttonContainerTemplate()} ${cancelLinkTemplate()}
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("V2-AreYouSure") },
        overlay: { classNames: "agent-difference-modal" },
    });

    return { ...modal, open };
};

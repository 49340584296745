import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ItineraryStandaloneDCPageViewModel } from "../../component-models/itinerary/ItineraryStandaloneDCPageViewModel";
import { PaymentResult } from "../../component-models/PaymentResult";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useAppContext } from "../../managers/useAppContext";
import { useMediaObtainer } from "./useMediaObtainer";
import { usePendingPaymentBanner } from "../payment/usePendingPaymentBanner";
import { useRef } from "haunted";
import { getParsedProperty } from "../../shared/common";

export const name = "ac-itinerary-standalone-dc-page";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
    model: string;
}

export interface Props {
    model: ItineraryStandaloneDCPageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: getParsedProperty<ItineraryStandaloneDCPageViewModel>(host.model),
    };

    // HELPERS

    const getArea = () => printedArea?.current;

    const printedArea = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();

    const mediaObtainer = useMediaObtainer(getArea, "transaccion");

    // EVENT HANDLERS

    // COMPONENTS

    const pendingPaymentBanner = usePendingPaymentBanner({
        isActive: !props.model.IsPaymentSuccessful,
        isDeclined: props.model.IsDeclined,
        isFlightless: true,
        paymentDescription: props.model.PaymentDescription,
        paymentId: props.model.PaymentId,
        pnr: props.model.Pnr,
        refreshSeconds: props.model.RefreshSeconds,
        retrieveUrl: props.model.RetrieveUrl,
    });

    //TEMPLATES

    const paymentResultsTemplate = () =>
        props.model.PaymentResults.map((paymentResult: PaymentResult) => {
            return html`
                <ac-transaction-logger
                    .logWithTealium=${true}
                    .paymentResultData=${paymentResult}
                    .tealiumProperties=${props.model.TealiumProperties}
                ></ac-transaction-logger>
            `;
        });

    const titleTemplate = () =>
        props.model.FirstName
            ? i18next.t("¡Felicidades {{firstName}}! Ya eres parte del Club de Descuentos", {
                  firstName: props.model.FirstName,
              })
            : i18next.t("¡Felicidades! Ya eres parte del Club de Descuentos");

    const imageTemplate = () => {
        switch (appContext.Culture) {
            case "pt-BR":
                return html`<img src="/Images/DcStandalone/dc-logo-2-pt.png" />`;
            case "en-US":
                return html`<img src="/Images/DcStandalone/dc-logo-2-en.png" />`;
            default:
                return html`<img src="/Images/DcStandalone/dc-logo-2.png" />`;
        }
    };

    const transactionInfoTemplate = () => html`
        <div id="mainContentWrapper" filename="transaccion" area="ts-printed-area">
            <section class="booking-wrapper transaction-wrapper standalone-dc">
                <header class="transactions-header">
                    <i class="js-circle-tick js-icon title-icon standalone-dc"></i>
                    <div class="title full-width-title">
                        <h2 class="main-title standalone-dc">${titleTemplate()}</h2>
                    </div>
                </header>

                <div ref=${ref(printedArea)} class="ts-printed-area">${paymentResultsTemplate()}</div>
                <div class="transaction-button-container standalone-dc no-print">
                    <a
                        class="rounded-transaction-btn print"
                        data-test-id=${T.ITINERARY.STANDALONE_DC_PRINT_BUTTON}
                        @click=${() => mediaObtainer?.print()}
                        >${i18next.t("Print")}</a
                    >
                    <a
                        class="rounded-transaction-btn download"
                        data-test-id=${T.ITINERARY.STANDALONE_DC_DOWNLOAD_BUTTON}
                        @click=${() => mediaObtainer?.download()}
                        >${i18next.t("Download")}</a
                    >
                </div>
            </section>
        </div>
    `;

    const benefitsSectionTemplate = () => html`
        <section class="standalone-dc-itinerary-wrapper no-print">
            ${imageTemplate()}
            <div class="standalone-dc-itinerary-banner">
                <i class="js-icon js-medal"></i>
                <div class="standalone-dc-itinerary-banner-text">
                    ${unsafeHTML(
                        i18next.t("Utiliza ya tus beneficios del {{-spanStart}}Club de Descuentos{{-spanEnd}}", {
                            spanStart: "<span>",
                            spanEnd: "</span>",
                        }),
                    )}
                </div>
            </div>
            <ul class="standalone-dc-itinerary-list">
                <li>
                    ${i18next.t(
                        "Descuento mínimo de {{-amount}} por tramo, para tarifas sobre {{-amountOver}}",
                        {
                            amount: props.model.DiscountMin,
                            amountOver: props.model.DiscountOver,
                        },
                    )}
                </li>
                <li>
                    ${i18next.t("Descuento mínimo de {{-amount}} en equipaje de mano o facturado", {
                        amount: props.model.BaggageMin,
                    })}
                </li>
                <li>${i18next.t("Descuentos aplican para ti y tus acompañantes, según membresía.")}</li>
                <li>${i18next.t("Acceso a promociones especiales.")}</li>
            </ul>
        </section>
    `;

    const buttonTemplate = () => html`
        <div class="standalone-dc-itinerary-btn-container no-print">
            <a class="rounded-primary-btn" href="/" data-test-id=${T.ITINERARY.STANDALONE_DC_SEARCH_FLIGHT_BUTTON}
                >${i18next.t("Comprar vuelos")}</a
            >
        </div>
    `;

    return props.model.IsPaymentSuccessful
        ? html` ${transactionInfoTemplate()} ${benefitsSectionTemplate()}${buttonTemplate()}`
        : pendingPaymentBanner.htmlTemplate();
};

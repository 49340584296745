import { FlightOptionModel } from "./../../component-models/flight/FlightOptionModel";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ApiItineraryViewModelV2 } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { useEffect, useMemo, useState } from "./../../shared/haunted/CustomHooks";
import { DEFAULT_DATE_FORMAT, INBOUND, OUTBOUND, SUCCESSFUL_FLIGHT_MOVE } from "./../../shared/commonConstants";
import { html } from "lit-html";
import dayjs, { Dayjs } from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import {
    getAntiForgeryTokenFromHtml,
    getMomentInstance,
    getParsedProperty,
    getRequestBodyFromInputs,
    showLoader,
    toBoolean,
} from "../../shared/common";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useRef } from "haunted";
import i18next from "i18next";
import { ref } from "../../directives/ref";
import { validate } from "../../shared/form-validation";
import { StationSettings } from "../../component-models/StationSettings";
import { ROUTES } from "../../shared/apiRoutes";
import { FlightMoveTripModel } from "../../component-models/FlightMoveTripModel";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { mapToFakeFlightPageViewModel } from "../../component-mappers/FlightMoveMapper";
import { classMap } from "lit-html/directives/class-map";
import { useFlightMoveDateSelector } from "./useFlightMoveDateSelector";
import { useForm } from "../../shared/customHooks/useForm/useForm";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { useLegSelector } from "./useLegSelector";
import { useFlightMoveFlightSelector } from "./useFlightMoveFlightSelector";
import { createFormHtmlElement } from "../../component-helpers/FormHelper";
import { useFlightMovePageContext } from "./useFlightMovePageContext";
import { useSameDateModal } from "./useSameDateModal";
import { useNoLongerAvailableModal } from "./useNoLongerAvailableModal";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-flight-move-page";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "arrival-date",
    "arrival-station-code",
    "bundle-color",
    "bundle-img",
    "can-inbound-journey-be-moved",
    "can-outbound-journey-be-moved",
    "departure-date",
    "departure-station-code",
    "itinerary-model",
    "move-back-inbound-journey",
    "move-back-outbound-journey",
    "move-forward-inbound-journey",
    "move-forward-outbound-journey",
    "number-of-journeys",
    "refund-url",
    "station-settings",
];

export interface Attributes {
    "anti-forgery-token": string;
    "arrival-date": string;
    "arrival-station-code": string;
    "bundle-color": string;
    "bundle-img": string;
    "can-inbound-journey-be-moved": string;
    "can-outbound-journey-be-moved": string;
    "departure-date": string;
    "departure-station-code": string;
    "itinerary-model": string;
    "move-back-inbound-journey": string;
    "move-back-outbound-journey": string;
    "move-forward-inbound-journey": string;
    "move-forward-outbound-journey": string;
    "number-of-journeys": string;
    "refund-url": string;
    "station-settings": string;
}

export interface Props {
    antiForgeryToken: string;
    arrivalDate: dayjs.Dayjs;
    arrivalStationCode: string;
    bundleColor: string;
    bundleImg: string;
    canInboundJourneyBeMoved: boolean;
    canOutboundJourneyBeMoved: boolean;
    departureDate: dayjs.Dayjs;
    departureStationCode: string;
    itineraryModel: ApiItineraryViewModelV2;
    moveBackInboundJourney: dayjs.Dayjs;
    moveBackOutboundJourney: dayjs.Dayjs;
    moveForwardInboundJourney: dayjs.Dayjs;
    moveForwardOutboundJourney: dayjs.Dayjs;
    numberOfJourneys: number;
    refundUrl: string;
    stationSettings: StationSettings;
}

export type FlightMovePageState = "LegSelect" | "DateSelect" | "FlightSelect";

export const Component: HauntedFunc<Props> = (host) => {
    // DEVNOTE This is to make it MAC safe. If any journey has the original station code, that's the journey we need.
    // DEVNOTE We cannot use indices, as the user might only change one journey of the two.
    const journeyByDepartureStationCode = (journeys: FlightMoveTripModel[], departureStationCode: string) =>
        journeys.find((journey) =>
            journey.FlightDates.some((flightDate) =>
                flightDate.FlightDetails.some((flightDetail) => flightDetail.OriginIata === departureStationCode),
            ),
        );

    const validateProperty = (key: keyof Props) =>
        host[key] && typeof host[key] === "string" && (host[key] as string).trim().length > 0;

    const getModel = () => {
        const model = validateProperty("itineraryModel")
            ? getParsedProperty<ApiItineraryViewModelV2>(host.itineraryModel)
            : undefined;

        return model;
    };

    const moveBackOutboundJourney = useMemo(() => {
        let journey =
            typeof host.moveBackOutboundJourney === "string"
                ? dayjs(host.moveBackOutboundJourney, DEFAULT_DATE_FORMAT)
                : undefined;

        if (journey && journey.isSameOrBefore(dayjs(), "day")) {
            journey = dayjs().add(1, "day");
        }

        return journey;
    }, [host.moveBackOutboundJourney]);

    const moveBackInboundJourney = useMemo(() => {
        let journey =
            typeof host.moveBackInboundJourney === "string"
                ? dayjs(host.moveBackInboundJourney, DEFAULT_DATE_FORMAT)
                : undefined;

        if (journey && journey.isSameOrBefore(dayjs(), "day")) {
            journey = dayjs().add(1, "day");
        }

        return journey;
    }, [host.moveBackInboundJourney]);

    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        arrivalDate: typeof host.arrivalDate === "string" ? dayjs(host.arrivalDate, DEFAULT_DATE_FORMAT) : undefined,
        arrivalStationCode: host.arrivalStationCode,
        bundleColor: host.bundleColor,
        bundleImg: host.bundleImg,
        canInboundJourneyBeMoved: toBoolean(host.canInboundJourneyBeMoved),
        canOutboundJourneyBeMoved: toBoolean(host.canOutboundJourneyBeMoved),
        departureDate:
            typeof host.departureDate === "string" ? dayjs(host.departureDate, DEFAULT_DATE_FORMAT) : undefined,
        departureStationCode: host.departureStationCode,
        itineraryModel: getModel(),
        moveBackInboundJourney,
        moveBackOutboundJourney,
        moveForwardInboundJourney:
            typeof host.moveForwardInboundJourney === "string"
                ? dayjs(host.moveForwardInboundJourney, DEFAULT_DATE_FORMAT)
                : undefined,
        moveForwardOutboundJourney:
            typeof host.moveForwardOutboundJourney === "string"
                ? dayjs(host.moveForwardOutboundJourney, DEFAULT_DATE_FORMAT)
                : undefined,
        numberOfJourneys: Number(host.numberOfJourneys),
        refundUrl: host.refundUrl,
        stationSettings:
            typeof host.stationSettings === "string"
                ? getParsedProperty<StationSettings>(host.stationSettings)
                : host.stationSettings,
    };

    const root = useRef<HTMLFormElement>(undefined);

    const { ajaxRequest, ajaxJsonRequest } = useAjax();

    const [antiForgeryToken, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [fromSellKeyInbound, setFromSellKeyInbound] = useState<string>(undefined);
    const [fromSellKeyOutbound, setFromSellKeyOutbound] = useState<string>(undefined);
    const [inboundFareSellKey, setInboundFareSellKey] = useState<string>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [outboundFareSellKey, setOutboundFareSellKey] = useState<string>(undefined);
    const [pageHistory, setPageHistory] = useState<FlightMovePageState[]>(["LegSelect"]);
    const [selectedJourneys, setSelectedJourneys] = useState<number[]>([]);
    const [viewModel, setViewModel] = useState<FlightPageViewModel>(undefined);

    const dateSelector = useFlightMoveDateSelector({
        arrivalDate: getMomentInstance(props.arrivalDate),
        arrivalStationCode: props.arrivalStationCode,
        canInboundJourneyBeMoved: props.canInboundJourneyBeMoved,
        canOutboundJourneyBeMoved:
            props.canOutboundJourneyBeMoved &&
            !props.itineraryModel.Journeys.find((j) => j.JourneyIndex === OUTBOUND)?.IsFlown,
        departureDate: getMomentInstance(props.departureDate),
        departureStationCode: props.departureStationCode,
        moveBackInboundJourney: props.moveBackInboundJourney,
        moveBackOutboundJourney: props.moveBackOutboundJourney,
        moveForwardInboundJourney: props.moveForwardInboundJourney,
        moveForwardOutboundJourney: props.moveForwardOutboundJourney,
        numberOfJourneys: props.numberOfJourneys,
        pageHistory,
        root: root.current,
        selectedJourneys,
        stationSettings: props.stationSettings,
        handleActivate: () => handleActivate("DateSelect"),
        handleDateSubmit: (outboundDate: Dayjs, inboundDate: Dayjs) => handleDateSubmit(outboundDate, inboundDate),
    });

    const [currentOutboundWeekday, setCurrentOutboundWeekday] = useState<dayjs.Dayjs>(
        dateSelector.selectedOutboundDate ||
            dayjs(viewModel?.Journeys.find((j) => j.TripIndex === OUTBOUND)?.FlightDetails[0].DepartureDate),
    );

    const [currentInboundWeekday, setCurrentInboundWeekday] = useState<dayjs.Dayjs>(
        dateSelector.selectedInboundDate ||
            dayjs(viewModel?.Journeys.find((j) => j.TripIndex === INBOUND)?.FlightDetails[0].DepartureDate),
    );

    const context = useFlightMovePageContext({
        currentInboundWeekday,
        currentOutboundWeekday,
        disabledInboundDates: dateSelector.disabledInboundDates,
        disabledOutboundDates: dateSelector.disabledOutboundDates,
        maxInboundDate: dateSelector.maxInboundDate,
        maxOutboundDate: dateSelector.maxOutboundDate,
        minInboundDate: dateSelector.minInboundDate,
        minOutboundDate: dateSelector.minOutboundDate,
        rootElem: root.current,
        viewModel,
        selectedJourneys,
        bundleColor: props.bundleColor,
        bundleImg: props.bundleImg,
    });

    const legSelector = useLegSelector({
        itineraryModel: props.itineraryModel,
        pageHistory,
        selectedJourneys,
        handleActivate: () => handleActivate("LegSelect"),
        handleJourneyClick: (journeyIndex: number) => handleJourneyClick(journeyIndex),
        handleLegSubmit: () => handleLegSubmit(),
    });

    const flightSelector = useFlightMoveFlightSelector({
        bundleColor: props.bundleColor,
        bundleImg: props.bundleImg,
        context,
        disabledInboundDates: dateSelector.disabledInboundDates,
        disabledOutboundDates: dateSelector.disabledOutboundDates,
        firstAvailableInboundDate: dateSelector.minInboundDate,
        firstAvailableOutboundDate: dateSelector.minOutboundDate,
        isValidated,
        lastAvailableInboundDate: dateSelector.maxInboundDate,
        lastAvailableOutboundDate: dateSelector.maxOutboundDate,
        pageHistory,
        root: root.current,
        selectedInboundDate: dateSelector.selectedInboundDate,
        selectedJourneys,
        selectedOutboundDate: dateSelector.selectedOutboundDate,
        stationSettings: props.stationSettings,
        handleBundleSelect: () => handleBundleSelect(),
        handleBundleUpgrade: () => handleBundleUpgrade(),
        handleFlightSubmit: () => handleFlightSubmit(),
        handleResetPerJourney: (
            journeyIndex: number,
            outboundWeekSelector: HTMLDivElement,
            inboundWeekSelector: HTMLDivElement,
        ) => handleResetPerJourney(journeyIndex, outboundWeekSelector, inboundWeekSelector),
        handleJourneySelect: (
            flightOption: FlightOptionModel,
            outboundWeekSelector: HTMLDivElement,
            inboundWeekSelector: HTMLDivElement,
        ) => handleJourneySelect(flightOption, outboundWeekSelector, inboundWeekSelector),
        handleWeekdayClick: (date: dayjs.Dayjs, journeyIndex: number) => handleWeekdayClick(date, journeyIndex),
        setCurrentInboundWeekday,
        setCurrentOutboundWeekday,
    });

    const dateForm = useForm({ customAttributes: [] });

    const sameDateModal = useSameDateModal();

    const noLongerAvailableModal = useNoLongerAvailableModal({ refundUrl: props.refundUrl });

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        window.onpopstate = () => {
            setPageHistory((currentState) => currentState.slice(0, currentState.length - 1));
        };
    };

    const handleScroll = () => {
        if (currentPage()) {
            window.setTimeout(() => {
                if (currentPage() === "LegSelect") {
                    ScrollHelper.scrollToTop();
                } else {
                    const element = document.body.querySelector(".flight-move-page.active") as HTMLDivElement;

                    ScrollHelper.scrollToElementAndHideNav({
                        element,
                        yOffset: 40,
                        timeOffset: 0,
                    });
                }
            }, 500);
        }
    };

    const currentPage = () => (pageHistory ? pageHistory[pageHistory.length - 1] : "");

    const hasOutboundFlightData = () =>
        context.flightState.selectedOutboundFlight &&
        props.canOutboundJourneyBeMoved &&
        props.itineraryModel.Journeys.some((j) => j.JourneyIndex === OUTBOUND) &&
        !props.itineraryModel.Journeys.find((j) => j.JourneyIndex === OUTBOUND)?.IsFlown &&
        selectedJourneys.includes(OUTBOUND);

    const hasInboundFlightData = () =>
        context.flightState.selectedInboundFlight &&
        props.canInboundJourneyBeMoved &&
        selectedJourneys.includes(INBOUND);

    const needsOutboundFlightData = () => selectedJourneys.includes(OUTBOUND);
    const needsInboundFlightData = () => selectedJourneys.includes(INBOUND);

    const createFinalHiddenForm = () => {
        const body = makeFlightSubmissionBody();
        return createFormHtmlElement(ROUTES.FlightMoveSelectFlightPost, body);
    };

    const getBodyWithAntiForgeryToken = (): any => {
        return {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
        };
    };

    const makeDateSubmissionBody = (
        origin: string,
        destination: string,
        outboundDate: dayjs.Dayjs,
        inboundDate: dayjs.Dayjs,
    ) => {
        let body = getBodyWithAntiForgeryToken();

        if (
            props.canOutboundJourneyBeMoved &&
            !props.itineraryModel.Journeys.find((j) => j.JourneyIndex === OUTBOUND)?.IsFlown
        ) {
            body = {
                ...body,
                "MoveFlights[0].Origin": origin,
                "MoveFlights[0].Destination": destination,
                "MoveFlights[0].DepartureDate": outboundDate.format(DEFAULT_DATE_FORMAT),
                "MoveFlights[0].JourneyIndex": "0",
            };
        }

        if (props.numberOfJourneys > 1 && props.canInboundJourneyBeMoved) {
            const index =
                props.canOutboundJourneyBeMoved &&
                !props.itineraryModel.Journeys.find((j) => j.JourneyIndex === OUTBOUND)?.IsFlown
                    ? 1
                    : 0;

            body = {
                ...body,
                [`MoveFlights[${index}].Origin`]: destination,
                [`MoveFlights[${index}].Destination`]: origin,
                [`MoveFlights[${index}].DepartureDate`]: inboundDate.format(DEFAULT_DATE_FORMAT),
                [`MoveFlights[${index}].JourneyIndex`]: "1",
            };
        }

        return body;
    };

    const makeFlightSubmissionBody = () => {
        let body = getBodyWithAntiForgeryToken();

        if (hasOutboundFlightData()) {
            body = {
                ...body,
                "MarketFareKeys[0]": `${outboundFareSellKey}|${context.flightState.selectedOutboundFlight.SellKey}|None|${fromSellKeyOutbound}|Move`,
            };
        }

        if (hasInboundFlightData()) {
            const index = hasOutboundFlightData() ? 1 : 0;
            body = {
                ...body,
                [`MarketFareKeys[${index}]`]: `${inboundFareSellKey}|${context.flightState.selectedInboundFlight.SellKey}|None|${fromSellKeyInbound}|Move`,
            };
        }

        return body;
    };

    const canSubmitFlight = () =>
        (!needsOutboundFlightData() || hasOutboundFlightData()) &&
        (!needsInboundFlightData() || hasInboundFlightData());

    const successfulFlightMove = () => {
        const newUrl = `${ROUTES.PageItinerary}?${SUCCESSFUL_FLIGHT_MOVE}`;

        window.location.href = newUrl;
    };

    const handleFlightSubmit = async () => {
        setIsValidated(true);

        if (canSubmitFlight()) {
            const form = createFinalHiddenForm();
            document.body.appendChild(form);

            showLoader({});

            await ajaxRequest({
                container: document.body,
                body: getRequestBodyFromInputs(form),
                form,
                onResponseCode: {
                    200: () => successfulFlightMove(),
                    400: () => noLongerAvailableModal.open(),
                    500: () => noLongerAvailableModal.open(),
                },
            });
        } else {
            ScrollHelper.scrollToFirstFlightPageError();
        }
    };

    const handleLegSubmit = () => {
        setPageHistory([...pageHistory, "DateSelect"]);
        history.pushState({}, "SelectDate", ROUTES.FlightMoveFakeSelectDate);
    };

    const isOutboundDateAfterInboundDate = (outboundDate: dayjs.Dayjs, inboundDate: dayjs.Dayjs) =>
        inboundDate && outboundDate && inboundDate.isBefore(outboundDate, "day");

    const handleWeekdayClick = (date: dayjs.Dayjs, journeyIndex: number) => {
        const isChangedDateOutbound = journeyIndex === OUTBOUND;

        const outboundDate = isChangedDateOutbound ? date : dateSelector.selectedOutboundDate;
        const inboundDate = isChangedDateOutbound ? dateSelector.selectedInboundDate : date;

        if (isOutboundDateAfterInboundDate(outboundDate, inboundDate)) {
            dateSelector.setSelectedOutboundDate(outboundDate);
            dateSelector.setSelectedOutboundDate(outboundDate);
            history.back();
        } else {
            handleDateSubmit(outboundDate, inboundDate);
        }
    };

    const handleDateSubmit = async (selectedOutboundDate: dayjs.Dayjs, selectedInboundDate: dayjs.Dayjs) => {
        dateForm.validate();

        if (!validate(root.current)) {
            return;
        }

        if (
            props.itineraryModel.Journeys.length > 1 &&
            selectedInboundDate &&
            selectedOutboundDate &&
            selectedOutboundDate.isSame(selectedInboundDate, "day")
        ) {
            sameDateModal.open();
            if (currentPage() === "FlightSelect") history.back();
            return;
        }

        // DEVNOTE Should come first so that loader can be shown
        setPageHistory([...pageHistory, "FlightSelect"]);
        history.pushState({}, "SelectFlight", ROUTES.FlightMoveFakeSelectFlight);

        // DEVNOTE Needed for loader animation
        window.setTimeout(async () => {
            const result = await ajaxJsonRequest<{ Trips: FlightMoveTripModel[] }>({
                loader: showLoader({ name: "flight-move-flight-selector" }),
                url: ROUTES.FlightMoveSelectDatePost,
                body: makeDateSubmissionBody(
                    dateSelector.selectedOrigin,
                    dateSelector.selectedDestination,
                    selectedOutboundDate,
                    selectedInboundDate,
                ),
                onResponseCode: {
                    400: () => noLongerAvailableModal.open(),
                    500: () => noLongerAvailableModal.open(),
                },
            });

            dateSelector.setSelectedOutboundDate(selectedOutboundDate);

            dateSelector.setSelectedInboundDate(selectedInboundDate ? selectedInboundDate : undefined);

            const newViewModel: FlightPageViewModel = mapToFakeFlightPageViewModel({
                trips: result.data.Trips,
                selectedJourneys,
                itineraryModel: props.itineraryModel,
                selectedOutboundDate,
                disabledOutboundDates: dateSelector.disabledOutboundDates,
                selectedInboundDate,
                disabledInboundDates: dateSelector.disabledInboundDates,
                lastOutboundDate: dateSelector.maxOutboundDate,
                lastInboundDate: dateSelector.maxInboundDate,
            });

            setViewModel(newViewModel);

            const outboundJourneyInResult = journeyByDepartureStationCode(
                result.data.Trips,
                props.departureStationCode,
            );
            const inboundJourneyInResult = journeyByDepartureStationCode(result.data.Trips, props.arrivalStationCode);

            setFromSellKeyOutbound(outboundJourneyInResult?.FromSellKey);
            setFromSellKeyInbound(inboundJourneyInResult?.FromSellKey);
            setOutboundFareSellKey(outboundJourneyInResult?.FlightDates[0].Flights[0].Fares[0].SellKey);
            setInboundFareSellKey(inboundJourneyInResult?.FlightDates[0].Flights[0].Fares[0].SellKey);
        }, 100);
    };

    const handleJourneyClick = (journeyIndex: number) => {
        if (selectedJourneys.includes(journeyIndex)) {
            setSelectedJourneys([...selectedJourneys.filter((item) => item !== journeyIndex)]);
        } else {
            setSelectedJourneys([...selectedJourneys, journeyIndex]);
        }
    };

    const handleBundleSelect = () => {
        // TODO OCH Not used, but will be when voluntary change comes
    };

    const handleBundleUpgrade = () => {
        // TODO OCH Not used, but will be when voluntary change comes
    };

    const handleResetPerJourney = (
        journeyIndex: number,
        outboundWeekSelector: HTMLDivElement,
        inboundWeekSelector: HTMLDivElement,
    ) => {
        context.resetFlight(journeyIndex, journeyIndex === OUTBOUND ? outboundWeekSelector : inboundWeekSelector);
    };

    const handleJourneySelect = (
        flightOption: FlightOptionModel,
        outboundWeekSelector: HTMLDivElement,
        inboundWeekSelector: HTMLDivElement,
    ) => {
        context.selectFlight({
            feeType: "Smart",
            flightOption,
            referenceObject: flightOption.JourneyIndex === OUTBOUND ? outboundWeekSelector : inboundWeekSelector,
        });
    };

    const handleActivate = (state: FlightMovePageState) => {
        const indexOfSection = pageHistory.indexOf(state);
        let stepsToRetract = pageHistory.length - indexOfSection - 1;

        while (stepsToRetract > 0) {
            history.go(-1);
            stepsToRetract--;
        }

        // DEVNOTE We have to reset the date to prevent changing the date, then unselecting the leg, and
        // then ending up with a non-changeable new date on the date selector
        if (state === "LegSelect") {
            dateSelector.setSelectedOutboundDate(getMomentInstance(props.departureDate));
            dateSelector.setSelectedInboundDate(getMomentInstance(props.arrivalDate));
        }
    };

    useEffect(() => {
        if (dateSelector.selectedOutboundDate) {
            setCurrentOutboundWeekday(dateSelector.selectedOutboundDate);
        }
    }, [dateSelector.selectedOutboundDate]);

    useEffect(() => {
        if (dateSelector.selectedInboundDate) {
            setCurrentInboundWeekday(dateSelector.selectedInboundDate);
        }
    }, [dateSelector.selectedInboundDate]);

    useEffect(() => dateForm.init(root.current), [root.current]);
    useEffect(handleScroll, [currentPage()]);
    useEffect(init, []);

    const flightsTemplate = () => (pageHistory.includes("FlightSelect") ? flightSelector.htmlTemplate() : "");

    const headerTemplate = () => html`
        <div class="i2-tab-title">
            <i class="js-icon-it js-it-edit"></i>
            <div>${i18next.t("Modifica tu reserva")}</div>
        </div>
    `;

    const breadcrumbsTemplate = () => {
        const legSelectClassMap = classMap({
            active: currentPage() === "LegSelect",
        });

        const dateSelectClassMap = classMap({
            active: currentPage() === "DateSelect",
        });

        const flightSelectClassMap = classMap({
            active: currentPage() === "FlightSelect",
        });

        return html`
            <ul class="flight-move-breadcrumbs">
                <li class=${legSelectClassMap}>
                    <span>1</span>
                    <div>${i18next.t("Selección de tramos")}</div>
                </li>
                <li class=${dateSelectClassMap}>
                    <span>2</span>
                    <div>${i18next.t("Selección de nuevas fechas")}</div>
                </li>
                <li class=${flightSelectClassMap}>
                    <span>3</span>
                    <div>${i18next.t("Selección de nuevo itinerario")}</div>
                </li>
                <!-- DEVNOTE There is no confirmation page, result is displayed on the itinerary page -->
                <li>
                    <span>4</span>
                    <div>${i18next.t("Confirmación")}</div>
                </li>
            </ul>
        `;
    };

    return html`
        <div class="flight-move-page-content-wrapper" id="mainContentWrapper" ref=${ref(root)}>
            <section class="booking-wrapper flight-move-page">${headerTemplate()}</section>
            <section class="booking-wrapper flight-move-page">${breadcrumbsTemplate()}</section>
            ${legSelector.htmlTemplate()} ${dateSelector.htmlTemplate()} ${flightsTemplate()}
        </div>
        ${sameDateModal.htmlTemplate()} ${noLongerAvailableModal.htmlTemplate()}
    `;
};

export interface RefundDropdownElement {
    name: string;
    isSelected: boolean;
}

export interface DropDownElementWithLabel {
    text: string;
    value: string;
}

export interface RefundInfoDocType {
    Id: number;
    IdDocType: string;
    Country: string;
}

export interface RefundInfoAccountType {
    Id: number;
    AccountType: string;
    Country: string;
}

export interface RefundInfoBankNames {
    Id: number;
    BankName: string;
    Country: string;
}

export const PRIORITISED_COUNTRIES = ["CL", "PE", "CO", "AR", "BR", "UY", "EC"];

export const REFUND_INFO_DOC_TYPES: RefundInfoDocType[] = [
    {
        IdDocType: "C. Ciudadanía",
        Country: "CO",
    },
    {
        IdDocType: "NIT",
        Country: "CO",
    },
    {
        IdDocType: "C. Extranjería",
        Country: "CO",
    },
    {
        IdDocType: "Pasaporte",
        Country: "CO",
    },
    {
        IdDocType: "Rut",
        Country: "CL",
    },
    {
        IdDocType: "CUIT/CUIL",
        Country: "AR",
    },
    {
        IdDocType: "CPF",
        Country: "BR",
    },
    {
        IdDocType: "RUC",
        Country: "PE",
    },
    {
        IdDocType: "RUT",
        Country: "UY",
    },
    {
        IdDocType: "Cédula de identidad",
        Country: "UY",
    },
    {
        IdDocType: "DNI/CI",
        Country: "EC",
    },
    {
        IdDocType: "RUC",
        Country: "EC",
    },
].map((dt, i) => {
    return { ...dt, Id: i };
});

export const REFUND_INFO_ACCOUNT_TYPES: RefundInfoAccountType[] = [
    {
        AccountType: "Cuenta Corriente",
        Country: "CL",
    },
    {
        AccountType: "Cuenta Vista",
        Country: "CL",
    },
    {
        AccountType: "Cuenta de Ahorro",
        Country: "CL",
    },
    {
        AccountType: "Cuenta Corriente",
        Country: "AR",
    },
    {
        AccountType: "Caja de Ahorro",
        Country: "AR",
    },
    {
        AccountType: "Cuenta Corriente",
        Country: "CO",
    },
    {
        AccountType: "Cuenta de Ahorros",
        Country: "CO",
    },
    {
        AccountType: "Conta Corrente",
        Country: "BR",
    },
    {
        AccountType: "Conta Poupança",
        Country: "BR",
    },
    {
        AccountType: "Cuenta Corriente",
        Country: "PE",
    },
    {
        AccountType: "Cuenta de Ahorros",
        Country: "PE",
    },
    {
        AccountType: "Cuenta Nómina",
        Country: "PE",
    },
    {
        AccountType: "Cuenta de Ahorros",
        Country: "UY",
    },
    {
        AccountType: "Cuenta Corriente",
        Country: "UY",
    },
    {
        AccountType: "Cuenta de Ahorros",
        Country: "EC",
    },
    {
        AccountType: "Cuenta Corriente",
        Country: "EC",
    },
].map((at, i) => {
    return { ...at, Id: i };
});

export const REFUND_INFO_BANK_NAMES: RefundInfoBankNames[] = [
    {
        BankName: "BANCO DE CHILE-EDWARDS",
        Country: "CL",
    },
    {
        BankName: "BANCO BICE",
        Country: "CL",
    },
    {
        BankName: "BANCO CONSORCIO",
        Country: "CL",
    },
    {
        BankName: "BANCO DEL ESTADO DE CHILE",
        Country: "CL",
    },
    {
        BankName: "BANCO DO BRASIL S.A.",
        Country: "CL",
    },
    {
        BankName: "BANCO FALABELLA",
        Country: "CL",
    },
    {
        BankName: "BANCO INTERNACIONAL",
        Country: "CL",
    },
    {
        BankName: "BANCO PARIS",
        Country: "CL",
    },
    {
        BankName: "BANCO RIPLEY",
        Country: "CL",
    },
    {
        BankName: "BANCO SANTANDER",
        Country: "CL",
    },
    {
        BankName: "BANCO SECURITY",
        Country: "CL",
    },
    {
        BankName: "BBVA (BCO BILBAO VIZCAYA ARG)",
        Country: "CL",
    },
    {
        BankName: "BCI (BCO DE CREDITO E INV)",
        Country: "CL",
    },
    {
        BankName: "COOPEUCH",
        Country: "CL",
    },
    {
        BankName: "HSBC BANK",
        Country: "CL",
    },
    {
        BankName: "ITAU CHILE",
        Country: "CL",
    },
    {
        BankName: "ITAU-CORPBANCA",
        Country: "CL",
    },
    {
        BankName: "SCOTIANBANK",
        Country: "CL",
    },
    {
        BankName: "BANCO DE LA NACION AR",
        Country: "AR",
    },
    {
        BankName: "BANCO DE LA PROVINCIA DE BUENOS AIRES",
        Country: "AR",
    },
    {
        BankName: "BANCO DE LA PROVINCIA DE CORDOBA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE LA CIUDAD DE BUENOS AIRES",
        Country: "AR",
    },
    {
        BankName: "BANCO MUNICIPAL DE ROSARIO",
        Country: "AR",
    },
    {
        BankName: "BANCO DEL CHUBUT S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M",
        Country: "AR",
    },
    {
        BankName: "BANCO DE CORRIENTES S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ",
        Country: "AR",
    },
    {
        BankName: "BANCO PROVINCIA DE TIERRA DEL FUEGO",
        Country: "AR",
    },
    {
        BankName: "BANCO DE INVERSION Y COMERCIO EXTERIOR S",
        Country: "AR",
    },
    {
        BankName: "BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL",
        Country: "AR",
    },
    {
        BankName: "NUEVO BANCO DEL CHACO S. A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE GALICIA Y BUENOS AIRES S.A.",
        Country: "AR",
    },
    {
        BankName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA",
        Country: "AR",
    },
    {
        BankName: "CITIBANK N.A.",
        Country: "AR",
    },
    {
        BankName: "BBVA BANCO FRANCES S.A.",
        Country: "AR",
    },
    {
        BankName: "THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.",
        Country: "AR",
    },
    {
        BankName: "BANCO SUPERVIELLE S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO PATAGONIA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO HIPOTECARIO S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE SAN JUAN S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DEL TUCUMAN S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO SANTANDER RIO S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE SANTA CRUZ S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO INTERFINANZAS S.A.",
        Country: "AR",
    },
    {
        BankName: "HSBC BANK AR S.A.",
        Country: "AR",
    },
    {
        BankName: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO",
        Country: "AR",
    },
    {
        BankName: "BANCO CREDICOOP COOPERATIVO LIMITADO",
        Country: "AR",
    },
    {
        BankName: "BANCO DE VALORES S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO ROELA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO MARIVA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO ITAU AR S.A.",
        Country: "AR",
    },
    {
        BankName: "BANK OF AMERICA, NATIONAL ASSOCIATION",
        Country: "AR",
    },
    {
        BankName: "BNP PARIBAS",
        Country: "AR",
    },
    {
        BankName: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGU",
        Country: "AR",
    },
    {
        BankName: "BANCO SAENZ S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO MERIDIAN S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO MACRO S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO COMAFI SOCIEDAD ANONIMA",
        Country: "AR",
    },
    {
        BankName: "BANCO PIANO S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO JULIO SOCIEDAD ANONIMA",
        Country: "AR",
    },
    {
        BankName: "BANCO DEL SOL S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO VOII S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE FORMOSA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO CMF S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE SANTIAGO DEL ESTERO S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO INDUSTRIAL S.A.",
        Country: "AR",
    },
    {
        BankName: "NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA",
        Country: "AR",
    },
    {
        BankName: "BANCO CETELEM AR S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE SERVICIOS FINANCIEROS S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO BRADESCO AR S.A.U.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE SERVICIOS Y TRANSACCIONES S.A.",
        Country: "AR",
    },
    {
        BankName: "RCI BANQUE S.A.",
        Country: "AR",
    },
    {
        BankName: "BACS BANCO DE CREDITO Y SECURITIZACION S",
        Country: "AR",
    },
    {
        BankName: "BANCO MASVENTAS S.A.",
        Country: "AR",
    },
    {
        BankName: "WILOBANK S.A.",
        Country: "AR",
    },
    {
        BankName: "NUEVO BANCO DE ENTRE RÍOS S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO COLUMBIA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO BICA S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO COINAG S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO COINAG S.A.",
        Country: "AR",
    },
    {
        BankName: "BANCO DE COMERCIO S.A.",
        Country: "AR",
    },
    {
        BankName: "Banco Agrario de Colombia",
        Country: "CO",
    },
    {
        BankName: "Banco AV Villas",
        Country: "CO",
    },
    {
        BankName: "Banco Caja Social",
        Country: "CO",
    },
    {
        BankName: "Banco de Occidente (Colombia)",
        Country: "CO",
    },
    {
        BankName: "Banco Popular (Colombia)",
        Country: "CO",
    },
    {
        BankName: "Bancóldex",
        Country: "CO",
    },
    {
        BankName: "Bancolombia",
        Country: "CO",
    },
    {
        BankName: "BBVA (Colombia)",
        Country: "CO",
    },
    {
        BankName: "Banco de Bogotá",
        Country: "CO",
    },
    {
        BankName: "CITI Colombia",
        Country: "CO",
    },
    {
        BankName: "Colpatria",
        Country: "CO",
    },
    {
        BankName: "Davivienda",
        Country: "CO",
    },
    {
        BankName: "GNB Sudameris",
        Country: "CO",
    },
    {
        BankName: "Banco do Brazil",
        Country: "BR",
    },
    {
        BankName: "Banco do Nordeste",
        Country: "BR",
    },
    {
        BankName: "Banco Itaú",
        Country: "BR",
    },
    {
        BankName: "Banco Safra",
        Country: "BR",
    },
    {
        BankName: "Banco Santander Brasil",
        Country: "BR",
    },
    {
        BankName: "Banco Sofisa",
        Country: "BR",
    },
    {
        BankName: "Banrisul",
        Country: "BR",
    },
    {
        BankName: "Bradesco",
        Country: "BR",
    },
    {
        BankName: "Unibanco",
        Country: "BR",
    },
    {
        BankName: "HSBC Brazil",
        Country: "BR",
    },
    {
        BankName: "Citibank Brazil",
        Country: "BR",
    },
    {
        BankName: "Banco de Credito del Perú",
        Country: "PE",
    },
    {
        BankName: "Banco Internacional del Perú INTERBANK",
        Country: "PE",
    },
    {
        BankName: "Citibank del Perú S.A.",
        Country: "PE",
    },
    {
        BankName: "Scotiabank Perú S.A.A.",
        Country: "PE",
    },
    {
        BankName: "BBVA Banco Continental",
        Country: "PE",
    },
    {
        BankName: "Banco de la Nación",
        Country: "PE",
    },
    {
        BankName: "Banco de Comercio",
        Country: "PE",
    },
    {
        BankName: "Banco Pichincha",
        Country: "PE",
    },
    {
        BankName: "Banco Interamericano de Finanzas",
        Country: "PE",
    },
    {
        BankName: "CrediScotia Financiera S.A.",
        Country: "PE",
    },
    {
        BankName: "Mibanco Banco de la Microempresa",
        Country: "PE",
    },
    {
        BankName: "Banco GNB",
        Country: "PE",
    },
    {
        BankName: "Banco Falabella Perú S.A.",
        Country: "PE",
    },
    {
        BankName: "Banco Ripley Perú",
        Country: "PE",
    },
    {
        BankName: "Banco Santander Perú S.A.",
        Country: "PE",
    },
    {
        BankName: "Banco Azteca del Perú S.A.",
        Country: "PE",
    },
    {
        BankName: "Banco ICBC",
        Country: "PE",
    },
    {
        BankName: "Compartamos Financiera",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Crédito Popular de Lima S.A.",
        Country: "PE",
    },
    {
        BankName: "CMAC Piura S.A.C.",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Trujillo S.A.",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Arequipa",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Sullana",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Cusco",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Huancayo",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito de Ica",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito Maynas",
        Country: "PE",
    },
    {
        BankName: "Caja Municipal de Ahorro y Crédito Tacna",
        Country: "PE",
    },
    {
        BankName: "BANCO BANDES URUGUAY S.A.",
        Country: "UY",
    },
    {
        BankName: "BANCO BILBAO VIZCAYA ARGENTARIA URUGUAY S.A.",
        Country: "UY",
    },
    {
        BankName: "BANCO DE LA NACIÓN ARGENTINA (Sucursal Uruguay)",
        Country: "UY",
    },
    {
        BankName: "BANCO ITAU URUGUAY S.A.",
        Country: "UY",
    },
    {
        BankName: "BANCO SANTANDER S.A.",
        Country: "UY",
    },
    {
        BankName: "BANQUE HERITAGE (URUGUAY) S.A.",
        Country: "UY",
    },
    {
        BankName: "CITIBANK N.A. (Sucursal Uruguay)",
        Country: "UY",
    },
    {
        BankName: "HSBC BANK (URUGUAY) S.A.",
        Country: "UY",
    },
    {
        BankName: "SCOTIABANK URUGUAY S.A.",
        Country: "UY",
    },
    {
        BankName: "Banco Central del Ecuador",
        Country: "EC",
    },
    {
        BankName: "Banco del Estado",
        Country: "EC",
    },
    {
        BankName: "Banco de Guayaquil",
        Country: "EC",
    },
    {
        BankName: "Banco Pichincha",
        Country: "EC",
    },
    {
        BankName: "Banco Internacional",
        Country: "EC",
    },
    {
        BankName: "Produbanco",
        Country: "EC",
    },
    {
        BankName: "Banco Bolivariano",
        Country: "EC",
    },
    {
        BankName: "Banco General Rumiñahui",
        Country: "EC",
    },
    {
        BankName: "Banco Promerica",
        Country: "EC",
    },
    {
        BankName: "Banco Pichincha del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco Internacional del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco de Guayaquil del Perú",
        Country: "EC",
    },
    {
        BankName: "Produbanco del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco Bolivariano del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco General Rumiñahui del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco Promerica del Perú",
        Country: "EC",
    },
    {
        BankName: "Banco del Austro",
        Country: "EC",
    },
    {
        BankName: "Banco Cofise",
        Country: "EC",
    },
    {
        BankName: "Banco de Loja",
        Country: "EC",
    },
    {
        BankName: "Banco ProCredit",
        Country: "EC",
    },
    {
        BankName: "Banco Finca",
        Country: "EC",
    },
    {
        BankName: "Banco de la Producción",
        Country: "EC",
    },
    {
        BankName: "Banco de Machala",
        Country: "EC",
    },
    {
        BankName: "Banco Solidario",
        Country: "EC",
    },
    {
        BankName: "Banco del Pacífico",
        Country: "EC",
    },
    {
        BankName: "Banco de la Nación",
        Country: "EC",
    },
    {
        BankName: "Banco Continental",
        Country: "EC",
    },
    {
        BankName: "Banco Popular",
        Country: "EC",
    },
].map((at, i) => {
    return { ...at, Id: i };
});

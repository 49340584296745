import { LoadSidebarEventData } from "../component-models/sidebar/LoadSidebarEventData";
import { usePubSub } from "../pub-sub-service/usePubSub";
import { useFlightTealiumManager } from "./Tealium/useFlightTealiumManager";

export const useFlightSelectionManager = () => {
    const handleFlightSelectChange = async (data: LoadSidebarEventData) => {
        triggers.sidebar.flightSelectionChanged.publish(data);
        tealiumManager.updateTealiumParameters(data.flightState);
    };

    const tealiumManager = useFlightTealiumManager();
    const { triggers } = usePubSub();

    return { handleFlightSelectChange };
};

import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { useReduxState } from "../../shared/redux/useReduxState";

const fareLockDurationSelectorClassName = "duration-selector-elem";
export interface Props {
    context: FlightPageContext;
    fareLock24FormattedPrice: string;
    fareLock48FormattedPrice: string;
    show24HourFarelockSelector: boolean;
    show48HourFarelockSelector: boolean;
    showFarelockDurationSelector: boolean;
}

export const useFarelockDurationSelector = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const isCugAdminOrSupervisor = useMemo(
        () => userContext?.cug.isAdminOrSupervisor || props.context?.model.IsCugAdminOrSupervisor,
        [userContext, props.context.model],
    );

    // EVENT LISTENERS

    const handleFarelock24Click = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.context?.selectFarelockType("Duration24");
    };

    const handleFarelock48Click = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.context?.selectFarelockType("Duration48");
    };

    const closeFarelockDurationSelector = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.context?.removeFarelock();
    };

    // TEMPLATES

    const closerTemplate = () => html`
        <div
            class="fare-lock-duration-close"
            data-test-id=${T.FARELOCK.SELECTOR_CANCEL}
            @click=${closeFarelockDurationSelector}
        >
            ${i18next.t("FareLockDurationSelectorClose")}
        </div>
    `;

    const cugHoldTemplate = () =>
        isCugAdminOrSupervisor
            ? html`
                  <div class="text-center" data-test-id=${T.FARELOCK.DURATION_CUG}>
                      <img src="/Images/Farelock/fl-12-disabled.png" />
                      <span class="fare-lock-duration-option condensed">
                          ${i18next.t("FareLockDurationOptionCug2")}
                          <div class="farelock-help-icon">
                              <ac-tooltip
                                  .icon=${"?"}
                                  .tooltip=${i18next.t("FareLockDurationOptionCug2Tooltip")}
                              ></ac-tooltip>
                          </div>
                      </span>
                      <button class="fare-lock-duration-btn cug2" data-test-id=${T.FARELOCK.BUTTON_CUG}>
                          <span>${i18next.t("FareLockDurationOptionCug2Btn")}</span>
                      </button>
                  </div>
              `
            : "";

    const farelock24SelectorTemplate = () => {
        const tempClassMap = classNames("fare-lock-duration-btn", {
            selected: props.context.farelockState.selectedType === "Duration24",
        });

        return props.show24HourFarelockSelector
            ? html`
                  <div class="text-center" data-test-id=${T.FARELOCK.DURATION_24}>
                      <img src="/Images/Farelock/fl-24-unselected.png" />
                      <span class="fare-lock-duration-option">
                          ${i18next.t("FareLockDurationOption {{hour}}", { hour: "24" })}
                      </span>
                      <button
                          class=${tempClassMap}
                          data-test-id=${T.FARELOCK.BUTTON_24}
                          data-test-value=${props.fareLock24FormattedPrice}
                          @click=${handleFarelock24Click}
                      >
                          <span>${props.fareLock24FormattedPrice}</span>
                          <span>${i18next.t("FareLockPerPassenger")}</span>
                      </button>
                  </div>
              `
            : "";
    };

    const farelock48SelectorTemplate = () => {
        const tempClassMap = classNames("fare-lock-duration-btn", {
            selected: props.context.farelockState.selectedType === "Duration48",
        });

        return props.show48HourFarelockSelector
            ? html`
                  <div class="text-center" data-test-id=${T.FARELOCK.DURATION_48}>
                      <img src="/Images/Farelock/fl-48-unselected.png" />
                      <span class="fare-lock-duration-option">
                          ${i18next.t("FareLockDurationOption {{hours}}", { hours: "48" })}
                      </span>
                      <button
                          class=${tempClassMap}
                          data-test-id=${T.FARELOCK.BUTTON_48}
                          data-test-value=${props.fareLock48FormattedPrice}
                          @click=${handleFarelock48Click}
                      >
                          <span>${props.fareLock48FormattedPrice}</span>
                          <span>${i18next.t("FareLockPerPassenger")}</span>
                      </button>
                  </div>
              `
            : "";
    };

    const mainClassMap = classNames(fareLockDurationSelectorClassName, "fare-lock-duration-selector", {
        active: props.showFarelockDurationSelector,
        taller: isCugAdminOrSupervisor,
    });

    const htmlTemplate = () => html`
        <div class=${mainClassMap} data-test-id=${T.FARELOCK.DURATION_SELECTOR}>
            <header>
                <i class="js-icon js-fare-lock" data-test-id=${T.FARELOCK.ICON}></i>
                <span>${i18next.t("FareLockDurationInfo")}</span>
            </header>
            <div class="fare-lock-duration-options">
                ${cugHoldTemplate()} ${farelock24SelectorTemplate()} ${farelock48SelectorTemplate()}
            </div>
            ${closerTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

import { ExtendedApiSeat } from "../../component-models/seatmap/ApiSeat";
import { useTealiumManager } from "./useTealiumManager";
import { OUTBOUND } from "../../shared/commonConstants";
import { useReduxState } from "../../shared/redux/useReduxState";

export const useSeatmapTealiumManager = () => {
    const tealiumManager = useTealiumManager();

    const [currency] = useReduxState("booking.currency");

    const logSeatmapPageLoad = () =>
        tealiumManager.logDomLoaded("seatmap", {
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });

    const logSameSeatSelectedInbound = (
        paxType: "A" | "C",
        paxNumber: number,
        seatDesignator: string,
        price: number,
        tealiumOutboundSeats: string[],
        tealiumInboundSeats: string[],
    ) =>
        tealiumManager.log({
            EventName: "inbound_seat_selected",
            EventParams: {
                currency,
                device: tealiumManager.getTealiumDeviceType(),
                direction: "I",
                paxindex: paxNumber,
                paxtype: paxType,
                price,
                seat_number: seatDesignator,
            },
            UdoParams: {
                outbound_seat_selected: tealiumOutboundSeats,
                inbound_seat_selected: tealiumInboundSeats,
            },
            UpdateCommonUdo: true,
        });

    const logSeatSelected = (
        paxType: "A" | "C",
        paxNumber: number,
        newSeat: ExtendedApiSeat,
        previousSeat: ExtendedApiSeat,
        tealiumOutboundSeats: string[],
        tealiumInboundSeats: string[],
    ) =>
        tealiumManager.log({
            EventName: `${newSeat.JourneyIndex === OUTBOUND ? "outbound" : "inbound"}_seat_selected`,
            EventParams: {
                currency,
                device: tealiumManager.getTealiumDeviceType(),
                direction: newSeat.JourneyIndex === OUTBOUND ? "O" : "I",
                paxindex: paxNumber,
                paxtype: paxType,
                previous_currency: previousSeat ? currency : "n/a",
                previous_price: previousSeat ? previousSeat.Price : "n/a",
                previous_seat_number: previousSeat ? `${previousSeat.Row}${previousSeat.Column}` : "n/a",
                price: newSeat.Price,
                seat_number: `${newSeat.Row}${newSeat.Column}`,
                segment: newSeat.SegmentIndex,
            },
            UdoParams: {
                outbound_seat_selected: tealiumOutboundSeats,
                inbound_seat_selected: tealiumInboundSeats,
            },
            UpdateCommonUdo: true,
        });

    return {
        logContinueClicked: tealiumManager.logContinueClicked,
        logSameSeatSelectedInbound,
        logSeatmapPageLoad,
        logSeatSelected,
    };
};

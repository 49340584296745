import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import { BaggageContext } from "../../../component-models/baggage/BaggageContext";
import i18next from "i18next";
import { OUTBOUND } from "../../../shared/commonConstants";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { html } from "lit-html";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerBookingBundle } from "./perBookingView/usePerBookingBundle";
import { usePerBookingViewSwitch } from "./perBookingView/usePerBookingViewSwitch";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { usePerBookingPaidOption } from "./perBookingView/usePerBookingPaidOption";
import { usePerBookingFreeOption } from "./perBookingView/usePerBookingFreeOption";
import { useMobilePerBookingOptions } from "./perBookingView/useMobilePerBookingOptions";
import { useBaggageSection } from "./common/useBaggageSection";
import { useBaggageIllustrations } from "./common/useBaggageIllustrations";
import { StaffBaggage } from "./common/useStaffBaggage";
import { BagType } from "./useBaggagePage";
import { usePerBookingValidationError } from "./perBookingView/usePerBookingValidationError";

export interface Props {
    context: BaggageContext;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useCheckedBags = (props: Props) => {
    const checkedBag = useBaggageSection({
        bagType: "CheckedBaggage",
        context: props.context,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const validationError = usePerBookingValidationError({ baggageSection: checkedBag, pageState: props.pageState });

    const { perBookingDcTemplate, tooManyPaxForDcTemplate } = useBaggageIllustrations({
        baggageSection: checkedBag,
        context: props.context,
    });

    const perBookingBundle = usePerBookingBundle({
        baggageState: checkedBag.state,
        bagType: "CheckedBaggage",
        context: props.context,
    });

    const viewSwitch = usePerBookingViewSwitch({
        baggageSection: checkedBag,
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: checkedBag,
        context: props.context,
        pageState: props.pageState,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: checkedBag,
        context: props.context,
        pageState: props.pageState,
    });

    const perBookingFreeOption = usePerBookingFreeOption({
        baggageSection: checkedBag,
        context: props.context,
        pageState: props.pageState,
    });

    const perBookingPaidOption = usePerBookingPaidOption({
        baggageSection: checkedBag,
        context: props.context,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerBookingOptions = useMobilePerBookingOptions({
        baggageSection: checkedBag,
        context: props.context,
        staffBaggage: props.staffBaggage,
    });

    const isBundleBought = useMemo(
        () => props.context.journeys.some((journey) => journey.checkedBundleType !== "None"),
        [props.context.journeys],
    );

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;

        const journeyAndPaxWithError = checkedBag.handlers.findJourneyAndPaxWithError();

        if (!journeyAndPaxWithError) return;

        checkedBag.handlers.openPax({
            JourneyIndices: [journeyAndPaxWithError.journeyIndex],
            PaxIndices: [journeyAndPaxWithError.paxIndex],
        });
    };

    useEffect(() => {
        if (!checkedBag.state.hasPerJourneyPerPaxStateChanged) return;

        const element = DomCrawlingHelper.getElemByClass(
            document.body,
            checkedBag.state.perJourneyPerPaxState === "open"
                ? props.context.classesToScrollToOnOpen.get("CheckedBaggage")
                : props.context.classesToScrollToOnClose.get("CheckedBaggage"),
        );
        ScrollHelper.scrollToElementAndHideNav({
            element,
            yOffset: 0,
            timeOffset: 500,
        });
    }, [checkedBag.state.perJourneyPerPaxState]);

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    const headerTemplate = () => {
        const titleText = isBundleBought
            ? i18next.t("¿Deseas más equipaje de bodega?")
            : i18next.t("Selecciona tu equipaje de bodega");

        const subtitleText = isBundleBought
            ? i18next.t("Recuerda que puedes agregar hasta 5 piezas de equipaje facturado por pasajero, por tramo.")
            : i18next.t("Elige si quieres llevar equipaje facturado, este tiene un peso máximo de 23 kg.");

        return html`
            <header
                class="b2-section-header push-down ${props.context.classesToScrollToOnClose.get("CheckedBaggage")}"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_HEADER, { c: "CheckedBaggage" })}
            >
                <div class="flex items-center">
                    <span
                        class="b2-section-number"
                        data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_NUMBER, { c: "CheckedBaggage" })}
                    >
                        2
                    </span>
                    <div>
                        <h3>${titleText}</h3>
                        <h4>${subtitleText}</h4>
                    </div>
                </div>
                <div class="flex flex-col items-start">
                    ${props.context.isDcBooking ? perBookingDcTemplate() : ""} ${perBookingBundle.htmlTemplate()}
                </div>
            </header>
        `;
    };

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "CheckedBaggage" })}>
            ${headerTemplate()}
            <div
                class="b2-bag-grid b2-checked-grid hidden-xs"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_DT, { c: "CheckedBaggage" })}
            >
                ${viewSwitch.htmlTemplate()}
                <div class="ac-per-booking-free-option">${perBookingFreeOption.htmlTemplate()}</div>
                <div class="ac-per-booking-paid-option">${perBookingPaidOption.htmlTemplate()}</div>
                <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                ${validationError.htmlTemplate(false)}
            </div>
            <div
                class="b2m-checked-bag-container hidden-sm-up"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_MB, { c: "CheckedBaggage" })}
            >
                ${mobilePerBookingOptions.htmlTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                ${validationError.htmlTemplate(true)}
            </div>
            ${tooManyPaxForDcTemplate(checkedBag.state.journeys[OUTBOUND]?.passengers.length || 0)}
        </div>
    `;

    return {
        availability: checkedBag.perJourneyPerPax,
        bagType: "CheckedBaggage" as BagType,
        handlers: checkedBag.handlers,
        state: checkedBag.state,
        htmlTemplate,
    };
};

import { useReduxState } from "../../shared/redux/useReduxState";
import { useTealiumManager } from "./useTealiumManager";

export const usePassengersTealiumManager = () => {
    const [total] = useReduxState("booking.total");
    const [currency] = useReduxState("booking.currency");

    const logPassengersPageLoad = (flatBookingData: string) =>
        tealiumManager.logDomLoaded("passengers", {
            UdoParams: {
                quoted_total: total,
                quoted_currency: currency,
                ...JSON.parse(flatBookingData),
            },
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });

    const tealiumManager = useTealiumManager();

    return {
        logContinueClicked: tealiumManager.logContinueClicked,
        logPassengersPageLoad,
    };
};

import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { useBaggageEditButton } from "../common/useBaggageEditButton";
import classNames from "classnames";

export interface Props {
    baggageSection: BaggageSection;
}

export const usePerBookingViewSwitch = (props: Props) => {
    const infoText = () =>
        paxAmount(props.baggageSection.state) === 1
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

    const editButton = useBaggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            c: props.baggageSection.bagType,
        }),
        isDisabled: paxAmount(props.baggageSection.state) === 1 && props.baggageSection.state.journeys.length === 1,
        onClick: props.baggageSection.handlers.openPerJourneyPerPaxView,
    });

    const containerClassMap = classNames("b2-openable", {
        "b2-opened": props.baggageSection.state.perJourneyPerPaxState !== "open",
    });

    const illustrationClassMap = classNames("b2-illustration", {
        taller: props.baggageSection.bagType === "CheckedBaggage",
    });

    const htmlTemplate = () => html`
        <div class="b2-view">
            <div class=${illustrationClassMap}></div>
            <div class=${containerClassMap}>
                <div class="b2-view-switch">
                    <div class="b2-view-info" data-test-id=${T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_INFO}>
                        ${infoText()}
                    </div>
                    <div class="b2-pax-amount">
                        ${paxAmount(props.baggageSection.state)} ${paxLabel(paxAmount(props.baggageSection.state))}
                    </div>
                    ${editButton.htmlTemplate()}
                </div>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

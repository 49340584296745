import {
    ChangeUserStatusEvent,
    EditUserEvent,
    GlobalFilterChangeEvent,
    ResetUserPasswordEvent,
    UserAddSubmissionEvent,
    UserEditSubmissionEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";
import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { GridState, PageChangeEvent, SortChangeEvent } from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import {
    handleCugLoader,
    toBoolean,
    SelectOption,
    getParsedProperty,
    getAntiForgeryTokenFromHtml,
} from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { CugUser, CugUserData } from "../../../component-models/CUG2b/CugUserData";
import { classMap } from "lit-html/directives/class-map";
import { AvailableRole } from "../../../component-models/CUG2b/AvailableRole";
import { User } from "../../../component-models/CUG2b/User";
import { getStringForCulture } from "../../../component-helpers/stringHelper";
import { PeruCompraUserData } from "../../../component-models/CUG2b/PeruCompraUserData";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "available-roles",
    "can-activate",
    "can-add",
    "can-edit",
    "can-reset",
    "current-user",
];
export const name = "ac-users-page";

export interface Attributes {
    "anti-forgery-token": string;
    "available-roles": string;
    "can-activate": string;
    "can-add": string;
    "can-edit": string;
    "can-reset": string;
    "current-user": string;
}
export interface Properties {
    antiForgeryToken: string;
    availableRoles: SelectOption[];
    canActivate: boolean;
    canAdd: boolean;
    canEdit: boolean;
    canReset: boolean;
    currentUser: string;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const getRoles = (): SelectOption[] =>
        getParsedProperty<AvailableRole[]>(host.availableRoles).map(
            (item: AvailableRole): SelectOption => ({ Text: item.FriendlyName, Value: item.Code }),
        );

    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        availableRoles: getRoles(),
        canActivate: toBoolean(host.canActivate),
        canAdd: toBoolean(host.canAdd),
        canEdit: toBoolean(host.canEdit),
        canReset: toBoolean(host.canReset),
        currentUser: host.currentUser,
    };

    // HELPERS

    const loadData = async (gridState: GridState<keyof CugUser>) => {
        const result = await ajaxJsonRequest<CugUserData | PeruCompraUserData>({
            container: root.current,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.AgencyUsers}/` +
                `?pageSize=1000` +
                `&page=0` +
                `&orderBy=${gridState.orderBy}` +
                `&orderDirection=${gridState.orderDir !== "none" ? gridState.orderDir : "asc"}`,
        });

        return {
            totalCount: result?.data.TotalCount,
            newData: result?.data.Users.map((item) => {
                return { ...item, id: item.Username };
            }),
        };
    };

    const initialGridState = (): GridState<keyof CugUser> => {
        return {
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "desc",
            globalFilterExpression: "",
        };
    };

    const updateDataState = async () => {
        handleCugLoader(grid.current, "loadData");

        const { newData } = await loadData(gridState);

        setData(newData);

        handleCugLoader(grid.current, "loadData");
    };

    const init = () => {
        const doIt = async () => {
            await updateDataState();
        };

        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        doIt();
    };

    const filteredUsers = () => {
        if (!gridState.globalFilterExpression?.trim()) {
            return data;
        }

        const filterExpression = gridState.globalFilterExpression.toLowerCase().trim();

        return data?.filter(
            (user) =>
                user.FirstName.toLowerCase().indexOf(filterExpression) > -1 ||
                user.LastName.toLowerCase().indexOf(filterExpression) > -1 ||
                user.Username.toLowerCase().indexOf(filterExpression) > -1,
        );
    };

    const usersInOnePage = () =>
        filteredUsers()?.slice(
            gridState.pageIndex * gridState.pageSize,
            (gridState.pageIndex + 1) * gridState.pageSize,
        );

    // COMPONENT

    const cug2AppContext = useCug2AppContext();
    const [userContext] = useReduxState("userContext");

    const { ajaxRequest, ajaxJsonRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const root = useRef<HTMLDivElement>(undefined);
    const grid = useRef<HTMLDivElement>(undefined);

    const [gridState, setGridState] = useState<GridState<keyof CugUser>>(initialGridState());
    const [data, setData] = useState<CugUser[]>(undefined);
    const [userToChangeStatus, setUserToChangeStatus] = useState<CugUser>(undefined);
    const [userToResetPassword, setUserToResetPassword] = useState<CugUser>(undefined);
    const [userToEdit, setUserToEdit] = useState<User>(undefined);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

    useEffect(init, []);

    // EVENT LISTENERS

    const onStatusChange = async (deactivate: boolean) => {
        const userId = userToChangeStatus.Username;
        setUserToChangeStatus(undefined);
        handleCugLoader(grid.current, "loadData");
        await ajaxRequest({
            body: { userId },
            container: root.current,
            url: deactivate ? ROUTES.ApiRoutes.AgencyDisableUser : ROUTES.ApiRoutes.AgencyEnableUser,
        });
        handleCugLoader(grid.current, "loadData");
        await updateDataState();
    };

    const onPasswordReset = async () => {
        const userId = userToResetPassword.Username;
        setUserToResetPassword(undefined);
        handleCugLoader(grid.current, "loadData");
        await ajaxRequest({
            body: { "forgotMemberPassword.Username": userId },
            container: root.current,
            url: ROUTES.SendForgetPasswordMail,
        });
        handleCugLoader(grid.current, "loadData");
        await updateDataState();
    };

    const onUserEdit = async (e: EditUserEvent) => {
        handleCugLoader(grid.current, "loadData");

        const result = await ajaxJsonRequest<User>({
            container: root.current,
            method: "GET",
            url: `${ROUTES.ApiRoutes.AgencyGetUserDetails}?userId=${e.detail.user.Username}`,
        });

        setUserToEdit(result?.data);

        handleCugLoader(grid.current, "loadData");
    };

    const onUserEditSubmission = async (e: UserEditSubmissionEvent) => {
        setUserToEdit(undefined);

        handleCugLoader(grid.current, "loadData");

        const result = await ajaxRequest({
            body: e.detail.form,
            container: root.current,
            url: ROUTES.ApiRoutes.Cug2BUpdateUser,
        });

        handleCugLoader(grid.current, "loadData");

        if (result.isResponseOk) {
            setIsSuccessModalOpen(true);
            updateDataState();
        }
    };

    const onUserAddSubmission = async (e: UserAddSubmissionEvent) => {
        handleCugLoader(grid.current, "loadData");

        await ajaxRequest({
            body: e.detail.form,
            container: root.current,
            url: ROUTES.ApiRoutes.Cug2BCreateUser,
        });

        handleCugLoader(grid.current, "loadData");

        await updateDataState();
    };

    const onGlobalFilterChange = (e: GlobalFilterChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: 0,
            globalFilterExpression: e.detail.filterExpression,
        });
    };

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof CugUser,
            orderDir: e.detail.orderDir,
        });
    };

    const onStatusClick = (e: ChangeUserStatusEvent) => {
        setUserToChangeStatus(e.detail.user);
    };

    const onClose = () => {
        setUserToEdit(undefined);
    };

    // TEMPLATES

    const usersGridTemplate = () => {
        return userContext.peruCompra.isAdmin
            ? html`
                  <ac-peru-compra-users-grid
                      .availableRoles=${props.availableRoles}
                      .canAdd=${props.canAdd}
                      .currentUser=${props.currentUser}
                      .data=${usersInOnePage()}
                      .gridState=${gridState}
                      .totalItemCount=${filteredUsers()?.length}
                      .userToEdit=${userToEdit}
                      @close=${onClose}
                      @changeStatus=${onStatusClick}
                      @edit=${onUserEdit}
                      @addSubmission=${onUserAddSubmission}
                      @editSubmission=${onUserEditSubmission}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onGlobalFilterChange=${onGlobalFilterChange}
                      @reset=${(e: ResetUserPasswordEvent) => setUserToResetPassword(e.detail.user)}
                  ></ac-peru-compra-users-grid>
              `
            : html`
                  <ac-cug-users-grid
                      .availableRoles=${props.availableRoles}
                      .canAdd=${props.canAdd}
                      .currentUser=${props.currentUser}
                      .data=${usersInOnePage()}
                      .gridState=${gridState}
                      .totalItemCount=${filteredUsers()?.length}
                      .userToEdit=${userToEdit}
                      @close=${onClose}
                      @changeStatus=${onStatusClick}
                      @edit=${onUserEdit}
                      @addSubmission=${onUserAddSubmission}
                      @editSubmission=${onUserEditSubmission}
                      @onSortChange=${onSortChange}
                      @onPageChange=${onPageChange}
                      @onGlobalFilterChange=${onGlobalFilterChange}
                      @reset=${(e: ResetUserPasswordEvent) => setUserToResetPassword(e.detail.user)}
                  ></ac-cug-users-grid>
              `;
    };

    const saveSuccessModalContentTemplate = () => html`
        <div class="p-8">
            <div class="cug2b-modal-question mb-4 sm:mb-8">
                ${i18next.t("La información ha sido guardada exitosamente.")}
            </div>
            <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                <button class="rounded-primary-btn" @click=${() => setIsSuccessModalOpen(false)}>
                    ${i18next.t("OK")}
                </button>
            </div>
        </div>
    `;

    const changeStatusModalContentTemplate = () => {
        const deactivate = userToChangeStatus?.IsInActiveStatus;
        const questionText = deactivate
            ? i18next.t("¿Estás seguro de que quieres {{-spanStart}}deactivar{{-spanEnd}} al usuario?", {
                  spanStart: "<span>",
                  spanEnd: "</span>",
              })
            : i18next.t("¿Estás seguro de que quieres {{-spanStart}}activar{{-spanEnd}} al usuario?", {
                  spanStart: "<span>",
                  spanEnd: "</span>",
              });

        return html`
            <div class="p-8">
                <div class="cug2b-modal-question mb-4 sm:mb-8">${unsafeHTML(questionText)}</div>
                <div class="cug2b-modal-question">${i18next.t("User Name")}: ${userToChangeStatus?.Username}</div>
                <div class="cug2b-modal-question">
                    ${i18next.t("Nombre y apellido")}: ${userToChangeStatus?.FirstName} ${userToChangeStatus?.LastName}
                </div>
                <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                    <button
                        class="rounded-secondary-btn mb-4 sm:mb-0 sm:mr-8"
                        @click=${() => setUserToChangeStatus(undefined)}
                    >
                        ${i18next.t("Cancelar")}
                    </button>
                    <button class="rounded-primary-btn" @click=${() => onStatusChange(deactivate)}>
                        ${i18next.t("Aceptar")}
                    </button>
                </div>
            </div>
        `;
    };

    const resetPasswordModalContentTemplate = () => {
        return html`
            <div class="p-8">
                <div class="cug2b-modal-question mb-4 sm:mb-8">
                    ${unsafeHTML(
                        i18next.t("¿Estás seguro que quieres {{-spanStart}}restablecer{{-spanEnd}} la contraseña?", {
                            spanStart: "<span>",
                            spanEnd: "</span>",
                        }),
                    )}
                </div>
                <div class="cug2b-modal-question">${i18next.t("User Name")}: ${userToResetPassword?.Username}</div>
                <div class="cug2b-modal-question">
                    ${i18next.t("Nombre y apellido")}: ${userToResetPassword?.FirstName}
                    ${userToResetPassword?.LastName}
                </div>
                <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                    <button
                        class="rounded-secondary-btn mb-4 sm:mb-0 sm:mr-8"
                        @click=${() => setUserToResetPassword(undefined)}
                    >
                        ${i18next.t("Cancelar")}
                    </button>
                    <button class="rounded-primary-btn" @click=${() => onPasswordReset()}>
                        ${i18next.t("Aceptar")}
                    </button>
                </div>
            </div>
        `;
    };

    const modalHeaderTemplate = () => html`
        <i class="js-icon js-question-broken-circle big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("¿Estás seguro?")}</span>
    `;

    const successModalHeaderTemplate = () => html`
        <i class="js-icon js-broken-circle-exclamation big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("Importante")}</span>
    `;

    const saveSuccessModalTemplate = () => {
        return html`
            <ac-cug-modal
                .canBeClosed=${true}
                .content=${saveSuccessModalContentTemplate()}
                .customClass=${"cug2b-save-success-modal"}
                .header=${successModalHeaderTemplate()}
                .isOpen=${isSuccessModalOpen}
                @close=${() => setIsSuccessModalOpen(false)}
            ></ac-cug-modal>
        `;
    };

    const changeStatusModalTemplate = () => {
        return html`
            <ac-cug-modal
                .canBeClosed=${true}
                .content=${changeStatusModalContentTemplate()}
                .customClass=${"cug2b-change-status-modal"}
                .header=${modalHeaderTemplate()}
                .isOpen=${userToChangeStatus}
                @close=${() => setUserToChangeStatus(undefined)}
            ></ac-cug-modal>
        `;
    };

    const resetPasswordModalTemplate = () => {
        return html`
            <ac-cug-modal
                .canBeClosed=${true}
                .content=${resetPasswordModalContentTemplate()}
                .customClass=${"cug2b-reset-password-modal"}
                .header=${modalHeaderTemplate()}
                .isOpen=${userToResetPassword}
                @close=${() => setUserToResetPassword(undefined)}
            ></ac-cug-modal>
        `;
    };

    const userRolesInfoTemplate = () =>
        !userContext.peruCompra.isAdmin
            ? html`
                  <div class="cug2b-users-info">
                      <h1>
                          ${i18next.t(
                              "Conoce aquí el detalle de las tareas que puede realizar cada rol de la organización",
                          )}
                      </h1>
                      <div>
                          <input type="checkbox" id="agent" />
                          <label for="agent">${i18next.t("Agente")}</label>
                          <p>
                              ${getStringForCulture(
                                  cug2AppContext.Cug2BSettings.RoleDescriptions.AgentDescription,
                                  cug2AppContext.Culture,
                              )}
                          </p>
                      </div>
                      <div>
                          <input type="checkbox" id="supervisor" />
                          <label for="supervisor">${i18next.t("Supervisor")}</label>
                          <p>
                              ${getStringForCulture(
                                  cug2AppContext.Cug2BSettings.RoleDescriptions.SupervisorDescription,
                                  cug2AppContext.Culture,
                              )}
                          </p>
                      </div>
                      <div>
                          <input type="checkbox" id="admin" />
                          <label for="admin">${i18next.t("Administrador")}</label>
                          <p>
                              ${getStringForCulture(
                                  cug2AppContext.Cug2BSettings.RoleDescriptions.AdministratorDescription,
                                  cug2AppContext.Culture,
                              )}
                          </p>
                      </div>
                  </div>
              `
            : "";

    const usersHeaderMessageTemplate = () =>
        userContext.peruCompra.isAdmin
            ? html`<h2>${i18next.t("Agrega y edita aquí todos los usuarios de tu organización")}</h2>`
            : html`<h2>${i18next.t("Agrega y edita aquí todos los usuarios de tu empresa o agencia")}</h2>`;

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-group"></i>
            <div>
                <h1>${i18next.t("Usuarios")}</h1>
                ${usersHeaderMessageTemplate()}
            </div>
        </div>
    `;

    const mainClassMap = classMap({
        "min-h-1/4": !data,
        "w-full": !data,
    });

    return html`
        <div ref=${ref(grid)} class=${mainClassMap}>${headerTemplate()} ${usersGridTemplate()}</div>
        ${userRolesInfoTemplate()} ${changeStatusModalTemplate()} ${resetPasswordModalTemplate()}
        ${saveSuccessModalTemplate()}
    `;
};

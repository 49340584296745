import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import i18next from "i18next";
import { CLASS_NAMES } from "../../shared/classNames";
import { FULL_EMAIL_REGEX } from "../../shared/commonConstants";
import { GiftcardModel } from "../../component-models/giftcard/GiftcardModel";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { ROUTES } from "../../shared/apiRoutes";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { getAntiForgeryTokenFromHtml, showLoader } from "../../shared/common";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useRef, useState, useEffect } from "haunted";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    antiForgeryToken: string;
    balanceDue: number;
    giftcardFullHtml: string;
    giftcardMobileHtml: string;
    giftcardModel: GiftcardModel;
    hasSecondaryContact: boolean;
}

export const useGiftcardObtainer = (props: Props) => {
    // HELPERS

    const toggleEmail = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleSendEmail = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setShowEmailError(false);

        if (!email.current.value || !FULL_EMAIL_REGEX.test(email.current.value)) {
            setShowEmailError(true);
        } else {
            const loader = showLoader({ name: LOADER_CLASS_NAMES.Generic, container: document.body });
            const response = await ajaxRequest({
                isJsonData: true,
                loader,
                url: ROUTES.ApiRoutes.AddGiftcardEmail,
                container: DomCrawlingHelper.getElemByClass(root.current, CLASS_NAMES.errorContainer).parentElement,
                body: { EmailAddress: email.current.value },
            });

            // FIXME Add type
            const isValid = JSON.parse(response.data).SendAddress;

            if (isValid) {
                setIsEmailSent(true);
            }
        }
    };

    const print = () => {
        if (!isPrinting) {
            setIsPrinting(true);
            // https://stackoverflow.com/questions/2255291/print-the-contents-of-a-div
            const mywindow = window.open("", "PRINT", "height=600,width=800");

            mywindow.document.write("<html><head><title>" + document.title + "</title>");
            mywindow.document.write(
                "<style>.to-print {opacity: 0;} @media only print {.to-print {opacity: 1;}}</style>",
            );
            mywindow.document.write("</head><body class='to-print'>");
            mywindow.document.write(
                fullSizeHtml.current.offsetHeight > 0
                    ? fullSizeHtml.current.innerHTML
                    : mobileSizeHtml.current.innerHTML,
            );
            mywindow.document.write("</body></html>");
            mywindow.document.close(); // necessary for IE >= 10

            mywindow.onload = () => {
                mywindow.focus(); // necessary for IE >= 10*/

                mywindow.print();
                // DEVNOTE Chrome bug fixed by settimeout
                window.setTimeout(() => {
                    mywindow.close();
                }, 1);
                setIsPrinting(false);
            };
        } else {
            // TODO localize!!
            alert(i18next.t("V2-Checkin-AlreadyPrintingError"));
        }
    };

    // COMPONENTS

    const root = useRef<HTMLDivElement>(undefined);
    const fullSizeHtml = useRef<HTMLDivElement>(undefined);
    const mobileSizeHtml = useRef<HTMLDivElement>(undefined);
    const email = useRef<HTMLInputElement>(undefined);

    const { ajaxRequest } = useAjax();

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);

    useEffect(() => setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken)), []);

    // TEMPLATES

    const headerTemplate = () =>
        props.giftcardModel.Quantity > 1
            ? html`
                  <div class="gc-itinerary-quantity">
                      ${unsafeHTML(
                          i18next.t("Gift-ItineraryQuantityInfo {{-spanStart}}{{-quantity}}{{-spanEnd}}", {
                              spanStart: "<span>",
                              quantity: `${props.giftcardModel.Quantity}`,
                              spanEnd: "</span>",
                          }),
                      )}
                  </div>
              `
            : "";

    const fullSizeImageTemplate = () => {
        const fullSizeClassMap = classMap({
            "giftcard-image": true,
            "hidden-xs": true,
            "for-gift": props.giftcardModel.ForGift,
            "second-card": props.giftcardModel.Quantity > 1,
            "third-card": props.giftcardModel.Quantity === 3,
        });

        return html`
            <div
                ref=${ref(fullSizeHtml)}
                class=${fullSizeClassMap}
                data-test-id=${getTestId(T.GIFTCARD.ITINERARY_IMAGE, { m: false })}
                data-test-value=${props.giftcardModel.ActivationCode}
            >
                <img style="max-width: 100%;" src=${`data:image/png;base64,${props.giftcardFullHtml}`} />
            </div>
        `;
    };

    const mobileSizeImageTemplate = () => {
        const mobilelSizeClassMap = classMap({
            "giftcard-image": true,
            "visible-xs": true,
            "text-center": true,
            "for-gift": props.giftcardModel.ForGift,
            "second-card": props.giftcardModel.Quantity > 1,
            "third-card": props.giftcardModel.Quantity === 3,
        });

        return html`
            <div
                ref=${ref(mobileSizeHtml)}
                class=${mobilelSizeClassMap}
                data-test-id=${getTestId(T.GIFTCARD.ITINERARY_IMAGE, { m: true })}
                data-test-value=${props.giftcardModel.ActivationCode}
            >
                <img style="max-width: 100%;" src=${`data:image/png;base64,${props.giftcardMobileHtml}`} />
            </div>
        `;
    };

    const sendGiftcardViaEmailBoxOpenerTemplate = () => {
        const templateClassMap = classMap({
            disabled: isEmailSent || props.hasSecondaryContact || props.balanceDue > 0,
        });

        return html`
            <a class=${templateClassMap} @click=${toggleEmail}>${i18next.t("Gift-OpenItineraryEmailBox")}</a>
        `;
    };

    const giftcardTransactionButtonsTemplate = () => html`
        <div class="giftcard-transaction-button-container push-right">
            <a class="rounded-transaction-btn print btn" @click=${print}>${i18next.t("Gift-Print")}</a>
            <a
                class="rounded-primary-btn btn"
                data-test-id=${T.GIFTCARD.ITINERARY_ACTIVATE_BUTTON}
                href=${`${props.giftcardModel.ActivationLinkLabel}${props.giftcardModel.ActivationCode}`}
                >${i18next.t("Gift-Activate")}</a
            >
        </div>
    `;

    const sendEmailButtonTemplate = () =>
        !isEmailSent
            ? html`
                  <div class="flex justify-center">
                      <button
                          class="rounded-primary-btn btn"
                          data-test-id="giftcard-send-email-button"
                          @click=${handleSendEmail}
                      >
                          ${i18next.t("Gift-Send")}
                      </button>
                  </div>
              `
            : "";

    const sendEmailErrorTemplate = () =>
        showEmailError ? html` <div class="send-giftcard-email-error">${i18next.t("Gift-EmailError")}</div> ` : "";

    const sendEmailSuccessTemplate = () =>
        isEmailSent ? html` <div class="send-giftcard-email-success">${i18next.t("Gift-EmailSuccess")}</div> ` : "";

    const sendGiftcardViaEmailBoxTemplate = () => {
        const containerClassMap = classMap({
            "send-giftcard-email-container": true,
            "open": isOpen,
        });

        const inputClassMap = classMap({
            disabled: isEmailSent,
        });

        return html`
            <div class=${containerClassMap}>
                <button class="emailSendingClose" @click=${() => setIsOpen(false)}>&times;</button>
                <div class="send-giftcard-title">${i18next.t("Gift-EmailLabel")}</div>
                <input
                    ref=${ref(email)}
                    class=${inputClassMap}
                    type="text"
                    name="name"
                    value=""
                    data-test-id="giftcard-send-email-field"
                />
                ${sendEmailButtonTemplate()} ${sendEmailErrorTemplate()} ${sendEmailSuccessTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class="no-print">
            ${headerTemplate()} ${fullSizeImageTemplate()} ${mobileSizeImageTemplate()}

            <div class="giftcard-options ts-error-container">
                ${sendGiftcardViaEmailBoxOpenerTemplate()} ${giftcardTransactionButtonsTemplate()}
                ${sendGiftcardViaEmailBoxTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate };
};

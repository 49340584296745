import i18next from "i18next";
import { ContactPassenger, PassengerAddress } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { ROUTES } from "../../shared/apiRoutes";
import { Region } from "../register/register";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { commonDebug } from "../../bootstrap";
import { commonValidationRules } from "../../shared/commonValidationRules";
import { emailFormat } from "../../shared/customHooks/useForm/custom-attributes/emailFormat";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { html, useEffect, useRef, useState } from "haunted";
import { ref } from "../../directives/ref";
import { sanitizeChileanPhoneInput, sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import {
    ARGENTINA_COUNTRY_CODE,
    ARGENTINA_PROVINCES,
    CHILEAN_CULTURE_CODE,
    CHILE_COUNTRY_CODE,
    PREFIX_CODES,
} from "../../shared/commonConstants";
import {
    clone,
    getRegionsAndComunas,
    getRequestBodyFromInputs,
    handleNumericInputChange,
    mapCountryCallingCodeListToSelectOptions,
    showLoader,
    updateMdl,
} from "../../shared/common";
import { MDL_CLASS_NAMES } from "../../shared/MdlClassNames";
import { classMap } from "lit-html/directives/class-map";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { useSuperForm } from "../../shared/customHooks/useSuperForm/useSuperForm";
import { uniqueByKey } from "../../shared/IterableHelpers";
import { useAppContext } from "../../managers/useAppContext";

export interface Props {
    model: ItineraryPageViewModel;
    contactPassengers: ContactPassenger[];
    isDataProvided: boolean;
    setContactPassengers: (newState: ContactPassenger[]) => void;
}

export const useItineraryPageTabsPassengersEditableForm = (props: Props) => {
    // HELPERS

    const init = () => {
        const doIt = async () => {
            const apiRegions: Region = await getRegionsAndComunas();
            const comunas = Object.keys(apiRegions)
                .reduce((allComunas: string[], key) => allComunas.concat(apiRegions[key]), [])
                .sort();
            setComunaList(comunas);
        };

        doIt();
    };

    const handleAllPaxSameDataCheckboxClick = () => {
        const isChecked = !useSamePaxInformationForAll;
        setUseSamePaxInformationForAll(isChecked);

        if (isChecked) {
            const tempContactPassengers = props.contactPassengers.map(
                (pax): ContactPassenger => ({
                    PaxIndex: pax.PaxIndex,
                    Name: pax.Name,
                    PaxAddress: clone(props.contactPassengers[0].PaxAddress),
                }),
            );
            props.setContactPassengers(tempContactPassengers);

            const elements = Array.from(
                document.getElementsByClassName("mdl-textfield--floating-label"),
            ) as HTMLDivElement[];
            elements.forEach((elem) => elem.classList.add(MDL_CLASS_NAMES.Dirty));
            window.setTimeout(updateMdl, 300);
        }
    };

    const validate = async () => {
        const isValid = await form.validate();

        if (isValid) {
            const loader = showLoader({ name: "passengers-tab", container: document.body, noPlane: false });

            removeAjaxErrorMessage({ container: formElement.current });

            try {
                await ajaxRequest({
                    body: getRequestBodyFromInputs(formElement.current),
                    container: formElement.current,
                    form: formElement.current,
                    loader,
                    onResponseCode: {
                        200: () => {
                            window.location.reload();
                        },
                    },
                });
            } catch (e) {
                commonDebug.error("Error during passenger data submit.");
            }
        }
    };

    const updateContactPassengers = (paxIndex: number, property: keyof PassengerAddress, value: any) => {
        const newContactPassengers = props.contactPassengers.map(
            (pax): ContactPassenger => ({
                ...pax,
                PaxAddress: {
                    ...pax.PaxAddress,
                    [property]: pax.PaxIndex === paxIndex ? value : pax.PaxAddress[property],
                },
            }),
        );
        props.setContactPassengers(newContactPassengers);
    };

    // EVENT HANDLERS

    const handlePhonePrefixChange = (pax: ContactPassenger, value: string) => {
        updateContactPassengers(pax.PaxIndex, "PhonePrefix", value);
        updateMdl();
    };

    const handleCountryChange = (paxIndex: number, value: string) => {
        updateContactPassengers(paxIndex, "Country", value);
        updateMdl();
    };

    const handleCityChange = (paxIndex: number, value: string) => {
        updateContactPassengers(paxIndex, "City", value);
        updateMdl();
    };

    const handleEmailInput = (paxIndex: number, e: KeyboardEvent) => {
        updateContactPassengers(paxIndex, "Email", sanitizeInputFieldValue(e, "e-mail"));
        updateMdl();
    };

    const handlePhoneNumberKeydown = (e: KeyboardEvent) => handleNumericInputChange(e);

    const handlePhoneNumberInput = (pax: ContactPassenger, e: KeyboardEvent) => {
        const sanitizedValue =
            pax.PaxAddress.PhonePrefix === PREFIX_CODES.get(CHILEAN_CULTURE_CODE)
                ? sanitizeChileanPhoneInput(e)
                : sanitizeInputFieldValue(e, "numeric");

        updateContactPassengers(pax.PaxIndex, "PhoneNumber", sanitizedValue);
        updateMdl();
    };

    const handleCityInput = (paxIndex: number, e: KeyboardEvent) => {
        updateContactPassengers(paxIndex, "City", sanitizeInputFieldValue(e, "address"));
        updateMdl();
    };

    const handleAddressInput = (paxIndex: number, e: KeyboardEvent) => {
        updateContactPassengers(paxIndex, "Address", sanitizeInputFieldValue(e, "address"));
        updateMdl();
    };

    // COMPONENT

    const appContext = useAppContext();

    const formElement = useRef<HTMLFormElement>(undefined);

    const { ajaxRequest, removeAjaxErrorMessage } = useAjax();

    const [chileComunas, setComunaList] = useState<string[]>([]);
    const [useSamePaxInformationForAll, setUseSamePaxInformationForAll] = useState<boolean>(false);

    const sameInformationCheckbox = useBasicCheckbox({
        inputTestId: T.ITINERARY.PAX_USE_SAME_DATA_FOR_ALL_CHECKBOX,
        isChecked: useSamePaxInformationForAll,
        labelText: i18next.t("Utilizar la misma información para todos los pasajeros"),
        labelTestId: T.ITINERARY.PAX_USE_SAME_DATA_FOR_ALL_CHECKBOX_LABEL,
        customWrapperClass: "pl-2",
        onClick: handleAllPaxSameDataCheckboxClick,
    });

    const form = useSuperForm({
        customAttributes: [...commonValidationRules(), emailFormat()],
        formElem: formElement.current,
        noScroll: true,
    });

    useEffect(init, []);

    // TEMPLATES

    const passengerNameTemplate = (pax: ContactPassenger) => {
        const passengerNameClassMap = classMap({
            "i2-passengers-divider": true,
            "block": pax.PaxIndex > 0,
            "hidden": pax.PaxIndex === 0,
        });

        return html`
            <div class=${passengerNameClassMap}></div>
            <div class="i2-passengers-index">${i18next.t("Pasajero")} ${pax.PaxIndex + 1}</div>
            <div
                class="i2-passengers-name"
                data-test-id=${getTestId(T.ITINERARY.PAX_TAB_PAX_NAME, { p: pax.PaxIndex })}
            >
                ${pax.Name}
            </div>
        `;
    };

    const emailInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-5-12">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Correo electrónico")}</label>
                <input
                    class="mdl-textfield__input js-input"
                    name="passengerContacts[${pax.PaxIndex}].Email"
                    tabindex="-1"
                    maxlength="52"
                    data-required
                    email
                    data-test-id=${getTestId(T.ITINERARY.PAX_EMAIL, {
                        c: "write",
                        p: pax.PaxIndex,
                    })}
                    .value=${pax.PaxAddress?.Email}
                    @input=${(e: KeyboardEvent) => handleEmailInput(pax.PaxIndex, e)}
                />
            </div>
        </div>
    `;

    const phonePrefixOptionsTemplate = (pax: ContactPassenger) =>
        mapCountryCallingCodeListToSelectOptions(appContext.PhonePrefixes).map((option) => {
            return html`
                <option value=${option.Value} ?selected=${option.Value === pax.PaxAddress?.PhonePrefix}>
                    ${option.Text}
                </option>
            `;
        });

    const phonePrefixTemplate = (pax: ContactPassenger) => html`
        <div class="i2-passengers-phone-prefix">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Código país")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    tabindex="-1"
                    data-required
                    data-test-id=${getTestId(T.ITINERARY.PAX_PHONE_PREFIX, {
                        c: "write",
                        p: pax.PaxIndex,
                    })}
                    .value=${pax.PaxAddress?.PhonePrefix}
                    @change=${(e: Event) => {
                        handlePhonePrefixChange(pax, (e.target as HTMLSelectElement).value);
                    }}
                >
                    <option selected value=""></option>
                    ${phonePrefixOptionsTemplate(pax)}
                </select>
            </div>
        </div>
    `;

    const phoneNumberTemplate = (pax: ContactPassenger) => html`
        <div class="i2-passengers-phone-number">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Teléfono")}</label>
                <input
                    class="mdl-textfield__input js-input"
                    maxlength="47"
                    tabindex="-1"
                    data-required
                    data-test-id=${getTestId(T.ITINERARY.PAX_PHONE_NUMBER, {
                        c: "write",
                        p: pax.PaxIndex,
                    })}
                    .value=${pax.PaxAddress?.PhoneNumber || ""}
                    @keydown=${handlePhoneNumberKeydown}
                    @input=${(e: KeyboardEvent) => handlePhoneNumberInput(pax, e)}
                />
            </div>
        </div>
        ${updateMdl()}
    `;

    const phoneInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-7-12">
            <div class="i2-passengers-phone-container">${phonePrefixTemplate(pax)}${phoneNumberTemplate(pax)}</div>
        </div>
    `;

    const countryListTemplate = (pax: ContactPassenger) =>
        uniqueByKey(appContext.PhonePrefixes, "CountryCode").map(
            (countryWithCallingCode) => html`
                <option
                    value="${countryWithCallingCode.CountryCode}"
                    .selected=${countryWithCallingCode.CountryCode === pax.PaxAddress?.Country}
                >
                    ${countryWithCallingCode.CountryName}
                </option>
            `,
        );

    const countryInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-4-12">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("País")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    name="passengerContacts[${pax.PaxIndex}].Country"
                    tabindex="-1"
                    data-required
                    data-test-id=${getTestId(T.ITINERARY.PAX_COUNTRY, {
                        c: "write",
                        p: pax.PaxIndex,
                    })}
                    .value=${pax.PaxAddress?.Country || ""}
                    @change=${(e: Event) => {
                        handleCountryChange(pax.PaxIndex, (e.target as HTMLSelectElement).value);
                    }}
                >
                    <option value=""></option>
                    ${countryListTemplate(pax)}
                </select>
            </div>
        </div>
    `;

    const comunaOptionsTemplate = (pax: ContactPassenger) =>
        pax.PaxAddress?.Country === CHILE_COUNTRY_CODE
            ? chileComunas.map(
                  (comuna) => html`
                      <option value=${comuna} .selected=${comuna === pax.PaxAddress?.City}>${comuna}</option>
                  `,
              )
            : ARGENTINA_PROVINCES.map(
                  (province) => html`
                      <option value=${province} .selected=${province === pax.PaxAddress?.City}>${province}</option>
                  `,
              );

    const comunaDropdownTemplate = (pax: ContactPassenger) =>
        pax.PaxAddress?.Country === CHILE_COUNTRY_CODE || pax.PaxAddress?.Country === ARGENTINA_COUNTRY_CODE
            ? html`
                  <div class="col-xs-1 col-sm-3-12">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                          <label class="mdl-textfield__label">
                              ${pax.PaxAddress?.Country === CHILE_COUNTRY_CODE
                                  ? i18next.t("Comuna")
                                  : i18next.t("Ciudad")}
                          </label>
                          <select
                              class="mdl-textfield__input js-input js-select"
                              name="passengerContacts[${pax.PaxIndex}].Province"
                              tabindex="-1"
                              data-required
                              data-test-id=${getTestId(T.ITINERARY.PAX_CITY, {
                                  c: "write",
                                  p: pax.PaxIndex,
                              })}
                              .value=${pax.PaxAddress?.City}
                              @change=${(e: Event) => {
                                  handleCityChange(pax.PaxIndex, (e.target as HTMLSelectElement).value);
                              }}
                          >
                              <option value=""></option>
                              ${comunaOptionsTemplate(pax)}
                          </select>
                      </div>
                  </div>
              `
            : "";

    const comunaTextInputTemplate = (pax: ContactPassenger) =>
        pax.PaxAddress?.Country !== CHILE_COUNTRY_CODE && pax.PaxAddress?.Country !== ARGENTINA_COUNTRY_CODE
            ? html`<div class="col-xs-1 col-sm-3-12">
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                      <label class="mdl-textfield__label">${i18next.t("Ciudad")}</label>
                      <input
                          class="mdl-textfield__input js-input"
                          name="passengerContacts[${pax.PaxIndex}].Province"
                          tabindex="-1"
                          maxlength="32"
                          data-required
                          data-test-id=${getTestId(T.ITINERARY.PAX_CITY, {
                              c: "write",
                              p: pax.PaxIndex,
                          })}
                          .value=${pax.PaxAddress?.City}
                          @input=${(e: KeyboardEvent) => handleCityInput(pax.PaxIndex, e)}
                      />
                  </div>
              </div>`
            : "";

    const comunaInputTemplate = (pax: ContactPassenger) =>
        html`${comunaDropdownTemplate(pax)} ${comunaTextInputTemplate(pax)}`;

    const addressInputTemplate = (pax: ContactPassenger) => html`
        <div class="col-xs-1 col-sm-5-12">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Dirección")}</label>
                <input
                    class="mdl-textfield__input js-input"
                    name="passengerContacts[${pax.PaxIndex}].Address"
                    maxlength="52"
                    tabindex="-1"
                    data-required
                    data-test-id=${getTestId(T.ITINERARY.PAX_ADDRESS, { c: "write", p: pax.PaxIndex })}
                    .value=${pax.PaxAddress?.Address}
                    @input=${(e: KeyboardEvent) => handleAddressInput(pax.PaxIndex, e)}
                />
            </div>
        </div>
    `;

    const checkboxTemplate = (pax: ContactPassenger) => {
        return pax.PaxIndex === 0 && props.contactPassengers.length > 1
            ? html` <div class="row">${sameInformationCheckbox.htmlTemplate()}</div> `
            : "";
    };

    const hiddenPhoneInputTemplate = (pax: ContactPassenger) => html`
        <input
            type="hidden"
            name="passengerContacts[${pax.PaxIndex}].Phone"
            value=${`${pax.PaxAddress?.PhonePrefix || ""} ${pax.PaxAddress?.PhoneNumber || ""}`.trim() || ""}
        />
    `;

    const contactPassengerTemplate = (pax: ContactPassenger) => html`
        <div>
            ${passengerNameTemplate(pax)}
            <div class="i2-passengers-form">
                <div class="row">${emailInputTemplate(pax)} ${phoneInputTemplate(pax)}</div>
                <div class="row">
                    ${countryInputTemplate(pax)} ${comunaInputTemplate(pax)} ${addressInputTemplate(pax)}
                </div>
                ${checkboxTemplate(pax)} ${hiddenPhoneInputTemplate(pax)}
            </div>
        </div>
    `;

    const formTemplate = () => html`
        <form class="i2-passengers-container" ref=${ref(formElement)} action=${ROUTES.ContactPassengers} method="post">
            ${props.contactPassengers.map(contactPassengerTemplate)}
        </form>
    `;

    const htmlTemplate = () =>
        props.model.ItineraryModelV2
            ? html`
                  <div class="passengers-tab">
                      <div class="i2-tab-title">
                          <i class="js-icon-refund js-refund-rw-passengers"></i>
                          <div>${i18next.t("Información de Pasajeros")}</div>
                      </div>
                      <div class="i2-passengers-label">
                          ${i18next.t(
                              "Ingresa aquí la información de contacto de cada uno de los pasajeros de tu reserva.",
                          )}
                      </div>
                      <div class="i2-tab-content">${formTemplate()}</div>
                  </div>
                  <div class="modal-button-container">
                      <button
                          class="rounded-primary-btn"
                          data-test-id=${T.ITINERARY.PAX_FORM_SUBMIT_BUTTON}
                          @click=${() => validate()}
                      >
                          ${i18next.t("Guardar Información")}
                      </button>
                  </div>
              `
            : html``;

    return {
        htmlTemplate,
        validate,
    };
};

import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { SelectOption, showLoader } from "../../shared/common";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { XmlInstallmentDropdownOption } from "../../component-models/XmlInstallmentOption";
import { useInstallmentsCommonTemplate } from "./useInstallmentsCommonTemplate";
import { useRef } from "haunted";
import { ref } from "../../directives/ref";
import { MDL_CLASS_NAMES } from "../../shared/MdlClassNames";
import ApiPaymentInstallmentOptions from "../../component-models/payment/ApiPaymentInstallmentOptions";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useBookingManager } from "../../managers/useBookingManager";
import { useAppContext } from "../../managers/useAppContext";
import { ApiInstallmentXmlRouteType } from "../../component-models/payment/ApiInstallmentXmlRouteType";
import { useFlowContext } from "../../managers/useFlowContext";
import { isBinInRange } from "../../component-helpers/payment/XmlInstallmentHelpers";
import { defaultInstallmentOptions, mapToInstallmentOptions } from "../../component-mappers/PaymentMappers";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";

export interface Props {
    isActive: boolean;
    model: PaymentPageViewModel;
    selectedInstallmentsNumber: number;
    handleInstallmentChange: (selectedInstallmentNumber: number, newTotal: number) => void;
    tealiumLogXmlInstallmentChange: (options: XmlInstallmentDropdownOption[], value: number) => void;
}

export const useInstallmentsXml = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const { isInstallmentsDisabledForFlow } = paymentHelper();

    const [paymentMethod] = useReduxState("payment.paymentMethod");
    const [cardData] = useReduxState("payment.cardData");
    const [payerData] = useReduxState("payment.payer");
    const [selectedCurrency] = useReduxState("payment.selectedCurrency");

    // DEVNOTE: To be removed when MDL is removed
    const mdlBugfix = useRef<HTMLDivElement>(undefined);

    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [options, setOptions] = useState<XmlInstallmentDropdownOption[]>([]);
    const [installmentOptionsType, setInstallmentOptionsType] = useState<"paymentMethod" | "cardCode">(undefined);
    const [installmentRouteType, setInstallmentRouteType] = useState<ApiInstallmentXmlRouteType>(
        ApiInstallmentXmlRouteType.Empty,
    );

    const installmentsCommonTemplate = useInstallmentsCommonTemplate({
        options: options.map((o): SelectOption => ({ Value: o.Value.toString(), Text: o.Text })),
        selectedInstallmentsNumber: props.selectedInstallmentsNumber,
        handleNumberOfInstallmentsChange: (e, numberOfInstallments) => handleInstallmentChange(e, numberOfInstallments),
    });

    // HELPERS

    const hasOptionsForCurrencyAndIssuerCountry = (option: ApiPaymentInstallmentOptions) =>
        option.CurrencyCode.toLowerCase() === selectedCurrency?.toLowerCase() &&
        option.CardIssuingCountry.toLowerCase() === payerData?.CurrentCardIssuerCountry?.toLowerCase();

    const showSelector = () =>
        props.isActive &&
        !isInstallmentsDisabledForFlow(appContext, flowContext) &&
        paymentMethod?.PaymentMethodType === "Credit" &&
        props.model.MethodsViewModel.InstallmentsXml.some(hasOptionsForCurrencyAndIssuerCountry);

    const getOptionsForMethodCountryAndCurrency = (options: ApiPaymentInstallmentOptions[]) =>
        options.filter(
            (item) =>
                item.PaymentMethodCode.toLowerCase() === paymentMethod?.PaymentMethodCode.toLowerCase() &&
                item.CurrencyCode.toLowerCase() === selectedCurrency?.toLowerCase() &&
                item.CardIssuingCountry.toLowerCase() === payerData?.CurrentCardIssuerCountry?.toLowerCase(),
        );

    const getOptionsForCardCode = (options: ApiPaymentInstallmentOptions[]) =>
        options.filter((item) => item.CodeCard.toLowerCase() === cardData?.PaymentMethodCodeToSubmit.toLowerCase());

    const getOptionsForNoCardCode = (options: ApiPaymentInstallmentOptions[]) =>
        options.filter((item) => !item.CodeCard);

    const getOptionsForRoute = (options: ApiPaymentInstallmentOptions[]) =>
        options.filter(
            (item) =>
                (item.Routes === ApiInstallmentXmlRouteType.DOM && props.model.BookingViewModel.IsDomesticFlight) ||
                (item.Routes === ApiInstallmentXmlRouteType.INTER && !props.model.BookingViewModel.IsDomesticFlight),
        );

    const getOptionsForNoRoute = (options: ApiPaymentInstallmentOptions[]) => options.filter((item) => !item.Routes);

    const getOptionsForBin = (options: ApiPaymentInstallmentOptions[]) =>
        options.find((item) => isBinInRange(item.BinRangeLower, item.BinRangeHigher, cardData?.CardNumber));

    const getInstallments = (): ApiPaymentInstallmentOptions => {
        const optionsForMethodCountryAndCurrency = getOptionsForMethodCountryAndCurrency(
            props.model.MethodsViewModel.InstallmentsXml,
        );

        const optionsForCardCode = getOptionsForCardCode(optionsForMethodCountryAndCurrency);
        const optionsForNoCardCode = getOptionsForNoCardCode(optionsForMethodCountryAndCurrency);

        const optionsForCardCodeAndRoute = getOptionsForRoute(optionsForCardCode);
        const optionsForNoCardCodeAndRoute = getOptionsForRoute(optionsForNoCardCode);
        const optionsForCardCodeAndNoRoute = getOptionsForNoRoute(optionsForCardCode);
        const optionsForNoCardCodeAndNoRoute = getOptionsForNoRoute(optionsForNoCardCode);

        return (
            getOptionsForBin(optionsForCardCodeAndRoute) ||
            getOptionsForBin(optionsForCardCodeAndNoRoute) ||
            getOptionsForBin(optionsForNoCardCodeAndRoute) ||
            getOptionsForBin(optionsForNoCardCodeAndNoRoute)
        );
    };

    const getPostBody = (installments: number) => ({
        "addInterestFee.CardBin": cardData?.CardNumber.substr(0, 6),
        "addInterestFee.CardIssuerCountryCode": payerData?.CurrentCardIssuerCountry,
        "addInterestFee.CurrencyCode": selectedCurrency,
        "addInterestFee.PaymentMethodCode": paymentMethod?.PaymentMethodCode,
        "addInterestFee.SelectedInstallmentCount": installments?.toString(),
        "addInterestFee.CodeCard": installmentOptionsType === "cardCode" ? cardData?.PaymentMethodCodeToSubmit : "",
        "addInterestFee.RouteType": installmentRouteType?.toString(),
    });

    const updateOptions = (options: ApiPaymentInstallmentOptions) => {
        const newOptions = mapToInstallmentOptions(options);
        setOptions(newOptions);
        setIsEnabled(true);
    };

    const reset = () => {
        setIsEnabled(false);
        props.handleInstallmentChange(1, 0);

        setOptions(defaultInstallmentOptions());
        installmentsCommonTemplate.removeMessage();
    };

    // EVENT HANDLERS

    const handleInstallmentChange = async (e?: MouseEvent, numberOfInstallments?: number) => {
        const value = numberOfInstallments ? numberOfInstallments : Number((e.target as HTMLSelectElement).value);

        if (isInstallmentsDisabledForFlow(appContext, flowContext) || !cardData?.CardNumber || !value) {
            return;
        }

        const body = getPostBody(value);
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });

        await bookingManager.postApplyInterestFee(body, loader);

        const newTotal = options.find((o) => o.Value === value)?.Total;

        props.tealiumLogXmlInstallmentChange(options, value);
        props.handleInstallmentChange(value, newTotal || 0);
    };

    const handleCardChange = async () => {
        if (!props.isActive || !props.model.MethodsViewModel.InstallmentsXml) return;

        if (
            !cardData?.CardNumber ||
            !cardData?.CardType ||
            cardData?.CardValidationStatus !== "valid" ||
            paymentMethod?.PaymentMethodType !== "Credit"
        ) {
            reset();
            return;
        }

        setIsEnabled(false);
        installmentsCommonTemplate.removeMessage();

        const installments = getInstallments();

        if (installments?.Options.length > 0) {
            setInstallmentOptionsType(installments.CodeCard ? "cardCode" : "paymentMethod");
            setInstallmentRouteType(installments.Routes);
            updateOptions(installments);
        } else {
            reset();
            setInstallmentRouteType(undefined);
            setInstallmentOptionsType(undefined);
        }

        await handleInstallmentChange(undefined, 1);
    };

    useEffect(() => mdlBugfix.current?.classList.add(MDL_CLASS_NAMES.Dirty));

    useEffect(handleCardChange, [
        cardData?.CardNumber,
        cardData?.CardType,
        cardData?.CardValidationStatus,
        props.isActive,
        selectedCurrency,
    ]);

    useEffect(() => handleInstallmentChange(undefined, 1), [selectedCurrency]);

    // TEMPLATES

    const tooltipTemplate = () => html`
        <div class="installment-tooltip-container">
            <span class="installment-tooltip">${i18next.t("InstallmentTooltipText")}</span>
        </div>
    `;

    const containerClassMap = classNames("col-xs-1 tooltip-trigger", { disabled: !isEnabled });

    const inputClassMap = classNames("mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow", {
        disabled: !isEnabled,
    });

    const htmlTemplate = () =>
        showSelector()
            ? html`
                  <div class="row">
                      <div class=${containerClassMap}>
                          <div class=${inputClassMap} ref=${ref(mdlBugfix)}>
                              ${installmentsCommonTemplate.htmlTemplate()}
                          </div>
                          ${tooltipTemplate()}
                      </div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

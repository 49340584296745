import { useEffect } from "haunted";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { COOKIE_NAMES } from "../../shared/commonConstants";
import { deleteCookie } from "../../shared/cookieHandling";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";

export const name = "ac-log-banco-estado-register";

export const observedAttributes: (keyof Attributes)[] = ["ibe", "org", "role", "programs", "level", "category"];

export interface Attributes {
    ibe: string;
    org: string;
    role: string;
    programs: string;
    level: string;
    category: string;
}

export interface Props {
    ibe: string;
    org: string;
    role: string;
    programs: string;
    level: string;
    category: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        ibe: host.ibe,
        org: host.org,
        role: host.role,
        programs: host.programs,
        level: host.level,
        category: host.category,
    };

    const init = () => {
        tealiumManager.logRegister(props.ibe, props.org, props.role, props.programs, props.level, props.category);

        deleteCookie(COOKIE_NAMES.Tealium);
    };

    const tealiumManager = useAuthenticationTealiumManager();

    useEffect(init, []);

    return html``;
};

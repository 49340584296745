export interface TravelDocument {
    DocSuffix: string;
    ExpirationDate: string;
    IsReadonly: boolean;
    IssuedByCode: string;
    Number: string;
    PassengerNumber: number;
    TypeCode: TravelDocumentType;
}

export const AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE = "OAFF";
export const AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER = "AA";

export type TravelDocumentType = "N" | "P" | "DNI" | typeof AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE;

export type Cug2bDocumentType = "RUT" | "PAS" | "DNI";

export type Gender = "Male" | "Female" | "Unknown";

import { CLASS_NAMES } from "./classNames";
import { MDL_CLASS_NAMES } from "./MdlClassNames";

const hiddenClassNames: string[] = ["hide", CLASS_NAMES.Hidden];
const disabledClassNames: string[] = [CLASS_NAMES.disabled, MDL_CLASS_NAMES.Disabled];
export default class DomCrawlingHelper {
    public static findParentByClass(element: HTMLElement, className: string): HTMLElement {
        while (element) {
            if (element.classList.contains(className)) {
                return element;
            }
            element = element.parentElement;
        }

        return undefined;
    }

    public static getElemByClass<T = HTMLElement>(element: HTMLElement, name: string): T {
        if (!element) {
            return undefined;
        }
        return element.querySelector("." + name) as T;
    }

    public static getArrayOfClass<T = HTMLElement>(element: HTMLElement, name: string): T[] {
        if (!element) {
            return [];
        }
        return Array.from(element.querySelectorAll("." + name)) as T[];
    }

    public static getElemById(element: HTMLElement, name: string): HTMLElement {
        if (!element) {
            return undefined;
        }
        return element.querySelector("#" + name);
    }

    public static isElementVisible(element: Element): boolean {
        let isVisible = true;

        while (element) {
            const isVisibleInDom = this.isElementVisibleInDom(element);
            isVisible = isVisible && isVisibleInDom;
            element = isVisible ? element.parentElement : undefined;
        }

        return isVisible;
    }

    public static isElementDisabled(element: Element): boolean {
        let isDisabled = false;

        while (element) {
            const isDisabledInDom = this.isElementDisabledInDom(element);
            isDisabled = isDisabled || isDisabledInDom;
            element = !isDisabled ? element.parentElement : undefined;
        }

        return isDisabled;
    }

    private static isElementVisibleInDom(element: Element) {
        return hiddenClassNames.reduce(
            (aggr, hiddenClassName) =>
                aggr && !element.classList.contains(hiddenClassName) && this.isElementVisibleInCss(element),
            true,
        );
    }

    private static isElementVisibleInCss(element: Element): boolean {
        return (
            (element as HTMLElement).style.visibility !== "hidden" && (element as HTMLElement).style.display !== "none"
        );
    }

    private static isElementDisabledInDom(element: Element): boolean {
        return disabledClassNames.reduce(
            (aggr: boolean, disabledClassName) =>
                aggr || element.classList.contains(disabledClassName) || element.hasAttribute("disabled"),
            false,
        );
    }
}

import { component } from "haunted";
import * as DC from "./dc-components/dc-components";

customElements.define(
    DC.Input.name,
    component<HTMLElement & DC.Input.Properties>(DC.Input.Component, {
        useShadowDOM: false,
        observedAttributes: DC.Input.observedAttributes,
    }),
);

customElements.define(
    DC.Tabs.name,
    component<HTMLElement & DC.Tabs.Properties>(DC.Tabs.Component, {
        useShadowDOM: false,
        observedAttributes: DC.Tabs.observedAttributes,
    }),
);

customElements.define(
    DC.Table.name,
    component<HTMLElement & DC.Table.Properties>(DC.Table.Component, {
        useShadowDOM: false,
        observedAttributes: DC.Table.observedAttributes,
    }),
);

customElements.define(
    DC.TableGrid.name,
    component<HTMLElement & DC.TableGrid.Properties>(DC.TableGrid.Component, {
        useShadowDOM: false,
        observedAttributes: DC.TableGrid.observedAttributes,
    }),
);

customElements.define(
    DC.Checkbox.name,
    component<HTMLElement & DC.Checkbox.Properties>(DC.Checkbox.Component, {
        useShadowDOM: false,
        observedAttributes: DC.Checkbox.observedAttributes,
    }),
);

customElements.define(
    DC.TableGridHeader.name,
    component<HTMLElement & DC.TableGridHeader.Properties>(DC.TableGridHeader.Component, {
        useShadowDOM: false,
        observedAttributes: [],
    }),
);

customElements.define(
    DC.Pager.name,
    component<HTMLElement & DC.Pager.Properties>(DC.Pager.Component, {
        useShadowDOM: false,
        observedAttributes: DC.Pager.observedAttributes,
    }),
);

customElements.define(
    DC.TableHeader.name,
    component<HTMLElement & DC.TableHeader.Properties>(DC.TableHeader.Component, {
        useShadowDOM: false,
        observedAttributes: DC.TableHeader.observedAttributes,
    }),
);

customElements.define(
    DC.DatePicker.name,
    component<HTMLElement & DC.DatePicker.Properties>(DC.DatePicker.Component, {
        useShadowDOM: false,
        observedAttributes: DC.DatePicker.observedAttributes,
    }),
);

import { AGENCY_URL_PARTIAL_STRING, COOKIE_NAMES, CUG2_AGENCY_LOGOS } from "./../../shared/commonConstants";
import { useEffect, useState } from "./../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { CLASS_NAMES } from "../../shared/classNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { useRef } from "haunted";
import { ref } from "../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import { getStringForCulture, insensitiveEquals } from "../../component-helpers/stringHelper";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { deleteCookie } from "../../shared/cookieHandling";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";

export const observedAttributes: (keyof Attributes)[] = ["notification-count"];
export const name = "ac-cug-header";

export interface Attributes {
    "notification-count": string;
}
export interface Properties {
    notificationCount: number;
}

// DEVNOTE The whole scrolling thing is a copy from the regular menu behaviour on scroll.
// Could be made nicer, but it works as is.
// If we change this, we should change the regular menu bar accordingly, too.

let didScroll = false;
let lastScrollTop = 0;

const headerClassName = "cug2b-header";
const mobileHeaderClassName = "cug2b-mobile-header";

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        notificationCount: Number(host.notificationCount),
    };

    // HELPERS
    const isAgencyWhenNotLoggedIn = () => window.location.href.indexOf(AGENCY_URL_PARTIAL_STRING) > -1;

    const isAgency = () => (userContext.isLoggedIn ? cug2AppContext.Type === "agency" : isAgencyWhenNotLoggedIn());

    const logoImage = (isMobile: boolean) => {
        const typeAndResolution = CUG2_AGENCY_LOGOS.filter(
            (item) => item.isAgency === isAgency() && item.isMobile === isMobile,
        );

        return (
            typeAndResolution.find((item) => insensitiveEquals(item.culture, culture())) ||
            typeAndResolution.find((item) => !item.culture)
        )?.url;
    };

    const settings = () => cug2AppContext.Cug2BSettings || appContext.Cug2BSettings;

    const isLoggedIn = () => userContext.isLoggedIn || userContext.isLoggedIn;
    const culture = () => cug2AppContext.Culture || appContext.Culture;

    const hasScrolled = () => {
        const navbar = DomCrawlingHelper.getElemByClass(document.body, headerClassName);
        const mobileNavbar = DomCrawlingHelper.getElemByClass(document.body, mobileHeaderClassName);

        const st = window.scrollY;

        if (Math.abs(lastScrollTop - st) <= 0) {
            return;
        }

        if (navbar && navbar.offsetHeight > 0 && window.watchScroll) {
            if (st > lastScrollTop && st > navbarHeight) {
                navbar.classList.add(CLASS_NAMES.mainHeaderCollapsed);
            } else {
                navbar.classList.remove(CLASS_NAMES.mainHeaderCollapsed);
            }
        }

        if (mobileNavbar && mobileNavbar.offsetHeight > 0 && window.watchScroll) {
            if (st > lastScrollTop && st > mobileNavbarHeight) {
                mobileNavbar.classList.add(CLASS_NAMES.mainHeaderCollapsed);
            } else {
                mobileNavbar.classList.remove(CLASS_NAMES.mainHeaderCollapsed);
            }
        }

        lastScrollTop = st;
    };

    const init = () => {
        userContextManager.init();

        document.addEventListener("click", onClickOutsideUserDropdown, true);
        document.addEventListener("click", onClickOutsideInfoDropdown, true);
        document.addEventListener("click", onClickOutsideHelpDropdown, true);

        window.addEventListener(
            "scroll",
            () => {
                didScroll = true;
            },
            false,
        );

        window.setInterval(() => {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        window.watchScroll = true;
        const navbar = DomCrawlingHelper.getElemByClass(document.body, headerClassName) as HTMLDivElement;
        const mobileNavbar = DomCrawlingHelper.getElemByClass(document.body, mobileHeaderClassName) as HTMLDivElement;
        setNavbarHeight(navbar.getBoundingClientRect().height);
        setMobileNavbarHeight(mobileNavbar.getBoundingClientRect().height);
    };

    // EVENT LISTENERS

    const onClickOutsideUserDropdown = (e: any) => {
        if (!userDropdownRoot?.current?.contains(e.target)) {
            setIsUserDropdownOpen(false);
        }
    };

    const onClickOutsideInfoDropdown = (e: any) => {
        if (!infoDropdownRoot.current.contains(e.target)) {
            setIsInfoDropdownOpen(false);
        }
    };

    const onClickOutsideHelpDropdown = (e: any) => {
        if (!helpDropdownRoot.current.contains(e.target)) {
            setIsHelpDropdownOpen(false);
        }
    };

    const handleLogout = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        tealiumManager.logUserLogout();

        deleteCookie(COOKIE_NAMES.Tealium);

        // DEVNOTE Timer is needed for tealium to finish, same as regular menubar logout.
        window.setTimeout(() => (window.location.href = ROUTES.Logout), 500);
    };

    // COMPONENT

    const appContext = useAppContext();
    const tealiumManager = useAuthenticationTealiumManager();
    const cug2AppContext = useCug2AppContext();
    const userContextManager = useUserContextBuilder();

    const [userContext] = useReduxState("userContext");

    const userDropdownRoot = useRef<HTMLDivElement>(undefined);
    const infoDropdownRoot = useRef<HTMLDivElement>(undefined);
    const helpDropdownRoot = useRef<HTMLDivElement>(undefined);

    const [navbarHeight, setNavbarHeight] = useState<number>(0);
    const [mobileNavbarHeight, setMobileNavbarHeight] = useState<number>(0);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
    const [isInfoDropdownOpen, setIsInfoDropdownOpen] = useState<boolean>(false);
    const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState<boolean>(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

    useEffect(init, []);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScroll);
        }
    }, [isMobileMenuOpen]);

    // TEMPLATES

    const websiteLinkTemplate = () => html` <a href="/">${i18next.t("JetSMART.com")}</a> `;

    const registrationLinkTemplate = () =>
        !isLoggedIn() ? html` <a href=${ROUTES.Cug2BRegisterPage}>${i18next.t("Inscríbete")}</a> ` : "";

    const loginLinkTemplate = () =>
        !isLoggedIn()
            ? html`
                  <a
                      href="${isAgency()
                          ? ROUTES.Cug2BStandaloneAgencyLoginPage
                          : ROUTES.Cug2BStandaloneCompanyLoginPage}"
                      >${i18next.t("Iniciar sesión")}</a
                  >
              `
            : "";

    const userDropdownContentTemplate = (hiddenClassName: string, isMobile = false) => {
        const tempClassMap = classMap({
            [hiddenClassName]: true,
            "cug2b-user-dropdown-content": true,
            "open": isUserDropdownOpen,
        });

        return html`
            <div class=${tempClassMap}>
                <a data-test-id=${getTestId(T.CUG2_HEADER.LOGOUT_USER_OPTION, { m: isMobile })} @click=${handleLogout}
                    >${i18next.t("Cerrar sessión")}</a
                >
            </div>
        `;
    };

    const userDropdownTemplate = (isMobile = false) => {
        const tempClassMap = classMap({
            "cug2b-user-dropdown": true,
            "open": isUserDropdownOpen,
        });

        return isLoggedIn()
            ? html`
                  <div
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.CUG2_HEADER.USER_DROPDOWN, { m: isMobile })}
                      ref=${ref(userDropdownRoot)}
                      @click=${() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                  >
                      <i class="js-icon-cug js-cug-man-and-plane mr-4"></i>
                      <div>
                          <div class="cug2b-name">${userContext.userFirstName} ${userContext.userLastName}</div>
                          <div class="cug2b-org-name">${userContext.cug.orgName}</div>
                      </div>
                      ${userDropdownContentTemplate("hidden-sm-down", isMobile)}
                  </div>
                  ${userDropdownContentTemplate("hidden-md-up", isMobile)}
              `
            : "";
    };

    const waiversTemplate = () =>
        userContext.isLoggedIn || userContext.isLoggedIn
            ? html`
                  <!-- TODO -->
                  <a href="" target="_blank" class="disabled"> ${i18next.t("Tus Beneficios")} </a>
              `
            : "";

    const infoDropdownContentTemplate = (hiddenClassName: string) => {
        const tempClassMap = classMap({
            [hiddenClassName]: true,
            "cug2b-info-dropdown-content": true,
            "open": isInfoDropdownOpen,
        });

        return html`
            <span class=${tempClassMap}>
                <a
                    href=${getStringForCulture(settings().TopBar.CharacteristicsUrl, cug2AppContext.Culture)}
                    target="_blank"
                >
                    ${i18next.t("Características")}
                </a>
                <a href=${getStringForCulture(settings().TopBar.YouTubeUrl, cug2AppContext.Culture)} target="_blank">
                    ${i18next.t("Aprende cómo usarlo")}
                </a>
            </span>
        `;
    };

    const infoDropdownTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-info-dropdown": true,
            "open": isInfoDropdownOpen,
        });

        return html`
            <span
                class=${tempClassMap}
                ref=${ref(infoDropdownRoot)}
                data-test-id=${T.CUG2_HEADER.INFO_DROPDOWN}
                @click=${() => setIsInfoDropdownOpen(!isInfoDropdownOpen)}
            >
                ${i18next.t("Conoce el Portal")} ${infoDropdownContentTemplate("hidden-sm-down")}
            </span>
            ${infoDropdownContentTemplate("hidden-md-up")}
        `;
    };

    const helpDropdownContentTemplate = (hiddenClassName: string) => {
        const tempClassMap = classMap({
            [hiddenClassName]: true,
            "cug2b-info-dropdown-content": true,
            "open": isHelpDropdownOpen,
        });

        return html`
            <span class=${tempClassMap}>
                <a href=${getStringForCulture(settings().TopBar.ConditionsUrl, cug2AppContext.Culture)} target="_blank">
                    ${i18next.t("Condiciones comerciales")}
                </a>
                <a
                    href=${getStringForCulture(settings().TopBar.TermsAndConditionsUrl, cug2AppContext.Culture)}
                    target="_blank"
                >
                    ${i18next.t("Términos y condiciones")}
                </a>
                <a href=${getStringForCulture(settings().TopBar.SmarticketUrl, cug2AppContext.Culture)} target="_blank">
                    ${i18next.t("Smarticket")}
                </a>
                <a href=${getStringForCulture(settings().TopBar.HelpCenterUrl, cug2AppContext.Culture)} target="_blank">
                    ${i18next.t("Centro de ayuda")}
                </a>
                <a href=${getStringForCulture(settings().TopBar.FaqUrl, cug2AppContext.Culture)} target="_blank">
                    ${i18next.t("Preguntas frecuentes")}
                </a>
            </span>
        `;
    };

    const helpDropdownTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-info-dropdown": true,
            "open": isHelpDropdownOpen,
        });

        return html`
            <span
                class=${tempClassMap}
                ref=${ref(helpDropdownRoot)}
                data-test-id=${T.CUG2_HEADER.HELP_DROPDOWN}
                @click=${() => setIsHelpDropdownOpen(!isHelpDropdownOpen)}
            >
                ${i18next.t("¿Necesitas Ayuda?")} ${helpDropdownContentTemplate("hidden-sm-down")}
            </span>
            ${helpDropdownContentTemplate("hidden-md-up")}
        `;
    };

    const cultureSelectorTemplate = () => html` <ac-culture-selector></ac-culture-selector> `;

    const userNotificationsTemplate = () => html`
        <ac-cug-notification .notificationCount=${props.notificationCount}></ac-cug-notification>
    `;

    const logoTemplate = (isMobile: boolean) =>
        !userContext.peruCompra.isAdmin && !userContext.peruCompra.isMember
            ? html` <img src=${logoImage(isMobile)} /> `
            : "";

    const desktopHeaderTemplate = () => {
        return html`
            <header class="${headerClassName} hidden-sm-down">
                <div class="cug2b-header-site-menu">
                    <div class="cug2b-container flex h-full items-center justify-end">
                        ${websiteLinkTemplate()} ${registrationLinkTemplate()} ${loginLinkTemplate()}
                        ${userNotificationsTemplate()} ${userDropdownTemplate()} ${cultureSelectorTemplate()}
                    </div>
                </div>
                <div class="cug2b-header-info-menu-container">
                    <div class="cug2b-container relative flex h-full items-center">
                        ${logoTemplate(false)}
                        <div class="cug2b-header-info-menu">
                            ${infoDropdownTemplate()} ${waiversTemplate()} ${helpDropdownTemplate()}
                        </div>
                    </div>
                </div>
            </header>
        `;
    };

    const mobileMenuOpenerTemplate = () =>
        isMobileMenuOpen
            ? html`
                  <div class="mobile-navigation" @click=${() => setIsMobileMenuOpen(false)}>
                      ${i18next.t("MobileMenuClose")}
                  </div>
              `
            : html`
                  <div class="mobile-navigation" @click=${() => setIsMobileMenuOpen(true)}>
                      ${i18next.t("MobileMenuLabel")}
                  </div>
              `;

    const mobileMenuContentTemplate = () =>
        isMobileMenuOpen
            ? html`
                  <div class="cug2b-mobile-menu-content hidden-md-up">
                      <div class="cug2b-header-site-menu">
                          ${websiteLinkTemplate()} ${registrationLinkTemplate()} ${loginLinkTemplate()}
                      </div>
                      ${userDropdownTemplate(true)}
                      <div class="cug2b-header-info-menu">
                          ${infoDropdownTemplate()} ${waiversTemplate()} ${helpDropdownTemplate()}
                      </div>
                  </div>
              `
            : "";

    const mobileHeaderTemplate = () => {
        return html`
            <header class="${mobileHeaderClassName} hidden-md-up">
                <input
                    class="mobile-navigation"
                    type="checkbox"
                    data-test-id=${T.CUG2_HEADER.HAMBURGER_MENU}
                    @click=${() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                />
                ${logoTemplate(true)}
                <div class="flex flex-row items-center">
                    ${userNotificationsTemplate()} ${cultureSelectorTemplate()} ${mobileMenuOpenerTemplate()}
                </div>
                ${isMobileMenuOpen
                    ? html` <span class="mobile-navigation"> ${i18next.t("MobileMenuClose")} </span> `
                    : html` <span class="mobile-navigation"> ${i18next.t("MobileMenuLabel")} </span> `}
            </header>
            ${mobileMenuContentTemplate()}
        `;
    };

    return html` ${desktopHeaderTemplate()} ${mobileHeaderTemplate()} `;
};

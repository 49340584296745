import { CheckinPassengerModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerModel";
import { useState } from "../../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { CLASS_NAMES } from "../../../../shared/classNames";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { useRef } from "haunted";
import { ref } from "../../../../directives/ref";
import { CheckinTypeSelection } from "./useCheckinType";
import { CheckinOptionClickedEvent } from "../../../../component-models/extras/checkin-type/CheckinTypeEvents";
import { classMap } from "lit-html/directives/class-map";
import { CheckinPassengerJourneyModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerJourneyModel";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { journeyBundleInfoTemplate, journeyRouteTemplate } from "../../../../common-templates/ExtrasCommonTemplates";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useBasicCheckbox } from "../../../ui/basic-checkbox/useBasicCheckbox";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import BookingData from "../../../../shared/BookingData";
import { useBookingContext } from "../../../../managers/useBookingContext";

export const name = "ac-checkin-type-passenger-journey";

export interface Props {
    checkinTypeSelection: CheckinTypeSelection;
    isValidated: boolean;
    model: CheckinPassengerJourneyModel;
    passenger: CheckinPassengerModel;
    disableCopy: () => void;
    enableCopy: () => void;
    uncheckCopy: () => void;
    setIsCopyDisabled: (isDisabled: boolean) => void;
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        checkinTypeSelection: host.checkinTypeSelection,
        isValidated: host.isValidated,
        model: host.model,
        passenger: host.passenger,
        disableCopy: host.disableCopy,
        enableCopy: host.enableCopy,
        uncheckCopy: host.uncheckCopy,
        setIsCopyDisabled: host.setIsCopyDisabled,
        setExtrasModel: host.setExtrasModel,
    };

    // HELPERS

    const showFreeOptionWarning = () => props.checkinTypeSelection === "free";

    const showPaidOptionWarning = () => props.checkinTypeSelection === "paid" && !props.model.IsDisabled;

    const showRequiredError = () => props.isValidated && props.checkinTypeSelection === "none";

    const postSelection = async (type: "add" | "remove") => {
        host.dispatchEvent(
            new CheckinOptionClickedEvent({
                paxNumber: props.passenger.PassengerNumber,
                journeyIndex: props.model.JourneyIndex,
                checkinTypeSelection: type === "add" ? "paid" : "free",
                previousPrice: props.checkinTypeSelection === "paid" ? props.model.PaidOption?.Amount : 0,
                price: type === "add" ? props.model.PaidOption?.Amount : 0,
                ssrAddKey: props.model.PaidOption?.SsrAddKey,
                ssrRemoveKey: props.model.PaidOption?.SsrDeleteKey,
            }),
        );

        props.uncheckCopy();
        props.setIsCopyDisabled(true);

        if (type === "add" || props.checkinTypeSelection === "paid") {
            setIsLoading(true);

            const container = DomCrawlingHelper.getElemByClass(root.current, CLASS_NAMES.errorContainer);
            const body = {
                "selectedJourneySsrs[0]":
                    type === "add" ? props.model.PaidOption?.SsrAddKey : props.model.PaidOption?.SsrDeleteKey,
            };

            const response = await bookingManager.postAirportCheckinSsrUpdate<{
                BookingSummary: BookingData;
                ExtrasModel: ApiExtrasModel;
            }>(body, container, undefined);

            props.setExtrasModel(response.ExtrasModel);

            setIsLoading(false);
        }

        props.setIsCopyDisabled(false);
    };

    // EVENT HANDLERS

    const handleFreeSelection = async () => {
        if (props.checkinTypeSelection === "free") {
            return;
        }

        await postSelection("remove");
    };

    const handlePaidSelection = async () => {
        if (props.checkinTypeSelection === "paid") {
            return;
        }

        await postSelection("add");
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const bookingManager = useBookingManager();

    const bookingContext = useBookingContext();

    const paidOptionLabelTemplate = (id: string) => {
        const contentClassMap = classMap({
            "checkin-type-label": true,
            "disabled": props.model.IsDisabled,
        });

        return html`
            <label
                for=${id}
                class=${contentClassMap}
                data-test-id=${getTestId(T.EXTRAS_CHECKIN.PAID_OPTION, {
                    j: props.model.JourneyIndex,
                    p: props.passenger.PassengerNumber,
                })}
            >
                <div class="checkin-type-info-container">
                    <span
                        class="cb-title checkin-type-title"
                        data-test-id=${getTestId(T.EXTRAS_CHECKIN.PAID_OPTION_TITLE, {
                            j: props.model.JourneyIndex,
                            p: props.passenger.PassengerNumber,
                        })}
                        >${props.model.PaidOption.Name}</span
                    >
                    <span
                        class="cb-title cb-amount checkin-type-amount"
                        data-test-id=${getTestId(T.EXTRAS_CHECKIN.PAID_OPTION_AMOUNT, {
                            j: props.model.JourneyIndex,
                            p: props.passenger.PassengerNumber,
                        })}
                    >
                        ${props.model.IsInBundle ? i18next.t("V2-Included") : props.model.PaidOption.FormattedAmount}
                    </span>
                </div>
            </label>
        `;
    };

    const freeOptionLabelTemplate = (id: string) => {
        const contentClassMap = classMap({
            "checkin-type-label": true,
            "disabled": props.model.IsDisabled,
        });

        return html`
            <label
                for=${id}
                class=${contentClassMap}
                data-test-id=${getTestId(T.EXTRAS_CHECKIN.FREE_OPTION, {
                    j: props.model.JourneyIndex,
                    p: props.passenger.PassengerNumber,
                })}
            >
                <div class="checkin-type-info-container">
                    <span
                        class="cb-title checkin-type-title"
                        data-test-id=${getTestId(T.EXTRAS_CHECKIN.FREE_OPTION_TITLE, {
                            j: props.model.JourneyIndex,
                            p: props.passenger.PassengerNumber,
                        })}
                    >
                        ${props.model.FreeOption.Name}
                        <span class="online-checkin-help">
                            <ac-tooltip .icon=${"?"} .tooltip=${i18next.t("V2-BoardingPassInfo2")}></ac-tooltip>
                        </span>
                    </span>
                    <span
                        class="cb-title cb-amount checkin-type-amount"
                        data-test-id=${getTestId(T.EXTRAS_CHECKIN.FREE_OPTION_AMOUNT, {
                            j: props.model.JourneyIndex,
                            p: props.passenger.PassengerNumber,
                        })}
                    >
                        ${i18next.t("V2-Included")}
                    </span>
                </div>
            </label>
        `;
    };

    const freeOptionCheckbox = useBasicCheckbox({
        isChecked: props.checkinTypeSelection === "free",
        customWrapperClass: "extras-checkin-type-option-wrapper",
        customInputClass: "checkin-type-checkbox-input",
        disabled: isLoading,
        labelTemplate: freeOptionLabelTemplate,
        readonly: props.model.IsInBundle || props.model.IsDisabled,
        onClick: handleFreeSelection,
    });

    const paidOptionCheckbox = useBasicCheckbox({
        isChecked: props.checkinTypeSelection === "paid",
        customWrapperClass: "extras-checkin-type-option-wrapper",
        customInputClass: "checkin-type-checkbox-input",
        disabled: isLoading,
        labelTemplate: paidOptionLabelTemplate,
        readonly: props.model.IsInBundle || props.model.IsDisabled,
        onClick: handlePaidSelection,
    });

    // TEMPLATES

    const mobileBundleHeaderTemplate = () =>
        props.model.IsInBundle
            ? journeyBundleInfoTemplate(
                  props.model.BundleColor,
                  props.model.BundleImg,
                  T.EXTRAS_CHECKIN.JOURNEY_BUNDLE,
                  props.passenger.PassengerNumber,
                  props.model.JourneyIndex,
                  true,
                  bookingContext.isGdsBooking,
              )
            : "";

    const mobileTitleTemplate = () => {
        return html` <h3 class="package-title visible-xs">${journeyRouteTemplate(props.model)}</h3> `;
    };

    const paidOptionCheckboxTemplate = () => {
        const mainClassMap = classMap({
            "row": true,
            "elevated-row": props.model.PaidOption.Amount === 0,
        });

        return html`
            <div class=${mainClassMap}>
                <div class="col-xs-1">
                    ${paidOptionCheckbox.htmlTemplate()}</div>
                </div>
            </div>
        `;
    };
    const freeOptionCheckboxTemplate = () => {
        const mainClassMap = classMap({
            "row": true,
            "elevated-row": props.model.FreeOption.Amount === 0,
        });

        return html`
            <div class=${mainClassMap}>
                <div class="col-xs-1">${freeOptionCheckbox.htmlTemplate()}</div>
            </div>
        `;
    };

    const requiredErrorTemplate = () =>
        showRequiredError()
            ? html`
                  <div
                      class="row"
                      data-test-id=${getTestId(T.EXTRAS_CHECKIN.REQUIRED_FIELD_WARNING, {
                          j: props.model.JourneyIndex,
                          p: props.passenger.PassengerNumber,
                      })}
                  >
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-PleaseSelectWarning")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const freeOptionWarningTemplate = () =>
        showFreeOptionWarning()
            ? html`
                  <div
                      class="full-width-information ts-extras-warning-1"
                      data-test-id=${getTestId(T.EXTRAS_CHECKIN.FREE_OPTION_WARNING, {
                          j: props.model.JourneyIndex,
                          p: props.passenger.PassengerNumber,
                      })}
                  >
                      <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                      <span> ${i18next.t("V2-AirportPriceWarning")} </span>
                  </div>
              `
            : "";

    const paidOptionWarningTemplate = () =>
        showPaidOptionWarning()
            ? html`
                  <div
                      class="full-width-information ts-extras-warning-2"
                      data-test-id=${getTestId(T.EXTRAS_CHECKIN.PAID_OPTION_WARNING, {
                          j: props.model.JourneyIndex,
                          p: props.passenger.PassengerNumber,
                      })}
                  >
                      <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                      <span>${i18next.t("V2-BoardingPassInfo3")}</span>
                  </div>
              `
            : "";

    const mainClassMap = classMap({
        "checkin-form": true,
        "bottom-half": true,
        [CLASS_NAMES.invalid]: props.isValidated && props.checkinTypeSelection === "none",
    });

    return html`
        <div
            ref=${ref(root)}
            data-test-id=${getTestId(T.EXTRAS_CHECKIN.PASSENGER_JOURNEY, {
                p: props.passenger.PassengerNumber,
                j: props.model.JourneyIndex,
            })}
        >
            ${mobileBundleHeaderTemplate()}

            <div class=${mainClassMap}>
                <div class="inner-box bottom-half ts-error-parent">
                    ${mobileTitleTemplate()} ${freeOptionCheckboxTemplate()} ${paidOptionCheckboxTemplate()}
                    ${requiredErrorTemplate()}
                </div>
                ${freeOptionWarningTemplate()} ${paidOptionWarningTemplate()}
            </div>
        </div>
    `;
};

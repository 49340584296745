import { html } from "lit-html";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface DcTermsTemplateProps {
    context: FlightPageContext;
}

export const useDcTermsTemplate = (props: DcTermsTemplateProps) => {
    const [userContext] = useReduxState("userContext");

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const selectedOption = props.context?.bannerState?.selectedOption;
    const flightVm = props.context?.bannerState?.bannerVM?.flightPageViewModel.FlightDataViewModel;

    const handleCheckboxClick = () => {
        setTermsAccepted(!termsAccepted);
        props.context.changeTermsAcceptance(
            props.context?.bannerState.termsAcceptance === "Accepted" ? "NotAccepted" : "Accepted",
            selectedOption,
        );
    };

    const dcCheckboxLabelTemplate = (id: string) => html`
        <label for=${id}>
            ${i18next.t("Dc-BannerTerms-FirstPart")}&nbsp;
            <a href=${flightVm.TermsUrl || ""} target="_blank" @click=${(e: MouseEvent) => e.stopPropagation()}>
                ${i18next.t("Términos y Condiciones del Club de Descuentos")}
            </a>
        </label>
    `;

    const dcCheckbox = useBasicCheckbox({
        customLabelClass: "dc-checkbox-label",
        customWrapperClass: "dc-checkbox-container",
        inputTestId: T.FLIGHT_DC_BANNER.TERMS_ACCEPTANCE_CHECKBOX,
        isChecked: props.context?.bannerState?.termsAcceptance === "Accepted",
        labelTestId: T.FLIGHT_DC_BANNER.TERMS_ACCEPTANCE_LABEL,
        labelTemplate: dcCheckboxLabelTemplate,
        onClick: handleCheckboxClick,
    });

    const htmlTemplate = () => (userContext?.isLoggedIn ? dcCheckbox.htmlTemplate() : "");

    return { termsAccepted, htmlTemplate };
};

interface PetInCabinOptionClickedEventDetail {
    selected: boolean;
    journeyIndex: number;
    paxIndex: number;
}

export class PetInCabinOptionClickedEvent extends CustomEvent<PetInCabinOptionClickedEventDetail> {
    constructor(detail: PetInCabinOptionClickedEventDetail) {
        super("select", { detail, bubbles: true });
    }
}

import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../../../shared/useModal";

export interface Props {
    onYes: () => void;
    onNo: () => void;
}

export const useCheckinSecondJourneyModal = (props: Props) => {
    const handleYes = () => {
        modal.close();
        props.onYes();
    };

    const handleNo = () => {
        modal.close();
        props.onNo();
    };

    const htmlTemplate = () => html`
        <div class="modal-body text-center">
            <div class="checkin-modal-buttons">
                <a
                    class="rounded-primary-btn"
                    data-test-id=${"handle-checkin-second-leg-modal-button"}
                    @click=${handleYes}
                >
                    ${i18next.t("Yes")}
                </a>
                <a class="checkin-modal-no" data-test-id=${"handle-checkin-second-leg-modal-no"} @click=${handleNo}>
                    ${i18next.t("V2-NoSecondLegCheckin")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("V2-CheckinSecondLeg") },
        overlay: { classNames: "seatmap-second-leg-modal" },
    });

    return modal;
};

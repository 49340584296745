import { CheckinPassengerJourneyModel } from "./../component-models/extras/checkin-type/CheckinPassengerJourneyModel";
import { FlexiFeeJourneyModel } from "../component-models/extras/flexi-fee/FlexiFeeJourneyModel";
import i18next from "i18next";
import { html, TemplateResult } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { OUTBOUND } from "../shared/commonConstants";
import { PetInCabinJourneyModel } from "../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { PriorityBoardingJourneyModel } from "../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { getTestId } from "../testing-helpers/TestIdHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export function journeyRouteTemplate(
    journey:
        | PriorityBoardingJourneyModel
        | PetInCabinJourneyModel
        | FlexiFeeJourneyModel
        | CheckinPassengerJourneyModel,
): TemplateResult {
    return html`
        <span class="emphasis">${routeLabel(journey)} - </span>${journey.DepartureStationName}
        <i class="js-arrow js-icon emphasis"></i> ${journey.DestinationStationName}
    `;
}

export function desktopJourneyRoutesTemplate(
    journeys: (PriorityBoardingJourneyModel | PetInCabinJourneyModel | FlexiFeeJourneyModel)[],
): TemplateResult[] {
    return journeys.map((journey) => {
        const tempClassMap = classMap({
            "col-xs-1": true,
            "col-sm-1-2": true,
            "col-sm-offset-1-4": journeys.length === 1,
            "filled-column": journeys.length !== 1,
        });

        return html`
            <div class=${tempClassMap}>
                <div class="inner-box upper-half">
                    <h3 class="package-title">${journeyRouteTemplate(journey)}</h3>
                </div>
            </div>
        `;
    });
}

export function journeyBundleInfoTemplate(
    bundleColor: string,
    bundleImg: string,
    testName: string,
    passengerNumber: number,
    journeyIndex: number,
    isMobile: boolean,
    isGds: boolean,
): TemplateResult {
    const tempClassMap = classMap({
        "extra-in-bundle-info": true,
        "visible-xs": isMobile,
    });

    return html`
        <div
            class=${tempClassMap}
            style="background-color: ${bundleColor || (isGds && bundleImg) ? "#1c355e" : ""};"
            data-test-id=${getTestId(testName, {
                p: passengerNumber,
                j: journeyIndex,
                m: isMobile,
            })}
        >
            ${unsafeHTML(
                i18next.t("BE2-ExtraIncludedInBundle {{-image}}", {
                    image: `<img src=${bundleImg} />`,
                }),
            )}
        </div>
    `;
}

function routeLabel(
    journey:
        | PriorityBoardingJourneyModel
        | PetInCabinJourneyModel
        | FlexiFeeJourneyModel
        | CheckinPassengerJourneyModel,
): string {
    return journey.JourneyIndex === OUTBOUND ? i18next.t("V2-Outbound") : i18next.t("V2-Inbound");
}

import { ScrollHelper } from "./../../shared/ScrollHelper";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ProfileGiftcardStatus } from "../../component-models/ProfileGiftcard";
import { LINKS } from "../../shared/commonConstants";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import { classMap } from "lit-html/directives/class-map";
import { useAppContext } from "../../managers/useAppContext";
import { ROUTES } from "../../shared/apiRoutes";
import { ProfileGiftcardWidgetViewModel } from "../../component-models/profile/ProfileGiftcardWidgetViewModel";
dayjs.extend(CustomParseFormat);

export interface Props {
    model: ProfileGiftcardWidgetViewModel;
}

export const useProfileGiftcardWidget = (props: Props) => {
    const name = "profile-giftcard-widget";

    // HELPERS

    // EVENT HANDLERS

    const toggleTooltip = (tooltipId: string) => {
        if (!tooltipId) {
            setOpenTooltipId(undefined);
        } else {
            setOpenTooltipId(tooltipId);
            // DEVNOTE I'm really sorry about this, but overflow css bug leaves no other option
            const scroller = DomCrawlingHelper.getElemByClass(document.body, scrollerClassName);
            const tooltipOpener = DomCrawlingHelper.getElemById(document.body, tooltipId);
            if (tooltipOpener) {
                setTooltipTop(tooltipOpener.offsetTop - scroller.scrollTop);
                setTooltipLeft(tooltipOpener.offsetLeft);
            }
        }
    };

    // COMPONENT

    const appContext = useAppContext();

    const [openTooltipId, setOpenTooltipId] = useState<string>(undefined);
    const [tooltipTop, setTooltipTop] = useState<number>(0);
    const [tooltipLeft, setTooltipLeft] = useState<number>(0);
    const [openedTab, setOpenedTab] = useState<ProfileGiftcardStatus>(ProfileGiftcardStatus.Active);

    const scrollerClassName = "gcwc-scroller";
    const tooltipPrefix = "gcwc-tt-";

    const filteredCards = openedTab === ProfileGiftcardStatus.Active ? props.model.Active : props.model.Expired;

    useEffect(() => {
        const scroller = DomCrawlingHelper.getElemByClass(document.body, scrollerClassName);
        if (window.acPerfectScrollbars[name] && scroller) {
            scroller.scrollTop = 0;
            window.acPerfectScrollbars[name].forEach((scroller) => scroller.update());
        }
    }, [openedTab]);

    useEffect(() => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[name] = ScrollHelper.addPerfectScrollbar(scrollerClassName);
        const scroller = DomCrawlingHelper.getElemByClass(document.body, scrollerClassName);
        scroller?.addEventListener("ps-scroll-y", () => toggleTooltip(undefined));
    }, []);

    // TEMPLATES

    const mainClassMap = classMap({
        "profile-widget": true,
        "profile-giftcard-widget": true,
        "portuguese": appContext.Culture === "pt-BR",
    });

    const emptyGiftcardContainerTemplate = () => {
        const tmpClassMap = classMap({
            "gcwc-body": true,
            "portuguese": appContext.Culture === "pt-BR",
        });

        return props.model.Active.length === 0 && props.model.Expired.length === 0
            ? html`
                  <div class="giftcard-widget-container">
                      <div class="giftcard-widget-content">
                          <div class="gcwc-header">
                              <div class="gcwc-logo">
                                  <i class="hidden-sm-down js-icon js-gift-card"></i>
                                  Gift<span>Card</span>
                              </div>
                              <div class="gcwc-number">
                                  <span>${i18next.t("MP-NoGiftCard-1")}:</span>
                                  ${i18next.t("MP-NoGiftCard-2")}
                              </div>
                          </div>
                          <div class=${tmpClassMap}>
                              <div class="gcwc-title">${i18next.t("MP-NoGiftCardTitle")}</div>
                              <div class="gcwc-item">
                                  <span class="font-black uppercase">${i18next.t("MP-Multiuse")}</span>:
                                  ${i18next.t("MP-MultiuseInfo")}
                              </div>
                              <div class="gcwc-item">
                                  <span class="font-black uppercase">${i18next.t("MP-Flights")}</span>:
                                  ${i18next.t("MP-FlightsInfo")}
                              </div>
                              <a href=${ROUTES.GiftcardSelect} target="_blank"
                                  >${i18next.t("MP-BuyGiftCard")} <i class="js-icon js-circle-chevron-right"></i
                              ></a>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const nonEmptyGiftcardContainerTemplate = () =>
        props.model.Active.length > 0 || props.model.Expired.length > 0
            ? html`
                  <div class="giftcard-widget-container">
                      <div class="giftcard-widget-content">
                          <div class="gcwc-header">
                              <div class="gcwc-logo">
                                  <i class="hidden-sm-down js-icon js-gift-card"></i>
                                  Gift<span>Card</span>
                              </div>
                              <div class="gcwc-number">
                                  <span>${i18next.t("Tienes")}:</span>
                                  ${i18next.t("{{amount}} Gift Card activas", { amount: props.model.Active.length })}
                              </div>
                          </div>
                          <div class="gcwc-body">
                              <div class="gcwc-tabs">
                                  <span
                                      class="${openedTab === ProfileGiftcardStatus.Active ? "selected" : ""}"
                                      @click=${() => setOpenedTab(ProfileGiftcardStatus.Active)}
                                      >${i18next.t("Gift Card Activas")}</span
                                  >
                                  <span
                                      class="${openedTab === ProfileGiftcardStatus.Expired ? "selected" : ""}"
                                      @click=${() => setOpenedTab(ProfileGiftcardStatus.Expired)}
                                      >${i18next.t("Gift Card Expiradas")}</span
                                  >
                              </div>
                              ${openedTab === ProfileGiftcardStatus.Active
                                  ? html`
                                        <div class="gcwc-table-header">
                                            <span>${i18next.t("Monto")}</span>
                                            <span>${i18next.t("Fecha expiración")}</span>
                                            <span>&nbsp;</span>
                                        </div>
                                    `
                                  : html`
                                        <div class="gcwc-table-header">
                                            <span>${i18next.t("Monto de la gift card")}</span>
                                            <span>${i18next.t("Fecha expiración")}</span>
                                            <span>${i18next.t("Status")}</span>
                                        </div>
                                    `}
                              <ul class="${scrollerClassName}">
                                  ${filteredCards.map(
                                      (card, index) => html`
                                          ${openedTab === ProfileGiftcardStatus.Active
                                              ? html`
                                                    <li>
                                                        <span
                                                            >${card.FormattedAvailable}
                                                            <i
                                                                class="js-icon js-flight-help"
                                                                id="${`${tooltipPrefix}${index}`}"
                                                                @mouseenter=${() =>
                                                                    toggleTooltip(`${tooltipPrefix}${index}`)}
                                                                @mouseleave=${() => toggleTooltip(undefined)}
                                                            ></i
                                                        ></span>
                                                        <span
                                                            >${dayjs(card.Expiry, "YYYY-MM-DD").format(
                                                                "DD-MM-YYYY",
                                                            )}</span
                                                        >
                                                        <span
                                                            ><button
                                                                @click=${() => (window.location.href = LINKS.Home)}
                                                            >
                                                                ${i18next.t("Canjear")}
                                                                <i class="js-icon js-circle-chevron-right"></i></button
                                                        ></span>
                                                    </li>
                                                `
                                              : html`
                                                    <li>
                                                        <span>${card.FormattedAmount} </span>
                                                        <span
                                                            >${dayjs(card.Expiry, "YYYY-MM-DD").format(
                                                                "DD-MM-YYYY",
                                                            )}</span
                                                        >
                                                        <span>${card.Status}</span>
                                                    </li>
                                                `
                                            }
                                      `,
                                  )}
                              </ul>
                          </div>
                      </div>
                  </div>
                  ${filteredCards.map(
                      (card, index) => html`
                          ${openTooltipId === `${tooltipPrefix}${index}`
                              ? html`
                                    <div class="gcwc-tooltip" style="top: ${tooltipTop}px; left: ${tooltipLeft}px;">
                                        <div>${i18next.t("Tu Gift card aplica a")}:</div>
                                        <ul>
                                            <li>${i18next.t("Tarifa aérea")}</li>
                                            <li>${i18next.t("Opcionales")}</li>
                                            ${!card.BonusVoucher
                                                ? html`
                                                      <li>${i18next.t("Tasas de embarque")}</li>
                                                      <li>${i18next.t("Impuestos")}</li>
                                                  `
                                                : ""}
                                        </ul>
                                    </div>
                                `
                              : ""}
                      `,
                  )}
              `
            : "";

    const htmlTemplate = () => html`
        <div class=${mainClassMap}>${emptyGiftcardContainerTemplate()}${nonEmptyGiftcardContainerTemplate()}</div>
    `;

    return { htmlTemplate };
};

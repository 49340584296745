import { html } from "lit-html";
import { useState } from "../../shared/haunted/CustomHooks";
import { useEffect } from "haunted";
import { getLocalizedStringValue } from "../../shared/common";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    selectedInstallmentNumber: number;
}

export const useInstallmentMessage = (props: Props) => {
    const appContext = useAppContext();

    const [paymentMethod] = useReduxState("payment.paymentMethod");

    const [message, setMessage] = useState<string>("");

    const updateMessage = () => {
        setMessage(
            props.selectedInstallmentNumber > 1
                ? getLocalizedStringValue(paymentMethod?.InstallmentMessage, appContext.Culture)
                : "",
        );
    };

    useEffect(updateMessage, [props.selectedInstallmentNumber]);

    const htmlTemplate = () => (message ? html` <span class="xml-installment-message"> ${message} </span> ` : "");

    return { htmlTemplate, removeMessage: () => setMessage("") };
};

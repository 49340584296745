import {
    ApiNewPassengersModel,
    ApiNewPassenger,
    ApiNewInfant,
} from "../component-models/passengers/ApiNewPassengersModel";
import { DEFAULT_DATE_FORMAT, FAKE_DATE_OF_BIRTH, FARE_LOCK_DUMMY_PAX_FIRST_NAME } from "../shared/commonConstants";
import { TravelDocument } from "../component-models/checkin/TravelDocument";
import { PassengerFormVM } from "../component-models/passengers/PassengerFormVM";
import { mapToApiPassengerTravelDocument } from "./TravelDocumentMapper";
import dayjs from "dayjs";
import { passengerMapperHelpers } from "../component-helpers/passengers/passengerMapperHelpers";

const getDummyLastNameForFarelockPax = (paxNumber: number): string => "ZABCDEFGHI"[paxNumber];

const getPassengerDateOfBirth = (passenger: PassengerFormVM): string => {
    switch (passenger.type) {
        case "ADT":
            return passenger.dateOfBirth?.format(DEFAULT_DATE_FORMAT) || FAKE_DATE_OF_BIRTH;
        case "CHD":
            return (
                passenger.dateOfBirth?.format(DEFAULT_DATE_FORMAT) ||
                dayjs().add(-10, "year").format(DEFAULT_DATE_FORMAT)
            );
        case "INF":
            return (
                passenger.dateOfBirth?.format(DEFAULT_DATE_FORMAT) ||
                dayjs().add(-1, "year").format(DEFAULT_DATE_FORMAT)
            );
        default:
            return FAKE_DATE_OF_BIRTH;
    }
};

const getPassengerName = (
    passenger: PassengerFormVM,
    isFarelockRoundOne: boolean,
): { FirstName: string; LastName: string } =>
    isFarelockRoundOne && passenger.uniqueIndex !== 0
        ? {
              FirstName: FARE_LOCK_DUMMY_PAX_FIRST_NAME,
              LastName: `${getDummyLastNameForFarelockPax(passenger.uniqueIndex)}${passenger.type === "INF" ? "INF" : ""}`,
          }
        : { FirstName: passenger.firstName, LastName: passenger.lastName };

const { concatenateString, filterPassports } = passengerMapperHelpers();

const postAddress = (passenger: PassengerFormVM) =>
    passenger.email ||
    passenger.officePhoneNumber ||
    passenger.phoneNumber ||
    (Array.from(Object.keys(passenger)) as (keyof PassengerFormVM)[])
        .filter((key: keyof PassengerFormVM) => key.startsWith("address") && key.toLowerCase().indexOf("prefix") === -1)
        .some((key: keyof PassengerFormVM) => passenger[key]);

const postContact = (passenger: PassengerFormVM) =>
    (Array.from(Object.keys(passenger)) as (keyof PassengerFormVM)[])
        .filter((key: keyof PassengerFormVM) => key.startsWith("contact") && key.toLowerCase().indexOf("prefix") === -1)
        .some((key: keyof PassengerFormVM) => passenger[key]);

// EXPORTS

export const mapToApiNewPassengersModel = (data: {
    culture: string;
    defaultCountry: string;
    departureStationCode: string;
    discountCode: string;
    documents: TravelDocument[];
    isFarelockRoundOne: boolean;
    passengers: PassengerFormVM[];
}): ApiNewPassengersModel => ({
    Passengers: data.passengers
        .filter((passenger) => passenger.type !== "INF")
        .map((passenger): ApiNewPassenger => {
            return {
                Address: postAddress(passenger)
                    ? {
                          AddressLine1: passenger.addressAddressLine1 || "",
                          City: passenger.addressCity || "",
                          CompanyName: "",
                          CountryCode: passenger.addressCountry || "",
                          CultureCode: data.culture,
                          EmailAddress: passenger.email || "",
                          OfficePhone: passenger.officePhoneNumber
                              ? concatenateString([passenger.officePhonePrefix, passenger.officePhoneNumber])
                              : "",
                          Phone: passenger.phoneNumber
                              ? concatenateString([passenger.phonePrefix, passenger.phoneNumber])
                              : "",
                          PostalCode: passenger.addressPostalCode || "",
                          StationCode: "",
                      }
                    : null,
                ContactPersonInfo: postContact(passenger)
                    ? {
                          AddressLine1: passenger.contactAddressAddressLine1 || "",
                          City: passenger.contactAddressCity || "",
                          CompanyName: passenger.contactLastName
                              ? concatenateString([passenger.contactFirstName, passenger.contactLastName])
                              : "",
                          CountryCode: "",
                          CultureCode: data.culture,
                          EmailAddress: passenger.contactEmail || "",
                          OfficePhone: passenger.contactOfficePhoneNumber
                              ? concatenateString([
                                    passenger.contactOfficePhonePrefix,
                                    passenger.contactOfficePhoneNumber,
                                ])
                              : "",
                          Phone: passenger.contactPhoneNumber
                              ? concatenateString([passenger.contactPhonePrefix, passenger.contactPhoneNumber])
                              : "",
                          PostalCode: passenger.contactAddressPostalCode || "",
                          StationCode: passenger.departureStationCode || data.departureStationCode,
                      }
                    : null,
                DateOfBirth: getPassengerDateOfBirth(passenger),
                Gender: passenger.gender || "Male",
                Name: getPassengerName(passenger, data.isFarelockRoundOne),
                Nationality: passenger.country || "",
                PassengerNumber: passenger.passengerNumber,
                PaxDiscountCode: data.discountCode,
                TravelDocuments: filterPassports(passenger, data.documents).map((document) =>
                    mapToApiPassengerTravelDocument({ ...data, document, passenger }),
                ),
                Type: passenger.type,
            };
        }),
    Infants: data.passengers
        .filter((passenger) => passenger.type === "INF")
        .map(
            (passenger): ApiNewInfant => ({
                AttachedPassengerNumber: passenger.attachedPassengerNumber,
                Name: getPassengerName(passenger, data.isFarelockRoundOne),
                DateOfBirth: getPassengerDateOfBirth(passenger),
                Gender: passenger.gender || "Male",
                Nationality: passenger.country || "",
                TravelDocuments: filterPassports(passenger, data.documents).map((document) =>
                    mapToApiPassengerTravelDocument({ ...data, document, passenger }),
                ),
            }),
        ),
});

// add a custom "ref" directive for use with lit-html
import { directive, AttributePart } from "lit-html";

const ref = directive((refInstance) => (part: any) => {
    if (!(part instanceof AttributePart)) {
        throw new Error("ref directive can only be used as an attribute");
    }
    refInstance.current = part.committer.element;
});

export { ref };

import i18next from "i18next";
import { html } from "haunted";
import useImagePreloader from "../../shared/customHooks/useImagePreloader/useImagePreloader";
import { useModal } from "../shared/useModal";

export interface Props {
    url: string;
}

const imgUrl = "/Images/Icons/online-change/js-plane-get-off.png";

export const useFlightMoveDevolutionModal = (props: Props) => {
    useImagePreloader([imgUrl]);

    const headerTemplate = () => html`
        <span class="flight-move-modal-title"> ${i18next.t("No podemos realizar tu cambio automáticamente")} </span>
        <div class="flight-move-modal-plane">
            <img src=${imgUrl} />
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                ${i18next.t(
                    "Lo sentimos, no podemos procesar tu cambio de vuelo automáticamente. Por favor completa el siguiente formulario y dentro de un plazo máximo de 7 días será procesado tu cambio.",
                )}
            </div>
            <div class="primary-modal-button-container">
                <a class="rounded-primary-btn" href=${props.url}> ${i18next.t("Ir al formulario")} </a>
            </div>
            <a class="move-modal-dont-want" @click=${modal.close}> ${i18next.t("No quiero realizar cambios")} </a>
        </div>
    `;

    const modal = useModal({
        closing: { buttonClassNames: "flight-move-modal-close", isClosable: true },
        content: { classNames: "flight-move-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { GiftcardTemplates } from "./GiftcardTemplates";
import { useGiftcardMessageEditor } from "./useGiftcardMessageEditor";
import { useDesignSelector } from "./useDesignSelector";

export interface GiftcardDesignerProps {
    isValidated: boolean;
    templates: GiftcardTemplates[];
}

export const useGiftcardDesigner = (props: GiftcardDesignerProps) => {
    const isValid = () => !isDesignerOpen || designSelector.selectedDesignCode;

    const showDesignerError = () => props.isValidated && !isValid();

    const handleDesignerCheckboxChange = () => {
        setIsDesignerOpen(!isDesignerOpen);
        messageEditor.clearMessage();
    };

    const [isDesignerOpen, setIsDesignerOpen] = useState<boolean>(false);

    const designSelector = useDesignSelector({
        isOpen: isDesignerOpen,
        templates: props.templates,
    });

    const messageEditor = useGiftcardMessageEditor();

    const designerCheckbox = useBasicCheckbox({
        customLabelClass: "cb-title",
        customWrapperClass: ["giftcard", "mt-8"],
        isChecked: isDesignerOpen,
        labelText: i18next.t("Gift-WantAsGiftLabel"),
        onClick: handleDesignerCheckboxChange,
    });

    const designerErrorTemplate = () =>
        showDesignerError()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="giftcard-error pull-up">${i18next.t("Gift-SelectDesignError")}</div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => {
        const tempClassMap = classMap({
            "giftcard-design-wrapper": true,
            "open": isDesignerOpen,
        });

        return html`
            ${designerCheckbox.htmlTemplate()}
            <div class=${tempClassMap}>
                <div class="giftcard-section-header push-down">
                    <i class="js-icon js-payment"></i>
                    <div>
                        <div class="giftcard-section-title">${i18next.t("Gift-PersonalizeGiftcardTitle")}</div>
                        <div class="giftcard-section-subtitle">${i18next.t("Gift-PersonalizeGiftcardSubtitle")}</div>
                    </div>
                </div>
                ${designerErrorTemplate()} ${designSelector.htmlTemplate()} ${messageEditor.htmlTemplate()}
            </div>
        `;
    };

    return {
        isDesignerOpen,
        isValid: messageEditor.isValid && isValid(),
        message: messageEditor.message,
        selectedDesignCode: designSelector.selectedDesignCode || props.templates[0].Code.toString(),
        htmlTemplate,
    };
};

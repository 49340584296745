import { ApiBaggage } from "../component-models/baggage/ApiBaggage";
import {
    BaggageContextJourney,
    BaggageContextJourneyPassenger,
    BaggageContextJourneyPassengerBaggage,
} from "../component-models/baggage/BaggageContext";
import { BaggageStateJourney, BaggageStateJourneyPassenger } from "../component-models/baggage/BaggageState";
import { BagType } from "../components/spa/baggage/useBaggagePage";
import { OUTBOUND } from "../shared/commonConstants";
import { ApiBaggageJourney, BundleUserType } from "./../component-models/baggage/ApiBaggageJourney";

export const mapToBaggageJourney = (journey: ApiBaggageJourney, bagType: BagType): BaggageStateJourney => ({
    index: journey.JourneyIndex,
    isOpen: journey.JourneyIndex === OUTBOUND,
    passengers: [
        ...journey.Passengers.map((p): BaggageStateJourneyPassenger => {
            const baggage = p[bagType];

            return {
                hasSelected:
                    baggage.HasSelected ||
                    Number(baggage.Quantity) > 0 ||
                    Number(baggage.MinCount) > 0 ||
                    baggage.IsSoldOut,
                isOpen:
                    journey.JourneyIndex === OUTBOUND
                        ? p.PaxIndex === getInitialOpenJourneyPax(journey, bagType)
                        : false,
                index: p.PaxIndex,
            };
        }),
    ],
});

export const getContextJourneys = (model: ApiBaggageJourney[], outboundBundleUrl: string, inboundBundleUrl: string) =>
    model.map(
        (journey): BaggageContextJourney => ({
            arrivalStationName: journey.ArrivalStationName,
            availableCabinBaggage: journey.AvailableCabinBaggageQuantity,
            availableCheckedBaggage: journey.AvailableCheckedInBaggageQuantity,
            availableOversizedBaggage: journey.AvailableOversizedBaggageQuantity,
            bundleImageUrl: journey.JourneyIndex === OUTBOUND ? outboundBundleUrl : inboundBundleUrl,
            bundleUserType:
                journey.BundleUserType === BundleUserType.Normal
                    ? "Normal"
                    : journey.BundleUserType === BundleUserType.GDS
                      ? "GDS"
                      : journey.BundleUserType === BundleUserType.BancoEstado
                        ? "BancoEstado"
                        : undefined,
            cabinBundleType: journey.IsCabinBagInBundle ? journey.BundleType : "None",
            checkedBundleType: journey.IsCheckedBagInBundle ? journey.BundleType : "None",
            departureStationName: journey.DepartureStationName,
            index: journey.JourneyIndex,
            passengers: journey.Passengers.map(
                (p): BaggageContextJourneyPassenger => ({
                    index: p.PaxIndex,
                    cabinBaggage: getContextBaggage(p.CabinBaggage),
                    checkedBaggage: getContextBaggage(p.CheckedBaggage),
                    oversizedBaggage: getContextBaggage(p.OversizedBaggage),
                }),
            ),
        }),
    );

const getContextBaggage = (baggage: ApiBaggage): BaggageContextJourneyPassengerBaggage => ({
    isSoldOut: baggage.IsSoldOut,
    max: Number(baggage.MaxCount),
    min: Number(baggage.MinCount),
    originalPrices: baggage.OriginalAmount?.split("|").map((i) => Number(i)) || [],
    prices: baggage.Amount?.split("|").map((i) => Number(i)) || [],
    quantity: baggage.Quantity || 0,
    ssrAddKey: baggage.SsrAddKey,
    ssrRemoveKey: baggage.SsrDeleteKey,
});

const getInitialOpenJourneyPax = (journey: ApiBaggageJourney, bagType: BagType) => {
    const paxWithNoSelection = journey.Passengers.find((p) => {
        switch (bagType) {
            case "CabinBaggage":
                return (
                    (!p.CabinBaggage.HasSelected || p.CabinBaggage.Quantity === 0) &&
                    journey.AvailableCabinBaggageQuantity > 0 &&
                    !p.CabinBaggage.IsSoldOut
                );
            case "CheckedBaggage":
                return (
                    (!p.CheckedBaggage.HasSelected || p.CheckedBaggage.Quantity === 0) &&
                    journey.AvailableCheckedInBaggageQuantity > 0 &&
                    !p.CheckedBaggage.IsSoldOut
                );
            case "OversizedBaggage":
                return (
                    (!p.OversizedBaggage.HasSelected || p.OversizedBaggage.Quantity === 0) &&
                    journey.AvailableOversizedBaggageQuantity > 0 &&
                    !p.OversizedBaggage.IsSoldOut
                );
        }
    });

    return paxWithNoSelection ? paxWithNoSelection.PaxIndex : 0;
};

import { html } from "lit-html";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import dayjs from "dayjs";
import { SelectOption } from "../../shared/common";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";

export type OrderingOption = "cheap" | "early" | "late";

export const useFlightOptionOrdering = () => {
    const getTime = (model: FlightOptionModel) => dayjs(model.FlightSelectModel.Std24H, "HH:mm");

    const sortOptions = new Map<OrderingOption, (a: FlightOptionModel, b: FlightOptionModel) => -1 | 0 | 1>([
        [
            "cheap",
            (a, b) =>
                a.UnformattedSmartPrice !== b.UnformattedSmartPrice
                    ? a.UnformattedSmartPrice > b.UnformattedSmartPrice
                        ? 1
                        : -1
                    : getTime(a).isBefore(getTime(b))
                      ? -1
                      : 1,
        ],
        ["early", (a, b) => (getTime(a).isBefore(getTime(b)) ? -1 : 1)],
        ["late", (a, b) => (getTime(a).isAfter(getTime(b)) ? -1 : 1)],
    ]);

    const options: SelectOption[] = [
        { Value: "cheap", Text: i18next.t("Más barato") },
        { Value: "early", Text: i18next.t("Salida más temprano") },
        { Value: "late", Text: i18next.t("Salida más tarde") },
    ];

    const handleToggle = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const [currentSortOption, setCurrentSortOption] = useState<OrderingOption>("cheap");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handler = () => setIsOpen(false);
        window.addEventListener("click", handler);

        return () => window.removeEventListener("click", handler);
    }, []);

    const arrowTemplate = () => {
        const tempClassMap = classMap({
            "flight-option-order-arrow": true,
            "flight-option-order-arrow-up": isOpen,
        });

        return html`<span class=${tempClassMap}>▼</span>`;
    };

    const optionTemplate = (option: SelectOption) => html`
        <div class="flight-option-order-option" @click=${() => setCurrentSortOption(option.Value as OrderingOption)}>
            ${option.Text}
        </div>
    `;

    const dropdownTemplate = () => {
        const tempClassMap = classMap({
            "flight-option-order-dropdown": true,
            "show": isOpen,
        });

        return html` <div class=${tempClassMap}>${options.map(optionTemplate)}</div> `;
    };

    const htmlTemplate = () =>
        html`<div class="flight-option-order-container">
            <div class="flight-option-order-opener" @click=${handleToggle}>
                ${i18next.t("Ordenar por")}:
                <span class="flight-option-order-selected-text">
                    ${options.find((o) => o.Value === currentSortOption).Text}
                </span>
                ${arrowTemplate()} ${dropdownTemplate()}
            </div>
        </div>`;

    return { htmlTemplate, sorter: sortOptions.get(currentSortOption) };
};

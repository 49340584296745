import { ApiInsurancePassenger } from "../../../component-models/extras/insurance/ApiInsurancePassenger";
import { TestIdDictionary as T, TestIdDictionary } from "../../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { getCoords } from "../../../shared/common";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { useInsurance } from "./insurance/useInsurance";
import { ROUTES } from "../../../shared/apiRoutes";
import { CLASS_NAMES } from "../../../shared/classNames";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import { ref } from "../../../directives/ref";
import { useFlexiFee } from "./flexi-fee/useFlexiFee";
import { mapToFlexiFeeModel } from "../../../component-mappers/ExtrasFlexiFeeMapper";
import { mapToCheckinTypeModel } from "../../../component-mappers/ExtrasCheckinTypeMappers";
import { useCheckinType } from "./checkin-type/useCheckinType";
import { usePriorityBoarding } from "./priority-boarding/usePriorityBoarding";
import { mapToPriorityBoardingModel } from "../../../component-mappers/ExtrasPriorityBoardingMappers";
import { usePetInCabin } from "./pet-in-cabin/use-pet-in-cabin";
import { mapToPetInCabinModel } from "../../../component-mappers/ExtrasPetInCabinMappers";
import { SearchInsuranceResult } from "../../../component-models/spa/SearchInsuranceResult";
import { YesNo, setTestCircuitDomValue } from "../../../testing-helpers/TestCircuitHelper";
import { NON_GROUP_BOOKING_MAX_PAX, URL_VARS } from "../../../shared/commonConstants";
import { useExtrasTealiumManager } from "../../../managers/Tealium/useExtrasTealiumManager";
import { useAppContext } from "../../../managers/useAppContext";
import { useRunOnce } from "../../useRunOnce";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useInsuranceModal } from "./insurance/useInsuranceModal";
import { useFlowContext } from "../../../managers/useFlowContext";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import { ApiExtrasSpaSectionViewModel } from "../../../component-models/spa/ExtrasSpaSectionViewModel";

export interface ExtrasPage extends SpaContent {
    extrasModel: ApiExtrasSpaSectionViewModel;
    passengerIndicesForDocumentsForInsurance: () => number[];
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export interface Props {
    onForward: () => void;
    spaModalStart: (submitCallback: () => Promise<void> | void) => void;
}

export const useExtrasPage = (props: Props): ExtrasPage => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const tealiumManager = useExtrasTealiumManager();

    const { triggers } = usePubSub();
    const { ajaxJsonRequest, searchInsurance } = useAjax();

    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [_, setHasUnpaidInsurance] = useReduxState("booking.hasUnpaidInsurance");

    const root = useRef<HTMLDivElement>(undefined);

    const [model, setModel] = useState<ApiExtrasSpaSectionViewModel>(undefined);
    const [insuranceCost, setInsuranceCost] = useState<SearchInsuranceResult>(undefined);
    const [insurancePassengers, setInsurancePassengers] = useState<ApiInsurancePassenger[]>(undefined);
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const runOnce = useRunOnce();

    const showDocs = useMemo(
        () =>
            model?.ExtrasModel.InsuranceSettings.CultureBasedSettings.find(
                (setting) => setting.Culture.toLowerCase() === appContext.Culture.toLowerCase(),
            )?.IdentificationNeeded,
        [appContext.Culture, model],
    );

    const insuranceModal = useInsuranceModal({
        insurance: insuranceCost,
        onContinue: () => {
            setTestCircuitDomValue("InsuranceModalToBeOpened", undefined);
            insurance.handleModalAddInsurance();
        },
        onCancel: () => {
            setTestCircuitDomValue("InsuranceModalToBeOpened", undefined);
            submitAndMoveOnToNextPage(true);
        },
    });

    useEffect(() => {
        if (model) setHasUnpaidInsurance(model.ExtrasModel.HasUnpaidInsuranceFee);
    }, [model]);

    const flexiFee = useFlexiFee({
        model: model ? mapToFlexiFeeModel(model) : undefined,
        setExtrasModel: setModel,
    });

    const checkinType = useCheckinType({
        isValidated,
        model: model ? mapToCheckinTypeModel(model) : undefined,
        setExtrasModel: setModel,
    });

    const priorityBoarding = usePriorityBoarding({
        model: model ? mapToPriorityBoardingModel(model) : undefined,
        bancoEstadoSavedAmountPriorityBoarding: model?.BancoEstadoSavedAmountPriorityBoarding,
        setExtrasModel: setModel,
    });

    const petInCabin = usePetInCabin({
        model: model ? mapToPetInCabinModel(model?.ExtrasModel) : undefined,
        termsUrl: model?.PetTermsUrl,
        setExtrasModel: setModel,
    });

    const insurance = useInsurance({
        insurance: insuranceCost,
        insurancePassengers,
        isPassengerInsuranceSelected: model?.ExtrasModel.IsPassengerInsuranceSelected,
        model,
        setExtrasModel: setModel,
    });

    const isInsuranceAvailable = () =>
        bookingContext.isInsuranceAvailable && insuranceCost?.Cost && insuranceCost?.CostCurrency;

    const isBaggageInsuranceAvailable = () =>
        bookingContext.isBaggageInsuranceAvailable && insuranceCost?.BaggageCost && insuranceCost?.BaggageCostCurrency;

    const isSubmitBtnEnabled = () => !isSubmitBtnDisabled && currentSpaSection === "Extras";

    const isPostBookingBannerInsurance = () =>
        window.location.search.toLowerCase().includes(URL_VARS.POST_BOOKING_INSURANCE.toLowerCase());

    const isGroupBooking = () =>
        bookingContext.adultsCount + bookingContext.childrenCount + bookingContext.infantsCount >
        NON_GROUP_BOOKING_MAX_PAX;

    const shouldLoadInsurance = () =>
        bookingContext.isInsuranceAvailable &&
        !isGroupBooking() &&
        !bookingContext.isCheckinClosedOutbound &&
        (!bookingContext.isCheckinClosedInbound || bookingContext.isOneWay);

    const handleSubmitWithInsuranceOff = (isValid: boolean) => {
        if (!isValid) {
            handleFormError();
            return;
        }

        submitAndMoveOnToNextPage(true);
    };

    const handleSubmitWithInsurance = (isValid: boolean) => {
        if (!insurance.validateInsurance() || !isValid) {
            handleFormError();
            return;
        }

        submitWithInsurance();
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isSubmitBtnEnabled()) {
            return;
        }

        setIsValidated(true);

        const isValid = checkinType.validateCheckin();

        if (!insuranceCost) {
            handleSubmitWithInsuranceOff(isValid);
            return;
        }

        if (isInsuranceAvailable()) {
            handleSubmitWithInsurance(isValid);
        } else {
            handleSubmitWithInsuranceOff(isValid);
        }
    };

    const submitWithInsurance = async () => {
        if (!insurance.isBaggageOnly() && isCovidModalNeeded()) {
            setTestCircuitDomValue("InsuranceModalToBeOpened", "YES");
            insuranceModal.open();
            return;
        }

        const isInsuranceFlowFinished = !showDocs || insurance.validateInsurance();
        submitAndMoveOnToNextPage(isInsuranceFlowFinished);
    };

    const handleForward = () => {
        raiseBookingFlowContinueEvent(true);
        props.onForward();
    };

    const submitAndMoveOnToNextPage = async (isInsuranceFlowFinished: boolean) => {
        setTestCircuitDomValue("InsuranceModalToBeOpened", "NO");
        if (!isInsuranceFlowFinished) {
            raiseBookingFlowContinueEvent(false);
            return;
        }

        setIsSubmitBtnDisabled(true);
        tealiumManager.logContinueClicked();

        if (typeof props.spaModalStart === "function") {
            props.spaModalStart(handleForward);
        } else {
            handleForward();
        }
    };

    const isBaggageInsuranceEnabled = (pax: ApiInsurancePassenger[]) =>
        pax.some((p) => p.IsCheckedBaggageEnabled || p.IsOversizedBaggageEnabled);

    const boughtNewBaggageInsurance = () =>
        isBaggageInsuranceAvailable() &&
        isBaggageInsuranceEnabled(insurancePassengers) &&
        insurance?.insuranceSelection.has("baggage");

    const boughtNewPaxInsurance = () => insurance?.insuranceSelection.has("pax");

    const isCovidModalNeeded = () =>
        flowContext.isBookingFlow &&
        (!isBaggageInsuranceAvailable() || !boughtNewBaggageInsurance()) &&
        !boughtNewPaxInsurance();

    const handleFormError = () => {
        window.setTimeout(() => {
            scrollToFirstError();
            setIsSubmitBtnDisabled(false);
            raiseBookingFlowContinueEvent(false);
        }, 100);
    };

    const scrollToFirstError = () => {
        const errors = Array.from(root.current.querySelectorAll("." + CLASS_NAMES.error)) as HTMLElement[];
        const firstError = errors.find((err) => err.offsetHeight > 0);

        if (firstError) {
            const parent = DomCrawlingHelper.findParentByClass(firstError, CLASS_NAMES.errorParent);
            const topOfElement = getCoords(parent).top - 280;
            window.scroll({ top: topOfElement, behavior: "smooth" });
        }
    };

    const scrollToInsuranceSection = () => {
        const insuranceSection = root.current.querySelector("." + CLASS_NAMES.extrasInsuranceHeader) as HTMLElement;

        if (insuranceSection) {
            window.scroll({ top: getCoords(insuranceSection).top - 280, behavior: "smooth" });
        }
    };

    const logDomLoad = () => tealiumManager.logExtrasPageLoad(model?.FlatBookingData);

    useEffect(() => {
        if (currentSpaSection === "Extras" && model) {
            runOnce.run(() => {
                logDomLoad();
                setIsSubmitBtnDisabled(false);
            });
        }
        if (currentSpaSection !== "Extras") runOnce.reset();
    }, [currentSpaSection, model]);

    const insuranceTemplate = () =>
        isInsuranceAvailable()
            ? html`
                  <div
                      class="for-loader"
                      data-test-id=${TestIdDictionary.EXTRAS.INSURANCE_VERSION}
                      data-test-value="V2"
                  >
                      ${insurance.htmlTemplate()}
                  </div>
              `
            : "";

    const buttonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "booking": true,
            "disabled": !isSubmitBtnEnabled(),
        });

        return html`
            <button class=${tempClassMap} data-test-id=${T.EXTRAS.SUBMIT_BUTTON} @click=${handleSubmit}>
                ${i18next.t("V2-Continue")}
            </button>
        `;
    };

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const newModel = await ajaxJsonRequest<ApiExtrasSpaSectionViewModel>({
                url: ROUTES.AjaxExtrasModel,
                method: "GET",
                onResponseCode: {},
                nonCancellable: true,
            });

            setModel(newModel.data);

            setTestCircuitDomValue<YesNo>("InsuranceCostAvailable", undefined);

            if (shouldLoadInsurance()) {
                const response = await ajaxJsonRequest<ApiInsurancePassenger[]>({
                    url: ROUTES.GetInsurancePassengers,
                    method: "GET",
                });

                setInsurancePassengers(response.data);
            }

            const cost = shouldLoadInsurance() ? await searchInsurance() : undefined;

            setTestCircuitDomValue<YesNo>("InsuranceCostAvailable", cost !== undefined ? "YES" : "NO");
            setInsuranceCost(cost);

            if (isPostBookingBannerInsurance()) {
                window.setTimeout(scrollToInsuranceSection, 500);
                insurance.addInsurance();
            }

            BookingFlowHandler.storeCurrentStep(BookingSteps.Extras);

            triggers.sidebar.bookingChanged.publish({});
            triggers.extras.pageLoaded.publish({});

            return { type: "success" };
        } catch (e) {
            return { type: "error" };
        }
    };

    const htmlTemplate = () => html`
        <form ref=${ref(root)} novalidate>
            <div class="extras-page" data-test-id=${T.EXTRAS.PAGE}>
                ${model?.ExtrasModel.ShowFlexiFee ? flexiFee.htmlTemplate() : ""} ${checkinType.htmlTemplate()}
                ${priorityBoarding.htmlTemplate()}
                ${appContext.isFeatureSwitchActive("PetsInCabin") ? petInCabin.htmlTemplate() : ""}
                ${insuranceTemplate()}
            </div>
            <div class="flex justify-end">${buttonTemplate()}</div>
        </form>
        ${insuranceModal.htmlTemplate()}
    `;
    return {
        build,
        htmlTemplate,
        extrasModel: model,
        passengerIndicesForDocumentsForInsurance: insurance.passengerIndicesForDocumentsForInsurance,
        setExtrasModel: setModel,
    };
};

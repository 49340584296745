import i18next from "i18next";
import { html } from "lit-html";
import { ROUTES } from "../../../shared/apiRoutes";
import classNames from "classnames";
import { ApiBoardingPassViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";
import { getAbbreviatedPaxName, getParsedProperty, groupBy } from "../../../shared/common";
import { ApiBoardingPassesViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassesViewModel";
import { useAppContext } from "../../../managers/useAppContext";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { useBoardingPassImageRepository } from "../boarding-passes/useBoardingPassImageRepository";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";

export const useShadowDOM = false;
export const name = "ac-boarding-pass-print-page";

export interface Attributes {
    model: string;
}

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Props {
    model: ApiBoardingPassesViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: typeof host.model === "string" ? getParsedProperty<ApiBoardingPassesViewModel>(host.model) : undefined,
    };

    const appContext = useAppContext();
    const [openAccordions, setOpenAccordions] = useState<string[]>([]);

    const { downloadModal, images, handleDownloadAllIntent, handleImageRequestChange } = useBoardingPassImageRepository(
        {
            areCardsDisplayed: true,
            model: props.model,
            showModal: false,
        },
    );

    const groupedBoardingPasses = useMemo(
        () => groupBy(props.model.BoardingPasses, (pass) => pass.Passenger.PaxName),
        [props.model.BoardingPasses],
    );

    const getPluralByCulture = (passes: ApiBoardingPassViewModel[]) =>
        passes.length > 1 ? (appContext.Culture === "en-us" || appContext.Culture === "pt-br" ? "es" : "s") : "";

    const handleAccordionToggle = (e: MouseEvent, key: string) => {
        e.preventDefault();
        e.stopPropagation();

        if (openAccordions.includes(key)) {
            setOpenAccordions(openAccordions.filter((item) => item !== key));
        } else {
            setOpenAccordions([...openAccordions, key]);
        }
    };

    useEffect(async () => {
        if (!openAccordions.length) return;

        const newIds = openAccordions.filter((paxName) => !images.some((image) => image.paxName === paxName));

        if (newIds.length === 0) return;

        await handleImageRequestChange(newIds);
    }, [openAccordions]);

    const downloadLinkTemplate = () =>
        props.model.BoardingPasses.length > 1
            ? html`
                  <div class="mt-4 flex w-full justify-end">
                      <button class="rounded-primary-btn red-white" @click=${handleDownloadAllIntent}>
                          ${i18next.t("Descargar todas las tarjetas de embarque")}
                      </button>
                  </div>
              `
            : "";

    const boardingPassTemplate = (model: ApiBoardingPassViewModel) => {
        const desktopImage = images.find(
            (image) =>
                image.paxName === model.Passenger.PaxName && image.index === model.Index && image.size === "desktop",
        );

        const mobileImage = images.find(
            (image) =>
                image.paxName === model.Passenger.PaxName && image.index === model.Index && image.size === "mobile",
        );

        return html`
            <ac-boarding-pass
                .item=${desktopImage}
                .mobileItem=${mobileImage}
                .model=${model}
                .pnr=${props.model.Pnr}
            ></ac-boarding-pass>
        `;
    };

    const boardingPassesTemplate = (passes: ApiBoardingPassViewModel[]) => passes.map(boardingPassTemplate);

    const passengerPassesTemplate = (key: string) => {
        const passes = groupedBoardingPasses[key];
        const hasImage = images.some((image) => image.paxName === passes[0].Passenger.PaxName);

        const tempClassNames = classNames("boarding-pass-accordion cursor-pointer", {
            open: openAccordions.includes(key) && hasImage,
        });

        return html`
            <div class=${tempClassNames} @click=${(e: MouseEvent) => handleAccordionToggle(e, key)}>
                <label>
                    <span>
                        <span class="hidden-sm-up">${getAbbreviatedPaxName(passes[0].Passenger.PaxName)}</span>
                        <span class="hidden-xs">${passes[0].Passenger.PaxName}</span>
                    </span>
                    <span class="whitespace-nowrap">
                        ${i18next.t("Ver tarjeta{{-plural}} de embarque", {
                            plural: getPluralByCulture(passes),
                        })}
                    </span>
                    <i class="js-icon js-chevron-right"></i>
                </label>
                <div class="boarding-pass-accordion-content">${boardingPassesTemplate(passes)}</div>
            </div>
        `;
    };

    const passengersTemplate = () => Object.keys(groupedBoardingPasses).map(passengerPassesTemplate);

    const interColombiaWarningTemplate = () =>
        props.model.IsInternationalFlightColombia
            ? html` <div class="inter-colombia-warning">${i18next.t("Boarding-Pass-Inter-Colombia-Warning")}</div> `
            : "";

    return html`
        <section class="booking-wrapper push-down ts-error-container">
            <header class="mb-4">
                <span class="js-boarding-pass js-icon title-icon"></span>
                <div class="title">
                    <h2 class="main-title">${i18next.t("Print-Header")}</h2>
                    <div class="subtitle">${i18next.t("Print-Success")}:</div>
                </div>
            </header>
            ${interColombiaWarningTemplate()} ${passengersTemplate()} ${downloadLinkTemplate()}
        </section>

        <div class="checkin-btn-container">
            <a href=${ROUTES.PageItinerary} class="rounded-primary-btn" data-test-id="print-submit-button">
                ${i18next.t("V2-Continue")}
            </a>
        </div>
        ${downloadModal.htmlTemplate()}
    `;
};

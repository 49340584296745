import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";

export interface Props {
    dataTestId: string;
    isDisabled: boolean;
    onClick: () => void;
}

export const useBaggageEditButton = (props: Props) => {
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick();
    };

    const htmlTemplate = () => html`
        <div
            class=${classNames(
                "max-w-[168px] cursor-pointer rounded-[10px] border border-solid border-be-blue bg-white bg-[url(https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/478a9d7c-1135-4605-b8d1-bdb512dbedcb/pencil.png)] bg-[size:14px_14px] bg-[81%_51%] bg-no-repeat px-[40px] py-[3px] font-body text-[15px] font-black text-be-blue",
                "sm:bg-[size:18px_18px] sm:bg-[77%_51%] sm:py-[6px] sm:pl-[60px] sm:pr-[95px] sm:text-[17px]",
                "sm:hover:border-be-red sm:hover:bg-be-red sm:hover:bg-[url('https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/d8a6fed6-2ac5-44a0-b99a-a2d198cabd02/pencil%20%281%29.png')] sm:hover:text-white sm:hover:underline",
                "md:bg-[size:14px_14px] md:pl-[30px] md:pr-[47px] md:text-[12px]",
                "lg:bg-[size:18px_18px] lg:text-[17px]",
                "xl:pl-[60px] xl:pr-[95px]",
                {
                    disabled: props.isDisabled,
                },
            )}
            data-test-id=${props.dataTestId}
            @click=${handleClick}
        >
            ${i18next.t("Editar")}
        </div>
    `;

    return { htmlTemplate };
};

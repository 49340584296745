import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { FlightHelper } from "../../component-helpers/flight/FlightHelper";
import { hideLoader, showLoader } from "../../shared/common";
import { ref } from "../../directives/ref";
import { useAppContext } from "../../managers/useAppContext";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { SelectedFlightFeeType } from "./flight-page";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

const loaderAnimationClass = "flight-fare-animation";

export interface Props {
    context: FlightPageContext;
    disabled: boolean;
    flightOption: FlightOptionModel;
    isInFlightMove: boolean;
    onReset: () => void;
    onSelect: (feeType: SelectedFlightFeeType) => Promise<void>;
}

export const useFlightFare = (props: Props) => {
    const appContext = useAppContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const loaderContainer = useRef<HTMLDivElement>(undefined);

    const [loader, setLoader] = useState<JsLoader>(undefined);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showClubPrice = useMemo(
        () =>
            (userContext?.dc.canBuyMembership || userContext?.dc.hasMembership) &&
            props.flightOption.HasClubFare &&
            !props.isInFlightMove,
        [userContext, props.flightOption],
    );

    const smartPrice = useMemo(
        () =>
            FlightHelper.smartPrice({
                context: props.context,
                culture: appContext.Culture,
                currency,
                flightOption: props.flightOption,
                formatNumber,
            }),
        [props.context, appContext.Culture, currency, props.flightOption],
    );

    const clubPrice = useMemo(
        () =>
            FlightHelper.clubPrice({
                context: props.context,
                culture: appContext.Culture,
                currency,
                flightOption: props.flightOption,
                userData: userContext,
                formatNumber,
            }),
        [props.context, appContext.Culture, currency, props.flightOption],
    );

    const isLoaded = useMemo(
        () => props.context?.bundleState?.bundleOffers?.length > 0,
        [props.context?.bundleState?.bundleOffers],
    );

    // HELPERS

    const handleLoader = () => {
        if (!isLoaded) {
            const container = loaderContainer.current;
            setLoader(showLoader({ container, noPlane: true, name: loaderAnimationClass }));
        } else {
            hideLoader(loader);
        }
    };

    // EVENT LISTENERS

    const handleSmartSelect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onSelect("Smart");
    };

    const handleDcSelect = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onSelect("Club");
    };

    const handleModalOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsModalOpen(true);
    };

    // EFFECTS

    useEffect(handleLoader, [isLoaded]);

    // TEMPLATES

    const loaderTemplate = () => html`
        <div
            class=${classNames(
                "absolute inset-0 flex h-full w-full items-center justify-center",
                "h-[calc(100%+10px)] sm:static sm:mt-[-10px]",
            )}
            ref=${ref(loaderContainer)}
        >
            <div class="${loaderAnimationClass} h-full w-full"></div>
        </div>
    `;

    const clubPriceIconTemplate = () => html`
        <div
            class=${classNames(
                "mr-1 inline-flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-2 border-solid border-white text-xs leading-none",
                "sm:mr-2",
            )}
            @click=${handleModalOpen}
        >
            i
        </div>
    `;

    const priceLabelTemplate = (type: SelectedFlightFeeType) => {
        const tempClassNames = classNames(
            "absolute inset-x-0 top-0 flex h-[26px] items-center justify-center border-b border-solid border-white text-center text-[13px] text-white",
            "sm:text-sm",
            {
                "bg-[#ffa400]": type === "Club" && !props.disabled,
                "bg-new-ui-blue": type === "Smart" && !props.disabled,
                "bg-be-gray-10": props.disabled,
            },
        );

        return html`
            <div class=${tempClassNames}>
                ${type === "Club" ? clubPriceIconTemplate() : ""}
                ${props.isInFlightMove
                    ? i18next.t("No existe diferencia tarifaria")
                    : type === "Club"
                      ? i18next.t("Club de Descuentos")
                      : i18next.t("Tarifa SMART")}
            </div>
        `;
    };

    const priceAmountTemplate = (type: SelectedFlightFeeType) => {
        const tempClassNames = classNames("text-[22px] font-bold  group-hover:text-white", "sm:text-2xl", {
            "text-[#ffa400]": type === "Club" && !props.disabled,
            "text-new-ui-blue": type === "Smart" && !props.disabled,
            "text-be-gray-10": props.disabled,
        });

        return html`
            <div class=${tempClassNames}>${props.isInFlightMove ? "$0" : type === "Club" ? clubPrice : smartPrice}</div>
        `;
    };

    const dcPriceTemplate = () => {
        const tempClassNames = classNames(
            "group relative flex h-full w-1/2 cursor-pointer items-center justify-center overflow-hidden border border-solid border-[#e0e0e0] pt-[26px] text-[22px] font-bold",
            "hover:bg-[#ffa400]",
            {
                "rounded-l-md": props.context.flightState.selectedFeeType === "Club",
                "rounded-r-md": props.context.flightState.selectedFeeType === "Smart",
            },
        );

        return showClubPrice
            ? html`
                  <div
                      class=${tempClassNames}
                      data-test-id=${getTestId(T.FLIGHT.CLUB_FEE, {
                          j: props.flightOption.JourneyIndex,
                          i: props.flightOption.Index,
                      })}
                      data-departure=${props.flightOption.FlightSelectModel.Std24H}
                      data-arrival=${props.flightOption.FlightSelectModel.Sta24H}
                      data-value=${props.flightOption.UnformattedClubPrice}
                      @click=${handleDcSelect}
                  >
                      ${priceLabelTemplate("Club")} ${priceAmountTemplate("Club")}
                  </div>
              `
            : "";
    };

    const priceTemplate = () => {
        const tempClassNames = classNames(
            "group relative flex h-full cursor-pointer items-center justify-center overflow-hidden border-y border-solid border-[#e0e0e0] pt-[26px]",
            "hover:bg-new-ui-blue",
            "sm:w-1/2",
            {
                "w-1/2": showClubPrice,
                "w-3/5 border-x": !showClubPrice,
                "rounded-l-md": props.context.flightState.selectedFeeType === "Smart" || !showClubPrice,
                "border-l": props.context.flightState.selectedFeeType === "Smart" && showClubPrice,
                "rounded-r-md": props.context.flightState.selectedFeeType === "Club" || !showClubPrice,
                "border-r": props.context.flightState.selectedFeeType === "Club" && showClubPrice,
            },
        );

        return html`
            <div
                class=${tempClassNames}
                data-test-id=${getTestId(T.FLIGHT.SMART_FEE, {
                    j: props.flightOption.JourneyIndex,
                    i: props.flightOption.Index,
                })}
                data-departure=${props.flightOption.FlightSelectModel.Std24H}
                data-arrival=${props.flightOption.FlightSelectModel.Sta24H}
                data-value=${props.flightOption.UnformattedSmartPrice}
                @click=${handleSmartSelect}
            >
                ${priceLabelTemplate("Smart")} ${priceAmountTemplate("Smart")}
            </div>
        `;
    };

    const priceSelectorTemplate = () =>
        props.context.flightState.selectedFeeType === "Club"
            ? html`${dcPriceTemplate()} ${priceTemplate()}`
            : html`${priceTemplate()} ${dcPriceTemplate()}`;

    const priceSelectorContainerTemplate = () => html`
        <div class="m-4 flex h-[80px] justify-center sm:absolute sm:inset-4 sm:m-0 sm:h-auto">
            ${priceSelectorTemplate()}
        </div>
    `;

    const htmlTemplate = () =>
        isLoaded
            ? html` <div class="relative h-full">${priceSelectorContainerTemplate()}</div>
                  <ac-dc-info-modal
                      .isOpen=${isModalOpen}
                      .vm=${props.context.model}
                      .onClose=${() => setIsModalOpen(false)}
                  ></ac-dc-info-modal>`
            : loaderTemplate();

    return { htmlTemplate };
};

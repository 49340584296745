export default class BookingFlowHandler {
    public static storeCurrentStep(value: number): void {
        window.sessionStorage.setItem(this.currentStepKey, value.toString());

        if (this.history.length === 0) {
            window.sessionStorage.setItem(this.historyKey, value.toString());
            return;
        }

        if (Number(this.history[this.history.length - 1]) !== value) {
            const currentHistory = this.history;
            currentHistory.push(value.toString());
            window.sessionStorage.setItem(this.historyKey, currentHistory.join("|"));
        }
    }

    public static getCurrentStep(): number {
        return Number(window.sessionStorage.getItem(this.currentStepKey));
    }

    public static getPreviousStep(): number {
        if (this.history.length < 2) {
            return undefined;
        } else {
            return Number(this.history[this.history.length - 2]);
        }
    }

    private static currentStepKey = "jsCurrentStep";
    private static historyKey = "jsHistory";

    private static get history(): string[] {
        return window.sessionStorage.getItem(this.historyKey)
            ? window.sessionStorage.getItem(this.historyKey).split("|")
            : [];
    }
}

import { html } from "haunted";
import i18next from "i18next";

export const GiftcardLogoTemplate = () => html`
    <!-- /Views/V2/PartialViews/Sidebar/_GiftcardBox.cshtml -->
    <div class="giftcard-sidebar-info">
        <i class="js-icon js-gift-card1"></i>
        ${i18next.t("Gift-SidebarBoxTitle-1")}
        <span>${i18next.t("Gift-SidebarBoxTitle-2")}</span>
        <div class="giftcard-sidebar-logo">
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 65 16"
                style="enable-background:new 0 0 65 16;"
                xml:space="preserve"
            >
                <path
                    d="M20.2,8.3c0.1-0.1,0.1-0.3,0.1-0.4c0.3-1,0.9-1.5,1.9-1.8c0.6-0.2,1.3-0.2,2-0.2c0.7,0,1.3,0.2,2,0.4
              c0.1,0,0.1,0.1,0.1,0.2c-0.1,0.5-0.2,1-0.2,1.6c-0.3-0.1-0.5-0.1-0.8-0.2c-0.7-0.1-1.3-0.2-2-0.2c-0.2,0-0.4,0.1-0.6,0.2
              c-0.4,0.2-0.5,0.8-0.1,1.2c0.2,0.2,0.5,0.3,0.8,0.4c0.6,0.2,1.1,0.4,1.7,0.6c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.4,0.8,0.4,1.3
              c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-1,0.8-1.7,1c-0.2,0.1-0.5,0.1-0.7,0.1c0,0,0,0,0,0h-0.9c-0.1,0-0.3,0-0.4-0.1
              c-0.7-0.1-1.3-0.3-1.9-0.5c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.5,0.2-1.1,0.3-1.6c0.1,0,0.1,0,0.2,0.1c0.8,0.4,1.7,0.6,2.7,0.6
              c0.3,0,0.5,0,0.7-0.1c0.5-0.2,0.7-0.8,0.4-1.2c-0.1-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.8-0.3-1.2-0.5c-0.5-0.2-1-0.4-1.5-0.7
              c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.3-0.1-0.6-0.1-0.9C20.2,8.4,20.3,8.3,20.2,8.3"
                />

                <path
                    d="M64,6c-0.1,0.5-0.2,0.9-0.4,1.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1-0.1,0.1c-0.7,0-1.4,0-2.2,0
              c-0.1,0-0.1,0-0.1,0.1c0,2.3,0,4.6,0,6.8c0,0.1,0,0.1-0.1,0.1c-0.6,0-1.3,0-1.9,0c-0.1,0-0.1,0-0.1-0.1c0-2.3,0-4.6,0-6.9
              c0-0.1,0-0.1-0.1-0.1c-0.7,0-1.4,0-2.1,0c-0.1,0-0.1,0-0.1-0.1c-0.1-0.5-0.3-1.1-0.4-1.6c0-0.1,0-0.1,0.1-0.1c2,0,4,0,5.9,0
              C62.8,6,63.4,6,64,6L64,6z"
                />

                <path
                    d="M35.8,9.4C35.7,9.4,35.7,9.5,35.8,9.4c-0.8,1.9-1.6,3.6-2.3,5.4c0,0.1-0.1,0.1-0.2,0.1c-0.4,0-0.8,0-1.2,0
              c-0.1,0-0.1,0-0.1-0.1c-0.7-1.6-1.3-3.2-2-4.8c-0.1-0.2-0.2-0.4-0.3-0.7c0,0.1,0,0.1,0,0.2c0,1.7,0,3.5,0,5.2c0,0.1,0,0.1-0.1,0.1
              c-0.6,0-1.1,0-1.7,0c-0.1,0-0.1,0-0.1-0.1c0-2.9,0-5.8,0-8.7C27.9,6,27.9,6,28,6c0.7,0,1.4,0,2.1,0c0.1,0,0.1,0,0.1,0.1
              c0.8,2,1.7,3.9,2.5,5.9c0,0,0,0.1,0.1,0.1c0,0,0,0,0-0.1c0.7-1.8,1.5-3.6,2.3-5.4c0.1-0.2,0.2-0.4,0.2-0.5C35.4,6,35.4,6,35.5,6
              c0.7,0,1.4,0,2.2,0c0.1,0,0.1,0,0.1,0.1c0,2.7,0,5.4,0,8c0,0.2,0,0.5,0,0.7c0,0.1,0,0.1-0.1,0.1c-0.6,0-1.2,0-1.8,0
              c-0.1,0-0.1,0-0.1-0.1c0-1.6,0-3.2,0-4.9c0-0.1,0-0.3,0-0.4C35.8,9.5,35.8,9.5,35.8,9.4"
                />

                <path
                    d="M54.2,11.2c0.7,1.2,1.5,2.5,2.2,3.7c0,0-0.1,0-0.1,0c-0.8,0-1.5,0-2.3,0c-0.1,0-0.1,0-0.1-0.1
              c-0.5-1.1-1-2.1-1.6-3.2c0-0.1-0.1-0.1-0.1-0.1c-0.4,0-0.8,0-1.1,0c-0.1,0-0.1,0-0.1,0.1c0,1,0,2.1,0,3.1c0,0.1,0,0.1-0.1,0.1
              c-0.6,0-1.3,0-1.9,0c-0.1,0-0.1,0-0.1-0.1c0-2.9,0-5.8,0-8.7c0-0.1,0-0.1,0.1-0.1c1.2,0,2.4,0,3.7,0c0.6,0,1.1,0.1,1.6,0.3
              C55,6.6,55.5,7.2,55.7,8c0.1,0.6,0.1,1.2,0,1.8C55.4,10.5,54.9,11,54.2,11.2 M50.9,8.9c0,0.4,0,0.8,0,1.1c0,0.1,0,0.1,0.1,0.1
              c0.2,0,0.4,0,0.7,0c0.3,0,0.6,0,1,0c0.5-0.1,0.8-0.3,0.9-0.7c0.1-0.3,0.1-0.7,0-1c-0.1-0.3-0.3-0.6-0.7-0.6c-0.2,0-0.3-0.1-0.5-0.1
              c-0.5,0-0.9,0-1.4,0c-0.1,0-0.1,0-0.1,0.1C50.9,8.1,50.9,8.5,50.9,8.9"
                />

                <path
                    d="M47.8,14.9h-0.1c-0.7,0-1.4,0-2.2,0c-0.1,0-0.1,0-0.1-0.1c-0.2-0.5-0.3-1-0.5-1.5c0-0.1-0.1-0.1-0.1-0.1
              c-1.1,0-2.1,0-3.2,0c-0.1,0-0.1,0-0.1,0.1c-0.2,0.5-0.3,1-0.5,1.6c0,0,0,0.1-0.1,0.1c-0.7,0-1.4,0-2.2,0c0.1-0.2,0.1-0.4,0.2-0.6
              c0.9-2.3,1.7-4.7,2.6-7c0.1-0.4,0.3-0.8,0.4-1.2C41.9,6,42,6,42,6c0.8,0,1.6,0,2.4,0c0.1,0,0.1,0,0.1,0.1c0.5,1.2,0.9,2.5,1.4,3.7
              C46.5,11.4,47.1,13.1,47.8,14.9C47.8,14.9,47.8,14.9,47.8,14.9 M43.2,7.5L43.2,7.5c-0.4,1.3-0.8,2.6-1.2,4h0.1c0.7,0,1.3,0,2,0
              c0.1,0,0.3,0,0.3,0c0.1-0.1,0-0.2-0.1-0.3c-0.3-1-0.6-2-0.9-3.1C43.3,8,43.3,7.7,43.2,7.5"
                />

                <path
                    d="M10.1,1.2C10.1,1.3,10.2,1.3,10.1,1.2c0.5,0.2,0.7,0.5,0.6,0.9c-0.1,0.5-0.1,1.1-0.2,1.6
              c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,0.5-0.1,1-0.2,1.5C10.1,7.7,10,8.2,10,8.8C9.9,9.4,9.8,10,9.6,10.5C9.3,11.3,9,12,8.4,12.6
              c-0.7,0.8-1.6,1.1-2.7,1.1c-1-0.1-2-0.3-2.8-0.9c-0.8-0.6-1.3-1.3-1.5-2.2c0-0.2-0.1-0.4,0.1-0.6C1.7,9.7,2,9.5,2.4,9.6
              c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.3,0.1,0.5c0.3,0.7,0.8,1.2,1.5,1.5c0.5,0.2,1,0.3,1.5,0.2c0.7-0.1,1.2-0.4,1.6-1
              c0.4-0.5,0.6-1.1,0.7-1.7c0.2-1,0.3-1.9,0.4-2.9C8.7,6,8.8,5.4,8.8,4.8C8.9,4.1,9,3.5,9,2.8c0-0.3,0-0.5,0.1-0.8
              c0-0.4,0.3-0.6,0.7-0.7c0,0,0,0,0,0H10.1z"
                />

                <path
                    d="M20.2,8.3c0,0,0,0.1,0,0.1c0,0.3,0,0.6,0.1,0.9c0.1,0.2,0.2,0.4,0.2,0.5c-0.4,0.6-0.8,1.1-1.4,1.5
              c-0.5,0.3-1.1,0.4-1.7,0.3c-0.6-0.1-1-0.4-1.3-0.9c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2,0.2-0.3,0.3-0.5,0.4c-0.5,0.4-1.1,0.7-1.7,0.8
              c-1.1,0.2-2.1-0.3-2.6-1.3C11.1,10,11,9.8,11,9.6c0-0.1,0-0.1-0.1-0.1c-0.4-0.2-0.4-0.8,0.1-1c0.1,0,0.1-0.1,0.1-0.1
              c0.2-0.6,0.5-1.2,1-1.6c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1.1,0.4,1.2,1c0.1,0.7-0.2,1.3-0.7,1.8c-0.4,0.4-1,0.7-1.6,0.8
              c0,0-0.1,0,0,0.1c0.2,0.4,0.4,0.7,1,0.8c0.6,0,1.1-0.2,1.6-0.6c0.3-0.3,0.6-0.6,0.9-0.9c0,0,0-0.1,0-0.1c0-0.7,0.1-1.3,0.2-2
              c0.1-0.5,0.2-1.1,0.3-1.6c0-0.1,0-0.1-0.1-0.1c-0.7,0-1.3,0-2,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.6-0.5
              c0.7,0,1.3,0,2,0c0.2,0,0.4,0,0.5,0c0.1,0,0.1,0,0.1-0.1c0.1-0.4,0.2-0.9,0.3-1.3c0.1-0.3,0.4-0.5,0.7-0.5c0.3,0,0.6,0.3,0.6,0.6
              c0,0.2,0,0.5-0.1,0.7c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.1,0.1,0.1c0.8,0,1.7,0,2.5,0c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.3,0.3,0.5
              c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.5,0.1-0.7,0.1c-0.8,0-1.6,0-2.4,0c-0.1,0-0.1,0-0.2,0.1c-0.2,1-0.4,2-0.6,3
              c-0.1,0.6,0,1.2,0.2,1.7c0,0.1,0,0.1,0.1,0.2c0.2,0.3,0.4,0.4,0.8,0.4c0.4-0.1,0.6-0.3,0.9-0.5c0.4-0.4,0.7-0.9,1-1.4
              C20.1,8.5,20.2,8.4,20.2,8.3 M12.4,8.6c0.5,0,1-0.4,1.1-0.9c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.2
              c-0.4,0.3-0.5,0.7-0.6,1.1C12.2,8.6,12.2,8.6,12.4,8.6"
                />
            </svg>
        </div>
    </div>
`;

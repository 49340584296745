import { useRef } from "haunted";
import { useEffect, useMemo } from "./CustomHooks";
import { DEBOUNCE_TIME } from "../commonConstants";

const debounce = (func: Function, delay: number) => {
    let timer: number;
    return function (this: any, ...args: any[]) {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => func.apply(this, args), delay);
    };
};

// DEVNOTE https://www.developerway.com/posts/debouncing-in-react
// NO OTHER debounce works with Haunted
export const useDebouncedFunc = (callback: Function) => {
    const ref = useRef(null);

    useEffect(() => void (ref.current = callback), [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => ref.current?.();
        return debounce(func, DEBOUNCE_TIME);
    }, []);

    return debouncedCallback;
};

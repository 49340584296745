import { useEffect, useMemo, useState } from "./../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { UpgradeCompanyInfoModel } from "../../../component-models/UpgradeCompanyInfoModel";
import {
    getAntiforgerySegment,
    getTaxnumberCodeByCountry,
    handleCugLoader,
    CommonRegion,
    SelectOption,
    maskCurrenciesForDisplay,
    getAntiForgeryTokenFromHtml,
} from "../../../shared/common";
import { normalizeCulture } from "../../../shared/localeHelper";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { classMap } from "lit-html/directives/class-map";
import { ROUTES } from "../../../shared/apiRoutes";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { OrganizationType, OrganizationVM } from "../../cug-register/cug-register-company";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

const EDIT_COMPANY_FORM_CLASS = "cug2-edit-company-form";

export const name = "ac-cug-company-edit";

export interface Properties {
    antiForgeryToken: string;
    canEditCompany: boolean;
    companyModel: UpgradeCompanyInfoModel;
    regions: CommonRegion[];
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        antiForgeryToken: host.antiForgeryToken,
        canEditCompany: host.canEditCompany,
        companyModel: host.companyModel,
        regions: host.regions,
    };

    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const DEFAULT_ORG_MODEL: OrganizationVM = useMemo(
        () => ({
            address: props.companyModel.Address.AddressLine || "",
            city: props.companyModel.Address.City || "",
            countryCode: props.companyModel.Address.CountryCode || "",
            currencyCode: props.companyModel.CurrencyCode || "",
            email: props.companyModel.Email || "",
            file: undefined,
            giro: props.companyModel.Giro || "",
            name: props.companyModel.Name || "",
            region: props.companyModel.Address.Region || "",
            rntExpirationDate: undefined,
            taxNumber: props.companyModel.TaxNumber || "",
            type: (props.companyModel.Type as OrganizationType) || "",
        }),
        [cug2AppContext.Countries],
    );

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const { ajaxRequest } = useAjax();

    const [editCompanyMode, setEditCompanyMode] = useState<boolean>(true);
    const [vm, setVm] = useState<OrganizationVM>(DEFAULT_ORG_MODEL);
    const [isValidated, setIsValidated] = useState<boolean>(undefined);

    const countryOptions = useMemo(
        () => [
            { Value: "", Text: "", IsSelected: !vm.countryCode },
            ...cug2AppContext.Countries.map(
                (c): SelectOption => ({
                    IsSelected: c.Value === vm.countryCode,
                    Text: c.Text,
                    Value: c.Value,
                }),
            ),
        ],
        [cug2AppContext.Countries, vm.countryCode],
    );

    const hasRegions = useMemo(
        () => props.regions.filter((region) => region.CountryCode === vm.countryCode).length > 0,
        [props.regions, vm.countryCode],
    );

    const regionOptions = useMemo(
        () => [
            { Value: "", Text: "", IsSelected: !vm.region },
            ...props.regions
                .filter((region) => region.CountryCode === vm.countryCode)
                .map(
                    (region): SelectOption => ({
                        IsSelected: region.RegionName === vm.region,
                        Text: region.RegionName,
                        Value: region.RegionName,
                    }),
                ),
        ],
        [props.regions, vm.countryCode, vm.region],
    );

    const validator = useFluentValidator<keyof OrganizationVM, OrganizationVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("countryCode", (vm: OrganizationVM) => vm.countryCode).isRequired(),
            Validation.ruleFor("name", (vm: OrganizationVM) => vm.name).isRequired(),
            Validation.ruleFor("taxNumber", (vm: OrganizationVM) => vm.taxNumber).isRequired(),
            Validation.ruleFor("giro", (vm: OrganizationVM) => vm.giro).isRequired(),
            Validation.ruleFor("city", (vm: OrganizationVM) => vm.city)
                .isRequired()
                .max(32),
            Validation.ruleFor("region", (vm: OrganizationVM) => vm.region)
                .isRequired()
                .max(2048),
            Validation.ruleFor("address", (vm: OrganizationVM) => vm.address)
                .isRequired()
                .max(52),
            Validation.ruleFor("currencyCode", (vm: OrganizationVM) => vm.currencyCode).isRequired(),
            Validation.ruleFor("email", (vm: OrganizationVM) => vm.email)
                .isRequired()
                .max(266)
                .isEmail(),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    useEffect(init, []);

    // EVENT LISTENERS

    const handleCompanySubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = await validator.validate();
        setIsValidated(true);

        if (isValid) {
            const editCompanyForm = document.body.querySelector(`.${EDIT_COMPANY_FORM_CLASS}`) as HTMLDivElement;

            handleCugLoader(editCompanyForm, "editCompany");

            const bodyToPost = {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
                "Name": vm.name,
                "Address.CountryCode": vm.countryCode,
                "TaxNumber": vm.taxNumber,
                "Giro": vm.giro,
                "Email": vm.email,
                "Address.City": vm.city,
                "Address.Region": vm.region,
                "Address.AddressLine": vm.address,
                "CurrencyCode": vm.currencyCode,
                "Type": vm.type,
                "CultureCode": normalizeCulture(props.companyModel.CultureCode),
            };

            await ajaxRequest({
                container: document.body,
                method: "POST",
                body: bodyToPost,
                url: ROUTES.Cug2BPostEditOrganization,
                onResponseCode: {
                    200: () => setEditCompanyMode(false),
                },
            });

            handleCugLoader(editCompanyForm, "editCompany");
        }
    };

    // TEMPLATES

    const saveMessageTemplate = () =>
        !editCompanyMode
            ? html`
                  <div class="cug2b-save-message" data-test-id=${T.CUG2_AGENCY_EDIT.SAVE_MESSAGE}>
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const nameTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3 mt-[5px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .isDisabled=${true}
                .errorMessage=${validator.getMessage("name")}
                .isInvalid=${!validator.isValid("name")}
                .label=${i18next.t("Razón Social")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .value=${vm.name}
                .onInput=${(value: string) => setVm({ ...vm, name: value })}
            ></ac-input>
        </div>
    `;

    const countryTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3 mt-[5px] w-full sm:w-1/3">
            <ac-select
                .errorMessage=${validator.getMessage("countryCode")}
                .isInvalid=${!validator.isValid("countryCode")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("País de origen")}
                .options=${countryOptions}
                .testId=${T.CUG2_AGENCY_EDIT.COUNTRY_SELECTOR}
                .onSelect=${(value: string) => {
                    setVm({
                        ...vm,
                        countryCode: value,
                    });
                }}
            ></ac-select>
        </div>
    `;

    const taxNumberTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3 mt-[5px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("taxNumber")}
                .isDisabled=${true}
                .isInvalid=${!validator.isValid("taxNumber")}
                .label=${getTaxnumberCodeByCountry(props.companyModel.Address.CountryCode) ||
                i18next.t("CUG-TaxNumber")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "travel-document-id")}
                .value=${vm.taxNumber}
                .onInput=${(value: string) => setVm({ ...vm, taxNumber: value })}
            ></ac-input>
        </div>
    `;

    const giroTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 mt-[10px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("giro")}
                .isInvalid=${!validator.isValid("giro")}
                .isDisabled=${true}
                .label=${i18next.t("Giro")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${T.CUG2_AGENCY_EDIT.GIRO_INPUT_FIELD}
                .value=${vm.giro}
                .onInput=${(value: string) => setVm({ ...vm, giro: value })}
            ></ac-input>
        </div>
    `;

    const emailTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 mt-[10px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("Correo electrónico")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail")}
                .testId=${T.CUG2_AGENCY_EDIT.EMAIL_INPUT_FIELD}
                .value=${vm.email}
                .onInput=${(value: string) => setVm({ ...vm, email: value })}
            ></ac-input>
        </div>
    `;

    const cityTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 mt-[10px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("city")}
                .isInvalid=${!validator.isValid("city")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("Ciudad")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "city")}
                .testId=${T.CUG2_AGENCY_EDIT.CITY_INPUT_FIELD}
                .value=${vm.city}
                .onInput=${(value: string) => setVm({ ...vm, city: value })}
            ></ac-input>
        </div>
    `;

    const regionTemplate = () => (hasRegions ? dropdownForRegionTemplate() : textFieldForRegionTemplate());

    const dropdownForRegionTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 mt-[10px] w-full sm:w-1/3">
            <ac-select
                .errorMessage=${validator.getMessage("region")}
                .isInvalid=${!validator.isValid("region")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("Comuna")}
                .options=${regionOptions}
                .testId=${T.CUG2_AGENCY_EDIT.REGION_SELECTOR}
                .onSelect=${(value: string) => setVm({ ...vm, region: value })}
            ></ac-select>
        </div>
    `;

    const textFieldForRegionTemplate = () => html`
        <div class="col-xs-1 col-sm-1-2 mt-[10px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("region")}
                .isInvalid=${!validator.isValid("region")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("Comuna")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "city")}
                .testId=${T.CUG2_AGENCY_EDIT.REGION_INPUT_FIELD}
                .value=${vm.region}
                .onInput=${(value: string) => setVm({ ...vm, region: value })}
            ></ac-input>
        </div>
    `;

    const addressTemplate = () => html`
        <div class="col-xs-1 mt-[10px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("address")}
                .isInvalid=${!validator.isValid("address")}
                .isDisabled=${!editCompanyMode || !props.canEditCompany}
                .label=${i18next.t("Dirección")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "address")}
                .testId=${T.CUG2_AGENCY_EDIT.ADDRESS_INPUT_FIELD}
                .value=${vm.address}
                .onInput=${(value: string) => setVm({ ...vm, address: value })}
            ></ac-input>
        </div>
    `;

    const currencyTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3 mt-[10px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("currencyCode")}
                .isInvalid=${!validator.isValid("currencyCode")}
                .isDisabled=${true}
                .label=${i18next.t("Moneda")}
                .testId=${T.CUG2_AGENCY_EDIT.CURRENCY_INPUT_FIELD}
                .value=${maskCurrenciesForDisplay(vm.currencyCode)}
                .onInput=${(value: string) => setVm({ ...vm, currencyCode: value })}
            ></ac-input>
        </div>
    `;

    const companyEditorTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-profile-edit": true,
            "cursor-default": editCompanyMode,
            "cursor-pointer": !editCompanyMode,
        });

        const onClick = !editCompanyMode ? () => setEditCompanyMode(true) : null;

        return props.canEditCompany
            ? html`
                  <div class=${tempClassMap} @click=${onClick}>
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";
    };

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !editCompanyMode || !props.canEditCompany,
        });

        return html`
            <div class="mt-8 flex w-full justify-end">
                <button
                    class=${tempClassMap}
                    data-test-id="submit-organization-edit-button"
                    @click=${handleCompanySubmit}
                    data-test-id=${T.CUG2_AGENCY_EDIT.SAVE_BUTTON}
                >
                    ${i18next.t("Guardar")}
                </button>
            </div>
        `;
    };

    return props.companyModel
        ? html`
              <div class="cug2b-edit-profile-form cug2-edit-company-form ts-error-parent">
                  <h1>
                      ${props?.companyModel?.Type === "AGENCY"
                          ? i18next.t("Datos de la agencia")
                          : i18next.t("Datos de la empresa")}
                      ${companyEditorTemplate()}
                  </h1>
                  ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)}
                  <div class="row">
                      ${nameTemplate()} ${countryTemplate()} ${taxNumberTemplate()} ${giroTemplate()} ${emailTemplate()}
                      ${cityTemplate()} ${regionTemplate()} ${addressTemplate()} ${currencyTemplate()}
                  </div>
                  ${formErrors.htmlTemplate()} ${submitButtonTemplate()}
              </div>
          `
        : html``;
};

// ChatGPT: What is a test circuit?

export type CircuitParamType =
    | "InsuranceModalToBeOpened"
    | "InsuranceCostAvailable"
    | "DcBannerToBeOpened"
    | "PerJourneyPerPaxOpened"
    | "SpaModalToBeOpened";

export type YesNo = "YES" | "NO";

export const setTestCircuitDomValue = <T>(param: CircuitParamType, value: T): void => {
    window.testCircuit = { ...window.testCircuit };
    window.testCircuit[param] = value as unknown as string;
};

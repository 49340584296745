import { ROUTES } from "../../../shared/apiRoutes";
import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export const useOxyModalFail = () => {
    const headerTemplate = () => html`<span>${i18next.t("V2-CheckinSanitary-Important")}</span>`;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <p class="text-center">
                    ${i18next.t(
                        "No es posible realizar su check-in online dado que tu reserva tiene un requerimiento especial, por favor diríjase al counter del aeropuerto y haremos tu check-in sin costo adicional.",
                    )}
                </p>
            </div>
            <div class="primary-modal-button-container">
                <a class="rounded-secondary-btn" href=${ROUTES.PageItinerary}> ${i18next.t("V2-Cancel")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import i18next from "i18next";
import { useModal } from "../shared/useModal";
import { html } from "lit-html";

export const useInfantLimitationModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body leading-normal">
            ${i18next.t(
                "Lo sentimos, este vuelo ya ha alcanzado la capacidad máxima de infantes permitidos a bordo. Por favor, seleccione otro vuelo.",
            )}
            <div class="primary-modal-button-container">
                <a class="rounded-primary-btn" @click=${modal.close}>OK</a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("Importante") },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

import i18next from "i18next";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";

export const passwordConfirm = (): InputFieldAttribute => ({
    name: "password-confirm",
    governingFieldAttributeName: "password-format",
    validators: [
        {
            errorMessage: {
                id: "PasswordConfirmation",
                message: i18next.t("El campo de confirmación de contraseña debe coincidir con el campo de contraseña."),
                scope: "field",
            },

            validate: (field: InputElement, governingField: InputElement) => {
                if (!field.value || !governingField.value) {
                    return Promise.resolve(true);
                }

                const result = field.value === governingField.value;

                return Promise.resolve(result);
            },
        },
    ],
});

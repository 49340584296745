import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import i18next from "i18next";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { ROUTES } from "../../../../shared/apiRoutes";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useSeatRecommendator } from "../recommendator/useSeatRecommendator";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import {
    bancoEstadoDiscountedSeatsInfoTemplate,
    bancoEstadoFreeSeatsTemplate,
} from "../../../../common-templates/SeatmapCommonTemplates";
import { useSeatPassengerJourney } from "../passengers/useSeatPassengerJourney";
import { useWindowSize } from "../../../../shared/customHooks/useWindowSize";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { OUTBOUND, INBOUND } from "../../../../shared/commonConstants";

export interface Props {
    canAddSameSeatsToNextSegment: boolean;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    isActive: boolean;
    seatmapState: SeatmapState;
    showAutoReturnSeatSelectionBubble: boolean;
    hasFlightColombianStation: boolean;
    handleApplyRecommendedSeats: (e: MouseEvent, isMobile: boolean) => void;
    handleContinue: (e: MouseEvent, fromModal: boolean) => void;
    handleInboundSelect: () => void;
    handleNoSelection: () => void;
    handleOutboundSelect: () => void;
    handleResetSelection: (e: MouseEvent) => void;
    handleSeatAutoAssignment: (journeyIndex: number, segmentIndex: number) => Promise<void>;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    handleSegmentClick: (segmentIndex: number) => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const useSidePanel = (props: Props) => {
    const [userContext] = useReduxState("userContext");
    const flowContext = useFlowContext();

    const desktopRecommendator = useSeatRecommendator({
        context: props.context,
        isForMobile: false,
        seatmapState: props.seatmapState,
        hasFlightColombianStation: props.hasFlightColombianStation,
        handleApplyRecommendedSeats: props.handleApplyRecommendedSeats,
        handleNoSelection: props.handleNoSelection,
    });

    const showNoSeatsButton = () => noOneIsSeatedOnEnter() && props.context.CheckinFlowType === "none" && !isMobile();

    const seatPassengerJourney = useSeatPassengerJourney({
        canAddSameSeatsToNextSegment: props.canAddSameSeatsToNextSegment,
        context: props.context,
        currentSeats: props.currentSeats,
        seatmapState: props.seatmapState,
        showAutoReturnSeatSelectionBubble: props.showAutoReturnSeatSelectionBubble,
        handleContinue: props.handleContinue,
        handleResetSelection: props.handleResetSelection,
        handleSeatAutoAssignment: props.handleSeatAutoAssignment,
        handleSeatDelete: props.handleSeatDelete,
        handleSegmentClick: props.handleSegmentClick,
        handleNoSelection: props.handleNoSelection,
        updateState: props.updateState,
        showNoSeatsButton,
    });

    const windowSize = useWindowSize();

    const isMobile = () => windowSize?.width < 1200;

    const [recommendatorLine1, recommendatorLine2, recommendatorLine3] = SeatmapHelper.recommendatorLines(
        props.context,
        props.seatmapState,
    );

    const showBancoEstadoAllSeatsTaken = () =>
        props.isActive &&
        props.context.Model?.IsBancoEstadoUserEligible &&
        SeatmapHelper.showAllBancoEstadoSeatsTaken(
            props.context.Model,
            props.seatmapState.CurrentJourney.JourneyIndex,
            props.seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
            [1, 2, 3, 5, 7].includes(userContext.bancoEstado.category),
            userContext.bancoEstado.remainingFreeSeats,
        );

    const isInboundTabEnabled = () =>
        props.context.CheckinFlowType !== "notAllSeated" ||
        SeatmapHelper.doAllPaxHaveSeats(
            props.currentSeats,
            props.context.Model?.Journeys[OUTBOUND],
            props.context.Model?.Passengers,
        ) ||
        props.context.Model?.CheckedJourneyIndex === INBOUND;

    const showOutbound = () => SeatmapHelper.showOutbound(props.context.Model);

    const showInbound = () => SeatmapHelper.showInbound(props.context.Model);

    const showMouseInfo = () =>
        !flowContext.isChangeFlow && [0, 1, 2, 3, 5, 7].includes(userContext.bancoEstado.category);

    const noOneIsSeatedOnEnter = () =>
        flowContext.isBookingFlow ||
        !props.context.Model?.Journeys.find(
            (j) => j.JourneyIndex === props.seatmapState?.CurrentJourney?.JourneyIndex,
        )?.Segments.some((s) => s.Seats.some((seat) => seat.PassengerNumber > -1));

    const mouseInfoTemplate = () =>
        showMouseInfo()
            ? html`
                  <div class="full-width-information info">
                      <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                      <span>${window.seatmapResources.mouseInfoText}</span>
                  </div>
              `
            : "";

    const bancoEstadoZoneFullTemplate = () =>
        props.seatmapState?.CurrentJourney &&
        SeatmapHelper.showBancoEstadoCategory6SecondaryZoneInfo(
            props.context.Model,
            props.seatmapState.CurrentJourney.JourneyIndex,
            props.seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
            userContext.bancoEstado.remainingFreeSeats,
        )
            ? html`
                  <div class="be2-seats-panel be2-no-seats-panel ">
                      <div class="discounted-seat-info">
                          <div>
                              ${i18next.t("¡Lo sentimos, no quedan asientos disponibles en la zona BancoEstado, pero")}
                              <span>
                                  ${i18next.t("hemos habilitado los asientos desde la fila 15 a la 32 GRATIS!")}
                              </span>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const bancoEstadoAllSeatsTakenCat1Template = () =>
        showBancoEstadoAllSeatsTaken()
            ? html`
                  <div class="be2-seats-panel be2-no-seats-panel ">${window.seatmapResources.allSeatsTakenText}</div>
              `
            : "";

    const selectorPanelTemplate = () => {
        const tempClassMap = classMap({
            "seatmap-info-content": true,
            "be-2": [5, 6].includes(userContext.bancoEstado.category),
        });

        return html`
            <div class=${tempClassMap}>
                ${recommendatorBannerTemplate()}
                <h1 class="seatmap-info-title">${i18next.t("Asignación de Asientos")}</h1>
                <div class="seatmap-journey-selector">
                    ${directionSwitchTemplate()} ${seatPassengerJourney.htmlTemplate()}
                </div>

                ${desktopRecommendator.htmlTemplate()}
            </div>
        `;
    };

    const recommendatorBannerTemplate = () =>
        desktopRecommendator.isRecommendatorOn
            ? html`
                  <div
                      class="seatmap-recommendator-banner"
                      data-test-id=${getTestId(T.SEATMAP.RECOMMENDATOR_BANNER, {
                          j: props.seatmapState.CurrentJourney.JourneyIndex,
                          s: props.seatmapState.CurrentSegment.SegmentIndex,
                      })}
                  >
                      ${recommendatorLine1} ${recommendatorLine2 ? html` <span>${recommendatorLine2}</span> ` : ""}
                      <span class="mt-2">${recommendatorLine3}</span>
                  </div>
              `
            : "";

    const outboundSwitchTemplate = () =>
        showOutbound()
            ? html`
                  <li class="direction-switch">
                      <input
                          id="tab1"
                          name="rd"
                          class="seatmap-journey-selector-radio"
                          type="radio"
                          .checked=${props.seatmapState?.CurrentJourney?.JourneyIndex !== INBOUND}
                          @click=${(e: MouseEvent) => {
                              e.preventDefault();
                              props.handleOutboundSelect();
                          }}
                      />
                      <label for="tab1" data-test-id=${getTestId(T.SEATMAP.DIRECTION_SWITCH, { j: OUTBOUND })}>
                          <span class="direction">${window.seatmapResources.outboundShortText}</span>
                          <span class="date">${props.context.Model?.Journeys[OUTBOUND].FormattedDate}</span>
                      </label>
                  </li>
              `
            : "";

    const inboundSwitchTemplate = () => {
        const tempClassMap = classMap({
            "seatmap-journey-selector-radio": true,
            "no-click": !isInboundTabEnabled(),
        });

        const isChecked = !showOutbound() || props.seatmapState?.CurrentJourney?.JourneyIndex === INBOUND;

        return showInbound()
            ? html`
                  <li class="direction-switch">
                      <input
                          class=${tempClassMap}
                          id="tab2"
                          name="rd"
                          type="radio"
                          .checked=${isChecked}
                          @click=${(e: MouseEvent) => {
                              e.preventDefault();
                              props.handleInboundSelect();
                          }}
                      />
                      <label
                          for="tab2"
                          class=${tempClassMap}
                          data-test-id=${getTestId(T.SEATMAP.DIRECTION_SWITCH, { j: INBOUND })}
                      >
                          <span class="direction">${window.seatmapResources.inboundText}</span>
                          <span class="date">${props.context.Model?.Journeys[INBOUND].FormattedDate}</span>
                      </label>
                  </li>
              `
            : "";
    };

    const directionSwitchTemplate = () => html`
        <nav>
            <ul class="direction-switch-container">
                ${outboundSwitchTemplate()} ${inboundSwitchTemplate()}
            </ul>
        </nav>
    `;

    const legendTemplate = () => html`
        <div class=" accordion-wrapper seatmap-accordion-wrapper">
            <input id="legend-acc" type="checkbox" />
            <label for="legend-acc" class="accordion-opener">
                <header class="hidden-xs">
                    <div class="title">
                        <h2 class="main-title">${i18next.t("Legend")}</h2>
                    </div>
                </header>
                <span class="open-icon">
                    <i class="js-circle-chevron-right js-icon"></i>
                </span>
            </label>
            <div class="accordion-content">
                <div class="accordion-content-inside">
                    <div class="inner-border-box">
                        <div class="legend-row">
                            <div class="row">
                                <div class="col-xs-1-2">
                                    <div class="legend-item">
                                        <label>${i18next.t("Available")}</label>
                                        <span class="cf-seat s-left category-1"></span>
                                        <span class="cf-seat middle category-2"></span>
                                        <span class="cf-seat middle category-3"></span>
                                        <span class="cf-seat middle category-4"></span>
                                        <span class="cf-seat s-right category-5"></span>
                                    </div>
                                </div>
                                <div class="col-xs-1-2">
                                    <div class="legend-item justify-end">
                                        <label>${i18next.t("Selected")}</label>
                                        <span class="cf-seat s-left selected category-1"></span>
                                        <span class="cf-seat middle selected category-2"></span>
                                        <span class="cf-seat middle selected category-3"></span>
                                        <span class="cf-seat middle selected category-4"></span>
                                        <span class="cf-seat s-right selected category-5"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="legend-row">
                            <div class="row">
                                <div class="col-xs-1-3">
                                    <div class="legend-item">
                                        <label>${i18next.t("NotAvailable")}</label>
                                        <span class="cf-seat category-1 s-left unavailable"></span>
                                    </div>
                                </div>
                                <div class="col-xs-1-3">
                                    <div class="legend-item justify-center">
                                        <label>${i18next.t("Toilet")}</label>
                                        <span class="toilet">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 31 31"
                                                style="enable-background:new 0 0 31 31;"
                                                xml:space="preserve"
                                            >
                                                <path
                                                    class="st0"
                                                    d="M25.3,30.3H5.7c-2.7,0-5-2.2-5-5V5.7c0-2.7,2.2-5,5-5h19.6c2.7,0,5,2.2,5,5v19.6C30.3,28,28,30.3,25.3,30.3z"
                                                />
                                                <path
                                                    class="st1"
                                                    d="M10.5,8.8H5.9c-1.1,0-2,0.9-2,2l0,6.3c0,0.5,0.4,0.9,0.9,0.9h0c0.5,0,0.9-0.4,0.9-0.9l0-6.3v0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,14.4c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1V17h0.4v8.2c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1c0-13.6,0-5.8,0-14.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v0l0,6.3c0,0.5,0.4,0.9,0.9,0.9h0c0.5,0,0.9-0.4,0.9-0.9l0-6.3c0,0,0,0,0,0C12.5,9.7,11.6,8.8,10.5,8.8L10.5,8.8z M10.5,8.8"
                                                />
                                                <path
                                                    class="st1"
                                                    d="M10,6.5c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C9.2,4.7,10,5.5,10,6.5L10,6.5z M10,6.5"
                                                />
                                                <path
                                                    class="st1"
                                                    d="M27.1,15.8c0,0-1.6-4.5-1.8-5.2c-0.4-1.2-1.1-1.7-2-1.7h-3c-0.9,0-1.6,0.6-2,1.7c-0.2,0.7-1.8,5.2-1.8,5.2c-0.2,0.4,0.1,0.9,0.5,1.1c0.4,0.2,0.9-0.1,1.1-0.5c0.1-0.1,1-2.8,1.5-4.3c-0.1,0.4,0.1-0.5-1.2,6.3c-0.1,0.3,0.2,0.6,0.5,0.6h0.7v6.2c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1V19h0.4v6.2c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1V19h0.7c0.3,0,0.5-0.3,0.5-0.6c-1.3-6.8-1.2-6-1.2-6.3c0.5,1.5,1.4,4.1,1.5,4.3c0.2,0.4,0.6,0.7,1.1,0.5C27,16.7,27.3,16.2,27.1,15.8L27.1,15.8z M27.1,15.8"
                                                />
                                                <path
                                                    class="st1"
                                                    d="M23.6,6.5c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C22.8,4.8,23.6,5.5,23.6,6.5L23.6,6.5zM23.6,6.5"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-1-3">
                                    <div class="legend-item justify-end">
                                        <label class="exit-label">${i18next.t("EmergencyExit")}</label>
                                        <span class="exit"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;

    const readOnlyContinueButtonTemplate = () =>
        props.context.Model?.IsReadOnly
            ? html`
                  <div class="text-right ">
                      <div class="seatmap-button-container sm:w-1/2">
                          <a
                              href=${`${ROUTES.PassengersCheckin}?sk=${props.context.Model?.SellKeyForCheckinUrl}`}
                              class="rounded-primary-btn"
                              data-test-id="${T.SEATMAP.CONTINUE_BUTTON}"
                          >
                              ${window.seatmapResources.continueText}
                          </a>
                      </div>
                  </div>
              `
            : "";

    const seatCategoriesTemplate = () => html`
        <ac-seat-categories-legend .seatmapSegment=${props.seatmapState?.CurrentSegment} .isMobile=${false}>
        </ac-seat-categories-legend>
    `;

    const htmlTemplate = () => html`
        <div class="seatmap-info hidden-md-down">
            ${mouseInfoTemplate()}
            ${bancoEstadoFreeSeatsTemplate({
                context: props.context,
                currentSeats: props.currentSeats,
                freeSeatsNotOnBooking: userContext.bancoEstado.remainingFreeSeats,
                isBookingFlow: flowContext.isBookingFlow,
                isCategory6: userContext.bancoEstado.category === 6,
                seatmapState: props.seatmapState,
            })}
            ${bancoEstadoDiscountedSeatsInfoTemplate(
                props.context,
                props.seatmapState,
                flowContext.isBookingFlow,
                userContext.bancoEstado.category === 6,
                userContext.bancoEstado.category === 5,
                userContext.bancoEstado.remainingFreeSeats,
            )}
            ${bancoEstadoZoneFullTemplate()} ${bancoEstadoAllSeatsTakenCat1Template()} ${selectorPanelTemplate()}
            ${legendTemplate()} ${seatCategoriesTemplate()} ${readOnlyContinueButtonTemplate()}
        </div>
    `;

    return {
        desktopRecommendator,
        isTermsAcceptanceCheckboxChecked: seatPassengerJourney.isTermsAcceptanceCheckboxChecked,
        scrollToTop: seatPassengerJourney.scrollToTop,
        htmlTemplate,
        showAcceptanceWarning: seatPassengerJourney.showAcceptanceWarning,
    };
};

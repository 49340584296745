export class ThreeDSecureHelper {
    public static threeDSBrowserColorDepth(): string {
        return window.screen.colorDepth.toString();
    }

    public static threeDSBrowserJavaEnabled(): string {
        return navigator.javaEnabled() ? "true" : "false";
    }

    public static threeDSBrowserLanguage(): string {
        return navigator.language || (navigator as any).userLanguage;
    }

    public static threeDSBrowserScreenHeight(): string {
        return screen.height.toString();
    }

    public static threeDSBrowserScreenWidth(): string {
        return screen.width.toString();
    }

    public static threeDSBrowserTimeZone(): string {
        return new Date().getTimezoneOffset().toString();
    }

    public static threeDSBrowserUserAgent(): string {
        return navigator.userAgent;
    }
}
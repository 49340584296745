import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import { PERUVIAN_SOL_CURRENCY_SIGN, URL_VARS, USA_DOLLAR_CODE } from "./../../shared/commonConstants";
import {
    ACTION_NAMES,
    BRASILIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    USA_CULTURE_CODE,
} from "../../shared/commonConstants";
import { useEffect } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useMemo, useRef, useState, useEffect as hauntedUseEffect } from "haunted";
import { getAntiForgeryTokenFromHtml, showLoader, toBoolean } from "../../shared/common";
import { EventBus } from "../../shared/eventbus/eventbus";
import { JetSmartEvent } from "../../shared/eventbus/JetSmartEvent";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../directives/ref";
import { GiftcardLogoTemplate } from "../../common-templates/GiftcardLogoTemplate";
import { ChargeGroupViewModel } from "../../component-models/sidebar/BreakdownViewModel";
import { useSidebarSegments } from "./useSidebarSegments";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { CLASS_NAMES } from "../../shared/classNames";
import { ROUTES } from "../../shared/apiRoutes";
import { ApiSidebarViewModel } from "../../component-models/sidebar/ApiSidebarViewModel";
import { useBreakdown } from "./useBreakdown";
import { useTotal } from "./useTotal";
import { LoadSidebarEventData } from "../../component-models/sidebar/LoadSidebarEventData";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import BookingData from "../../shared/BookingData";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useFlightHttpContextHandler } from "../flight-select/useFlightHttpContextHandler";
import { useArgentinaPriceBreakdownModal } from "../useArgentinaPriceBreakdownModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export const name = "ac-sidebar-booking";

const SIDEBAR_SCROLLER_CLASS_NAME = "sidebar-scroller-container";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "is-total-pending"];

export interface Attributes {
    "anti-forgery-token": string;
    "is-total-pending": string;
}

export interface Props {
    antiForgeryToken: string;
    isTotalPending: boolean;
}

let debounceTimer: number;

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        isTotalPending: toBoolean(host.isTotalPending),
    };

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        reloadSidebar();

        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[name] = ScrollHelper.addPerfectScrollbar(SIDEBAR_SCROLLER_CLASS_NAME);

        const handler = triggers.shared.currencyChanged.subscribe(() => setIsOpen(false));
        return () => handler.unsubscribe();
    };

    // Helpers

    const sellKeysAndBundleCodesQuery = (data: LoadSidebarEventData) =>
        data.flightState && data.bundleState
            ? getSellKeysAndBundlesUrlQueryParams(
                  [data.flightState?.selectedOutboundFlight?.SellKey, data.flightState?.selectedInboundFlight?.SellKey],
                  [
                      data.bundleState.selectedOutboundBundle?.BundleCode,
                      data.bundleState.selectedInboundBundle?.BundleCode,
                  ],
              )
            : "";

    const reloadFlightPageSidebarNonCancellable = async (data: LoadSidebarEventData) => {
        const query = `?${URL_VARS.CURRENCY}=${data.selectedCurrency}&${sellKeysAndBundleCodesQuery(
            data,
        )}&jetSmartPassengerDiscount.ApplyPaxDiscount=${false}`;

        await reloadSidebar(true, ROUTES.PriceBreakdownFlightSelect + query, sellKeysAndBundleCodesQuery(data));
    };

    const reloadFlightPageSidebar = async (data: LoadSidebarEventData) => {
        let query = `?${URL_VARS.CURRENCY}=${data.selectedCurrency}&${sellKeysAndBundleCodesQuery(
            data,
        )}&jetSmartPassengerDiscount.ApplyPaxDiscount=${data.dcState.applyDiscount}`;

        if (data.dcState.selectedMembershipType) {
            if (data.dcState.selectedMembershipType === "Group") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=group";
            }

            if (data.dcState.selectedMembershipType === "Standard") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=standard";
            }

            if (data.dcState.selectedMembershipType === "Upgrade") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=upgrade";
            }
        }

        await reloadSidebar(false, ROUTES.PriceBreakdownFlightSelect + query, sellKeysAndBundleCodesQuery(data));
    };

    const reloadSidebar = async (nonCancellable = false, url?: string, query?: string) => {
        const loader = showLoader({ name: CLASS_NAMES.sidebarLoader, noPlane: true });
        const result = await ajaxJsonRequest<ApiSidebarViewModel>({
            loader,
            method: "GET",
            noCors: true,
            nonCancellable,
            url: url ? url : ROUTES.PriceBreakdown,
        });

        // DEVNOTE Only flight page has an url for this call
        if (url) {
            await bookingDataManager.fetchAndUpdate(ajaxJsonRequest, query);
            window.eventBus.raiseEvent({
                name: JetSmartEvent.SidebarReloaded,
                params: window.bookingData,
            });
        }

        if (result?.data) {
            setModel(result.data);
            updateSidebarBreakdown(result.data);
        }
    };

    const raiseSidebarReloadedEvent = async () => {
        if (!window.eventBus) {
            window.eventBus = new EventBus();
        }

        if (flowContext.action.toLowerCase() !== ACTION_NAMES.FLIGHT.toLowerCase()) {
            bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
                window.eventBus.raiseEvent({
                    name: JetSmartEvent.SidebarReloaded,
                    params: bookingData,
                });
            });
        }
    };

    const updateSidebarBreakdown = (newModel: ApiSidebarViewModel) => {
        const newSsrData = newModel?.SsrData.split("|");
        if (ssrData !== newSsrData) setSsrData(newSsrData);

        if (
            newModel?.BreakdownModel.TotalAmountToPay !== undefined &&
            Number(newModel?.BreakdownModel.TotalAmountToPay) >= 0 &&
            Number(newModel?.BreakdownModel.TotalAmountToPay) < 9999999
        ) {
            setTotal(Number(newModel?.BreakdownModel.TotalAmountToPay).toString());

            setIsSidebarLoaded(true);
        }

        window.setTimeout(() => {
            if (!window.acPerfectScrollbars) window.acPerfectScrollbars = {};

            window.acPerfectScrollbars[name]?.forEach((scroller) => scroller.update());
            raiseSidebarReloadedEvent();
        }, 0);
    };

    // Event handlers

    const handleSidebarOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsOpen(!isOpen);
        document.body.classList.toggle("mobile-sidebar-opened");
    };

    const handleArgentinianDcDetailsModalOpen = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        argentinaPriceModal.open();
    };

    const debounceOnFlightSelect = (data: LoadSidebarEventData) => {
        if (flowContext.action !== ACTION_NAMES.FLIGHT) {
            return;
        }

        if (debounceTimer) {
            window.clearTimeout(debounceTimer);
        }

        if (data?.nonCancellable) {
            reloadFlightPageSidebarNonCancellable(data);
        }

        debounceTimer = window.setTimeout(() => {
            reloadFlightPageSidebar(data);
        }, 250);
    };

    const debounceAfterFlightSelect = () => {
        if (flowContext.action === ACTION_NAMES.FLIGHT) {
            return;
        }

        if (debounceTimer) {
            window.clearTimeout(debounceTimer);
        }

        debounceTimer = window.setTimeout(() => {
            reloadSidebar();
        }, 250);
    };

    // Components

    const root = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();

    const { ajaxJsonRequest } = useAjax();

    const [userContext] = useReduxState("userContext");
    const [_a, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [_b, setIsSidebarLoaded] = useReduxState("isSidebarLoaded");
    const [_c, setTotal] = useReduxState("booking.total");
    const [currency] = useReduxState("booking.currency");
    const [ssrData, setSsrData] = useReduxState("booking.ssrData");

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showCurrencySelector, setShowCurrencySelector] = useState<boolean>(false);
    const [model, setModel] = useState<ApiSidebarViewModel>();

    const argentinaPriceModal = useArgentinaPriceBreakdownModal();

    const journeySegments = useSidebarSegments({ segments: model?.Segments });

    const { getSellKeysAndBundlesUrlQueryParams } = useFlightHttpContextHandler({ model: undefined });

    const { triggers } = usePubSub();
    const { formatNumber } = useNumberFormatter();

    const subscribeToTriggers = () => {
        const handler1 = triggers.sidebar.bookingChanged.subscribe(debounceAfterFlightSelect);
        const handler2 = triggers.sidebar.flightSelectionChanged.subscribe(debounceOnFlightSelect);

        return () => {
            handler1.unsubscribe();
            handler2.unsubscribe();
        };
    };

    hauntedUseEffect(subscribeToTriggers, [debounceTimer]);

    useEffect(() => {
        setShowCurrencySelector(userContext?.bancoEstado.category > 0 ? false : model?.ShowCurrencySelector);
    }, [userContext?.bancoEstado.category, model?.ShowCurrencySelector]);

    hauntedUseEffect(init, []);

    const isUncommittedFareLock = useMemo(() => {
        return (
            (Boolean(model?.BreakdownModel.UncommittedChapter?.FareLock) && !flowContext.isPostBookingFlow) ||
            flowContext.isFarelockRoundTwo
        );
    }, [model]);

    const isCommittedFareLock = useMemo(() => {
        return Boolean(model?.BreakdownModel.CommittedChapter?.FareLock) && !flowContext.isPostBookingFlow;
    }, [model]);

    const uncommittedFareLock = useMemo(() => {
        return model?.BreakdownModel.UncommittedChapter && model?.BreakdownModel.UncommittedChapter.FareLock
            ? model?.BreakdownModel.UncommittedChapter.FareLock
            : null;
    }, [model]);

    const committedFareLock = useMemo(() => {
        return model?.BreakdownModel.CommittedChapter && model?.BreakdownModel.CommittedChapter.FareLock
            ? model?.BreakdownModel.CommittedChapter.FareLock
            : null;
    }, [model]);

    const isBuyingDcStandaloneInPeru = useMemo(() => {
        return (
            flowContext.action === ACTION_NAMES.DC_REGISTER &&
            appContext.Culture === PERUVIAN_CULTURE_CODE.toLowerCase() &&
            model?.BreakdownModel.TotalAmountToPay === 0
        );
    }, [model]);

    const uncommittedChapterBreakdown = useBreakdown({
        chapter: model?.BreakdownModel.UncommittedChapter,
        model: model?.BreakdownModel,
        nonInfantPaxNumber: model?.NonInfantPaxNumber,
        paxNumber: model?.PaxNumber,
    });

    const committedChapterBreakdown = useBreakdown({
        chapter: model?.BreakdownModel.CommittedChapter,
        model: model?.BreakdownModel,
        nonInfantPaxNumber: model?.NonInfantPaxNumber,
        paxNumber: model?.PaxNumber,
    });

    const mobileTotal = useTotal({
        farelock: uncommittedFareLock ? uncommittedFareLock : committedFareLock,
        hideTotalOnMobile: true,
        isMobile: true,
        isTotalPending: props.isTotalPending,
        model,
        showCurrencySelector,
        setIsOpen,
    });

    const uncommittedTotal = useTotal({
        farelock: uncommittedFareLock,
        hideTotalOnMobile: false,
        isMobile: false,
        isTotalPending: props.isTotalPending,
        model,
        showCurrencySelector,
        setIsOpen,
    });

    const committedTotal = useTotal({
        farelock: committedFareLock,
        hideTotalOnMobile: false,
        isMobile: false,
        isTotalPending: props.isTotalPending,
        model,
        showCurrencySelector,
        setIsOpen,
    });

    // Templates
    const mobileOpenerTemplate = () => {
        const sectionClassMap = classMap({
            "no-print": true,
            "sidebar-opener": true,
            "visible-sm-down": true,
            "push-down": userContext.bancoEstado.category !== 0,
            "small": isOpen,
        });

        return html`
            <section @click=${handleSidebarOpen} class=${sectionClassMap} data-test-id=${T.SIDEBAR.MOBILE_OPENER}>
                ${isOpen ? mobileOpenerOpenTemplate() : mobileOpenerClosedTemplate()}
            </section>
        `;
    };

    const mobileOpenerClosedTemplate = () =>
        model
            ? html`
                  <div class="total-section">
                      <i class="js-icon js-trolley"></i>
                      <span>${i18next.t("MobileSidebar-TotalSectionTitle")}</span>
                      <span class="total-currency">
                          ${formatNumber({
                              amount: model.BreakdownModel.TotalAmountToPay,
                              currency: model?.Currency,
                          })}</span
                      >
                  </div>
              `
            : "";

    const mobileOpenerOpenTemplate = () => html`
        <div class="total-section ">
            <span class="mobile-total-closer">&times;</span>
            <span @click=${handleSidebarOpen} class="mobile-total-closer-caption">${i18next.t("Common-Close")}</span>
        </div>
    `;

    const mobileTotalTemplate = () => {
        const tempClassMap = classMap({
            "hidden-md-up": true,
            "new-mobile-total": true,
            "hidden": flowContext.isDcStandaloneFlow,
        });

        return html` <div class=${tempClassMap}>${mobileTotal.htmlTemplate()}</div> `;
    };

    const dcStandaloneBoxTemplate = () => {
        const tempClassMap = classMap({
            "dc-standalone-sidebar-info": true,
            "english": appContext.Culture === USA_CULTURE_CODE,
            "portuguese": appContext.Culture === BRASILIAN_CULTURE_CODE,
        });

        return flowContext.isDcStandaloneFlow && flowContext.action !== "standalonedcitinerary"
            ? html`
                  <div class=${tempClassMap}>
                      ${i18next.t("DcStandalone-SidebarInfo1")}
                      <span>${i18next.t("DcStandalone-SidebarInfo2")}</span>
                  </div>
              `
            : "";
    };

    const giftcardBoxTemplate = () => (flowContext.isGiftcardPurchaseFlow ? GiftcardLogoTemplate() : "");

    const bookingFlowBreadcrumbsTemplate = () => html` <ac-breadcrumbs-booking></ac-breadcrumbs-booking> `;

    const bookingDetailsTemplate = () =>
        !flowContext.isGiftcardPurchaseFlow || flowContext.action !== "select"
            ? html`
                  ${journeySegments.htmlTemplate()} ${uncommittedChapterTemplate()} ${committedChapterTemplate()}
                  ${argentinianMessageTemplate()} ${vatNoteTemplate()}
              `
            : "";

    const vatNoteTemplate = () => html`
        <p class="information-small-content" data-test-id=${T.SIDEBAR.TAX_MESSAGE}>
            ${i18next.t("V2-VatNotification")}
        </p>
    `;

    // DEVNOTE There is a desktop version for this in total.ts
    const mobileBookingTotalInSolTemplate = (farelock: ChargeGroupViewModel) => {
        const isNonFarelockFlow =
            ((!farelock || flowContext.isFarelockRoundTwo) && !model?.IsFareLockRequestExpired) ||
            flowContext.isChangeFlow ||
            flowContext.isCheckinFlow;

        const shouldDisplay =
            !(
                userContext.peruCompra.isAdmin ||
                userContext.peruCompra.isMember ||
                bookingContext.isPeruCompraBooking
            ) &&
            currency === USA_DOLLAR_CODE &&
            (isBuyingDcStandaloneInPeru || (model?.TotalInSol && isNonFarelockFlow));

        return shouldDisplay
            ? html`
                  <div class="mobile-sol-template">
                      <div class="row hidden-md-up">
                          <div class="col-xs-5-7">
                              <span> ${i18next.t("PriceDisplay-TotalInSol")} </span>
                          </div>
                          <div class="col-xs-2-7">
                              <span class="pull-left whitespace-nowrap">
                                  ${PERUVIAN_SOL_CURRENCY_SIGN} ${model?.TotalInSol}
                              </span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const uncommittedChapterTemplate = () => {
        const tempClassMap = classMap({
            "prices-wrapper": true,
            "fare-lock-breakdown": isUncommittedFareLock,
        });

        return model?.BreakdownModel.UncommittedChapter
            ? html`
                  ${uncommittedTotal.htmlTemplate()}

                  <div class=${tempClassMap} data-test-id=${T.SIDEBAR.BREAKDOWN_CONTAINER}>
                      ${!model?.BreakdownModel.CommittedChapter && !flowContext.isGiftcardPurchaseFlow
                          ? iconsTemplate()
                          : ""}
                      ${uncommittedChapterBreakdown.htmlTemplate()}
                  </div>

                  ${mobileBookingTotalInSolTemplate(uncommittedFareLock)}
              `
            : html` ${committedTotal.htmlTemplate()} `;
    };

    const committedChapterTemplate = () => {
        const tempClassMap = classMap({
            "prices-wrapper": true,
            "faded": Boolean(model?.BreakdownModel.UncommittedChapter),
            "fare-lock-breakdown": isCommittedFareLock,
        });

        return model?.BreakdownModel.CommittedChapter
            ? html`
                  <div class=${tempClassMap}>
                      ${!flowContext.isGiftcardPurchaseFlow ? iconsTemplate() : ""}
                      ${committedChapterBreakdown.htmlTemplate()}
                  </div>
                  ${!model?.BreakdownModel.UncommittedChapter
                      ? html`${mobileBookingTotalInSolTemplate(uncommittedFareLock)}`
                      : ""}
              `
            : "";
    };

    const iconsTemplate = () =>
        model?.PaxNumber > 0
            ? html`
                  <p
                      class="bottom-border price-bold"
                      data-test-id=${T.SIDEBAR.PAX_COUNT}
                      data-test-value=${model?.PaxNumber}
                  >
                      <i class="far fa-user"></i> ${i18next.t("PriceDisplay-PassengersSectionTitle")}:
                      ${model?.PaxNumber}
                  </p>
              `
            : "";

    const argentinianMessageTemplate = () =>
        model?.ShowArgentinianMessage
            ? html`
                  <p
                      @click=${handleArgentinianDcDetailsModalOpen}
                      class="information-small-content argentin-modal-link"
                  >
                      ${i18next.t("V2-ArgentinianFlightNotification")}
                  </p>
              `
            : "";

    const promoCodeBoxTemplate = () => html` <ac-promo-code-box .model=${model}> </ac-promo-code-box> `;

    const sidebarScrollerTemplate = () => html`
        <div class="sidebar-scroller ${SIDEBAR_SCROLLER_CLASS_NAME}">
            <section class="sidebar-content ts-error-container" data-test-id=${T.SIDEBAR.CONTENT_CONTAINER}>
                ${promoCodeBoxTemplate()} ${dcStandaloneBoxTemplate()} ${giftcardBoxTemplate()}
                ${bookingFlowBreadcrumbsTemplate()} ${bookingDetailsTemplate()}
            </section>
        </div>
    `;

    const wrapperClassMap = classMap({
        "sidebar-wrapper": true,
        "no-print": true,
        "opened": isOpen,
    });

    return html`
        <div class=${wrapperClassMap}>
            <div class="sidebar-container ts-sidebar-container">
                <div ref=${ref(root)}>
                    ${mobileOpenerTemplate()} ${mobileTotalTemplate()} ${sidebarScrollerTemplate()}
                </div>
            </div>
        </div>
        ${argentinaPriceModal.htmlTemplate()}
    `;
};

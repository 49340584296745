import { useEffect, useMemo } from "../../../../shared/haunted/CustomHooks";
import {
    ARGENTINIAN_PESO_CODE,
    BRASIL_REAL_CODE,
    COLOMBIAN_PESO_CODE,
    USA_DOLLAR_CODE,
} from "../../../../shared/commonConstants";
import { FlexiFeeJourneyModel } from "../../../../component-models/extras/flexi-fee/FlexiFeeJourneyModel";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { FlexiFeeModel } from "../../../../component-models/extras/flexi-fee/FlexiFeeModel";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import {
    desktopJourneyRoutesTemplate,
    journeyBundleInfoTemplate,
} from "../../../../common-templates/ExtrasCommonTemplates";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../../managers/useAppContext";
import { usePubSub } from "../../../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";

export interface Props {
    model: FlexiFeeModel;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const useFlexiFee = (props: Props) => {
    const init = () => {
        if (appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")) {
            triggers.sidebar.bookingChanged.publish({});
        }
    };

    const displayedJourneyPrice = (journey: FlexiFeeJourneyModel) => {
        if (!appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")) {
            return journey.FormattedAmount;
        }

        let amount;

        switch (currency) {
            case USA_DOLLAR_CODE:
                amount = 6.9;
                break;
            case BRASIL_REAL_CODE:
                amount = 29.91;
                break;
            case COLOMBIAN_PESO_CODE:
                amount = 23793;
                break;
            case ARGENTINIAN_PESO_CODE:
                amount = 350;
                break;
            default:
                amount = 5000;
                break;
        }

        return formatNumber({ amount, leadingSign: true });
    };

    // COMPONENT

    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const showTemplate = useMemo(() => !props.model?.Journeys.every((j) => j.IsInBundle), [props.model]);

    const { triggers } = usePubSub();
    const { formatNumber } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    useEffect(init, [props.model?.Journeys?.[0].IsInBundle]);

    // TEMPLATES

    const bundleInfoContentTemplate = (journey: FlexiFeeJourneyModel) =>
        journey.IsInBundle
            ? journeyBundleInfoTemplate(
                  journey.BundleColor,
                  journey.BundleImg,
                  T.EXTRAS_FLEXI_FEE.JOURNEY_BUNDLE,
                  undefined,
                  journey.JourneyIndex,
                  false,
                  bookingContext.isGdsBooking,
              )
            : "";

    const newOptionalRibbonTemplate = () =>
        !appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
            ? html`
                  <div class="new-optional-label">
                      <span>${i18next.t("V2-NewOptional")}</span>
                      <span>${i18next.t("V2-NewOptionalFlexiInfo")}</span>
                  </div>
              `
            : "";

    const forcedSubtitleTemplate = () =>
        appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
            ? html`
                  <div class="subtitle pull-up" data-test-id=${T.EXTRAS_FLEXI_FEE.SUBTITLE}>
                      ${unsafeHTML(
                          i18next.t("V2-ForcedFlexiFeeInfo {{-spanStart}} {{-spanEnd}}", {
                              spanStart: '<br /><span class="footnote">',
                              spanEnd: "</span>",
                          }),
                      )}
                  </div>
              `
            : "";

    const unForcedSubtitleTemplate = () =>
        !appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
            ? html`
                  <div class="subtitle pull-up short" data-test-id=${T.EXTRAS_FLEXI_FEE.SUBTITLE}>
                      ${unsafeHTML(
                          i18next.t("V2-FlexiFeeInfo {{-spanStart}} {{-spanEnd}}", {
                              spanStart: '<br /><span class="footnote">',
                              spanEnd: "</span>",
                          }),
                      )}
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <header>
            <i class="js-flexi-fee js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_FLEXI_FEE.TITLE}>${i18next.t("V2-FlexiFee")}</h2>
                ${forcedSubtitleTemplate()} ${unForcedSubtitleTemplate()}
            </div>
        </header>
    `;

    const desktopBundleHeadersTemplate = () =>
        props.model?.Journeys.map((journey) => {
            const tempClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.model?.Journeys.length === 1,
            });

            return html` <div class=${tempClassMap}>${bundleInfoContentTemplate(journey)}</div> `;
        });

    const journeysTemplate = () =>
        props.model?.Journeys.map((journey) => {
            const tempClassMap = classMap({
                "col-xs-1": true,
                "col-sm-1-2": true,
                "col-sm-offset-1-4": props.model?.Journeys.length === 1,
            });

            return html`
                <div class=${tempClassMap}>
                    <ac-flexi-fee
                        .journey=${journey}
                        .isFlexiFeeForced=${appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")}
                        .isSelected=${journey.IsSold || journey.IsInBundle}
                        .displayedPrice=${displayedJourneyPrice(journey)}
                        .setExtrasModel=${props.setExtrasModel}
                    >
                    </ac-flexi-fee>
                </div>
            `;
        });

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "booking-wrapper": true,
            "ts-flexi-fee": true,
            "extras-step": true,
            "ts-error-container": true,
            "forced-flexi-fee": appContext.isFeatureSwitchActive("ForceFlexiFeeForAll"),
        });

        return showTemplate
            ? html`
                  <section class=${mainClassMap} data-test-id=${T.EXTRAS_FLEXI_FEE.CONTAINER}>
                      ${newOptionalRibbonTemplate()}

                      <div class="ts-error-container">
                          ${headerTemplate()}

                          <div class="row hidden-xs">${desktopBundleHeadersTemplate()}</div>

                          <div class="row hidden-xs">${desktopJourneyRoutesTemplate(props.model?.Journeys)}</div>

                          <div class="row">${journeysTemplate()}</div>
                      </div>
                  </section>
              `
            : "";
    };

    return { htmlTemplate };
};

import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "haunted";
import {
    BreakdownViewModel,
    BreakdownChapter,
    ChargeGroupViewModel,
    PassengerPriceDisplayViewModel,
} from "../../component-models/sidebar/BreakdownViewModel";
import { classMap } from "lit-html/directives/class-map";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    chapter: BreakdownChapter;
    model: BreakdownViewModel;
    nonInfantPaxNumber: number;
    paxNumber: number;
}

export const useBreakdown = (props: Props) => {
    // Helpers

    const getGenericPaxName = (index: number) => {
        const retVal = props.chapter.PerPassengerChargeCollection.find((p) => p.PassengerNumber === index)?.DisplayName;
        return retVal ? retVal : i18next.t("V2-PassengerLabel") + " " + index + 1;
    };

    // Event handlers

    const handleBundleOpenerChange = (e: MouseEvent) => {
        (e.currentTarget as HTMLLabelElement).classList.toggle("open");

        const labelForAttribute = (e.currentTarget as HTMLLabelElement).htmlFor;
        const pricesContainerForLabel: HTMLDivElement = document.getElementById(labelForAttribute) as HTMLDivElement;
        pricesContainerForLabel.classList.toggle("open");
    };

    // Component

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");
    const [passengerNames] = useReduxState("booking.passengerNames");

    // Templates

    const bundleContainerClassMap = classMap({
        "bd-bundle-container": true,
        "open": !(
            userContext.peruCompra.isAdmin ||
            userContext.peruCompra.isMember ||
            bookingContext.isPeruCompraBooking
        ),
    });

    const bundleChargeGroupTemplate = (chargeGroups: ChargeGroupViewModel[], paxIndex: number) =>
        chargeGroups.map((item, index) => {
            const labelIndex = `bd_opener_${paxIndex}_${index}`;

            const dataTestId = getTestId(T.SIDEBAR.BUNDLE, { p: paxIndex, j: index });

            return html`
                <ac-breakdown-line
                    .chargeCode=${""}
                    .dataTestIdName=${getTestId(T.SIDEBAR.BUNDLE_NAME, { p: paxIndex, j: index })}
                    .dataTestIdPrice=${getTestId(T.SIDEBAR.BUNDLE_PRICE, { p: paxIndex, j: index })}
                    .displayName=${item.DisplayName}
                    .inTotal=${true}
                    .index=${index}
                    .labelIndex=${labelIndex}
                    .paxNumber=${paxIndex}
                    .price=${item.FormattedPrice}
                    .unformattedPrice=${item.Price}
                    .handleBundleOpenerChange=${handleBundleOpenerChange}
                ></ac-breakdown-line>

                <div class=${bundleContainerClassMap} id=${labelIndex} data-test-id=${dataTestId}>
                    ${item.Charges.map(
                        (line, lineIndex) => html`
                            <ac-breakdown-line
                                .chargeCode=${line.Code}
                                .count=${line.Count}
                                .displayName=${line.DisplayName}
                                .price=${line.FormattedPrice}
                                .unformattedPrice=${line.Price}
                                .dataTestIdName=${getTestId(T.SIDEBAR.BUNDLE_ITEM_NAME, {
                                    p: paxIndex,
                                    j: index,
                                    i: lineIndex,
                                    c: line.Code,
                                })}
                                .dataTestIdPrice=${getTestId(T.SIDEBAR.BUNDLE_ITEM_PRICE, {
                                    p: paxIndex,
                                    j: index,
                                    i: lineIndex,
                                    c: line.Code,
                                })}
                            ></ac-breakdown-line>
                        `,
                    )}
                </div>
            `;
        });

    const tariffRelatedChargeGroupTemplate = (chargeGroup: ChargeGroupViewModel, paxIndex: number) => html`
        <ac-breakdown-line
            .chargeCode=${""}
            .dataTestIdName=${T.SIDEBAR.FARES}
            .dataTestIdPrice=${T.SIDEBAR.FARES_PRICE}
            .displayName=${chargeGroup.DisplayName}
            .inTotal=${true}
            .index=${0}
            .paxNumber=${paxIndex}
            .price=${chargeGroup.FormattedPrice}
            .unformattedPrice=${chargeGroup.Price}
        ></ac-breakdown-line>
        ${chargeGroup.Charges.map(
            (line, lineIndex) => html`
                <ac-breakdown-line
                    .chargeCode=${line.Code}
                    .displayName=${line.DisplayName}
                    .price=${line.FormattedPrice}
                    .unformattedPrice=${line.Price}
                    .dataTestIdName=${getTestId(T.SIDEBAR.FARES_ITEM, { p: paxIndex, i: lineIndex, c: line.Code })}
                    .dataTestIdPrice=${getTestId(T.SIDEBAR.FARES_ITEM_PRICE, {
                        p: paxIndex,
                        i: lineIndex,
                        c: line.Code,
                    })}
                ></ac-breakdown-line>
            `,
        )}
    `;

    const paxTemplate = () =>
        props.chapter.PerPassengerChargeCollection.map((passenger, index) => {
            const dataTestId = getTestId(T.SIDEBAR.PASSENGER, { i: index });

            return html`
                <div class="js-accordion" data-test-id=${dataTestId}>
                    <div class="tab">
                        ${paxNameTemplate(passenger, index)}
                        <div class="${!flowContext.isGiftcardPurchaseFlow ? "tab-content" : ""} bottom-border-inner">
                            ${mainChargeGroupsTemplate(passenger)} ${optionalChargeGroupTemplate(passenger)}
                            ${separateChargeGroupsTemplate(passenger)} ${passengerTotalTemplate(passenger)}
                        </div>
                    </div>
                </div>
            `;
        });

    const paxNameTemplate = (passenger: PassengerPriceDisplayViewModel, index: number) => {
        const id = `tab-price-${passenger.PassengerNumber}`;
        const dataTestId = getTestId(T.SIDEBAR.PASSENGER_NAME, { p: index });

        return !flowContext.isGiftcardPurchaseFlow
            ? html`
                  <input id=${id} type="checkbox" name="tabs" checked />
                  <label for=${id}>
                      <span class="price-bold" data-test-id=${dataTestId}>
                          ${passengerNames
                              ? passengerNames[passenger.PassengerNumber]?.trim() ||
                                getGenericPaxName(passenger.PassengerNumber)
                              : getGenericPaxName(passenger.PassengerNumber)}
                      </span>
                      <i class="fas fa-angle-down icon-middle sidebar-details-open-icon float-right"></i>
                  </label>
              `
            : "";
    };

    const mainChargeGroupsTemplate = (passenger: PassengerPriceDisplayViewModel) =>
        props.chapter.StartWithBundleChargeGroup
            ? html`
                  ${passenger.BundleChargeGroups
                      ? bundleChargeGroupTemplate(passenger.BundleChargeGroups, passenger.PassengerNumber)
                      : ""}
                  ${passenger.TariffRelatedChargeGroup
                      ? tariffRelatedChargeGroupTemplate(passenger.TariffRelatedChargeGroup, passenger.PassengerNumber)
                      : ""}
              `
            : html`
                  ${passenger.TariffRelatedChargeGroup
                      ? tariffRelatedChargeGroupTemplate(passenger.TariffRelatedChargeGroup, passenger.PassengerNumber)
                      : ""}
                  ${passenger.BundleChargeGroups
                      ? bundleChargeGroupTemplate(passenger.BundleChargeGroups, passenger.PassengerNumber)
                      : ""}
              `;

    const optionalChargeGroupTemplate = (passenger: PassengerPriceDisplayViewModel) =>
        passenger.OptionalChargeGroup
            ? html`
                  ${!flowContext.isGiftcardPurchaseFlow
                      ? html`
                            <ac-breakdown-line
                                .chargeCode=${""}
                                .displayName=${passenger.OptionalChargeGroup.DisplayName}
                                .inTotal=${true}
                                .price=${passenger.OptionalChargeGroup.FormattedPrice}
                                .showGiftcard=${true}
                                .unformattedPrice=${passenger.OptionalChargeGroup.Price}
                                .dataTestIdName=${getTestId(T.SIDEBAR.OPTIONALS, {
                                    p: passenger.PassengerNumber,
                                })}
                                .dataTestIdPrice=${getTestId(T.SIDEBAR.OPTIONALS_PRICE, {
                                    p: passenger.PassengerNumber,
                                })}
                                .index=${0}
                                .paxNumber=${passenger.PassengerNumber}
                            ></ac-breakdown-line>
                        `
                      : ""}
                  ${passenger.OptionalChargeGroup.Charges.map(
                      (line, lineIndex) => html`
                          <ac-breakdown-line
                              .chargeCode=${line.Code}
                              .count=${line.Count}
                              .displayName=${line.DisplayName}
                              .price=${line.FormattedPrice}
                              .showGiftcard=${true}
                              .unformattedPrice=${line.Price}
                              .dataTestIdName=${getTestId(T.SIDEBAR.OPTIONAL_ITEM, {
                                  p: passenger.PassengerNumber,
                                  i: lineIndex,
                                  c: line.Code,
                              })}
                              .dataTestIdPrice=${getTestId(T.SIDEBAR.OPTIONAL_ITEM_PRICE, {
                                  p: passenger.PassengerNumber,
                                  i: lineIndex,
                                  c: line.Code,
                              })}
                          ></ac-breakdown-line>
                      `,
                  )}
              `
            : "";

    const separateChargeGroupsTemplate = (passenger: PassengerPriceDisplayViewModel) =>
        passenger.SeparateChargeGroups
            ? passenger.SeparateChargeGroups.map((line, index) =>
                  line.Charges.length === 1
                      ? html` <ac-breakdown-line
                            .chargeCode=${line.Charges[0].Code}
                            .dataTestIdName=${getTestId(T.SIDEBAR.SEPARATE_CHARGE, { i: index })}
                            .dataTestIdPrice=${getTestId(T.SIDEBAR.SEPARATE_CHARGE_PRICE, { i: index })}
                            .displayName=${line.DisplayName}
                            .inTotal=${true}
                            .index=${index}
                            .paxNumber=${passenger.PassengerNumber}
                            .price=${line.FormattedPrice}
                            .unformattedPrice=${line.Price}
                        ></ac-breakdown-line>`
                      : html`
                            <ac-breakdown-line
                                .chargeCode=${line.Charges[0].Code}
                                .dataTestIdName=${T.SIDEBAR.SEPARATE_CHARGE}
                                .dataTestIdPrice=${T.SIDEBAR.SEPARATE_CHARGE}
                                .displayName=${line.DisplayName}
                                .inTotal=${true}
                                .index=${index}
                                .paxNumber=${passenger.PassengerNumber}
                                .price=${line.FormattedPrice}
                                .unformattedPrice=${line.Price}
                            ></ac-breakdown-line>
                            ${line.Charges.map(
                                (line2, lineIndex) => html`
                                    <ac-breakdown-line
                                        .chargeCode=${line.Charges[0].Code}
                                        .displayName=${line2.DisplayName}
                                        .price=${line2.FormattedPrice}
                                        .unformattedPrice=${line2.Price}
                                        .dataTestIdName=${getTestId(T.SIDEBAR.SEPARATE_CHARGE_ITEM, {
                                            p: passenger.PassengerNumber,
                                            i: lineIndex,
                                            c: line2.Code,
                                        })}
                                        .dataTestIdPrice=${getTestId(T.SIDEBAR.SEPARATE_CHARGE_ITEM_PRICE, {
                                            p: passenger.PassengerNumber,
                                            i: lineIndex,
                                            c: line2.Code,
                                        })}
                                    ></ac-breakdown-line>
                                `,
                            )}
                        `,
              )
            : "";

    const passengerTotalTemplate = (passenger: PassengerPriceDisplayViewModel) =>
        !flowContext.isGiftcardPurchaseFlow
            ? html`
                  <div class="total-row">
                      <ac-breakdown-line
                          .chargeCode=${""}
                          .dataTestIdName=${getTestId(T.SIDEBAR.PAX_TOTAL, { p: passenger.PassengerNumber })}
                          .dataTestIdPrice=${getTestId(T.SIDEBAR.PAX_TOTAL_PRICE, { p: passenger.PassengerNumber })}
                          .displayName=${i18next.t("V2-Total")}
                          .inTotal=${true}
                          .index=${0}
                          .paxNumber=${passenger.PassengerNumber}
                          .price=${passenger.FormattedTotal}
                          .unformattedPrice=${passenger.Total}
                      ></ac-breakdown-line>
                  </div>
              `
            : "";

    const discountClubDiscountTemplate = () =>
        props.chapter.DiscountClubDiscount
            ? html`
                  <div class="tab-content bottom-border-inner last-elem">
                      <ac-breakdown-line
                          .chargeCode=${""}
                          .displayName=${props.model.IsStaffBooking || userContext.isStaff
                              ? i18next.t("Total descuentos")
                              : i18next.t("Total descuentos miembro Club de descuentos")}
                          .dataTestIdName=${T.SIDEBAR.DC_DISCOUNTS}
                          .dataTestIdPrice=${T.SIDEBAR.DC_DISCOUNTS_PRICE}
                          .inTotal=${true}
                          .price=${props.chapter.DiscountClubDiscount.FormattedPrice}
                          .unformattedPrice=${props.chapter.DiscountClubDiscount.Price}
                      ></ac-breakdown-line>
                      ${props.chapter.DiscountClubDiscount.Charges.map(
                          (line, lineIndex) => html`
                              <ac-breakdown-line
                                  .chargeCode=${""}
                                  .displayName=${line.DisplayName}
                                  .price=${line.FormattedPrice}
                                  .unformattedPrice=${line.Price}
                                  .dataTestIdName=${getTestId(T.SIDEBAR.DC_DISCOUNT_ITEM, {
                                      i: lineIndex,
                                      c: line.Code,
                                  })}
                                  .dataTestIdPrice=${getTestId(T.SIDEBAR.DC_DISCOUNT_ITEM_PRICE, {
                                      i: lineIndex,
                                      c: line.Code,
                                  })}
                              ></ac-breakdown-line>
                          `,
                      )}
                  </div>
              `
            : "";

    const promotionDiscountTemplate = () =>
        props.chapter.PromotionDiscount
            ? html`
                  <div class="tab-content bottom-border-inner last-elem">
                      <ac-breakdown-line
                          .chargeCode=${""}
                          .displayName=${userContext.peruCompra.isAdmin ||
                          userContext.peruCompra.isMember ||
                          bookingContext.isPeruCompraBooking
                              ? i18next.t("Descuento (aplica solo en tarifa aérea)")
                              : props.chapter.PromotionDiscount.DisplayName}
                          .dataTestIdName=${T.SIDEBAR.PROMO_DISCOUNTS}
                          .dataTestIdPrice=${T.SIDEBAR.PROMO_DISCOUNTS_PRICE}
                          .inTotal=${true}
                          .price=${props.chapter.PromotionDiscount.FormattedPrice}
                          .unformattedPrice=${props.chapter.PromotionDiscount.Price}
                      ></ac-breakdown-line>
                      ${!(
                          userContext.peruCompra.isAdmin ||
                          userContext.peruCompra.isMember ||
                          bookingContext.isPeruCompraBooking
                      )
                          ? props.chapter.PromotionDiscount.Charges.map(
                                (line, lineIndex) => html`
                                    <ac-breakdown-line
                                        .chargeCode=${""}
                                        .displayName=${line.DisplayName}
                                        .price=${line.FormattedPrice}
                                        .unformattedPrice=${line.Price}
                                        .dataTestIdName=${getTestId(T.SIDEBAR.PROMO_DISCOUNT_ITEM, {
                                            i: lineIndex,
                                            c: line.Code,
                                        })}
                                        .dataTestIdPrice=${getTestId(T.SIDEBAR.PROMO_DISCOUNT_ITEM_PRICE, {
                                            i: lineIndex,
                                            c: line.Code,
                                        })}
                                    ></ac-breakdown-line>
                                `,
                            )
                          : ""}
                  </div>
              `
            : "";

    const bancoEstadoDiscountTemplate = () =>
        props.chapter.BancoEstadoDiscount
            ? html`
                  <div class="tab-content bottom-border-inner last-elem">
                      <div class="banco-estado-breakdown-discount">
                          <ac-breakdown-line
                              .chargeCode=${""}
                              .dataTestIdName=${T.SIDEBAR.BANCO_ESTADO_DISCOUNTS}
                              .dataTestIdPrice=${T.SIDEBAR.BANCO_ESTADO_DISCOUNTS_PRICE}
                              .displayName=${props.chapter.BancoEstadoDiscount.DisplayName}
                              .inTotal=${true}
                              .price=${props.chapter.BancoEstadoDiscount.FormattedPrice}
                              .unformattedPrice=${props.chapter.BancoEstadoDiscount.Price}
                          ></ac-breakdown-line>
                      </div>

                      ${props.chapter.BancoEstadoDiscount.Charges.map(
                          (line, lineIndex) => html`
                              <ac-breakdown-line
                                  .chargeCode=${""}
                                  .displayName=${line.DisplayName}
                                  .price=${line.FormattedPrice}
                                  .unformattedPrice=${line.Price}
                                  .dataTestIdName=${getTestId(T.SIDEBAR.BANCO_ESTADO_DISCOUNT_ITEM, {
                                      i: lineIndex,
                                      c: line.Code,
                                  })}
                                  .dataTestIdPrice=${getTestId(T.SIDEBAR.BANCO_ESTADO_DISCOUNT_ITEM_PRICE, {
                                      i: lineIndex,
                                      c: line.Code,
                                  })}
                              ></ac-breakdown-line>
                          `,
                      )}
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div data-test-id=${T.SIDEBAR.BREAKDOWN}>
            ${paxTemplate()} ${discountClubDiscountTemplate()} ${promotionDiscountTemplate()}
            ${bancoEstadoDiscountTemplate()}
        </div>
    `;

    return { htmlTemplate };
};

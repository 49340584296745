import i18next from "i18next";
import { html } from "lit-html";
import { ApiFlightPageDiscountClubViewModel } from "../../../component-models/flight/api/ApiFlightPageDiscountClubViewModel";

export interface BenefitsProps {
    vm: ApiFlightPageDiscountClubViewModel;
}

export const useBenefitsTemplate = (props: BenefitsProps) => {
    const htmlTemplate = () => html`
        <div class="extend-membership-list">
            <div class="extend-membership-list-line">
                <i class="js-icon js-flight-tick"></i>
                <span>
                    ${i18next.t("Descuento mínimo de {{-amount}} por tramo, para tarifas sobre {{-amountOver}}", {
                        amount: props.vm.DiscountMin,
                        amountOver: props.vm.DiscountOver,
                    })}
                </span>
            </div>
            <div class="extend-membership-list-line">
                <i class="js-icon js-flight-tick"></i>
                <span>
                    ${i18next.t("Descuento mínimo de {{-amount}} en equipaje de mano o facturado", {
                        amount: props.vm.BaggageMin,
                    })}
                </span>
            </div>
            <div class="extend-membership-list-line">
                <i class="js-icon js-flight-tick"></i>
                <span>${i18next.t("Descuentos aplican para ti y acompañantes, según membresía.")}</span>
            </div>
            <div class="extend-membership-list-line">
                <i class="js-icon js-flight-tick"></i>
                <span>${i18next.t("Acceso a promociones especiales")}</span>
            </div>
        </div>
    `;

    return { htmlTemplate };
};

import { html } from "haunted";
import i18next from "i18next";
import { useModal } from "../shared/useModal";

export interface Props {
    bancoEstadoTermsUrl: string;
    formattedAmount: string;
    onYes: () => void;
}

export const useCreditShellModal = (props: Props) => {
    const handleYes = () => {
        modal.close();
        props.onYes();
    };

    const titleTemplate = () => html`
        <div class="payment-modal-title">
            <img class="be-modal-img" src="/Images/BancoEstado/be-logo-w.svg" />
            <span>${i18next.t("BE-CreditShellCaption-1")}</span>
        </div>
    `;

    const contentTemplate = () => html`
        <div class="be-modal-border-box">
            <div class="be-modal-content-box">
                <div class="row">
                    <div class="col-xs-1">
                        <p class="text-center">
                            ${i18next.t("BE-CreditShellModalInfo-1 {{formattedAmount}}", {
                                formattedAmount: props.formattedAmount,
                            })}
                        </p>
                        <p class="be-modal-link text-center">
                            Más información en
                            <a href=${props.bancoEstadoTermsUrl} target="_blank"> Condiciones Generales BancoEstado</a>.
                        </p>
                    </div>
                </div>
                <div class="be-modal-button-container">
                    <button @click=${handleYes} class="rounded-primary-btn">
                        ${i18next.t("BE-CreditShellContinue")}
                    </button>
                </div>
            </div>
        </div>
    `;

    const htmlTemplate = () => html` ${titleTemplate()} ${contentTemplate()} `;

    const modal = useModal({
        overlay: { classNames: "payment-modal" },
        content: { classNames: "payment-modal-content credit-shell-modal", template: htmlTemplate },
        closing: { buttonClassNames: "payment-modal-close", isClosable: true },
    });

    return modal;
};

import { html } from "lit-html";
import { ApiBoardingPassViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassViewModel";
import { MOBILE_BOARDING_PASS_HEIGHT, MOBILE_BOARDING_PASS_WIDTH } from "./useBoardingPassImageCreator";
import classNames from "classnames";
import i18next from "i18next";
import { useOperatorAndStopoverInfo } from "./useOperatorAndStopoverInfo";

export const useMobileBoardingCard = () => {
    const { getOperationAndStopoverInfo } = useOperatorAndStopoverInfo();

    const headerTemplate = (model: ApiBoardingPassViewModel) => html`
        <img class="mx-auto mt-2" width="123" height="30" src="/Images/Header/jetsmart-logo.png" />
        <div class="flex flex-col justify-center gap-1">
            <div class="mt-2 font-news text-xs font-bold leading-none text-[#818a91]">
                ${i18next.t("Pasajero")} / Passenger
            </div>
            <div class="font-news text-base font-bold leading-none text-be-blue">${model.Passenger.PaxName}</div>
        </div>
    `;

    const barcodeTemplate = (model: ApiBoardingPassViewModel) => html`
        <img class="ml-2 block h-[180px] w-[180px]" src=${model.BarcodeImage} />
    `;

    const itineraryHeaderCellTemplate = (internationalLabel: string, englishLabel: string, value: string) => html`
        <div>
            <div class="font-news text-sm text-[#818a91]">${internationalLabel} / ${englishLabel}</div>
            <div class="mb-1 whitespace-nowrap font-news text-base font-bold uppercase text-be-blue">${value}</div>
        </div>
    `;

    const itineraryHeaderTemplate = (model: ApiBoardingPassViewModel) => html`
        <div class="flex w-1/2 flex-col pr-5">
            ${itineraryHeaderCellTemplate(i18next.t("Código de reserva"), "Booking code", model.Header.Pnr)}
            ${itineraryHeaderCellTemplate(i18next.t("Vuelo"), "Flight", model.Header.FlightNumber)}
            ${itineraryHeaderCellTemplate(i18next.t("Fecha"), "Date", model.Header.DepartureDate)}
        </div>
    `;

    const endpointTemplate = (model: ApiBoardingPassViewModel, type: "origin" | "destination") => {
        const endpoint = type === "origin" ? model.Itinerary.Departure : model.Itinerary.Arrival;

        return html`
            <div class=${classNames("flex w-2/5 flex-col", { "items-end": type === "destination" })}>
                <div class="mb-2 whitespace-nowrap font-news text-sm leading-none text-[#818a91]">
                    ${type === "origin" ? `${i18next.t("Origen")} / Origin` : `${i18next.t("Destino")} / Destination`}
                </div>
                <div class="font-news text-3xl font-bold leading-none text-[#0a355e]">${endpoint.StationCode}</div>
                <div class="font-news text-lg font-bold leading-none text-[#0a355e]">${endpoint.StationName}</div>
                <div class="py-1 font-news text-xs font-light text-[#818a91]">${endpoint.AirportName}</div>
                <div class="font-news text-lg font-bold leading-none text-[#0a355e]">${endpoint.Time}</div>
            </div>
        `;
    };

    const itineraryTemplate = (model: ApiBoardingPassViewModel) => html`
        ${endpointTemplate(model, "origin")}
        <img class="h-10 w-10" src="/Images/Icons/boarding-card/plane.png" />
        ${endpointTemplate(model, "destination")}
    `;

    const tableHeaderTemplate = (internationalLabel: string, englishLabel: string, isRed = false) => {
        const tempClassNames = classNames(
            "flex h-7 flex-col items-center justify-center text-center font-body uppercase text-white",
            {
                "bg-be-red-2": isRed,
                "bg-be-blue": !isRed,
            },
        );

        return html`
            <div class=${tempClassNames}>
                <span class="relative top-[-2px] text-[10px] leading-none">${internationalLabel}</span>
                <span class="relative top-[-1px] text-[10px] leading-none">${englishLabel}</span>
            </div>
        `;
    };

    const cellTemplate = (content: string, isRed = false) => {
        const tempClassNames = classNames(
            "flex h-8 flex-col items-center justify-center text-center font-body text-sm font-black uppercase",
            {
                "text-be-blue": !isRed,
                "text-new-ui-red": isRed,
            },
        );

        return html` <div class=${tempClassNames}>${content}</div> `;
    };

    const tablesTemplate = (model: ApiBoardingPassViewModel) => html`
        <div class="grid w-3/4 grid-cols-4 overflow-hidden rounded-md border border-solid border-be-blue">
            ${tableHeaderTemplate(i18next.t("Embarque"), "Boarding")}
            ${tableHeaderTemplate(i18next.t("Terminal"), "Terminal")}
            ${tableHeaderTemplate(`${i18next.t("Puerta")}*`, "Gate*")}
            ${tableHeaderTemplate(i18next.t("Asiento"), "Seat")} ${cellTemplate(model.Details.BoardingTime)}
            ${cellTemplate(model.Details.Terminal)} ${cellTemplate(model.Details.Gate)}
            ${cellTemplate(
                model.Details.IsEmergencyRow ? `${model.Details.Seat} *` : model.Details.Seat,
                model.Details.IsEmergencyRow,
            )}
        </div>
        <div class="grid w-1/4 grid-cols-2 overflow-hidden rounded-md border border-solid border-be-red-2">
            ${tableHeaderTemplate(i18next.t("Seq"), "Seq", true)}
            ${tableHeaderTemplate(i18next.t("Zona"), "Zone", true)} ${cellTemplate(model.Details.Sequence)}
            ${cellTemplate(model.Details.Zone)}
        </div>
    `;

    const footnotesTemplate = (model: ApiBoardingPassViewModel) => html`
        ${infantTemplate(model)}
        <div class="relative z-10 flex">
            <img class="mr-2 block h-[33px] w-[33px] flex-1" src="/Images/Icons/boarding-card/exclamation-dt.png" />
            <div>
                <div class="text-justify font-news text-xs text-[#818a91]">
                    *${i18next.t("GateWarning1 {{hours}}", { hours: model.ShowUpTime })}
                </div>
            </div>
        </div>
        <div class="relative z-10 pt-1 text-justify font-news text-[10px] leading-4">
            <span class="text-be-blue">${i18next.t("¿Pagaste tu equipaje?")}</span>
            <span class="text-[#818a91]">
                ${i18next.t(
                    "Tu tarifa SMART incluye mochila o artículo personal (peso máx. 10kg / dimensiones 45 cm x 35 cm x 25 cm) y asiento  estándar. Si llevas equipaje y no lo has pagado te recomendamos comprarlo ahora en JetSMART.com, de lo contrario, en aeropuerto será más caro. ",
                )}
            </span>
        </div>
    `;

    const infantTemplate = (model: ApiBoardingPassViewModel) =>
        model.HasInfant
            ? html`
                  <div
                      class="absolute inset-0 -z-10 flex items-center justify-center font-body text-[180px] font-semibold leading-none tracking-wider text-cug-gray-4"
                  >
                      INF
                  </div>
              `
            : "";

    const ssrTemplate = (ssr: string, smaller: boolean) => {
        const tempClassNames = classNames(
            "flex h-full min-h-[18px] w-full items-center justify-center rounded-sm bg-be-blue py-1 font-body font-bold leading-none text-white",
            {
                "text-xs": !smaller,
                "text-[10px]": smaller,
            },
        );

        return html`<div class=${tempClassNames}>${ssr}</div>`;
    };

    const ssrsTemplate = (model: ApiBoardingPassViewModel) =>
        model.Ssrs.map((ssr) => ssrTemplate(ssr, model.Ssrs.length > 6));

    const itineraryExtraInfoLineTemplate = (line: string) => html`
        <div class="font-extra text-[10px] font-normal leading-snug text-[#0a355e]">${line}</div>
    `;

    const itineraryExtraInfoTemplate = (model: ApiBoardingPassViewModel) =>
        getOperationAndStopoverInfo({
            arrivalStationCode: model.Itinerary.Arrival.StationCode,
            isMobile: true,
            model: model.Itinerary.Stops,
        }).map(itineraryExtraInfoLineTemplate);

    const renderMobileBoardingCard = (model: ApiBoardingPassViewModel) =>
        model
            ? html`
                  <div
                      class="h-[400px] w-[700px] rounded-2xl border border-solid border-be-blue bg-white"
                      style=${`width: ${MOBILE_BOARDING_PASS_WIDTH}px; height: ${MOBILE_BOARDING_PASS_HEIGHT}px;`}
                  >
                      <div class="flex h-24 flex-col border-b-4 border-solid border-be-red-2 px-5">
                          ${headerTemplate(model)}
                      </div>
                      <div class="flex items-center">${barcodeTemplate(model)} ${itineraryHeaderTemplate(model)}</div>
                      <div class="flex w-full items-center justify-between px-5 pb-1">${itineraryTemplate(model)}</div>
                      <div class="px-5 pb-1">${itineraryExtraInfoTemplate(model)}</div>
                      <div class="flex w-full gap-2 px-5 pb-1">${tablesTemplate(model)}</div>
                      <div class="relative z-0 w-full px-5 pb-1">${footnotesTemplate(model)}</div>
                      <div class="relative z-10 grid w-full auto-rows-min grid-cols-6 gap-1 px-5">
                          ${ssrsTemplate(model)}
                      </div>
                  </div>
              `
            : html``;

    return { renderMobileBoardingCard };
};

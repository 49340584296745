import { BundleHelper } from "../../../component-helpers/flight/BundleHelper";
import { BundleContext } from "../../../component-models/flight/contexts/BundleContext";
import {
    BundleType,
    CustomizedBundle,
    CustomizedBundleOffers,
} from "../../../component-models/flight/BundleOffersV2Model";
import { ExtendedTripModel } from "../../../component-models/flight/ExtendedTripModel";
import { FlightPageBundleState } from "../../../component-models/flight/contexts/FlightPageBundleState";
import { FlightPageFlightState } from "../../../component-models/flight/contexts/FlightPageFlightState";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { useAppContext } from "../../../managers/useAppContext";
import { clone, showLoader } from "../../../shared/common";
import { OUTBOUND, INBOUND } from "../../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { BundlesMode, SelectedFlightFeeType } from "../flight-page";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { commonDebug } from "../../../bootstrap";
import { useCallback } from "haunted";
import { useFlightHttpContextHandler } from "../useFlightHttpContextHandler";
import { useFlightPageAjaxHandler } from "../useFlightPageAjaxHandler";
import { UserContext } from "../../../component-models/app/UserContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { bundleSsrHelper } from "../../../component-helpers/flight/BundleSsrHelper";
import { allPossibleBundleSsrs } from "../../../component-models/AllPossibleBundleSsrs";
import { useBookingContext } from "../../../managers/useBookingContext";

export interface BundleContextProps {
    model: FlightPageViewModel;
    onChange: () => void;
}

export const useBundleContext = (props: BundleContextProps): BundleContext => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const { getCustomizedBundleOffers } = useFlightPageAjaxHandler();
    const { isSsrInBundle } = bundleSsrHelper();

    const [state, setState] = useState<FlightPageBundleState>(undefined);

    const { getPreselectedSellKeys, getPreselectedBundleCodes } = useFlightHttpContextHandler({
        model: props.model,
    });

    const areAllBundlesSelected = useMemo(
        () =>
            Boolean(
                state?.selectedOutboundBundle &&
                    (state?.selectedInboundBundle || props.model.FlightDataViewModel.IsOneWay),
            ),
        [state?.selectedInboundBundle, state?.selectedOutboundBundle, props.model.FlightDataViewModel.IsOneWay],
    );

    const isSsrIncluded = useCallback(
        (bundle: CustomizedBundle, ssrCodes: string[]) => {
            const isPeruCompra =
                bookingContext?.isPeruCompraBooking ||
                userContext?.peruCompra.isAdmin ||
                userContext?.peruCompra.isMember;

            return isSsrInBundle(
                bundle,
                ssrCodes,
                userContext?.bancoEstado.category,
                appContext.isFeatureSwitchActive("PeruCompraFreeAirportCheckin") && isPeruCompra,
            );
        },
        [userContext?.bancoEstado.category, appContext, bookingContext],
    );

    const ssrIdsInOrder = useMemo(() => {
        if (!userContext?.userRole || !state?.bundleOffers?.length) return [];

        const noBundle = state.bundleOffers
            .find((bo) => bo.NormalOffers)
            ?.NormalOffers.find((o) => o.BundleType === "None");
        const smartBundle = state.bundleOffers
            .find((bo) => bo.NormalOffers)
            ?.NormalOffers.find((o) => o.BundleType === "Simple");

        if (!noBundle || !smartBundle) return [];

        const allPossibleSsrs = allPossibleBundleSsrs(
            appContext.isFeatureSwitchActive("ForceFlexiFeeForAll"),
            state?.concatenateBags,
        );

        const ssrsInNoBundle = allPossibleSsrs.filter((s) => isSsrIncluded(noBundle, s.SsrCodes));
        const ssrsInSmartBundle = allPossibleSsrs.filter(
            (s) => !isSsrIncluded(noBundle, s.SsrCodes) && isSsrIncluded(smartBundle, s.SsrCodes),
        );
        const ssrsInFullBundle = allPossibleSsrs.filter(
            (s) => !isSsrIncluded(noBundle, s.SsrCodes) && !isSsrIncluded(smartBundle, s.SsrCodes),
        );

        return [...ssrsInNoBundle, ...ssrsInSmartBundle, ...ssrsInFullBundle].map((s) => s.Id);
    }, [userContext?.userRole, state?.bundleOffers, appContext, isSsrIncluded]);

    const init = async () => {
        const newState: FlightPageBundleState = {
            bundleOffers: [],
            bundlesMode: "Legacy",
            concatenateBags: false,
            selectedInboundBundle: undefined,
            selectedOutboundBundle: undefined,
        };

        const { customizedBundleOffers, shouldConcatenateBagSsrsInBundles, bundlesMode } = await updateBundles({
            currentBundlesMode: props.model.BundleViewModel.BundlesMode as BundlesMode,
            newUserContext: userContext,
        });

        const { selectedOutboundBundle, selectedInboundBundle } = preselectBundles(customizedBundleOffers);

        newState.bundleOffers = customizedBundleOffers;
        newState.concatenateBags = shouldConcatenateBagSsrsInBundles;
        newState.bundlesMode = bundlesMode;
        newState.selectedOutboundBundle = selectedOutboundBundle;
        newState.selectedInboundBundle = selectedInboundBundle;

        setState(newState);
    };

    // HELPERS

    const preselectBundle = (data: {
        updatedBundleOffers: CustomizedBundleOffers[];
        sellKey: string;
        bundleCode: string;
    }): CustomizedBundle => {
        let bundle;

        if (!data.sellKey) {
            return bundle;
        }

        // DEVNOTE The sell keys are changing when we change the currency, that is why we substr
        const bundleSet = data.updatedBundleOffers.find((b) => b.SellKey.substr(-45) === data.sellKey.substr(-45));

        if (bundleSet) {
            bundle = [...bundleSet.NormalOffers, ...bundleSet.DCOffers].find((b) => b.BundleCode === data.bundleCode);
        }

        return bundle;
    };

    const preselectBundles = (bundles: CustomizedBundleOffers[]) => {
        const [outboundSellKey, inboundSellKey] = getPreselectedSellKeys();
        const [outboundBundleCode, inboundBundleCode] = getPreselectedBundleCodes();

        let selectedOutboundBundle;
        let selectedInboundBundle;

        const outboundBundle = preselectBundle({
            updatedBundleOffers: bundles,
            sellKey: outboundSellKey,
            bundleCode: outboundBundleCode,
        });
        const inboundBundle = preselectBundle({
            updatedBundleOffers: bundles,
            sellKey: inboundSellKey,
            bundleCode: inboundBundleCode,
        });

        if (outboundBundle) {
            selectedOutboundBundle = outboundBundle;
        }

        if (inboundBundle) {
            selectedInboundBundle = inboundBundle;
        }

        return { selectedOutboundBundle, selectedInboundBundle };
    };

    const isNotEnoughBundles = (bundles: CustomizedBundleOffers[], userData: UserContext = userContext) => {
        const notEnoughNormalNone = bundles.find((bundle) => !bundle.NormalOffers.some((o) => o.BundleType === "None"));
        const notEnoughNormalSimple = bundles.find(
            (bundle) => !bundle.NormalOffers.some((o) => o.BundleType === "Simple"),
        );
        const notEnoughNormalFull = bundles.find((bundle) => !bundle.NormalOffers.some((o) => o.BundleType === "Full"));
        const notEnoughDcNone = bundles.find((bundle) => !bundle.DCOffers.some((o) => o.BundleType === "None"));
        const notEnoughDcSimple = bundles.find((bundle) => !bundle.DCOffers.some((o) => o.BundleType === "Simple"));
        const notEnoughDcFull = bundles.find((bundle) => !bundle.DCOffers.some((o) => o.BundleType === "Full"));

        const isDcNeeded =
            !userData?.cug.isMember &&
            !userData?.peruCompra.isAdmin &&
            !userData?.peruCompra.isMember &&
            !userData?.isStaff;

        if (notEnoughNormalNone) {
            commonDebug.error(`No normal none bundle for ${notEnoughNormalNone.SellKey}`);
            return true;
        }

        if (notEnoughNormalSimple) {
            commonDebug.error(`No normal simple bundle for ${notEnoughNormalSimple.SellKey}`);
            return true;
        }

        if (notEnoughNormalFull) {
            commonDebug.error(`No normal full bundle for ${notEnoughNormalFull.SellKey}`);
            return true;
        }

        if (isDcNeeded && notEnoughDcNone) {
            commonDebug.error(`No dc none bundle for ${notEnoughDcNone.SellKey}`);
            return true;
        }

        if (isDcNeeded && notEnoughDcSimple) {
            commonDebug.error(`No dc simple bundle for ${notEnoughDcSimple.SellKey}`);
            return true;
        }

        if (isDcNeeded && notEnoughDcFull) {
            commonDebug.error(`No dc full bundle for ${notEnoughDcFull.SellKey}`);
            return true;
        }

        return false;
    };

    const initBundlesMode = (data: {
        bundles: CustomizedBundleOffers[];
        bundlesMode: string;
        newUserContext?: UserContext;
    }): BundlesMode => {
        if (isNotEnoughBundles(data.bundles, data.newUserContext)) {
            commonDebug.error("Not enough bundles.");
            return "Legacy";
        }

        if (userContext.isStaff) {
            return "Legacy";
        }

        switch (data.bundlesMode) {
            case "Legacy":
                return "Legacy";
            case "PerLeg":
                return "PerLeg";
            default:
                throw new Error("Invalid Bundles Mode.");
        }
    };

    const updateBundles = async (data: { currentBundlesMode: BundlesMode; newUserContext?: UserContext }) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });

        const { customizedBundleOffers, shouldConcatenateBagSsrsInBundles } = await getCustomizedBundleOffers(
            loader,
            props.model.Journeys,
            appContext.Culture,
        );

        const bundlesMode = initBundlesMode({
            bundles: customizedBundleOffers,
            bundlesMode: data.currentBundlesMode,
            newUserContext: data.newUserContext,
        });

        return { customizedBundleOffers, shouldConcatenateBagSsrsInBundles, bundlesMode };
    };

    const getSelectedBundle = (data: {
        journeyIndex: number;
        selectedFlightFeeType: SelectedFlightFeeType;
        sellKey: string;
        tooManyPax: boolean;
        type: BundleType;
    }) => {
        const bundleSet = state.bundleOffers.find((bs) => bs.SellKey === data.sellKey);
        const offers =
            data.selectedFlightFeeType === "Smart" || data.tooManyPax ? bundleSet.NormalOffers : bundleSet.DCOffers;
        const newBundle = offers.find((o) => o.BundleType === data.type);

        const isLast =
            (data.journeyIndex === OUTBOUND &&
                (props.model.FlightDataViewModel.IsOneWay || Boolean(state.selectedInboundBundle))) ||
            (data.journeyIndex === INBOUND && Boolean(state.selectedOutboundBundle));

        return { newBundle, isLast };
    };

    const tryToGetSelectedBundlesAfterLogin = (
        bundleState: FlightPageBundleState,
        flightState: FlightPageFlightState,
    ) => {
        const selectedOutboundBundle = preselectBundle({
            updatedBundleOffers: bundleState.bundleOffers,
            sellKey: flightState.selectedOutboundFlight?.SellKey,
            bundleCode: bundleState.selectedOutboundBundle?.BundleCode,
        });

        const selectedInboundBundle = preselectBundle({
            updatedBundleOffers: bundleState.bundleOffers,
            sellKey: flightState.selectedInboundFlight?.SellKey,
            bundleCode: bundleState.selectedInboundBundle?.BundleCode,
        });

        return { selectedOutboundBundle, selectedInboundBundle };
    };

    const getNewBundlesOnMembershipChange = (data: {
        type: "upgrade" | "downgrade";
        bundleState: FlightPageBundleState;
        outboundSellKey: string;
        inboundSellKey: string;
    }): CustomizedBundle[] => {
        let newOutboundBundle;
        const currentOutboundType = data.bundleState.selectedOutboundBundle?.BundleType;
        const outboundBundleSet = currentOutboundType
            ? data.bundleState.bundleOffers.find((b) => b.SellKey === data.outboundSellKey)
            : undefined;

        if (outboundBundleSet) {
            newOutboundBundle =
                data.type === "upgrade"
                    ? outboundBundleSet.DCOffers.find((o) => o.BundleType === currentOutboundType)
                    : outboundBundleSet.NormalOffers.find((o) => o.BundleType === currentOutboundType);
        }

        let newInboundBundle;
        const currentInboundType = data.bundleState.selectedInboundBundle?.BundleType;
        const inboundBundleSet = currentInboundType
            ? data.bundleState.bundleOffers.find((b) => b.SellKey === data.inboundSellKey)
            : undefined;

        if (inboundBundleSet) {
            newInboundBundle =
                data.type === "upgrade"
                    ? inboundBundleSet.DCOffers.find((o) => o.BundleType === currentInboundType)
                    : inboundBundleSet.NormalOffers.find((o) => o.BundleType === currentInboundType);
        }

        return [newOutboundBundle, newInboundBundle];
    };

    // EXPORTS

    const selectBundle = async (data: {
        journeyIndex: number;
        selectedFlightFeeType: SelectedFlightFeeType;
        sellKey: string;
        tooManyPax: boolean;
        type: BundleType;
        callback: (outboundBundleCode: string, inboundBundleCode: string, isLast: boolean) => void;
    }) => {
        if (state.bundlesMode !== "PerLeg") return;

        const newState = clone(state);

        const { newBundle, isLast } = getSelectedBundle({
            journeyIndex: data.journeyIndex,
            selectedFlightFeeType: data.selectedFlightFeeType,
            sellKey: data.sellKey,
            tooManyPax: data.tooManyPax,
            type: data.type,
        });

        switch (data.journeyIndex) {
            case OUTBOUND:
                newState.selectedOutboundBundle = newBundle;
                break;
            case INBOUND:
                newState.selectedInboundBundle = newBundle;
                break;
        }

        setState(newState);

        if (typeof data.callback === "function") {
            data.callback(
                newState.selectedOutboundBundle?.BundleCode,
                newState.selectedInboundBundle?.BundleCode,
                isLast,
            );
        }
    };

    const upgradeBundle = (data: {
        flightState: FlightPageFlightState;
        journeyIndex: number;
        callback?: (outboundBundleCode: string, inboundBundleCode: string) => void;
    }) => {
        const newState = clone(state);

        // DEVNOTE setTimeout is needed so that the price change occurs in the middle of the zoom animation
        window.setTimeout(async () => {
            switch (data.journeyIndex) {
                case OUTBOUND:
                    newState.selectedOutboundBundle = getUpgradedBundle(
                        data.flightState.selectedOutboundFlight.SellKey,
                        newState.selectedOutboundBundle,
                    );
                    break;
                case INBOUND:
                    newState.selectedInboundBundle = getUpgradedBundle(
                        data.flightState.selectedInboundFlight.SellKey,
                        newState.selectedInboundBundle,
                    );
                    break;
            }

            setState(newState);

            data.callback(newState.selectedOutboundBundle?.BundleCode, newState.selectedInboundBundle?.BundleCode);
        }, 100);
    };

    const selectDcMembershipType = (data: {
        inboundSellKey: string;
        outboundSellKey: string;
        type: "upgrade" | "downgrade";
        callback?: (outboundBundleCode: string, inboundBundleCode: string) => void;
    }) => {
        const newState = clone(state);
        const [newOutboundBundle, newInboundBundle] = getNewBundlesOnMembershipChange({
            bundleState: newState,
            inboundSellKey: data.inboundSellKey,
            outboundSellKey: data.outboundSellKey,
            type: data.type,
        });

        newState.selectedOutboundBundle = newOutboundBundle;
        newState.selectedInboundBundle = newInboundBundle;

        setState(newState);

        if (typeof data.callback === "function") {
            data.callback(newState.selectedOutboundBundle?.BundleCode, newState.selectedInboundBundle?.BundleCode);
        }
    };

    const reset = (journeyIndices: number[]) => {
        const newState = clone(state);

        if (journeyIndices.includes(OUTBOUND)) {
            newState.selectedOutboundBundle = undefined;
        }

        if (journeyIndices.includes(INBOUND)) {
            newState.selectedInboundBundle = undefined;
        }

        setState(newState);
    };

    const handleLogin = async (data: {
        flightState: FlightPageFlightState;
        journeys: ExtendedTripModel[];
        newUserContext: UserContext;
        callback: (outboundBundleCode: string, inboundBundleCode: string, bundles: CustomizedBundleOffers[]) => void;
    }) => {
        const newState = clone(state);

        const { customizedBundleOffers, shouldConcatenateBagSsrsInBundles, bundlesMode } = await updateBundles({
            currentBundlesMode: newState.bundlesMode,
            newUserContext: data.newUserContext,
        });

        newState.bundleOffers = customizedBundleOffers;
        newState.concatenateBags = shouldConcatenateBagSsrsInBundles;
        newState.bundlesMode = bundlesMode;

        if (data.newUserContext.isStaff || bundlesMode === "Legacy") {
            newState.selectedInboundBundle = undefined;
            newState.selectedOutboundBundle = undefined;
        }

        if (data.newUserContext.cug.isMember || data.newUserContext.bancoEstado.category > 0) {
            const { selectedOutboundBundle, selectedInboundBundle } = tryToGetSelectedBundlesAfterLogin(
                newState,
                data.flightState,
            );
            newState.selectedOutboundBundle = selectedOutboundBundle;
            newState.selectedInboundBundle = selectedInboundBundle;
        }

        if (BundleHelper.canGetDcBundles(data.newUserContext, props.model)) {
            const [newOutboundBundle, newInboundBundle] = getNewBundlesOnMembershipChange({
                bundleState: newState,
                inboundSellKey: data.flightState.selectedInboundFlight?.SellKey,
                outboundSellKey: data.flightState.selectedOutboundFlight?.SellKey,
                type: "upgrade",
            });

            newState.selectedOutboundBundle = newOutboundBundle;
            newState.selectedInboundBundle = newInboundBundle;
        }

        if (typeof data.callback === "function") {
            data.callback(
                newState.selectedOutboundBundle?.BundleCode,
                newState.selectedInboundBundle?.BundleCode,
                customizedBundleOffers,
            );
        }

        setState(newState);
    };

    const showBundleUpgradeOffer = (bundle: CustomizedBundle) =>
        bundle && state?.bundlesMode === "PerLeg" && bundle.BundleType !== "Full";

    const getUpgradedBundle = useCallback(
        (sellKey: string, bundle: CustomizedBundle) => {
            if (!sellKey || !bundle) return undefined;

            const isCurrentBundleNone = bundle.BundleType === "None";
            const bundleGroup = state.bundleOffers.find((b) => b.SellKey === sellKey);

            const isCurrentBundleDc = bundleGroup?.DCOffers.some((b) => b.BundleCode === bundle.BundleCode);

            const bundleToOffer = isCurrentBundleDc
                ? bundleGroup?.DCOffers.find((b) =>
                      isCurrentBundleNone ? b.BundleType === "Simple" : b.BundleType === "Full",
                  )
                : bundleGroup?.NormalOffers.find((b) =>
                      isCurrentBundleNone ? b.BundleType === "Simple" : b.BundleType === "Full",
                  );

            return bundleToOffer;
        },
        [state?.bundleOffers],
    );

    useEffect(props.onChange, [state?.selectedOutboundBundle?.BundleCode, state?.selectedInboundBundle?.BundleCode]);

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    return {
        areAllBundlesSelected,
        ssrIdsInOrder,
        state,
        getUpgradedBundle,
        handleLogin,
        reset,
        selectBundle,
        selectDcMembershipType,
        showBundleUpgradeOffer,
        upgradeBundle,
    };
};

import { useCompactDatepicker } from "./../../ui/useCompactDatepicker/useCompactDatepicker";
import { InputFieldAttribute } from "../../../shared/customHooks/useForm/InputFieldAttribute";
import {
    BRASILIAN_CULTURE_CODE,
    CHILE_COUNTRY_CODE,
    DEFAULT_DATE_FORMAT,
    FAKE_DATE_OF_BIRTH,
    LINKS,
} from "../../../shared/commonConstants";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useMemo, useRef } from "haunted";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import {
    ageDictionary,
    getAntiforgerySegment,
    getAntiForgeryTokenFromHtml,
    getRequestBodyFromNamedInputs,
    SelectOption,
    showLoader,
    updateMdl,
} from "../../../shared/common";
import { normalizeCulture } from "../../../shared/localeHelper";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { ROUTES } from "../../../shared/apiRoutes";
import { ref } from "../../../directives/ref";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { classMap } from "lit-html/directives/class-map";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { passwordFormat } from "../../../shared/customHooks/useForm/custom-attributes/passwordFormat";
import { passwordConfirm } from "../../../shared/customHooks/useForm/custom-attributes/passwordConfirm";
import {
    PRIORITISED_COUNTRIES,
    REFUND_INFO_ACCOUNT_TYPES,
    REFUND_INFO_BANK_NAMES,
    REFUND_INFO_DOC_TYPES,
} from "../../itinerary/RefundBankInfoHelper";
import { unconditionalRut } from "../../../shared/customHooks/useForm/custom-attributes/unconditionalRut";
import { useAppContext } from "../../../managers/useAppContext";
import { ProfilePersonWidgetViewModel } from "../../../component-models/profile/ProfilePersonWidgetViewModel";
import { useProfileBancoeOverlay } from "../useProfileBancoeOverlay";
import dayjs, { Dayjs } from "dayjs";
import { RefundBankVM, useRefundBankSection } from "./useRefundBankSection";
import { Validation } from "../../../validator/Validation";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { validateRut } from "../../../shared/form-validation";
import { WidgetSectionStrategyDataObject, useWidgetSectionHelper } from "./useWidgetSectionHelper";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../../shared/customHooks/useAjax/useAjax";
import { useWindowSize } from "../../../shared/customHooks/useWindowSize";
import classNames from "classnames";
import { TemplateResult } from "lit-html";

export interface Props {
    antiForgeryToken: string;
    model: ProfilePersonWidgetViewModel;
}

export type WidgetSectionType = "personal" | "password" | "bank" | "address";

export interface RefundBankValidatorPartialMethods {
    isValid: (field: keyof RefundBankVM) => boolean;
    getMessage: (field: keyof RefundBankVM) => string | TemplateResult;
    getFormMessages: () => string | TemplateResult | (string | TemplateResult)[];
}

type FieldNames = keyof RefundBankVM;

export const useProfilePersonWidget = (props: Props) => {
    const tooltipOpenerClass = "pw-savings-tooltip-opener";
    const scrollerClassName = "person-wc-scroller";
    const widgetLoaderClassName = "widget-loader-class";

    const windowSize = useWindowSize();

    const DEFAULT_REFUND_BANK_VM: RefundBankVM = {
        firstName: props.model.ProfilePersonalDataViewModel.FirstName,
        lastName: props.model.ProfilePersonalDataViewModel.LastName,
        country: props.model.ProfileRefundViewModel.RefundCountry || "",
        bank: props.model.ProfileRefundViewModel.RefundBankName || "",
        documentType: props.model.ProfileRefundViewModel.RefundDocType || "",
        documentNumber: props.model.ProfileRefundViewModel.RefundDocNumber || "",
        accountType: props.model.ProfileRefundViewModel.RefundBankAccountType || "",
        accountNumber: props.model.ProfileRefundViewModel.RefundBankAccountNumber || "",
    };

    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        if (windowSize.width >= 768) {
            const container = DomCrawlingHelper.getElemByClass(
                document.body,
                "profile-widget-container",
            ) as HTMLDivElement;
            const widget = DomCrawlingHelper.getElemByClass(document.body, "person-widget-content") as HTMLDivElement;
            widget.style.maxHeight = `${container.offsetHeight}px`;
        }

        if (windowSize.width >= 1024) {
            ScrollHelper.addPerfectScrollbar(scrollerClassName);
        }
    };

    // DEVNOTE: The OtherGender is already applied on the backend
    const supplementedGenderList = () =>
        props.model.ProfilePersonalDataViewModel.Genders.map((gender) => {
            if (gender.Value === "0") {
                return { ...gender, Text: i18next.t("Otro") };
            }
            return gender;
        });

    const getAddressPostUrl = () => {
        return props.model.ProfilePersonalDataViewModel.ContactAddressIndex === -1
            ? ROUTES.ProfileCreateAddress
            : ROUTES.ProfileUpdateAddress;
    };

    const customAttributes = (): InputFieldAttribute[] => [passwordFormat(), passwordConfirm(), unconditionalRut()];

    const getWindowWidth = () => {
        const w = window;
        const doc = document;
        const e = doc.documentElement;
        const g = doc.getElementsByTagName("body")[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;

        return x;
    };

    const openerIconClassMap = (section: WidgetSectionType) =>
        classMap({
            "js-icon": true,
            "js-circle-chevron-right": true,
            "pw-section-opener": true,
            "open": openedSection === section,
        });

    const accordionClassMap = (section: WidgetSectionType) =>
        classMap({
            "pw-section": true,
            "pw-section-open": openedSection === section,
            "z-50": openedSection === section,
        });

    const mapBankNameArray = (currentCountry: string): void => {
        setRefundBankNames(REFUND_INFO_BANK_NAMES.filter((b) => b.Country === currentCountry).map((b) => b.BankName));
        updateMdl();
    };

    const mapDocTypesArray = (currentCountry: string): void => {
        setRefundDocTypese(
            REFUND_INFO_DOC_TYPES.filter((dt) => dt.Country === currentCountry).map((dt) => {
                return { Text: dt.IdDocType, Value: dt.Id.toString() };
            }),
        );
        updateMdl();
    };

    const mapAccountTypeArray = (currentCountry: string): void => {
        setRefundAccountTypes(
            REFUND_INFO_ACCOUNT_TYPES.filter((at) => at.Country === currentCountry).map((at) => at.AccountType),
        );
        updateMdl();
    };

    const getBodyToPostForBank = () => {
        return {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
            countryCode: bankVm.country,
            documentType: bankVm.documentType,
            documentNumberField: bankVm.documentNumber,
            bankNameField: bankVm.bank,
            cardTypeField: bankVm.accountType,
            accountNumberField: bankVm.accountNumber,
        };
    };

    const getWidgetSectionStrategies = () =>
        new Map<WidgetSectionType, WidgetSectionStrategyDataObject>([
            [
                "address",
                {
                    component: addressForm,
                    bodyToPost: getRequestBodyFromNamedInputs(addressFormElem.current),
                    postUrl: getAddressPostUrl(),
                },
            ],
            [
                "bank",
                {
                    component: bankSectionValidator,
                    bodyToPost: getBodyToPostForBank(),
                    postUrl: ROUTES.ProfileUpdateBankData,
                },
            ],
            [
                "password",
                {
                    component: passwordForm,
                    bodyToPost: getRequestBodyFromNamedInputs(passwordFormElem.current),
                    postUrl: ROUTES.ProfileLegacyUpdateBasicInfo,
                },
            ],
            [
                "personal",
                {
                    component: personalForm,
                    bodyToPost: getRequestBodyFromNamedInputs(personalFormElem.current),
                    postUrl: ROUTES.ProfileUpdateBasicInfo,
                },
            ],
        ]);

    // EVENT HANDLERS

    const toggleMobileOpen = () => {
        if (isMobileOpen) {
            setIsMobileOpen(false);
            setIsOpen(false);
        } else {
            setIsMobileOpen(true);
            setIsMobileOpenerOpen(true);
        }
    };

    const toggleTooltip = (setOpen: boolean) => {
        if (!setOpen) {
            setIsTooltipOpen(false);
        } else {
            setIsTooltipOpen(true);
            // DEVNOTE I'm really sorry about this, but overflow css bug leaves no other option
            const tooltipOpener = DomCrawlingHelper.getElemByClass(document.body, tooltipOpenerClass);
            const x = getWindowWidth();
            if (tooltipOpener) {
                setTooltipBottom(x < 1024 ? -10 : tooltipOpener.offsetTop);
                setTooltipLeft(tooltipOpener.offsetLeft);
            }
        }
    };

    const handleSubmit = async (e: MouseEvent, formElemType: WidgetSectionType) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        const widgetSectionStrategy = widgetSectionHelper.selectWidgetSectionStrategy(
            getWidgetSectionStrategies(),
            formElemType,
        );

        const isFormValid = await widgetSectionStrategy.validate();

        if (isFormValid) {
            showLoader({ name: widgetLoaderClassName });

            await widgetSectionStrategy.submit();

            if (window.location.href.includes("giftCard=success")) {
                window.location.href = ROUTES.PageProfile;
            } else {
                window.location.reload();
            }
        }
    };

    const toggleSections = (openSection: WidgetSectionType) => {
        setOpenedSection(openedSection === openSection ? undefined : openSection);
        updateMdl();
    };

    const handleAddressInput = (e: KeyboardEvent) => {
        return sanitizeInputFieldValue(e, "address");
    };

    const handleCityInput = (e: KeyboardEvent) => {
        return sanitizeInputFieldValue(e, "city");
    };

    const handleZIPCodeInput = (e: KeyboardEvent) => {
        return sanitizeInputFieldValue(e, "postal-code");
    };

    const handleNumericInput = (e: KeyboardEvent) => {
        return sanitizeInputFieldValue(e, "numeric");
    };

    const onClose = () => {
        setIsMobileOpen(false);
        setIsOpen(false);
    };

    // COMPONENT

    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");
    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const personalFormElem = useRef<HTMLFormElement>(undefined);
    const passwordFormElem = useRef<HTMLFormElement>(undefined);
    const addressFormElem = useRef<HTMLFormElement>(undefined);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
    const [openedSection, setOpenedSection] = useState<WidgetSectionType>(undefined);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const [tooltipBottom, setTooltipBottom] = useState<number>(0);
    const [tooltipLeft, setTooltipLeft] = useState<number>(0);
    const [isMobileTooltipOpen, setIsMobileTooltipOpen] = useState<boolean>(false);
    const [isMobileOpenerOpen, setIsMobileOpenerOpen] = useState<boolean>(true);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
    const [refundAccountTypes, setRefundAccountTypes] = useState<string[]>([]);
    const [refundBankNames, setRefundBankNames] = useState<string[]>([]);
    const [refundDocTypes, setRefundDocTypese] = useState<SelectOption[]>([]);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [bankVm, setBankVm] = useState<RefundBankVM>(DEFAULT_REFUND_BANK_VM);

    const isFakeDOB = useMemo(
        () =>
            props.model.ProfilePersonalDataViewModel.DateOfBirth &&
            dayjs(props.model.ProfilePersonalDataViewModel.DateOfBirth, "YYYY-MM-DD").isSame(
                dayjs(FAKE_DATE_OF_BIRTH, DEFAULT_DATE_FORMAT),
                "day",
            ),
        [],
    );

    const initialDOB = useMemo(
        () =>
            props.model.ProfilePersonalDataViewModel.DateOfBirth && !isFakeDOB
                ? dayjs(props.model.ProfilePersonalDataViewModel.DateOfBirth, "DD-MM-YYYY")
                : undefined,
        [isFakeDOB],
    );

    const [dateOfBirth, setDateOfBirth] = useState<Dayjs | undefined>(initialDOB);

    const widgetSectionHelper = useWidgetSectionHelper();

    const bankSectionValidator = useFluentValidator<FieldNames, RefundBankVM>({
        vm: bankVm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("accountNumber", (vm: RefundBankVM) => vm.accountNumber).isRequired(),
            Validation.ruleFor("accountType", (vm: RefundBankVM) => vm.accountType).isRequired(),
            Validation.ruleFor("bank", (vm: RefundBankVM) => vm.bank).isRequired(),
            Validation.ruleFor("documentNumber", (vm: RefundBankVM) => vm.documentNumber).isRequired(),
            Validation.ruleFor("documentNumber", (vm: RefundBankVM) => vm.documentNumber)
                .when((vm) => vm.country === CHILE_COUNTRY_CODE && vm.documentType === "4")
                .isRequired()
                .fulfils(
                    async (docNumber: string) => docNumber && validateRut(docNumber),
                    i18next.t("BE-RutFormatError"),
                ),
            Validation.ruleFor("documentType", (vm: RefundBankVM) => vm.documentType).isRequired(),
            Validation.ruleFor("country", (vm: RefundBankVM) => vm.country).isRequired(),
            Validation.ruleFor("lastName", (vm: RefundBankVM) => vm.lastName).isRequired(),
            Validation.ruleFor("firstName", (vm: RefundBankVM) => vm.firstName).isRequired(),
        ],
    });

    const bankSectionValidatorPartials: RefundBankValidatorPartialMethods = {
        isValid: (field: keyof RefundBankVM) => bankSectionValidator.isValid(field),
        getMessage: (field: keyof RefundBankVM) => bankSectionValidator.getMessage(field),
        getFormMessages: () => bankSectionValidator.getFormMessages(),
    };

    const refundBankSection = useRefundBankSection({
        model: props.model,
        openedSection,
        isValidated,
        vm: bankVm,
        antiForgeryToken: props.antiForgeryToken,
        validatorPartialMethods: bankSectionValidatorPartials,
        refundAccountTypes,
        refundBankNames,
        refundDocTypes,
        toggleSections,
        handleSubmit,
        mapAccountTypeArray,
        mapBankNameArray,
        mapDocTypesArray,
        setVm: setBankVm,
    });

    const minDate = useMemo(() => dayjs().subtract(ageDictionary.get("ADT").max, "year").add(1, "day"), []);
    const maxDate = useMemo(() => dayjs().subtract(ageDictionary.get("ADT").min, "year"), []);

    const birthDate = useCompactDatepicker({
        isInvalid: isValidated && !dateOfBirth,
        isRequired: true,
        label: i18next.t("DateOfBirthLabel"),
        maxDate,
        minDate,
        opensToModal: true,
        value: dateOfBirth,
        onChange: (value) => setDateOfBirth(value),
    });

    const personalForm = useForm({
        customAttributes: [...commonValidationRules(), ...customAttributes()],
    });

    const passwordForm = useForm({
        customAttributes: [...commonValidationRules(), ...customAttributes()],
    });

    const addressForm = useForm({
        customAttributes: [...commonValidationRules(), ...customAttributes()],
    });

    const bancoeOverlay = useProfileBancoeOverlay({
        customClass: "ppw-bancoe-overlay",
        isOpen: isOpen || isMobileOpen,
        onClose,
    });

    useEffect(() => personalForm.init(personalFormElem.current), [personalFormElem.current]);
    useEffect(() => passwordForm.init(passwordFormElem.current), [passwordFormElem.current]);
    useEffect(() => addressForm.init(addressFormElem.current), [addressFormElem.current]);

    useEffect(init, [windowSize.width]);

    useEffect(() => {
        if (PRIORITISED_COUNTRIES.includes(props.model.ProfileRefundViewModel.RefundCountry)) {
            mapBankNameArray(props.model.ProfileRefundViewModel.RefundCountry);
            mapAccountTypeArray(props.model.ProfileRefundViewModel.RefundCountry);
            mapDocTypesArray(props.model.ProfileRefundViewModel.RefundCountry);
        }
    }, []);

    useEffect(() => {
        setBankVm(DEFAULT_REFUND_BANK_VM);
        setIsValidated(false);
        updateMdl();
    }, [openedSection]);

    // TEMPLATES

    const bancoEstadoCategory5BadgeTemplate = () =>
        userContext.bancoEstado.category === 5
            ? html`
                  <div class="pw-badge banco-estado-badge hidden-xs">
                      ${i18next.t("Tarjeta")}
                      <img src="/Images/BancoEstado/be-smart-text-logo-orange.svg" />
                  </div>
                  <div class="pw-badge banco-estado-badge hidden-sm-up">
                      ${i18next.t("Tarjeta")}
                      <img src="/Images/BancoEstado/be-smart-white-text-orange-bg.svg" class="smart-logo" />
                  </div>
              `
            : "";

    const bancoEstadoCategory6BadgeTemplate = () =>
        userContext.bancoEstado.category === 6
            ? html`
                  <div class="pw-badge banco-estado-badge hidden-xs">
                      ${i18next.t("Tarjeta")}
                      <img src="/Images/BancoEstado/be-smart-plus-text-logo-gray.svg" />
                  </div>
                  <div class="pw-badge banco-estado-badge hidden-sm-up">
                      ${i18next.t("Tarjeta")}
                      <img src="/Images/BancoEstado/be-smart-plus-white-text-gray-bg.svg" class="smart-logo" />
                  </div>
              `
            : "";

    const membershipBadgeTemplate = () =>
        props.model.ProfileDiscountsViewModel.HasGroupMembership ||
        props.model.ProfileDiscountsViewModel.HasStandardMembership
            ? html`
                  <div class="pw-badge">
                      <i class="js-icon-be2 js-be2-money"></i> ${i18next.t("Club de Descuentos")}
                  </div>
              `
            : "";

    const staffBadgeTemplate = () =>
        userContext.isStaff
            ? html`
                  <div class="pw-badge staff-badge">
                      <i class="js-icon-mp js-mp-name-tag"></i>
                      ${i18next.t("Team")}
                      <i class="js-icon-mp js-mp-logo"
                          ><span class="path1"></span><span class="path2"></span><span class="path3"></span
                          ><span class="path4"></span><span class="path5"></span><span class="path6"></span
                          ><span class="path7"></span
                      ></i>
                  </div>
              `
            : "";

    const badgeContainerTemplate = () => {
        const showBadge =
            props.model.ProfileDiscountsViewModel.HasGroupMembership ||
            props.model.ProfileDiscountsViewModel.HasStandardMembership ||
            [5, 6].includes(userContext.bancoEstado.category) ||
            userContext.isStaff;

        return showBadge
            ? html`
                  <div class="pw-badge-container">
                      ${bancoEstadoCategory5BadgeTemplate()} ${bancoEstadoCategory6BadgeTemplate()}
                      ${membershipBadgeTemplate()} ${staffBadgeTemplate()}
                  </div>
              `
            : "";
    };

    const dcSavingsContainerTemplate = () => {
        const showSavings =
            (props.model.ProfileDiscountsViewModel.HasGroupMembership ||
                props.model.ProfileDiscountsViewModel.HasStandardMembership) &&
            ![5, 6].includes(userContext.bancoEstado.category);

        const amount =
            userContext.bancoEstado.category === 1
                ? props.model.ProfileDiscountsViewModel.DcBeSaving?.replace(" ", "") || ""
                : props.model.ProfileDiscountsViewModel.DcSavings?.replace(" ", "") || "";

        const updateDate =
            userContext.bancoEstado.category === 1
                ? props.model.AgentCreatedDate
                : props.model.ProfileDiscountsViewModel.DcStartDate;

        return showSavings
            ? html`
                  <div class="pw-savings">
                      <span>
                          ${i18next.t("¡Felicidades! Con el Club de Descuentos has ahorrado {{- amount}}", {
                              amount,
                          })}
                      </span>
                      <span
                          >${i18next.t("*Cálculo realizado desde {{- updateDate}} hasta hoy.", {
                              updateDate,
                          })}</span
                      >
                  </div>
              `
            : "";
    };

    const bancoEstadoSavingsContainerTemplate = () => {
        const topImageUrl =
            userContext.bancoEstado.category === 5
                ? "/Images/BancoEstado/mobile-joy-top.png"
                : "/Images/BancoEstado/mobile-joy-top-gray.png";

        const bottomImageUrl =
            userContext.bancoEstado.category === 5
                ? "/Images/BancoEstado/mobile-joy-bottom.png"
                : "/Images/BancoEstado/mobile-joy-bottom-gray.png";

        const amount = props.model.ProfileDiscountsViewModel.BeSavings?.replace(" ", "") || "";
        const plus = userContext.bancoEstado.category === 6 ? "+" : "";

        return [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <img class="hidden-sm-up" src=${topImageUrl} />
                  <div class="pw-savings">
                      <span>
                          ${i18next.t("¡Felicidades! Has ahorrado con tu tarjeta SMART{{plus}} {{amount}}", {
                              amount,
                              plus,
                          })}
                          <i
                              class="js-icon js-flight-help ${tooltipOpenerClass}"
                              @mouseenter=${() => toggleTooltip(true)}
                              @mouseleave=${() => toggleTooltip(false)}
                              @click=${() => setIsMobileTooltipOpen(true)}
                          ></i>
                      </span>
                      <span
                          >${i18next.t("*Cálculo realizado desde {{- updateDate}} hasta hoy.", {
                              updateDate: props.model.AgentCreatedDate,
                          })}</span
                      >
                  </div>
                  <img class="hidden-sm-up" src=${bottomImageUrl} />

                  ${bancoEstadoUpgradeCategoryTemplate()}
              `
            : "";
    };

    const bancoEstadoUpgradeCategoryTemplate = () =>
        userContext.bancoEstado.category === 5
            ? html`
                  <div class="pw-upgrade-smart">
                      <span>
                          ${i18next.t("¡Cámbiate a")}
                          <img src="/Images/BancoEstado/smart-plus-extras-2.svg" />
                          ${i18next.t("! Ahorra más y obtén más beneficios")}
                      </span>
                      <a href=${LINKS.SmartPlus} target="_blank">
                          ${i18next.t("Quiero ser")}<img src="/Images/BancoEstado/be-smart-plus-text-logo-gray.svg" />
                          <i class="js-icon js-circle-chevron-right"></i>
                      </a>
                  </div>
              `
            : "";

    const staffInfoTemplate = () => {
        const tempClassMap = classMap({
            "pw-staff-info": true,
            "portuguese": appContext.Culture.toLowerCase() === BRASILIAN_CULTURE_CODE,
        });

        return userContext.isStaff
            ? html`
                  <div class=${tempClassMap}>
                      <span>
                          ${i18next.t(
                              "Disfruta de todos nuestros destinos, con descuentos especialmernte para ti y grupo familiar inscrito, viaja y",
                          )}
                          <span class="font-semibold">${i18next.t("Conéctate SMART!")}</span>
                      </span>
                  </div>
              `
            : "";
    };

    const mainHeaderTemplate = () => {
        const onClick = () => {
            setIsOpen(!isOpen);
            updateMdl();
        };

        return html`
            <div class="pw-main">
                <i class="hidden-xs js-icon-mp js-mp-person"></i>
                <div class="pw-name">
                    ${props.model.ProfilePersonalDataViewModel.FirstName}
                    ${props.model.ProfilePersonalDataViewModel.LastName}
                </div>
                ${!isOpen
                    ? html`
                          ${badgeContainerTemplate()} ${dcSavingsContainerTemplate()}
                          ${bancoEstadoSavingsContainerTemplate()} ${staffInfoTemplate()}
                      `
                    : ""}
                <div class="pw-edit" data-test-id="profile-edit" @click=${onClick}>
                    <span class="underline">${i18next.t("Editar")}</span> <i class="js-icon-mp js-mp-edit"></i>
                </div>
            </div>
        `;
    };

    const personalDataTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Nombres")}</label>
                    <input
                        class="mdl-textfield__input js-input disabled"
                        autocomplete="cc-number"
                        tabindex="-1"
                        data-required
                        data-test-id="first-name"
                        value=${props.model.ProfilePersonalDataViewModel.FirstName}
                    />
                </div>
            </div>
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Apellidos")}</label>
                    <input
                        class="mdl-textfield__input js-input disabled"
                        autocomplete="cc-number"
                        tabindex="-1"
                        data-required
                        data-test-id="last-name"
                        value=${props.model.ProfilePersonalDataViewModel.LastName}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">${birthDate.htmlTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("Sexo")}</label>
                    <select
                        name="updatedProfile.Member.Gender"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="1"
                        data-required
                        data-test-id="gender"
                        value=${props.model.ProfilePersonalDataViewModel.Gender}
                    >
                        <option value=""></option>
                        ${supplementedGenderList().map(
                            (gender) => html`
                                <option
                                    .value=${gender.Value}
                                    ?selected=${gender.Value ===
                                    props.model.ProfilePersonalDataViewModel.Gender.toString()}
                                >
                                    ${gender.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("Idioma")}</label>
                    <select
                        name="updatedProfile.Member.CultureCode"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="2"
                        data-required
                        data-test-id="language"
                        value=${normalizeCulture(props.model.ProfilePersonalDataViewModel.Culture)}
                    >
                        <option value=""></option>
                        ${props.model.ProfilePersonalDataViewModel.Languages.map(
                            (language) => html`
                                <option
                                    .value=${language.Value}
                                    ?selected=${language.Value === props.model.ProfilePersonalDataViewModel.Culture}
                                >
                                    ${language.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("Moneda")}</label>
                    <select
                        name="updatedProfile.Member.CurrencyCode"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="3"
                        data-required
                        data-test-id="currency"
                        value=${props.model.ProfilePersonalDataViewModel.Currency}
                    >
                        <option value=""></option>
                        ${props.model.ProfilePersonalDataViewModel.Currencies.map(
                            (currency) => html`
                                <option
                                    .value=${currency.Value}
                                    ?selected=${currency.Value === props.model.ProfilePersonalDataViewModel.Currency}
                                >
                                    ${currency.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("Nacionalidad")}</label>
                    <select
                        name="updatedProfile.Member.Nationality"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="4"
                        data-required
                        data-test-id="nationality"
                        value=${props.model.ProfilePersonalDataViewModel.Nationality}
                    >
                        <option value=""></option>
                        ${props.model.Countries.map(
                            (country) => html`
                                <option
                                    .value=${country.Value}
                                    ?selected=${country.Value === props.model.ProfilePersonalDataViewModel.Nationality}
                                >
                                    ${country.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("País de residencia")}</label>
                    <select
                        name="updatedProfile.Member.ResidentCountry"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="5"
                        data-required
                        data-test-id="country"
                        value=${props.model.ProfilePersonalDataViewModel.Residence}
                    >
                        <option value=""></option>
                        ${props.model.Countries.map(
                            (country) => html`
                                <option
                                    .value=${country.Value}
                                    ?selected=${country.Value === props.model.ProfilePersonalDataViewModel.Residence}
                                >
                                    ${country.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
        </div>
    `;

    const contactInPersonalDataTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Correo electrónico")}</label>
                    <input
                        class="mdl-textfield__input js-input disabled"
                        autocomplete="cc-number"
                        tabindex="-1"
                        data-required
                        data-test-id="email-address"
                        value=${props.model.ProfilePersonalDataViewModel.Email}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Telefono")}</label>
                    <input
                        name="updatedProfile.Member.HomePhoneNumber.Number"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="6"
                        data-required
                        data-test-id="phone-number"
                        value=${props.model.ProfilePersonalDataViewModel.Phone}
                        @input=${handleNumericInput}
                        @blur=${handleNumericInput}
                    />
                </div>
            </div>
        </div>
    `;

    const addressDataTemplate = () => html`
        <input
            value=${props.model.ProfilePersonalDataViewModel.ContactAddressIndex}
            type="hidden"
            name="personAddresses.Index"
        />
        <input
            value=${props.model.ProfilePersonalDataViewModel.AddressId}
            type="hidden"
            name="personAddresses.UpdateItem.PersonAddressId"
        />
        <input
            value=${props.model.ProfilePersonalDataViewModel.PersonId}
            type="hidden"
            name="personAddresses.UpdateItem.PersonId"
        />
        <input type="hidden" value="true" name="personAddresses.UpdateItem.Default" />
        <input type="hidden" value="H" name="personAddresses.UpdateItem.TypeCode" />

        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Dirección")}</label>
                    <input
                        name="personAddresses.UpdateItem.Address.AddressLine1"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="7"
                        data-required
                        data-test-id="address"
                        value=${props.model.ProfilePersonalDataViewModel.Address}
                        @input=${handleAddressInput}
                        @blur=${handleAddressInput}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Dirección (línea 2 - opcional)")}</label>
                    <input
                        name="personAddresses.UpdateItem.Address.AddressLine2"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="8"
                        value=${props.model.ProfilePersonalDataViewModel.Address2}
                        @input=${handleAddressInput}
                        @blur=${handleAddressInput}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Ciudad")}</label>
                    <input
                        name="personAddresses.UpdateItem.Address.City"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="9"
                        data-required
                        data-test-id="city"
                        value=${props.model.ProfilePersonalDataViewModel.City}
                        @input=${handleCityInput}
                        @blur=${handleCityInput}
                    />
                </div>
            </div>
            <div class="col-xs-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Código postal")}</label>
                    <input
                        name="personAddresses.UpdateItem.Address.PostalCode"
                        class="mdl-textfield__input js-input"
                        max="10"
                        autocomplete="off"
                        tabindex="10"
                        data-required
                        data-test-id="postal-code"
                        value=${props.model.ProfilePersonalDataViewModel.Zip}
                        @input=${handleZIPCodeInput}
                        @blur=${handleZIPCodeInput}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                    <label class="mdl-textfield__label">${i18next.t("País")}</label>
                    <select
                        name="personAddresses.UpdateItem.Address.CountryCode"
                        class="mdl-textfield__input js-input js-select"
                        autocomplete="off"
                        tabindex="11"
                        data-required
                        data-test-id="country-code"
                        value=${props.model.ProfilePersonalDataViewModel.Country}
                    >
                        <option value=""></option>
                        ${props.model.Countries.map(
                            (country) => html`
                                <option
                                    .value=${country.Value}
                                    ?selected=${country.Value === props.model.ProfilePersonalDataViewModel.Country}
                                >
                                    ${country.Text}
                                </option>
                            `,
                        )}
                    </select>
                </div>
            </div>
        </div>
    `;

    const passwordDataTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Contraseña antigua")}</label>
                    <input
                        name="oldPassword"
                        type="password"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="12"
                        data-required
                        data-test-id="old-password"
                        value=${oldPassword}
                        @change=${(e: MouseEvent) => setOldPassword((e.target as HTMLInputElement).value)}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Contraseña nueva")}</label>
                    <input
                        name="newPassword"
                        type="password"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="13"
                        password-format
                        data-required
                        data-test-id="new-password"
                        value=${newPassword}
                        @change=${(e: MouseEvent) => setNewPassword((e.target as HTMLInputElement).value)}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Confirmación de nueva contraseña")}</label>
                    <input
                        name="newPasswordConfirmation"
                        type="password"
                        class="mdl-textfield__input js-input"
                        autocomplete="off"
                        tabindex="14"
                        password-confirm
                        data-required
                        data-test-id="new-password-confirmation"
                        value=${newPasswordConfirmation}
                        @change=${(e: MouseEvent) => setNewPasswordConfirmation((e.target as HTMLInputElement).value)}
                    />
                </div>
            </div>
        </div>
    `;

    const seatPriceTemplate = () => {
        const amount = props.model.ProfileDiscountsViewModel.SeatReferencePrice?.replace(" ", "") || "";

        return userContext.bancoEstado.category === 6
            ? html`
                  <li>
                      -
                      ${i18next.t("Uso de {{amount}}", {
                          amount: props.model.ProfileDiscountsViewModel.SeatQuantity,
                      })}
                      <span class="font-black"
                          >${i18next.t("asiento{{plural}}", {
                              plural: Number(props.model.ProfileDiscountsViewModel.SeatQuantity) !== 1 ? "s" : "",
                          })}</span
                      >
                      ${i18next.t("GRATIS (precio ref. {{amount}}).", {
                          amount,
                      })}
                  </li>
              `
            : "";
    };

    const tooltipContentTemplate = () => {
        const discountAmount =
            props.model.ProfileDiscountsViewModel.DiscountedSeatReferencePrice?.replace(" ", "") || "";
        const savedPrice = props.model.ProfileDiscountsViewModel.PriorityReferencePrice?.replace(" ", "") || "";

        return html`
            ${i18next.t("Monto ahorrado con tu Tarjeta es un valor aproximado que considera:")}
            <ul>
                ${userContext.bancoEstado.category === 6 || userContext.dc.hasMembership
                    ? html`
                          <li>
                              - ${i18next.t("Ahorro de ")}
                              <span class="font-black"
                                  >${props.model.ProfileDiscountsViewModel.DcBeSaving?.replace(" ", "") || ""}</span
                              >
                              ${i18next.t("con el")}
                              <span class="font-black">${i18next.t("Club de descuentos")}.</span>
                          </li>
                      `
                    : ""}
                <li>
                    -
                    <span class="font-black"
                        >${props.model.ProfileDiscountsViewModel.BeBeSaving?.replace(" ", "") || ""}</span
                    >
                    ${i18next.t("en")}
                    <span class="font-black">${i18next.t("beneficios BancoEstado")}</span>
                    ${i18next.t("utilizados.")}
                </li>
                ${seatPriceTemplate()}
                <li>
                    -
                    ${i18next.t("Uso de {{amount}} asientos con", {
                        amount: props.model.ProfileDiscountsViewModel.DiscountedSeatQuantity,
                    })}
                    <span class="font-black">${i18next.t("40% descuento en zona BancoEstado")}</span>
                    ${i18next.t("(dcto. referencial aplicado {{amount}}).", {
                        amount: discountAmount,
                    })}
                </li>
                <li>
                    ${i18next.t("- Uso de {{amount}} Embarque Prioritario GRATIS (precio ref. {{price}}).", {
                        amount: props.model.ProfileDiscountsViewModel.PriorityQuantity,
                        price: savedPrice,
                    })}
                </li>
            </ul>
        `;
    };

    const tooltipTemplate = () =>
        isTooltipOpen
            ? html`
                  <div
                      class="pw-savings-tooltip hidden-sm-down"
                      style="bottom: ${tooltipBottom}px; left: ${tooltipLeft}px"
                  >
                      ${tooltipContentTemplate()}
                  </div>
              `
            : "";

    const mobileTooltipTemplate = () =>
        isMobileTooltipOpen
            ? html`
                  <div class="modal hidden-md-up" @click=${() => setIsMobileTooltipOpen(false)}></div>
                  <div class="pw-savings-tooltip pw-mobile-tooltip hidden-md-up">${tooltipContentTemplate()}</div>
              `
            : "";

    const mobileOpenerTemplate = () => {
        const tempClassMap = classMap({
            "hidden-sm-up": true,
            "pw-mobile-opener": true,
            "closed": !isMobileOpenerOpen,
        });

        const arrows = isMobileOpen ? "<<" : ">>";

        const onClick = (e: MouseEvent) => {
            e.stopPropagation();
            setIsMobileOpenerOpen(false);
        };

        return html`
            <div class=${tempClassMap} @click=${toggleMobileOpen}>
                ${isMobileOpenerOpen
                    ? html`
                          <i class="js-icon-mp js-mp-person"></i> ${arrows}
                          <div class="hidden-sm-up pwc-close-btn" @click=${onClick}>&times;</div>
                      `
                    : html` <span>${i18next.t("Perfil")}</span> `}
            </div>
        `;
    };

    const sectionHeaderEditTemplate = (showEdit: boolean) =>
        showEdit ? html` <i data-test-id="password-edit" class="js-icon-mp js-mp-edit cursor-pointer"></i> ` : "";

    const sectionHeaderTemplate = (
        section: WidgetSectionType,
        caption: string,
        icons: string[],
        showEdit?: boolean,
    ) => html`
        <div class="pw-editor-title mt-4" @click=${() => toggleSections(section)}>
            <i class="${icons.join(" ")}"></i>${caption} ${sectionHeaderEditTemplate(showEdit)}
            <i class=${openerIconClassMap(section)}></i>
        </div>
    `;

    const personalFormTemplate = () => html`
        ${sectionHeaderTemplate("personal", i18next.t("Datos personales"), ["js-icon-mp", "js-mp-person"])}
        <div class=${accordionClassMap("personal")}>
            <form action=${ROUTES.ProfileUpdateBasicInfo} method="post" ref=${ref(personalFormElem)}>
                ${getAntiforgerySegment(props.antiForgeryToken)} ${personalDataTemplate()}
                ${contactInPersonalDataTemplate()}
                <input
                    value=${dateOfBirth?.format(DEFAULT_DATE_FORMAT) || ""}
                    type="hidden"
                    name="updatedProfile.Member.DateOfBirth"
                />
                ${submitButtonTemplate("personal", "personal")}
            </form>
        </div>
    `;

    const passwordFormTemplate = () => html`
        ${sectionHeaderTemplate("password", i18next.t("Contraseña"), ["js-icon-mp", "js-mp-lock"], true)}
        <div class=${accordionClassMap("password")}>
            <form action=${ROUTES.ProfileLegacyUpdateBasicInfo} method="post" ref=${ref(passwordFormElem)}>
                ${getAntiforgerySegment(props.antiForgeryToken)} ${passwordDataTemplate()}
                ${submitButtonTemplate("password", "password")}
            </form>
        </div>
    `;

    const addressFormTemplate = () => html`
        ${sectionHeaderTemplate("address", i18next.t("Detalles de contacto"), ["js-icon-mp", "js-mp-phone"])}
        <div class=${accordionClassMap("address")}>
            <form action=${getAddressPostUrl()} method="post" ref=${ref(addressFormElem)}>
                ${getAntiforgerySegment(props.antiForgeryToken)} ${addressDataTemplate()}
                ${submitButtonTemplate("address", "address")}
            </form>
        </div>
    `;

    const submitButtonTemplate = (section: WidgetSectionType, formElem: WidgetSectionType) => {
        const dataTestId = `profile-edit-submit-${section}-button`;

        return html`
            <div class="my-2 text-right md:my-4 ">
                <button
                    class="pw-cta"
                    data-test-id=${dataTestId}
                    @click=${(e: MouseEvent) => handleSubmit(e, formElem)}
                >
                    ${i18next.t("Guardar")} <i class="js-icon js-circle-chevron-right"></i>
                </button>
            </div>
        `;
    };

    const refundMessageTemplate = () =>
        !props.model.ProfileRefundViewModel.IsRefundDataProvided
            ? html`
                  <div class="pw-bankdata-missing">
                      <i class="js-icon js-broken-circle-exclamation"></i>
                      <span class="pw-bankdata-missing-text">
                          ${i18next.t("Hay información pendiente de actualizar")}
                      </span>
                  </div>
              `
            : "";

    const mainClassMap = classMap({
        "person-widget-container": true,
        "open": isOpen,
        "mobile-open": isMobileOpen,
    });

    const htmlTemplate = () => html`
        ${bancoeOverlay.htmlTemplate()}
        <div class=${mainClassMap}>
            <div class="person-widget-content ${widgetLoaderClassName}">
                ${mainHeaderTemplate()}
                <div class=${classNames(scrollerClassName, "overflow-y-scroll md:overflow-hidden")}>
                    ${refundMessageTemplate()}
                    <div class="pw-editor">
                        ${personalFormTemplate()} ${passwordFormTemplate()} ${refundBankSection.htmlTemplate()}
                        ${addressFormTemplate()}
                    </div>
                </div>
            </div>
        </div>
        ${mobileOpenerTemplate()} ${tooltipTemplate()} ${mobileTooltipTemplate()}
    `;

    return { htmlTemplate, isOpen, isMobileOpen };
};

import { HauntedFunc } from "./../shared/haunted/HooksHelpers";

import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useEffect, useState } from "../shared/haunted/CustomHooks";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-checkbox";

const DEFAULTS: Properties = {
    checked: false,
    label: "",
    readonly: false,
    dataTestId: "",
    customClass: "",
};

export interface Properties {
    checked: boolean;
    label: string;
    readonly?: boolean;
    dataTestId?: string;
    customClass?: string;
}

interface ChangeEventDetail {
    checked: boolean;
}

export class ChangeEvent extends CustomEvent<ChangeEventDetail> {
    constructor(detail: ChangeEventDetail) {
        super("change", { detail });
    }
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        checked: host.checked !== undefined ? host.checked : DEFAULTS.checked,
        label: host.label !== undefined ? host.label : DEFAULTS.label,
        readonly: host.readonly !== undefined ? host.readonly : DEFAULTS.readonly,
        dataTestId: host.dataTestId !== undefined ? host.dataTestId : DEFAULTS.dataTestId,
        customClass: host.customClass !== undefined ? host.customClass : DEFAULTS.customClass,
    };

    // Event Handlers

    const onClicked = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        if (!props.readonly) {
            setChecked(!checked);
            host.dispatchEvent(
                new ChangeEvent({
                    checked: !checked,
                }),
            );
        }
    };

    // COMPONENT

    const [checked, setChecked] = useState(false);
    const [componentId, setComponentId] = useState<string>("");

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    useEffect(() => {
        const id = `_${name}_${Math.random().toString(36).substr(2, 9)}`;
        setComponentId(id);
    }, []);

    // TEMPLATE

    return html`
        <div class="${props.customClass}">
            <input
                class="dc-checkbox"
                type="checkbox"
                id="${componentId}"
                ?checked=${checked}
                ?readonly=${props.readonly}
                data-test-id=${props.dataTestId}
                @click=${onClicked}
            />
            <label class="form-label" for="${componentId}">${unsafeHTML(props.label)}</label>
        </div>
    `;
};

import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import i18next from "i18next";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useSeatPassengerHelper } from "../../../../component-helpers/seatmap/useSeatPassengerHelper";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export const desktopPassengerClassName = "ts-seat-passenger";

export const name = "ac-banco-estato-56-seat-passenger";

export interface Props {
    apiPassenger: ApiSeatmapPassenger;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    journeyIndex: number;
    seatmapState: SeatmapState;
    segmentIndex: number;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    onPassengerSelect: (paxNumber: number) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        apiPassenger: host.apiPassenger,
        context: host.context,
        currentSeats: host.currentSeats,
        journeyIndex: host.journeyIndex,
        seatmapState: host.seatmapState,
        segmentIndex: host.segmentIndex,
        handleSeatDelete: host.handleSeatDelete,
        onPassengerSelect: host.onPassengerSelect,
    };

    const { canShowSeatDeleteButton, isSeatDisabled, isSeatSelected, getSeatNumberForPaxForSegment } =
        useSeatPassengerHelper({
            context: props.context,
            currentSeats: props.currentSeats,
            seatmapState: props.seatmapState,
        });

    // HELPERS

    const isSeatForPaxForSegment = (seat: ExtendedApiSeat) =>
        seat.PassengerNumber === props.apiPassenger.PassengerNumber &&
        seat.JourneyIndex === props.journeyIndex &&
        seat.SegmentIndex === props.segmentIndex;

    const isSeatFreeForPax = () =>
        !props.seatmapState?.CurrentJourney.AreAllSeatsInBundle &&
        props.currentSeats?.some((s) => isSeatForPaxForSegment(s) && s.IsFree);

    const isSeatDiscountedForPax = () =>
        !props.seatmapState?.CurrentJourney.AreAllSeatsInBundle &&
        props.currentSeats?.some((s) => isSeatForPaxForSegment(s) && s.IsDiscounted && !s.IsFree);

    const showSeatDeleteButton = () => canShowSeatDeleteButton(props.apiPassenger);

    const isDisabled = () => isSeatDisabled();

    const isSelected = () => isSeatSelected(props.apiPassenger);

    const seatNumber = () => getSeatNumberForPaxForSegment(props.apiPassenger);

    const getPaxName = () => (passengerNames ? passengerNames[props.apiPassenger.PassengerNumber] : "");

    // COMPONENT

    const [passengerNames] = useReduxState("booking.passengerNames");

    // EVENT LISTENERS

    const handleClickPassenger = () => {
        if (props.context.Model.IsReadOnly) {
            return;
        }

        props.onPassengerSelect(props.apiPassenger.PassengerNumber);
    };

    // TEMPLATES

    const desktopTemplate = () => {
        const tempClassMap = classMap({
            "hidden-md-down": true,
            "seat-pax": true,
            "is-past": isDisabled(),
        });

        const seatClassMap = classMap({
            "new-be-seat": true,
            "selected": !props.context.Model.IsReadOnly && isSelected(),
        });

        const numberClassMap = classMap({
            "nbs-number": true,
            "selected": seatNumber(),
        });

        const numberInsideClassMap = classMap({
            "nbs-seat-number": true,
            "condensed": !showSeatDeleteButton(),
        });

        const nameDataTestId = getTestId(T.SEATMAP.BANCO_ESTADO_56_PASSENGER_NAME_AND_SEAT_CONTAINER, {
            p: props.apiPassenger.PassengerNumber,
            j: props.journeyIndex,
            s: props.segmentIndex,
        });

        const numberDataTestId = getTestId(T.SEATMAP.BANCO_ESTADO_56_PASSENGER_SEAT_NUMBER, {
            p: props.apiPassenger.PassengerNumber,
            j: props.journeyIndex,
            s: props.segmentIndex,
        });

        return html`
            <div class=${tempClassMap}>
                <div @click=${handleClickPassenger} class=${seatClassMap}>
                    <div class="nbs-name" data-test-value=${seatNumber()} data-test-id=${nameDataTestId}>
                        ${getPaxName()}
                    </div>
                    <div class="nbs-info">
                        <div class=${numberClassMap}>
                            <div class=${numberInsideClassMap} data-test-id=${numberDataTestId}>${seatNumber()}</div>
                            ${desktopDeleteButtonTemplate()}
                        </div>
                        ${dummyDesktopFreeSeatTemplate()} ${desktopFreeSeatTemplate()}
                        ${desktopDiscountedSeatTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    const dummyDesktopFreeSeatTemplate = () =>
        !isSeatFreeForPax() && !isSeatDiscountedForPax() ? html` <div class="be2-seat-free-info"></div> ` : "";

    const desktopDeleteButtonTemplate = () =>
        !props.context.Model.IsReadOnly && showSeatDeleteButton()
            ? html`
                  <div
                      class="nbs-delete-seat"
                      data-test-id=${getTestId(T.SEATMAP.BANCO_ESTADO_56_SEAT_DELETE_BUTTON, {
                          p: props.apiPassenger.PassengerNumber,
                          j: props.journeyIndex,
                          s: props.segmentIndex,
                      })}
                      @click=${() => props.handleSeatDelete(props.apiPassenger)}
                  >
                      &times;
                  </div>
              `
            : "";

    const desktopFreeSeatTemplate = () =>
        isSeatFreeForPax()
            ? html`
                  <div class="be2-seat-free-info">
                      <i class="js-icon-be2 js-be2-seats"></i>
                      <span>${i18next.t("Gratis")}</span>
                  </div>
              `
            : "";

    const desktopDiscountedSeatTemplate = () =>
        isSeatDiscountedForPax()
            ? html`
                  <div class="be2-seat-discount-info">
                      <span class="be2-seat-discount-info-1">${i18next.t("desde")}</span>
                      <span class="be2-seat-discount-info-2">${i18next.t("40% Dcto")}</span>
                  </div>
              `
            : "";

    return html` <div class="${desktopPassengerClassName} h-full">${desktopTemplate()}</div> `;
};

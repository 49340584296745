import i18next from "i18next";
import { html } from "lit-html";
import { isKeyNumeric } from "../../../shared/common";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { ApiEmergencyContact } from "../../../component-models/passengers/ApiEmergencyContact";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";

export interface Props {
    hasFlightColombianStation: boolean;
    isValidated: boolean;
}

export const useEmergencyContact = (props: Props) => {
    const [vm, setVm] = useState<ApiEmergencyContact>({
        Address: "",
        City: "",
        CompanyName: "",
        EmailAddress: "",
        Phone: "",
    });

    const { ajaxJsonRequest } = useAjax();

    const init = async () => {
        try {
            const result = await ajaxJsonRequest<ApiEmergencyContact>({
                url: ROUTES.ApiRoutes.EmergencyContact,
                method: "GET",
            });

            if (result.data) setVm(result.data);
        } catch (error) {
            // do nothing, no Emergency Contact yet
        }
    };

    const validator = useFluentValidator<keyof ApiEmergencyContact, ApiEmergencyContact>({
        vm,
        validated: props.isValidated,
        validations: [
            Validation.ruleFor("Address", (vm: ApiEmergencyContact) => vm.Address)
                .when(() => props.hasFlightColombianStation)
                .isRequired(),
            Validation.ruleFor("City", (vm: ApiEmergencyContact) => vm.City)
                .when(() => props.hasFlightColombianStation)
                .isRequired(),
            Validation.ruleFor("CompanyName", (vm: ApiEmergencyContact) => vm.CompanyName).isRequired(),
            Validation.ruleFor("Phone", (vm: ApiEmergencyContact) => vm.Phone).isRequired(),
            Validation.ruleFor("EmailAddress", (vm: ApiEmergencyContact) => vm.EmailAddress)
                .isEmail()
                .isRequired(),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, true)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    useEffect(init, []);

    const nameTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("CompanyName")}
            .isInvalid=${!validator.isValid("CompanyName")}
            .label=${i18next.t("EmergencyContact-Name")}
            .value=${vm.CompanyName ?? ""}
            .onInput=${(value: string) => setVm({ ...vm, CompanyName: value })}
        ></ac-input>
    `;

    const phoneTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("Phone")}
            .isInvalid=${!validator.isValid("Phone")}
            .label=${i18next.t("EmergencyContact-PhoneNumber")}
            .value=${vm.Phone ?? ""}
            .onKeyUp=${handlePhoneChange}
            .onInput=${(value: string) => setVm({ ...vm, Phone: value })}
        ></ac-input>
    `;

    const emailTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("EmailAddress")}
            .isInvalid=${!validator.isValid("EmailAddress")}
            .label=${i18next.t("EmergencyContact-EmailAddress")}
            .value=${vm.EmailAddress ?? ""}
            .onInput=${(value: string) => setVm({ ...vm, EmailAddress: value })}
        ></ac-input>
    `;

    const cityTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("City")}
            .isInvalid=${!validator.isValid("City")}
            .label=${i18next.t("Ciudad")}
            .value=${vm.City ?? ""}
            .onInput=${(value: string) => setVm({ ...vm, City: value })}
        ></ac-input>
    `;

    const addressLineTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("Address")}
            .isInvalid=${!validator.isValid("Address")}
            .label=${i18next.t("Dirección")}
            .value=${vm.Address ?? ""}
            .onInput=${(value: string) => setVm({ ...vm, Address: value })}
        ></ac-input>
    `;

    const addressTemplate = () =>
        props.hasFlightColombianStation
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">${cityTemplate()}</div>
                      <div class="col-xs-1 col-sm-1-2">${addressLineTemplate()}</div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div id="mainContentWrapper" class="checkin-passengers" data-test-id=${T.COMMON.EMERGENCY_CONTACT}>
            <section class="booking-wrapper ts-error-container">
                <header>
                    <span class="js-phone js-icon title-icon"></span>
                    <div class="title">
                        <h2 class="main-title">${i18next.t("V2-Checkin-EmergencyContact")}</h2>
                        <div class="subtitle">${i18next.t("V2-Checkin-EmergencyContactInfo")}</div>
                    </div>
                </header>
                <div class="inner-box ts-passenger-details">
                    <div class="inner-deep-box padded checkin-pax-select ts-error-parent">
                        <div class="row">
                            <div class="col-xs-1 col-sm-1-2 col-md-1-3 mb-4">${nameTemplate()}</div>
                            <div class="col-xs-1 col-sm-1-2 col-md-1-3 mb-4">${phoneTemplate()}</div>
                            <div class="col-xs-1 col-sm-1 col-md-1-3 mb-4">${emailTemplate()}</div>
                        </div>
                        ${addressTemplate()} ${formErrors.htmlTemplate()}
                    </div>
                </div>
            </section>
        </div>
    `;

    return { vm, htmlTemplate, validate: validator.validate };
};

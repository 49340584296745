import { BookingDataAncillary } from "./../../shared/BookingData";
import dayjs from "dayjs";
import BookingData from "../../shared/BookingData";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { useBookingDataManager } from "../useBookingDataManager";
import { useAppContext } from "../useAppContext";
import { useCug2AppContext } from "../useCug2AppContext";
import {
    AIRPORT_CHECKIN_CODES,
    CABIN_BAG_CODES,
    CHECKED_BAG_CODES,
    FLEXI_FEE_CODES,
    INSURANCE_FEE_CODES,
    OVERSIZED_BAG_CODES,
    PET_IN_CABIN_CODES,
    PRIORITY_BOARDING_CODES,
    SEAT_CODES,
    SEAT_FEE_CODES,
} from "../../shared/commonConstants";
import { normalizeCulture } from "../../shared/localeHelper";
import { useBookingContext } from "../useBookingContext";
import { useFlowContext } from "../useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface TealiumLog {
    EventName?: string;
    EventParams?: any;
    UdoParams?: any;
    UpdateCommonUdo?: boolean;
    UpdateRealUdo?: boolean;
}

const SMART_BUNDLE_END = "1";
const FULL_BUNDLE_END = "2";

export const useTealiumManager = () => {
    const storageKey = "js_fudo_od_st";
    const testMode = false; /* DEVNOTE set this true for testing, so it logs tealium info into console */

    const appContext = useAppContext();
    const [userContext] = useReduxState("userContext");
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();
    const cug2AppContext = useCug2AppContext();

    const [total] = useReduxState("booking.total");
    const [currency] = useReduxState("booking.currency");
    const [ssrData] = useReduxState("booking.ssrData");

    const shouldSkipTealiumLogging = () =>
        !testMode &&
        ((!appContext.isFeatureSwitchActive("TealiumClientSide") &&
            !cug2AppContext.isFeatureSwitchActive("TealiumClientSide")) ||
            flowContext.isFlightMove);

    const getOriginIata = (bookingData: BookingData) =>
        bookingData.OutboundJourney?.DepartureStationCode ||
        bookingData.AvailableOutboundJourneys?.[0]?.DepartureStationCode ||
        "n/a";

    const getDestinationIata = (bookingData: BookingData) =>
        bookingData.OutboundJourney?.ArrivalStationCode ||
        bookingData.AvailableOutboundJourneys?.[0]?.ArrivalStationCode ||
        "n/a";

    const getDepartureJourneyDate = (bookingData: BookingData) =>
        bookingData.OutboundJourney?.DepartureDate ||
        bookingData.AvailableOutboundJourneys?.[0]?.DepartureDate?.substring(0, 10) ||
        "n/a";

    const getReturnJourneyDate = (bookingData: BookingData) =>
        bookingData.ReturnJourney?.DepartureDate ||
        bookingData.AvailableReturnJourneys?.[0]?.DepartureDate?.substring(0, 10) ||
        "n/a";

    const getMinFarePriceOutbound = (bookingData: BookingData) =>
        Math.min(...bookingData.AvailableOutboundJourneys?.map((j) => j.FareAmount)).toString();

    const getMinFarePriceInbound = (bookingData: BookingData) =>
        bookingData.AvailableReturnJourneys
            ? Math.min(...bookingData.AvailableReturnJourneys?.map((j) => j.FareAmount)).toString()
            : "n/a";

    const getQPaxCount = (bookingData: BookingData) =>
        (bookingData.PassengersAdultCount || 0) + (bookingData.PassengersChildCount || 0);

    const getTotalFare = (bookingData: BookingData) =>
        ((bookingData.OutboundJourney?.FareAmount || 0) + (bookingData.ReturnJourney?.FareAmount || 0)).toString();

    const getTotalFareUsd = (bookingData: BookingData) =>
        (
            (bookingData.OutboundJourney?.FareAmountUSD || 0) + (bookingData.ReturnJourney?.FareAmountUSD || 0)
        ).toString();

    const getTotalAncillary = (bookingData: BookingData) => bookingData.TotalAncillaryLocal?.toString() || "0";

    const getTotalAncillaryUsd = (bookingData: BookingData) => bookingData.TotalAncillaryUSD?.toString() || "0";

    const getBookingChannelType = (bookingData: BookingData) => bookingData.BookingChannelType || "n/a";

    const getAncillaryAmountByChargeCodesPerJourney = (ancillaries: BookingDataAncillary[], chargeCodes: string[]) =>
        ancillaries
            ? ancillaries.reduce(
                  (agg, ancillary) => agg + (chargeCodes.includes(ancillary.ChargeCode) ? ancillary.Quantity : 0),
                  0,
              )
            : 0;

    const getOutboundBundle = (bookingData: BookingData) => bookingData.OutboundBundleCode || "n/a";

    const getInboundBundle = (bookingData: BookingData) => bookingData.ReturnBundleCode || "n/a";

    const isAncillarySelectedOnJourney = (ancillaries: BookingDataAncillary[], chargeCodes: string[]) =>
        ancillaries?.some((ancillary) => chargeCodes.includes(ancillary.ChargeCode));

    const getAncillaryAmountByChargeCodes = (bookingData: BookingData, chargeCodes: string[]) =>
        bookingData.Passengers?.reduce(
            (aggr, passenger) =>
                aggr +
                getAncillaryAmountByChargeCodesPerJourney(passenger.Ancillaries?.Outbound?.Selected, chargeCodes) +
                getAncillaryAmountByChargeCodesPerJourney(passenger.Ancillaries?.Return?.Selected, chargeCodes),
            0,
        ) || 0;

    const hasAncillaryOnBooking = (bookingData: BookingData, chargeCodes: string[]) =>
        bookingData.Passengers?.some(
            (passenger) =>
                isAncillarySelectedOnJourney(passenger.Ancillaries?.Outbound?.Selected, chargeCodes) ||
                isAncillarySelectedOnJourney(passenger.Ancillaries?.Return?.Selected, chargeCodes),
        )
            ? "1"
            : "0";

    const getBundleCountOnBooking = (
        bookingData: BookingData,
        bundleCodeEnd: typeof SMART_BUNDLE_END | typeof FULL_BUNDLE_END,
    ) =>
        [bookingData.OutboundBundleCode, bookingData.ReturnBundleCode].filter(
            (bundle) => bundle && bundle.substring(3, 4) === bundleCodeEnd,
        ).length * getQPaxCount(bookingData);

    const update = (data: TealiumLog, bookingData: BookingData) => {
        updateFakeUdo(data, bookingData);

        if (data.UpdateRealUdo) window.utag.data = getFakeUdo();

        if (data?.EventName && data.EventParams) {
            updateEvent(data.EventName, data.EventParams, bookingData);
        }
    };
    const getFakeUdo = () => {
        const inStorageData = window.sessionStorage.getItem(storageKey);
        return inStorageData ? JSON.parse(inStorageData) : {};
    };

    const updateFakeUdo = (data: TealiumLog, bookingData: BookingData) => {
        // DEVNOTE Settimeout is used to get Redux state
        // TODO Is this still needed?
        window.setTimeout(() => {
            let fakeUdoData = getFakeUdo();

            fakeUdoData = { ...fakeUdoData, ...window.utag.data };

            if (data.UpdateCommonUdo) {
                fakeUdoData = { ...fakeUdoData, ...updatedCommonUdo(bookingData) };
            }

            if (data?.UdoParams) {
                fakeUdoData = { ...fakeUdoData, ...data.UdoParams };
            }

            if (data?.EventName && data.EventParams) {
                fakeUdoData = { ...fakeUdoData, ...data.EventParams };
            }

            window.sessionStorage.setItem(storageKey, JSON.stringify(fakeUdoData));
        }, 0);
    };

    const updateEvent = <T>(eventName: string, eventParams: T, bookingData: BookingData) => {
        try {
            const newParams = {
                tealium_event: eventName,
                bsid: flowContext.bsid || cug2AppContext.Bsid || "n/a",
                company_identifier: bookingData?.CompanyIdentifier || "n/a",
                promocode: bookingData?.PromotionCode || bookingContext.promoCode || "n/a",
            } as unknown as T;

            (Object.keys(eventParams) as (keyof T)[])
                .filter(
                    (key) =>
                        eventParams[key] ||
                        (eventParams[key] as unknown as boolean) === false ||
                        (eventParams[key] as unknown as number) === 0,
                )
                .forEach((key) => (newParams[key] = eventParams[key]));
            window.utag.link(newParams);

            if (testMode) {
                // eslint-disable-next-line no-console
                console.info("TEALIUM TEST event triggered: '" + eventName + "'", newParams);
            }

            return newParams;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Could not fire Tealium event.", eventName, eventParams);
            return {};
        }
    };

    const updatedCommonUdo = (bookingData: BookingData) => ({
        ...updateBookingInfo(bookingData),
        ...updateEnvironmentInfo(),
        ...updateFlowInfo(),
        ...updateFlightInfo(bookingData),
        ...updatePaxInfo(bookingData),
        ...updateSsrInfo(),
        ...updateUserInfo(bookingData),
    });

    const updateBookingInfo = (bookingData: BookingData) => {
        let data: any = {
            breakdown_due_amount: total || "",
            PNR: bookingContext.pnr || "",
            promocode: bookingData?.PromotionCode || bookingContext.promoCode || "n/a",
        };

        if (bookingData) {
            data = {
                ...data,
                booking_channel_type: getBookingChannelType(bookingData),
                breakdown_ancillaries_amount: bookingData.TotalAncillaryLocal?.toString() || "0",
                breakdown_currency: bookingData.TotalPriceCurrencyLocal,
                breakdown_fare_amount: getTotalFare(bookingData),
                breakdown_tax_amount: bookingData.TotalTaxLocal?.toString() || "0",
                breakdown_total_amount: bookingData.TotalPriceLocal?.toString() || "0",
            };
        }

        return data;
    };

    const updateEnvironmentInfo = () => ({
        culture: appContext.Culture || cug2AppContext.Culture,
        ibe_release: appContext.IbeRelease,
        useragent: window.navigator.userAgent,
    });

    const updateFlowInfo = () => ({ flow_type: getTealiumFlowType() });

    const updateFlightInfo = (bookingData: BookingData) => {
        if (!bookingData) return {};

        const data: any = {};

        if (bookingData.OutboundJourney) {
            data.outbound_arrival_iata = bookingData.OutboundJourney.ArrivalStationCode;
            data.outbound_departure_iata = bookingData.OutboundJourney.DepartureStationCode;
            data.outbound_fare_amount = bookingData.OutboundJourney.FareAmount?.toString() || "0";
            data.outbound_fare_currency_code = bookingData.OutboundJourney.FareCurrencyCode;
            data.outbound_selected_flight = `${bookingData.OutboundJourney.InventoryType}|${bookingData.OutboundJourney.FlightNumber}|${bookingData.OutboundJourney.DepartureDate}|${bookingData.OutboundJourney.ArrivalDate}|${bookingData.OutboundJourney.FareAmount}|${bookingData.OutboundJourney.FareCurrencyCode}`;
        }

        if (bookingData.ReturnJourney) {
            data.inbound_arrival_iata = bookingData.ReturnJourney.ArrivalStationCode;
            data.inbound_departure_iata = bookingData.ReturnJourney.DepartureStationCode;
            data.inbound_fare_amount = bookingData.ReturnJourney.FareAmount?.toString() || "0";
            data.inbound_fare_currency_code = bookingData.ReturnJourney.FareCurrencyCode;
            data.inbound_selected_flight = `${bookingData.ReturnJourney.InventoryType}|${bookingData.ReturnJourney.FlightNumber}|${bookingData.ReturnJourney.DepartureDate}|${bookingData.ReturnJourney.ArrivalDate}|${bookingData.ReturnJourney.FareAmount}|${bookingData.ReturnJourney.FareCurrencyCode}`;
        }

        return data;
    };

    const updatePaxInfo = (bookingData: BookingData) =>
        bookingData
            ? {
                  pax_adult_count: bookingContext.adultsCount?.toString() || "0",
                  pax_children_count: bookingContext.childrenCount?.toString() || "0",
                  pax_infant_count: bookingContext.infantsCount?.toString() || "0",
              }
            : {};

    const updateSsrInfo = () => {
        if (!ssrData) return {};

        try {
            const [
                ssrsCodeAvailableOutbound,
                ssrsCodeIncludedOutbound,
                ssrsCodeAvailableInbound,
                ssrsCodeIncludedInbound,
            ] = ssrData;

            return {
                ssrs_code_available_inbound: ssrsCodeAvailableInbound,
                ssrs_code_available_outbound: ssrsCodeAvailableOutbound,
                ssrs_code_included_inbound: ssrsCodeIncludedInbound,
                ssrs_code_included_outbound: ssrsCodeIncludedOutbound,
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Could not update Tealium UDO with SSR data.");
            return {};
        }
    };

    const updateUserInfo = (bookingData: BookingData) => ({
        company_identifier: bookingData?.CompanyIdentifier || "n/a",
        user_bancoe_level: userContext.bancoEstado.category,
        organization: userContext.cug.orgCode,
        user_dc_level: userContext.dc.dcLevel,
        user_program: userContext.userProgram,
        user_role: userContext.userRole,
    });

    // EXPORTED

    const getDomLoadedData = (bookingData: BookingData) => {
        if (!bookingData) return {};

        return {
            booking_channel_type: getBookingChannelType(bookingData),
            company_identifier: bookingData.CompanyIdentifier ? bookingData.CompanyIdentifier : "n/a",
            inbound_bundle: bookingData.ReturnBundleCode ? bookingData.ReturnBundleCode : "n/a",
            inbound_fare_arrival_date: bookingData.ReturnJourney?.ArrivalDate
                ? dayjs.utc(bookingData.ReturnJourney?.ArrivalDate).format("YYYY-MM-DD")
                : undefined,
            inbound_fare_arrival_iata: bookingData.ReturnJourney?.ArrivalStationCode,
            inbound_fare_currency_code: bookingData.TotalPriceCurrencyLocal,
            inbound_fare_departure_date: bookingData.ReturnJourney?.DepartureDate
                ? dayjs.utc(bookingData.ReturnJourney?.DepartureDate).format("YYYY-MM-DD")
                : undefined,
            inbound_fare_departure_iata: bookingData.ReturnJourney?.DepartureStationCode,
            inbound_fare_price: bookingData.ReturnJourney?.FareAmount,
            outbound_bundle: bookingData.OutboundBundleCode ? bookingData.OutboundBundleCode : "n/a",
            outbound_fare_arrival_date: bookingData.OutboundJourney?.ArrivalDate
                ? dayjs.utc(bookingData.OutboundJourney?.ArrivalDate).format("YYYY-MM-DD")
                : undefined,
            outbound_fare_arrival_iata: bookingData.OutboundJourney?.ArrivalStationCode,
            outbound_fare_currency_code: bookingData.TotalPriceCurrencyLocal,
            outbound_fare_departure_date: bookingData.OutboundJourney?.DepartureDate
                ? dayjs.utc(bookingData.OutboundJourney?.DepartureDate).format("YYYY-MM-DD")
                : undefined,
            outbound_fare_departure_iata: bookingData.OutboundJourney?.DepartureStationCode,
            outbound_fare_price: bookingData.OutboundJourney?.FareAmount,
            pax_adult_count: bookingContext.adultsCount.toString(),
            pax_children_count: bookingContext.childrenCount.toString(),
            pax_infant_count: bookingContext.infantsCount.toString(),
            promocode: bookingData.PromotionCode || bookingContext.promoCode || "n/a",
        };
    };

    const logContinueClicked = async (isPayment = false) => {
        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            if (!bookingData) return;

            let eventParams: any = {
                culture: normalizeCulture(appContext.Culture),
                device: getTealiumDeviceType(),
                origin_iata: getOriginIata(bookingData),
                destination_iata: getDestinationIata(bookingData),
                market: bookingData.RouteMarket,
                q_pax: getQPaxCount(bookingData).toString(),
                departure_journey_date: getDepartureJourneyDate(bookingData),
                return_journey_date: getReturnJourneyDate(bookingData),
                trip_type: bookingData.ReturnJourney ? "R" : "O",
                pax_adult_count: bookingContext.adultsCount.toString(),
                pax_children_count: bookingContext.childrenCount.toString(),
                pax_infant_count: bookingContext.infantsCount.toString(),
                bundle_smart_count: getBundleCountOnBooking(bookingData, SMART_BUNDLE_END).toString(),
                bundle_full_count: getBundleCountOnBooking(bookingData, FULL_BUNDLE_END).toString(),
                total_fare: getTotalFare(bookingData),
                total_fare_usd: getTotalFareUsd(bookingData),
                total_ancillary: getTotalAncillary(bookingData),
                total_ancillary_usd: getTotalAncillaryUsd(bookingData),
                cabin_bag_count: getAncillaryAmountByChargeCodes(bookingData, CABIN_BAG_CODES).toString(),
                checked_bag_count: getAncillaryAmountByChargeCodes(bookingData, CHECKED_BAG_CODES).toString(),
                oversize_bag_count: getAncillaryAmountByChargeCodes(bookingData, OVERSIZED_BAG_CODES).toString(),
                flexismart: hasAncillaryOnBooking(bookingData, FLEXI_FEE_CODES),
                airportcheckin: hasAncillaryOnBooking(bookingData, AIRPORT_CHECKIN_CODES),
                insurance: hasAncillaryOnBooking(bookingData, INSURANCE_FEE_CODES),
                priority_boarding: hasAncillaryOnBooking(bookingData, PRIORITY_BOARDING_CODES),
                pet_on_board: hasAncillaryOnBooking(bookingData, PET_IN_CABIN_CODES),
                seats_count: getAncillaryAmountByChargeCodes(bookingData, [
                    ...SEAT_CODES,
                    ...SEAT_FEE_CODES,
                ]).toString(),
            };

            if (isPayment) {
                eventParams = {
                    ...eventParams,
                    booking_currency: currency,
                    booking_total_amount: total,
                    booking_total_ancillaries: getTotalAncillary(bookingData),
                };
            } else {
                eventParams = {
                    ...eventParams,
                    currency: bookingData.TotalPriceCurrencyLocal || "n/a",
                    quoted_total: bookingData.TotalPriceLocal?.toString() || "0",
                };
            }

            log({
                EventName: isPayment ? "payment_clicked" : "continue_clicked",
                EventParams: eventParams,
                UpdateCommonUdo: true,
                UpdateRealUdo: true,
            });
        });
    };

    const logValidationError = (messages: string[]) =>
        log({
            EventName: "validation_error",
            EventParams: {
                page: window.location.pathname,
                messages,
            },
        });

    const logCurrencyChanged = (currency: string) =>
        log({ EventName: "breakdown_currency_changed", EventParams: { currency } });

    const getTealiumDeviceType = () => {
        const width = ScrollHelper.getWindowWidth();

        if (width < 768) return "mobile";

        if (width < 1024) return "tablet";

        return "desktop";
    };

    const getTealiumFlowType = () => {
        if (flowContext.isCheckinFlow) return "CK";

        if (flowContext.isChangeFlow) return "PB";

        if (flowContext.isBookingFlow || flowContext.isFarelockRoundOne || flowContext.isFarelockRoundTwo) {
            return "DB";
        }

        return "n/a";
    };

    const logDomLoaded = async (
        pageName: "flight" | "passengers" | "baggage" | "seatmap" | "extras" | "payment" | "itinerary",
        data: TealiumLog,
    ) => {
        if (shouldSkipTealiumLogging()) return;

        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            if (!bookingData) return;

            const udoParams = data.UdoParams || {};
            let eventParams: any = {
                culture: normalizeCulture(appContext.Culture),
                device: getTealiumDeviceType(),
                origin_iata: getOriginIata(bookingData),
                destination_iata: getDestinationIata(bookingData),
                flow_type: getTealiumFlowType(),
                market: bookingData.RouteMarket,
                departure_journey_date: getDepartureJourneyDate(bookingData),
                return_journey_date: getReturnJourneyDate(bookingData),
                trip_type: bookingContext.isOneWay ? "O" : "R",
                currency: currency || "n/a",
                pax_adult_count: bookingContext.adultsCount.toString(),
                pax_children_count: bookingContext.childrenCount.toString(),
                pax_infant_count: bookingContext.infantsCount.toString(),
            };

            if (pageName === "flight") {
                eventParams = {
                    ...eventParams,
                    min_fare_price_outbound: getMinFarePriceOutbound(bookingData),
                    min_fare_price_inbound: getMinFarePriceInbound(bookingData),
                };
            } else {
                eventParams = {
                    ...eventParams,
                    q_pax: getQPaxCount(bookingData).toString(),
                    bundle_smart_count: getBundleCountOnBooking(bookingData, SMART_BUNDLE_END).toString(),
                    bundle_full_count: getBundleCountOnBooking(bookingData, FULL_BUNDLE_END).toString(),
                    total_fare: getTotalFare(bookingData),
                    total_fare_usd: getTotalFareUsd(bookingData),
                    total_ancillary: getTotalAncillary(bookingData),
                    total_ancillary_usd: getTotalAncillaryUsd(bookingData),
                    quoted_total: total || "0",
                    outbound_bundle: getOutboundBundle(bookingData),
                    inbound_bundle: getInboundBundle(bookingData),
                };
            }

            if (pageName === "seatmap" || pageName === "extras" || pageName === "payment" || pageName === "itinerary") {
                eventParams = {
                    ...eventParams,
                    cabin_bag_count: getAncillaryAmountByChargeCodes(bookingData, CABIN_BAG_CODES).toString(),
                    checked_bag_count: getAncillaryAmountByChargeCodes(bookingData, CHECKED_BAG_CODES).toString(),
                    oversize_bag_count: getAncillaryAmountByChargeCodes(bookingData, OVERSIZED_BAG_CODES).toString(),
                };
            }

            if (pageName === "extras" || pageName === "payment" || pageName === "itinerary") {
                eventParams = {
                    ...eventParams,
                    seats_count: getAncillaryAmountByChargeCodes(bookingData, [
                        ...SEAT_CODES,
                        ...SEAT_FEE_CODES,
                    ]).toString(),
                };
            }

            if (pageName === "payment" || pageName === "itinerary") {
                eventParams = {
                    ...eventParams,
                    flexismart: hasAncillaryOnBooking(bookingData, FLEXI_FEE_CODES),
                    airportcheckin: hasAncillaryOnBooking(bookingData, AIRPORT_CHECKIN_CODES),
                    insurance: hasAncillaryOnBooking(bookingData, INSURANCE_FEE_CODES),
                    priority_boarding: hasAncillaryOnBooking(bookingData, PRIORITY_BOARDING_CODES),
                    pet_on_board: hasAncillaryOnBooking(bookingData, PET_IN_CABIN_CODES),
                };
            }

            if (pageName === "itinerary") {
                eventParams = {
                    ...eventParams,
                    contact_email: bookingData.Contact.Email || "n/a",
                    booking_channel_type: getBookingChannelType(bookingData),
                    pnr: bookingData.PNR || "n/a",
                };
            }

            log({
                EventName: `${pageName}_dom_loaded`,
                EventParams: eventParams,
                UdoParams: udoParams,
                UpdateCommonUdo: true,
                UpdateRealUdo: true,
            });
        });
    };

    const log = async (data: TealiumLog) => {
        if (shouldSkipTealiumLogging()) return;

        // eslint-disable-next-line no-console
        if (testMode) console.info("TEALIUM TEST log", data);

        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            if (window.utag) {
                update(data, bookingData);
                return;
            }

            const tealiumInterval = window.setInterval(() => {
                if (!window.utag) return;

                window.clearInterval(tealiumInterval);
                window.clearTimeout(tealiumTimeLimit);
                update(data, bookingData);
            }, 100);

            const tealiumTimeLimit = window.setTimeout(() => {
                window.clearInterval(tealiumInterval);
                // eslint-disable-next-line no-console
                console.error("Could not access Tealium.");
            }, 3000);
        });
    };

    return {
        getDomLoadedData,
        getTealiumDeviceType,
        getTealiumFlowType,
        log,
        logContinueClicked,
        logCurrencyChanged,
        logDomLoaded,
        logValidationError,
    };
};

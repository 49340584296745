import { SeatmapJourney } from "./../../component-models/seatmap/SeatmapJourney";
import { SeatmapState } from "./../../component-models/seatmap/SeatmapState";
import { SeatmapModel } from "./../../component-models/seatmap/SeatmapModel";
import { ApiSeatmapPassenger } from "../../component-models/seatmap/ApiSeatmapPassenger";
import i18next from "i18next";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { ExtendedApiSeat } from "../../component-models/seatmap/ApiSeat";
import { SeatmapContext } from "../../component-models/seatmap/SeatmapContext";

export class SeatmapHelper {
    public static isRecommendatorOnForSegment(
        journey: SeatmapJourney,
        segmentIndex: number,
        isBancoEstadoCategory0: boolean,
        isBookingFlow: boolean,
        hasFlightColombianStation: boolean,
    ) {
        return (
            // DEVNOTE If this is ever allowed for multisegment flights, just remove the next 2 conditions
            journey?.Segments.length === 1 &&
            segmentIndex === 0 &&
            isBancoEstadoCategory0 &&
            isBookingFlow &&
            journey?.Segments[segmentIndex]?.SeatMapRecommendation?.length > 0 &&
            !journey?.AreAllSeatsInBundle &&
            !hasFlightColombianStation
        );
    }

    public static recommendatorLines(context: SeatmapContext, seatmapState: SeatmapState) {
        if (!seatmapState) return ["", "", ""];

        const [recommendatorLine1, recommendatorLine2, recommendatorLine3] = [
            SeatmapHelper.recommendatorLine1(seatmapState.CurrentSegment.SeatMapRecommendation),
            SeatmapHelper.recommendatorLine2({
                passengers: context.Model.Passengers,
                context,
                currentSeats: context.Model.Journeys.reduce(
                    (allSeats, j) =>
                        allSeats.concat(
                            j.Segments.reduce((journeySeats, segment) => journeySeats.concat(segment.Seats), []),
                        ),
                    [],
                ),

                seatmapState,
            }),
            SeatmapHelper.recommendatorLine3(seatmapState.CurrentSegment.SeatMapRecommendation),
        ];

        return [recommendatorLine1, recommendatorLine2, recommendatorLine3];
    }

    public static showSeatAutoAssignmentButton(context: SeatmapContext, seatmapState: SeatmapState) {
        return (
            context.CheckinFlowType === "notAllSeated" &&
            seatmapState.CurrentJourney.JourneyIndex === context.Model.CheckedJourneyIndex &&
            !seatmapState.CurrentJourney.AreAllSeatsInBundle
        );
    }

    public static showOutbound(model: SeatmapModel): boolean {
        return model?.ShownJourneys.includes(OUTBOUND);
    }
    public static showInbound(model: SeatmapModel): boolean {
        return model?.ShownJourneys.includes(INBOUND);
    }

    public static getRowAndColumnFromDesignator(designator: string) {
        if (!designator) return undefined;

        const splitDesignator = designator.split("");
        const column = splitDesignator.pop();

        return { row: Number(splitDesignator.join("")), column };
    }

    public static showResetSeatsButton(context: SeatmapContext, currentSeats: ExtendedApiSeat[]) {
        return context.CheckinFlowType === "allSeated" && SeatmapHelper.hasSeatChanges(currentSeats);
    }

    public static hasSeatChangesOnLastLeg(
        context: SeatmapContext,
        seatmapState: SeatmapState,
        currentSeats: ExtendedApiSeat[],
    ) {
        if (!seatmapState?.CurrentJourney) return false;
        const lastLegIndex = this.showInbound(context.Model) ? INBOUND : OUTBOUND;
        const isLastLeg = seatmapState.CurrentJourney.JourneyIndex === lastLegIndex;

        const hasChanges = currentSeats.some(
            (s) =>
                s.JourneyIndex === lastLegIndex &&
                s.PassengerNumber > -1 &&
                s.PassengerNumber !== s.OriginalPassengerNumber,
        );

        return isLastLeg && hasChanges;
    }

    public static showFreeSeatInfo(data: {
        context: SeatmapContext;
        seatmapState: SeatmapState;
        isBookingFlow: boolean;
        isCategory6: boolean;
        freeSeatsNotOnBooking: number;
    }) {
        if (!data.isBookingFlow || data.seatmapState?.CurrentJourney.AreAllSeatsInBundle) {
            return false;
        }

        return data.isCategory6 && data.freeSeatsNotOnBooking + data.context.Model?.FreeSeatsInBooking > 0;
    }

    public static showDiscountedSeatInfo(
        context: SeatmapContext,
        seatmapState: SeatmapState,
        isBookingFlow: boolean,
        isCategory6: boolean,
        isCategory5: boolean,
        freeSeatsNotOnBooking: number,
    ) {
        if (!isBookingFlow || seatmapState?.CurrentJourney.AreAllSeatsInBundle) {
            return false;
        }

        return (isCategory6 && freeSeatsNotOnBooking + context.Model.FreeSeatsInBooking === 0) || isCategory5;
    }

    public static showAllBancoEstadoSeatsTaken(
        model: SeatmapModel,
        journeyIndex: number,
        segmentIndex: number,
        isCategory6: boolean,
        isCategory12357: boolean,
        freeSeatsNotOnBooking: number,
    ): boolean {
        if (!model || journeyIndex === undefined) return false;

        const showSecondaryZoneInfo = SeatmapHelper.showBancoEstadoCategory6SecondaryZoneInfo(
            model,
            journeyIndex,
            segmentIndex,
            isCategory6,
            freeSeatsNotOnBooking,
        );
        const isBancoEstadoCategory6WithNoSeats = isCategory6 && freeSeatsNotOnBooking === 0;

        return (
            model.Journeys[journeyIndex].Segments[segmentIndex].AreAllBancoEstadoSeatsSold &&
            (isCategory12357 || (isBancoEstadoCategory6WithNoSeats && !showSecondaryZoneInfo))
        );
    }

    public static showBancoEstadoCategory6SecondaryZoneInfo(
        model: SeatmapModel,
        journeyIndex: number,
        segmentIndex: number,
        isCategory6: boolean,
        freeSeatsNotOnBooking: number,
    ): boolean {
        if (
            !isCategory6 ||
            freeSeatsNotOnBooking + model.FreeSeatsInBooking === 0 ||
            model.Journeys[journeyIndex].AreAllSeatsInBundle
        ) {
            return false;
        }

        return model.Journeys[journeyIndex].Segments[segmentIndex].OpenSecondaryBancoEstadoZone;
    }

    public static isContinueBtnDisabled(
        context: SeatmapContext,
        seatmapState: SeatmapState,
        currentSeats: ExtendedApiSeat[],
    ): boolean {
        return (
            context.CheckinFlowType === "notAllSeated" &&
            SeatmapHelper.notAllSeated(context, seatmapState, currentSeats)
        );
    }

    public static getFreeSeatsUsedInBooking(seats: ExtendedApiSeat[]): number {
        return seats.filter((s) => s.IsFree && s.PassengerNumber >= 0).length;
    }

    public static remainingAvailableFreeSeatsForBooking(data: {
        freeSeatsInBooking: number;
        currentSeats: ExtendedApiSeat[];
        freeSeatsNotOnBooking: number;
    }): number {
        return (
            data.freeSeatsNotOnBooking +
            data.freeSeatsInBooking -
            SeatmapHelper.getFreeSeatsUsedInBooking(data.currentSeats)
        );
    }

    public static freeSeatsUsedForSegment(
        seats: ExtendedApiSeat[],
        journeyIndex: number,
        segmentIndex: number,
    ): string[] {
        return seats
            .filter(
                (seat) =>
                    seat.IsFree &&
                    seat.JourneyIndex === journeyIndex &&
                    seat.SegmentIndex === segmentIndex &&
                    seat.PassengerNumber >= 0,
            )
            .map((seat) => `${seat.Row}${seat.Column}`);
    }

    public static doAllPaxHaveSeats(
        seats: ExtendedApiSeat[],
        journey: SeatmapJourney,
        apiPassengers: ApiSeatmapPassenger[],
    ): boolean {
        return apiPassengers?.every((passenger) => {
            return this.paxHasSeat(seats, passenger, journey);
        });
    }

    public static journeyTotalPriceDifference(
        seats: ExtendedApiSeat[],
        journey: SeatmapJourney,
        isCategory6: boolean,
    ): number {
        const difference =
            seats && journey
                ? seats
                      .filter(
                          (seat) =>
                              seat.JourneyIndex === journey.JourneyIndex &&
                              !seat.IsFree &&
                              !seat.IsAutoAssigned &&
                              seat.PassengerNumber >= 0,
                      )
                      .reduce(
                          (journeyDifference, seat) =>
                              journeyDifference +
                              this.findPriceDifferenceForPaxForSegment(
                                  seats,
                                  seat.PassengerNumber,
                                  seat.JourneyIndex,
                                  seat.SegmentIndex,
                                  isCategory6,
                              ),
                          0,
                      )
                : 0;

        return difference > 0 ? difference : 0;
    }

    public static findOriginalPriceForPaxForSegment(
        seats: ExtendedApiSeat[],
        passenger: number,
        journeyIndex: number,
        segmentIndex: number,
        isCategory6: boolean,
    ): number {
        const seat = seats.find(
            (s) =>
                s.OriginalPassengerNumber === passenger &&
                s.JourneyIndex === journeyIndex &&
                s.SegmentIndex === segmentIndex,
        );

        if (seat?.IsFree && isCategory6) {
            return 0;
        }

        if (seat?.IsAutoAssigned) {
            return 0;
        }

        if (seat?.IsSeatFreeForPax) {
            return 0;
        }

        return seat ? seat.Price : 0;
    }

    public static isAnySelectedSeatInExitRow(seats: ExtendedApiSeat[]): boolean {
        return seats?.some((seat) => seat.IsInExitRow && seat.PassengerNumber >= 0);
    }

    public static recommendedSeatNumberForPax(paxIndex: number, seatmapState: SeatmapState): string {
        return seatmapState.CurrentSegment.SeatMapRecommendation
            ? seatmapState.CurrentSegment.SeatMapRecommendation[paxIndex]
            : "";
    }

    public static numberOfDiscountedSeatsUsedInBooking(seats: ExtendedApiSeat[]): number {
        return seats.filter((seat) => seat.IsDiscounted && !seat.IsFree && seat.PassengerNumber >= 0).length;
    }

    private static recommendatorLine1(recommendedSeats: string[]): string {
        const seats = recommendedSeats?.join(", ");
        return recommendedSeats?.length > 1
            ? i18next.t("Te recomendamos los asientos {{seats}}", {
                  seats,
              })
            : i18next.t("Te recomendamos el asiento {{seats}}", { seats });
    }

    private static recommendatorLine2(data: {
        passengers: ApiSeatmapPassenger[];
        context: SeatmapContext;
        seatmapState: SeatmapState;
        currentSeats: ExtendedApiSeat[];
    }): string {
        const categories = SeatmapHelper.getRecommendedSeatCategories(data);

        if (categories.size > 1) {
            return "";
        }

        switch (Array.from(categories.values())[0]) {
            case 4:
                return i18next.t(
                    "tendrás más espacio y comodidad. Es la mejor opción si buscas viajar con full comodidad para estirar las piernas o trabajar mientras vuelas.",
                );
            case 3:
                return data.passengers.length > 1
                    ? i18next.t("¡son perfectos para un desembarque rápido!")
                    : i18next.t("¡es perfecto para un desembarque rápido!");
            case 2:
                return i18next.t("para que ahorres tiempo en subir y descender del avión");
            case 1:
                return i18next.t(
                    "para que vueles en primera fila, con full comodidad y con más espacio para estirar las piernas.",
                );
            default:
                return "";
        }
    }

    private static recommendatorLine3(recommendedSeats: string[]): string {
        return recommendedSeats?.length > 1
            ? i18next.t("¡Elige todos tus asientos con solo un click!")
            : i18next.t("¡Elige tu asiento con solo un click!");
    }

    private static paxHasSeat(
        seats: ExtendedApiSeat[],
        passenger: ApiSeatmapPassenger,
        journey: SeatmapJourney,
    ): boolean {
        return journey?.Segments.every((segment) =>
            seats.some(
                (s) =>
                    s.PassengerNumber === passenger.PassengerNumber &&
                    s.JourneyIndex === journey.JourneyIndex &&
                    s.SegmentIndex === segment.SegmentIndex,
            ),
        );
    }

    private static findPriceDifferenceForPaxForSegment(
        seats: ExtendedApiSeat[],
        passenger: number,
        journeyIndex: number,
        segmentIndex: number,
        isCategory6: boolean,
    ): number {
        const originalPrice = this.findOriginalPriceForPaxForSegment(
            seats,
            passenger,
            journeyIndex,
            segmentIndex,
            isCategory6,
        );
        const price = this.findPriceForPaxForSegmentForPriceDifference(seats, passenger, journeyIndex, segmentIndex);

        return price - originalPrice;
    }

    private static findPriceForPaxForSegmentForPriceDifference(
        seats: ExtendedApiSeat[],
        passengerNumber: number,
        journeyIndex: number,
        segmentIndex: number,
    ): number {
        const seat = seats.find(
            (s) =>
                s.PassengerNumber === passengerNumber &&
                s.JourneyIndex === journeyIndex &&
                s.SegmentIndex === segmentIndex,
        );

        // TODO CFS Why was this here?
        // if (passengerNumber === seat?.OriginalPassengerNumber) {
        //     return 0;
        // }

        const retVal = seat?.Price;

        return retVal ? retVal : 0;
    }

    private static getRecommendedSeatCategories(data: {
        context: SeatmapContext;
        seatmapState: SeatmapState;
        currentSeats: ExtendedApiSeat[];
    }) {
        const categories = new Set<number>();
        data.context.Model.Passengers?.forEach((pax) => {
            const seatCategory = data.currentSeats.find(
                (s) =>
                    s.JourneyIndex === data.seatmapState.CurrentJourney.JourneyIndex &&
                    s.SegmentIndex === data.seatmapState.CurrentSegment.SegmentIndex &&
                    `${s.Row}${s.Column}` ===
                        SeatmapHelper.recommendedSeatNumberForPax(pax.PassengerNumber, data.seatmapState),
            )?.CategoryNumber;
            categories.add(seatCategory);
        });

        return categories;
    }

    private static notAllSeated(
        context: SeatmapContext,
        seatmapState: SeatmapState,
        currentSeats: ExtendedApiSeat[],
    ): boolean {
        return (
            seatmapState.CurrentJourney.JourneyIndex === context.Model.CheckedJourneyIndex &&
            !SeatmapHelper.doAllPaxHaveSeats(currentSeats, seatmapState.CurrentJourney, context.Model.Passengers)
        );
    }

    private static hasSeatChanges(seats: ExtendedApiSeat[]): boolean {
        return seats ? seats.some((s) => s.OriginalPassengerNumber !== s.PassengerNumber) : false;
    }
}

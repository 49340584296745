import { html } from "lit-html";
import { useModal } from "../shared/useModal";
import i18next from "i18next";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";

export const useGiftcardFlowBlockedModal = () => {
    const [userContext] = useReduxState("userContext");

    const init = () => {
        if (userContext.cug.isMember) modal.open();
    };

    useEffect(init, []);

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <span>${i18next.t("Gift-BlockModalBody")}</span>
            </div>
            <div class="primary-modal-button-container">
                <a class="rounded-primary-btn" href="/" data-test-id="gc-blocked-button">
                    ${i18next.t("Gift-BlockModalButton")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => html`<span>${i18next.t("Gift-BlockModalTitle")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

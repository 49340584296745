import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import { useEffect, useState } from "./../../shared/haunted/CustomHooks";
import { ROUTES } from "./../../shared/apiRoutes";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef } from "haunted";
import {
    getAntiforgerySegment,
    getCoords,
    getParsedProperty,
    getUrlVars,
    redoSearch,
    toBoolean,
} from "../../shared/common";
import { ApiTripCalendar } from "../../component-models/flight-calendar/ApiTripCalendar";
import i18next from "i18next";
import { mapToTripCalendar } from "../../component-mappers/FlightCalendarMapper";
import {
    CHANGING_CURRENCY_URL_ELEMENT,
    DEFAULT_DATE_FORMAT,
    INBOUND,
    OUTBOUND,
    URL_VARS,
} from "../../shared/commonConstants";
import { Month, TripCalendar, Week } from "../../component-models/flight-calendar/TripCalendar";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { DaySelectEvent, MonthSelectEvent } from "../../component-models/flight-calendar/FlightCalendarEvents";
import { ref } from "../../directives/ref";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-flight-calendar-page";

export const observedAttributes: (keyof Attributes)[] = [
    "anti-forgery-token",
    "day-names",
    "destination",
    "inbound-availability",
    "is-one-way",
    "number-of-adults",
    "number-of-children",
    "number-of-infants",
    "origin",
    "outbound-availability",
    "passenger-query",
    "short-day-names",
    "trip-calendars",
];

export interface Attributes {
    "anti-forgery-token": string;
    "day-names": string;
    "destination": string;
    "inbound-availability": string;
    "is-one-way": string;
    "number-of-adults": string;
    "number-of-children": string;
    "number-of-infants": string;
    "origin": string;
    "outbound-availability": string;
    "passenger-query": string;
    "short-day-names": string;
    "trip-calendars": string;
}

export interface FlightCalendarProps {
    antiForgeryToken: string;
    dayNames: string[];
    destination: string;
    inboundAvailability: string;
    isOneWay: boolean;
    numberOfAdults: number;
    numberOfChildren: number;
    numberOfInfants: number;
    origin: string;
    outboundAvailability: string;
    passengerQuery: string;
    shortDayNames: string[];
    tripCalendars: TripCalendar[];
}

export const Component: HauntedFunc<FlightCalendarProps> = (host) => {
    const props: FlightCalendarProps = {
        antiForgeryToken: host.antiForgeryToken,
        dayNames: (host.dayNames as unknown as string).split("|"),
        destination: host.destination,
        inboundAvailability: host.inboundAvailability,
        isOneWay: toBoolean(host.isOneWay),
        numberOfAdults: Number(host.numberOfAdults),
        numberOfChildren: Number(host.numberOfChildren),
        numberOfInfants: Number(host.numberOfInfants),
        origin: host.origin,
        outboundAvailability: host.outboundAvailability,
        passengerQuery: host.passengerQuery,
        shortDayNames: (host.shortDayNames as unknown as string)
            .split("|")
            .map((dn) => dn.toLowerCase().replace(".", "")),
        tripCalendars:
            typeof host.tripCalendars === "string"
                ? getParsedProperty<ApiTripCalendar[]>(host.tripCalendars).map((c) => mapToTripCalendar(c))
                : [],
    };

    const init = async () => {
        const urlCurrency = getUrlVars()[URL_VARS.CURRENCY]?.[0] || currency;

        // DEVNOTE This is needed to remove loader from total
        const [baseUrl, queryString] = window.location.href.split("?");

        const newQuery = queryString
            ?.split("&")
            .filter((elem) => elem !== CHANGING_CURRENCY_URL_ELEMENT)
            .join("&");

        const newUrl = newQuery ? `${baseUrl}?${newQuery}` : baseUrl;

        history.replaceState({}, null, newUrl);
        window.setTimeout(
            () =>
                triggers.sidebar.flightSelectionChanged.publish({
                    selectedCurrency: currency,
                    flightState: undefined,
                    bundleState: undefined,
                    dcState: undefined,
                }),
            0,
        );
        setCurrency(urlCurrency);

        // EOF This is needed to remove loader from total
    };

    const handleSubmit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        redoSearch({
            isOneWay: props.isOneWay,
            origin: props.origin,
            destination: props.destination,
            unformattedOutboundDate: selectedOutboundDate.format(DEFAULT_DATE_FORMAT),
            unformattedInboundDate: !props.isOneWay ? selectedInboundDate.format(DEFAULT_DATE_FORMAT) : undefined,
            adults: props.numberOfAdults.toString(),
            children: props.numberOfChildren.toString(),
            infants: props.numberOfInfants.toString(),
            promoCode: bookingContext.promoCode,
            culture: appContext.Culture,
            currency,
        });
    };

    const handleSelectMonth = (e: MonthSelectEvent) => {
        let outboundDate = selectedOutboundDate;
        let inboundDate = selectedInboundDate;

        if (e.detail.calendarIndex === OUTBOUND) {
            outboundDate = dayjs(e.detail.date);

            if (!props.isOneWay && selectedInboundDate && selectedInboundDate.isBefore(e.detail.date, "day")) {
                inboundDate = dayjs(e.detail.date);
            }
        } else {
            inboundDate = dayjs(e.detail.date);

            if (inboundDate.isBefore(outboundDate, "day")) {
                inboundDate = dayjs(outboundDate);
            }
        }

        redoSearch({
            isOneWay: props.isOneWay,
            origin: props.origin,
            destination: props.destination,
            unformattedOutboundDate: outboundDate.format(DEFAULT_DATE_FORMAT),
            unformattedInboundDate: !props.isOneWay ? inboundDate.format(DEFAULT_DATE_FORMAT) : undefined,
            adults: props.numberOfAdults.toString(),
            children: props.numberOfChildren.toString(),
            infants: props.numberOfInfants.toString(),
            promoCode: bookingContext.promoCode,
            culture: appContext.Culture,
            currency,
            isCalendarView: true,
        });
    };

    const handleSelectDay = (e: DaySelectEvent) => {
        if (e.detail.calendarIndex === OUTBOUND) {
            setSelectedOutboundDate(dayjs(e.detail.day.Date));

            if (!props.isOneWay && selectedInboundDate && selectedInboundDate.isBefore(e.detail.day.Date, "day")) {
                setSelectedInboundDate(dayjs(e.detail.day.Date));
            }
        } else {
            setSelectedInboundDate(dayjs(e.detail.day.Date));
        }

        if (e.detail.calendarIndex === OUTBOUND && !props.isOneWay && inboundCalendar.current) {
            scroller(inboundCalendar.current);
        } else if (submitButton.current) {
            scroller(submitButton.current);
        }
    };

    const scroller = (elem: HTMLElement) => {
        const topOfElement = getCoords(elem).top - 100;
        window.scroll({
            top: topOfElement,
            behavior: "smooth",
        });
    };

    const initialDate = (direction: number) => {
        if (props.isOneWay && direction === INBOUND) {
            return undefined;
        }

        const calendar = props.tripCalendars[direction];
        const month = calendar?.Months.find((m) => m.IsSelectedMonth);
        const selectedDate = calendar.SelectedDay;

        if (getSelectedDateInCalendar(month, selectedDate)?.HasAmount) {
            return selectedDate;
        }

        return getFirstDateWithAmount(month) || getFirstDateInMonth(month);
    };

    const getSelectedDateInCalendar = (month: Month, selectedDate: dayjs.Dayjs) =>
        month?.Weeks.find((w) => w.Days.some((d) => d.Date.isSame(selectedDate, "day")))?.Days.find((d) =>
            d.Date.isSame(selectedDate, "day"),
        );

    const getFirstDateWithAmount = (month: Month) => getFirstDayWithAmount(getFirstWeekWithAmount(month))?.Date;

    const getFirstWeekWithAmount = (month: Month) => month?.Weeks.find((w) => w.Days.some((d) => d.HasAmount));

    const getFirstDayWithAmount = (week: Week) => week?.Days.find((d) => d.HasAmount);

    const getFirstDateInMonth = (month: Month) =>
        month.Weeks[0].Days.find((d) => d.Date.get("M") === month.Date.get("M"))?.Date;

    const inboundCalendar = useRef<HTMLDivElement>(undefined);
    const submitButton = useRef<HTMLButtonElement>(undefined);

    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const [currency, setCurrency] = useReduxState("booking.currency");

    const [selectedOutboundDate, setSelectedOutboundDate] = useState<dayjs.Dayjs>(initialDate(OUTBOUND));
    const [selectedInboundDate, setSelectedInboundDate] = useState<dayjs.Dayjs>(initialDate(INBOUND));

    const { triggers } = usePubSub();

    useEffect(init, []);

    const calendarsTemplate = () =>
        props.tripCalendars.map(
            (tripCalendar, i) => html`
                <div ref=${i === INBOUND ? ref(inboundCalendar) : undefined}>
                    <ac-flight-calendar-journey
                        .model=${tripCalendar}
                        .calendarIndex=${i}
                        .context=${props}
                        .selectedOutboundDate=${selectedOutboundDate}
                        .selectedInboundDate=${selectedInboundDate}
                        @selectDay=${handleSelectDay}
                        @selectMonth=${handleSelectMonth}
                    ></ac-flight-calendar-journey>
                </div>
            `,
        );

    const mobileLegendTemplate = () => html`
        <div class="row visible-xs">
            <div class="col-sm-1">
                <div class="calendar-legend">
                    <span>${i18next.t("CalendarLegend-Cheapest")}</span>
                    <span>${i18next.t("CalendarLegend-Smart")}</span>
                </div>
            </div>
        </div>
    `;

    const buttonTemplate = () => html`
        <div class="calendar-button-container">
            <button
                class="rounded-primary-btn booking"
                ref=${ref(submitButton)}
                @click=${handleSubmit}
                data-test-id=${T.FLIGHT_CALENDAR.SUBMIT_BUTTON}
            >
                ${i18next.t("V2-Continue")}
            </button>
        </div>
    `;

    return html`
        <form action=${ROUTES.PostCalendar} method="POST">
            ${getAntiforgerySegment(props.antiForgeryToken)} ${calendarsTemplate()} ${mobileLegendTemplate()}
            ${buttonTemplate()}
            <input type="hidden" name="availability.IsCalendar" value="true" />
        </form>
    `;
};

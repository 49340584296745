import { useRef } from "haunted";
import { useEffect, useState } from "./CustomHooks";

export const useDebouncedValue = <T>(value: T, delay = 500): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = window.setTimeout(() => setDebouncedValue(value as any), delay);

        return () => {
            window.clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return debouncedValue as T;
};

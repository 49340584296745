import { getUrlVars, getUrlVarsAsQueryString } from "../../shared/common";
import { URL_VARS } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";

export const useSpaHistory = () => {
    const getUrlHistory = () => {
        const urlHistory = getUrlVars()[URL_VARS.SPA_HISTORY] as string;

        if (!urlHistory || urlHistory.length === 0) return [];

        return atob(urlHistory[0])
            .split("")
            .map((item) => Number(item));
    };

    const [spaHistory, setSpaHistory] = useState<number[]>(getUrlHistory());

    const getCustomUrlParams = () => {
        const query = getUrlVarsAsQueryString([URL_VARS.SPA_HISTORY]);
        return query ? `&${query}` : "";
    };

    const updateUrl = () => {
        if (!spaHistory) return;

        const baseUrl = window.location.href.split("?")[0];
        const historyQuery = `${URL_VARS.SPA_HISTORY}=${btoa(spaHistory.join(""))}`;
        history.replaceState({ page: history.state?.page }, null, `${baseUrl}?${historyQuery}${getCustomUrlParams()}`);
    };

    useEffect(updateUrl, [spaHistory]);

    // EXPORTS

    const add = (pageIndex: number) => setSpaHistory([...spaHistory, pageIndex]);

    const remove = () => {
        setSpaHistory(spaHistory.slice(0, -1));
    };

    return {
        history: spaHistory,
        add,
        remove,
    };
};

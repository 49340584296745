import i18next from "i18next";
import { html } from "lit-html";
import {
    CULTURES_WITH_DECIMAL_DOT,
    CURRENCIES_WITH_DECIMALS,
    useNumberFormatter,
} from "../../shared/useNumberFormatter";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { isNumber } from "util";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useAppContext } from "../../managers/useAppContext";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { useState } from "../../shared/haunted/CustomHooks";

export interface Props {
    isValidated: boolean;
    model: PaymentPageViewModel;
}

export interface AgencyPaymentVM {
    selectedAgencyPaymentAmount: string;
}

type FieldNames = keyof AgencyPaymentVM;

export const useAgencyForm = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const [vm, setVm] = useState<AgencyPaymentVM>({ selectedAgencyPaymentAmount: "" });

    const validator = useFluentValidator<FieldNames, AgencyPaymentVM>({
        vm,
        validated: props.isValidated,
        validations: [
            Validation.ruleFor("selectedAgencyPaymentAmount", (vm: AgencyPaymentVM) => vm.selectedAgencyPaymentAmount)
                .isRequired()
                .fulfils(
                    async () =>
                        Promise.resolve(
                            !isSelectedAmountValid() ||
                                props.model.AgencyViewModel.AvailableUnformattedAmount >=
                                    sanitizeInputFieldAmount(vm.selectedAgencyPaymentAmount),
                        ),
                    i18next.t("V2-InsufficientPrepayment"),
                )
                .fulfils(
                    async () => Promise.resolve(isSelectedAmountValid()),
                    i18next.t("Por favor ingresa un monto válido"),
                ),
        ],
    });

    const sanitizeInputFieldAmount = (value: string) => Number(value.replace(",", "."));

    const isSelectedAmountValid = () => {
        const sanitizedInputValue = sanitizeInputFieldAmount(vm.selectedAgencyPaymentAmount);

        return (
            acceptedDecimalSignRegEx().test(vm.selectedAgencyPaymentAmount) &&
            isNumber(sanitizedInputValue) &&
            sanitizedInputValue > 0 &&
            props.model.AgencyViewModel.QuotedUnformattedAmount >= sanitizedInputValue
        );
    };

    const agencyPaymentInitialValue = () =>
        props.model
            ? formatNumber({
                  amount:
                      props.model.AgencyViewModel.AvailableUnformattedAmount <
                      props.model.AgencyViewModel.QuotedUnformattedAmount
                          ? props.model.AgencyViewModel.AvailableUnformattedAmount
                          : props.model.AgencyViewModel.QuotedUnformattedAmount,

                  leadingSign: false,
                  omitGrouping: true,
              })
            : "0";

    const acceptedDecimalSignRegEx = () => {
        if (!CURRENCIES_WITH_DECIMALS.includes(currency)) {
            return /^\d+$/;
        }
        return CULTURES_WITH_DECIMAL_DOT.includes(appContext.Culture) ? /^\d+\.?\d*$/ : /^\d+\,?\d*$/;
    };

    const agencyAmountInputTemplate = () =>
        !flowContext.isFarelockRoundOne
            ? html`
                  <div class="mt-[20px]">
                      <ac-input
                          .errorMessage=${validator.getMessage("selectedAgencyPaymentAmount")}
                          .isInvalid=${!validator.isValid("selectedAgencyPaymentAmount")}
                          .label=${`${i18next.t("Monto")} *`}
                          .autoComplete=${"off"}
                          .value=${vm.selectedAgencyPaymentAmount}
                          .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric")}
                          .onInput=${(value: string) => setVm({ ...vm, selectedAgencyPaymentAmount: value })}
                      ></ac-input>
                  </div>
              `
            : "";

    const agencyAvailableAmountTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .label=${i18next.t("V2-PrepaidAmount")}
                .isReadonly=${true}
                .value=${formatNumber({
                    amount: props.model.AgencyViewModel.AvailableUnformattedAmount,
                    leadingSign: false,
                })}
            ></ac-input>
        </div>
    `;

    const agencyQuotedAmountTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .label=${i18next.t("V2-Charge")}
                .isReadonly=${true}
                .value=${formatNumber({
                    amount: props.model.AgencyViewModel.QuotedUnformattedAmount,
                    leadingSign: false,
                })}
            ></ac-input>
        </div>
    `;

    const htmlTemplate = () => html`
        <form>
            ${!(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking)
                ? html`
                      <div class="row">
                          <div class="col-xs-1 col-sm-1-2">${agencyAvailableAmountTemplate()}</div>
                          <div class="col-xs-1 col-sm-1-2">${agencyQuotedAmountTemplate()}</div>
                      </div>
                      <div class="row">
                          <div class="col-xs-1 col-sm-1-2">${agencyAmountInputTemplate()}</div>
                          <div class="col-xs-1 col-sm-1-2">
                              <div class="agency-partial-payment-info-container">
                                  <span>
                                      <!-- TODO Move the svg from here -->
                                      <svg
                                          width="35"
                                          height="36"
                                          viewBox="0 0 35 36"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_53_2135)">
                                              <path
                                                  d="M21.2702 24.861L21.0712 25.901C21.0712 25.901 19.3162 26.8468 17.5227 26.8468C15.7292 26.8468 14.1877 25.5441 14.8576 23.1563C14.8589 23.1497 14.8616 23.1444 14.8629 23.1378L15.9626 19.2801C16.5503 17.22 15.9494 16.7026 15.0579 16.7026C14.1665 16.7026 13.5244 17.0342 13.5244 17.0342L13.7685 16.0101C15.2091 15.388 16.2956 15.1293 17.1512 15.1134C18.0122 15.0975 18.641 15.3283 19.1623 15.6865C20.2037 16.4001 20.071 17.9071 19.9052 18.4855C19.8017 18.8503 19.738 19.0718 19.7062 19.1846L19.6916 19.2377C19.6876 19.2523 19.685 19.2629 19.6823 19.2695L19.6797 19.2801L18.7497 22.5421C18.1992 24.4749 18.5508 25.178 19.7009 25.178C20.851 25.178 21.2702 24.861 21.2702 24.861Z"
                                                  fill="#0F416E"
                                              />
                                              <path
                                                  d="M19.2284 13.2894C20.4578 13.2894 21.4544 12.3872 21.4544 11.2743C21.4544 10.1614 20.4578 9.25928 19.2284 9.25928C17.999 9.25928 17.0024 10.1614 17.0024 11.2743C17.0024 12.3872 17.999 13.2894 19.2284 13.2894Z"
                                                  fill="#0F416E"
                                              />
                                              <path
                                                  d="M17.5 2.09187C21.749 2.09187 25.7446 3.7461 28.7492 6.75076C31.7539 9.75542 33.4081 13.7497 33.4081 18C33.4081 22.2503 31.7539 26.2446 28.7492 29.2492C25.7446 32.2539 21.7503 33.9081 17.5 33.9081C13.2497 33.9081 9.25542 32.2539 6.25076 29.2492C3.2461 26.2446 1.59187 22.2503 1.59187 18C1.59187 13.7497 3.2461 9.75542 6.25076 6.75076C9.25542 3.7461 13.251 2.09187 17.5 2.09187ZM17.5 0.5C7.83467 0.5 0 8.33467 0 18C0 27.6653 7.83467 35.5 17.5 35.5C27.1653 35.5 35 27.6653 35 18C35 8.33467 27.1653 0.5 17.5 0.5Z"
                                                  fill="#0F416E"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_53_2135">
                                                  <rect
                                                      width="35"
                                                      height="35"
                                                      fill="white"
                                                      transform="translate(0 0.5)"
                                                  />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                  </span>
                                  <span
                                      >${i18next.t(
                                          "Si quieres pagar con dos medios de pago, edita el monto a pagar con tu cuenta portal aquí. El monto restante será procesado con otro método de pago que elijas.",
                                      )}</span
                                  >
                              </div>
                          </div>
                      </div>
                  `
                : ""}
        </form>
    `;

    return {
        selectedAgencyPaymentAmount: vm.selectedAgencyPaymentAmount,
        htmlTemplate,
        resetSelectedAgencyPaymentAmount: () => setVm({ selectedAgencyPaymentAmount: agencyPaymentInitialValue() }),
        validate: validator.validate,
    };
};

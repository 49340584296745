import {
    ARGENTINIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    PARAGUAYAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    URUGUAYAN_CULTURE_CODE,
    USA_CULTURE_CODE,
} from "./../../../shared/commonConstants";
import i18next from "i18next";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const name = "ac-contact-page";

export interface Properties {}

export const Component: HauntedFunc<Properties> = () => {
    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    const [language, country] = cug2AppContext.Culture.split("-");

    const phoneNumber = () => {
        switch (cug2AppContext.Culture) {
            case ARGENTINIAN_CULTURE_CODE:
                return "Teléfono: 112 206 7799 OPCIÓN 4";
            case PERUVIAN_CULTURE_CODE:
                return "Teléfono: 01 311 0005 OPCIÓN 3";
            case PARAGUAYAN_CULTURE_CODE:
                return "Teléfono: 595 217 289 039 OPCIÓN 3";
            case URUGUAYAN_CULTURE_CODE:
                return "Teléfono: 000 413 598 5582 OPCIÓN 3";
            case COLOMBIAN_CULTURE_CODE:
                return "Teléfono: (601) 348 9581 OPCIÓN 3";
            case BRASILIAN_CULTURE_CODE:
                return "Telefone: 55 11 3042-1183 OPÇÃO 3";
            case USA_CULTURE_CODE:
                return "Phone: 600 600 1311 OPTION 4";
            default:
                return "Teléfono: 600 600 1311 OPCIÓN 4";
        }
    };

    const emailAddress = () => {
        switch (cug2AppContext.Culture) {
            case ARGENTINIAN_CULTURE_CODE:
                return "ventas.ar@jetsmart.com";
            case PERUVIAN_CULTURE_CODE:
                return "ventas.pe@jetsmart.com";
            case PARAGUAYAN_CULTURE_CODE:
                return "ventas.py@jetsmart.com";
            case URUGUAYAN_CULTURE_CODE:
                return "ventas.uy@jetsmart.com";
            case COLOMBIAN_CULTURE_CODE:
                return "ventas.co@jetsmart.com";
            case BRASILIAN_CULTURE_CODE:
                return "vendas.br@jetsmart.com";
            default:
                return "ventas.cl@jetsmart.com";
        }
    };

    const headerTemplate = () =>
        userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
            ? html`
                  <div class="cug2b-contact-header">
                      <div class="cug2b-title-container peru-compra">
                          <i class="js-icon-cug js-cug-phone"></i>
                          <div>
                              <h1>${i18next.t("Comunícate a nuestro Contact Center para clientes Perú Compras")}</h1>
                              <h2>
                                  <span>Teléfono : +51 (01) 311-0005 Opción 3 </span> /
                                  <span>Mail : perucompras@jetsmart.com</span>
                              </h2>
                          </div>
                      </div>
                  </div>
              `
            : html`
                  <div class="cug2b-contact-header">
                      <i class="js-icon-cug js-cug-phone"></i>
                      <div>
                          <h1>${i18next.t("Contact Center Empresas y Agencias")}</h1>
                          <h1>${i18next.t("Disponible 24 hrs")}</h1>
                          <h2>${phoneNumber()}</h2>
                      </div>
                  </div>
              `;

    const contentTemplate = () =>
        userContext.peruCompra.isAdmin || userContext.peruCompra.isMember
            ? html`
                  <div class="cug2b-contact-content">
                      <div class="cug2b-contact-img peru-compra"></div>
                      <div class="cug2b-contact-info peru-compra">
                          <h2>
                              ${unsafeHTML(
                                  i18next.t(
                                      "<span> Si tienes dudas adicionales y deseas comunicarte directamente con Perú Compras, </span> aquí tienes los siguientes medios de contacto para que puedas resolver tus inquietudes:",
                                  ),
                              )}
                          </h2>
                          <ul>
                              <li><span>Teléfono:</span> +51 (01) 643-0000</li>
                              <li><span>Mail:</span> acuerdosmarco@perucompras.gob.pe</li>
                          </ul>
                      </div>
                  </div>
              `
            : html`
                  <div class="cug2b-contact-content">
                      <div class="cug2b-contact-img"></div>
                      <div class="cug2b-contact-info">
                          <h1>${i18next.t("Opciones de soporte")}</h1>

                          <h2>${i18next.t("Post venta y funcionalidades Portal Empresas y Agencias")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.empresas@jetsmart.com")}</p>

                          <h2>${i18next.t("Asuntos comerciales Empresas y Agencias")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${emailAddress()}</p>

                          <h2>${i18next.t("Solicitudes de Grupos")}</h2>
                          <p>
                              <i class="js-icon-cug js-cug-globe"></i>
                              ${`https://jetsmart.com/${country}/${language}/reservas-en-grupo/`}
                          </p>

                          <h2>${i18next.t("Solicitudes de Series Grupales")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("grupos@jetsmart.com")}</p>

                          <h2>${i18next.t("Post venta Grupos y Series")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.grupos@jetsmart.com")}</p>

                          <h2>${i18next.t("Solicitudes de chárters")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("charter@jetsmart.com")}</p>

                          <h2>${i18next.t("Post venta chárters")}</h2>
                          <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.charter@jetsmart.com")}</p>
                      </div>
                  </div>
              `;

    return html`${headerTemplate()}${contentTemplate()}`;
};

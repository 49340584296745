import i18next from "i18next";
import { validateRut } from "../../../form-validation";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";

export const unconditionalRut = (): InputFieldAttribute => ({
    name: "rut",
    validators: [
        {
            errorMessage: {
                id: "Rut",
                message: i18next.t("BE-RutFormatError"),
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = validateRut(field.value);

                return Promise.resolve(result);
            },
        },
    ],
});

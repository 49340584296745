import { ScrollHelper } from "./../../shared/ScrollHelper";
import { LINKS } from "./../../shared/commonConstants";
import i18next from "i18next";
import { html } from "haunted";
import { ProfileTrip } from "../../component-models/ProfileTrip";
import { useState, useEffect } from "../../shared/haunted/CustomHooks";
import { getAntiforgerySegment, showLoader } from "../../shared/common";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import MyBooking from "../../V2/profile/my-trips-grid-container/MyBooking";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
dayjs.extend(CustomParseFormat);
import { ROUTES } from "../../shared/apiRoutes";
import { ProfileTripsWidgetViewModel } from "../../component-models/profile/ProfileTripsWidgetViewModel";

export interface Props {
    model: ProfileTripsWidgetViewModel;
    antiForgeryToken: string;
}

export const useProfileTripsWidget = (props: Props) => {
    const name = "profile-trips-widget";
    const scrollerClassName = "pwc-scroller";
    const formId = "ptwcForm";

    const bookings: MyBooking[] = props.model.BookingDataList
        ? (props.model.BookingDataList as MyBooking[])
        : undefined;

    const trips = bookings
        ? (bookings.map((booking) => {
              return {
                  pnr: booking.PNR,
                  flightNumber: booking.OutboundJourney.FlightNumber,
                  origin: booking.OutboundJourney.DepartureStationCode,
                  destination: booking.OutboundJourney.ArrivalStationCode,
                  formattedDate: dayjs(booking.OutboundJourney.DepartureDate).format("LL").replace(/de /gi, ""),
                  formattedShortDate: dayjs(booking.OutboundJourney.DepartureDate).format("ll").replace(/de /gi, ""),
                  isPast: booking.OutboundJourney.IsFlown && (!booking.IsReturnFlight || booking.ReturnJourney.IsFlown),
                  link: booking.PNR,
              };
          }) as ProfileTrip[])
        : [];

    // HELPERS

    // EVENT HANDLERS

    const handleView = (pnr: string) => {
        showLoader({});
        const form = DomCrawlingHelper.getElemById(document.body, formId) as HTMLFormElement;
        form.setAttribute("action", `${ROUTES.BookingRetrieveInline}/${pnr}`);
        form.submit();
    };

    // COMPONENT

    const [showPastTrips, setShowPastTrips] = useState<boolean>(false);

    const filteredTrips = trips.filter((trip) => trip.isPast === showPastTrips);

    // DEVNOTE We store the PerfectScrollbar instance on the window, so that we don't instantiate it
    // every time this widget renders
    useEffect(() => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        if (window.acPerfectScrollbars[name] && filteredTrips.length > 0) {
            window.acPerfectScrollbars[name].forEach((scroller) => scroller.update());
        } else if (window.acPerfectScrollbars[name] && filteredTrips.length === 0) {
            window.acPerfectScrollbars[name].forEach((scroller) => scroller.destroy());
            window.acPerfectScrollbars[name] = null;
            // DEVNOTE Perfect scrollbar bug fix
            const scroller = DomCrawlingHelper.getElemByClass(document.body, scrollerClassName);
            scroller.classList.remove("ps");
        } else if (filteredTrips.length > 0) {
            window.acPerfectScrollbars[name] = ScrollHelper.addPerfectScrollbar(scrollerClassName);
        }
    }, [filteredTrips]);

    // TEMPLATES

    const formTemplate = () =>
        html`
            <form action=${ROUTES.BookingRetrieveInline} method="post" style="display: none;" id=${formId}>
                ${getAntiforgerySegment(props.antiForgeryToken)}
                <input type="hidden" name="isBookingListRetrieve" value="true" />
            </form>
        `;

    const tripWidgetTemplate = () =>
        html`
            <div class="trips-widget-container">
                <div class="twc-tabs">
                    <span class="${showPastTrips ? "" : "selected"}" @click=${() => setShowPastTrips(false)}
                        >${i18next.t("Próximos viajes")}</span
                    >
                    <span class="${showPastTrips ? "selected" : ""}" @click=${() => setShowPastTrips(true)}
                        >${i18next.t("Historial de vuelo")}</span
                    >
                </div>
                ${filteredTrips.length > 0 ? tableHeaderTemplate() : ""} ${tableTemplate()}
            </div>
        `;

    const tableHeaderTemplate = () => {
        return html`
            <div class="twc-table-header">
                <span>${i18next.t("Código de reserva")}</span>
                <span>${i18next.t("Vuelo")}</span>
                <span>${i18next.t("Ruta")}</span>
                <span>${i18next.t("Fecha")}</span>
                <span><i class="js-icon-mp js-mp-view"></i></span>
            </div>
        `;
    };

    const tableTemplate = () => {
        return html`
            <div class="twc-table">
                <ul class="${scrollerClassName}">
                    ${filteredTrips.length > 0
                        ? html`
                              ${filteredTrips.map(
                                  (trip) => html`
                                      <li>
                                          <span>${trip.pnr}</span>
                                          <span>${trip.flightNumber}</span>
                                          <span
                                              >${trip.origin}
                                              <i class="js-icon-mp js-mp-plane-right"></i> ${trip.destination}</span
                                          >
                                          <span class="capitalize hidden-xs">${trip.formattedDate}</span>
                                          <span class="capitalize hidden-sm-up">${trip.formattedShortDate}</span>
                                          <span @click=${() => handleView(trip.link)}
                                              ><i class="js-icon-mp js-mp-view"></i
                                          ></span>
                                      </li>
                                  `,
                              )}
                          `
                        : html`
                              <li class="twc-no-trips">
                                  <div class="flex items-center justify-center">
                                      <i class="js-icon-mp js-mp-no-flight"></i>
                                      ${i18next.t("No tienes viajes")}
                                  </div>
                                  <button @click=${() => (window.location.href = LINKS.Home)}>
                                      ${i18next.t("Compra aquí")} <i class="js-icon js-circle-chevron-right"></i>
                                  </button>
                              </li>
                          `}
                </ul>
            </div>
        `;
    };

    const htmlTemplate = () =>
        html` <div class="profile-widget profile-trips-widget">${formTemplate()} ${tripWidgetTemplate()}</div> `;

    return { htmlTemplate };
};

import { useEffect } from "./../shared/haunted/CustomHooks";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { showLoader } from "../shared/common";
import { LOADER_CLASS_NAMES } from "../shared/LoaderClassNames";

export const name = "ac-standalone-loader";

export const Component: HauntedFunc<{}> = () => {
    useEffect(() => {
        showLoader({ name: LOADER_CLASS_NAMES.Standalone, noPlane: true });
    }, []);

    return html`
        <div class="standalone-loader"></div>
    `;
};

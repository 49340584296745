import { PaxType } from "../../shared/commonConstants";
import { useTealiumManager } from "./useTealiumManager";

export const useCheckinTealiumManager = () => {
    const logCheckinPassengerSelectContinue = (paxIndex: number[], paxType: string[]) =>
        tealiumManager.log({
            EventName: "continue_clicked",
            EventParams: {
                paxIndex: paxIndex.sort().join("|"),
                paxType: paxType.join("|"),
            },
        });

    const logCheckinAdditionalInfoContinue = () =>
        tealiumManager.log({ EventName: "continue_clicked", EventParams: {} });

    const logBoardingCardDownload = (paxIndex: number, paxType: PaxType) =>
        tealiumManager.log({
            EventName: "boarding_card_downloaded",
            EventParams: {
                paxindex: paxIndex,
                paxtype: paxType === "ADT" ? "A" : "C",
            },
        });

    const tealiumManager = useTealiumManager();

    return {
        logCheckinAdditionalInfoContinue,
        logBoardingCardDownload,
        logCheckinPassengerSelectContinue,
        logValidationError: tealiumManager.logValidationError,
    };
};

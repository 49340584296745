import { BRASILIAN_CULTURE_CODE } from "../../../shared/commonConstants";
import { useEffect } from "../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ApiSpecialAssistanceSsrModel } from "../../../component-models/passengers/ApiSpecialAssistanceSsrModel";
import { AssistanceCheckboxChangeEvent } from "../../../component-models/passengers/PassengerEvents";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../../managers/useAppContext";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";

export const name = "ac-assistance-option";

export interface Properties {
    isChecked: boolean;
    isLoading: boolean;
    option: ApiSpecialAssistanceSsrModel;
    paxIndex: number;
    onBlockBooking: () => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        isChecked: host.isChecked,
        isLoading: host.isLoading,
        option: host.option,
        paxIndex: host.paxIndex,
        onBlockBooking: host.onBlockBooking,
    };

    // HELPERS

    const currentKeys = () => props.option.SsrAddKeys.map((sk) => sk.SsrKey);

    const init = () => {
        if (props.isChecked && appContext.Culture === BRASILIAN_CULTURE_CODE) {
            props.onBlockBooking();
        }
    };

    // EVENT HANDLERS

    const handleClick = () => {
        if (props.isLoading) {
            return;
        }

        if (appContext.Culture === BRASILIAN_CULTURE_CODE) {
            props.onBlockBooking();
            return;
        }

        host.dispatchEvent(new AssistanceCheckboxChangeEvent({ keys: currentKeys() }));
    };

    // COMPONENT

    const appContext = useAppContext();

    const customLabelTemplate = (id: string) =>
        html`<label class="special-assistance-label" for=${id} @click=${handleClick}>
            <span
                class="cb-title"
                data-test-value=${props.isChecked ? "1" : "0"}
                data-test-id=${getTestId(T.PASSENGERS.ASSISTANCE_TITLE, {
                    p: props.paxIndex,
                    c: props.option.SsrCode,
                })}
                >${props.option.Name}
            </span>
        </label>`;

    const assistantCheckbox = useBasicCheckbox({
        isChecked: props.isChecked,
        customInputClass: "special-assistance-checkbox-input",
        disabled: props.isLoading,
        labelTemplate: customLabelTemplate,
        onClick: handleClick,
    });

    useEffect(init, []);

    // TEMPLATES

    return html`
        <div class="special-assistance-checkbox-wrapper" @click=${handleClick}>${assistantCheckbox.htmlTemplate()}</div>
    `;
};

import { html } from "lit-html";
import { ref } from "../../../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import { OUTBOUND, INBOUND } from "../../../../shared/commonConstants";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { useRef } from "haunted";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";

export interface Props {
    context: SeatmapContext;
    seatmapState: SeatmapState;
    handleInboundSelect: () => void;
    handleOutboundSelect: () => void;
}

export const passengerContainerClassName = "mobile-passenger-container";

export const useMobileJourneySelector = (props: Props) => {
    const isJourney = (journeyIndex: number) => props.seatmapState?.CurrentJourney.JourneyIndex === journeyIndex;

    const showOutbound = () => SeatmapHelper.showOutbound(props.context.Model);

    const showInbound = () => SeatmapHelper.showInbound(props.context.Model);

    // DEVNOTE We use the "children[x]" to cover both normal and BancoEstado templates easily
    const selectOutbound = () => {
        if (root.current && root.current.children.length > 0) {
            root.current.children[0].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start",
            });
        }

        props.handleOutboundSelect();
    };

    // DEVNOTE We use the "children[x]" to cover both normal and BancoEstado templates easily
    const selectInbound = () => {
        if (root.current && root.current.children.length > 1) {
            root.current.children[1].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "end",
            });
        }

        props.handleInboundSelect();
    };

    const root = useRef<HTMLDivElement>(undefined);

    const outboundTemplate = () => {
        const tempClassMap = classMap({
            journey: true,
            selected: isJourney(OUTBOUND),
        });

        return showOutbound()
            ? html`
                  <div
                      @click=${selectOutbound}
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.SEATMAP.DIRECTION_SWITCH, { j: OUTBOUND, m: true })}
                  >
                      <span>
                          <span class="direction">${window.seatmapResources.outboundText.toUpperCase()}</span>&nbsp;
                          ${props.context.Model.Journeys[OUTBOUND].LongRoute}
                      </span>
                  </div>
              `
            : "";
    };

    const inboundTemplate = () => {
        const tempClassMap = classMap({
            journey: true,
            selected: isJourney(INBOUND),
        });

        return showInbound()
            ? html`
                  <div
                      @click=${selectInbound}
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.SEATMAP.DIRECTION_SWITCH, { j: INBOUND, m: true })}
                  >
                      <span>
                          <span class="direction">${window.seatmapResources.inboundText.toUpperCase()}</span>&nbsp;
                          ${props.context.Model.Journeys[INBOUND].LongRoute}
                      </span>
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class=${passengerContainerClassName}>${outboundTemplate()} ${inboundTemplate()}</div>
    `;

    return { htmlTemplate, root };
};

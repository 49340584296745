import { ExtendedApiSeat } from "../../../component-models/seatmap/ApiSeat";
import { SeatmapModel } from "../../../component-models/seatmap/SeatmapModel";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";

export interface Props {
    model: SeatmapModel;
}

export const useSellKeyHandler = (props: Props) => {
    const init = () => {
        setSellKeysAsKnownByBackend(
            new Set(
                getInitialPassengerSeats()
                    .filter((seat) => seat.PassengerNumber >= 0)
                    .map((seat) => getAddSeatKey(seat)),
            ),
        );
    };

    const getPartialSeatKeyWithoutPaxIndex = (seat: ExtendedApiSeat) => {
        const keyElements = [
            seat.FlightKey,
            "1",
            seat.CompartmentDesignator,
            `${seat.Row}${seat.Column.toUpperCase()}`,
        ];

        return keyElements.join("|");
    };

    const getAddSeatKey = (seat: ExtendedApiSeat, paxIndex = seat.PassengerNumber) =>
        `${paxIndex}|${getPartialSeatKeyWithoutPaxIndex(seat)}`;

    const getInitialPassengerSeats = () =>
        props.model.Journeys.reduce(
            (allSeats: ExtendedApiSeat[], journey) =>
                allSeats.concat(
                    journey.Segments.reduce(
                        (journeySeats: ExtendedApiSeat[], segment) =>
                            journeySeats.concat(
                                segment.Seats.map((segmentSeat) => ({
                                    ...segmentSeat,
                                    PassengerNumber: segmentSeat.PassengerNumber,
                                })),
                            ),
                        [],
                    ),
                ),
            [],
        );

    const [sellKeysAsKnownByBackend, setSellKeysAsKnownByBackend] = useState<Set<string>>(new Set());

    const findStringBySubstring = (haystack: Set<string>, needle: string) =>
        Array.from(haystack.values()).find((v) => v.indexOf(needle) > -1);

    const resetBackendSeatKeys = (seats: ExtendedApiSeat[]) =>
        new Set<string>(
            seats.filter((seat) => seat.PassengerNumber >= 0).map((seat) => getAddSeatKey(seat, seat.PassengerNumber)),
        );

    const getStoredSellkeyForSeat = (seat: ExtendedApiSeat) =>
        findStringBySubstring(sellKeysAsKnownByBackend, getPartialSeatKeyWithoutPaxIndex(seat));

    const samePaxOnSeat = (seat: ExtendedApiSeat) => sellKeysAsKnownByBackend.has(getAddSeatKey(seat));

    const storedSeats = (seats: ExtendedApiSeat[]) =>
        Array.from(sellKeysAsKnownByBackend.values()).map((key) =>
            seats.find((s) => key === getStoredSellkeyForSeat(s)),
        );

    const getAddAndDeleteKeys = (currentSeats: ExtendedApiSeat[]) => {
        const keysToDelete = storedSeats(currentSeats).reduce(
            (deleteKeys: string[], seat) =>
                !samePaxOnSeat(seat) ? deleteKeys.concat([`${getStoredSellkeyForSeat(seat)}|XX`]) : deleteKeys,
            [],
        );

        const keysToAdd = currentSeats
            .filter((s) => s.PassengerNumber >= 0)
            .reduce(
                (addKeys: string[], seat) => (!samePaxOnSeat(seat) ? addKeys.concat([getAddSeatKey(seat)]) : addKeys),
                [],
            );

        setSellKeysAsKnownByBackend(resetBackendSeatKeys(currentSeats));

        // DEVNOTE Sorting is just for debugging readability
        return [...keysToDelete.sort(), ...keysToAdd.sort()];
    };

    useEffect(() => {
        if (props.model) init();
    }, [props.model]);

    return { getAddAndDeleteKeys };
};

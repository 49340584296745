import i18next from "i18next";
import { html } from "lit-html";
import { ROUTES } from "../../shared/apiRoutes";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useModal } from "../shared/useModal";

export interface Props {
    refundUrl: string;
}

export const useNoLongerAvailableModal = (props: Props) => {
    const handleNewSearch = async () => {
        window.location.href = ROUTES.FlightMoveSelectLegs;
        modal.close();
    };

    const handleOtherOptions = () => {
        window.location.href = props.refundUrl;
        modal.close();
    };

    const headerTemplate = () => html`
        <i class="js-icon js-dc-exclamation"></i>
        ${i18next.t("Importante").toUpperCase()}
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <span class="brand-secondary-text leading-normal"> ${i18next.t("FlightMove-Not-Available")} </span>
            <div class="no-longer-available-modal-button-container">
                <button
                    class="rounded-primary-btn"
                    data-test-id=${T.FLIGHT_MOVE.NO_LONGER_AVAILABLE_MODAL_NEW_SEARCH_BUTTON}
                    @click=${handleNewSearch}
                >
                    ${i18next.t("Nueva búsqueda")}
                </button>
            </div>
            <div class="flight-move-modal-link">
                <a
                    data-test-id=${T.FLIGHT_MOVE.NO_LONGER_AVAILABLE_MODAL_OTHER_OPTIONS_BUTTON}
                    @click=${handleOtherOptions}
                >
                    ${i18next.t("Otras opciones")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "no-longer-available-modal-container", testId: T.FLIGHT_MOVE.NO_LONGER_AVAILABLE_MODAL },
    });

    return modal;
};

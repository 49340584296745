import i18next from "i18next";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { URL_VARS } from "../../shared/commonConstants";
import { getUrlVars } from "../../shared/common";
import { html } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useModal } from "../shared/useModal";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    userName: string;
}

export const useGiftcardSuccessModal = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    useEffect(() => {
        if (getUrlVars().hasOwnProperty(URL_VARS.GIFTCARD)) modal.open();
    }, []);

    const headerTemplate = () => html`
        <div class="giftcard-header">
            <i class="js-icon js-gift-card1"></i>
            ${i18next.t("Gift-Title-1")}
            <span>${i18next.t("Gift-Title-2")}</span>
            <div class="giftcard-header-logo">
                <img src="/Images/Header/jetsmart-logo-colored.svg" />
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="giftcard-modal-title">
            ${unsafeHTML(
                i18next.t("Gift-SuccessModalTitle {{-username}}", {
                    username: props.userName,
                }),
            )}
        </div>
        <div class="giftcard-modal-text">${i18next.t("Gift-SuccessModalText1")}</div>
        <div class="giftcard-modal-text">
            ${userContext.bancoEstado.category === 0
                ? i18next.t("Gift-SuccessModalText2")
                : i18next.t("Gift-SuccessModalText2-BE")}
        </div>
    `;

    const modal = useModal({
        closing: {
            isClosable: true,
            buttonClassNames: "modal-close",
            buttonTestId: T.GIFTCARD.SUCCESS_MODAL_CLOSE_BUTTON,
        },
        content: { testId: T.GIFTCARD.SUCCESS_MODAL, template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "giftcard-success-modal" },
    });

    return modal;
};

import { ApiPassengerBaggage } from "./ApiPassengerBaggage";

export interface ApiBaggageJourney {
    ArrivalStationCode: string;
    ArrivalStationName: string;
    AvailableCabinBaggageQuantity: number;
    AvailableCheckedInBaggageQuantity: number;
    AvailableOversizedBaggageQuantity: number;
    BundleCode: string;
    BundleType: "Full" | "Smart" | "None";
    BundleUserType: BundleUserType;
    DepartureStationCode: string;
    DepartureStationName: string;
    IsCabinBagInBundle: boolean;
    IsCheckedBagInBundle: boolean;
    JourneyIndex: number;
    Passengers: ApiPassengerBaggage[];
}

export enum BundleUserType{
    Normal,
    BancoEstado,
    GDS
}
import { useEffect, useState } from "haunted";
import "./dc-input.scss";
import { html } from "lit-html";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { resetFieldError } from "../shared/errorHandler";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-input";

const DEFAULTS: Properties = {
    isPassword: false,
    isRequired: false,
    label: "",
    readonly: false,
    value: "",
    dataTestId: "",
};

export interface Properties {
    isPassword?: boolean;
    isRequired?: boolean;
    label: string;
    readonly?: boolean;
    value: string;
    dataTestId: string;
}

interface DcInputEventDetail {
    value: string;
}

export class DcInputEvent extends CustomEvent<DcInputEventDetail> {
    constructor(detail: DcInputEventDetail) {
        super("input", { detail });
    }
}

interface KeyUpEventDetail {
    key: string;
}

export class KeyUpEvent extends CustomEvent<KeyUpEventDetail> {
    constructor(detail: KeyUpEventDetail) {
        super("keyup", { detail });
    }
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        isPassword: host.isPassword !== undefined ? host.isPassword : DEFAULTS.isPassword,
        isRequired: host.isRequired !== undefined ? host.isRequired : DEFAULTS.isRequired,
        label: host.label !== undefined ? host.label : DEFAULTS.label,
        readonly: host.readonly !== undefined ? host.readonly : DEFAULTS.readonly,
        value: host.value !== undefined ? host.value : DEFAULTS.value,
        dataTestId: host.dataTestId !== undefined ? host.dataTestId : DEFAULTS.dataTestId,
    };

    const uniqueId = (): string => Math.random().toString(36).substring(2, 11);

    // Event Handlers

    const onInput = (e: CustomEvent) => {
        if (!props.readonly) {
            e.stopPropagation();
            host.dispatchEvent(
                new DcInputEvent({
                    value: (e.currentTarget as any).value,
                }),
            );
        }
    };

    const onKeyUp = (e: KeyboardEvent) => {
        if (!props.readonly) {
            e.stopPropagation();
            host.dispatchEvent(
                new KeyUpEvent({
                    key: e.key,
                }),
            );
        }
    };

    // COMPONENT

    const [componentId, setComponentId] = useState<string>("");

    useEffect(() => {
        const elem = document.getElementById(componentId) as HTMLInputElement;

        if (elem) {
            elem.value = props.value;
        }
    }, [props.value]);

    useEffect(() => {
        const id = `_${name}_${uniqueId()}`;
        setComponentId(id);
    }, []);

    // TEMPLATE

    return html`
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label ${props.value
                ? "is-upgraded is-dirty"
                : ""}"
        >
            <label class="mdl-textfield__label"> ${props.label} </label>
            <input
                autocomplete=${props.isPassword ? "new-password" : "cc-exp"}
                type=${props.isPassword ? "password" : "text"}
                class="mdl-textfield__input js-input"
                value=${props.value}
                ?data-required=${props.isRequired}
                ?readonly=${props.readonly}
                data-test-id=${props.dataTestId}
                @input=${onInput}
                @keyup=${onKeyUp}
                @blur=${(e: MouseEvent) => resetFieldError(e.target as HTMLInputElement)}
            />
        </div>
    `;
};

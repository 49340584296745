import {
    ARGENTINA_COUNTRY_CODE,
    ARGENTINIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    BRASIL_COUNTRY_CODE,
    CHILEAN_CULTURE_CODE,
    CHILE_COUNTRY_CODE,
    COLOMBIAN_CULTURE_CODE,
    COLOMBIA_COUNTRY_CODE,
    ECUADORIAN_CULTURE_CODE,
    ECUADOR_COUNTRY_CODE,
    MERCADO_PAGO_BASE_PREFIX,
    MERCADO_PAGO_DIVIDER,
    MERCADO_PAGO_DOMESTIC_POSTFIX,
    MERCADO_PAGO_INTERNATIONAL_POSTFIX,
    NARANJA_CARD_BIN,
    PARAGUAYAN_CULTURE_CODE,
    PARAGUAY_COUNTRY_CODE,
    PERUVIAN_CULTURE_CODE,
    PERU_COUNTRY_CODE,
    URUGUAYAN_CULTURE_CODE,
    URUGUAY_COUNTRY_CODE,
    USA_COUNTRY_CODE,
    USA_CULTURE_CODE,
    WOMPI_PAYMENT_METHOD_CODES,
} from "../../shared/commonConstants";
import { commonDebug } from "../../bootstrap";
import { eloCardBinRange } from "../../component-models/payment/EloCardBinRange";
import { Card, PaymentMethod } from "../../component-models/PaymentMethodDescriptors";
import {
    CardDescriptor,
    CardIssuerType,
    XmlMercadoPagoAggregatorInstallment,
    XmlMercadoPagoInstallmentOption,
} from "../../component-models/PaymentDescriptors";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { areArraysTheSame, getLocalizedStringValue } from "../../shared/common";
import { CreditCardValidationResult } from "../../component-models/payment/CreditCardValidationResult";
import { AppContext } from "../../component-models/app/AppContext";
import { FlowContext } from "../../component-models/app/FlowContext";
import {
    Agreement,
    ApiMercadoPagoInstallmentOption,
} from "../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { CardData } from "../../component-models/payment/CardData";
import { mapXmlMercadoPagoApiOptionsToXmlDropdownOptions } from "../../component-mappers/PaymentMappers";
import dayjs from "dayjs";
import { isBinInRange } from "./XmlInstallmentHelpers";

const DEFAULT_CREDIT_CARD_NUMBER_LENGTH = 16;

export const paymentHelper = () => {
    const isCardNumberElo = (cardNumber: string): boolean => {
        for (const eloBeginning of eloCardBinRange) {
            if (cardNumber.substr(0, 6) === eloBeginning.toString()) return true;
        }
        return false;
    };

    // DEVNOTE We did not write or understand this
    const isMod10ValidationOkay = (cardNumber: string): boolean => {
        const cardNumberWithoutDashes = cardNumber.split("-").join("");
        // Checksum ("Mod 10")
        // Add even digits in even length strings or odd digits in odd length strings.
        let checksum = 0;
        for (let i = 2 - (cardNumberWithoutDashes.length % 2); i <= cardNumberWithoutDashes.length; i += 2) {
            checksum += parseInt(cardNumberWithoutDashes.charAt(i - 1), 10);
        }
        // Analyze odd digits in even length strings or even digits in odd length strings.
        for (let i = (cardNumberWithoutDashes.length % 2) + 1; i < cardNumberWithoutDashes.length; i += 2) {
            const digit = parseInt(cardNumberWithoutDashes.charAt(i - 1), 10) * 2;
            if (digit < 10) {
                checksum += digit;
            } else {
                checksum += digit - 9;
            }
        }
        return checksum % 10 === 0;
    };

    const isNaranja = (cardNumber: string): boolean => {
        if (!cardNumber.startsWith(NARANJA_CARD_BIN)) return false;

        try {
            const multipliers = [4, 3, 2, 7, 6, 5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
            const r = multipliers.reduce((aggr, curr, i) => aggr + curr * Number(cardNumber[i]), 0);

            let lastDigit = 11 - (r % 11);
            if (lastDigit > 9) {
                lastDigit = 0;
            }

            return lastDigit === Number(cardNumber[15]);
        } catch (e) {
            commonDebug.error("invalid Naranja card number");
            return false;
        }
    };

    const getCardPropertiesByType = (model: PaymentPageViewModel, type: CardIssuerType) =>
        model.MethodsViewModel.PaymentSettings?.CardDescriptors.find((card) => card.Type === type);

    const getFopBrand = (card: CardDescriptor, culture: string): string =>
        getLocalizedStringValue(card?.DisplayedName, culture);

    const getPaymentMethodToSubmit = (paymentMethod: PaymentMethod, type: CardIssuerType) =>
        paymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode
            ? cardTypeCode(paymentMethod, type)
            : paymentMethod?.PaymentMethodCode;

    const cardTypeCode = (paymentMethod: PaymentMethod, type: CardIssuerType) =>
        paymentMethod?.AllowedCards.find((card) => card.Type === type)?.Code;

    const isCategoryIdAllowed = (data: {
        agreement: Agreement;
        cardData: CardData;
        categoryId: string;
        model: PaymentPageViewModel;
    }) =>
        data.agreement.CategoryId === data.categoryId &&
        data.model.MethodsViewModel.PaymentSettings.MercadoPagoInstallmentBranchIds.some(
            (branchId) =>
                branchId.PaymentMethodCode === data.cardData?.PaymentMethodCodeToSubmit &&
                (!branchId.CategoryIds ||
                    branchId.CategoryIds.length === 0 ||
                    branchId.CategoryIds.some((id) => id === data.categoryId)),
        );

    const getRouteAwareNonBaseBranch = (data: {
        apiOptions: ApiMercadoPagoInstallmentOption[];
        cardData: CardData;
        model: PaymentPageViewModel;
    }) => {
        let apiOption;

        const prefixArray = getAllPrefixes(data.apiOptions);

        const prefixesWithInter = prefixArray.filter((prefix) =>
            filterPrefixesForCategory(
                data.apiOptions,
                `${prefix}${MERCADO_PAGO_DIVIDER}${MERCADO_PAGO_INTERNATIONAL_POSTFIX}`,
            ),
        );

        const prefixesWithDom = prefixArray.filter((prefix) =>
            filterPrefixesForCategory(
                data.apiOptions,
                `${prefix}${MERCADO_PAGO_DIVIDER}${MERCADO_PAGO_DOMESTIC_POSTFIX}`,
            ),
        );

        const nonBaseInterPrefixes = prefixesWithInter.filter((prefix) => prefix !== MERCADO_PAGO_BASE_PREFIX);
        const nonBaseDomPrefixes = prefixesWithDom.filter((prefix) => prefix !== MERCADO_PAGO_BASE_PREFIX);

        const prefixes = data.model.BookingViewModel.IsDomesticArgentina ? nonBaseDomPrefixes : nonBaseInterPrefixes;
        const postfix = data.model.BookingViewModel.IsDomesticArgentina
            ? MERCADO_PAGO_DOMESTIC_POSTFIX
            : MERCADO_PAGO_INTERNATIONAL_POSTFIX;

        if (prefixes.length > 0) {
            apiOption = data.apiOptions.find((option) =>
                option.Agreements.some((agreement) =>
                    prefixes.some((prefix) =>
                        isCategoryIdAllowed({
                            ...data,
                            agreement,
                            categoryId: `${prefix}${MERCADO_PAGO_DIVIDER}${postfix}`,
                        }),
                    ),
                ),
            );
        }

        return apiOption;
    };

    const getRouteAgnosticNonBaseBranch = (data: {
        apiOptions: ApiMercadoPagoInstallmentOption[];
        cardData: CardData;
        model: PaymentPageViewModel;
        prefixes: string[];
    }) => {
        let apiOption;

        if (data.prefixes.length > 0) {
            apiOption = data.apiOptions.find((option) =>
                option.Agreements.some((agreement) =>
                    data.prefixes.some((prefix) => isCategoryIdAllowed({ ...data, agreement, categoryId: prefix })),
                ),
            );
        }

        return apiOption;
    };

    const getRouteAwareBaseBranch = (data: { postfix: string; apiOptions: ApiMercadoPagoInstallmentOption[] }) =>
        data.apiOptions.find((option) =>
            option.Agreements.some(
                (agreement) =>
                    agreement.CategoryId === `${MERCADO_PAGO_BASE_PREFIX}${MERCADO_PAGO_DIVIDER}${data.postfix}`,
            ),
        );

    const getRouteAgnosticBaseBranch = (apiOptions: ApiMercadoPagoInstallmentOption[]) =>
        apiOptions.find((option) =>
            option.Agreements.some((agreement) => agreement.CategoryId === MERCADO_PAGO_BASE_PREFIX),
        );

    const getGeneralBranch = (apiOptions: ApiMercadoPagoInstallmentOption[]) =>
        apiOptions.find((option) => option.Agreements.some((agreement) => !agreement.CategoryId));

    const getAllPrefixes = (apiOptions: ApiMercadoPagoInstallmentOption[]) =>
        Array.from(
            new Set(
                apiOptions.reduce(
                    (prefixes, option) =>
                        prefixes.concat(
                            option.Agreements.map((agreement) => agreement.CategoryId.split(MERCADO_PAGO_DIVIDER)[0]),
                        ),
                    new Array<string>(),
                ),
            ).values(),
        ).filter((prefix) => prefix);

    const filterPrefixesForCategory = (apiOptions: ApiMercadoPagoInstallmentOption[], categoryId: string) =>
        apiOptions.some((option) => option.Agreements.some((agreement) => agreement.CategoryId === categoryId));

    const getXmlOptionsForDaysOfWeek = (options: XmlMercadoPagoInstallmentOption[]) => {
        const dayOfWeek = dayjs().day();

        return options.reduce((aggr: XmlMercadoPagoInstallmentOption[], option) => {
            const filteredOptions = option.InterestFees.filter((intf) => {
                const daysOfWeek = intf.DaysOfWeek.split(",").map((dow) => Number(dow));
                return daysOfWeek.includes(dayOfWeek);
            });

            return filteredOptions.length > 0 ? aggr.concat({ ...option, InterestFees: filteredOptions }) : aggr;
        }, []);
    };

    const getXmlOptionsForPaymentMethod = (model: PaymentPageViewModel, cardData: CardData) =>
        model.MethodsViewModel.PaymentSettings.MercadoPagoAggregatorInstallments?.filter(
            (item) => item.PaymentMethodCode === cardData?.PaymentMethodCodeToSubmit,
        );

    const getXmlOptionsForInstallmentNumbers = (
        apiOptions: ApiMercadoPagoInstallmentOption[],
        xmlOptions: XmlMercadoPagoAggregatorInstallment[],
    ) =>
        xmlOptions
            .map((installment) => replaceInstallmentOptionsWithApiAllowedInstallmentOptions(apiOptions, installment))
            .filter(hasAnyInstallmentOptions);

    const replaceInstallmentOptionsWithApiAllowedInstallmentOptions = (
        apiOptions: ApiMercadoPagoInstallmentOption[],
        installment: XmlMercadoPagoAggregatorInstallment,
    ) => ({
        ...installment,
        InstallmentOptions: installment.InstallmentOptions.map((installmentOption) =>
            replaceInterestFeesWithApiAllowedInterestFees(apiOptions, installmentOption),
        ).filter(hasAnyInterestFees),
    });

    const replaceInterestFeesWithApiAllowedInterestFees = (
        apiOptions: ApiMercadoPagoInstallmentOption[],
        installmentOption: XmlMercadoPagoInstallmentOption,
    ) => ({
        ...installmentOption,
        InterestFees: xmlInterestFeesAllowedByApi(apiOptions, installmentOption),
    });

    const hasAnyInstallmentOptions = (installment: XmlMercadoPagoAggregatorInstallment) =>
        installment.InstallmentOptions.length > 0;

    const hasAnyInterestFees = (installmentOption: XmlMercadoPagoInstallmentOption) =>
        installmentOption.InterestFees.length > 0;

    const getInstallmentNumbersAllowedByApi = (apiOptions: ApiMercadoPagoInstallmentOption[]) =>
        new Set<number>([
            ...apiOptions.reduce(
                (aggr, apiOption) => aggr.concat(apiOption.PayerCosts.map((pc) => pc.Installments)),
                [],
            ),
        ]);

    const xmlInterestFeesAllowedByApi = (
        apiOptions: ApiMercadoPagoInstallmentOption[],
        option: XmlMercadoPagoInstallmentOption,
    ) => {
        const installmentsFromApi = getInstallmentNumbersAllowedByApi(apiOptions);
        return option.InterestFees.filter((intf) => installmentsFromApi.has(intf.Number));
    };

    const getXmlOptionsForRoute = (options: XmlMercadoPagoAggregatorInstallment[], model: PaymentPageViewModel) =>
        options.filter(
            (option) =>
                !option.Routes ||
                option.Routes === "ALL" ||
                (model.BookingViewModel.IsDomesticArgentina && option.Routes === "DOM") ||
                (!model.BookingViewModel.IsDomesticArgentina && option.Routes === "INTER"),
        );

    const getXmlOptionsForBinRange = (options: XmlMercadoPagoAggregatorInstallment[], cardData: CardData) =>
        options.filter((item) => isBinInRange(item.BinRangeLower, item.BinRangeHigher, cardData?.CardNumber));

    const getXmlOptionsForStations = (options: XmlMercadoPagoAggregatorInstallment[], model: PaymentPageViewModel) =>
        options.reduce(
            (aggr: XmlMercadoPagoInstallmentOption[], option) =>
                aggr.concat(
                    option.InstallmentOptions.filter(
                        (option) =>
                            !option.Stations ||
                            option.Stations.length === 0 ||
                            option.Stations.some((ios) =>
                                areArraysTheSame(ios.split("-"), [
                                    model.BookingViewModel.Endpoint1,
                                    model.BookingViewModel.Endpoint2,
                                ]),
                            ),
                    ),
                ),
            [],
        );

    const getXmlOptionsForDates = (options: XmlMercadoPagoInstallmentOption[], model: PaymentPageViewModel) =>
        options.filter(
            (option) =>
                !option.FlightDateRange ||
                (dayjs(option.FlightDateRange.Start).isSameOrBefore(model.BookingViewModel.StartDate, "day") &&
                    (!model.BookingViewModel.EndDate ||
                        dayjs(option.FlightDateRange.End).isSameOrAfter(model.BookingViewModel.EndDate))),
        );

    // EXPORTS

    const showBilling = (model: PaymentPageViewModel, isFlightlessPurchaseFlow: boolean) =>
        !model.BillingViewModel.HasPendingPayment &&
        (!model.BookingViewModel.IsFlightlessPnr || isFlightlessPurchaseFlow);

    const canOnlyHold = (model: PaymentPageViewModel): boolean =>
        model.AgencyViewModel.ShowHoldBooking &&
        !model.AgencyViewModel.ShowAgencyPayment &&
        !hasXmlPaymentMethods(model);

    const hasXmlPaymentMethods = (model: PaymentPageViewModel): boolean =>
        model.MethodsViewModel.PaymentMethods.PaymentMethods?.length > 0;

    const isCardInvalidForCountryAndCurrency = (
        cardType: CardIssuerType,
        allowedCards: Card[],
        currentCardIssuerCountry: string,
    ): boolean =>
        allowedCards.some(
            (card) =>
                card.Type === cardType &&
                card.Restrictions.some(
                    (restriction) =>
                        (restriction.CardIssuerCountryWhitelist.length > 0 &&
                            !restriction.CardIssuerCountryWhitelist.includes(currentCardIssuerCountry)) ||
                        restriction.CardIssuerCountryBlacklist.includes(currentCardIssuerCountry),
                ),
        );

    const isCardNumberLengthValid = (
        paymentMethodCode: string,
        cardType: CardIssuerType,
        cardNumber: string,
    ): boolean => {
        if (!cardNumber) return true;

        if (WOMPI_PAYMENT_METHOD_CODES.includes(paymentMethodCode)) {
            return cardNumber.length >= 13 && cardNumber.length <= 17;
        }

        const specialLengths = new Map<CardIssuerType, number>([
            ["AmEx", 15],
            ["Diners", 14],
        ]);

        const minimumLength = specialLengths.has(cardType)
            ? specialLengths.get(cardType)
            : DEFAULT_CREDIT_CARD_NUMBER_LENGTH;

        return cardNumber.length >= minimumLength;
    };

    const isInstallmentsDisabledForFlow = (appContext: AppContext, flowContext: FlowContext): boolean =>
        flowContext.isCugFundingFlow ||
        flowContext.isDcStandaloneFlow ||
        flowContext.isFarelockRoundOne ||
        (flowContext.isGiftcardPurchaseFlow && appContext.Culture !== ARGENTINIAN_CULTURE_CODE);

    const validateCreditCard = (
        model: PaymentPageViewModel,
        paymentMethod: PaymentMethod,
        cardNumber: string,
        culture: string,
        bancoEstadoBins: string[],
    ): CreditCardValidationResult => {
        const response: CreditCardValidationResult = {
            Card: undefined,
            FopBrand: undefined,
            IsBancoEstado: false,
            IsValid: false,
            PaymentMethodCodeToSubmit: "",
        };

        if (!cardNumber) return response;

        const allowedTypes = paymentMethod?.AllowedCards.map((card) => card.Type);

        // DEVNOTE We need to split card types into two. The specific card types have bins
        // that overlap with the general card types, so we have to check first if the card
        // is a specific card, and only then should we check for general card providers.

        const specificCardProviders = new Map<CardIssuerType, RegExp>([
            ["Argencard", /^501105/],
            ["Cencosud", /^(603493|527104|559198|510541)/],
            ["Cmr", /^557039/],
            ["Cordobesa", /^(542702|544764|550073|528824)/],
            ["Nativa", /^(546553|520053|527601)/],
            ["Shopping", /^(27995|483154)/],
            ["Walmart", /^522135/],
        ]);

        // DEVNOTE The Cabal bin (603522) is in fact invalid, included as per client request
        // DEVNOTE The Maestro bins are in fact invalid, included as per client request
        const generalCardProviders = new Map<CardIssuerType, RegExp>([
            ["AmEx", /^3[4,7]\d{13}$/],
            ["Cabal", /^(603522|589657|650272|65008700|(60420[1-9]|6042[1-9][0-9]|6043[0-4][0-9]|60435[0-4]))/],
            ["Diners", /^3[0,6,8]\d{12}$/],
            ["Discover", /^6011-?\d{4}-?\d{4}-?\d{4}$/],
            [
                "Maestro",
                /^(3572|5010|5018|5020|5031|5038|5046|5081|5579|5887|5896|5897|6017|6304|6759|6761|6763)[0-9]{8,19}/,
            ],
            [
                "Mastercard",
                /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}$/,
            ],
            ["Visa", /^4\d{3}\d{4}\d{4}\d{4}\d{0,3}$/],
            // JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        ]);

        // DEVNOTE Sometimes we have to allow invalid card numbers (ask the client why :-)), or we don't
        // know the whole validation regex. For such cases we can still check the bin only for the cards.

        const generalCardProviderBeginnings = new Map<CardIssuerType, RegExp>([
            ["AmEx", /^3[4,7]/],
            ["Diners", /^3[0,6,8]/],
            ["Discover", /^6011/],
            ["Hipercard", /^(606282|637599|637095|637568|637609|637612|637600)/],
            ["Magna", /^560359/],
            ["Maestro", /^(3572|50|5579|56|57|58|6017)/],
            ["Mastercard", /^5[1-5]/],
            ["Visa", /^4/],
        ]);

        // Elo
        // DEVNOTE It has to be first, because there is an overlap between Elo and Visa bin ranges,
        // and Elo cannot be described with a regex like above because of the many many possible bins
        response.Card =
            allowedTypes.includes("Elo") && isCardNumberElo(cardNumber)
                ? getCardPropertiesByType(model, "Elo")
                : undefined;

        if (!response.Card) {
            specificCardProviders.forEach((value, key) => {
                if ((!allowedTypes || allowedTypes.includes(key)) && value.test(cardNumber)) {
                    response.Card = getCardPropertiesByType(model, key);
                }
            });
        }

        if (!response.Card) {
            generalCardProviders.forEach((value, key) => {
                if ((!allowedTypes || allowedTypes.includes(key)) && value.test(cardNumber)) {
                    response.Card = getCardPropertiesByType(model, key);
                }
            });
        }

        if (!response.Card) {
            generalCardProviderBeginnings.forEach((value, key) => {
                if ((!allowedTypes || allowedTypes.includes(key)) && value.test(cardNumber)) {
                    response.Card = getCardPropertiesByType(model, key);
                }
            });
        }

        response.IsBancoEstado = bancoEstadoBins.includes(cardNumber.substr(0, 6));

        response.IsValid = isMod10ValidationOkay(cardNumber);

        if (!response.IsValid && allowedTypes.includes("Naranja") && isNaranja(cardNumber)) {
            response.Card = getCardPropertiesByType(model, "Naranja");
            response.IsValid = true;
        }

        response.FopBrand = response.Card ? getFopBrand(response.Card, culture) : undefined;
        response.PaymentMethodCodeToSubmit = getPaymentMethodToSubmit(paymentMethod, response.Card?.Type);

        return response;
    };

    const getCultureForCountry = (countryCode: string) => {
        const countryCultureMap = new Map<string, string>([
            [USA_COUNTRY_CODE, USA_CULTURE_CODE],
            [CHILE_COUNTRY_CODE, CHILEAN_CULTURE_CODE],
            [PERU_COUNTRY_CODE, PERUVIAN_CULTURE_CODE],
            [COLOMBIA_COUNTRY_CODE, COLOMBIAN_CULTURE_CODE],
            [ARGENTINA_COUNTRY_CODE, ARGENTINIAN_CULTURE_CODE],
            [BRASIL_COUNTRY_CODE, BRASILIAN_CULTURE_CODE],
            [PARAGUAY_COUNTRY_CODE, PARAGUAYAN_CULTURE_CODE],
            [URUGUAY_COUNTRY_CODE, URUGUAYAN_CULTURE_CODE],
            [ECUADOR_COUNTRY_CODE, ECUADORIAN_CULTURE_CODE],
        ]);

        return countryCultureMap.get(countryCode);
    };

    const getCountryForCulture = (cultureCode: string) => {
        const cultureCountryMap = new Map<string, string>([
            [USA_CULTURE_CODE, USA_COUNTRY_CODE],
            [CHILEAN_CULTURE_CODE, CHILE_COUNTRY_CODE],
            [PERUVIAN_CULTURE_CODE, PERU_COUNTRY_CODE],
            [COLOMBIAN_CULTURE_CODE, COLOMBIA_COUNTRY_CODE],
            [ARGENTINIAN_CULTURE_CODE, ARGENTINA_COUNTRY_CODE],
            [BRASILIAN_CULTURE_CODE, BRASIL_COUNTRY_CODE],
            [PARAGUAYAN_CULTURE_CODE, PARAGUAY_COUNTRY_CODE],
            [URUGUAYAN_CULTURE_CODE, URUGUAY_COUNTRY_CODE],
            [ECUADORIAN_CULTURE_CODE, ECUADOR_COUNTRY_CODE],
        ]);

        return cultureCountryMap.get(cultureCode);
    };

    const getAvailableCurrencies = (paymentMethod: PaymentMethod, currentCardIssuerCountry: string) => {
        const currenciesForCountry = paymentMethod?.SelectableCurrenciesPerCardIssuerCountry?.find(
            (currencyList) => currencyList.Country === currentCardIssuerCountry,
        );

        if (currenciesForCountry) return currenciesForCountry.Currencies;

        const defaultCurrency = paymentMethod?.SelectableCurrenciesPerCardIssuerCountry?.find(
            (currencyList) => !currencyList.Country,
        );

        return defaultCurrency ? defaultCurrency.Currencies : [];
    };

    const getRelevantMercadoPagoGatewayApiOption = (data: {
        apiOptions: ApiMercadoPagoInstallmentOption[];
        cardData: CardData;
        model: PaymentPageViewModel;
    }) => {
        const prefixArray = getAllPrefixes(data.apiOptions);

        const prefixesWithNoPostfix = prefixArray.filter((prefix) =>
            filterPrefixesForCategory(data.apiOptions, prefix),
        );

        const nonBaseNeitherPrefixes = prefixesWithNoPostfix.filter((prefix) => prefix !== MERCADO_PAGO_BASE_PREFIX);

        let apiOption = getRouteAwareNonBaseBranch(data);

        if (!apiOption) {
            apiOption = getRouteAgnosticNonBaseBranch({ ...data, prefixes: nonBaseNeitherPrefixes });
        }

        if (!apiOption) {
            apiOption = getRouteAwareBaseBranch({
                apiOptions: data.apiOptions,
                postfix: data.model.BookingViewModel.IsDomesticArgentina
                    ? MERCADO_PAGO_DOMESTIC_POSTFIX
                    : MERCADO_PAGO_INTERNATIONAL_POSTFIX,
            });
        }

        if (!apiOption) apiOption = getRouteAgnosticBaseBranch(data.apiOptions);

        if (!apiOption) apiOption = getGeneralBranch(data.apiOptions);

        return apiOption;
    };

    const getRelevantMercadoPagoAggregatorApiOption = (data: {
        amount: number;
        apiOptions: ApiMercadoPagoInstallmentOption[];
        cardData: CardData;
        culture: string;
        model: PaymentPageViewModel;
        selectedCurrency: string;
        formatNumber: (data: {
            amount: number;
            leadingSign?: boolean;
            omitGrouping?: boolean;
            currency?: string;
        }) => string;
    }) => {
        const xmlOptionsForPaymentMethod = getXmlOptionsForPaymentMethod(data.model, data.cardData);

        const xmlOptionsForInstallmentNumber = getXmlOptionsForInstallmentNumbers(
            data.apiOptions,
            xmlOptionsForPaymentMethod,
        );

        const xmlOptionsForRoute = getXmlOptionsForRoute(xmlOptionsForInstallmentNumber, data.model);

        const xmlOptionsForBinRange = getXmlOptionsForBinRange(xmlOptionsForRoute, data.cardData);

        const xmlOptionsForStations = getXmlOptionsForStations(xmlOptionsForBinRange, data.model);

        const xmlOptionsForDates = getXmlOptionsForDates(xmlOptionsForStations, data.model);

        const xmlOptionsForDaysOfWeek = getXmlOptionsForDaysOfWeek(xmlOptionsForDates);

        const optionsToDisplay = mapXmlMercadoPagoApiOptionsToXmlDropdownOptions({
            amount: data.amount,
            currency: data.selectedCurrency,
            installmentOptions: xmlOptionsForDaysOfWeek,
            interestText: getLocalizedStringValue(
                data.model.MethodsViewModel.PaymentSettings.InterestFeeText,
                data.culture,
            ),
            noInterestText: getLocalizedStringValue(
                data.model.MethodsViewModel.PaymentSettings.NoInterestFeeText,
                data.culture,
            ),
            culture: data.culture,
            formatNumber: data.formatNumber,
        });

        return optionsToDisplay;
    };

    return {
        canOnlyHold,
        getAvailableCurrencies,
        getCountryForCulture,
        getCultureForCountry,
        getRelevantMercadoPagoAggregatorApiOption,
        getRelevantMercadoPagoGatewayApiOption,
        hasXmlPaymentMethods,
        isCardInvalidForCountryAndCurrency,
        isCardNumberLengthValid,
        isInstallmentsDisabledForFlow,
        showBilling,
        validateCreditCard,
    };
};

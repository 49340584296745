import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { BRASILIAN_CULTURE_CODE, USA_CULTURE_CODE } from "../../shared/commonConstants";
import i18next from "i18next";
import { html } from "haunted";
import { useAppContext } from "../../managers/useAppContext";
import classNames from "classnames";
import { useModal } from "../shared/useModal";

export const useTooManyPaxModal = () => {
    const appContext = useAppContext();

    const sidebarClassNames = classNames("flight-modal-sidebar", {
        english: appContext.Culture === USA_CULTURE_CODE,
        portuguese: appContext.Culture === BRASILIAN_CULTURE_CODE,
    });

    const htmlTemplate = () => html`
        <div class=${sidebarClassNames}></div>
        <div class="flight-scroller">
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-1 text-center">
                        <div class="too-many-pax-info">
                            <i class="js-icon js-broken-circle-exclamation"></i>
                            <div class="pax-text-container">
                                ${unsafeHTML(
                                    i18next.t("TooManyPaxInfo {{-start}} {{-end}}", {
                                        start: "<span>",
                                        end: "</span>",
                                    }),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-1">
                        <div class="modal-button-container many-modal-buttons">
                            <button @click=${modal.close} class="primary-btn">
                                <span>${i18next.t("V2-Continue")}</span>
                                <i class="js-icon js-circle-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true, buttonClassNames: "flight-modal-close" },
        content: {
            classNames: "flight-modal-content many-modal with-sidebar",
            testId: T.FLIGHT.TOO_MANY_PAX_MODAL,
            template: htmlTemplate,
        },
    });

    return modal;
};

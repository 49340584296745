import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import {
    BRASILIAN_CULTURE_CODE,
    DC_MEMBERSHIP_GROUP,
    DC_MEMBERSHIP_STANDARD,
    FAKE_DATE_OF_BIRTH,
    FULL_EMAIL_REGEX,
    MAX_PAX_IN_STANDARD_DC_MEMBERSHIP,
    NAME_INPUT_MAX_LENGTH,
    USA_CULTURE_CODE,
} from "../../../shared/commonConstants";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import { SelectOption, hideLoader, showLoader } from "../../../shared/common";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { classMap } from "lit-html/directives/class-map";
import { ROUTES } from "../../../shared/apiRoutes";
import { CLASS_NAMES } from "../../../shared/classNames";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { ref } from "../../../directives/ref";
import LoginResult from "../../../component-models/LoginResult";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useFlightTealiumManager } from "../../../managers/Tealium/useFlightTealiumManager";
import { useAuthenticationTealiumManager } from "../../../managers/Tealium/useAuthenticationTealiumManager";
import { useAppContext } from "../../../managers/useAppContext";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { SelectedDcMembershipType } from "./useDiscountClubBanner";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useModal } from "../../shared/useModal";
import classNames from "classnames";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import { useAvailabilityValidator } from "../../../validator/useAvailabilityValidator";
import { validatePassword } from "../../../validator/validation-helper";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../../shared/redux/useReduxState";

interface DcModalVm {
    confirmEmail: string;
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    selectedMembership: SelectedDcMembershipType;
    termsAccepted: boolean;
}

const defaultVm: DcModalVm = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    confirmEmail: "",
    termsAccepted: false,
    selectedMembership: "Standard",
};

type FieldNames = keyof DcModalVm;

export interface Props {
    context: FlightPageContext;
    membershipType: SelectedDcMembershipType;
    noCallback: () => void;
    onMembershipTypeChange: (membershipType: SelectedDcMembershipType) => void;
    yesCallback: (selectedDcMembershipType: SelectedDcMembershipType) => void;
}

export const useDiscountClubBannerModal = (props: Props) => {
    let tabIndexCounter = 1;

    const getTabIndex = () => tabIndexCounter++;

    const tealiumManager = useFlightTealiumManager();
    const authTealiumManager = useAuthenticationTealiumManager();
    const appContext = useAppContext();

    const { ajaxJsonRequest, removeAjaxErrorMessage } = useAjax();

    const [_, setUserContext] = useReduxState("userContext");

    const root = useRef<HTMLDivElement>(undefined);
    const emailField = useRef<HTMLDivElement>(undefined);
    const continueButton = useRef<HTMLButtonElement>(undefined);

    const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(undefined);

    const [vm, setVm] = useState<DcModalVm>(defaultVm);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const emailAvailabilityValidator = useAvailabilityValidator<{ EmailAddress: string }, { IsUserExist: boolean }>({
        bodyKey: "EmailAddress",
        container: root.current,
        method: "POST",
        responseKey: "IsUserExist",
        url: `${ROUTES.ApiRoutes.CheckUserExist}`,
    });

    const validateEmail = async (value: string) => {
        if (!FULL_EMAIL_REGEX.test(value)) {
            return true;
        }

        if (emailField.current?.contains(document.activeElement)) return true;

        const loader = showLoader({});
        const result = await emailAvailabilityValidator.validate(value);
        hideLoader(loader);
        return result;
    };

    const validator = useFluentValidator<FieldNames, DcModalVm>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("password", (vm: DcModalVm) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => validatePassword(password),
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                ),
            Validation.ruleFor("lastName", (vm: DcModalVm) => vm.lastName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("firstName", (vm: DcModalVm) => vm.firstName)
                .isRequired()
                .max(NAME_INPUT_MAX_LENGTH),
            Validation.ruleFor("termsAccepted", (vm: DcModalVm) => vm.termsAccepted)
                .fulfils((_) => Promise.resolve(false), i18next.t("Breakdown-AcceptTermsFirst"))
                .when((model: DcModalVm) => !model.termsAccepted),
            Validation.ruleFor("confirmPassword", (vm: DcModalVm) => vm.confirmPassword)
                .isRequired()
                .fulfils(
                    async (confirmPassword: string) => confirmPassword === vm.password,
                    i18next.t("El campo de confirmación de contraseña debe coincidir con el campo de contraseña."),
                ),
            Validation.ruleFor("confirmEmail", (vm: DcModalVm) => vm.confirmEmail)
                .isRequired()
                .fulfils(
                    async (confirmEmail: string) => confirmEmail === vm.email,
                    i18next.t("V2DC-EmailConfirmMismatch"),
                ),
            Validation.ruleFor("email", (vm: DcModalVm) => vm.email)
                .isRequired()
                .isEmail()
                .fulfils(validateEmail, i18next.t("RegisterEmailAlreadyRegistered")),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const submitForm = async () => {
        tealiumManager.logDcMembershipContinueClicked(
            vm.selectedMembership === "Standard" ? "A" : "B",
            vm.selectedMembership === "Standard"
                ? props.context.model.DiscountClubViewModel.StandardPriceUnformatted
                : props.context.model.DiscountClubViewModel.GroupPriceUnformatted,
        );

        removeAjaxErrorMessage({
            container: root.current,
        });

        const loader = showLoader({ name: "dc-modal-body", container: root.current.parentElement, noPlane: true });

        try {
            setAreButtonsDisabled(true);

            const result = await ajaxJsonRequest<LoginResult>({
                url: ROUTES.RegisterInline,
                loader,
                container: root.current,
                body: {
                    "feeCode": vm.selectedMembership === "Standard" ? DC_MEMBERSHIP_STANDARD : DC_MEMBERSHIP_GROUP,
                    "registerMember.Member.Name.First": vm.firstName,
                    "registerMember.Member.Name.Last": vm.lastName,
                    "registerMember.Member.NewPasswordConfirmation": vm.confirmPassword,
                    "registerMember.Member.Password": vm.password,
                    "registerMember.Member.PersonalEmailAddress.EmailAddress": vm.email,
                    "registerMember.Member.Username": vm.email,
                    "registerMember.Member.DateOfBirth": FAKE_DATE_OF_BIRTH,
                },
            });

            if (result?.statusCode === 200) {
                if (result.data.IsLoggedIn) {
                    await handleLogin(result.data);
                } else {
                    window.location.reload();
                }
            } else {
                setAreButtonsDisabled(false);
            }
        } catch (e) {
            window.location.reload();
        }
    };

    const handleLogin = async (result: LoginResult) => {
        setAreButtonsDisabled(false);
        modal.hide();
        setUserContext({
            bancoEstado: {
                category: 0,
                remainingFreeSeats: 0,
                todosumaPoints: 0,
            },
            cug: {
                isAgent: result.Cug.IsAgent,
                isMember: result.Cug.IsMember,
                isAdminOrSupervisor: result.Cug.IsAdminOrSupervisor,
                orgCode: result.Cug.OrgCode,
                orgCurrency: result.Cug.OrgCurrency,
                orgName: result.Cug.OrgName,
            },
            dc: {
                dcLevel: result.DC.DcLevel,
                canBuyMembership: false,
                groupPriceFormatted: result.DC.GroupPriceFormatted,
                standardPriceFormatted: result.DC.StandardPriceFormatted,
                upgradePriceFormatted: result.DC.UpgradePriceFormatted,
                hasMembership: true,
                hasGroupMembership: vm.selectedMembership === "Group",
                hasStandardMembership: vm.selectedMembership === "Standard",
                membershipDaysRemaining: result.DC.MembershipDaysRemaining,
                programNumber: result.DC.ProgramNumber,
                showWarning15: result.DC.ShowWarning15,
                showWarning30: result.DC.ShowWarning30,
            },
            peruCompra: {
                isAdmin: result.PeruCompra.IsAdmin,
                isMember: result.PeruCompra.IsMember,
                availableAmount: result.PeruCompra.AvailableAmount,
            },
            isFarelockDisabled: result.IsFarelockDisabled,
            isLoggedIn: true,
            isStaff: false,
            userFirstName: result.FirstName,
            userLastName: result.LastName,
            userUserName: result.UserName,
            userProgram: result.UserProgram,
            userRole: result.UserRole,
        });

        authTealiumManager.logUserLoginRegister();

        await props.yesCallback(vm.selectedMembership === "Standard" ? "Standard" : "Group");
    };

    const scrollToFirstError = () => {
        const errors = Array.from(root.current.querySelectorAll("." + CLASS_NAMES.invalid)) as HTMLElement[];
        const firstError = errors.find((err) => err.offsetHeight > 0);

        if (firstError) {
            const topOfElement = getTopOfRowWithError(firstError);
            modal.scroller.scroll({ top: topOfElement, behavior: "smooth" });
        }
    };

    const getTopOfRowWithError = (elem: HTMLElement) => {
        const rowParent = DomCrawlingHelper.findParentByClass(elem, "row");

        return Math.round(rowParent.offsetTop);
    };

    const labelTemplate = (id: string) => html`
        <label
            class="dc-banner-terms-label my-3 gap-1"
            for=${id}
            data-test-id=${T.FLIGHT_DC_BANNER_MODAL.ACCEPT_TERMS_LABEL}
        >
            ${i18next.t("V2DC-AcceptTermsLinkFirstPart")}
            <a
                href=${props.context.model.DiscountClubViewModel.TermsUrl}
                target="_blank"
                @click=${(e: MouseEvent) => e.stopPropagation()}
                >${i18next.t("V2DC-AcceptTermsLinkSecondPart")}</a
            >
            ${i18next.t("y")}
            <a
                href=${props.context.model.DiscountClubViewModel.PrivacyUrl}
                class="inline-block"
                target="_blank"
                @click=${(e: MouseEvent) => e.stopPropagation()}
                >${i18next.t("V2DC-AcceptTermsLinkThirdPart")}</a
            >.
        </label>
    `;

    const termsCheckbox = useBasicCheckbox({
        inputTestId: T.FLIGHT_DC_BANNER_MODAL.ACCEPT_TERMS,
        isChecked: vm.termsAccepted,
        labelTemplate,
        onClick: () => setVm({ ...vm, termsAccepted: !vm.termsAccepted }),
    });

    const handleCloseButton = () => {
        props.noCallback();
        setIsValidated(false);
        modal.hide();
    };

    const handleContinueButton = async (e?: MouseEvent) => {
        if (areButtonsDisabled) return;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setIsValidated(true);

        setAreButtonsDisabled(true);

        const isValid = await validator.validate();

        if (!isValid) {
            scrollToFirstError();
        } else {
            await submitForm();
        }

        setAreButtonsDisabled(false);
    };

    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            handleContinueButton();
        }
    };

    useEffect(() => {
        if (modal.isOpen) props.onMembershipTypeChange(vm.selectedMembership);
    }, [vm.selectedMembership]);

    const logoTemplate = () => {
        switch (appContext.Culture) {
            case USA_CULTURE_CODE:
                return html` <img class="dc-modal-header-img" src="/Images/DcStandalone/dc-logo-2-en.png" /> `;
            case BRASILIAN_CULTURE_CODE:
                return html` <img class="dc-modal-header-img" src="/Images/DcStandalone/dc-logo-2-pt.png" /> `;
            default:
                return html` <img class="dc-modal-header-img" src="/Images/DcStandalone/dc-logo-2.png" /> `;
        }
    };

    const membershipOptions = useMemo((): SelectOption[] => {
        const standardValue: SelectedDcMembershipType = "Standard";
        const groupValue: SelectedDcMembershipType = "Group";

        return props.context.model.FlightDataViewModel.NumberOfPax <= MAX_PAX_IN_STANDARD_DC_MEMBERSHIP
            ? [
                  {
                      Text: i18next.t("DC-StandardMembership"),
                      Value: standardValue,
                      IsSelected: vm.selectedMembership === standardValue,
                  },
                  {
                      Text: i18next.t("DC-GroupMembership"),
                      Value: groupValue,
                      IsSelected: vm.selectedMembership === groupValue,
                  },
              ]
            : [
                  {
                      Text: i18next.t("DC-GroupMembership"),
                      Value: groupValue,
                      IsSelected: vm.selectedMembership === groupValue,
                  },
              ];
    }, [vm.selectedMembership, props.context.model.FlightDataViewModel.NumberOfPax]);

    const membershipTemplate = () => {
        return html`
            <div class="mt-[20px]">
                <ac-select
                    .label=${i18next.t("V2DC-TypeOfMembershipLabel")}
                    .options=${membershipOptions}
                    .tabIndex=${getTabIndex()}
                    .testId=${T.FLIGHT_DC_BANNER_MODAL.MEMBERSHIP}
                    .onSelect=${(value: SelectedDcMembershipType) => setVm({ ...vm, selectedMembership: value })}
                >
                </ac-select>
            </div>
        `;
    };

    const firstNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("firstName")}
                .isInvalid=${!validator.isValid("firstName")}
                .label=${`${i18next.t("V2-FirstNameLabel")}*`}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.FIRST_NAME}
                .value=${vm.firstName ?? ""}
                .onKeyUp=${handleKeydown}
                .onInput=${(value: string) => setVm({ ...vm, firstName: value })}
            ></ac-input>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("lastName")}
                .isInvalid=${!validator.isValid("lastName")}
                .label=${`${i18next.t("V2-LastNameLabel")}*`}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.LAST_NAME}
                .value=${vm.lastName ?? ""}
                .onKeyUp=${handleKeydown}
                .onInput=${(value: string) => setVm({ ...vm, lastName: value })}
            ></ac-input>
        </div>
    `;

    const passwordTemplate = () => html`
        <div class="relative mt-[20px]">
            <ac-input
                .autoComplete=${"new-password"}
                .errorMessage=${validator.getMessage("password")}
                .isInvalid=${!validator.isValid("password")}
                .label=${`${i18next.t("V2-PasswordPlaceholder")}*`}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.PASSWORD}
                .type=${"password"}
                .value=${vm.password ?? ""}
                .onKeyUp=${handleKeydown}
                .onInput=${(value: string) => setVm({ ...vm, password: value })}
            ></ac-input>
            <span class="pw-help icon">
                <ac-tooltip .icon=${"?"} .tooltip="${i18next.t("V2DC-PasswordTooltip")}"></ac-tooltip>
            </span>
        </div>
    `;

    const confirmPasswordTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("confirmPassword")}
                .isInvalid=${!validator.isValid("confirmPassword")}
                .label=${`${i18next.t("V2-ConfirmPassword")}*`}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.CONFIRM_PASSWORD}
                .type=${"password"}
                .value=${vm.confirmPassword ?? ""}
                .onKeyUp=${handleKeydown}
                .onInput=${(value: string) => setVm({ ...vm, confirmPassword: value })}
            ></ac-input>
        </div>
    `;

    const emailTemplate = () => html`
        <div class="mt-[20px]" ref=${ref(emailField)}>
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .label=${`${i18next.t("V2-EmailLabel")}*`}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.EMAIL}
                .value=${vm.email ?? ""}
                .onKeyUp=${handleKeydown}
                .onInput=${(value: string) => setVm({ ...vm, email: value })}
            ></ac-input>
        </div>
    `;

    const confirmEmailTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("confirmEmail")}
                .isInvalid=${!validator.isValid("confirmEmail")}
                .label=${`${i18next.t("V2-ConfirmEmail")}*`}
                .tabIndex=${getTabIndex()}
                .testId=${T.FLIGHT_DC_BANNER_MODAL.CONFIRM_EMAIL}
                .value=${vm.confirmEmail ?? ""}
                .onInput=${(value: string) => setVm({ ...vm, confirmEmail: value })}
            ></ac-input>
        </div>
    `;

    const limitInfoTemplate = () =>
        appContext.isFeatureSwitchActive("DC1stPurchaseLimit")
            ? html`
                  <div class="dc-modal-membership-warning">
                      <i class="js-icon js-dc-exclamation exclamation"></i>
                      <span class="dc-modal-membership-warning-text"> ${i18next.t("V2DC-DiscountInfo")} </span>
                  </div>
              `
            : "";

    const buttonsTemplate = () => {
        const cancelClassMap = classMap({
            "rounded-secondary-btn": true,
            "disabled": areButtonsDisabled,
        });

        const continueClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": areButtonsDisabled,
        });

        return html`
            <div class="dc-modal-btn-container">
                <button
                    class=${cancelClassMap}
                    data-test-id=${T.FLIGHT_DC_BANNER_MODAL.CANCEL_BUTTON}
                    @click=${handleCloseButton}
                >
                    ${i18next.t("V2-ReturnLabel")}
                </button>
                <button
                    ref=${ref(continueButton)}
                    class=${continueClassMap}
                    data-test-id=${T.FLIGHT_DC_BANNER_MODAL.CONTINUE_BUTTON}
                    @click=${handleContinueButton}
                >
                    ${i18next.t("V2-ApplyLabel")}
                </button>
            </div>
        `;
    };

    const headerTemplate = () => html`
        <div class="dc-modal-header">
            <div class="dc-modal-header-content">
                ${logoTemplate()}
                <div class="dc-modal-header-texts">
                    <div class="dc-modal-header-smaller-text uppercase">${i18next.t("FS-ModalTitle-1")}</div>
                    <div class="dc-modal-header-main-text uppercase">${i18next.t("FS-ModalTitle-2")}</div>
                </div>
            </div>
        </div>
    `;

    const termsErrorTemplate = () =>
        !validator.isValid("termsAccepted")
            ? html`
                  <div
                      class="error-message-container elevated-error text.left"
                      data-test-id=${T.FLIGHT_DC_BANNER_MODAL.ACCEPT_TERMS_ERROR}
                  >
                      <div class="form-error-message">${i18next.t("Breakdown-AcceptTermsFirst")}</div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="dc-modal-body" ref=${ref(root)}>
            <div class="dc-modal-body-title">${i18next.t("FS-ModalSubtitle-1")}</div>
            <div class="dc-modal-body-description">${i18next.t("FS-ModalSubtitle-2")}</div>
            <div class="ts-error-parent">
                <form class="dc-modal-form">
                    <div class="ts-error-container">
                        <div class="row">
                            <div class="col-xs-1 col-sm-1-2">${membershipTemplate()}</div>
                        </div>
                        <div class="row">
                            <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                            <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                        </div>
                        <div class="row">
                            <div class="col-xs-1 col-sm-1-2">${passwordTemplate()}</div>
                            <div class="col-xs-1 col-sm-1-2">${confirmPasswordTemplate()}</div>
                        </div>

                        <div class="row">
                            <div class="col-xs-1 col-sm-1-2">${emailTemplate()}</div>
                            <div class="col-xs-1 col-sm-1-2">${confirmEmailTemplate()}</div>
                        </div>
                    </div>

                    <div>${limitInfoTemplate()} ${termsCheckbox.htmlTemplate()} ${termsErrorTemplate()}</div>
                </form>
            </div>
            ${formErrors.htmlTemplate()} ${buttonsTemplate()}
        </div>
    `;

    const modal = useModal({
        closing: {
            buttonClassNames: "flight-modal-close",
            buttonTestId: T.FLIGHT_DC_BANNER_MODAL.CLOSE_BUTTON,
            isClosable: true,
            onClose: handleCloseButton,
        },
        content: {
            classNames: classNames({
                "dc-limit-info": appContext.isFeatureSwitchActive("DC1stPurchaseLimit"),
            }),
            testId: T.FLIGHT_DC_BANNER_MODAL.SCROLLER_CONTAINER,
            template: htmlTemplate,
        },
        header: { noScroll: true, template: headerTemplate },
        overlay: { classNames: "discount-club-modal" },
        scrolling: { isScrollable: true, classNames: "inset-x-0 bottom-0 top-[160px] sm:top-[90px] md:top-[100px]" },
    });

    return {
        ...modal,
        open: (selectedMembershipType: SelectedDcMembershipType) => {
            setVm({ ...defaultVm, selectedMembership: selectedMembershipType });
            modal.open();
        },
    };
};

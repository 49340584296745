import { CLASS_NAMES } from "../../../shared/classNames";
import { classMap } from "lit-html/directives/class-map";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { ChangeDateEvent } from "../../../dc-components/dc-datepicker";
import { html } from "lit-html";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { DEFAULT_DATE_FORMAT } from "../../../shared/commonConstants";
import { convertToMoment } from "../../../shared/common";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { handleFieldBlur } from "../../../shared/form-validation";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { useAppContext } from "../../../managers/useAppContext";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export interface Props {
    classes: string[];
    disabledDates?: dayjs.Dayjs[];
    inputClasses: string[];
    inputTestId?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isRange?: boolean;
    max?: dayjs.Dayjs;
    min?: dayjs.Dayjs;
    placeHolder: string;
    value: dayjs.Dayjs | dayjs.Dayjs[] | string | string[];
    wide?: boolean;
    onChange: (e: ChangeDateEvent) => void;
    onClose?: () => void;
    onOpen?: () => void;
}

export function useDatepicker(props: Props) {
    // HELPERS

    const init = () => {
        document.addEventListener("click", onClickOutside);
    };

    const onClickOutside = (e: any) => {
        const path = e.path || (e.composedPath && e.composedPath());

        const isClickInside = path.indexOf(inputField.current) > -1 || path.indexOf(datePicker.current) > -1;

        if (!isClickInside) {
            setIsOpen(false);
        }
    };

    const displayedValue = (): dayjs.Dayjs =>
        props.value
            ? (convertToMoment(
                  Array.isArray(props.value) ? props.value[props.value.length - 1] : props.value,
              ) as dayjs.Dayjs)
            : undefined;

    // EVENT LISTENERS

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();

        setIsOpen(!isOpen);
    };

    const handleChange = (e: ChangeDateEvent) => {
        props.onChange(e);
        setIsOpen(false);

        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.errorContainer);

        if (root.current && parent) {
            handleFieldBlur(e.target as HTMLInputElement, parent);
        }
    };

    const open = () => setIsOpen(true);

    const close = () => setIsOpen(false);

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);
    const inputField = useRef<HTMLInputElement>(undefined);
    const datePicker = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();
    const cug2AppContext = useCug2AppContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.errorContainer);
        if (parent && inputField.current?.offsetHeight > 0) {
            handleFieldBlur(inputField.current, parent);
        }
    }, [JSON.stringify(props.value)]);

    useEffect(() => {
        if (isOpen && typeof props.onOpen === "function") {
            props.onOpen();
        }

        if (!isOpen && typeof props.onClose === "function") {
            props.onClose();
        }
    }, [isOpen]);

    useEffect(init, []);

    // TEMPLATES

    const loaderTemplate = () =>
        props.isLoading
            ? html`
                  <div class="loader-container">
                      <div class="loader-line">
                          <div class="loader-line-wrap"></div>
                      </div>
                      <div class="loader-line">
                          <div class="loader-line-wrap"></div>
                      </div>
                      <div class="loader-line">
                          <div class="loader-line-wrap"></div>
                      </div>
                  </div>
              `
            : "";

    const iconTemplate = () =>
        !props.isLoading ? html` <i class="js-icon-cug-notification js-cn-calendar"></i> ` : "";

    const rootClassMap = classMap({
        "ac-datepicker": true,
        "open": isOpen,
        "wide": props.wide,
    });

    const inputClassMap = classMap({
        ...props.inputClasses
            .filter((i) => i)
            .reduce((obj, className) => {
                obj[className] = true;
                return obj;
            }, {} as any),
        "cursor-pointer": true,
        "disabled": props.isLoading || props.isDisabled,
    });

    const datePickerTemplate = () =>
        isOpen
            ? html`
                  <dc-datepicker
                      ref=${ref(datePicker)}
                      .culture=${appContext.Culture || cug2AppContext.Culture}
                      .disabledDates=${props.disabledDates || []}
                      .isRange=${props.isRange}
                      .classes=${props.classes}
                      .max=${props.max}
                      .min=${props.min}
                      .value=${props.value}
                      .setIsOpen=${setIsOpen}
                      @change=${handleChange}
                  ></dc-datepicker>
              `
            : "";

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class=${rootClassMap}>
            <div class="form-group form-group-with-loader">
                <input
                    ref=${ref(inputField)}
                    class=${inputClassMap}
                    placeholder=${props.placeHolder}
                    readonly
                    data-required
                    data-test-id=${props.inputTestId || ""}
                    value=${displayedValue()?.format(DEFAULT_DATE_FORMAT) || ""}
                    .value=${displayedValue()?.format(DEFAULT_DATE_FORMAT) || ""}
                    @click=${handleClick}
                />
                ${iconTemplate()} ${datePickerTemplate()} ${loaderTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate, open, close };
}

import { BaggageContext } from "../../component-models/baggage/BaggageContext";
import { BaggageState, BaggageStateJourneyPassenger } from "../../component-models/baggage/BaggageState";
import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, OUTBOUND } from "../../shared/commonConstants";
import { TealiumLog, useTealiumManager } from "./useTealiumManager";
import { BagType } from "../../components/spa/baggage/useBaggagePage";
import { StaffBaggage } from "../../components/spa/baggage/common/useStaffBaggage";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    bagType: BagType;
    context: BaggageContext;
    staffBaggage: StaffBaggage;
    state: BaggageState;
}

export const useBaggageTealiumManager = (props: Props) => {
    const tealiumManager = useTealiumManager();

    const { formatNumber } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    const logBaggageReset = (data: { journeyIndices: number[]; paxIndices: number[] }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                    bagType: props.bagType,
                    journeyIndex,
                    passengerIndex,
                });
                const commonObject = commonTealiumLogObject({
                    bagType: props.bagType,
                    context: props.context,
                    journeyIndex,
                    paxIndex: passengerIndex,
                    state: props.state,
                });
                tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: contextJourneyPassengerBaggage.min,
                        price: 0,
                    },
                });
            });
        });
    };

    const getBagPrice = (data: {
        journeyIndex: number;
        passenger: BaggageStateJourneyPassenger;
        passengerIndex: number;
        type: "add" | "remove";
    }) => {
        const canGetDiscount = props.staffBaggage.canGetFirstBaggageStaffDiscount({
            journeyIndex: data.journeyIndex,
            paxIndex: data.passengerIndex,
            bagType: props.bagType,
        });
        const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
            bagType: props.bagType,
            journeyIndex: data.journeyIndex,
            passengerIndex: data.passengerIndex,
        });

        // DEVNOTE Staff members get the first bag for $1
        const getRelatedStaffBaggage = props.staffBaggage.state.filter(
            (b) => b.passengerNumber === data.passengerIndex && b.journeyIndex === data.journeyIndex,
        );

        return contextJourneyPassengerBaggage.prices.reduce((aggr, curr, i) => {
            if (
                i === 0 &&
                (canGetDiscount ||
                    getRelatedStaffBaggage.some(
                        (b) =>
                            (props.bagType === "CabinBaggage" && b.used === "Cabin") ||
                            (props.bagType === "CheckedBaggage" && b.used === "Checked"),
                    ))
            ) {
                return aggr + FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
            }

            const quantity = props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: data.journeyIndex,
                passengerIndex: data.passengerIndex,
            }).quantity;

            return i < quantity + (data.type === "add" ? 1 : -1) ? aggr + curr : aggr;
        }, 0);
    };

    const logBaggageAdd = (data: {
        bagType: BagType;
        context: BaggageContext;
        journeyIndices: number[];
        paxIndices: number[];
        state: BaggageState;
    }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const passenger = props.state.journeys[journeyIndex].passengers[passengerIndex];
                const commonObject = commonTealiumLogObject({
                    ...data,
                    journeyIndex,
                    paxIndex: passengerIndex,
                });

                const amount = getBagPrice({
                    ...data,
                    journeyIndex,
                    passenger,
                    passengerIndex,
                    type: "add",
                });

                const quantity = props.context.getContextJourneyPassengerBaggage({
                    bagType: props.bagType,
                    journeyIndex,
                    passengerIndex,
                }).quantity;

                tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: quantity + 1,
                        price: formatNumber({ amount, leadingSign: false, omitGrouping: true }),
                    },
                });
            });
        });
    };

    const logBaggageRemove = (data: { journeyIndices: number[]; paxIndices: number[] }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const passenger = props.state.journeys[journeyIndex].passengers[passengerIndex];
                const commonObject = commonTealiumLogObject({
                    bagType: props.bagType,
                    context: props.context,
                    journeyIndex,
                    paxIndex: passengerIndex,
                    state: props.state,
                });

                const amount = getBagPrice({
                    journeyIndex,
                    passenger,
                    passengerIndex,
                    type: "remove",
                });

                const quantity = props.context.getContextJourneyPassengerBaggage({
                    bagType: props.bagType,
                    journeyIndex,
                    passengerIndex,
                }).quantity;

                tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: quantity - 1,
                        price: formatNumber({ amount, leadingSign: false, omitGrouping: true }),
                    },
                });
            });
        });
    };

    const commonTealiumLogObject = (data: {
        bagType: BagType;
        context: BaggageContext;
        journeyIndex: number;
        paxIndex: number;
        state: BaggageState;
    }): TealiumLog => {
        const previousQuantityPax = props.context.journeys[data.journeyIndex].passengers[data.paxIndex];

        return {
            EventName: "baggage_selected",
            EventParams: {
                currency,
                direction: data.journeyIndex === OUTBOUND ? "O" : "I",
                paxindex: data.paxIndex,
                paxtype: props.context.passengers[data.paxIndex].type === "ADT" ? "A" : "C",
                previous_quantity: (data.bagType === "CabinBaggage"
                    ? previousQuantityPax.cabinBaggage
                    : data.bagType === "CheckedBaggage"
                      ? previousQuantityPax.checkedBaggage
                      : previousQuantityPax.oversizedBaggage
                ).quantity,
                type: props.bagType === "CabinBaggage" ? "C" : props.bagType === "CheckedBaggage" ? "B" : "S",
            },
            UpdateCommonUdo: true,
        };
    };

    return {
        logBaggageAdd,
        logBaggageRemove,
        logBaggageReset,
        logContinueClicked: tealiumManager.logContinueClicked,
    };
};

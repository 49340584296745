import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { BaggageContext } from "../../../component-models/baggage/BaggageContext";
import { html, useEffect } from "haunted";
import i18next from "i18next";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { finishedSelection, paxLabel, paxAmount } from "../../../component-helpers/BaggageHelper";
import { isMobile } from "../../../shared/common";
import { useBaggageEditButton } from "./common/useBaggageEditButton";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerBookingQuantityButtons } from "./perBookingView/usePerBookingQuantityButtons";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { useMobilePerBookingOversized } from "./perBookingView/useMobilePerBookingOversized";
import { useBaggageSection } from "./common/useBaggageSection";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { StaffBaggage } from "./common/useStaffBaggage";
import classNames from "classnames";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export interface Props {
    context: BaggageContext;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useOversizedBags = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const oversizedBag = useBaggageSection({
        bagType: "OversizedBaggage",
        context: props.context,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const editButton = useBaggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, { c: "OversizedBaggage" }),
        isDisabled: oversizedBag.perBooking.quantity === undefined,
        onClick: () => handlePerJourneyPerPaxViewOpen(),
    });

    const quantityButtons = usePerBookingQuantityButtons({
        baggageSection: oversizedBag,
        isMobile: false,
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: oversizedBag,
        context: props.context,
        pageState: props.pageState,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: oversizedBag,
        context: props.context,
        pageState: props.pageState,
    });

    const mobilePerBookingOversized = useMobilePerBookingOversized({
        baggageSection: oversizedBag,
        context: props.context,
        setIsOversizedBaggageOpen: setIsOpen,
    });

    const freeBagSelected = useMemo(
        () =>
            oversizedBag.state.journeys.every((baggageStatejourney) =>
                baggageStatejourney.passengers.every((passenger) => {
                    const journeyPassengers = props.context.journeys.find(
                        (journey) => journey.index === baggageStatejourney.index,
                    ).passengers;
                    const journeyPassenger = journeyPassengers.find((pax) => pax.index === passenger.index);
                    return !journeyPassenger.oversizedBaggage.quantity;
                }),
            ),
        [oversizedBag.state.journeys, props.context.journeys],
    );

    const showOversizedErrors = useMemo(
        () => isOpen && !finishedSelection(oversizedBag.state),
        [isOpen, oversizedBag.state],
    );

    const isOversizedSoldOutAndOneJourney = useMemo(
        () =>
            (() => props.context.journeys.length === 1)() &&
            props.context.journeys[0].passengers.some((passenger) => passenger.oversizedBaggage.isSoldOut),
        [props.context.journeys],
    );

    const isOversizedBought = useMemo(
        () =>
            props.context.journeys.some((journey) =>
                journey.passengers.some((passenger) => passenger.oversizedBaggage.quantity > 0),
            ),
        [props.context.journeys],
    );

    const reset = () => setIsOpen(isOversizedBought || isOversizedSoldOutAndOneJourney);

    const unformattedLowestNextPrice = (isMinimum: boolean) =>
        oversizedBag.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: isMinimum,
            original: false,
            stateJourneys: oversizedBag.state.journeys,
        });

    const formattedLowestNextPrice = (isMinimum: boolean) =>
        formatNumber({ amount: unformattedLowestNextPrice(isMinimum), leadingSign: true });

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;

        const journeyWithError = oversizedBag.state.journeys.find((journey) =>
            journey.passengers.some(
                (passenger) =>
                    !oversizedBag.handlers.isPassengerValid({
                        journey,
                        passenger,
                    }),
            ),
        );

        if (
            journeyWithError &&
            oversizedBag.state.perJourneyPerPaxState === "open" &&
            !finishedSelection(oversizedBag.state)
        ) {
            const paxWithError = journeyWithError.passengers.find(
                (passenger) =>
                    !oversizedBag.handlers.isPassengerValid({
                        journey: journeyWithError,
                        passenger,
                    }),
            );

            if (paxWithError) {
                oversizedBag.handlers.openPax({
                    JourneyIndices: [journeyWithError.index],
                    PaxIndices: [paxWithError.index],
                });
            }
        }
    };

    // Event handlers

    const handlePerJourneyPerPaxViewOpen = () => {
        oversizedBag.handlers.openPerJourneyPerPaxView();
        setIsOpen(true);
    };

    const handlePerBookingAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        oversizedBag.handlers.add();
    };

    const handleAccordionToggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (!oversizedBag.state.hasPerJourneyPerPaxStateChanged) return;

        const element = DomCrawlingHelper.getElemByClass(
            document.body,
            oversizedBag.state.perJourneyPerPaxState === "open"
                ? props.context.classesToScrollToOnOpen.get("OversizedBaggage")
                : props.context.classesToScrollToOnClose.get("OversizedBaggage"),
        );
        ScrollHelper.scrollToElementAndHideNav({
            element,
            yOffset: 0,
            timeOffset: 500,
        });
    }, [oversizedBag.state.perJourneyPerPaxState]);

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    useEffect(reset, [props.model]);

    // Template

    const perBookingAddButtonTemplate = () => {
        const dataTestId = getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, { c: "OversizedBaggage" });

        return freeBagSelected
            ? html`
                  <button @click=${handlePerBookingAdd} class="b2-primary-button" data-test-id=${dataTestId}>
                      ${i18next.t("Agregar")}
                  </button>
              `
            : html`
                  <div class="flex w-full items-center justify-around">
                      <button disabled class="b2-primary-button ticked" data-test-id=${dataTestId}>
                          ${i18next.t("Agregado")}
                      </button>
                      <div class="b2-oversized-quantity">
                          <div class="ac-per-booking-quantity-buttons">${quantityButtons.htmlTemplate()}</div>
                      </div>
                  </div>
                  ${perBookingNextPriceTemplate(false)}
              `;
    };

    const viewSwitchTemplate = () => {
        const infoText =
            paxAmount(oversizedBag.state) === 1
                ? i18next.t("Estás agregando para todos los tramos")
                : i18next.t("Estás agregando para todos los pasajeros y tramos");

        const tempClassMap = classNames("b2-openable hidden-xs", {
            "b2-opened": oversizedBag.state.perJourneyPerPaxState !== "open",
        });

        return html`
            <div class=${tempClassMap}>
                <div class="flex w-full items-center justify-between">
                    <div class="b2-per-pax-view-switch-oversized">
                        <div class="b2-view-info">${infoText}</div>
                        <div class="b2-pax-amount">
                            ${paxAmount(oversizedBag.state)} ${paxLabel(paxAmount(oversizedBag.state))}
                        </div>
                        ${editButton.htmlTemplate()}
                    </div>
                    <div class="flex flex-col items-center justify-center">${perBookingAddButtonTemplate()}</div>
                </div>
            </div>
        `;
    };

    const perBookingNextPriceTemplate = (isMobile: boolean) =>
        oversizedBag.perBooking.isAddAvailable
            ? html`
                  <div class="b2-add-another-oversized">
                      ${isMobile ? i18next.t("Agrega otro por") : i18next.t("Agrega otro desde")}
                      <span
                          class="b2-amount"
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, { c: "OversizedBaggage" })}
                          data-test-value=${unformattedLowestNextPrice(false)}
                      >
                          ${formattedLowestNextPrice(false)}
                      </span>
                  </div>
              `
            : "";

    const headerTemplate = () => {
        const tempClassMap = classNames("b2-oversized-top", {
            "per-pax": oversizedBag.state.perJourneyPerPaxState === "open" && isOpen,
        });

        const openerClassMap = classNames("b2-oversized-top-amount", {
            "hidden-sm-up": oversizedBag.state.perJourneyPerPaxState === "open" && isOpen,
        });

        const iconClassMap = classNames("js-icon js-circle-chevron-right", {
            rotated: isOpen,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${T.BAGGAGE.OVERSIZED_ACCORDION_OPENER}
                @click=${handleAccordionToggle}
            >
                <div class="b2-oversized-title" data-test-id=${T.BAGGAGE.OVERSIZED_TITLE}>
                    <i class="js-icon-bag js-bag-guitar-surf-golf"></i>
                    <span>${i18next.t("¿Viajas con equipaje sobredimensionado?")}</span>
                </div>
                <div class="b2-oversized-opener">
                    <div class=${openerClassMap}>
                        ${unformattedLowestNextPrice(true) >= 0
                            ? html`
                                  ${i18next.t("Agrégalo desde")}
                                  <span
                                      data-test-value=${unformattedLowestNextPrice(true)}
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                                          c: "OversizedBaggage",
                                          m: isMobile(),
                                      })}
                                  >
                                      ${formattedLowestNextPrice(true)}
                                  </span>
                              `
                            : ""}
                    </div>
                    <i class=${iconClassMap}></i>
                </div>
            </div>
        `;
    };

    const accordionHeaderTemplate = () => {
        const tempClassMap = classNames("b2-oversize-open-top", {
            bordered: oversizedBag.state.perJourneyPerPaxState === "open",
        });

        return html`
            <div class=${tempClassMap}>
                <div class="b2-oversize-info push-down">
                    ${i18next.t(
                        "Artículo deportivo o instrumento musical con un peso máximo de 23 KG y medidas entre 158 y 230 cm lineales.",
                    )}
                </div>
                <div class="b2-oversize-info">${i18next.t("*Máximo 3 equipajes sobredimensionados por persona.")}</div>
                ${viewSwitchTemplate()}
            </div>
        `;
    };

    const accordionTemplate = () =>
        isOpen
            ? html`
                  <div class="b2-oversized-grid" data-test-id=${T.BAGGAGE.OVERSIZED_ACCORDION}>
                      ${accordionHeaderTemplate()}
                      <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                  </div>
                  <div
                      class="b2m-oversized-bag-container hidden-sm-up"
                      data-test-id=${getTestId(T.BAGGAGE.OVERSIZED_ACCORDION, { m: true })}
                  >
                      ${mobilePerBookingOversized.htmlTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "OversizedBaggage" })}>
            <header class="b2-oversized-header ${props.context.classesToScrollToOnClose.get("OversizedBaggage")}">
                ${headerTemplate()} ${accordionTemplate()}
            </header>
        </div>
    `;

    return {
        showOversizedErrors,
        handlers: oversizedBag.handlers,
        state: oversizedBag.state,
        htmlTemplate,
    };
};

import { useRef } from "haunted";
import { useEffect } from "./CustomHooks";

export const useClickOutside = <T extends Element>(callback: () => void) => {
    const ref = useRef<T>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [callback]);

    return ref;
};

import {
    BaggageContext,
    BaggageContextJourney,
    BaggageContextJourneyPassengerBaggage,
} from "../../../../component-models/baggage/BaggageContext";
import { BagType } from "../useBaggagePage";
import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, OUTBOUND } from "../../../../shared/commonConstants";
import {
    BaggageState,
    BaggageStateJourney,
    BaggageStateJourneyPassenger,
} from "../../../../component-models/baggage/BaggageState";
import { StaffBaggage } from "./useStaffBaggage";
import { PerBookingAvailability } from "../../../../component-models/baggage/BaggageSection";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useCallback } from "haunted";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    bagType: BagType;
    context: BaggageContext;
    staffBaggage: StaffBaggage;
    state: BaggageState;
}

export const usePerBookingAvailability = (props: Props): PerBookingAvailability => {
    const bookingContext = useBookingContext();

    const firstPaxBag = useMemo(
        () =>
            props.context.getContextJourneyPassengerBaggage({
                bagType: props.bagType,
                journeyIndex: OUTBOUND,
                passengerIndex: 0,
            }),
        [props.context.getContextJourneyPassengerBaggage, props.bagType],
    );

    const isOversizedOnePax = useMemo(
        () => props.bagType === "OversizedBaggage" && props.context.passengers.length === 1,
        [props.bagType, props.context.passengers.length],
    );

    const isAnySoldOut = useMemo(
        () =>
            props.context.journeys.some((journey) =>
                journey.passengers.some(
                    (passenger) =>
                        props.context.getContextJourneyPassengerBaggage({
                            bagType: props.bagType,
                            journeyIndex: journey.index,
                            passengerIndex: passenger.index,
                        }).isSoldOut,
                ),
            ),
        [props.context, props.context.getContextJourneyPassengerBaggage, props.bagType],
    );

    const doAllPaxHaveTheSameQuantity = useMemo(
        () =>
            props.context.journeys.every((journey) =>
                journey.passengers.every((passenger) => {
                    const quantity = props.context.getContextJourneyPassengerBaggage({
                        bagType: props.bagType,
                        journeyIndex: journey.index,
                        passengerIndex: passenger.index,
                    }).quantity;

                    return quantity === firstPaxBag.quantity;
                }),
            ),
        [props.context, props.context.getContextJourneyPassengerBaggage, props.bagType, firstPaxBag.quantity],
    );

    const isNotEnoughOversizedForAllJourneysAllPax = useMemo(
        () => !props.context.journeys.every((j) => j.availableOversizedBaggage >= props.context.passengers.length),
        [props.context.journeys],
    );

    const doAllPaxHaveSameMinAndMax = useMemo(
        () =>
            props.state.journeys.every((journey) =>
                journey.passengers.every((passenger) => {
                    const contextJourneyPassengerBaggage = props.context.getContextJourneyPassengerBaggage({
                        bagType: props.bagType,
                        journeyIndex: journey.index,
                        passengerIndex: passenger.index,
                    });

                    return (
                        contextJourneyPassengerBaggage.max === firstPaxBag.max &&
                        contextJourneyPassengerBaggage.min === firstPaxBag.min
                    );
                }),
            ),
        [
            props.context,
            props.context.getContextJourneyPassengerBaggage,
            props.bagType,
            firstPaxBag.max,
            firstPaxBag.min,
        ],
    );

    const isPerBookingAvailable = useCallback(
        (isReset: boolean) => {
            if (bookingContext.isCheckinClosedOutbound || bookingContext.isCheckinClosedInbound) return false;
            if (!doAllPaxHaveSameMinAndMax) return false;
            if (!doAllPaxHaveTheSameQuantity && !isReset) return false;
            if (isAnySoldOut) return false;
            if (isNotEnoughOversizedForAllJourneysAllPax && props.bagType === "OversizedBaggage") return false;

            return true;
        },

        [
            bookingContext.isCheckinClosedInbound,
            bookingContext.isCheckinClosedOutbound,
            doAllPaxHaveSameMinAndMax,
            doAllPaxHaveTheSameQuantity,
            isAnySoldOut,
            isNotEnoughOversizedForAllJourneysAllPax,
            props.bagType,
        ],
    );

    const isAddAvailable = useMemo(
        () =>
            isPerBookingAvailable(false) &&
            firstPaxBag.quantity < firstPaxBag.max &&
            props.state.journeys.every((j) => {
                const currentJourney = props.context.journeys.find((journey) => journey.index === j.index);
                const availableCurrentBagTypeQuantity =
                    currentJourney[
                        props.bagType === "CabinBaggage"
                            ? "availableCabinBaggage"
                            : props.bagType === "CheckedBaggage"
                              ? "availableCheckedBaggage"
                              : "availableOversizedBaggage"
                    ];

                return availableCurrentBagTypeQuantity >= props.context.passengers.length;
            }),
        [
            isPerBookingAvailable(false),
            firstPaxBag.quantity,
            firstPaxBag.max,
            props.state.journeys,
            props.context.journeys,
            props.bagType,
            props.context.passengers.length,
        ],
    );

    const isRemoveAvailable = useMemo(
        () =>
            isPerBookingAvailable(false) &&
            firstPaxBag.quantity > Math.max(props.bagType === "OversizedBaggage" ? 0 : 1, firstPaxBag.min),
        [isPerBookingAvailable(false), firstPaxBag],
    );

    const isResetAvailable = useMemo(
        () => isPerBookingAvailable(true) && !isOversizedOnePax,
        [isPerBookingAvailable(true), isOversizedOnePax],
    );

    const isRemoveAllAvailable = useMemo(
        () => isResetAvailable && firstPaxBag.min === 0,
        [isResetAvailable, firstPaxBag],
    );

    const getLowestNextPrice = useCallback(
        (data: {
            contextJourneys: BaggageContextJourney[];
            minimum: boolean;
            original: boolean;
            stateJourneys: BaggageStateJourney[];
        }) => {
            if (!data.stateJourneys?.length) return 0;

            const retVal = data.contextJourneys.reduce((lowestPrice, journey) => {
                const lowestJourneyPrice = journey.passengers.reduce((lowestJourneyPrice, passenger) => {
                    const relatedPax: BaggageStateJourneyPassenger = data.stateJourneys
                        .find((j) => j.index === journey.index)
                        .passengers.find((p) => p.index === passenger.index);

                    // DEVNOTE Staff members get the first bag for $1
                    if (
                        props.staffBaggage.canGetFirstBaggageStaffDiscount({
                            bagType: props.bagType,
                            journeyIndex: journey.index,
                            paxIndex: relatedPax.index,
                        })
                    ) {
                        return FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
                    }

                    const paxBag: BaggageContextJourneyPassengerBaggage =
                        props.context.getContextJourneyPassengerBaggage({
                            bagType: props.bagType,
                            journeyIndex: journey.index,
                            passengerIndex: passenger.index,
                        });

                    const paxBagPrices = paxBag[data.original ? "originalPrices" : "prices"];

                    const quantity = props.context.getContextJourneyPassengerBaggage({
                        bagType: props.bagType,
                        journeyIndex: journey.index,
                        passengerIndex: relatedPax.index,
                    }).quantity;

                    const index = data.minimum ? 0 : quantity < paxBag.max ? quantity : quantity - 1;
                    return paxBagPrices[index] < lowestJourneyPrice ? paxBagPrices[index] : lowestJourneyPrice;
                }, Number.POSITIVE_INFINITY);

                return lowestJourneyPrice < lowestPrice ? lowestJourneyPrice : lowestPrice;
            }, Number.POSITIVE_INFINITY);

            return retVal < 99999999 ? retVal : -1;
        },
        [
            props.staffBaggage.canGetFirstBaggageStaffDiscount,
            props.context.getContextJourneyPassengerBaggage,
            props.bagType,
        ],
    );

    return {
        isAddAvailable,
        isRemoveAllAvailable,
        isRemoveAvailable,
        isResetAvailable,
        quantity: firstPaxBag.quantity,
        getLowestNextPrice,
    };
};

import { html } from "lit-html";
import { ACTIONS_WITH_BANCO_ESTADO_BAR } from "../../shared/commonConstants";
import classNames from "classnames";
import i18next from "i18next";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    showSidebar: boolean;
}

export const useBancoEstadoRibbon = (props: Props) => {
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const isCurrentActionForBancoEstadoBanners = () => ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action) > -1;

    // DEVNOTE This only works AFTER the flight page, because on the flight page they can log in, and AppContext might not be up
    const isBancoEstadoRibbonDisplayed = () =>
        !flowContext.isCheckinFlow &&
        [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category) &&
        isCurrentActionForBancoEstadoBanners();

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext?.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const showBancoEstadoRibbon = () => {
        return (
            !flowContext.isCheckinFlow &&
            isCurrentBancoEstadoCategory([1, 2, 3, 4, 7]) &&
            ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action) > -1
        );
    };

    const htmlTemplate = () =>
        showBancoEstadoRibbon()
            ? html`
                  <section class=${classNames("be-ribbon", { "full-width": !props.showSidebar })}>
                      ${flowContext.isChangeFlow
                          ? html` <div class="be-container">
                                <span>${i18next.t("BE-ribbon-1")}</span>&nbsp; &nbsp;
                                <span>${i18next.t("BE-ribbon-2")}</span>
                            </div>`
                          : html` <div class="be-container">
                                <span>${i18next.t("BE-ribbon-0")}</span>&nbsp; &nbsp;
                                <span>${i18next.t("BE-ribbon-2")}</span>
                            </div>`}
                  </section>
              `
            : "";

    return { htmlTemplate, isBancoEstadoRibbonDisplayed };
};

import { CugUser } from "./CugUserData";
import { Partner } from "./Partner";
export interface CancelBookingEventDetail {
    index: number;
}

export class CancelBookingEvent extends CustomEvent<CancelBookingEventDetail> {
    constructor(detail: CancelBookingEventDetail) {
        super("onCancel", { detail });
    }
}

export interface EditRowEventDetail {
    index: number;
    toExtras: boolean;
}

export class EditRowEvent extends CustomEvent<EditRowEventDetail> {
    constructor(detail: EditRowEventDetail) {
        super("onEditRow", { detail });
    }
}

export interface EditUserEventDetail {
    user: CugUser;
}

export class EditUserEvent extends CustomEvent<EditUserEventDetail> {
    constructor(detail: EditUserEventDetail) {
        super("edit", { detail });
    }
}

export interface ResetUserPasswordEventDetail {
    user: CugUser;
}

export class ResetUserPasswordEvent extends CustomEvent<ResetUserPasswordEventDetail> {
    constructor(detail: ResetUserPasswordEventDetail) {
        super("reset", { detail });
    }
}

export interface ChangeUserStatusEventDetail {
    user: CugUser;
}

export class ChangeUserStatusEvent extends CustomEvent<ChangeUserStatusEventDetail> {
    constructor(detail: ChangeUserStatusEventDetail) {
        super("changeStatus", { detail });
    }
}

export interface UserAddSubmissionEventDetail {
    form: any;
}

export class UserAddSubmissionEvent extends CustomEvent<UserAddSubmissionEventDetail> {
    constructor(detail: UserAddSubmissionEventDetail) {
        super("addSubmission", { detail, bubbles: true });
    }
}

export interface UserEditSubmissionEventDetail {
    form: any;
}

export class UserEditSubmissionEvent extends CustomEvent<UserEditSubmissionEventDetail> {
    constructor(detail: UserEditSubmissionEventDetail) {
        super("editSubmission", { detail, bubbles: true });
    }
}

export interface GlobalFilterChangeEventDetail {
    filterExpression: string;
}

export class GlobalFilterChangeEvent extends CustomEvent<GlobalFilterChangeEventDetail> {
    constructor(detail: GlobalFilterChangeEventDetail) {
        super("onGlobalFilterChange", { detail });
    }
}

export class ClearFilterByDateEvent extends CustomEvent<{}> {
    constructor() {
        super("onClearFilterByDate");
    }
}

export class ClickFilterByDateEvent extends CustomEvent<{}> {
    constructor() {
        super("onFilterByDate");
    }
}

export class ModalCloseEvent extends CustomEvent<{}> {
    constructor() {
        super("close", { bubbles: true });
    }
}

export class UpdatePartnerEventDetail {
    partner: Partner;
}

export class UpdatePartnerEvent extends CustomEvent<UpdatePartnerEventDetail> {
    constructor(detail: UpdatePartnerEventDetail) {
        super("update", { detail, bubbles: true });
    }
}

export class DeleteRowEventDetail {
    spnr: string;
}

export class DeleteRowEvent extends CustomEvent<DeleteRowEventDetail> {
    constructor(detail: DeleteRowEventDetail) {
        super("delete", { detail });
    }
}

export class DeletePartnersModalYesEvent extends CustomEvent<{}> {
    constructor() {
        super("selectYes", { bubbles: true });
    }
}

export class DeletePartnersModalNoEvent extends CustomEvent<{}> {
    constructor() {
        super("selectNo", { bubbles: true });
    }
}

import { html } from "haunted";
import i18next from "i18next";
import { useModal } from "../shared/useModal";

export const useMessageLengthModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">${i18next.t("V2-GiftCardMEssageError-ModalText")}</div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => html`<span>${i18next.t("V2-GiftCardMEssageError-Important")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};

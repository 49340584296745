import i18next from "i18next";
import { html } from "lit-html";
import { useInstallmentDropdownOptions } from "./useInstallmentDropdownOptions";
import { useInstallmentMessage } from "./useInstallmentMessage";
import { SelectOption } from "../../shared/common";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    options: SelectOption[];
    selectedInstallmentsNumber: number;
    handleNumberOfInstallmentsChange: (e?: MouseEvent, numberOfInstallments?: number) => void;
}

export const useInstallmentsCommonTemplate = (props: Props) => {
    const [paymentMethod] = useReduxState("payment.paymentMethod");

    const optionsList = useInstallmentDropdownOptions({ options: props.options });

    const installmentMessage = useInstallmentMessage({ selectedInstallmentNumber: props.selectedInstallmentsNumber });

    const htmlTemplate = () => html`
        <label class="mdl-textfield__label">${i18next.t("Payment-NumberOfPayments")}</label>
        <select
            class="mdl-textfield__input js-input  js-select"
            data-test-id=${`installments-number-${paymentMethod?.PaymentMethodCode}`}
            .value=${props.selectedInstallmentsNumber.toString()}
            @change=${props.handleNumberOfInstallmentsChange}
        >
            ${optionsList.htmlTemplate()}
        </select>
        ${installmentMessage.htmlTemplate()}
    `;

    return {
        htmlTemplate,
        removeMessage: installmentMessage.removeMessage,
    };
};

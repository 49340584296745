import { PassengerFormVM } from "../../component-models/passengers/PassengerFormVM";
import { mapToFrequentFlyerDocument } from "../../component-mappers/TravelDocumentMapper";
import {
    AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER,
    AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE,
    TravelDocument,
} from "../../component-models/checkin/TravelDocument";
import { ApiNewPassenger } from "../../component-models/passengers/ApiNewPassengersModel";
import { useFlowContext } from "../../managers/useFlowContext";
import { useState } from "../../shared/haunted/CustomHooks";

export const useFrequentFlyerNumberCollectionHandler = () => {
    const flowContext = useFlowContext();

    const [isInitialized, setIsInitialized] = useState(false);
    const [frequentFlyerNumbers, setFrequentFlyerNumbers] = useState<TravelDocument[]>([]);

    const getInitialDocuments = (passengers: ApiNewPassenger[]) =>
        passengers.reduce((allDocuments, passenger) => {
            const passengerDocuments = passenger.TravelDocuments.filter(
                (d) => d.DocumentType === AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE,
            ).map((document) =>
                mapToFrequentFlyerDocument({
                    document,
                    isBookingFlow: flowContext.isBookingFlow,
                    passenger,
                }),
            );

            return allDocuments.concat(passengerDocuments);
        }, [] as TravelDocument[]);

    const getNewFrequentFlyerNumberFromPaxForm = (newDocumentPassenger: PassengerFormVM): TravelDocument => ({
        DocSuffix: " ",
        ExpirationDate: "",
        IsReadonly: false,
        IssuedByCode: AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER,
        Number: newDocumentPassenger.frequentFlyerNumber,
        PassengerNumber: newDocumentPassenger.passengerNumber,
        TypeCode: AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE,
    });

    const updateExistingFrequentFlyerNumber = (newDocumentPassenger: PassengerFormVM) =>
        setFrequentFlyerNumbers(
            frequentFlyerNumbers.map((doc) =>
                doc.PassengerNumber === newDocumentPassenger.passengerNumber
                    ? { ...doc, Number: newDocumentPassenger.frequentFlyerNumber }
                    : doc,
            ),
        );

    const addNewFrequentFlyerNumber = (newDocumentPassenger: PassengerFormVM) =>
        setFrequentFlyerNumbers([...frequentFlyerNumbers, getNewFrequentFlyerNumberFromPaxForm(newDocumentPassenger)]);

    const hasExistingFrequentFlyerNumber = (newDocumentPassenger: PassengerFormVM) =>
        frequentFlyerNumbers.some((doc) => doc.PassengerNumber === newDocumentPassenger.passengerNumber);

    // EXPORTS

    const init = (passengers: ApiNewPassenger[]) => {
        if (isInitialized) return;

        const frequentFlyerNumbers = getInitialDocuments(passengers);

        setFrequentFlyerNumbers(frequentFlyerNumbers);
        setIsInitialized(true);
    };

    const areAllFrequentFlyerNumbersUnique = () =>
        !frequentFlyerNumbers.some((document) => !isFrequentFlyerNumberUnique(document.Number));

    const isFrequentFlyerNumberUnique = (documentNumber: string) =>
        frequentFlyerNumbers.filter(
            (document) => document?.Number && documentNumber && document.Number === documentNumber,
        ).length < 2;

    const updateFrequentFlyerNumber = (newDocumentPassenger: PassengerFormVM) => {
        if (hasExistingFrequentFlyerNumber(newDocumentPassenger)) {
            updateExistingFrequentFlyerNumber(newDocumentPassenger);
            return;
        }

        addNewFrequentFlyerNumber(newDocumentPassenger);
    };

    return {
        frequentFlyerNumbers,
        areAllFrequentFlyerNumbersUnique,
        init,
        isFrequentFlyerNumberUnique,
        updateFrequentFlyerNumber,
    };
};

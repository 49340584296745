import * as dayjs from "dayjs";
import {
    ApiPeruCompraReservationsModel,
    ApiPeruCompraReservationsQueryStringParameters,
    ApiPeruCompraReservationModel,
} from "../component-models/peru-compra/ApiPeruCompraReservationModel";
import {
    PeruCompraReservationsModel,
    PeruCompraReservationsQueryStringParameters,
    PeruCompraReservationModel,
} from "../component-models/peru-compra/PeruCompraReservationModel";
import {
    ApiPeruCompraCancelledReservationModel,
    ApiPeruCompraCancelledReservationsModel,
} from "../component-models/peru-compra/ApiPeruCompraCancelledReservationModel";
import {
    PeruCompraCancelledReservationModel,
    PeruCompraCancelledReservationsModel,
} from "../component-models/peru-compra/PeruCompraCancelledReservationModel";
import { PERU_COMPRAS_ONE_WAY, PERU_COMPRAS_ROUND_TRIP } from "../shared/commonConstants";

export function mapToPeruCompraReservations(model: ApiPeruCompraReservationsModel): PeruCompraReservationsModel {
    return {
        ...model,
        Journeys: model.Journeys.map(mapToPeruCompraReservationModel),
        OriginalParams: mapToPeruCompraReservationsQueryStringParameters(model.OriginalParams),
    };
}

export function mapToPeruCompraCancelledReservations(
    model: ApiPeruCompraCancelledReservationsModel,
): PeruCompraCancelledReservationsModel {
    return {
        ...model,
        Journeys: model.Journeys.map(mapToPeruCompraCancelledReservationModel),
        OriginalParams: mapToPeruCompraReservationsQueryStringParameters(model.OriginalParams),
    };
}

function mapToPeruCompraReservationsQueryStringParameters(
    model: ApiPeruCompraReservationsQueryStringParameters,
): PeruCompraReservationsQueryStringParameters {
    return {
        ...model,
        ParsedJourneyDateFrom: dayjs(model.FormattedJourneyDateFrom, "YYYY-MM-DD HH:mm"),
        ParsedJourneyDateTo: dayjs(model.FormattedJourneyDateTo, "YYYY-MM-DD HH:mm"),
    };
}

function mapToPeruCompraReservationModel(model: ApiPeruCompraReservationModel): PeruCompraReservationModel {
    return {
        ...model,
        AdminId: model.CreatedByAdmin ? model.DNI : "",
        ArrivalDate: dayjs(model.FormattedArrivalDate, "YYYY-MM-DD HH:mm"),
        CreatedDate: dayjs(model.FormattedCreatedDate, "YYYY-MM-DD HH:mm"),
        DepartureDate: dayjs(model.FormattedDepartureDate, "YYYY-MM-DD HH:mm"),
        FirstName: model.PassengerFirstName,
        FlightNumber: model.FlightNumber,
        Id: `${model.ReservationCode}${model.Origin}${model.Destination}`,
        LastName: model.PassengerLastName,
        MemberId: !model.CreatedByAdmin ? model.DNI : "",
        NumberOfLegs: model.NumberOfLegs,
        OperationType: model.BookingType === "Booking" ? "EM" : model.BookingType === "Cancellation" ? "SA" : "RE",
        PassengerDocumentId: model.PassengerId,
        PNR: model.ReservationCode,
        ServiceType: model.ServiceType === "OneWay" ? PERU_COMPRAS_ONE_WAY : PERU_COMPRAS_ROUND_TRIP,
        TotalAmount: model.FormattedTotalAmount,
    };
}

function mapToPeruCompraCancelledReservationModel(
    model: ApiPeruCompraCancelledReservationModel,
): PeruCompraCancelledReservationModel {
    return {
        ...model,
        AdminId: model.CreatedByAdmin ? model.DNI : "",
        ArrivalDate: dayjs(model.FormattedArrivalDate, "YYYY-MM-DD HH:mm"),
        CreatedDate: dayjs(model.FormattedCreatedDate, "YYYY-MM-DD HH:mm"),
        DepartureDate: dayjs(model.FormattedDepartureDate, "YYYY-MM-DD HH:mm"),
        FirstName: model.PassengerFirstName,
        FlightNumber: model.FlightNumber,
        Id: `${model.ReservationCode}${model.Origin}${model.Destination}`,
        LastName: model.PassengerLastName,
        MemberId: !model.CreatedByAdmin ? model.DNI : "",
        NumberOfLegs: model.NumberOfLegs,
        OperationType: model.BookingType === "Booking" ? "EM" : model.BookingType === "Cancellation" ? "SA" : "RE",
        PassengerDocumentId: model.PassengerId,
        PNR: model.ReservationCode,
        RefundAmount: model.FormattedRefundAmount,
        ServiceType: model.ServiceType === "OneWay" ? PERU_COMPRAS_ONE_WAY : PERU_COMPRAS_ROUND_TRIP,
    };
}

import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "./shared/useModal";

export const useArgentinaPriceBreakdownModal = () => {
    const priceTableDesktopTemplate = () => html`
        <div class="hidden-xs">
            <table class="argentina-prices-table">
                <tr>
                    <td>${i18next.t("ArgentinaTableCol1")}</td>
                    <td>${i18next.t("ArgentinaTableCol2")}</td>
                    <td>${i18next.t("ArgentinaTableCol3")}</td>
                    <td>${i18next.t("ArgentinaTableCol4")}</td>
                    <td>${i18next.t("ArgentinaTableCol5")}</td>
                    <td>${i18next.t("ArgentinaTableCol6")}</td>
                    <td>${i18next.t("ArgentinaTableCol7")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCell1")}</td>
                    <td>${i18next.t("ArgentinaTableCell2")}</td>
                    <td>${i18next.t("ArgentinaTableCell3")}</td>
                    <td>${i18next.t("ArgentinaTableCell4")}</td>
                    <td>${i18next.t("ArgentinaTableCell5")}</td>
                    <td>${i18next.t("ArgentinaTableCell6")}</td>
                    <td>${i18next.t("ArgentinaTableCell7")}</td>
                </tr>
            </table>
        </div>
    `;

    const priceTableMobileTemplate = () => html`
        <div class="visible-xs">
            <table class="argentina-prices-table">
                <tr>
                    <td>${i18next.t("ArgentinaTableCol1")}</td>
                    <td>${i18next.t("ArgentinaTableCell1")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol2")}</td>
                    <td>${i18next.t("ArgentinaTableCell2")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol3")}</td>
                    <td>${i18next.t("ArgentinaTableCell3")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol4")}</td>
                    <td>${i18next.t("ArgentinaTableCell4")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol5")}</td>
                    <td>${i18next.t("ArgentinaTableCell5")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol6")}</td>
                    <td>${i18next.t("ArgentinaTableCell6")}</td>
                </tr>
                <tr>
                    <td>${i18next.t("ArgentinaTableCol7")}</td>
                    <td>${i18next.t("ArgentinaTableCell7")}</td>
                </tr>
            </table>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            ${priceTableDesktopTemplate()} ${priceTableMobileTemplate()}
            <div class="modal-button-container button-center">
                <a @click=${modal.close} class="rounded-primary-btn"> ${i18next.t("CloseArgentinaModal")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content argentina-modal", template: htmlTemplate },
        header: { template: () => i18next.t("V2-ArgentinianFlightNotification") },
    });

    return modal;
};

import i18next from "i18next";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { getInputId, getInputName, paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { html } from "lit-html";
import {
    BACKPACK_MOBILE_IMG,
    CHECKED_BAGGAGE_IMG,
    PAID_CABIN_MOBILE_IMG,
    WITHOUT_CHECKED_BAGGAGE_IMG,
} from "../../spa-container";
import { useBaggageEditButton } from "../common/useBaggageEditButton";
import { useBaggagePriceBreakdown } from "./useBaggagePriceBreakdown";
import { usePerBookingQuantityButtons } from "./usePerBookingQuantityButtons";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export interface Props {
    baggageSection: BaggageSection;
    context: BaggageContext;
    staffBaggage: StaffBaggage;
}

export const useMobilePerBookingOptions = (props: Props) => {
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const isViewSwitchDisabled = useMemo(
        () => props.baggageSection.state.journeys.length === 1 && paxAmount(props.baggageSection.state) === 1,
        [props.baggageSection.state],
    );

    const isNoBagSelected = useMemo(
        () =>
            props.baggageSection.state.journeys.every((j) =>
                j.passengers.every(
                    (p) =>
                        !props.context.getContextJourneyPassengerBaggage({
                            bagType: props.baggageSection.bagType,
                            journeyIndex: j.index,
                            passengerIndex: p.index,
                        }).quantity &&
                        (p.hasSelected ||
                            !props.baggageSection.perJourneyPerPax.isAddAvailable({
                                journeyIndex: j.index,
                                passengerIndex: p.index,
                            })),
                ),
            ),
        [
            props.baggageSection.state,
            props.baggageSection.bagType,
            props.baggageSection.perJourneyPerPax.isAddAvailable,
            props.context.getContextJourneyPassengerBaggage,
        ],
    );

    const isPaidBagSelected = useMemo(
        () =>
            props.baggageSection.state.journeys.every((j) =>
                j.passengers.every(
                    (p) =>
                        p.hasSelected &&
                        props.context.getContextJourneyPassengerBaggage({
                            bagType: props.baggageSection.bagType,
                            journeyIndex: j.index,
                            passengerIndex: p.index,
                        }).quantity > 0,
                ),
            ),
        [props.baggageSection.state, props.baggageSection.bagType, props.context.getContextJourneyPassengerBaggage],
    );

    const priceBreakdown = useBaggagePriceBreakdown({
        context: props.context,
        isShown: isPaidBagSelected,
        staffBaggage: props.staffBaggage,
    });

    const editButton = useBaggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            m: true,
            c: props.baggageSection.bagType,
        }),
        isDisabled: isViewSwitchDisabled,
        onClick: props.baggageSection.handlers.openPerJourneyPerPaxView,
    });

    const quantityButtons = usePerBookingQuantityButtons({
        baggageSection: props.baggageSection,
        isMobile: true,
    });

    const showOriginalPrice = useMemo(
        () =>
            flowContext.isBookingFlow &&
            props.context.isDcBooking &&
            props.baggageSection.perBooking.getLowestNextPrice({
                contextJourneys: props.context.journeys,
                minimum: true,
                original: true,
                stateJourneys: props.baggageSection.state.journeys,
            }),
        [
            flowContext.isBookingFlow,
            props.baggageSection.perBooking.getLowestNextPrice,
            props.baggageSection.state.journeys,
            props.context.isDcBooking,
            props.context.journeys,
        ],
    );

    const containsNoBundleForCabin = useMemo(
        () =>
            props.baggageSection.bagType === "CabinBaggage" &&
            props.context.journeys.every((j) => j.cabinBundleType === "None"),
        [props.baggageSection.bagType, props.context.journeys],
    );

    const unformattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        props.baggageSection.perBooking.getLowestNextPrice({
            contextJourneys: props.context.journeys,
            minimum: isMinimum,
            original: isOriginal,
            stateJourneys: props.baggageSection.state.journeys,
        });

    const formattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        formatNumber({ amount: unformattedLowestNextPrice(isOriginal, isMinimum), leadingSign: true });

    // Event handlers

    const handlePerBookingAdd = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.baggageSection.perBooking.isAddAvailable) {
            props.baggageSection.handlers.add();
        }
    };

    const handlePerBookingRemoveAll = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.baggageSection.perBooking.isRemoveAllAvailable) {
            props.baggageSection.handlers.reset();
        }
    };

    // Templates

    const freeCabinTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <img
                      class="b2-free-cabin-img"
                      src=${BACKPACK_MOBILE_IMG}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, {
                          c: props.baggageSection.bagType,
                          m: true,
                      })}
                  />
                  <span
                      class="b2m-weight"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_WEIGHT, {
                          c: props.baggageSection.bagType,
                          m: true,
                      })}
                      >10kg.</span
                  >
              `
            : "";

    const freeCheckedTemplate = () =>
        props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <img
                      class="b2-free-checked-img"
                      src=${WITHOUT_CHECKED_BAGGAGE_IMG}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, {
                          c: props.baggageSection.bagType,
                          m: true,
                      })}
                  />
              `
            : "";

    const mobileIncludedTemplate = () =>
        props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <span data-test-id=${getTestId(T.BAGGAGE.IS_INCLUDED, { c: props.baggageSection.bagType, m: true })}
                      >${i18next.t("Incluido con tu tarifa")}</span
                  >
              `
            : "";

    const freeBagName =
        props.baggageSection.bagType === "CabinBaggage"
            ? i18next.t("Mochila o artículo personal")
            : props.baggageSection.bagType === "CheckedBaggage"
              ? i18next.t("No quiero llevar equipaje facturado")
              : i18next.t("No quiero llevar equipaje sobredimensionado");

    const perBookingFreeTemplate = () => {
        const id = getInputId(props.baggageSection.bagType, undefined, undefined, true, true);
        const inputName = getInputName(props.baggageSection.bagType, undefined, undefined, true);

        const freeClassMap = classNames("b2m-per-booking-section free", {
            selected: isNoBagSelected,
        });

        const onClick = props.baggageSection.perBooking.isRemoveAllAvailable ? handlePerBookingRemoveAll : null;

        return html`
            <div class=${freeClassMap} @click=${onClick}>
                <div class="b2m-illustration">${freeCabinTemplate()} ${freeCheckedTemplate()}</div>
                <div class="b2m-per-booking-selector">
                    ${mobileIncludedTemplate()}
                    <input
                        type="radio"
                        name=${inputName}
                        id=${id}
                        .checked=${isNoBagSelected && !isPaidBagSelected}
                        .disabled=${!props.baggageSection.perBooking.isRemoveAllAvailable}
                    />
                    <label
                        for=${id}
                        data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_RESET_BUTTON, {
                            m: true,
                            c: props.baggageSection.bagType,
                        })}
                    >
                        <div class="b2m-label">
                            ${freeBagName}
                            ${props.baggageSection.bagType === "CabinBaggage"
                                ? html`
                                      <div
                                          class="b2m-dimensions smaller"
                                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                              c: props.baggageSection.bagType,
                                          })}
                                      >
                                          35cm &times; 25cm &times; 45cm
                                      </div>
                                  `
                                : ""}
                        </div>
                    </label>
                </div>
            </div>
        `;
    };

    const paidCabinIllustrationTemplate = () => html`
        <div class="b2m-illustration">
            <div
                class="b2m-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                    c: props.baggageSection.bagType,
                    m: true,
                })}
            >
                <span>&star;</span> ${i18next.t("¡El más vendido!")}
            </div>
            <img class="b2-paid-cabin-img-mb" src=${PAID_CABIN_MOBILE_IMG} />
            <div class="flex w-full items-center justify-center">
                <div
                    class="b2m-weight wider"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: props.baggageSection.bagType,
                        m: true,
                    })}
                >
                    <span>10kg.</span>
                    <span>10kg.</span>
                </div>
            </div>
        </div>
    `;

    const noBundleCabinOriginalPriceTemplate = () =>
        showOriginalPrice
            ? html`
                  <span class="b2m-crossed-out-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(true, false)}
                      </span>
                  </span>
              `
            : "";

    const noBundleCabinPriceTemplate = () =>
        containsNoBundleForCabin
            ? html`
                  ${noBundleCabinOriginalPriceTemplate()}
                  <span class=${classNames({ reduced: props.context.isDcBooking })}>
                      ${i18next.t("Desde")}
                      <span
                          data-test-value=${unformattedLowestNextPrice(false, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(false, true)}
                      </span>
                  </span>
              `
            : "";

    const perBookingCabinPaidTemplate = () => {
        const cabinId = getInputId("CabinBaggage", undefined, undefined, true, false);
        const cabinInputName = getInputName("CabinBaggage", undefined, undefined, true);

        const tempClassMap = classNames("b2m-per-booking-section padded", {
            selected: isPaidBagSelected,
        });

        return props.baggageSection.bagType === "CabinBaggage"
            ? html`
                  <div class=${tempClassMap} @click=${handlePerBookingAdd}>
                      ${paidCabinIllustrationTemplate()}
                      <div class="b2m-per-booking-selector">
                          ${noBundleCabinPriceTemplate()}
                          <input
                              type="radio"
                              id=${cabinId}
                              name=${cabinInputName}
                              .checked=${isPaidBagSelected && !isNoBagSelected}
                          />
                          <label
                              for=${cabinId}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                                  m: true,
                                  c: props.baggageSection.bagType,
                              })}
                          >
                              <div class="b2m-label">
                                  ${i18next.t("Mochila o artículo personal")}
                                  <div
                                      class="b2m-dimensions smaller"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                          c: props.baggageSection.bagType,
                                      })}
                                  >
                                      35cm &times; 25cm &times; 45cm
                                  </div>
                              </div>
                              <div class="b2m-label">
                                  ${i18next.t("Equipaje de mano")}
                                  <div
                                      class="b2m-dimensions smaller"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_DIMENSIONS, {
                                          c: props.baggageSection.bagType,
                                      })}
                                  >
                                      35cm &times; 25cm &times; 55cm
                                  </div>
                              </div>
                          </label>
                      </div>
                      ${priceBreakdown.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const paidCheckedIllustrationTemplate = () => html`
        <div class="b2m-illustration">
            <div
                class="b2m-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                    c: props.baggageSection.bagType,
                    m: true,
                })}
            >
                <span>&#9825;</span> ${i18next.t("¡Recomendado para ti!")}
            </div>
            <div>
                <img class="b2-paid-checked-img" src=${CHECKED_BAGGAGE_IMG} />
            </div>
            <div class="flex items-center">
                <div
                    class="b2m-weight"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: props.baggageSection.bagType,
                        m: true,
                    })}
                >
                    23kg.
                </div>
            </div>
        </div>
    `;

    const checkedOriginalPriceTemplate = () =>
        showOriginalPrice
            ? html`
                  <span class="b2m-crossed-out-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: props.baggageSection.bagType,
                              m: true,
                          })}
                          >${formattedLowestNextPrice(true, false)}</span
                      >
                  </span>
              `
            : "";

    const checkedNextPriceTemplate = () => html`
        <span class=${classNames({ reduced: props.context.isDcBooking })}>
            ${i18next.t("Desde")}
            <span
                data-test-value=${unformattedLowestNextPrice(false, false)}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                    c: props.baggageSection.bagType,
                    m: true,
                })}
                >${formattedLowestNextPrice(false, false)}</span
            >
        </span>
    `;

    const perBookingCheckedQuantityButtonsTemplate = () => (isPaidBagSelected ? quantityButtons.htmlTemplate() : "");

    const perBookingCheckedPaidTemplate = () => {
        const checkedId = getInputId("CheckedBaggage", undefined, undefined, true, false);
        const checkedInputName = getInputName("CheckedBaggage", undefined, undefined, true);

        const tempClassMap = classNames("b2m-per-booking-section padded", {
            selected: isPaidBagSelected,
        });

        const selectorClassMap = classNames("b2m-per-booking-selector", {
            padded: isPaidBagSelected,
        });

        return props.baggageSection.bagType === "CheckedBaggage"
            ? html`
                  <div class=${tempClassMap}>
                      ${paidCheckedIllustrationTemplate()}
                      <div class=${selectorClassMap}>
                          ${checkedOriginalPriceTemplate()} ${checkedNextPriceTemplate()}
                          <input type="radio" name=${checkedInputName} id=${checkedId} .checked=${isPaidBagSelected} />
                          <label
                              for=${checkedId}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                                  m: true,
                                  c: props.baggageSection.bagType,
                              })}
                              @click=${handlePerBookingAdd}
                          >
                              <div class="b2m-label">
                                  ${i18next.t("Equipaje facturado")}
                                  <div
                                      class="b2m-dimensions"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                          c: props.baggageSection.bagType,
                                      })}
                                  >
                                      158cm ${i18next.t("Baggage-Length")}
                                  </div>
                              </div>
                              ${perBookingCheckedQuantityButtonsTemplate()}
                          </label>
                      </div>
                  </div>
              `
            : "";
    };

    const perBookingViewSwitchTemplate = () => {
        const infoText = (() => paxAmount(props.baggageSection.state) === 1)()
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

        return html`
            <div class="b2m-view">
                <div class="b2m-info-amount">
                    <div class="b2m-view-info">${infoText}</div>
                    <div class="b2m-pax-amount">
                        ${paxAmount(props.baggageSection.state)} ${paxLabel(paxAmount(props.baggageSection.state))}
                    </div>
                </div>
                ${editButton.htmlTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () =>
        props.baggageSection.state.perJourneyPerPaxState !== "open"
            ? html`
                  ${perBookingFreeTemplate()} ${perBookingCabinPaidTemplate()} ${perBookingCheckedPaidTemplate()}
                  ${perBookingViewSwitchTemplate()}
              `
            : html``;

    return { htmlTemplate };
};

export const validatePassword = (value: string): boolean => {
    const rules = [/[A-z]/, /[0-9]/, /[\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\*|\:|\;|\<|\>|\{|\|\+}]/];

    if (!value) return true;

    const ruleValidation: number = rules.reduce((aggr, curr) => aggr + (value.search(curr) > -1 ? 1 : 0), 0);

    return isPasswordLengthValid(value) && ruleValidation >= 2;
};

export const isPasswordLengthValid = (value: string): boolean => value.length >= 8 && value.length <= 16;

import { classMap } from "lit-html/directives/class-map";
import {
    DeleteRowEvent,
    EditRowEvent,
    GlobalFilterChangeEvent,
} from "../../../component-models/CUG2b/Cug2PortalEvents";
import i18next from "i18next";
import { CugReservation } from "../../../component-models/CUG2b/CugBookings";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { Column, GridState, ViewModel } from "../../../dc-components/dc-table-models";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";

export const name = "ac-cug-pending-reservations";

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell"];

export interface Properties {
    canExport: boolean;
    data: CugReservation[];
    gridState: GridState<keyof CugReservation>;
    selectedIds: string[];
    totalItemCount: number;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        canExport: host.canExport,
        data: host.data,
        gridState: host.gridState,
        selectedIds: host.selectedIds,
        totalItemCount: host.totalItemCount,
    };

    // HELPERS

    const isDownloadSelectionDisabled = () => props.selectedIds.length === 0;

    const deleteBooking = async () => {
        setReservationToDelete(undefined);
        host.dispatchEvent(new DeleteRowEvent({ spnr: reservationToDelete }));
    };

    // COMPONENT

    const inputField = useRef<HTMLInputElement>(undefined);

    const [reservationToDelete, setReservationToDelete] = useState<string>(undefined);
    const [filterExpression, setFilterExpression] = useState<string>("");

    // EVENT LISTENERS

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            inputField.current?.blur();
            host.dispatchEvent(new GlobalFilterChangeEvent({ filterExpression }));
        }
    };

    const handleFilterChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const editRow = (index: number, toExtras = false) => {
        host.dispatchEvent(new EditRowEvent({ index, toExtras }));
    };

    const handleDownload = (selectedOnly: boolean) => {
        window.location.href =
            ROUTES.ApiRoutes.AgencyReservationsCsv +
            `?bookingStatus=Hold` +
            `&pageSize=1000` +
            `&page=0` +
            `&orderBy=FlightDate` +
            `&orderDirection=asc` +
            `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}` +
            `&IsCsvRequested=true` +
            (selectedOnly ? `&spnrs=${Array.from(props.selectedIds.values()).join("|")}` : "");
    };

    const handleSingleDelete = (index: number) => {
        setReservationToDelete(props.data[index].ReservationCode);
    };

    // TEMPLATES

    const areYouSureModalHeaderTemplate = () => html`
        <span>${i18next.t("¿Seguro que quieres eliminar la reserva?")}</span>
    `;

    const areYouSureModalContentTemplate = () => html`
        <div class="cug2b-are-you-sure-modal-info">
            ${i18next.t("Recuerda que al eliminar esta reserva, no podrá ser recuperada.")}
        </div>
        <div class="my-4 sm:my-8 flex flex-col sm:flex-row items-center justify-center">
            <button
                class="rounded-secondary-btn with-arrow mb-4 sm:mb-0 sm:mr-4"
                @click=${() => setReservationToDelete(undefined)}
            >
                ${i18next.t("Cancelar")}
            </button>
            <button class="rounded-primary-btn" @click=${deleteBooking}>
                ${i18next.t("Aceptar")}
            </button>
        </div>
    `;

    const areYouSureModalTemplate = () =>
        html`
            <ac-cug-modal
                .canBeClosed=${true}
                .content=${areYouSureModalContentTemplate()}
                .customClass=${"cug2b-are-you-sure-modal"}
                .header=${areYouSureModalHeaderTemplate()}
                .isOpen=${reservationToDelete}
                @close=${() => setReservationToDelete(undefined)}
            ></ac-cug-modal>
        `;

    const rowEditTemplate = (index: number) =>
        html`
            <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i class="js-icon-cug js-cug-bin mr-2" @click=${() => handleSingleDelete(index)}></i>
                    <div class="action-cell-text mr-2" @click=${() => editRow(index)}>
                        ${i18next.t("Pagar")} &rsaquo;
                    </div>
                </div>
            </div>
        `;

    const cellTemplate = (index: number, field: keyof CugReservation) => {
        const row = props.data[index];

        if (field) {
            return html`
                <span title=${row[field].toString()}>${row[field].toString()}</span>
            `;
        }

        return "";
    };

    const filterTemplate = () => {
        return html`
            <div class="cug2b-search-and-title">
                <div class="cug2b-page-subtitle">${i18next.t("Reservas con pago pendiente")}</div>
                <div class="cug2b-searchbox">
                    <input
                        placeholder=${i18next.t("Buscar")}
                        ref=${ref(inputField)}
                        value=${props.gridState.globalFilterExpression}
                        autocomplete="cc-exp"
                        type="text"
                        name="filter"
                        @keydown=${handleKeyDown}
                        @input=${handleFilterChange}
                    />
                    <i class="js-icon-cug js-cug-search"></i>
                </div>
            </div>
        `;
    };

    const exportTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "cug2b-btn": true,
            "mt-4": true,
            "sm:mt-0": true,
            "disabled": isDownloadSelectionDisabled(),
        });

        return props.canExport
            ? html`
                  <div class="flex flex-col sm:flex-row items-center justify-end mt-8">
                      <button @click=${() => handleDownload(false)} class="rounded-primary-btn cug2b-btn sm:mr-3">
                          ${i18next.t("CUG-Download")}
                      </button>
                      <button @click=${() => handleDownload(true)} class=${tempClassMap}>
                          ${i18next.t("CUG-DownloadSelected")}
                      </button>
                  </div>
              `
            : "";
    };

    if (!props.gridState || !props.data) {
        return html``;
    }

    const vm: ViewModel<keyof CugReservation> = {
        columns: [
            {
                field: "ReservationCode",
                columnType: "string",
                label: i18next.t("Código reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ReservationCode"),
            } as Column<keyof CugReservation>,
            {
                field: "Route",
                columnType: "string",
                label: i18next.t("Ruta"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Route"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedFlightDate",
                columnType: "string",
                label: i18next.t("Fecha de vuelo"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha de vuelo")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG2-FlightDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedFlightDate"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedExpiryDate",
                columnType: "string",
                label: i18next.t("Fecha expiración"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha expiración")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG2-ExpiryDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedExpiryDate"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedByAgent",
                columnType: "string",
                label: i18next.t("Usuario"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedByAgent"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedTotalAmount",
                columnType: "string",
                label: i18next.t("Monto"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedTotalAmount"),
            } as Column<keyof CugReservation>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(props.selectedIds.values()).map((id) =>
                props.data.map((i) => i.Id).indexOf(id),
            ),
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    return html`
        ${filterTemplate()}<dc-table-grid .vm=${vm}></dc-table-grid> ${exportTemplate()} ${areYouSureModalTemplate()}
    `;
};

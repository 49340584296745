/* eslint-disable complexity */
import { html } from "lit-html";
import { BodyToPost, getFilteredBodyToPost, mapCountryCallingCodeListToSelectOptions } from "../../shared/common";
import { normalizeCulture } from "../../shared/localeHelper";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { classMap } from "lit-html/directives/class-map";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { TestIdDictionary as T } from "../../../../V2FE/src/testing-helpers/TestIdHelper";
import { useBookingManager } from "../../managers/useBookingManager";
import { useAppContext } from "../../managers/useAppContext";
import {
    CHILEAN_CULTURE_CODE,
    ECUADORIAN_CULTURE_CODE,
    NAME_INPUT_MAX_LENGTH,
    PREFIX_CODES,
} from "../../shared/commonConstants";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { sanitizeChileanPhoneInput, sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../shared/customHooks/useAjax/useAjax";
import { ApiNewPassenger } from "../../component-models/passengers/ApiNewPassengersModel";

export interface Props {
    antiForgeryToken: string;
    firstPassenger: ApiNewPassenger;
    model: PaymentPageViewModel;
}

export interface PaymentContactForm {
    vm: PaymentContactVM;
    htmlTemplate: () => ReturnType<typeof html>;
    setIsContactFormValidated: (value: boolean) => void;
    submitForm: () => Promise<boolean>;
    validateForm: () => Promise<boolean>;
}

interface PaymentContactVM {
    additionalEmail: string;
    email: string;
    firstName: string;
    hiddenCustomerNumber: string;
    lastName: string;
    phoneNumber: string;
    phonePrefix: string;
    ruc: string;
}

export interface FirstPaxContactViewModel {
    firstName: string;
    lastName: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
}

type FieldNames = keyof PaymentContactVM;

const contactFormContainerClass = "payment-contact-form";

export const usePaymentContactForm = (props: Props): PaymentContactForm => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const [userContext] = useReduxState("userContext");

    const isPeruCompra = () => userContext?.peruCompra.isAdmin || userContext?.peruCompra.isMember;

    const presetPhone = () => {
        const prefixFromCountryList =
            appContext.PhonePrefixes.find(
                (countryWithCallingCode) => countryWithCallingCode.CountryCode === appContext.Country,
            )?.Code ?? "";

        if (isPeruCompra()) {
            const phone = splitPhoneNumber(props.firstPassenger.Address?.Phone);

            return {
                number: phone[1],
                prefix: phone[0],
            };
        }

        if (props.model.ContactViewModel.PhoneNumber?.split(" ")[1] === undefined) {
            return {
                number: "",
                prefix: prefixFromCountryList,
            };
        }

        const number = props.model.ContactViewModel.PhoneNumber?.split(" ")[1] ?? "";
        const prefixFromStoredNumber =
            props.model.ContactViewModel.PhoneNumber?.split(" ")[0]
                .replace(/[^0-9.]+/g, "")
                .trim() ?? "";

        return {
            number,
            prefix: prefixFromStoredNumber || prefixFromCountryList,
        };
    };

    const getDefaultModel = (): PaymentContactVM => ({
        additionalEmail: props.model.ContactViewModel.AdditionalEmail,
        email: isPeruCompra() ? props.firstPassenger.Address?.EmailAddress : props.model.ContactViewModel.EmailAddress,
        firstName: isPeruCompra() ? props.firstPassenger.Name.FirstName : props.model.ContactViewModel.FirstName,
        lastName: isPeruCompra() ? props.firstPassenger.Name.LastName : props.model.ContactViewModel.LastName,
        phoneNumber: presetPhone().number,
        phonePrefix: presetPhone().prefix,
        ruc: props.model.ContactViewModel.Ruc,
        hiddenCustomerNumber: props.model.ContactViewModel.CustomerNumber,
    });

    const [isClearContactChecked, setIsClearContactChecked] = useState<boolean>(false);
    const [isCopyContactChecked, setIsCopyContactChecked] = useState<boolean>(false);
    const [isAdditionalEmailSelected, setIsAdditionalEmailSelected] = useState<boolean>(undefined);

    const [isContactFormValidated, setIsContactFormValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<PaymentContactVM>(undefined);
    const [copiedContact, setCopiedContact] = useState<PaymentContactVM>(undefined);

    const phonePrefixes = useMemo(
        () => mapCountryCallingCodeListToSelectOptions(appContext.PhonePrefixes, appContext.Culture, vm?.phonePrefix),
        [appContext.PhonePrefixes, appContext.Culture, vm?.phonePrefix],
    );

    const validator = useFluentValidator<FieldNames, PaymentContactVM>({
        vm,
        validated: isContactFormValidated,
        validations: vm
            ? [
                  Validation.ruleFor("lastName", (vm: PaymentContactVM) => vm.lastName)
                      .isRequired()
                      .max(NAME_INPUT_MAX_LENGTH),
                  Validation.ruleFor("firstName", (vm: PaymentContactVM) => vm.firstName)
                      .isRequired()
                      .max(NAME_INPUT_MAX_LENGTH),
                  Validation.ruleFor("email", (vm: PaymentContactVM) => vm.email)
                      .isRequired()
                      .isEmail(),
                  Validation.ruleFor("additionalEmail", (vm: PaymentContactVM) => vm.additionalEmail)
                      .isEmail()
                      .when((_) => isAdditionalEmailSelected && vm.additionalEmail.length > 0),
                  Validation.ruleFor("phoneNumber", (vm: PaymentContactVM) => vm.phoneNumber)
                      .isRequired()
                      .when((_) => vm.phonePrefix !== "56")
                      .fulfils(
                          async (phoneNumber: string) => Promise.resolve(phoneNumber.length <= 16),
                          `${window.formResources.fieldMaximumLengthIs} 16 ${window.formResources.characters}`,
                      ),
                  Validation.ruleFor("phoneNumber", (vm: PaymentContactVM) => vm.phoneNumber)
                      .isRequired()
                      .when((_) => vm.phonePrefix === "56")
                      .fulfils(
                          async (phoneNumber: string) => Promise.resolve(phoneNumber.length === 9),
                          `${window.formResources.fieldMustHave} 9 ${window.formResources.charactersLength}`,
                      ),
                  Validation.ruleFor("ruc", (vm: PaymentContactVM) => vm.ruc)
                      .when((_) => isEcuador() && !isCopyContactChecked)
                      .isRequired(),
              ]
            : [],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const contactCopyCheckbox = useBasicCheckbox({
        customLabelClass: "fontnormal",
        inputTestId: T.PAYMENT_CONTACT_FORM.COPY_CHECKBOX,
        labelTestId: T.PAYMENT_CONTACT_FORM.COPY_CHECKBOX_LABEL,
        labelText: flowContext.isDcStandaloneFlow ? i18next.t("BEDC-CopyContact") : i18next.t("V2-FirstPassenger"),
        isChecked: isCopyContactChecked,
        onClick: () => handleCopyContactClick(),
    });

    const isEcuador = () => appContext.Culture === ECUADORIAN_CULTURE_CODE;

    const splitPhoneNumber = (personPhone: string) => {
        const [phonePrefix, phoneNumber] = (personPhone || "").split(" ");

        if (phonePrefix && phoneNumber) return [phonePrefix, phoneNumber];

        return ["", ""];
    };

    const updateCopiedContact = (vm: PaymentContactVM) => {
        if (!props.model || !props.firstPassenger || !userContext?.userRole) return;

        if (flowContext.isFlightlessPurchaseFlow) {
            let newCopy = { ...vm, email: props.model.ContactViewModel.MemberEmailAddress };

            if (props.model.ContactViewModel.MemberPhoneNumber || props.model.ContactViewModel.PhoneNumber) {
                const separatedPhoneNumber = splitPhoneNumber(
                    props.model.ContactViewModel.MemberPhoneNumber || props.model.ContactViewModel.PhoneNumber,
                );
                newCopy = {
                    ...newCopy,
                    phonePrefix: separatedPhoneNumber[0],
                    phoneNumber: separatedPhoneNumber[1],
                };
            }
            setCopiedContact(newCopy);
            return;
        }

        const firstPassengerEmail = props.firstPassenger.Address?.EmailAddress || "";
        const [firstPassengerPhonePrefix, firstPassengerPhoneNumber] = splitPhoneNumber(
            props.firstPassenger.Address?.Phone,
        );
        const [memberPhonePrefix, memberPhoneNumber] = splitPhoneNumber(props.model.ContactViewModel.MemberPhoneNumber);

        if (userContext.cug.isMember || !userContext.isLoggedIn) {
            const newCopy = {
                firstName: props.firstPassenger?.Name.FirstName || "",
                lastName: props.firstPassenger?.Name.LastName || "",
                email: firstPassengerEmail,
                phonePrefix: firstPassengerPhonePrefix || presetPhone().prefix,
                phoneNumber: firstPassengerPhoneNumber,
                ruc: "",
                additionalEmail: props.model.ContactViewModel.AdditionalEmail || "",
                hiddenCustomerNumber: props.model.ContactViewModel.CustomerNumber || "",
            };

            setCopiedContact(newCopy);
            return;
        }

        const newCopy = {
            firstName: props.firstPassenger?.Name.FirstName || props.model.ContactViewModel.MemberFirstName || "",
            lastName: props.firstPassenger?.Name.LastName || props.model.ContactViewModel.MemberLastName || "",
            email: firstPassengerEmail || props.model.ContactViewModel.MemberEmailAddress || "",
            phonePrefix: firstPassengerPhonePrefix || memberPhonePrefix || presetPhone().prefix,
            phoneNumber: firstPassengerPhoneNumber || memberPhoneNumber,
            ruc: "",
            additionalEmail: props.model.ContactViewModel.AdditionalEmail || "",
            hiddenCustomerNumber:
                props.model.ContactViewModel.CustomerNumber || props.model.ContactViewModel.MemberCustomerNumber || "",
        };

        setCopiedContact(newCopy);
    };

    const copy = () =>
        setVm({
            additionalEmail: vm.additionalEmail,
            email: copiedContact.email || vm.email,
            firstName: copiedContact.firstName || vm.firstName,
            hiddenCustomerNumber: copiedContact.hiddenCustomerNumber || vm.hiddenCustomerNumber,
            lastName: copiedContact.lastName || vm.lastName,
            phoneNumber: copiedContact.phoneNumber || vm.phoneNumber,
            phonePrefix: copiedContact.phonePrefix || vm.phonePrefix,
            ruc: "",
        });

    const clearCopy = () => {
        if (flowContext.isFlightlessPurchaseFlow) {
            const clearedContact: PaymentContactVM = {
                additionalEmail: vm.additionalEmail,
                email: "",
                firstName: "",
                hiddenCustomerNumber: "",
                lastName: "",
                phoneNumber: "",
                phonePrefix: "",
                ruc: props.model.ContactViewModel.Ruc || "",
            };
            setVm(clearedContact);
            return;
        }

        const clearedContact: PaymentContactVM = {
            additionalEmail: vm.additionalEmail,
            email: copiedContact.email ? "" : vm.email,
            firstName: copiedContact.firstName ? "" : vm.firstName,
            hiddenCustomerNumber: copiedContact.hiddenCustomerNumber
                ? props.model.ContactViewModel.CustomerNumber
                : vm.hiddenCustomerNumber,
            lastName: copiedContact.lastName ? "" : vm.lastName,
            phoneNumber: copiedContact.phoneNumber ? "" : vm.phoneNumber,
            phonePrefix: copiedContact.phonePrefix ? "" : vm.phonePrefix,
            ruc: props.model.ContactViewModel.Ruc || "",
        };

        setVm(clearedContact);
    };

    const getBodyToPost = () => {
        const unfilteredBodyToPost: BodyToPost = {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken,
            "jetSmartContact.CultureCode": normalizeCulture(appContext.Culture),
            "jetSmartContact.CustomerNumber": vm.hiddenCustomerNumber,
            "jetSmartContact.DistributionOption": "Email",
            "jetSmartContact.HomePhone": `${vm.phonePrefix} ${vm.phoneNumber}`,
            "jetSmartContact.TypeCode": "P",
            "jetSmartContact.Name.First": vm.firstName,
            "jetSmartContact.Name.Last": vm.lastName,
            "jetSmartContact.AddressLine2": vm.ruc,
            "jetSmartContact.EmailAddress": vm.email,
            "jetSmartContact.AdditionalEmail": vm.additionalEmail,
        };

        return getFilteredBodyToPost(unfilteredBodyToPost);
    };

    const submitForm = async (): Promise<boolean> => {
        const body = getBodyToPost();
        const container = DomCrawlingHelper.getElemByClass(document.body, contactFormContainerClass);

        return Boolean(bookingManager.postPaymentContact(body, container.parentElement));
    };

    const handleCopyContactClick = () => {
        setIsCopyContactChecked(!isCopyContactChecked);
        if (!isCopyContactChecked) {
            copy();
            setIsContactFormValidated(false);
        } else {
            clearCopy();
            setIsContactFormValidated(false);
        }
    };

    const handleClearContactClick = () => {
        setIsClearContactChecked(!isClearContactChecked);
        if (!isClearContactChecked) {
            clearCopy();
            setIsContactFormValidated(false);
        } else {
            copy();
            setIsContactFormValidated(false);
        }
    };

    const handleFirstNameInput = (value: string) => {
        setVm({ ...vm, firstName: value, hiddenCustomerNumber: "" });
        setIsCopyContactChecked(false);
    };

    const handleLastNameInput = (value: string) => {
        setVm({ ...vm, lastName: value, hiddenCustomerNumber: "" });
        setIsCopyContactChecked(false);
    };

    useEffect(() => updateCopiedContact(vm), [props.firstPassenger, props.model, userContext?.userRole, vm]);

    useEffect(() => {
        if (!props.model || !props.firstPassenger || vm || !userContext?.userRole) return;

        setVm(getDefaultModel());
        setIsAdditionalEmailSelected(Boolean(props.model.ContactViewModel.AdditionalEmail));
    }, [props.model, userContext?.userRole, props.firstPassenger]);

    // Templates

    const copyTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking) &&
        !flowContext.isGiftcardPurchaseFlow &&
        (!props.model.BookingViewModel.IsFlightlessPnr || flowContext.isDcStandaloneFlow)
            ? contactCopyCheckbox.htmlTemplate()
            : "";

    const otherContactTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking) &&
        flowContext.isGiftcardPurchaseFlow &&
        userContext.bancoEstado.category !== 0
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="mdl-checkbox-wrapper payment-contact">
                              <label class="mdl-checkbox mdl-js-checkbox ">
                                  <input
                                      class="mdl-checkbox__input"
                                      type="checkbox"
                                      data-test-id="payment-clear-contact"
                                      tabindex="-1"
                                      @click=${handleClearContactClick}
                                  />
                                  <span class="mdl-checkbox__label">
                                      <span class="cb-title">${i18next.t("Gift-NonBancoEstadoData")}</span>
                                  </span>
                              </label>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <header>
            <span class="js-circle-user js-icon title-icon"></span>
            <div class="title">
                <h2 class="main-title">
                    ${userContext.peruCompra.isAdmin ||
                    userContext.peruCompra.isMember ||
                    bookingContext.isPeruCompraBooking
                        ? i18next.t("Responsable de la emisión")
                        : flowContext.isDcStandaloneFlow
                          ? i18next.t("V2-ContactInfoStandaloneDc")
                          : i18next.t("V2-ContactInfo")}
                </h2>
            </div>
        </header>
    `;

    const firstNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("firstName")}
                .isInvalid=${!validator.isValid("firstName")}
                .name=${"jetSmartContact.Name.First"}
                .autoComplete=${"off"}
                .label=${`${i18next.t("V2-FirstNameLabel")} *`}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .testId=${T.PAYMENT_CONTACT_FORM.FIRST_NAME}
                .value=${vm?.firstName ?? ""}
                .onInput=${handleFirstNameInput}
            ></ac-input>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("lastName")}
                .isInvalid=${!validator.isValid("lastName")}
                .label=${`${i18next.t("V2-LastNameLabel")} *`}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                .name=${"jetSmartContact.Name.Last"}
                .autoComplete=${"off"}
                .testId=${T.PAYMENT_CONTACT_FORM.LAST_NAME}
                .value=${vm?.lastName ?? ""}
                .onInput=${handleLastNameInput}
            ></ac-input>
        </div>
    `;

    const emailTemplate = () => html`
        <div class="show-email-tooltip mt-[20px]">
            <ac-input
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .autoComplete=${"off"}
                .name=${"jetSmartContact.EmailAddress"}
                .label=${`${i18next.t("V2-EmailLabel")} *`}
                .testId=${T.PAYMENT_CONTACT_FORM.EMAIL}
                .value=${vm?.email ?? ""}
                .onInput=${(value: string) => setVm({ ...vm, email: value })}
            ></ac-input>
            ${giftcardEmailTooltipTemplate()}
        </div>
    `;

    const giftcardEmailTooltipTemplate = () =>
        flowContext.isGiftcardPurchaseFlow
            ? html` <div class="giftcard-email-tooltip">${i18next.t("Gift-EmailTooltip")}</div> `
            : "";

    const phonePrefixTemplate = () => html`
        <div class="col-xs-1-2 col-sm-1-4">
            <div class="mt-[20px]">
                <ac-select
                    .errorMessage=${validator.getMessage("phonePrefix")}
                    .isInvalid=${!validator.isValid("phonePrefix")}
                    .label=${`${i18next.t("V2-PhoneCountryLabel")} *`}
                    .options=${phonePrefixes}
                    .testId=${T.PAYMENT_CONTACT_FORM.HOMEPHONE_PREFIX}
                    .onSelect=${(value: string) => setVm({ ...vm, phonePrefix: value, phoneNumber: "" })}
                >
                </ac-select>
            </div>
        </div>
    `;

    const phoneNumberTemplate = () => html`
        <div class="col-xs-1-2 col-sm-1-4">
            <div class="mt-[20px]">
                <ac-input
                    .errorMessage=${validator.getMessage("phoneNumber")}
                    .isInvalid=${!validator.isValid("phoneNumber")}
                    .autoComplete=${"off"}
                    .name=${"jetSmartContact.EmailAddress"}
                    .label=${`${i18next.t("V2-PhoneLabel")} *`}
                    .testId=${T.PAYMENT_CONTACT_FORM.HOMEPHONE_NUMBER}
                    .sanitizer=${(e: KeyboardEvent) =>
                        vm?.phonePrefix === PREFIX_CODES.get(CHILEAN_CULTURE_CODE)
                            ? sanitizeChileanPhoneInput(e)
                            : sanitizeInputFieldValue(e, "numeric")}
                    .value=${vm?.phoneNumber ?? ""}
                    .onInput=${(value: string) => setVm({ ...vm, phoneNumber: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const additionalEmailTemplate = () =>
        isAdditionalEmailSelected
            ? html`
                  <div class="additional-email-field">
                      <div class="mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("additionalEmail")}
                              .isInvalid=${!validator.isValid("additionalEmail")}
                              .autoComplete=${"off"}
                              .name=${"jetSmartContact.AdditionalEmail"}
                              .label=${`${i18next.t("V2-EmailLabel")} (2)`}
                              .testId=${"payment-contact-email2"}
                              .value=${vm?.additionalEmail ?? ""}
                              .onInput=${(value: string) => setVm({ ...vm, additionalEmail: value })}
                          ></ac-input>
                      </div>
                      <div
                          class="additional-email-remover"
                          @click=${() => {
                              setVm({ ...vm, additionalEmail: "" });
                              setIsAdditionalEmailSelected(false);
                          }}
                      >
                          &times; ${i18next.t("PaymentContact-AdditionalEmailRemove")}
                      </div>
                  </div>
              `
            : "";

    const additionalEmailContainerTemplate = () => {
        const tempClassMap = classMap({
            "additional-email-label": true,
            "open": isAdditionalEmailSelected,
        });

        return !flowContext.isGiftcardPurchaseFlow
            ? html`
                  <div class="">
                      <div class="row">
                          <div class="col-xs-1 col-sm-1 additional-email">
                              <div class=${tempClassMap} @click=${() => setIsAdditionalEmailSelected(true)}>
                                  ${i18next.t("PaymentContact-AdditionalEmail")}
                              </div>
                              ${additionalEmailTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const rucTemplate = () =>
        isEcuador() && !isCopyContactChecked
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .errorMessage=${validator.getMessage("ruc")}
                                  .isInvalid=${!validator.isValid("ruc")}
                                  .autoComplete=${"off"}
                                  .name=${"jetSmartContact.AddressLine2"}
                                  .label=${`${i18next.t("RUC")} *`}
                                  .testId=${T.PAYMENT_CONTACT_FORM.RUC}
                                  .value=${vm?.ruc ?? ""}
                                  .onInput=${(value: string) => setVm({ ...vm, ruc: value })}
                              ></ac-input>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="ts-error-container ${contactFormContainerClass}">
            ${headerTemplate()}
            <div class="inner-deep-box payment-chb ts-error-parent">
                ${copyTemplate()} ${otherContactTemplate()}
                <div class="row">
                    <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                    <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                </div>
                ${rucTemplate()}
                <div class="row">
                    <div class="col-xs-1 col-sm-1-2">${emailTemplate()} ${additionalEmailContainerTemplate()}</div>
                    ${phonePrefixTemplate()} ${phoneNumberTemplate()}
                </div>
                ${formErrors.htmlTemplate()}
            </div>
        </div>
    `;
    return {
        vm,
        setIsContactFormValidated,
        htmlTemplate,
        submitForm,
        validateForm: validator.validate,
    };
};

import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "lit-html";
import { CardIssuerType } from "../../component-models/PaymentDescriptors";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { getLocalizedStringValue } from "../../shared/common";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { usePaymentTealiumManager } from "../../managers/Tealium/usePaymentTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";

export interface Props {
    isCardBancoEstado: boolean;
    isValidated: boolean;
    model: PaymentPageViewModel;
    setCardNumber: (value: string) => void;
}

export const useInputCardNumber = (props: Props) => {
    const appContext = useAppContext();
    const tealiumManager = usePaymentTealiumManager();

    const [userContext] = useReduxState("userContext");
    const [paymentMethod] = useReduxState("payment.paymentMethod");
    const [payerData] = useReduxState("payment.payer");
    const [cardData] = useReduxState("payment.cardData");

    const { validateCreditCard, isCardNumberLengthValid, isCardInvalidForCountryAndCurrency } = paymentHelper();

    // HELPERS

    const isCardErrorMessageNeeded = () => {
        const noCardNumberYet = !props.isValidated && !cardData?.CardNumber;

        if (noCardNumberYet || cardData?.CardValidationStatus === "unknown") {
            return false;
        }

        const cardLengthIsValid = isCardNumberLengthValid(
            paymentMethod?.PaymentMethodCode,
            cardData?.CardType,
            cardData?.CardNumber,
        );

        const alreadySubmittedAndHasCardNumber = props.isValidated && cardData?.CardNumber;

        if (
            (cardLengthIsValid || alreadySubmittedAndHasCardNumber) &&
            !paymentMethod?.AllowUnrecognizedCardTypes &&
            cardData?.CardValidationStatus === "invalid"
        ) {
            return true;
        }

        return isCardInvalidForCountryAndCurrency(
            cardData?.CardType,
            paymentMethod?.AllowedCards,
            payerData.CurrentCardIssuerCountry,
        );
    };

    const isRequiredBancoEstadoCardError = () =>
        props.isValidated &&
        isCardNumberLengthValid(paymentMethod?.PaymentMethodCode, cardData?.CardType, cardData?.CardNumber) &&
        [1, 2, 3, 5, 6, 7].includes(userContext.bancoEstado.category) &&
        !props.isCardBancoEstado;

    // EVENT HANDLERS

    const handleCardNumberInput = async (value: string) => props.setCardNumber(value);

    const handleCardNumberInputBlur = (value: string) => {
        const response = validateCreditCard(
            props.model,
            paymentMethod,
            value,
            appContext.Culture,
            appContext.BancoEstadoBins,
        );

        tealiumManager.logCardNumberInputBlur(
            response,
            paymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode
                ? cardData?.CardType
                : paymentMethod?.PaymentMethodCode,
            getLocalizedStringValue(
                props.model.MethodsViewModel.PaymentSettings?.CardDescriptors.find(
                    (card) => card.Type === cardData?.CardType,
                )?.DisplayedName,
                appContext.Culture,
            ),
        );
    };

    // TEMPLATES

    const cardErrorTemplate = () =>
        isCardErrorMessageNeeded() && !isRequiredBancoEstadoCardError()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-CardNumberWarning")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const bancoEstadoErrorTemplate = () =>
        isRequiredBancoEstadoCardError()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("BE-NonBancoEstadoCardErrorMessage")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const cardImages = new Map<CardIssuerType, string>([
        ["AmEx", "/Images/Icons/payment-types/payment-amex-small.png"],
        ["Argencard", "/Images/Icons/payment-types/payment-argencard.png"],
        ["Cabal", "/Images/Icons/payment-types/payment-cabal-small.png"],
        ["Cencosud", "/Images/Icons/payment-types/payment-cencosud.png"],
        ["Cmr", "/Images/Icons/payment-types/payment-cmr.png"],
        ["Cordobesa", "/Images/Icons/payment-types/payment-cordobesa.png"],
        ["Diners", "/Images/Icons/payment-types/payment-diners-small.png"],
        ["Discover", "/Images/Icons/payment-types/payment-discover-small.png"],
        ["Elo", "/Images/Icons/payment-types/payment-elo.png"],
        ["Hipercard", "/Images/Icons/payment-types/payment-hipercard.png"],
        ["Maestro", "/Images/Icons/payment-types/payment-maestro-small.png"],
        ["Magna", "/Images/Icons/payment-types/payment-magna.png"],
        ["Mastercard", "/Images/Icons/payment-types/payment-mastercard-small.png"],
        ["Naranja", "/Images/Icons/payment-types/payment-naranja.png"],
        ["Nativa", "/Images/Icons/payment-types/payment-nativa.png"],
        ["Shopping", "/Images/Icons/payment-types/payment-shopping.png"],
        ["Visa", "/Images/Icons/payment-types/payment-visa-small.png"],
        ["Walmart", "/Images/Icons/payment-types/payment-walmart.png"],
    ]);

    const cardTypeTemplate = () =>
        cardData?.CardType && cardImages.has(cardData?.CardType)
            ? html`
                  <img
                      class="card-result-image"
                      src=${cardImages.get(cardData?.CardType)}
                      data-test-id=${T.PAYMENT.CARD_TYPE}
                      data-test-value=${cardData?.CardType}
                  />
              `
            : html`
                  <span
                      class="text-info card-result"
                      data-test-id=${T.PAYMENT.CARD_TYPE}
                      data-test-value=${cardData?.CardValidationStatus === "valid" ? "OK" : "&times;"}
                  >
                      ${unsafeHTML(cardData?.CardValidationStatus === "valid" ? "OK" : "&times;")}
                  </span>
              `;

    const cardNumberInputTemplate = () => {
        const inputClassMap = classNames("mdl-textfield__input js-input", {
            invalid: isCardErrorMessageNeeded() || (props.isValidated && !cardData?.CardNumber),
        });

        return html`
            <div class="col-xs-1 col-md-1-2">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <label class="mdl-textfield__label">${i18next.t("Número de tarjeta")} *</label>
                    <input
                        class=${inputClassMap}
                        autocomplete="off"
                        data-test-id=${getTestId(T.PAYMENT.CARD_NUMBER, { c: paymentMethod?.PaymentMethodCode })}
                        .value=${cardData?.CardNumber || ""}
                        @blur=${(e: KeyboardEvent) => handleCardNumberInputBlur((e.target as HTMLInputElement).value)}
                        @input=${(e: KeyboardEvent) => handleCardNumberInput((e.target as HTMLInputElement).value)}
                    />

                    ${cardTypeTemplate()}
                </div>
                ${cardErrorTemplate()} ${bancoEstadoErrorTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () => html` <div class="row">${cardNumberInputTemplate()}</div> `;

    return { htmlTemplate };
};

import { useRef } from "haunted";
import { useEffect, useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { showLoader } from "../../../../shared/common";
import { CLASS_NAMES } from "../../../../shared/classNames";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ref } from "../../../../directives/ref";
import { CheckinOptionClickedEvent } from "../../../../component-models/extras/checkin-type/CheckinTypeEvents";
import { PassengerSelection } from "../../../../component-models/extras/checkin-type/PassengerSelection";
import { CheckinTypeModel } from "../../../../component-models/extras/checkin-type/CheckinTypeModel";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import BookingData from "../../../../shared/BookingData";
import { CheckinPassengerJourneyModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerJourneyModel";
import { CheckinPassengerModel } from "../../../../component-models/extras/checkin-type/CheckinPassengerModel";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useAppContext } from "../../../../managers/useAppContext";

export interface Props {
    isValidated: boolean;
    model: CheckinTypeModel;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export type CheckinTypeSelection = "none" | "free" | "paid";

export const useCheckinType = (props: Props) => {
    const validateCheckin = () => !passengerSelections.some((ps) => ps.checkinTypeSelection === "none");

    const getCheckinTypeSelection = (journey: CheckinPassengerJourneyModel, pax: CheckinPassengerModel) => {
        if (isPeruCompra() && appContext.isFeatureSwitchActive("PeruCompraFreeAirportCheckin")) {
            return "free";
        }

        const selection = passengerSelections?.find(
            (ps) => ps.journeyIndex === journey.JourneyIndex && ps.paxNumber === pax.PassengerNumber,
        );

        return selection?.checkinTypeSelection === "paid" || journey.PaidOption?.IsSold
            ? "paid"
            : selection?.checkinTypeSelection === "free" || journey.FreeOption?.IsSold
              ? "free"
              : "none";
    };

    const updatePassengerSelection = () =>
        props.model?.Passengers.reduce(
            (allSelections: PassengerSelection[], passenger) =>
                allSelections.concat(
                    passenger.Journeys.reduce(
                        (paxSelections: PassengerSelection[], journey) =>
                            paxSelections.concat([
                                {
                                    paxNumber: passenger.PassengerNumber,
                                    journeyIndex: journey.JourneyIndex,
                                    ssrAddKey: journey.PaidOption?.SsrAddKey,
                                    ssrRemoveKey: journey.PaidOption?.SsrDeleteKey,
                                    checkinTypeSelection: getCheckinTypeSelection(journey, passenger),
                                },
                            ]),
                        [],
                    ),
                ),
            [],
        );

    const handleSelection = async (e: CheckinOptionClickedEvent) => {
        const newState = [
            ...passengerSelections.filter(
                (ps) => ps.journeyIndex !== e.detail.journeyIndex || ps.paxNumber !== e.detail.paxNumber,
            ),
            { ...e.detail },
        ];

        setPassengerSelections(newState);

        tealiumManager.logBinaryExtraSelected(
            "airportCheckin",
            props.model?.Passengers[e.detail.paxNumber].Type === "ADT" ? "A" : "C",
            e.detail.paxNumber,
            e.detail.journeyIndex,
            e.detail.checkinTypeSelection === "paid" ? "add" : "remove",
            e.detail.price,
        );
    };

    const handleCheckinCopy = async () => {
        if (isCheckinCopySelected) {
            setIsCheckinCopySelected(false);
            return;
        }

        setIsCheckinCopySelected(true);

        const firstPaxCurrentCheckins = passengerSelections.filter((ps) => ps.paxNumber === 0);
        const ssrKeys: string[] = [];

        passengerSelections
            .filter((ps) => ps.paxNumber !== 0)
            .forEach((ps) => {
                const firstPaxSelection = firstPaxCurrentCheckins.find(
                    (fpcc) => fpcc.journeyIndex === ps.journeyIndex,
                ).checkinTypeSelection;

                switch (firstPaxSelection) {
                    case "none":
                    case "free":
                        if (ps.checkinTypeSelection === "paid") {
                            ssrKeys.push(ps.ssrRemoveKey);
                        }
                        break;
                    case "paid":
                        if (ps.checkinTypeSelection !== "paid") {
                            ssrKeys.push(ps.ssrAddKey);
                        }
                        break;
                }
            });

        const newState: PassengerSelection[] = [
            ...passengerSelections.map((i) => ({
                ...i,
                checkinTypeSelection: firstPaxCurrentCheckins.find((fpcc) => fpcc.journeyIndex === i.journeyIndex)
                    ?.checkinTypeSelection,
            })),
        ];

        setPassengerSelections(newState);

        if (ssrKeys.length > 0) {
            const loader = showLoader({
                name: LOADER_CLASS_NAMES.ExtrasCheckin,
                container: root.current,
                noPlane: true,
            });

            const body = ssrKeys.reduce(
                (aggr, curr, i) => {
                    aggr[`selectedJourneySsrs[${i}]`] = curr;
                    return aggr;
                },
                {} as { [key: string]: string },
            );

            const container = DomCrawlingHelper.getElemByClass(root.current, CLASS_NAMES.errorContainer);

            const response = await bookingManager.postAirportCheckinSsrUpdate<{
                BookingSummary: BookingData;
                ExtrasModel: ApiExtrasSpaSectionViewModel;
            }>(body, container, loader);

            props.setExtrasModel(response.ExtrasModel);
        }
    };

    const root = useRef<HTMLDivElement>(undefined);

    const bookingManager = useBookingManager();
    const tealiumManager = useExtrasTealiumManager();

    const appContext = useAppContext();
    const [userContext] = useReduxState("userContext");

    const [passengerSelections, setPassengerSelections] = useState<PassengerSelection[]>(undefined);
    const [isCheckinCopySelected, setIsCheckinCopySelected] = useState<boolean>(false);

    const isPeruCompra = () => userContext?.peruCompra.isAdmin || userContext?.peruCompra.isMember;

    const showTemplate = useMemo(
        () =>
            !props.model?.Passengers.every((p) => p.Journeys.every((j) => j.IsInBundle)) &&
            !(isPeruCompra() && appContext.isFeatureSwitchActive("PeruCompraFreeAirportCheckin")),
        [props.model, userContext],
    );

    useEffect(
        () => setPassengerSelections(updatePassengerSelection()),
        [JSON.stringify(props.model?.Passengers.map((p) => p.Journeys))],
    );

    const passengersTemplate = () =>
        props.model?.Passengers.map(
            (passenger) => html`
                <ac-checkin-type-passenger
                    .handleCheckinCopy=${handleCheckinCopy}
                    .isCheckinCopySelected=${isCheckinCopySelected}
                    .setIsCheckinCopySelected=${setIsCheckinCopySelected}
                    .isValidated=${props.isValidated}
                    .moreThanOnePassenger=${props.model?.Passengers.length > 1}
                    .parentRoot=${root.current}
                    .passenger=${passenger}
                    .passengerSelections=${passengerSelections}
                    .setExtrasModel=${props.setExtrasModel}
                    @select=${handleSelection}
                >
                </ac-checkin-type-passenger>
            `,
        );

    const headerTemplate = () => html`
        <header>
            <i class="js-boarding-pass js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_CHECKIN.TITLE}>${i18next.t("V2-BoardingPass")}</h2>
                <div class="subtitle" data-test-id=${T.EXTRAS_CHECKIN.SUBTITLE}>
                    ${i18next.t("V2-BoardingPassInfo")}
                </div>
            </div>
        </header>
    `;

    const htmlTemplate = () =>
        showTemplate
            ? html`
                  <section
                      ref=${ref(root)}
                      class="booking-wrapper extras-step ts-error-container"
                      data-test-id=${T.EXTRAS_CHECKIN.CONTAINER}
                  >
                      ${headerTemplate()} ${passengersTemplate()}
                  </section>
              `
            : "";

    return { htmlTemplate, validateCheckin };
};

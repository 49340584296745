import { CultureSettings } from "../component-models/CultureSettings";
import { CultureListItem } from "../components/layout/culture-selector";
import { insensitiveEquals } from "./stringHelper";

const mainCultureSubCultureCodes = (settings: CultureSettings, currentCultureCode: string): string[] =>
    settings.Cultures.find((culture) => insensitiveEquals(culture.Main, currentCultureCode))?.SubCultures || [];

const nonMainCultureSubCultureCodes = (settings: CultureSettings, currentCultureCode: string): string[] => {
    const mainCultureSubCultures = mainCultureSubCultureCodes(settings, currentCultureCode);

    return settings.AllCultures.filter(
        (cultureCode) =>
            !mainCultureSubCultures.some((subCultureCode) => insensitiveEquals(subCultureCode, cultureCode)),
    );
};

const convertCodesToCultureListItems = (cultureList: CultureListItem[], cultureCodes: string[]): CultureListItem[] =>
    cultureCodes
        .map((cultureCode) => getCultureListItemByCode(cultureList, cultureCode))
        .filter((listItem) => listItem);

const getSelectableMainCultureSubCultures = (
    settings: CultureSettings,
    cultureList: CultureListItem[],
    currentCulture: string,
): CultureListItem[] =>
    convertCodesToCultureListItems(cultureList, mainCultureSubCultureCodes(settings, currentCulture));

const getSelectableNonMainCultureSubCultures = (
    settings: CultureSettings,
    cultureList: CultureListItem[],
    currentCulture: string,
): CultureListItem[] =>
    convertCodesToCultureListItems(cultureList, nonMainCultureSubCultureCodes(settings, currentCulture)).sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
    );

export const getCultureListItemByCode = (cultureList: CultureListItem[], cultureCode: string) =>
    cultureList.find((listItem) => insensitiveEquals(listItem.culture, cultureCode));

export const isCultureEnabled = (settings: CultureSettings, cultureCode: string) =>
    !settings.Cultures.some((culture) => culture.IsDisabled && insensitiveEquals(culture.Main, cultureCode));

export const getSelectableCulturesInOrder = (
    settings: CultureSettings,
    cultureList: CultureListItem[],
    currentCultureCode: string,
): CultureListItem[] => {
    const mainCultureSubCultures = getSelectableMainCultureSubCultures(settings, cultureList, currentCultureCode);
    const remainingSubCultures = getSelectableNonMainCultureSubCultures(settings, cultureList, currentCultureCode);

    return [...mainCultureSubCultures, ...remainingSubCultures];
};

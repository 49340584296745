import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { BaggageContext } from "../../../../component-models/baggage/BaggageContext";
import i18next from "i18next";
import { html } from "lit-html";
import { INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageState } from "../../../../component-models/baggage/BaggageState";
import { paxAmount, displayedBundleJourneyIndex } from "../../../../component-helpers/BaggageHelper";
import { BagType } from "../useBaggagePage";
import classNames from "classnames";

export interface Props {
    baggageState: BaggageState;
    bagType: BagType;
    context: BaggageContext;
}

export const usePerBookingBundle = (props: Props) => {
    const oneWayBundleDirection = () => displayedBundleJourneyIndex(props.context.journeys, props.bagType);

    const oneWayBundleText = () =>
        oneWayBundleDirection() === OUTBOUND ? i18next.t("para el vuelo de ida") : i18next.t("para el vuelo de vuelta");

    const bundleInfoText = () =>
        paxAmount(props.baggageState) === 1
            ? props.bagType === "CheckedBaggage"
                ? i18next.t("Recuerda que tu llevas equipaje facturado porque compraste")
                : i18next.t("Recuerda que tu llevas equipaje de mano porque compraste")
            : props.bagType === "CheckedBaggage"
              ? i18next.t("Recuerda que todos los pasajeros llevan equipaje facturado porque compraron el")
              : i18next.t("Recuerda que todos los pasajeros llevan equipaje de mano porque compraron el");

    const differentBundlesInfoText1 = () =>
        paxAmount(props.baggageState) === 1
            ? i18next.t("Recuerda que agregaste un")
            : i18next.t("Recuerda que todos los pasajeros agregaron un");

    const differentBundlesInfoText2 = () =>
        props.bagType === "CheckedBaggage"
            ? i18next.t("que incluye equipaje facturado")
            : i18next.t("que incluye equipaje de mano");

    const showOutboundBundleImage = () =>
        (props.context.journeys[OUTBOUND].bundleImageUrl && props.baggageState.journeys.length === 1) ||
        !props.context.journeys[INBOUND]?.bundleImageUrl ||
        props.context.journeys[OUTBOUND].bundleImageUrl === props.context.journeys[INBOUND].bundleImageUrl;

    const showInboundBundleImage = () =>
        !props.context.journeys[OUTBOUND].bundleImageUrl && props.context.journeys[INBOUND].bundleImageUrl;

    const showFullBundle = () =>
        props.context.journeys.every(
            (journey) =>
                (props.bagType === "CabinBaggage" && journey.cabinBundleType === "Full") ||
                (props.bagType === "CheckedBaggage" && journey.checkedBundleType === "Full"),
        );
    const showSimpleBundle = () =>
        props.context.journeys.every(
            (journey) =>
                (props.bagType === "CabinBaggage" && journey.cabinBundleType === "Smart") ||
                (props.bagType === "CheckedBaggage" && journey.checkedBundleType === "Smart"),
        );
    const showGenericBundle = () =>
        props.context.journeys.some(
            (journey1) =>
                journey1.bundleUserType === "GDS" ||
                props.context.journeys.some(
                    (journey2) =>
                        (props.bagType === "CabinBaggage" && journey2.cabinBundleType !== journey1.cabinBundleType) ||
                        (props.bagType === "CheckedBaggage" &&
                            journey2.checkedBundleType !== journey1.checkedBundleType),
                ),
        );

    const getDataTestValue = () =>
        !showBundle()
            ? "None"
            : showFullBundle()
              ? "Full"
              : showSimpleBundle()
                ? "Simple"
                : showGenericBundle()
                  ? "Mixed"
                  : "n/a";

    const imgUrl = () =>
        showOutboundBundleImage()
            ? props.context.journeys[OUTBOUND].bundleImageUrl
            : showInboundBundleImage()
              ? props.context.journeys[INBOUND].bundleImageUrl
              : "";

    const imgSrc = () =>
        (showFullBundle() || showSimpleBundle()) && imgUrl()
            ? imgUrl()
            : showGenericBundle()
              ? "/Images/Bundles/bundle-baggage.svg"
              : undefined;

    const showBundle = () =>
        props.context.journeys.some(
            (journey) =>
                (props.bagType === "CabinBaggage" && journey.cabinBundleType !== "None") ||
                (props.bagType === "CheckedBaggage" && journey.checkedBundleType !== "None"),
        );

    const bundleContentTemplate = () =>
        showGenericBundle()
            ? html`
                  <span>${differentBundlesInfoText1()}</span>
                  <img src=${imgSrc()} />
                  <span>${differentBundlesInfoText2()}</span>
              `
            : html`
                  <span>${bundleInfoText()}</span>
                  ${specificBundleImageTemplate()} ${specificBundleOneWayTemplate()}
              `;

    const specificBundleOneWayTemplate = () =>
        oneWayBundleDirection() !== undefined ? html` <span>${oneWayBundleText()}</span> ` : "";

    const specificBundleImageTemplate = () => (imgSrc() ? html` <img src=${imgSrc()} /> ` : "");

    const mainClassMap = classNames("b2-baggage-bundle-info", {
        "push-down": !props.context.isDcBooking,
    });

    const htmlTemplate = () =>
        showBundle()
            ? html`
                  <div
                      class=${mainClassMap}
                      data-test-value=${getDataTestValue()}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_BUNDLE_MESSAGE, { c: props.bagType })}
                  >
                      <i class="js-icon js-broken-circle-exclamation"></i>
                      <div>${bundleContentTemplate()}</div>
                  </div>
              `
            : html``;

    return { htmlTemplate };
};

interface PriorityBoardingOptionClickedEventDetail {
    selected: boolean;
    journeyIndex: number;
    paxIndex: number;
}

export class PriorityBoardingOptionClickedEvent extends CustomEvent<PriorityBoardingOptionClickedEventDetail> {
    constructor(detail: PriorityBoardingOptionClickedEventDetail) {
        super("select", { detail, bubbles: true });
    }
}

import { useEffect, useState } from "haunted";
import { WompiInitResponse } from "../../component-models/payment/WompiInitResponse";
import { ROUTES } from "../../shared/apiRoutes";
import { WOMPI_PAYMENT_METHOD_CODES } from "../../shared/commonConstants";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export const useWompiInitializer = () => {
    const { ajaxJsonRequest } = useAjax();

    const [selectedMethod] = useReduxState("payment.paymentMethod");

    const [wompiTermsUrl, setWompiTermsUrl] = useState<string>(null);

    const handleWompiSelect = async () => {
        const res = await ajaxJsonRequest<WompiInitResponse>({
            method: "POST",
            url: ROUTES.ApiRoutes.InitWompiPayment,
        });
        setWompiTermsUrl(res.data.TermsUrl);
    };

    useEffect(() => {
        if (!selectedMethod) return;

        if (WOMPI_PAYMENT_METHOD_CODES.includes(selectedMethod.PaymentMethodCode)) {
            handleWompiSelect();
        } else {
            setWompiTermsUrl(null);
        }
    }, [selectedMethod?.PaymentMethodCode]);

    return { wompiTermsUrl };
};

import {
    ARGENTINA_COUNTRY_CODE,
    ARGENTINIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    BRASIL_COUNTRY_CODE,
    CHILE_COUNTRY_CODE,
    COLOMBIAN_CULTURE_CODE,
    COLOMBIA_COUNTRY_CODE,
    ECUADORIAN_CULTURE_CODE,
    ECUADOR_COUNTRY_CODE,
    INBOUND,
    OUTBOUND,
    PARAGUAYAN_CULTURE_CODE,
    PARAGUAY_COUNTRY_CODE,
    PERUVIAN_CULTURE_CODE,
    PERU_COUNTRY_CODE,
    URUGUAYAN_CULTURE_CODE,
    URUGUAY_COUNTRY_CODE,
} from "../../shared/commonConstants";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { City, Country, RoutePickerModel } from "../../component-models/RoutePickerModel";
import { StationSettings } from "../../component-models/StationSettings";
import i18next from "i18next";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { html } from "haunted";
import { clone } from "../../shared/common";
import { useAppContext } from "../../managers/useAppContext";
import { useRouteSelectorField } from "./useRouteSelectorField";
import { stationUtils } from "./stationUtils";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    destination: string;
    origin: string;
    root: HTMLDivElement;
    sameCitiesOnly: boolean;
    stationSettings: StationSettings;
    onSelect: (origin: string, destination: string) => void;
}

export const useRouteSelector = (props: Props) => {
    const appContext = useAppContext();

    const [countries] = useReduxState("countries");

    const {
        getCountryByCityCode,
        getDisplayedCityName,
        getFilteredModel,
        getPreselectedCity,
        getRouteModel,
        isOnlyOneFilteredCityLeft,
    } = stationUtils();

    const [filterExpression, setFilterExpression] = useState<string>("");

    const [originModel, setOriginModel] = useState<RoutePickerModel>(undefined);
    const [isOriginActive, setIsOriginActive] = useState<boolean>(false);
    const [activeOriginCountry, setActiveOriginCountry] = useState<Country>(undefined);
    const [selectedOrigin, setSelectedOrigin] = useState<City>(undefined);

    const [destinationModel, setDestinationModel] = useState<RoutePickerModel>(undefined);
    const [isDestinationActive, setIsDestinationActive] = useState<boolean>(false);
    const [activeDestinationCountry, setActiveDestinationCountry] = useState<Country>(undefined);
    const [selectedDestination, setSelectedDestination] = useState<City>(undefined);

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const originSelector = useRouteSelectorField({
        activeCountry: activeOriginCountry,
        dataTestId: T.SEARCH.ORIGIN,
        inputTestId: T.SEARCH.ORIGIN_INPUT,
        isActive: isOriginActive,
        isDisabled: !originModel || props.sameCitiesOnly,
        isTyping: Boolean(filterExpression),
        journeyIndex: OUTBOUND,
        model: getFilteredModel(originModel, filterExpression),
        optionsDataTestId: T.SEARCH.ORIGIN_OPTIONS,
        placeHolder: i18next.t("V2-FromLabel"),
        root: props.root,
        selectedCityCode: selectedOrigin?.code,
        value: selectedOrigin ? getDisplayedCityName(selectedOrigin) : "",
        onCityClick: (city: City) => applyOriginSelection(city),
        onClose: () => setIsOriginActive(false),
        onCountryClick: (country: Country) => handleCountryClick("origin", country),
        onKeydown: (value: string) => handleOriginKeydown(value),
        onKeyup: (e: KeyboardEvent, value: string) => handleOriginKeyup(e, value),
        onOpen: () => handleOriginInputFieldClick(),
    });

    const destinationSelector = useRouteSelectorField({
        activeCountry: activeDestinationCountry,
        dataTestId: T.SEARCH.DESTINATION,
        inputTestId: T.SEARCH.DESTINATION_INPUT,
        isActive: isDestinationActive,
        isDisabled: !destinationModel || !selectedOrigin || props.sameCitiesOnly,
        isTyping: Boolean(filterExpression),
        journeyIndex: INBOUND,
        model: getFilteredModel(destinationModel, filterExpression),
        optionsDataTestId: T.SEARCH.DESTINATION_OPTIONS,
        placeHolder: i18next.t("V2-ToLabel"),
        root: props.root,
        selectedCityCode: selectedDestination?.code,
        value: selectedDestination ? getDisplayedCityName(selectedDestination) : "",
        onCityClick: (city: City) => applyDestinationSelection(city),
        onClose: () => setIsDestinationActive(false),
        onCountryClick: (country: Country) => handleCountryClick("destination", country),
        onKeydown: (value: string) => handleDestinationKeydown(value),
        onKeyup: (e: KeyboardEvent, value: string) => handleDestinationKeyup(e, value),
        onOpen: () => handleDestinationInputFieldClick(),
    });

    const init = () => {
        const newOriginModel = getRouteModel(countries);
        const newDestinationModel = props.origin ? getRouteModel(countries, props.origin) : clone(newOriginModel);

        setOriginModel(newOriginModel);
        setDestinationModel(newDestinationModel);
        preselectValues(newOriginModel, newDestinationModel);
        initActiveCountries(newOriginModel, newDestinationModel);
        window.setTimeout(() => {
            if (!props.origin || !props.destination) return;
            const newOriginCountry = getCountryByCityCode(countries, props.origin);
            const newDestinationCountry = getCountryByCityCode(countries, props.destination);
            setActiveOriginCountry(newOriginCountry);
            setActiveDestinationCountry(newDestinationCountry);
        }, 0);
        setIsInitialized(true);
    };

    const handleOriginKeydown = (value: string) => {
        setSelectedOrigin(undefined);
        setFilterExpression(value);
    };

    const handleOriginKeyup = (e: KeyboardEvent, value: string) => {
        if (e.key === "Enter") {
            const newModel = getFilteredModel(originModel, value);

            if (value.length > 2 && isOnlyOneFilteredCityLeft(newModel)) {
                setActiveOriginCountry(newModel.countries[0]);
                applyOriginSelection(newModel.countries[0].regions[0].cities[0]);
            }
            return;
        }

        if (e.key === "Escape") setIsOriginActive(false);

        setSelectedDestination(undefined);
        setIsDestinationActive(false);

        setFilterExpression(value);
    };

    const handleDestinationInputFieldClick = () => {
        if (isDestinationActive) return;

        setIsOriginActive(false);
        setFilterExpression("");
        setIsDestinationActive(true);
    };

    const handleDestinationKeydown = (value: string) => {
        setSelectedDestination(undefined);
        setFilterExpression(value);
    };

    const handleDestinationKeyup = (e: KeyboardEvent, value: string) => {
        if (e.key === "Enter") {
            const newModel = getFilteredModel(destinationModel, value);

            if (value.length > 2 && isOnlyOneFilteredCityLeft(newModel)) {
                setActiveDestinationCountry(newModel.countries[0]);
                applyDestinationSelection(newModel.countries[0].regions[0].cities[0]);
            }
            return;
        }

        if (e.key === "Escape") setIsDestinationActive(false);

        setFilterExpression(value);
    };

    const preselectValues = (origin: RoutePickerModel, destination: RoutePickerModel) => {
        if (props.origin) setSelectedOrigin(getPreselectedCity(origin, props.origin));

        if (props.destination) setSelectedDestination(getPreselectedCity(destination, props.destination));
    };

    const initActiveCountries = (origin: RoutePickerModel, destination: RoutePickerModel) => {
        let originCountryCode: string;
        let destinationCountryCode: string;

        switch (appContext.Culture) {
            case ARGENTINIAN_CULTURE_CODE:
                originCountryCode = ARGENTINA_COUNTRY_CODE;
                destinationCountryCode = ARGENTINA_COUNTRY_CODE;
                break;
            case PERUVIAN_CULTURE_CODE:
                originCountryCode = PERU_COUNTRY_CODE;
                destinationCountryCode = PERU_COUNTRY_CODE;
                break;
            case COLOMBIAN_CULTURE_CODE:
                originCountryCode = COLOMBIA_COUNTRY_CODE;
                break;
            case BRASILIAN_CULTURE_CODE:
                originCountryCode = BRASIL_COUNTRY_CODE;
                break;
            case PARAGUAYAN_CULTURE_CODE:
                originCountryCode = PARAGUAY_COUNTRY_CODE;
                break;
            case URUGUAYAN_CULTURE_CODE:
                originCountryCode = URUGUAY_COUNTRY_CODE;
                break;
            case ECUADORIAN_CULTURE_CODE:
                originCountryCode = ECUADOR_COUNTRY_CODE;
                break;
            default:
                originCountryCode = CHILE_COUNTRY_CODE;
                destinationCountryCode = CHILE_COUNTRY_CODE;
                break;
        }

        setActiveOriginCountry(origin.countries.find((c) => c.code === originCountryCode));
        setActiveDestinationCountry(destination.countries.find((c) => c.code === destinationCountryCode));
    };

    const handleOriginInputFieldClick = () => {
        if (isOriginActive) return;

        setIsOriginActive(true);
        setFilterExpression("");
        setIsDestinationActive(false);
    };

    const handleCountryClick = (endpoint: "origin" | "destination", country: Country) => {
        setFilterExpression("");

        if (endpoint === "origin") {
            setActiveOriginCountry(country);
        } else {
            setActiveDestinationCountry(country);
        }
    };

    const applyOriginSelection = (city: City) => {
        const newDestinationModel = getRouteModel(countries, city.code);

        setDestinationModel(newDestinationModel);
        setSelectedOrigin(city);
        setSelectedDestination(undefined);
        setFilterExpression("");
        setIsOriginActive(false);

        // DEVNOTE Only open selector after model is set
        window.setTimeout(() => setIsDestinationActive(true), 0);
    };

    const applyDestinationSelection = (city: City) => {
        setSelectedDestination(city);
        setIsDestinationActive(false);
        setFilterExpression("");
    };

    useEffect(() => {
        if (countries?.length && !isInitialized) init();
    }, [countries?.length, isInitialized]);

    useEffect(() => {
        if (isInitialized) {
            props.onSelect(selectedOrigin?.code, selectedDestination?.code);
        }
    }, [selectedOrigin, selectedDestination]);

    useEffect(() => {
        const availableDestinationCountries = getFilteredModel(destinationModel, filterExpression);

        if (!availableDestinationCountries?.countries?.some((c) => c.code === activeDestinationCountry?.code)) {
            setActiveDestinationCountry(availableDestinationCountries?.countries[0]);
        }
    }, [isDestinationActive, activeDestinationCountry]);

    const htmlTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">${originSelector.htmlTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${destinationSelector.htmlTemplate()}</div>
        </div>
    `;

    return {
        selectedOriginCountry: activeOriginCountry?.code,
        selectedDestinationCountry: activeDestinationCountry?.code,
        htmlTemplate,
    };
};

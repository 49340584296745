import { OrganizationVM } from "./cug-register-company";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { commonDebug } from "./../../bootstrap";
import { ApiCreateUpdateAgencyModel } from "./../../component-models/CUG2b/ApiCreateUpdateAgencyModel";
import { html, useRef } from "haunted";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import i18next from "i18next";
import {
    getRequestBodyFromObject,
    getTaxnumberCodeByCountry,
    handleCugLoader,
    maskCurrenciesForDisplay,
} from "./../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { ChangeEvent } from "../../dc-components/dc-checkbox";
import { ref } from "../../directives/ref";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { CugRegisterFailureReason } from "../../component-models/CUG2b/CugRegisterFailureReason";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { ApiCreateAgencyResponse } from "../../component-models/CUG2b/ApiCreateAgencyResponse";
import { ROUTES } from "../../shared/apiRoutes";
import { normalizeCulture } from "../../shared/localeHelper";
import { DEFAULT_DATE_FORMAT } from "../../shared/commonConstants";
import { ApiCreateUpdateOrganizationModel } from "../../component-models/CUG2b/ApiCreateUpdateOrganizationModel";
import { ApiCreateUpdateAgentModel } from "../../component-models/CUG2b/ApiCreateUpdateAgentModel";
import { AdminVM } from "./cug-register-admin";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);

export const name = "ac-cug-register-summary";

const captchaContainerClass = "ac-captcha-container";

export interface Properties {
    adminVM: AdminVM;
    gRecapcthaSiteKey: string;
    organizationVM: OrganizationVM;
    privacyLink: string;
    termsLink: string;
    goForward: () => void;
    goToError: () => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        adminVM: host.adminVM,
        gRecapcthaSiteKey: host.gRecapcthaSiteKey,
        organizationVM: host.organizationVM,
        privacyLink: host.privacyLink,
        termsLink: host.termsLink,
        goForward: host.goForward,
        goToError: host.goToError,
    };

    // HELPERS

    const init = () => {
        if (!cug2AppContext.isFeatureSwitchActive("Captcha")) {
            return;
        }

        const grecaptchaInterval = window.setInterval(() => {
            const captchaContainer = document.body.querySelector(`.${captchaContainerClass} div`) as HTMLDivElement;

            if (window.grecaptcha && captchaContainer) {
                setCaptchaId(
                    window.grecaptcha.render(captchaContainer, {
                        sitekey: props.gRecapcthaSiteKey,
                    }),
                );

                window.clearInterval(grecaptchaInterval);
                window.clearTimeout(grecaptchaTimeLimit);
            }
        }, 100);

        const grecaptchaTimeLimit = window.setTimeout(() => {
            window.clearInterval(grecaptchaInterval);
            setShowCaptchaLoadError(true);
        }, 3000);
    };

    const sendFormsToEmarsys = async () => {
        try {
            const country = props.adminVM.countryCode;
            const email = props.adminVM.email;
            const firstName = props.adminVM.firstName;
            const fullPhoneNumber = `${props.adminVM.phonePrefix} ${props.adminVM.phoneNumber}`;
            const lastName = props.adminVM.lastName;
            const rut = props.adminVM.documentNumber;

            const addressLine = props.organizationVM.address;
            const city = props.organizationVM.city;
            const currencyCode = props.organizationVM.currencyCode;
            const giro = props.organizationVM.giro;
            const isAgency = props.organizationVM.type === "AGENCY";
            const organizationCountry = props.organizationVM.countryCode;
            const organizationEmail = props.organizationVM.email;
            const organizationName = props.organizationVM.name;
            const organizationRut = props.organizationVM.taxNumber;
            const region = props.organizationVM.region;

            const emarsysUrl = isAgency
                ? `https://linkscl.jetsmart.com/u/register.php?CID=814442181&f=1108&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y` +
                  `&inp_1=${firstName}&inp_2=${lastName}&inp_3=${email}&inp_14=${country}&inp_13751=${rut}&inp_37=${fullPhoneNumber}` +
                  `&inp_16106[]=1&inp_18=${organizationName}&inp_16168=${organizationRut}&inp_16167=${organizationEmail}&inp_16108=${giro}` +
                  `&inp_45=${organizationCountry}&inp_43=${city}&inp_13715=${region}&inp_41=${addressLine}&inp_16109[]=${currencyCode}` +
                  `&inp_16170[]=0`
                : `https://linkscl.jetsmart.com/u/register.php?CID=814442181&f=1096&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y` +
                  `&inp_1=${firstName}&inp_2=${lastName}&inp_3=${email}&inp_14=${country}&inp_13751=${rut}&inp_37=${fullPhoneNumber}` +
                  `&inp_15801[]=1&inp_18=${organizationName}&inp_16168=${organizationRut}&inp_16167=${organizationEmail}&inp_16108=${giro}` +
                  `&inp_45=${organizationCountry}&inp_43=${city}&inp_13715=${region}&inp_41=${addressLine}&inp_16109[]=${currencyCode}` +
                  `&inp_16170[]=0`;

            await ajaxRequest({
                method: "GET",
                url: emarsysUrl,
                noCors: true,
                onResponseCode: {
                    0: () => commonDebug.error("Register Emarsys submit failed"),
                },
            });
        } catch {
            commonDebug.error("Cug-modal-summary Emarsys submit failed");
        }
    };

    const validateTermsAcceptance = () => {
        if (!termsAccepted) {
            tealiumManager.logValidationError(["cug-register terms not accepted"]);
        }

        setShowAcceptanceError(!termsAccepted);

        return termsAccepted;
    };

    const transformErrorReason = (reason: CugRegisterFailureReason) => {
        switch (reason) {
            case "RntExpired":
                return i18next.t("El RNT ingresado ha expirado.");
            case "RntExpirationTooClose":
                return i18next.t("El RNT ingresado expira en menos de 60 días.");
            case "MissingFile":
                return i18next.t("Debe adjuntar el RNT.");
            case "FileTooLarge":
                return i18next.t("El archivo adjunto es demasiado grande.");
            case "InvalidFileType":
                return i18next.t("El archivo adjunto no es válido.");
            case "MissingRntExpirationDate":
                return i18next.t("Debe ingresar la fecha de expiración del RNT.");
            case "AgencyCreateException":
                return i18next.t("ErrorOccured");
        }
    };

    // EVENT HANDLERS

    const handleCheckboxChange = (e: ChangeEvent) => {
        setShowAcceptanceError(false);
        setTermsAccepted(e.detail.checked);
    };

    const handleSubmitAll = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setErrorMessage(undefined);

        let isValid = validateTermsAcceptance();

        if (
            cug2AppContext.isFeatureSwitchActive("Captcha") &&
            captchaId !== undefined &&
            window.grecaptcha.getResponse(captchaId).length === 0
        ) {
            setShowCaptchaError(true);
            isValid = false;
        } else {
            setShowCaptchaError(false);
        }

        if (isValid) {
            handleCugLoader(root.current, "loadData");

            await sendFormsToEmarsys();

            const organizationVm: ApiCreateUpdateOrganizationModel = {
                Address: {
                    AddressLine: props.organizationVM.address,
                    City: props.organizationVM.city,
                    CountryCode: props.organizationVM.countryCode,
                    PostalCode: null,
                    ProvinceState: null,
                    Region: props.organizationVM.region,
                },
                CultureCode: normalizeCulture(cug2AppContext.Culture),
                CurrencyCode: props.organizationVM.currencyCode,
                Email: props.organizationVM.email,
                Giro: props.organizationVM.giro,
                Name: props.organizationVM.name,
                OrganizationCode: null,
                RntExpirationDate: props.organizationVM.rntExpirationDate?.format(DEFAULT_DATE_FORMAT) || null,
                TaxNumber: props.organizationVM.taxNumber,
                Type: props.organizationVM.type as "AGENCY" | "COMPANY",
            };

            const adminVM: ApiCreateUpdateAgentModel = {
                CultureCode: normalizeCulture(cug2AppContext.Culture),
                Name: {
                    First: props.adminVM.firstName,
                    Last: props.adminVM.lastName,
                    Middle: "",
                    Suffix: "",
                    Title: "",
                },
                Nationality: cug2AppContext.Country,
                Person: {
                    CustomerNumber: "",
                    HomePhoneNumber: {
                        Default: true,
                        Number: `${props.adminVM.phonePrefix} ${props.adminVM.phoneNumber}`,
                        PhoneCode: "",
                        TypeCode: "",
                    },
                    MobilePhoneNumber: {
                        Default: true,
                        Number: "",
                        PhoneCode: "",
                        TypeCode: "",
                    },
                    Name: { Name: { First: "", Last: "" } },
                    NationalIdNumber: props.adminVM.documentNumber,
                    NewPasswordConfirmation: props.adminVM.confirmPassword,
                    Password: props.adminVM.password,
                    PersonalEmailAddress: { Default: true, EmailAddress: props.adminVM.email },
                    PhoneNumbers: [],
                    Role: "",
                    WorkEmailAddress: { Default: true, EmailAddress: "" },
                },
                UserName: props.adminVM.userName,
            };

            const body: ApiCreateUpdateAgencyModel & "g-recaptcha-response" = getRequestBodyFromObject({
                "File": props.organizationVM.file,
                "Organization": organizationVm,
                "Agent": adminVM,
                "g-recaptcha-response": cug2AppContext.isFeatureSwitchActive("Captcha")
                    ? (DomCrawlingHelper.getElemByClass(document.body, "g-recaptcha-response") as HTMLTextAreaElement)
                          ?.value
                    : null,
            });

            //DEVNOTE: when backend has unified exception handling for ajax requests (JET-9124), unify it on the frontend too
            const result = await ajaxJsonRequest<ApiCreateAgencyResponse>({
                url: ROUTES.ApiRoutes.Cug2BCreateAgency,
                body,
                file: {
                    name: "file",
                    content: props.organizationVM.file,
                },
                onResponseCode: {
                    400: () => null,
                    404: () => null,
                    500: () => null,
                },
            });

            handleCugLoader(root.current, "loadData");

            if (result.statusCode === 200) {
                tealiumManager.logRegister(
                    result.data.IbeRelease,
                    result.data.OrganizationCode,
                    result.data.RoleCodes.join(","),
                    "",
                    "",
                    "",
                );
                props.goForward();
            } else {
                setErrorMessage(
                    transformErrorReason(
                        (result as unknown as { data: { Errors: { ErrorCode: CugRegisterFailureReason }[] } }).data
                            .Errors[0].ErrorCode,
                    ),
                );
            }
        }
    };

    // COMPONENT

    const root = useRef<HTMLDivElement>(undefined);

    const tealiumManager = useAuthenticationTealiumManager();
    const cug2AppContext = useCug2AppContext();

    const { ajaxRequest, ajaxJsonRequest } = useAjax();

    const [captchaId, setCaptchaId] = useState<any>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);
    const [showAcceptanceError, setShowAcceptanceError] = useState<boolean>(undefined);
    const [showCaptchaError, setShowCaptchaError] = useState<boolean>(undefined);
    const [showCaptchaLoadError, setShowCaptchaLoadError] = useState<boolean>(undefined);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(undefined);

    useEffect(init, []);

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-register-header" data-test-id=${T.CUG2_REGISTER.SUMMARY_HEADER}>
            <i class="js-icon-cug js-cug-user"></i>
            <div>
                <h1>${i18next.t("Resumen")}</h1>
                <h2>
                    ${i18next.t(
                        "Revisa toda la información ingresada de la organización, al igual que los datos del usuario.",
                    )}
                </h2>
            </div>
        </div>
    `;

    const companyDataTemplate = () => {
        const countryName = cug2AppContext.Countries.find((c) => c.Value === props.organizationVM.countryCode)?.Text;
        const taxLabel = getTaxnumberCodeByCountry(props.organizationVM.countryCode);

        return html`
            <div class="cug2b-register-summary-section" data-test-id=${T.CUG2_REGISTER.SUMMARY_CUG_SECTION}>
                <div class="cug2b-register-summary-section-title">
                    <i class="js-icon-cug js-cug-man-and-plane"></i>
                    ${i18next.t("Datos de la organización")}
                </div>
                <ul>
                    <li>
                        <span>${i18next.t("Razón Social")}</span>
                        <span>${props.organizationVM.name}</span>
                    </li>
                    <li>
                        <span>${i18next.t("País de origen")}</span>
                        <span>${countryName}</span>
                    </li>
                    <li>
                        <span>${taxLabel}</span>
                        <span>${props.organizationVM.taxNumber}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Giro")}</span>
                        <span>${props.organizationVM.giro}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Correo")}</span>
                        <span class="break-all">${props.organizationVM.email}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Ciudad")}</span>
                        <span>${props.organizationVM.city}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Comuna")}</span>
                        <span>${props.organizationVM.region}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Dirección")}</span>
                        <span>${props.organizationVM.address}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Moneda")}</span>
                        <span>${maskCurrenciesForDisplay(props.organizationVM.currencyCode)}</span>
                    </li>
                </ul>
            </div>
        `;
    };

    const adminDataTemplate = () => {
        const countryName = cug2AppContext.Countries.find((c) => c.Value === props.adminVM.countryCode)?.Text;
        const taxLabel = getTaxnumberCodeByCountry(props.adminVM.countryCode, true);

        return html`
            <div class="cug2b-register-summary-section" data-test-id=${T.CUG2_REGISTER.SUMMARY_ADMIN_SECTION}>
                <div class="cug2b-register-summary-section-title">
                    <i class="js-icon-cug js-cug-user"></i>
                    ${i18next.t("Datos del usuario administrador")}
                </div>
                <ul>
                    <li>
                        <span>${i18next.t("Nombres")}</span>
                        <span>${props.adminVM.firstName}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Apellidos")}</span>
                        <span>${props.adminVM.lastName}</span>
                    </li>
                    <li>
                        <span>${i18next.t("País")}</span>
                        <span>${countryName}</span>
                    </li>
                    <li>
                        <span>${taxLabel}</span>
                        <span>${props.adminVM.documentNumber}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Teléfono")}</span>
                        <span>${`${props.adminVM.phonePrefix} ${props.adminVM.phoneNumber}`}</span>
                    </li>
                    <li>
                        <span>${i18next.t("Correo electrónico")}</span>
                        <span class="break-all">${props.adminVM.email}</span>
                    </li>
                    <li>
                        <span>${i18next.t("User name")}</span>
                        <span>${props.adminVM.userName}</span>
                    </li>
                </ul>
            </div>
        `;
    };

    const captchaErrorTemplate = () =>
        showCaptchaError
            ? html`
                  <div class="error-message-container elevated-error text-left">
                      <div class="form-error-message">
                          ${i18next.t("Debes validar la confirmación captcha para continuar")}
                      </div>
                  </div>
              `
            : "";

    const captchaLoadErrorTemplate = () =>
        showCaptchaLoadError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="captcha-load-error">
                              ${i18next.t(
                                  "Google ReCaptcha no se pudo cargar. Por favor, inténtelo de nuevo más tarde.",
                              )}
                          </div>
                      </div>
                  </div>
              `
            : "";

    const captchaTemplate = () =>
        cug2AppContext.isFeatureSwitchActive("Captcha")
            ? html`
                  <div>
                      <div>
                          <div class="form-captcha-container ${captchaContainerClass}">
                              <div></div>
                          </div>
                      </div>
                      ${captchaErrorTemplate()} ${captchaLoadErrorTemplate()}
                  </div>
              `
            : "";

    const acceptanceErrorTemplate = () =>
        showAcceptanceError
            ? html`
                  <div class="error-message-container elevated-error text-left">
                      <div class="form-error-message">${i18next.t("Acepte primero los términos y condiciones.")}</div>
                  </div>
              `
            : "";

    const termsTemplate = () => html`
        <div>
            <div class="cug2b-register-terms-container" data-test-id=${T.CUG2_REGISTER.SUMMARY_ACCEPT_TERMS_CONTAINER}>
                <dc-checkbox
                    .dataTestId=${T.CUG2_REGISTER.SUMMARY_ACCEPT_TERMS_CHECKBOX}
                    @change=${handleCheckboxChange}
                ></dc-checkbox>
                <span>
                    ${unsafeHTML(
                        i18next.t(
                            "Acepto los {{- link1start}}Términos y Condiciones{{- link1end}} y {{- link2start}}Políticas de Privacidad{{- link2end}}.",
                            {
                                link1start: `<a target='_blank' href='${props.termsLink}'>`,
                                link1end: "</a>",
                                link2start: `<a target='_blank' href='${props.privacyLink}'>`,
                                link2end: "</a>",
                            },
                        ),
                    )}
                </span>
            </div>
            ${acceptanceErrorTemplate()}
        </div>
    `;

    const buttonsTemplate = () => html`
        <div class="cug2b-register-btn-container">
            <div></div>
            <button class="rounded-primary-btn" @click=${handleSubmitAll}>${i18next.t("Continuar")}</button>
        </div>
    `;

    const warningTemplate = () => html`
        <div class="cug2b-invoice-warning" data-test-id=${T.CUG2_REGISTER.SUMMARY_INVOICE_WARNING}>
            <i class="js-icon js-dc-exclamation"></i>
            ${i18next.t(
                "¡Recuerda! Los datos ingresados anteriormente serán utilizados para la emisión de factura. Es muy importante que estén ingresados correctamente dado que no se reemitirán facturas por datos erróneos.",
            )}
        </div>
    `;

    const errorTemplate = () =>
        errorMessage
            ? html` <div class="row">
                  <div class="col-xs-1">
                      <div class="error-message-container">
                          <div class="form-error-message ts-rut-error-message">${errorMessage}</div>
                      </div>
                  </div>
              </div>`
            : "";

    return html`
        <div ref=${ref(root)}>
            ${headerTemplate()}
            <div class="cug2b-register-summary" data-test-id=${T.CUG2_REGISTER.SUMMARY_CONTAINER}>
                ${companyDataTemplate()} ${adminDataTemplate()}
            </div>
            <div class="cug2b-register-form" data-test-id=${T.CUG2_REGISTER.FORM}>
                ${warningTemplate()}
                <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                    ${captchaTemplate()} ${termsTemplate()}
                </div>
                ${errorTemplate()} ${buttonsTemplate()}
            </div>
        </div>
    `;
};

import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { TogglePaxInsuranceOpenEvent } from "../../../../component-models/extras/insurance/InsuranceEvents";
import { classMap } from "lit-html/directives/class-map";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { maskCurrenciesForDisplay } from "../../../../shared/common";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export const name = "ac-insurance-passenger-insurance-selector";

export interface Props {
    insurancePerPaxPrice: number;
    insurancePerPaxCurrency: string;
    showInfantWarning: boolean;
    isSelected: boolean;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        insurancePerPaxPrice: host.insurancePerPaxPrice,
        insurancePerPaxCurrency: host.insurancePerPaxCurrency,
        showInfantWarning: host.showInfantWarning,
        isSelected: host.isSelected,
    };

    // HELPERS

    // EVENT HANDLERS

    const handleInsuranceButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        host.dispatchEvent(new TogglePaxInsuranceOpenEvent());
    };

    // COMPONENT

    const { formatNumber } = useNumberFormatter();

    // TEMPLATES

    const headerTemplate = () => html`
        <header class="insurance-title">
            <i class="js-airplane-on-shield js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_TRAVEL_INSURANCE.TITLE}>
                    ${i18next.t("Seguro de Viaje")}
                </h2>
                <div class="subtitle" data-test-id=${T.EXTRAS_TRAVEL_INSURANCE.SUBTITLE}>
                    ${i18next.t(
                        "¡Disfruta en tu destino sin preocupaciones! Contrata tu seguro de viajes CHUBB con obertura COVID-19 incluida.",
                    )}
                </div>
            </div>
        </header>
    `;

    const descriptionTemplate = () => html`
        <div class="insurance-description">
            <i class="js-icon-covid js-cv-covid-shield-tick-2"></i>
            <ul class="insurance-description-list">
                <li>${i18next.t("Hasta $5.000 USD en gastos médicos")}</li>
                <li>${i18next.t("Asistencia en español las 24 horas, los 365 días del año.")}</li>
                <li>${i18next.t("Cobertura en gastos médicos por accidente o enfermedad.")}</li>
                <li>${i18next.t("Cancelación de viaje")}</li>
            </ul>
        </div>
    `;

    const infantWarningTemplate = () =>
        props.showInfantWarning
            ? html`
                  <div class="text-sm text-be-blue">
                      *
                      ${i18next.t(
                          "El seguro agregado del infante lo podrá visualizar en el detalle a pagar del primer pasajero.",
                      )}
                  </div>
              `
            : "";

    const openerTemplate = () => {
        const openerText = i18next.t(
            "Contrata tu seguro de viajes CHUBB con cobertura COVID-19 incluida {{-boldstart}}desde {{insurancePerPaxPrice}} por pasajero{{-boldend}}",
            {
                boldstart: `<span class="font-bold">`,
                boldend: "</span>",
                insurancePerPaxPrice: `${formatNumber({
                    amount: props.insurancePerPaxPrice,
                    currency: props.insurancePerPaxCurrency,
                    leadingSign: true,
                }).replace(" ", "")} ${maskCurrenciesForDisplay(props.insurancePerPaxCurrency)}`,
            },
        );

        return html`
            <div class="insurance-data-opener">
                <div class="data-opener-title" data-test-id=${T.EXTRAS.INSURANCE_DATA_OPENER}>
                    ${unsafeHTML(openerText)} ${infantWarningTemplate()}
                </div>
                ${buttonTemplate()}
            </div>
        `;
    };

    const buttonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "with-x-logo": props.isSelected,
            "blue-white": props.isSelected,
            "white-blue": !props.isSelected,
        });

        const caption = props.isSelected ? i18next.t("Quitar") : i18next.t("Agregar");

        return html`
            <div class="data-opener-button-container">
                <button
                    class=${tempClassMap}
                    @click=${handleInsuranceButtonClick}
                    data-test-id=${T.EXTRAS_TRAVEL_INSURANCE.BUTTON}
                >
                    ${caption}
                </button>
            </div>
        `;
    };

    return html`
        <div class="insurance-content-container">${headerTemplate()} ${descriptionTemplate()} ${openerTemplate()}</div>
    `;
};

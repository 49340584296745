import { commonDebug } from "../bootstrap";
import { useAjax } from "../shared/customHooks/useAjax/useAjax";
import { useState } from "../shared/haunted/CustomHooks";
import { Validator } from "./Validation";

export interface AvailabilityValidatorProps<T1, T2> {
    bodyKey?: keyof T1;
    container?: HTMLElement;
    method: "POST" | "GET";
    responseKey: keyof T2;
    url: string;
}

export const useAvailabilityValidator = <T1, T2>(props: AvailabilityValidatorProps<T1, T2>): Validator => {
    const validate = async (value: string) => {
        if (!value) {
            return Promise.resolve(true);
        }

        if (validatedItems.has(value)) return Promise.resolve(validatedItems.get(value));

        const result = await ajaxJsonRequest<T2>({
            body: props.method === "POST" && props.bodyKey ? { [props.bodyKey]: value } : undefined,
            container: props.container,
            isJsonData: props.method === "POST",
            method: props.method,
            url: props.url,
            onResponseCode: {
                400: () => commonDebug.error(`Could not check availability. "${props.url}"`),
            },
        });

        if (!result.data || result.data[props.responseKey] === undefined) {
            return Promise.resolve(false);
        }

        const newResults = new Map(validatedItems);
        newResults.set(value, !result.data[props.responseKey]);
        setValidatedItems(newResults);

        return Promise.resolve(!result.data[props.responseKey]);
    };

    const { ajaxJsonRequest } = useAjax();

    const [validatedItems, setValidatedItems] = useState<Map<string, boolean>>(new Map<string, boolean>());

    return { validate };
};
